import { async } from "q"
import { axiosGet, axiosPost } from "../../../../../react_axios/Axios"
import { getExhibitorDetailsUrl, GET_EXHIBITOR_DETAILS_ACTION_TYPE, updateExhibitorDetailsUrl, UPDATE_EXHIBITOR_DETAILS_ACTION_TYPE, headers, } from "../../../../../utils/constants"

export const getExhibitorDetails_action = () => async (dispatch) => {
    try {
        const params = {
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhregistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
            AccountID: JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID,
            Token: sessionStorage.getItem('tokenSS'),
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ServiceID: sessionStorage.getItem('ServiceIDSS')
        }
        console.log("Space Booking Action ----->", params)
        const res = await axiosGet(getExhibitorDetailsUrl, params, headers)

        dispatch({
            type: GET_EXHIBITOR_DETAILS_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const updateExhibitorDetails_action = (chapterList, Remark) => async (dispatch) => {
    try {
        const mapData = {
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhRegistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
            chapterList: chapterList,
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            Remark: Remark,
        }
        console.log("map", mapData)
        const res = await axiosPost(updateExhibitorDetailsUrl, mapData, headers)

        dispatch({
            type: UPDATE_EXHIBITOR_DETAILS_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}
