import { GET_VENDOR_REGISTRATION_LIST, ADD_VENDOR_REGISTRATION, DELETE_VENDOR_REGISTRATION, UPDATE_VENDOR_REGISTRATION, DETAILS_VENDOR_REGISTRATION } from "../../../../utils/constants";


const initialState = [];

function vendorRegistration_reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_VENDOR_REGISTRATION_LIST:
            return [...state, payload];
        case ADD_VENDOR_REGISTRATION:
            return [...state, payload];
        case UPDATE_VENDOR_REGISTRATION:
            return [...state, payload];
        case DELETE_VENDOR_REGISTRATION:
            return [...state, payload];
        case DETAILS_VENDOR_REGISTRATION:
            return [...state, payload];
        default:
            return state;
    }
}
export default vendorRegistration_reducer;