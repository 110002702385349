import { GET_DETAILS_VISITOR_VISA_APPLICATION_ACTION_TYPE, GET_VISITOR_VISA_APPLICATION_ACTION_TYPE, GET_VISITOR_VISA_INVITATION_ACTION_TYPE, UPDATE_VISITOR_VISA_APPROVAL_ACTION_TYPE } from "../../../utils/constants";


const initialState = [];

function visitor_visaApprovalReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_VISITOR_VISA_INVITATION_ACTION_TYPE:
            return [...state, payload];
        case GET_VISITOR_VISA_APPLICATION_ACTION_TYPE:
            return [...state, payload];
        case UPDATE_VISITOR_VISA_APPROVAL_ACTION_TYPE:
            return [...state, payload];
        case GET_DETAILS_VISITOR_VISA_APPLICATION_ACTION_TYPE:
            return [...state, payload];

        default:
            return state;
    }
}
export default visitor_visaApprovalReducer;