import { axiosGet, axiosPost } from "../../../../react_axios/Axios";
import { getExhFreightForwardDetailsUrl, insertUpdateExhFreightForwardUrl, INSERT_UPDATE_EXH_FREIGHT_FORWARD, GET_EXH_EXH_FREIGHT_DETAILS, headers } from "../../../../utils/constants";


export const getExhFreightForwardDetails_action = (ExihibitionID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExihibitionID,
        }

        const res = await axiosGet(getExhFreightForwardDetailsUrl, params, headers)

        dispatch({
            type: GET_EXH_EXH_FREIGHT_DETAILS,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const insertUpdateExhFreightForward_action = (ExihibitionID, ExhFreightForwardList) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExihibitionID,
            ExhFreightForwardList: ExhFreightForwardList,
        }

        const res = await axiosPost(insertUpdateExhFreightForwardUrl, params, headers)

        dispatch({
            type: INSERT_UPDATE_EXH_FREIGHT_FORWARD,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}