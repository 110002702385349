import { axiosGet, axiosPost } from "../../../../react_axios/Axios";
import { getExhNoteDetailsUrl, insertUpdateExhNoteUrl, INSERT_UPDATE_EXH_NOTE_CONFIGRATION, GET_EXH_NOTE_DETAILS, headers } from "../../../../utils/constants";


export const getExhNoteDetails_action = (ExihibitionID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExihibitionID,
        }

        const res = await axiosGet(getExhNoteDetailsUrl, params, headers)

        dispatch({
            type: GET_EXH_NOTE_DETAILS,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const insertUpdateExhNote_action = (ExihibitionID, ExhRegistrationNoteList) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExihibitionID,
            ExhRegistrationNoteList: ExhRegistrationNoteList,
        }

        const res = await axiosPost(insertUpdateExhNoteUrl, params, headers)

        dispatch({
            type: INSERT_UPDATE_EXH_NOTE_CONFIGRATION,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}