import { async } from "q"
import { axiosGet, axiosPost } from "../../../../../react_axios/Axios"
import { headers, getVisitorMeetingListUrl, insertUpdateVisitorMeetingUrl, GET_VISITOR_MEETING_LIST, INSERT_UPDATE_VISITOR_MEETING } from "../../../../../utils/constants"

export const getVisitorMeeting_action = (companyName, Designation, city, chapter) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhRegistrationID: parseInt(sessionStorage.getItem('ExhRegistrationIDSS')),
            CompanyName: companyName,
            Designation: Designation,
            City: city,
            ChapterName: chapter
        }

        const res = await axiosGet(getVisitorMeetingListUrl, params)
        dispatch({
            type: GET_VISITOR_MEETING_LIST,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const insertUpdateVisitorMeeting_action = (VisitorMeetingBookingDataID, MeetingDate, MeetingTime, Remark) => async (dispatch) => {
    try {
        const mapData = {
            UserID: parseInt(JSON.parse(sessionStorage.getItem('loginDataSS')).UserID),
            ExhibitionID: parseInt(sessionStorage.getItem('sessionExhibitionID')),
            ExhregistrationID: parseInt(sessionStorage.getItem('ExhRegistrationIDSS')),
            VisitorMeetingBookingDataID: parseInt(VisitorMeetingBookingDataID),
            MeetingDate: MeetingDate,
            MeetingTime: MeetingTime,
            Remark: Remark,
        }
        const res = await axiosPost(insertUpdateVisitorMeetingUrl, mapData, headers)

        dispatch({
            type: INSERT_UPDATE_VISITOR_MEETING,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}
