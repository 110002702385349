import { SEND_EMAIL_OF_AVAILABLE_AREA_ACTION_TYPE, MOVE_TO_APPLY_STATUS_ACTION_TYPE, SEND_WAITING_EMAIL_ACTION_TYPE, GET_APPLICATION_STATUS_MANAGEMENT_ACTION_TYPE } from "../../../../utils/constants";


const initialState = [];

function application_status_management_reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SEND_EMAIL_OF_AVAILABLE_AREA_ACTION_TYPE:
            return [...state, payload];
        case MOVE_TO_APPLY_STATUS_ACTION_TYPE:
            return [...state, payload];
        case SEND_WAITING_EMAIL_ACTION_TYPE:
            return [...state, payload];
        case GET_APPLICATION_STATUS_MANAGEMENT_ACTION_TYPE:
            return [...state, payload];
        default:
            return state;
    }
}
export default application_status_management_reducer;