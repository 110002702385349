import { axiosGet, axiosPost } from "../../../react_axios/Axios";
import { GET_REMINDER_LIST_ACTION_TYPE, REMINDER_EMAIL_ACTION_TYPE, getReminderListUrl, headers, reminderEmailUrl } from "../../../utils/constants";



export const reminderEmail_action = (ReminderEventID, list) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ReminderEventID: ReminderEventID,
            exhinitorList: list
        }
        console.log("reminderEmail_action Action------->", params)

        const res = await axiosPost(reminderEmailUrl, params, headers)

        dispatch({
            type: REMINDER_EMAIL_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getReminderList_action = (companyName, reminderId) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            CompanyName: companyName,
            ReminderEventID: reminderId
        }
        console.log("getReminderList_action Action------->", params)

        const res = await axiosGet(getReminderListUrl, params, headers)

        dispatch({
            type: GET_REMINDER_LIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}