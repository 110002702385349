import { GET_MANUAL_FORMS_ACTION_TYPE } from "../../../utils/constants";


const initialState = [];

function manualFormReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case GET_MANUAL_FORMS_ACTION_TYPE:
            return [...state, payload];
        default:
            return state;
    }
};

export default manualFormReducer;