
import { Component, forwardRef } from "react";
import Header from "../../../../common_components/Header";
import Loader from "../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import Pagination from "../../../../utils/pagination";
import { withRouter } from "../../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action } from '../../../../actions/fillCombo_action';
import { downloadReport, exhibitorTypeActionId, toastError, toastSuccess } from "../../../../utils/constants";
import AdminDrawer from "../../../../common_components/admin_module_drawer";
import Modal from "react-modal";
import HeaderWithLogo from "../../../../common_components/header_with_logo";
import "react-datepicker/dist/react-datepicker.css";
import ExhibitorHeader from "../../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../../common_components/exhibitor_module_drawer";
import ExhibitorTopMenu from "../../../../common_components/exhibitor_module_topmenu";
import { deleteVendorAllocation_action, getHallStallList_action, addUpdateVendorAllocation_action, getVendorAllocationDetails_action } from "../../../../actions/admin_module_action/vendor_action/vendorAllocation_action/vendorAllocation_action";
import { exhibitionId, vendorListId, serviceTypeId, hallActionId, stallActionId } from "../../../../utils/constants";
import { map } from "jquery";

const styles = {
    width: '100%',
};

class VendorAllocationList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            vendorRegistrationList: [],
            currentPage: 1,
            hallStallCurrentPage: 1,
            selectedHallStallCurrentPage: 1,
            ExhibitionID: '',
            exhibitionList: [],
            vendorId: '',
            vendorList: [],
            vendorServicesList: [],
            serviceDropdownList: [],
            isOpen: false,
            hallId: '',
            hallList: [],
            stallId: '',
            stallList: [],
            hallStallList: [],
            allSelected: false,
            selectedHallStallList: [],
            vendorAccountID: '',
            AccountID: '',
            ServiceID: '',
            allSelectedHallStall: false,
            renderhallStallList: [],
            dummyRenderhallStallList: [],
            ExhibitionIDErr: ''
        }
        this.updateItem = this.updateItem.bind(this)
        this.updateHallStallItem = this.updateHallStallItem.bind(this)
        this.updateSelectedHallStallItem = this.updateSelectedHallStallItem.bind(this) 
    }

    componentDidMount() {
        this.getFillList(exhibitionId, '', '', '', '', '');
        this.getFillList(vendorListId, '', '', '', '', '');
        this.getFillList(serviceTypeId, '', '', '', '', '');

    }

    inputValidate() {
        const { ExhibitionID } = this.state

        if (ExhibitionID == '' || ExhibitionID == 'Select' || ExhibitionID == null) {
            this.setState({
                ExhibitionIDErr: 'Please select exhibition'
            })
        }
    }

    // get fill List dropdown  
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, exhRegistrationID) {
        const { } = this.state
        this.setState({ isLoading: true })
        console.log("actionID, exhibitionID, moduleID, keyValue, value, exhRegistrationID", actionID, exhibitionID, moduleID, keyValue, value, exhRegistrationID)
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value, exhRegistrationID)
            .then((data) => {
                if (data.error != 1) {
                    // voucher list
                    if (actionID === exhibitionId) {
                        // console.log("voucher type data", data.data)
                        this.setState({
                            exhibitionList: data.data, isLoading: false,
                        })
                    }
                    if (actionID === vendorListId) {
                        // console.log("voucher type data", data.data)
                        this.setState({
                            vendorList: data.data, isLoading: false,
                        })
                    }
                    // for vendor service type List
                    if (actionID === 54) {

                        if (data.data['chekedData'] != '' && data.data['Data'] != '') {
                            const uniqueArray = this.removeDuplicates(data.data['chekedData'], 'ServiceID', 'ExhibitionID');
                            data.data['Data'].map((data) => {
                                data.Is_checked = 1
                                uniqueArray.map((item) => {
                                    if (item.ExhibitionID == this.state.ExhibitionID) {
                                        if (item.ServiceID == data.ServiceId) {
                                            data.Is_flag = 1
                                        }
                                    }
                                })
                            })

                            this.setState({
                                vendorServicesList: data.data['Data'], isLoading: false,
                            })

                        } else {
                            data.data['Data'] != '' ?
                                data.data['Data'].map((item) => {
                                    item.Is_checked = 1
                                    item.Is_flag = 0
                                }) : <></>

                            this.setState({
                                vendorServicesList: data.data['Data'], isLoading: false,
                            })
                        }

                    }
                    if (actionID === serviceTypeId) {
                        this.setState({
                            serviceDropdownList: data.data,
                            isLoading: false,
                        })
                    }
                    if (actionID === hallActionId) {
                        console.log("hallActionId -------------", data.data)
                        this.setState({
                            hallList: data.data,
                            isLoading: false,
                        })
                    }
                    if (actionID === stallActionId) {
                        console.log("stallActionId", data.data)
                        const array = []
                        data.data.map((obj) => {
                            if (this.state.hallId == obj.HallId) {
                                array.push(obj)
                            }
                        })
                        this.setState({
                            stallList: array,
                            isLoading: false,
                        })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    removeDuplicates(array, key, value) {
        return array.filter((item, index, self) =>
            index === self.findIndex((t) => t[key] === item[key] && t[value] === item[value])
        );
    }

    addVendor() {
        this.props.navigate('/VendorRegistrationAU', { state: { add: true } })
    }

    // Update Current Page
    updateItem(item) {
        const { vendorRegistrationList } = this.state
        this.setState({ currentPage: item });
        const recordsPerPage = 10;
        const indexOfLastRecord = item * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = vendorRegistrationList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(vendorRegistrationList.length / recordsPerPage)
    }

    updateHallStallItem(item) {
        const { renderhallStallList } = this.state
        this.setState({ hallStallCurrentPage: item });
        const recordsPerPage = 10;
        const indexOfLastRecord = item * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = renderhallStallList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(renderhallStallList.length / recordsPerPage)
    }

    updateSelectedHallStallItem(item) {
        const { selectedHallStallList } = this.state
        this.setState({ selectedHallStallCurrentPage: item });

        const selectedHallStallRecordsPerPage = 10;
        const selectedHallStallIndexOfLastRecord = item * selectedHallStallRecordsPerPage;
        const selectedHallStallIndexOfFirstRecord = selectedHallStallIndexOfLastRecord - selectedHallStallRecordsPerPage
        const selectedHallStallCurrentRecords = selectedHallStallList.slice(selectedHallStallIndexOfFirstRecord, selectedHallStallIndexOfLastRecord);
        const selectedHallStallnPages = Math.ceil(selectedHallStallList.length / selectedHallStallRecordsPerPage)
    }

    deleteVenddorAllocation(id1, id2) {
        this.props.deleteVendorAllocation_action(id1, id2)
            .then((data) => {
                if (data.error != 1) {
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })

    }

    getHallStallList(AccountID, ServiceID) {
        const { ExhibitionID } = this.state
        this.props.getHallStallList_action(
            ExhibitionID,
            null,
            null,
            AccountID,
            ServiceID != '' && ServiceID != 'Select' ? ServiceID : null
        )
            .then((data) => {
                if (data.error != 1) {
                    const array = []
                    if (data.data != '') {
                        data.data.map((obj) => {
                            obj.vendorAllocationMappingID = null
                        })
                    }
                    this.setState({ hallStallList: data.data })
                    this.getVendorAllocationDetails(ExhibitionID, ServiceID, AccountID, data.data)
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })

    }

    getVendorAllocationDetails(ExhibitionID, ServiceID, AccountID, list) {
        // this.setState({ isLoading: true })
        this.props.getVendorAllocationDetails_action(ExhibitionID, AccountID,
            ServiceID != '' && ServiceID != 'Select' ? ServiceID : null
        )
            .then((data) => {
                if (data.error != 1) {
                    if (data.data != '' && list != '') {
                        const array = []
                        list.map((item) => {
                            data.data.map((obj) => {
                                if (obj.HallID == item.HallID && obj.StallID == item.StallID) {
                                    item.vendorAllocationMappingID = obj.vendorAllocationMappingID
                                    array.push(item)
                                }
                            })
                        })
                        console.log("sagar---------------------------------", array)
                        this.setState({ selectedHallStallList: array, allSelectedHallStall: true })
                    }
                    this.setState({ isLoading: false })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })

    }

    selectVendor(e) {
        const { vendorList } = this.state
        const accId = e.target.value

        if (e.target.value != '' && e.target.value != 'Select') {
            vendorList.map((data) => {
                if (e.target.value == data.AccountID) {
                    this.setState({ vendorAccountID: data.AccountID })
                    this.getFillList(54, '', '', '', accId, '');
                }
            })
            this.setState({ vendorId: e.target.value })
        } else {
            this.setState({ vendorId: '', vendorServicesList: [] })
        }
    }

    addMoreServices() {
        const { vendorServicesList, vendorAccountID } = this.state

        const checkedBlankServices = vendorServicesList.every(item => item.ServiceId != 'Select')

        const obj = {
            "AccountId": vendorAccountID,
            'ServiceId': 'Select',
            'Is_checked': 0,
        }

        console.log("checkedBlankServices", checkedBlankServices)

        if (checkedBlankServices) {
            vendorServicesList.push(obj)
        } else {
            toastError("Plese select first services")
        }
        this.setState({ vendorServicesList: vendorServicesList })

    }

    onServiceType(e, index) {
        const { vendorServicesList } = this.state

        const value = e.target.value

        const checkeService = vendorServicesList.some(item => item.ServiceId == value)

        vendorServicesList.map((data, i) => {
            if (i === index) {
                if (value != '' && value != 'Select') {
                    if (!checkeService) {
                        data.ServiceId = value
                    } else {
                        data.ServiceId = 'Select'
                        toastError("Service type already exist!")
                    }
                }
            }
        })

        this.setState({ vendorServicesList: vendorServicesList })
    }

    vendorAllocate(serviceId, accountId) {
        const { AccountID, ServiceID, ExhibitionIDErr, ExhibitionID } = this.state
        this.inputValidate()
        if (ExhibitionID != '' && ExhibitionID != 'Select') {
            if (serviceId != '' && serviceId != 'Select') {
                this.setState({
                    isOpen: true,
                    AccountID: accountId,
                    ServiceID: serviceId,
                })
                this.getHallStallList(accountId, serviceId)
                this.getFillList(hallActionId, ExhibitionID, '', '', '', '');
            } else {
                toastError('Please select service')
            }
        } else {
            toastError('Please select exhibition')
        }
    }

    toggleModal() {
        const { isOpen, hallId, stallId, hallStallList, selectedHallStallList } = this.state

        this.setState({
            isOpen: !isOpen,
        })
        this.modaleClearValues()
    }

    editVendorAllocation(ServiceId, AccountId) {
        const { isOpen, ExhibitionID } = this.state
        this.inputValidate()
        if (ExhibitionID != '' && ExhibitionID != 'Select') {
            this.setState({
                isOpen: true,
                AccountID: AccountId,
                ServiceID: ServiceId,
            })
            this.getHallStallList(AccountId, ServiceId)
            this.getFillList(hallActionId, ExhibitionID, '', '', '', '');
        } else {
            toastError('Please select exhibition')
        }
    }

    modaleClearValues() {
        this.setState({
            hallId: '',
            stallId: '',
            selectedHallStallList: [],
            hallStallList: [],
            allSelected: false,
            allSelectedHallStall: false,
        })
    }

    vendorDelete(accountId, serviceId, index) {
        const { vendorServicesList } = this.state
        vendorServicesList.map((data, i) => {
            if (index === i) {
                if (data.Is_checked == 1) {
                    console.log("data-----------------------------sagar")
                    this.deleteVenddorAllocation(accountId, serviceId)
                    const List = vendorServicesList.filter((item) => item.ServiceId != serviceId)
                    this.setState({ vendorServicesList: List })
                    toastSuccess("Vendor delete successfully")
                } else {
                    const List = vendorServicesList.filter((item) => item.ServiceId != serviceId)
                    this.setState({ vendorServicesList: List })
                    toastSuccess("Vendor delete successfully")
                }
            }
        })
    }

    onStallSelect(e) {
        const { hallStallList, dummyRenderhallStallList, renderhallStallList } = this.state
        const value = e.target.value
        if (value != '' && value != 'Select') {
            this.setState({ stallId: e.target.value })
            setTimeout(() => {
                this.setState({ allSelected: false, })
                const array = []
                hallStallList != '' ?
                    hallStallList.map((data) => {
                        if (value == data.StallID) {
                            array.push(data)
                        }
                    }) : <></>
                this.setState({ renderhallStallList: array })
                if (array != '') {
                    const checkRenderhallStallAllSelected = array.every(item => item.isCheck == true)

                    if (checkRenderhallStallAllSelected) {
                        this.setState({ allSelected: true })
                    } else {
                        this.setState({ allSelected: false })
                    }
                }
            }, 50);
        } else {
            if (dummyRenderhallStallList != '') {
                const checkRenderhallStallAllSelected = dummyRenderhallStallList.every(item => item.isCheck == true)
                if (checkRenderhallStallAllSelected) {
                    this.setState({ allSelected: true })
                } else {
                    this.setState({ allSelected: false })
                }
            }
            this.setState({ stallId: '', renderhallStallList: dummyRenderhallStallList })
        }
    }

    onHallSelect(e) {
        const { stallId, hallStallList, renderhallStallList, ExhibitionID } = this.state
        const value = e.target.value
        if (value != '' && value != 'Select') {
            this.setState({ hallId: e.target.value, stallId: '', allSelected: false, })
            this.getFillList(stallActionId, ExhibitionID, '', '', '', '')
            const array = []
            hallStallList != '' ?
                hallStallList.map((data) => {
                    if (data.HallID != null && data.StallID != null) {
                        if (value == data.HallID) {
                            array.push(data)
                        }
                    }
                }) : <></>
            this.setState({ renderhallStallList: array, dummyRenderhallStallList: array })
            if (array != '') {
                const checkRenderhallStallAllSelected = array.every(item => item.isCheck == true)

                if (checkRenderhallStallAllSelected) {
                    this.setState({ allSelected: true })
                } else {
                    this.setState({ allSelected: false })
                }
            }
        } else {
            this.setState({ hallId: '', stallId: '', allSelected: false })
        }
    }

    allSelected(e) {
        const { hallStallList, selectedHallStallList, hallId, renderhallStallList } = this.state
        const value = e.target.checked
        if (hallId != '' && hallId != 'Select') {
            if (renderhallStallList != []) {
                if (value != '') {
                    renderhallStallList.map((data) => {
                        if (selectedHallStallList != '') {
                            const checkedAlreadyPresent = selectedHallStallList.some((obj) => data.HallID == obj.HallID && data.StallID == obj.StallID)
                            if (!checkedAlreadyPresent) {
                                selectedHallStallList.push(data)
                                this.setState({ allSelectedHallStall: true })
                            }
                        } else {
                            selectedHallStallList.push(data)
                            this.setState({ allSelectedHallStall: true })
                        }

                        data.isCheck = true
                    })
                    this.setState({ allSelected: true, renderhallStallList: renderhallStallList, allSelectedHallStall: true })
                } else {
                    if (renderhallStallList != '') {
                        renderhallStallList.map((data) => {
                            const removeSelectedHallStall = selectedHallStallList.filter((obj) => data.HallID != obj.HallID && data.StallID != obj.StallID)
                            this.setState({ selectedHallStallList: removeSelectedHallStall })
                            data.isCheck = false
                        })
                    }
                    this.setState({ allSelected: false, renderhallStallList: renderhallStallList, allSelectedHallStall: false })
                }
            }
        }
    }

    onSaveVendorAllocate() {
        const { selectedHallStallList, AccountID, ServiceID, ExhibitionID } = this.state
        console.log('selectedHallStallList ---------onsubmit', selectedHallStallList)

        const array = []

        selectedHallStallList.map((data) => {
            if (data.isCheck == true) {
                array.push(data)
            }
        })
        this.setState({ isLoading: true })
        this.props.addUpdateVendorAllocation_action(ExhibitionID, AccountID, ServiceID, array)
            .then((data) => {
                if (data.error != 1) {
                    toastSuccess("Services allocate successfully")
                    this.setState({ isOpen: false, isLoading: false })
                    this.getFillList(54, '', '', '', AccountID, '');
                    this.modaleClearValues()
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    onCheckbox(hallId, e, stallId) {
        const { hallStallList, selectedHallStallList, renderhallStallList } = this.state

        const value = e.target.checked


        hallStallList.map((data, i) => {
            if (hallId === data.HallID && stallId === data.StallID) {
                if (value == true) {
                    if (selectedHallStallList != '') {
                        const checkedAlreadyPresent = selectedHallStallList.some((obj) => data.HallID == obj.HallID && data.StallID == obj.StallID)
                        if (!checkedAlreadyPresent) {
                            selectedHallStallList.push(data)
                            this.setState({ allSelectedHallStall: true })
                        }
                    } else {
                        this.setState({ allSelectedHallStall: true })
                        selectedHallStallList.push(data)
                    }

                    setTimeout(() => {
                        if (renderhallStallList != '') {
                            const checkRenderhallStallAllSelected = renderhallStallList.every(item => item.isCheck == true)

                            if (checkRenderhallStallAllSelected) {
                                this.setState({ allSelected: true })
                            } else {
                                this.setState({ allSelected: false })
                            }
                        }
                    }, 10);

                    data.isCheck = true
                } else {
                    const array = selectedHallStallList.filter(item => item.HallID != data.HallID || item.StallID != data.StallID)
                    data.isCheck = false
                    this.setState({ selectedHallStallList: array })
                }
            }
        })

        this.setState({ hallStallList: hallStallList })

        const checkAllSelected = hallStallList.every(item => item.isCheck == true)

        if (checkAllSelected) {
            this.setState({ allSelected: true })
        } else {
            this.setState({ allSelected: false })
        }
    }

    onSelectedHallStallCheckbox(name, e, index) {
        const { hallStallList, selectedHallStallList, renderhallStallList } = this.state

        const value = e.target.checked

        setTimeout(() => {
            if (renderhallStallList != '') {
                const checkRenderhallStallAllSelected = renderhallStallList.every(item => item.isCheck == true)

                if (checkRenderhallStallAllSelected) {
                    this.setState({ allSelected: true })
                } else {
                    this.setState({ allSelected: false })
                }
            }
        }, 10);

        selectedHallStallList.map((data, i) => {
            if (index === i) {
                if (value == true) {
                    data.isCheck = true
                } else {
                    data.isCheck = false
                }
            }
        })
        const array = selectedHallStallList.filter(item => item.isCheck == true)

        this.setState({ selectedHallStallList: array, allSelectedHallStall: true })

    }

    allSelectedHallStall(e) {
        const { selectedHallStallList } = this.state
        const value = e.target.checked
        if (selectedHallStallList != []) {
            if (value != '') {
                selectedHallStallList.map((data) => {
                    data.isCheck = true
                })
                this.setState({ allSelectedHallStall: true, selectedHallStallList: selectedHallStallList })
            } else {
                selectedHallStallList.map((data) => {
                    data.isCheck = false
                })
                this.setState({ allSelectedHallStall: false, selectedHallStallList: [], allSelected: false })
            }
        }
    }

    onExhibitionSelect(e) {
        const { vendorId } = this.state
        this.setState({ ExhibitionID: e.target.value, ExhibitionIDErr: '' })

        // vendorList.map((data) => {
        //     if (e.target.value == data.AccountID) {
        //         this.setState({ vendorAccountID: data.AccountID })
        this.getFillList(54, '', '', '', vendorId, '')
        //     }
        // })
    }

    render() {
        const { isLoading, ExhibitionID, vendorId, vendorList, currentPage, vendorServicesList, exhibitionList, serviceDropdownList, isOpen, hallList, stallList, hallId, stallId, hallStallCurrentPage, hallStallList, allSelected, selectedHallStallList, allSelectedHallStall, renderhallStallList, ExhibitionIDErr, selectedHallStallCurrentPage } = this.state

        const recordsPerPage = 10;
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = vendorServicesList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(vendorServicesList.length / recordsPerPage)

        const hallStallRecordsPerPage = 10;
        const hallStallIndexOfLastRecord = hallStallCurrentPage * hallStallRecordsPerPage;
        const hallStallIndexOfFirstRecord = hallStallIndexOfLastRecord - hallStallRecordsPerPage
        const hallStallCurrentRecords = renderhallStallList.slice(hallStallIndexOfFirstRecord, hallStallIndexOfLastRecord);
        const hallStallnPages = Math.ceil(renderhallStallList.length / hallStallRecordsPerPage)

        const selectedHallStallRecordsPerPage = 10;
        const selectedHallStallIndexOfLastRecord = selectedHallStallCurrentPage * selectedHallStallRecordsPerPage;
        const selectedHallStallIndexOfFirstRecord = selectedHallStallIndexOfLastRecord - selectedHallStallRecordsPerPage
        const selectedHallStallCurrentRecords = selectedHallStallList.slice(selectedHallStallIndexOfFirstRecord, selectedHallStallIndexOfLastRecord);
        const selectedHallStallnPages = Math.ceil(selectedHallStallList.length / selectedHallStallRecordsPerPage)


        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                {
                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                        <ExhibitorHeader /> : <HeaderWithLogo />
                }
                <div className={JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ? "with-dr-container" : "with-dr-container com-height"}>
                    <div class="main-content">

                        {
                            JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                                <ExhibitorDrawer /> : <AdminDrawer />
                        }
                        {
                            JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                                <ExhibitorTopMenu /> : null
                        }
                        <div class="row">
                            <div class="step-name">
                                <p>Vendor Allocation</p>
                            </div>
                        </div>

                        <div class="row mt-1 pt-3 pb-3 p-2">
                            <div className="col-md-3 ">
                                <label>Exhibition</label>
                                <select
                                    className="form-control"
                                    id="roledb"
                                    // style={{ textAlign: "left" }}
                                    value={ExhibitionID}
                                    onChange={(e) => this.onExhibitionSelect(e)}
                                >
                                    <option>Select</option>
                                    {exhibitionList.length > 0 && exhibitionList.map((data) => (
                                        <option key={data.ExhibitionID
                                        } value={data.ExhibitionID
                                        }>{data.ExhibitionName}</option>
                                    ))}
                                </select>
                                {<span className='text-danger text-start text-left d-block mt-1'>{ExhibitionIDErr}</span>}
                            </div>
                            <div className="col-md-3">
                                <label>Select Vendor</label>
                                <select
                                    className="form-control"
                                    id="roledb"
                                    // style={{ textAlign: "left" }}
                                    value={vendorId}
                                    onChange={(e) => this.selectVendor(e)}
                                >
                                    <option>Select</option>
                                    {vendorList.length > 0 && vendorList.map((data) => (
                                        <option key={data.AccountID
                                        } value={data.AccountID
                                        }>{data.CompanyName}</option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div class="table-responsive p-2">
                            <table class="table border text-nowrap text-md-nowrap table-bordered mb-0 ">
                                <thead>
                                    <tr>
                                        {/* text-center */}
                                        <th style={{ width: '50%' }}>Service Provided</th>
                                        <th style={{ width: '50%' }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        currentRecords.length > 0 && currentRecords != null ?
                                            currentRecords.map((data, i) => (
                                                <tr>
                                                    <td>
                                                        <select
                                                            className="form-control"
                                                            // style={{ textAlign: "left" }}
                                                            value={data.ServiceId}
                                                            onChange={(e) => this.onServiceType(e, i)}
                                                            disabled={data.Is_checked == 1}
                                                        >
                                                            <option>Select</option>
                                                            {serviceDropdownList.length > 0 && serviceDropdownList.map((data) => (
                                                                <option key={data.ServiceID
                                                                } value={data.ServiceID
                                                                }>{data.Name}</option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                    <td className="text-center">
                                                        {
                                                            data.Is_flag == 1 ?
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary"
                                                                    style={{ padding: "1px 10px", margin: '0px 10px' }}
                                                                    onClick={(e) => this.editVendorAllocation(data.ServiceId, data.AccountId)}
                                                                >
                                                                    <i className="fa fa-pencil" aria-hidden="true" /> Edit
                                                                </button> :
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-info"
                                                                    style={{ padding: "1px 10px", margin: '0px 10px' }}
                                                                    onClick={(e) => this.vendorAllocate(data.ServiceId, data.AccountId)}
                                                                >
                                                                    Allocate
                                                                </button>
                                                        }
                                                        <button
                                                            type="button"
                                                            className="btn btn-danger"
                                                            style={{ padding: "1px 10px" }}
                                                            onClick={(e) => this.vendorDelete(data.AccountId, data.ServiceId, i)}
                                                        >
                                                            <i className="fa fa-trash" aria-hidden="true" /> Delete
                                                        </button>

                                                    </td>

                                                </tr>
                                            )) : (<tr><td colSpan={10} className='text-center'>Record not found</td></tr>)
                                    }
                                </tbody>
                            </table>

                            {vendorId != '' && vendorId != "Select" ?
                                <div className="row">
                                    <div className="col-md-12">
                                        <div
                                            className="btn-group mb-1"
                                            role="group"
                                            aria-label="Basic example"
                                            style={{ float: "left" }}
                                        >
                                            <button type="button" className="btn ad-fil-btn mt-3"
                                                onClick={() => this.addMoreServices()}
                                            >
                                                Add More
                                            </button>
                                        </div>
                                    </div>
                                </div> : <></>
                            }

                            <div className="mt-5 float-left">
                                {
                                    vendorServicesList.length > 0 ?
                                        <Pagination
                                            nPages={nPages}
                                            currentPage={currentPage}
                                            setCurrentPage={this.updateItem}
                                        /> : <></>
                                }
                                <div className="tab-rec " style={{ float: "right" }}>
                                    <p>{`${currentPage} of ${nPages} pages`}</p>
                                </div>
                            </div>
                        </div>

                        <div class="footer-sec ">
                            <div class="sdsd">
                                <strong>Ownership:</strong> All content, formats and images used on this site are owned or licensed by India ITME Society. You may not copy, reproduce, distribute, publish, display, perform, modify, create derivative works, transmit, or in any other way exploit any part of copyrighted material without permission of the copyright owner. Failure to comply shall result in legal action.
                            </div>
                        </div>

                        <div class="footer-first">
                            <div class="container-fluid">
                                <div class="footer-first">  ITME All Rights Reserved <i class="fa fa-copyright" aria-hidden="true"></i> 2023  </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal isOpen={isOpen} onRequestClose={(e) => this.toggleModal(e)} >
                    <div
                        className="modal-dialog modal-dialog-centered bd-example-modal-xl modal-xl"
                        id="detail"
                    // tabIndex={-1}
                    // aria-labelledby="detailLabel"
                    // aria-hidden="true"

                    >
                        <div className="modal-dialog modal-xl" >
                            <div className="modal-content estimate-details"  >
                                <div className="modal-body p-2">
                                    <div className="d-flex">
                                        <div className="col-md-6">
                                            <label>Hall Dropdown</label>
                                            <select
                                                className="form-control"
                                                id="roledb"
                                                // style={{ textAlign: "left" }}
                                                value={hallId}
                                                onChange={(e) => this.onHallSelect(e)}
                                            >
                                                <option>Select</option>
                                                {hallList.length > 0 && hallList.map((data) => (
                                                    <option key={data.HallId
                                                    } value={data.HallId
                                                    }>{data.HallName}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-md-6">
                                            <label>Stall Dropdown</label>
                                            <select
                                                className="form-control"
                                                id="roledb"
                                                // style={{ textAlign: "left" }}
                                                disabled={hallId == '' || hallId == 'Select'}
                                                value={stallId}
                                                onChange={(e) => this.onStallSelect(e)}
                                            >
                                                <option>Select</option>
                                                {stallList.length > 0 && stallList.map((data) => (
                                                    <option key={data.StallId
                                                    } value={data.StallId
                                                    }>{data.StallName}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div class="table-responsive mt-3 p-2">
                                        <div className="d-flex">
                                            <table class="table border text-nowrap text-md-nowrap table-bordered mb-0 table-fix-Content" style={{ marginRight: '10px' }}>
                                                <thead>
                                                    <tr>
                                                        {/* text-center */}
                                                        <th style={{ width: '10px' }}>
                                                            <input
                                                                type='checkbox'
                                                                checked={allSelected}
                                                                onChange={(e) => this.allSelected(e)}
                                                            />
                                                        </th>
                                                        <th>Hall / Stall</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        hallStallCurrentRecords.length > 0 && hallStallCurrentRecords != null ?
                                                            <>
                                                                {hallId != '' && hallId != 'Select' ?
                                                                    hallStallCurrentRecords.map((data, i) => (
                                                                        <tr>
                                                                            <td>
                                                                                <input
                                                                                    type='checkbox'
                                                                                    checked={data.isCheck}
                                                                                    onChange={(e) => this.onCheckbox(data.HallID, e, data.StallID)}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                {data.Name}
                                                                            </td>
                                                                        </tr>
                                                                    )) : (<tr><td colSpan={10} className='text-center'>Record not found</td></tr>)
                                                                }
                                                            </>
                                                            :
                                                            (<tr><td colSpan={10} className='text-center'>Record not found</td></tr>)
                                                    }
                                                </tbody>
                                            </table>
                                            <table class="table border text-nowrap text-md-nowrap table-bordered mb-0 table-fix-Content">
                                                <thead>
                                                    <tr>
                                                        {/* text-center */}
                                                        <th style={{ width: '10px' }}>
                                                            <input
                                                                type='checkbox'
                                                                checked={allSelectedHallStall}
                                                                onChange={(e) => this.allSelectedHallStall(e)}
                                                            />
                                                        </th>
                                                        <th >Selected Hall / Stall</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        selectedHallStallList != '' ?
                                                            selectedHallStallList.map((item, i) => (
                                                                <tr><td>
                                                                    <input
                                                                        type='checkbox'
                                                                        checked={item.isCheck}
                                                                        onChange={(e) => this.onSelectedHallStallCheckbox(item.Name, e, i)}
                                                                    />
                                                                </td>
                                                                    <td className="text-center">
                                                                        {item.Name}
                                                                    </td>
                                                                </tr>
                                                            ))
                                                            : (<tr><td colSpan={10} className='text-center'>Record not found</td></tr>)
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-flex w-100" style={{ justifyContent: 'space-between' }}>
                                            <div className="mt-3 float-left">
                                                {
                                                    hallStallList.length > 0 ?
                                                        <Pagination
                                                            nPages={hallStallnPages}
                                                            currentPage={hallStallCurrentPage}
                                                            setCurrentPage={this.updateHallStallItem}
                                                        /> : <></>
                                                }
                                            </div>
                                            <div className="mt-3 float-right">
                                                {
                                                    hallStallList.length > 0 ?
                                                        <Pagination
                                                            nPages={selectedHallStallnPages}
                                                            currentPage={selectedHallStallCurrentRecords}
                                                            setCurrentPage={this.updateSelectedHallStallItem}
                                                        /> : <></>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="modal-footer p-1">
                                    {selectedHallStallList != '' ?
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-bs-dismiss="modal"
                                            onClick={() => this.onSaveVendorAllocate()}
                                        >
                                            Save
                                        </button>
                                        : <></>}
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={() => this.toggleModal()}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

            </>
        )
    }
}
export default withRouter(connect(null, { fill_action, deleteVendorAllocation_action, getHallStallList_action, addUpdateVendorAllocation_action, getVendorAllocationDetails_action })(VendorAllocationList));

