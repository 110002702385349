import { Component } from "react";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action } from "../../../actions/fillCombo_action"
import { ToastContainer } from "react-toastify";
import Loader from "../../../utils/Loader";
import { cityActionId, countryActionId, pincodeActionId, primaryChapterListActionId, stateActionId, toastError, toastSuccess } from "../../../utils/constants";
import ExhibitionDrawer from "../../../common_components/admin_module_drawer";
import ExhibitionHeader from "../../../common_components/admin_module_header";
import HeaderWithLogo from "../../../common_components/header_with_logo";
import { data } from "jquery";
import { agentEntrySave_action, verifyAgentEmail_action, agentDetails_action, agentEntryUpate_action, insertAgentPI1_action, insertAgentOpenSidePI_action, getAgentMailDetails_action, insertAgentPI2_action } from "../../../actions/admin_module_action/agent_entry_action/agent_action"
import $ from 'jquery';
import Select from 'react-select';
import Modal from "react-modal";
import { emailValidator } from "../../../utils/Validator";
import { element } from "prop-types";
import AgentHeader from "../../../common_components/agent_module_header";
import AgentDrawer from "../../../common_components/agent_module_drawer";
import AgentTopMenu from "../../../common_components/agent_module_topmenu";




class Agententry extends Component {

    // Initialize constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            nationalityId: '',
            is_add: false,
            is_update: false,
            is_view: false,
            is_enroll: false,
            billingType: '',
            isSelfPaid: 1,
            exhibitionID: '',

            isOtherPersonInchFirst: false,
            isDisplayPincodeList: false,
            isDisplayCityList: false,
            isDisplayCountryList: false,
            isDisplayStateList: false,
            isSameAsCorrespondence: false,
            isDisplayBillCountryList: false,
            isDisplayBillStateList: false,
            isDisplayBillPincodeList: false,
            isDisplayBillCityList: false,
            isDisplayAgentCountryList: false,
            isbillOtherPersonInchFirst: false,
            isbillDisplayPincodeList: false,
            isbillDisplayCityList: false,
            isbillDisplayCountryList: false,
            isbillDisplayStateList: false,

            //list
            countryList: [],
            tempCountryList: [],
            billTempCountryList: [],
            agentTempCountryList: [],
            stateList: [],
            tempStateList: [],
            billTempStateList: [],
            cityList: [],
            tempCityList: [],
            billTempCityList: [],
            pincodeList: [],
            tempPincodeList: [],
            billTempPincodeList: [],
            accTypeList: ['Saving Account', 'Fixed Deposit Account', 'Reccurring Deposit Account', 'NRI Account', 'Others'],
            primaryChapterList: [],
            interNationalCountryList: [],
            selectedCountry: [],
            selectedCountryIdList: [],
            loginAccesslist: [],

            //correspondence details
            areaAllocationId: "",
            AccountId: "",
            preAgentCode: "AG1",
            agentCode: "",
            billingRadioId: "",
            otherPersonInchFirst: "",
            personInchFirst: "",
            prefixPersonInchFirst: "MR",
            personInchLast: '',
            designation: '',
            companyName: '',
            add1: '',
            add2: '',
            pin: '',
            city: '',
            state: '',
            country: '',
            countryId: '',
            preMobNo: '',
            mobNo: '',
            altMobNo: '',
            altPreMobNo: '',
            telephone: '',
            altTelephone: '',
            preTelephone: '',
            altPreTelephone: '',
            primaryEmail: '',
            secondaryEmail: '',
            website: '',
            currencyID: '',

            //billing
            billOtherPersonInchFirst: "",
            billPersonInchFirst: "",
            billPrefixPersonInchFirst: "MR",
            billPersonInchLast: '',
            billDesignation: '',
            billCompanyName: '',
            billAdd1: '',
            billAdd2: '',
            billPin: '',
            billCity: '',
            billState: '',
            billCountry: '',
            billCountryId: '',
            billPreMobNo: '',
            billMobNo: '',
            billAltMobNo: '',
            billAltPreMobNo: '',
            billTelephone: '',
            billAltTelephone: '',
            billPreTelephone: '',
            billAltPreTelephone: '',
            billPrimaryEmail: '',
            billSecondaryEmail: '',
            billWebsite: '',

            //bank details
            accName: '',
            accNo: '',
            accType: '',
            accTypeOthers: '',
            bankName: '',
            branchName: '',
            IFSC: '',

            //area des
            agentCountries: '',
            agentCountryId: '',
            totalNationalArea: '',
            totalInternationalArea: '',
            nationalAreaDesc1: '',
            nationalAreaDesc2: '',
            nationalAreaDesc3: '',
            nationalAreaDesc4: '',
            internationalAreaDesc1: '',
            internationalAreaDesc2: '',
            internationalAreaDesc3: '',
            internationalAreaDesc4: '',

            //error
            agentCodeErr: "",
            billingTypeErr: '',
            primaryEmailErr: '',
            preMobNoErr: '',
            mobNoErr: '',
            countryErr: '',
            stateErr: '',
            cityErr: '',
            pinErr: '',
            add1Err: '',
            companyNameErr: '',
            designationErr: '',
            personInchLastErr: '',
            personInchFirstErr: '',

            //billing error
            billPrimaryEmailErr: '',
            billPreMobNoErr: '',
            billMobNoErr: '',
            billCountryErr: '',
            billStateErr: '',
            billCityErr: '',
            billPinErr: '',
            billAdd1Err: '',
            billCompanyNameErr: '',
            billDesignationErr: '',
            billPersonInchLastErr: '',
            billPersonInchFirstErr: '',

            //bank error
            accNameErr: '',
            accNoErr: '',
            IFSCErr: '',

            //area error
            totalNationalAreaErr: '',
            totalInternationalAreaErr: '',

            stallTypeList: [],
            stallTypeErr: '',
            isOpen: false,
            PIbutton: '',
            conversionRate: '',
            installment1: '',
            installment2: '',

            is_navFromProfile: false,
            is1stAgentPICreated: 0,
            is2ndAgentPICreated: 0,
            isManualPICreated: 0,
            isTaxInvoiceCreated: 0,
            isOpenSidePICreated: 0
        }

        this.getFillList = this.getFillList.bind(this)
        this.onCountrySearch = this.onCountrySearch.bind(this)
        this.renderCountryList = this.renderCountryList.bind(this)
        this.maxLengthCheck = this.maxLengthCheck.bind(this)
        this.preventMinus = this.preventMinus.bind(this)
        this.onStateSearch = this.onStateSearch.bind(this)
        this.PIModalOpen = this.PIModalOpen.bind(this)
        this.renderStateList = this.renderStateList.bind(this)
        this.onCitySearch = this.onCitySearch.bind(this)
        this.renderCityList = this.renderCityList.bind(this)
        this.onPincodeSearch = this.onPincodeSearch.bind(this)
        this.renderPincodeList = this.renderPincodeList.bind(this)
        this.onBillCountrySearch = this.onBillCountrySearch.bind(this)
        this.renderBillCountryList = this.renderBillCountryList.bind(this)
        this.onBillStateSearch = this.onBillStateSearch.bind(this)
        // this.callBillStateList = this.callBillStateList.bind(this)
        this.renderBillStateList = this.renderBillStateList.bind(this)
        this.renderBillCityList = this.renderBillCityList.bind(this)
        this.onBillCitySearch = this.onBillCitySearch.bind(this)
        this.renderBillPincodeList = this.renderBillPincodeList.bind(this)
        this.onBillPincodeSearch = this.onBillPincodeSearch.bind(this)
        this.onAgentCountrySearch = this.onAgentCountrySearch.bind(this)
        this.renderAgentCountryList = this.renderAgentCountryList.bind(this)
        this.OnChapterSelect = this.OnChapterSelect.bind(this)
        this.onSave = this.onSave.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.getDetails = this.getDetails.bind(this)
        this.inputValidate = this.inputValidate.bind(this)
        this.sameAsCorrespondence = this.sameAsCorrespondence.bind(this)
        this.sameAsCorrespondanceValidate = this.sameAsCorrespondanceValidate.bind(this)
        this.onCancel = this.onCancel.bind(this)
        this.onNationalAreaChange = this.onNationalAreaChange.bind(this)
        this.onInternationalAreaChange = this.onInternationalAreaChange.bind(this)
        this.handleCountrySelect = this.handleCountrySelect.bind(this)
        this.verifyAgentEmail = this.verifyAgentEmail.bind(this)
        this.getWirteAccess = this.getWirteAccess.bind(this)
        this.getUpdateAccess = this.getUpdateAccess.bind(this)
        this.onDomesticPriceChange = this.onDomesticPriceChange.bind(this)
        this.onInternationalPriceChange = this.onInternationalPriceChange.bind(this)
        this.onGenerate = this.onGenerate.bind(this)

    }

    componentDidMount = () => {
        console.log("11111111111111111111111111111111111111", JSON.parse(sessionStorage.getItem('loginDataSS')), JSON.parse(sessionStorage.getItem('indirectAgentloginDataSS')), this.props.location.state.is_update, this.props.location.state)
        // this.props.location.state != null && (this.props.location.state.is_view || this.props.location.state.is_update) && this.getDetails()


        this.setState({
            is_add: this.props.location.state != null && this.props.location.state.is_add != null ? this.props.location.state.is_add : false,
            is_update: this.props.location.state != null && this.props.location.state.is_update != null ? this.props.location.state.is_update : false,
            is_view: this.props.location.state != null && this.props.location.state.is_view != null ? this.props.location.state.is_view : false,
            is_navFromProfile: this.props.location.state != null && this.props.location.state.is_navFromProfile != null ? this.props.location.state.is_navFromProfile : false,
            is_enroll: this.props.location.state != null && this.props.location.state.is_enroll != null ? this.props.location.state.is_enroll : false,
            exhibitionID: this.props.location.state != null && this.props.location.state.exhibitionID != null ? this.props.location.state.exhibitionID : sessionStorage.getItem('sessionExhibitionID'),
            nationalityId: this.props.location.state != null && this.props.location.state.nationalityId != null ? this.props.location.state.nationalityId : '',
            agentCountryId: this.props.location.state != null && this.props.location.state.nationalityId == "national" ? "91" : '',
            selectedCountry: this.props.location.state != null && this.props.location.state.nationalityId == "national" ? [{ label: "India", value: "91" }] : [],
            nationalityId: this.props.location.state != null && this.props.location.state.nationalityId ? this.props.location.state.nationalityId : '',
            AccountId: this.props.location.state != null && this.props.location.state.Account_Id ? this.props.location.state.Account_Id : '',
            loginAccesslist: JSON.parse(sessionStorage.getItem('loginDataSS'))['loginAccesslist'],

            // is1stAgentPICreated: this.props.location.state != null && this.props.location.state.is1stAgentPICreated != null ? this.props.location.state.is1stAgentPICreated : false,
            // is2ndAgentPICreated: this.props.location.state != null && this.props.location.state.is2ndAgentPICreated != null ? this.props.location.state.is2ndAgentPICreated : false,
            // isManualPICreated: this.props.location.state != null && this.props.location.state.isManualPICreated != null ? this.props.location.state.isManualPICreated : false,
            // isTaxInvoiceCreated: this.props.location.state != null && this.props.location.state.isTaxInvoiceCreated != null ? this.props.location.state.isTaxInvoiceCreated : false,

        })
        this.setState({
            stallTypeList: [
                // { 'Stalltype': 'BARE SPACE PRICING', 'DomesticPrice': '', 'InternationalPrice': '', 'DomesticPriceErr': null, 'InternationalPriceErr': null },
                // { 'Stalltype': 'SHELL SPACE PRICING', 'DomesticPrice': '', 'InternationalPrice': '', 'DomesticPriceErr': null, 'InternationalPriceErr': null },
                // { 'Stalltype': 'DOUBLE STOREY PRICING', 'DomesticPrice': '', 'InternationalPrice': '', 'DomesticPriceErr': null, 'InternationalPriceErr': null },
                // { 'Stalltype': 'SECURITY DEPOSITE', 'DomesticPrice': '', 'InternationalPrice': '', 'DomesticPriceErr': null, 'InternationalPriceErr': null },

                { 'Stalltype': 'BARE SPACE PRICING', 'DomesticPrice': 0, 'InternationalPrice': 0, 'DomesticPriceErr': null, 'InternationalPriceErr': null },
                { 'Stalltype': 'SHELL SPACE PRICING', 'DomesticPrice': 0, 'InternationalPrice': 0, 'DomesticPriceErr': null, 'InternationalPriceErr': null },
                { 'Stalltype': 'DOUBLE STOREY PRICING', 'DomesticPrice': 0, 'InternationalPrice': 0, 'DomesticPriceErr': null, 'InternationalPriceErr': null },
                { 'Stalltype': 'SECURITY DEPOSITE', 'DomesticPrice': 0, 'InternationalPrice': 0, 'DomesticPriceErr': null, 'InternationalPriceErr': null },
            ]
        })
        var promise = new Promise((resolve, rej) => {
            this.getFillList(countryActionId, '', '', '', '', '');
            this.getFillList(stateActionId, '', '', '', '', '');
            this.getFillList(cityActionId, '', '', '', '', '');
            this.getFillList(pincodeActionId, '', '', '', '', '');
            resolve(true)
        })
        promise.then((result) => {
            if (result) {
                console.log("result----------->", result)
            }
        })
        this.getFillList(primaryChapterListActionId, '', '', '', '', '').then((data) => {
            console.log('ll', data)
            this.props.location.state != null && (this.props.location.state.is_view || this.props.location.state.is_update)
                && this.getDetails()
        })
    }

    //Input Field Validations
    inputValidate() {
        const { personInchLast, primaryEmail, telephone, altTelephone, mobNo, designation, countryId, city, add1, companyName, personInchFirst,
            accName, accNo, billPersonInchFirst, billPersonInchLast, billDesignation, billCompanyName, billAdd1, billPin, billCity,
            billCountryId, billMobNo, isSelfPaid, billPrimaryEmail, IFSC, stallTypeList, totalInternationalArea, totalNationalArea, nationalityId, pin, state, billState,
            primaryChapterList, agentCode } = this.state
        if (agentCode == "") {
            this.setState({ agentCodeErr: "Please enter Agent Code" });
        }
        if (personInchFirst == "") {
            this.setState({ personInchFirstErr: "Please enter first name" });
        }
        if (personInchLast == "") {
            this.setState({ personInchLastErr: "Please enter last name" });
        }
        if (designation == "") {
            this.setState({ designationErr: "Please Enter Designation" });
        }
        if (companyName == "") {
            this.setState({ companyNameErr: "Please Enter Company Name" });
        }
        if (add1 == "") {
            this.setState({ add1Err: "Please enter address" });
        }
        if (pin == "") {
            this.setState({ pinErr: "Please Enter Zipcode" });
        }
        if (city == "") {
            this.setState({ cityErr: "Please Enter city" });
        }
        if (countryId == "91" && state == "") {
            this.setState({ stateErr: "Please Enter State" });
        }
        if (countryId == "") {
            this.setState({ countryErr: "Please Enter country" });
        }
        if (mobNo == "") {
            this.setState({ mobNoErr: "Please Enter Mobile No." });
        }
        if (primaryEmail == "") {
            this.setState({ primaryEmailErr: "Please enter Email" });
        }
        if (primaryEmail != "") {
            var emailError = emailValidator(primaryEmail)
            if (emailError != '') {
                this.setState({ primaryEmailErr: emailError })
                return
            }
        }
        if (billPersonInchFirst == "") {
            this.setState({ billPersonInchFirstErr: "Please enter first name" });
        }
        if (billPersonInchLast == "") {
            this.setState({ billPersonInchLastErr: "Please enter person incharge last name" });
        }
        if (billDesignation == "") {
            this.setState({ billDesignationErr: "Please Enter Designation" });
        }
        if (billCompanyName == "") {
            this.setState({ billCompanyNameErr: "Please Enter Company Name" });
        }
        if (billAdd1 == "") {
            this.setState({ billAdd1Err: "Please Enter Address" });
        }
        if (billCity == "") {
            this.setState({ billCityErr: "Please Enter city" });
        }
        if (billPin == "") {
            this.setState({ billPinErr: "Please Enter zipcode" });
        }
        if (billState == "" && billCountryId == "91") {
            this.setState({ billStateErr: "Please Enter State" });
        }
        if (billCountryId == "") {
            this.setState({ billCountryErr: "Please Enter Country" });
        }
        if (billMobNo == "") {
            this.setState({ billMobNoErr: "Please Enter Mobile No." });
        }
        if (billPrimaryEmail == "") {
            this.setState({ billPrimaryEmailErr: "Please enter Email" });
        }
        if (billPrimaryEmail != "") {
            var emailError = emailValidator(billPrimaryEmail)
            if (emailError != '') {
                this.setState({ billPrimaryEmailErr: emailError })
                return
            }
        }
        if (accName == "" && isSelfPaid != 0) {
            this.setState({ accNameErr: "Please Enter Account Name" });
        }
        if (accNo == "" && isSelfPaid != 0) {
            this.setState({ accNoErr: "Please Enter Account No." });
        }
        if (IFSC == "" && isSelfPaid != 0) {
            this.setState({ IFSCErr: "Please Enter IFSC Code" });
        }
        if (totalInternationalArea == '' && (nationalityId == 'mix' || nationalityId == 'international')) {
            this.setState({ totalInternationalAreaErr: "Please Enter total international area" });
        }
        if (totalNationalArea == '' && (nationalityId == 'mix' || nationalityId == 'national')) {
            this.setState({ totalNationalAreaErr: "Please Enter total national area" });
        }

        // Validation For Chapter wise area description
        primaryChapterList.length > 0 && primaryChapterList.map((data) => {
            if (nationalityId == "national") {
                if (data.IsSelect == true) {
                    if (data.nationalArea == "") {
                        data.nationalAreaErr = "Please Enter Area Description"
                    } else {
                        data.nationalAreaErr = ""
                    }
                }

            } else if (nationalityId == "international") {
                if (data.IsSelect == true) {
                    if (data.internationalArea == "") {
                        data.internationalAreaErr = "Please Enter Area Description"
                    } else {
                        data.internationalAreaErr = ""
                    }
                }

            } else if (nationalityId == "mix") {
                if (data.IsSelect == true) {
                    if (data.internationalArea == "") {
                        data.internationalAreaErr = "Please Enter Area Description"
                    } else {
                        data.internationalAreaErr = ""
                    }
                    if (data.nationalArea == "") {
                        data.nationalAreaErr = "Please Enter Area Description"
                    } else {
                        data.nationalAreaErr = ""
                    }
                }
            }
        })
        // Validation For Chapter wise area description
        // stallTypeList.length > 0 && stallTypeList.map((data) => {
        //     if (nationalityId == "national") {
        //         if (data.Stalltype == 'BARE SPACE PRICING') {
        //             if (data.DomesticPrice == "" || data.DomesticPrice == null) {
        //                 data.DomesticPriceErr = "Please Enter Domestic price"
        //             } else {
        //                 data.DomesticPriceErr = null
        //             }
        //         }
        //         if (data.Stalltype == 'SHELL SPACE PRICING') {
        //             if (data.DomesticPrice == "" || data.DomesticPrice == null) {
        //                 data.DomesticPriceErr = "Please Enter Domestic price"
        //             } else {
        //                 data.DomesticPriceErr = null
        //             }
        //         }

        //     } else if (nationalityId == "international") {
        //         if (data.Stalltype == 'BARE SPACE PRICING') {
        //             if (data.InternationalPrice == "" || data.InternationalPrice == null) {
        //                 data.InternationalPriceErr = "Please Enter international price"
        //             } else {
        //                 data.InternationalPriceErr = null
        //             }
        //         }
        //         if (data.Stalltype == 'SHELL SPACE PRICING') {
        //             if (data.InternationalPrice == "" || data.InternationalPrice == null) {
        //                 data.InternationalPriceErr = "Please Enter international price"
        //             } else {
        //                 data.InternationalPriceErr = null
        //             }
        //         }
        //     } else if (nationalityId == "mix") {
        //         if (data.Stalltype == 'BARE SPACE PRICING') {
        //             if (data.DomesticPrice == "" || data.DomesticPrice == null) {
        //                 console.log(data.DomesticPrice, stallTypeList)
        //                 data.DomesticPriceErr = "Please Enter Domestic price"
        //             } else {
        //                 data.DomesticPriceErr = null
        //             }
        //             if (data.InternationalPrice == "" || data.InternationalPrice == null) {
        //                 data.InternationalPriceErr = "Please Enter international price"
        //             } else {
        //                 data.InternationalPriceErr = null
        //             }
        //         }
        //         if (data.Stalltype == 'SHELL SPACE PRICING') {
        //             if (data.DomesticPrice == "" || data.DomesticPrice == null) {
        //                 data.DomesticPriceErr = "Please Enter Domestic price"
        //             } else {
        //                 data.DomesticPriceErr = null
        //             }
        //             if (data.InternationalPrice == "" || data.InternationalPrice == null) {
        //                 data.InternationalPriceErr = "Please Enter international price"
        //             } else {
        //                 data.InternationalPriceErr = null
        //             }
        //         }
        //     }
        // })
        // this.setState({ stallTypeList: stallTypeList })
    }

    // save 
    onSave() {
        this.inputValidate()

        setTimeout(() => {
            const { personInchLast, primaryEmail, primaryEmailErr, telephone, altTelephone, preTelephoneErr, telephoneErr, mobNo, preMobNoErr,
                preMobNo, mobNoErr, designation, country, countryErr, city, cityErr, add1, add1Err, add2, designationErr, companyName,
                companyNameErr, personInchFirstErr, personInchFirst, accName, accNo, personInchLastErr, billPersonInchFirst, billPersonInchLast,
                billDesignation, billCompanyName, billAdd1, billPin, billCity, billCountry, billPreMobNo, billMobNo, billPrimaryEmail,
                billPrimaryEmailErr, billPreMobNoErr, altTelephoneErr, altPreTelephoneErr, billMobNoErr, billCountryErr, billCityErr,
                billPinErr, billAdd1Err, billCompanyNameErr, billDesignationErr, billPersonInchLastErr, billPersonInchFirstErr, IFSC,
                totalInternationalArea, totalInternationalAreaErr, totalNationalArea, totalNationalAreaErr, accNameErr, accNoErr, IFSCErr,
                nationalityId, isSelfPaid, state, pin, countryId, altMobNo, secondaryEmail, website, isSameAsCorrespondence, billAdd2,
                billState, billCountryId, billAltMobNo, billTelephone, billAltTelephone, billSecondaryEmail, billWebsite, accType, bankName,
                branchName, prefixPersonInchFirst, billPrefixPersonInchFirst, altPreMobNo, preTelephone, altPreTelephone, billAltPreMobNo,
                billPreTelephone, billAltPreTelephone, selectedCountry, stallTypeList, primaryChapterList, agentCode, preAgentCode, agentCodeErr, installment1, installment2 } = this.state
            //Validate InputFields before Submit
            if (personInchLast != "" && primaryEmail != "" && mobNo != "" && preMobNo != "" && designation != "" && country != "" && city != "" && add1 != "" && companyName != "" && personInchFirst != "" && (isSelfPaid != 0 ? accName != "" : true) && (isSelfPaid != 0 ? accNo != "" : true) && billPersonInchFirst != "" && billPersonInchLast != "" && billDesignation != "" && billCompanyName != "" && billAdd1 != "" && (nationalityId.toLowerCase().trim() == 'international' ? billPin != "" : true) && billCity != "" && billCountry != "" && billPreMobNo != "" && billMobNo != "" && billPrimaryEmail != "" && (isSelfPaid != 0 ? IFSC != "" : true) && agentCode != "" && (nationalityId.toLowerCase().trim() == "" ? totalInternationalArea != "" && totalNationalArea != "" : ((nationalityId.toLowerCase().trim() == "national" ? totalNationalArea != "" : totalInternationalArea != "")))) {

                if (personInchFirstErr == '' && personInchLastErr == '' && designationErr == '' && companyNameErr == '' && add1Err == '' &&
                    cityErr == '' && countryErr == '' && preMobNoErr == '' && mobNoErr == '' && primaryEmailErr == '' && billPersonInchFirstErr == '' &&
                    billPersonInchLastErr == '' && billDesignationErr == '' && billCompanyNameErr == '' && billAdd1Err == '' && billCityErr == '' &&
                    billPinErr == '' && billCountryErr == '' && billPreMobNoErr == '' && billMobNoErr == '' && billPrimaryEmailErr == '' &&
                    (isSelfPaid != 0 ? accNameErr == '' : true) && (isSelfPaid != 0 ? accNoErr == '' : true) && (isSelfPaid != 0 ? IFSCErr == '' : true) && totalInternationalAreaErr == '' && totalNationalAreaErr == '' &&
                    agentCodeErr == "") {

                    /// Setting data in session storage For Insert Next
                    sessionStorage.setItem("sessionPreMobNo", preMobNo)
                    sessionStorage.setItem("sessionPreAltMobNo", altPreMobNo)
                    sessionStorage.setItem("sessionPreTelNo", preTelephone)
                    sessionStorage.setItem("sessionPreAltTelNo", altPreTelephone)
                    sessionStorage.setItem("sessionInvPreMobNo", billPreMobNo)
                    sessionStorage.setItem("sessionInvPreAltMobNo", billAltPreMobNo)
                    sessionStorage.setItem("sessionInvPreTelNo", billPreTelephone)
                    sessionStorage.setItem("sessionInvPreAltTelNo", billAltPreTelephone)

                    if (selectedCountry.length > 0 || nationalityId.toLowerCase().trim() == "national") {
                        var isChapterSelected = primaryChapterList.length > 0 && primaryChapterList.some(data => data.IsSelect)
                        if (isChapterSelected) {

                            // Checking For any area messages in Chapter List
                            var isErr = false
                            primaryChapterList.length > 0 && primaryChapterList.map((item) => {

                                if (item.IsSelect) {
                                    if (nationalityId == "national") {
                                        if (item.nationalAreaErr != "") {
                                            isErr = true
                                        } else {
                                            isErr = false
                                        }
                                    } else if (nationalityId == "international") {
                                        if (item.internationalAreaErr != "") {
                                            isErr = true
                                        } else {
                                            isErr = false
                                        }
                                    } else if (nationalityId == "mix") {
                                        if (item.nationalAreaErr != "") {
                                            isErr = true
                                        } else {
                                            if (item.internationalAreaErr != "") {
                                                isErr = true
                                            } else {
                                                isErr = false
                                            }
                                        }
                                    }
                                }
                            })
                            var stallTypeErr = false
                            // stallTypeList.length > 0 && stallTypeList.map((item) => {

                            //     // if (item.IsSelect) {
                            //     if (nationalityId == "national") {
                            //         if (item.Stalltype == 'BARE SPACE PRICING') {
                            //             if (item.DomesticPrice == "" || item.DomesticPrice == null) {
                            //                 stallTypeErr = true
                            //             }
                            //         }
                            //         if (item.Stalltype == 'SHELL SPACE PRICING') {
                            //             if (item.DomesticPrice == "" || item.DomesticPrice == null) {
                            //                 stallTypeErr = true
                            //             }
                            //         }
                            //     } else if (nationalityId == "international") {
                            //         if (item.Stalltype == 'BARE SPACE PRICING') {
                            //             if (item.InternationalPrice == "" || item.InternationalPrice == null) {
                            //                 stallTypeErr = true
                            //             }
                            //         }
                            //         if (item.Stalltype == 'SHELL SPACE PRICING') {
                            //             if (item.InternationalPrice == "" || item.InternationalPrice == null) {
                            //                 stallTypeErr = true
                            //             }
                            //         }
                            //     } else if (nationalityId == "mix") {
                            //         if (item.Stalltype == 'BARE SPACE PRICING') {
                            //             if (item.DomesticPrice == "" || item.DomesticPrice == null) {
                            //                 stallTypeErr = true
                            //             }
                            //         }
                            //         if (item.Stalltype == 'SHELL SPACE PRICING') {
                            //             if (item.DomesticPrice == "" || item.DomesticPrice == null) {
                            //                 stallTypeErr = true
                            //             }
                            //         }
                            //         if (item.Stalltype == 'BARE SPACE PRICING') {
                            //             if (item.InternationalPrice == "" || item.InternationalPrice == null) {
                            //                 stallTypeErr = true
                            //             }
                            //         }
                            //         if (item.Stalltype == 'SHELL SPACE PRICING') {
                            //             if (item.InternationalPrice == "" || item.InternationalPrice == null) {
                            //                 stallTypeErr = true
                            //             }
                            //         }
                            //     }
                            //     // }
                            // })
                            console.log(stallTypeList)
                            if (!stallTypeErr) {
                                if (!isErr) {
                                    var isAreaErr = false
                                    var allNationalArea = []
                                    var allInternationalArea = []
                                    if (nationalityId == "national") {
                                        primaryChapterList.length > 0 && primaryChapterList.map((data) => {
                                            allNationalArea.push(parseInt(data.nationalArea))
                                        })
                                        var sumNatioalArea = allNationalArea.filter(value => !isNaN(value)).reduce((acc, curr) => acc + curr, 0);
                                        console.log("allNationalArea------------>", allNationalArea)
                                        console.log("sumNatioalArea------------>", sumNatioalArea)
                                        if (sumNatioalArea > totalNationalArea) {
                                            isAreaErr = true
                                        } else {
                                            isAreaErr = false
                                        }
                                    }
                                    if (nationalityId == "international") {
                                        primaryChapterList.length > 0 && primaryChapterList.map((data) => {
                                            allInternationalArea.push(parseInt(data.internationalArea))
                                        })
                                        var sumInternationalArea = allInternationalArea.filter(value => !isNaN(value)).reduce((acc, curr) => acc + curr, 0);
                                        if (sumInternationalArea > totalInternationalArea) {
                                            isAreaErr = true
                                        } else {
                                            isAreaErr = false
                                        }
                                    }
                                    if (nationalityId == "mix") {
                                        primaryChapterList.length > 0 && primaryChapterList.map((data) => {
                                            allNationalArea.push(parseInt(data.nationalArea))
                                            allInternationalArea.push(parseInt(data.internationalArea))
                                        })
                                        var sumNatioalArea = allNationalArea.filter(value => !isNaN(value)).reduce((acc, curr) => acc + curr, 0);
                                        var sumInternationalArea = allInternationalArea.filter(value => !isNaN(value)).reduce((acc, curr) => acc + curr, 0);
                                        console.log("sumNatioalArea", sumNatioalArea, totalNationalArea)
                                        console.log("sumInternationalArea", sumInternationalArea, totalInternationalArea)
                                        console.log("condition------->", sumNatioalArea > totalNationalArea)
                                        console.log("condition1111111111------->", sumInternationalArea > totalInternationalArea)
                                        if (sumNatioalArea > totalNationalArea) {
                                            isAreaErr = true
                                        } else if (sumInternationalArea > totalInternationalArea) {
                                            isAreaErr = true
                                        } else {
                                            isAreaErr = false
                                        }
                                    }

                                    if (!isAreaErr) {
                                        // Preparing Area Allocation Country According UDT
                                        var areaAllocationCountryData = []
                                        selectedCountry.length > 0 && selectedCountry.map(data => {
                                            var mapData = {
                                                'CountryID': parseInt(data.value),
                                            }
                                            areaAllocationCountryData.push(mapData)
                                        });
                                        // Preparing Area Allocation Chapter According UDT
                                        var areaAllocationChapterData = []
                                        primaryChapterList.map(data => {
                                            if (data.IsSelect) {
                                                var mapData2 = {
                                                    'ChapterID': parseInt(data.ChapterID),
                                                    'DomesticArea': !isNaN(data.nationalArea) && data.nationalArea != "" &&
                                                        data.nationalArea != null ? parseFloat(data.nationalArea).toFixed(2) : 0,
                                                    'internationalArea': !isNaN(data.internationalArea) && data.internationalArea != "" &&
                                                        data.internationalArea != null ? parseFloat(data.internationalArea).toFixed(2) : 0,
                                                }
                                                areaAllocationChapterData.push(mapData2)
                                            }
                                        });
                                        var finalAgentCode = `${preAgentCode}${agentCode}`
                                        console.log("OK TESTED")
                                        this.setState({ isLoading: true })
                                        this.props.agentEntrySave_action(isSelfPaid, companyName, add1, add2, city, state, pin, countryId, prefixPersonInchFirst, personInchFirst,
                                            personInchLast, designation, mobNo, altMobNo, telephone, altTelephone, primaryEmail, secondaryEmail, website,
                                            isSameAsCorrespondence, billCompanyName, billAdd1, billAdd2, billCity, billState, billPin, billCountryId, billPrefixPersonInchFirst,
                                            billPersonInchFirst, billPersonInchLast, billDesignation, billMobNo, billAltMobNo, billTelephone, billAltTelephone,
                                            billPrimaryEmail, billSecondaryEmail, billWebsite, accName, accNo, accType, bankName, branchName, IFSC, finalAgentCode,
                                            nationalityId == "national" ? 1 : nationalityId == "international" ? 2 : nationalityId == "mix" ? 3 : "",
                                            totalNationalArea, totalInternationalArea, areaAllocationCountryData, areaAllocationChapterData, stallTypeList, installment1, installment2)
                                            .then((data) => {
                                                if (data.error != 1) {
                                                    toastSuccess("Agent Register Succesfully")
                                                    setTimeout(() => {
                                                        this.props.navigate('/agentlist')
                                                    }, 1000);
                                                    this.setState({ isLoading: false })
                                                } else {
                                                    this.setState({ isLoading: false })
                                                    toastError(data.msg)
                                                }
                                            }).catch((err) => {
                                                console.log(err)
                                            })
                                    } else {
                                        toastError("Area is not matched")
                                    }
                                }
                                else {
                                    toastError("Please Enter Area Description")
                                }
                            } else {
                                toastError("Please Enter Area Pricing")
                            }
                        } else {
                            toastError("Please Select Atleast One Chapter")
                        }
                    }
                    else {
                        toastError("Please Select Atleast One Country")
                    }
                }
                else {
                    toastError("Enter Mandatory Fields")
                }
            }
            else {
                toastError("Enter Mandatory Fields")
            }
        }, 1000);
    }

    //update
    handleUpdate(e) {
        const { isLoading, secondaryEmail, website, personInchLast, altPreMobNo, primaryEmail, primaryEmailErr, telephone, altTelephone,
            preTelephone, altPreTelephone, preTelephoneErr, telephoneErr, altMobNo, mobNo, preMobNoErr, preMobNo, mobNoErr, designation,
            isDisplayCountryList, pin, pinErr, country, countryErr, state, isDisplayStateList, stateErr, city, cityErr, isDisplayCityList,
            isDisplayPincodeList, add1, add1Err, add2, designationErr, companyName, companyNameErr, personInchFirstErr,
            isOtherPersonInchFirst, prefixPersonInchFirst, otherPersonInchFirst, personInchFirst, accName, accNo,
            isbillOtherPersonInchFirst, isDisplayBillCountryList, isDisplayBillCityList, isDisplayBillPincodeList, billOtherPersonInchFirst,
            billPersonInchFirst, billPrefixPersonInchFirst, billPersonInchLast, billDesignation, billCompanyName, billAdd1, billAdd2,
            billPin, billCity, billState, billCountry, billPreMobNo, billMobNo, billAltMobNo, isDisplayBillStateList, billAltPreMobNo,
            billTelephone, billAltTelephone, billPreTelephone, billAltPreTelephone, billPrimaryEmail, billSecondaryEmail, billWebsite,
            billPrimaryEmailErr, billPreTelephoneErr, billTelephoneErr, billPreMobNoErr, billMobNoErr, billCountryErr, billStateErr,
            billCityErr, billPinErr, billAdd1Err, billCompanyNameErr, billDesignationErr, billPersonInchLastErr, billPersonInchFirstErr,
            accType, accTypeOthers, bankName, branchName, IFSC, agentCountries, totalInternationalArea, totalInternationalAreaErr,
            totalNationalArea, totalNationalAreaErr, altTelephoneErr, altPreTelephoneErr, nationalityId, agentCode, personInchLastErr,
            accNameErr, accNoErr, IFSCErr, agentCodeErr, primaryChapterList, preAgentCode, selectedCountry, isSelfPaid, countryId,
            isSameAsCorrespondence, billCountryId, AccountId, areaAllocationId, stallTypeList, installment1, installment2 } = this.state

        //Validate InputFields before edit
        this.inputValidate()
        if (
            personInchLast != "" && primaryEmail != "" && mobNo != "" && preMobNo != "" && designation != "" && country != "" && city != "" && add1 != "" && companyName != "" && personInchFirst != "" && (isSelfPaid != 0 ? accName != "" : true) && (isSelfPaid != 0 ? accNo != "" : true) && billPersonInchFirst != "" && billPersonInchLast != "" &&
            billDesignation != "" && billCompanyName != "" && billAdd1 != "" &&
            (nationalityId.toLowerCase().trim() == 'international' ? billPin != "" : true) && billCity != "" && billCountry != "" &&
            billPreMobNo != "" && billMobNo != "" && billPrimaryEmail != "" && (isSelfPaid != 0 ? IFSC != "" : true) && agentCode != "" &&
            (nationalityId.toLowerCase().trim() == "" ? totalInternationalArea != "" &&
                totalNationalArea != "" : ((nationalityId.toLowerCase().trim() == "national" ? totalNationalArea != "" : totalInternationalArea != "")))
        ) {
            if (personInchFirstErr == '' && personInchLastErr == '' && designationErr == '' && companyNameErr == '' && add1Err == '' &&
                cityErr == '' && countryErr == '' && preMobNoErr == '' && mobNoErr == '' && primaryEmailErr == '' && billPersonInchFirstErr == '' &&
                billPersonInchLastErr == '' && billDesignationErr == '' && billCompanyNameErr == '' && billAdd1Err == '' && billCityErr == '' &&
                billPinErr == '' && billCountryErr == '' && billPreMobNoErr == '' && billMobNoErr == '' && billPrimaryEmailErr == '' &&
                (isSelfPaid != 0 ? accNameErr == '' : true) && (isSelfPaid != 0 ? accNoErr == '' : true) && (isSelfPaid != 0 ? IFSCErr == '' : true) && totalInternationalAreaErr == '' && totalNationalAreaErr == '' &&
                agentCodeErr == "") {

                /// Setting data in session storage For Insert Next
                sessionStorage.setItem("sessionPreMobNo", preMobNo)
                sessionStorage.setItem("sessionPreAltMobNo", altPreMobNo)
                sessionStorage.setItem("sessionPreTelNo", preTelephone)
                sessionStorage.setItem("sessionPreAltTelNo", altPreTelephone)
                sessionStorage.setItem("sessionInvPreMobNo", billPreMobNo)
                sessionStorage.setItem("sessionInvPreAltMobNo", billAltPreMobNo)
                sessionStorage.setItem("sessionInvPreTelNo", billPreTelephone)
                sessionStorage.setItem("sessionInvPreAltTelNo", billAltPreTelephone)
                console.log(selectedCountry)
                if (selectedCountry.length > 0 || nationalityId.toLowerCase().trim() == "national") {
                    var isChapterSelected = primaryChapterList.length > 0 && primaryChapterList.some(data => data.IsSelect)
                    if (isChapterSelected) {
                        // Checking For any area messages in Chapter List
                        var isErr = false
                        primaryChapterList.length > 0 && primaryChapterList.map((item) => {

                            if (item.IsSelect) {
                                if (nationalityId == "national") {
                                    if (item.nationalAreaErr != "") {
                                        isErr = true
                                    } else {
                                        isErr = false
                                    }
                                } else if (nationalityId == "international") {
                                    if (item.internationalAreaErr != "") {
                                        isErr = true
                                    } else {
                                        isErr = false
                                    }
                                } else if (nationalityId == "mix") {
                                    if (item.nationalAreaErr != "") {
                                        isErr = true
                                    } else {
                                        if (item.internationalAreaErr != "") {
                                            isErr = true
                                        } else {
                                            isErr = false
                                        }
                                    }
                                }
                            }
                        })
                        var stallTypeErr = false
                        // stallTypeList.length > 0 && stallTypeList.map((item) => {

                        //     // if (item.IsSelect) {
                        //     if (nationalityId == "national") {
                        //         if (item.Stalltype == 'BARE SPACE PRICING') {
                        //             if (item.DomesticPrice == "" || item.DomesticPrice == null) {
                        //                 stallTypeErr = true
                        //             }
                        //         }
                        //         if (item.Stalltype == 'SHELL SPACE PRICING') {
                        //             if (item.DomesticPrice == "" || item.DomesticPrice == null) {
                        //                 stallTypeErr = true
                        //             }
                        //         }
                        //     } else if (nationalityId == "international") {
                        //         if (item.Stalltype == 'BARE SPACE PRICING') {
                        //             console.log(item.InternationalPriceErr, 'stallTypeErr', nationalityId, stallTypeErr)
                        //             if (item.InternationalPrice == "" || item.InternationalPrice == null) {
                        //                 stallTypeErr = true
                        //             }
                        //         }
                        //         if (item.Stalltype == 'SHELL SPACE PRICING') {
                        //             if (item.InternationalPrice == "" || item.InternationalPrice == null) {
                        //                 stallTypeErr = true
                        //             }
                        //         }
                        //     } else if (nationalityId == "mix") {
                        //         if (item.Stalltype == 'BARE SPACE PRICING') {
                        //             if (item.DomesticPrice == "" || item.DomesticPrice == null) {
                        //                 stallTypeErr = true
                        //             }
                        //         }
                        //         if (item.Stalltype == 'SHELL SPACE PRICING') {
                        //             if (item.DomesticPrice == "" || item.DomesticPrice == null) {
                        //                 stallTypeErr = true
                        //             }
                        //         }
                        //         if (item.Stalltype == 'BARE SPACE PRICING') {
                        //             if (item.InternationalPrice == "" || item.InternationalPrice == null) {
                        //                 stallTypeErr = true
                        //             }
                        //         }
                        //         if (item.Stalltype == 'SHELL SPACE PRICING') {
                        //             if (item.InternationalPrice == "" || item.InternationalPrice == null) {
                        //                 stallTypeErr = true
                        //             }
                        //         }
                        //     }
                        //     // }
                        // })
                        if (!stallTypeErr) {

                            if (!isErr) {
                                var isAreaErr = false
                                var allNationalArea = []
                                var allInternationalArea = []
                                if (nationalityId == "national") {
                                    primaryChapterList.length > 0 && primaryChapterList.map((data) => {
                                        allNationalArea.push(parseInt(data.nationalArea))
                                    })
                                    var sumNatioalArea = allNationalArea.filter(value => !isNaN(value)).reduce((acc, curr) => acc + curr, 0);
                                    console.log("allNationalArea------------>", allNationalArea)
                                    console.log("sumNatioalArea------------>", sumNatioalArea)
                                    if (sumNatioalArea > totalNationalArea) {
                                        isAreaErr = true
                                    } else {
                                        isAreaErr = false
                                    }
                                }
                                if (nationalityId == "international") {
                                    primaryChapterList.length > 0 && primaryChapterList.map((data) => {
                                        allInternationalArea.push(parseInt(data.internationalArea))
                                    })
                                    var sumInternationalArea = allInternationalArea.filter(value => !isNaN(value)).reduce((acc, curr) => acc + curr, 0);
                                    if (sumInternationalArea > totalInternationalArea) {
                                        isAreaErr = true
                                    } else {
                                        isAreaErr = false
                                    }
                                }
                                if (nationalityId == "mix") {
                                    primaryChapterList.length > 0 && primaryChapterList.map((data) => {
                                        allNationalArea.push(parseInt(data.nationalArea))
                                        allInternationalArea.push(parseInt(data.internationalArea))
                                    })
                                    var sumNatioalArea = allNationalArea.filter(value => !isNaN(value)).reduce((acc, curr) => acc + curr, 0);
                                    var sumInternationalArea = allInternationalArea.filter(value => !isNaN(value)).reduce((acc, curr) => acc + curr, 0);
                                    console.log("sumNatioalArea", sumNatioalArea, totalNationalArea)
                                    console.log("sumInternationalArea", sumInternationalArea, totalInternationalArea)
                                    console.log("condition------->", sumNatioalArea > totalNationalArea)
                                    console.log("condition1111111111------->", sumInternationalArea > totalInternationalArea)
                                    if (sumNatioalArea > totalNationalArea) {
                                        isAreaErr = true
                                    } else if (sumInternationalArea > totalInternationalArea) {
                                        isAreaErr = true
                                    } else {
                                        isAreaErr = false
                                    }
                                }
                                if (!isAreaErr) {
                                    // Preparing Area Allocation Country According UDT
                                    var areaAllocationCountryData = []
                                    selectedCountry.length > 0 && selectedCountry.map(data => {
                                        var mapData = {
                                            'CountryID': parseInt(data.value),
                                        }
                                        areaAllocationCountryData.push(mapData)
                                    });
                                    // Preparing Area Allocation Chapter According UDT
                                    var areaAllocationChapterData = []
                                    primaryChapterList.map(data => {
                                        if (data.IsSelect) {
                                            var mapData2 = {
                                                'ChapterID': parseInt(data.ChapterID),
                                                'DomesticArea': !isNaN(data.nationalArea) && data.nationalArea != "" &&
                                                    data.nationalArea != null ? parseFloat(data.nationalArea).toFixed(2) : 0,
                                                'internationalArea': !isNaN(data.internationalArea) && data.internationalArea != "" &&
                                                    data.internationalArea != null ? parseFloat(data.internationalArea).toFixed(2) : 0,
                                            }
                                            areaAllocationChapterData.push(mapData2)
                                        }
                                    });
                                    var finalAgentCode = `${preAgentCode}${agentCode}`
                                    this.setState({ isLoading: true })
                                    this.props.agentEntryUpate_action(AccountId, isSelfPaid, companyName, add1, add2, city, state, pin, countryId, prefixPersonInchFirst, personInchFirst,
                                        personInchLast, designation, mobNo, altMobNo, telephone, altTelephone, primaryEmail, secondaryEmail, website,
                                        isSameAsCorrespondence, billCompanyName, billAdd1, billAdd2, billCity, billState, billPin, billCountryId, billPrefixPersonInchFirst,
                                        billPersonInchFirst, billPersonInchLast, billDesignation, billMobNo, billAltMobNo, billTelephone, billAltTelephone,
                                        billPrimaryEmail, billSecondaryEmail, billWebsite, accName, accNo, accType, bankName, branchName, IFSC, finalAgentCode,
                                        nationalityId == "national" ? 1 : nationalityId == "international" ? 2 : nationalityId == "mix" ? 3 : "",
                                        totalNationalArea, totalInternationalArea, areaAllocationId, areaAllocationCountryData, areaAllocationChapterData, stallTypeList, installment1, installment2)
                                        .then((data) => {
                                            if (data.error != 1) {
                                                toastSuccess("Agent Updated Succesfully")
                                                setTimeout(() => {
                                                    this.props.navigate('/agentlist')
                                                }, 1000);
                                                this.setState({ isLoading: false })
                                            } else {
                                                this.setState({ isLoading: false })
                                                toastError(data.msg)
                                            }
                                        }).catch((err) => {
                                            console.log(err)
                                        })
                                } else {
                                    toastError("Area is not matched")
                                }
                            } else {
                                toastError("Please Enter Area Description")
                            }
                        } else {
                            toastError("Please Enter  Area Pricing")
                        }
                    } else {
                        toastError("Please Select Atleast One Chapter")
                    }
                } else {
                    toastError("Please Select Atleast One Country")
                }
            }
            else {
                toastError("Please Enter Mandatory Fields")
            }
        }
        else {
            toastError("Enter Mandatory Fields")
        }
    }

    // Get Details
    getDetails() {
        setTimeout(() => {
            const { primaryChapterList, interNationalCountryList, exhibitionID, stallTypeList } = this.state
            var accId = this.props.location.state.Account_Id
            this.setState({ isLoading: true })
            this.props.agentDetails_action(accId, exhibitionID)
                .then((data) => {
                    if (data.error != 1) {
                        console.log("Get Agent Details------------->", data.data.description[0])
                        // Spliting Agent Code
                        var code = data.data.description[0]['AgentCode'] != null && data.data.description[0]['AgentCode'] != '' ? data.data.description[0]['AgentCode'] : ''
                        var code2 = code != "" && code != null ? code.slice(3) : ''
                        // Split Phone Numbers and country Code 
                        // Registration Mobile No
                        var mob1 = data.data.description[0]['MobileNo'] != null ? data.data.description[0]['MobileNo'] : ""
                        const parts = mob1 != "" && mob1 != null && mob1.split("-");
                        const regMob1Code = parts[0]
                        const regMob1No = parts[1]
                        // Registration Alternate Mobile No
                        var mob2 = data.data.description[0]['AlternateMobileNo'] != null ? data.data.description[0]['AlternateMobileNo'] : ""
                        const parts2 = mob2 != "" && mob2 != null && mob2.split("-")
                        const regMob2Code = parts2[0]
                        const regMob2No = parts2[1]
                        // // Registration Telephone No
                        var tel = data.data.description[0]['Telephone'] != null ? data.data.description[0]['Telephone'] : ""
                        var parts3 = tel != "" && tel != null && tel.split("-")
                        const regTelCode = parts3[0]
                        const regTelNo = parts3[1]
                        // // Registration Telephone No 
                        var tel1 = data.data.description[0]['Telephone1'] != null ? data.data.description[0]['Telephone1'] : ""
                        const parts4 = tel1 != "" && tel1 != null && tel1.split("-")
                        const tel1Code = parts4[0]
                        const tel1No = parts4[1]
                        // Invoice Mobile No
                        var invMob = data.data.description[0]['InvMobileNo'] != null ? data.data.description[0]['InvMobileNo'] : ""
                        const parts6 = invMob != "" && invMob != null && invMob.split("-")
                        const invMobCode = parts6[0]
                        const invMobNo = parts6[1]
                        // // Invoice Alternate Mob No
                        var invMob2 = data.data.description[0]['InvAlternateMobileNo'] != null ? data.data.description[0]['InvAlternateMobileNo'] : ""
                        const parts7 = invMob2 != "" && invMob2 != null && invMob2.split("-")
                        const invMob2Code = parts7[0]
                        const invMob2No = parts7[1]
                        // // Invoice TelePhone No 
                        var invTel = data.data.description[0]['InvTelephone'] != null ? data.data.description[0]['InvTelephone'] : ""
                        const parts8 = invTel != "" && invTel != null && invTel.split("-")
                        const invTelCode = parts8[0]
                        const invTelNo = parts8[1]
                        // // Invoice TelePhone 1 No 
                        var invTel1 = data.data.description[0]['InvTelephone1'] != null ? data.data.description[0]['InvTelephone1'] : ""
                        const parts9 = invTel1 != "" && invTel1 != null && invTel1.split("-")
                        const invTel1Code = parts9[0]
                        const invTel1No = parts9[1]
                        // Preparing Agent Country List
                        var countryIdList = data.data.agentCountries != null ? data.data.agentCountries : []
                        var dataList = []
                        for (let i = 0; i < countryIdList.length; i++) {
                            var data2 = interNationalCountryList.filter((item) => item.Code == countryIdList[i]['CountryID'])
                            console.log("data2 : ", data2)
                            if (data2.length > 0) {
                                dataList.push(data2[0])
                            }
                        }
                        var finalCountryList = []
                        console.log("xxxxxxxxxxxxxxxxxxxxxx", interNationalCountryList)
                        console.log("1111111111111111111111", countryIdList)
                        console.log("dataList : ", dataList)

                        dataList != null && dataList.length > 0 && dataList.map((element) => {
                            var mapdata = {
                                "label": element.Description != null ? element.Description : '',
                                "value": element.Code != null ? element.Code : ''
                            }
                            finalCountryList.push(mapdata)
                        })
                        console.log("111111111111111111111111111111111", primaryChapterList)
                        // // Preparing Primary Chapters
                        var chapters = data.data.PrimaryChapterList != null ? data.data.PrimaryChapterList : []
                        console.log("222222222222222222222222222222222222", chapters)
                        chapters.length > 0 && chapters.map((data) => {
                            primaryChapterList.length > 0 && primaryChapterList.map((item) => {
                                if (data.ChapterID == item.ChapterID) {
                                    item.IsSelect = true
                                    item.ReadOnly = false
                                    item.nationalArea = data.DomesticArea
                                    item.internationalArea = data.InternationalArea
                                }
                            })
                        })
                        var areaPricingLlist = data.data.areaPricingList != null ? data.data.areaPricingList : []
                        areaPricingLlist.length > 0 && areaPricingLlist.map((data) => {
                            stallTypeList.length > 0 && stallTypeList.map((item) => {
                                if (data.StallType == item.Stalltype) {
                                    item.DomesticPrice = data.DomesticPrice
                                    item.InternationalPrice = data.InternationalPrice
                                }
                            })
                        })
                        this.setState({
                            isSelfPaid: data.data.description[0]['IsSelfPaid'] != null ? data.data.description[0]['IsSelfPaid'] : 1,
                            preAgentCode: data.data.description[0]['IsSelfPaid'] != null && data.data.description[0]['IsSelfPaid'] == 1 ? 'AG1' : 'AG2',
                            agentCode: code2,
                            prefixPersonInchFirst: data.data.description[0]['Title_PersonIncharge'] != null ? data.data.description[0]['Title_PersonIncharge'] : '',
                            personInchFirst: data.data.description[0]['PersonIncharge'] != null ? data.data.description[0]['PersonIncharge'] : '',
                            personInchLast: data.data.description[0]['LastNamePersonIncharge'] != null ? data.data.description[0]['LastNamePersonIncharge'] : '',
                            designation: data.data.description[0]['Designation'] != null ? data.data.description[0]['Designation'] : '',
                            companyName: data.data.description[0]['CompanyName'] != null ? data.data.description[0]['CompanyName'] : '',
                            add1: data.data.description[0]['Address1'] != null ? data.data.description[0]['Address1'] : '',
                            add2: data.data.description[0]['Address2'] != null ? data.data.description[0]['Address2'] : '',
                            pin: data.data.description[0]['Pin'] != null ? data.data.description[0]['Pin'] : '',
                            city: data.data.description[0]['City'] != null ? data.data.description[0]['City'] : '',
                            state: data.data.description[0]['State'] != null ? data.data.description[0]['State'] : '',
                            country: data.data.description[0]['CountryName'] != null ? data.data.description[0]['CountryName'] : '',
                            countryId: data.data.description[0]['CountryID'] != null ? data.data.description[0]['CountryID'] : '',
                            preMobNo: regMob1Code,
                            mobNo: regMob1No,
                            altPreMobNo: regMob2Code,
                            altMobNo: regMob2No,
                            preTelephone: regTelCode,
                            telephone: regTelNo,
                            altPreTelephone: tel1Code,
                            altTelephone: tel1No,
                            primaryEmail: data.data.description[0]['PrimaryEmail'] != null ? data.data.description[0]['PrimaryEmail'] : '',
                            secondaryEmail: data.data.description[0]['SecondaryEmail'] != null ? data.data.description[0]['SecondaryEmail'] : '',
                            website: data.data.description[0]['Website'] != null ? data.data.description[0]['Website'] : '',
                            isSameAsCorrespondence: data.data.description[0]['SameAsCorrespondence'] != null ? data.data.description[0]['SameAsCorrespondence'] : false,
                            billPrefixPersonInchFirst: data.data.description[0]['Title_InvPersonIncharge'] != null ? data.data.description[0]['Title_InvPersonIncharge'] : false,
                            billPersonInchFirst: data.data.description[0]['InvPersonIncharge'] != null ? data.data.description[0]['InvPersonIncharge'] : false,
                            billPersonInchLast: data.data.description[0]['InvLastNamePersonIncharge'] != null ? data.data.description[0]['InvLastNamePersonIncharge'] : false,
                            billDesignation: data.data.description[0]['InvDesignation'] != null ? data.data.description[0]['InvDesignation'] : false,
                            billCompanyName: data.data.description[0]['InvCompanyName'] != null ? data.data.description[0]['InvCompanyName'] : false,
                            billAdd1: data.data.description[0]['InvAddress1'] != null ? data.data.description[0]['InvAddress1'] : false,
                            billAdd2: data.data.description[0]['InvAddress2'] != null ? data.data.description[0]['InvAddress2'] : false,
                            billPin: data.data.description[0]['InvPin'] != null ? data.data.description[0]['InvPin'] : false,
                            billCity: data.data.description[0]['InvCity'] != null ? data.data.description[0]['InvCity'] : false,
                            billState: data.data.description[0]['InvState'] != null ? data.data.description[0]['InvState'] : false,
                            billCountryId: data.data.description[0]['InvCountryID'] != null ? data.data.description[0]['InvCountryID'] : false,
                            billCountry: data.data.description[0]['InVCountryName'] != null ? data.data.description[0]['InVCountryName'] : false,
                            currencyID: data.data.description[0]['currencyID'] != null ? data.data.description[0]['currencyID'] : false,
                            billPreMobNo: invMobCode,
                            billMobNo: invMobNo,
                            billAltPreMobNo: invMob2Code,
                            billAltMobNo: invMob2No,
                            billPreTelephone: invTelCode,
                            billTelephone: invTelNo,
                            billAltPreTelephone: invTel1Code,
                            billAltTelephone: invTel1No,
                            billPrimaryEmail: data.data.description[0]['InvPrimaryEmail'] != null ? data.data.description[0]['InvPrimaryEmail'] : "",
                            billSecondaryEmail: data.data.description[0]['InvSecondaryEmail'] != null ? data.data.description[0]['InvSecondaryEmail'] : "",
                            billWebsite: data.data.description[0]['InvWebsite'] != null ? data.data.description[0]['InvWebsite'] : "",
                            accName: data.data.description[0]['AccountName'] != null ? data.data.description[0]['AccountName'] : "",
                            accNo: data.data.description[0]['AccountNo'] != null ? data.data.description[0]['AccountNo'] : "",
                            accType: data.data.description[0]['AccountType'] != null ? data.data.description[0]['AccountType'] : "",
                            bankName: data.data.description[0]['BankName'] != null ? data.data.description[0]['BankName'] : "",
                            branchName: data.data.description[0]['BranchName'] != null ? data.data.description[0]['BranchName'] : "",
                            IFSC: data.data.description[0]['SwiftCode_IFSCCode'] != null ? data.data.description[0]['SwiftCode_IFSCCode'] : "",
                            totalNationalArea: data.data.description[0]['DomesticArea'] != null ? data.data.description[0]['DomesticArea'] : "",
                            totalInternationalArea: data.data.description[0]['InternationalArea'] != null ? data.data.description[0]['InternationalArea'] : "",
                            installment1: data.data.description[0]['Installment_1_percent'] != null ? data.data.description[0]['Installment_1_percent'] : "",
                            installment2: data.data.description[0]['Installment_2_percent'] != null ? data.data.description[0]['Installment_2_percent'] : "",
                            areaAllocationId: data.data.description[0]['AgentAreaAllocationID'] != null ? data.data.description[0]['AgentAreaAllocationID'] : "",
                            is1stAgentPICreated: data.data.description[0]['is1stAgentPICreated'] != null ? data.data.description[0]['is1stAgentPICreated'] : 0,
                            is2ndAgentPICreated: data.data.description[0]['is2ndAgentPICreated'] != null ? data.data.description[0]['is2ndAgentPICreated'] : 0,
                            isTaxInvoiceCreated: data.data.description[0]['isTaxInvoiceCreated'] != null ? data.data.description[0]['isTaxInvoiceCreated'] : 0,
                            isOpenSidePICreated: data.data.description[0]['isOpenSidePICreated'] != null ? data.data.description[0]['isOpenSidePICreated'] : 0,
                            isManualPICreated: data.data.description[0]['isManualPICreated'] != null ? data.data.description[0]['isManualPICreated'] : 0,
                            selectedCountry: finalCountryList.length > 0 && finalCountryList,
                            primaryChapterList: primaryChapterList,
                            // isLoading: false
                        })
                        setTimeout(() => {
                            this.setState({ isLoading: false })
                            console.log(this.state.installment1, 'installment1 settt', data.data.description[0]['Installment1'])
                        }, 500);
                    } else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                }).catch((err) => {
                    console.log(err)
                })
        }, 1500);
    }

    // Get Fill Lists
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        const { } = this.state
        const is_add = this.props.location.state != null && this.props.location.state.is_add != null ? this.props.location.state.is_add : false
        this.setState({ isLoading: true })
        if (actionID === primaryChapterListActionId) {
            return new Promise((resolve, reject) => {
                this.props.fill_action(actionID, exhibitionID, moduleID, keyValue, value)
                    .then((data) => {
                        var i = 0
                        console.log("1111111111", data.data['level2'])
                        console.log("primaryChapterListActionId 121212", data.data['primaryChapter'])
                        for (var i = 0; i < data.data['level2'].length; i++) {
                            for (var j = 0; j < data.data['level2'].length - 1 - i; j++) {
                                var seq1 = data.data['level2'][j].seqno
                                var seq2 = data.data['level2'][j + 1].seqno
                                if (seq1 != null && seq2 != null) {
                                    if ((parseFloat(seq1) > parseFloat(seq2))) {
                                        var temp = data.data['level2'][j]
                                        data.data['level2'][j] = data.data['level2'][j + 1]
                                        data.data['level2'][j + 1] = temp;
                                        // console.log(i, j, arr[j])
                                    }
                                }
                            }
                        }
                        var arr = data.data['level2'].length > 0 ? data.data['level2'] : []
                        for (var i = 0; i < arr.length; i++) {
                            for (var j = 0; j < arr[i].ChapterName.length; j++) {
                                if (/^[a-zA-Z]$/.test(arr[i].ChapterName[j])) {
                                    var str = arr[i].ChapterName.slice(j)
                                    arr[i].ChapterName = str
                                    // console.log("primaryChapterListActionId 121212", i, finalName)
                                    break
                                }
                            }
                        }
                        arr.length > 0 && arr.map((item) => {
                            item.nationalArea = ''
                            item.nationalAreaErr = ''
                            item.internationalArea = ''
                            item.internationalAreaErr = ''
                            item.ReadOnly = true
                        });
                        this.setState({
                            primaryChapterList: arr ? arr : [],
                        })
                        console.log("111111111111111111111111111111", arr, data.data['level2'])
                        if (is_add) {
                            this.setState({ isLoading: false })
                        }
                        resolve(true)
                    })
            })

        }
        else {
            this.props
                .fill_action(actionID, exhibitionID, moduleID, keyValue, value)
                .then((data) => {
                    if (data.error != 1) {
                        // country list
                        if (actionID === countryActionId) {
                            console.log("country data", data.data)
                            var internationalCountryData = data.data.length > 0 && data.data.filter((item) => item.Code != "91")
                            this.setState({
                                countryList: data.data,
                                tempCountryList: data.data,
                                billTempCountryList: data.data,
                                agentTempCountryList: data.data,
                                interNationalCountryList: internationalCountryData,
                            })
                            if (is_add) {
                                this.setState({ isLoading: false })
                            }
                        }
                        // state list
                        if (actionID === stateActionId) {
                            console.log("stateList data", data.data)
                            this.setState({
                                stateList: data.data,
                                tempStateList: data.data,
                                billTempStateList: data.data,
                            })
                            if (is_add) {
                                this.setState({ isLoading: false })
                            }
                        }
                        // city list
                        if (actionID === cityActionId) {
                            console.log("city data", data.data)
                            this.setState({
                                cityList: data.data,
                                tempCityList: data.data,
                                billTempCityList: data.data,
                            })
                            if (is_add) {
                                this.setState({ isLoading: false })
                            }
                        }
                        // pincode
                        if (actionID === pincodeActionId) {
                            console.log("pincode data", data.data)
                            this.setState({
                                pincodeList: data.data,
                                tempPincodeList: data.data,
                                billTempPincodeList: data.data,
                            })
                            if (is_add) {
                                this.setState({ isLoading: false })
                            }
                        }
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }

    // Search Country
    onCountrySearch(e) {
        const { countryList, tempCountryList } = this.state
        this.setState({ country: '' })
        if (e.target.value != '') {
            console.log("tempCountryList ++++----------->", tempCountryList)

            var searchCoutryList = tempCountryList.filter(data => {
                return (
                    data
                        .Description
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            console.log("searchCoutryList ++++----------->", searchCoutryList)

            this.setState({
                country: e.target.value,
                countryErr: '',
                tempCountryList: searchCoutryList,
                isDisplayCountryList: true
            })
        }
        else {
            this.setState({
                country: e.target.value,
                tempCountryList: countryList,
                isDisplayCountryList: false
            })
        }
    }

    // render Country
    renderCountryList() {
        const { tempCountryList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempCountryList.length > 0 && tempCountryList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({
                                country: data.Description, countryId: data.Code, isDisplayCountryList: false, preMobNo: `+${data.CountryCode}`,
                                altPreMobNo: `+${data.CountryCode}`
                            })}
                        >{data.Description}</li>
                    ))
                }
            </ul>
        )
    }

    // search state
    onStateSearch(e) {
        const { tempStateList, stateList } = this.state
        if (e.target.value != '') {
            var searchStateList = tempStateList.filter(data => {
                return (
                    data
                        .Description
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            this.setState({
                state: e.target.value,
                stateErr: '',
                tempStateList: searchStateList,
                isDisplayStateList: true
            })
        } else {
            this.setState({
                state: e.target.value,
                tempStateList: stateList,
                isDisplayStateList: false
            })
        }
    }

    // state list
    // callStateList(value) {

    //     const { state, regCountryId } = this.state
    //     this.setState({ state: value, isDisplayStateList: false })
    //     this.getFillList(stateActionId, '', '', '', state, '', '')
    // }

    // render state
    renderStateList() {
        const { tempStateList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempStateList.length > 0 && tempStateList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({ state: data.Description, isDisplayStateList: false })}
                        // onClick={e => this.callStateList(data.Description)}
                        >{data.Description}</li>

                    ))
                }
            </ul>
        )
    }

    // search city
    onCitySearch(e) {
        const { cityList, tempCityList } = this.state
        if (e.target.value != '') {
            var searchCityList = tempCityList.filter(data => {
                return (
                    data
                        .Description
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            this.setState({
                city: e.target.value,
                cityErr: '',
                tempCityList: searchCityList,
                isDisplayCityList: true
            })
        } else {
            this.setState({
                city: e.target.value,
                tempCityList: cityList,
                isDisplayCityList: false
            })
        }
    }

    // render city
    renderCityList() {
        const { tempCityList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempCityList.length > 0 && tempCityList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({ city: data.Description, isDisplayCityList: false })}
                        >{data.Description}</li>
                    ))
                }
            </ul>
        )
    }

    //Pincode Search
    onPincodeSearch(e) {
        const { pincodeList, tempPincodeList } = this.state
        if (e.target.value != '') {
            var searchPincodeList = tempPincodeList.filter(data => {
                return (
                    data
                        .Pincode
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            this.setState({
                pin: e.target.value,
                pinErr: '',
                tempPincodeList: searchPincodeList,
                isDisplayPincodeList: true
            })
        } else {
            this.setState({
                pin: e.target.value,
                tempPincodeList: pincodeList,
                isDisplayPincodeList: false
            })
        }
    }

    // render pincode
    renderPincodeList() {
        const { tempPincodeList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempPincodeList.length > 0 && tempPincodeList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({
                                pin: data.Pincode,
                                isDisplayPincodeList: false
                            })}
                        >{data.Pincode}</li>
                    ))
                }
            </ul>
        )
    }

    // search Bill country
    onBillCountrySearch(e) {
        const { countryList, billTempCountryList } = this.state
        if (e.target.value != '') {
            var searchBillCountryList = billTempCountryList.filter(data => {
                return (
                    data
                        .Description
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            this.setState({
                billCountry: e.target.value,
                billCountryErr: '',
                billTempCountryList: searchBillCountryList,
                isDisplayBillCountryList: true
            })
        } else {
            this.setState({
                billCountry: e.target.value,
                billTempCountryList: countryList,
                isDisplayBillCountryList: false
            })
        }
    }

    // render Bill country 
    renderBillCountryList() {
        const { billTempCountryList } = this.state
        return (
            <ul className="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    billTempCountryList.length > 0 && billTempCountryList.map(data => (
                        <li className="list-group-item" key={Math.random()}
                            onClick={e => this.setState({
                                billCountry: data.Description, billCountryId: data.Code, isDisplayBillCountryList: false,
                                billPreMobNo: `+${data.CountryCode}`, billAltPreMobNo: `+${data.CountryCode}`
                            })}
                        >{data.Description}</li>
                    ))
                }
            </ul>
        )
    }

    // search state
    onBillStateSearch(e) {
        const { stateList, billTempStateList } = this.state
        console.log("onStateSearch")
        if (e.target.value != '') {
            var searchBillStateList = billTempStateList.filter(data => {
                return (
                    data
                        .Description
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            this.setState({
                billState: e.target.value,
                billStateErr: '',
                billTempStateList: searchBillStateList,
                isDisplayBillStateList: true
            })
        } else {
            this.setState({
                billState: e.target.value,
                billTempStateList: stateList,
                isDisplayBillStateList: false
            })
        }
    }

    // state list
    // callBillStateList(value) {
    //     const { billState, billTempStateList, billCountryId } = this.state
    //     this.setState({ billState: value, isDisplayBillStateList: false })
    //     this.getFillList(stateActionId, '', '', '', billState, '', '')
    // }


    // render Bill state
    renderBillStateList() {
        const { billTempStateList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    billTempStateList.length > 0 && billTempStateList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({ billState: data.Description, isDisplayBillStateList: false })}
                        // onClick={e => this.callBillStateList(data.Description)}
                        >{data.Description}</li>
                    ))
                }
            </ul>
        )
    }

    // Bill Pincode Search
    onBillPincodeSearch(e) {
        const { pincodeList, billTempPincodeList, billCity } = this.state
        if (e.target.value != '') {
            var searchBillPincodeList = billTempPincodeList.filter(x => {
                return (
                    x
                        .Pincode
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            this.setState({
                billPin: e.target.value,
                billPinErr: '',
                billTempPincodeList: searchBillPincodeList,
                isDisplayBillPincodeList: true
            })
        } else {
            this.setState({
                billPin: e.target.value,
                billTempPincodeList: pincodeList,
                isDisplayBillPincodeList: false
            })
        }
    }

    // render Bill pincode
    renderBillPincodeList() {
        const { billTempPincodeList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    billTempPincodeList.length > 0 && billTempPincodeList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({
                                billPin: data.Pincode,
                                isDisplayBillPincodeList: false
                            })}
                        >{data.Pincode}</li>
                    ))
                }
            </ul>
        )
    }

    // search Bill city
    onBillCitySearch(e) {
        const { cityList, billTempCityList } = this.state
        if (e.target.value != '') {
            var searchBillCityList = billTempCityList.filter(data => {
                return (
                    data
                        .Description
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            this.setState({
                billCity: e.target.value,
                billCityErr: '',
                billTempCityList: searchBillCityList,
                isDisplayBillCityList: true
            })
        } else {
            this.setState({
                billCity: e.target.value,
                billTempCityList: cityList,
                isDisplayBillCityList: false
            })
        }
    }

    // render Bill city
    renderBillCityList() {
        const { billTempCityList } = this.state
        return (
            <ul className="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    billTempCityList.length > 0 && billTempCityList.map(data => (
                        <li className="list-group-item" key={Math.random()}
                            onClick={e => this.setState({ billCity: data.Description, isDisplayBillCityList: false })}
                        >{data.Description}</li>
                    ))
                }
            </ul>
        )
    }

    // search Bill country
    onAgentCountrySearch(e) {
        const { countryList, agentTempCountryList } = this.state
        if (e.target.value != '') {
            var searchAgentCountryList = agentTempCountryList.filter(data => {
                return (
                    data
                        .Description
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            this.setState({
                agentCountries: e.target.value,
                agentTempCountryList: searchAgentCountryList,
                isDisplayAgentCountryList: true
            })
        } else {
            this.setState({
                agentCountries: e.target.value,
                agentTempCountryList: countryList,
                isDisplayAgentCountryList: false
            })
        }
    }

    // render Bill country 
    renderAgentCountryList() {
        const { agentTempCountryList } = this.state
        return (
            <ul className="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    agentTempCountryList.length > 0 && agentTempCountryList.map(data => (
                        <li className="list-group-item" key={Math.random()}
                            onClick={e => this.setState({ agentCountries: data.Description, agentCountryId: data.Code, isDisplayAgentCountryList: false })}
                        >{data.Description}</li>
                    ))
                }
            </ul>
        )
    }

    sameAsCorrespondanceValidate() {
        const { otherPersonInchFirst, personInchFirst, prefixPersonInchFirst, personInchLast, designation, companyName, add1, add2, pin,
            city, state, country, countryId, preMobNo, mobNo, altMobNo, altPreMobNo, telephone, altTelephone, preTelephone, altPreTelephone,
            billPersonInchFirst, billPersonInchLast, billDesignation, billCompanyName, billAdd1, billCity, billCountry, billMobNo,
            billPreMobNo, billPrimaryEmail, billPinprimaryEmail, secondaryEmail, website, isSameAsCorrespondence, primaryEmail, billPin,
            nationalityId } = this.state
        if (personInchFirst != '') {
            this.setState({ billPersonInchFirstErr: '' })
        }
        if (personInchLast != '') {
            this.setState({ billPersonInchLastErr: '' })
        }
        if (designation != '') {
            this.setState({ billDesignationErr: '' })
        }
        if (companyName != '') {
            this.setState({ billCompanyNameErr: '' })
        }
        if (add1 != '') {
            this.setState({ billAdd1Err: '' })
        }
        if (city != '') {
            this.setState({ billCityErr: '' })
        }
        if (country != '') {
            this.setState({ billCountryErr: '' })
        }
        if (preMobNo != '') {
            this.setState({ billPreMobNoErr: '' })
        }
        if (mobNo != '') {
            this.setState({ billMobNoErr: '' })
        }
        if (primaryEmail != '') {
            this.setState({ billPrimaryEmailErr: '' })
        }
        if (pin != '') {
            this.setState({ billPinErr: '' })
        }
    }
    //same as corresponding details
    sameAsCorrespondence() {
        const { otherPersonInchFirst, personInchFirst, prefixPersonInchFirst, personInchLast, designation, companyName, add1, add2, pin,
            city, state, country, countryId, preMobNo, mobNo, altMobNo, altPreMobNo, telephone, altTelephone, preTelephone, altPreTelephone,
            billPersonInchFirst, billPersonInchLast, billDesignation, billCompanyName, billAdd1, billCity, billCountry, billMobNo,
            billPreMobNo, billPrimaryEmail, billPinprimaryEmail, secondaryEmail, website, isSameAsCorrespondence, primaryEmail, billPin,
            nationalityId } = this.state
        this.setState({ isSameAsCorrespondence: !isSameAsCorrespondence })
        // if (isSameAsCorrespondence == true) {
        this.setState({
            billOtherPersonInchFirst: otherPersonInchFirst,
            billPersonInchFirst: personInchFirst,
            billPrefixPersonInchFirst: prefixPersonInchFirst,
            billPersonInchLast: personInchLast,
            billDesignation: designation,
            billCompanyName: companyName,
            billAdd1: add1,
            billAdd2: add2,
            billPin: pin,
            billCity: city,
            billState: state,
            billCountry: country,
            billCountryId: countryId,
            billPreMobNo: preMobNo,
            billMobNo: mobNo,
            billAltMobNo: altMobNo,
            billAltPreMobNo: altPreMobNo,
            billTelephone: telephone,
            billAltTelephone: altTelephone,
            billPreTelephone: preTelephone,
            billAltPreTelephone: altPreTelephone,
            billPrimaryEmail: primaryEmail,
            billSecondaryEmail: secondaryEmail,
            billWebsite: website,
            billPreMobNo: preMobNo,
            billAltPreMobNo: altPreMobNo,
            billCountryId: countryId
        })

        this.sameAsCorrespondanceValidate()
    }

    maxLengthCheck = (object, maxLength) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    preventMinus = (e) => {
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    }

    // On Cancel Click
    onCancel(e) {
        e.preventDefault()
        this.props.navigate('/agentlist')
    }

    OnChapterSelect(e) {
        const { primaryChapterList } = this.state
        var id = e.target.value
        var check = e.target.checked
        primaryChapterList.length > 0 && primaryChapterList.map((data) => {
            if (data.ChapterID == id) {
                if (check) {
                    data.IsSelect = true
                    data.ReadOnly = false
                } else {
                    data.ReadOnly = true
                    data.IsSelect = false
                    data.nationalArea = ""
                    data.internationalArea = ""
                    data.nationalAreaErr = ""
                    data.internationalAreaErr = ""
                }
            }
        })
        console.log("OnPrimary Chapter Select", primaryChapterList)
        this.setState({ primaryChapterList: primaryChapterList })
    }

    //On chapter Wise National Area Change
    onNationalAreaChange(e, id) {
        const { primaryChapterList } = this.state
        var value = e.target.value
        primaryChapterList.length > 0 && primaryChapterList.map((data) => {
            if (data.ChapterID == id) {
                data.nationalArea = value >= 0 && value
                data.nationalAreaErr = ""

            }
        })
        console.log("national area updates ----->", primaryChapterList)
        this.setState({ primaryChapterList: primaryChapterList })
    }

    //On chapter Wise interNational Area Change
    onInternationalAreaChange(e, id) {
        const { primaryChapterList } = this.state
        var value = e.target.value
        primaryChapterList.length > 0 && primaryChapterList.map((data) => {
            if (data.ChapterID == id) {
                data.internationalArea = value >= 0 && value
                data.internationalAreaErr = ""
            }
        })
        console.log("InterNational area updates ----->", primaryChapterList)
        this.setState({ primaryChapterList: primaryChapterList })
    }

    // On Multiple Country Select
    handleCountrySelect(e) {
        console.log("111111111111111111111", e)
        this.setState({ selectedCountry: e })
    }

    // Verify Email Address
    verifyAgentEmail(e, key) {
        const { preAgentCode } = this.state
        if (key == 1) {
            var value = e.target.value
            if (value != "") {
                var emailError = emailValidator(value)
                if (emailError != '') {
                    this.setState({ primaryEmailErr: emailError })
                    return
                } else {
                    this.setState({ isLoading: true })
                    this.props.verifyAgentEmail_action(value, null)
                        .then((data) => {
                            if (data.error != 1) {
                                this.setState({ isLoading: false })
                                console.log("verifyEmail--->", data.data['ErrMsg'])
                                if (data.data['ErrMsg'] != "" && data.data['ErrMsg'] != null) {
                                    this.setState({ primaryEmailErr: data.data['ErrMsg'] })
                                } else {
                                    this.setState({ primaryEmailErr: "" })
                                }
                            } else {
                                this.setState({ isLoading: false })
                            }

                        })
                        .catch((err) => {
                            console.log(err)
                        })
                }
            } else {
                this.setState({ primaryEmailErr: "Please enter Email" })
            }
        } else if (key == 2) {
            value = e.target.value
            var finalAgentCode = `${preAgentCode}${value}`
            this.setState({ isLoading: true })
            this.props.verifyAgentEmail_action(null, finalAgentCode)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({ isLoading: false })
                        console.log("verifyEmail--->", data.data['ErrMsg'])
                        if (data.data['ErrMsg'] != "" && data.data['ErrMsg'] != null) {
                            this.setState({ agentCodeErr: data.data['ErrMsg'] })
                        } else {
                            this.setState({ agentCodeErr: "" })
                        }
                    } else {
                        this.setState({ isLoading: false })
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    getWirteAccess() {
        const { loginAccesslist } = this.state
        const moduleAccess = loginAccesslist.find((item) => item.ModuleID == '8');
        if (moduleAccess) {
            return moduleAccess.WriteAccess;
        } else {
            return false
        }
        return false;
    }

    getUpdateAccess() {
        const { loginAccesslist } = this.state
        const moduleAccess = loginAccesslist.find((item) => item.ModuleID == '8');
        if (moduleAccess) {
            return moduleAccess.UpdateAccess;
        } else {
            return false
        }
        return false;
    }

    //On chapter Wise National Area Change
    onDomesticPriceChange(e, id) {
        const { stallTypeList } = this.state
        var value = e.target.value
        stallTypeList.length > 0 && stallTypeList.map((data) => {
            if (data.Stalltype == id) {
                data.DomesticPrice = value >= 0 && value
                data.DomesticPriceErr = null
            }
        })
        console.log("national area updates ----->", stallTypeList)
        this.setState({ stallTypeList: stallTypeList })
    }

    //On chapter Wise interNational Area Change
    onInternationalPriceChange(e, id) {
        const { stallTypeList } = this.state
        var value = e.target.value
        stallTypeList.length > 0 && stallTypeList.map((data) => {
            if (data.Stalltype == id) {
                data.InternationalPrice = value >= 0 && value
                data.InternationalPriceErr = null
            }
        })
        console.log("InterNational area updates ----->", stallTypeList)
        this.setState({ stallTypeList: stallTypeList })
    }

    PIModalOpen(PI) {
        this.setState({ PIbutton: PI, }, () => {
            this.setState({ isOpen: true })
        })
    }

    onGenerate() {
        const { PIbutton, AccountId, conversionRate, nationalityId, currencyID } = this.state
        console.log(PIbutton)
        if (PIbutton == 'Generate 1st PI') {
            console.log(AccountId, conversionRate, nationalityId)
            this.setState({ isLoading: true })
            this.props.insertAgentPI1_action(AccountId, parseFloat(conversionRate), nationalityId == 'mix' ? 'M' : (nationalityId == 'international' ? 'I' : 'D'), currencyID)
                .then((data) => {
                    this.setState({ isLoading: false, conversionRate: '', PIbutton: '', isOpen: false, })
                    if (data.error != 1) {
                        toastSuccess("updated successfully")
                        this.getDetails()
                    } else {
                        toastError("something went wrong...")
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        else if (PIbutton == 'Generate 2nd PI') {
            console.log(AccountId, conversionRate, nationalityId)
            this.setState({ isLoading: true })
            this.props.insertAgentPI2_action(AccountId, null, (nationalityId == 'mix' ? 'M' : (nationalityId == 'international' ? 'I' : 'D')), currencyID)
                .then((data) => {
                    this.setState({ isLoading: false, conversionRate: '', PIbutton: '', isOpen: false, })
                    if (data.error != 1) {
                        toastSuccess("updated successfully")
                        this.getDetails()
                    } else {
                        toastError("something went wrong...")
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        else if (PIbutton == 'Generate Tax Invoice') {
            console.log(AccountId, null, (nationalityId == 'mix' ? 'M' : (nationalityId == 'international' ? 'I' : 'D')), currencyID, PIbutton)
            this.setState({ isLoading: true })
            this.props.getAgentMailDetails_action(AccountId, null, (nationalityId == 'mix' ? 'M' : (nationalityId == 'international' ? 'I' : 'D')), currencyID, PIbutton)
                .then((data) => {
                    this.setState({ isLoading: false, conversionRate: '', PIbutton: '', isOpen: false, })
                    if (data.error != 1) {
                        toastSuccess("updated successfully")
                        this.getDetails()
                    } else {
                        toastError("something went wrong...")
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        else if (PIbutton == 'Generate Manual Form PI') {
            console.log(AccountId, null, (nationalityId == 'mix' ? 'M' : (nationalityId == 'international' ? 'I' : 'D')), currencyID, PIbutton)
            this.setState({ isLoading: true })
            this.props.getAgentMailDetails_action(AccountId, parseFloat(conversionRate), (nationalityId == 'mix' ? 'M' : (nationalityId == 'international' ? 'I' : 'D')), currencyID, PIbutton)
                .then((data) => {
                    this.setState({ isLoading: false, conversionRate: '', PIbutton: '', isOpen: false, })
                    if (data.error != 1) {
                        toastSuccess("updated successfully")
                        this.getDetails()
                    } else {
                        toastError("something went wrong...")
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        else if (PIbutton == 'Generate Open Side PI') {
            console.log(AccountId, conversionRate, nationalityId)
            this.setState({ isLoading: true })
            this.props.insertAgentOpenSidePI_action(AccountId, (nationalityId == 'mix' ? 'M' : (nationalityId == 'international' ? 'I' : 'D')))
                .then((data) => {
                    this.setState({ isLoading: false, conversionRate: '', PIbutton: '', isOpen: false, })
                    if (data.error != 1) {
                        toastSuccess("updated successfully")
                        this.getDetails()
                    } else {
                        toastError("something went wrong...")
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    render() {
        const { isLoading, secondaryEmail, installment1, installment2, website, personInchLast, altPreMobNo, is_navFromProfile, primaryEmail, primaryEmailErr, telephone, altTelephone, isOpen, is_enroll,
            preTelephone, altPreTelephone, preTelephoneErr, telephoneErr, altMobNo, mobNo, preMobNoErr, preMobNo, mobNoErr, designation,
            isDisplayCountryList, pin, pinErr, country, countryErr, state, isDisplayStateList, stateErr, city, cityErr, isDisplayCityList,
            isDisplayPincodeList, add1, add1Err, add2, designationErr, companyName, companyNameErr, personInchFirstErr, isOtherPersonInchFirst,
            prefixPersonInchFirst, otherPersonInchFirst, personInchFirst, accName, accNo, is_add, is_update, personInchLastErr,
            isbillOtherPersonInchFirst, isDisplayBillCountryList, isDisplayBillCityList, isDisplayAgentCountryList, isDisplayBillPincodeList,
            billOtherPersonInchFirst, billPersonInchFirst, billPrefixPersonInchFirst, billPersonInchLast, billDesignation, billCompanyName,
            billAdd1, billAdd2, billPin, billCity, billState, billCountry, billPreMobNo, billMobNo, billAltMobNo, isDisplayBillStateList,
            billAltPreMobNo, billTelephone, billAltTelephone, billPreTelephone, billAltPreTelephone, billPrimaryEmail, billSecondaryEmail,
            billWebsite, billPrimaryEmailErr, billPreTelephoneErr, billTelephoneErr, billPreMobNoErr, altTelephoneErr, altPreTelephoneErr,
            billMobNoErr, billCountryErr, billStateErr, billCityErr, billPinErr, billAdd1Err, billCompanyNameErr, billDesignationErr,
            billPersonInchLastErr, billPersonInchFirstErr, accType, accTypeOthers, bankName, branchName, IFSC, agentCountries,
            totalInternationalArea, totalInternationalAreaErr, totalNationalArea, totalNationalAreaErr, accNameErr, accNoErr, IFSCErr,
            isSameAsCorrespondence, is_view, accTypeList, nationalAreaDesc1, nationalAreaDesc2, nationalAreaDesc3, nationalAreaDesc4,
            internationalAreaDesc1, internationalAreaDesc2, internationalAreaDesc3, internationalAreaDesc4, nationalityId,
            primaryChapterList, isSelfPaid, agentCountryId, countryList, interNationalCountryList, countryId, billCountryId, selectedCountry,
            preAgentCode, agentCode, agentCodeErr } = this.state

        // console.log("AAAAAAAAAAAAAAAAAAA", selectedCountry)

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                {
                    sessionStorage.getItem('IsAgentDirectLogin') ? sessionStorage.getItem('IsAgentDirectLogin') == 'false' ?
                        JSON.parse(sessionStorage.getItem('indirectAgentloginDataSS')).RoleID == 4 && <AgentHeader /> :
                        JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 4 && <AgentHeader /> :
                        <HeaderWithLogo />
                }
                {
                    sessionStorage.getItem('IsAgentDirectLogin') ? sessionStorage.getItem('IsAgentDirectLogin') == 'false' ?
                        JSON.parse(sessionStorage.getItem('indirectAgentloginDataSS')).RoleID == 4 && <AgentDrawer /> :
                        JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 4 && <AgentDrawer /> :
                        <ExhibitionDrawer />
                }

                {/* <HeaderWithLogo /> */}
                {/* <ExhibitionDrawer /> */}

                <div className="page">
                    <div className="page-main">
                        {/*app-content open*/}
                        <div className={sessionStorage.getItem('IsAgentDirectLogin') ? sessionStorage.getItem('IsAgentDirectLogin') == 'true' ?
                            JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 4 && "main-content with-dr-container" :
                            JSON.parse(sessionStorage.getItem('indirectAgentloginDataSS')).RoleID == 4 && "main-content with-dr-container" :
                            "main-content app-content with-dr-container"
                        }>
                            <div className="">

                                <div className="row">
                                    {sessionStorage.getItem('IsAgentDirectLogin') ?
                                        sessionStorage.getItem('IsAgentDirectLogin') == 'true' ?
                                            JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 4 &&
                                            <AgentTopMenu /> :
                                            JSON.parse(sessionStorage.getItem('indirectAgentloginDataSS')).RoleID == 4
                                            && <AgentTopMenu /> : <></>
                                    }
                                </div>
                                <div className="">
                                    <div className="col-xl-12 p-0">
                                        <div className="mt-1">
                                            <div className="card-header p-3">
                                                <h3 className="card-title" style={{ fontSize: 15 }}>
                                                    {nationalityId == 'international' ? 'International' : nationalityId == 'national' ? 'National' : nationalityId == 'mix' ? 'Mix' : ''} Agent Registration
                                                </h3>
                                            </div>
                                            <div className="card-body p-2">
                                                <div className="row mt-2">

                                                    <div className="col-sm-6">
                                                        <label className="d-flex">
                                                            {" "}
                                                            <input type="radio" name="billingRadio"
                                                                checked={isSelfPaid == 1}
                                                                disabled={is_view || (is_update && !is_enroll) || is_navFromProfile}
                                                                onChange={(e) => this.setState({ isSelfPaid: 1, preAgentCode: "AG1" })}
                                                            />
                                                            <strong>Full
                                                                Billing </strong> (Space Booking & Utility Payments-Billed to
                                                            Agent)
                                                        </label>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <label className="d-flex">
                                                            {" "}
                                                            <input type="radio" name="billingRadio"
                                                                checked={isSelfPaid == 0}
                                                                disabled={is_view || (is_update && !is_enroll) || is_navFromProfile}
                                                                onChange={(e) => this.setState({ isSelfPaid: 0, preAgentCode: "AG2" })}
                                                            />
                                                            <strong>Exhibitor Billing </strong> (Space
                                                            Booking & Utility Payments-Billed to Agent Exhibitor )
                                                        </label>
                                                    </div>

                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-sm-3">
                                                        <label>Agent Code</label>
                                                        <div className="ag1">
                                                            <h4>{preAgentCode}-</h4>
                                                            <input
                                                                placeholder="Enter Agent Code"
                                                                className="form-control"
                                                                type="text"
                                                                value={agentCode}
                                                                maxLength={'10'}
                                                                disabled={is_view || (is_update) || is_navFromProfile}
                                                                onChange={e => this.setState({ agentCode: e.target.value, agentCodeErr: "" })}
                                                                // onInput={e => this.maxLengthCheck(e, 10)} size={'10'}
                                                                // onKeyDown={(e) => this.preventMinus(e)}
                                                                // onWheel={(e) => e.target.blur()}
                                                                style={{
                                                                    paddingLeft: '5px'
                                                                }}
                                                                onBlur={(e) => this.verifyAgentEmail(e, 2)}
                                                            />
                                                        </div>
                                                        {<span className='text-danger text-start text-left d-block mb-2'>{agentCodeErr}</span>}
                                                    </div>

                                                </div>
                                                <hr />
                                                {/* name */}
                                                <div className="row mt-1">
                                                    <h6 className="mb-4" style={{ fontWeight: 500 }}>
                                                        Step 1: Name and full address of the company (for entry in
                                                        the Exhibition Catalogue &amp; Correspondence)
                                                    </h6>
                                                    <div className="col-xl-3 pb-0">
                                                        <label htmlFor="validationCustom03">
                                                            First Name of Person Incharge
                                                            <span className="text-red">*</span>
                                                        </label>
                                                        {
                                                            isOtherPersonInchFirst &&
                                                            <input
                                                                type="text"
                                                                className="form-control-1 other-fil"
                                                                value={otherPersonInchFirst}
                                                                disabled={is_view || (is_update && !is_enroll) || is_navFromProfile}
                                                                onChange={e => this.setState({ otherPersonInchFirst: e.target.value })}
                                                                placeholder={'Other'}
                                                                style={{
                                                                    // position: "absolute",
                                                                    paddingLeft: '5px'
                                                                }}
                                                            />
                                                        }
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            value={personInchFirst}
                                                            disabled={is_view || (is_update && !is_enroll) || is_navFromProfile}
                                                            onChange={(e) => this.setState({ personInchFirst: e.target.value, personInchFirstErr: "" })}
                                                            style={{
                                                                // position: "absolute",
                                                                paddingLeft: '50px'
                                                            }}
                                                        />
                                                        <div className="col-xl-1 mb-3">
                                                            <div
                                                                className="form-group"
                                                                style={{
                                                                    width: '45px',
                                                                    position: "absolute",
                                                                    top: "-58px",
                                                                    left: '0px'
                                                                }}
                                                            >
                                                                <label />
                                                                <select
                                                                    className="form-control-1 select2 select-hidden-accessible"
                                                                    id="roledb"
                                                                    style={{
                                                                        paddingLeft: '0px',
                                                                        paddingRight: '0px',
                                                                        borderTop: "none",
                                                                        borderBottom: "none",
                                                                        marginTop: '0px',
                                                                        textAlign: "left"
                                                                    }}
                                                                    value={prefixPersonInchFirst}
                                                                    disabled={is_view || (is_update && !is_enroll) || is_navFromProfile}
                                                                    onChange={(e) => this.setState({
                                                                        prefixPersonInchFirst: e.target.value,
                                                                        isOtherPersonInchFirst: e.target.value == "Other" && true
                                                                    })}
                                                                >
                                                                    <option value="Mr">Mr</option>
                                                                    <option value="Ms">Ms</option>
                                                                    <option value="DR">DR</option>
                                                                    <option value="Prof">Prof</option>
                                                                    <option value="Other">Other</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {<span className='text-danger text-start text-left d-block mb-2'>{personInchFirstErr}</span>}
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <label>
                                                            Person In charge(Last Name)<span className="text-red">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            maxLength={100}
                                                            value={personInchLast}
                                                            disabled={is_view || (is_update && !is_enroll) || is_navFromProfile}
                                                            onChange={(e) => this.setState({ personInchLast: e.target.value, personInchLastErr: "" })}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{personInchLastErr}</span>}
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom03">
                                                            Designation<span className="text-red">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            value={designation}
                                                            disabled={is_view || is_navFromProfile}
                                                            onChange={(e) => this.setState({ designation: e.target.value, designationErr: "" })}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{designationErr}</span>}
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom03">
                                                            Company Name<span className="text-red">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            value={companyName}
                                                            disabled={is_view || is_navFromProfile}
                                                            onChange={(e) => this.setState({ companyName: e.target.value, companyNameErr: "" })}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{companyNameErr}</span>}
                                                    </div>
                                                </div>
                                                {/* address */}
                                                <div className="row mt-2">
                                                    <div className="col-xl-6">
                                                        <label htmlFor="validationCustom03">
                                                            Address Line 1<span className="text-red">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            value={add1}
                                                            disabled={is_view || is_navFromProfile}
                                                            onChange={(e) => this.setState({ add1: e.target.value, add1Err: "" })}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{add1Err}</span>}
                                                    </div>
                                                    <div className="col-xl-6">
                                                        <label htmlFor="validationCustom03">Address Line 2</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            value={add2}
                                                            disabled={is_view || is_navFromProfile}
                                                            onChange={(e) => this.setState({ add2: e.target.value })}
                                                        />
                                                    </div>
                                                </div>
                                                {/* city */}
                                                <div className="row mt-2">
                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom03">
                                                            {countryId == "91" ? "Pincode" : "Zipcode"}
                                                            <span className="text-red">*</span>
                                                        </label>
                                                        {
                                                            countryId == "91" &&
                                                            <input
                                                                type="text"
                                                                maxLength={6}
                                                                className="form-control"
                                                                id="validationCustom03"
                                                                disabled={is_view || is_navFromProfile}
                                                                value={pin}
                                                                onChange={(e) => this.onPincodeSearch(e)}
                                                            />
                                                        }
                                                        {
                                                            countryId != "91" &&
                                                            <input
                                                                type="number"
                                                                maxLength={10}
                                                                className="form-control"
                                                                id="validationCustom03"
                                                                value={pin}
                                                                disabled={is_view || is_navFromProfile}
                                                                onChange={(e) => this.onPincodeSearch(e)}
                                                            // onInput={toInputUppercase}
                                                            />
                                                        }
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{pinErr}</span>}
                                                        {isDisplayPincodeList && this.renderPincodeList()}
                                                    </div>

                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom03">
                                                            City<span className="text-red">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            disabled={is_view || is_navFromProfile}
                                                            value={city}
                                                            onChange={(e) => this.onCitySearch(e)}
                                                        />
                                                        {isDisplayCityList && this.renderCityList()}
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{cityErr}</span>}
                                                    </div>

                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom03">
                                                            {countryId == "91" ? "State" : "State/Province"}
                                                            {countryId == "91" && <span className="text-red">*</span>}
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            disabled={is_view || is_navFromProfile}
                                                            value={state}
                                                            onChange={(e) => this.onStateSearch(e)}
                                                        />
                                                        {isDisplayStateList && this.renderStateList()}
                                                    </div>

                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom03">
                                                            Country<span className="text-red">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            disabled={is_view || (is_update && !is_enroll) || is_navFromProfile}
                                                            value={country}
                                                            onChange={(e) => this.onCountrySearch(e)}
                                                        />
                                                        {isDisplayCountryList && this.renderCountryList()}
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{countryErr}</span>}
                                                    </div>
                                                </div>
                                                {/* mobile no */}
                                                <div className="row mt-2">
                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom01">Mobile No<span className="text-red">*</span></label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            value={mobNo} maxLength={'10'}
                                                            disabled={is_view}
                                                            onChange={e => e.target.value >= 0 && this.setState({ mobNo: e.target.value, mobNoErr: '' })}
                                                            onInput={e => agentCountryId == 91 ? this.maxLengthCheck(e, 10) : null} size={'10'}
                                                            onKeyDown={(e) => this.preventMinus(e)}
                                                            onWheel={(e) => e.target.blur()}
                                                            style={{ paddingLeft: '50px' }}
                                                        />
                                                        <input
                                                            type="text"
                                                            className="form-control-1"
                                                            value={preMobNo}
                                                            disabled={is_view}
                                                            onChange={e => this.setState({ preMobNo: e.target.value, preMobNoErr: '' })}
                                                            style={{
                                                                width: '45px',
                                                                position: "absolute",
                                                                top: '23px',
                                                                left: '12px',
                                                                paddingLeft: '14px'
                                                            }}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{mobNoErr}</span>}
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom03">
                                                            Alternate Mobile No
                                                        </label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            disabled={is_view}
                                                            value={altMobNo} maxLength={'10'}
                                                            onChange={e => e.target.value >= 0 && this.setState({ altMobNo: e.target.value })}
                                                            onInput={e => agentCountryId == 91 ? this.maxLengthCheck(e, 10) : null} size={'10'}
                                                            onKeyDown={(e) => this.preventMinus(e)}
                                                            onWheel={(e) => e.target.blur()}
                                                            style={{ paddingLeft: '50px' }}
                                                        />
                                                        <input
                                                            type="text"
                                                            className="form-control-1"
                                                            value={altPreMobNo}
                                                            disabled={is_view}
                                                            onChange={e => this.setState({ altPreMobNo: e.target.value })}
                                                            style={{
                                                                width: '45px',
                                                                position: "absolute",
                                                                top: '23px',
                                                                left: '12px',
                                                                paddingLeft: '14px'
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom03">
                                                            Telephone
                                                        </label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            style={{ paddingLeft: 50 }}
                                                            disabled={is_view || is_navFromProfile}
                                                            value={telephone} maxLength={'10'}
                                                            onInput={(e) => agentCountryId == 91 ? this.maxLengthCheck(e, 10) : null} size={'10'}
                                                            onKeyDown={(e) => this.preventMinus(e)}
                                                            onWheel={(e) => e.target.blur()}
                                                            onChange={(e) => e.target.value >= 0 && this.setState({ telephone: e.target.value, })}
                                                        />
                                                        <input
                                                            type="text"
                                                            placeholder="STD"
                                                            className="form-control-1"
                                                            disabled={is_view || is_navFromProfile}
                                                            value={preTelephone}
                                                            onChange={(e) => this.setState({ preTelephone: e.target.value })}
                                                            style={{
                                                                width: 45,
                                                                position: "absolute",
                                                                top: 22,
                                                                padding: "6px !important",
                                                                paddingLeft: 5,
                                                                borderLeft: 0,
                                                                borderTop: 0,
                                                                background: "none",
                                                                borderBottom: 0,
                                                                height: 35
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom03">
                                                            Telephone No 1
                                                        </label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            style={{ paddingLeft: 50 }}
                                                            value={altTelephone} maxLength={'10'}
                                                            disabled={is_view || is_navFromProfile}
                                                            onInput={(e) => agentCountryId == 91 ? this.maxLengthCheck(e, 10) : null} size={'10'}
                                                            onKeyDown={(e) => this.preventMinus(e)}
                                                            onWheel={(e) => e.target.blur()}
                                                            onChange={(e) => e.target.value >= 0 && this.setState({ altTelephone: e.target.value, })}
                                                        />
                                                        <input
                                                            type="text"
                                                            placeholder="STD"
                                                            className="form-control-1"
                                                            value={altPreTelephone}
                                                            disabled={is_view || is_navFromProfile}
                                                            onChange={(e) => this.setState({ altPreTelephone: e.target.value })}
                                                            style={{
                                                                width: 45,
                                                                position: "absolute",
                                                                top: 22,
                                                                padding: "6px !important",
                                                                paddingLeft: 5,
                                                                borderLeft: 0,
                                                                borderTop: 0,
                                                                background: "none",
                                                                borderBottom: 0,
                                                                height: 35
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                {/* email */}
                                                <div className="row mt-2">
                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom03">
                                                            Primary Email<span className="text-red">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            value={primaryEmail}
                                                            disabled={is_view || (is_update && !is_enroll) || is_navFromProfile}
                                                            onChange={(e) => this.setState({ primaryEmail: e.target.value, primaryEmailErr: "" })}
                                                            onBlur={(e) => this.verifyAgentEmail(e, 1)}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{primaryEmailErr}</span>}
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom03">
                                                            Secondary Email
                                                        </label>
                                                        <input
                                                            type="text"
                                                            disabled={is_view}
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            value={secondaryEmail}
                                                            onChange={(e) => this.setState({ secondaryEmail: e.target.value })}
                                                        />
                                                    </div>
                                                    <div className="col-xl-6">
                                                        <label htmlFor="validationCustom03">
                                                            Website
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            disabled={is_view || is_navFromProfile}
                                                            value={website}
                                                            onChange={(e) => this.setState({ website: e.target.value })}
                                                        />
                                                        {/* {<span className='text-danger text-start text-left d-block mt-1'>{billWebErr}</span>} */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="main-container bg-section">
                                <div className="container-fluid pt-3 pb-3 p-2">
                                    <div className="">
                                        {/* Invoice Start Here ------------------------------------------------------------------*/}
                                        <h5 style={{ float: "left" }}>
                                            <strong>Billing Details</strong>
                                        </h5>
                                        <label className="d-flex w-auto" style={{ float: "right" }}>
                                            <input type="checkbox" name=""
                                                onClick={this.sameAsCorrespondence}
                                                checked={isSameAsCorrespondence}
                                                disabled={is_view || is_navFromProfile} />
                                            <strong>Same as Correspondence Details</strong>{" "}
                                        </label>
                                        <br />
                                        <br />
                                        {/* bill name */}
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col-xl-3">
                                            <label htmlFor="validationCustom03">
                                                Billing (First Name)
                                                <span className="text-red">*</span>
                                            </label>
                                            {
                                                isbillOtherPersonInchFirst &&
                                                <input
                                                    type="text"
                                                    className="form-control-1 other-fil"
                                                    value={billOtherPersonInchFirst}
                                                    disabled={is_view || isSameAsCorrespondence || is_navFromProfile}
                                                    onChange={e => this.setState({ billOtherPersonInchFirst: e.target.value })}
                                                    placeholder={'Other'}
                                                    style={{
                                                        // position: "absolute",
                                                        paddingLeft: '5px'
                                                    }}
                                                />
                                            }
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="validationCustom03"
                                                disabled={is_view || isSameAsCorrespondence || is_navFromProfile}
                                                value={billPersonInchFirst}
                                                onChange={(e) => this.setState({ billPersonInchFirst: e.target.value, billPersonInchFirstErr: "" })}
                                                style={{
                                                    // position: "absolute",
                                                    paddingLeft: '50px'
                                                }}
                                            />
                                            <div className="col-xl-1 mb-1">
                                                <div
                                                    className="form-group"
                                                    style={{
                                                        width: '45px',
                                                        position: "absolute",
                                                        top: "-58px",
                                                        left: '0px'
                                                    }}
                                                >
                                                    <label />
                                                    <select
                                                        className="form-control-1 select2 select-hidden-accessible"
                                                        id="roledb"
                                                        style={{
                                                            paddingLeft: '0px',
                                                            paddingRight: '0px',
                                                            borderTop: "none",
                                                            borderBottom: "none",
                                                            marginTop: '0px',
                                                            textAlign: "left"
                                                        }}
                                                        disabled={is_view || isSameAsCorrespondence || is_navFromProfile}
                                                        value={billPrefixPersonInchFirst}
                                                        onChange={(e) => this.setState({
                                                            billPrefixPersonInchFirst: e.target.value,
                                                            isbillOtherPersonInchFirst: e.target.value == "Other" && true
                                                        })}
                                                    >
                                                        <option value="Mr">Mr</option>
                                                        <option value="Ms">Ms</option>
                                                        <option value="DR">DR</option>
                                                        <option value="Prof">Prof</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {<span className='text-danger text-start text-left d-block mt-1'>{billPersonInchFirstErr}</span>}
                                        </div>
                                        <div className="col-xl-3">
                                            <label>
                                                Last Name<span className="text-red">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                disabled={is_view || isSameAsCorrespondence || is_navFromProfile}
                                                className="form-control"
                                                maxLength={100}
                                                value={billPersonInchLast}
                                                onChange={(e) => this.setState({ billPersonInchLast: e.target.value, billPersonInchLastErr: "" })}
                                            />
                                            {<span className='text-danger text-start text-left d-block mt-1'>{billPersonInchLastErr}</span>}
                                        </div>
                                        <div className="col-xl-3">
                                            <label htmlFor="validationCustom03">
                                                Designation<span className="text-red">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="validationCustom03"
                                                value={billDesignation}
                                                disabled={is_view || isSameAsCorrespondence || is_navFromProfile}
                                                onChange={(e) => this.setState({ billDesignation: e.target.value, billDesignationErr: "" })}
                                            />
                                            {<span className='text-danger text-start text-left d-block mt-1'>{billDesignationErr}</span>}
                                        </div>
                                        <div className="col-xl-3">
                                            <label htmlFor="validationCustom03">
                                                Name of the Company<span className="text-red">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="validationCustom03"
                                                value={billCompanyName}
                                                disabled={is_view || isSameAsCorrespondence || is_navFromProfile}
                                                onChange={(e) => this.setState({ billCompanyName: e.target.value, billCompanyNameErr: "" })}
                                            />
                                            {<span className='text-danger text-start text-left d-block mt-1'>{billCompanyNameErr}</span>}
                                        </div>
                                    </div>
                                    {/* bill address */}
                                    <div className="row mt-2">
                                        <div className="col-xl-6">
                                            <label htmlFor="validationCustom03">
                                                Address Line 1<span className="text-red">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="validationCustom03"
                                                value={billAdd1}
                                                disabled={is_view || isSameAsCorrespondence || is_navFromProfile}
                                                onChange={(e) => this.setState({ billAdd1: e.target.value, billAdd1Err: "" })}
                                            />
                                            {<span className='text-danger text-start text-left d-block mt-1'>{billAdd1Err}</span>}
                                        </div>
                                        <div className="col-xl-6">
                                            <label htmlFor="validationCustom03">Address Line 2</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="validationCustom03"
                                                value={billAdd2}
                                                disabled={is_view || isSameAsCorrespondence || is_navFromProfile}
                                                onChange={(e) => this.setState({ billAdd2: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    {/* bill city */}
                                    <div className="row mt-2">
                                        <div className="col-xl-3">
                                            <label htmlFor="validationCustom03">
                                                {billCountryId == "91" ? "Pincode" : "Zipcode"}
                                                <span className="text-red">*</span>
                                            </label>
                                            {
                                                billCountryId == "91" &&
                                                <input
                                                    type="text"
                                                    maxLength={6}
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    disabled={is_view || isSameAsCorrespondence || is_navFromProfile}
                                                    value={billPin}
                                                    onChange={(e) => this.onBillPincodeSearch(e)}
                                                />
                                            }
                                            {
                                                billCountryId != "91" &&
                                                <input
                                                    type="number"
                                                    maxLength={10}
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    value={billPin}
                                                    disabled={is_view || isSameAsCorrespondence || is_navFromProfile}
                                                    onChange={(e) => this.onBillPincodeSearch(e)}
                                                // onInput={toInputUppercase}
                                                />
                                            }
                                            {isDisplayBillPincodeList && this.renderBillPincodeList()}
                                            {<span className='text-danger text-start text-left d-block mt-1'>{billPinErr}</span>}
                                        </div>

                                        <div className="col-xl-3">
                                            <label htmlFor="validationCustom03">
                                                City<span className="text-red">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="validationCustom03"
                                                disabled={is_view || isSameAsCorrespondence || is_navFromProfile}
                                                value={billCity}
                                                onChange={(e) => this.onBillCitySearch(e)}
                                            />
                                            {isDisplayBillCityList && this.renderBillCityList()}
                                            {<span className='text-danger text-start text-left d-block mt-1'>{billCityErr}</span>}
                                        </div>

                                        <div className="col-xl-3">
                                            <label htmlFor="validationCustom03">
                                                {billCountryId == "91" ? "State" : "State/Province"}
                                                {billCountryId == "91" && <span className="text-red">*</span>}
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="validationCustom03"
                                                disabled={is_view || isSameAsCorrespondence || is_navFromProfile}
                                                value={billState}
                                                onChange={(e) => this.onBillStateSearch(e)}
                                            />
                                            {isDisplayBillStateList && this.renderBillStateList()}
                                        </div>

                                        <div className="col-xl-3">
                                            <label htmlFor="validationCustom03">
                                                Country<span className="text-red">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="validationCustom03"
                                                disabled={is_view || isSameAsCorrespondence || is_navFromProfile}
                                                value={billCountry}
                                                onChange={(e) => this.onBillCountrySearch(e)}
                                            />
                                            {isDisplayBillCountryList && this.renderBillCountryList()}
                                            {<span className='text-danger text-start text-left d-block mt-1'>{billCountryErr}</span>}
                                        </div>
                                    </div>
                                    {/* bill mobile no */}
                                    <div className="row mt-2">
                                        <div className="col-xl-3">
                                            <label htmlFor="validationCustom01">Mobile No<span className="text-red">*</span></label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={billMobNo} maxLength={'10'}
                                                disabled={is_view || isSameAsCorrespondence || is_navFromProfile}
                                                onChange={e => e.target.value >= 0 && this.setState({ billMobNo: e.target.value, billMobNoErr: '' })}
                                                onInput={e => agentCountryId == 91 ? this.maxLengthCheck(e, 10) : null} size={'10'}
                                                onKeyDown={(e) => this.preventMinus(e)}
                                                onWheel={(e) => e.target.blur()}
                                                style={{ paddingLeft: '50px' }}
                                            />
                                            <input
                                                type="text"
                                                placeholder="+91"
                                                className="form-control-1"
                                                value={billPreMobNo}
                                                disabled={is_view || isSameAsCorrespondence || is_navFromProfile}
                                                onChange={e => this.setState({ billPreMobNo: e.target.value, billPreMobNoErr: '' })}
                                                // placeholder={+91}
                                                style={{
                                                    width: '45px',
                                                    position: "absolute",
                                                    top: '23px',
                                                    left: '12px',
                                                    paddingLeft: '14px'
                                                }}
                                            />
                                            {<span className='text-danger text-start text-left d-block mt-1'>{billMobNoErr}</span>}
                                        </div>
                                        <div className="col-xl-3">
                                            <label htmlFor="validationCustom03">
                                                Alternate Mobile No
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                disabled={is_view || isSameAsCorrespondence || is_navFromProfile}
                                                value={billAltMobNo} maxLength={'10'}
                                                onChange={e => e.target.value >= 0 && this.setState({ billAltMobNo: e.target.value })}
                                                onInput={e => agentCountryId == 91 ? this.maxLengthCheck(e, 10) : null} size={'10'}
                                                onKeyDown={(e) => this.preventMinus(e)}
                                                onWheel={(e) => e.target.blur()}
                                                style={{ paddingLeft: '50px' }}
                                            />
                                            <input
                                                type="text"
                                                placeholder="+91"
                                                className="form-control-1"
                                                disabled={is_view || isSameAsCorrespondence || is_navFromProfile}
                                                value={billAltPreMobNo}
                                                onChange={e => this.setState({ billAltPreMobNo: e.target.value })}
                                                // placeholder={+91}
                                                style={{
                                                    width: '45px',
                                                    position: "absolute",
                                                    top: '23px',
                                                    left: '12px',
                                                    paddingLeft: '14px'
                                                }}
                                            />
                                        </div>
                                        <div className="col-xl-3">
                                            <label htmlFor="validationCustom03">
                                                Telephone
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                style={{ paddingLeft: 50 }}
                                                disabled={is_view || isSameAsCorrespondence || is_navFromProfile}
                                                value={billTelephone} maxLength={'10'}
                                                onInput={(e) => agentCountryId == 91 ? this.maxLengthCheck(e, 10) : null} size={'10'}
                                                onKeyDown={(e) => this.preventMinus(e)}
                                                onWheel={(e) => e.target.blur()}
                                                onChange={(e) => e.target.value >= 0 && this.setState({ billTelephone: e.target.value })}
                                            />
                                            <input
                                                type="text"
                                                placeholder="STD"
                                                className="form-control-1"
                                                value={billPreTelephone}
                                                disabled={is_view || isSameAsCorrespondence || is_navFromProfile}
                                                onChange={(e) => this.setState({ billPreTelephone: e.target.value })}
                                                style={{
                                                    width: 45,
                                                    position: "absolute",
                                                    top: 22,
                                                    padding: "6px !important",
                                                    paddingLeft: 5,
                                                    borderLeft: 0,
                                                    borderTop: 0,
                                                    background: "none",
                                                    borderBottom: 0,
                                                    height: 35
                                                }}
                                            />
                                        </div>
                                        <div className="col-xl-3">
                                            <label htmlFor="validationCustom03">
                                                Telephone No 1
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                style={{ paddingLeft: 50 }}
                                                value={billAltTelephone} maxLength={'10'}
                                                disabled={is_view || isSameAsCorrespondence || is_navFromProfile}
                                                onInput={(e) => agentCountryId == 91 ? this.maxLengthCheck(e, 10) : null} size={'10'}
                                                onKeyDown={(e) => this.preventMinus(e)}
                                                onWheel={(e) => e.target.blur()}
                                                onChange={(e) => e.target.value >= 0 && this.setState({ billAltTelephone: e.target.value })}
                                            />
                                            <input
                                                type="text"
                                                className="form-control-1"
                                                placeholder="STD"
                                                value={billAltPreTelephone}
                                                disabled={is_view || isSameAsCorrespondence || is_navFromProfile}
                                                onChange={(e) => this.setState({ billAltPreTelephone: e.target.value })}
                                                style={{
                                                    width: 45,
                                                    position: "absolute",
                                                    top: 22,
                                                    padding: "6px !important",
                                                    paddingLeft: 5,
                                                    borderLeft: 0,
                                                    borderTop: 0,
                                                    background: "none",
                                                    borderBottom: 0,
                                                    height: 35
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {/* bill email */}
                                    <div className="row mt-2">
                                        <div className="col-xl-3">
                                            <label htmlFor="validationCustom03">
                                                Primary Email<span className="text-red">*</span>
                                            </label>
                                            <input
                                                type="text"

                                                className="form-control"
                                                id="validationCustom03"
                                                disabled={is_view || isSameAsCorrespondence || (is_update && !is_enroll) || is_navFromProfile}
                                                value={billPrimaryEmail}
                                                onChange={(e) => this.setState({ billPrimaryEmail: e.target.value, billPrimaryEmailErr: "" })}
                                            />
                                            {<span className='text-danger text-start text-left d-block mt-1'>{billPrimaryEmailErr}</span>}
                                        </div>
                                        <div className="col-xl-3">
                                            <label htmlFor="validationCustom03">
                                                Secondary Email
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="validationCustom03"
                                                disabled={is_view || isSameAsCorrespondence || is_navFromProfile}
                                                value={billSecondaryEmail}
                                                onChange={(e) => this.setState({ billSecondaryEmail: e.target.value })}
                                            />
                                        </div>
                                        <div className="col-xl-6">
                                            <label htmlFor="validationCustom03">
                                                Website
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="validationCustom03"
                                                value={billWebsite}
                                                disabled={is_view || isSameAsCorrespondence || is_navFromProfile}
                                                onChange={(e) => this.setState({ billWebsite: e.target.value })}
                                            />
                                            {/* {<span className='text-danger text-start text-left d-block mt-1'>{billWebErr}</span>} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* bank details */}
                            <div className="main-container">
                                <div className="container-fluid pt-3 pb-3">
                                    <h5 style={{ float: "left", width: "100%" }}>
                                        <strong>Bank Details</strong>
                                    </h5>
                                    <br />
                                    <div>
                                        {" "}
                                        <strong>
                                            (Kindly fill the Bank Details in the Below format to get the
                                            Security Deposit &amp; other refunds if any. Please note that the
                                            refund will be credited to this account only.)
                                        </strong>
                                    </div>
                                    <div className="form-row mt-4">
                                        <div className="col-xl-6 mb-3">
                                            <label>
                                                Account Name{isSelfPaid != 0 && <span className="text-red">*</span>}
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="validationCustom03"
                                                value={accName}
                                                disabled={is_view || is_navFromProfile}
                                                onChange={(e) => this.setState({ accName: e.target.value, accNameErr: "" })}
                                            />
                                            {isSelfPaid != 0 && <span className='text-danger text-start text-left d-block mt-1'>{accNameErr}</span>}
                                        </div>
                                        <div className="col-xl-3 mb-3">
                                            <label>
                                                {" "}
                                                Account No {isSelfPaid != 0 && <span className="text-red">*</span>}
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="validationCustom03"
                                                value={accNo}
                                                disabled={is_view || is_navFromProfile}
                                                onChange={(e) => this.setState({ accNo: e.target.value, accNoErr: "" })}
                                            />
                                            {isSelfPaid != 0 && <span className='text-danger text-start text-left d-block mt-1'>{accNoErr}</span>}
                                        </div>
                                        <div className="col-xl-3 ">
                                            <label htmlFor="validationCustom01">Account Type</label>
                                            <select
                                                className="form-control-1 select2 onl-bor select-hidden-accessible text-left"
                                                id="roledb"
                                                style={{ textAlign: "left" }}
                                                value={accType}
                                                disabled={is_view || is_navFromProfile}
                                                onChange={e => this.setState({ accType: e.target.value })}>
                                                <option>Select</option>
                                                {accTypeList.length > 0 ? accTypeList.map(data => (
                                                    <option>
                                                        {data}
                                                    </option>
                                                )) : <></>}
                                                {/* <option value={'Saving account'}>Saving account</option>
                                                <option value={'Fixed Deposit account'}>Fixed Deposit account</option>
                                                <option value={'Reccurring Deposit account'}>Reccurring Deposit account</option>
                                                <option value={'NRI account'}>NRI account</option>
                                                <option value={'Others'}>Others</option> */}
                                            </select>
                                            {accType == 'Others' ? (
                                                <input
                                                    type="text"
                                                    maxLength={20}
                                                    disabled={is_view || is_navFromProfile}
                                                    className="form-control acount-type-oth"
                                                    value={accTypeOthers}
                                                    onChange={e => this.setState({ accTypeOthers: e.target.value })}
                                                />
                                            ) : (null)}

                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-xl-6 mb-3">
                                            <label>Bank Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="validationCustom03"
                                                value={bankName}
                                                disabled={is_view || is_navFromProfile}
                                                onChange={(e) => this.setState({ bankName: e.target.value })}
                                            />
                                        </div>
                                        <div className="col-xl-3 mb-3">
                                            <label>Branch Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="validationCustom03"
                                                value={branchName}
                                                disabled={is_view || is_navFromProfile}
                                                onChange={(e) => this.setState({ branchName: e.target.value })}
                                            />
                                        </div>
                                        <div className="col-xl-3 mb-3">
                                            <label>
                                                IFSC CODE {isSelfPaid != 0 && <span className="text-red">*</span>}
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="validationCustom03"
                                                value={IFSC}
                                                disabled={is_view || is_navFromProfile}
                                                onChange={(e) => this.setState({ IFSC: e.target.value, IFSCErr: '' })}
                                            />
                                            {isSelfPaid != 0 && <span className='text-danger text-start text-left d-block mt-1'>{IFSCErr}</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* area des */}
                            {nationalityId == "national" &&
                                <div className="main-container bg-section">
                                    <div className="container-fluid pt-2 pb-2">
                                        <h5 className="mb-0">
                                            <strong>Area Description</strong>
                                        </h5>
                                        <br />
                                        <div className="form-row">
                                            <div className="col-xl-3 mb-3">
                                                <label>Agent Countries<span className="text-red">*</span></label>
                                                <select
                                                    className="form-control-1 form-control"
                                                    id="roledb"
                                                    value={agentCountryId}
                                                    disabled={nationalityId == "national" || is_navFromProfile}
                                                    style={{ textAlign: "left" }}
                                                    onChange={e => this.setState({ agentCountryId: e.target.value, })}
                                                >
                                                    <option>Select Country</option>
                                                    {
                                                        countryList.length > 0 && countryList.map((data) => (
                                                            <option key={data.Code} value={data.Code} >{data.Description}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className="col-xl-3 mb-3">
                                                <label>
                                                    National Total Area<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    disabled={is_view || is_navFromProfile}
                                                    maxLength={'10'}
                                                    onInput={e => this.maxLengthCheck(e, 10)}
                                                    value={totalNationalArea}
                                                    onKeyDown={(e) => this.preventMinus(e)}
                                                    onWheel={(e) => e.target.blur()}
                                                    onChange={(e) => e.target.value >= 0 && this.setState({ totalNationalArea: e.target.value, totalNationalAreaErr: "" })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{totalNationalAreaErr}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                nationalityId == "international" &&
                                <div className="main-container bg-section">
                                    <div className="container-fluid pt-3 pb-3">
                                        <h5 className="mb-0">
                                            <strong>Area Description</strong>
                                        </h5>
                                        <br />
                                        <div className="form-row">
                                            <div className="col-xl-6 mb-3">
                                                <label>
                                                    Agent Countries<span className="text-red">*</span>
                                                </label>
                                                <Select
                                                    options={interNationalCountryList.map((option) => ({ label: option.Description, value: option.Code }))}
                                                    value={selectedCountry} isDisabled={is_view || is_navFromProfile}
                                                    onChange={this.handleCountrySelect}
                                                    isMulti
                                                />
                                            </div>
                                            <div className="col-xl-3 mb-3">
                                                <label>
                                                    International Total Area<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    maxLength={'10'}
                                                    disabled={is_view || is_navFromProfile}
                                                    onInput={e => this.maxLengthCheck(e, 10)}
                                                    value={totalInternationalArea}
                                                    onKeyDown={(e) => this.preventMinus(e)}
                                                    onWheel={(e) => e.target.blur()}
                                                    onChange={(e) => e.target.value >= 0 && this.setState({ totalInternationalArea: e.target.value, totalInternationalAreaErr: "" })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{totalInternationalAreaErr}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            }
                            {
                                nationalityId == "mix" &&
                                <div className="main-container bg-section">
                                    <div className="container-fluid pt-3 pb-3">
                                        <h5 className="mb-0">
                                            <strong>Area Description</strong>
                                        </h5>
                                        <br />
                                        <div className="form-row">
                                            <div className="col-xl-3 mb-3">
                                                <label>
                                                    Domestic Total Area<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    maxLength={'10'}
                                                    disabled={is_view || is_navFromProfile}
                                                    onInput={e => this.maxLengthCheck(e, 10)}
                                                    value={totalNationalArea}
                                                    onKeyDown={(e) => this.preventMinus(e)}
                                                    onWheel={(e) => e.target.blur()}
                                                    onChange={(e) => e.target.value >= 0 && this.setState({ totalNationalArea: e.target.value, totalNationalAreaErr: "" })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{totalNationalAreaErr}</span>}
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-xl-3 mb-3">
                                                <label>
                                                    International Total Area<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    maxLength={'10'}
                                                    disabled={is_view || is_navFromProfile}
                                                    onInput={e => this.maxLengthCheck(e, 10)}
                                                    value={totalInternationalArea}
                                                    onKeyDown={(e) => this.preventMinus(e)}
                                                    onWheel={(e) => e.target.blur()}
                                                    onChange={(e) => e.target.value >= 0 && this.setState({ totalInternationalArea: e.target.value, totalInternationalAreaErr: "" })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{totalNationalAreaErr}</span>}
                                            </div>
                                            <div className="col-xl-6 mb-3">
                                                <label>
                                                    Agent Countries<span className="text-red">*</span>
                                                </label>
                                                <Select
                                                    options={interNationalCountryList.map((option) => ({ label: option.Description, value: option.Code }))}
                                                    value={selectedCountry} isDisabled={is_view || is_navFromProfile}
                                                    onChange={this.handleCountrySelect}
                                                    isMulti
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            }
                            <div className="main-container">
                                <div className="container-fluid pt-5 pb-3">
                                    <h5 className="mb-0">
                                        <strong>Chapter Wise Area Description</strong>
                                    </h5>
                                    <br />
                                    {
                                        primaryChapterList.length > 0 && primaryChapterList.map((data, index) => (
                                            <div className="form-row mt-3 mb-3" key={data.ChapterID}>

                                                <div style={{ position: 'relative' }} className="col-xl-6 mb-1">
                                                    <label style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', textAlign: 'left' }} className="chap-dsc">
                                                        <input type="checkbox" name="collapse"
                                                            select={data.IsSelect} checked={data.IsSelect}
                                                            value={data.ChapterID} disabled={is_view || is_navFromProfile}
                                                            onChange={(e) => this.OnChapterSelect(e)}
                                                        />{data.ChapterName}
                                                    </label>

                                                </div>
                                                <div className="col-md-5">
                                                    <div className="row">
                                                        {
                                                            this.state.nationalityId == "international" ? null :
                                                                <div className="col-sm-6">
                                                                    <input
                                                                        type="number"
                                                                        className="form-control"
                                                                        id="validationCustom03"
                                                                        placeholder="National Area"
                                                                        readOnly={data.ReadOnly}
                                                                        value={data.nationalArea}
                                                                        disabled={is_view || is_navFromProfile}
                                                                        maxLength={'10'}
                                                                        onInput={e => this.maxLengthCheck(e, 10)}
                                                                        onChange={(e) => this.onNationalAreaChange(e, data.ChapterID)}
                                                                        onKeyDown={(e) => this.preventMinus(e)}
                                                                        onWheel={(e) => e.target.blur()}
                                                                    />
                                                                    {<span className='text-danger text-start text-left d-block mt-1'>{data.nationalAreaErr}</span>}
                                                                </div>
                                                        }
                                                        {
                                                            this.state.nationalityId == "national" ? null :
                                                                <div className="col-sm-6">
                                                                    <input
                                                                        type="number"
                                                                        className="form-control"
                                                                        id="validationCustom03"
                                                                        placeholder="International Area"
                                                                        readOnly={data.ReadOnly}
                                                                        value={data.internationalArea}
                                                                        disabled={is_view || is_navFromProfile}
                                                                        maxLength={'10'}
                                                                        onInput={e => this.maxLengthCheck(e, 10)}
                                                                        onChange={(e) => this.onInternationalAreaChange(e, data.ChapterID)}
                                                                        onKeyDown={(e) => this.preventMinus(e)}
                                                                        onWheel={(e) => e.target.blur()}
                                                                    />
                                                                    {<span className='text-danger text-start text-left d-block mt-1'>{data.internationalAreaErr}</span>}
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                        ))

                                    }
                                </div>
                            </div>
                            {/* <div className="main-container bg-section">
                                <div className="container-fluid pt-5 pb-3">

                                    <h5 className="mb-0">
                                        <strong>Area Pricing</strong>
                                    </h5>
                                    <br />
                                    {this.state.stallTypeList.length > 0 && this.state.stallTypeList.map(data => (
                                        <div className="form-row mt-1">
                                            <div className="col-md-3">
                                                <label className="chap-dsc mt-2">{data.Stalltype}{(data.Stalltype == 'BARE SPACE PRICING' || data.Stalltype == 'SHELL SPACE PRICING') && <span className="text-red">*</span>}
                                                </label>
                                            </div>
                                            <div className="col-md-5">
                                                <div className="row">
                                                    {this.state.nationalityId == "international" ? null :
                                                        <div className="col-sm-6">
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                id="validationCustom03"
                                                                placeholder="Domestic (INR)"
                                                                // readOnly={data.ReadOnly}
                                                                value={data.DomesticPrice}
                                                                disabled={is_view}
                                                                maxLength={'10'}
                                                                onInput={e => this.maxLengthCheck(e, 10)}
                                                                onChange={(e) => this.onDomesticPriceChange(e, data.Stalltype)}
                                                                onKeyDown={(e) => this.preventMinus(e)}
                                                                onWheel={(e) => e.target.blur()}
                                                            />
                                                            {(data.Stalltype == 'BARE SPACE PRICING' || data.Stalltype == 'SHELL SPACE PRICING') && <span className='text-danger text-start text-left d-block mt-1'>{data.DomesticPriceErr}</span>}
                                                        </div>}
                                                    {this.state.nationalityId == "national" ? null : <div className="col-sm-6">
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            placeholder="International (USD)"
                                                            // readOnly={data.ReadOnly}
                                                            value={data.InternationalPrice}
                                                            disabled={is_view}
                                                            maxLength={'10'}
                                                            onInput={e => this.maxLengthCheck(e, 10)}
                                                            onChange={(e) => this.onInternationalPriceChange(e, data.Stalltype)}
                                                            onKeyDown={(e) => this.preventMinus(e)}
                                                            onWheel={(e) => e.target.blur()}
                                                        />
                                                        {(data.Stalltype == 'BARE SPACE PRICING' || data.Stalltype == 'SHELL SPACE PRICING') && <span className='text-danger text-start text-left d-block mt-1'>{data.InternationalPriceErr}</span>}
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div> */}
                            <div className="main-container bg-section">
                                <div className="container-fluid pt-5 pb-3">

                                    <h5 className="mb-0">
                                        <strong>Installments</strong>
                                    </h5>
                                    <br />
                                    <div className="form-row mt-1">
                                        <div className="col-md-3">
                                            <label>
                                                Intallment 1 (%)
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="validationCustom03"
                                                disabled={is_view || is_navFromProfile}
                                                value={installment1}
                                                onChange={(e) => this.setState({ installment1: e.target.value })}
                                            />
                                        </div>
                                        {/* {console.log(installment1)} */}
                                        <div className="col-md-3">
                                            <label>
                                                Intallment 2 (%)
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="validationCustom03"
                                                maxLength={'10'}
                                                disabled={is_view || is_navFromProfile}
                                                onInput={e => this.maxLengthCheck(e, 10)}
                                                value={installment2}
                                                onKeyDown={(e) => this.preventMinus(e)}
                                                onWheel={(e) => e.target.blur()}
                                                onChange={(e) => this.setState({ installment2: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="main-container">
                                <div className="container-fluid ">
                                    {is_add == true || is_enroll ?
                                        <button type="button"
                                            onClick={(e) => this.onSave(e)}
                                            className="btn btn-primary m-2 rounded-0"
                                            disabled={!this.getWirteAccess()}
                                        >
                                            Submit
                                        </button> : is_update == true ?
                                            <button type="button"
                                                onClick={e => this.handleUpdate(e)}
                                                className="btn btn-primary m-2 rounded-0"
                                                disabled={!this.getUpdateAccess()}
                                            >
                                                Update
                                            </button> : null
                                    }
                                    {/* <button type="button"
                                        onClick={e => this.onCancel(e)}
                                        className="btn btn-primary m-2 rounded-0">
                                        Cancel
                                    </button> */}
                                    {is_view && isSelfPaid == 1 && <>
                                        <button type="button"
                                            onClick={(e) => this.PIModalOpen('Generate 1st PI')}
                                            className="btn btn-primary m-2 rounded-0"
                                            disabled={!this.getWirteAccess() || this.state.is1stAgentPICreated != 0}

                                        >
                                            Generate 1st PI(50%)
                                        </button>
                                        <button type="button"
                                            onClick={(e) => this.PIModalOpen('Generate 2nd PI')}
                                            className="btn btn-primary m-2 rounded-0"
                                            disabled={!this.getWirteAccess() || this.state.is2ndAgentPICreated != 0}
                                        >
                                            Generate 2nd PI(50%)
                                        </button>
                                        <button type="button"
                                            onClick={(e) => this.PIModalOpen('Generate Tax Invoice')}
                                            className="btn btn-primary m-2 rounded-0"
                                            disabled={!this.getWirteAccess() || (this.state.is1stAgentPICreated == 0 || this.state.is2ndAgentPICreated == 0)}
                                        >
                                            Generate Tax Invoice
                                        </button>
                                        <button type="button"
                                            onClick={(e) => this.PIModalOpen('Generate Manual Form PI')}
                                            className="btn btn-primary m-2 rounded-0"
                                            disabled={!this.getWirteAccess() || this.state.isManualPICreated != 0}
                                        >
                                            Generate Manual Form PI
                                        </button>
                                        <button type="button"
                                            onClick={(e) => this.PIModalOpen('Generate Open Side PI')}
                                            className="btn btn-primary m-2 rounded-0"
                                            disabled={!this.getWirteAccess() || this.state.isOpenSidePICreated != 0}
                                        >
                                            Generate Open Side PI
                                        </button>
                                    </>}
                                </div>
                            </div>
                            <div style={{ height: 100 }} />

                        </div>
                    </div>
                </div >
                <Modal isOpen={isOpen} onRequestClose={() => this.setState({ isOpen: false })}>
                    <div className="modal-dialog" style={{ marginTop: '20%' }}>
                        <div className="modal-content confirm-logout">
                            <div class="modal-header text-right p-2">
                                <h5 class="modal-title modal_title_text text-Primary">{this.state.PIbutton}</h5>
                            </div>
                            {this.state.PIbutton == 'Generate 1st PI' && < div class="modal-body">
                                <div class="row">
                                    <div className="col-md-9">
                                        <label>
                                            Conversion rate
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="validationCustom03"
                                            maxLength={'10'}
                                            // disabled={is_view}
                                            onInput={e => this.maxLengthCheck(e, 10)}
                                            value={this.state.conversionRate}
                                            onKeyDown={(e) => this.preventMinus(e)}
                                            onWheel={(e) => e.target.blur()}
                                            onChange={(e) => this.setState({ conversionRate: e.target.value })}
                                        />
                                    </div>
                                </div>
                            </div>}
                            <div class="modal-footer p-1">
                                <div class="form-group text-right mod-fot">
                                    <button type="button" className="btn btn-primary m-2 rounded-0"
                                        onClick={(e) => this.onGenerate()}
                                        disabled={this.state.PIbutton == 'Generate 1st PI' && this.state.conversionRate == ''}
                                    >Generate</button>
                                    <button type="button" className="btn btn-danger m-2 rounded-0" data-dismiss="modal" style={{ marginLeft: '5px' }}
                                        onClick={() => this.setState({ isOpen: false })}
                                    >Cancel</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal >
            </>
        )
    }
}
export default withRouter(connect(null, {
    fill_action, agentEntrySave_action, verifyAgentEmail_action, getAgentMailDetails_action,
    agentDetails_action, agentEntryUpate_action, insertAgentPI1_action, insertAgentPI2_action, insertAgentOpenSidePI_action
})(Agententry));