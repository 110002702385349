import { Component } from "react"
import Loader from "../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import HeaderWithLogo from "../../../common_components/header_with_logo";
import AdminDrawer from "../../../common_components/admin_module_drawer";
import Footerwithdr from "../../../common_components/Footerwithdr";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import { reminderEmail_action, getReminderList_action } from "../../../actions/admin_module_action/reminder_email_actions/reminder_email_action";
import { data } from "jquery";
import { reminderEventActionId, toastError, toastSuccess } from "../../../utils/constants";
import moment from "moment";
import { fill_action } from "../../../actions/fillCombo_action";
import Pagination from "../../../utils/pagination";



class ReminderEmails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            reminderList: [],
            currentDate: new Date(),
            reminderTypeId: "",
            reminderEventList: [],
            companyName: "",
            currentPage: 1,
            checkedAll: false,
            selectedCompany: [],
            emailReminderTypeId: "",
            loginAccesslist: [],

        }
        this.reminderEmail = this.reminderEmail.bind(this)
        this.getReminderList = this.getReminderList.bind(this)
        this.deadlineReminder = this.deadlineReminder.bind(this)
        this.getFillList = this.getFillList.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.updateItem = this.updateItem.bind(this)
        this.selectAllCompany = this.selectAllCompany.bind(this)
        this.handleCheckedCompany = this.handleCheckedCompany.bind(this)
        this.getSendAccess = this.getSendAccess.bind(this)
    }

    // On Component Load
    componentDidMount() {
        this.setState({ loginAccesslist: JSON.parse(sessionStorage.getItem('loginDataSS'))['loginAccesslist'] })
        this.getFillList(reminderEventActionId, '', '', '', '', '');
        this.getReminderList()
    }

    // Get Fill Lists
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        this.setState({ isLoading: true })
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value)
            .then((data) => {
                if (data.error != 1) {
                    // Reminder list
                    if (actionID === reminderEventActionId) {
                        console.log("Reminder list data", data.data)
                        this.setState({
                            reminderEventList: data.data.length > 0 ? data.data : [],
                            isLoading: false
                        })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    // On Search Click
    onSearchClick() {
        this.getReminderList()
    }

    // Get Reminder List
    getReminderList() {
        const { companyName, reminderTypeId } = this.state
        this.setState({ isLoading: true })
        this.props.getReminderList_action(companyName, reminderTypeId != "" && reminderTypeId != "Select Reminder Type" ? reminderTypeId : "")
            .then((data) => {
                if (data.error != 1) {
                    console.log("getReminderList Data", data.data)
                    data.data.length > 0 && data.data.map((item) => {
                        item.Is_Select = false
                    })
                    this.setState({
                        reminderList: data.data,
                        isLoading: false
                    })
                } else {
                    this.setState({ isLoading: false })
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    reminderEmail() {
        const { reminderList, emailReminderTypeId } = this.state
        var selectedExhibitor = []
        selectedExhibitor = reminderList.length > 0 && reminderList.filter((item) => item.Is_Select == true)
        if (selectedExhibitor.length > 0) {

            // Exhibitor data Preparing to store
            var list = []
            selectedExhibitor.map(data => {
                var mapData = {
                    'ExhregistrationID': parseInt(data.ExhRegistrationID),
                }
                list.push(mapData)
            });
            this.setState({ isLoading: true })
            this.props.reminderEmail_action(emailReminderTypeId, list)
                .then((data) => {
                    if (data.error != 1) {
                        console.log("reminderEmail", data.data)
                        this.setState({ isLoading: false })
                        toastSuccess("Reminder Mail Sent")
                        setTimeout(() => {
                            window.location.reload()
                        }, 1000);
                    } else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            toastError('Select Atleast One Exhibitor')
        }

    }

    //  Deadline Reminder
    deadlineReminder(e, key, id) {
        var installment;
        if (id == 2) {
            if (key == 1) {
                installment = 1
            } else if (key == 2) {
                installment = 2
            }
        } else if (id == 3) {
            if (key == 1) {
                installment = 3
            } else if (key == 2) {
                installment = 4
            }
        }
        this.setState({ isLoading: true })
        this.props.reminderEmail_action(installment)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ isLoading: false })
                } else {
                    this.setState({ isLoading: false })
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // Update Current Page
    updateItem(item) {
        const { reminderList } = this.state
        this.setState({ currentPage: item });
        const recordsPerPage = 10;
        const indexOfLastRecord = item * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = reminderList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(reminderList.length / recordsPerPage)
    }

    //on Select All Click
    selectAllCompany(e) {
        const { checkedAll, reminderList } = this.state
        this.setState({ checkedAll: !checkedAll })
        const checked = e.target.checked
        reminderList.length > 0 && reminderList.map((data) => {
            if (checked) {
                data.Is_Select = true
            } else {
                data.Is_Select = false
            }
        })
        console.log("yyyyyyyyyyyyy", reminderList)
        this.setState({ selectedCompany: reminderList })
    }

    // On Checked Company
    handleCheckedCompany(e, id) {
        const { reminderList, selectedCompany } = this.state
        const checked = e.target.checked

        reminderList.length > 0 && reminderList.map((data) => {
            if (data.ExhRegistrationID == id) {
                if (checked) {
                    data.Is_Select = true
                } else {
                    data.Is_Select = false
                    this.setState({ checkedAll: false })
                }
            }
        })
        console.log("aaaaaaaaaaa", reminderList)
        this.setState({})
    }

    getSendAccess(){
        const {loginAccesslist} = this.state
        const moduleAccess = loginAccesslist.find((item) => item.ModuleID == '4');
        if (moduleAccess) {
        return moduleAccess.SendAccess;
      }else{
        return false
      }
      return false;
    }

    render() {
        const { isLoading, reminderList, currentDate, reminderTypeId, reminderEventList, companyName, currentPage, checkedAll, emailReminderTypeId } = this.state
        const recordsPerPage = 10;
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = reminderList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(reminderList.length / recordsPerPage)
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />

                <>
                    <AdminDrawer />
                    {/*app-content open*/}
                    <div className={JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ? "with-dr-container" : "with-dr-container com-height"}>
                        <div className="main-content">
                            <div className="">
                                <div className="row">
                                    <div className="step-name">
                                        <p>Reminder Email</p>
                                    </div>
                                </div>
                                <div className="p-2" >
                                    {/* <div className="row mb-4 mt-4">
                                        <div className="table-responsive mt-1 ">
                                            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 ">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center">Sr. No</th>
                                                        <th className="text-center">Date</th>
                                                        <th>Description</th>
                                                        <th className="text-center">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="text-center">1</td>
                                                        <td className=" text-center">15/05/2023</td>
                                                        <td> Online application with 25% advance stall payment</td>
                                                        <td className="text-center">
                                                            <button className="btn btn-primary rem-btn">
                                                                Send Me Reminder (Before Deadline)
                                                            </button>
                                                            <button className="btn btn-secondary rem-btn">
                                                                Send Me Reminder (Before Deadline)
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">2</td>
                                                        <td className=" text-center">16/05/2023</td>
                                                        <td> Online application with 25% advance stall payment</td>
                                                        <td className="text-center">
                                                            <button className="btn btn-primary rem-btn">
                                                                Send Me Reminder (Before Deadline)
                                                            </button>
                                                            <button className="btn btn-secondary rem-btn">
                                                                Send Me Reminder (Before Deadline)
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div> */}
                                    <div className="row mt-4">
                                        <div className="col-md-4">
                                            <label htmlFor="validationCustom03">Company Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search Company"
                                                value={companyName}
                                                onChange={(e) => this.setState({ companyName: e.target.value })}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="validationCustom03">Reminder Type</label>
                                            <select className="form-control"
                                                value={reminderTypeId}
                                                onChange={(e) => this.setState({ reminderTypeId: e.target.value })}
                                            >
                                                <option>Select Reminder Type</option>
                                                {
                                                    reminderEventList.length > 0 && reminderEventList.map((data) => (
                                                        <option key={data.ReminderEventID} value={data.ReminderEventID}>{data.ReminderEvent}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="col-md-1">
                                            <label>&nbsp;</label>
                                            <button class="btn rounded-0 btn-dark"
                                                onClick={(e) => this.onSearchClick()}
                                            >Search</button></div>
                                    </div>
                                    <div className="row mb-3 mt-4">
                                        <div className="table-responsive mt-1 ">
                                            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 ">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center">
                                                            <input type="checkbox"
                                                                checked={checkedAll}
                                                                onChange={(e) => this.selectAllCompany(e)}
                                                            />
                                                        </th>
                                                        <th className="text-center">Sr. No</th>
                                                        <th className="text-center">Registration NO</th>
                                                        <th>Company Name</th>
                                                        <th>Primary Email</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        reminderList.length > 0 && currentRecords.map((data, index) => (
                                                            <tr key={Math.random()}>
                                                                <td className="text-center">
                                                                    <input type="checkbox"
                                                                        value={data.Is_Select}
                                                                        checked={data.Is_Select}
                                                                        onChange={(e) => this.handleCheckedCompany(e, data.ExhRegistrationID)}
                                                                    />
                                                                </td>
                                                                <td className="text-center">{index + 1}</td>
                                                                <td className="text-center">{data.ExhRegistrationNo}</td>
                                                                <td>{data.CompanyName}</td>
                                                                <td>{data.PrimaryEmail}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="mt-2 float-left">
                                            {
                                                reminderList.length > 0 ?
                                                    <Pagination
                                                        nPages={nPages}
                                                        currentPage={currentPage}
                                                        setCurrentPage={this.updateItem}
                                                    /> : <></>
                                            }
                                            <div className="tab-rec " style={{ float: "right" }}>
                                                <p>{`${currentPage} of ${nPages} pages`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 mb-4">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label htmlFor="validationCustom03">Email Reminder Type</label>
                                                <select className="form-control"
                                                    value={emailReminderTypeId}
                                                    onChange={(e) => this.setState({ emailReminderTypeId: e.target.value })}
                                                >
                                                    <option>Select Reminder Type</option>
                                                    {
                                                        reminderEventList.length > 0 && reminderEventList.map((data) => (
                                                            <option key={data.ReminderEventID} value={data.ReminderEventID}>{data.ReminderEvent}</option>
                                                        ))
                                                    }

                                                </select>
                                            </div>
                                            <div className="col-md-2">
                                                <label>&nbsp;</label>
                                                <button className="btn btn-primary"
                                                    disabled={emailReminderTypeId != "" && emailReminderTypeId != "Select Reminder Type" && this.getSendAccess() ? false : true}
                                                    onClick={() => this.reminderEmail()}
                                                >
                                                    Send Reminder Mail
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <style
                                dangerouslySetInnerHTML={{
                                    __html: "\n    .rem-btn{\n        padding: 2px 6px !important;\n    }\n"
                                }}
                            />

                        </div>

                    </div>
                </>

                {/* <Footerwithdr /> */}

            </>
        );
    }


}

export default withRouter(connect(null, { reminderEmail_action, getReminderList_action, fill_action })(ReminderEmails));
