import { axiosGet, axiosPost } from "../../../../react_axios/Axios";
import {
    GET_AIR_CONNECTION_APPROVAL_DETAILS_ACTION_TYPE, GET_AIR_CONNECTION_APPROVAL_LIST_ACTION_TYPE, GET_AIR_CONNECTION_APPROVAL_EXPORT_DATA_ACTION_TYPE, UPDATE_AIR_CONNECTION_APPROVAL__ACTION_TYPE,
    getAirConnectionApprovalDetailsUrl, getAirConnectionApprovalListUrl, getAirConnectionExportDataUrl, updateAirConnectionApprovalUrl, headers
} from "../../../../utils/constants";

export const getAirConnectionApprovalDetails_action = (companyName, status, exhibitorTypeId) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            CompanyName: companyName,
            Status: status,
            ExhibitorTypeID: exhibitorTypeId,
        }
        console.log("getVisitorVisaInvitation_action Action------->", params)

        const res = await axiosGet(getAirConnectionApprovalDetailsUrl, params, headers)

        dispatch({
            type: GET_AIR_CONNECTION_APPROVAL_DETAILS_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getAirConnectionApprovalList_action = (companyname) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            CompanyName: companyname,

        }
        console.log("getVisitorVisaApplication_action Action------->", params)

        const res = await axiosGet(getAirConnectionApprovalListUrl, params, headers)

        dispatch({
            type: GET_AIR_CONNECTION_APPROVAL_LIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getAirConnectionExportData_action = () => async (dispatch) => {
    try {
        const params = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
        }
        console.log("getVisitorVisaisaApplicationExportData_action Action------->", params)

        const res = await axiosGet(getAirConnectionExportDataUrl, params, headers)

        dispatch({
            type: GET_AIR_CONNECTION_APPROVAL_EXPORT_DATA_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const updateAirConnectionApproval_action = (isApproved, list) => async (dispatch) => {
    try {
        const mapData = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            Is_Approved: isApproved,
            approvalList: list
        }
        console.log("updateVisitorVisaApproval_action Action------->", mapData)

        const res = await axiosPost(updateAirConnectionApprovalUrl, mapData, headers)

        dispatch({
            type: UPDATE_AIR_CONNECTION_APPROVAL__ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

