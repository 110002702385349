import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "../../../../utils/withRouter";
import Loader from "../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import HeaderWithLogo from "../../../../common_components/header_with_logo";
import AdminDrawer from "../../../../common_components/admin_module_drawer";
import { getExhBadeApplications_action, updateExhBadgeApproval_action } from "../../../../actions/admin_module_action/manual_forms_admin_action/exhBadges_approval_action/exhBadge_approval_action";
import { toastError, toastSuccess } from "../../../../utils/constants";
import { data } from "jquery";

class ExhibitorBadgesApproval extends Component {

    // Initialized Constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            CompanyName: "",
            exhBadgeApplicationList: [],

        }
        this.getExhBadgesApplications = this.getExhBadgesApplications.bind(this)
        this.onCheckedAppliactions = this.onCheckedAppliactions.bind(this)
        this.onRemarkEnter = this.onRemarkEnter.bind(this)
        this.updateExhbadgeApproval = this.updateExhbadgeApproval.bind(this)
        this.onViewClick = this.onViewClick.bind(this)
    }

    // On Component Load
    componentDidMount() {
        this.props.location.state != null && this.props.location.state.companyName != "" && this.getExhBadgesApplications(this.props.location.state.companyName)

        this.setState({
            CompanyName: this.props.location.state != null && this.props.location.state.companyName ? this.props.location.state.companyName : '',
        })
    }

    // Get Exh Badges Appliactions
    getExhBadgesApplications(companyname) {
        this.setState({ isLoading: true })
        this.props.getExhBadeApplications_action(companyname)
            .then((data) => {
                if (data.error != 1) {
                    console.log("getExhBadgesApplications Data", data.data)
                    data.data.map((item) => {
                        item.isSelected = false;
                        item.isReadOnly = true;
                    })
                    this.setState({
                        exhBadgeApplicationList: data.data,
                        isLoading: false
                    })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // On Check Exh Badge Applications
    onCheckedAppliactions(e) {
        const { exhBadgeApplicationList } = this.state
        var checked = e.target.checked
        var id = e.target.value

        exhBadgeApplicationList.length > 0 && exhBadgeApplicationList.map((data) => {
            if (checked) {
                if (id == data.ExhBadgesFormID) {
                    data.isSelected = true;
                    data.isReadOnly = false
                }
            } else {
                if (id == data.ExhBadgesFormID) {
                    data.isSelected = false;
                    data.isReadOnly = true
                }
            }
        })
        console.log("09000909090", exhBadgeApplicationList)
        this.setState({})
    }

    /// On Remark Enter
    onRemarkEnter(e, id) {
        const { exhBadgeApplicationList } = this.state
        var value = e.target.value
        var list = [...exhBadgeApplicationList]
        list.length > 0 && list.map((data) => {
            if (data.ExhBadgesFormID == id) {
                data.Remarks = value
            }
        })
        console.log("0101010100101", list)
        this.setState({ exhBadgeApplicationList: list })

    }

    // On Update Visa Approval
    updateExhbadgeApproval(e, key) {
        const { exhBadgeApplicationList, CompanyName } = this.state
        var applicationList = exhBadgeApplicationList.filter((item) => item.isSelected == true)
        var list
        // checked Application List data Preparing to store
        var list = []
        applicationList.map(data => {
            var mapData = {
                'ExhBadgeFormID': parseInt(data.ExhBadgesFormID),
                "Remarks": data.Remarks
            }
            list.push(mapData)
        });
        const isCheckedRemark = list.every(item => item.Remarks != '' && item.Remarks != null)
        if (list.length > 0) {
            if (key == 2 ? isCheckedRemark : true) {
                this.setState({ isLoading: true })
                this.props.updateExhBadgeApproval_action(key == 1 ? true : false, list)
                    .then((data) => {
                        console.log("response data ------->", data)
                        if (data.error != 1) {
                            this.setState({ isLoading: false })
                            toastSuccess("Exhibitor Badge Approval Updated")
                            console.log("success ------->", data)
                            setTimeout(() => {
                                this.getExhBadgesApplications(CompanyName)
                            }, 1000);
                        } else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } else {
                toastError("Please Enter Remark")
            }
        } else {
            toastError("Select Atleast One Application")
        }
    }

    // On View Click
    onViewClick(Id) {
        this.props.navigate('/exhibitorbadgeAU', { state: { is_View: true, exhibitorBadgeFormId: Id } })
    }

    render() {
        const { isLoading, exhBadgeApplicationList } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                <AdminDrawer />
                <div className="main-content with-dr-container com-height">
                    <div className="">
                        <div className="row">
                            <div className="step-name">
                                <p>Exhibitor Badge Approval</p>
                            </div>
                        </div>
                        <div>
                            <div className="row mb-6 m-0">
                                {/* <div className="col-lg-12 mt-2">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label htmlFor="validationCustom03">Login Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                defaultValue="jindal rectifiers"
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="validationCustom03">Badge Quota</label>
                                            <input type="text" className="form-control" />
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="validationCustom03">Balance Quota</label>
                                            <input type="text" className="form-control" />
                                        </div>
                                    </div>
                                </div> */}
                                <div className="table-responsive mt-4">
                                    <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 ">
                                        <thead>
                                            <tr>
                                                <th className="text-center">Sr.No</th>
                                                <th className="text-center" >Select</th>
                                                <th className="text-center">Vaccine</th>
                                                <th>Name</th>
                                                <th className="text-center">Country</th>
                                                <th className="text-center">Status</th>
                                                <th>Remarks</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                exhBadgeApplicationList.length > 0 && exhBadgeApplicationList.map((data, index) => (
                                                    <tr key={data.ExhBadgesFormID}>
                                                        <td className="text-center">{index + 1}</td>
                                                        <td className="text-center">
                                                            <input type="checkbox"
                                                                checked={data.isSelected} value={data.ExhBadgesFormID}
                                                                disabled={data.Status == "Approved" || data.Status == "Disapproved"}
                                                                onChange={(e) => this.onCheckedAppliactions(e)}
                                                            />
                                                        </td>
                                                        <td className="text-center grid-img">
                                                            <a
                                                                href={data['VaccinationCertifcateFilePath']}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <img src={data['VaccinationCertifcateFilePath']} style={{ width: 40 }} />
                                                            </a>
                                                        </td>

                                                        <td>{data.Name}</td>
                                                        <td className="text-center">{data.Country}</td>
                                                        <td className="text-center">{data.Status}</td>
                                                        <td>
                                                            <form>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    style={{ height: 40 }}
                                                                    disabled={data.isReadOnly}
                                                                    value={data.Remarks}
                                                                    onChange={(e) => this.onRemarkEnter(e, data.ExhBadgesFormID)}
                                                                />
                                                            </form>
                                                        </td>
                                                        <td>
                                                            <button
                                                                type="button"
                                                                className="btn btn-outline-primary"
                                                                style={{ padding: "4px 10px" }}
                                                                onClick={(e) => this.onViewClick(data.ExhBadgesFormID)}
                                                            >
                                                                View
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))
                                            }

                                        </tbody>
                                    </table>
                                </div>
                                <div className="main-container mt-2">
                                    <div className="mt-1 mb-4">
                                        <a

                                            className="btn btn-success rounded-0 text-white m-1"
                                            style={{ padding: "6px 25px" }}
                                            type="submit"
                                            onClick={(e) => this.updateExhbadgeApproval(e, 1)}
                                        >
                                            Data Approval
                                        </a>
                                        <a

                                            className="btn btn-dark rounded-0 text-white m-1"
                                            type="submit"
                                            onClick={(e) => this.updateExhbadgeApproval(e, 2)}
                                        >
                                            Data Disapproval
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </>
        )
    }
}
export default withRouter(connect(null, { getExhBadeApplications_action, updateExhBadgeApproval_action })(ExhibitorBadgesApproval))