import { Component, forwardRef } from "react";
import { ToastContainer } from "react-toastify";
import Loader from "../../../../utils/Loader";

import AdminDrawer from "../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../common_components/header_with_logo";
import { withRouter } from "../../../../utils/withRouter";
import { connect } from "react-redux";
import { downloadReport, toastError, toastSuccess, adjustAreaId, adjustAreaFromToId } from "../../../../utils/constants";
import { getAreaManagementList_action, updateAreaManagement_action } from '../../../../actions/admin_module_action/manage_area/area_management_action/area_management_action';
import Pagination from "../../../../utils/pagination";
import { fill_action } from '../../../../actions/fillCombo_action';
import moment from 'moment';
class AreaManagement extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            currentPage: 1,
            areaManagementList: [],
            Area: '',
            AreaErr: '',
            FromErr: '',
            ToErr: '',
            adjustAreaErr: '',
            AdjustAreaId: '',
            fromId: '',
            toId: '',
            adjustAreaList: [],
            adjustAreaFromToList: [],
            Buffer: 0,
            TotalArea: 0,
            Available: 0,
            bufferErr: '',
            availableErr: ''
        }

        this.updateItem = this.updateItem.bind(this)
    }

    // On Component Load
    componentDidMount() {
        this.getFillList(adjustAreaId, '', '', '', '', '');
        this.getFillList(adjustAreaFromToId, '', '', '', '', '');

        this.getAreaManagementList()

    }

    // get fill List dropdown  
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, exhRegistrationID) {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value, exhRegistrationID)
            .then((data) => {
                if (data.error != 1) {
                    // voucher list
                    if (actionID === adjustAreaId) {
                        // console.log("exhibitorList", data.data)
                        this.setState({
                            adjustAreaList: data.data, isLoading: false,
                        })
                    }
                    if (actionID === adjustAreaFromToId) {
                        // console.log("accountTypeList", data.data)
                        this.setState({
                            adjustAreaFromToList: data.data, isLoading: false,
                        })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }


    getAreaManagementList() {
        const { AdjustAreaId } = this.state
        this.setState({ isLoading: true })
        this.props.getAreaManagementList_action(
            AdjustAreaId != '' && AdjustAreaId != 'Select' ? AdjustAreaId : null
        )
            .then((data) => {
                if (data.error != 1) {
                    console.log("data", data)

                    if (data.data != '') {
                        if (data.data[0]['emptyData'] != 'empty') {
                            this.setState({
                                isLoading: false,
                                areaManagementList: data.data
                            })
                        } else {
                            this.setState({
                                isLoading: false,
                                areaManagementList: []
                            })
                        }
                    } else {
                        this.setState({
                            isLoading: false,
                            areaManagementList: data.data
                        })
                    }

                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })

    }

    updateAreaManagement() {
        const { AdjustAreaId, fromId, toId, Area, adjustAreaList, Buffer, TotalArea, Available } = this.state

        if (fromId == 'Buffer') {
            if (Buffer >= parseInt(Area)) {
                var buffCondition = true
            } else {
                var buffCondition = false
                this.setState({ bufferErr: 'adjust are can not be more than Buffer area' })
            }
        } else {
            if (Available >= parseInt(Area)) {
                var availableCondition = true
            } else {
                var availableCondition = false
                this.setState({ availableErr: 'adjust are can not be more than available area' })
            }
        }

        this.setState({ isLoading: true })
        this.props.updateAreaManagement_action(AdjustAreaId, fromId, toId, Area)
            .then((data) => {
                if (data.error != 1) {

                    toastSuccess("Area manage successfully")

                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })

    }

    handleClear() {
        const { areaManagementList } = this.state

        this.setState({
            Area: '',
            AreaErr: '',
            FromErr: '',
            ToErr: '',
            adjustAreaErr: '',
            AdjustAreaId: '',
            fromId: '',
            toId: '',
            Buffer: 0,
            TotalArea: 0,
            Available: 0,
            bufferErr: '',
            availableErr: ''
        })
        setTimeout(() => {
            this.getAreaManagementList()
            this.getFillList(adjustAreaId, '', '', '', '', '');
        }, 100);
    }

    inputValidate() {
        const { AdjustAreaId, fromId, toId, Area } = this.state
        if (AdjustAreaId == "" || AdjustAreaId == null || AdjustAreaId == 'Select') {
            this.setState({ adjustAreaErr: 'Please select adjust area' })
        }
        if (fromId == "" || fromId == null || fromId == 'Select') {
            this.setState({ FromErr: 'Please select from area' })
        }
        if (toId == "" || toId == null || toId == 'Select') {
            this.setState({ ToErr: 'Please select to area' })
        }
        if (Area == "" || Area == null) {
            this.setState({ AreaErr: 'Please enter area' })
        }

    }

    adjustArea() {
        const { AdjustAreaId, fromId, toId, Area, adjustAreaList, Buffer, TotalArea, Available, AreaErr } = this.state
        this.inputValidate()
        console.log("Buffer ------------------", Buffer)
        console.log("Available ------------------", Available)
        setTimeout(() => {
            var buffCondition = false
            var availableCondition = false
            if (Area != '') {
                if (fromId == 'Buffer') {
                    if (Buffer >= parseInt(Area)) {
                        var buffCondition = true
                        this.setState({ bufferErr: '' })
                        this.setState({ availableErr: '' })
                    } else {
                        var buffCondition = false
                        this.setState({ bufferErr: 'Enter Area can not be more than Buffer area' })
                        this.setState({ availableErr: '' })
                    }
                } else if (fromId == 'Available') {
                    if (Available >= parseInt(Area)) {
                        var availableCondition = true
                        this.setState({ bufferErr: '' })
                        this.setState({ availableErr: '' })
                    } else {
                        var availableCondition = false
                        this.setState({ availableErr: 'Enter Area can not be more than available area' })
                        this.setState({ bufferErr: '' })
                    }
                }
            }

            adjustAreaList != '' ?
                adjustAreaList.map((data) => {
                    if (data.ChapterID == AdjustAreaId) {
                        console.log("step ------------------",)
                        const primaryChapterId = data.ChapterID
                        this.setState({ isLoading: true })
                        if (AdjustAreaId != '' && fromId != '' && fromId != 'Select' && toId != '' && toId != 'Select' && Area != '') {
                            if (buffCondition == true || availableCondition == true) {
                                this.props.updateAreaManagement_action(primaryChapterId, fromId, toId, Area)
                                    .then((data) => {
                                        if (data.error != 1) {
                                            this.setState({ isLoading: false })
                                            toastSuccess("Area manage successfully")
                                            this.getAreaManagementList()
                                            this.handleClear()
                                        } else {
                                            this.setState({ isLoading: false })
                                            toastError(data.msg)
                                        }
                                    })
                                    .catch((err) => {
                                        console.log(err)
                                    })
                            } else {
                                this.setState({ isLoading: false })
                            }
                        } else {
                            this.setState({ isLoading: false })
                        }
                    }
                }) : <></>
        }, 100);

    }

    onAdjustAreaFill(e) {

        const { adjustAreaList } = this.state


        if (e.target.value != '' && e.target.value != 'Select') {
            adjustAreaList.map((data) => {
                if (data.ChapterID == e.target.value) {
                    this.setState({
                        Buffer: data.Buffer,
                        TotalArea: data.TotalArea,
                        Available: data.Available,
                    })
                }
            })
            this.setState({ AdjustAreaId: e.target.value, adjustAreaErr: '' })
        } else {
            this.setState({
                Buffer: 0,
                TotalArea: 0,
                Available: 0,
            })
            this.setState({ AdjustAreaId: '', adjustAreaErr: '' })
        }
        setTimeout(() => {
            this.getAreaManagementList()
        }, 100);
    }

    updateItem(item) {
        const { areaManagementList } = this.state
        this.setState({ currentPage: item });
        const recordsPerPage = 10;
        const indexOfLastRecord = item * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = areaManagementList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(areaManagementList.length / recordsPerPage)
    }

    manageAreaInput(e) {

        if (e.target.value.replace(/\D/, '') != '') {
            this.setState({ Area: e.target.value.replace(/\D/, ''), AreaErr: '', bufferErr: '', availableErr: '' })
        } else {
            this.setState({
                Area: ''
            })
        }
    }

    render() {
        const { isLoading, areaManagementList, AdjustAreaId, Area, currentPage, AreaErr, FromErr, ToErr, adjustAreaErr, toId, fromId, adjustAreaList, adjustAreaFromToList, Buffer, TotalArea, Available, bufferErr, availableErr } = this.state

        const recordsPerPage = 10;
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = areaManagementList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(areaManagementList.length / recordsPerPage)

        return (
            <>

                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <AdminDrawer />

                    <div class="main-content">

                        <div class="row mt-1 pt-3 pb-3 p-2 " style={{ alignItems: 'center', width: '100%' }}>
                            <div className="col-md-3">
                                <label>Adjust Area</label>
                                <select
                                    className="form-control"
                                    id="roledb"
                                    // style={{ textAlign: "left" }}
                                    value={AdjustAreaId}
                                    onChange={(e) => this.onAdjustAreaFill(e)}
                                >
                                    <option>Select</option>
                                    {adjustAreaList.length > 0 && adjustAreaList.map((data) => (
                                        <option key={data.ChapterID
                                        } value={data.ChapterID
                                        }>{data.PrimaryChapter}</option>
                                    ))}
                                </select>{<span className='text-danger text-start text-left d-block mt-1'>{adjustAreaErr}</span>}
                            </div>
                            <div class={adjustAreaErr == '' ? "col-md-2 d-flex pt-3" : "col-md-2 d-flex pt-0"}>
                                <span class="btn" style={{ border: '1px solid #fff' }}><strong>Available Area : {Available} m<sup>2</sup></strong></span>
                            </div>
                            <div class={adjustAreaErr == '' ? "col-md-2 d-flex pt-3" : "col-md-2 d-flex pt-0"}>
                                <span class="btn" style={{ border: '1px solid #fff' }}><strong>Buffer Area : {Buffer}  m<sup>2</sup></strong></span>
                            </div>


                        </div>
                        <div class="row p-2 mb-2" >
                            <div className="col-md-3">
                                <label>From <i class="fa fa-arrow-right" ></i> to</label>
                                {/* fa fa-eye */}
                                <select
                                    className="form-control"
                                    id="roledb"
                                    // style={{ textAlign: "left" }}
                                    value={fromId}
                                    onChange={(e) => this.setState({ fromId: e.target.value, FromErr: '' })}
                                >
                                    <option>Select</option>
                                    {adjustAreaFromToList.length > 0 && adjustAreaFromToList.map((data) => (
                                        <option key={data.CODE
                                        } value={data.CODE
                                        }>{data.NAME}</option>
                                    ))}
                                </select>{<span className='text-danger text-start text-left d-block mt-1'>{FromErr}</span>}
                            </div>
                            <div className="col-md-3">
                                <label></label>
                                <select
                                    className="form-control"
                                    id="roledb"
                                    // style={{ textAlign: "left" }}
                                    value={toId}
                                    onChange={(e) => this.setState({ toId: e.target.value, ToErr: '' })}
                                >
                                    <option>Select</option>
                                    {adjustAreaFromToList.length > 0 && adjustAreaFromToList.map((data) => (
                                        <option key={data.CODE
                                        } value={data.CODE
                                        }>{data.NAME}</option>
                                    ))}
                                </select>{<span className='text-danger text-start text-left d-block mt-1'>{ToErr}</span>}
                            </div>
                            <div className="col-md-4">
                                <label>Area</label>
                                <input
                                    value={Area}
                                    onChange={(e) => this.manageAreaInput(e)}
                                    className="form-control" type="text"
                                    maxLength={30}
                                />
                                {<span className='text-danger text-start text-left d-block mt-1'>{AreaErr}</span>}
                                {<span className='text-danger text-start text-left d-block mt-1'>{bufferErr}</span>}
                                {<span className='text-danger text-start text-left d-block mt-1'>{availableErr}</span>}
                            </div>
                            <div className="col-md-2 d-flex p-0" style={{ alignItems: 'center', justifyContent: 'left', marginTop: '20px' }}>
                                <button class="btn rounded-0 btn-dark"
                                    style={{ padding: "4px 13px", marginLeft: "10px", position: 'absolute', top: '4px' }}
                                    onClick={() => this.adjustArea()}
                                >Adjust area</button>
                            </div>

                        </div>

                        <div class="table-responsive mb-5 p-2">
                            <table class="table border text-nowrap text-md-nowrap table-bordered mb-0 ">
                                <thead>
                                    <tr>
                                        <th>User Name</th>
                                        <th>Date</th>
                                        <th>Chapter</th>
                                        <th>From</th>
                                        <th>To</th>
                                        <th>Area</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        currentRecords.length > 0 && currentRecords != null ?
                                            currentRecords.map((data, i) => (
                                                <tr>
                                                    <td>{data.UserName}</td>
                                                    <td>{data.CreatedDate != null ? moment(data.CreatedDate).format('DD-MM-YYYY') : '-'}</td>
                                                    <td>{data.ChapterName != null ? data.ChapterName : '-'}</td>
                                                    <td>{data.FromID}</td>
                                                    <td>{data.ToID}</td>
                                                    <td>{data.Area != null ? data.Area : '-'}</td>

                                                </tr>
                                            )) : (<tr><td colSpan={10} className='text-center'>Record not found</td></tr>)
                                    }

                                </tbody>
                            </table>
                            <div className="mt-2 float-left">
                                {
                                    areaManagementList.length > 0 ?
                                        <Pagination
                                            nPages={nPages}
                                            currentPage={currentPage}
                                            setCurrentPage={this.updateItem}
                                        /> : <></>
                                }
                                <div className="tab-rec " style={{ float: "right" }}>
                                    <p>{`${currentPage} of ${nPages} pages`}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </>

                {/* <Footerwithdr /> */}

            </>
        );
    }


}

export default withRouter(connect(null, { getAreaManagementList_action, updateAreaManagement_action, fill_action })(AreaManagement));