import { combineReducers } from 'redux';
import simpleReducer from './simpleReducer';
import loginReducer from './user_login_auth_reducer.js/login_reducer';
import fillReducer from './fillReducer/fillReducer';
import registrationReducer from './registration_reducer/registration_reducer';
import primaryChapterReducer from './registration_reducer/primary_chapter_reducer';
import excelListDataReducer from './registration_reducer/excelListDataReducer';
import lastSubmitReducer from './registration_reducer/excelListDataReducer';
import receipt_reducer from './admin_module_reducer/registration_receipt_reducers/receipt_reducer';
import selectExhibitionReducer from './admin_module_reducer/exhibition_reducer/select_exhibition_reducer';
import selectApplicationReducer from './exhibitor_module_reducer/select_application/select_application_reducer';
import spaceBookingReducer from './exhibitor_module_reducer/space_booking_reducer/spaceBooking_reducer';
import exhibitionTimelineReducer from './exhibitor_module_reducer/exhibition_Timeline_reducer/exibition_Timeline_reducer';
import exhibitorDashboardReducer from './exhibitor_module_reducer/exhibitor_dashboard_reducer/exhibitor_dashboard_reducer';
import updatePaymentReducer from './exhibitor_module_reducer/account_reducer/update_payment_reducer';
import manualFormReducer from '../../src/reducers/exhibitor_module_reducer/manualForms_reducers/manual_form_reducer'
import fasciaFormReducer from '../reducers/exhibitor_module_reducer/manualForms_reducers/forms_reducers/fascia_form_reducer/fascia_form_reducer'
import exhibitorBardgeReducer from '../../src/reducers/exhibitor_module_reducer/manualForms_reducers/forms_reducers/exhibitor_badge_reducers/exhibitor_badge_reducer'
import visaApplicationReducer from '../../src/reducers/exhibitor_module_reducer/manualForms_reducers/forms_reducers/invitationVisaApplication_reducer/visa_application_reducer'
import exhibitorDetailsReducer from '../../src/reducers/exhibitor_module_reducer/manualForms_reducers/forms_reducers/exhibitor_details_reducer/exhibitor_details_reducer'
import powerConnectionReducer from '../reducers/exhibitor_module_reducer/manualForms_reducers/forms_reducers/power_connection_reducer/power_connection_reducer'
import visitorRegistrationReducer from './visitor_module_reducer/visitor_registration_reducer/visitor_registration_reducer';
import adminAccountReducer from './admin_module_reducer/admin_account_reducer/admin_account_reducer'
import adminDashboardReducer from './admin_module_reducer/admin_dashboard_reducer/admin_dashboard_reducer'
import adminDashboardSummaryReducer from './admin_module_reducer/admin_dashboard_reducer/admin_dashboard_summary_reducer/admin_dashboard_summary_reducer'
import visitorDashboardReducer from './visitor_module_reducer/visitor_dashboard_reducer/visitor_dashboard_reducer'
import agent_reducer from './admin_module_reducer/agent_entry_reducer/agent_entry_reducer'
import agentDashboardReducer from './agent_module_reducer/agent_dashboard_reducer/agent_dashboard_reducer'
import visitorFormReducer from './visitor_module_reducer/visitor_form_reducer/visitor_form_reducer';
import proformaListReducer from './exhibitor_module_reducer/proformaInvList_reducer/proformaInvList_reducer';
import eDirectoryReducer from './exhibitor_module_reducer/manualForms_reducers/forms_reducers/e_directory_form_reducer/eDirectory_form_reducer';
import keywordsAndInformationReducer from './exhibitor_module_reducer/manualForms_reducers/forms_reducers/keyword_information_reducer/keyword_information_reducer';
import visa_approvalReducer from './admin_module_reducer/visa_approval_reducer/visa_approval_reducer';
import visitor_visaApprovalReducer from './admin_module_reducer/visitor_visa_approval_reducers/visitor_visa_approvalReducer';
import reminderEmailReducer from './admin_module_reducer/reminderEmail_reducer/reminderEmailReducer';
import circularReducer from './admin_module_reducer/circular_reducer/circularReducer';
import exhCircularReducer from './exhibitor_module_reducer/exhibitor_circular_reducer/exhCircularReducer';
import exhibitorSpacebookingReducer from './admin_module_reducer/exhibitor_spacebooking_reducer/exhibitor_spacebooking_reducer';
import stallPossessionReducer from './exhibitor_module_reducer/manualForms_reducers/forms_reducers/stall_possession_reducer/stall_possessionReducer';
import fascia_approvalReducer from './admin_module_reducer/admin_manualForm_reducer/fascia_approval_reducer/fascia_approvalReducer';
import exhBadges_approvalReducer from './admin_module_reducer/admin_manualForm_reducer/exhBadges_approval_reducer/exhBadges_Approval_reducer';
import eDirectory_approvalReducer from './admin_module_reducer/admin_manualForm_reducer/eDirectory_approval_reducer/eDirectory_approval_reducer';
import PowerConnectionReducer from '../reducers/exhibitor_module_reducer/manualForms_reducers/forms_reducers/power_connection_reducer/power_connection_reducer';
import exhibitor_manualForm_summaryReducer from './admin_module_reducer/exhibitor_manualForm_summary_reducer/exhibitor_manualForm_summaryReducer';
import power_approvalReducer from './admin_module_reducer/admin_manualForm_reducer/power_approval_reducer/power_approval_reducer';
import visitor_listReducer from './admin_module_reducer/visitor_list_reducer/visitor_listReducer';
import featuresReducer from './admin_module_reducer/features_reducer/features_reducer';
import exhibitionMasterReducer from './admin_module_reducer/master_reducer/exhibition_master_reducer';
import roleMasterReducer from './admin_module_reducer/master_reducer/role_master_reducer';
import reportReducer from './admin_module_reducer/report_reducer/report_reducer';
import exhTaxInvoiceReducer from './exhibitor_module_reducer/exh_tax_invoice_reducer/exh_tax_invoice_reducer';
import timelineMasterReducer from './admin_module_reducer/master_reducer/timeline_master_reducer';
import visitorCircularReducer from './admin_module_reducer/visitorCircular_reducer/visitorCircularReducer'
import adminUserReducer from './admin_module_reducer/user_reducer/user_reducer';


import ledger_reducer from '../reducers/admin_module_reducer/ledger_reducer/ledger_reducer';
import application_status_management_reducer from '../reducers/admin_module_reducer/manage_area_reducer/application_status_management_reducer/application_status_management_reducer';
import area_management_reducer from '../reducers/admin_module_reducer/manage_area_reducer/area_management_reducer/area_management_reducer';

import vendorRegistration_reducer from '../reducers/admin_module_reducer/vendor_reducer/vendorRegistration_reducer/vendorRegistration_reducer';
import vendorAllocation_reducer from '../reducers/admin_module_reducer/vendor_reducer/vendorAllocation_reducer/vendorAllocation_reducer';
import getVendorExhibitionListReducer from './vendor_modul_reducer/vendor_exhibition_list_reducer/vendor_exhibition_list_reducer';
import carPassessReducer from './exhibitor_module_reducer/manualForms_reducers/forms_reducers/carPassessApplication_reducer/carPassessApplication_reducer';
import exhibitorContractorBadgeApllicationReducer from './exhibitor_module_reducer/manualForms_reducers/forms_reducers/exhibitorContractorBadgeApplication_reducer/exhibitorContractorBadgeApplication_reducer';
import liqourLicenseReducer from './exhibitor_module_reducer/manualForms_reducers/forms_reducers/liquorLicense_reducer/liquorLicense_reducer';
import leadRetrievalFormReducer from './exhibitor_module_reducer/manualForms_reducers/forms_reducers/leadRetrievalForm_reducer/leadRetrievalForm_reducer';
import MeetingRoomReducer from './exhibitor_module_reducer/manualForms_reducers/forms_reducers/meetingRoom_reducer/meetingRoom_reducer';
import InternetConnectionReducer from './exhibitor_module_reducer/manualForms_reducers/forms_reducers/internetConnection_reducer/internetConnection_reducer';
import HouseKeepingReducer from './exhibitor_module_reducer/manualForms_reducers/forms_reducers/houseKeeping_reducer/houseKeeping_reducer';
import securityApplicationReducer from './exhibitor_module_reducer/manualForms_reducers/forms_reducers/securityApplcation_reducer/securityApplcation_reducer';
import brandingOpportunityReducer from './exhibitor_module_reducer/manualForms_reducers/forms_reducers/brandingOpportunity_reducer/brandingOpportunity_reducer';
import airConnectionReducer from './exhibitor_module_reducer/manualForms_reducers/forms_reducers/airConnection_reducer/airConnection_reducer';
import rentalAudioAndVideoReducer from './exhibitor_module_reducer/manualForms_reducers/forms_reducers/rentalAudioAndVideo_reducer/rentalAudioAndVideo_reducer';
import airConnectionApprovalReducer from './admin_module_reducer/admin_manualForm_reducer/airConnectionApproval_reducer/airConnectionApproval_reducer';
import waterConnectionApprovalReducer from './admin_module_reducer/admin_manualForm_reducer/waterConnectionApproval_reducer/waterConnectionApproval_reducer';
import meetingRoomApprovalReducer from './admin_module_reducer/admin_manualForm_reducer/meetingRoomApproval_reducer/meetingRoomApproval_reducer';
import brandingOpportunityApprovalReducer from './admin_module_reducer/admin_manualForm_reducer/brandingOpportunityApproval_reducer/brandingOpportunityApproval_reducer';
import cateringServicesReducer from './exhibitor_module_reducer/manualForms_reducers/forms_reducers/cateringServices_reducer/cateringServices_reducer';
import frieghtForwardReducer from './exhibitor_module_reducer/manualForms_reducers/forms_reducers/frightForward_reducer/frightForward_reducer';
import stallDesignReducer from './exhibitor_module_reducer/manualForms_reducers/forms_reducers/stallDesign_reducer/stallDesign_reducer';
import stallDesignApprovalReducer from './admin_module_reducer/admin_manualForm_reducer/stallDesignApproval_reducer/stallDesignApproval_reducer';
import manualFormReportReducer from './admin_module_reducer/report_reducer/manualFormReport_reducer';
import exhNoteReducer from './admin_module_reducer/master_reducer/exh_note';
import emailConfigrationReducer from './admin_module_reducer/master_reducer/emailConfigrationReducer';
import exhFreightForwardiReducer from './admin_module_reducer/master_reducer/exh_freightForward_reducer';
import exhSeminarReducer from './admin_module_reducer/master_reducer/exh_seminar_reducer';
import exhibitorSeminarReducer from './exhibitor_module_reducer/exhibitorSeminar_reducer/exhibitorSeminar_reducer';
import delegateCircularReducer from './admin_module_reducer/delegate_Circular_reducer/delegate_Circular_reducer';
import delegate_InvitationEventReducer from './admin_module_reducer/delegate_master_reducer/delegate_InvitationEvent_reducer';
import delegate_InvitationTypeReducer from './admin_module_reducer/delegate_master_reducer/delegate_InvitationType_reducer';
import exhManualFormPricesReducer from './admin_module_reducer/master_reducer/exh_manualFormPrices_reducer';

export default combineReducers({
    simpleReducer,
    loginReducer,
    fillReducer,
    registrationReducer,
    primaryChapterReducer,
    excelListDataReducer,
    lastSubmitReducer,
    receipt_reducer,
    selectExhibitionReducer,
    selectApplicationReducer,
    spaceBookingReducer,
    exhibitionTimelineReducer,
    adminUserReducer,
    exhibitorDashboardReducer,
    exhibitionMasterReducer,
    reportReducer,
    roleMasterReducer,
    updatePaymentReducer,
    manualFormReducer,
    fasciaFormReducer,
    exhibitorBardgeReducer,
    visaApplicationReducer,
    exhibitorDetailsReducer,
    // powerConnectionReducer,
    visitorRegistrationReducer,
    adminAccountReducer,
    adminDashboardReducer,
    adminDashboardSummaryReducer,
    visitorDashboardReducer,
    agent_reducer,
    agentDashboardReducer,
    visitorFormReducer,
    proformaListReducer,
    eDirectoryReducer,
    keywordsAndInformationReducer,
    visa_approvalReducer,
    visitor_visaApprovalReducer,
    reminderEmailReducer,
    circularReducer,
    exhCircularReducer,
    exhibitorSpacebookingReducer,
    stallPossessionReducer,
    fascia_approvalReducer,
    exhBadges_approvalReducer,
    eDirectory_approvalReducer,
    PowerConnectionReducer,
    exhibitor_manualForm_summaryReducer,
    power_approvalReducer,
    visitor_listReducer,
    featuresReducer,
    PowerConnectionReducer,
    ledger_reducer,
    application_status_management_reducer,
    exhTaxInvoiceReducer,
    timelineMasterReducer,
    area_management_reducer,
    visitorCircularReducer,
    vendorRegistration_reducer,
    vendorAllocation_reducer,
    getVendorExhibitionListReducer,
    carPassessReducer,
    exhibitorContractorBadgeApllicationReducer,
    liqourLicenseReducer,
    leadRetrievalFormReducer,
    MeetingRoomReducer,
    InternetConnectionReducer,
    HouseKeepingReducer,
    securityApplicationReducer,
    brandingOpportunityReducer,
    airConnectionReducer,
    rentalAudioAndVideoReducer,
    airConnectionApprovalReducer,
    waterConnectionApprovalReducer,
    meetingRoomApprovalReducer,
    brandingOpportunityApprovalReducer,
    cateringServicesReducer,
    frieghtForwardReducer,
    stallDesignReducer,
    stallDesignApprovalReducer,
    manualFormReportReducer,
    exhNoteReducer,
    emailConfigrationReducer,
    exhFreightForwardiReducer,
    exhSeminarReducer,
    exhibitorSeminarReducer,
    delegateCircularReducer,
    delegate_InvitationEventReducer,
    delegate_InvitationTypeReducer,
    exhManualFormPricesReducer,
});