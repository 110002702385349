import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import moment from "moment";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { getSecurityApplicationMasterList_action, insertUpdateSecurityApplicationMaster_action, deleteSecurityApplicationMaster_action } from '../../../../../actions/admin_module_action/master_action/manual_form_master_action/security_application_master_action'
import { fill_action } from '../../../../../actions/fillCombo_action'
import { BASE_URL, countryActionId, toastError, toastSuccess, visaIdTypeActionId } from "../../../../../utils/constants";
import { emailValidator, mobileNoValidator } from "../../../../../utils/Validator";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import { data } from "jquery";
import Resizer from "react-image-file-resizer";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";
import Passport_Front_1008_VIS_903747 from "../../../../../files/Passport_Front_1008_VIS_903747.jpeg"

class SecurityApplicationMasterAU extends Component {

    // initialize constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            status: '',
            // fields
            BankName: '',
            BankAddress: '',
            BeneficiaryName: '',
            BeneficiaryAddress: '',
            AccountNumber: '',
            SwiftCode: '',
            IFSCCode: '',
            PAN: '',
            GST: '',
            Name: "",
            Address: "",
            Phone: '',
            Mobile: '',
            Fax: '',
            PrimaryEmail: '',
            SecondaryEmail: '',

            date: '',
            Description: '',
            DayRateINR: '',
            NightRateINR: '',
            DayRateUSD: '',
            NightRateUSD: '',
            // List
            SecurityApplicationContactList: [],
            SecurityApplicationDescriptionList: [],
            BankNameErr: '',
            BeneficiaryNameErr: '',
            BeneficiaryAddressErr: '',
            BankAddressErr: '',
            AccountNumberErr: '',
            SwiftCodeErr: '',
            IFSCCodeErr: '',
            PANErr: '',
            GSTErr: '',
            NameErr: "",
            AddressErr: "",
            MobileErr: '',
            PrimaryEmailErr: '',
            index: 1,
            SecondaryEmailErr: '',
            dateErr: '',
            DescriptionErr: '',
            DayRateINRErr: '',
            NightRateINRErr: '',
        }
        this.OnCancel = this.OnCancel.bind(this)
        this.inputValidateContact = this.inputValidateContact.bind(this)
        this.inputValidateDescription = this.inputValidateDescription.bind(this)
        this.renderSecurityApplicationContactList = this.renderSecurityApplicationContactList.bind(this)
        this.renderSecurityApplicationDescriptionList = this.renderSecurityApplicationDescriptionList.bind(this)
        this.handleAddMoreDescription = this.handleAddMoreDescription.bind(this)
        // this.resizeFile = this.resizeFile.bind(this)
        this.handleAddMoreContact = this.handleAddMoreContact.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.ClearValue = this.ClearValue.bind(this)
        // this.handleUpdate = this.handleUpdate.bind(this)
        // this.getDetails = this.getDetails.bind(this)
        // this.emailValidation = this.emailValidation.bind(this)
        // this.onCrossClick = this.onCrossClick.bind(this)
        // this.mobileValidation = this.mobileValidation.bind(this)
        // this.onDocDelete = this.onDocDelete.bind(this)
        // this.handleDelete = this.handleDelete.bind(this)
    }

    componentDidMount() {
        this.setState({
            status: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.status != " " ? this.props.location.state.status : '',
        })
        this.getSecurityApplicationMasterList()
    }

    // On cancel Click
    OnCancel() {
        this.props.navigate('/SecurityApplicationMasterList')
    }
    getSecurityApplicationMasterList() {
        const { status } = this.state
        this.setState({ isLoading: true })
        this.props.getSecurityApplicationMasterList_action()
            .then((data) => {
                if (data.error != 1) {
                    // if (status == 1) {
                    var contactList = data.data[0]
                    var bankDetailsList = data.data[1]
                    var finalList = []
                    for (var i = 0; i < contactList.length; i++) {
                        for (var j = 0; j < bankDetailsList.length; j++) {
                            if (bankDetailsList[j].SecurityApplicationContactPersonID != null) {
                                if (contactList[i].SecurityApplicationContactPersonID == bankDetailsList[j].SecurityApplicationContactPersonID) {
                                    var mergObj = { ...contactList[i], ...bankDetailsList[j] }
                                    finalList.push(mergObj)
                                }
                            }
                        }
                    }
                    console.log(finalList)
                    this.setState({
                        SecurityApplicationContactList: finalList,
                        SecurityApplicationDescriptionList: data.data[2].length > 0 ? data.data[2] : []
                    })
                    // }
                    this.setState({ isLoading: false })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // Input Fields Validation
    inputValidateContact() {
        const { BankName, BeneficiaryName, BeneficiaryAddress, BankAddress, BankAddressErr, AccountNumber, SwiftCode, IFSCCode, PAN, GST, Name, Address, Phone, Mobile, Fax, PrimaryEmail, SecondaryEmail } = this.state
        if (BankName.trim() == "") {
            this.setState({ BankNameErr: "Please Enter Bank Name" })
        }
        if (BeneficiaryName.trim() == "") {
            this.setState({ BeneficiaryNameErr: "Please Enter Beneficiary Name" })
        }
        if (BeneficiaryAddress.trim() == "") {
            this.setState({ BeneficiaryAddressErr: "Please Enter Beneficiary Address" })
        }
        if (BankAddress.trim() == "") {
            this.setState({ BankAddressErr: "Please Enter Bank Address" })
        }
        if (AccountNumber == "") {
            this.setState({ AccountNumberErr: "Please Enter Account number" })
        }
        if (SwiftCode.trim() == "") {
            this.setState({ SwiftCodeErr: "Please Enter Swift Code" })
        }
        if (IFSCCode == "") {
            this.setState({ IFSCCodeErr: "Please Enter IFSC code" })
        }
        if (PAN.trim() == "") {
            this.setState({ PANErr: "Please Enter PAN number" })
        }
        if (GST == "") {
            this.setState({ GSTErr: "Please Enter GST number" })
        }
        if (Name == "") {
            this.setState({ NameErr: "Please Enter Name" })
        }
        if (Address == "") {
            this.setState({ AddressErr: "Please Enter Address" })
        }
        if (Mobile == "") {
            this.setState({ MobileErr: "Please Enter Mobile No." })
        }
        if (PrimaryEmail == "") {
            this.setState({ PrimaryEmailErr: "Please Enter Primary Email" })
        } else {
            var PEmailerr = (emailValidator(PrimaryEmail) == '') ? false : emailValidator(PrimaryEmail)
            if (PEmailerr) {
                this.setState({ PrimaryEmailErr: "Please Enter Proper Primary Email" })
            }
        }
        if (SecondaryEmail != "" && SecondaryEmail != null) {
            var SEmailerr = (emailValidator(SecondaryEmail) == '') ? false : emailValidator(SecondaryEmail)
            if (SEmailerr) {
                this.setState({ SecondaryEmailErr: "Please Enter Proper Secondary Email" })
            }
        }
    }
    inputValidateDescription() {
        const { date, Description, DayRateINR, NightRateINR, DayRateUSD, NightRateUSD, dateErr, NightRateINRErr, DayRateINRErr, DescriptionErr } = this.state
        if (date == "") {
            this.setState({ dateErr: "Please Choose Date" })
        }
        if (Description.trim() == "") {
            this.setState({ DescriptionErr: "Please Enter Description" })
        }
        if (DayRateINR.trim() == "") {
            this.setState({ DayRateINRErr: "Please Enter Day Rate(INR)" })
        }
        if (NightRateINR.trim() == "") {
            this.setState({ NightRateINRErr: "Please Enter Night Rate(INR)" })
        }
    }

    renderSecurityApplicationDescriptionList() {
        const { SecurityApplicationDescriptionList } = this.state
        return (
            <ul class="list-group " style={{ position: 'relative', zIndex: 1 }}>
                {
                    SecurityApplicationDescriptionList.length > 0 && SecurityApplicationDescriptionList.map((data, index) => (
                        data.isShow &&
                        <li class="list-group-item" key={Math.random()}>
                            <div className="added-more p-3 rounded-2">
                                <h5 style={{ color: "#0056ff" }}>Added Security Description Master {data.index}</h5>
                                <h6 >Description </h6>
                                <div className="row mt-4">
                                    <div className="col-md-3">
                                        <h6>date: {data.date}</h6>
                                    </div>
                                    <div className="col-md-3">
                                        <h6>Description: {data.Description}</h6>
                                    </div>
                                    <div className="col-md-3">
                                        <h6>Day Rate(INR): {data.DayRateINR}</h6>
                                    </div>
                                    <div className="col-md-3">
                                        <h6>Night Rate(INR): {data.NightRateINR}</h6>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <h6>Day Rate(USD): {data.DayRateUSD}</h6>
                                    </div>
                                    <div className="col-md-3">
                                        <h6>Night Rate(USD): {data.NightRateUSD}</h6>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))
                }
            </ul>
        )
    }

    renderSecurityApplicationContactList() {
        const { SecurityApplicationContactList } = this.state
        return (
            <ul class="list-group " style={{ position: 'relative', zIndex: 1 }}>
                {
                    SecurityApplicationContactList.length > 0 && SecurityApplicationContactList.map((data, index) => (
                        data.isShow &&
                        <li class="list-group-item" key={Math.random()}>
                            <div className="added-more p-3 rounded-2">
                                <h5 style={{ color: "#0056ff" }}>Added Security Application Master {data.index}</h5>
                                <h6 >Agency Contact </h6>
                                <div className="row mt-4">
                                    <div className="col-md-3">
                                        <h6>Name: {data.Name}</h6>
                                    </div>
                                    <div className="col-md-3">
                                        <h6>Mobile no.: {data.Mobile}</h6>
                                    </div>
                                    <div className="col-md-6">
                                        <h6>Address: {data.Address}</h6>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <h6>Telephone: {data.Phone}</h6>
                                    </div>
                                    <div className="col-md-3">
                                        <h6>Fax: {data.Fax}</h6>
                                    </div>
                                    <div className="col-md-3">
                                        <h6>Primary Email: {data.PrimaryEmail}</h6>
                                    </div>
                                    <div className="col-md-3">
                                        <h6>Secondary Email: {data.SecondaryEmail}</h6>
                                    </div>
                                </div>
                            </div>
                            <hr className="m-1" />
                            <div className="added-more p-3 rounded-2">
                                <h6>Bank Details </h6>
                                <div className="row mt-4">
                                    <div className="col-md-3">
                                        <h6>Bank Name: {data.BankName}</h6>
                                    </div>
                                    <div className="col-md-6">
                                        <h6>Bank Address: {data.BankAddress}</h6>
                                    </div>
                                    <div className="col-md-3">
                                        <h6>Account Number: {data.AccountNumber}</h6>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <h6>Beneficiary Name: {data.BeneficiaryName}</h6>
                                    </div>
                                    <div className="col-md-6">
                                        <h6>Beneficiary Address: {data.BeneficiaryAddress}</h6>
                                    </div>
                                    <div className="col-md-3">
                                        <h6>Swift Code: {data.SwiftCode}</h6>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <h6>IFSC Code: {data.IFSCCode}</h6>
                                    </div>
                                    <div className="col-md-3">
                                        <h6>PAN Number: {data.PAN}</h6>
                                    </div>
                                    <div className="col-md-3">
                                        <h6>GST Number: {data.GST}</h6>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))
                }
            </ul>
        )
    }

    handleAddMoreContact() {
        const {
            BankName, BeneficiaryName, SecurityApplicationList, SecurityApplicationContactList, BeneficiaryAddress, BankAddress, BankAddressErr, AccountNumber, SwiftCode, IFSCCode, PAN, GST, Name, Address, Phone, Mobile, Fax, PrimaryEmail, SecondaryEmail, BankNameErr, BeneficiaryNameErr, BeneficiaryAddressErr, AccountNumberErr, SwiftCodeErr, IFSCCodeErr, index, PANErr, GSTErr, NameErr, AddressErr, MobileErr, PrimaryEmailErr, SecondaryEmailErr, } = this.state
        this.inputValidateContact()
        if (BankName != '' && BeneficiaryName != '' && BeneficiaryAddress != '' && BankAddress != '' && AccountNumber != '' && SwiftCode != '' && IFSCCode != '' && PAN != '' && GST != '' && Name != '' && Address != "" && Mobile != "" && PrimaryEmail != "") {
            if (NameErr == '' && BankNameErr == '' && BeneficiaryNameErr == '' && BankAddressErr == '' && BeneficiaryAddressErr == '' && AccountNumberErr == '' && SwiftCodeErr == '' &&
                IFSCCodeErr == '' && PANErr == '' && GSTErr == '' && AddressErr == '' && MobileErr == '' && PrimaryEmailErr == '' && SecondaryEmailErr == '') {
                SecurityApplicationContactList.push({
                    'BankName': BankName,
                    'BankAddress': BankAddress,
                    'BeneficiaryName': BeneficiaryName,
                    'BeneficiaryAddress': BeneficiaryAddress,
                    'AccountNumber': AccountNumber,
                    'SwiftCode': SwiftCode,
                    'IFSCCode': IFSCCode,
                    'PAN': PAN,
                    'GST': GST,
                    'Name': Name,
                    'Address': Address,
                    'Phone': Phone,
                    'Mobile': Mobile,
                    'Fax': Fax,
                    'PrimaryEmail': PrimaryEmail,
                    'SecondaryEmail': SecondaryEmail,
                    'isShow': true,
                    'index': index
                })
                setTimeout(() => { this.ClearValue() }, 200);
            }
            else {
                toastError('Please enter proper fields')
            }
        }
        else {
            toastError('Please enter mandatory fields')
        }
        console.log(this.state.SecurityApplicationContactList)
    }

    handleAddMoreDescription() {
        const { date, Description, DayRateINR, NightRateINR, SecurityApplicationDescriptionList, DayRateUSD, NightRateUSD, dateErr, NightRateINRErr, DayRateINRErr, DescriptionErr, index } = this.state
        this.inputValidateContact()
        if (date != '' && Description != '' && DayRateINR != '' && NightRateINR != '') {
            if (dateErr == '' && NightRateINRErr == '' && DayRateINRErr == '' && DescriptionErr == '') {
                SecurityApplicationDescriptionList.push({
                    'date': moment(date).format('DD-MM-YYYY'),
                    'Description': Description,
                    'DayRateINR': DayRateINR,
                    'NightRateINR': NightRateINR,
                    'DayRateUSD': DayRateUSD,
                    'NightRateUSD': NightRateUSD,
                    'isShow': true,
                    'index': index
                })
                setTimeout(() => { this.ClearValue() }, 200);
            }
            else {
                toastError('Please enter proper fields')
            }
        }
        else {
            toastError('Please enter mandatory fields')
        }
        // console.log(this.state.SecurityApplicationContactList)
    }

    handleSave() {
        const { SecurityApplicationContactList, SecurityApplicationDescriptionList } = this.state
        // this.setState({ isLoading: true })
        console.log(SecurityApplicationContactList, SecurityApplicationDescriptionList)
        this.props.insertUpdateSecurityApplicationMaster_action(SecurityApplicationContactList, SecurityApplicationDescriptionList)
            .then((data) => {
                if (data.error != 1) {
                    toastSuccess("Record Added Successfully")
                    setTimeout(() => {
                        this.setState({ isLoading: false })
                        this.props.navigate('/SecurityApplicationMasterList')
                    }, 300);
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    ClearValue() {
        if (this.state.status == 1) {
            this.setState({
                BankName: '',
                BankAddress: '',
                BeneficiaryName: '',
                BeneficiaryAddress: '',
                AccountNumber: '',
                SwiftCode: '',
                IFSCCode: '',
                PAN: '',
                GST: '',
                Name: "",
                Address: "",
                Phone: '',
                Mobile: '',
                Fax: '',
                PrimaryEmail: '',
                SecondaryEmail: '',
                index: this.state.index + 1
            })
        } else if (this.state.status == 2) {
            this.setState({
                date: '',
                Description: '',
                DayRateINR: '',
                NightRateINR: '',
                DayRateUSD: '',
                NightRateUSD: '',
                index: this.state.index + 1
            })
        }
    }

    render() {
        const { isLoading, BankName, BeneficiaryName, SecurityApplicationList, BeneficiaryAddress, BankAddress, BankAddressErr, AccountNumber, SwiftCode, IFSCCode, PAN, GST, Name, Address, Phone, Mobile, Fax, PrimaryEmail, SecondaryEmail, BankNameErr, BeneficiaryNameErr, BeneficiaryAddressErr, AccountNumberErr, SwiftCodeErr, IFSCCodeErr, PANErr, GSTErr, status, NameErr, AddressErr, MobileErr, PrimaryEmailErr, SecondaryEmailErr, date, Description, DayRateINR, NightRateINR, SecurityApplicationDescriptionList, DayRateUSD, NightRateUSD, dateErr, NightRateINRErr, DayRateINRErr, DescriptionErr
        } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <div className="app-content with-dr-container com-height">
                        <AdminDrawer />
                        <div className="row">
                            <div className="step-name">
                                <p>Security Application Master</p>
                            </div>
                        </div>
                        <div className="m-2">
                            {status == 1 && <div className="">

                                <div className="">
                                    <div
                                        className="card-header p-3"
                                        style={{ paddingLeft: "7px !important" }}
                                    >
                                        <h3 className="card-title" style={{ fontSize: 15 }}>
                                            Agency contact
                                        </h3>
                                    </div>
                                    <div className="card-body p-2 mt-1">
                                        <div className="row">
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    Name<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={Name}
                                                    onChange={(e) => this.setState({ Name: e.target.value, NameErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{NameErr}</span>}

                                            </div>
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    Mobile No.<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={Mobile}
                                                    onChange={(e) => this.setState({ Mobile: e.target.value, MobileErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{MobileErr}</span>}

                                            </div>
                                            <div className="col-xl-6">
                                                <label htmlFor="validationCustom03">
                                                    Address<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={Address}
                                                    onChange={(e) => this.setState({ Address: e.target.value, AddressErr: '' })}
                                                />

                                                {<span className='text-danger text-start text-left d-block mt-1'>{AddressErr}</span>}
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    Telephone
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={Phone}
                                                    onChange={(e) => this.setState({ Phone: e.target.value, })}
                                                />
                                            </div>
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    Fax
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={Fax}
                                                    onChange={(e) => this.setState({ Fax: e.target.value, })}
                                                />
                                            </div>
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">Primary Email<span className="text-red">*</span></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={PrimaryEmail}
                                                    onChange={(e) => this.setState({ PrimaryEmail: e.target.value, PrimaryEmailErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{PrimaryEmailErr}</span>}
                                            </div>
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">Secondary Email</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={SecondaryEmail}
                                                    onChange={(e) => this.setState({ SecondaryEmail: e.target.value, SecondaryEmailErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{SecondaryEmailErr}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <div
                                        className="card-header p-3"
                                        style={{ paddingLeft: "7px !important" }}
                                    >
                                        <h3 className="card-title" style={{ fontSize: 15 }}>
                                            Bank Details
                                        </h3>
                                    </div>
                                    <div className="card-body p-2 mt-1">
                                        <div className="row">
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    Bank Name<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={BankName}
                                                    onChange={(e) => this.setState({ BankName: e.target.value, BankNameErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{BankNameErr}</span>}

                                            </div>
                                            <div className="col-xl-6">
                                                <label htmlFor="validationCustom03">
                                                    Bank Address<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={BankAddress}
                                                    onChange={(e) => this.setState({ BankAddress: e.target.value, BankAddressErr: '' })}
                                                />

                                                {<span className='text-danger text-start text-left d-block mt-1'>{BankAddressErr}</span>}
                                            </div>
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    Account Number<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={AccountNumber}
                                                    onChange={(e) => this.setState({ AccountNumber: e.target.value, AccountNumberErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{AccountNumberErr}</span>}
                                            </div>
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    Beneficiary Name<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={BeneficiaryName}
                                                    onChange={(e) => this.setState({ BeneficiaryName: e.target.value, BeneficiaryNameErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{BeneficiaryNameErr}</span>}

                                            </div>
                                            <div className="col-xl-6">
                                                <label htmlFor="validationCustom03">
                                                    Beneficiary Address<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={BeneficiaryAddress}
                                                    onChange={(e) => this.setState({ BeneficiaryAddress: e.target.value, BeneficiaryAddressErr: '' })}
                                                />

                                                {<span className='text-danger text-start text-left d-block mt-1'>{BeneficiaryAddressErr}</span>}
                                            </div>
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    Swift Code<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={SwiftCode}
                                                    onChange={(e) => this.setState({ SwiftCode: e.target.value, SwiftCodeErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{SwiftCodeErr}</span>}

                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">IFSC Code<span className="text-red">*</span></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={IFSCCode}
                                                    onChange={(e) => this.setState({ IFSCCode: e.target.value, IFSCCodeErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{IFSCCodeErr}</span>}
                                            </div>
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">PAN Number<span className="text-red">*</span></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={PAN}
                                                    onChange={(e) => this.setState({ PAN: e.target.value, PANErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{PANErr}</span>}
                                            </div>
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">GST Number<span className="text-red">*</span></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={GST}
                                                    onChange={(e) => this.setState({ GST: e.target.value, GSTErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{GSTErr}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="btn-group mt-2 save-can d-block">
                                    <div className="btn-group mt-2" role="group" aria-label="Basic example">
                                        <button
                                            style={{ marginRight: 10 }}
                                            type="button"
                                            className="btn btn-primary rounded-0 d-block"
                                            // style={{ }}
                                            onClick={() => this.handleAddMoreContact()}
                                        >
                                            {this.state.index == 1 ? 'Add' : 'Add More'}
                                        </button>
                                        {/* <button type="button" class="btn btn-primary rounded-0">Payment Listing Page</button> */}
                                    </div>

                                    <hr />
                                    {this.renderSecurityApplicationContactList()}
                                </div>
                            </div>}
                            {status == 2 && <div className="">

                                <div className="">
                                    <div
                                        className="card-header p-3"
                                        style={{ paddingLeft: "7px !important" }}
                                    >
                                        <h3 className="card-title" style={{ fontSize: 15 }}>
                                            Security Description
                                        </h3>
                                    </div>
                                    <div className="card-body p-2 mt-1">
                                        <div className="row">
                                            <div className="col-xl-3">
                                                <label for="validationCustom03">Date<span className="text-red">*</span></label>
                                                <DatePicker
                                                    style={{ zIndex: '99999' }}
                                                    selected={date}
                                                    dateFormat={"dd/MM/yyyy"}
                                                    className="form-control"
                                                    onChange={(date) => this.setState({ date: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())), dateErr: "" })}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                // placeholderText="Date"
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{dateErr}</span>}
                                            </div>
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    Description<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={Description}
                                                    onChange={(e) => this.setState({ Description: e.target.value, DescriptionErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{DescriptionErr}</span>}

                                            </div>
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    Day Rate(INR)<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={DayRateINR}
                                                    onChange={(e) => this.setState({ DayRateINR: e.target.value, DayRateINRErr: '' })}
                                                />

                                                {<span className='text-danger text-start text-left d-block mt-1'>{DayRateINRErr}</span>}
                                            </div>
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    Night Rate(INR)<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={NightRateINR}
                                                    onChange={(e) => this.setState({ NightRateINR: e.target.value, NightRateINRErr: '' })}
                                                />

                                                {<span className='text-danger text-start text-left d-block mt-1'>{NightRateINRErr}</span>}
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    Day Rate(USD)<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={DayRateUSD}
                                                    onChange={(e) => this.setState({ DayRateUSD: e.target.value, })}
                                                />
                                            </div>
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    Night Rate(USD)<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={NightRateUSD}
                                                    onChange={(e) => this.setState({ NightRateUSD: e.target.value, })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="btn-group mt-2 save-can d-block">
                                    <div className="btn-group mt-2" role="group" aria-label="Basic example">
                                        <button
                                            style={{ marginRight: 10 }}
                                            type="button"
                                            className="btn btn-primary rounded-0 d-block"
                                            // style={{ }}
                                            onClick={() => this.handleAddMoreDescription()}
                                        >
                                            {this.state.index == 1 ? 'Add' : 'Add More'}
                                        </button>
                                        {/* <button type="button" class="btn btn-primary rounded-0">Payment Listing Page</button> */}
                                    </div>

                                    <hr />
                                    {this.renderSecurityApplicationDescriptionList()}
                                </div>
                            </div>}
                            <div className="row">
                                <div className="btn-group mt-2 save-can d-block" role="group" style={{ float: 'right' }} aria-label="Basic example">
                                    <button
                                        type="button"
                                        className="btn btn-success rounded-0"
                                        style={{ marginRight: '10px', width: '100px' }}
                                        onClick={() => this.handleSave()}
                                        disabled={this.state.index == 1}
                                    >
                                        Save
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-warning rounded-0"
                                        style={{ marginRight: '10px', width: '100px' }}
                                        onClick={() => this.OnCancel()}
                                    >
                                        Back
                                    </button>

                                    {/* <button type="button" class="btn btn-primary rounded-0">Payment Listing Page</button> */}
                                </div>
                            </div>


                        </div>
                    </div>
                </>
            </>
        )
    }
}
export default withRouter(connect(null, { getSecurityApplicationMasterList_action, insertUpdateSecurityApplicationMaster_action, deleteSecurityApplicationMaster_action, fill_action })(SecurityApplicationMasterAU))