import { Component } from "react";
import Loader from "../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action } from '../../../../actions/fillCombo_action';
import { cityActionId, stallTypeActionId, stallListActionId, countryActionId, stateActionId, exhibitionActionId, toastError, toastSuccess } from "../../../../utils/constants";
import { emailValidator } from "../../../../utils/Validator";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import Pagination from "../../../../utils/pagination";
import AdminDrawer from "../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../common_components/header_with_logo";
import { useNavigate } from "react-router-dom";
import ExhibitorDrawer from "../../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../../common_components/exhibitor_module_header";
import { data } from "jquery";
import { getCityMasterList_action, deleteCityMaster_action } from "../../../../actions/admin_module_action/master_action/city_master_action";
import Footer from "../../../../common_components/Footer";


class CityMasterList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isOpen: false,
            currentPage: 1,
            countryList: [],
            CityList: [],
            stateList: [],
            deleteID: '',
            countryID: '',
            StateID: '',
            CityName: '',
            Status: ''
        }
        this.getFillList = this.getFillList.bind(this)
        this.record = this.record.bind(this)
        this.clearSearch = this.clearSearch.bind(this)
        this.deleteCityMaster = this.deleteCityMaster.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.onDelete = this.onDelete.bind(this)
        this.getCityMasterList = this.getCityMasterList.bind(this)
        this.updateItem = this.updateItem.bind(this)
    }

    componentDidMount() {
        this.getFillList(countryActionId, '', '', '', '', '').then(() => {
            this.getFillList(stateActionId, '', '', '', '', '').then(() => {
                this.getCityMasterList()
            })
        })
    }

    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        const { } = this.state
        return new Promise((resolve, reject) => {
            this.setState({ isLoading: true })
            this.props
                .fill_action(actionID, exhibitionID, moduleID, keyValue, value)
                .then((data) => {
                    if (data.error != 1) {
                        // country list
                        if (actionID === countryActionId) {
                            console.log("country data", data.data)
                            // var internationalCountryData = data.data.length > 0 && data.data.filter((item) => item.Code != "91")
                            this.setState({
                                countryList: data.data
                            })
                            resolve(true)
                        }
                        else if (actionID === stateActionId) {
                            console.log("country data", data.data)
                            // var internationalCountryData = data.data.length > 0 && data.data.filter((item) => item.Code != "91")
                            this.setState({
                                stateList: data.data
                            })
                            resolve(true)
                        }
                    }
                    this.setState({ isLoading: false })
                })
                .catch((e) => {
                    console.log(e);
                });
        })
    }

    record(list) {
        return (
            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
                <thead>
                    <tr>
                        <th style={{ width: '50px' }}>SR no.</th>
                        <th className="text-center" >City</th>
                        <th className="text-center" >State</th>
                        <th className="text-center" >Country</th>
                        <th style={{ width: '50px' }}></th>
                        <th style={{ width: '50px' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.length > 0 ? <>{
                            list.map((data, i) => (
                                <tr key={Math.random()}>
                                    {/* <td className="text-center">{data.RoleID}</td> */}
                                    {/* <td className="text-center">{moment(data.ExhibitionDateFrom).format('L')}</td>
                                    <td className="text-center">{moment(data.ExhibitionDateTo).format('L')}</td>
                                <td className="text-center">{this.state.countryList.map(id => (parseInt(id.Code) == data.CountryID ? id.Description : null))}</td> */}
                                    <td className="text-center">{i + 1}</td>
                                    <td className="text-center">{data.CityName}</td>
                                    <td className="text-center">{data.StateName}</td>
                                    <td className="text-center">{data.CountryName}</td>
                                    <td className="text-center">
                                        <button
                                            type="button"
                                            className="btn btn-outline-primary btn-sm"
                                            onClick={(e) => this.handleUpdate(data.CityID)}
                                        >
                                            Update
                                        </button>
                                    </td>
                                    <td className="text-center">
                                        <button
                                            type="button"
                                            className="btn btn-outline-danger btn-sm"
                                            onClick={(e) => this.onDelete(data.CityID)}
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </> :
                            <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                    }
                </tbody>
            </table>)
    }

    getCityMasterList() {
        this.setState({ isLoading: true })
        const { countryID, stateName, StateID, Status, CityName } = this.state
        console.log('CountryID', countryID)
        this.props.getCityMasterList_action(countryID, StateID, CityName, Status)
            .then((data) => {
                if (data.error != 1) {
                    console.log(data.data[0])
                    this.setState({ CityList: data.data[0] })
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    clearSearch() {
        const { Stateno, PANNo } = this.state
        this.setState({
            countryID: '',
            StateID: '',
            CityName: '',
            Status: ''
        })
        setTimeout(() => {
            this.getCityMasterList()
        }, 100);
    }

    handleAdd() {
        this.props.navigate('/cityMasterAU', { state: { is_add: true, } })
    }

    handleUpdate(id) {
        this.props.navigate('/cityMasterAU', { state: { is_update: true, CityID: id } })
    }

    deleteCityMaster(id) {
        this.setState({ isLoading: true })
        this.props.deleteCityMaster_action(id)
            .then((data) => {
                if (data.error != 1) {
                    console.log(data.data)
                    this.setState({ isLoading: false })
                    toastSuccess("deleted successfully")
                    setTimeout(() => {
                        this.getCityMasterList()
                    }, 200);
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
                this.setState({ isOpen: false })
            })
            .catch((err) => {
                console.log(err)
            })
    }

    onDelete(i) {
        const { } = this.state
        this.setState({ deleteID: i })
        this.setState({ isOpen: true, })
    }

    updateItem(item) {
        const { CityList } = this.state
        this.setState({ currentPage: item });
        const recordsPerPage = 10;
        const indexOfLastRecord = item * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = CityList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(CityList.length / recordsPerPage)
        this.setState({ startIndex: recordsPerPage * (item - 1) });
    }

    render() {
        const { isOpen, isLoading, currentPage, CityList, stateList, CityName, StateID, stateName, Status, countryList, countryID } = this.state
        const recordsPerPage = 10;
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = CityList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(CityList.length / recordsPerPage)
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />

                <>
                    <div className="app-content with-dr-container com-height">
                        <AdminDrawer />
                        <div className="row">
                            <div className="step-name">
                                <p>City Master List</p>
                            </div>
                        </div>
                        <div className="m-3">
                            <div className="">
                                {
                                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 1 &&
                                    <div className="row ">
                                        <div className="col-md-3">
                                            <label>Country</label>
                                            <select className="form-control"
                                                value={countryID}
                                                onChange={(e) => this.setState({ countryID: e.target.value })}  >
                                                <option value={''}>Select</option>
                                                {
                                                    countryList.length > 0 && countryList.map(data => (
                                                        <option value={data.Code} key={data.Code}>{data.Description}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="col-md-3">
                                            <label>State</label>
                                            <select className="form-control"
                                                value={StateID}
                                                onChange={(e) => this.setState({ StateID: e.target.value })}  >
                                                <option value={''}>Select</option>
                                                {
                                                    stateList.length > 0 && stateList.map(data => (
                                                        <option value={data.Code} key={data.Code}>{data.Description}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="col-md-3">
                                            <label>City</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={CityName}
                                                onChange={(e) => this.setState({ CityName: e.target.value, })}
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <label>Status</label>
                                            <select className="form-control"
                                                value={Status}
                                                onChange={(e) => this.setState({ Status: e.target.value })}  >
                                                <option value={''}>Select</option>
                                                <option value={1}>Active</option>
                                                <option value={0}>Inactive</option>
                                            </select>
                                        </div>
                                    </div>
                                }
                            </div>
                            < div className="row mt-2 mb-1" >
                                <div className="col-md-12 mt-3 mb-1">
                                    {
                                        JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 1 &&
                                        <div
                                            className="btn-group mb-1"
                                            role="group"
                                            aria-label="Basic example"
                                            style={{ float: "right" }}
                                        >
                                            <button type="button" className="btn ad-fil-btn"
                                                onClick={e => this.getCityMasterList()}
                                            >
                                                Search
                                            </button>
                                            <button type="button" className="btn ad-fil-btn"
                                                onClick={e => this.handleAdd()}
                                            >
                                                Add
                                            </button>
                                            <button type="button" className="btn ad-fil-btn"
                                                onClick={e => this.clearSearch()}
                                            >
                                                Clear
                                            </button>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-md-12 pl-0">
                                <div className="table-responsive">
                                    {this.record(currentRecords)}
                                </div>
                                <div className="row">
                                    <div className="col-md-9 pl-0">
                                        <div className="mt-2 float">
                                            {
                                                CityList.length > 0 ?
                                                    <Pagination
                                                        nPages={nPages}
                                                        currentPage={currentPage}
                                                        setCurrentPage={this.updateItem}
                                                    /> : <></>
                                            }

                                        </div>
                                    </div>
                                    <div className="col-md-3 text-right">
                                        <div className="tab-rec mt-3" style={{ float: "right" }}>
                                            <p>{`${currentPage} of ${nPages} pages`}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </>
                <Modal isOpen={isOpen} onRequestClose={() => this.setState({ isOpen: false })}>
                    <div className="modal-dialog" style={{ marginTop: '20%' }}>
                        <div className="modal-content confirm-logout">
                            <div class="modal-header text-right p-2">
                                <h5 class="modal-title modal_title_text text-danger">Do you really want to delete this record?</h5>

                                {/* <button type="button " class="close pop-close" data-dismiss="modal"
                                    onClick={() => this.setState({ isOpen: false })}
                                ><span aria-hidden="true" class="pop-cancel">&times;</span></button> */}
                            </div>
                            {/* <div class="modal-body">
                                <div class="row">
                                </div>
                            </div> */}
                            <div class="modal-footer p-1">
                                <div class="form-group text-right mod-fot">
                                    <button type="button" class="btn btn-del-pop"
                                        onClick={(e) => this.deleteCityMaster(this.state.deleteID)}
                                    >Yes</button>
                                    <button type="button" class="btn btn-can-pop" data-dismiss="modal" style={{ marginLeft: '5px' }}
                                        onClick={() => this.setState({ isOpen: false })}
                                    >No</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
                <Footer />
            </>)
    }

}
export default withRouter(connect(null, { fill_action, getCityMasterList_action, deleteCityMaster_action })(CityMasterList));