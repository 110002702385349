import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { addExhibitorBadge_action, getExhibitorBadgeDetails_action, updateExhibitorBadge_action, deleteExBadgeDoc_action } from '../../../../../actions/exhibitor_module_actions/manual_Forms_actions/forms_actions/exhibitor_badge_action/exhibitor_badge_action'
import { fill_action } from '../../../../../actions/fillCombo_action'
import { countryActionId, toastError, toastSuccess, exhibitorBadgeVisitDaysActionId, cityActionId, stateActionId, ProductCategoryActionId, mainChapterListActionId } from "../../../../../utils/constants";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import { data } from "jquery";
import Resizer from "react-image-file-resizer";
import { all } from "axios";
import Modal from "react-modal";
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";
import { emailValidator, mobileNoValidator } from "../../../../../utils/Validator";
import Select from 'react-select';
import { ThirtyFpsSelect } from "@mui/icons-material";
const customStyles = {
    control: (provided) => ({
        ...provided,
        borderColor: "#c9c9c9", // Blue border color
        boxShadow: "none",
        "&:hover": {
            borderColor: "#80bdff", // Blue border color on hover
        },
    }),
    multiValue: (provided) => ({
        ...provided,
        borderRadius: "14px",
        padding: "4px",
        backgroundColor: "#00BCD4", // Blue background color for selected value
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: "white", // Text color for selected value
        fontSize: "12px",
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        color: "white",
        "&:hover": {
            backgroundColor: "#00BCD4",
            color: "#fff", // Blue border color on hover
        }, // Remove icon color for selected value
    }),
};
class AddExhibitorBadge extends Component {

    // initialize constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isDisplayCountryList: false,
            isAdd: false,
            isUpdate: false,
            exBadgeFormId: "",
            badgeLength: "",
            vaccFileName: "",
            isDelOpen: false,
            isView: false,
            filePath: "",

            // fields
            exhibitorName: "",
            designation: "",
            country: "",
            countryId: "",
            category: "",
            companyName: "",
            email: "",
            mobileNo: "",
            remarks: "",
            daysList: [],
            allDaysList: [],
            vaccineCertificateFile: "",
            vaccineCertificateFileType: "",

            // List
            countryList: [],
            tempCountryList: [],

            // err messages
            exhibitorNameErr: "",
            designationErr: "",
            countryErr: "",
            categoryErr: "",
            companyNameErr: "",
            mobileNoErr: "",
            emailErr: "",
            UnPaid: false,
            DeadlineDate: '',
            StatusID: '', cityName: '',
            stateName: '',
            ExhBadgesProductCategoryList: [],
            stateList: [],
            tempStateList: [],
            cityList: [],
            tempCityList: [],
            isDisplayCityList: false,
            cityNameErr: '',
            stateNameErr: '',
            ProductCategoryList: [],
            primaryChapterList: [],
            isShowVaccination: false
        }
        this.OnCancel = this.OnCancel.bind(this)
        this.inputValidate = this.inputValidate.bind(this)
        this.getFillList = this.getFillList.bind(this)
        this.onCountrySearch = this.onCountrySearch.bind(this)
        this.renderCountryList = this.renderCountryList.bind(this)
        this.onSubmitExhibitorBadge = this.onSubmitExhibitorBadge.bind(this)
        this.onFilePick = this.onFilePick.bind(this)
        this.onPdfSelect = this.onPdfSelect.bind(this)
        this.resizeFile = this.resizeFile.bind(this)
        this.onSelectDates = this.onSelectDates.bind(this)
        this.onSelectAllDates = this.onSelectAllDates.bind(this)
        this.getExhibitorBadgeDetails = this.getExhibitorBadgeDetails.bind(this)
        this.onUpdateExhibitorBadge = this.onUpdateExhibitorBadge.bind(this)
        this.onCrossClick = this.onCrossClick.bind(this)
        this.onDocDelete = this.onDocDelete.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.mobileValidation = this.mobileValidation.bind(this)
        this.emailValidation = this.emailValidation.bind(this)
    }

    componentDidMount() {
        this.getFillList(countryActionId, sessionStorage.getItem('sessionExhibitionID'), "", "", "", "")
        // calling date fill list only in Add mode
        // this.props.location.state != null && this.props.location.state.is_add &&
        this.getFillList(exhibitorBadgeVisitDaysActionId, sessionStorage.getItem('sessionExhibitionID'), "", "", "", "")
        this.getFillList(cityActionId, sessionStorage.getItem('sessionExhibitionID'), "", "", "", "")
        this.getFillList(stateActionId, sessionStorage.getItem('sessionExhibitionID'), "", "", "", "")
        this.getFillList(ProductCategoryActionId, sessionStorage.getItem('sessionExhibitionID'), "", "", "", "")
        setTimeout(() => {
            this.getFillList(mainChapterListActionId, sessionStorage.getItem('sessionExhibitionID'), "", "", "", "")
        }, 200);

        this.setState({
            isAdd: this.props.location.state != null && this.props.location.state.is_add ? this.props.location.state.is_add : false,
            isUpdate: this.props.location.state != null && this.props.location.state.is_update ? this.props.location.state.is_update : false,
            isView: this.props.location.state != "" && this.props.location.state.is_View ? this.props.location.state.is_View : false,
            exBadgeFormId: this.props.location.state != null && this.props.location.state.exhibitorBadgeFormId ? this.props.location.state.exhibitorBadgeFormId : "",
            badgeLength: this.props.location.state != null && this.props.location.state.exBadgeListLength ? this.props.location.state.exBadgeListLength : "",
            UnPaid: this.props.location.state != null && this.props.location.state.UnPaid ? this.props.location.state.UnPaid : false,
            StatusID: this.props.location.state != null && this.props.location.state.StatusID ? this.props.location.state.StatusID : null,
            DeadlineDate: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.Deadline != "" ? new Date(this.props.location.state.Deadline) : '',

        })
        console.log("sessionStorage.getItem-->", JSON.parse(
            sessionStorage.getItem("sessionHeaderData")
        )[0]['CompanyName'])
        // Calling Get Exhibitor Badge Details on Update
        this.props.location.state != null && this.props.location.state.is_add && this.setState({
            companyName: JSON.parse(sessionStorage.getItem("sessionHeaderData"))[0]['CompanyName']
        })
        this.props.location.state != null && (this.props.location.state.is_update || this.props.location.state.is_View) && this.getExhibitorBadgeDetails()
    }

    // Get Exhibitor Badge Details
    getExhibitorBadgeDetails() {

        this.setState({ isLoading: true })
        var exhibitorBadgeFormId = this.props.location.state.exhibitorBadgeFormId
        this.props.getExhibitorBadgeDetails_action(exhibitorBadgeFormId)
            .then((data) => {
                if (data.error != 1) {
                    setTimeout(() => {
                        const { allDaysList, daysList, vaccFileName } = this.state
                        console.log('daysList : ', daysList);
                        var visitDays = data.data[0]['VisitDays'] != null ? data.data[0]['VisitDays'].split(",").map((data) => data.trim()) : []
                        var filePath = data.data[0]['VaccinationCertifcateFilePath']
                        var array = data.data[0]['VaccinationCertifcateFilePath'] != null &&
                            data.data[0]['VaccinationCertifcateFilePath'] != "" && filePath.split("/")
                        const FileName = array[array.length - 1]
                        this.setState({ vaccFileName: FileName })

                        console.log("conditopn ", visitDays, daysList)
                        daysList.length > 0 && daysList.map((element) => {
                            for (let i = 0; i < visitDays.length; i++) {
                                if (visitDays[i] == element.description) {
                                    element.isSelect = true
                                } else {
                                    if (visitDays[i] == "All Days") {
                                        allDaysList.length > 0 && allDaysList.map((item) => {
                                            item.isSelect = true
                                        })
                                        element.isReadOnly = true
                                    }
                                }
                            }
                        })
                        this.setState({ daysList: daysList, allDaysList: allDaysList })
                    }, 200);

                    setTimeout(() => {
                        if (this.state.ProductCategoryList != null && this.state.ProductCategoryList != '') {
                            const getCategoryIdArray = []
                            const newCategoryArray = []
                            getCategoryIdArray.push(data.data[0]['ChapterIDs'].split(','))
                            getCategoryIdArray[0].map(data => {
                                for (let i = 0; i < this.state.ProductCategoryList.length; i++) {
                                    if (data == this.state.ProductCategoryList[i]['value']) {
                                        newCategoryArray.push({
                                            'label': this.state.ProductCategoryList[i]['label'],
                                            'value': this.state.ProductCategoryList[i]['value']
                                        })
                                    }
                                }

                            })
                            this.setState({ category: newCategoryArray })
                        }
                    }, 200);

                    console.log("getExhibitorBadgeDetails---------->", data.data)
                    this.setState({
                        isLoading: false,
                        exhibitorName: data.data[0]['Name'] != null ? data.data[0]['Name'] : '',
                        designation: data.data[0]['Designation'] != null ? data.data[0]['Designation'] : '',
                        country: data.data[0]['CountryName'] != null ? data.data[0]['CountryName'] : '',
                        countryId: data.data[0]['CountryID'] != null ? data.data[0]['CountryID'] : '',
                        companyName: data.data[0]['CompanyName'] != null ? data.data[0]['CompanyName'] : '',
                        remarks: data.data[0]['Remarks'] != null ? data.data[0]['Remarks'] : '',
                        companyName: data.data[0]['CompanyName'] != null ? data.data[0]['CompanyName'] : '',
                        mobileNo: data.data[0]['MobileNo'] != null ? data.data[0]['MobileNo'] : '',
                        email: data.data[0]['Email'] != null ? data.data[0]['Email'] : '',
                        cityName: data.data[0]['City'] != null ? data.data[0]['City'] : '',
                        stateName: data.data[0]['State'] != null ? data.data[0]['State'] : '',
                        filePath: data.data[0]['VaccinationCertifcateFilePath'] != null ? data.data[0]['VaccinationCertifcateFilePath'] : ''
                    })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // get Fill List
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props.fill_action(actionID, exhibitionID, moduleID, keyValue, value)
            .then((data) => {
                if (data.error != 1) {
                    if (actionID == ProductCategoryActionId) {
                        this.setState({ isLoading: false, primaryChapterList: data.data, isShowVaccination: data.data[0]['isShowVaccination'] });
                    }

                    if (actionID == mainChapterListActionId) {
                        var tempCityArr = []
                        for (var i = 0; i < data.data.length; i++) {
                            var cityObj = {}
                            cityObj.value = data.data[i].ChapterID
                            cityObj.label = data.data[i].ChapterName
                            tempCityArr.push(cityObj)
                        }
                        const list = []
                        if (this.state.primaryChapterList != '') {
                            for (let i = 0; i < this.state.primaryChapterList.length; i++) {
                                for (let j = 0; j < tempCityArr.length; j++) {
                                    if (this.state.primaryChapterList[i]['PrimaryChapter'] == tempCityArr[j]['value']) {
                                        list.push(tempCityArr[j])
                                    }
                                }
                            }
                        }
                        if (this.props.location.state != null && this.props.location.state.is_add ? this.props.location.state.is_add : false) {
                            this.setState({ category: list })
                        }
                        this.setState({ ProductCategoryList: tempCityArr, isLoading: false, });
                    }
                    // Country List
                    if (actionID === countryActionId) {
                        console.log("country data", data.data)
                        this.setState({
                            countryList: data.data,
                            tempCountryList: data.data,
                            isLoading: false
                        })
                    }
                    if (actionID === exhibitorBadgeVisitDaysActionId) {
                        console.log("exhibitor data", data.data)
                    
                        var allDaysLists= [{ Code: 'all', description: "All Days", isSelect: this.props.location.state != null && (this.props.location.state.is_update || this.props.location.state.is_View) ? false : true, isReadOnly: false }]
                        var DateList = []
                        data.data.map((item) => {
                            var mapData = {
                                "Code": item['DateValue'],
                                "description": item['DateValue'],
                                "isSelect": false,
                                "isReadOnly": this.props.location.state != null && (this.props.location.state.is_update || this.props.location.state.is_View) ? false : true
                            }
                            DateList.push(mapData)
                        })
                        this.setState({
                            allDaysList : allDaysLists,
                            daysList: DateList,
                            isLoading: false
                        })
                    }

                    // state list
                    if (actionID === stateActionId) {
                        this.setState({
                            stateList: data.data,
                            tempStateList: data.data,
                            isLoading: false,
                        });
                    }

                    // city list
                    if (actionID === cityActionId) {
                        this.setState({
                            cityList: data.data,
                            tempCityList: data.data,
                            isLoading: false,
                        });
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }


    // render city
    renderCityList() {
        const { cityList, cityName, tempCityList } = this.state;
        return (
            <ul
                class="list-group pincode-drop"
                style={{ position: "absolute", zIndex: 1 }}
            >
                {tempCityList.length > 0 &&
                    tempCityList.map((data) => (
                        <li
                            class="list-group-item"
                            key={Math.random()}
                            onClick={(e) =>
                                this.setState({
                                    cityName: data.Description,
                                    isDisplayCityList: false,
                                })
                            }
                        >
                            {data.Description}
                        </li>
                    ))}
            </ul>
        );
    }
    // searcg city
    onCitySearch(e) {
        const { cityList, cityName, tempCityList, countryId } = this.state;
        if (countryId == 91) {
            if (e.target.value != "") {
                var searchCityList = tempCityList.filter((data) => {
                    return data.Description.toLowerCase().includes(
                        e.target.value.toLowerCase()
                    );
                });
                this.setState({
                    cityName: e.target.value,
                    cityNameErr: "",
                    tempCityList: searchCityList,
                    isDisplayCityList: true,
                });
                // call state fill list pass city id
                //this.getFillList(stateActionId,"","",)
            } else {
                this.setState({
                    cityName: e.target.value,
                    tempCityList: cityList,
                    isDisplayCityList: false,
                });
            }
        } else {
            this.setState({
                cityName: e.target.value,
                tempCityList: cityList,
                isDisplayCityList: false,
            });
        }

    }
    // state list
    callStateList(value, id) {
        const { stateList, stateName, tempStateList } = this.state;
        this.setState({ stateName: value, stateId: id, isDisplayStateList: false });
        this.getFillList(stateActionId, "", "", "", stateName, "", "");
    }

    // render state
    renderStateList() {
        const { stateList, stateName, tempStateList } = this.state;
        return (
            <ul
                class="list-group pincode-drop"
                style={{ position: "absolute", zIndex: 1 }}
            >
                {tempStateList.length > 0 &&
                    tempStateList.map((data) => (
                        <li
                            class="list-group-item"
                            key={Math.random()}
                            onClick={(e) => this.callStateList(data.Description, data.Code)}
                        >
                            {data.Description}
                        </li>
                    ))}
            </ul>
        );
    }
    // search state
    onStateSearch(e) {
        const { stateList, stateName, tempStateList, countryId } = this.state;
        console.log("onStateSearch");
        if (countryId == 91) {
            if (e.target.value != "") {
                var searchStateList = tempStateList.filter((data) => {
                    return data.Description.toLowerCase().includes(
                        e.target.value.toLowerCase()
                    );
                });
                this.setState({
                    stateName: e.target.value,
                    stateNameErr: "",
                    tempStateList: searchStateList,
                    isDisplayStateList: true,
                });
                // call pincode fill list pass city id and state id
                // this.getFillList(pincodeActionId, '', '', '', stateName, '')
            } else {
                this.setState({
                    stateName: e.target.value,
                    tempStateList: stateList,
                    isDisplayStateList: false,
                });
            }
        } else {
            this.setState({
                stateName: e.target.value,
                tempStateList: stateList,
                isDisplayStateList: false,
            });
        }

    }

    // On cancel Click
    OnCancel() {
        // this.props.navigate('/exhibitorbadge')
        const { DeadlineDate, StatusID } = this.state
        this.props.navigate('/exhibitorbadge', { state: { Deadline: DeadlineDate, StatusID: StatusID } })
    }

    // Input Fields Validation
    inputValidate() {
        const { exhibitorName, designation, country, category, companyName, countryId, cityName, stateName } = this.state
        if (exhibitorName == "") {
            this.setState({ exhibitorNameErr: "Please Enter Exhibitor Name" })
        }
        if (designation == "") {
            this.setState({ designationErr: "Please Enter Designation" })
        }
        if (countryId == "" || countryId == null || countryId == 'Select') {
            this.setState({ countryErr: "Please Select Country" })
        }
        if (category == "") {
            this.setState({ categoryErr: "Please Enter Category" })
        }
        if (companyName == "") {
            this.setState({ companyNameErr: "Please Enter Company Name" })
        }
        if (cityName == "" || cityName == null) {
            this.setState({ cityNameErr: "Please Enter City" })
        }
        if (countryId == 91) {
            if (stateName == "" || stateName == null) {
                this.setState({ stateNameErr: "Please Enter State" })
            }
        }
    }

    // Search Country
    onCountrySearch(e) {
        const { countryList, tempCountryList } = this.state
        this.setState({ country: '' })
        if (e.target.value != '') {

            var searchCoutryList = tempCountryList.filter(data => {
                return (
                    data
                        .Description
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })

            this.setState({
                country: e.target.value,
                countryErr: '',
                tempCountryList: searchCoutryList,
                isDisplayCountryList: true
            })
        }
        else {
            this.setState({
                country: e.target.value,
                tempCountryList: countryList,
                isDisplayCountryList: false
            })
        }
    }

    // render Country
    renderCountryList() {
        const { tempCountryList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempCountryList.length > 0 && tempCountryList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({
                                country: data.Description,
                                countryId: data.Code,
                                isDisplayCountryList: false
                            })}
                        >{data.Description}</li>
                    ))
                }
            </ul>
        )
    }

    // Submit Exhibitor Badge
    onSubmitExhibitorBadge() {
        const { exhibitorName, designation, country, category, companyName, exhibitorNameErr, designationErr, countryErr, categoryErr,
            companyNameErr, remarks, vaccineCertificateFile, countryId, daysList, allDaysList, vaccineCertificateFileType, badgeLength,
            email, emailErr, mobileNo, mobileNoErr, UnPaid, DeadlineDate, stateNameErr, cityNameErr, cityName, stateName,
        } = this.state
        // input validation 
        this.inputValidate()
        var allDays = [...allDaysList, ...daysList]

        const selectedDays = allDays.filter(data => data.isSelect == true)

        // preparing Selected Days for udt
        console.log("Selected Days------------>", selectedDays)
        var list = []
        selectedDays.map((data) => {
            var data = {
                "visitDay": data.description
            }
            list.push(data)
        })
        if (exhibitorName != "" && designation != "" && countryId != "" && category.length > 0 && category != '' && companyName != "" && cityName != '' && cityName != null && (countryId == 91 ? stateName != '' && stateName != null : true)) {
            if (exhibitorNameErr == "" && designationErr == "" && countryErr == "" && categoryErr == "" && companyNameErr == "" && emailErr == "" &&
                mobileNoErr == "" && (sessionStorage.getItem("CurrencyID") == 1 ? stateNameErr == '' : true) && cityNameErr == '') {
                if (selectedDays.length > 0) {
                    this.setState({ isLoading: true })
                    this.props.addExhibitorBadge_action(exhibitorName, designation, countryId, companyName, remarks, vaccineCertificateFile, list, vaccineCertificateFileType, badgeLength, mobileNo, email, UnPaid ? 0 : 1, cityName, stateName, category)
                        .then((data) => {
                            if (data.error != 1) {
                                console.log("Add Exhibitor -------->", data.data)
                                toastSuccess("Exhibitor Badge Added Successfully")
                                setTimeout(() => {
                                    this.setState({ isLoading: false })
                                    this.OnCancel()
                                }, 1000);
                            }
                            else {
                                this.setState({ isLoading: false })
                                toastError(data.msg)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                } else {
                    toastError("Please Select Date")
                }
            }
            else {
                toastError("Please Enter Mandatory Fields")
            }
        }
        else {
            toastError("Please Enter Mandatory Fields")
        }
    }

    // Update Exhibitor Badge
    onUpdateExhibitorBadge() {
        const { allDaysList, daysList, exhibitorName, designation, country, category, companyName, exhibitorNameErr, designationErr,
            countryErr, companyNameErr, categoryErr, countryId, remarks, vaccineCertificateFile, vaccineCertificateFileType,
            exBadgeFormId, email, emailErr, mobileNo, mobileNoErr, UnPaid, cityName, stateName, } = this.state
        // Input Validation
        this.inputValidate()

        var allDays = [...allDaysList, ...daysList]
        const selectedDays = allDays.filter(data => data.isSelect == true)
        // preparing Selected Days for udt
        var list = []
        selectedDays.map((data) => {
            var data = {
                "visitDay": data.description
            }
            list.push(data)
        })
        console.log("list------------>", list)
        if (exhibitorName != "" && designation != "" && countryId != "" && category.length > 0 && category != '' && companyName != "" && cityName != '' && cityName != null && (countryId == 91 ? stateName != '' && stateName != null : true)) {
            if (exhibitorNameErr == "" && designationErr == "" && countryErr == "" && categoryErr == "" && companyNameErr == "" && mobileNoErr == "" && emailErr == "") {
                if (selectedDays.length > 0) {
                    this.setState({ isLoading: true })
                    this.props.updateExhibitorBadge_action(exBadgeFormId, exhibitorName, designation, countryId, companyName, remarks,
                        vaccineCertificateFile, list, vaccineCertificateFileType, mobileNo, email, UnPaid ? 0 : 1, cityName, stateName, category)
                        .then((data) => {
                            if (data.error != 1) {
                                console.log("updateExhibitorBadge----------->", data.data)
                                toastSuccess("Exhibitor Badge Updated Successfully")
                                setTimeout(() => {
                                    this.setState({ isLoading: false })
                                    this.OnCancel()
                                }, 1000);
                            }
                            else {
                                this.setState({ isLoading: false })
                                toastError(data.msg)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                }
                else {
                    toastError("Please Select Date")
                }
            }
            else {
                toastError("Please Enter Mandatory Fields")
            }
        }
        else {
            toastError("Please Enter Mandatory Fields")
        }
    }

    onFilePick = async (e, file) => {
        e.preventDefault()
        console.log("File01010101------", file)
        const { } = this.state
        if (file[0]['size'] >= 5 * 1024 * 1024) {
            toastError('File size more than 5mb are not allow.')
        } else {
            var image = '';
            if (file[0]['name'].toString().toLowerCase().includes('.pdf') ||
                file[0]['name'].toString().toLowerCase().includes('.jpeg') ||
                file[0]['name'].toString().toLowerCase().includes('.png') ||
                file[0]['name'].toString().toLowerCase().includes('.jpg')
            ) {

                if (file[0]['name'].toString().toLowerCase().trim().includes('.jpg') ||
                    file[0]['name'].toString().toLowerCase().trim().includes('.png') ||
                    file[0]['name'].toString().toLowerCase().trim().includes('.jpeg')
                ) {
                    image = await this.resizeFile(file[0]);
                    this.setState({ vaccineCertificateFile: image })
                }
                else if (file[0]['name'].toString().toLowerCase().trim().includes('.pdf')) {
                    image = await this.onPdfSelect(file[0]);
                    this.setState({ vaccineCertificateFile: image })
                }

                var fileType = file[0]['type']
                var fileTypeList;
                if (fileType.includes("/")) {
                    fileTypeList = fileType.split("/")

                }
                this.setState({ vaccineCertificateFileType: fileTypeList.length > 0 ? fileTypeList[1] : "" })
            } else {
                toastError('Only .pdf , .jpeg, .png, .jpg files are allowed')
            }
        }
    }

    onPdfSelect = (file) =>
        new Promise((resolve) => {
            let base64;
            // FileReader function for read the file.
            let fileReader = new FileReader();
            // Onload of file read the file content
            fileReader.onload = function (fileLoadedEvent) {
                base64 = fileLoadedEvent.target.result;
                // Print data in console
                resolve(base64);
            };
            // Convert data to base64
            fileReader.readAsDataURL(file);
        });

    resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                500,
                600,
                'JPEG',
                80,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64",
                300,
                300,
            );
        });

    // On Select Days
    onSelectDates(e) {
        const { allDaysList, daysList } = this.state
        console.log("daysList", e.target.value, e.target.checked, daysList)
        var code = e.target.value
        var isThreelDatesChecked = false
        daysList.length > 0 && daysList.map((data) => {
            if (data.Code == code) {
                data.isSelect = true
            }

        })
        var allDaySelected = daysList.every(data => data.isSelect == true)
        console.log("09090909", allDaySelected)
        if (allDaySelected) {
            isThreelDatesChecked = true
        }
        else {
            isThreelDatesChecked = false
        }

        if (isThreelDatesChecked) {
            allDaysList.length > 0 && allDaysList.map((data) => {
                data.isSelect = true
            })
            daysList.map((item) => {
                item.isSelect = false
                item.isReadOnly = true
            })
        }
        console.log("allDaysList", allDaysList)

        this.setState({ allDaysList: allDaysList, daysList: daysList })

    }

    // On Select all Days
    onSelectAllDates(e) {
        const { allDaysList, daysList } = this.state
        console.log("daysList", e.target.value, e.target.checked, daysList)
        allDaysList.length > 0 && allDaysList.map((data) => {
            if (e.target.checked) {
                data.isSelect = true
                daysList.map((item) => {
                    item.isSelect = false
                    item.isReadOnly = true
                })
            }
            else {
                data.isSelect = false
                daysList.map((item) => {
                    // item.isSelect = !item.isSelect
                    item.isReadOnly = false
                    // console.log("item", item)
                })
            }
        })
        console.log("allDaysList", allDaysList)

        this.setState({ allDaysList: allDaysList, daysList: daysList })
    }

    // on Cross click
    onCrossClick() {
        this.setState({ vaccineCertificateFile: "" })
        document.getElementById("vaccFile").value = ""
    }

    // On Document Delete Modal Toggle
    onDocDelete() {
        const { isDelOpen } = this.state
        this.setState({ isDelOpen: !isDelOpen })
    }

    // Delete Exhibitor BAdge Document Delete
    handleDelete(e) {
        e.preventDefault()
        const { isDelOpen, exBadgeFormId, vaccFileName } = this.state
        this.setState({ isLoading: true })
        this.props.deleteExBadgeDoc_action(exBadgeFormId)
            .then((data) => {
                if (data.error != 1) {
                    toastSuccess("Exhibitor Badge Document Deleted SuccessFully")
                    this.setState({ isLoading: false, isDelOpen: !isDelOpen, vaccFileName: "" })
                } else {
                    toastError(data.msg)
                    this.setState({ isLoading: false })
                }
            }).catch((error) => {
                console.log(error)
            })
    }

    // Mobile No Validation
    mobileValidation(e) {
        const { countryId } = this.state
        var mobileError
        if (countryId == 91) {
            mobileError = mobileNoValidator(e.target.value)
        } else {
            mobileError = ""
        }
        this.setState({ mobileNo: e.target.value })
        if (mobileError != '') {
            this.setState({ mobileNoErr: mobileError })
        }
        else {
            this.setState({ mobileNoErr: '' })
        }
    }

    // Email Validation
    emailValidation(e) {
        var emailError = emailValidator(e.target.value)
        this.setState({ email: e.target.value })
        if (emailError != '') {
            this.setState({ emailErr: emailError })
        }
        else {
            this.setState({ emailErr: '' })
        }
    }

    render() {
        const { isLoading, exhibitorName, designation, country, category, companyName, remarks, exhibitorNameErr, designationErr,
            countryErr, categoryErr, companyNameErr, isDisplayCountryList, daysList, allDaysList, isAdd, isUpdate, vaccineCertificateFile,
            vaccFileName, isDelOpen, isView, filePath, email, mobileNo, mobileNoErr, emailErr, countryId, UnPaid, isDisplayCityList, cityName, stateName, isDisplayStateList, countryList, cityNameErr, stateNameErr, ProductCategoryList, isShowVaccination } = this.state

        const toInputUppercase = e => {
            e.target.value = ("" + e.target.value).toUpperCase();
        };

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer></ToastContainer>
                {
                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                        <ExhibitorHeader /> : <HeaderWithLogo />
                }
                {
                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                        <ExhibitorDrawer /> : <AdminDrawer />
                }
                <div className="page">
                    <div className="page-main">
                        <div className={JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ? "main-content with-dr-container" : "main-content with-dr-container com-height"}>
                            <div className=" width-dr-nav">
                                <div className="row">
                                    {
                                        JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                                            <ExhibitorTopMenu /> : null
                                    }
                                </div>
                            </div>
                            <div className="">
                                <div className="col-xl-12 p-0">
                                    <div className="card rounded-0 pt-1">
                                        <div className="card-header p-3">
                                            <h3 className="card-title"> Exhibitor {UnPaid ? 'UnPaid' : 'Paid'} Badge </h3>
                                        </div>
                                        <div className="card-body p-2">
                                            {/* {!UnPaid ?
                                                <div className="row">
                                                    <div className="col-xl-4">
                                                        <label htmlFor="validationCustom03">Price</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            maxLength={'100'}
                                                            placeholder="Enter Exhibitor Name"
                                                            disabled={true}
                                                            value={3000}
                                                            onInput={toInputUppercase}
                                                            onChange={(e) => this.setState({ exhibitorName: e.target.value, exhibitorNameErr: "" })}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{exhibitorNameErr}</span>}
                                                    </div>
                                                </div>
                                                : <></>} */}
                                            <div className="row">
                                                <div className="col-xl-8">
                                                    <label htmlFor="validationCustom03">Exhibitor Name
                                                        <span className="text-red">*</span></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom03"
                                                        maxLength={'100'}
                                                        placeholder="Enter Exhibitor Name"
                                                        disabled={isView}
                                                        value={exhibitorName}
                                                        onInput={toInputUppercase}
                                                        onChange={(e) => this.setState({ exhibitorName: e.target.value, exhibitorNameErr: "" })}
                                                    />
                                                    {<span className='text-danger text-start text-left d-block mt-1'>{exhibitorNameErr}</span>}
                                                </div>
                                                <div className="col-xl-4">
                                                    <label htmlFor="validationCustom03">Designation
                                                        <span className="text-red">*</span></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom03"
                                                        maxLength={'50'}
                                                        placeholder="Enter Designation"
                                                        disabled={isView}
                                                        value={designation}
                                                        onInput={toInputUppercase}
                                                        onChange={(e) => this.setState({ designation: e.target.value, designationErr: "" })}
                                                    />
                                                    {<span className='text-danger text-start text-left d-block mt-1'>{designationErr}</span>}
                                                </div>
                                                <div className="col-xl-4 mt-3">
                                                    <label>Country<span className="text-red">*</span></label>
                                                    <select
                                                        className="form-control"
                                                        id="roledb"
                                                        // style={{ textAlign: "left" }}
                                                        value={countryId}
                                                        onChange={(e) => this.setState({ countryId: e.target.value, cityName: '', stateName: '', isDisplayCityList: false, isDisplayStateList: false })}
                                                    >
                                                        <option>Select</option>
                                                        {countryList.length > 0 && countryList.map((data) => (
                                                            <option key={data.Code
                                                            } value={data.Code
                                                            }>{data.Description}</option>
                                                        ))}
                                                    </select>
                                                    {<span className='text-danger text-start text-left d-block mt-1'>{countryErr}</span>}
                                                </div>
                                                <div className="col-xl-4 mt-3">
                                                    <label htmlFor="validationCustom01">
                                                        City<span className="text-red">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        required=""
                                                        value={cityName}
                                                        onChange={(e) => this.onCitySearch(e)}
                                                    />
                                                    {isDisplayCityList && this.renderCityList()}
                                                    {
                                                        <span className="text-danger text-start text-left d-block mt-1">
                                                            {cityNameErr}
                                                        </span>
                                                    }
                                                </div>
                                                <div className="col-xl-4 mt-3">
                                                    <label htmlFor="validationCustom01">
                                                        {countryId == 91
                                                            ? "State"
                                                            : "State/Province"}
                                                        {countryId == 91 ? (
                                                            <span className="text-red">*</span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        required=""
                                                        value={stateName}
                                                        onChange={(e) => this.onStateSearch(e)}
                                                    />
                                                    {isDisplayStateList && this.renderStateList()}
                                                    {
                                                        <span className="text-danger text-start text-left d-block mt-1">
                                                            {stateNameErr}
                                                        </span>
                                                    }
                                                </div>
                                                <div className="col-xl-4 mt-3">
                                                    <label htmlFor="validationCustom03">Company Name
                                                        <span className="text-red">*</span></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom03"
                                                        maxLength={'200'}
                                                        placeholder="Enter Company Name"
                                                        disabled={isView}
                                                        value={companyName}
                                                        onInput={toInputUppercase}
                                                        onChange={(e) => this.setState({ companyName: e.target.value, companyNameErr: "" })}
                                                    />
                                                    {<span className='text-danger text-start text-left d-block mt-1'>{companyNameErr}</span>}
                                                </div>
                                                <div className="col-xl-4 mt-3">
                                                    <label htmlFor="validationCustom03">Email
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom03"
                                                        maxLength={'200'}
                                                        placeholder="Enter Email"
                                                        disabled={isView}
                                                        value={email}
                                                        onChange={(e) => this.emailValidation(e)}
                                                    // onChange={(e) => this.setState({ email: e.target.value })}
                                                    />
                                                    {<span className='text-danger text-start text-left d-block mt-1'>{emailErr}</span>}
                                                </div>
                                                <div className="col-xl-4 mt-3">
                                                    <label htmlFor="validationCustom03">Mobile No
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom03"
                                                        maxLength={countryId == 91 ? 13 : 30}
                                                        disabled={isView}
                                                        value={mobileNo}
                                                        onChange={(e) => this.mobileValidation(e)}
                                                    />
                                                    {<span className='text-danger text-start text-left d-block mt-1'>{mobileNoErr}</span>}
                                                </div>
                                                <div className="col-xl-8 mt-3">
                                                    <label>Category
                                                        <span class="text-red">*</span>
                                                    </label>
                                                    <Select
                                                        isMulti
                                                        // isDisabled={statusId == '0'}
                                                        // isClearable={statusId != '0'}
                                                        // styles={statusId == '0' ? deletedStyles : customStyles}
                                                        styles={customStyles}
                                                        value={category}
                                                        onChange={e => this.setState({ category: e })}
                                                        options={ProductCategoryList} />
                                                    {<span className='text-danger text-start text-left d-block mt-1'>{categoryErr}</span>}
                                                </div>
                                                <div className="col-xl-12 mt-3">
                                                    <label htmlFor="validationCustom03">Remarks</label>
                                                    <textarea
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom03"
                                                        maxLength={'500'}
                                                        value={remarks}
                                                        disabled={isView}
                                                        onInput={toInputUppercase}
                                                        onChange={(e) => this.setState({ remarks: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <h6 className="mt-2">Select Days
                                                    <span className="text-red">*</span></h6>
                                                {
                                                    allDaysList.length > 0 && allDaysList.map((data) => (
                                                        <div className="col-xl-2 px-3 " key={data.Code}>
                                                            <div className="form-group">
                                                                <label className="custom-control custom-checkbox mb-0">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="custom-control-input"
                                                                        name="example-checkbox1"
                                                                        select={data.isSelect}
                                                                        checked={data.isSelect}
                                                                        disabled={isView}
                                                                        value={data.Code}
                                                                        onChange={(e) => this.onSelectAllDates(e)}
                                                                    />
                                                                    <span className="custom-control-label">{data.description}</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                                {
                                                    daysList.length > 0 && daysList.map((data) => (
                                                        <div className="col-xl-2 px-3 " key={data.Code}>
                                                            <div className="form-group">
                                                                <label className="custom-control custom-checkbox mb-0">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="custom-control-input"
                                                                        name="example-checkbox1"
                                                                        select={data.isSelect}
                                                                        checked={data.isSelect}
                                                                        disabled={data.isReadOnly || isView}
                                                                        value={data.Code}
                                                                        onChange={(e) => this.onSelectDates(e)}
                                                                    />
                                                                    <span className="custom-control-label">{data.description}</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            <div className="row mt-3">
                                                {/* <div className="col-xl-4 mt-3">
                                                <label htmlFor="validationCustom03">Upload Photo</label>
                                                <div className="box">
                                                    <div className="js--image-preview" />
                                                    <div className="upload-options">
                                                        <label>
                                                            <input
                                                                type="file"
                                                                className="image-upload"
                                                                accept="image/*"
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 mt-3">
                                                <label htmlFor="validationCustom03">Upload Photo ID</label>
                                                <div className="box">
                                                    <div className="js--image-preview" />
                                                    <div className="upload-options">
                                                        <label>
                                                            <input
                                                                type="file"
                                                                className="image-upload"
                                                                accept="image/*"
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div> */}
                                                {isShowVaccination && <div className="col-xl-4 mt-3">
                                                    <label htmlFor="validationCustom03">
                                                        Upload Fully Vaccination Certificate
                                                    </label>
                                                    <div className="box">
                                                        <div className="js--image-preview" />
                                                        {
                                                            isAdd ?
                                                                <div className="upload-options">
                                                                    <label>
                                                                        <input
                                                                            type="file"
                                                                            id="vaccFile"
                                                                            className="image-upload"
                                                                            disabled={isView}
                                                                            accept=".jpg, .jpeg, .png, .pdf"
                                                                            onChange={e => this.onFilePick(e, e.target.files)}
                                                                        />
                                                                        {
                                                                            vaccineCertificateFile != "" &&
                                                                            <i class="fa fa-times text-danger img-can" onClick={() => this.onCrossClick()}></i>
                                                                        }
                                                                    </label>

                                                                </div> : isUpdate || isView ? vaccFileName != "" && vaccFileName != null ?
                                                                    <div style={{
                                                                        border: '1px solid #ccc',
                                                                        padding: '10px',
                                                                        display: 'inline-flex',
                                                                        alignItems: 'center',
                                                                        marginTop: '10px'
                                                                    }}>
                                                                        <a
                                                                            href={filePath}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            <span style={{ marginRight: '10px' }}
                                                                            >{vaccFileName}</span>
                                                                            <i className={`fa fa-times text-danger img-can ${isView ? 'disabled' : ''}`}
                                                                                onClick={!isView ? (e) => this.onDocDelete() : undefined}></i>
                                                                        </a>
                                                                    </div> :
                                                                    <div className="upload-options">
                                                                        <label>
                                                                            <input
                                                                                type="file"
                                                                                id="vaccFile"
                                                                                className="image-upload"
                                                                                accept=".jpg, .jpeg, .png, .pdf"
                                                                                disabled={isView}
                                                                                onChange={e => this.onFilePick(e, e.target.files)}
                                                                            />
                                                                            {
                                                                                vaccineCertificateFile != "" &&
                                                                                <i class="fa fa-times text-danger img-can ml-10" onClick={() => this.onCrossClick()}></i>
                                                                            }
                                                                        </label>

                                                                    </div> : <></>



                                                        }
                                                        {/* {
                                                        isAdd && vaccFileName == "" ?
                                                            <div className="upload-options">
                                                                <label>
                                                                    <input
                                                                        type="file"
                                                                        id="vaccFile"
                                                                        className="image-upload"
                                                                        accept=".jpg, .jpeg, .png, .pdf"
                                                                        onChange={e => this.onFilePick(e, e.target.files)}
                                                                    />
                                                                    {
                                                                        vaccineCertificateFile != "" &&
                                                                        <i class="fa fa-times text-danger img-can" onClick={() => this.onCrossClick()}></i>
                                                                    }
                                                                </label>
                                                            </div> : null
                                                    }
                                                    {
                                                        isUpdate ?
                                                            <div style={{
                                                                border: '1px solid #ccc',
                                                                padding: '10px',
                                                                display: 'inline-flex',
                                                                alignItems: 'center',
                                                                marginTop: '10px'
                                                            }}>
                                                                <span style={{ marginRight: '10px' }}
                                                                >{vaccFileName}</span>
                                                                <i class="fa fa-times text-danger img-can"></i>
                                                            </div> : <></>
                                                        // <div className="upload-options">
                                                        //     <label>
                                                        //         <input
                                                        //             type="file"
                                                        //             id="vaccFile"
                                                        //             className="image-upload"
                                                        //             accept=".jpg, .jpeg, .png, .pdf"
                                                        //             onChange={e => this.onFilePick(e, e.target.files)}
                                                        //         />
                                                        //         {
                                                        //             vaccineCertificateFile != "" &&
                                                        //             <i class="fa fa-times text-danger img-can" onClick={() => this.onCrossClick()}></i>
                                                        //         }
                                                        //     </label>

                                                        // </div>

                                                    } */}
                                                    </div>
                                                </div>}
                                                <div className="btn-group mt-4 save-can d-block">
                                                    {
                                                        isAdd &&
                                                        <button
                                                            className="btn  btn-outline-primary rounded-0"
                                                            style={{ marginRight: 4 }}
                                                            onClick={(e) => this.onSubmitExhibitorBadge()}
                                                        >
                                                            Submit
                                                        </button>
                                                    }
                                                    {
                                                        isUpdate &&
                                                        <button
                                                            className="btn  btn-outline-primary rounded-0"
                                                            style={{ marginRight: 4 }}
                                                            onClick={(e) => this.onUpdateExhibitorBadge()}
                                                        >
                                                            Update
                                                        </button>

                                                    }
                                                    <button className="btn apper-cancel"
                                                        onClick={(e) => this.OnCancel()}>Back</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div class="footer-sec">
          <div class="sdsd">
              <strong>Ownership:</strong> All content, formats and images used on this site are owned or licensed by India ITME Society. You may not copy, reproduce, distribute, publish, display, perform, modify, create derivative works, transmit, or in any other way exploit any part of copyrighted material without permission of the copyright owner. Failure to comply shall result in legal action.
          </div>
      </div>

      <div class="footer-first">
          <div class="container-fluid">
              <div class="footer-first">  ITME All Rights Reserved <i class="fa fa-copyright" aria-hidden="true"></i> 2023  </div>
          </div>
      </div> */}
                </div>
                <Modal isOpen={isDelOpen} onRequestClose={() => this.setState({ isDelOpen: false })}>
                    <div className="modal-dialog">
                        <div className="modal-content confirm-logout">
                            <div class="modal-header p-2">
                                <h5 class="modal-title modal_title_text text-danger">Confirm Delete</h5>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={() => this.setState({ isDelOpen: false })} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <p class="pop_label">Do you really want to delete this Document?</p>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer p-1">
                                <div class="form-group text-right mod-fot">
                                    <button type="button" class="btn btn-del-pop" onClick={(e) => this.handleDelete(e)}>Yes</button>
                                    <button type="button" class="btn btn-can-pop" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={() => this.setState({ isDelOpen: false })}>No</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
            </>
        )
    }
}
export default withRouter(connect(null, {
    addExhibitorBadge_action, fill_action, getExhibitorBadgeDetails_action,
    updateExhibitorBadge_action, deleteExBadgeDoc_action
})(AddExhibitorBadge))