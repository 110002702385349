import { GET_ADMIN_ADDITIONAL_SUMMARY_LIST_ACTION_TYPE, GET_ADMIN_APPLICATION_SUMMARY_LIST_ACTION_TYPE, GET_ADMIN_AREA_SUMMARY_LIST_ACTION_TYPE, GET_ADMIN_PARTICIPANT_SUMMARY_LIST_ACTION_TYPE, GET_ADMIN_PAYMENT_SUMMARY_LIST_ACTION_TYPE } from "../../../../utils/constants";


const initialState = [];

function adminDashboardSummaryReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case GET_ADMIN_ADDITIONAL_SUMMARY_LIST_ACTION_TYPE:
            return [...state, payload];
        case GET_ADMIN_APPLICATION_SUMMARY_LIST_ACTION_TYPE:
            return [...state, payload];
        case GET_ADMIN_AREA_SUMMARY_LIST_ACTION_TYPE:
            return [...state, payload];
        case GET_ADMIN_PARTICIPANT_SUMMARY_LIST_ACTION_TYPE:
            return [...state, payload];
        case GET_ADMIN_PAYMENT_SUMMARY_LIST_ACTION_TYPE:
            return [...state, payload];
        default:
            return state;
    }
};

export default adminDashboardSummaryReducer