import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action, fill_master_action } from '../../../../../actions/fillCombo_action';
import { cityActionId, stallTypeActionId, stallListActionId, countryActionId, toastError, toastSuccess, opensSidesListActionId } from "../../../../../utils/constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import { useNavigate } from "react-router-dom";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import { data } from "jquery";
import { getManageAreaList_action, addManageAreaStall_action, updateManageArea_action } from "../../../../../actions/admin_module_action/master_action/exhibition_master_action/manage_area_master_action";


class ManageAreaMaster extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isDoubleStorey: false,
            isEarlyBird: false,
            isSSI: false,
            isOpenSideCharges: false,
            isSDcharges: false,
            chapterList: [],
            totalExhibitionArea: '',
            StallSizeList: [],
            stallTypelist: [],
            stallList: [],
            mainStallList: [],
            firstInstallment: '',
            isSelectedAll: false,
            isOpen1: false,
            isOpen2: false,
            secondInstallment: '',
            length: '',
            height: '',
            StallArea: '',
            ExhibitionID: '',
            StalltypeID: '',
            noOfInstallments: '',
            noOfInstallmentsErr: '',
            installmentStartDate: '',
            installmentStartDateErr: "",
            installmentList: [],
            installmentErr: "",
            baseArea: "",
            baseAreaErr: "",
            minBaseArea: "",
            minBaseAreaErr: "",
            inrPrice: "",
            inrPriceErr: "",
            usdPrice: "",
            usdPriceErr: "",
            earlyBirdDate: "",
            earlyBirdDateErr: "",
            earlyBirdDomestic: "",
            earlyBirdDomesticErr: "",
            earlyBirdInternational: "",
            earlyBirdInternationalErr: "",
            maxAreaSSI: "",
            domesticSSI: "",
            internationalSSi: "",
            maxAreaSSIErr: "",
            domesticSSIErr: "",
            internationalSSiErr: "",
            sides: 2,
            twoSideList: [
                {
                    "OpenSidesID": 2,
                    "sideStart": 1,
                    "sideEnd": '',
                    "domestic": '',
                    "International": ''
                }
            ],
            threeSideList: [
                {
                    "OpenSidesID": 3,
                    "sideStart": 1,
                    "sideEnd": '',
                    "domestic": '',
                    "International": ''
                }
            ],
            fourSideList: [
                {
                    "OpenSidesID": 4,
                    "sideStart": 1,
                    "sideEnd": '',
                    "domestic": '',
                    "International": ''
                }
            ],
            sidesList: [],
            SDlist: [
                {
                    "AreaStart": 1,
                    "AreaEnd": '',
                    "domestic": '',
                    "International": ''
                }
            ],
        }
        this.updateManageArea = this.updateManageArea.bind(this)
        this.onChangeTotalSpace = this.onChangeTotalSpace.bind(this)
        this.selectAllCheckbox = this.selectAllCheckbox.bind(this)
        this.onChangeBuffer = this.onChangeBuffer.bind(this)
        this.onStallSelect = this.onStallSelect.bind(this)
        this.getManageAreaList = this.getManageAreaList.bind(this)
        this.onChooseStall = this.onChooseStall.bind(this)
        this.onHeightChange = this.onHeightChange.bind(this)
        this.onLengthChange = this.onLengthChange.bind(this)
        this.maxLengthCheck = this.maxLengthCheck.bind(this)
        this.onAddStall = this.onAddStall.bind(this)
        this.enterPriceINR = this.enterPriceINR.bind(this)
        this.enterPriceUSD = this.enterPriceUSD.bind(this)
        this.onInstallmentChange = this.onInstallmentChange.bind(this)
        this.onCutoffSelect = this.onCutoffSelect.bind(this)
        this.onAddNew = this.onAddNew.bind(this)
        this.onSideStartEnter = this.onSideStartEnter.bind(this)
        this.onSideEndEnter = this.onSideEndEnter.bind(this)
        this.onDomesticEnter = this.onDomesticEnter.bind(this)
        this.onInstallmentCheck = this.onInstallmentCheck.bind(this)
        this.onInternationalEnter = this.onInternationalEnter.bind(this)
        this.deleteRow = this.deleteRow.bind(this)
        this.inputValidate = this.inputValidate.bind(this)
        this.enterSubPriceINR = this.enterSubPriceINR.bind(this)
        this.enterSubPriceUSD = this.enterSubPriceUSD.bind(this)
        this.onAreaEndEnter = this.onAreaEndEnter.bind(this)
        this.onAreaStartEnter = this.onAreaStartEnter.bind(this)
        this.onSDDomesticEnter = this.onSDDomesticEnter.bind(this)
        this.onSDInternationalEnter = this.onSDInternationalEnter.bind(this)
        this.deleteSDRow = this.deleteSDRow.bind(this)
        this.onAddNewSD = this.onAddNewSD.bind(this)

    }

    componentDidMount() {
        const { ExhibitionID } = this.state
        this.setState({
            ExhibitionID: this.props.location.state.ExihibitionID != undefined ? (this.props.location.state != null && this.props.location.state.ExihibitionID != null ? this.props.location.state.ExihibitionID : '') : this.props.id != null && this.props.id != undefined ? this.props.id : '',
        })

        setTimeout(() => {
            console.log(this.props.id, this.props.location.state.ExihibitionID, ExhibitionID, this.props.location.state != null && this.props.location.state.ExihibitionID != null)
            this.getManageAreaList()
            this.getFillList(stallListActionId, this.state.ExhibitionID, '', '', '', '');
            this.getFillList(stallTypeActionId, this.state.ExhibitionID, '', '', '', '');
            this.getFillList(opensSidesListActionId, this.state.ExhibitionID, '', '', '', '');

        }, 200);
    }

    onAddStall() {
        const { length, height, StallArea, StalltypeID } = this.state
        var ExhibitionStallDimension = `${length}X${height}`
        if (length != '' && length != null && height != '' && height != null && StalltypeID != '' && StalltypeID != null) {
            this.setState({ isLoading: true })
            this.props.addManageAreaStall_action(ExhibitionStallDimension, StallArea, StalltypeID)
                .then((data) => {
                    if (data.error != 1) {
                        this.getFillList(stallListActionId, '', '', '', '', '');
                        this.setState({ isLoading: false, isOpen2: false, length: '', height: '', StallArea: '', StalltypeID: '' })
                        toastSuccess("stall add successful")
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        else {
            toastError("Please Fill Required Fields")
        }

    }

    maxLengthCheck = (object, maxLength) => {
        if (object.target.value.length > maxLength) {
            object.target.value = object.target.value.slice(0, maxLength)
        }
    }

    onLengthChange(value) {
        const { length, StallArea, height } = this.state
        this.setState({ length: value })
        if (height != '' && height != null) {
            this.setState({ StallArea: value * height })
        }
    }

    onHeightChange(value) {
        const { length, StallArea, height } = this.state
        this.setState({ height: value })
        if (length != '' && length != null) {
            this.setState({ StallArea: value * length })
        }
    }

    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from, isSession) {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props
            .fill_master_action(actionID, exhibitionID, moduleID, keyValue, value, from, isSession)
            .then((data) => {
                console.log("actionID, exhibitionID, moduleID, keyValue, value, from, isSessiona", actionID, exhibitionID, moduleID, keyValue, value, from, isSession)
                if (data.error != 1) {
                    // country list
                    if (actionID === stallTypeActionId) {
                        console.log("country data", data.data)
                        this.setState({ stallTypelist: data.data, isLoading: false })
                    }
                    // Sides list
                    if (actionID === opensSidesListActionId) {
                        console.log("Sides data", data.data)
                        this.setState({ sidesList: data.data, isLoading: false })
                    }
                    if (actionID === stallListActionId) {
                        console.log("country data", data.data,)
                        var temp = []
                        {
                            data.data.length > 0 && data.data.map((element) => {
                                element.isNew = 0
                            })
                        }
                        for (var i = 0; i < data.data.length; i++) {
                            if (data.data[i].IS_Selected == 0) {
                                this.setState({ isSelectedAll: false })
                                break
                            }
                            else {
                                this.setState({ isSelectedAll: true })
                            }
                        }
                        console.log(temp.length)
                        this.setState({ stallList: data.data, mainStallList: data.data, isLoading: false })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    onChangeTotalSpace(e, id) {
        // e.preventDefault()
        const { totalExhibitionArea, chapterList } = this.state
        console.log(id, e.target.value, totalExhibitionArea)
        if (totalExhibitionArea == '' || totalExhibitionArea == null || isNaN(totalExhibitionArea)) {
            chapterList.map((data, i) => {
                if (id == i) {
                    data.TotalSpace = ''
                }
            })
            toastError("please fill total exhibition area")
        }
        else {
            console.log("here")
            chapterList.map((data, i) => {
                if (id == i) {
                    data.TotalSpace = parseInt(e.target.value)
                }
            })
        }
        var total = 0
        for (var q = 0; q < chapterList.length; q++) {
            if (chapterList[q].TotalSpace != null && chapterList[q].TotalSpace != '') {
                total = chapterList[q].TotalSpace + total
            }
        }
        console.log(total)

        if (total > parseInt(totalExhibitionArea)) {
            // var list = [...chapterList]
            chapterList.map((data, i) => {
                if (id == i) {
                    data.TotalSpace = ''
                    setTimeout(() => {
                        // this.setState({ chapterList: chapterList })
                        toastError("total areas should not be more than total exhibiton area")
                    }, 200);
                }
            })
        }
        setTimeout(() => {
            console.log(chapterList)
            this.setState({ chapterList: chapterList })
        }, 200);
    }

    onChangeBuffer(e, id) {
        e.preventDefault()
        const { totalExhibitionArea, chapterList } = this.state
        console.log(id, e, totalExhibitionArea)
        if (totalExhibitionArea == '' || totalExhibitionArea == null) {
            chapterList.map((data, i) => {
                if (id == i) {
                    data.Buffer = ''
                }
            })
            toastError("please fill total exhibition area")
        }
        else {
            // var list = [...chapterList]
            chapterList.map((data, i) => {
                if (id == i) {
                    console.log(data.TotalSpace)
                    if (data.TotalSpace == '' || data.TotalSpace == null) {
                        toastError("please fill total area")
                    }
                    else {
                        if (parseInt(data.TotalSpace - parseInt(e.target.value)) < 0) {
                            data.Buffer = ''
                            data.Available = ''
                            toastError("Buffer cannot be greater than total space")
                        }
                        else {
                            data.Buffer = parseInt(e.target.value)
                            data.Available = parseInt(data.TotalSpace - parseInt(e.target.value))
                        }

                        console.log(data.TotalSpace, data.Buffer, e.target.value)
                    }
                }
            })
        }
        setTimeout(() => {
            this.setState({ chapterList: chapterList })
            console.log(chapterList)
        }, 200);
    }



    getManageAreaList() {
        this.setState({ isLoading: true })
        this.props.getManageAreaList_action(this.state.ExhibitionID)
            .then((data) => {
                if (data.error != 1) {
                    console.log(data.data)
                    // this.setState({ installmentList: [] })

                    data.data.chapterList.map((element) => {
                        element.ExhibitionID = this.state.ExhibitionID
                    })
                    data.data.stallSizeList.map((element) => {
                        element.ExhibitionID = this.state.ExhibitionID
                    })
                    for (var i = 0; i < data.data.chapterList.length; i++) {
                        if (data.data.chapterList[i].TotalSpace != null && data.data.chapterList[i].Buffer != null) {
                            data.data.chapterList[i].Available = data.data.chapterList[i].TotalSpace - data.data.chapterList[i].Buffer
                        }
                    }
                    var listForInstall = []
                    for (var i = 0; i < data.data.installmentList.length; i++) {
                        var obj = {}
                        obj.InstallmentNo = data.data.installmentList[i].InstallmentNo
                        obj.Installment = data.data.installmentList[i].InstallmentPercentage
                        obj.cutOffDate = data.data.installmentList[i].CutOffDate != null && data.data.installmentList[i].CutOffDate != '' ? new Date(moment(data.data.installmentList[i].CutOffDate).format('YYYY-MM-DD')) : ''
                        listForInstall.push(obj)
                    }
                    var side2List = []
                    var side3List = []
                    var side4List = []

                    if (data.data.isDoubleStorey) {
                        this.setState({
                            baseArea: data.data.baseArea,
                            minBaseArea: data.data.minBaseArea,
                            inrPrice: data.data.inrPrice,
                            usdPrice: data.data.usdPrice,
                            isDoubleStorey: data.data.isDoubleStorey
                        })
                    }
                    if (data.data.isEarlyBird) {
                        this.setState({
                            earlyBirdDate: data.data.earlyBirdDate != null && data.data.earlyBirdDate != '' ? new Date(moment(data.data.earlyBirdDate).format('YYYY-MM-DD')) : '',
                            earlyBirdDomestic: data.data.earlyBirdDomestic,
                            earlyBirdInternational: data.data.earlyBirdInternational,
                            isEarlyBird: data.data.isEarlyBird,
                        })
                    }
                    if (data.data.isOpenSideCharges) {
                        this.setState({ isOpenSideCharges: data.data.isOpenSideCharges })
                        console.log(data.data.sideList)
                        for (var i = 0; i < data.data.sideList.length; i++) {
                            var obj = {}
                            if (data.data.sideList[i].OpenSidesID == 2) {
                                obj.OpenSidesID = data.data.sideList[i].OpenSidesID
                                obj.sideStart = data.data.sideList[i].AreaFrom
                                obj.sideEnd = data.data.sideList[i].AreaTo
                                obj.domestic = data.data.sideList[i].DomasticPercentage
                                obj.International = data.data.sideList[i].InternationalPercentage
                                side2List.push(obj)
                            }
                            console.log(side2List)

                            if (data.data.sideList[i].OpenSidesID == 3) {
                                obj.OpenSidesID = data.data.sideList[i].OpenSidesID
                                obj.sideStart = data.data.sideList[i].AreaFrom
                                obj.sideEnd = data.data.sideList[i].AreaTo
                                obj.domestic = data.data.sideList[i].DomasticPercentage
                                obj.International = data.data.sideList[i].InternationalPercentage
                                side3List.push(obj)
                            }
                            if (data.data.sideList[i].OpenSidesID == 4) {
                                obj.OpenSidesID = data.data.sideList[i].OpenSidesID
                                obj.sideStart = data.data.sideList[i].AreaFrom
                                obj.sideEnd = data.data.sideList[i].AreaTo
                                obj.domestic = data.data.sideList[i].DomasticPercentage
                                obj.International = data.data.sideList[i].InternationalPercentage
                                side4List.push(obj)
                            }
                        }
                    }
                    var SDlist = []
                    if (data.data.isSD) {
                        console.log(data.data.SDList)
                        for (var i = 0; i < data.data.SDList.length; i++) {
                            var obj = {}
                            obj.AreaStart = data.data.SDList[i].FromArea
                            obj.AreaEnd = data.data.SDList[i].ToArea
                            obj.domestic = data.data.SDList[i].DomesticPrice
                            obj.International = data.data.SDList[i].InternationalPrice
                            SDlist.push(obj)
                            console.log(SDlist)
                        }
                        this.setState({ SDlist: SDlist, isSDcharges: data.data.isSD })
                    }
                    if (data.data.isSSI) {
                        this.setState({
                            domesticSSI: data.data.domesticSSI,
                            internationalSSi: data.data.internationalSSi,
                            maxAreaSSI: data.data.maxAreaSSI,
                            isSSI: data.data.isSSI,
                        })
                    }

                    this.setState({
                        totalExhibitionArea: data.data.TotalExhibitionArea,
                        installmentStartDate: data.data.installmentStartDate != null ? new Date(moment(data.data.installmentStartDate).format('YYYY-MM-DD')) : null,
                        noOfInstallments: data.data.noOfInstallments,
                        firstInstallment: data.data.firstInstallment, secondInstallment: data.data.secondinstallment,
                        chapterList: data.data.chapterList,
                        StallSizeList: data.data.stallSizeList,
                        installmentList: listForInstall,
                    })
                    console.log(side2List, side3List, side4List, 'data.data.stallSizeList', data.data.stallSizeList)

                    side2List.length > 0 && (this.setState({
                        sides: side2List[0].OpenSidesID,
                        twoSideList: side2List,
                    }))
                    side3List.length > 0 && this.setState({
                        sides: side3List[0].OpenSidesID,
                        threeSideList: side3List,
                    })
                    side4List.length > 0 && this.setState({
                        sides: side4List[0].OpenSidesID,
                        fourSideList: side4List,
                    })
                    console.log(this.state.installmentList, this.state.sides)
                    console.log("YYYYY", data.data.isOpenSideCharges)
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    inputValidate() {
        const { installmentStartDate, noOfInstallments, baseArea, minBaseArea, inrPrice, usdPrice, isDoubleStorey, isEarlyBird,
            earlyBirdDate, earlyBirdDomestic, earlyBirdInternational, isSSI, maxAreaSSI, domesticSSI, internationalSSi } = this.state
        var errinputValidate = false
        if (noOfInstallments == "" || noOfInstallments == null) {
            this.setState({ noOfInstallmentsErr: "Enter No of Installments" })
            errinputValidate = "Enter No of Installments"
        }
        if (installmentStartDate == "" || installmentStartDate == null) {
            this.setState({ installmentStartDateErr: "Select Installment Start Date" })
            errinputValidate = "Select Installment Start Date"
        }
        if (isDoubleStorey) {
            if (baseArea == "" || baseArea == null) {
                this.setState({ baseAreaErr: "Enter Base Area" })
                errinputValidate = "Enter Base Area"
            }
            if (minBaseArea == "" || minBaseArea == null) {
                this.setState({ minBaseAreaErr: "Enter Minimum Base Area" })
                errinputValidate = "Enter Minimum Base Area"
            }
            if (inrPrice == "" || inrPrice == null) {
                this.setState({ inrPriceErr: "Enter INR Price" })
                errinputValidate = "Enter INR Price"
            }
            if (usdPrice == "" || usdPrice == null) {
                this.setState({ usdPriceErr: "Enter USD Price" })
                errinputValidate = "Enter USD Price"
            }
        }
        if (isEarlyBird) {
            if (earlyBirdDate == "" || earlyBirdDate == null) {
                this.setState({ earlyBirdDateErr: "Enter Date" })
                errinputValidate = "Enter Date"
            }
            if (earlyBirdDomestic == "" || earlyBirdDomestic == null) {
                this.setState({ earlyBirdDomesticErr: "Enter Domestic Discout" })
                errinputValidate = "Enter Domestic Discout"
            }
            if (earlyBirdInternational == "" || earlyBirdInternational == null) {
                this.setState({ earlyBirdInternationalErr: "Enter International Discout" })
                errinputValidate = "Enter International Discout"
            }
        }
        console.log(isSSI, 'maxAreaSSI', maxAreaSSI)
        if (isSSI) {
            if (maxAreaSSI == "" || maxAreaSSI == null) {
                this.setState({ maxAreaSSIErr: "Enter Max SSI Area" })
                errinputValidate = "Enter Max SSI Area"
            }
            if (domesticSSI == "" || domesticSSI == null) {
                this.setState({ domesticSSIErr: "Enter Domestic SSI" })
                errinputValidate = "Enter Domestic SSI"
            }
            if (internationalSSi == "" || internationalSSi == null) {
                this.setState({ internationalSSiErr: "Enter International SSI" })
                errinputValidate = "Enter International SSI"
            }
        }
        return errinputValidate
    }

    updateManageArea() {
        const { totalExhibitionArea, ExhibitionID, firstInstallment, secondInstallment, chapterList, StallSizeList, noOfInstallments,
            installmentStartDate, noOfInstallmentsErr, installmentStartDateErr, installmentList, twoSideList, threeSideList, fourSideList,
            isOpenSideCharges, minBaseArea, minBaseAreaErr, baseArea, baseAreaErr, inrPrice, inrPriceErr, usdPrice, usdPriceErr, isEarlyBird, isDoubleStorey, isSSI,
            earlyBirdDate, earlyBirdDateErr, earlyBirdDomestic, earlyBirdDomesticErr, earlyBirdInternational, earlyBirdInternationalErr, maxAreaSSIErr, domesticSSIErr, internationalSSiErr, maxAreaSSI, domesticSSI, internationalSSi, sidesList, sides, SDlist, isSDcharges } = this.state
        const errinputValidate = this.inputValidate()
        console.log(errinputValidate, 'maxAreaSSIIIII', maxAreaSSIErr)

        var chapterErr = false
        var stallErr = false
        var totalSpaceErr = false
        var installmentError = false
        var openSideChargesError = false
        var SDerr = false
        var totalTotalSpace = 0
        var finalSideList = []
        chapterList.map((data, i) => {
            if (isNaN(data.TotalSpace)) {
                data.TotalSpace = null
            }
            if (isNaN(data.Buffer)) {
                data.Buffer = null
            }
            if (isNaN(data.Available)) {
                data.Available = null
            }
        })
        StallSizeList.map((data) => {
            if (isNaN(data['Price(INR)'])) {
                data['Price(INR)'] = null
            }
            if (isNaN(data['Price(USD)'])) {
                data['Price(USD)'] = null
            }
        })
        for (var i = 0; i < chapterList.length; i++) {
            if (chapterList[i].TotalSpace == '' || chapterList[i].TotalSpace == null || chapterList[i].Buffer == '' || chapterList[i].Buffer == null) {
                chapterErr = true
                break
            }
            else if (chapterList[i].TotalSpace < chapterList[i].Buffer) {
                totalSpaceErr = true
                break
            }
            else {
                totalTotalSpace = totalTotalSpace + chapterList[i].TotalSpace
            }
        }
        for (var i = 0; i < StallSizeList.length; i++) {
            if (StallSizeList[i]['Price(INR)'] == '' || StallSizeList[i]['Price(INR)'] == null) {
                stallErr = true
                break
            }
        }
        for (var i = 0; i < installmentList.length; i++) {
            if (installmentList[i]['Installment'] == '' || installmentList[i]['cutOffDate'] == '') {
                installmentError = true
                break
            }
        }
        if (sides == 2) {
            for (var i = 0; i < twoSideList.length; i++) {
                if (
                    twoSideList[i]['sideStart'] === '' ||
                    twoSideList[i]['sideEnd'] === '' ||
                    twoSideList[i]['domestic'] === '' ||
                    twoSideList[i]['International'] === ''
                ) {
                    openSideChargesError = true;
                    break;
                }
            }
        }

        if (sides == 3) {
            for (var i = 0; i < threeSideList.length; i++) {
                if (
                    threeSideList[i]['sideStart'] === '' ||
                    threeSideList[i]['sideEnd'] === '' ||
                    threeSideList[i]['domestic'] === '' ||
                    threeSideList[i]['International'] === ''
                ) {
                    openSideChargesError = true;
                    break;
                }
            }
        }

        if (sides == 4) {
            for (var i = 0; i < fourSideList.length; i++) {
                if (
                    fourSideList[i]['sideStart'] === '' ||
                    fourSideList[i]['sideEnd'] === '' ||
                    fourSideList[i]['domestic'] === '' ||
                    fourSideList[i]['International'] === ''
                ) {
                    openSideChargesError = true;
                    break;
                }
            }
        }
        console.log(sides)
        if (openSideChargesError != true) {
            if (sides == 2) {
                for (var i = 0; i < twoSideList.length; i++) {
                    finalSideList.push(twoSideList[i])
                    console.log(twoSideList[i])
                }
                for (var i = 0; i < threeSideList.length; i++) {
                    if (
                        threeSideList[i]['sideStart'] != '' &&
                        threeSideList[i]['sideEnd'] != '' &&
                        threeSideList[i]['domestic'] != '' &&
                        threeSideList[i]['International'] != ''
                    ) {
                        finalSideList.push(threeSideList[i])
                        console.log('lllll', finalSideList)
                    }
                    console.log('lllll', threeSideList)
                }
                for (var i = 0; i < fourSideList.length; i++) {
                    if (
                        fourSideList[i]['sideStart'] != '' &&
                        fourSideList[i]['sideEnd'] != '' &&
                        fourSideList[i]['domestic'] != '' &&
                        fourSideList[i]['International'] != ''
                    ) {
                        finalSideList.push(fourSideList[i])
                    }
                }
            }
            if (sides == 3) {
                for (var i = 0; i < threeSideList.length; i++) {
                    finalSideList.push(threeSideList[i])
                }
                for (var i = 0; i < twoSideList.length; i++) {
                    if (
                        twoSideList[i]['sideStart'] != '' &&
                        twoSideList[i]['sideEnd'] != '' &&
                        twoSideList[i]['domestic'] != '' &&
                        twoSideList[i]['International'] != ''
                    ) {
                        finalSideList.push(twoSideList[i])
                        console.log('lllll', finalSideList)
                    }
                    console.log('lllll', twoSideList)
                }
                for (var i = 0; i < fourSideList.length; i++) {
                    if (
                        fourSideList[i]['sideStart'] != '' &&
                        fourSideList[i]['sideEnd'] != '' &&
                        fourSideList[i]['domestic'] != '' &&
                        fourSideList[i]['International'] != ''
                    ) {
                        finalSideList.push(fourSideList[i])
                    }
                }
            }
            if (sides == 4) {
                for (var i = 0; i < fourSideList.length; i++) {
                    finalSideList.push(fourSideList[i])
                }
                for (var i = 0; i < threeSideList.length; i++) {
                    if (
                        threeSideList[i]['sideStart'] != '' &&
                        threeSideList[i]['sideEnd'] != '' &&
                        threeSideList[i]['domestic'] != '' &&
                        threeSideList[i]['International'] != ''
                    ) {
                        finalSideList.push(threeSideList[i])
                        console.log('lllll', finalSideList)
                    }
                    console.log('lllll', threeSideList)
                }
                for (var i = 0; i < twoSideList.length; i++) {
                    if (
                        twoSideList[i]['sideStart'] != '' &&
                        twoSideList[i]['sideEnd'] != '' &&
                        twoSideList[i]['domestic'] != '' &&
                        twoSideList[i]['International'] != ''
                    ) {
                        finalSideList.push(twoSideList[i])
                        console.log('lllll', twoSideList)
                    }
                }
            }
        }
        if (isSDcharges) {
            for (var i = 0; i < SDlist.length; i++) {
                if (SDlist[i].AreaEnd == '' || SDlist[i].AreaEnd == null || isNaN(SDlist[i].AreaEnd)) {
                    var SDerr = true
                }
                if (SDlist[i].AreaStart == '' || SDlist[i].AreaStart == null || isNaN(SDlist[i].AreaStart)) {
                    var SDerr = true
                }
                if (SDlist[i].domestic == '' || SDlist[i].domestic == null || isNaN(SDlist[i].domestic)) {
                    var SDerr = true
                }
                if (SDlist[i].International == '' || SDlist[i].International == null || isNaN(SDlist[i].International)) {
                    var SDerr = true
                }
            }
        }
        console.log("oo", finalSideList)
        if (totalExhibitionArea == '' || totalExhibitionArea == null || isNaN(totalExhibitionArea)) {
            this.setState({ isLoading: false })
            toastError("please fill total exhibition area")
        }
        else {
            if (chapterErr == true || stallErr == true || SDerr == true) {
                this.setState({ isLoading: false })
                toastError("please fill the mandatory fields")
            }
            else {
                if (totalExhibitionArea < totalTotalSpace) {
                    this.setState({ isLoading: false })
                    toastError("total areas should not be more than total exhibiton area")
                }
                else {
                    if (totalSpaceErr == true) {
                        this.setState({ isLoading: false })
                        toastError("Buffer cannot be greater than total space")
                    } else {
                        if (installmentError == true) {
                            this.setState({ isLoading: false })
                            toastError("Please fill Installment Details")
                        } else {
                            if (isOpenSideCharges && openSideChargesError == true) {
                                this.setState({ isLoading: false })
                                toastError("Please fill Open Side Charges Details")
                            }
                            else {
                                console.log("maxAreaSSIErr", maxAreaSSIErr)
                                if (noOfInstallments != "" && installmentStartDate != "" && installmentList.length > 0) {
                                    if (errinputValidate == false) {
                                        console.log("save---->", ExhibitionID, totalExhibitionArea, chapterList, StallSizeList, noOfInstallments, installmentStartDate, baseArea, minBaseArea, inrPrice, usdPrice, earlyBirdDate, earlyBirdDomestic, earlyBirdInternational, maxAreaSSI, domesticSSI, internationalSSi, isOpenSideCharges, isEarlyBird, isDoubleStorey, isSSI, sides, installmentList, finalSideList)
                                        this.setState({ isLoading: true })
                                        this.props.updateManageArea_action(ExhibitionID,
                                            totalExhibitionArea,
                                            chapterList,
                                            StallSizeList,
                                            noOfInstallments,
                                            installmentStartDate,
                                            (isDoubleStorey != true || baseArea == '' || baseArea == null) ? null : baseArea,
                                            (isDoubleStorey != true || minBaseArea == '' || minBaseArea == null) ? null : minBaseArea,
                                            (isDoubleStorey != true || inrPrice == '' || inrPrice == null) ? null : inrPrice,
                                            (isDoubleStorey != true || usdPrice == '' || usdPrice == null) ? null : usdPrice,
                                            (isEarlyBird != true || earlyBirdDate == '' || earlyBirdDate == null) ? null : earlyBirdDate,
                                            (isEarlyBird != true || earlyBirdDomestic == '' || earlyBirdDomestic == null) ? null : earlyBirdDomestic,
                                            (isEarlyBird != true || earlyBirdInternational == '' || earlyBirdInternational == null) ? null : earlyBirdInternational,
                                            (isSSI != true || maxAreaSSI == '' || maxAreaSSI == null) ? null : maxAreaSSI,
                                            (isSSI != true || domesticSSI == '' || domesticSSI == null) ? null : domesticSSI,
                                            (isSSI != true || internationalSSi == '' || internationalSSi == null) ? null : internationalSSi,
                                            isOpenSideCharges,
                                            isEarlyBird,
                                            isDoubleStorey,
                                            isSSI,
                                            (isOpenSideCharges != true || sides == '' || sides == null) ? null : sides,
                                            installmentList,
                                            (isOpenSideCharges != true || finalSideList.length == 0) ? [] : finalSideList, isSDcharges, (isSDcharges != true ? [] : SDlist))
                                            .then((data) => {
                                                if (data.error != 1) {
                                                    this.getManageAreaList()
                                                    this.setState({ isLoading: false })
                                                    toastSuccess("update successful")
                                                }
                                                else {
                                                    this.setState({ isLoading: false })
                                                    toastError(data.msg)
                                                }
                                            })
                                            .catch((err) => {
                                                console.log(err)
                                            })
                                    } else {
                                        toastError(errinputValidate)
                                    }
                                } else {
                                    toastError("Please Fill Mandatory Fields")
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    onStallSelect(ID) {
        // e.preventDefault()
        const { stallList } = this.state
        stallList.map((data, i) => {
            if (ID == i) {
                console.log(data)
                if (data.IS_Selected == 1) {
                    data.IS_Selected = 0
                }
                else {
                    data.IS_Selected = 1
                    data.isNew = 1
                }
            }
        })
        // setTimeout(() => {
        // console.log(stallList)
        this.setState({ stallList: stallList })
        // }, 50);
    }

    selectAllCheckbox() {
        const { stallList, isSelectedAll } = this.state
        if (isSelectedAll == false) {
            stallList.map((data) => (
                data.IS_Selected = 1
            ))
            this.setState({ isSelectedAll: true })
        }
        else {
            stallList.map((data) => (
                data.IS_Selected = 0
            ))
            this.setState({ isSelectedAll: false })
        }
        this.setState({ stallList: stallList })
    }

    onChooseStall() {
        const { stallList, StallSizeList, mainStallList, ExhibitionID } = this.state
        this.setState({ StallSizeList: [] })
        var tempStallList = []
        for (var i = 0; i < stallList.length; i++) {
            if (stallList[i].IS_Selected == 1) {
                var obj = {}
                if (stallList[i].isNew == 1) {
                    obj['Price(INR)'] = ''
                    obj['Price(USD)'] = ''
                    obj['Subsidised_Rate_INR'] = ''
                    obj['Subsidised_Rate_USD'] = ''
                }
                else {
                    obj['Price(INR)'] = stallList[i].UnitPrice_INR
                    obj['Price(USD)'] = stallList[i].UnitPrice_USD
                    obj['Subsidised_Rate_INR'] = stallList[i].Subsidised_Rate_INR
                    obj['Subsidised_Rate_USD'] = stallList[i].Subsidised_Rate_USD
                }
                obj['Stall Size'] = stallList[i].ExhibitionStallDimension
                obj['StallArea'] = stallList[i].StallArea
                obj['StallTypeID'] = stallList[i].StallTypeID
                obj['StallTypeName'] = stallList[i].StallTypeName
                obj['ExhibitionID'] = ExhibitionID
                tempStallList.push(obj)
            }
        }
        console.log(tempStallList)
        this.setState({ StallSizeList: tempStallList, isOpen1: false })
    }

    enterPriceINR(e, index) {
        const { StallSizeList } = this.state
        const list = [...StallSizeList]
        for (let i = 0; i < list.length; i++) {
            if (i == index) {
                list[i]['Price(INR)'] = e.target.value
            }
        }
        this.setState({
            StallSizeList: list
        })
    }

    enterPriceUSD(e, index) {
        const { StallSizeList } = this.state
        const list = [...StallSizeList]
        for (let i = 0; i < list.length; i++) {
            if (i == index) {
                list[i]['Price(USD)'] = e.target.value
            }
        }
        this.setState({
            StallSizeList: list
        })
    }

    enterSubPriceINR(e, index) {
        const { StallSizeList } = this.state
        const list = [...StallSizeList]
        for (let i = 0; i < list.length; i++) {
            if (i == index) {
                list[i]['Subsidised_Rate_INR'] = e.target.value
            }
        }
        this.setState({
            StallSizeList: list
        })
    }

    enterSubPriceUSD(e, index) {
        const { StallSizeList } = this.state
        const list = [...StallSizeList]
        for (let i = 0; i < list.length; i++) {
            if (i == index) {
                list[i]['Subsidised_Rate_USD'] = e.target.value
            }
        }
        this.setState({
            StallSizeList: list
        })
    }

    onInstallmentChange(e) {
        const { installmentList } = this.state
        console.log("maxAreaSSIErr", this.state.maxAreaSSIErr)
        var value = e.target.value;
        var InstallmentListData = [];

        if (value > installmentList.length) {
            InstallmentListData = [...installmentList]
            console.log(InstallmentListData)
            for (let i = installmentList.length; i < value; i++) {
                var mapData = {
                    "InstallmentNo": i + 1,
                    "Installment": "",
                    "cutOffDate": ""
                };
                InstallmentListData.push(mapData);
            }
        }
        else if (value < installmentList.length) {
            for (let i = 0; i < value; i++) {
                var mapData = {
                    "InstallmentNo": installmentList[i].InstallmentNo,
                    "Installment": installmentList[i].Installment,
                    "cutOffDate": installmentList[i].cutOffDate
                };
                InstallmentListData.push(mapData);
            }
        }

        this.setState({ installmentList: InstallmentListData });
    }

    onCutoffSelect(date, i) {
        const { installmentList } = this.state
        installmentList.length > 0 && installmentList.map((data, index) => {
            if (i == index) {
                data.cutOffDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
            }
        })
        console.log("xxxxxxxxxxxxxxxxxxxxxx", installmentList)
        console.log(date)
        this.setState({ installmentList: installmentList })
    }

    onEnterInstallment(e, i) {
        const { installmentList } = this.state;
        const value = parseFloat(e.target.value); // Convert value to a number

        if (installmentList.length > 0) {
            const updatedList = installmentList.map((data, index) => {
                if (i === index) {
                    this.setState({ installmentErr: "" })
                    return { ...data, Installment: value };

                } else {
                    return data;
                }
            });

            console.log("yyyyyyyyyyyyyyyyyyyyy", updatedList);

            // Check if it's the last index
            if (i === installmentList.length - 1) {
                const total = updatedList.reduce((acc, curr) => acc + curr.Installment, 0);
                if (total > 100) {
                    this.setState({ installmentErr: "Installment % should not more than 100%" })
                    return;
                }
            }

            this.setState({ installmentList: updatedList });
        }
    }

    onInstallmentCheck() {
        const { installmentList } = this.state
        var err
        var total = 0
        for (var i = 0; i < installmentList.length; i++) {
            if (installmentList[i].Installment != '' && installmentList[i].Installment != null) {
                total = total + installmentList[i].Installment
            }
            else {
                total = 100
                break
            }
        }
        if (total != 100) {
            this.setState({ installmentErr: "Installment % should be 100%" })
        }
    }

    onAddNew() {
        const { twoSideList, threeSideList, fourSideList, sides } = this.state
        var list = []
        var startNo
        if (sides == 2) {
            list = twoSideList
            startNo = twoSideList[twoSideList.length - 1].sideEnd
        } else if (sides == 3) {
            list = threeSideList
            startNo = threeSideList[threeSideList.length - 1].sideEnd
        } else if (sides == 4) {
            list = fourSideList
            startNo = fourSideList[fourSideList.length - 1].sideEnd
        }
        console.log(list.length)
        var mapData = {
            "OpenSidesID": sides,
            "sideStart": parseInt(startNo),
            "sideEnd": '',
            "domestic": '',
            "International": ''
        }
        list.push(mapData)
        console.log("xxxxxxxxxxxxxxxx", list)
        if (sides == 2) {
            this.setState({ twoSideList: list })
        } else if (sides == 3) {
            this.setState({ threeSideList: list })
        } else if (sides == 4) {
            this.setState({ fourSideList: list })
        }
    }

    onSideStartEnter(e, index) {
        const { twoSideList, threeSideList, fourSideList, sides } = this.state
        var value = e.target.value
        var list = []
        if (sides == 2) {
            list = twoSideList
        } else if (sides == 3) {
            list = threeSideList
        } else if (sides == 4) {
            list = fourSideList
        }

        list.length > 0 && list.map((data, i) => {
            if (index == i) {
                data.sideStart = value
            }
        })

        console.log("xxxxxxxxxxxxxxxx", list)
        if (sides == 2) {
            this.setState({ twoSideList: list })
        } else if (sides == 3) {
            this.setState({ threeSideList: list })
        } else if (sides == 4) {
            this.setState({ fourSideList: list })
        }
    }

    onSideEndEnter(e, index) {
        const { twoSideList, threeSideList, fourSideList, sides } = this.state
        var value = e.target.value
        console.log(twoSideList[index + 1])
        var list = []
        if (sides == 2) {
            list = twoSideList
        } else if (sides == 3) {
            list = threeSideList
        } else if (sides == 4) {
            list = fourSideList
        }

        list.length > 0 && list.map((data, i) => {
            if (index == i) {
                data.sideEnd = value

            }
        })
        if (list[index + 1] != undefined) {
            list[index + 1].sideStart = parseInt(value)
        }

        console.log("xxxxxxxxxxxxxxxx", list)
        if (sides == 2) {
            this.setState({ twoSideList: list })
        } else if (sides == 3) {
            this.setState({ threeSideList: list })
        } else if (sides == 4) {
            this.setState({ fourSideList: list })
        }
    }

    onDomesticEnter(e, index) {
        const { twoSideList, threeSideList, fourSideList, sides } = this.state
        var value = e.target.value
        var list = []
        if (sides == 2) {
            list = twoSideList
        } else if (sides == 3) {
            list = threeSideList
        } else if (sides == 4) {
            list = fourSideList
        }

        list.length > 0 && list.map((data, i) => {
            if (index == i) {
                data.domestic = value
            }
        })

        console.log("xxxxxxxxxxxxxxxx", list)
        if (sides == 2) {
            this.setState({ twoSideList: list })
        } else if (sides == 3) {
            this.setState({ threeSideList: list })
        } else if (sides == 4) {
            this.setState({ fourSideList: list })
        }
    }

    onInternationalEnter(e, index) {
        const { twoSideList, threeSideList, fourSideList, sides } = this.state
        var value = e.target.value
        var list = []
        if (sides == 2) {
            list = twoSideList
        } else if (sides == 3) {
            list = threeSideList
        } else if (sides == 4) {
            list = fourSideList
        }

        list.length > 0 && list.map((data, i) => {
            if (index == i) {
                data.International = value
            }
        })

        console.log("xxxxxxxxxxxxxxxx", list)
        if (sides == 2) {
            this.setState({ twoSideList: list })
        } else if (sides == 3) {
            this.setState({ threeSideList: list })
        } else if (sides == 4) {
            this.setState({ fourSideList: list })
        }
    }

    deleteRow() {
        const { twoSideList, threeSideList, fourSideList, sides } = this.state
        var list = []
        if (sides == 2) {
            list = twoSideList
        } else if (sides == 3) {
            list = threeSideList
        } else if (sides == 4) {
            list = fourSideList
        }

        list.pop()
        console.log("xxxxxxxxxxxxxxxx", list)
        if (sides == 2) {
            this.setState({ twoSideList: list })
        } else if (sides == 3) {
            this.setState({ threeSideList: list })
        } else if (sides == 4) {
            this.setState({ fourSideList: list })
        }
    }

    onAddNewSD() {
        const { SDlist } = this.state
        var list = [...SDlist]
        var startNo
        startNo = SDlist[SDlist.length - 1].AreaEnd

        console.log(SDlist[SDlist.length - 1].AreaEnd)
        var mapData = {
            "AreaStart": SDlist[SDlist.length - 1].AreaEnd != '' ? parseInt(startNo) + 1 : '',
            "AreaEnd": '',
            "domestic": '',
            "International": ''
        }
        list.push(mapData)
        console.log("xxxxxxxxxxxxxxxx", list)
        this.setState({ SDlist: list })
    }
    onAreaStartEnter(e, index) {
        const { SDlist } = this.state
        var value = e.target.value
        var list = [...SDlist]

        list.length > 0 && list.map((data, i) => {
            if (index == i) {
                data.AreaStart = value
            }
        })
        console.log("xxxxxxxxxxxxxxxx", list)
        this.setState({ SDlist: list })
    }
    onAreaEndEnter(e, index) {
        const { SDlist } = this.state
        var value = e.target.value
        // console.log(twoSideList[index + 1])
        var list = [...SDlist]

        list.length > 0 && list.map((data, i) => {
            if (index == i) {
                data.AreaEnd = value

            }
        })
        if (list[index + 1] != undefined) {
            list[index + 1].AreaStart = parseInt(value) + 1
        }

        console.log("xxxxxxxxxxxxxxxx", list)
        this.setState({ SDlist: list })
    }

    onSDDomesticEnter(e, index) {
        const { SDlist } = this.state
        var value = e.target.value
        var list = [...SDlist]

        list.length > 0 && list.map((data, i) => {
            if (index == i) {
                data.domestic = value
            }
        })

        this.setState({ SDlist: list })
    }

    onSDInternationalEnter(e, index) {
        const { SDlist } = this.state
        var value = e.target.value
        var list = [...SDlist]

        list.length > 0 && list.map((data, i) => {
            if (index == i) {
                data.International = value
            }
        })

        console.log("xxxxxxxxxxxxxxxx", list)
        this.setState({ SDlist: list })
    }

    deleteSDRow() {
        const { SDlist } = this.state
        var list = [...SDlist]

        list.pop()
        console.log("xxxxxxxxxxxxxxxx", list)
        this.setState({ SDlist: list })

    }

    calculateTotalAmount(Array, property) {
        return Array.reduce((accumulator, currentItem) => {
            return accumulator + (currentItem[property] || 0);
        }, 0);
    }

    render() {
        const { isLoading, chapterList, isOpen1, firstInstallment, secondInstallment, length, height, totalExhibitionArea, StallArea,
            StalltypeID, isOpen2, StallSizeList, noOfInstallments, installmentStartDate, installmentList, installmentErr, isDoubleStorey,
            isEarlyBird, isSSI, isOpenSideCharges, sides, twoSideList, threeSideList, fourSideList, sidesList, baseArea, minBaseArea,
            inrPrice, usdPrice, earlyBirdDate, earlyBirdDomestic, earlyBirdInternational, maxAreaSSI, domesticSSI, internationalSSi,
            noOfInstallmentsErr, installmentStartDateErr, baseAreaErr, minBaseAreaErr, inrPriceErr, usdPriceErr } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                <AdminDrawer />
                <div className="main-container p-2 mt-5">
                    <div className="row ">
                        {
                            JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 1 &&
                            <div className="row ">
                                {/* <div class="card-body mt-5"> */}
                                <div class="col-md-4">
                                    <label for="validationCustom03">Total Exhibition Area<span style={{ color: 'red' }}>*</span></label>
                                    <input value={totalExhibitionArea} onChange={(e) => this.setState({ totalExhibitionArea: parseInt(e.target.value) })} type="number" class="form-control" placeholder="" />
                                </div>
                                {/* </div> */}
                                <div class="table-responsive">
                                    <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-3">
                                        <thead>
                                            <tr>
                                                <th className="text-center">Primary Chapter<span style={{ color: 'red' }}>*</span></th>
                                                <th className="text-center">Total Space<span style={{ color: 'red' }}>*</span></th>
                                                <th className="text-center">Buffer<span style={{ color: 'red' }}>*</span></th>
                                                <th className="text-center">Available</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                chapterList.length > 0 ? <>{
                                                    chapterList.map((data, i) => (
                                                        <tr key={i}>
                                                            <td >{data.ChapterName}</td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={data.TotalSpace}
                                                                    onChange={(e) => this.onChangeTotalSpace(e, i)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={data.Buffer}
                                                                    onChange={(e) => this.onChangeBuffer(e, i)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={data.Available}
                                                                    disabled={true}
                                                                // onChange={(e) => this.onEnterProductName(e, ID)}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}

                                                    <tr>

                                                        <td><strong>Total</strong></td>
                                                        <td style={{ padding: '10px 20px', fontSize: '16px' }}><strong>{this.calculateTotalAmount(chapterList, 'TotalSpace')} </strong>  </td>
                                                        <td style={{ padding: '10px 20px', fontSize: '16px' }}><strong>{this.calculateTotalAmount(chapterList, 'Buffer')}</strong></td>
                                                        <td style={{ padding: '10px 20px', fontSize: '16px' }}><strong>{this.calculateTotalAmount(chapterList, 'Available')}</strong></td>

                                                    </tr>
                                                </> :
                                                    <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div class="card-body pt-2">
                                    <button type="button" class="btn btn-primary rounded-0" style={{ float: 'right' }} onClick={(e) => this.setState({ isOpen1: true })}>
                                        Choose stall
                                    </button>
                                    <div class="table-responsive">
                                        <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-3">
                                            <thead>
                                                <tr>
                                                    <th className="text-center">Stall Size<span style={{ color: 'red' }}>*</span></th>
                                                    <th className="text-center">Stall Area<span style={{ color: 'red' }}>*</span></th>
                                                    <th className="text-center">Stall Type<span style={{ color: 'red' }}>*</span></th>
                                                    <th className="text-center">Price (INR)<span style={{ color: 'red' }}>*</span></th>
                                                    <th className="text-center">Price (USD)</th>
                                                    <th className="text-center">Subsidised Price (INR)</th>
                                                    <th className="text-center">Subsidised Price (USD)</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    StallSizeList.length > 0 ? <>{
                                                        StallSizeList.map((data, ID) => (
                                                            <tr key={ID}>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder=""
                                                                        disabled
                                                                        value={data['Stall Size']}
                                                                    // onChange={(e) => this.onChangeTotalSpace(e, i)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder=""
                                                                        disabled
                                                                        value={data['StallArea']}
                                                                    // onChange={(e) => this.onChangeTotalSpace(e, i)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder=""
                                                                        disabled
                                                                        value={data['StallTypeName']}
                                                                    // onChange={(e) => this.onChangeTotalSpace(e, i)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder=""
                                                                        value={data['Price(INR)']}
                                                                        onChange={(e) => this.enterPriceINR(e, ID)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder=""
                                                                        value={data['Price(USD)']}
                                                                        // disabled={true}
                                                                        onChange={(e) => this.enterPriceUSD(e, ID)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder=""
                                                                        value={data['Subsidised_Rate_INR']}
                                                                        // disabled={true}
                                                                        onChange={(e) => this.enterSubPriceINR(e, ID)}
                                                                    />
                                                                </td>    <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder=""
                                                                        value={data['Subsidised_Rate_USD']}
                                                                        // disabled={true}
                                                                        onChange={(e) => this.enterSubPriceUSD(e, ID)}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </> :
                                                        <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="row mt-3">
                                        {/* <div class="card-body mt-5"> */}
                                        <div class="col-md-2">
                                            <label for="validationCustom03">No of Installments<span style={{ color: 'red' }}>*</span></label>
                                            <input value={noOfInstallments} onBlur={(e) => this.onInstallmentChange(e)}
                                                onChange={(e) => this.setState({ noOfInstallments: e.target.value, noOfInstallmentsErr: "" })}
                                                type="number" class="form-control" placeholder="" />
                                            {<span className='text-danger text-start text-left d-block mt-1'>{noOfInstallmentsErr}</span>}
                                        </div>
                                        <div class="col-md-3">
                                            <label for="validationCustom03">Start Date<span style={{ color: 'red' }}>*</span></label>
                                            <DatePicker
                                                style={{ zIndex: '99999' }}
                                                selected={installmentStartDate}
                                                dateFormat={"dd/MM/yyyy"}
                                                className="form-control"
                                                onChange={(date) => this.setState({ installmentStartDate: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())), installmentStartDateErr: "" })}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                placeholderText="Installment Start Date"
                                            />
                                            {<span className='text-danger text-start text-left d-block mt-1'>{installmentStartDateErr}</span>}
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div class=" col-md-6">
                                            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-3">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center">Installment No</th>
                                                        <th className="text-center">Installment %</th>
                                                        <th className="text-center">Cutoff Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        installmentList.length > 0 ? <>{
                                                            installmentList.map((data, ID) => (
                                                                <tr key={ID}>
                                                                    <td className="text-center">
                                                                        {ID + 1}
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            placeholder=""
                                                                            value={data['Installment']}
                                                                            onChange={(e) => this.onEnterInstallment(e, ID)}
                                                                            onBlur={(e) => this.onInstallmentCheck()}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <DatePicker
                                                                            style={{ zIndex: '99999' }}
                                                                            selected={data['cutOffDate']}
                                                                            dateFormat={"dd/MM/yyyy"}
                                                                            disabled={installmentStartDate == ""}
                                                                            className="form-control"
                                                                            onChange={(date) => this.onCutoffSelect(date, ID)}
                                                                            peekNextMonth
                                                                            minDate={installmentStartDate}
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            dropdownMode="select"
                                                                            placeholderText="Cutoff Date"
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </> :
                                                            <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        {<span className='text-danger text-start text-left d-block mt-1'>{installmentErr}</span>}
                                    </div>
                                    <div className="">
                                        <>
                                            <div className="d-flex">
                                                <input
                                                    checked={isOpenSideCharges}
                                                    className="toggle-box "
                                                    id="identifier-1"
                                                    type="checkbox"
                                                    style={{ float: "left" }}
                                                    onClick={(e) => this.setState({ isOpenSideCharges: !isOpenSideCharges })}
                                                />
                                                <div >
                                                    <label htmlFor="identifier-1" style={{ fontSize: 16 }} className="">
                                                        Charges for open side
                                                    </label>
                                                </div>
                                            </div>
                                            {isOpenSideCharges &&
                                                <div1>
                                                    <div className="row m-0">
                                                        <div className="some-class d-flex">
                                                            {
                                                                sidesList.length > 0 && sidesList.map((data, i) => (
                                                                    <label htmlFor={`${'a' + i}`} style={{ maxWidth: "160px" }}>
                                                                        {" "}
                                                                        <input
                                                                            type="radio"
                                                                            className="radio m-0"
                                                                            name="x"
                                                                            defaultValue="y"
                                                                            id={`${'a' + i}`}
                                                                            checked={sides == data.OpenSidesID}
                                                                            onClick={(e) => this.setState({ sides: data.OpenSidesID })}
                                                                        />{" "}
                                                                        {data.Name} open side
                                                                    </label>
                                                                ))
                                                            }
                                                        </div>
                                                        <div className="col-md-6 ">
                                                            <div className="table-responsive mt-1">
                                                                <table
                                                                    className="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-2"
                                                                    id="myTable"
                                                                >
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Side</th>
                                                                            <th className="text-center">Domestic(%)</th>
                                                                            <th className="text-center">International(%)</th>
                                                                            <th />
                                                                        </tr>
                                                                    </thead>
                                                                    {sides == 2 &&
                                                                        <tbody>
                                                                            {twoSideList.length > 0 && twoSideList.map((data, index) => (
                                                                                <tr>
                                                                                    <td>
                                                                                        <div className="d-flex">
                                                                                            <input
                                                                                                type="text"
                                                                                                className="form-control equ-field text-center"
                                                                                                disabled
                                                                                                value={data.sideStart}
                                                                                                onChange={(e) => this.onSideStartEnter(e, index)}
                                                                                            />
                                                                                            <span className="m-2">-</span>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="form-control equ-field text-center"
                                                                                                defaultValue={50}
                                                                                                value={data.sideEnd}
                                                                                                onChange={(e) => this.onSideEndEnter(e, index)}
                                                                                            />
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control text-center"
                                                                                            value={data.domestic}
                                                                                            onChange={(e) => this.onDomesticEnter(e, index)}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control text-center"
                                                                                            value={data.International}
                                                                                            onChange={(e) => this.onInternationalEnter(e, index)}
                                                                                        />
                                                                                    </td>
                                                                                    {twoSideList.length - 1 == index && twoSideList.length != 1 &&
                                                                                        <td><i className="fa fa-trash text-danger mt-3" onClick={(e) => this.deleteRow(e)} />
                                                                                        </td>
                                                                                    }
                                                                                    <td />
                                                                                </tr>
                                                                            ))

                                                                            }
                                                                        </tbody>
                                                                    }
                                                                    {sides == 3 &&
                                                                        <tbody>
                                                                            {threeSideList.length > 0 && threeSideList.map((data, index) => (
                                                                                <tr>
                                                                                    <td>
                                                                                        <div className="d-flex">
                                                                                            <input
                                                                                                type="text"
                                                                                                className="form-control equ-field text-center"
                                                                                                disabled
                                                                                                value={data.sideStart}
                                                                                                onChange={(e) => this.onSideStartEnter(e, index)}
                                                                                            />
                                                                                            <span className="m-2">-</span>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="form-control equ-field text-center"
                                                                                                defaultValue={50}
                                                                                                value={data.sideEnd}
                                                                                                onChange={(e) => this.onSideEndEnter(e, index)}
                                                                                            />
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control text-center"
                                                                                            value={data.domestic}
                                                                                            onChange={(e) => this.onDomesticEnter(e, index)}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control text-center"
                                                                                            value={data.International}
                                                                                            onChange={(e) => this.onInternationalEnter(e, index)}
                                                                                        />
                                                                                    </td>
                                                                                    {threeSideList.length - 1 == index && threeSideList.length != 1 &&
                                                                                        <td><i className="fa fa-trash text-danger mt-3" onClick={(e) => this.deleteRow(e)} />
                                                                                        </td>
                                                                                    }
                                                                                    <td />
                                                                                </tr>
                                                                            ))

                                                                            }
                                                                        </tbody>
                                                                    }
                                                                    {sides == 4 &&
                                                                        <tbody>
                                                                            {fourSideList.length > 0 && fourSideList.map((data, index) => (
                                                                                <tr>
                                                                                    <td>
                                                                                        <div className="d-flex">
                                                                                            <input
                                                                                                type="text"
                                                                                                className="form-control equ-field text-center"
                                                                                                disabled
                                                                                                value={data.sideStart}
                                                                                                onChange={(e) => this.onSideStartEnter(e, index)}
                                                                                            />
                                                                                            <span className="m-2">-</span>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="form-control equ-field text-center"
                                                                                                defaultValue={50}
                                                                                                value={data.sideEnd}
                                                                                                onChange={(e) => this.onSideEndEnter(e, index)}
                                                                                            />
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control text-center"
                                                                                            value={data.domestic}
                                                                                            onChange={(e) => this.onDomesticEnter(e, index)}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control text-center"
                                                                                            value={data.International}
                                                                                            onChange={(e) => this.onInternationalEnter(e, index)}
                                                                                        />
                                                                                    </td>
                                                                                    {fourSideList.length - 1 == index && fourSideList.length != 1 &&
                                                                                        <td><i className="fa fa-trash text-danger mt-3" onClick={(e) => this.deleteRow(e)} />
                                                                                        </td>
                                                                                    }
                                                                                    <td />
                                                                                </tr>
                                                                            ))

                                                                            }
                                                                        </tbody>
                                                                    }
                                                                </table>
                                                            </div>
                                                            <button
                                                                type="button"
                                                                className="btn btn-outline-primary rounded-1 mt-2"
                                                                style={{ padding: "0px 10px", height: 33 }}
                                                                onClick={(e) => this.onAddNew(e)}
                                                            >
                                                                Add New
                                                            </button>
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div1>
                                            }
                                        </>

                                    </div>
                                    <div className="row mt-4">
                                        <>
                                            <div className="d-flex">
                                                <input
                                                    checked={this.state.isSDcharges}
                                                    className="toggle-box "
                                                    id="identifier-1"
                                                    type="checkbox"
                                                    style={{ float: "left" }}
                                                    onClick={(e) => this.setState({ isSDcharges: !this.state.isSDcharges })}
                                                />
                                                <div >
                                                    <label htmlFor="identifier-1" style={{ fontSize: 16 }} className="">
                                                        Charges of Security Deposit
                                                    </label>
                                                </div>
                                            </div>
                                            {this.state.isSDcharges &&
                                                <div1>
                                                    <div className="row m-0">
                                                        <div className="col-md-6 ">
                                                            <div className="table-responsive mt-1">
                                                                <table
                                                                    className="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-2"
                                                                    id="myTable"
                                                                >
                                                                    <thead>
                                                                        <tr>
                                                                            <th className="text-center">Area(sq. mtr)<span style={{ color: 'red' }}>*</span></th>
                                                                            <th className="text-center">Domestic Exhibitor<span style={{ color: 'red' }}>*</span></th>
                                                                            <th className="text-center">International Exhibitor<span style={{ color: 'red' }}>*</span></th>
                                                                            <th />
                                                                        </tr>
                                                                    </thead>
                                                                    {
                                                                        <tbody>
                                                                            {this.state.SDlist.length > 0 && this.state.SDlist.map((data, index) => (
                                                                                <tr>
                                                                                    <td>
                                                                                        <div className="d-flex">
                                                                                            <input
                                                                                                type="text"
                                                                                                className="form-control equ-field text-center"
                                                                                                disabled
                                                                                                value={data.AreaStart}
                                                                                                onChange={(e) => this.onAreaStartEnter(e, index)}
                                                                                            />
                                                                                            <span className="m-2">-</span>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="form-control equ-field text-center"
                                                                                                // defaultValue={50}
                                                                                                value={data.AreaEnd}
                                                                                                onChange={(e) => this.onAreaEndEnter(e, index)}
                                                                                            />
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control text-center"
                                                                                            value={data.domestic}
                                                                                            onChange={(e) => this.onSDDomesticEnter(e, index)}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control text-center"
                                                                                            value={data.International}
                                                                                            onChange={(e) => this.onSDInternationalEnter(e, index)}
                                                                                        />
                                                                                    </td>
                                                                                    {this.state.SDlist.length - 1 == index && this.state.SDlist.length != 1 &&
                                                                                        <td><i className="fa fa-trash text-danger mt-3" onClick={(e) => this.deleteSDRow(e)} />
                                                                                        </td>
                                                                                    }
                                                                                    <td />
                                                                                </tr>
                                                                            ))

                                                                            }
                                                                        </tbody>
                                                                    }
                                                                </table>
                                                            </div>
                                                            <button
                                                                type="button"
                                                                className="btn btn-outline-primary rounded-1 mt-2"
                                                                style={{ padding: "0px 10px", height: 33 }}
                                                                onClick={(e) => this.onAddNewSD(e)}
                                                            >
                                                                Add New
                                                            </button>
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div1>
                                            }
                                        </>

                                    </div>
                                    <div className="row mt-4">
                                        <>
                                            <div className="d-flex">
                                                <input
                                                    className="toggle-box mt-1"
                                                    id="identifier-2"
                                                    type="checkbox"
                                                    style={{ float: "left" }}
                                                    checked={isDoubleStorey}
                                                    onClick={(e) => this.setState({ isDoubleStorey: !isDoubleStorey })}
                                                />
                                                <div >
                                                    <label htmlFor="identifier-2" style={{ fontSize: 16 }}>
                                                        Double storey addition charges

                                                    </label>
                                                </div>
                                            </div>
                                            {isDoubleStorey &&
                                                <div1>
                                                    <div className="row m-0 mt-2">
                                                        {/* <label class="p-0 w-auto" style="font-size: 16px;"><input type="checkbox"> <strong>Double storey addition charges</strong></label> */}
                                                        <div className="row m-0">
                                                            <div className="col-xl-4">
                                                                <div className="row">
                                                                    <div className="col-xl-5 mt-1">
                                                                        <label>
                                                                            <strong>
                                                                                Minimum Base Area
                                                                                <span style={{ color: 'red' }}>*</span>
                                                                            </strong>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-xl-7 d-flex">
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            style={{ textAlign: "right" }}
                                                                            value={baseArea}
                                                                            onChange={(e) => this.setState({ baseArea: e.target.value, baseAreaErr: "" })}
                                                                        />
                                                                        {<span className='text-danger text-start text-left d-block mt-1'>{baseAreaErr}</span>}
                                                                        <spa className="mt-2" style={{ marginLeft: 6 }}>
                                                                            Sqm
                                                                        </spa>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="col-xl-5">
                                                                        <label>
                                                                            <strong>
                                                                                Minimum Base Area (percentage)
                                                                                <span style={{ color: 'red' }}>*</span>
                                                                            </strong>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-xl-7 d-flex">
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            style={{ textAlign: "right" }}
                                                                            value={minBaseArea}
                                                                            onChange={(e) => this.setState({ minBaseArea: e.target.value, minBaseAreaErr: "" })}
                                                                        />
                                                                        {<span className='text-danger text-start text-left d-block mt-1'>{minBaseAreaErr}</span>}
                                                                        <spa className="mt-2" style={{ marginLeft: 6 }}>
                                                                            %
                                                                        </spa>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="col-xl-6">
                                                                        <label>
                                                                            <strong>
                                                                                INR Price
                                                                                <span style={{ color: 'red' }}>*</span>
                                                                            </strong>
                                                                        </label>
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            style={{ textAlign: "right" }}
                                                                            value={inrPrice}
                                                                            onChange={(e) => this.setState({ inrPrice: e.target.value, inrPriceErr: "" })}
                                                                        />
                                                                        {<span className='text-danger text-start text-left d-block mt-1'>{inrPriceErr}</span>}
                                                                    </div>
                                                                    <div className="col-xl-6">
                                                                        <label>
                                                                            <strong>
                                                                                USD Price
                                                                                <span style={{ color: 'red' }}>*</span>
                                                                            </strong>
                                                                        </label>
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            style={{ textAlign: "right" }}
                                                                            value={usdPrice}
                                                                            onChange={(e) => this.setState({ usdPrice: e.target.value, usdPriceErr: "" })}
                                                                        />
                                                                        {<span className='text-danger text-start text-left d-block mt-1'>{usdPriceErr}</span>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-5 p-0">
                                                            <div className="mt-1"></div>
                                                        </div>
                                                    </div>
                                                </div1>
                                            }
                                        </>
                                    </div>
                                    <div className="row mt-4">
                                        <>
                                            <div className="d-flex">
                                                <input
                                                    className="toggle-box mt-1"
                                                    id="identifier-3"
                                                    type="checkbox"
                                                    style={{ float: "left" }}
                                                    checked={isEarlyBird}
                                                    onClick={(e) => this.setState({ isEarlyBird: !isEarlyBird })}
                                                />
                                                <div>

                                                    <label htmlFor="identifier-3" style={{ fontSize: 16 }}>
                                                        Early bird discount
                                                    </label>
                                                </div>
                                            </div>
                                            {isEarlyBird &&
                                                <div1>
                                                    {" "}
                                                    <div className="row m-0">
                                                        <div className="row m-0">
                                                            <div className="col-xl-3 mt-2">
                                                                <div className="row">
                                                                    <div className="col-xl-4">
                                                                        <label>
                                                                            <strong>
                                                                                End  Date
                                                                                <span style={{ color: 'red' }}>*</span>
                                                                            </strong>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-xl-8 d-flex">
                                                                        <DatePicker
                                                                            style={{ zIndex: '99999' }}
                                                                            selected={earlyBirdDate}
                                                                            dateFormat={"dd/MM/yyyy"}
                                                                            className="form-control"
                                                                            onChange={(date) => this.setState({ earlyBirdDateErr: '', earlyBirdDate: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())) })}
                                                                            peekNextMonth
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            dropdownMode="select"
                                                                            placeholderText="End Date"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row m-0">
                                                            <div className="col-xl-3 mt-2">
                                                                <div className="row">
                                                                    <div className="col-xl-4">
                                                                        <label>
                                                                            <strong>
                                                                                Domestic
                                                                                <span style={{ color: 'red' }}>*</span>
                                                                            </strong>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-xl-8 d-flex">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            style={{ textAlign: "right" }}
                                                                            value={earlyBirdDomestic}
                                                                            onChange={(e) => this.setState({ earlyBirdDomesticErr: '', earlyBirdDomestic: e.target.value })}
                                                                        />{" "}
                                                                        <spa className="mt-2" style={{ marginLeft: 6 }}>
                                                                            %
                                                                        </spa>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row m-0">
                                                            <div className="col-xl-3 mt-2">
                                                                <div className="row">
                                                                    <div className="col-xl-4">
                                                                        <label>
                                                                            <strong>
                                                                                international
                                                                                <span style={{ color: 'red' }}>*</span>
                                                                            </strong>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-xl-8 d-flex">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            style={{ textAlign: "right" }}
                                                                            value={earlyBirdInternational}
                                                                            onChange={(e) => this.setState({ earlyBirdInternationalErr: '', earlyBirdInternational: e.target.value })}
                                                                        />{" "}
                                                                        <spa className="mt-2" style={{ marginLeft: 6 }}>
                                                                            %
                                                                        </spa>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-5 p-0">
                                                            <div className="mt-1"></div>
                                                        </div>
                                                    </div>
                                                </div1>
                                            }
                                        </>

                                    </div>
                                    <div className="row mt-4">
                                        <>
                                            <div className="d-flex">
                                                <input
                                                    className="toggle-box mt-1"
                                                    id="identifier-4"
                                                    type="checkbox"
                                                    style={{ float: "left" }}
                                                    checked={isSSI}
                                                    onClick={(e) => this.setState({ isSSI: !isSSI })}
                                                />
                                                <div>

                                                    <label htmlFor="identifier-4" style={{ fontSize: 16 }}>
                                                        SSI certificate discount
                                                    </label>
                                                </div>
                                            </div>
                                            {isSSI &&
                                                <div1>
                                                    <div className="row m-0">
                                                        <div className="row m-0">
                                                            <div className="col-xl-10 mt-2">
                                                                <div className="row">
                                                                    <div className="col-xl-4">
                                                                        <label>
                                                                            <strong>
                                                                                Maximum area allowed for SSI Discount
                                                                                <span style={{ color: 'red' }}>*</span>
                                                                            </strong>
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            style={{ textAlign: "right", float: "left", width: "83%" }}
                                                                            value={maxAreaSSI}
                                                                            onChange={(e) => this.setState({ maxAreaSSIErr: '', maxAreaSSI: e.target.value })}
                                                                        />{" "}
                                                                        <spa className="mt-2" style={{ marginLeft: 6, float: "right" }}>
                                                                            Sqm
                                                                        </spa>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row m-0">
                                                            <div className="col-xl-3 mt-2">
                                                                <div className="row">
                                                                    <div className="col-xl-4">
                                                                        <label>
                                                                            <strong>
                                                                                Domestic
                                                                                <span style={{ color: 'red' }}>*</span>
                                                                            </strong>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-xl-8 d-flex">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            style={{ textAlign: "right" }}
                                                                            value={domesticSSI}
                                                                            onChange={(e) => this.setState({ domesticSSIErr: '', domesticSSI: e.target.value })}
                                                                        />{" "}
                                                                        <spa className="mt-2" style={{ marginLeft: 6 }}>
                                                                            %
                                                                        </spa>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row m-0">
                                                            <div className="col-xl-3 mt-2">
                                                                <div className="row">
                                                                    <div className="col-xl-4">
                                                                        <label>
                                                                            <strong>
                                                                                International <span style={{ color: 'red' }}>*</span>
                                                                            </strong>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-xl-8 d-flex">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            style={{ textAlign: "right" }}
                                                                            value={internationalSSi}
                                                                            onChange={(e) => this.setState({ internationalSSiErr: '', internationalSSi: e.target.value })}
                                                                        />{" "}
                                                                        <spa className="mt-2" style={{ marginLeft: 6 }}>
                                                                            %
                                                                        </spa>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-5 p-0">
                                                            <div className="mt-1"></div>
                                                        </div>
                                                    </div>
                                                </div1>
                                            }
                                        </>
                                    </div>
                                    <div class="col-md-12">
                                        <button type="button" class="btn btn-primary rounded-0 mt-4" style={{ float: 'right' }}
                                            onClick={(e) => this.updateManageArea()}>
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div >
                <Modal isOpen={isOpen1} onRequestClose={() => this.setState({ isOpen1: false })}>
                    <div className="modal-dialog">
                        <div className="modal-content confirm-logout">
                            {/* <div class="modal-header text-right p-2">
                                <button type="button " class="close pop-close" data-dismiss="modal"
                                    onClick={() => this.setState({ isOpen1: false })}
                                ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
                            </div> */}
                            <div class="modal-body">
                                <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
                                    <thead>
                                        <tr>
                                            <th className="text-center">Stall Dimensions</th>
                                            <th className="text-center">Stall Area</th>
                                            <th className="text-center">Stall type</th>
                                            <th className="text-center">
                                                <input type="checkbox"
                                                    checked={this.state.isSelectedAll}
                                                    // disabled={data.Status == "Approved" || data.Status == "Disapproved"}
                                                    onChange={(e) => this.selectAllCheckbox()}
                                                />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.stallList.length > 0 ? <>{
                                                this.state.stallList.map((data, i) => (
                                                    <tr key={Math.random()}>
                                                        <td className="text-center">{data.ExhibitionStallDimension}</td>
                                                        <td className="text-center">{data.StallArea}</td>
                                                        <td className="text-center">{data.StallTypeName}</td>
                                                        <td className="text-center">
                                                            <input type="checkbox"
                                                                checked={data.IS_Selected}
                                                                // disabled={data.Status == "Approved" || data.Status == "Disapproved"}
                                                                onChange={(e) => this.onStallSelect(i)}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </> :
                                                <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div class="modal-footer p-1">
                                <div class="form-group text-right mod-fot">
                                    <button type="button" class="btn rounded-0 btn-primary mt-3"
                                        onClick={() => this.setState({ isOpen2: true })}

                                    >Add</button>
                                    <button type="button" class="btn rounded-0 mt-3" data-dismiss="modal" style={{ marginLeft: '5px' }}
                                        onClick={this.onChooseStall}
                                    >Ok</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
                <Modal isOpen={isOpen2} onRequestClose={() => this.setState({ isOpen2: false })}>
                    <div className="modal-dialog">
                        <div className="modal-content confirm-logout">
                            <div class="modal-header">
                                <h4 class="modal-title modal_title_text">Add Stall</h4>
                            </div>
                            <div class="modal-body">
                                <div className="row ">
                                    {/* <div class="card-body mt-5"> */}
                                    <div class="col-md-4">
                                        <label for="validationCustom03">Length<span style={{ color: 'red' }}>*</span></label>
                                        <input
                                            value={length}
                                            onChange={(e) => this.onLengthChange(e.target.value)}
                                            onInput={(e) => this.maxLengthCheck(e, 4)}
                                            type="number" class="form-control" placeholder="" />
                                    </div>
                                    <div class="col-md-4">
                                        <label for="validationCustom03">Height<span style={{ color: 'red' }}>*</span></label>
                                        <input
                                            value={height}
                                            onInput={(e) => this.maxLengthCheck(e, 4)}
                                            onChange={(e) => this.onHeightChange(e.target.value)}
                                            type="number" class="form-control" placeholder="" />
                                    </div>
                                    <div class="col-md-4">
                                        <label for="validationCustom03">Stall Area<span style={{ color: 'red' }}>*</span></label>
                                        <input
                                            disabled={true}
                                            value={StallArea}
                                            //  onChange={(e) => this.setState({ totalExhibitionArea: e.target.value })}
                                            type="number" class="form-control" placeholder="" />
                                    </div>
                                    <div class="col-md-4">
                                        <label htmlFor="validationCustom03">Stall type<span className="text-red">*</span></label>
                                        <select className="form-control"
                                            value={StalltypeID}
                                            // disabled={isView}
                                            onChange={(e) => this.setState({ StalltypeID: e.target.value })}
                                        >
                                            <option value={'select'}>Select</option>
                                            {
                                                this.state.stallTypelist.length > 0 && this.state.stallTypelist.map(data => (
                                                    <option value={data.Code} key={data.Code}>{data.Description}</option>
                                                ))
                                            }
                                        </select>
                                    </div>

                                </div>

                            </div>
                            <div class="modal-footer p-1">
                                <div class="form-group text-right mod-fot">
                                    <button type="button" class="btn rounded-0 btn-primary mt-3"
                                        onClick={(e) => this.onAddStall()}
                                    >Submit</button>
                                    <button type="button" class="btn rounded-0  mt-3" data-dismiss="modal" style={{ marginLeft: '5px' }}
                                        onClick={() => this.setState({ isOpen2: false, length: '', height: '', StallArea: '', StalltypeID: '' })}
                                    >Cancel</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
            </>
        )
    }
}

export default withRouter(connect(null, { fill_action, fill_master_action, getManageAreaList_action, updateManageArea_action, addManageAreaStall_action })(ManageAreaMaster));