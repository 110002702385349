import { axiosGet, axiosPost } from "../../../../../react_axios/Axios"
import { GET_STALL_DESIGN_DETAILS_ACTION_TYPE, INSERT_UPDATE_STALL_DESIGN_ACTION_TYPE, getStallDesignDetailsUrl, insertUpdateStallDesignUrl, headers } from "../../../../../utils/constants"


export const getStallDesignDetails_action = () => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhregistrationID: sessionStorage.getItem('ExhRegistrationIDSS')
        }
        // console.log("getMeetingRoomDetails_action ----->", params)
        const res = await axiosGet(getStallDesignDetailsUrl, params, headers)

        dispatch({
            type: GET_STALL_DESIGN_DETAILS_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const insertUpdateStallDesign_action = (StallDesignFormID, FloorPlanDocument, ThreeDViewDocument, FrontViewDocument, SupportingDocument, DimentionalView, FireSafteyCertificate, Remark,
    filePath_1,
    filePath_2,
    filePath_3,
    filePath_4,
    filePath_5,
    filePath_6,
    fileName_1,
    fileName_2,
    fileName_3,
    fileName_4,
    fileName_5,
    fileName_6,
    fileType_1,
    fileType_2,
    fileType_3,
    fileType_4,
    fileType_5,
    fileType_6,
) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhregistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
            StallDesignFormID: StallDesignFormID,
            FloorPlanDocument: FloorPlanDocument,
            ThreeDViewDocument: ThreeDViewDocument,
            FrontViewDocument: FrontViewDocument,
            SupportingDocument: SupportingDocument,
            DimentionalView: DimentionalView,
            FireSafteyCertificate: FireSafteyCertificate,
            Remark: Remark,
            FilePath_1: filePath_1 != '' ? filePath_1 : null,
            FilePath_2: filePath_2 != '' ? filePath_2 : null,
            FilePath_3: filePath_3 != '' ? filePath_3 : null,
            FilePath_4: filePath_4 != '' ? filePath_4 : null,
            FilePath_5: filePath_5 != '' ? filePath_5 : null,
            FilePath_6: filePath_6 != '' ? filePath_6 : null,
            FileName_1: fileName_1 != '' ? fileName_1 : null,
            FileName_2: fileName_2 != '' ? fileName_2 : null,
            FileName_3: fileName_3 != '' ? fileName_3 : null,
            FileName_4: fileName_4 != '' ? fileName_4 : null,
            FileName_5: fileName_5 != '' ? fileName_5 : null,
            FileName_6: fileName_6 != '' ? fileName_6 : null,
            FileType_1: fileType_1 != '' ? fileType_1 : null,
            FileType_2: fileType_2 != '' ? fileType_2 : null,
            FileType_3: fileType_3 != '' ? fileType_3 : null,
            FileType_4: fileType_4 != '' ? fileType_4 : null,
            FileType_5: fileType_5 != '' ? fileType_5 : null,
            FileType_6: fileType_6 != '' ? fileType_6 : null,
        }
        // console.log("getMeetingRoomDetails_action ----->", params)
        const res = await axiosPost(insertUpdateStallDesignUrl, params)

        dispatch({
            type: INSERT_UPDATE_STALL_DESIGN_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}