import { Component, forwardRef } from "react";
import Loader from "../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import ExhibitorDrawer from "../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../common_components/exhibitor_module_header";
import ExhibitorTopMenu from "../../../common_components/exhibitor_module_topmenu";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import Footerwithdr from "../../../common_components/Footerwithdr";
import { downloadReport, toastError, taxInvoiceDoc, fetchAuthReport } from "../../../utils/constants";
import { getExhibitionTimeline_action } from "../../../actions/exhibitor_module_actions/exh_tax_invoice_action/exh_tax_invoice_action";
import { data } from "jquery";
import DatePicker from "react-datepicker";
import HeaderWithLogo from "../../../common_components/header_with_logo";
import AdminDrawer from "../../../common_components/admin_module_drawer";
import Pagination from "../../../utils/pagination";
import { axiosPost } from "../../../react_axios/Axios";
import moment from "moment";

class TaxInvoices extends Component {

    // Initialize Constructor
    constructor(props) {
        super(props)
        //States    
        this.state = {
            isLoading: false,
            taxInvoiceList: [],
            currentPage: 1
        }

    }

    componentDidMount() {
        this.taxInvoiceList()
    }

    // handle Proforma invoice
    taxInvoiceList() {

        this.setState({ isLoading: true })
        this.props.getExhibitionTimeline_action()
            .then((data) => {
                if (data.error != 1) {

                    this.setState({ isLoading: false, taxInvoiceList: data.data })


                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            }).catch((e) => {
                console.log(e)
            })
    }

    handleDownLoad = async (id) => {
        this.setState({ isLoading: true });
        try {
            var list = [];
            const mapdata1 = {
                "ParameterName": "@TaxInvoiceID",
                "ParameterValue": id,
                "ParameterDataType": "int"
            };
            list.push(mapdata1);
            const mapData = {
                "report_name": taxInvoiceDoc,
                "parameter_details": list
            };
            console.log("xxxxxxxxxxxxxxxxxxxxx", mapData)
            const res = await axiosPost(fetchAuthReport, mapData);
            this.setState({ isLoading: false });

            if (res != null) {
                var url = `${downloadReport}?SessionIdentifier=${res}&Export=true`;
                // const win = window.open(url, '_blank', 'noopener,noreferrer');
                const win = window.location.replace(url, '_blank');
                if (win != null) {
                    win.focus();
                }
            }
        } catch (err) {
            console.error(err);
        }
    }

    render() {
        const { isLoading, taxInvoiceList, currentPage } = this.state
        const recordsPerPage = 10;
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = taxInvoiceList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(taxInvoiceList.length / recordsPerPage)


        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                {
                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                        <ExhibitorHeader /> : <HeaderWithLogo />
                }
                <div className={JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ? "with-dr-container" : "with-dr-container com-height"}>
                    <div className="main-container">
                        {
                            JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                                <ExhibitorDrawer /> : <AdminDrawer />
                        }
                        {
                            JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                                <ExhibitorTopMenu /> : null
                        }

                        <div className="row">
                            <div className="step-name">
                                <p>Tax Invoice List</p>
                            </div>
                        </div>
                    </div>
                    <div className="main-container p-2 mt-1">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="table-responsive">
                                    <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1">
                                        <thead>
                                            <tr>
                                                <th>Reference No</th>
                                                <th>Exhibitor Name</th>
                                                <th>Invoice No </th>
                                                <th>Date </th>
                                                <th>Amount </th>
                                                <th>Type </th>
                                                <th>Action </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                taxInvoiceList.length > 0 ?
                                                    currentRecords.map((data, index) => (
                                                        <>
                                                            <tr key={index}>

                                                                <td>{data.ExhRegistrationNo}</td>
                                                                <td>{data.CompanyName}</td>
                                                                <td>{data.TaxInvoice_No}</td>
                                                                <td>{data.TaxInvoice_Date != null ? moment(data.TaxInvoice_Date).format("DD/MM/YYYY") : ''}</td>
                                                                <td>{data.Total_Payable}</td>
                                                                <td>{data.ExhibitorType}</td>
                                                                <td>
                                                                    <i className="fa fa-download"
                                                                        style={{ cursor: 'pointer' }}
                                                                        aria-hidden="true" onClick={e => this.handleDownLoad(data.TaxInvoiceID)}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )) : <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                                            }

                                        </tbody>
                                    </table>
                                </div>
                                <div className="mt-2 float-left">
                                    {
                                        taxInvoiceList.length > 0 ?
                                            <Pagination
                                                nPages={nPages}
                                                currentPage={currentPage}
                                                setCurrentPage={this.updateItem}
                                            /> : <></>
                                    }
                                    <div className="tab-rec " style={{ float: "right" }}>
                                        <p>{`${currentPage} of ${nPages} pages`}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Footerwithdr /> */}
            </>

        )
    }
}

export default withRouter(connect(null, { getExhibitionTimeline_action })(TaxInvoices));