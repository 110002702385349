import { GET_MANUAL_FORM_PRICES, INSERT_UPDATE_MANUAL_FORM_PRICES } from "../../../utils/constants";

const initialState = [];

function exhManualFormPricesReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case GET_MANUAL_FORM_PRICES:
            return [...state, payload];
        case INSERT_UPDATE_MANUAL_FORM_PRICES:
            return [...state, payload];

        default:
            return state;
    }
};

export default exhManualFormPricesReducer