import { axiosGet, axiosPost } from "../../../react_axios/Axios";
import { DEL_CIRCULAR_ACTION_TYPE, GET_CIRCULAR_COMPANY_ACTION_TYPE, GET_CIRCULAR_LIST_ACTION_TYPE, INSERT_CIRCULAR_ACTION_TYPE, deleteCircularUrl, getCircularCompantUrl, getCircularListUrl, headers, insertCircularUrl } from "../../../utils/constants";


export const getCircularCompany_action = (paymentTypeId, exhibitorTypeId, companyName) => async (dispatch) => {
    try {
        const params = {
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            PaymentTypeID: paymentTypeId,
            ExhibitorTypeID: exhibitorTypeId,
            CompanyName: companyName
        }
        console.log("getCircularCompany_action Action------->", params)

        const res = await axiosGet(getCircularCompantUrl, params, headers)

        dispatch({
            type: GET_CIRCULAR_COMPANY_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getCircularList_action = () => async (dispatch) => {
    try {
        const params = {
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID
        }
        console.log("getCircularList_action Action------->", params)

        const res = await axiosGet(getCircularListUrl, params, headers)

        dispatch({
            type: GET_CIRCULAR_LIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const insertCicular_action = (circularDate, circularTitle, circularFile, list, circularFileType, isSendMail) => async (dispatch) => {
    try {
        const mapData = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            CircularID: null,
            CircularDate: circularDate,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            title: circularTitle,
            Attachment: circularFile,
            circularListData: list,
            AttachmentType: circularFileType,
            isSendMail: isSendMail,
        }
        console.log("insertCicular_action Action------->", mapData)

        const res = await axiosPost(insertCircularUrl, mapData, headers)

        dispatch({
            type: INSERT_CIRCULAR_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const delCircular_action = (circularId) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            CircularID: circularId
        }
        console.log("delCircular_action Action------->", params)

        const res = await axiosGet(deleteCircularUrl, params, headers)

        dispatch({
            type: DEL_CIRCULAR_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}