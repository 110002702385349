import { axiosGet } from "../../../react_axios/Axios";
import { GET_EXHIBITOR_MANUAL_FORM_SUMMARY_EXPORT, GET_EXHIBITOR_MANUAL_FORM_SUMMARY_LIST, getExhibitorManualFormSummaryExportUrl, getExhibitorManualFormSummaryListUrl, headers } from "../../../utils/constants";

export const getExhibitorManualFormSummaryList_action = (exhibitor, exhibitorTypeId) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            AccountID: JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            CompanyName: exhibitor,
            ExhTypeID: exhibitorTypeId
        }
        console.log("getExhibitorManualFormSummary_action -Action--------------------------->", params)

        const res = await axiosGet(getExhibitorManualFormSummaryListUrl, params, headers);
        dispatch({
            type: GET_EXHIBITOR_MANUAL_FORM_SUMMARY_LIST,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const getExhibitorManualFormSummaryExport_action = () => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
        }
        console.log("getExhibitorManualFormSummaryExport_action -Action--------------------------->", params)

        const res = await axiosGet(getExhibitorManualFormSummaryExportUrl, params, headers);
        dispatch({
            type: GET_EXHIBITOR_MANUAL_FORM_SUMMARY_EXPORT,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}