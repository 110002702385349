import { Component, forwardRef } from "react";
import { ToastContainer } from "react-toastify";
import Loader from "../../../../utils/Loader";

import AdminDrawer from "../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../common_components/header_with_logo";
import { withRouter } from "../../../../utils/withRouter";
import { connect } from "react-redux";
import { downloadReport, toastError, toastSuccess, applicationHistoryExhibitorId, exhibitionId } from "../../../../utils/constants";
import { getApplicationHistoryList_action } from '../../../../actions/admin_module_action/manage_area/application_history_action/application_history_action'
import Pagination from "../../../../utils/pagination";
import { fill_action } from '../../../../actions/fillCombo_action';
import moment from "moment";
import Workbook from 'react-excel-workbook'
import * as XLSX from 'xlsx';
class ApplicationHistory extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            currentPage: 1,
            ApplicationHistoryList: [],
            exhibitorId: '',
            exhibitorList: [],
            BookingDate: '',
            ApplicationRefno: '',
            exhibitionId: '',
            exhibitionList: []
        }

        this.updateItem = this.updateItem.bind(this)
    }

    // On Component Load
    componentDidMount() {
        this.getFillList(applicationHistoryExhibitorId, null, '', '', '', '');
        this.getFillList(exhibitionId, null, '', '', '', '');
        this.getApplicationHistoryList()

    }

    // get fill List dropdown  
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, exhRegistrationID) {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value, exhRegistrationID)
            .then((data) => {
                if (data.error != 1) {
                    // voucher list
                    if (actionID === applicationHistoryExhibitorId) {
                        // console.log("exhibitorList", data.data)
                        this.setState({
                            exhibitorList: data.data, isLoading: false,
                        })
                    }
                    if (actionID === exhibitionId) {
                        // console.log("voucher type data", data.data)
                        this.setState({
                            exhibitionList: data.data, isLoading: false,
                        })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    getApplicationHistoryList() {
        const { exhibitorId } = this.state
        this.setState({ isLoading: true })
        this.props.getApplicationHistoryList_action(exhibitorId)
            .then((data) => {
                if (data.error != 1) {

                    if (data.data != '') {
                        if (data.data[0]['emptyData'] != 'empty') {
                            this.setState({
                                isLoading: false,
                                ApplicationHistoryList: data.data
                            })
                        } else {
                            this.setState({
                                isLoading: false,
                                ApplicationHistoryList: []
                            })
                        }
                    } else {
                        this.setState({
                            isLoading: false,
                            ApplicationHistoryList: data.data
                        })
                    }

                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })

    }

    // to create excel file
    exportToExcel() {
        const { ApplicationHistoryList } = this.state

        ApplicationHistoryList.map((data) => {
            delete data.AccountID
            delete data.CountryID
            delete data.CreatedBy
            delete data.ExhRegistrationID
            delete data.ExhRegistrationLogID
            delete data.ExhibitionID
            delete data.ExhibitionStallSizeID
            delete data.InvCountryID
            delete data.Modifiedy
            delete data.PrimaryChapter
            delete data.StallTypeID
            delete data.StatusID
        })

        // Create a new workbook
        const wb = XLSX.utils.book_new();

        // Convert the data to a worksheet
        const ws = XLSX.utils.json_to_sheet(ApplicationHistoryList);

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        // Save the workbook to an Excel file
        XLSX.writeFile(wb, 'Application_History.xlsx');
    };

    handleClear() {
        const { ApplicationHistoryList } = this.state

        this.setState({
            exhibitorId: '',
            BookingDate: '',
            ApplicationRefno: '',
            exhibitionId: '',
        })
        setTimeout(() => {
            this.getApplicationHistoryList()
        }, 100);
    }


    updateItem(item) {
        const { ApplicationHistoryList } = this.state
        this.setState({ currentPage: item });
        const recordsPerPage = 10;
        const indexOfLastRecord = item * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = ApplicationHistoryList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(ApplicationHistoryList.length / recordsPerPage)
    }

    onExhibitor(e) {
        const { exhibitorList } = this.state

        this.setState({ exhibitorId: e.target.value })

        if (e.target.value != '' && e.target.value != 'Select') {
            exhibitorList.map((data) => {
                if (data.ExhRegistrationID == e.target.value) {
                    this.setState({
                        BookingDate: data.BookingDate,
                        ApplicationRefno: data.ApplicationRefno,
                    })
                }
            })
            setTimeout(() => {
                this.getApplicationHistoryList()
            }, 100);
        } else {
            this.setState({
                BookingDate: '',
                ApplicationRefno: '',
            })
            this.setState({ exhibitorId: '' })
        }
    }

    onExhibitionSelect(e) {
        const { exhibitorList } = this.state
        const value = e.target.value

        if (value != '' && value != 'Select') {
            this.setState({ exhibitionId: value })
            this.getFillList(applicationHistoryExhibitorId, value, '', '', '', '');
            this.getFillList(exhibitionId, value, '', '', '', '');
        } else {
            this.setState({ exhibitionId: '', exhibitorId: '', BookingDate: '', ApplicationRefno: '' })
            this.getFillList(applicationHistoryExhibitorId, null, '', '', '', '');
            this.getFillList(exhibitionId, null, '', '', '', '');
        }
    }

    render() {
        const { isLoading, ApplicationHistoryList, exhibitorId, exhibitorList, currentPage, BookingDate, ApplicationRefno, exhibitionId, exhibitionList } = this.state

        const recordsPerPage = 10;
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = ApplicationHistoryList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(ApplicationHistoryList.length / recordsPerPage)

        return (
            <>

                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <AdminDrawer />

                    <div class="main-content">

                        <div class="row mt-1 pt-3 pb-3 p-2 " style={{ alignItems: 'center' }}>
                            <div className="col-md-3">
                                <label>Exhibition</label>
                                <select
                                    className="form-control"
                                    id="roledb"
                                    // style={{ textAlign: "left" }}
                                    value={exhibitionId}
                                    onChange={(e) => this.onExhibitionSelect(e)}
                                >
                                    <option>Select</option>
                                    {exhibitionList.length > 0 && exhibitionList.map((data) => (
                                        <option key={data.ExhibitionID
                                        } value={data.ExhibitionID
                                        }>{data.ExhibitionName}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-3">
                                <label>Exhibitor</label>
                                <select
                                    className="form-control"
                                    id="roledb"
                                    // style={{ textAlign: "left" }}
                                    value={exhibitorId}
                                    onChange={(e) => this.onExhibitor(e)}
                                >
                                    <option>Select</option>
                                    {exhibitorList.length > 0 && exhibitorList.map((data) => (
                                        <option key={data.ExhRegistrationID
                                        } value={data.ExhRegistrationID
                                        }>{data.ExhibitorName}</option>
                                    ))}
                                </select>
                            </div>
                            <div class=" pt-3 col-md-3" style={{ flexDirection: 'column', textAlign: 'left', paddingLeft: '30px' }}>
                                <span >Booking Date:&nbsp;&nbsp;</span>
                                <span >{BookingDate != null && BookingDate != '' ? moment(BookingDate).format('DD-MM-YYYY') : ''}</span>
                            </div>
                            <div class="pt-3 col-md-3  " style={{ flexDirection: 'column', textAlign: 'left' }}>
                                <span >Application Ref No:&nbsp;&nbsp; </span>
                                <span >{ApplicationRefno != '' ? ApplicationRefno : ''}</span>
                            </div>


                        </div>

                        <div class="row p-2 mb-2" >
                            <div class="btn-group w-auto" style={{ justifyContent: 'center' }}>
                                {/* <button class="btn rounded-0 btn-primary"
                                    onClick={() => this.getApplicationHistoryList()}
                                    style={{ padding: "4px 13px" }}>Search</button> */}
                                <button class="btn rounded-0 btn-primary"
                                    onClick={() => this.handleClear()}
                                    style={{ padding: "4px 13px", marginLeft: "10px" }}>Clear</button>
                                {
                                    ApplicationHistoryList != '' ?
                                        <button class="btn rounded-0 btn-primary"
                                            onClick={() => this.exportToExcel()}
                                            style={{ padding: "4px 13px", marginLeft: "10px" }}>Download Excel</button>
                                        : <></>
                                }
                            </div>
                        </div>

                        <div class="table-responsive mb-5 p-2">
                            <table class="table border text-nowrap text-md-nowrap table-bordered mb-0 ">
                                <thead>
                                    <tr>
                                        <th>Exhibuter Name</th>
                                        <th>Modified Date</th>
                                        <th>Stall Type</th>
                                        <th>Area</th>
                                        <th>Open Side</th>
                                        <th>Booking Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        currentRecords.length > 0 && currentRecords != null ?
                                            currentRecords.map((data, i) => (
                                                <tr>
                                                    <td>{data.CompanyName}</td>
                                                    <td>{data.ModifiedDate != null ? moment(data.ModifiedDate).format('DD-MM-YYYY') : ''}</td>
                                                    <td>{data.StallTypeName}</td>
                                                    <td>{data.StallArea}</td>
                                                    <td>{data.OpenSides}</td>
                                                    <td>{data.BookingStatus}</td>

                                                </tr>
                                            )) : (<tr><td colSpan={10} className='text-center'>Record not found</td></tr>)
                                    }

                                </tbody>
                            </table>
                            <div className="mt-2 float-left">
                                {
                                    ApplicationHistoryList.length > 0 ?
                                        <Pagination
                                            nPages={nPages}
                                            currentPage={currentPage}
                                            setCurrentPage={this.updateItem}
                                        /> : <></>
                                }
                                <div className="tab-rec " style={{ float: "right" }}>
                                    <p>{`${currentPage} of ${nPages} pages`}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </>

                {/* <Footerwithdr /> */}

            </>
        );
    }


}

export default withRouter(connect(null, { fill_action, getApplicationHistoryList_action })(ApplicationHistory));