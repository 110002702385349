import { GET_RENTAL_AUDIO_AND_VIDEO_DETAILS_ACTION_TYPE, INSERT_UPDATE_RENTAL_AUDIO_AND_VIDEO_ACTION_TYPE } from "../../../../../utils/constants";

const initialState = [];

function rentalAudioAndVideoReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case GET_RENTAL_AUDIO_AND_VIDEO_DETAILS_ACTION_TYPE:
            return [...state, payload];
        case INSERT_UPDATE_RENTAL_AUDIO_AND_VIDEO_ACTION_TYPE:
            return [...state, payload];

        default:
            return state;
    }
};

export default rentalAudioAndVideoReducer;