import Loader from "../utils/Loader";
import { useState } from 'react';
import moment from "moment";


const VisitorHeader = () => {

    const [isLoading, setLoader] = useState(false);


    // Exhibition From Date
    const dateString = sessionStorage.getItem('exhibitionDateFrom');
    const dateStringMoment = moment.utc(dateString).format('DD MMMM YYYY')
    const dateObject = new Date(dateStringMoment);
    const fromDay = dateObject.getDate();
    const fromMonth = dateObject.toLocaleString('default', { month: 'long' });
    const fromYear = dateObject.getFullYear();
    const fromDate = `${fromDay} ${fromMonth} ${fromYear}`;
    console.log('dateString', dateString)
    console.log('dateStringMoment', dateStringMoment)
    console.log('dateObject', dateObject)

    // Exhibition to Date
    const dateString1 = sessionStorage.getItem('exhibitionDateTo');
    const dateString1Moment = moment.utc(dateString1).format('DD MMMM YYYY')
    const dateObject1 = new Date(dateString1Moment);
    const toDay = dateObject1.getDate();
    const toMonth = dateObject1.toLocaleString('default', { month: 'long' });
    const toYear = dateObject1.getFullYear();
    const toDate = `${toDay} ${toMonth} ${toYear}`;

    // Exhibition Logo File Path
    const logoPath = sessionStorage.getItem('sessionLogoPath')
    const ExhibitionEmail = JSON.parse(sessionStorage.getItem('sessionExhibitionEmail'))
    const ExhibitionMobileNo = JSON.parse(sessionStorage.getItem('sessionExhibitionMobileNo'))

    // Exhibition Address
    const address = sessionStorage.getItem('exhibitionAddress')

    return (
        <>
            {isLoading ? (
                <Loader></Loader>
            ) : (
                null
            )}
            <div className="app-header header sticky">
                <div className="app-header header sticky">
                    <div className="container-fluid main-container">
                        <div className="d-flex">
                            <a className="logo-horizontal " href="index.html">
                                <img
                                    src={logoPath} className="header-brand-img desktop-logo" alt="logo" />
                            </a>
                            <div class="side-header">
                                <a class="header-brand1" >
                                    <img src={logoPath} class="header-brand-img header-brand-img-1 light-logo1" alt="top-left logo" />
                                </a>
                            </div>
                            <p className=" header-logo-name">{fromDate} - {toDate} <br></br>
                                {address}
                            </p>
                            <p className=" header-assistance">
                                For Assistance
                                <br>
                                </br>
                                {ExhibitionMobileNo.length > 0 ? ExhibitionMobileNo.map((data, i) => (<>
                                    {data}{i != ExhibitionMobileNo.length - 1 ? ',' : null}
                                </>
                                )) : null}
                                <br>
                                    {/* ` ${(data)}`(i != ExhibitionMobileNo.length - 1 ? ',' : null) */}
                                </br>
                                {ExhibitionEmail.length > 0 ? ExhibitionEmail.map((data, i) => (
                                    <a href={data} > {data} {i != ExhibitionEmail.length - 1 ? '/' : null} </a>
                                )) : null}
                            </p>


                            <div class="right-logo">
                                <p className='right-logo-text'>Owned and Organised by</p>
                                <img src="../images/itme-right-logo.png" className="header-brand-img desktop-logo" alt="logo" />
                            </div>

                        </div>
                    </div>
                </div>


            </div>
        </>
    )
}

export default VisitorHeader;