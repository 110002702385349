import { axiosGet, axiosPost } from "../../../react_axios/Axios";
import { GET_VISITOR_LIST_ACTION_TYPE, SEND_USER_CRED_ACTION_TYPE, getVisitorListUrl, sendUserCredVisUrl, headers, sendVisitorBadgeUrl, SEND_VISITOR_BADGE_ACTION_TYPE, insertVisitorBadgeAccessLogUrl, INSERT_VISITOR_BADGE_ACCESS_LOG_ACTION_TYPE, getVisitorBadgeAccessLogUrl, GET_VISITOR_BADGE_ACCESS_LOG_ACTION_TYPE } from "../../../utils/constants";


export const visitorList_action = (visitor, company, ExhibitionID, visitorStatusId, visRegNo) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExhibitionID != '' && ExhibitionID != null ? ExhibitionID : sessionStorage.getItem("sessionExhibitionID"),
            CompanyName: company,
            VisitorName: visitor,
            VisitorRegistrationNo: visRegNo,
            Status: visitorStatusId != "" ? visitorStatusId : null
        }
        console.log("visitorList_action -Action--------------------------->", params)

        const res = await axiosGet(getVisitorListUrl, params, headers);
        dispatch({
            type: GET_VISITOR_LIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const sendUserCred_action = (ExhVisID) => async (dispatch) => {
    try {
        const params = {
            ExVisitorID: ExhVisID
        }
        console.log("sendUserCred_action -Action--------------------------->", params)
        const res = await axiosGet(sendUserCredVisUrl, params, headers);
        dispatch({
            type: SEND_USER_CRED_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const sendVisitorBadge_action = (list) => async (dispatch) => {
    try {
        const params = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            visitorList: list
        }
        console.log("sendUserCred_action -Action--------------------------->", params)
        const res = await axiosPost(sendVisitorBadgeUrl, params, headers);
        dispatch({
            type: SEND_VISITOR_BADGE_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const insertVisitorBadgeAccessLog_action = (VisID, Action) => async (dispatch) => {
    try {
        const mapdata = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            VISITORID: VisID,
            Action: Action
        }
        console.log("insertVisitorBadgeAccessLog_action -Action--------------------------->", mapdata)

        const res = await axiosPost(insertVisitorBadgeAccessLogUrl, mapdata, headers);
        dispatch({
            type: INSERT_VISITOR_BADGE_ACCESS_LOG_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const getVisitorBadgeAccessLog_action = () => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
        }
        console.log("getVisitorBadgeAccessLog_action -Action--------------------------->", params)

        const res = await axiosGet(getVisitorBadgeAccessLogUrl, params, headers);
        dispatch({
            type: GET_VISITOR_BADGE_ACCESS_LOG_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}