import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { toastError, toastSuccess, exhDescriptionActionId } from "../../../../../utils/constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import Pagination from "../../../../../utils/pagination";
import { fill_action } from '../../../../../actions/fillCombo_action';
import { insertUpdateExhSeminar_action, getExhSeminarDetails_action, deleteExhSeminar_action } from '../../../../../actions/admin_module_action/master_action/exhibition_master_action/exh_seminar_action';

class Exh_seminar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            currentPage: 1,
            ExihibitionID: '',
            seminarList: [
                {
                    SeminarID: '',
                    C_SeminarID: '',
                    id: 1,
                    SeqNo: 1,
                    parentId: 1,
                    isParent: true,
                    isChild: false,
                    Day: '',
                    DateTime: '',
                    Description: '',
                    Speaker: '',
                    All: false,
                    Exhibitor: false,
                    Visitor: false,
                    Delegate: false,
                }
            ]
        }
    }

    componentDidMount() {
        this.getFillList(exhDescriptionActionId, '', '', '', '')

        this.setState({
            ExihibitionID: this.props.location.state.ExihibitionID != undefined ? (this.props.location.state != null && this.props.location.state.ExihibitionID != null ? this.props.location.state.ExihibitionID : '') : this.props.id != null && this.props.id != undefined ? this.props.id : '',
        })
        setTimeout(() => {
            console.log(this.state.ExihibitionID)

            this.getExhSeminarDetails()
        }, 200);
    }

    getFillList(value, userID, keyword, keyvalue) {
        // this.setState({ isLoading: true })
        this.props
            .fill_action(value, (userID == '' || userID == null) ? null : userID, keyword != '' ? keyword : null, keyvalue != '' ? keyvalue : null)
            .then((data) => {
                if (data.error != 1) {
                    if (value == exhDescriptionActionId) {
                        this.setState({
                            DescriptionList: data.data,
                            tempDescriptionList: data.data,
                        })
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    getExhSeminarDetails() {
        const { ExihibitionID } = this.state
        this.setState({ isLoading: true })
        this.props.getExhSeminarDetails_action(ExihibitionID)
            .then((data) => {
                if (data.error != 1) {
                    if (data.data != '') {
                        const seminars = [...data.data].sort((a, b) => a.SeqNo - b.SeqNo);

                        const output = [];
                        let parentCounter = 1;
                        var index = 1
                        seminars.forEach(seminar => {
                            seminar.Exhibitor = seminar.IsExhibitor
                            seminar.Visitor = seminar.IsVisitor
                            seminar.Delegate = seminar.IsDelegate

                            if (seminar.Exhibitor && seminar.Visitor && seminar.Delegate) {
                                seminar.All = true
                            } else {
                                seminar.All = false
                            }
                            if (seminar.ParentSeminarID === null) {
                                // Assign parent id
                                seminar.id = parentCounter;
                                seminar.parentId = parentCounter;
                                output.push(seminar);
                                seminar.isParent = true
                                // Increment parent counter for next independent parent seminar
                                parentCounter += 1;
                            } else {
                                // Find the parent seminar in the output array
                                const parentSeminar = output.find(s => s.SeminarID === seminar.ParentSeminarID);
                                if (parentSeminar) {
                                    // Assign child id based on the parent's id
                                    seminar.parentId = parentSeminar.parentId;
                                    seminar.id = `${parentSeminar.id}.${output.filter(s => s.ParentSeminarID === seminar.ParentSeminarID).length + 1}`;
                                    output.push(seminar);
                                }
                            }
                        });

                        console.log("result--------->", output)
                        this.setState({ seminarList: output })
                    }
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    deleteExhSeminarDetails(id) {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props.deleteExhSeminar_action(id)
            .then((data) => {
                if (data.error != 1) {
                    if (data.data != '') {
                        this.getExhSeminarDetails()
                    }
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    insertUpdateExhSeminar() {
        const { ExihibitionID, seminarList } = this.state
        this.setState({ isLoading: true })
        console.log('seminarList------>insert',seminarList)
        const parentList = []
        const childList = []

        seminarList.map((item) => {
            if (item.isParent) {
                parentList.push(
                    {
                        P_SeminarID: item.SeminarID,
                        P_ParentNo: item.parentId,
                        P_Day: item.Day,
                        P_DateTime: item.DateTime,
                        P_Description: item.Description,
                        P_Speaker: item.Speaker,
                        P_SeqNo: item.SeqNo,
                        P_IsExhibitor: item.Exhibitor,
                        P_IsVisitor: item.Visitor,
                        P_IsDelegate: item.Delegate,
                    }
                )
            } else {
                childList.push(
                    {
                        C_SeminarID: item.SeminarID,
                        C_ParentNo: item.parentId,
                        C_Day: item.Day,
                        C_DateTime: item.DateTime,
                        C_Description: item.Description,
                        C_Speaker: item.Speaker,
                        C_SeqNo: item.SeqNo,
                        C_IsExhibitor: item.Exhibitor,
                        C_IsVisitor: item.Visitor,
                        C_IsDelegate: item.Delegate,
                    }
                )
            }
        })

        console.log("parentList--->", parentList)
        console.log("childList--->",)

        this.props.insertUpdateExhSeminar_action(ExihibitionID, parentList, childList)
            .then((data) => {
                if (data.error != 1) {
                    this.getExhSeminarDetails()
                    toastSuccess("data updated Successfuly!")
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }


    addChild(parentId, position = null) {
        const { seminarList } = this.state;

        // Find the parent index
        const parentIndex = seminarList.findIndex(item => item.id === parentId);

        if (parentIndex !== -1) {
            // Determine the position where the new child should be inserted
            let insertIndex = position !== null ? position : parentIndex + 1;

            // Create the new child item
            const parentBaseId = Math.floor(parentId);
            const children = seminarList.filter(item => Math.floor(item.id) === parentBaseId && !item.isParent);
            const maxChildId = children.length > 0 ? Math.max(...children.map(item => item.id)) : parentId;
            const newId = parseFloat((maxChildId + 0.1).toFixed(1));


            const newItem = {
                id: newId,
                SeqNo: seminarList.length + 1,
                parentId: parentBaseId,
                isParent: false,
                isChild: true,
                Day: '',
                DateTime: '',
                Description: '',
                Speaker: '',
                All: false,
                Exhibitor: false,
                Visitor: false,
                Delegate: false,
                SeminarID: ''
            };

            // Insert the new child item
            seminarList.splice(insertIndex, 0, newItem);

            // Adjust the ids of all subsequent children
            let childCounter = 1;
            for (let i = 0; i < seminarList.length; i++) {
                if (Math.floor(seminarList[i].id) === parentBaseId && !seminarList[i].isParent) {
                    seminarList[i].id = parseFloat(`${parentBaseId}.${childCounter}`);
                    childCounter++;
                }
            }
            seminarList.map((item, i) => {
                item.SeqNo = i + 1
            })
            // console.log('seminarList----->', seminarList);
            // Update the state with the new list
            this.setState({ seminarList });
        } else {
            console.log('Parent ID not found');
        }
    }

    addParent(i) {
        const { seminarList } = this.state
        const array = []
        seminarList.map(data => {
            if (data.isParent) {
                array.push(data)
            }
        })
        seminarList.push(
            {
                id: array.length + 1,
                parentId: array.length + 1,
                SeqNo: seminarList.length + 1,
                isParent: true,
                isChild: false,
                Day: '',
                DateTime: '',
                Description: '',
                Speaker: '',
                All: false,
                Exhibitor: false,
                Visitor: false,
                Delegate: false,
                SeminarID: ''
            }
        )
        console.log('seminarList----->', seminarList);
        this.setState({ seminarList: seminarList })
    }

    checkForDuplicates(arr, keys) {
        const seen = new Set();
        for (const obj of arr) {
            const uniqueKey = keys.map(key => obj[key]).join('|');
            if (seen.has(uniqueKey)) {
                return true; // Duplicate found
            }
            seen.add(uniqueKey);
        }
        return false; // No duplicates found
    }


    deleteExhSeminarList(id, SeminarID) {
        const { seminarList } = this.state;
        if (SeminarID != '') {
            this.deleteExhSeminarDetails(SeminarID)
        } else {
            // Identify if the item to be deleted is a parent
            const itemToDelete = seminarList.find(item => item.id === id);
            const isParent = itemToDelete.isParent;

            let updatedList;

            if (isParent) {
                // Delete the parent and all its children
                const parentBaseId = Math.floor(id);
                updatedList = seminarList.filter(item => Math.floor(item.id) !== parentBaseId);
            } else {
                // Delete only the child
                updatedList = seminarList.filter(item => item.id !== id);
            }

            // Reassign IDs for remaining parents and their children
            let parentCounter = 1;
            updatedList = updatedList.map((item) => {
                if (item.isParent) {
                    // Update parent ID to be sequential
                    const newParentId = parentCounter;
                    const oldParentId = Math.floor(item.id);

                    // Update this parent ID
                    item.id = newParentId;
                    item.parentId = newParentId;

                    // Update all its children IDs
                    let childCounter = 1;
                    updatedList.forEach(childItem => {
                        if (Math.floor(childItem.id) === oldParentId && !childItem.isParent) {
                            childItem.id = parseFloat(`${newParentId}.${childCounter}`);
                            childCounter++;
                        }
                    });

                    parentCounter++;
                }
                return item;
            });

            if (updatedList.length > 0) {
                updatedList.map((item, i) => {
                    item.SeqNo = i + 1
                })
                console.log('seminarList Delete----->', updatedList);
                this.setState({ seminarList: updatedList });
            } else {
                this.setState({
                    seminarList: [
                        {
                            id: 1,
                            SeqNo: 1,
                            isParent: true,
                            isChild: false,
                            Day: '',
                            DateTime: '',
                            Description: '',
                            Speaker: '',
                            All: false,
                            Exhibitor: false,
                            Visitor: false,
                            Delegate: false,
                        }
                    ]
                });
            }
        }
    }


    onInputChange(e, i, param, flag) {
        const { seminarList } = this.state
        // const event = e.target.value
        const updateSelection = (data, selectedSeminarID, value) => {
            // Find the selected item
            const itemToSelect = data.find(item => item.SeminarID === selectedSeminarID);

            if (itemToSelect) {
                // Update the selected item
                const childData = []
                data.map((obj) => {
                    if (obj.ParentSeminarID === itemToSelect.ParentSeminarID) {
                        childData.push(obj)
                    }
                })

                console.log("childData-->", childData)
                // If the selected item has a parent, update the parent's isSelect property
                if (itemToSelect.ParentSeminarID !== null) {
                    const parentItem = data.find(item => item.SeminarID === itemToSelect.ParentSeminarID);
                    if (parentItem) {
                        if (value == 1) {
                            parentItem.Exhibitor = 1;
                        } else if (value == 2) {
                            parentItem.Visitor = 1;
                        } else if (value == 3) {
                            parentItem.Delegate = 1;
                        } else {
                            parentItem.Exhibitor = 1;
                            parentItem.Visitor = 1;
                            parentItem.Delegate = 1;
                            parentItem.All = 1;
                        }
                    }
                }
            }

            return data;
        };

        function mapData(Array, SeminarID, value) {
            const childData = []
            Array.map((obj) => {
                if (obj.ParentSeminarID === SeminarID) {
                    childData.push(obj)
                }
            })
            console.log("childData---->", childData)
            var checked
            if (value == 1) {
                checked = childData.some(item => item.Exhibitor == 1)
            } else if (value == 2) {
                checked = childData.some(item => item.Visitor == 1)
            } else if (value == 3) {
                checked = childData.some(item => item.Delegate == 1)
            } else {
                checked = childData.some(item => item.Exhibitor == 1 || item.Visitor == 1 || item.Delegate == 1)
            }
            return checked
        }

        seminarList.map((data, index) => {
            if (i === index) {
                if (param == 'Day') {
                    data.Day = e.target.value
                } else if (param == 'DateTime') {
                    data.DateTime = e.target.value
                } else if (param == 'Description') {
                    data.Description = e.target.value
                } else if (param == 'Speaker') {
                    data.Speaker = e.target.value
                } else if (param == 'All') {
                    if (data.ParentSeminarID != null) {
                        if (e.target.checked) {
                            updateSelection(seminarList, data.SeminarID, 4)
                        }
                        data.All = e.target.checked
                        data.Exhibitor = e.target.checked
                        data.Visitor = e.target.checked
                        data.Delegate = e.target.checked
                    } else {
                        const value = mapData(seminarList, data.SeminarID, 4)
                        if (value) {
                            data.All = 1
                            data.Exhibitor = 1
                            data.Visitor = 1
                            data.Delegate = 1
                        } else {
                            data.All = e.target.checked
                            data.Exhibitor = e.target.checked
                            data.Visitor = e.target.checked
                            data.Delegate = e.target.checked
                        }
                    }
                } else if (param == 'Exhibitor') {
                    if (data.ParentSeminarID != null) {
                        if (e.target.checked) {
                            updateSelection(seminarList, data.SeminarID, 1)
                        }
                        data.Exhibitor = e.target.checked
                    } else {
                        const value = mapData(seminarList, data.SeminarID, 1)
                        if (value) {
                            data.Exhibitor = 1
                        } else {
                            data.Exhibitor = e.target.checked
                        }
                    }
                } else if (param == 'Visitor') {
                    if (data.ParentSeminarID != null) {
                        if (e.target.checked) {
                            updateSelection(seminarList, data.SeminarID, 2)
                        }
                        data.Visitor = e.target.checked
                    } else {
                        const value = mapData(seminarList, data.SeminarID, 2)
                        if (value) {
                            data.Visitor = 1
                        } else {
                            data.Visitor = e.target.checked
                        }
                    }
                } else if (param == 'Delegate') {
                    if (data.ParentSeminarID != null) {
                        if (e.target.checked) {
                            updateSelection(seminarList, data.SeminarID, 3)
                        }
                        data.Delegate = e.target.checked
                    } else {
                        const value = mapData(seminarList, data.SeminarID, 3)
                        if (value) {
                            data.Delegate = 1
                        } else {
                            data.Delegate = e.target.checked
                        }
                    }
                }

                if (param === 'Exhibitor' || param === 'Visitor' || param === 'Delegate') {

                    if (data.Exhibitor && data.Visitor && data.Delegate) {
                        data.All = true
                    } else {
                        data.All = false
                    }
                }
            }
        })
        console.log("seminarList----->", seminarList)
        this.setState({ seminarList: seminarList })
    }


    record(seminarList) {
        return (
            <table className="table border text-nowrap text-md-nowrap table-bordered mb-2">
                <thead>
                    <tr style={{ border: '1px solid #ccc' }}>
                        <th className="text-center">Seq No</th>
                        <th className="text-center">Action</th>
                        <th className="text-left">Day</th>
                        <th className="text-left">Date and Time</th>
                        <th className="text-left">Description</th>
                        <th className="text-center">Speaker</th>
                        <th className="text-center">All</th>
                        <th className="text-center">Exhibitor</th>
                        <th className="text-center">Visitor</th>
                        <th className="text-center">Delegate</th>
                    </tr>
                </thead>
                <tbody >
                    {
                        seminarList != '' && seminarList != null ? (
                            seminarList.map((data, i) => (
                                <tr key={i} className={data.isParent ? "seminar" : ''}>
                                    {/* {data.isParent ? <td >{data.id}</td> : <td ></td >} */}
                                    <td >{data.id}</td>
                                    <td className="text-center">
                                        <button
                                            title="Add FreightForward"
                                            class="btn btn-sm padding_0"
                                            onClick={() => this.addChild(data.id)}
                                        >
                                            <i class="fa fa-plus action_btn_table"></i>
                                        </button>
                                        <button
                                            title="Delete FreightForward"
                                            class="btn btn-sm padding_0"
                                            onClick={() => this.deleteExhSeminarList(data.id, data.SeminarID)}
                                        >
                                            <i class="fa fa-trash action_btn_table"></i>
                                        </button>

                                    </td>
                                    <td >
                                        <input className="form-control"
                                            style={{ width: '200px' }}
                                            type="text"
                                            value={data.Day}
                                            onChange={(e) => this.onInputChange(e, i, 'Day')}
                                        />
                                    </td>
                                    <td>
                                        <input className="form-control"
                                            style={{ width: '400px' }}
                                            type="text"
                                            value={data.DateTime}
                                            onChange={(e) => this.onInputChange(e, i, 'DateTime')}
                                        />
                                    </td>
                                    <td>
                                        <input className="form-control"
                                            style={{ width: '700px' }}
                                            type="text"
                                            value={data.Description}
                                            onChange={(e) => this.onInputChange(e, i, 'Description')}
                                        />
                                    </td>
                                    <td>
                                        <input className="form-control"
                                            style={{ width: '700px' }}
                                            type="text"
                                            value={data.Speaker}
                                            onChange={(e) => this.onInputChange(e, i, 'Speaker')}
                                        />
                                    </td>
                                    <td className="text-center">
                                        <input
                                            style={{ marginTop: '12px' }}
                                            type="checkbox"
                                            checked={data.All}
                                            onChange={(e) => this.onInputChange(e, i, 'All')}
                                        />
                                    </td>
                                    <td className="text-center">
                                        <input
                                            style={{ marginTop: '12px' }}
                                            type="checkbox"
                                            checked={data.Exhibitor}
                                            onChange={(e) => this.onInputChange(e, i, 'Exhibitor')}
                                        />
                                    </td>
                                    <td className="text-center">
                                        <input
                                            style={{ marginTop: '12px' }}
                                            type="checkbox"
                                            checked={data.Visitor}
                                            onChange={(e) => this.onInputChange(e, i, 'Visitor')}
                                        />
                                    </td>
                                    <td className="text-center">
                                        <input
                                            style={{ marginTop: '12px' }}
                                            type="checkbox"
                                            checked={data.Delegate}
                                            onChange={(e) => this.onInputChange(e, i, 'Delegate')}
                                        />
                                    </td>
                                </tr>
                            ))
                        ) : (<tr><td colSpan={12} className='text-center'>Record not found</td></tr>)
                    }
                </tbody>
            </table>
        )
    }


    render() {
        const { isLoading, seminarList, currentPage } = this.state

        const recordsPerPage = 10
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        // Records to be displayed on the current page
        const currentRecords = seminarList.slice(indexOfFirstRecord, indexOfLastRecord)
        const nPages = Math.ceil(seminarList.length / recordsPerPage)

        return (
            <>

                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <AdminDrawer />


                    <div className="main-container p-2">
                        <div className="row" style={{ margin: 'auto' }}>
                            <div className="table-responsive mt-2">
                                {this.record(seminarList)}
                            </div>
                            <div class="col-md-12 mt-2">
                                <button type="button" class="btn btn-primary rounded-0" style={{ float: 'right' }}
                                    onClick={() => this.insertUpdateExhSeminar()}>
                                    Save
                                </button>
                                <button class="btn rounded-0 btn-dark" style={{ float: 'right', marginRight: '10px' }}
                                    onClick={() => this.addParent()}>
                                    Add More Seminar
                                </button>
                            </div>
                            {/* <div className="mt-2 float-left">
                                {seminarList.length > 0 ? <Pagination
                                    nPages={nPages}
                                    currentPage={currentPage}
                                    setCurrentPage={this.updateItem} /> : <></>}

                            </div> */}
                        </div>
                    </div>

                </>

            </>
        );
    }
}

export default withRouter(connect(null, { fill_action, insertUpdateExhSeminar_action, getExhSeminarDetails_action, deleteExhSeminar_action })(Exh_seminar));