import { axiosGet, axiosPost } from "../../../../react_axios/Axios";
import { GET_E_DIRECTORY_APPLICATION_ACTION_TYPE, GET_E_DIRECTORY_SUMMARY_ACTION_TYPE, UPDATE_E_DIRECTORY_APPROVAL_ACTION_TYPE, getDirectoryExportUrl, getEDirectoryApplicationUrl, getEDirectorySummaryUrl, headers, updateEDirectoryApprovalUrl } from "../../../../utils/constants";


export const getEDirectorySummary_action = (companyName, status, exhibitorTypeId) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            CompanyName: companyName,
            Status: status,
            ExhibitorTypeID: exhibitorTypeId
        }
        console.log("getEDirectorySummary_action Action------->", params)

        const res = await axiosGet(getEDirectorySummaryUrl, params, headers)

        dispatch({
            type: GET_E_DIRECTORY_SUMMARY_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getEDirectoryApplications_action = (companyname) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            CompanyName: companyname,
        }
        console.log("getEDirectoryApplications_action Action------->", params)

        const res = await axiosGet(getEDirectoryApplicationUrl, params, headers)

        dispatch({
            type: GET_E_DIRECTORY_APPLICATION_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const updateEDirectoryApproval_action = (isApproved, list) => async (dispatch) => {
    try {
        const mapData = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            Is_Approved: isApproved,
            approvalList: list
        }
        console.log("updateEDirectoryApproval_action Action------->", mapData)

        const res = await axiosPost(updateEDirectoryApprovalUrl, mapData, headers)

        dispatch({
            type: UPDATE_E_DIRECTORY_APPROVAL_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getDirectoryExport_action = () => async (dispatch) => {
    try {
        const params = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
        }
        console.log("getEDirectorySummary_action Action------->", params)

        const res = await axiosGet(getDirectoryExportUrl, params, headers)

        dispatch({
            type: GET_E_DIRECTORY_SUMMARY_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}