import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from "react-router-dom";
import $ from 'jquery';
import Modal from "react-modal";
import { withRouter } from '../utils/withRouter';
import { connect } from 'react-redux';


const AgentTopMenu = (props) => {
    const history = useNavigate();
    const navigate = useNavigate();
    const location = useLocation();

    const [isOpen, setIsOpen] = useState(false)
    const [loginAccesslist, setLoginAccesslist] = useState([])

    //toggle model for logout.................................
    const toggleModal = () => {
        setIsOpen(!isOpen)
    }

    const loadScripts = () => {
        // This array contains all the files/CDNs 
        // const dynamicScripts = [
        //     'js/jquery.sticky.js',
        //     'js/metisMenu.min.js',
        //     'js/metisMenu-active.js',
        //     'js/main.js'
        // ];

        // for (let i = 0; i < dynamicScripts.length; i++) {
        //     const node = document.createElement('script');
        //     node.src = dynamicScripts[i];
        //     node.type = 'text/javascript';
        //     node.async = false;
        //     document.getElementsByTagName('head')[0].appendChild(node);
        // }
    }
    const logout = () => {
        sessionStorage.clear()
        navigate('/')
    }

    const profileView = (e) => {
        var nationalityID = sessionStorage.getItem('IsAgentDirectLogin') == 'false' ?
            JSON.parse(sessionStorage.getItem('indirectAgentloginDataSS')).AgentRegistrationType :
            JSON.parse(sessionStorage.getItem('loginDataSS')).AgentRegistrationType;
        var Account_ID = sessionStorage.getItem('IsAgentDirectLogin') == 'false' ?
            JSON.parse(sessionStorage.getItem('indirectAgentloginDataSS')).AccountID :
            JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID;
        console.log('nationalityID', nationalityID);
        console.log('Account_ID', Account_ID);

        props.navigate('/agententry', {
            state: {
                is_navFromProfile: true,
                is_update: true,
                nationalityId: nationalityID,
                Account_Id: Account_ID,
            }
        })
    }

    useEffect(() => {
        console.log("ExhibitorTopMenu")
        // loadScripts();
        // console.log("233", JSON.parse(sessionStorage.getItem('LoginAccesslist'))[0]['IsRead'])
        // setLoginAccesslist(JSON.parse(sessionStorage.getItem('LoginAccesslist')))
    }, []);

    const onRedirect = (path) => {
        history.push({ pathname: path, state: { 'isSearchClear': true } });
    }

    const isRouteActive = (routePath) => {
        return location.pathname == routePath
    }

    return (
        <>
            <Modal isOpen={isOpen} onRequestClose={e => toggleModal()}>
                <div className="modal-dialog modal-dialog-centered bd-example-modal-lg" >
                    <div className="modal-content" style={{ borderRadius: '0px' }}>
                        <div className="modal-header p-2">
                            <h4 className="modal-title">Confirm Logout</h4>
                            {/* <button type="button" class="close modal-close" data-dismiss="modal" onClick={this.toggleModal}>&times;</button> */}
                            <button style={{ backgroundColor: '#eee' }} type="button " className="close pop-close text-dark" data-dismiss="modal" onClick={e => toggleModal()} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

                        </div>
                        <div class="modal-body pl-0 pt-4 pb-4">
                            <p class="pop_label">Do you really want to logout?</p>
                        </div>
                        <div className='modal-footer d-block p-2'>
                            <button className="btn btn-outline-danger" onClick={logout}> Yes</button>
                            <button class="btn btn-outline-secondary" data-dismiss="modal" onClick={toggleModal}> No</button>
                        </div>

                    </div>
                </div>
            </Modal>
            {/* Top menu */}
            <div className="navg">
                <ul className="nav nav-pills">
                    <Link to={'/agentdashboard'}>
                        <li className="nav-item ">
                            {" "}
                            <a className={isRouteActive('/agentdashboard') ? "nav-link active px-4 py-2" : "nav-link  px-4 py-2"}>
                                Dashboard{" "}
                            </a>{" "}
                        </li>
                    </Link>
                    <Link to={'/rulesRegulations'}>
                        <li className="nav-item ">
                            {" "}
                            <a className={isRouteActive('/rulesRegulations') ? "nav-link active px-4 py-2" : "nav-link  px-4 py-2"}>
                                Rules And Regulations{" "}
                            </a>{" "}
                        </li>
                    </Link>
                    <Link to={'/importantDates'}>
                        <li className="nav-item ">
                            {" "}
                            <a className={isRouteActive('/importantDates') ? "nav-link active px-4 py-2" : "nav-link  px-4 py-2"} >
                                Important Dates{" "}
                            </a>{" "}
                        </li>
                    </Link>

                    <li className="nav-item "
                        onClick={(e) => profileView(e)}

                    >

                        <a className={isRouteActive('/agententry') ? "nav-link active px-4 py-2" : "nav-link  px-4 py-2"} >
                            Profile
                        </a>
                    </li>

                </ul>
                {
                    sessionStorage.getItem('IsAgentDirectLogin') != null && sessionStorage.getItem('IsAgentDirectLogin') == "true" &&
                    <span className="log-1" onClick={e => toggleModal()}>
                        <i className="fa fa-power-off" aria-hidden="true" /> Logout
                    </span>
                }
            </div>

            {/* Top menu */}

        </>
    );
}
export default withRouter(connect(null, {})(AgentTopMenu));