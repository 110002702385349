import { GET_ROLE_MASTER_DETAILS, GET_ROLE_MASTER_LIST, ADD_ROLE_MASTER, UPDATE_ROLE_MASTER, DELETE_ROLE_MASTER, } from "../../../utils/constants";


const initialState = [];

function roleMasterReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case GET_ROLE_MASTER_DETAILS:
            return [...state, payload];
        case GET_ROLE_MASTER_LIST:
            return [...state, payload];
        case ADD_ROLE_MASTER:
            return [...state, payload];
        case UPDATE_ROLE_MASTER:
            return [...state, payload];
        case DELETE_ROLE_MASTER:
            return [...state, payload];

        default:
            return state;
    }
};

export default roleMasterReducer