import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { toastError, toastSuccess, BASE_URL } from "../../../../../utils/constants";
import { insertUpdateCateringServices_action, getCateringServicesDetails_action } from '../../../../../actions/exhibitor_module_actions/manual_Forms_actions/forms_actions/cateringServices_action/cateringServices_action'
import Modal from "react-modal";
import $ from 'jquery'
// 
class CateringServices extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            PowerConnectionList: [],
            Is_Agree: false,
            Remark: '',
            CGST: '',
            SGST: '',
            Is_AgreeErr: '',
            DeadlineDate: "",
            Is_Required: true,
            carPassessId: '',
            currentDate: new Date(),
            contactDetailsList: [],
            IsIntrested: false,
            StatusID: ''
        }
        // this.getPowerConnectionList = this.getPowerConnectionList.bind(this)

    }

    componentDidMount() {
        this.setState({
            DeadlineDate: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.Deadline != "" ?
                new Date(this.props.location.state.Deadline) : '',
            StatusID: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.StatusID != "" ?
                this.props.location.state.StatusID : '',
        })

        const url = BASE_URL.split('/api')
        this.setState({ url: url[0] })

        this.getCarPassessDetails()
        this.loadscript()
    }
    loadscript() {
        $(document).ready(function () {
            $(this).scrollTop(0);
        });
    }


    handleCancel() {
        this.props.navigate('/manualForms')
    }

    inputValidate() {
        const { Is_Agree } = this.state

        if (Is_Agree == 0 || Is_Agree == false) {
            this.setState({ Is_AgreeErr: 'kindly agree the above disclaimer for catering services' })
        }
    }

    getCarPassessDetails() {
        const { Remark, Is_Agree, isQuantity, Is_Required } = this.state
        this.setState({ isLoading: true })
        this.props
            .getCateringServicesDetails_action()
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ isLoading: false, contactDetailsList: data.contactDetails })
                    if (data.data != '') {
                        this.setState({
                            IsIntrested: data.data[0]['IsIntrested'],
                            Remark: data.data[0]['Remark'],
                            Is_Agree: data.data[0]['Is_Agree'],
                        })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    addCateringServices() {
        const { Remark, Is_Agree, IsIntrested } = this.state
        this.inputValidate()
        this.setState({ isLoading: true })
        if (Is_Agree) {
            this.props
                .insertUpdateCateringServices_action(IsIntrested, Remark, Is_Agree)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({ isLoading: false })
                        this.handleCancel()
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            toastError("Plase Enter mandatory feilds!")
            this.inputValidate()
            this.setState({ isLoading: false })
        }
    }

    openImageInNextTab(path) {
        const { url } = this.state
        window.open(`${url}${path}#toolbar=0&navpanes=0`, '_blank');
    }

    render() {
        const { isLoading, Is_Agree, Remark, Is_AgreeErr, currentDate, DeadlineDate, contactDetailsList, IsIntrested, url, StatusID } = this.state

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <ExhibitorHeader />
                <ExhibitorDrawer />
                <div className="main-content app-content mt-0 with-dr-container">
                    <div className="">
                        <div className="row">
                            <ExhibitorTopMenu />
                        </div>
                        <div className=" mt-2">
                            <div className="row">
                                <div class="card rounded-0 pt-1">
                                    <div class="card-header p-3" style={{ paddingleft: "7px !important" }}>
                                        <h3 class="card-title" style={{ fontsize: "15px" }}>Catering Services</h3>
                                    </div>
                                    <div class="card-body p-1 m-2">
                                        <ul style={{ lineHeight: '25px', textAlign: 'left', padding: 0 }}>
                                            <li>1) The cost/rates and terms & conditions of utilizing the individual services from the given service provider is solely discretion of exhibitors.
                                            </li>
                                            <li>2) India ITME Society is only information facilitator for the service provider & In no event shall INDIA ITME SOCIETY be liable for any direct, indirect, punitive, incidental, special consequential issues, whatsoever arising out of taking services with the above given service provider.
                                            </li>
                                        </ul>
                                        <strong><p className="m-0">Contact Details:-</p></strong>
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {contactDetailsList != '' && contactDetailsList.length > 0 ?
                                                contactDetailsList.map((data) => (
                                                    <>
                                                        <div class="table-responsive w-50 d-flex" style={{ backgroundColor: '#ebebeb', marginBottom: '14px', borderRight: '1px solid lightgray', justifyContent: 'space-between' }}>
                                                            <div style={{ margin: '10px 0 10px 10px', lineHeight: '23px' }}>
                                                                <p className="m-0"><strong>Name:</strong>  {data.Name} </p>
                                                                <p className="m-0"><strong>Phone:</strong>  {data.Phone}</p>
                                                                <p className="m-0"><strong>Fax:</strong>  {data.Fax}</p>
                                                                <p className="m-0"><strong>Mobile No.</strong>  {data.Mobile}</p>
                                                                <p className="m-0"><strong>Email:</strong>  {data.PrimaryEmail}</p>
                                                                <p className="m-0"><strong>Alternate E-mail:</strong> {data.SecondaryEmail}</p>
                                                                <p className="m-0"><strong>Address:</strong> {data.Address}</p>
                                                            </div>
                                                            <div className="m-2" >
                                                                <iframe
                                                                    title="Passport Front Page"
                                                                    src={`${url}${data.CateringImage}#toolbar=0&navpanes=0`}
                                                                    // src='https://ems_uat.india-itme.com/document/Circular/10/Circular_10.pdf#toolbar=0&navpanes=0'
                                                                    style={{ width: "220px", height: "250px", cursor: 'pointer' }}
                                                                    id="myIframe"
                                                                />
                                                                <div
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: '124px',
                                                                        width: '195px',
                                                                        height: '227px',
                                                                        cursor: 'pointer',
                                                                        backgroundColor: 'transparent',
                                                                    }}
                                                                    onClick={(e) => this.openImageInNextTab(data.CateringImage)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                ))
                                                :
                                                <div class="table-responsive w-50" style={{ backgroundColor: '#ebebeb', marginBottom: '14px', borderRight: '1px solid lightgray' }}>
                                                    <div style={{ margin: '10px 0 10px 10px', lineHeight: '23px' }}>
                                                        <p className="m-0">No record found </p>
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        {/* <div class="table-responsive">
                                            <table class="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1" style={{ textAlign: 'center' }}>
                                                <thead>
                                                    <tr >
                                                        <th colSpan={7}><strong>ANY ADVANCE BOOKING KINDLY CONTACT <br />
                                                            Mr Deepak Kumar Jha, Operation Manager <br />
                                                            Mobile : 91 9910054512, Email: operations.catering@seasonsgroup.co.in
                                                        </strong></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={6}><strong>F&B OFFERINGS</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={6}><strong>Event Dated 8TH TO 13TH DEC 2022</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Date</strong></td>
                                                        <td><strong>Particular</strong></td>
                                                        <td><strong>Location</strong></td>
                                                        <td><strong>Serving</strong></td>
                                                        <td><strong>Days/Timing</strong></td>
                                                        <td><strong>Rate +GST</strong></td>
                                                        <td><strong>Remarks</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div> */}
                                    </div>
                                </div>
                                <div class="row m-1">
                                    <form>
                                        <label class="d-flex mt-1" style={{ lineheight: "15px;" }}>
                                            <input type="checkbox"
                                                checked={IsIntrested}
                                                onChange={(e) => this.setState({ IsIntrested: e.target.checked })}
                                            />
                                            <strong> I am interested in catering services.</strong></label>
                                        <strong> <label>Remark</label></strong>
                                        <textarea class="form-control"
                                            value={Remark}
                                            onChange={(e) => this.setState({ Remark: e.target.value })}
                                            maxLength={300}
                                            rows="3">
                                        </textarea>
                                        <label class="d-flex mt-1" style={{ lineheight: "15px;" }}>
                                            <input type="checkbox"
                                                checked={Is_Agree}
                                                onChange={(e) => this.setState({ Is_Agree: e.target.checked, Is_AgreeErr: '' })}
                                            />
                                            <strong> I agree the above disclaimer for catering services<span className="required">*</span>.</strong></label>{<span className='text-danger text-start text-left d-block mt-1'>{Is_AgreeErr}</span>}

                                    </form>
                                </div>

                            </div>
                            <div className="row">
                                {currentDate > DeadlineDate &&
                                    <span className="text-danger">THE DEADLINE TO FILL OUT THE FORM HAS PASSED.</span>
                                }
                            </div>
                            <div class='d-flex m-3'>

                                <button
                                    type="button"
                                    className="btn btn-primary rounded-0 d-block mt-3 mb-3"
                                    style={{ marginRight: 10 }}
                                    disabled={currentDate > DeadlineDate }
                                    onClick={() => this.addCateringServices()}
                                >
                                    Submit
                                </button>
                                <button className="btn rounded-0 d-block mt-3 mb-3 apper-cancel" onClick={() => this.handleCancel()}>Back</button>

                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(connect(null, { insertUpdateCateringServices_action, getCateringServicesDetails_action })(CateringServices));