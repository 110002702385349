import { GET_EXH_TAX_INVOICE_LIST_ACTION_TYPE } from "../../../utils/constants";


const initialState = [];

function exhTaxInvoiceReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case GET_EXH_TAX_INVOICE_LIST_ACTION_TYPE:
            return [...state, payload];

        default:
            return state;
    }
};

export default exhTaxInvoiceReducer