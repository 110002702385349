import { GET_STALL_DESIGN_APPROVAL_DETAILS_ACTION_TYPE, GET_STALL_DESIGN_APPROVAL_LIST_ACTION_TYPE, GET_STALL_DESIGN_APPROVAL_EXPORT_DATA_ACTION_TYPE, UPDATE_STALL_DESIGN_APPROVAL__ACTION_TYPE } from "../../../../utils/constants";


const initialState = [];

function stallDesignApprovalReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_STALL_DESIGN_APPROVAL_DETAILS_ACTION_TYPE:
            return [...state, payload];
        case GET_STALL_DESIGN_APPROVAL_LIST_ACTION_TYPE:
            return [...state, payload];
        case GET_STALL_DESIGN_APPROVAL_EXPORT_DATA_ACTION_TYPE:
            return [...state, payload];
        case UPDATE_STALL_DESIGN_APPROVAL__ACTION_TYPE:
            return [...state, payload];

        default:
            return state;
    }
};

export default stallDesignApprovalReducer;