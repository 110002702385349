import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../common_components/Header';
import Drawer from '../../common_components/Drawer';
import {
    changePassword_action,
} from '../../actions/user_login_auth_actions/user_login_auth_action'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../utils/withRouter';
import moment from 'moment';
import Modal from "react-modal";
import Loader from '../../utils/Loader'
import { clearSessionStorage } from '../../utils/constants'
import Pagination from '../../utils/pagination'
import { useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
/* 
 * mapDispatchToProps
*/
// const mapDispatchToProps = dispatch => ({
//     loginAction: () => dispatch(loginAction())
// })

/* 
 * mapStateToProps
*/
const mapStateToProps = state => ({
    ...state
})

class ChangePassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            old_pass: '',
            new_pass: '',
            confirm_pass: '',
            isLoading: false,
            is_showNewpass: false,
            is_showOldpass: false,
            is_showConfirmpass: false,
            is_forgotPassword: false,

        }
        this.handleChangePassword = this.handleChangePassword.bind(this)
        this.handleObscure = this.handleObscure.bind(this)

    }

    componentDidMount() {
        clearSessionStorage("")
        this.setState({
            is_forgotPassword: this.props.location.state != null && this.props.location.state.is_forgotPassword != null ? this.props.location.state.is_forgotPassword : false
        })
    }

    handleObscure(pass) {
        const { is_showOldpass, is_showConfirmpass, is_showNewpass } = this.state
        if (pass == 'new_pass') {
            this.setState({ is_showNewpass: !is_showNewpass })
        }
        else if (pass == 'old_pass') {
            this.setState({ is_showOldpass: !is_showOldpass })
        }
        else if (pass == 'confirm_pass') {
            this.setState({ is_showConfirmpass: !is_showConfirmpass })
        }

    }

    handleChangePassword = () => {
        const { old_pass, new_pass, confirm_pass } = this.state
        clearSessionStorage("")
        if (new_pass != '' && confirm_pass != '' && old_pass != '' && new_pass === confirm_pass) {
            this.setState({ isLoading: true })
            this.props
                .changePassword_action(old_pass, new_pass)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({ isLoading: false })
                        toast.success('Password changed successfully.Please login again.', {
                            theme: "colored",
                            autoClose: 3000,
                            hideProgressBar: true
                        });
                        setTimeout(() => {
                            localStorage.clear()
                            this.props.navigate('/login')
                        }, 300);

                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please check your password', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }

    }

    render() {
        const { old_pass, new_pass, confirm_pass, is_showOldpass, is_showConfirmpass, is_showNewpass, is_forgotPassword } = this.state


        return (
            <>
                <Header />
                <div className="container-scroller">
                    <div className="container-fluid page-body-wrapper">
                        {/* Left side menu */}
                        <Drawer />
                        {/* start page */}
                        <div className="main-panel main-box">
                            <div className="content-wrapper">
                                <div className="main-breadcum">
                                    <ul className="breadcrumb">
                                        <li>
                                            <a href="javascript:void(0)">Change Password</a>
                                        </li>
                                        <li />
                                    </ul>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 pg-body grid-margin stretch-card">
                                        <div className="card">
                                            <div className="card-body page-mid">
                                                <div className="change-pass">
                                                    <div className="row">
                                                        <div className="col-md-3 mt-4 pl-0" />
                                                        <div className="col-md-3  p-0">
                                                            <img
                                                                src="images/password.png"
                                                                className="img-fluid"
                                                                style={{ width: '245px' }}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 mt-4 pl-0">
                                                            <h5 className='text-start'>Change Password</h5>

                                                            <form>
                                                                <div className="form-group col-6">
                                                                    <div
                                                                        className="com-inp"
                                                                        style={{ position: "relative" }}
                                                                    >
                                                                        <label>Old Password</label>
                                                                        <input type={is_showOldpass ? "type" : "password"}
                                                                            className="form-control forg-password"
                                                                            placeholder={is_forgotPassword ? "Enter password which got in an email" : ""}
                                                                            required
                                                                            value={old_pass}
                                                                            onChange={e => this.setState({ old_pass: e.target.value })}
                                                                        />
                                                                        <i
                                                                            className="fa fa-eye"
                                                                            onClick={e => this.handleObscure('old_pass')}
                                                                            style={{
                                                                                top: ' -1.7rem',
                                                                                left: '8rem',
                                                                                position: "relative",
                                                                                color: 'gray'

                                                                            }}
                                                                        />
                                                                        <i className="fa fa-lock" aria-hidden="true" style={{
                                                                            zIndex: 0
                                                                        }} />


                                                                    </div>

                                                                    <div
                                                                        className="com-inp"
                                                                        style={{ position: "relative" }}
                                                                    >
                                                                        <label>New Password</label>
                                                                        <input type={is_showNewpass ? "type" : "password"}
                                                                            className="form-control forg-password"
                                                                            placeholder=""
                                                                            required
                                                                            value={new_pass}
                                                                            onChange={e => this.setState({ new_pass: e.target.value })}

                                                                        />
                                                                        <i
                                                                            className="fa fa-eye"
                                                                            onClick={e => this.handleObscure('new_pass')}
                                                                            style={{
                                                                                top: ' -1.7rem',
                                                                                left: '8rem',
                                                                                position: "relative",
                                                                                color: 'gray'
                                                                            }}
                                                                        />
                                                                        <i className="fa fa-lock" aria-hidden="true" style={{
                                                                            zIndex: 0
                                                                        }} />


                                                                    </div>
                                                                    <div
                                                                        className="com-inp"
                                                                        style={{ position: "relative" }}
                                                                    >
                                                                        <label>Confirm Password</label>
                                                                        <input type={is_showConfirmpass ? "type" : "password"}
                                                                            className="form-control forg-password"
                                                                            placeholder=""
                                                                            required
                                                                            value={confirm_pass}
                                                                            onChange={e => this.setState({ confirm_pass: e.target.value })}
                                                                        />
                                                                        <i
                                                                            className="fa fa-eye"
                                                                            onClick={e => this.handleObscure('confirm_pass')}
                                                                            style={{
                                                                                top: ' -1.7rem',
                                                                                left: '8rem',
                                                                                position: "relative",
                                                                                color: 'gray'
                                                                            }}
                                                                        />
                                                                        <i className="fa fa-lock" aria-hidden="true" style={{
                                                                            zIndex: 0
                                                                        }} />

                                                                    </div>
                                                                    <div className="row button m-1 mt-3">
                                                                        <a
                                                                            onClick={this.handleChangePassword}
                                                                            style={{
                                                                                width: "100%",
                                                                                background: "#121566",
                                                                                textAlign: "center",
                                                                                padding: "8px 0",
                                                                                borderRadius: 3,
                                                                                color: "#fff"
                                                                            }}
                                                                        >
                                                                            Submit
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ height: 150 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* end page */}
                        </div>


                        <ToastContainer />
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(connect(null, {
    changePassword_action
})(ChangePassword));