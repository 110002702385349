import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { toastError, toastSuccess } from "../../../../../utils/constants";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import Modal from "react-modal";
import { data } from "jquery";
import { submitKeywordsAndInformation_action, getKeywordsAndInformationList_action } from "../../../../../actions/exhibitor_module_actions/manual_Forms_actions/forms_actions/keyword_and_information_action/keyword_and_information_action";

class KeywordsInformation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            keywordsList: [],
            is_delete: false,
            keyword: "",
            HSNCode: "",
            listLength: 0,
            KeywordID: '',
            ManualFormID: 6,
            StatusID: '',
            DeadlineDate: '',
            currentDate: new Date()
        }
        this.OnAddKeyword = this.OnAddKeyword.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.getKeywordsListDetails = this.getKeywordsListDetails.bind(this)
        this.deleteKeywordInformationList = this.deleteKeywordInformationList.bind(this)


    }

    componentDidMount() {
        this.setState({
            DeadlineDate: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.Deadline != "" ?
                new Date(this.props.location.state.Deadline) : '',
            StatusID: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.StatusID != "" ?
                this.props.location.state.StatusID : '',
        })
        setTimeout(() => {
            this.getKeywordsListDetails()
        }, 100);
    }

    getKeywordsListDetails() {

        this.setState({ isLoading: true })
        this.props
            .getKeywordsAndInformationList_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log("done")

                    this.setState({ isLoading: false })

                    if (data.data != '') {
                        data.data.map((data, i) => {
                            data.is_delete = false
                            data.ID = i++
                        })
                        console.log("keywordsList *****", data.data)
                        this.setState({ keywordsList: data.data })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    chehckedKeywordInformationList(e, index) {
        const { keywordsList } = this.state;
        const pList = [...keywordsList];
        const event = e.target.checked;

        if (event == true) {
            pList.forEach((data, i) => {
                if (index === data.ID) {
                    data.is_delete = true;
                }
            });
            console.log("pList", pList)
            this.setState({
                keywordsList: pList,
            })
        } else {
            pList.forEach((data, i) => {
                if (index === data.ID) {
                    data.is_delete = false;
                }
            });
        }
        this.setState({
            keywordsList: pList,
        });
    }

    handleSubmit(e) {
        e.preventDefault()
        const { KeywordID, ManualFormID, keywordsList } = this.state

        const newArray = []

        keywordsList.map((data, i) => {
            newArray.push(
                {
                    'Keywords': data.Keywords,
                    'hsncode': data.HSNCode,
                }
            )
        })

        var Keyword = false
        const keyword = keywordsList.every(item => item.Keywords != '')
        if (keyword) {
            Keyword = true
        } else {
            Keyword = false
        }

        var Hsncode = false
        const hcncode = keywordsList.every(item => item.HSNCode != '')
        if (hcncode) {
            Hsncode = true
        } else {
            Hsncode = false
        }

        this.setState({ isLoading: true })
        if (Keyword) {
            if (Hsncode) {
                this.props
                    .submitKeywordsAndInformation_action(KeywordID, ManualFormID, newArray)
                    .then((data) => {
                        if (data.error != 1) {
                            console.log("done")
                            // toastSuccess("Record Added Successfully")
                            setTimeout(() => {
                                this.props.navigate('/manualForms')
                                this.setState({ isLoading: false })
                            }, 100);

                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } else {
                toastError("Plase enter HSN code")
                this.setState({ isLoading: false })
            }
        } else {
            toastError("Plase enter keywords")
            this.setState({ isLoading: false })
        }

    }

    deleteKeywordInformationList() {
        const { keywordsList } = this.state
        console.log("keywordsList", keywordsList)

        var is_delete = false
        const keywordDelete = keywordsList.every(item => item.is_delete != true)
        if (keywordDelete) {
            is_delete = true
            console.log("**:::::::::::::true", is_delete)
        } else {
            is_delete = false
            console.log("**:::::::::::::false", is_delete)
        }
        if (is_delete == false) {
            var list = keywordsList.filter((data) => data.is_delete != true)
            toastSuccess("data deleted successfully!")
            this.setState({
                keywordsList: list,
            });
        } else {
            toastError("please select atleast one entry!")
        }


    }

    OnAddKeyword(e) {
        e.preventDefault();
        console.log(this.state.listLength)
        const { keywordsList } = this.state
        var id = keywordsList.length + 1
        var map = {
            ID: id,
            is_delete: false,
            Keywords: "",
            HSNCode: "",

        }
        keywordsList.push(map)
        this.setState({ keywordsList: keywordsList, })
        console.log(this.state.keywordsList)
    }

    enterKeywords(e, index) {
        const { keywordsList } = this.state
        const list = [...keywordsList]
        for (let i = 0; i < list.length; i++) {
            if (i == index) {
                list[i]['Keywords'] = e.target.value

            }
        }
        this.setState({
            keywordsList: list
        })
    }

    enterHCNcode(e, index) {
        const { keywordsList } = this.state
        const list = [...keywordsList]
        for (let i = 0; i < list.length; i++) {
            if (i == index) {
                list[i]['HSNCode'] = e.target.value

            }
        }
        this.setState({
            keywordsList: list
        })
    }

    handleCancel() {
        this.props.navigate('/manualForms')
    }

    render() {
        const { isLoading, keywordsList, DeadlineDate, StatusID, currentDate } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <ExhibitorHeader />
                <ExhibitorDrawer />
                <div className="main-content with-dr-container mt-0">
                    <div className="width-dr-nav">
                        <div className="row">
                            <ExhibitorTopMenu />
                        </div>
                        <div className=" mt-2">
                            <div className="row">
                                <div class="col-xl-12 p-0">
                                    <div class="card rounded-0 pt-1">
                                        <div class="card-header " style={{ paddingleft: "7px !important" }}>
                                            <div class="col-12">
                                                <h3 class="card-title mt-2" style={{ fontsize: "15px", float: 'left' }}>Keywords Information</h3>
                                            </div>
                                        </div>
                                        <div class="card-body pt-2">
                                            <button type="button" class="btn btn-primary rounded-0" style={{ float: 'right' }} onClick={(e) => this.OnAddKeyword(e)}>
                                                Add Keyword
                                            </button>
                                            <div class="table-responsive">
                                                <table class="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1">
                                                    <thead>
                                                        <tr>
                                                            <th width="50px" class="text-center">Sr No</th>
                                                            <th style={{ width: "70px" }} class="text-center">Delete</th>
                                                            <th>KEYWORDS</th>
                                                            <th>HSN CODE</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {keywordsList.length > 0 ? keywordsList.map((data, i) => (
                                                            <tr key={data.ID}>
                                                                <td class="text-center" style={{ lineHeight: '30px' }}>
                                                                    {i + 1}
                                                                </td>
                                                                <td class="text-center">
                                                                    <input
                                                                        type="checkbox" style={{ width: '50px' }}
                                                                        class="example-checkbox mt-2 p-0 m-0"
                                                                        value={data.is_delete}
                                                                        // placeholder="3.7.22"

                                                                        onChange={(e) => this.chehckedKeywordInformationList(e, data.ID)}
                                                                    />
                                                                </td>
                                                                <td className="p-1">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={data.Keywords}

                                                                        onChange={(e) => this.enterKeywords(e, i)}
                                                                    />
                                                                </td>
                                                                <td className="p-1">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={data.HSNCode}
                                                                        onChange={(e) => this.enterHCNcode(e, i)}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        ))
                                                            : (<tr >
                                                                <td colSpan={10} className="text-center" >Record not available</td>
                                                            </tr>)
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="btn-group p-1" style={{ float: 'left', }} role="group" aria-label="Basic example">
                                <button type="button" class="btn btn-primary rounded-0"
                                    disabled={currentDate > DeadlineDate }
                                    style={{ marginRight: "10px" }} onClick={this.handleSubmit}>Submit</button>
                                <button type="button" class="btn btn-danger rounded-0" onClick={this.deleteKeywordInformationList}>Delete</button>
                                <button className="btn rounded-0 apper-cancel" style={{ marginLeft: "10px" }} onClick={() => this.handleCancel()}>Back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(connect(null, { submitKeywordsAndInformation_action, getKeywordsAndInformationList_action })(KeywordsInformation));