import { axiosGet,axiosPost } from "../../../react_axios/Axios";
import { getExhibitorSeminarDetailsUrl, insertUpdateExhibitorSeminarUrl, INSERT_UPDATE_EXHIBITOR_SEMINAR, GET_EXHIBITOR_SEMINAR_DETAILS, headers } from "../../../utils/constants";


export const getExhibitorSeminarDetails_action = () => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhRegistrationID: sessionStorage.getItem("ExhRegistrationIDSS"),
            ExhibitionID: sessionStorage.getItem("sessionExhibitionID"),
        }

        const res = await axiosGet(getExhibitorSeminarDetailsUrl, params, headers)

        dispatch({
            type: GET_EXHIBITOR_SEMINAR_DETAILS,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const insertUpdateExhibitorSeminar_action = ( SeminarIDList) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhRegistrationID: sessionStorage.getItem("ExhRegistrationIDSS"),
            ExhibitionID: sessionStorage.getItem("sessionExhibitionID"),
            SeminarIDList: SeminarIDList,
        }

        const res = await axiosPost(insertUpdateExhibitorSeminarUrl, params, headers)

        dispatch({
            type: INSERT_UPDATE_EXHIBITOR_SEMINAR,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}