import { axiosGet, axiosPost } from "../../../react_axios/Axios";
import { DELETE_VISA_DOCUMENT_ACTION_TYPE, GET_COMBINE_VISITOR_REGISTRATION_DETAIL_ACTION_TYPE, VISITOR_REGISTRATION_UPDATE_ACTION_TYPE, deleteVisaDocUrl, getCombineVisitorRegistrationDetailsUrl, headers, visitorRegistrationUpdateUrl } from "../../../utils/constants";

export const combineVisitorRegistrationDetail_action = (flag) => async (dispatch) => {
    try {
        const param = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            AccountID: flag == true ? JSON.parse(sessionStorage.getItem('SelectedVisitorDataSS')).AccountID : JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID,
            ExhibitionID: sessionStorage.getItem("sessionExhibitionID"),
            ExhVisitorID: flag == true ? JSON.parse(sessionStorage.getItem('SelectedVisitorDataSS')).ExhVisitorID : JSON.parse(sessionStorage.getItem('VisitorDataSS')).ExhVisitorID
        }

        const res = await axiosGet(getCombineVisitorRegistrationDetailsUrl, param, headers);
        dispatch({
            type: GET_COMBINE_VISITOR_REGISTRATION_DETAIL_ACTION_TYPE,
            payload: res,
        });
        console.log("combineVisitorRegistrationDetail_action--------->", param)
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const visitorRegistrationUpdate_action = (countryId, attendeeTypeId, preName, firstName, middleName, lastName, designation,
    institute, mobNo, whatsAppMobNo, isSameAsMobNo, city, regState, pincode, website, email, telNo, tel1No, needVisaAssistance,
    passName, applicantMobNo, applicantEmail, passNo, passDate, passExipryDate, passCountryId, passportFile, remarks,
    isAgree, dateList, chapterList, purposeList, interestListData, passportFileType, isVisaUpdated, isGrpDelegation,
    leader, delegationCountryId, arrivalDate, departureDate, delegationRemark, preffDate, prefTime, seminarInterestListData,
    b2bRemark, isCarParking, parkingCharges, chapterListData2, flag, isInterestTwo, isInterstOne) => async (dispatch) => {
        try {
            const mapdata = {
                UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
                AccountID: flag == true ? JSON.parse(sessionStorage.getItem('SelectedVisitorDataSS')).AccountID : JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID,
                ExhVisitorID: flag == true ? JSON.parse(sessionStorage.getItem('SelectedVisitorDataSS')).ExhVisitorID : JSON.parse(sessionStorage.getItem('VisitorDataSS')).ExhVisitorID,
                ExhibitionID: sessionStorage.getItem("sessionExhibitionID"),
                CountryID: countryId,
                AttendeeTypeID: attendeeTypeId,
                Title: preName,
                FirstName: firstName,
                MiddleName: middleName,
                LastName: lastName,
                Designation: designation,
                Institute: institute,
                MobileNo: `${sessionStorage.getItem('sessionrPreMobNo')}-${mobNo}`,
                WhatsappNo: `${sessionStorage.getItem('sessionrPreWhatsappMobNo')}-${whatsAppMobNo}`,
                Is_SameMobileNo: isSameAsMobNo,
                State: regState,
                City: city,
                Pincode: pincode,
                Website: website,
                Email: email,
                Telephone: `${sessionStorage.getItem('sessionrPreTelNo')}-${telNo}`,
                Telephone1: `${sessionStorage.getItem('sessionrPreTel1No')}-${tel1No}`,
                isVisaAssistance: needVisaAssistance,
                NameOnPassport: needVisaAssistance ? passName : "",
                ApplicantMobileNo: needVisaAssistance ? `${sessionStorage.getItem('sessionrPreApplicantMobNo')}-${applicantMobNo}` : "",
                ApplicantEmailID: needVisaAssistance ? applicantEmail : "",
                PassportNo: needVisaAssistance ? passNo : "",
                PassportIssueDate: needVisaAssistance && passDate != "" ? passDate : null,
                PassportExpiryDate: needVisaAssistance && passExipryDate != "" ? passExipryDate : null,
                PassportIssuingCountryID: needVisaAssistance ? passCountryId : "",
                PassportFilePath: needVisaAssistance ? passportFile : "",
                Remarks: remarks,
                ISAgree: isAgree,
                visitDaysList: dateList,
                visitorChapterList: chapterList,
                purposeOfVisitList: purposeList,
                areaOfInterestList: interestListData,
                visitorRegNo: JSON.parse(sessionStorage.getItem('VisitorDataSS')).VisitorRegistrationNo,
                passportFileType: passportFileType,
                IsVisaUpdated: isVisaUpdated,
                IsGroupDelegation: isGrpDelegation,
                DelegationName: leader,
                DelegationCountryID: delegationCountryId,
                DelegationArrivalDate: arrivalDate,
                DelegationDepartureDate: departureDate,
                DelegationRemark: delegationRemark,
                B2BDepartureDate: preffDate,
                B2BPreferredTime: prefTime,
                interestSeminarList: seminarInterestListData,
                B2BRemark: b2bRemark,
                CarParking: isCarParking,
                ParkingCharges: parkingCharges,
                hallChapterList: chapterListData2,
                Isinterestedforb2b: isInterestTwo,
                IsInterestedforSeminar: isInterstOne
            }
            console.log("visitorRegistrationSubmit_action--------->", mapdata)
            const res = await axiosPost(visitorRegistrationUpdateUrl, mapdata, headers);
            dispatch({
                type: VISITOR_REGISTRATION_UPDATE_ACTION_TYPE,
                payload: res,
            });

            return Promise.resolve(res);
        } catch (err) {
            return Promise.reject(err);
        }
    };


export const deleteVisaDoc_action = () => async (dispatch) => {

    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            AccountID: JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID,
            ExhvisitorID: JSON.parse(sessionStorage.getItem('VisitorDataSS')).ExhVisitorID,
        }
        console.log("Delete deleteExhibitorBadge_ Document Action---------------->", params)
        const res = await axiosGet(deleteVisaDocUrl, params, headers)
        dispatch({
            type: DELETE_VISA_DOCUMENT_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}


