import { ASSIGN_CIRCULAR_ACTION_TYPE, DEL_VISITOR_CIRCULAR_ACTION_TYPE, GET_VISITOR_CIRCULAR_COMPANY_ACTION_TYPE, GET_VISITOR_CIRCULAR_LIST_ACTION_TYPE, INSERT_VISITOR_CIRCULAR_ACTION_TYPE } from "../../../utils/constants";


const initialState = [];

function visitorCircularReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_VISITOR_CIRCULAR_COMPANY_ACTION_TYPE:
            return [...state, payload];
        case INSERT_VISITOR_CIRCULAR_ACTION_TYPE:
            return [...state, payload];
        case GET_VISITOR_CIRCULAR_LIST_ACTION_TYPE:
            return [...state, payload];
        case DEL_VISITOR_CIRCULAR_ACTION_TYPE:
            return [...state, payload];
        case ASSIGN_CIRCULAR_ACTION_TYPE:
            return [...state, payload];


        default:
            return state;
    }
};

export default visitorCircularReducer;