import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { getExhibitorDetails_action, updateExhibitorDetails_action } from "../../../../../actions/exhibitor_module_actions/manual_Forms_actions/forms_actions/exhibitor_details_action/exhibitor_details_action"
import { primaryChapterListActionId, toastError, toastSuccess } from "../../../../../utils/constants";
import Modal from "react-modal";
import { data } from "jquery";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import { fill_action } from "../../../../../actions/fillCombo_action";
import {
    getDoubleStoreySSI_action,
} from '../../../../../actions/primary_chapter_action'

class ExhibitorDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isOpen: false,
            exhibitionID: "",
            exhRegistrationID: "",
            exbTableErr: '',
            stallArea: '',
            subChapters: [],
            level2: "",
            level3: "",
            level4: "",
            DeadlineDate: "",
            currentDate: new Date(),
            guideLines: [],
            isShowMachineryGuidelines: false,
            guidelinesAddress: '',
            Remark: '',
            StatusID: '',
        }

        this.getExhibitorDetails = this.getExhibitorDetails.bind(this)
        this.updateExhibitorDetails = this.updateExhibitorDetails.bind(this)
        this.onEnterProductName = this.onEnterProductName.bind(this)
        this.onMachineDetailsChange = this.onMachineDetailsChange.bind(this)
        this.onEnterHSNCode = this.onEnterHSNCode.bind(this)
        this.onLengthChange = this.onLengthChange.bind(this)
        this.onBreadthChange = this.onBreadthChange.bind(this)
        this.onHeightChange = this.onHeightChange.bind(this)
        this.onFloorCapacityChange = this.onFloorCapacityChange.bind(this)
        this.onMachineWeightChange = this.onMachineWeightChange.bind(this)
        this.onManufacturerNameChange = this.onManufacturerNameChange.bind(this)
        this.maxLengthCheck = this.maxLengthCheck.bind(this)
        this.onAddClick = this.onAddClick.bind(this)
        this.onCheckLevel2 = this.onCheckLevel2.bind(this)
        this.record = this.record.bind(this)
        this.onSelectLevel4 = this.onSelectLevel4.bind(this)
        this.onCheckLevel3 = this.onCheckLevel3.bind(this)
        this.recordFour = this.recordFour.bind(this)
    }

    componentDidMount() {
        this.setState({
            DeadlineDate: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.Deadline != "" ?
                new Date(this.props.location.state.Deadline) : '',
            StatusID: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.StatusID != "" ?
                this.props.location.state.StatusID : '',
        })

        this.getExhibitorDetails()
        this.getDoubleStroeySSI()
        this.getFillList(primaryChapterListActionId, '', '', '', '', '');
    }

    handleCancel() {
        this.props.navigate('/manualForms')
    }

    updateExhibitorDetails() {
        const { subChapters, exbTableErr, Remark } = this.state
        // Preparing SubChapter List For Udt 
        var list = []
        subChapters.map(data => {
            var data = {
                "ExhibitionID": parseInt(sessionStorage.getItem('sessionExhibitionID')),
                "ExhRegistrationID": sessionStorage.getItem('ExhRegistrationIDSS'),
                "ChapterID": data.ChapterID,
                'ChapterName': data.ChapterName,
                "HSNCode": data.HSNCode,
                "MachineDetails": data.MachineDetails,
                "Length": data.Length,
                "Breadth": data.Breadth,
                "Height": data.Height,
                "FloorCapacity": data.FloorCapacity,
                "MachineWeight": data.MachineWeight,
                "ManufacturerName": data.ManufacturerName,
                "ManufacturingCountry": data.ManufacturingCountry,
            }
            list.push(data)
        })
        console.log("Ramark--", Remark)
        if (exbTableErr == "") {
            this.setState({ isLoading: true })
            this.props.updateExhibitorDetails_action(list, Remark)
                .then((data) => {
                    if (data.error != 1) {
                        console.log("updatedetails", data.data)
                        toastSuccess("Data Updated Successfully")
                        setTimeout(() => {
                            this.setState({ isLoading: false })
                            this.handleCancel()
                        }, 300);
                    } else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                }).catch((err) => {
                    console.log(err)
                })
        }
        else {
            toastError("Please Correct Length And Breadth Size")
        }
    }

    // Get Fill List
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value)
            .then((data) => {
                if (data.error != 1) {
                    if (actionID === primaryChapterListActionId) {
                        async function seqnoFormat(arr) {
                            for (var i = 0; i < arr.length; i++) {
                                if (arr[i].seqno != '' && arr[i].seqno != null) {
                                    arr[i].ChapterName.trim()
                                    // console.log(arr[i].ChapterName)
                                    if (/^\d$/.test(arr[i].ChapterName[0])) {
                                        for (var j = 0; j < arr[i].ChapterName.length; j++) {
                                            if (arr[i].ChapterName[j] == ' ') {
                                                var str = arr[i].ChapterName.slice(j)
                                                var finalName = arr[i].seqno + str
                                                arr[i].ChapterName = finalName
                                                // console.log("primaryChapterListActionId 121212", i, finalName)
                                                break
                                            }
                                        }
                                    } else {
                                        var str = arr[i].ChapterName
                                        var finalName = arr[i].seqno + ' ' + str
                                        arr[i].ChapterName = finalName
                                    }
                                }
                            }
                            return
                        }
                        for (var i = 0; i < data.data['primaryChapter'].length; i++) {
                            for (var j = 0; j < data.data['primaryChapter'].length - 1 - i; j++) {
                                var seq1 = data.data['primaryChapter'][j].seqno
                                var seq2 = data.data['primaryChapter'][j + 1].seqno
                                if ((parseFloat(seq1) > parseFloat(seq2))) {
                                    var temp = data.data['primaryChapter'][j]
                                    data.data['primaryChapter'][j] = data.data['primaryChapter'][j + 1]
                                    data.data['primaryChapter'][j + 1] = temp;
                                    // console.log(i, j, arr[j])
                                }
                            }
                            if (data.data['primaryChapter'][i].seqno == null || data.data['primaryChapter'][i].seqno == undefined) {
                                console.log(data.data['primaryChapter'][i].seqno, i)
                            }
                            // seqnoFormat(data.data['primaryChapter'])
                        }
                        for (var i = 0; i < data.data['level2'].length; i++) {
                            for (var j = 0; j < data.data['level2'].length - 1 - i; j++) {
                                var seq1 = data.data['level2'][j].seqno
                                var seq2 = data.data['level2'][j + 1].seqno
                                if ((parseFloat(seq1) > parseFloat(seq2))) {
                                    var temp = data.data['level2'][j]
                                    data.data['level2'][j] = data.data['level2'][j + 1]
                                    data.data['level2'][j + 1] = temp;
                                    // console.log(i, j, arr[j])
                                }
                            }
                            if (data.data['level2'][i].seqno == null || data.data['level2'][i].seqno == undefined) {
                                console.log(data.data['level2'][i].seqno, i)
                            }
                            seqnoFormat(data.data['level2'])
                        }
                        console.log("prr", data.data['level2'])
                        for (var i = 0; i < data.data['level3'].length; i++) {
                            for (var j = 0; j < data.data['level3'].length - 1 - i; j++) {

                                var seq1 = data.data['level3'][j].seqno
                                seq1 = seq1 != null && seq1.replaceAll('.', '')
                                var seq2 = data.data['level3'][j + 1].seqno
                                seq2 = seq2 != null && seq2.replaceAll('.', '')

                                if ((parseFloat(seq1) > parseFloat(seq2))) {
                                    var temp = data.data['level3'][j]
                                    data.data['level3'][j] = data.data['level3'][j + 1]
                                    data.data['level3'][j + 1] = temp;
                                }
                            }
                            if (data.data['level3'][i].seqno == null || data.data['level3'][i].seqno == undefined) {
                                console.log(data.data['level3'][i].seqno, i)
                            }
                            seqnoFormat(data.data['level3'])
                        }
                        for (var i = 0; i < data.data['level4'].length; i++) {
                            for (var j = 0; j < data.data['level4'].length - 1 - i; j++) {
                                var seq1 = data.data['level4'][j].seqno
                                seq1 = seq1 != null && seq1.replaceAll('.', '')
                                var seq2 = data.data['level4'][j + 1].seqno
                                seq2 = seq2 != null && seq2.replaceAll('.', '')
                                if ((parseFloat(seq1) > parseFloat(seq2))) {
                                    var temp = data.data['level4'][j]
                                    data.data['level4'][j] = data.data['level4'][j + 1]
                                    data.data['level4'][j + 1] = temp;
                                    // console.log(i, j, arr[j])
                                }
                            }
                            if (data.data['level4'][i].seqno == null || data.data['level4'][i].seqno == undefined) {
                                console.log(data.data['level4'][i].seqno, i)
                            }
                            seqnoFormat(data.data['level4'])
                        }

                        for (var i = 0; i < data.data['level2'].length; i++) {
                            data.data['level2'][i].isHighlight = false;
                        }
                        this.setState({
                            primaryChapterListData: data.data['primaryChapter'],
                            level0: data.data['level0'],
                            level2: data.data['level2'],
                            level3: data.data['level3'],
                            level4: data.data['level4'],
                            isLoading: false,
                        })
                        setTimeout(() => {
                            this.filterLevelData()
                        }, 300);
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    filterLevelData() {
        const { level2, level3, level4 } = this.state
        var newLevel = [...level2]
        for (var i = 0; i < level3.length; i++) {
            var tempLevel4 = [];
            for (var j = 0; j < level4.length; j++) {
                if (level3[i]['ChapterID'] == level4[j]['ParentChapterID']) {
                    tempLevel4.push(level4[j]);
                }
            }
            level3[i]['level_four_List'] = tempLevel4;
            // console.log(" level3[i]['level_four_List']", tempLevel4);
        }

        for (var i = 0; i < newLevel.length; i++) {
            var tempLevel3 = [];
            for (var j = 0; j < level3.length; j++) {
                if (newLevel[i]['ChapterID'] == level3[j]['ParentChapterID']) {
                    tempLevel3.push(level3[j]);
                }
            }
            newLevel[i]['level_third_List'] = tempLevel3;
            // console.log("level2[i]['level_third_List']", tempLevel3);

        }

        ///////////============
        console.log("level2", newLevel)
        this.setState({ level2: newLevel })
    }

    // Get  Details
    getExhibitorDetails() {
        this.setState({ isLoading: true })
        this.props.getExhibitorDetails_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log("Get details", data.data)

                    this.setState({ Remark: data.data['primaryChapterHeaderDetails'][0]['Remark'] })

                    // Chapter Level 2 Level 3 And Level 4
                    var allChap = []
                    const lev2 = data.data['primaryChapterListDetails']['level2'].filter(obj => obj.IsSelect == true)
                    allChap.push(...lev2)
                    const lev3 = data.data['primaryChapterListDetails']['level3'].filter(obj => obj.IsSelect == true)
                    allChap.push(...lev3)
                    const lev4 = data.data['primaryChapterListDetails']['level4'].filter(obj => obj.IsSelect == true)
                    allChap.push(...lev4)

                    // Primary Chapter Stall Area
                    const primaryChapterStallArea = data.data['primaryChapterHeaderDetails'][0].StallArea

                    // Only Sub Chapters
                    const finalSubChap = allChap.filter(element => element.IsCheckbox == true)
                    console.log("final chapters", finalSubChap)

                    // Setting chapters
                    const { level2, level3, level4 } = this.state
                    console.log("111->>>>>>>>>>>>>>>>>>>>>", level2)
                    console.log("222->>>>>>>>>>>>>>>>>>>>>", level3)
                    console.log("333->>>>>>>>>>>>>>>>>>>>>", level4)
                    level2.length > 0 && level2.map((data) => {
                        finalSubChap.length > 0 && finalSubChap.map((item) => {
                            if (data.ChapterID == item.ChapterID) {
                                data.IsSelect = true
                            }
                        })
                    })

                    level3.length > 0 && level3.map((data) => {
                        finalSubChap.length > 0 && finalSubChap.map((item) => {
                            if (data.ChapterID == item.ChapterID) {
                                data.IsSelect = true
                            }
                        })
                    })

                    level4.length > 0 && level4.map((data) => {
                        finalSubChap.length > 0 && finalSubChap.map((item) => {
                            if (data.ChapterID == item.ChapterID) {
                                data.IsSelect = true
                            }
                        })
                    })

                    this.setState({
                        isLoading: false,
                        subChapters: finalSubChap,
                        stallArea: primaryChapterStallArea,
                    })
                    console.log(allChap)
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            }).catch((err) => {
                console.log(err)
            })
    }

    // On Change Of Product Name In Exhibit Table
    onEnterProductName(e, ID) {
        const { subChapters } = this.state
        subChapters.map((data, i) => {
            if (ID == i) {
                data.ChapterName = e.target.value
            }
        })
        for (var i = 0; i < subChapters.length; i++) {
            if (ID != i && subChapters[i].ChapterName.toString().trim().toLowerCase() == e.target.value.toString().trim().toLowerCase()) {
                this.setState({ exbTableErr: 'Product name must be unique.' })
                return;
            } else {
                this.setState({ exbTableErr: '' })
            }
        }
        setTimeout(() => {
            this.setState({ subChapters: subChapters })
        }, 200);
    }

    // On Machine Details Change In Exhibit Table
    onMachineDetailsChange(e, ID) {
        const { subChapters } = this.state
        subChapters.map((data, i) => {
            if (ID == i) {
                data.MachineDetails = e.target.value
            }
        })
        setTimeout(() => {
            this.setState({ subChapters: subChapters })
        }, 100);
    }

    // On HSN No Change In Exhibit Table
    onEnterHSNCode(e, ID) {
        const { subChapters } = this.state
        subChapters.map((data, i) => {
            if (ID == i) {
                data.HSNCode = e.target.value
            }
        })
        setTimeout(() => {
            this.setState({ subChapters: subChapters })
        }, 200);
    }

    // On Length Change In Exhibit Table
    onLengthChange(e, ID) {
        const { subChapters, stallArea } = this.state
        var breadth = 0;
        subChapters.map((data, i) => {
            if (ID == i) {
                data.Length = e.target.value
                breadth = data.Breadth;
            }
        })
        setTimeout(() => {
            this.setState({ subChapters: subChapters })
        }, 100);
        if (stallArea != null && stallArea != '' && e.target.value != null && e.target.value != '' && e.target.value != 0 &&
            breadth != null && breadth != 0) {
            var totalEnterSpace;
            totalEnterSpace = parseFloat(e.target.value) * parseFloat(breadth);
            if (totalEnterSpace > stallArea) {
                this.setState({ exbTableErr: 'Length and breadth must be below or equal to stall area.' })
            } else {
                this.setState({ exbTableErr: '' })
            }
        }

    }

    // On Bredth Change In ExiBit Table
    onBreadthChange(e, ID) {
        const { subChapters, stallArea } = this.state
        var length = 0;
        subChapters.map((data, i) => {
            if (ID == i) {
                data.Breadth = e.target.value
                length = data.Length;
            }
        })
        if (stallArea != null && stallArea != '' && e.target.value != null && e.target.value != '' && e.target.value != 0 &&
            length != null && length != 0) {
            var totalEnterSpace;
            totalEnterSpace = parseFloat(e.target.value) * parseFloat(length);
            if (totalEnterSpace > stallArea) {
                subChapters.map((data, i) => {
                    if (ID == i) {
                        data.isLengthValidate = true;
                    }
                })
                this.setState({ exbTableErr: 'Length and breadth must be below or equal to stall area.' })
            } else {
                subChapters.map((data, i) => {
                    if (ID == i) {
                        data.isLengthValidate = false;
                    }
                })
                this.setState({ exbTableErr: '' })
            }
        }
        setTimeout(() => {
            this.setState({ subChapters: subChapters })
            console.log(this.state.subChapters)
        }, 100);
    }

    // On Height Change In Exibit Table
    onHeightChange(e, ID) {
        const { subChapters } = this.state
        subChapters.map((data, i) => {
            if (ID == i) {
                data.Height = e.target.value
            }
        })
        setTimeout(() => {
            this.setState({ subChapters: subChapters })
        }, 100);

        if (e.target.value != null && e.target.value != '' && e.target.value != 0 && e.target.value > 3) {
            this.setState({ exbTableErr: 'Height must be less than or equal to 3.' })
        } else {
            this.setState({ exbTableErr: '' })
        }
    }

    // On Flor Capacity Change In Exibit Table
    onFloorCapacityChange(e, ID) {
        const { subChapters } = this.state
        subChapters.map((data, i) => {
            if (ID == i) {
                data.FloorCapacity = e.target.value
            }
        })
        setTimeout(() => {
            this.setState({ subChapters: subChapters })
        }, 100);
    }

    // On Machine Weight Change 
    onMachineWeightChange(e, ID) {
        const { subChapters } = this.state
        subChapters.map((data, i) => {
            if (ID == i) {
                data.MachineWeight = e.target.value
            }
        })
        setTimeout(() => {
            this.setState({ subChapters: subChapters })
        }, 100);
    }

    // On Manufacturer Name Change
    onManufacturerNameChange(e, ID) {
        const { subChapters } = this.state
        subChapters.map((data, i) => {
            if (ID == i) {
                data.ManufacturerName = e.target.value
            }
        })
        setTimeout(() => {
            this.setState({ subChapters: subChapters })
        }, 100);
    }

    // On Manufacturing Country Name Change
    onManufacturingCountryChange(e, ID) {
        const { subChapters } = this.state
        subChapters.map((data, i) => {
            if (ID == i) {
                data.ManufacturingCountry = e.target.value
            }
        })
        setTimeout(() => {
            this.setState({ subChapters: subChapters })
        }, 100);
    }

    maxLengthCheck = (object, maxLength) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    // On Cancel Click
    onCancelClick() {
        this.props.navigate('/manualForms')
    }

    // On Add click
    onAddClick(e) {
        e.preventDefault()
        const { isOpen } = this.state
        this.setState({ isOpen: !isOpen })
    }

    // on check level 2
    onCheckLevel2(e, id) {
        const { level2, subChapters } = this.state
        var tempExhibitDetailsList = []
        for (let i = 0; i < level2.length; i++) {
            if (level2[i]['ChapterID'] == id) {

                if (e.target.checked) {
                    if (!subChapters.includes(level2[i])) {
                        level2[i].isLengthValidate = false;
                        subChapters.push(level2[i])
                        this.setState({ subChapters: subChapters })
                    }
                }
                else {
                    this.setState({ subChapters: subChapters.filter(item => item.ChapterID != level2[i]['ChapterID']) })
                }

                level2[i]['IsSelect'] = !level2[i]['IsSelect']
            }
        }
        setTimeout(() => {
            this.setState({ level2: level2 })
        }, 200);
    }

    // on check level 3
    onCheckLevel3(e, id) {
        const { level3, subChapters } = this.state
        var tempExhibitDetailsList = []
        for (let i = 0; i < level3.length; i++) {
            if (level3[i]['ChapterID'] == id) {

                if (e.target.checked) {
                    if (!subChapters.includes(level3[i])) {
                        level3[i].isLengthValidate = false;
                        subChapters.push(level3[i])
                        this.setState({ subChapters: subChapters })
                    }
                }
                else {
                    this.setState({ subChapters: subChapters.filter(item => item.ChapterID != level3[i]['ChapterID']) })
                }

                level3[i]['IsSelect'] = !level3[i]['IsSelect']
            }
        }
        setTimeout(() => {
            this.setState({ level3: level3 })
        }, 200);
    }

    getDoubleStroeySSI() {

        this.setState({ isLoading: true });
        const exhRegistrationID = sessionStorage.getItem('ExhRegistrationIDSS')
        const exhibitionID = sessionStorage.getItem('sessionExhibitionID')

        this.props
            .getDoubleStoreySSI_action(exhRegistrationID, exhibitionID)
            .then((data) => {
                if (data.error != 1) {
                    if (data.data != '') {

                        var guideLines = []
                        for (var i = 0; i < data.data[1].length; i++) {
                            if (data.data[1][i].NoteType == 'Guidelines') {
                                guideLines.push(data.data[1][i])
                            }
                        }
                        this.setState({
                            guideLines: guideLines,
                            isShowMachineryGuidelines: data.data[0][0].isShowMachineryGuidelines,
                            guidelinesAddress: data.data[0][0].Address,
                        })
                        this.setState({ isLoading: false })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    // to call level third list 
    record(level2, level3, level4) {
        // var newdata = JSON.stringify(data);
        var tempLevel3 = [];
        for (var j = 0; j < level3.length; j++) {
            if (level2.ChapterID == level3[j]['ParentChapterID']) {
                tempLevel3.push(level3[j]);
            }
        }
        return (
            <>
                <div id={'collapse_l1_' + level2.ChapterID} class="accordion-collapse collapse" aria-labelledby={'heading_l1_' + level2.ChapterID} data-bs-parent="#accordionExample">
                    {
                        tempLevel3 && tempLevel3.map(level3data => {
                            return (
                                <div class="accordion-body">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id={'heading_l2_' + level3data.ChapterID}>
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={'#collapse_l2_' + level3data.ChapterID} aria-expanded="false" aria-controls={'collapse_l2_' + level3data.ChapterID}>
                                                {/* // checkbox for level3 */}
                                                {
                                                    level3data.IsCheckbox && <input type="checkbox" className="from-left-sp"
                                                        name="" key={level3data.ChapterID} checked={level3data.IsSelect}
                                                        onClick={e => this.onCheckLevel3(e, level3data.ChapterID)}
                                                    />
                                                }

                                                {level3data.ChapterName} </button>
                                        </h2>
                                        {this.recordFour(level3data, level4)}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </>
        );
    }

    // on check level 4
    onSelectLevel4(e, id) {
        const { level4, subChapters } = this.state
        var tempExhibitDetailsList = []
        for (let i = 0; i < level4.length; i++) {
            if (level4[i]['ChapterID'] == id) {

                if (e.target.checked) {
                    if (!subChapters.includes(level4[i])) {
                        level4[i].isLengthValidate = false;
                        subChapters.push(level4[i])
                        this.setState({ subChapters: subChapters })
                    }
                }
                else {
                    this.setState({ subChapters: subChapters.filter(item => item.ChapterID != level4[i]['ChapterID']) })
                }

                level4[i]['IsSelect'] = !level4[i]['IsSelect']
            }
        }
        setTimeout(() => {
            console.log("xxxxxxxxxxxxxxxxxxxxxxxxx", subChapters)
            this.setState({ level4: level4 })
        }, 200);

    }

    // to call level four list
    recordFour(level3data, level4) {
        var tempLevel4 = [];
        for (var j = 0; j < level4.length; j++) {
            if (level3data.ChapterID == level4[j]['ParentChapterID']) {
                tempLevel4.push(level4[j]);
            }
        }
        return (<>
            <div id={'collapse_l2_' + level3data.ChapterID} class="accordion-collapse collapse" aria-labelledby={'heading_l2_' + level3data.ChapterID}>
                {
                    tempLevel4 && tempLevel4.map(level4data => {
                        return (
                            <div class="accordion-body third-step">
                                {/* level4data.IsCheckbox && */}
                                <input type="checkbox" name="" key={level4data.ChapterID} checked={level4data.IsSelect}
                                    onClick={(e) => this.onSelectLevel4(e, level4data.ChapterID)} />
                                {level4data.ChapterName}
                            </div>
                        )
                    })
                }
            </div>
        </>);
    }


    render() {
        const { subChapters, isLoading, exbTableErr, isOpen, level2, level3, level4, DeadlineDate, currentDate, guideLines, isShowMachineryGuidelines, guidelinesAddress, Remark, StatusID } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <ExhibitorHeader />
                <ExhibitorDrawer />
                <div className="page">
                    <div className="page-main">
                        <div className="main-content with-dr-container">
                            <div className=" width-dr-nav">
                                <div className="row">
                                    <ExhibitorTopMenu />
                                </div>
                            </div>
                            <div className="mt-2">
                                <div className="col-xl-12 p-1">
                                    <div className="card-header p-3">
                                        <h3 className="card-title"> Exhibit Details </h3>{<span className='text-danger ml-2' style={{ float: 'left' }}>{exbTableErr}</span>}
                                    </div>
                                    <div className="row">
                                        {currentDate > DeadlineDate &&
                                            <span className="text-danger">THE DEADLINE TO FILL OUT THE FORM HAS PASSED.</span>
                                        }
                                    </div>
                                    <div className="mt-1">
                                        <button className="byn btn-primary rounded p-1"
                                            style={{ float: "right", marginBottom: "10px", height: "30px", cursor: 'pointer' }}
                                            disabled={currentDate > DeadlineDate}
                                            onClick={(e) => this.onAddClick(e)}
                                        >Add Chapter</button>
                                    </div>
                                    <table
                                        className="table table-bordered mt-2"
                                        style={{ border: "1px solid #d8d8d8 !important" }}
                                    >
                                        <thead>
                                            <tr>
                                                <th>Chapter Selected</th>
                                                <th>Machine Details</th>
                                                <th>HSN Code</th>
                                                <th colSpan={3}>Machine Dimension (mtr)</th>
                                                <th>Floor Capacity(Kgs per sq mtr)</th>
                                                <th>Machine Weight (Kg)</th>
                                                <th>Manufacturer Name</th>
                                                <th>Manufacturer Country</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td />
                                                <td />
                                                <td />
                                                <td>Length</td>
                                                <td>Breadth</td>
                                                <td>Height</td>
                                                <td />
                                                <td />
                                                <td />
                                                <td />
                                            </tr>
                                            {
                                                subChapters.length > 0 ? subChapters.map((data, ID) => (
                                                    <tr key={ID}>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="3.7.22"
                                                                value={data.ChapterName}
                                                                onChange={(e) => this.onEnterProductName(e, ID)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={0.0}
                                                                maxLength={'200'}
                                                                value={data.MachineDetails}
                                                                onChange={(e) => this.onMachineDetailsChange(e, ID)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={0.0}
                                                                maxLength={'10'}
                                                                value={data.HSNCode}
                                                                onInput={(e) => this.maxLengthCheck(e, 10)}
                                                                onChange={(e) => this.onEnterHSNCode(e, ID)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                maxLength={'9'}
                                                                value={data.Length}
                                                                onInput={(e) => this.maxLengthCheck(e, 10)}
                                                                onChange={(e) => this.onLengthChange(e, ID)}

                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                maxLength={'9'}
                                                                value={data.Breadth}
                                                                onInput={e => this.maxLengthCheck(e, 10)}
                                                                onChange={(e) => this.onBreadthChange(e, ID)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                value={data.Height}
                                                                maxLength={'9'}
                                                                onInput={(e) => this.maxLengthCheck(e, 10)}
                                                                onChange={(e) => this.onHeightChange(e, ID)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                maxLength={'9'}
                                                                value={data.FloorCapacity}
                                                                onInput={(e) => this.maxLengthCheck(e, 10)}
                                                                onChange={(e) => this.onFloorCapacityChange(e, ID)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={data.MachineWeight}
                                                                maxLength={'9'}
                                                                onInput={(e) => this.maxLengthCheck(e, 10)}
                                                                onChange={(e) => this.onMachineWeightChange(e, ID)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                maxLength={'200'}
                                                                value={data.ManufacturerName}
                                                                onChange={(e) => this.onManufacturerNameChange(e, ID)}

                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                maxLength={'100'}
                                                                value={data.ManufacturingCountry}
                                                                onChange={(e) => this.onManufacturingCountryChange(e, ID)}
                                                            />
                                                        </td>
                                                    </tr>
                                                )) :
                                                    <tr >
                                                        <td colSpan={10} className="text-center">Record not available</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-xl-12">
                                    {/* <p class="mb-3"><strong>Guideline for Machinery Display at Kenyatta International Convention Centre, Nairobi</strong></p>
                                    <p class="mb-3">1.   Display of machinery is allowed only in static mode</p>
                                    <p class="mb-3">2.  Very heavy-duty equipment and machinery are not allowed into the Hall and shall be allocated in the external exhibition space. </p>
                                    <p class="mb-3">3.  Floor load capacity: The floor space can hold 3 tons per sqm inside the hall.</p>
                                    <p class="mb-3">4.  Compressed Air and water supply are not available in the halls.</p>
                                    <p class="mb-3">5.  Loading door dimensions: The length is 9.56 mtr and height is 3.18 mtr.</p>
                                    <p class="mb-3"><strong>Note:</strong> <span class="text-danger"> If  any exhibitor wants to show live machinery, please contact the organizer for special arrangements.</span></p> */}

                                    {isShowMachineryGuidelines ?
                                        <p className="m-0 mt-2">
                                            <strong>Guideline for Machinery Display at {guidelinesAddress}</strong><br />
                                            {this.state.guideLines.length > 0 && this.state.guideLines.map((data, i) => (
                                                <>
                                                    {i + 1}- {data.Description}< br />
                                                </>
                                            ))}
                                        </p>
                                        : <></>}

                                    <div class="row mt-4 mb-4">
                                        <form>
                                            <strong> <label>Remark</label></strong>
                                            <textarea class="form-control"
                                                value={Remark}
                                                onChange={(e) => this.setState({ Remark: e.target.value })}
                                                maxLength={300}
                                                rows="3">
                                            </textarea>
                                        </form>
                                    </div>

                                    <div className="d-flex mb-3">
                                        <button
                                            type="button"
                                            className="btn btn-primary rounded-0 d-block"
                                            style={{ marginRight: 10 }}
                                            disabled={currentDate > DeadlineDate}
                                            onClick={() => this.updateExhibitorDetails()}
                                        >
                                            Update
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-primary rounded-0 d-block"
                                            style={{ marginRight: 10 }}
                                            onClick={() => this.onCancelClick()}
                                        >
                                            Back
                                        </button>
                                    </div>
                                </div>


                            </div>
                        </div>
                        {/* <div class="footer-sec">
          <div class="sdsd">
              <strong>Ownership:</strong> All content, formats and images used on this site are owned or licensed by India ITME Society. You may not copy, reproduce, distribute, publish, display, perform, modify, create derivative works, transmit, or in any other way exploit any part of copyrighted material without permission of the copyright owner. Failure to comply shall result in legal action.
          </div>
      </div>

      <div class="footer-first">
          <div class="container-fluid">
              <div class="footer-first">  ITME All Rights Reserved <i class="fa fa-copyright" aria-hidden="true"></i> 2023  </div>
          </div>
      </div> */}
                    </div>
                </div>
                <Modal isOpen={isOpen} onRequestClose={this.onAddClick}>
                    <div className="modal-dialog">
                        <div className="modal-content" style={{ marginTop: '6em', left: '-8em' }}>
                            <div className="modal-header p-3 pb-1">
                                <h3 className="card-title" style={{ fontSize: 14 }}><strong>Select Below Product</strong>
                                </h3>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ fontSize: '12px' }}
                                    // data-bs-dismiss="modal"
                                    // aria-label="Close"
                                    onClick={e => this.setState({ isOpen: !isOpen })}
                                />
                            </div>
                            <div className="modal-body" style={{ maxHeight: '400px', overflow: 'auto' }}>
                                <div className="" style={{ paddingLeft: 10 }}>
                                    <form className="needs-validation pb-2" noValidate="">
                                        <div className="form-row">

                                            <div class="accordion" id="accordionExample">
                                                {
                                                    level2.length > 0 && level2.map(level2Data => (
                                                        <>
                                                            {
                                                                <div class="accordion-item">
                                                                    <h2 class="accordion-header" id={'heading_l1_' + level2Data.ChapterID}>
                                                                        <button class={"accordion-button collapsed"}
                                                                            type="button" data-bs-toggle="collapse"
                                                                            data-bs-target={'#collapse_l1_' + level2Data.ChapterID} aria-expanded="false"
                                                                            aria-controls={'collapse_l1_' + level2Data.ChapterID}>
                                                                            {
                                                                                level2Data.IsCheckbox &&
                                                                                <input type="checkbox" className="from-left-sp" name="" key={level2Data.ChapterID}
                                                                                    checked={level2Data.IsSelect}
                                                                                    onClick={e => this.onCheckLevel2(e, level2Data.ChapterID)}
                                                                                />
                                                                            }
                                                                            {level2Data.ChapterName} </button>
                                                                    </h2>
                                                                    {this.record(level2Data, level3, level4)}
                                                                </div>
                                                            }
                                                        </>
                                                    ))
                                                }
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
            </>
        )
    }
}

export default withRouter(connect(null, { getExhibitorDetails_action, updateExhibitorDetails_action, getDoubleStoreySSI_action, fill_action })(ExhibitorDetails));