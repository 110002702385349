import { Component, forwardRef } from "react";
import { ToastContainer } from "react-toastify";
import Loader from "../../../../utils/Loader";

import AdminDrawer from "../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../common_components/header_with_logo";
import { withRouter } from "../../../../utils/withRouter";
import { connect } from "react-redux";
import { downloadReport, toastError, toastSuccess } from "../../../../utils/constants";
import Pagination from "../../../../utils/pagination";
import { sendEmailOfAvailableArea_action, moveToApplyStatus_action, sendWaitingEmail_action, getApplicationStatusManagementList_action } from '../../../../actions/admin_module_action/manage_area/application_status_management_action/application_status_management_action';
import { fill_action } from '../../../../actions/fillCombo_action';
import { allExhibitorNameActionIdId, accountTypeActionId, statusTypeActionId, primaryChapterActionId, exhibitionId } from "../../../../utils/constants";
import moment from 'moment';
class ApplicationStatusManagement extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            currentPage: 1,
            applicationStatusManagementList: [],
            isDisable: true,
            allSelected: false,
            exhibitorList: [],
            bookingStatusId: '',
            bookingStatusList: '',
            exhibitionId: '',
            exhibitionList: [],
            ChapterName: '',
            BookingStatusId: '',
            primaryChapterList: [],
            exhibitorId: '',
            sumOfArea: '',
        }

        this.updateItem = this.updateItem.bind(this)
    }

    // On Component Load
    componentDidMount() {
        this.getFillList(allExhibitorNameActionIdId, '', '', '', '', '');
        this.getFillList(accountTypeActionId, '', '', '', '', '');
        this.getFillList(primaryChapterActionId, '', '', '', '', '');
        this.getFillList(statusTypeActionId, '', '', '', '', '');
        this.getFillList(exhibitionId, '', '', '', '', '');

        this.getApplicationStatusManagementList()

    }

    // get fill List dropdown  
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, exhRegistrationID) {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value, exhRegistrationID)
            .then((data) => {
                if (data.error != 1) {
                    // voucher list
                    if (actionID === allExhibitorNameActionIdId) {
                        // console.log("exhibitorList", data.data)
                        this.setState({
                            exhibitorList: data.data, isLoading: false,
                        })
                    }
                    if (actionID === statusTypeActionId) {
                        // console.log("accountTypeList", data.data)
                        this.setState({
                            bookingStatusList: data.data, isLoading: false,
                        })
                    }
                    if (actionID === primaryChapterActionId) {
                        // console.log("voucher type data", data.data)
                        this.setState({
                            primaryChapterList: data.data, isLoading: false,
                        })
                    }
                    if (actionID === exhibitionId) {
                        // console.log("voucher type data", data.data)
                        this.setState({
                            exhibitionList: data.data, isLoading: false,
                        })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    getApplicationStatusManagementList() {
        const { bookingStatusId, exhibitionId, exhibitorId, ChapterName } = this.state
        this.setState({ isLoading: true })
        this.props.getApplicationStatusManagementList_action(exhibitionId, exhibitorId, ChapterName, bookingStatusId)
            .then((data) => {
                if (data.error != 1) {
                    data.data != '' && data.data != null ?

                        data.data.map((item) => {
                            item.Is_checked = 0
                        })

                        : <></>
                    console.log("data", data)
                    this.setState({ isLoading: false, applicationStatusManagementList: data.data })

                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })

    }

    sumOfArea() {
        const { applicationStatusManagementList } = this.state

        const newArray = []

        applicationStatusManagementList.map((data) => {
            if (data.Is_checked == 1) {
                newArray.push(data)
            }
        })

        const sum = newArray.reduce((acc, obj) => acc + obj.AREA, 0);

        this.setState({ sumOfArea: sum })
    }

    checkedBoxList(pList, index, e) {
        const { applicationStatusManagementList } = this.state
        const event = e.target.checked

        if (event == true) {
            pList.forEach((data, i) => {
                if (i === index) {
                    data.Is_checked = 1
                }
            });

        } else {
            pList.forEach((data, i) => {
                if (i === index) {
                    data.Is_checked = 0
                }
            });
        }
        setTimeout(() => {
            this.sumOfArea()
        }, 100);
        console.log("pList", pList)

        const checkboxDisable = applicationStatusManagementList.some(item => item.Is_checked == 1)

        // debugger
        if (checkboxDisable) {
            this.setState({ isDisable: false })
        }
        else {
            this.setState({ isDisable: true })
        }

        const allSelectedChecked = applicationStatusManagementList.every(item => item.Is_checked == 1)

        // debugger
        if (allSelectedChecked) {
            this.setState({ allSelected: true })
        }
        else {
            this.setState({ allSelected: false })
        }

    }

    allSelectedCheckbox(e) {
        const { applicationStatusManagementList } = this.state

        if (e.target.checked == true) {
            applicationStatusManagementList.map((data) => {
                data.Is_checked = 1
            })
            this.setState({ allSelected: true, isDisable: false })
        } else {
            applicationStatusManagementList.map((data) => {
                data.Is_checked = 0
            })
            this.setState({ allSelected: false, isDisable: true })
        }

        setTimeout(() => {
            this.sumOfArea()
        }, 100);
    }

    handleClear() {
        const { applicationStatusManagementList } = this.state

        this.setState({
            exhibitorId: '',
            currencyID: '',
            fromAmount: '',
            toAmount: '',
            accountTypeId: '',
            currencyId: '',
            isDisable: true,
            allSelected: false,
            exhibitionId: '',
            bookingStatusId: '',
            ChapterName: ''
        })
        setTimeout(() => {
            this.getApplicationStatusManagementList()
            this.sumOfArea()
        }, 100);
        applicationStatusManagementList != '' ?
            applicationStatusManagementList.map((data) => {
                data.Is_checked = 0
            }) : <></>
    }

    sendEmailOfAvailableArea() {
        const { applicationStatusManagementList } = this.state
        const list = []
        applicationStatusManagementList.map((data) => {
            if (data.Is_checked == 1) {
                list.push(
                    {
                        ReceiptID: data.ReceiptID
                    }
                )
            }
        })
        console.log("applicationStatusManagementList", list)
        this.setState({ isLoading: true })
        this.props.sendEmailOfAvailableArea_action(list)
            .then((data) => {
                if (data.error != 1) {
                    console.log("data", data)
                    this.setState({ isLoading: false })
                    toastSuccess("Email Send successfully!")
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })

    }

    moveToApplyStatus() {
        const { applicationStatusManagementList } = this.state
        const list = []
        applicationStatusManagementList.map((data) => {
            if (data.Is_checked == 1) {
                list.push(
                    {
                        ReceiptID: data.ReceiptID
                    }
                )
            }
        })
        console.log("applicationStatusManagementList", list)
        this.setState({ isLoading: true })
        this.props.moveToApplyStatus_action(list)
            .then((data) => {
                if (data.error != 1) {
                    console.log("data", data)
                    this.setState({ isLoading: false })
                    toastSuccess("Email Send successfully!")
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })

    }

    sendWaitingEmail() {
        const { applicationStatusManagementList } = this.state
        const list = []
        applicationStatusManagementList.map((data) => {
            if (data.Is_checked == 1) {
                list.push(
                    {
                        UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
                        ExhibitionID: data.ExhibitionID,
                        ExhRegistrationID: data.ExhRegistrationID
                    }
                )
            }
        })
        console.log("applicationStatusManagementList", list)
        this.setState({ isLoading: true })
        this.props.sendWaitingEmail_action(list)
            .then((data) => {
                if (data.error != 1) {
                    console.log("data", data)
                    this.setState({ isLoading: false })
                    toastSuccess("Email Send successfully!")
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })

    }

    updateItem(item) {
        const { applicationStatusManagementList } = this.state
        this.setState({ currentPage: item });
        const recordsPerPage = 10;
        const indexOfLastRecord = item * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = applicationStatusManagementList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(applicationStatusManagementList.length / recordsPerPage)
    }

    render() {
        const { isLoading, applicationStatusManagementList, exhibitorId, exhibitorList, currentPage, isDisable, allSelected, ChapterName, primaryChapterList, bookingStatusList, bookingStatusId, exhibitionId, exhibitionList, sumOfArea } = this.state

        const recordsPerPage = 10;
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = applicationStatusManagementList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(applicationStatusManagementList.length / recordsPerPage)

        return (
            <>

                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <AdminDrawer />

                    <div class="main-content">

                        <div class="row mt-1 pt-3 pb-3 p-2" style={{ alignItems: 'center' }}>
                            <div className="col-md-3">
                                <label>Exhibition</label>
                                <select
                                    className="form-control"
                                    id="roledb"
                                    // style={{ textAlign: "left" }}
                                    value={exhibitionId}
                                    onChange={(e) => this.setState({ exhibitionId: e.target.value })}
                                >
                                    <option>Select</option>
                                    {exhibitionList.length > 0 && exhibitionList.map((data) => (
                                        <option key={data.ExhibitionID
                                        } value={data.ExhibitionID
                                        }>{data.ExhibitionName}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-3">
                                <label>Exhibitor</label>
                                <select
                                    className="form-control"
                                    id="roledb"
                                    // style={{ textAlign: "left" }}
                                    value={exhibitorId}
                                    onChange={(e) => this.setState({ exhibitorId: e.target.value })}
                                >
                                    <option>Select</option>
                                    {exhibitorList.length > 0 && exhibitorList.map((data) => (
                                        <option key={data.ExhRegistrationID
                                        } value={data.ExhRegistrationID
                                        }>{data.CompanyName}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-3">
                                <label>Chapter</label>
                                <select
                                    className="form-control"
                                    id="roledb"
                                    // style={{ textAlign: "left" }}
                                    value={ChapterName}
                                    onChange={(e) => this.setState({ ChapterName: e.target.value })}
                                >
                                    <option>Select</option>
                                    {primaryChapterList.length > 0 && primaryChapterList.map((data) => (
                                        <option key={data.PrimaryChapter
                                        } value={data.PrimaryChapter
                                        }>{data.PrimaryChapter}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div class="row pb-3 p-2 pt-0" style={{ alignItems: 'center' }}>
                            <div className="col-md-3">
                                <label>Status</label>
                                <select
                                    className="form-control"
                                    id="roledb"
                                    // style={{ textAlign: "left" }}
                                    value={bookingStatusId}
                                    onChange={(e) => this.setState({ bookingStatusId: e.target.value })}
                                >
                                    <option>Select</option>
                                    {bookingStatusList.length > 0 && bookingStatusList.map((data) => (
                                        <option key={data.ID
                                        } value={data.ID
                                        }>{data.Name}</option>
                                    ))}
                                </select>
                            </div>
                            <div class="btn-group w-auto" style={{ justifyContent: 'center', marginTop: '20px' }}>
                                <button class="btn rounded-0 btn-primary"
                                    onClick={() => this.getApplicationStatusManagementList()}
                                    style={{ padding: "4px 13px" }}>Search</button>
                                <button class="btn rounded-0 btn-primary"
                                    onClick={() => this.handleClear()}
                                    style={{ padding: "4px 13px", marginLeft: "10px" }}>Clear</button>
                            </div>

                        </div>
                        <div class="row p-2 mb-2 w-100" style={{ justifyContent: 'space-between' }}>
                            <div class="btn-group w-auto d-flex " >
                                <button class="btn rounded-0 btn-dark"
                                    style={{ padding: "4px 13px", marginLeft: "10px" }}
                                    disabled={isDisable}
                                    onClick={() => this.sendEmailOfAvailableArea()}
                                >send email of available area</button>
                                <button class="btn rounded-0 btn-dark"
                                    style={{ padding: "4px 13px", marginLeft: "10px" }}
                                    disabled={isDisable}
                                    onClick={() => this.moveToApplyStatus()}
                                >move to apply status</button>
                                <button class="btn rounded-0 btn-dark"
                                    style={{ padding: "4px 13px", marginLeft: "10px" }}
                                    disabled={isDisable}
                                    onClick={() => this.sendWaitingEmail()}
                                >send waiting email</button>
                            </div>

                            <div class="btn-group w-40">
                                <span class="btn" style={{ border: '1px solid #fff' }}><strong>Balance Area : 0 m<sup>2</sup></strong></span>
                                <span class="btn w-80" style={{ textAlign: 'left', border: '1px solid #fff' }}><strong>Selectes Exhibuter Area : {sumOfArea != '' ? sumOfArea : 0} m<sup>2</sup></strong></span>
                            </div>

                        </div>

                        <div class="table-responsive mb-5 p-2">
                            <table class="table border text-nowrap text-md-nowrap table-bordered mb-0 ">
                                <thead>
                                    <tr>
                                        <th class="text-center">
                                            <input type="checkbox"
                                                onClick={(e) => this.allSelectedCheckbox(e)}
                                                checked={allSelected}
                                            /></th>
                                        <th>Exhibuter Name</th>
                                        <th>Reference No.</th>
                                        <th style={{ width: '15%' }}> Registration Date</th>
                                        <th>Chapter</th>
                                        <th>Area</th>
                                        <th>Status</th>
                                        <th>Currency</th>
                                        <th>Payment Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        currentRecords.length > 0 && currentRecords != null ?
                                            currentRecords.map((data, i) => (
                                                <tr>
                                                    <td class="text-center"> <input type="checkbox"
                                                        onClick={(e) => this.checkedBoxList(currentRecords, i, e)}
                                                        checked={data.Is_checked}
                                                        class="" name=""
                                                    /> </td>
                                                    <td>{data.CompanyName != null ? data.CompanyName : '-'}</td>
                                                    <td>{data.REFNO != null ? data.REFNO : '-'}</td>
                                                    <td>{data.BOOKING_DATE != null ? moment(data.BOOKING_DATE).format('DD-MM-YYYY') : '-'}</td>
                                                    <td>{data.ChapterName != null ? data.ChapterName : '-'}</td>
                                                    <td>{data.AREA != null ? data.AREA : '-'}</td>
                                                    <td>{data.Status != null ? data.Status : '-'}</td>
                                                    <td>{data.Code != null ? data.Code : '-'}</td>
                                                    <td>{data.PaymentType != null ? data.PaymentType : '-'}</td>

                                                </tr>
                                            )) : (<tr><td colSpan={10} className='text-center'>Record not found</td></tr>)
                                    }

                                </tbody>
                            </table>
                            <div className="mt-2 float-left">
                                {
                                    applicationStatusManagementList.length > 0 ?
                                        <Pagination
                                            nPages={nPages}
                                            currentPage={currentPage}
                                            setCurrentPage={this.updateItem}
                                        /> : <></>
                                }
                                <div className="tab-rec " style={{ float: "right" }}>
                                    <p>{`${currentPage} of ${nPages} pages`}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </>

                {/* <Footerwithdr /> */}

            </>
        );
    }


}

export default withRouter(connect(null, { getApplicationStatusManagementList_action, sendEmailOfAvailableArea_action, moveToApplyStatus_action, sendWaitingEmail_action, fill_action })(ApplicationStatusManagement));