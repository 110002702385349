import {
    SELECT_EXHIBITION_ACTION_TYPE, selectExhibitionUrl, headers
} from "../../../utils/constants"
import { axiosPost, axiosGet } from "../../../react_axios/Axios"

export const
    selectExhibition_action = (ExhAccountID) => async (dispatch) => {

        try {
            const params = {
                AccountId: sessionStorage.getItem("AccountIDSS"),
                Email : sessionStorage.getItem("userEmailSS"),
                UserID : JSON.parse(sessionStorage.getItem('loginDataSS')).UserID
            }
            // console.log("ReceiptList-Action--------------------------->", mapdata)

            const res = await axiosGet(selectExhibitionUrl, params, headers);
            dispatch({
                type: SELECT_EXHIBITION_ACTION_TYPE,
                payload: res,
            });

            return Promise.resolve(res);
        } catch (err) {
            return Promise.reject(err);
        }

    }