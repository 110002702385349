import { async } from "q"
import { axiosGet, axiosPost } from "../../../../../react_axios/Axios"
import { headers, getKeywordAndInformationListUrl, deleteKeywordAndInformationUrl, insertUpdateKeywordAndInformationUrl, GET_KEYWORDS_INFORMATION_LIST__ACTION_TYPE, DELETE_KEYWORDS_INFORMATION_LIST__ACTION_TYPE, INSERT_AND_UPDATE_KEYWORDS_INFORMATION_LIST__ACTION_TYPE } from "../../../../../utils/constants"

export const getKeywordsAndInformationList_action = () => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhregistrationID: parseInt(sessionStorage.getItem('ExhRegistrationIDSS')),
        }

        const res = await axiosGet(getKeywordAndInformationListUrl, params)
        dispatch({
            type: GET_KEYWORDS_INFORMATION_LIST__ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const submitKeywordsAndInformation_action = (KeywordID, ManualFormID, keywordInformationList) => async (dispatch) => {
    try {
        const mapData = {
            UserID: parseInt(JSON.parse(sessionStorage.getItem('loginDataSS')).UserID),
            KeywordID: KeywordID != '' ? parseInt(KeywordID) : null,
            ManualFormID: parseInt(ManualFormID),
            ExhibitionID: parseInt(sessionStorage.getItem('sessionExhibitionID')),
            ExhregistrationID: parseInt(sessionStorage.getItem('ExhRegistrationIDSS')),
            keywordInformationList: keywordInformationList,
        }
        const res = await axiosPost(insertUpdateKeywordAndInformationUrl, mapData, headers)

        dispatch({
            type: INSERT_AND_UPDATE_KEYWORDS_INFORMATION_LIST__ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const deleteKeywordsAndInformation_action = (KeywordID) => async (dispatch) => {
    try {
        const mapData = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            KeywordID: KeywordID
        }
        const res = await axiosPost(deleteKeywordAndInformationUrl, mapData, headers)

        dispatch({
            type: DELETE_KEYWORDS_INFORMATION_LIST__ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}