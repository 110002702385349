import { Component } from "react";
import Loader from "../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action } from '../../../../actions/fillCombo_action';
import { cityActionId, stallTypeActionId, stallListActionId, countryActionId, exhibitionActionId, toastError, toastSuccess, mainChapterListActionId } from "../../../../utils/constants";
import DatePicker from "react-datepicker";
import Select from 'react-select';

import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import Pagination from "../../../../utils/pagination";
import AdminDrawer from "../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../common_components/header_with_logo";
import { useNavigate } from "react-router-dom";
import ExhibitorDrawer from "../../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../../common_components/exhibitor_module_header";
import { data } from "jquery";
import { getHallMasterDetails_action, insertUpdateHallMaster_action } from "../../../../actions/admin_module_action/master_action/hall_master_action";
import Footer from "../../../../common_components/Footer";


class HallMasterAU extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            currentPage: 1,
            exhibitionList: [],
            chapterList: [],
            selectedChapterList: [],
            is_add: false,
            is_update: false,
            exhibitionID: '',
            hallName: '',
            hallID: ''
        }

        this.getFillList = this.getFillList.bind(this)
        this.getHallMasterDetails = this.getHallMasterDetails.bind(this)
        this.onPreviousClick = this.onPreviousClick.bind(this)
        this.insertHallMaster = this.insertHallMaster.bind(this)
        this.updateHallMaster = this.updateHallMaster.bind(this)
        this.onNextClick = this.onNextClick.bind(this)
        this.onExhibitionadd = this.onExhibitionadd.bind(this)
        this.handleSegmentSelection = this.handleSegmentSelection.bind(this)

    }

    componentDidMount() {
        this.setState({
            is_add: this.props.location.state != null && this.props.location.state.is_add != null ? this.props.location.state.is_add : false,
            is_update: this.props.location.state != null && this.props.location.state.is_update != null ? this.props.location.state.is_update : false,
            hallID: this.props.location.state != null && this.props.location.state.hallID != null ? this.props.location.state.hallID : '',
        })
        this.getFillList(exhibitionActionId, '', '', '', '', '');
        setTimeout(() => {
            this.state.is_update && this.getHallMasterDetails()
        }, 100);
    }

    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value)
            .then((data) => {
                if (data.error != 1) {
                    // country list
                    if (actionID === exhibitionActionId) {
                        console.log("country data", data.data)
                        // var internationalCountryData = data.data.length > 0 && data.data.filter((item) => item.Code != "91")
                        this.setState({
                            exhibitionList: data.data
                        })

                    }
                    if (actionID === mainChapterListActionId) {
                        console.log("country data", data.data)
                        // var internationalCountryData = data.data.length > 0 && data.data.filter((item) => item.Code != "91")
                        this.setState({
                            chapterList: data.data
                        })

                    }
                }
                this.setState({ isLoading: false })
            })
            .catch((e) => {
                console.log(e);
            });
    }

    getHallMasterDetails() {
        this.setState({ isLoading: true })
        const { hallID, selectedChapterList } = this.state
        this.props.getHallMasterDetails_action(hallID)
            .then((data) => {
                if (data.error != 1) {
                    console.log(data.data[1])
                    for (var i = 0; i < data.data[1].length; i++) {
                        var obj = { label: data.data[1][i].ChapterName, value: data.data[1][i].ChapterID }
                        selectedChapterList.push(obj)
                    }
                    this.setState({ hallName: data.data[0][0].HallName })
                    // this.setState({ exhibitionID: data.data[0][0].ExhibitionID })
                    this.onExhibitionadd(data.data[0][0].ExhibitionID)
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    onPreviousClick() {
        this.props.navigate('/hallMasterList',)
    }

    insertHallMaster() {
        const { isLoading, exhibitionID, hallName, is_update, selectedChapterList } = this.state
        const { hallID } = this.state
        if (exhibitionID != '' && exhibitionID != null && hallName != '' && hallName != '') {
            if (selectedChapterList.length > 0) {

                this.setState({ isLoading: true })
                this.props.insertUpdateHallMaster_action(exhibitionID, hallName, null, selectedChapterList)
                    .then((data) => {
                        if (data.error != 1) {
                            console.log(data.data)
                            this.props.navigate('/hallMasterList',)
                            toastSuccess("added successfully")
                            this.setState({ isLoading: false })
                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
            else {
                toastError("Please select atleast 1 segment")
            }
        }
        else {
            toastError("Please enter mandatory fields")
        }
    }

    updateHallMaster() {
        const { isLoading, exhibitionID, hallName, is_update, selectedChapterList } = this.state
        const { hallID } = this.state
        if (exhibitionID != '' && exhibitionID != null && hallName != '' && hallName != '' && hallID != null && hallID != '') {
            if (selectedChapterList.length > 0) {

                this.setState({ isLoading: true })
                this.props.insertUpdateHallMaster_action(exhibitionID, hallName, hallID, selectedChapterList)
                    .then((data) => {
                        if (data.error != 1) {
                            console.log(data.data)
                            this.props.navigate('/hallMasterList',)
                            toastSuccess("update successful")
                            this.setState({ isLoading: false })
                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
            else {
                toastError("Please select atleast 1 segment")
            }
        }
        else {
            toastError("Please enter mandatory fields")
        }
    }

    onNextClick() {
        const { is_update } = this.state;
        if (is_update == true) {
            this.updateHallMaster();
        } else {
            this.insertHallMaster();
        }
    }

    onExhibitionadd(val) {
        this.setState({ exhibitionID: val })
        this.getFillList(mainChapterListActionId, val, '', '', '', '');

    }

    handleSegmentSelection(e) {
        console.log("111111111111111111111", e)
        this.setState({ selectedChapterList: e })
    }

    render() {
        const { isLoading, exhibitionID, currentPage, hallMasterList, is_add, exhibitionList, hallName } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <div className="app-content with-dr-container com-height">
                        <AdminDrawer />

                        <div className="row">
                            <div className="step-name">
                                <p>Hall {is_add ? 'Add' : 'Update'}</p>
                            </div>
                        </div>
                        {/* <div className="app-content with-dr-container com-height"> */}
                        <div className="m-3">
                            <div className="row ">
                                {
                                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 1 &&
                                    <div className="row ">
                                        <div className="col-md-4">
                                            <label>Exhibition <span className="text-red">*</span></label>
                                            <select className="form-control"
                                                value={exhibitionID}
                                                onChange={(e) => this.onExhibitionadd(e.target.value)}  >
                                                <option value={''}>Select</option>
                                                {
                                                    exhibitionList.length > 0 && exhibitionList.map(data => (
                                                        <option value={data.ExhibitionID} key={data.ExhibitionID}>{data.ExhibitionName}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="col-md-4">
                                            <label>Hall Name <span className="text-red">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={hallName}
                                                onChange={(e) => this.setState({ hallName: e.target.value, })}
                                            />
                                        </div>
                                        <div
                                            className="col-md-4"
                                            style={{ padding: "9px!important" }}
                                        >
                                            <label className="" style={{ fontSize: 14 }}>
                                                Segment<span className="text-red">*</span>:
                                            </label>
                                            <Select
                                                options={this.state.chapterList.map((option) => ({ label: option.ChapterName, value: option.ChapterID }))}
                                                value={this.state.selectedChapterList}
                                                onChange={this.handleSegmentSelection}
                                                isMulti
                                            />
                                        </div>
                                        {/* <div class="">
                                    <label for="validationCustom03">Total Exhibition Area<span style={{ color: 'red' }}>*</span></label>
                                    <input value={totalExhibitionArea} onChange={(e) => this.setState({ totalExhibitionArea: parseInt(e.target.value) })} type="number" class="form-control" placeholder="" />
                                </div> */}
                                    </div>
                                }
                            </div>
                            < div className="row mt-2" >
                                <div className="col-md-12">
                                    <div className="main-container container-fluid p-2 pt-0 pb-0">
                                        <div className="mt-1 mb-4">
                                            {
                                                sessionStorage.getItem("sessionExhRegistrationID") == null &&
                                                <button className="btn btn-previous" onClick={e => this.onPreviousClick()}>
                                                    {" "}
                                                    Back{" "}
                                                </button>
                                            }

                                            <button className="btn btn-next-bot mx-2" onClick={e => this.onNextClick()} >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* </div> */}
                    </div>
                </>
                <Footer />
            </>)
    }

}
export default withRouter(connect(null, { fill_action, getHallMasterDetails_action, insertUpdateHallMaster_action })(HallMasterAU));