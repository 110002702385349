import { Component } from "react";
import Loader from "../../../../utils/Loader";
// import React, { Component } from 'react';
import * as XLSX from 'xlsx';
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action } from '../../../../actions/fillCombo_action';
import { cityActionId, stallTypeActionId, stallListActionId, countryActionId, exhibitionActionId, hallMasterListActionId, toastError, toastSuccess } from "../../../../utils/constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import Pagination from "../../../../utils/pagination";
import AdminDrawer from "../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../common_components/header_with_logo";
import { useNavigate } from "react-router-dom";
import ExhibitorDrawer from "../../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../../common_components/exhibitor_module_header";
import { data } from "jquery";
import { insertUpdateHallMaster_action, getHallMasterDetails_action } from "../../../../actions/admin_module_action/master_action/stall_master_action";
import Footer from "../../../../common_components/Footer";


class StallMasterAU extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            currentPage: 1,
            exhibitionList: [],
            hallMasterList: [],
            hallID: '',
            stallName: '',
            stallID: '',
            is_add: false,
            is_update: false,
            exhibitionID: '',
            stallList: []
        }

        this.getFillList = this.getFillList.bind(this)
        this.getStallMasterDetails = this.getStallMasterDetails.bind(this)
        this.onPreviousClick = this.onPreviousClick.bind(this)
        this.handleFileUpload = this.handleFileUpload.bind(this)
        this.insertStallMaster = this.insertStallMaster.bind(this)
        this.updateStallMaster = this.updateStallMaster.bind(this)
        this.onNextClick = this.onNextClick.bind(this)
        this.onExhibitionChange = this.onExhibitionChange.bind(this)

    }

    componentDidMount() {
        this.setState({
            is_add: this.props.location.state != null && this.props.location.state.is_add != null ? this.props.location.state.is_add : false,
            is_update: this.props.location.state != null && this.props.location.state.is_update != null ? this.props.location.state.is_update : false,
            stallID: this.props.location.state != null && this.props.location.state.stallID != null ? this.props.location.state.stallID : '',
        })
        this.getFillList(exhibitionActionId, '', '', '', '', '');
        this.state.is_add && this.getFillList(hallMasterListActionId, '', '', '', '', '');
        setTimeout(() => {
            this.state.is_update && this.getStallMasterDetails()
        }, 100);
    }

    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value)
            .then((data) => {
                if (data.error != 1) {
                    // country list
                    if (actionID === exhibitionActionId) {
                        console.log("country data", data.data)
                        // var internationalCountryData = data.data.length > 0 && data.data.filter((item) => item.Code != "91")
                        this.setState({
                            exhibitionList: data.data
                        })

                    }
                }
                if (data.error != 1) {
                    // country list
                    if (actionID === hallMasterListActionId) {
                        console.log("country data", data.data)
                        // var internationalCountryData = data.data.length > 0 && data.data.filter((item) => item.Code != "91")
                        this.setState({
                            hallMasterList: data.data
                        })

                    }
                }
                this.setState({ isLoading: false })
            })
            .catch((e) => {
                console.log(e);
            });
    }

    onExhibitionChange(value) {
        const { exhibitionID } = this.state
        this.setState({ exhibitionID: value })
        this.getFillList(hallMasterListActionId, value, '', '', '', '');
    }

    getStallMasterDetails() {
        this.setState({ isLoading: true })
        const { stallID } = this.state
        this.props.getHallMasterDetails_action(stallID)
            .then((data) => {
                if (data.error != 1) {
                    console.log(data.data[0][0])
                    this.setState({ hallName: data.data[0][0].HallName })
                    this.setState({ exhibitionID: data.data[0][0].ExhibitionID })
                    this.setState({ hallID: data.data[0][0].HallId })
                    this.setState({ stallName: data.data[0][0].StallName })
                    this.getFillList(hallMasterListActionId, data.data[0][0].ExhibitionID, '', '', '', '');
                    this.setState({ isLoading: false })

                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    onPreviousClick() {
        this.props.navigate('/stallMasterList',)
    }

    insertStallMaster() {
        const { isLoading, exhibitionID, hallName, stallID, stallName, hallID, is_update, stallList } = this.state
        var finalList = [...stallList]
        if (finalList.length == 0) {
            if (stallName != '' && stallName != '') {
                finalList.push({ stallName: stallName })
            }
        }
        if (exhibitionID != '' && exhibitionID != null && finalList.length > 0 && hallID != '' && hallID != '') {
            this.setState({ isLoading: true })
            this.props.insertUpdateHallMaster_action(exhibitionID, finalList, hallID, null)
                .then((data) => {
                    if (data.error != 1) {
                        console.log(data.data)
                        this.props.navigate('/stallMasterList',)
                        toastSuccess("added successfully")
                        this.setState({ isLoading: false })
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
            console.log(finalList)
        }
        else {
            toastError("Please enter mandatory fields")
        }
    }

    updateStallMaster() {
        const { isLoading, exhibitionID, hallName, stallList, stallID, stallName, hallID, is_update } = this.state
        var finalList = [...stallList]
        if (finalList.length == 0) {
            if (stallName != '' && stallName != '') {
                finalList.push({ stallName: stallName })
            }
        }
        if (exhibitionID != '' && exhibitionID != null && finalList.length > 0 && hallID != '' && hallID != '') {
            this.setState({ isLoading: true })
            this.props.insertUpdateHallMaster_action(exhibitionID, finalList, hallID, stallID)
                .then((data) => {
                    if (data.error != 1) {
                        console.log(data.data)
                        this.props.navigate('/stallMasterList',)
                        toastSuccess("update successful")
                        this.setState({ isLoading: false })
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        else {
            toastError("Please enter mandatory fields")
        }
    }

    onNextClick() {
        const { is_update } = this.state;
        if (is_update == true) {
            this.updateStallMaster();
        } else {
            this.insertStallMaster();
        }
    }

    handleFileUpload = (e) => {
        const file = e.target.files[0];
        console.log(e.target)
        const reader = new FileReader();
        console.log(reader)
        reader.onload = (event) => {
            const binaryString = event.target.result;
            const workbook = XLSX.read(binaryString, { type: 'binary' });
            const sheetName = workbook.SheetNames[0]; // Assuming we are dealing with the first sheet
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            // console.log(binaryString, '222', workbook, '3333', sheetName, '444', sheet, '555', jsonData)
            var finalStallList = []
            for (var i = 0; i < jsonData.length; i++) {
                for (var j = 0; j < jsonData[i].length; j++) {
                    var obj = {}
                    if (j == 0) {
                        obj.stallName = jsonData[i][j]
                        finalStallList.push(obj)
                        // console.log(jsonData[i][0], jsonData[i][j][0], i, j, finalStallList)
                    }
                }
            }
            this.setState({ stallList: finalStallList })
            console.log(finalStallList)
        };
        reader.readAsBinaryString(file);
    };

    onCrossClick() {
        this.setState({ stallList: [] })
        document.getElementById("path").value = ""
    }

    render() {
        const { isLoading, exhibitionID, currentPage, stallID, hallID, stallName, is_add, hallMasterList, exhibitionList, hallName } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <div className="app-content with-dr-container com-height">

                        <AdminDrawer />
                        <div className="row">
                            <div className="step-name">
                                <p>Stall {is_add ? 'Add' : 'Update'}</p>
                            </div>
                        </div>
                        {/* <div className="app-content with-dr-container com-height"> */}
                        <div className="m-3">
                            <div className="row ">
                                {
                                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 1 &&
                                    <div className="row ">
                                        <div className="col-md-4">
                                            <label>Exhibition <span className="text-red">*</span></label>
                                            <select className="form-control"
                                                value={exhibitionID}
                                                onChange={(e) => this.onExhibitionChange(e.target.value)}  >
                                                <option value={''}>Select</option>
                                                {
                                                    exhibitionList.length > 0 && exhibitionList.map(data => (
                                                        <option value={data.ExhibitionID} key={data.ExhibitionID}>{data.ExhibitionName}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="col-md-4">
                                            <label>Hall Name <span className="text-red">*</span></label>
                                            <select className="form-control"
                                                value={hallID}
                                                onChange={(e) => this.setState({ hallID: e.target.value })}  >
                                                <option value={''}>Select</option>
                                                {
                                                    hallMasterList.length > 0 && hallMasterList.map(data => (
                                                        <option value={data.HallId} key={data.HallId}>{data.HallName}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="col-md-4">
                                            <label>Stall Name <span className="text-red">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={stallName}
                                                onChange={(e) => this.setState({ stallName: e.target.value, })}
                                            />
                                            {/* <hr /> */}
                                            {this.state.is_add &&
                                                <>
                                                    <p style={{ textAlign: 'center', padding: '5px', }}><strong>---------------------- OR ----------------------</strong></p>
                                                    {/* <hr /> */}

                                                    <label>
                                                        <input type="file"
                                                            id="path"
                                                            //  className="image-upload"
                                                            accept=".xlsx"
                                                            onChange={this.handleFileUpload}
                                                        />
                                                        {
                                                            this.state.stallList.length > 0 &&
                                                            <i class="fa fa-times text-danger img-can"
                                                                onClick={() => this.onCrossClick()}
                                                            ></i>
                                                        }
                                                    </label>
                                                </>}
                                        </div>
                                        {/* <div class="">
                                    <label for="validationCustom03">Total Exhibition Area<span style={{ color: 'red' }}>*</span></label>
                                    <input value={totalExhibitionArea} onChange={(e) => this.setState({ totalExhibitionArea: parseInt(e.target.value) })} type="number" class="form-control" placeholder="" />
                                </div> */}
                                    </div>
                                }
                            </div>
                            < div className="row mt-2" >
                                <div className="col-md-12">
                                    <div className="main-container container-fluid p-2 pt-0 pb-0">
                                        <div className="mt-1 mb-4">
                                            {
                                                sessionStorage.getItem("sessionExhRegistrationID") == null &&
                                                <button className="btn btn-previous" onClick={e => this.onPreviousClick()}>
                                                    {" "}
                                                    Back{" "}
                                                </button>
                                            }

                                            <button className="btn btn-next-bot mx-2" onClick={e => this.onNextClick()} >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div></div>
                    </div>
                </>
                <Footer />
            </>)
    }

}
export default withRouter(connect(null, { fill_action, insertUpdateHallMaster_action, getHallMasterDetails_action })(StallMasterAU));