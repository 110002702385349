import { Component } from "react";
import { ToastContainer } from "react-toastify";
import Loader from "../../../utils/Loader";
import ExhibitorHeader from "../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../common_components/exhibitor_module_drawer";
import ExhibitorTopMenu from "../../../common_components/exhibitor_module_topmenu";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import { getProformaList_action, insertPayment_action, getPaymentDetails_action, updatePayment_action } from "../../../actions/exhibitor_module_actions/accounts_action/update_payment_action"
import { data } from "jquery";
import { paymentModeActionId, toastError, toastSuccess } from "../../../utils/constants";
import { fill_action } from '../../../actions/fillCombo_action';
import DatePicker from "react-datepicker";
import moment from "moment";

class UpdatePaymentAdd extends Component {

    // Initialize Constructor
    constructor(props) {
        super(props)
        //States
        this.state = {
            isLoading: false,
            isAdd: false,
            isUpdate: false,
            isView: false,
            AccountPaymentID: "",

            // input Fields 
            currencyCode: "",
            grossAmt: "",
            tdsAmt: "",
            netAmt: "",
            instrumentTypeId: "",
            instrumentDate: "",
            instrumentNo: "",
            bank: "",
            branch: "",

            // Lists
            proformaInvList: [],
            instrumentTypeList: [],

            // Error Messages
            grossAmtErr: "",
            tdsAmtErr: "",
            netAmtErr: "",
            instrumentTypeIdErr: ""

        }
        this.getProformaList = this.getProformaList.bind(this)
        this.onCheckedInv = this.onCheckedInv.bind(this)
        this.onRecvdAmtChange = this.onRecvdAmtChange.bind(this)
        this.preventNegative = this.preventNegative.bind(this)
        this.calculateTotals = this.calculateTotals.bind(this)
        this.handleGrossAmt = this.handleGrossAmt.bind(this)
        this.handleTdsAmt = this.handleTdsAmt.bind(this)
        this.handleNetAmt = this.handleNetAmt.bind(this)
        this.getFillList = this.getFillList.bind(this)
        this.inputValidate = this.inputValidate.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.getPaymentDetails = this.getPaymentDetails.bind(this)
        this.onUpdate = this.onUpdate.bind(this)
        this.onCancel = this.onCancel.bind(this)
    }

    // On Component Load
    componentDidMount() {
        /// Proforma List 
        this.getProformaList()

        // Currency Code
        var code = JSON.parse(sessionStorage.getItem('ApplicationDataSS')) != null && JSON.parse(sessionStorage.getItem('ApplicationDataSS')).Code
        this.setState({ currencyCode: code })

        // Get Fill List For Instrument Type
        this.getFillList(paymentModeActionId, '', '', '', '', '');

        this.setState({
            isAdd: this.props.location.state != null && this.props.location.state.is_add ? this.props.location.state.is_add : false,
            // isUpdate: this.props.location.state != null && this.props.location.state.is_update ? this.props.location.state.is_update : false,
            AccountPaymentID: this.props.location.state != null && this.props.location.state.AccountPaymentID ? this.props.location.state.AccountPaymentID : '',
        })

        this.props.location.state != null && this.props.location.state.is_update && this.getPaymentDetails()
    }

    // get fill List dropdown  
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, exhRegistrationID) {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value, exhRegistrationID)
            .then((data) => {
                if (data.error != 1) {

                    // Instrument Type list
                    if (actionID === paymentModeActionId) {
                        console.log("Instrument Type data", data.data)
                        this.setState({
                            instrumentTypeList: data.data, isLoading: false,
                        })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    // Input Feilds Validation
    inputValidate() {
        const { grossAmt, tdsAmt, netAmt, instrumentTypeId } = this.state

        if (grossAmt == "") {
            this.setState({ grossAmtErr: 'Please Enter Gross Amount' })
        }
        if (tdsAmt == "") {
            this.setState({ tdsAmtErr: 'Please Enter TDS Amount' })
        }
        if (netAmt == "") {
            this.setState({ netAmtErr: 'Please Enter Net Amount' })
        }
        if (instrumentTypeId == "" || instrumentTypeId == "Select") {
            this.setState({ instrumentTypeIdErr: 'Please Select Instrument Type' })
        }

    }

    // Get Proforma List 
    getProformaList() {
        this.setState({ isLoading: true })
        this.props.getProformaList_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log("Proforma List----->", data.data)
                    data.data.map((item) => {
                        item.isSelected = false;
                        item.isReadOnly = true;
                        item.recvdAmt = ""
                    })
                    this.setState({ isLoading: false, proformaInvList: data.data })
                    setTimeout(() => {
                        this.calculateTotals()
                    }, 100);
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // On Check Invoice
    onCheckedInv(e) {
        const { proformaInvList } = this.state
        var checked = e.target.checked
        var id = e.target.value

        proformaInvList.length > 0 && proformaInvList.map((data) => {
            if (checked) {
                if (id == data.ProformaInvoiceID) {
                    data.isSelected = true;
                    data.isReadOnly = false

                }
            }
            else {
                data.isSelected = false;
                data.isReadOnly = true
            }
        })
        console.log("09000909090", proformaInvList)
        this.setState({})
    }

    // On Received A'mount Change
    onRecvdAmtChange(e, id) {
        const { proformaInvList } = this.state
        var value = e.target.value
        proformaInvList.length > 0 && proformaInvList.map((data) => {
            if (value >= 0) {
                if (data.ProformaInvoiceID == id) {
                    if (value <= data.Pending) {
                        data.recvdAmt = value
                        data.recvdAmtErr = ""
                    } else {
                        data.recvdAmt = value
                        data.recvdAmtErr = "Received amount must be less than or equal to pending amount"

                    }
                    this.calculateTotals()
                }
            }
        })
        this.setState({ proformaInvList: proformaInvList })
    }

    //prevent Negative values in number inputs
    preventNegative(e) {
        (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()
        if (e.code === 'Minus') {
            e.preventDefault();
        }
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
            const currentVal = parseFloat(e.target.value);
            const increment = e.key === "ArrowUp" ? 1 : -1;
            const newVal = currentVal + increment;
            if (newVal < 0) {
                e.preventDefault()
            }
        }
    }

    //For Calculation All total Amounts in table 
    calculateTotals() {
        try {
            const { proformaInvList } = this.state
            let totalAmt = 0;
            let totalCurrPend = 0;
            let totalReceivedAmt = 0;

            proformaInvList.forEach((data) => {

                totalAmt += Number(data.Total_Payable);
                totalCurrPend += Number(data.Pending);
                totalReceivedAmt += Number(data.recvdAmt)

            });
            return {
                totalAmt,
                totalCurrPend,
                totalReceivedAmt
            };

        } catch (e) {
            console.log('exception : ', e);
        }

    }

    // on Gross Amt
    handleGrossAmt(e) {
        this.setState({ grossAmt: parseFloat(e.target.value).toFixed(2), grossAmtErr: "" })
        this.handleNetAmt()
    }

    // On Tds Amount
    handleTdsAmt(e) {
        this.setState({ tdsAmt: parseFloat(e.target.value).toFixed(2), tdsAmtErr: "" })
        this.handleNetAmt()
    }

    // Handle Total Net Amount
    handleNetAmt() {
        const { grossAmt, tdsAmt } = this.state
        var grossAmount = grossAmt != "" ? grossAmt : "0"
        var tdsAmount = tdsAmt != "" ? tdsAmt : "0"
        if (grossAmount != "" && tdsAmount != "") {
            const totalCal = parseFloat(grossAmount) + parseFloat(tdsAmount)
            this.setState({ netAmt: totalCal.toFixed(2), netAmtErr: "" })
        } else {
            this.setState({ netAmt: "" })

        }
    }

    // Submit Payment
    onSubmit(e) {
        e.preventDefault()
        const { grossAmt, tdsAmt, netAmt, instrumentTypeId, instrumentNo, instrumentDate, bank, branch, proformaInvList, grossAmtErr,
            tdsAmtErr, netAmtErr, instrumentTypeIdErr } = this.state
        const { totalReceivedAmt } = this.calculateTotals()
        var isTotalsMatch = false
        if (totalReceivedAmt == netAmt) {
            isTotalsMatch = true
        } else {
            isTotalsMatch = false
        }

        var isAnySelected = proformaInvList.some(item => item.isSelected)
        if (isAnySelected) {
            if (netAmt > 0) {
                if (isTotalsMatch) {
                    this.inputValidate()
                    if (grossAmt != "" && tdsAmt != "" && netAmt != "" && instrumentTypeId != "") {
                        if (grossAmtErr == "" && tdsAmtErr == "" && netAmtErr == "" && instrumentTypeIdErr == "") {
                            // proforma List data Preparing to store
                            var list = []
                            var selectedList = proformaInvList.length > 0 && proformaInvList.filter(obj => obj.isSelected)
                            selectedList.length > 0 && selectedList.map(data => {
                                var mapData = {
                                    'ProformaID': parseInt(data.ProformaInvoiceID),
                                    "ReceivedAmount": !isNaN(data.recvdAmt) && data.recvdAmt != "" &&
                                        data.recvdAmt != null ? parseFloat(data.recvdAmt).toFixed(2) : 0
                                }
                                list.push(mapData)
                            });
                            this.setState({ isLoading: true })
                            this.props.insertPayment_action(grossAmt, tdsAmt, netAmt, instrumentTypeId, instrumentNo,
                                instrumentDate,
                                bank, branch, list)
                                .then((data) => {
                                    if (data.error != 1) {
                                        this.setState({ isLoading: false })
                                        toastSuccess("Payement Added Succesfully")
                                        setTimeout(() => {
                                            this.props.navigate('/updatePayment')
                                        }, 2000);
                                    } else {
                                        this.setState({ isLoading: false })
                                    }
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    }
                }
                else {
                    toastError("Total Net Amount is not Match")
                }
            } else {
                toastError("Amount Should not be 0")
            }

        }
        else {
            toastError("Select Atleast 1 Utility")
        }
    }

    // Update Payment
    onUpdate(e) {
        e.preventDefault()
        const { grossAmt, tdsAmt, netAmt, instrumentTypeId, instrumentNo, instrumentDate, bank, branch, proformaInvList, grossAmtErr,
            tdsAmtErr, netAmtErr, instrumentTypeIdErr, AccountPaymentID } = this.state
        const { totalReceivedAmt } = this.calculateTotals()
        var isTotalsMatch = false
        if (totalReceivedAmt == netAmt) {
            isTotalsMatch = true
        } else {
            isTotalsMatch = false
        }

        var isAnySelected = proformaInvList.some(item => item.isSelected)
        if (isAnySelected) {
            if (netAmt > 0) {
                if (isTotalsMatch) {
                    this.inputValidate()
                    if (grossAmt != "" && tdsAmt != "" && netAmt != "" && instrumentTypeId != "") {
                        if (grossAmtErr == "" && tdsAmtErr == "" && netAmtErr == "" && instrumentTypeIdErr == "") {
                            // proforma List data Preparing to store
                            var list = []
                            var selectedList = proformaInvList.length > 0 && proformaInvList.filter(obj => obj.isSelected)
                            selectedList.length > 0 && selectedList.map(data => {
                                var mapData = {
                                    'ProformaID': parseInt(data.ProformaInvoiceID),
                                    "ReceivedAmount": !isNaN(data.recvdAmt) && data.recvdAmt != "" &&
                                        data.recvdAmt != null ? parseFloat(data.recvdAmt).toFixed(2) : 0
                                }
                                list.push(mapData)
                            });
                            this.setState({ isLoading: true })
                            this.props.updatePayment_action(AccountPaymentID, grossAmt, tdsAmt, netAmt, instrumentTypeId, instrumentNo,
                                instrumentDate,
                                bank, branch, list)
                                .then((data) => {
                                    if (data.error != 1) {
                                        this.setState({ isLoading: false })
                                        toastSuccess("Payement Updated Succesfully")
                                        setTimeout(() => {
                                            this.props.navigate('/updatePayment')
                                        }, 2000);
                                    } else {
                                        this.setState({ isLoading: false })
                                    }
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    }
                }
                else {
                    toastError("Total Net Amount is not Match")
                }
            } else {
                toastError("Amount Should not be 0")
            }

        }
        else {
            toastError("Select Atleast 1 Utility")
        }
    }

    // Get Payment Details
    getPaymentDetails() {
        var Id = this.props.location.state.AccountPaymentID
        console.log("hshsjskksskskskks", Id)
        setTimeout(() => {
            this.setState({ isLoading: true })
            this.props.getPaymentDetails_action(Id)
                .then((data) => {
                    if (data.error != 1) {
                        const { proformaInvList } = this.state
                        console.log("Get Payment Details--------->", data.data)
                        // Preparing proforma in Data to display
                        var PI = data.data.proformaList.length > 0 ? data.data.proformaList : []
                        proformaInvList.length > 0 && proformaInvList.map((item) => {
                            PI.length > 0 && PI.map((element) => {
                                if (item.ProformaInvoiceID == element.ProformaInvoiceID) {
                                    item.isSelected = true
                                    item.isReadOnly = false
                                    item.recvdAmt = element.ReceivedAmount;
                                }
                            })
                        })

                        this.setState({
                            isLoading: false,
                            grossAmt: data.data.description[0]['GrossAmount'] != null ? data.data.description[0]['GrossAmount'] : '',
                            tdsAmt: data.data.description[0]['TDSAmount'] != null ? data.data.description[0]['TDSAmount'].toString() : '',
                            netAmt: data.data.description[0]['NetAmount'] != null ? data.data.description[0]['NetAmount'] : '',
                            instrumentTypeId: data.data.description[0]['InstrumentTypeID'] != null ? data.data.description[0]['InstrumentTypeID'] : '',
                            instrumentDate: data.data.description[0]['InstrumentDate'] != null ? new Date(data.data.description[0]['InstrumentDate']) : '',
                            instrumentNo: data.data.description[0]['InstrumentNo'] != null ? data.data.description[0]['InstrumentNo'] : '',
                            bank: data.data.description[0]['Bank'] != null ? data.data.description[0]['Bank'] : '',
                            branch: data.data.description[0]['Branch'] != null ? data.data.description[0]['Branch'] : '',
                            isUpdate: data.data.description[0]['Editable'] != null ? data.data.description[0]['Editable'] : false,
                            isView: data.data.description[0]['Editable'] != null ? !(data.data.description[0]['Editable']) : false,
                            proformaInvList: data.data.proformaList.length > 0 && proformaInvList
                        })
                    } else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }, 300);

    }

    // On Cancel
    onCancel(e) {
        e.preventDefault()
        this.props.navigate('/updatePayment')
    }

    maxLengthCheck = (object, maxLength) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }


    render() {
        const { isLoading, isAdd, proformaInvList, grossAmt, tdsAmt, netAmt, instrumentTypeList, instrumentTypeId, instrumentDate,
            instrumentNo, bank, branch, grossAmtErr, tdsAmtErr, netAmtErr, instrumentTypeIdErr, currencyCode, isUpdate, isView } = this.state
        const { totalAmt, totalCurrPend, totalReceivedAmt } = this.calculateTotals()

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <ExhibitorHeader />
                <ExhibitorDrawer />
                {/*app-content open*/}
                <div className="page">
                    <div className="page-main">
                        <div className="main-content  with-dr-container">

                            <div className="row">
                                {/* <div className="step-name">
                                <p>Exhibitor Dashboard</p>
                            </div> */}
                                <ExhibitorTopMenu />
                            </div>

                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card rounded-0 pt-1">
                                        <div className="card-header mt-0 p-3 mb-2" >
                                            <h3 className="card-title" style={{ fontSize: 15 }}>
                                                Select And Update Payment Details
                                            </h3>
                                        </div>
                                        <div className="card-body p-2">
                                            <div className="table-responsive ">
                                                <table className="table border text-nowrap text-md-nowrap table-bordered mb-3">
                                                    <thead>
                                                        <tr>
                                                            <th>Select</th>
                                                            <th>Utility Name</th>
                                                            <th>Amount ({currencyCode})</th>
                                                            <th>Total Utility Amount({currencyCode})</th>
                                                            <th>Total Pending Amount({currencyCode})</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            proformaInvList.length > 0 && proformaInvList.map((data) => (
                                                                <tr key={data.ProformaInvoiceID}>
                                                                    <td className="text-center">
                                                                        <input type="checkbox" className="cus-check"
                                                                            disabled={isView}
                                                                            checked={data.isSelected} value={data.ProformaInvoiceID}
                                                                            onChange={(e) => this.onCheckedInv(e)}
                                                                        />
                                                                    </td>
                                                                    <td>{data.ServiceName}</td>
                                                                    <td className="p-1">
                                                                        <input
                                                                            className="form-control"
                                                                            placeholder={0}
                                                                            type={"number"}
                                                                            maxLength={'16'}
                                                                            onInput={(e) => this.maxLengthCheck(e, 16)}
                                                                            disabled={data.isReadOnly || isView}
                                                                            value={data.recvdAmt}
                                                                            style={{ height: 30 }}
                                                                            onKeyDown={(e) => this.preventNegative(e)}
                                                                            onKeyUp={(e) => this.setState({
                                                                                grossAmt: parseFloat(totalReceivedAmt).toFixed(2)
                                                                                , netAmt: parseFloat(totalReceivedAmt).toFixed(2),
                                                                                tdsAmt: parseFloat(0).toFixed(2)
                                                                            })}
                                                                            onChange={(e) => this.onRecvdAmtChange(e, data.ProformaInvoiceID)}
                                                                        />
                                                                        {<span className='text-danger text-start text-left d-block mt-1'>{data.recvdAmtErr}</span>}
                                                                    </td>
                                                                    <td className="text-r">{data.Total_Payable}</td>
                                                                    <td className="text-r">{data.Pending}</td>
                                                                </tr>
                                                            ))
                                                        }

                                                        <tr>
                                                            <td colSpan={2} className="text-center">
                                                                <strong>TOTAL</strong>
                                                            </td>
                                                            <td>
                                                                <strong>{totalReceivedAmt}</strong>
                                                            </td>
                                                            <td className="text-r">
                                                                <strong>{totalAmt}</strong>
                                                            </td>
                                                            <td className="text-r">
                                                                <strong>{totalCurrPend}</strong>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <h6 className="mt-4">
                                                <strong>Currency ({currencyCode})</strong>
                                            </h6>
                                            <div className="row">
                                                <div className="col-xl-4">
                                                    <label htmlFor="validationCustom03">Gross Amount
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        id="validationCustom03"
                                                        value={grossAmt}
                                                        disabled={isView}
                                                        onChange={(e) => e.target.value >= 0 && this.setState({ grossAmt: e.target.value, grossAmtErr: "" })}
                                                        onKeyDown={(e) => this.preventNegative(e)}
                                                        onBlur={(e) => this.handleGrossAmt(e)}
                                                    />
                                                    {<span className='text-danger text-start text-left d-block mt-1'>{grossAmtErr}</span>}
                                                </div>
                                                <div className="col-xl-4">
                                                    <label htmlFor="validationCustom03">TDS Amount
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        id="validationCustom03"
                                                        value={tdsAmt}
                                                        disabled={isView}
                                                        onChange={(e) => e.target.value >= 0 && this.setState({ tdsAmt: e.target.value, tdsAmtErr: "" })}
                                                        onKeyDown={(e) => this.preventNegative(e)}
                                                        onBlur={(e) => this.handleTdsAmt(e)}
                                                    />
                                                    {<span className='text-danger text-start text-left d-block mt-1'>{tdsAmtErr}</span>}
                                                </div>
                                                <div className="col-xl-4">
                                                    <label htmlFor="validationCustom03">Net Amount
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        id="validationCustom03"
                                                        value={netAmt}
                                                        // disabled={isView}
                                                        disabled={true}
                                                        onKeyDown={(e) => this.preventNegative(e)}
                                                        onChange={(e) => e.target.value >= 0 && this.setState({ netAmt: e.target.value, netAmtErr: "" })}
                                                        onBlur={(e) => this.setState({ netAmt: parseFloat(e.target.value).toFixed(2), netAmtErr: "" })}
                                                    />
                                                    {<span className='text-danger text-start text-left d-block mt-1'>{netAmtErr}</span>}
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-xl-4">
                                                    <label htmlFor="validationCustom03">Instrument Type
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <select className="form-control" value={instrumentTypeId}
                                                        disabled={isView}
                                                        onChange={(e) => this.setState({ instrumentTypeId: e.target.value, instrumentTypeIdErr: "" })}
                                                    >
                                                        <option>Select</option>
                                                        {
                                                            instrumentTypeList.length > 0 && instrumentTypeList.map((data) => (
                                                                <option key={data.PaymentModeID} value={data.PaymentModeID} >{data.Name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    {<span className='text-danger text-start text-left d-block mt-1'>{instrumentTypeIdErr}</span>}
                                                </div>
                                                <div className="col-xl-4">
                                                    <label htmlFor="validationCustom03">Instrument Date</label>
                                                    <DatePicker selected={instrumentDate}
                                                        onChange={(date) => this.setState({ instrumentDate: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())) })}
                                                        dateFormat={"dd/MM/yyyy"} className="form-control"
                                                        peekNextMonth
                                                        disabled={isView}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        placeholderText="Instrument Date"
                                                        // minDate={new Date()}
                                                        dayClassName={() => "example-datepicker-day-class"}
                                                        popperClassName="example-datepicker-class" />
                                                </div>
                                                <div className="col-xl-4">
                                                    <label htmlFor="validationCustom03">Instrument Number</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom03"
                                                        value={instrumentNo}
                                                        disabled={isView}
                                                        onChange={(e) => this.setState({ instrumentNo: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-xl-4">
                                                    <label htmlFor="validationCustom03">Bank</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom03"
                                                        value={bank}
                                                        disabled={isView}
                                                        onChange={(e) => this.setState({ bank: e.target.value })}
                                                    />
                                                </div>
                                                <div className="col-xl-4">
                                                    <label htmlFor="validationCustom03">Branch</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom03"
                                                        value={branch}
                                                        disabled={isView}
                                                        onChange={(e) => this.setState({ branch: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                <div className="btn-group" role="group" aria-label="Basic example"
                                                    style={{ width: "100px" }}>
                                                    {
                                                        isAdd &&
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary rounded-0"
                                                            style={{ marginRight: 10 }}
                                                            onClick={(e) => this.onSubmit(e)}
                                                        >
                                                            Submit
                                                        </button>
                                                    }
                                                    {
                                                        isUpdate && !(isView) &&
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary rounded-0"
                                                            style={{ marginRight: 10 }}
                                                            onClick={(e) => this.onUpdate(e)}
                                                        >
                                                            Update
                                                        </button>
                                                    }
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary rounded-0"
                                                        style={{ marginRight: 10 }}
                                                        onClick={(e) => this.onCancel(e)}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </>
        )
    }
}

export default withRouter(connect(null, {
    getProformaList_action, fill_action, insertPayment_action, getPaymentDetails_action,
    updatePayment_action
})(UpdatePaymentAdd));