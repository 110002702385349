import { axiosGet, axiosPost } from "../../../react_axios/Axios";
import { insertUpdatePurposeofVisitMasterListUrl, INSERT_UPDATE_PURPOSE_OF_VISIT_MASTER, headers } from "../../../utils/constants";

// export const deleteManualformsMaster_action = (ExihibitionID, ManualFormMappingID) => async (dispatch) => {
//     try {
//         const params = {
//             UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
//             ExhibitionID: ExihibitionID,
//             ManualFormMappingID: ManualFormMappingID

//         }
//         console.log("getVisaInvitation_action Action------->", params)

//         const res = await axiosGet(deleteManualFormsMasterUrl, params, headers)

//         dispatch({
//             type: DELETE_MANUAL_FORMS_MASTER,
//             payload: res,
//         });
//         return Promise.resolve(res)
//     } catch (error) {
//         return Promise.reject(error)
//     }
// }

export const insertUpdatePurposeofVisitMaster_action = (PurposeofVisitList) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            // ExhibitionID: ExihibitionID,
            PurposeofVisitList: PurposeofVisitList
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosPost(insertUpdatePurposeofVisitMasterListUrl, params, headers)

        dispatch({
            type: INSERT_UPDATE_PURPOSE_OF_VISIT_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}