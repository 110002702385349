import { ASSIGN_CIRCULAR_ACTION_TYPE, DEL_DELEGATE_CIRCULAR_ACTION_TYPE, GET_DELEGATE_CIRCULAR_COMPANY_ACTION_TYPE, GET_DELEGATE_CIRCULAR_LIST_ACTION_TYPE, INSERT_DELEGATE_CIRCULAR_ACTION_TYPE } from "../../../utils/constants";


const initialState = [];

function delegateCircularReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_DELEGATE_CIRCULAR_COMPANY_ACTION_TYPE:
            return [...state, payload];
        case INSERT_DELEGATE_CIRCULAR_ACTION_TYPE:
            return [...state, payload];
        case GET_DELEGATE_CIRCULAR_LIST_ACTION_TYPE:
            return [...state, payload];
        case DEL_DELEGATE_CIRCULAR_ACTION_TYPE:
            return [...state, payload];
        case ASSIGN_CIRCULAR_ACTION_TYPE:
            return [...state, payload];


        default:
            return state;
    }
};

export default delegateCircularReducer;