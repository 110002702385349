import { GET_AIR_CONNECTION_DETAILS_ACTION_TYPE, INSERT_UPDATE_AIR_CONNECTION_ACTION_TYPE } from "../../../../../utils/constants";


const initialState = [];

function airConnectionReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case GET_AIR_CONNECTION_DETAILS_ACTION_TYPE:
            return [...state, payload];
        case INSERT_UPDATE_AIR_CONNECTION_ACTION_TYPE:
            return [...state, payload];

        default:
            return state;
    }
};

export default airConnectionReducer;