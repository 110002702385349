import { axiosGet, axiosPost } from "../../../../../react_axios/Axios"
import { INSERT_AND_UPDATE_FRIEGHT_FORWARD_ACTION_TYPE, GET_FRIEGHT_FORWARD_DETAILS_ACTION_TYPE, getFrieghtForwardDetailsUrl, insertUpdateFrieghtForwardUrl, headers } from "../../../../../utils/constants"


export const getFrieghtForwardDetails_action = () => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhregistrationID: sessionStorage.getItem('ExhRegistrationIDSS')
        }
        // console.log("getMeetingRoomDetails_action ----->", params)
        const res = await axiosGet(getFrieghtForwardDetailsUrl, params, headers)

        dispatch({
            type: GET_FRIEGHT_FORWARD_DETAILS_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const insertUpdateFrieghtForward_action = (FreightForwardersAgencyContactDetailID, IsOnSiteServiceRequired, IsHireOfEquipment, remark, is_Agree, FreightForwardParticularMappingList, FreightForwardersDetailsMappingList, FreightForwardersOrderEquipmentBuildUpPeriodList, FreightForwardersDismantlingPeriodList, TimetableForMoveInList, sortHallChapterEmailId, hallChapterEmailIdSend) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhregistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
            FreightForwardersAgencyContactDetailID: FreightForwardersAgencyContactDetailID,
            IsOnSiteServiceRequired: IsOnSiteServiceRequired,
            IsHireOfEquipment: IsHireOfEquipment,
            Remark: remark,
            Is_Agree: is_Agree,
            FreightForwardParticularMappingList: FreightForwardParticularMappingList,
            FreightForwardersDetailsMappingList: FreightForwardersDetailsMappingList,
            FreightForwardersOrderEquipmentBuildUpPeriodList: FreightForwardersOrderEquipmentBuildUpPeriodList,
            FreightForwardersDismantlingPeriodList: FreightForwardersDismantlingPeriodList,
            TimetableForMoveInList: IsHireOfEquipment != 0 ? TimetableForMoveInList : null,
            SortHallChapterEmailId: sortHallChapterEmailId,
            HallChapterEmailIdSend: hallChapterEmailIdSend
        }
        // console.log("getMeetingRoomDetails_action ----->", params)
        const res = await axiosPost(insertUpdateFrieghtForwardUrl, params, headers)

        dispatch({
            type: INSERT_AND_UPDATE_FRIEGHT_FORWARD_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}