import { axiosGet, axiosPost } from "../../../react_axios/Axios";
import { headers, INSERT_DELEGATE_INVITATION_TYPE_ACTION_TYPE, UPDATE_DELEGATE_INVITATION_TYPE_ACTION_TYPE, GET_DELEGATE_INVITATION_TYPE_ACTION_TYPE, DELETE_DELEGATE_INVITATION_TYPE_ACTION_TYPE, insertDelegateInvitationTypeUrl, UpdateDelegateInvitationTypeUrl, getDelegateInvitationTypeListUrl, deleteDelegateInvitationTypeUrl } from "../../../utils/constants";


export const insertInvitationType_action = (InvitationTypeName) => async (dispatch) => {
    try {
        const params = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            InvitationTypeName: InvitationTypeName,
        }

        const res = await axiosPost(insertDelegateInvitationTypeUrl, params, headers)

        dispatch({
            type: INSERT_DELEGATE_INVITATION_TYPE_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const updateInvitationType_action = (InvitationTypeID, InvitationTypeName) => async (dispatch) => {
    try {
        const params = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            InvitationTypeID: InvitationTypeID,
            InvitationTypeName: InvitationTypeName,
        }

        const res = await axiosPost(UpdateDelegateInvitationTypeUrl, params, headers)

        dispatch({
            type: UPDATE_DELEGATE_INVITATION_TYPE_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getInvitationType_action = (InvitationTypeName, Status,InvitationTypeID) => async (dispatch) => {
    try {
        const params = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            InvitationTypeName: InvitationTypeName,
            Status: Status,
            InvitationTypeID: InvitationTypeID,
        }

        const res = await axiosGet(getDelegateInvitationTypeListUrl, params, headers)

        dispatch({
            type: GET_DELEGATE_INVITATION_TYPE_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const deleteInvitationType_action = (InvitationTypeID) => async (dispatch) => {
    try {
        const params = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            InvitationTypeID: InvitationTypeID
        }

        const res = await axiosGet(deleteDelegateInvitationTypeUrl, params, headers)

        dispatch({
            type: DELETE_DELEGATE_INVITATION_TYPE_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}