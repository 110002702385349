import { connect } from "react-redux";
import { withRouter } from "../../../utils/withRouter";
import ExhibitorTopMenu from "../../../common_components/exhibitor_module_topmenu";
import { ToastContainer } from "react-toastify";
import ExhibitorHeader from "../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../common_components/exhibitor_module_drawer";
import Loader from "../../../utils/Loader";
import { getExhibitorSeminarDetails_action, insertUpdateExhibitorSeminar_action } from "../../../actions/exhibitor_module_actions/exhibitorSeminar_action/exhibitorSeminar_action";
import { toastError, seminarInterestActionId, toastSuccess } from "../../../utils/constants";
import { data } from "jquery";
import moment from "moment";
import { fill_action } from "../../../actions/fillCombo_action";
const { Component } = require("react");

class ExhibitorSeminar extends Component {

    // Intialize Constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            SeminarList: []
        }
    }

    // On componet Load
    componentDidMount() {
        this.getFillList(seminarInterestActionId, '', '', '1', '');
        setTimeout(() => {
            this.getExhibitorSeminarDetails()
        }, 1000);
    }

    // Get Fill Lists
    getFillList(actionID, exhibitionID, moduleID, keyValue, value) {
        const { isAdd } = this.state
        this.setState({ isLoading: true })
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value)
            .then((data) => {
                if (data.error != 1) {
                    // country list
                    if (actionID === seminarInterestActionId) {
                        const seminars = [...data.data].sort((a, b) => a.SeqNo - b.SeqNo);
                        this.setState({
                            SeminarList: seminars,
                            isLoading: false
                        })

                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    getExhibitorSeminarDetails() {
        const { SeminarList } = this.state
        this.setState({ isLoading: true })
        this.props.getExhibitorSeminarDetails_action()
            .then((data) => {
                if (data.error != 1) {
                    if (data.data != '') {
                        const result = SeminarList.map(item => ({
                            ...item,
                            IsSelect: data.data.some(item2 => item2.SeminarID === item.SeminarID) ? 1 : 0,

                        }))
                        console.log("result----->", result)
                        this.setState({ SeminarList: result });

                    }
                    this.setState({ isLoading: false })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    insertUpdateExhibitorSeminar() {
        const { SeminarList } = this.state
        const array = []
        SeminarList.map(data => {
            if (data.IsSelect) {
                array.push({ SeminarID: data.SeminarID })
            }
        })
        this.setState({ isLoading: true })
        this.props.insertUpdateExhibitorSeminar_action(array)
            .then((data) => {
                if (data.error != 1) {
                    toastSuccess("Data Updated Successfully!")
                    this.getExhibitorSeminarDetails()
                    this.setState({ isLoading: false })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    onCheked(event, index) {
        const { SeminarList } = this.state
        SeminarList.forEach((data, i) => {
            if (i === index) {
                data.IsSelect = event
            }
        })
        this.setState({ SeminarList: SeminarList })
    }

    render() {
        const { isLoading, SeminarList } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <ExhibitorHeader />
                <ExhibitorDrawer />
                <div className="main-content with-dr-container">
                    <div className="">
                        <div className="">
                            <ExhibitorTopMenu />
                        </div>
                        <div className="row">
                            <div className="col-xl-12 p-0">
                                <div className="card rounded-0 pt-1">
                                    <div className="card-header p-3">
                                        <h3 className="card-title">Exhibitor Seminar</h3>
                                    </div>
                                    <div className="card-body p-4">
                                        <div className="table-responsive mb-2">
                                            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 ">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center">Day</th>
                                                        <th style={{ width: '180px' }}>Date & Time</th>
                                                        <th style={{ width: '680px' }}>Description</th>
                                                        <th style={{ width: '380px' }}>Speaker</th>
                                                        <th className="text-center">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        SeminarList.length > 0 ? SeminarList.map((data, index) => (
                                                            <>
                                                                {data.ParentSeminarID == null ?
                                                                    <tr>

                                                                        <td><strong>{data.Day}</strong></td>
                                                                        <td><strong>{data.DateTime}</strong></td>
                                                                        <td><strong>{data.Description}</strong></td>
                                                                        <td><strong>{data.Speaker}</strong></td>
                                                                        <td ></td>
                                                                    </tr> :
                                                                    <tr>
                                                                        <td>{data.Day}</td>
                                                                        <td>{data.DateTime}</td>
                                                                        <td>{data.Description}</td>
                                                                        <td>{data.Speaker}</td>
                                                                        <td>
                                                                            <input type="checkbox" checked={data.IsSelect}
                                                                                onChange={(e) => this.onCheked(e.target.checked, index)}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                }
                                                            </>
                                                        )) : <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <button
                                            type="button"
                                            className="btn btn-primary rounded-0 mt2"
                                            style={{ marginRight: 10, float: 'right', marginTop: '10px' }}
                                            onClick={() => this.insertUpdateExhibitorSeminar()}
                                            disabled={SeminarList.length == 0}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}

export default withRouter(connect(null, { fill_action, getExhibitorSeminarDetails_action, insertUpdateExhibitorSeminar_action })(ExhibitorSeminar))