import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action, fill_master_action } from '../../../../../actions/fillCombo_action';
import { cityActionId, countryActionId, toastError, stalltypeMasterListActionId, attendeeTypeActionId, industryChaptersActionId, visitPurposeActionId, visitorTypeListActionId, dashboardDownloadListId, countryMasterListActionId, toastSuccess } from "../../../../../utils/constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import Exh_chapter from '../exh_chapter/exh_chapter';
import { TimePicker } from '@hilla/react-components/TimePicker.js';
import Resizer from "react-image-file-resizer";
// import ManageArea from './manage_area/manage_area';
import Exh_timeline from '../exh_timeline/exh_timeline';
import ManageAreaMaster from '../manage_area_master/manage_area_master';
import ManualFormsMaster from "../manual_froms_master/manual_forms_master";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import { useNavigate } from "react-router-dom";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import { data } from "jquery";
import {
    addExhibitionMaster_action, getExhibitionMasterDetails_action, updateExhibitionMaster_action, deleteExhibitionDocument_action
} from "../../../../../actions/admin_module_action/master_action/exhibition_master_action/exhibition_master_action";
import {
    getExhibitionConfigrationDetails_action, insertUpdateVisitorConfigration_action
} from "../../../../../actions/admin_module_action/master_action/exhibition_master_action/visitor_configuration_action";
import Exh_promoCode from "../exh_promoCode/exh_promoCode";

class VisitorConfigration extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            ExihibitionID: '',
            visitorTypeList: [],
            attendeeTypeList: [],
            purposeOfVisitList: [],
            visitDaysList: [],
            industrySegmentList: [],
            eBadgeDownloadDate: '',
            eBadgeDownloadDateErr: '',
            eventTimingList: [],
        }
        this.getMasterFillList = this.getMasterFillList.bind(this)
        this.onSelectPurpose = this.onSelectPurpose.bind(this)
        this.getVisitorConfigurationDetails = this.getVisitorConfigurationDetails.bind(this)
        this.onSelectVisitType = this.onSelectVisitType.bind(this)
        this.onSelecAttendeeType = this.onSelecAttendeeType.bind(this)
        this.onPriceUSDChange = this.onPriceUSDChange.bind(this)
        this.onPriceINRChange = this.onPriceINRChange.bind(this)
        this.onPromoCodeDiscountChange = this.onPromoCodeDiscountChange.bind(this)
        this.onPromoCodeChange = this.onPromoCodeChange.bind(this)
        this.onInsertUpdateVisitorConfig = this.onInsertUpdateVisitorConfig.bind(this)
        this.onSelectDays = this.onSelectDays.bind(this)
        this.onsetEventTime = this.onsetEventTime.bind(this)
        this.onSelecIndustrySegment = this.onSelecIndustrySegment.bind(this)

    }

    componentDidMount() {
        this.setState({
            ExihibitionID: this.props.location.state.ExihibitionID != undefined ? (this.props.location.state != null && this.props.location.state.ExihibitionID != null ? this.props.location.state.ExihibitionID : '') : this.props.id != null && this.props.id != undefined ? this.props.id : '',
        }, () => {
            // this.getMasterFillList(attendeeTypeActionId, '', '', '', '', '').then(() => {
            //     this.getMasterFillList(visitPurposeActionId, '', '', '', '', '').then(() => {
            //         this.getMasterFillList(visitorTypeListActionId, '', '', '', '', '').then(() => {
            this.getVisitorConfigurationDetails()
            //         })
            //     })
            // })

        })
    }

    getMasterFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        return new Promise((resolve => {
            this.props.fill_master_action(actionID, exhibitionID, moduleID, keyValue, value)
                .then((data) => {
                    if (data.error != 1) {
                        // Country List
                        if (actionID === attendeeTypeActionId) {
                            data.data.length > 0 && data.data.map((item) => {
                                item.isSelect = false
                            })
                            this.setState({
                                attendeeTypeList: data.data,
                                // TempCountryList: data.data,
                                isLoading: false,
                                // allocateCountryList: alloCountryList
                            })
                            console.log("dashboardDownloadList data", data.data)
                            resolve(true)
                        }
                        if (actionID === visitPurposeActionId) {
                            data.data.length > 0 && data.data.map((item) => {
                                item.isSelect = false
                            })
                            this.setState({
                                purposeOfVisitList: data.data,
                                // TempCountryList: data.data,
                                isLoading: false,
                                // allocateCountryList: alloCountryList
                            })
                            console.log("dashboardDownloadList data", data.data)
                            resolve(true)
                        }
                        if (actionID === visitorTypeListActionId) {
                            data.data.length > 0 && data.data.map((item) => {
                                item.isSelect = false
                            })
                            this.setState({
                                visitorTypeList: data.data,
                                // TempCountryList: data.data,
                                isLoading: false,
                                // allocateCountryList: alloCountryList
                            })
                            console.log("dashboardDownloadList data", data.data)
                            resolve(true)
                        }
                    }
                })
            // }
        }))
    }

    getVisitorConfigurationDetails() {
        this.setState({ isLoading: true })
        const { ExihibitionID, visitorTypeList, attendeeTypeList, purposeOfVisitList } = this.state
        this.props.getExhibitionConfigrationDetails_action(null)
            .then((defaultData) => {
                if (defaultData.error != 1) {
                    defaultData.data[0].length > 0 && defaultData.data[0].map(item => {
                        item.isSelect = false
                    })
                    defaultData.data[1].length > 0 && defaultData.data[1].map(item => {
                        item.isSelect = false
                    })
                    defaultData.data[2].length > 0 && defaultData.data[2].map(item => {
                        item.isSelect = false
                    })
                    defaultData.data[3].length > 0 && defaultData.data[3].map(item => {
                        item.isSelect = false
                    })
                    defaultData.data[4].length > 0 && defaultData.data[4].map(item => {
                        item.isSelect = false
                    })
                    this.props.getExhibitionConfigrationDetails_action(ExihibitionID)
                        .then((data) => {
                            if (data.error != 1) {
                                console.log('defaultData.data', data.data[0], defaultData.data[0])
                                defaultData.data[0].length > 0 && defaultData.data[0].map(item => {
                                    data.data[0].length > 0 && data.data[0].map(element => {
                                        if (element.IndustrySegmentID == item.IndustrySegmentID) {
                                            item.isSelect = true
                                        }
                                    })
                                })
                                defaultData.data[1].length > 0 && defaultData.data[1].map(item => {
                                    data.data[1].length > 0 && data.data[1].map(element => {
                                        if (element.VisitorTypeID == item.VisitorTypeID) {
                                            item.isSelect = true
                                        }
                                    })
                                })
                                defaultData.data[2].length > 0 && defaultData.data[2].map((item) => {
                                    data.data[2].length > 0 && data.data[2].map(element => {
                                        if (element.AttendeeTypeID == item.AttendeeTypeID) {
                                            item.isSelect = true
                                        }
                                    })
                                })
                                defaultData.data[3].length > 0 && defaultData.data[3].map((item) => {
                                    data.data[3].length > 0 && data.data[3].map(element => {
                                        if (element.PurposeofVisitID == item.PurposeofVisitID) {
                                            item.isSelect = true
                                        }
                                    })
                                })
                                // defaultData.data[3].length > 0 && defaultData.data[3].map((item) => {
                                data.data[4].length > 0 && data.data[4].map(element => {
                                    // if (element.VisitDate == item.VisitDate) {
                                    element.isSelect = true
                                    // }
                                })
                                // })
                                if (data.data[6] != '') {
                                    data.data.map((item, i) => {

                                    })
                                    var eventTimingList = []
                                    data.data[6].length > 0 && data.data[6].map((item, i) => {
                                        var start = ''
                                        var end = ''
                                        var onSitestart = ''
                                        var onSiteend = ''
                                        var time = item['EXHIBITION HOURS'] != '' && item['EXHIBITION HOURS'] != null ? item['EXHIBITION HOURS'].split(" TO ") : null
                                        var onSitetime = item['ONSITE REGISTRATION TIMING'] != '' && item['ONSITE REGISTRATION TIMING'] != null ? item['ONSITE REGISTRATION TIMING'].split(" TO ") : null
                                        start = time != null ? moment(time[0], "hh:mm A").format("HH:mm") : ''
                                        end = time != null ? moment(time[1], "hh:mm A").format("HH:mm") : ''
                                        onSitestart = onSitetime != null ? moment(onSitetime[0], "hh:mm A").format("HH:mm") : ''
                                        onSiteend = onSitetime != null ? moment(onSitetime[1], "hh:mm A").format("HH:mm") : ''
                                        const dateStr = item.Dates;
                                        console.log('dateStr', dateStr)
                                        const date = moment(dateStr, "DD MMM YYYY")
                                            .utcOffset("+05:30")
                                            .format("ddd MMM DD YYYY HH:mm:ss [GMT+0530] ([India Standard Time])")
                                        console.log('date', date)
                                        var obj = {
                                            'EventDate': date,
                                            'ExhibitionHrsStartTime': start,
                                            'ExhibitionHrsEndTime': end,
                                            'OnsiteStartTime': onSitestart,
                                            'OnsiteEndTime': onSiteend,
                                            id: i + 1
                                        }
                                        eventTimingList.push(obj)
                                    })
                                    this.setState({ eventTimingList: eventTimingList, })
                                } else {
                                    this.setState({
                                        eventTimingList: [
                                            {
                                                EventDate: '',
                                                ExhibitionHrsStartTime: '',
                                                ExhibitionHrsEndTime: '',
                                                OnsiteStartTime: '',
                                                OnsiteEndTime: '',
                                                id: 1
                                            }
                                        ]
                                    })
                                }


                                console.log(defaultData.data[3], data.data[3])
                                this.setState({
                                    attendeeTypeList: defaultData.data[2], industrySegmentList: defaultData.data[0], visitorTypeList: defaultData.data[1], purposeOfVisitList: defaultData.data[3], visitDaysList: data.data[4],
                                    eBadgeDownloadDate: data.data[5][0].VisitorEBadgeDownloadDate != null && data.data[5][0].VisitorEBadgeDownloadDate != '' ? new Date(data.data[5][0].VisitorEBadgeDownloadDate) : null
                                })
                                this.setState({ isLoading: false })
                            }
                            else {
                                this.setState({ isLoading: false })
                                toastError(data.msg)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(defaultData.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    onSelectPurpose(e) {
        const { purposeOfVisitList } = this.state
        var code = e.target.value
        var checked = e.target.checked
        console.log(code, checked)

        purposeOfVisitList.length > 0 && purposeOfVisitList.map((data) => {
            if (checked) {
                if (code == data.PurposeofVisitID) {
                    data.isSelect = true

                }
            }
            else {
                if (code == data.PurposeofVisitID) {
                    data.isSelect = false
                }
            }
        })
        console.log("purposeOfVisitList", purposeOfVisitList)
        this.setState({ purposeOfVisitList: purposeOfVisitList, })
    }

    onSelectDays(e) {
        const { visitDaysList } = this.state
        var code = e.target.value
        var checked = e.target.checked

        visitDaysList.length > 0 && visitDaysList.map((data) => {
            if (checked) {
                if (code == data.VisitDate) {
                    data.isSelect = true

                }
            }
            else {
                if (code == data.VisitDate) {
                    data.isSelect = false
                }
            }
        })
        console.log("purposeOfVisitList", visitDaysList)
        this.setState({ visitDaysList: visitDaysList, })
    }

    onSelectVisitType(e) {
        const { visitorTypeList } = this.state
        var code = e.target.value
        var checked = e.target.checked
        console.log(code, checked)
        visitorTypeList.length > 0 && visitorTypeList.map((data) => {
            if (checked) {
                if (code == data.VisitorTypeID) {
                    data.isSelect = true

                }
            }
            else {
                if (code == data.VisitorTypeID) {
                    data.isSelect = false
                }
            }
        })
        console.log("visitorTypeList", visitorTypeList)
        this.setState({ visitorTypeList: visitorTypeList, })
    }

    onSelecIndustrySegment(e) {
        const { industrySegmentList } = this.state
        var code = e.target.value
        var checked = e.target.checked

        industrySegmentList.length > 0 && industrySegmentList.map((data) => {
            if (checked) {
                if (code == data.IndustrySegmentID) {
                    data.isSelect = true

                }
            }
            else {
                if (code == data.IndustrySegmentID) {
                    data.isSelect = false
                }
            }
        })
        console.log("attendeeTypeList", industrySegmentList)
        this.setState({ industrySegmentList: industrySegmentList })
    }

    onSelecAttendeeType(e) {
        const { attendeeTypeList } = this.state
        var code = e.target.value
        var checked = e.target.checked

        attendeeTypeList.length > 0 && attendeeTypeList.map((data) => {
            if (checked) {
                if (code == data.AttendeeTypeID) {
                    data.isSelect = true

                }
            }
            else {
                if (code == data.AttendeeTypeID) {
                    data.isSelect = false
                }
            }
        })
        console.log("attendeeTypeList", attendeeTypeList)
        this.setState({ attendeeTypeList: attendeeTypeList, })
    }

    onPriceINRChange(e, ID) {
        e.preventDefault()
        const { visitDaysList } = this.state
        visitDaysList.map((data, i) => {
            if (ID == i) {
                data.PriceINR = parseFloat(e.target.value)
            }
        })
        setTimeout(() => {
            console.log(visitDaysList)
            this.setState({ visitDaysList: visitDaysList })
        }, 200);
    }

    onPriceUSDChange(e, ID) {
        e.preventDefault()
        const { visitDaysList } = this.state
        visitDaysList.map((data, i) => {
            if (ID == i) {
                data.PriceUSD = parseFloat(e.target.value)
            }
        })
        setTimeout(() => {
            console.log(visitDaysList)
            this.setState({ visitDaysList: visitDaysList })
        }, 200);
    }

    onPromoCodeChange(e, ID) {
        e.preventDefault()
        const { visitDaysList } = this.state
        visitDaysList.map((data, i) => {
            if (ID == i) {
                data.PromoCode = (e.target.value)
            }
        })
        setTimeout(() => {
            console.log(visitDaysList)
            this.setState({ visitDaysList: visitDaysList })
        }, 200);
    }

    onPromoCodeDiscountChange(e, ID) {
        e.preventDefault()
        const { visitDaysList } = this.state
        console.log(parseInt(e.target.value))
        if ((parseInt(e.target.value) > 100 || parseInt(e.target.value) < 0)) {
            visitDaysList.map((data, i) => {
                if (ID == i) {
                    data.PromoCodeDiscount = ''
                }
            })
            toastError("Discount(%) should be between 0 to 100")
        } else {
            visitDaysList.map((data, i) => {
                if (ID == i) {
                    if (e.target.value != '') {
                        data.PromoCodeDiscount = parseInt(e.target.value)
                    } else {
                        data.PromoCodeDiscount = ''
                    }
                }
            })
        }
        setTimeout(() => {
            console.log(visitDaysList)
            this.setState({ visitDaysList: visitDaysList })
        }, 200);
    }

    onInsertUpdateVisitorConfig() {
        const { visitDaysList, visitorTypeList, industrySegmentList, ExihibitionID, eBadgeDownloadDate, eventTimingList, attendeeTypeList, purposeOfVisitList } = this.state
        const visitDays = visitDaysList.filter(data => data.isSelect == true)
        var list1 = []
        visitDays.map((data) => {
            var data = {
                "visitDay": data.VisitDate,
                'PriceINR': data.PriceINR,
                'PriceUSD': data.PriceUSD,
                'PromoCode': data.PromoCode,
                'PromoCodeDiscount': data.PromoCodeDiscount
            }
            list1.push(data)
        })

        const visitorType = visitorTypeList.filter(data => data.isSelect == true)
        var list2 = []
        visitorType.map((data) => {
            var data = {
                "VisitorTypeID": data.VisitorTypeID,
            }
            list2.push(data)
        })

        const attendeeType = attendeeTypeList.filter(data => data.isSelect == true)
        var list3 = []
        attendeeType.map((data) => {
            var data = {
                "AttendeeTypeID": data.AttendeeTypeID
            }
            list3.push(data)
        })

        const purposeOfVisit = purposeOfVisitList.filter(data => data.isSelect == true)
        var list4 = []
        purposeOfVisit.map((data) => {
            var data = {
                "PurposeofVisitID": data.PurposeofVisitID
            }
            list4.push(data)
        })

        const industrySegment = industrySegmentList.filter(data => data.isSelect == true)
        var list5 = []
        industrySegment.map((data) => {
            var data = {
                "IndustrySegmentID": data.IndustrySegmentID
            }
            list5.push(data)
        })

        const array = []
        eventTimingList.map(item => {
            array.push(
                {
                    EventDate: item.EventDate != '' && item.EventDate != null ? moment(item.EventDate).format("YYYY-MM-DD") : '',
                    ExhibitionHrsStartTime: item.ExhibitionHrsStartTime,
                    ExhibitionHrsEndTime: item.ExhibitionHrsEndTime,
                    OnsiteStartTime: item.OnsiteStartTime,
                    OnsiteEndTime: item.OnsiteEndTime,
                }
            )
        })

        const checkedBlankTimingList = array.every(item => item.EventDate != '' && item.EventDate != null && item.ExhibitionHrsStartTime != '' && item.ExhibitionHrsStartTime != null && item.ExhibitionHrsEndTime != '' && item.ExhibitionHrsEndTime != null && item.OnsiteStartTime != '' && item.OnsiteStartTime != null && item.OnsiteEndTime != '' && item.OnsiteEndTime != null)

        console.log("array------>", array)

        this.setState({ isLoading: true })
        if (checkedBlankTimingList) {
            this.props.insertUpdateVisitorConfigration_action(ExihibitionID, list3, list4, list2, list1, list5, eBadgeDownloadDate, array)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({ isLoading: false })
                        toastSuccess("data updated Successfuly!")
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            this.setState({ isLoading: false })
            toastError("Please enter all event timing feilds")
        }
    }

    onsetEventTime(id, time, status) {
        const { isLoading, eventTimingList, visitorTypeList, eBadgeDownloadDate, eBadgeDownloadDateErr, attendeeTypeList, industrySegmentList, purposeOfVisitList, visitDaysList } = this.state
        for (var i = 0; i < eventTimingList.length; i++) {
            if (i == id) {
                if (status == 'ExhibitionHrsStartTime') {
                    eventTimingList[i].ExhibitionHrsStartTime = time
                }
                else if (status == 'ExhibitionHrsEndTime') {
                    eventTimingList[i].ExhibitionHrsEndTime = time
                }
                else if (status == 'OnsiteStartTime') {
                    eventTimingList[i].OnsiteStartTime = time
                }
                else if (status == 'OnsiteEndTime') {
                    eventTimingList[i].OnsiteEndTime = time
                }
                else if (status == 'EventDate') {
                    console.log("EventDate-------->", time)
                    eventTimingList[i].EventDate = time.toString()
                }
            }
        }
        this.setState({ eventTimingList: eventTimingList })
        setTimeout(() => {
            console.log(eventTimingList)

        }, 500);
    }

    addExhEventTimingList(i) {
        const { eventTimingList } = this.state
        eventTimingList.splice(
            i + 1, 0, {
            id: eventTimingList.length + 1,
            EventDate: '',
            ExhibitionHrsStartTime: '',
            ExhibitionHrsEndTime: '',
            OnsiteStartTime: '',
            OnsiteEndTime: '',
        }
        )
        this.setState({ eventTimingList: eventTimingList })
    }

    deleteExhEventTimingList(id) {
        const { eventTimingList } = this.state
        const filterArray = eventTimingList.filter(item => item.id != id)
        filterArray.map((item, i) => {
            item.id = i + 1
        })
        this.setState({ eventTimingList: filterArray })
    }

    render() {
        const { isLoading, visitorTypeList, eBadgeDownloadDate, eBadgeDownloadDateErr, attendeeTypeList, industrySegmentList, purposeOfVisitList, visitDaysList } = this.state

        console.log(visitDaysList)
        return (
            <>

                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <AdminDrawer />
                    <div className="main-container p-2">

                        <div className="one-section">
                            <div className="containerfluid">
                                <div className="row mt-2">
                                    <div className="col-md-12">
                                        <div className="p-1 title-bg d-flex">
                                            <p className="mb-0">
                                                Visitor Type
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-12 mb-2">
                                <div className="row mt-2">
                                    {
                                        visitorTypeList.length > 0 && visitorTypeList.map((data) => (<>
                                            <div className="col-sm-3 mb-2" key={data.VisitorTypeID}>
                                                <label className="d-flex">
                                                    <input type="checkbox" name="fav_language"
                                                        select={data.isSelect} checked={data.isSelect}
                                                        value={data.VisitorTypeID}
                                                        onChange={(e) => this.onSelectVisitType(e)}
                                                    />
                                                    {data.VisitorTypeName}
                                                </label>
                                            </div>
                                        </>

                                        ))
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="one-section">
                            <div className="containerfluid">
                                <div className="row mt-4">
                                    <div className="col-md-12">
                                        <div className="p-1 title-bg d-flex">
                                            <p className="mb-0">
                                                Industry Segment
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-12 mb-2">
                                <div className="row mt-2">
                                    {
                                        industrySegmentList.length > 0 && industrySegmentList.map((data) => (<>
                                            <div className="col-sm-3 mb-2" key={data.IndustrySegmentID}>
                                                <label className="d-flex">
                                                    <input type="checkbox" name="fav_language"
                                                        select={data.isSelect} checked={data.isSelect}
                                                        value={data.IndustrySegmentID}
                                                        onChange={(e) => this.onSelecIndustrySegment(e)}
                                                    />
                                                    {data.Name}
                                                </label>
                                            </div>
                                        </>

                                        ))
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="one-section">
                            <div className="containerfluid">
                                <div className="row mt-4">
                                    <div className="col-md-12">
                                        <div className="p-1 title-bg d-flex">
                                            <p className="mb-0">
                                                Attendee Type
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-12 mb-2">
                                <div className="row mt-2">
                                    {
                                        attendeeTypeList.length > 0 && attendeeTypeList.map((data) => (<>
                                            <div className="col-sm-3 mb-2" key={data.AttendeeTypeID}>
                                                <label className="d-flex">
                                                    <input type="checkbox" name="fav_language"
                                                        select={data.isSelect} checked={data.isSelect}
                                                        value={data.AttendeeTypeID}
                                                        onChange={(e) => this.onSelecAttendeeType(e)}
                                                    />
                                                    {data.AttendeeTypeName}
                                                </label>
                                            </div>
                                        </>

                                        ))
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="one-section">
                            <div className="containerfluid">
                                <div className="row mt-4">
                                    <div className="col-md-12">
                                        <div className="p-1 title-bg d-flex">
                                            <p className="mb-0">
                                                Purpose of Visit
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-12 mb-2">
                                <div className="row mt-2">
                                    {
                                        purposeOfVisitList.length > 0 && purposeOfVisitList.map((data) => (<>
                                            <div className="col-sm-3 mb-2" key={data.PurposeofVisitID}>
                                                <label className="d-flex">
                                                    <input type="checkbox" name="fav_language"
                                                        select={data.isSelect} checked={data.isSelect}
                                                        value={data.PurposeofVisitID}
                                                        onChange={(e) => this.onSelectPurpose(e)}
                                                    />
                                                    {data.PurposeofVisitName}
                                                </label>
                                            </div>
                                        </>

                                        ))
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="one-section">
                            <div className="containerfluid">
                                <div className="row mt-4">
                                    <div className="col-md-12">
                                        <div className="p-1 title-bg d-flex">
                                            <p className="mb-0">
                                                Visit Days
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-3">
                                    <thead>
                                        <tr>
                                            {/* <th className="text-center"></th> */}
                                            <th className="text-center">Date</th>
                                            <th className="text-center">Price (INR)</th>
                                            <th className="text-center">Price (USD)</th>
                                            <th className="text-center">PromoCode</th>
                                            <th className="text-center">PromoCode Discount(%)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            visitDaysList.length > 0 ? <>{
                                                visitDaysList.map((data, i) => (
                                                    <tr key={i}>
                                                        {/* <td className="text-center">
                                                            <input type="checkbox" name="fav_language"
                                                                select={data.isSelect} checked={data.isSelect}
                                                                value={data.VisitDate}
                                                                onChange={(e) => this.onSelectDays(e)}
                                                            />
                                                        </td> */}
                                                        <td className="text-center">{data.VisitDate}</td>
                                                        <td>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                placeholder=""
                                                                value={data.PriceINR}
                                                                // disabled={true}
                                                                onChange={(e) => this.onPriceINRChange(e, i)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                placeholder=""
                                                                value={data.PriceUSD}
                                                                // disabled={true}
                                                                onChange={(e) => this.onPriceUSDChange(e, i)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder=""
                                                                value={data.PromoCode}
                                                                // disabled={true}
                                                                onChange={(e) => this.onPromoCodeChange(e, i)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder=""
                                                                value={data.PromoCodeDiscount}
                                                                // disabled={true}
                                                                onChange={(e) => this.onPromoCodeDiscountChange(e, i)}
                                                            />
                                                        </td>

                                                    </tr>
                                                ))}
                                            </> :
                                                <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="one-section">
                            <div className="containerfluid">
                                <div className="row mt-4">
                                    <div className="col-md-12">
                                        <div className="p-1 title-bg d-flex">
                                            <p className="mb-0">
                                                Visitor E-Badge Download date
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div >
                                <div className="col-xl-3">
                                    <label className="mt-3">Visitor E-Badge Download date
                                        <span className="text-red">*</span>
                                    </label>
                                    <DatePicker selected={eBadgeDownloadDate}
                                        onChange={(date) =>
                                            this.setState({
                                                eBadgeDownloadDate: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())),
                                                eBadgeDownloadDateErr: ""
                                            })}
                                        dateFormat={"dd/MM/yyyy"} className="form-control"
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        // minDate={passDate}
                                        dropdownMode="select"
                                        placeholderText="Passport Expiry Date"
                                        dayClassName={() => "example-datepicker-day-class"}
                                        popperClassName="example-datepicker-class" />
                                    {<span className='text-danger text-start text-left d-block mt-1'>{eBadgeDownloadDateErr}</span>}
                                </div>
                            </div>

                            {console.log(this.state.eventTimingList)}
                            <div className="one-section">
                                <div className="containerfluid">
                                    <div className="row mt-4">
                                        <div className="col-md-12">
                                            <div className="p-1 title-bg d-flex">
                                                <p className="mb-0">
                                                    Event Timings
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="table-responsive">
                                    <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-3">
                                        <thead>
                                            <tr>
                                                {/* <th className="text-center"></th> */}
                                                <th className="text-center">Date<span className="text-red">*</span></th>
                                                <th className="text-center">Exhibition Start Time<span className="text-red">*</span></th>
                                                <th className="text-center">Exhibition End Time<span className="text-red">*</span></th>
                                                <th className="text-center">Onsite Start Time<span className="text-red">*</span></th>
                                                <th className="text-center">Onsite End Time<span className="text-red">*</span></th>
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        {console.log(this.state.eventTimingList, 'this.state.eventTimingList')}
                                        <tbody>
                                            {
                                                this.state.eventTimingList.length > 0 ? <>{
                                                    this.state.eventTimingList.map((data, i) => (
                                                        <tr key={i}>
                                                            {/* <td className="text-center">
                                                            <input type="checkbox" name="fav_language"
                                                                select={data.isSelect} checked={data.isSelect}
                                                                value={data.VisitDate}
                                                                onChange={(e) => this.onSelectDays(e)}
                                                            />
                                                        </td> */}
                                                            <td className="text-center">
                                                                {/* {data.EventDate} */}
                                                                {console.log("ggg", data.EventDate)}
                                                                <DatePicker className="form-control"
                                                                    dateFormat="dd-MM-yyyy"
                                                                    selected={data.EventDate != '' && data.EventDate != null ? new Date(data.EventDate) : ''}
                                                                    onChange={(e) => this.onsetEventTime(i, e, 'EventDate')}
                                                                    peekNextMonth
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    placeholderText="dd-mm-yyyy"
                                                                />
                                                            </td>
                                                            <td className="text-center">
                                                                <TimePicker
                                                                    className='time-picker'
                                                                    max={data.ExhibitionHrsEndTime != '' ? data.ExhibitionHrsEndTime : null}
                                                                    style={{ width: '50%' }}
                                                                    value={data.ExhibitionHrsStartTime != undefined ? data.ExhibitionHrsStartTime.replace(/\s+(?:AM|PM)/, "") : ''}
                                                                    onChange={(e) => this.onsetEventTime(i, ((e.target.value)), 'ExhibitionHrsStartTime')}
                                                                    // onInput={() => this.setState({ FromTimeSlotErr: '' })}
                                                                    step={60 * 15}
                                                                />
                                                            </td>
                                                            <td className="text-center">
                                                                <TimePicker
                                                                    className='time-picker'
                                                                    min={data.ExhibitionHrsStartTime != '' ? data.ExhibitionHrsStartTime : null}
                                                                    style={{ width: '50%' }}
                                                                    value={data.ExhibitionHrsEndTime != undefined ? data.ExhibitionHrsEndTime.replace(/\s+(?:AM|PM)/, "") : ''}
                                                                    onChange={(e) => this.onsetEventTime(i, ((e.target.value)), 'ExhibitionHrsEndTime')}
                                                                    // onInput={() => this.setState({ FromTimeSlotErr: '' })}
                                                                    step={60 * 15}
                                                                />
                                                            </td>
                                                            <td className="text-center">
                                                                <TimePicker
                                                                    className='time-picker'
                                                                    max={data.OnsiteEndTime != '' ? data.OnsiteEndTime : null}
                                                                    style={{ width: '50%' }}
                                                                    value={data.OnsiteStartTime != undefined ? data.OnsiteStartTime.replace(/\s+(?:AM|PM)/, "") : ''}
                                                                    onChange={(e) => this.onsetEventTime(i, ((e.target.value)), 'OnsiteStartTime')}
                                                                    // onInput={() => this.setState({ FromTimeSlotErr: '' })}
                                                                    step={60 * 15}
                                                                />
                                                            </td>
                                                            <td className="text-center">
                                                                <TimePicker
                                                                    className='time-picker'
                                                                    min={data.OnsiteStartTime != '' ? data.OnsiteStartTime : null}
                                                                    style={{ width: '50%' }}
                                                                    value={data.OnsiteEndTime != undefined ? data.OnsiteEndTime.replace(/\s+(?:AM|PM)/, "") : ''}
                                                                    onChange={(e) => this.onsetEventTime(i, ((e.target.value)), 'OnsiteEndTime')}
                                                                    // onInput={() => this.setState({ FromTimeSlotErr: '' })}
                                                                    step={60 * 15}
                                                                />
                                                            </td>
                                                            <td className="text-center">
                                                                <button
                                                                    title="Add Event Timing"
                                                                    class="btn btn-sm padding_0"
                                                                    onClick={() => this.addExhEventTimingList(i)}
                                                                >
                                                                    <i class="fa fa-plus action_btn_table"></i>
                                                                </button>
                                                                {this.state.eventTimingList.length != 1 &&
                                                                    <button
                                                                        title="Delete Event Timing"
                                                                        class="btn btn-sm padding_0"
                                                                        onClick={() => this.deleteExhEventTimingList(data.id)}
                                                                    >
                                                                        <i class="fa fa-trash action_btn_table"></i>
                                                                    </button>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </> :
                                                    <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>


                            <div style={{ float: 'right' }} >
                                <button type="button" class="btn btn-success rounded-0 mt-3 ms-2"
                                    onClick={(e) => this.onInsertUpdateVisitorConfig(e)}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            </>)
    }
}


export default withRouter(connect(null, {
    fill_master_action, getExhibitionConfigrationDetails_action, insertUpdateVisitorConfigration_action
})(VisitorConfigration));