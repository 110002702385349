import { GET_EXCEL_DATA_ACTION_TYPE} from "../../utils/constants"


const initialState = [];

function excelListDataReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
      case GET_EXCEL_DATA_ACTION_TYPE:
      return [...state, payload];
    default:
      return state;
  }
};

export default excelListDataReducer;