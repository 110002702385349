import { axiosGet, axiosPost } from "../../../../../react_axios/Axios";
import { GET_STALL_POSSESSION_ACTION_TYPE, INSERT_STALL_POSSESSION_ACTION_TYPE, getStallPossessionUrl, headers, insertStallPossessionUrl } from "../../../../../utils/constants";


export const getStallPossession_action = () => async (dispatch) => {
    try {
        const param = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhRegistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
        }
        const res = await axiosGet(getStallPossessionUrl, param, headers)
        dispatch({
            type: GET_STALL_POSSESSION_ACTION_TYPE,
            payload: res
        });
        return Promise.resolve(res)
    } catch (error) {
        Promise.reject(error)
    }
}

export const insertStallPossession_action = (personName, companyName, contactNo, email, isAgree, remark) => async (dispatch) => {
    try {
        const mapData = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhRegistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
            PersonName: personName,
            CompanyName: companyName,
            ContactNo: contactNo,
            Email: email,
            Is_Agree: isAgree,
            Remark: remark
        }
        const res = await axiosPost(insertStallPossessionUrl, mapData, headers)
        dispatch({
            type: INSERT_STALL_POSSESSION_ACTION_TYPE,
            payload: res
        });
        return Promise.resolve(res)
    } catch (error) {
        Promise.reject(error)
    }
}