import { Component } from "react";
import { withRouter } from "../../utils/withRouter";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import VisitorHeader2 from "../../common_components/visitor_module_header2";
import VisitorDrawer from "../../common_components/visitor_module_drawer";
import VisitorTopMenu from "../../common_components/visitor_module_topmenu";
import Loader from "../../utils/Loader";
import HeaderWithLogo from "../../common_components/header_with_logo";
import ExhibitionHeader from "../../common_components/admin_module_header";
import ExhibitorDrawer from "../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../common_components/exhibitor_module_header";
import ExhibitionDrawer from "../../common_components/admin_module_drawer";
import ExhibitorTopMenu from "../../common_components/exhibitor_module_topmenu";
import { updatePassword_action } from "../../actions/user_login_auth_actions/user_login_auth_action";
import { toastError, toastSuccess } from "../../utils/constants";
import { newPasswordValidator, oldPasswordValidator, passwordValidator } from "../../utils/Validator";
import AgentHeader from "../../common_components/agent_module_header";
import AgentDrawer from "../../common_components/agent_module_drawer";
import AgentTopMenu from "../../common_components/agent_module_topmenu";


class ChangePassword2 extends Component {

    // Initialize Constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            is_showOldpass: false,
            is_showConfirmpass: false,
            is_showNewpass: false,
            old_pass: "",
            new_pass: "",
            confirm_pass: "",

            old_passErr: "",
            new_passErr: "",
            confirm_passErr: "",
        }
        this.updatePassword = this.updatePassword.bind(this)
        this.handleObscure = this.handleObscure.bind(this)
        this.inputValidate = this.inputValidate.bind(this)
    }

    // Obscure handle
    handleObscure(pass) {
        const { is_showOldpass, is_showConfirmpass, is_showNewpass } = this.state
        if (pass == 'new_pass') {
            this.setState({ is_showNewpass: !is_showNewpass })
        }
        else if (pass == 'old_pass') {
            this.setState({ is_showOldpass: !is_showOldpass })
        }
        else if (pass == 'confirm_pass') {
            this.setState({ is_showConfirmpass: !is_showConfirmpass })
        }

    }

    // Input Validate
    inputValidate() {
        const { old_pass, new_pass, confirm_pass } = this.state
        if (old_pass == "") {
            this.setState({ old_passErr: "Please Enter Current Password" })
        }
        if (new_pass == "") {
            this.setState({ new_passErr: "Please Enter New password" })
        }
        if (new_pass != "") {
            var passErr = newPasswordValidator(new_pass)
            if (passErr != "") {
                this.setState({ new_passErr: passErr })
                return
            }
        }
        if (confirm_pass == "") {
            this.setState({ confirm_passErr: "Please Enter Confirm Password" })
        }
        if (confirm_pass != "") {
            if (new_pass != confirm_pass) {
                this.setState({ confirm_passErr: "New password and Confirm paswword shoud be same" })
            } else {
                this.setState({ confirm_passErr: "" })
            }
        }
    }

    //  ON update Password Click
    updatePassword() {

        this.inputValidate()
        setTimeout(() => {
            const { old_pass, new_pass, confirm_pass, old_passErr, new_passErr, confirm_passErr } = this.state
            if (old_pass != "" && new_pass != "" && confirm_pass != "" && new_pass == confirm_pass) {
                console.log("xxxxxxxxxxxxxxxxxxxxxxxx", new_passErr == "", new_passErr)
                if (old_passErr == "" && new_passErr == "" && confirm_passErr == "") {
                    this.setState({ isLoading: true })
                    this.props.updatePassword_action(old_pass, new_pass)
                        .then((data) => {
                            if (data.error != 1) {
                                this.setState({ isLoading: false })
                                toastSuccess("Password Chnaged Successfully")
                            } else {
                                this.setState({ isLoading: false })
                                toastError(data.msg)
                            }
                        }).catch((e) => {
                            console.log(e)
                        })

                }

            } else {
                this.setState({ isLoading: false })
                toastError("Please Check Your Password")
            }
        }, 500);
    }

    render() {
        const { isLoading, is_showOldpass, is_showConfirmpass, is_showNewpass, old_pass, new_pass, confirm_pass, old_passErr, new_passErr,
            confirm_passErr } = this.state

        return (
            <>

                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                {
                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                        <ExhibitorHeader /> : JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 2 ?
                            <HeaderWithLogo /> : JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 3 ?
                                <VisitorHeader2 /> : JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 4 ?
                                    <AgentHeader />
                                    : null
                }
                {
                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                        <ExhibitorDrawer /> : JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 2 ?
                            <ExhibitionDrawer /> : JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 3 ?
                                <VisitorDrawer /> : JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 4 ?
                                    <AgentDrawer /> :
                                    null
                }
                <div
                    className="main-content app-content mt-0 with-dr-container"
                >
                    <div >
                        {
                            JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                                <ExhibitorTopMenu /> :
                                JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 3 ?
                                    <VisitorTopMenu /> :
                                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 4 ?
                                        <AgentTopMenu /> :
                                        null
                        }
                    </div>
                    <div className="side-app">
                        {/* <div className="container-fluid p-0">
                            <div className="row">
                                <div
                                    className="step-name" style={{ backgroundColor: "#bc202b", color: "#fff;", marginLeft: "225px" }}
                                >
                                    <p>ITME Africa &amp; Middle East 2023 Visitor Registration form</p>
                                </div>
                            </div>
                        </div> */}
                        <div className="card card-outline-secondary-1 space-from-top" >
                            <div className="card-header bg-white border-0  text-center d-block mb-2 ">
                                <h5 className="mb-0 chsp p-2">Change Password</h5>
                            </div>
                            <div className="card-body">
                                <form className="form" role="form" autoComplete="off">
                                    <div className="form-group mb-3" style={{ position: 'relative' }}>
                                        <label htmlFor="inputPasswordOld">Current Password</label>
                                        <input
                                            type={is_showOldpass ? "type" : "password"}
                                            className="form-control"
                                            id="inputPasswordOld"
                                            placeholder="Enter Your Old Password"
                                            required
                                            value={old_pass}
                                            onChange={e => this.setState({ old_pass: e.target.value, old_passErr: "" })}
                                        />
                                        {<span className='text-danger text-start text-left d-block mt-1'>{old_passErr}</span>}
                                        <i
                                            className="fa fa-eye"
                                            onClick={e => this.handleObscure('old_pass')}
                                            style={{
                                                top: ' 32px',
                                                right: '5px',
                                                position: "absolute",
                                                color: 'gray'
                                            }}
                                        />
                                    </div>
                                    <div className="form-group mb-3" style={{ position: 'relative' }}>
                                        <label htmlFor="inputPasswordNew">New Password</label>
                                        <input
                                            type={is_showNewpass ? "type" : "password"}
                                            className="form-control"
                                            id="inputPasswordNew"
                                            placeholder="Enter New Password"
                                            maxLength={'20'}
                                            required
                                            value={new_pass}
                                            onChange={e => this.setState({ new_pass: e.target.value, new_passErr: "" })}
                                        />
                                        {<span className='text-danger text-start text-left d-block mt-1'>{new_passErr}</span>}
                                        <i
                                            className="fa fa-eye"
                                            onClick={e => this.handleObscure('new_pass')}
                                            style={{
                                                top: ' 32px',
                                                right: '5px',
                                                position: "absolute",
                                                color: 'gray'

                                            }}
                                        />
                                    </div>
                                    <div className="form-group mb-3" style={{ position: 'relative' }}>
                                        <label htmlFor="inputPasswordNewVerify">Confirm Password</label>
                                        <input
                                            type={is_showConfirmpass ? "type" : "password"}
                                            className="form-control"
                                            id="inputPasswordNewVerify"
                                            placeholder="Enter New Password again"
                                            maxLength={'20'}
                                            required
                                            value={confirm_pass}
                                            onChange={e => this.setState({ confirm_pass: e.target.value, confirm_passErr: "" })}
                                        />
                                        {<span className='text-danger text-start text-left d-block mt-1'>{confirm_passErr}</span>}
                                        <i
                                            className="fa fa-eye"
                                            onClick={e => this.handleObscure('confirm_pass')}
                                            style={{
                                                top: ' 32px',
                                                right: '5px',
                                                position: "absolute",
                                                color: 'gray'

                                            }}
                                        />
                                    </div>
                                    <div className="form-group mb-2">
                                        <a
                                            className="btn btn-primary rounded-0 d-block"
                                            style={{ padding: "4px 25px" }}
                                            type="submit"
                                            onClick={(e) => this.updatePassword(e)}
                                        >
                                            Change Password
                                        </a>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </>

        )
    }
}
export default withRouter(connect(null, { updatePassword_action })(ChangePassword2))