import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action } from '../../../../../actions/fillCombo_action';
import { cityActionId, countryActionId, toastError, toastSuccess, manualFormsPricesListActionId } from "../../../../../utils/constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import { useNavigate } from "react-router-dom";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import { data } from "jquery";
import { getManualformsMasterPricesList_action, insertUpdateManualformsMasterPricesList_action } from "../../../../../actions/admin_module_action/master_action/exhibition_master_action/exhManualFormPrices_action";


class Exh_ManualFormPrices extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            list: [],
            manualFormsList: [],
            ManualFormID: '',
            isOpen: false,
            toAdd: false,
            ExhibitionID: '',
        }
    }

    componentDidMount() {
        this.getFillList(manualFormsPricesListActionId, '', '', '', '', '');
        this.setState({
            ExhibitionID: this.props.location.state.ExhibitionID != undefined ? (this.props.location.state != null && this.props.location.state.ExhibitionID != null ? this.props.location.state.ExhibitionID : '') : this.props.id != null && this.props.id != undefined ? this.props.id : '',
        })
    }


    // Get Fill Lists
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value)
            .then((data) => {
                if (data.error != 1) {
                    // country list
                    if (actionID === manualFormsPricesListActionId) {
                        console.log("country data", data.data)
                        this.setState({ manualFormsList: data.data, isLoading: false })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    getManualFormsMasterList(ManualFormID) {
        this.setState({ isLoading: true })
        this.props.getManualformsMasterPricesList_action(this.state.ExhibitionID, ManualFormID)
            .then((data) => {
                if (data.error != 1) {
                    if (data.data.length > 0) {
                        this.setState({
                            list: data.data,
                            isAdd: false
                        })
                    }
                    else {
                        this.setState({ isAdd: true })
                    }
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    insertUpdateManualFormsMasterList() {
        this.setState({ isLoading: true })
        const { list, ExhibitionID, ManualFormID } = this.state
        this.props.insertUpdateManualformsMasterPricesList_action(ExhibitionID, ManualFormID, list)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ isLoading: false })
                    toastSuccess("update successfully")
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }


    onPriceINRChange(e, index) {
        e.preventDefault()
        const { list } = this.state
        list.map((data, i) => {
            if (index == i) {
                data.Amount_in_INR = parseFloat(e.target.value)
            }
        })
        this.setState({ list: list })
    }

    onPriceUSDChange(e, index) {
        e.preventDefault()
        const { list } = this.state
        list.map((data, i) => {
            if (index == i) {
                data.Amount_in_USD = parseFloat(e.target.value)
            }
        })
        this.setState({ list: list })
    }


    render() {
        const { isLoading, isOpen, list, manualFormsList, ManualFormID } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                <AdminDrawer />
                <div className="main-container p-2 mt-2">
                    <div className="main-container p-2">

                        <div className="row" >
                            <div className="col-md-4">
                                <label htmlFor="validationCustom03">Manual Form</label>
                                <select
                                    className="form-control"
                                    value={ManualFormID}
                                    onChange={(e) => this.setState({ ManualFormID: e.target.value }, () => {
                                        if (e.target.value != '') {
                                            this.getManualFormsMasterList(e.target.value)
                                        }
                                    })}
                                >
                                    <option value={''}>Select</option>
                                    {manualFormsList.length > 0 &&
                                        manualFormsList.map((data) => (
                                            <option key={data.ManualFormID} value={data.ManualFormID}>
                                                {data.Name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </div>
                        <div class="table-responsive mt-3">
                            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
                                <thead>
                                    <tr>
                                        <th className="text-center">ServiceName</th>
                                        <th className="text-center">Amount (INR)</th>
                                        <th className="text-center">Amount (USD)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        list.length > 0 ? <>{
                                            list.map((data, i) => (
                                                <tr key={i}>
                                                    <td>
                                                        {data.ServiceName}
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            placeholder=""
                                                            value={data.Amount_in_INR}
                                                            onChange={(e) => this.onPriceINRChange(e, i)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            placeholder=""
                                                            value={data.Amount_in_USD}
                                                            onChange={(e) => this.onPriceUSDChange(e, i)}
                                                        />
                                                    </td>

                                                </tr>
                                            ))}
                                        </> :
                                            <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                                    }
                                </tbody>
                            </table>
                            <div style={{ float: 'right' }} >

                                <button type="button" class="btn btn-success rounded-0 mt-3 ms-2"
                                    onClick={(e) => this.insertUpdateManualFormsMasterList(e)}
                                    disabled={ManualFormID==''}
                                    >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(connect(null, { fill_action, getManualformsMasterPricesList_action, insertUpdateManualformsMasterPricesList_action })(Exh_ManualFormPrices));