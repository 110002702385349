import { GET_VENDOR_EXHIBITION_LIST__ACTION_TYPE, } from "../../../utils/constants";


const initialState = [];

function getVendorExhibitionListReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case GET_VENDOR_EXHIBITION_LIST__ACTION_TYPE:
            return [...state, payload];

        default:
            return state;
    }
};

export default getVendorExhibitionListReducer