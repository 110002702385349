import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../../utils/withRouter';
import Loader from '../../../../utils/Loader'
// import { clearSessionStorage } from '../../../utils/constants'
import Header from '../../../../common_components/Header';
// import { emailValidator } from '../../../utils/Validator'
import Modal from "react-modal";
import Footerwithdr from '../../../../common_components/Footerwithdr';
import HeaderWithLogo from '../../../../common_components/header_with_logo';
import AdminDrawer from '../../../../common_components/admin_module_drawer';
import { fill_action } from '../../../../actions/fillCombo_action'
// import ReceiptList from '../registration_receipt/ReceiptList';
import { Link } from "react-router-dom";
import { getAdminAreaSummaryList_action } from "../../../../actions/admin_module_action/admin_dasboard_action/admin_dashboard_summary_action/admin_dashboard_summary_action"
import { getAdminDashboardData_action } from "../../../../actions/admin_module_action/admin_dasboard_action/admin_dashboard_action"
import { data } from 'jquery';

class AreaSummary extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            adminAreaSummaryList: [],
            pendingPaymentCount: '',
        }
        this.record = this.record.bind(this)
        this.getDashboardData = this.getDashboardData.bind(this)
        this.navToPaymentList = this.navToPaymentList.bind(this)
        this.getAreaSummaryList = this.getAreaSummaryList.bind(this)
        this.onCountClick = this.onCountClick.bind(this)
        this.onCountClick = this.onCountClick.bind(this)
    }

    // On Component Load
    componentDidMount() {
        this.getDashboardData()
        this.getAreaSummaryList()
    }

    // Get DashBoard Data
    getDashboardData() {
        this.setState({ isLoading: true })
        this.props.getAdminDashboardData_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log("Admin Dashboard Data ------->", data.data)
                    this.setState({
                        isLoading: false,
                        pendingPaymentCount: data.data[0]['Count_Of_Payment']
                    })
                } else {
                    this.setState({ isLoading: false })
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // Get suummary list
    getAreaSummaryList() {
        this.setState({ isLoading: true })
        this.props.getAdminAreaSummaryList_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log("Admin Data ------->", data.data)
                    // data.data.length > 0 && data.data.map((dat) => (
                    //     dat.Total = dat.Applied + dat.Agent + dat.Waiting + dat.Incomplete
                    // ))
                    var temptotalAgent = 0
                    var temptotalApplied = 0
                    var temptotalAvailable = 0
                    var temptotalBalance = 0
                    var temptotalWaiting = 0
                    var temptotalOverall = 0
                    var temptotalBuffer = 0
                    var obj = {}

                    for (var i = 0; i < data.data.length; i++) {
                        temptotalAgent = temptotalAgent + parseInt(data.data[i].Agent)
                        temptotalApplied = temptotalApplied + parseInt(data.data[i].Applied)
                        temptotalAvailable = temptotalAvailable + parseInt(data.data[i].Available)
                        temptotalBalance = temptotalBalance + parseInt(data.data[i].Balance)
                        temptotalWaiting = temptotalWaiting + parseInt(data.data[i].Waiting)
                        temptotalOverall = temptotalOverall + parseInt(data.data[i].Overall)
                        temptotalBuffer = temptotalBuffer + parseInt(data.data[i].Buffer)
                    }
                    obj.Agent = temptotalAgent
                    obj.Applied = temptotalApplied
                    obj.Available = temptotalAvailable
                    obj.Balance = temptotalBalance
                    obj.Waiting = temptotalWaiting
                    obj.Overall = temptotalOverall
                    obj.Buffer = temptotalBuffer
                    obj.ChapterName = 'TOTAL'
                    if (data.data.length > 0 && data.data[data.data.length - 1].ChapterName != 'TOTAL') {
                        data.data.push(obj)
                    }
                    this.setState({
                        isLoading: false,
                        adminAreaSummaryList: data.data,

                        // pendingPaymentCount: data.data[0]['Count_Of_Payment']
                    })
                    // console.log("Data ------->", this.state.applicationSummaryList)

                    setTimeout(() => {
                        // console.log("Admin Data ------->", this.state.totalAgent)

                    }, 500);
                } else {
                    this.setState({ isLoading: false })
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    navToPaymentList() {
        this.props.navigate('/paymentlist')
    }

    onCountClick(chapterName, status) {
        console.log("Keyword------------>", `${chapterName.trim()}/${status}`)
        this.props.navigate('/exhibitorlist', { state: { fromDashBoard: true, keyword: `${chapterName.trim()}/${status}`, tab: "Area Summary" } })
    }

    record(list) {
        return (
            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
                <thead>
                    <tr>
                        <th className="text-center">Chapters</th>
                        <th className="text-center">Overall</th>
                        <th className="text-center">Available</th>
                        <th className="text-center">Buffer</th>
                        <th className="text-center">Applied</th>
                        <th className="text-center">Agent</th>
                        <th className="text-center">Balance</th>
                        <th className="text-center">Waiting</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.length > 0 ? <>{
                            list.map((data, i) => (
                                <tr key={Math.random()}>
                                    <td className="text-center">{i == list.length - 1 ? <strong>{data.ChapterName}</strong> : data.ChapterName}</td>
                                    <td className="text-center">{i == list.length - 1 ? <strong>{data.Overall}</strong> : data.Overall}</td>
                                    <td className="text-center">{i == list.length - 1 ? <strong>{data.Available}</strong> : data.Available}</td>
                                    <td className="text-center">{i == list.length - 1 ? <strong>{data.Buffer}</strong> : data.Buffer}</td>
                                    <td className="text-center" style={{ cursor: "pointer" }}
                                        onClick={(e) => this.onCountClick(data.ChapterName, "Applied")}
                                    >{i == list.length - 1 ? <strong>{data.Applied}</strong> : data.Applied}</td>
                                    <td className="text-center">{i == list.length - 1 ? <strong>{data.Agent}</strong> : data.Agent}</td>
                                    <td className="text-center">{i == list.length - 1 ? <strong>{data.Balance}</strong> : data.Balance}</td>
                                    <td className="text-center">{i == list.length - 1 ? <strong>{data.Waiting}</strong> : data.Waiting}</td>

                                </tr>
                            ))}
                            {/* {list[list.length - 1].ChapterName != 'TOTAL' &&
                                <tr key={Math.random()}>
                                    <td className="text-center"><strong>TOTAL</strong></td>
                                    <td className="text-center"><strong>{this.state.totalOverall}</strong></td>
                                    <td className="text-center"><strong>{this.state.totalAvailable}</strong></td>
                                    <td className="text-center"><strong>{this.state.totalBuffer}</strong></td>
                                    <td className="text-center"><strong>{this.state.totalApplied}</strong></td>
                                    <td className="text-center"><strong>{this.state.totalAgent}</strong></td>
                                    <td className="text-center"><strong>{this.state.totalBalance}</strong></td>
                                    <td className="text-center"><strong>{this.state.totalWaiting}</strong></td>

                                </tr>} */}
                        </> :
                            <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                    }
                </tbody>
            </table>)
    }

    render() {
        const { isLoading, pendingPaymentCount, adminAreaSummaryList } = this.state
        return (
            <>

                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <AdminDrawer />

                    {/*app-content open*/}
                    {/* <div className="app-content with-dr-container com-height"> */}
                    {/* <AdminDashboardTopmenu /> */}

                    <div className="row mt-3">
                        <div className="table-responsive mb-3">
                            {this.record(adminAreaSummaryList)}
                        </div>
                    </div>
                    {/* <div className='container-fluid'>
                            <div className="col-md-3 mt-5">
                                <div
                                    className="com-box com-box-1"
                                    style={{ borderBottom: "2px solid #ff9770", width: '400px' }}
                                >
                                    <h4>Today's Updates</h4>
                                    <div
                                        className="box-count"
                                        style={{
                                            height: '150px',
                                            padding: '25px',
                                            textAlign: "center",
                                            fontSize: '17px',
                                            fontWeight: '500',
                                            fontFamily: '"Poppins", sans-serif'
                                        }}
                                    >
                                        <p onClick={this.navToPaymentList}>Updated Payment Count <span style={{ float: 'right' }}>{pendingPaymentCount}</span></p>
                                        <p>New Application  <span style={{ float: 'right' }}>0</span></p>
                                        <p>Applications in Waiting  <span style={{ float: 'right' }}>0</span></p>

                                    </div>
                                </div>
                            </div>

                        </div> */}
                    {/* </div> */}
                </>

                {/* <Footerwithdr /> */}

            </>
        );
    }
}

export default withRouter(connect(null, { getAdminDashboardData_action, getAdminAreaSummaryList_action })(AreaSummary));
