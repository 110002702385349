import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action } from '../../../../../actions/fillCombo_action';
import { cityActionId, stallTypeActionId, staLeadRetrivalConnectionListActionId, countryActionId, exhibitionActionId, toastError, toastSuccess } from "../../../../../utils/constants";
import { emailValidator } from "../../../../../utils/Validator";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import Pagination from "../../../../../utils/pagination";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import { useNavigate } from "react-router-dom";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import { data } from "jquery";
import { getLeadRetrivalConnectionMasterList_action, insertUpdateLeadRetrivalConnectionMaster_action, deleteLeadRetrivalConnectionMaster_action } from "../../../../../actions/admin_module_action/master_action/manual_form_master_action/lead_retrival_connection_master_action";
import Footer from "../../../../../common_components/Footer";


class LeadRetrivalConnectionMaster extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isOpen: false,
            // currentPage: 1,
            LeadRetrivalConnectionList: [{
                'Name': '',
                'Address': '',
                'Phone': '',
                "Mobile": '',
                'Fax': '',
                'PrimaryEmail': '',
                'SecondaryEmail': '',
                'isNew': true,
            }],
            deleteID: '',
        }

        this.record = this.record.bind(this)
        this.onInput = this.onInput.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.deleteLeadRetrivalConnectionMaster = this.deleteLeadRetrivalConnectionMaster.bind(this)
        this.onDelete = this.onDelete.bind(this)
        this.getLeadRetrivalConnectionMasterList = this.getLeadRetrivalConnectionMasterList.bind(this)
    }

    componentDidMount() {
        this.getLeadRetrivalConnectionMasterList(null)
    }

    onInput(value, i, flag) {
        const { LeadRetrivalConnectionList } = this.state
        console.log(value, i, flag)
        if (flag == 'Name') {
            LeadRetrivalConnectionList[i].Name = value
        } else if (flag == 'Address') {
            LeadRetrivalConnectionList[i].Address = value
        } else if (flag == 'Mobile') {
            LeadRetrivalConnectionList[i].Mobile = value
        } else if (flag == 'Telephone') {
            LeadRetrivalConnectionList[i].Phone = value
        } else if (flag == 'Fax') {
            LeadRetrivalConnectionList[i].Fax = value
        } else if (flag == 'PrimaryEmail') {
            LeadRetrivalConnectionList[i].PrimaryEmail = value
        } else if (flag == 'SecondaryEmail') {
            LeadRetrivalConnectionList[i].SecondaryEmail = value
        }
        this.setState({ LeadRetrivalConnectionList: LeadRetrivalConnectionList })
    }

    record(list) {
        return (
            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
                <thead>
                    <tr>
                        <th className="text-center" style={{ width: '50px' }}>Sr no.</th>
                        <th className="text-center">Name<span className="text-red">*</span></th>
                        <th className="text-center">Address</th>
                        <th className="text-center">Mobile No.<span className="text-red">*</span></th>
                        <th className="text-center">Telephone</th>
                        <th className="text-center">Fax</th>
                        <th className="text-center">Primary Email<span className="text-red">*</span></th>
                        <th className="text-center">Secondary Email</th>
                        <th className="text-center" style={{ width: '50px' }}></th>

                    </tr>
                </thead>
                <tbody>
                    {
                        list.length > 0 ? <>{
                            list.map((data, i) => (
                                <tr key={i}>
                                    <td className="text-center" style={{ width: '50px' }}>{i + 1}</td>
                                    <td className="text-center">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            value={data.Name}
                                            onChange={(e) => this.onInput(e.target.value, i, 'Name')}
                                        />
                                    </td>
                                    <td className="text-center">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            value={data.Address}
                                            onChange={(e) => this.onInput(e.target.value, i, 'Address')}
                                        />
                                    </td>
                                    <td className="text-center">
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder=""
                                            value={data.Mobile}
                                            onChange={(e) => this.onInput(e.target.value, i, 'Mobile')}

                                        />
                                    </td>
                                    <td className="text-center">
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder=""
                                            value={data.Phone}
                                            onChange={(e) => this.onInput(e.target.value, i, 'Telephone')}

                                        />
                                    </td>
                                    <td className="text-center">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            value={data.Fax}
                                            onChange={(e) => this.onInput(e.target.value, i, 'Fax')}

                                        />
                                    </td>
                                    <td className="text-center">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            value={data.PrimaryEmail}
                                            onChange={(e) => this.onInput(e.target.value, i, 'PrimaryEmail')}

                                        />
                                    </td>
                                    <td className="text-center">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            value={data.SecondaryEmail}
                                            onChange={(e) => this.onInput(e.target.value, i, 'SecondaryEmail')}

                                        />
                                    </td>
                                    <td className="text-center">
                                        <button
                                            type="button"
                                            className="btn btn-outline-danger btn-sm"
                                            onClick={(e) => this.onDelete(data.LeadRetrievalContactPersonID, i, data.isNew)}
                                        >
                                            <i className="fa fa-trash action_btn_table" />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </> :
                            <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                    }
                </tbody>
            </table>)
    }

    getLeadRetrivalConnectionMasterList(status) {
        this.setState({ isLoading: true })
        const { LeadRetrivalConnectionList } = this.state
        this.props.getLeadRetrivalConnectionMasterList_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log(LeadRetrivalConnectionList)
                    status == 1 ? data.data.map((item) => { item.isNew = false }) : data.data.length > 0 && data.data.map((item) => { item.isNew = false })
                    status == 1 ? this.setState({ LeadRetrivalConnectionList: data.data }) : data.data.length > 0 && this.setState({ LeadRetrivalConnectionList: data.data })
                    this.setState({ isLoading: false, })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    handleAdd() {
        const { LeadRetrivalConnectionList } = this.state
        console.log("pp")
        var obj = {
            'Name': '',
            'Address': '',
            'Phone': '',
            "Mobile": '',
            'Fax': '',
            'PrimaryEmail': '',
            'SecondaryEmail': '',
            'isNew': true
        }
        LeadRetrivalConnectionList.push(obj)
        this.setState({ LeadRetrivalConnectionList: LeadRetrivalConnectionList })
    }

    handleSubmit() {
        const { LeadRetrivalConnectionList } = this.state
        var arr = [...LeadRetrivalConnectionList]
        var err = false
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].Name == '' || arr[i].Name == null) {
                err = 'Please enter mandatory fields'
                break
            }
            if (arr[i].Mobile == '' || arr[i].Mobile == null) {
                err = 'Please enter mandatory fields'
                break
            }
            if (arr[i].PrimaryEmail == '' || arr[i].PrimaryEmail == null) {
                err = 'Please enter mandatory fields'
                break
            } else {
                err = (emailValidator(arr[i].PrimaryEmail) == '') ? false : emailValidator(arr[i].PrimaryEmail)
                if (err) {
                    break
                }
            }
            if (arr[i].SecondaryEmail != '' && arr[i].SecondaryEmail != null) {
                err = (emailValidator(arr[i].SecondaryEmail) == '') ? false : emailValidator(arr[i].SecondaryEmail)
                if (err) {
                    break
                }
            }
        }
        if (!err) {
            this.setState({ isLoading: true })
            this.props.insertUpdateLeadRetrivalConnectionMaster_action(arr)
                .then((data) => {
                    if (data.error != 1) {
                        console.log(data.data)
                        this.getLeadRetrivalConnectionMasterList(1)
                        this.setState({ isLoading: false, })
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            toastError(err)
        }
    }

    deleteLeadRetrivalConnectionMaster(id) {
        this.setState({ isLoading: true })
        this.props.deleteLeadRetrivalConnectionMaster_action(id)
            .then((data) => {
                if (data.error != 1) {
                    console.log(data.data)
                    this.setState({ isLoading: false })
                    toastSuccess("deleted successfully")
                    setTimeout(() => {
                        this.getLeadRetrivalConnectionMasterList(1)
                    }, 200);
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
                this.setState({ isOpen: false })
            })
            .catch((err) => {
                console.log(err)
            })
    }

    onDelete(id, index, status) {
        const { LeadRetrivalConnectionList } = this.state
        console.log(status, index)
        if (status) {
            LeadRetrivalConnectionList.splice(index, 1)
            this.setState({ LeadRetrivalConnectionList: LeadRetrivalConnectionList })
        } else {
            this.setState({ deleteID: id })
            this.setState({ isOpen: true, })
        }
    }


    render() {
        const { isOpen, isLoading, LeadRetrivalConnectionList } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <div className="app-content with-dr-container com-height">
                        <AdminDrawer />
                        <div className="row">
                            <div className="step-name">
                                <p>Lead Retrieval Connection Master</p>
                            </div>
                        </div>
                        <div className="m-2">

                            <div className="table-responsive">
                                <div
                                    className="card-header p-3"
                                    style={{ paddingLeft: "7px !important" }}
                                >
                                    <h3 className="card-title" style={{ fontSize: 15 }}>
                                        Agency contact details
                                    </h3>
                                </div>
                                {this.record(LeadRetrivalConnectionList)}
                            </div>
                        </div>
                        <div className="btn-group m-2 save-can d-block" >
                            <div className="btn-group" role="group" aria-label="Basic example">
                                <button type="button" className="btn btn-primary rounded-0 d-block" style={{ marginLeft: '5px', cursor: 'pointer' }}
                                    onClick={this.handleAdd}
                                >Add more</button>
                            </div>
                        </div>
                        <div className="btn-group m-2 save-can d-block" >
                            <div className="btn-group" role="group" aria-label="Basic example" style={{ float: 'right' }} >
                                <button type="button" className="btn btn-success rounded-0 d-block" data-dismiss="modal" style={{ marginLeft: '5px' }}
                                    onClick={this.handleSubmit} disabled={LeadRetrivalConnectionList.length == 0}
                                >Submit</button>
                            </div>
                        </div>
                    </div>
                </>
                <Modal isOpen={isOpen} onRequestClose={() => this.setState({ isOpen: false })}>
                    <div className="modal-dialog" style={{ marginTop: '20%' }}>
                        <div className="modal-content confirm-logout">
                            <div class="modal-header text-right p-2">
                                <h5 class="modal-title modal_title_text text-danger">Do you really want to delete this record?</h5>

                                {/* <button type="button " class="close pop-close" data-dismiss="modal"
                                    onClick={() => this.setState({ isOpen: false })}
                                ><span aria-hidden="true" class="pop-cancel">&times;</span></button> */}
                            </div>
                            {/* <div class="modal-body">
                                <div class="row">
                                </div>
                            </div> */}
                            <div class="modal-footer p-1">
                                <div class="form-group text-right mod-fot">
                                    <button type="button" class="btn btn-del-pop"
                                        onClick={(e) => this.deleteLeadRetrivalConnectionMaster(this.state.deleteID)}
                                    >Yes</button>
                                    <button type="button" class="btn btn-can-pop" data-dismiss="modal" style={{ marginLeft: '5px' }}
                                        onClick={() => this.setState({ isOpen: false })}
                                    >No</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
                <Footer />
            </>)
    }

}
export default withRouter(connect(null, { fill_action, getLeadRetrivalConnectionMasterList_action, insertUpdateLeadRetrivalConnectionMaster_action, deleteLeadRetrivalConnectionMaster_action })(LeadRetrivalConnectionMaster));