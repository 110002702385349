import { AGENT_ENTRY_SAVE_ACTION_TYPE, AGENT_ENTRY_UPDATE_ACTION_TYPE, AGENT_LIST_ACTION_TYPE, GET_AGENT_DETAILS_ACTION_TYPE, VERIFY_AGENT_EMAIL_ACTION_TYPE } from "../../../utils/constants";


const initialState = [];

function agent_reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case AGENT_ENTRY_SAVE_ACTION_TYPE:
            return [...state, payload];
        case AGENT_ENTRY_UPDATE_ACTION_TYPE:
            return [...state, payload];
        case AGENT_LIST_ACTION_TYPE:
            return [...state, payload];
        case GET_AGENT_DETAILS_ACTION_TYPE:
            return [...state, payload];
        case VERIFY_AGENT_EMAIL_ACTION_TYPE:
            return [...state, payload];
        default:
            return state;
    }
}
export default agent_reducer;