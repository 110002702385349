import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { getDelegateMeetingMasterList_action, insertUpdateDelegateMeetingMaster_action, deleteDelegateMeetingMaster_action } from '../../../../../actions/admin_module_action/master_action/manual_form_master_action/delegate_meeting_master_action'
import { fill_action } from '../../../../../actions/fillCombo_action'
import { BASE_URL, countryActionId, toastError, toastSuccess, visaIdTypeActionId } from "../../../../../utils/constants";
import { emailValidator, mobileNoValidator } from "../../../../../utils/Validator";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import { data } from "jquery";
import Resizer from "react-image-file-resizer";
import { TimePicker } from '@hilla/react-components/TimePicker.js';
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";
import Passport_Front_1008_VIS_903747 from "../../../../../files/Passport_Front_1008_VIS_903747.jpeg"
import moment from "moment";

class DelegateMeetingMasterAU extends Component {

    // initialize constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            // fields
            PersonInchargeName: '',
            Designation: '',
            CountryID: '',
            CompanyProfile: '',
            IndustrySegment: '',
            Interest: '',
            PurposeOfVisit: '',
            CompanyName: '',
            MeetingDate: '',
            MeetingFromTime: '',
            MeetingToTime: '',
            // List
            DelegateMeetingContactList: [],
            DelegateMeetingTimeslotList: [],
            PersonInchargeNameErr: '',
            DesignationErr: '',
            CountryErr: '',
            CompanyProfileErr: '',
            CompanyNameErr: '',
            IndustrySegmentErr: '',
            InterestErr: '',
            PurposeOfVisitErr: '',
            status: '',
            MeetingDateErr: '',
            MeetingFromTimeErr: '',
            MeetingToTimeErr: '',
            countryList: [],
            index: 1
        }
        this.OnCancel = this.OnCancel.bind(this)
        this.inputValidateContact = this.inputValidateContact.bind(this)
        // this.getFillList = this.getFillList.bind(this)
        this.renderDelegateMeetingContactList = this.renderDelegateMeetingContactList.bind(this)
        // this.onFilePick = this.onFilePick.bind(this)
        // this.onPdfSelect = this.onPdfSelect.bind(this)
        // this.resizeFile = this.resizeFile.bind(this)
        this.handleAddMoreContact = this.handleAddMoreContact.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.ClearValue = this.ClearValue.bind(this)
        this.getFillList = this.getFillList.bind(this)
        this.inputValidateMeeting = this.inputValidateMeeting.bind(this)
        this.handleAddMoreTimeslot = this.handleAddMoreTimeslot.bind(this)
        this.renderDelegateMeetingTimeslotList = this.renderDelegateMeetingTimeslotList.bind(this)
        // this.onDocDelete = this.onDocDelete.bind(this)
        // this.handleDelete = this.handleDelete.bind(this)
    }

    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        const { } = this.state;
        this.setState({ isLoading: true });
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value)
            .then((data) => {
                if (data.error != 1) {
                    // country list
                    if (actionID === countryActionId) {
                        console.log("country data", data.data);
                        this.setState({
                            countryList: data.data,
                            isLoading: false,
                        });
                    }
                }
            })
    }

    componentDidMount() {
        this.setState({
            status: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.status != " " ? this.props.location.state.status : '',
        })
        this.getFillList(countryActionId, '', '', '', '', '')
        this.getDelegateMeetingContactList()
    }

    // On cancel Click
    OnCancel() {
        this.props.navigate('/DelegateMeetingMasterList')
    }

    getDelegateMeetingContactList(status) {
        this.setState({ isLoading: true })
        this.props.getDelegateMeetingMasterList_action()
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ DelegateMeetingContactList: data.data[1] })
                    this.setState({ DelegateMeetingTimeslotList: data.data[0] })
                    this.setState({ isLoading: false })
                    console.log(data.data[1], data.data[0])
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // Input Fields Validation
    inputValidateContact() {
        const { PersonInchargeName,
            Designation,
            CountryID,
            CompanyProfile,
            IndustrySegment,
            CompanyName,
            Interest,
            PurposeOfVisit,
            MeetingDate,
            MeetingFromTime,
            MeetingToTime,
            DelegateMeetingContactList,
            DelegateMeetingTimeslotList,
            PersonInchargeNameErr,
            DesignationErr,
            CountryErr,
            CompanyProfileErr,
            IndustrySegmentErr,
            InterestErr,
            PurposeOfVisitErr,
            MeetingDateErr,
            MeetingFromTimeErr,
            MeetingToTimeErr } = this.state
        if (PersonInchargeName.trim() == "") {
            this.setState({ PersonInchargeNameErr: "Please Enter Person Incharge Name" })
        }
        if (Designation.trim() == "") {
            this.setState({ DesignationErr: "Please Enter Designation Name" })
        }
        if (CountryID == "") {
            this.setState({ CountryErr: "Please Select Country" })
        }
        if (CompanyProfile == "") {
            this.setState({ CompanyProfileErr: "Please Enter Company Profile" })
        }
        if (CompanyName == "") {
            this.setState({ CompanyNameErr: "Please Enter Company Name" })
        }
        if (IndustrySegment.trim() == "") {
            this.setState({ IndustrySegmentErr: "Please Enter Industry segment" })
        }
        if (PurposeOfVisit == "") {
            this.setState({ PurposeOfVisitErr: "Please Enter Purpose of Visit" })
        }
        if (Interest.trim() == "") {
            this.setState({ InterestErr: "Please Enter Interest" })
        }
    }

    inputValidateMeeting() {
        const { PersonInchargeName,
            Designation,
            CountryID,
            CompanyProfile,
            IndustrySegment,
            CompanyName,
            Interest,
            PurposeOfVisit,
            MeetingDate,
            MeetingFromTime,
            MeetingToTime,
            DelegateMeetingContactList,
            DelegateMeetingTimeslotList,
            PersonInchargeNameErr,
            DesignationErr,
            CountryErr,
            CompanyProfileErr,
            IndustrySegmentErr,
            InterestErr,
            PurposeOfVisitErr,
            MeetingDateErr,
            MeetingFromTimeErr,
            MeetingToTimeErr } = this.state
        const timePattern = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/
        return new Promise((resolve, reject) => {
            var err = false
            if (MeetingDate == "") {
                this.setState({ MeetingDateErr: "Please Select Meeting Date" })
                err = true
            }
            // console.log('!timePattern.test(MeetingFromTime)', !timePattern.test(MeetingFromTime))
            if (MeetingFromTime == "") {
                this.setState({ MeetingFromTimeErr: "Please Enter Meeting From Time" })
                err = true
            }
            if (MeetingFromTime == "Invalid date") {
                this.setState({ MeetingFromTimeErr: "Please Enter Proper From Time" })
                err = true
            }
            // if (timePattern.test(MeetingFromTime)) {
            //     this.setState({ MeetingFromTimeErr: "Please Enter Proper From Time", })
            //     err = true
            // }
            if (MeetingToTime == "") {
                this.setState({ MeetingToTimeErr: "Please Enter Meeting To Time" })
                err = true
            }
            if (MeetingToTime == "Invalid date") {
                this.setState({ MeetingToTimeErr: "Please Enter Proper To Time" })
                err = true
            }
            // if (timePattern.test(MeetingToTime)) {
            //     this.setState({ MeetingToTimeErr: "Please Enter Proper Meeting To Time", })
            //     err = true
            // }
            !err ? resolve(true) : reject(true)
        })
    }

    renderDelegateMeetingContactList() {
        const { DelegateMeetingContactList, countryList } = this.state
        return (
            <ul class="list-group " style={{ position: 'relative' }}>
                {
                    DelegateMeetingContactList.length > 0 && DelegateMeetingContactList.map((data, index) => (
                        data.isShow &&
                        <li class="list-group-item" key={Math.random()}>
                            <div className="added-more p-3 rounded-2">
                                <h5 style={{ color: "#0056ff" }}>Added Delegate Meeting Master {data.index}</h5>
                                <h6 >Agency Contact </h6>
                                <div className="row mt-4">
                                    <div className="col-md-3">
                                        <h6>Person in Charge: {data.PersonInchargeName}</h6>
                                    </div>
                                    <div className="col-md-3">
                                        <h6>Designation: {data.Designation}</h6>
                                    </div>
                                    <div className="col-md-3">
                                        <h6>Company Name: {data.CompanyName}</h6>
                                    </div>
                                    <div className="col-md-3">
                                        <h6>Country: {countryList.map(item => (
                                            item.Code == data.CountryID && item.Description
                                        ))}</h6>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-12">
                                        <h6>Company Profile: {data.CompanyProfile}</h6>
                                    </div>
                                    <div className="col-md-12">
                                        <h6>Industry Segment: {data.IndustrySegment}</h6>
                                    </div>
                                    <div className="col-md-12">
                                        <h6>Interest: {data.Interest}</h6>
                                    </div>
                                    <div className="col-md-12">
                                        <h6>Purpose of Visit: {data.PurposeOfVisit}</h6>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))
                }
            </ul>
        )
    }

    renderDelegateMeetingTimeslotList() {
        const { DelegateMeetingTimeslotList, countryList } = this.state
        return (
            <ul class="list-group " style={{ position: 'relative', }}>
                {
                    DelegateMeetingTimeslotList.length > 0 && DelegateMeetingTimeslotList.map((data, index) => (
                        data.isShow &&
                        <li class="list-group-item" key={Math.random()}>
                            <div className="added-more p-3 rounded-2">
                                <h5 style={{ color: "#0056ff" }}>Added Delegate Meeting Master {data.index}</h5>
                                <h6 >Meeting</h6>
                                <div className="row mt-4">
                                    <div className="col-md-3">
                                        <h6>Meeting Date: {moment(data.MeetingDate).format('DD-MM-YYYY')}</h6>
                                    </div>
                                    <div className="col-md-3">
                                        <h6>Meeting From Time: {moment(data.MeetingFromTime).format('HH:mm')}</h6>
                                    </div>
                                    <div className="col-md-3">
                                        <h6>Meeting To Time: {moment(data.MeetingToTime).format('HH:mm')}</h6>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))
                }
            </ul>
        )
    }

    handleAddMoreContact() {
        const {
            PersonInchargeName, Designation, CountryID, CompanyProfile, CompanyName, IndustrySegment, PurposeOfVisit, CompanyNameErr, Interest, MeetingDate, MeetingFromTime, MeetingToTime, DelegateMeetingContactList, DelegateMeetingTimeslotList, PersonInchargeNameErr, DesignationErr, CountryErr, CompanyProfileErr, IndustrySegmentErr, InterestErr, PurposeOfVisitErr, index, MeetingDateErr, MeetingFromTimeErr, MeetingToTimeErr } = this.state
        this.inputValidateContact()
        if (PersonInchargeName != '' && Designation != '' && CompanyProfile != '' && CompanyName != '' && CountryID != '' && IndustrySegment != '' && Interest != '' && PurposeOfVisit != '') {
            if (PersonInchargeNameErr == '' && DesignationErr == '' && CountryErr == '' && CompanyProfileErr == '' && CompanyNameErr == '' && IndustrySegmentErr == '' && InterestErr == '' &&
                PurposeOfVisitErr == '') {
                DelegateMeetingContactList.push({
                    'PersonInchargeName': PersonInchargeName,
                    'Designation': Designation,
                    'CountryID': CountryID,
                    'CompanyProfile': CompanyProfile,
                    'CompanyName': CompanyName,
                    'IndustrySegment': IndustrySegment,
                    'Interest': Interest,
                    'PurposeOfVisit': PurposeOfVisit,
                    'isShow': true,
                    'index': index
                })
                setTimeout(() => { this.ClearValue() }, 200);
            }
            else {
                toastError('Please enter proper fields')
            }
        }
        else {
            toastError('Please enter mandatory fields')
        }
        // console.log(this.state.DelegateMeetingList)
    }

    handleAddMoreTimeslot() {
        const {
            PersonInchargeName, Designation, CountryID, CompanyProfile, CompanyName, IndustrySegment, PurposeOfVisit, CompanyNameErr, Interest, MeetingDate, MeetingFromTime, MeetingToTime, DelegateMeetingContactList, DelegateMeetingTimeslotList, PersonInchargeNameErr, DesignationErr, CountryErr, CompanyProfileErr, IndustrySegmentErr, InterestErr, PurposeOfVisitErr, index, MeetingDateErr, MeetingFromTimeErr, MeetingToTimeErr } = this.state
        this.inputValidateMeeting().then(res => {
            console.log('/////', MeetingDate, MeetingFromTime, MeetingToTime,)
            if (MeetingDate != '' && MeetingFromTime != '' && MeetingToTime != '') {
                if (MeetingDateErr == '' && MeetingFromTimeErr == '' && MeetingToTimeErr == '') {
                    DelegateMeetingTimeslotList.push({
                        'MeetingDate': moment(MeetingDate).format('YYYY-MM-DD'),
                        'MeetingFromTime': moment(new Date(`${moment(MeetingDate).format('YYYY-MM-DD')} ${MeetingFromTime}`)).format(),
                        'MeetingToTime': moment(new Date(`${moment(MeetingDate).format('YYYY-MM-DD')} ${MeetingToTime}`)).format(),
                        'isShow': true,
                        'index': index
                    })
                    setTimeout(() => { this.ClearValue() }, 200);
                    console.log(moment(new Date(`${moment(MeetingDate).format('YYYY-MM-DD')} ${MeetingToTime}`)).format()
                    )
                }
                else {
                    toastError('Please enter proper fields')
                }
            }
            else {
                toastError('Please enter mandatory fields')
            }
            // console.log(this.state.DelegateMeetingList)
        }).catch(err => {
            toastError('Please enter proper fields')
        })
    }

    handleSave() {
        const { DelegateMeetingContactList, DelegateMeetingTimeslotList } = this.state
        this.setState({ isLoading: true })
        this.props.insertUpdateDelegateMeetingMaster_action(DelegateMeetingContactList, DelegateMeetingTimeslotList)
            .then((data) => {
                if (data.error != 1) {
                    toastSuccess("Record Added Successfully")
                    setTimeout(() => {
                        this.setState({ isLoading: false })
                        this.props.navigate('/DelegateMeetingMasterList')
                    }, 300);
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    ClearValue() {
        if (this.state.status == 1) {
            this.setState({
                PersonInchargeName: '',
                Designation: '',
                CountryID: '',
                CompanyProfile: '',
                CompanyName: '',
                IndustrySegment: '',
                Interest: '',
                PurposeOfVisit: '',
                MeetingDate: '',
                MeetingFromTime: '',
                MeetingToTime: '',
                index: this.state.index + 1
            })
        } else if (this.state.status == 2) {
            this.setState({
                MeetingDate: '',
                MeetingFromTime: '',
                MeetingToTime: '',
                index: this.state.index + 1
            })
        }
    }

    render() {
        const { isLoading, PersonInchargeName, status, Designation, CountryID, CompanyName, CompanyProfile, PurposeOfVisit, CompanyNameErr, IndustrySegment, Interest, MeetingDate, MeetingFromTime, MeetingToTime, DelegateMeetingContactList, DelegateMeetingTimeslotList, PersonInchargeNameErr, DesignationErr, CountryErr, CompanyProfileErr, IndustrySegmentErr, InterestErr, PurposeOfVisitErr, countryList, MeetingDateErr, MeetingFromTimeErr, MeetingToTimeErr
        } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <div className="app-content with-dr-container com-height">
                        <AdminDrawer />
                        <div className="row">
                            <div className="step-name">
                                <p>Delegate Meeting Master</p>
                            </div>
                        </div>
                        <div className="m-2">
                            {status == 1 && <div className="">

                                <div className="">
                                    <div
                                        className="card-header p-3"
                                        style={{ paddingLeft: "7px !important" }}
                                    >
                                        <h3 className="card-title" style={{ fontSize: 15 }}>
                                            Agency contact
                                        </h3>
                                    </div>
                                    <div className="card-body p-2 mt-1">
                                        <div className="row">
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    Person Incharge<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={PersonInchargeName}
                                                    onChange={(e) => this.setState({ PersonInchargeName: e.target.value, PersonInchargeNameErr: '' })}
                                                />

                                                {<span className='text-danger text-start text-left d-block mt-1'>{PersonInchargeNameErr}</span>}
                                            </div>
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    Designation<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={Designation}
                                                    onChange={(e) => this.setState({ Designation: e.target.value, DesignationErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{DesignationErr}</span>}
                                            </div>
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    Company Name<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={CompanyName}
                                                    onChange={(e) => this.setState({ CompanyName: e.target.value, CompanyNameErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{CompanyNameErr}</span>}
                                            </div>
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    Country<span className="text-red">*</span>
                                                </label>
                                                <select className="form-control select2 select-hidden-accessible pl-0"
                                                    style={{ width: "100%" }}
                                                    value={CountryID}
                                                    onChange={e => this.setState({ CountryID: e.target.value, CountryErr: '' })}>
                                                    <option value={''}>Select</option>
                                                    {
                                                        countryList.length > 0 && countryList.map((item) => (
                                                            <option key={item.Code} value={item.Code}>{item.Description}</option>
                                                        ))
                                                    }
                                                </select>
                                                {<span className='text-danger text-start text-left d-block mt-1'>{CountryErr}</span>}

                                            </div>
                                            <div className="col-xl-12 mt-2 mb-2">
                                                <label htmlFor="validationCustom03">
                                                    Company Profile<span className="text-red">*</span>
                                                </label>
                                                <textarea type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    value={CompanyProfile}
                                                    onChange={(e) => this.setState({ CompanyProfile: e.target.value, CompanyProfileErr: '' })}>
                                                </textarea>
                                                {<span className='text-danger text-start text-left d-block mt-1'>{CompanyProfileErr}</span>}
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-xl-12 mb-2">
                                                <label htmlFor="validationCustom03">
                                                    Industry Segment<span className="text-red">*</span>
                                                </label>
                                                <textarea type="text"
                                                    className="form-control"
                                                    rows={1}
                                                    id="validationCustom03"
                                                    value={IndustrySegment}
                                                    onChange={(e) => this.setState({ IndustrySegment: e.target.value, IndustrySegmentErr: '' })}
                                                >
                                                </textarea>
                                                {<span className='text-danger text-start text-left d-block mt-1'>{IndustrySegmentErr}</span>}
                                            </div>
                                            <div className="col-xl-12 mb-2">
                                                <label htmlFor="validationCustom03">Interest<span className="text-red">*</span></label>
                                                <textarea type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    rows={1}
                                                    value={Interest}
                                                    onChange={(e) => this.setState({ Interest: e.target.value, InterestErr: '' })}
                                                >
                                                </textarea>
                                                {<span className='text-danger text-start text-left d-block mt-1'>{InterestErr}</span>}
                                            </div>
                                            <div className="col-xl-12 mb-2">
                                                <label htmlFor="validationCustom03">Purpose Of Visit<span className="text-red">*</span></label>
                                                <textarea type="text"
                                                    className="form-control"
                                                    rows={2}
                                                    id="validationCustom03"
                                                    value={PurposeOfVisit}
                                                    onChange={(e) => this.setState({ PurposeOfVisit: e.target.value, PurposeOfVisitErr: '' })}
                                                >
                                                </textarea>
                                                {<span className='text-danger text-start text-left d-block mt-1'>{PurposeOfVisitErr}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="btn-group mt-2 save-can d-block">
                                    <div className="btn-group mt-2" role="group" aria-label="Basic example">
                                        <button
                                            style={{ marginRight: 10 }}
                                            type="button"
                                            className="btn btn-primary rounded-0 d-block"
                                            // style={{ }}
                                            onClick={() => this.handleAddMoreContact()}
                                        >
                                            {this.state.index == 1 ? 'Add' : 'Add More'}
                                        </button>
                                        {/* <button type="button" class="btn btn-primary rounded-0">Payment Listing Page</button> */}
                                    </div>

                                    <hr />
                                    {this.renderDelegateMeetingContactList()}

                                </div>
                            </div>
                            }
                            {status == 2 && <div className="">
                                {console.log(MeetingFromTime, MeetingToTime)}
                                <div className="">
                                    <div
                                        className="card-header p-3"
                                        style={{ paddingLeft: "7px !important" }}
                                    >
                                        <h3 className="card-title" style={{ fontSize: 15 }}>
                                            Delegate Meeting
                                        </h3>
                                    </div>
                                    <div className="card-body p-2 mt-1">
                                        <div className="row">
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    Meeting Date<span className="text-red">*</span>
                                                </label>
                                                <DatePicker
                                                    style={{ zIndex: '99999' }}
                                                    selected={MeetingDate}
                                                    dateFormat={"dd/MM/yyyy"}
                                                    className="form-control"
                                                    onChange={(date) => this.setState({ MeetingDate: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())), MeetingDateErr: "" })}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                // placeholderText="Date"
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{MeetingDateErr}</span>}
                                            </div>
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    Meeting From Time<span className="text-red">*</span>
                                                </label>
                                                <TimePicker
                                                    className='time-picker'
                                                    max={MeetingToTime != '' ? MeetingToTime : null}
                                                    style={{ width: '100%' }}
                                                    value={MeetingFromTime != undefined ? MeetingFromTime.replace(/\s+(?:AM|PM)/, "") : ''}
                                                    onChange={(e) => this.setState({ MeetingFromTime: ((e.target.value)) })}
                                                    onInput={() => this.setState({ MeetingFromTimeErr: '' })}
                                                    step={60 * 15} />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{MeetingFromTimeErr}</span>}
                                            </div>
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    Meeting To Time<span className="text-red">*</span>
                                                </label>
                                                <TimePicker
                                                    className='time-picker'
                                                    style={{ width: '100%' }}
                                                    min={MeetingFromTime != '' ? MeetingFromTime : null}
                                                    value={MeetingToTime != undefined ? MeetingToTime.replace(/\s+(?:AM|PM)/, "") : ''}
                                                    onChange={(e) => this.setState({ MeetingToTime: (e.target.value) })}
                                                    onInput={() => this.setState({ MeetingToTimeErr: '' })}
                                                    step={60 * 15} />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{MeetingToTimeErr}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="btn-group mt-2 save-can d-block">
                                    <div className="btn-group mt-2" role="group" aria-label="Basic example">
                                        <button
                                            style={{ marginRight: 10 }}
                                            type="button"
                                            className="btn btn-primary rounded-0 d-block"
                                            // style={{ }}
                                            onClick={() => this.handleAddMoreTimeslot()}
                                        >
                                            {this.state.index == 1 ? 'Add' : 'Add More'}
                                        </button>
                                        {/* <button type="button" class="btn btn-primary rounded-0">Payment Listing Page</button> */}
                                    </div>

                                    <hr />
                                    {this.renderDelegateMeetingTimeslotList()}

                                </div>
                            </div>
                            }
                            <div className="row">
                                <div className="btn-group mt-2 save-can d-block" role="group" style={{ float: 'right' }} aria-label="Basic example">
                                    <button
                                        type="button"
                                        className="btn btn-success rounded-0"
                                        style={{ marginRight: '10px', width: '100px' }}
                                        onClick={() => this.handleSave()}
                                        disabled={this.state.index == 1}
                                    >
                                        Save
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-warning rounded-0"
                                        style={{ marginRight: '10px', width: '100px' }}
                                        onClick={() => this.OnCancel()}
                                    >
                                        Back
                                    </button>

                                    {/* <button type="button" class="btn btn-primary rounded-0">Payment Listing Page</button> */}
                                </div>
                            </div>

                        </div>
                    </div>
                </>
            </>
        )
    }
}
export default withRouter(connect(null, { getDelegateMeetingMasterList_action, insertUpdateDelegateMeetingMaster_action, deleteDelegateMeetingMaster_action, fill_action })(DelegateMeetingMasterAU))