import { axiosGet } from "../../../react_axios/Axios";
import { GET_PAYMENT_LIST_ACTION_TYPE, getPaymentListUrl, headers } from "../../../utils/constants";



export const getPaymentList_action = () => async (dispatch) => {

    try {
        const params = {
            Userid: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            AccountID: JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
        }
        console.log("getPaymentList_action Action------->", params)

        const res = await axiosGet(getPaymentListUrl, params, headers)

        dispatch({
            type: GET_PAYMENT_LIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}