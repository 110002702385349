import { axiosGet, axiosPost } from "../../../../react_axios/Axios";
import { chapterAllocationUrl, CHAPTER_ALLOCATION_MASTER, headers } from "../../../../utils/constants";


export const chapterAllocation_action = (ExihibitionID, chapterAllocationList) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExihibitionID,
            chapterAllocationList: chapterAllocationList
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosPost(chapterAllocationUrl, params, headers)

        dispatch({
            type: CHAPTER_ALLOCATION_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}