import { Component } from "react";
import { ToastContainer } from "react-toastify";
import Loader from "../../../utils/Loader";
import ExhibitorHeader from "../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../common_components/exhibitor_module_drawer";
import ExhibitorTopMenu from "../../../common_components/exhibitor_module_topmenu";
import { getExhibitionTimeline_action } from "../../../actions/exhibitor_module_actions/exhibitionTimeline_action/exhibitionTimeline_action"
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import { toastError, toastSuccess } from "../../../utils/constants"
import AgentTopMenu from "../../../common_components/agent_module_topmenu";
import AgentDrawer from "../../../common_components/agent_module_drawer";
import AgentHeader from "../../../common_components/agent_module_header";

class ImportantDates extends Component {

    // Initialize Constructor
    constructor(props) {
        super(props)
        //States
        this.state = {
            isLoading: false,
            timelineList: [],
        }
        this.getExhibitionTimeline = this.getExhibitionTimeline.bind(this);
    }

    componentDidMount() {
        console.log(JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID)

        this.getExhibitionTimeline()
    }

    // Get Exhibition Timeline
    getExhibitionTimeline() {
        this.setState({ isLoading: true })
        this.props.getExhibitionTimeline_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log("Get getExhibitionTimeline", data.data)
                    this.setState({ timelineList: data.data, isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            }).catch((err) => {
                console.log(err)
            })
    }

    render() {
        const { isLoading, timelineList } = this.state

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                {sessionStorage.getItem('loginDataSS') && JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 8 ?
                    sessionStorage.getItem('IsAgentDirectLogin') == 'true' ?
                    sessionStorage.getItem('loginDataSS') && JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 4 ?
                            <AgentHeader /> : <ExhibitorHeader /> :
                            sessionStorage.getItem('indirectAgentloginDataSS') && JSON.parse(sessionStorage.getItem('indirectAgentloginDataSS')).RoleID == 4
                            ? <AgentHeader /> : <ExhibitorHeader />
                    : <ExhibitorHeader />}
                {sessionStorage.getItem('loginDataSS') && JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 8 ?
                    sessionStorage.getItem('IsAgentDirectLogin') == 'true' ?
                    sessionStorage.getItem('loginDataSS') && JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 4 ?
                            <AgentDrawer /> : <ExhibitorDrawer /> :
                            sessionStorage.getItem('indirectAgentloginDataSS') && JSON.parse(sessionStorage.getItem('indirectAgentloginDataSS')).RoleID == 4
                            ? <AgentDrawer /> : <ExhibitorDrawer />
                    : <ExhibitorDrawer />
                }
                {/*app-content open*/}
                <div className="page">
                    <div className="page-main">
                        <div className="main-content with-dr-container">
                            {/* <div className="container-fluid">
                                <div className="row">
                                    <div className="step-name">
                                <p>Exhibitor Dashboard</p>
                            </div>
                                    <ExhibitorTopMenu />
                                </div>
                            </div> */}
                            <div className=" width-dr-nav">
                                <div className="row">
                                    {sessionStorage.getItem('loginDataSS') && JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 8 ?
                                        sessionStorage.getItem('IsAgentDirectLogin') == 'true' ?
                                        sessionStorage.getItem('loginDataSS') && JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 4 ?
                                                <AgentTopMenu /> : <ExhibitorTopMenu /> :
                                                sessionStorage.getItem('indirectAgentloginDataSS') && JSON.parse(sessionStorage.getItem('indirectAgentloginDataSS')).RoleID == 4
                                                ? <AgentTopMenu /> : <ExhibitorTopMenu />
                                        : <ExhibitorTopMenu />
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="card-header p-3 m-2 mt-0" >
                                    <h3 className="card-title">{sessionStorage.getItem("sessionExhibitionName")} (IMPORTANT DATES)</h3>
                                </div>
                            </div>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-xl-12 p-0">
                                        <div className="card rounded-0 pt-1">

                                            <div className="card-body p-0">
                                                <div className="table-responsive">
                                                    <table className="table border text-nowrap text-md-nowrap table-striped mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th>TIMELINES&nbsp;</th>
                                                                <th>DESCRIPTION</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {timelineList.length > 0 && timelineList.map(data => (
                                                                <tr key={Math.random()}>
                                                                    <td>{data.Date.replaceAll('/', '.')}</td>
                                                                    <td colSpan={data.Description.split(' . ').length}>{data.Description.split(' . ').map(item => (
                                                                        <tr className="text-start">{item}</tr>
                                                                    ))}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <p className="mt-2 text-danger">
                                                    <strong>* Note: </strong> Above dates are subject to change
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </>

        )
    }
}

export default withRouter(connect(null, { getExhibitionTimeline_action })(ImportantDates));
