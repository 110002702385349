import {GET_KEYWORDS_INFORMATION_LIST__ACTION_TYPE, DELETE_KEYWORDS_INFORMATION_LIST__ACTION_TYPE, INSERT_AND_UPDATE_KEYWORDS_INFORMATION_LIST__ACTION_TYPE  } from "../../../../../utils/constants";


const initialState = [];

function keywordsAndInformationReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_KEYWORDS_INFORMATION_LIST__ACTION_TYPE:
      return [...state, payload];
      case INSERT_AND_UPDATE_KEYWORDS_INFORMATION_LIST__ACTION_TYPE:
        return [...state, payload];
      case DELETE_KEYWORDS_INFORMATION_LIST__ACTION_TYPE:
        return [...state, payload];
    default:
      return state;
  }
};

export default keywordsAndInformationReducer;