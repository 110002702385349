import { axiosPost, axiosGet } from "../../../react_axios/Axios"
import {
    platform, headers, verifyPartnerCodeUrl, VERIFY_PARTNER_CODE, sendPartnerRegistrationFormUrl, getPartnerStaticDataUrl, GET_PARTNER_STATIC_DATA, PARTNER_REGISTRATION_FORM,
} from "../../../utils/constants"


export const verifyPartnerCode_action = (Code) => async (dispatch) => {
    try {
        const mapdata = {
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            Code: Code,
        }

        const res = await axiosGet(verifyPartnerCodeUrl, mapdata, headers);
        dispatch({
            type: VERIFY_PARTNER_CODE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const getPartnerStaticData_action = (ExhibitionID, Code) => async (dispatch) => {
    try {
        const mapdata = {
            ExhibitionID: ExhibitionID,
            Code: Code,
        }

        const res = await axiosGet(getPartnerStaticDataUrl, mapdata, headers);
        dispatch({
            type: GET_PARTNER_STATIC_DATA,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const sendPartnerRegistrationform_action = (ExhibitionID,
    PartnerTypeID,
    Title_PersonIncharge,
    PersonIncharge,
    LastNamePersonIncharge,
    Designation,
    CompanyName,
    FasciaName,
    Address1,
    Address2,
    Pin,
    City,
    State,
    CountryID,
    MobileNo,
    AlternateMobileNo,
    Telephone,
    PrimaryEmail,
    SecondaryEmail,
    Website,
    logo,
    isAgree,
    partnerDeliverableList,
    partnerBearerList,
    partnerRegionList,
    partnerTotalCirculationList,
    partnerOverseasCirculationList,
    partnerOnlinePresenceList,
    chapterList,
    logoImg,
    logoImgType) => async (dispatch) => {
        try {
            const mapdata = {
                ExhibitionID: ExhibitionID,
                PartnerTypeID: PartnerTypeID,
                Title_PersonIncharge: Title_PersonIncharge,
                PersonIncharge: PersonIncharge,
                LastNamePersonIncharge: LastNamePersonIncharge,
                Designation: Designation,
                CompanyName: CompanyName,
                FasciaName: FasciaName,
                Address1: Address1,
                Address2: Address2,
                Pin: Pin,
                City: City,
                State: State,
                CountryID: CountryID,
                MobileNo: MobileNo,
                AlternateMobileNo: AlternateMobileNo,
                Telephone: Telephone,
                PrimaryEmail: PrimaryEmail,
                SecondaryEmail: SecondaryEmail,
                Website: Website,
                logo: logo,
                isAgree: isAgree,
                partnerDeliverableList: partnerDeliverableList,
                partnerBearerList: partnerBearerList,
                partnerRegionList: partnerRegionList,
                partnerTotalCirculationList: partnerTotalCirculationList,
                partnerOverseasCirculationList: partnerOverseasCirculationList,
                partnerOnlinePresenceList: partnerOnlinePresenceList,
                chapterList: chapterList,
                logoImg: logoImg,
                logoImgType: logoImgType
            }

            const res = await axiosPost(sendPartnerRegistrationFormUrl, mapdata, headers);
            dispatch({
                type: PARTNER_REGISTRATION_FORM,
                payload: res,
            });

            return Promise.resolve(res);
        } catch (err) {
            return Promise.reject(err);
        }
    };