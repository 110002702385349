import { GET_HOUSE_KEEPING_DEFAULT_DETAILS_ACTION_TYPE, GET_HOUSE_KEEPING_DETAILS_ACTION_TYPE,INSERT_UPDATE_HOUSE_KEEPING_ACTION_TYPE } from "../../../../../utils/constants";


const initialState = [];

function HouseKeepingReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_HOUSE_KEEPING_DEFAULT_DETAILS_ACTION_TYPE:
      return [...state, payload];
    case GET_HOUSE_KEEPING_DETAILS_ACTION_TYPE:
      return [...state, payload];
    case INSERT_UPDATE_HOUSE_KEEPING_ACTION_TYPE:
      return [...state, payload];
    default:
      return state;
  }
};

export default HouseKeepingReducer;