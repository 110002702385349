import { Component } from "react";
import Loader from "../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action } from '../../../../actions/fillCombo_action';
import { cityActionId, stallTypeActionId, stallListActionId, countryActionId, exhibitionActionId, toastError, toastSuccess } from "../../../../utils/constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import Pagination from "../../../../utils/pagination";
import AdminDrawer from "../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../common_components/header_with_logo";
import { useNavigate } from "react-router-dom";
import ExhibitorDrawer from "../../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../../common_components/exhibitor_module_header";
import { data } from "jquery";
import { deleteHallMaster_action } from "../../../../actions/admin_module_action/master_action/hall_master_action";
import { getBankDetailsMasterList_action, deleteBankDetailsMaster_action } from "../../../../actions/admin_module_action/master_action/bank_details_action";
import Footer from "../../../../common_components/Footer";


class BankDetailsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isOpen: false,
            currentPage: 1,
            bankDetailsList: [],
            deleteID: '',
            NAMEOFTHEBENEFICIARY: '',
            CURRENTACCOUNTNO: '',
            BRANCHNAME: '',
            BANKNAME: '',
        }

        this.record = this.record.bind(this)
        this.clearSearch = this.clearSearch.bind(this)
        this.deleteBankDetailsMaster = this.deleteBankDetailsMaster.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.onDelete = this.onDelete.bind(this)
        this.getBankDetailsMasterList = this.getBankDetailsMasterList.bind(this)
        this.updateItem = this.updateItem.bind(this)
    }

    componentDidMount() {

        this.getBankDetailsMasterList()
    }

    record(list) {
        return (
            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
                <thead>
                    <tr>
                        <th className="text-center" style={{ width: '50px' }}>Sr no.</th>
                        <th className="text-center">NAME OF THE BENEFICIARY</th>
                        <th className="text-center">       CURRENT ACCOUNT NO</th>
                        <th className="text-center">     BANK NAME</th>
                        <th className="text-center">     BRANCH NAME</th>
                        <th className="text-center">           SWIFT CODE</th>
                        <th className="text-center">        IFS Code</th>
                        <th className="text-center" style={{ width: '50px' }}></th>
                        <th className="text-center" style={{ width: '50px' }}></th>

                    </tr>
                </thead>
                <tbody>
                    {
                        list.length > 0 ? <>{
                            list.map((data, i) => (
                                <tr key={Math.random()}>
                                    <td className="text-center" style={{ width: '50px' }}>{i + 1}</td>
                                    <td className="text-center">{data.BeneficiaryName}</td>
                                    <td className="text-center">{data.CurrentAccountNo}</td>
                                    <td className="text-center">{data.BankName}</td>
                                    <td className="text-center">{data.BranchName}</td>
                                    <td className="text-center">{data.SwiftCode}</td>
                                    <td className="text-center">{data.IFSCode}</td>

                                    <td className="text-center">
                                        <button
                                            type="button"
                                            className="btn btn-outline-primary btn-sm"
                                            onClick={(e) => this.handleUpdate(data.BankDetailID)}
                                        >
                                            Update
                                        </button>
                                    </td>
                                    <td className="text-center">
                                        <button
                                            type="button"
                                            className="btn btn-outline-danger btn-sm"
                                            onClick={(e) => this.onDelete(data.BankDetailID)}
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </> :
                            <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                    }
                </tbody>
            </table>)
    }

    getBankDetailsMasterList() {
        this.setState({ isLoading: true })
        const { hallName, exhibitionID, NAMEOFTHEBENEFICIARY, CURRENTACCOUNTNO, BANKNAME, BRANCHNAME } = this.state
        this.props.getBankDetailsMasterList_action(null, null, NAMEOFTHEBENEFICIARY, CURRENTACCOUNTNO, BANKNAME, BRANCHNAME)
            .then((data) => {
                if (data.error != 1) {
                    console.log(data.data[0])
                    this.setState({ bankDetailsList: data.data[0] })
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    clearSearch() {
        const { exhibitionID, hallName } = this.state
        this.setState({
            NAMEOFTHEBENEFICIARY: '',
            CURRENTACCOUNTNO: '',
            BRANCHNAME: '',
            BANKNAME: '',
        })
        setTimeout(() => {
            this.getBankDetailsMasterList()
        }, 100);
    }

    handleAdd() {
        this.props.navigate('/bankDetailsAU', { state: { is_add: true, } })
    }

    handleUpdate(id) {
        this.props.navigate('/bankDetailsAU', { state: { is_update: true, BankDetailID: id } })
    }

    deleteBankDetailsMaster(id) {
        this.setState({ isLoading: true })
        this.props.deleteBankDetailsMaster_action(id)
            .then((data) => {
                if (data.error != 1) {
                    console.log(data.data)
                    this.setState({ isLoading: false })
                    toastSuccess("deleted successfully")
                    setTimeout(() => {
                        this.getBankDetailsMasterList()
                    }, 200);
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
                this.setState({ isOpen: false })
            })
            .catch((err) => {
                console.log(err)
            })
    }

    onDelete(i) {
        const { } = this.state
        this.setState({ deleteID: i })
        this.setState({ isOpen: true, })
    }

    updateItem(item) {
        const { bankDetailsList } = this.state
        this.setState({ currentPage: item });
        const recordsPerPage = 10;
        const indexOfLastRecord = item * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = bankDetailsList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(bankDetailsList.length / recordsPerPage)
        this.setState({ startIndex: recordsPerPage * (item - 1) });
    }

    render() {
        const { isOpen, isLoading, exhibitionID, currentPage, NAMEOFTHEBENEFICIARY, BANKNAME, CURRENTACCOUNTNO, BRANCHNAME, bankDetailsList, } = this.state
        const recordsPerPage = 10;
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = bankDetailsList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(bankDetailsList.length / recordsPerPage)
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <div className="app-content with-dr-container com-height">
                        <AdminDrawer />
                        <div className="row">
                            <div className="step-name">
                                <p>Bank Details list</p>
                            </div>
                        </div>
                        <div className="m-3">
                            <div className="">
                                {
                                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 1 &&
                                    <div className="row ">
                                        <div className="col-md-4">
                                            <label>NAME OF THE BENEFICIARY</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={NAMEOFTHEBENEFICIARY}
                                                onChange={(e) => this.setState({ NAMEOFTHEBENEFICIARY: e.target.value.toUpperCase(), })}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <label>CURRENT ACCOUNT NO</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={CURRENTACCOUNTNO}
                                                onChange={(e) => this.setState({ CURRENTACCOUNTNO: e.target.value.toUpperCase(), })}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <label>BANK NAME</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={BANKNAME}
                                                onChange={(e) => this.setState({ BANKNAME: e.target.value.toUpperCase(), })}
                                            />
                                        </div>
                                        <div className="col-md-4 mt-3">
                                            <label>BRANCH BRANCH</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={BRANCHNAME}
                                                onChange={(e) => this.setState({ BRANCHNAME: e.target.value.toUpperCase(), })}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                            < div className="row mt-2 mb-1" >
                                <div className="col-md-12">
                                    {
                                        JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 1 &&
                                        <div
                                            className="btn-group mb-1"
                                            role="group"
                                            aria-label="Basic example"
                                            style={{ float: "right" }}
                                        >
                                            <button type="button" className="btn ad-fil-btn"
                                                onClick={e => this.getBankDetailsMasterList()}
                                            >
                                                Search
                                            </button>
                                            <button type="button" className="btn ad-fil-btn"
                                                onClick={e => this.handleAdd()}
                                            >
                                                Add
                                            </button>
                                            <button type="button" className="btn ad-fil-btn"
                                                onClick={e => this.clearSearch()}
                                            >
                                                Clear
                                            </button>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="table-responsive">
                                {this.record(currentRecords)}
                            </div>
                            <div className="mt-2 float">
                                {
                                    bankDetailsList.length > 0 ?
                                        <Pagination
                                            nPages={nPages}
                                            currentPage={currentPage}
                                            setCurrentPage={this.updateItem}
                                        /> : <></>
                                }
                                <div className="tab-rec " style={{ float: "right" }}>
                                    <p>{`${currentPage} of ${nPages} pages`}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                <Modal isOpen={isOpen} onRequestClose={() => this.setState({ isOpen: false })}>
                    <div className="modal-dialog" style={{ marginTop: '20%' }}>
                        <div className="modal-content confirm-logout">
                            <div class="modal-header text-right p-2">
                                <h5 class="modal-title modal_title_text text-danger">Do you really want to delete this record?</h5>

                                {/* <button type="button " class="close pop-close" data-dismiss="modal"
                                    onClick={() => this.setState({ isOpen: false })}
                                ><span aria-hidden="true" class="pop-cancel">&times;</span></button> */}
                            </div>
                            {/* <div class="modal-body">
                                <div class="row">
                                </div>
                            </div> */}
                            <div class="modal-footer p-1">
                                <div class="form-group text-right mod-fot">
                                    <button type="button" class="btn btn-del-pop"
                                        onClick={(e) => this.deleteBankDetailsMaster(this.state.deleteID)}
                                    >Yes</button>
                                    <button type="button" class="btn btn-can-pop" data-dismiss="modal" style={{ marginLeft: '5px' }}
                                        onClick={() => this.setState({ isOpen: false })}
                                    >No</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
                <Footer />
            </>)
    }

}
export default withRouter(connect(null, { fill_action, getBankDetailsMasterList_action, deleteHallMaster_action, getBankDetailsMasterList_action, deleteBankDetailsMaster_action })(BankDetailsList));