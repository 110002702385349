import { axiosGet, axiosPost } from "../../../../react_axios/Axios";
import { headers, sendEmailOfAvailableAreaUrl, moveToApplyStatusUrl, sendWaitingEmailUrl, SEND_EMAIL_OF_AVAILABLE_AREA_ACTION_TYPE, MOVE_TO_APPLY_STATUS_ACTION_TYPE, SEND_WAITING_EMAIL_ACTION_TYPE, getApplicationStatusManagementListUrl, GET_APPLICATION_STATUS_MANAGEMENT_ACTION_TYPE } from "../../../../utils/constants";


export const getApplicationStatusManagementList_action = (ExhibitionID, ExhRegistrationID, ChapterName, BookingStatusId) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExhibitionID != '' ? parseInt(ExhibitionID) : null,
            ExhRegistrationID: ExhRegistrationID != '' ? parseInt(ExhRegistrationID) : null,
            ChapterName: ChapterName != '' ? ChapterName : null,
            BookingStatusId: BookingStatusId != '' ? parseInt(BookingStatusId) : null,
        }

        const res = await axiosGet(getApplicationStatusManagementListUrl, params, headers)

        dispatch({
            type: GET_APPLICATION_STATUS_MANAGEMENT_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const sendEmailOfAvailableArea_action = (receiptIdList) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ReceiptIdList: receiptIdList,
        }

        const res = await axiosPost(sendEmailOfAvailableAreaUrl, params, headers)

        dispatch({
            type: SEND_EMAIL_OF_AVAILABLE_AREA_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const moveToApplyStatus_action = (receiptIdList) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ReceiptIdList: receiptIdList,
        }

        const res = await axiosPost(moveToApplyStatusUrl, params, headers)

        dispatch({
            type: MOVE_TO_APPLY_STATUS_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const sendWaitingEmail_action = (ExhRegistrationList) => async (dispatch) => {
    try {
        const params = {
            ExhRegistrationList: ExhRegistrationList,
        }

        console.log("params----------", params)

        const res = await axiosGet(sendWaitingEmailUrl, params)

        dispatch({
            type: SEND_WAITING_EMAIL_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}