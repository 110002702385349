import { Component, forwardRef } from "react";
import Loader from "../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../../utils/withRouter";
import { connect } from "react-redux";
import HeaderWithLogo from "../../../../common_components/header_with_logo";
import AdminDrawer from "../../../../common_components/admin_module_drawer";
import Pagination from "../../../../utils/pagination";
import { downloadReport, loginUrl, priorityNoActionId, toastError, headers, toastSuccess, exhibitionActionId, exhibitorNameActionId, visitorListActionId } from "../../../../utils/constants";
import Modal from "react-modal";
import { data } from "jquery";
import { descriptionPreview_action } from "../../../../actions/description_action"
import { fill_action } from "../../../../actions/fillCombo_action"
import Accordion from '@mui/material/Accordion';
import { getAirConnectionReport_action, getWaterConnectionReport_action, getPowerConnectionReport_action, getFrightForwarderReport_action, getFasciaReport_action, getEDirectoryFormReport_action, getMeetingRoomFormReport_action, getExhBadgesFormReport_action, getExhibitDetailFormReport_action, getExhContractorBadgeFormReport_action, getStallBookingSummaryFormReport_action, getStallDesignFormFormReport_action, getVisitorBadgeFormFormReport_action, getStallPossessionFormReport_action, getVisaApplicationFormReport_action } from "../../../../actions/admin_module_action/report_action/manualFormReport_action";
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Workbook from 'react-excel-workbook'
import { axiosGet, axiosPost } from "../../../../react_axios/Axios";
import { saveAs } from 'file-saver';
import moment from "moment";
import { color } from "@mui/system";

const ExcelJS = require('exceljs');

class ManualFormReport extends Component {
    // intialize constructor
    constructor(props) {
        super(props)
        this.state = {
            reportName: '',
            reportID: '',

            isLoading: false,
            showTable: false,
            exhibitionID: '',
            exhibitionList: [],
            exhibitorList: [],
            list: [],
            columnArr: [],
            rowArr: [],
            ExhRegistrationID: '',
            xlrowArr: [],
            reportAccessList: [],
            VisitorID: '',
            VisitorList: [],
            data: [],
            data1: [],
            data2: [],
            data3: [],
            data4: [],
            data5: [],
            data6: [],
            data7: [],
            data8: [],
        }

        this.onViewClick = this.onViewClick.bind(this)
        this.renderTable = this.renderTable.bind(this)
        this.onDownloadClick = this.onDownloadClick.bind(this)
        this.getFillList = this.getFillList.bind(this)
        this.reportIdCondition = this.reportIdCondition.bind(this)
        this.getDownloadAccess = this.getDownloadAccess.bind(this)
        this.getViewAccess = this.getViewAccess.bind(this)
    }

    componentDidMount() {
        // this.getExhibitionManualForm()
        this.getFillList(exhibitionActionId, '', '', '', '', '')
        this.getFillList(exhibitorNameActionId, '', '', '', '', '')
        this.getFillList(visitorListActionId, '', '', '', '', '')
        this.setState({
            reportName: this.props.location.state != null && this.props.location.state.reportName != null ? this.props.location.state.reportName : '',
            reportID: this.props.location.state != null && this.props.location.state.reportID != null ? this.props.location.state.reportID : '',
            reportAccessList: JSON.parse(sessionStorage.getItem('loginDataSS'))['reportAccesslist']
        })
        setTimeout(() => {
            console.log("BBBBBBBBBBBBBBBBBBBB", JSON.parse(sessionStorage.getItem('loginDataSS'))['reportAccesslist'])
            console.log("sagar-------", this.props)
        }, 100);

    }

    handleDownload = async () => {
        const { data1, data2, data3, data4, data5, data6, data7, data8 } = this.state
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('My Sheet');

            // Function to apply styles to a row
            const applyCellStyles = (cell, color, alignment, isBold) => {
                cell.font = { name: 'Arial', size: 12, bold: isBold ? true : false };
                cell.alignment = { vertical: 'middle', horizontal: alignment == 'Center' ? 'center' : 'left' };
                cell.fill = {
                    type: 'pattern',
                    
                    pattern: 'solid',
                    fgColor: { argb: color == 'Y' ? 'FFFFFF00' : color == 'G' ? '92D050' : color == 'O' ? 'FFFFC000' : color == 'B' ? 'FF00B0F0' : color == 'SK' ? 'FFC7ACA0' : 'Gr' ? 'FFB8D3EF' : '' },  // Yellow color
                    bgColor: { argb: color == 'Y' ? 'FFFFFF00' : color == 'G' ? '92D050' : color == 'O' ? 'FFFFC000' : color == 'B' ? 'FF00B0F0' : color == 'SK' ? 'FFC7ACA0' : 'Gr' ? 'FFB8D3EF' : '' }
                };
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                }
            };

            const applyCellStylesSelect7 = (cell) => {
                cell.font = { name: 'Arial', size: 20, bold: true, color: { argb: '00FFFFFF' } };
                cell.alignment = { vertical: 'middle', horizontal: 'center' }
                cell.height = 40;
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '00000000' },  // Yellow color
                    bgColor: { argb: '00000000' }
                }
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                }
            };

            const applyRowStyles = (row, color) => {
                row.font = { name: 'Arial', size: 12, bold: true };
                row.alignment = { vertical: 'middle', horizontal: 'center' };
                row.height = 20;
                row.eachCell((cell) => {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: color == 'Y' ? 'FFFFFF00' : color == 'G' ? 'FF00FF00' : color == 'O' ? 'FFFF00C0' : color == 'B' ? 'FF00B0F0' : color == 'SK' ? 'FFC7ACA0' : 'Gr' ? 'FFB8D3EF' : '' },
                        bgColor: { argb: color == 'Y' ? 'FFFFFF00' : 'FF00FF00' }
                    };
                });
            };

            worksheet.mergeCells('A1:C1');
            const titleRow = worksheet.getCell('A1');
            titleRow.value = 'SUMMARY OF BOOKING'
            applyCellStyles(titleRow, 'Y', 'Center', true);

            // Add 'DATE' cell
            worksheet.mergeCells('D1:E1')
            const dateCell = worksheet.getCell('D1');
            dateCell.value = `DATE : ${moment(new Date).format('DD/MM/YYYY')}`;
            applyCellStyles(dateCell, 'Y', 'Center', true);

            worksheet.mergeCells('A2:E2');
            const mergedCellA2 = worksheet.getCell('A2');
            mergedCellA2.value = 'Participants Information';
            applyCellStyles(mergedCellA2, 'G', 'Center', true)

            const mergedCellA3 = worksheet.getCell('A3');
            mergedCellA3.value = 'Exhibitors';
            applyCellStyles(mergedCellA3, 'G', 'Left', true)

            worksheet.mergeCells('B3:D3');
            const mergedCellB3 = worksheet.getCell('B3');
            mergedCellB3.value = 'EXHIBITOR';
            applyCellStyles(mergedCellB3, 'G', 'Center', true)

            const mergedCellE3 = worksheet.getCell('E3');
            mergedCellE3.value = 'Area (Sqm)';
            applyCellStyles(mergedCellE3, 'G', 'Center', true)

            data1.forEach((item, index) => {
                const cell = worksheet.getCell(`A${index + 4}`);
                cell.value = item.Exhibitors;
                if (item.Exhibitors == 'Total space booked') {
                    applyCellStyles(cell, 'G', 'Left', true)
                } else {
                    applyCellStyles(cell, 'G', 'Left', false)
                }
            });

            data1.forEach((item, index) => {
                worksheet.mergeCells(`B${index + 4} : D${index + 4}`)
                const cell = worksheet.getCell(`B${index + 4}`);
                cell.value = item.EXHIBITOR
                if (item.Exhibitors == 'Total space booked') {
                    applyCellStyles(cell, 'G', 'Center', true)
                } else {
                    applyCellStyles(cell, 'G', 'Center', false)
                }
            });
            var indexValue
            data1.forEach((item, index) => {
                const cell = worksheet.getCell(`E${index + 4}`);  // Start from row 3
                cell.value = item.Area;
                indexValue = index + 6
                if (item.Exhibitors == 'Total space booked') {
                    applyCellStyles(cell, 'G', 'Center', true)
                } else {
                    applyCellStyles(cell, 'G', 'Center', false)
                }
            });
            worksheet.addRow()

            // second select
            data2.forEach((item, index) => {
                const cell = worksheet.getCell(`A${index + indexValue}`);
                cell.value = item.Exhibitors;
                if (item.Exhibitors == 'Total') {
                    applyCellStyles(cell, 'O', 'Left', true)
                } else {
                    applyCellStyles(cell, 'O', 'Left', false)
                }
            });

            data2.forEach((item, index) => {
                worksheet.mergeCells(`B${index + indexValue} : D${index + indexValue}`)
                const cell = worksheet.getCell(`B${index + indexValue}`);
                cell.value = item.EXHIBITOR
                if (item.Exhibitors == 'Total') {
                    applyCellStyles(cell, 'O', 'Center', true)
                } else {
                    applyCellStyles(cell, 'O', 'Center', false)
                }
            });
            var indexValue2
            data2.forEach((item, index) => {
                const cell = worksheet.getCell(`E${index + indexValue}`);  // Start from row 3
                cell.value = item.Area;
                indexValue2 = index + indexValue + 2
                if (item.Exhibitors == 'Total') {
                    applyCellStyles(cell, 'O', 'Center', true)
                } else {
                    applyCellStyles(cell, 'O', 'Center', false)
                }
            });
            worksheet.addRow()

            // third select
            data3.forEach((item, index) => {
                const cell = worksheet.getCell(`A${index + indexValue2}`);
                cell.value = item.Exhibitors;
                if (item.Exhibitors == 'Total') {
                    applyCellStyles(cell, 'B', 'Left', true)
                } else {
                    applyCellStyles(cell, 'B', 'Left', false)
                }
            });

            data3.forEach((item, index) => {
                worksheet.mergeCells(`B${index + indexValue2} : D${index + indexValue2}`)
                const cell = worksheet.getCell(`B${index + indexValue2}`);
                cell.value = item.EXHIBITOR
                if (item.Exhibitors == 'Total') {
                    applyCellStyles(cell, 'B', 'Center', true)
                } else {
                    applyCellStyles(cell, 'B', 'Center', false)
                }
            });
            var indexValue3
            data3.forEach((item, index) => {
                const cell = worksheet.getCell(`E${index + indexValue2}`);  // Start from row 3
                cell.value = item.Area;
                indexValue3 = index + indexValue2 + 2
                if (item.Exhibitors == 'Total') {
                    applyCellStyles(cell, 'B', 'Center', true)
                } else {
                    applyCellStyles(cell, 'B', 'Center', false)
                }
            });
            worksheet.addRow()

            // fourth select
            data4.forEach((item, index) => {
                const cell = worksheet.getCell(`A${index + indexValue3}`);
                cell.value = item.Exhibitors1;
                if (item.Exhibitors1 == 'Total') {
                    applyCellStyles(cell, 'SK', 'Left', true)
                } else {
                    applyCellStyles(cell, 'SK', 'Left', false)
                }
            });

            data4.forEach((item, index) => {
                worksheet.mergeCells(`C${index + indexValue3} : D${index + indexValue3}`)
                const cell = worksheet.getCell(`B${index + indexValue3}`);
                const cell2 = worksheet.getCell(`C${index + indexValue3}`);
                const cell3 = worksheet.getCell(`D${index + indexValue3}`);
                applyCellStyles(cell2, 'SK', 'Center', false)
                applyCellStyles(cell3, 'SK', 'Center', false)
                cell.value = item.Exhibitors2
                if (item.Exhibitors1 == 'Total') {
                    applyCellStyles(cell, 'SK', 'Center', true)
                } else {
                    applyCellStyles(cell, 'SK', 'Center', false)
                }
            });

            data4.forEach((item, index) => {
                const cell = worksheet.getCell(`C${index + indexValue3}`);
                cell.value = item.EXHIBITOR
                if (item.Exhibitors1 == 'Total') {
                    applyCellStyles(cell, 'SK', 'Center', true)
                } else {
                    applyCellStyles(cell, 'SK', 'Center', false)
                }
            });

            var indexValue4
            data4.forEach((item, index) => {
                const cell = worksheet.getCell(`E${index + indexValue3}`);
                cell.value = item.Area;
                indexValue4 = index + indexValue3 + 2
                if (item.Exhibitors1 == 'Total') {
                    applyCellStyles(cell, 'SK', 'Center', true)
                } else {
                    applyCellStyles(cell, 'SK', 'Center', false)
                }
            });
            worksheet.addRow()

            // fifth select
            const cell1 = worksheet.getCell(`A${indexValue4}`);
            cell1.value = 'Space Rental';
            applyCellStyles(cell1, 'Gr', 'Center', true)
            const cell2 = worksheet.getCell(`B${indexValue4}`);
            cell2.value = 'Exhibitors';
            applyCellStyles(cell2, 'Gr', 'Center', true)
            const cell3 = worksheet.getCell(`C${indexValue4}`);
            cell3.value = 'Area';
            applyCellStyles(cell3, 'Gr', 'Center', true)
            const cell4 = worksheet.getCell(`D${indexValue4}`);
            cell4.value = 'Space Billing';
            applyCellStyles(cell4, 'Gr', 'Center', true)
            const cell5 = worksheet.getCell(`E${indexValue4}`);
            cell5.value = 'Amount Received';
            applyCellStyles(cell5, 'Gr', 'Center', true)
            indexValue4 += 1

            data5.forEach((item, index) => {
                const cell = worksheet.getCell(`A${index + indexValue4}`);
                cell.value = item.Space_Rental;
                if (item.Space_Rental == 'Indian  (include agents)' || item.Space_Rental == 'Foreign  (include agents)' || item.Space_Rental == "Total Amount") {
                    applyCellStyles(cell, 'Gr', 'Left', true)
                } else {
                    applyCellStyles(cell, 'Gr', 'Left', false)
                }
            });

            data5.forEach((item, index) => {
                const cell = worksheet.getCell(`B${index + indexValue4}`);
                if (item.Space_Rental == 'Indian  (include agents)' || item.Space_Rental == 'Foreign  (include agents)') {
                    cell.value = '';
                } else {
                    cell.value = item.EXHIBITOR;
                }
                if (item.Space_Rental == 'Indian  (include agents)' || item.Space_Rental == 'Foreign  (include agents)' || item.Space_Rental == "Total Amount") {
                    applyCellStyles(cell, 'Gr', 'Center', true)
                } else {
                    applyCellStyles(cell, 'Gr', 'Center', false)
                }
            });

            data5.forEach((item, index) => {
                const cell = worksheet.getCell(`C${index + indexValue4}`);
                if (item.Space_Rental == 'Indian  (include agents)' || item.Space_Rental == 'Foreign  (include agents)') {
                    cell.value = '';
                } else {
                    cell.value = item.Area;
                }
                if (item.Space_Rental == 'Indian  (include agents)' || item.Space_Rental == 'Foreign  (include agents)' || item.Space_Rental == "Total Amount") {
                    applyCellStyles(cell, 'Gr', 'Center', true)
                } else {
                    applyCellStyles(cell, 'Gr', 'Center', false)
                }
            });

            data5.forEach((item, index) => {
                const cell = worksheet.getCell(`D${index + indexValue4}`);
                if (item.Space_Rental == 'Indian  (include agents)' || item.Space_Rental == 'Foreign  (include agents)') {
                    cell.value = '';
                } else {
                    cell.value = item.Space_Billing;
                }
                if (item.Space_Rental == 'Indian  (include agents)' || item.Space_Rental == 'Foreign  (include agents)' || item.Space_Rental == "Total Amount") {
                    applyCellStyles(cell, 'Gr', 'Center', true)
                } else {
                    applyCellStyles(cell, 'Gr', 'Center', false)
                }
            });
            var indexValue5
            data5.forEach((item, index) => {
                const cell = worksheet.getCell(`E${index + indexValue4}`);
                if (item.Space_Rental == 'Indian  (include agents)' || item.Space_Rental == 'Foreign  (include agents)') {
                    cell.value = '';
                } else {
                    cell.value = item.Amount_Received;
                }
                indexValue5 = index + indexValue4 + 2
                if (item.Space_Rental == 'Indian  (include agents)' || item.Space_Rental == 'Foreign  (include agents)' || item.Space_Rental == "Total Amount") {
                    applyCellStyles(cell, 'Gr', 'Center', true)
                } else {
                    applyCellStyles(cell, 'Gr', 'Center', false)
                }
            });
            worksheet.addRow()

            // sixth select
            data6.forEach((item, index) => {
                const cell = worksheet.getCell(`A${index + indexValue5}`);
                cell.value = item.Exhibitors;
                if (item.Space_Rental == 'Total') {
                    applyCellStyles(cell, 'SK', 'Left', true)
                } else {
                    applyCellStyles(cell, 'SK', 'Left', false)
                }
            });

            data6.forEach((item, index) => {
                const cell = worksheet.getCell(`B${index + indexValue5}`);
                cell.value = item.EXHIBITOR;
                if (item.Space_Rental == 'Total') {
                    applyCellStyles(cell, 'SK', 'Center', true)
                } else {
                    applyCellStyles(cell, 'SK', 'Center', false)
                }
            });

            data6.forEach((item, index) => {
                const cell = worksheet.getCell(`C${index + indexValue5}`);
                cell.value = item.Area;
                if (item.Space_Rental == 'Total') {
                    applyCellStyles(cell, 'SK', 'Center', true)
                } else {
                    applyCellStyles(cell, 'SK', 'Center', false)
                }
            });

            data6.forEach((item, index) => {
                const cell = worksheet.getCell(`D${index + indexValue5}`);
                cell.value = item.Space_Billing;
                if (item.Space_Rental == 'Total') {
                    applyCellStyles(cell, 'SK', 'Center', true)
                } else {
                    applyCellStyles(cell, 'SK', 'Center', false)
                }
            });
            var indexValue6
            data6.forEach((item, index) => {
                const cell = worksheet.getCell(`E${index + indexValue5}`);
                cell.value = item.Amount_Received;
                indexValue6 = index + indexValue5 + 2
                if (item.Space_Rental == 'Total') {
                    applyCellStyles(cell, 'SK', 'Center', true)
                } else {
                    applyCellStyles(cell, 'SK', 'Center', false)
                }
            });
            worksheet.addRow()

            // seventh Select

            worksheet.mergeCells(`A${indexValue6} : G${indexValue6}`)
            worksheet.getRow(`A${indexValue6}`).height = 40
            const cell = worksheet.getCell(`A${indexValue6}`)
            cell.value = 'INDIAN + FOREIGN + AGENTS SHELL/BARE STATUS'
            applyCellStylesSelect7(cell)

            indexValue6 += 1
            const cell6 = worksheet.getCell(`A${indexValue6}`);
            cell6.value = '';
            applyCellStyles(cell6, 'Y', 'Center', true)

            worksheet.mergeCells(`B${indexValue6} : C${indexValue6}`)
            const cell7 = worksheet.getCell(`B${indexValue6}`);
            cell7.value = 'SHELL';
            applyCellStyles(cell7, 'Y', 'Center', true)

            worksheet.mergeCells(`D${indexValue6} : E${indexValue6}`)
            const cell8 = worksheet.getCell(`D${indexValue6}`);
            cell8.value = 'BARE';
            applyCellStyles(cell8, 'Y', 'Center', true)

            worksheet.mergeCells(`F${indexValue6} : G${indexValue6}`)
            const cell9 = worksheet.getCell(`F${indexValue6}`);
            cell9.value = 'TOTAL';
            applyCellStyles(cell9, 'Y', 'Center', true)

            indexValue6 += 1
            const cell10 = worksheet.getCell(`A${indexValue6}`);
            cell10.value = '';
            applyCellStyles(cell10, 'Y', 'Center', true)

            const cell11 = worksheet.getCell(`B${indexValue6}`);
            cell11.value = 'EXHIBITORS';
            applyCellStyles(cell11, 'Y', 'Center', true)

            const cell12 = worksheet.getCell(`C${indexValue6}`);
            cell12.value = 'AREA (SQM)';
            applyCellStyles(cell12, 'Y', 'Center', true)

            const cell13 = worksheet.getCell(`D${indexValue6}`);
            cell13.value = 'EXHIBITORS';
            applyCellStyles(cell13, 'Y', 'Center', true)

            const cell14 = worksheet.getCell(`E${indexValue6}`);
            cell14.value = 'AREA (SQM)';
            applyCellStyles(cell14, 'Y', 'Center', true)

            const cell15 = worksheet.getCell(`F${indexValue6}`);
            cell15.value = 'EXHIBITORS';
            applyCellStyles(cell15, 'Y', 'Center', true)

            const cell16 = worksheet.getCell(`G${indexValue6}`);
            cell16.value = 'AREA (SQM)';
            applyCellStyles(cell16, 'Y', 'Center', true)

            indexValue6 += 1
            data7.forEach((item, index) => {
                const cell = worksheet.getCell(`A${index + indexValue6}`);
                cell.value = item.Name;
                if (item.Name == 'Total') {
                    applyCellStyles(cell, 'B', 'Center', true)
                } else {
                    applyCellStyles(cell, 'Gr', 'Center', false)
                }
            });

            data7.forEach((item, index) => {
                const cell = worksheet.getCell(`B${index + indexValue6}`);
                cell.value = item.Shell_EXHIBITOR;
                if (item.Name == 'Total') {
                    applyCellStyles(cell, 'B', 'Center', true)
                } else {
                    applyCellStyles(cell, 'Gr', 'Center', false)
                }
            });

            data7.forEach((item, index) => {
                const cell = worksheet.getCell(`C${index + indexValue6}`);
                cell.value = item.Shell_Area;
                if (item.Name == 'Total') {
                    applyCellStyles(cell, 'B', 'Center', true)
                } else {
                    applyCellStyles(cell, 'Gr', 'Center', false)
                }
            });

            data7.forEach((item, index) => {
                const cell = worksheet.getCell(`D${index + indexValue6}`);
                cell.value = item.Bare_EXHIBITOR;
                if (item.Name == 'Total') {
                    applyCellStyles(cell, 'B', 'Center', true)
                } else {
                    applyCellStyles(cell, 'Gr', 'Center', false)
                }
            });

            data7.forEach((item, index) => {
                const cell = worksheet.getCell(`E${index + indexValue6}`);
                cell.value = item.Bare_Area;
                if (item.Name == 'Total') {
                    applyCellStyles(cell, 'B', 'Center', true)
                } else {
                    applyCellStyles(cell, 'Gr', 'Center', false)
                }
            });

            data7.forEach((item, index) => {
                const cell = worksheet.getCell(`F${index + indexValue6}`);
                cell.value = item.Total_EXHIBITOR;
                if (item.Name == 'Total') {
                    applyCellStyles(cell, 'B', 'Center', true)
                } else {
                    applyCellStyles(cell, 'Gr', 'Center', false)
                }
            });
            var indexValue7
            data7.forEach((item, index) => {
                const cell = worksheet.getCell(`G${index + indexValue6}`);
                cell.value = item.Total_Area;
                indexValue7 = index + indexValue6 + 2
                if (item.Name == 'Total') {
                    applyCellStyles(cell, 'B', 'Center', true)
                } else {
                    applyCellStyles(cell, 'Gr', 'Center', false)
                }
            });
            worksheet.addRow()

            // Eighth select

            const cell17 = worksheet.getCell(`A${indexValue7}`);
            cell17.value = 'Primary Chapter';
            applyCellStyles(cell17, 'G', 'Left', true)

            const cell18 = worksheet.getCell(`B${indexValue7}`);
            cell18.value = 'EXHIBITOR';
            applyCellStyles(cell18, 'G', 'Center', true)

            const cell19 = worksheet.getCell(`C${indexValue7}`);
            cell19.value = 'AREA';
            applyCellStyles(cell19, 'G', 'Center', true)

            indexValue7 += 1

            data8.forEach((item, index) => {
                const cell = worksheet.getCell(`A${index + indexValue7}`);
                cell.value = item.PrimaryChapter;
                if (item.PrimaryChapter == 'Total') {
                    applyCellStyles(cell, 'O', 'Left', true)
                } else {
                    applyCellStyles(cell, 'O', 'Left', false)
                }
            });

            data8.forEach((item, index) => {
                const cell = worksheet.getCell(`B${index + indexValue7}`);
                cell.value = item.EXHIBITOR;
                if (item.PrimaryChapter == 'Total') {
                    applyCellStyles(cell, 'O', 'Center', true)
                } else {
                    applyCellStyles(cell, 'O', 'Center', false)
                }
            });

            data8.forEach((item, index) => {
                const cell = worksheet.getCell(`C${index + indexValue7}`);
                cell.value = item.Area;
                if (item.PrimaryChapter == 'Total') {
                    applyCellStyles(cell, 'O', 'Center', true)
                } else {
                    applyCellStyles(cell, 'O', 'Center', false)
                }
            });

            // Set column widths for A to C
            worksheet.getColumn('A').width = 50;
            worksheet.getColumn('B').width = 20;
            worksheet.getColumn('C').width = 20;
            worksheet.getColumn('D').width = 20;
            worksheet.getColumn('E').width = 20;
            worksheet.getColumn('F').width = 20;
            worksheet.getColumn('G').width = 20;
            // Adjust column widths based on content for other columns
            worksheet.columns.forEach((column) => {
                if (['A', 'B', 'C', 'D', 'E', 'F', 'G'].indexOf(column.letter) === -1) {
                    let maxLength = 0;
                    column.eachCell({ includeEmpty: true }, (cell) => {
                        const length = cell.value ? cell.value.toString().length : 0;
                        if (length > maxLength) {
                            maxLength = length;
                        }
                    });
                    column.width = maxLength < 10 ? 10 : maxLength + 2;
                }
            });

            // Create buffer and save
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, 'stall_booking_summary.xlsx');
        } catch (error) {
            console.error('Error exporting Excel:', error);
        }
    };

    reportIdCondition(status, value) {
        const { reportID, reportName } = this.state
        if (reportName == "Air") {
            this.getAirConnectionSummary(status, value)
        } else if (reportName == "Water") {
            this.getWaterConnectionSummary(status, value)
        } else if (reportName == "Power") {
            this.getPowerConnectionSummary(status, value)
        } else if (reportName == "Fascia") {
            this.getFasciaFormSummary(status, value)
        } else if (reportName == "Freight Forwarders") {
            this.getFreightForwarderSummary(status, value)
        } else if (reportName == "Directory Listing") {
            this.getEDirectorySummary(status, value)
        } else if (reportName == "Meeting Room") {
            this.getMeetingRoomSummary(status, value)
        } else if (reportName == "Exhibitor Badges") {
            this.getExhBadgesFormSummary(status, value)
        } else if (reportName == "Exhibit Details") {
            this.getExhibitDetailFormSummary(status, value)
        } else if (reportName == "Contractor Badges") {
            this.getExhContractorBadgeFormSummary(status, value)
        } else if (reportName == "Stall Booking Summary") {
            this.getStallBookingSummaryFormSummary(status, value)
        } else if (reportName == "Stall Design") {
            this.getStallDesignFormSummary(status, value)
        } else if (reportName == "Visitor Badges") {
            this.getVisitorBadgeFormFormSummary(status, value)
        } else if (reportName == "Stall Possession") {
            this.getStallPossessionFormSummary(status, value)
        } else if (reportName == "Invitation Letter For Visa Application") {
            this.getVisaApplicationFormSummary(status, value)
        }
    }

    getAirConnectionSummary(status, value) {
        const { exhibitionID, ExhRegistrationID } = this.state
        console.log(status, value)
        if (status == 'exhibitionID') {
            this.setState({ exhibitionID: value })
            if (value != 'select') {
                this.setState({ isLoading: true })
                this.props.getAirConnectionReport_action(parseInt(value), ExhRegistrationID == '' || ExhRegistrationID == null ? 0 : ExhRegistrationID)
                    .then((data) => {
                        console.log(data)
                        if (data.error != 1) {
                            this.setState({ list: data.data })
                            this.getTableData(data.data)
                            this.setState({ showTable: false })
                            this.setState({ isLoading: false })
                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
            else {
                console.log(value)
                this.setState({ list: [], rowArr: [], columnArr: [], showTable: false })
            }
        }
        if (status == 'ExhRegistrationID') {
            console.log(exhibitionID)
            this.setState({ ExhRegistrationID: value })
            if (exhibitionID != null && exhibitionID != '' && exhibitionID != 'select') {
                this.setState({ isLoading: true })
                this.props.getAirConnectionReport_action(exhibitionID, value == 'select' ? 0 : value)
                    .then((data) => {
                        console.log(data)
                        if (data.error != 1) {
                            this.setState({ list: data.data })
                            // if (status == 'view') {
                            this.getTableData(data.data)
                            this.setState({ showTable: false })
                            this.setState({ isLoading: false })
                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        }
    }

    getWaterConnectionSummary(status, value) {
        const { exhibitionID, ExhRegistrationID } = this.state
        console.log(status, value)
        if (status == 'exhibitionID') {
            this.setState({ exhibitionID: value })
            if (value != 'select') {
                this.setState({ isLoading: true })
                this.props.getWaterConnectionReport_action(parseInt(value), ExhRegistrationID == '' || ExhRegistrationID == null ? 0 : ExhRegistrationID)
                    .then((data) => {
                        console.log(data)
                        if (data.error != 1) {
                            this.setState({ list: data.data })
                            this.getTableData(data.data)
                            this.setState({ showTable: false })
                            this.setState({ isLoading: false })
                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
            else {
                console.log(value)
                this.setState({ list: [], rowArr: [], columnArr: [], showTable: false })
            }
        }
        if (status == 'ExhRegistrationID') {
            console.log(exhibitionID)
            this.setState({ ExhRegistrationID: value })
            if (exhibitionID != null && exhibitionID != '' && exhibitionID != 'select') {
                this.setState({ isLoading: true })
                this.props.getWaterConnectionReport_action(exhibitionID, value == 'select' ? 0 : value)
                    .then((data) => {
                        console.log(data)
                        if (data.error != 1) {
                            this.setState({ list: data.data })
                            // if (status == 'view') {
                            this.getTableData(data.data)
                            this.setState({ showTable: false })
                            this.setState({ isLoading: false })
                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        }
    }

    getPowerConnectionSummary(status, value) {
        const { exhibitionID, ExhRegistrationID } = this.state
        console.log(status, value)
        if (status == 'exhibitionID') {
            this.setState({ exhibitionID: value })
            if (value != 'select') {
                this.setState({ isLoading: true })
                this.props.getPowerConnectionReport_action(parseInt(value), ExhRegistrationID == '' || ExhRegistrationID == null ? 0 : ExhRegistrationID)
                    .then((data) => {
                        console.log(data)
                        if (data.error != 1) {
                            this.setState({ list: data.data })
                            this.getTableData(data.data)
                            this.setState({ showTable: false })
                            this.setState({ isLoading: false })
                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
            else {
                console.log(value)
                this.setState({ list: [], rowArr: [], columnArr: [], showTable: false })
            }
        }
        if (status == 'ExhRegistrationID') {
            console.log(exhibitionID)
            this.setState({ ExhRegistrationID: value })
            if (exhibitionID != null && exhibitionID != '' && exhibitionID != 'select') {
                this.setState({ isLoading: true })
                this.props.getPowerConnectionReport_action(exhibitionID, value == 'select' ? 0 : value)
                    .then((data) => {
                        console.log(data)
                        if (data.error != 1) {
                            this.setState({ list: data.data })
                            // if (status == 'view') {
                            this.getTableData(data.data)
                            this.setState({ showTable: false })
                            this.setState({ isLoading: false })
                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        }
    }

    getFasciaFormSummary(status, value) {
        const { exhibitionID, ExhRegistrationID } = this.state
        console.log(status, value)
        if (status == 'exhibitionID') {
            this.setState({ exhibitionID: value })
            if (value != 'select') {
                this.setState({ isLoading: true })
                this.props.getFasciaReport_action(parseInt(value), ExhRegistrationID == '' || ExhRegistrationID == null ? 0 : ExhRegistrationID)
                    .then((data) => {
                        console.log(data)
                        if (data.error != 1) {
                            this.setState({ list: data.data })
                            this.getTableData(data.data)
                            this.setState({ showTable: false })
                            this.setState({ isLoading: false })
                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
            else {
                console.log(value)
                this.setState({ list: [], rowArr: [], columnArr: [], showTable: false })
            }
        }
        if (status == 'ExhRegistrationID') {
            console.log(exhibitionID)
            this.setState({ ExhRegistrationID: value })
            if (exhibitionID != null && exhibitionID != '' && exhibitionID != 'select') {
                this.setState({ isLoading: true })
                this.props.getFasciaReport_action(exhibitionID, value == 'select' ? 0 : value)
                    .then((data) => {
                        console.log(data)
                        if (data.error != 1) {
                            this.setState({ list: data.data })
                            // if (status == 'view') {
                            this.getTableData(data.data)
                            this.setState({ showTable: false })
                            this.setState({ isLoading: false })
                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        }
    }

    getFreightForwarderSummary(status, value) {
        const { exhibitionID, ExhRegistrationID } = this.state
        console.log(status, value)
        if (status == 'exhibitionID') {
            this.setState({ exhibitionID: value })
            if (value != 'select') {
                this.setState({ isLoading: true })
                this.props.getFrightForwarderReport_action(parseInt(value), ExhRegistrationID == '' || ExhRegistrationID == null ? 0 : ExhRegistrationID)
                    .then((data) => {
                        console.log(data)
                        if (data.error != 1) {
                            this.setState({ list: data.data })
                            this.getTableData(data.data)
                            this.setState({ showTable: false })
                            this.setState({ isLoading: false })
                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
            else {
                console.log(value)
                this.setState({ list: [], rowArr: [], columnArr: [], showTable: false })
            }
        }
        if (status == 'ExhRegistrationID') {
            console.log(exhibitionID)
            this.setState({ ExhRegistrationID: value })
            if (exhibitionID != null && exhibitionID != '' && exhibitionID != 'select') {
                this.setState({ isLoading: true })
                this.props.getFrightForwarderReport_action(exhibitionID, value == 'select' ? 0 : value)
                    .then((data) => {
                        console.log(data)
                        if (data.error != 1) {
                            this.setState({ list: data.data })
                            // if (status == 'view') {
                            this.getTableData(data.data)
                            this.setState({ showTable: false })
                            this.setState({ isLoading: false })
                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        }
    }

    getEDirectorySummary(status, value) {
        const { exhibitionID, ExhRegistrationID } = this.state
        console.log(status, value)
        if (status == 'exhibitionID') {
            this.setState({ exhibitionID: value })
            if (value != 'select') {
                this.setState({ isLoading: true })
                this.props.getEDirectoryFormReport_action(parseInt(value), ExhRegistrationID == '' || ExhRegistrationID == null ? 0 : ExhRegistrationID)
                    .then((data) => {
                        console.log(data)
                        if (data.error != 1) {
                            this.setState({ list: data.data })
                            this.getTableData(data.data)
                            this.setState({ showTable: false })
                            this.setState({ isLoading: false })
                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
            else {
                console.log(value)
                this.setState({ list: [], rowArr: [], columnArr: [], showTable: false })
            }
        }
        if (status == 'ExhRegistrationID') {
            console.log(exhibitionID)
            this.setState({ ExhRegistrationID: value })
            if (exhibitionID != null && exhibitionID != '' && exhibitionID != 'select') {
                this.setState({ isLoading: true })
                this.props.getEDirectoryFormReport_action(exhibitionID, value == 'select' ? 0 : value)
                    .then((data) => {
                        console.log(data)
                        if (data.error != 1) {
                            this.setState({ list: data.data })
                            // if (status == 'view') {
                            this.getTableData(data.data)
                            this.setState({ showTable: false })
                            this.setState({ isLoading: false })
                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        }
    }

    getMeetingRoomSummary(status, value) {
        const { exhibitionID, ExhRegistrationID } = this.state
        console.log(status, value)
        if (status == 'exhibitionID') {
            this.setState({ exhibitionID: value })
            if (value != 'select') {
                this.setState({ isLoading: true })
                this.props.getMeetingRoomFormReport_action(parseInt(value), ExhRegistrationID == '' || ExhRegistrationID == null ? 0 : ExhRegistrationID)
                    .then((data) => {
                        console.log(data)
                        if (data.error != 1) {
                            this.setState({ list: data.data })
                            this.getTableData(data.data)
                            this.setState({ showTable: false })
                            this.setState({ isLoading: false })
                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
            else {
                console.log(value)
                this.setState({ list: [], rowArr: [], columnArr: [], showTable: false })
            }
        }
        if (status == 'ExhRegistrationID') {
            console.log(exhibitionID)
            this.setState({ ExhRegistrationID: value })
            if (exhibitionID != null && exhibitionID != '' && exhibitionID != 'select') {
                this.setState({ isLoading: true })
                this.props.getMeetingRoomFormReport_action(exhibitionID, value == 'select' ? 0 : value)
                    .then((data) => {
                        console.log(data)
                        if (data.error != 1) {
                            this.setState({ list: data.data })
                            // if (status == 'view') {
                            this.getTableData(data.data)
                            this.setState({ showTable: false })
                            this.setState({ isLoading: false })
                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        }
    }

    getExhBadgesFormSummary(status, value) {
        const { exhibitionID, ExhRegistrationID } = this.state
        console.log(status, value)
        if (status == 'exhibitionID') {
            this.setState({ exhibitionID: value })
            if (value != 'select') {
                this.setState({ isLoading: true })
                this.props.getExhBadgesFormReport_action(parseInt(value), ExhRegistrationID == '' || ExhRegistrationID == null ? 0 : ExhRegistrationID)
                    .then((data) => {
                        console.log(data)
                        if (data.error != 1) {
                            this.setState({ list: data.data })
                            this.getTableData(data.data)
                            this.setState({ showTable: false })
                            this.setState({ isLoading: false })
                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
            else {
                console.log(value)
                this.setState({ list: [], rowArr: [], columnArr: [], showTable: false })
            }
        }
        if (status == 'ExhRegistrationID') {
            console.log(exhibitionID)
            this.setState({ ExhRegistrationID: value })
            if (exhibitionID != null && exhibitionID != '' && exhibitionID != 'select') {
                this.setState({ isLoading: true })
                this.props.getExhBadgesFormReport_action(exhibitionID, value == 'select' ? 0 : value)
                    .then((data) => {
                        console.log(data)
                        if (data.error != 1) {
                            this.setState({ list: data.data })
                            // if (status == 'view') {
                            this.getTableData(data.data)
                            this.setState({ showTable: false })
                            this.setState({ isLoading: false })
                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        }
    }

    getExhibitDetailFormSummary(status, value) {
        const { exhibitionID, ExhRegistrationID } = this.state
        console.log(status, value)
        if (status == 'exhibitionID') {
            this.setState({ exhibitionID: value })
            if (value != 'select') {
                this.setState({ isLoading: true })
                this.props.getExhibitDetailFormReport_action(parseInt(value), ExhRegistrationID == '' || ExhRegistrationID == null ? 0 : ExhRegistrationID)
                    .then((data) => {
                        console.log(data)
                        if (data.error != 1) {
                            this.setState({ list: data.data })
                            this.getTableData(data.data)
                            this.setState({ showTable: false })
                            this.setState({ isLoading: false })
                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
            else {
                console.log(value)
                this.setState({ list: [], rowArr: [], columnArr: [], showTable: false })
            }
        }
        if (status == 'ExhRegistrationID') {
            console.log(exhibitionID)
            this.setState({ ExhRegistrationID: value })
            if (exhibitionID != null && exhibitionID != '' && exhibitionID != 'select') {
                this.setState({ isLoading: true })
                this.props.getExhibitDetailFormReport_action(exhibitionID, value == 'select' ? 0 : value)
                    .then((data) => {
                        console.log(data)
                        if (data.error != 1) {
                            this.setState({ list: data.data })
                            // if (status == 'view') {
                            this.getTableData(data.data)
                            this.setState({ showTable: false })
                            this.setState({ isLoading: false })
                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        }
    }

    getExhContractorBadgeFormSummary(status, value) {
        const { exhibitionID, ExhRegistrationID } = this.state
        console.log(status, value)
        if (status == 'exhibitionID') {
            this.setState({ exhibitionID: value })
            if (value != 'select') {
                this.setState({ isLoading: true })
                this.props.getExhContractorBadgeFormReport_action(parseInt(value), ExhRegistrationID == '' || ExhRegistrationID == null ? 0 : ExhRegistrationID)
                    .then((data) => {
                        console.log(data)
                        if (data.error != 1) {
                            this.setState({ list: data.data })
                            this.getTableData(data.data)
                            this.setState({ showTable: false })
                            this.setState({ isLoading: false })

                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
            else {
                console.log(value)
                this.setState({ list: [], rowArr: [], columnArr: [], showTable: false })
            }
        }
        if (status == 'ExhRegistrationID') {
            console.log(exhibitionID)
            this.setState({ ExhRegistrationID: value })
            if (exhibitionID != null && exhibitionID != '' && exhibitionID != 'select') {
                this.setState({ isLoading: true })
                this.props.getExhContractorBadgeFormReport_action(exhibitionID, value == 'select' ? 0 : value)
                    .then((data) => {
                        console.log(data)
                        if (data.error != 1) {
                            this.setState({ list: data.data })
                            // if (status == 'view') {
                            this.getTableData(data.data)
                            this.setState({ showTable: false })
                            this.setState({ isLoading: false })
                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        }
    }

    getStallBookingSummaryFormSummary(status, value) {
        const { exhibitionID, ExhRegistrationID } = this.state
        console.log(status, value)
        if (status == 'exhibitionID') {
            this.setState({ exhibitionID: value })
            if (value != 'select') {
                this.setState({ isLoading: true })
                this.props.getStallBookingSummaryFormReport_action(parseInt(value), ExhRegistrationID == '' || ExhRegistrationID == null ? 0 : ExhRegistrationID)
                    .then((data) => {
                        console.log(data)
                        if (data.error != 1) {
                            this.setState({ list: data.data })
                            this.getTableData(data.data)
                            this.setState({ showTable: false })
                            this.setState({ isLoading: false })
                            this.setState({
                                data1: data.data,
                                data2: data.data1,
                                data3: data.data2,
                                data4: data.data3,
                                data5: data.data4,
                                data6: data.data5,
                                data7: data.data6,
                                data8: data.data7,
                                data9: data.data8,
                            })
                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
            else {
                console.log(value)
                this.setState({ list: [], rowArr: [], columnArr: [], showTable: false })
            }
        }
        if (status == 'ExhRegistrationID') {
            console.log(exhibitionID)
            this.setState({ ExhRegistrationID: value })
            if (exhibitionID != null && exhibitionID != '' && exhibitionID != 'select') {
                this.setState({ isLoading: true })
                this.props.getStallBookingSummaryFormReport_action(exhibitionID, value == 'select' ? 0 : value)
                    .then((data) => {
                        console.log(data)
                        if (data.error != 1) {
                            this.setState({ list: data.data })
                            // if (status == 'view') {
                            this.getTableData(data.data)
                            this.setState({ showTable: false })
                            this.setState({ isLoading: false })
                            this.setState({
                                data1: data.data,
                                data2: data.data1,
                                data3: data.data2,
                                data4: data.data3,
                                data5: data.data4,
                                data6: data.data5,
                                data7: data.data6,
                                data8: data.data7,
                            })
                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        }
    }

    getStallDesignFormSummary(status, value) {
        const { exhibitionID, ExhRegistrationID } = this.state
        console.log(status, value)
        if (status == 'exhibitionID') {
            this.setState({ exhibitionID: value })
            if (value != 'select') {
                this.setState({ isLoading: true })
                this.props.getStallDesignFormFormReport_action(parseInt(value), ExhRegistrationID == '' || ExhRegistrationID == null ? 0 : ExhRegistrationID)
                    .then((data) => {
                        console.log(data)
                        if (data.error != 1) {
                            this.setState({ list: data.data })
                            this.getTableData(data.data)
                            this.setState({ showTable: false })
                            this.setState({ isLoading: false })
                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
            else {
                console.log(value)
                this.setState({ list: [], rowArr: [], columnArr: [], showTable: false })
            }
        }
        if (status == 'ExhRegistrationID') {
            console.log(exhibitionID)
            this.setState({ ExhRegistrationID: value })
            if (exhibitionID != null && exhibitionID != '' && exhibitionID != 'select') {
                this.setState({ isLoading: true })
                this.props.getStallDesignFormFormReport_action(exhibitionID, value == 'select' ? 0 : value)
                    .then((data) => {
                        console.log(data)
                        if (data.error != 1) {
                            this.setState({ list: data.data })
                            // if (status == 'view') {
                            this.getTableData(data.data)
                            this.setState({ showTable: false })
                            this.setState({ isLoading: false })
                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        }
    }

    getVisitorBadgeFormFormSummary(status, value) {
        const { exhibitionID, VisitorID } = this.state
        console.log(status, value)
        if (status == 'exhibitionID') {
            this.setState({ exhibitionID: value })
            if (value != 'select') {
                this.setState({ isLoading: true })
                this.props.getVisitorBadgeFormFormReport_action(parseInt(value), VisitorID == '' || VisitorID == null ? 0 : VisitorID)
                    .then((data) => {
                        console.log(data)
                        if (data.error != 1) {
                            this.setState({ list: data.data })
                            this.getTableData(data.data)
                            this.setState({ showTable: false })
                            this.setState({ isLoading: false })
                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
            else {
                console.log(value)
                this.setState({ list: [], rowArr: [], columnArr: [], showTable: false })
            }
        }
        if (status == 'VisitorID') {
            console.log(exhibitionID)
            this.setState({ VisitorID: value })
            if (exhibitionID != null && exhibitionID != '' && exhibitionID != 'select') {
                this.setState({ isLoading: true })
                this.props.getVisitorBadgeFormFormReport_action(exhibitionID, value == 'select' ? 0 : value)
                    .then((data) => {
                        console.log(data)
                        if (data.error != 1) {
                            this.setState({ list: data.data })
                            // if (status == 'view') {
                            this.getTableData(data.data)
                            this.setState({ showTable: false })
                            this.setState({ isLoading: false })
                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        }
    }

    getStallPossessionFormSummary(status, value) {
        const { exhibitionID, ExhRegistrationID } = this.state
        console.log(status, value)
        if (status == 'exhibitionID') {
            this.setState({ exhibitionID: value })
            if (value != 'select') {
                this.setState({ isLoading: true })
                this.props.getStallPossessionFormReport_action(parseInt(value), ExhRegistrationID == '' || ExhRegistrationID == null ? 0 : ExhRegistrationID)
                    .then((data) => {
                        console.log(data)
                        if (data.error != 1) {
                            this.setState({ list: data.data })
                            this.getTableData(data.data)
                            this.setState({ showTable: false })
                            this.setState({ isLoading: false })
                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
            else {
                console.log(value)
                this.setState({ list: [], rowArr: [], columnArr: [], showTable: false })
            }
        }
        if (status == 'ExhRegistrationID') {
            console.log(exhibitionID)
            this.setState({ ExhRegistrationID: value })
            if (exhibitionID != null && exhibitionID != '' && exhibitionID != 'select') {
                this.setState({ isLoading: true })
                this.props.getStallPossessionFormReport_action(exhibitionID, value == 'select' ? 0 : value)
                    .then((data) => {
                        console.log(data)
                        if (data.error != 1) {
                            this.setState({ list: data.data })
                            // if (status == 'view') {
                            this.getTableData(data.data)
                            this.setState({ showTable: false })
                            this.setState({ isLoading: false })
                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        }
    }

    getVisaApplicationFormSummary(status, value) {
        const { exhibitionID, ExhRegistrationID } = this.state
        console.log(status, value)
        if (status == 'exhibitionID') {
            this.setState({ exhibitionID: value })
            if (value != 'select') {
                this.setState({ isLoading: true })
                this.props.getVisaApplicationFormReport_action(parseInt(value), ExhRegistrationID == '' || ExhRegistrationID == null ? 0 : ExhRegistrationID)
                    .then((data) => {
                        console.log(data)
                        if (data.error != 1) {
                            this.setState({ list: data.data })
                            this.getTableData(data.data)
                            this.setState({ showTable: false })
                            this.setState({ isLoading: false })
                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
            else {
                console.log(value)
                this.setState({ list: [], rowArr: [], columnArr: [], showTable: false })
            }
        }
        if (status == 'ExhRegistrationID') {
            console.log(exhibitionID)
            this.setState({ ExhRegistrationID: value })
            if (exhibitionID != null && exhibitionID != '' && exhibitionID != 'select') {
                this.setState({ isLoading: true })
                this.props.getVisaApplicationFormReport_action(exhibitionID, value == 'select' ? 0 : value)
                    .then((data) => {
                        console.log(data)
                        if (data.error != 1) {
                            this.setState({ list: data.data })
                            // if (status == 'view') {
                            this.getTableData(data.data)
                            this.setState({ showTable: false })
                            this.setState({ isLoading: false })
                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        }
    }


    // get Fill List
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props.fill_action(actionID, exhibitionID, moduleID, keyValue, value)
            .then((data) => {
                if (data.error != 1) {
                    // Country List
                    if (actionID === exhibitionActionId) {
                        console.log("country data", data.data)
                        this.setState({
                            exhibitionList: data.data,
                            isLoading: false
                        })
                    }
                    // visa  id type
                    if (actionID === exhibitorNameActionId) {
                        console.log("visaIdTypeActionId data", data.data)
                        this.setState({
                            exhibitorList: data.data,
                            isLoading: false
                        })
                    }
                    if (actionID === visitorListActionId) {
                        this.setState({
                            VisitorList: data.data,
                            isLoading: false
                        })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    onViewClick() {
        const { exhibitionID, ExhRegistrationID } = this.state
        console.log(this.state.list)
        if (exhibitionID == '' || exhibitionID == null || exhibitionID == 'select') {
            toastError('Please select exhibition ID')
        }
        else {
            this.setState({ showTable: true })
        }
    }

    getTableData(list) {
        const { } = this.state
        // for column data 
        var columnArray = []
        var rowData = []
        console.log('columnArray', list);

        list.map((data, index) => {
            if (index == 0) {
                console.log('columnArray', data, Object.keys);

                Object.keys(data).map((key, index) => {

                    columnArray.push(key)
                })
            }
        })
        this.setState({ columnArr: columnArray })
        console.log('columnArray', columnArray);
        var xlRowdata = []
        // // for row data
        list.map((data, index) => {
            rowData.push(Object.values(data))
            xlRowdata.push(data)
        })
        this.setState({ rowArr: rowData })
        this.setState({ xlrowArr: xlRowdata })
        setTimeout(() => {

            console.log(rowData, xlRowdata)
        }, 500);
    }

    onDownloadClick() {
        const { list, columnArr, rowArr, exhibitionID, reportName } = this.state
        if (exhibitionID == '' || exhibitionID == null || exhibitionID == 0) {
            toastError('Please select exhibition ID')
        }
        else {
            this.setState({ showTable: true })
            return (
                <div className="row text-center">
                    {list.length > 0 && <Workbook filename={`${reportName}.xlsx`} style={{}} element={
                        <button type="button" className="btn ad-fil-btn m-0" >
                            download
                        </button>
                    }>
                        <Workbook.Sheet data={list} name="Sheet A">
                            {columnArr.map((value, index) =>
                                <Workbook.Column label={value} value={value} />
                            )
                            }
                        </Workbook.Sheet>
                    </Workbook>}
                </div>
            );
        }

    }

    getViewAccess() {
        const { reportAccessList, reportID } = this.state
        const moduleAccess = reportAccessList.find((item) => item.ReportID == reportID);
        if (moduleAccess) {
            return moduleAccess.ViewAccess;
        } else {
            return false
        }
        return false;
    }

    getDownloadAccess() {
        const { reportAccessList, reportID } = this.state
        const moduleAccess = reportAccessList.find((item) => item.ReportID == reportID);
        if (moduleAccess) {
            return moduleAccess.DownloadAccess;
        } else {
            return false
        }
        return false;
    }


    renderTable() {
        const { columnArr, rowArr } = this.state
        console.log('k')
        return (
            <>
                <div className="table-responsive mb-4">
                    <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1">
                        <thead> <tr>
                            {
                                columnArr.length > 0 && columnArr.map((data, index) => (
                                    <>  {(<th>{data}</th>)}</>
                                ))
                            }    </tr>

                        </thead>

                        <tbody>
                            {
                                rowArr.length > 0 ?
                                    rowArr.map((data, i) => (
                                        <tr key={i}>
                                            {data != null && data != '' && data.map((element, index) => (
                                                <>
                                                    <td>{element}
                                                    </td></>

                                            ))}
                                        </tr>
                                    )) : <tr><td colSpan={3} style={{ textAlign: 'center' }}>Record not found</td></tr>
                            }
                        </tbody>
                    </table>
                </div>
            </>
        )
    }

    render() {
        const { isLoading, reportID, reportName, rowArr, exhibitorList, showTable, exhibitionID, ExhRegistrationID, exhibitionList, xlrowArr, columnArr, list, VisitorList, VisitorID } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                <div className={JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ? "with-dr-container" : "with-dr-container com-height"}>
                    <AdminDrawer />
                    <div className="row">
                        <div className="step-name-space" style={{ zIndex: 9 }}>
                            <p>{reportName}</p>
                        </div>
                    </div>

                    <div className="main-container">
                        <div className="row mt-5 m-2">
                            <div className="col-xl-3">
                                <label htmlFor="validationCustom03">Select Exhibiton <span className="text-red">*</span></label>
                                <select className="form-control"
                                    value={exhibitionID}
                                    // disabled={isView}
                                    onChange={(e) => this.reportIdCondition("exhibitionID", e.target.value != '' && e.target.value)}  >
                                    <option value={'select'}>Select</option>
                                    {
                                        exhibitionList.length > 0 && exhibitionList.map(data => (
                                            <option value={data.ExhibitionID} key={data.ExhibitionID}>{data.ExhibitionName}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            {reportName != 'Stall Booking Summary' ?
                                <>
                                    {reportName != 'Visitor Badges' ?
                                        <div className="col-xl-3">
                                            <label htmlFor="validationCustom03">Select Exhibitor company</label>
                                            <select className="form-control"
                                                value={ExhRegistrationID}
                                                // disabled={isView}
                                                onChange={(e) => this.reportIdCondition('ExhRegistrationID', e.target.value != '' && e.target.value)}  >
                                                <option value={'select'}>Select</option>
                                                <option value={0}>All</option>

                                                {
                                                    exhibitorList.length > 0 && exhibitorList.map(data => (
                                                        <option value={data.ExhRegistrationID} key={data.ExhRegistrationID}>{data.CompanyName}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        :
                                        <div className="col-xl-3">
                                            <label htmlFor="validationCustom03">Select Visitor company</label>
                                            <select className="form-control"
                                                value={VisitorID}
                                                // disabled={isView}
                                                onChange={(e) => this.reportIdCondition("VisitorID", e.target.value != '' && e.target.value)}  >
                                                <option value={'select'}>Select</option>
                                                {
                                                    VisitorList.length > 0 && VisitorList.map(data => (
                                                        <option value={data.ExhVisitorID} key={data.ExhVisitorID}>{data.CompanyName}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    }
                                </>
                                : <></>}
                            <div className="btn-group mt-4 save-can d-block" style={{ float: 'right', right: '0px' }} role="group" aria-label="Basic example">
                                {reportName != 'Stall Booking Summary' ?
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary p-1 m-2"
                                        style={{ width: 140, fontSize: 15, }}
                                        disabled={!this.getViewAccess()}
                                        onClick={this.onViewClick}
                                    >
                                        View
                                    </button>
                                    : <></>
                                }
                                {list.length > 0 && this.getDownloadAccess() ?
                                    <>
                                        {reportName != 'Stall Booking Summary' ?
                                            <Workbook filename={`${reportName}.xlsx`} style={{}} element={
                                                <button className="btn btn-outline-primary p-1 ml-3"
                                                    disabled={!this.getDownloadAccess()}
                                                    style={{ width: 140, fontSize: 15 }} >
                                                    download
                                                </button>
                                            }>
                                                <Workbook.Sheet data={xlrowArr} name="Sheet A">
                                                    {columnArr.map((value, index) =>
                                                        <Workbook.Column label={value} value={value} />
                                                    )
                                                    }
                                                </Workbook.Sheet>
                                            </Workbook>
                                            :
                                            <button className="btn btn-outline-primary p-1" style={{ width: 140, fontSize: 15 }}
                                                onClick={() => this.handleDownload()}
                                            >
                                                download
                                            </button>
                                        }
                                    </>
                                    : <button className="btn btn-outline-primary p-1" style={{ width: 140, fontSize: 15 }} disabled={true}>
                                        download
                                    </button>
                                }
                            </div>
                        </div>
                        {showTable && this.renderTable()}
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(connect(null, { fill_action, getAirConnectionReport_action, getWaterConnectionReport_action, getPowerConnectionReport_action, getFrightForwarderReport_action, getFasciaReport_action, getEDirectoryFormReport_action, getMeetingRoomFormReport_action, getExhBadgesFormReport_action, getExhibitDetailFormReport_action, getExhContractorBadgeFormReport_action, getStallBookingSummaryFormReport_action, getStallDesignFormFormReport_action, getVisitorBadgeFormFormReport_action, getStallPossessionFormReport_action, getVisaApplicationFormReport_action })(ManualFormReport))