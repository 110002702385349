import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action, fill_master_action } from '../../../../../actions/fillCombo_action';
import { cityActionId, countryActionId, toastError, stalltypeMasterListActionId, dashboardDownloadListId, countryMasterListActionId, toastSuccess } from "../../../../../utils/constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import Exh_chapter from '../exh_chapter/exh_chapter';
import Resizer from "react-image-file-resizer";
// import ManageArea from './manage_area/manage_area';
import Exh_timeline from '../exh_timeline/exh_timeline';
import ManageAreaMaster from '../manage_area_master/manage_area_master';
import ManualFormsMaster from "../manual_froms_master/manual_forms_master";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import { useNavigate } from "react-router-dom";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import { data } from "jquery";
import {
    addExhibitionMaster_action, getExhibitionMasterDetails_action, updateExhibitionMaster_action, deleteExhibitionDocument_action
} from "../../../../../actions/admin_module_action/master_action/exhibition_master_action/exhibition_master_action";
import {
    updateExhibitionConfigration_action, getExhibitionConfigrationDetails_action
} from "../../../../../actions/admin_module_action/master_action/exhibition_master_action/configration_action";
import Exh_promoCode from "../exh_promoCode/exh_promoCode";

class ExhibitionConfigration extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isDelOpen: false,

            exhibitionSignature: '',
            signatureFileType: "",
            signatureName: "",
            signatureFilePath: "",
            exhibitionUndertaking: '',
            undertakingFileType: "",
            undertakingName: "",
            undertakingFilePath: "",
            exhibitionRandR: '',
            RandRFileType: "",
            RandRName: "",
            RandRFilePath: "",
            waterMark: '',
            waterMarkName: '',
            waterMarkFilepath: '',
            waterMartFileType: '',
            deleteKey: "",

            exhibitionMailHeader: '',
            mailHeaderFiletype: '',
            mailHeaderName: '',
            mailHeaderFilePath: '',
            exhibitionMailFooter: '',
            mailFooterFiletype: '',
            mailFooterName: '',
            mailFooterFilePath: '',

            isHighlight: false,
            isShowMachineryGuidelines: false,
            isShowChapterNotes: false,
            isSendLoginCredentials: false,
            isVisitorSeminar: false,
            isVisitorB2B: false,
            isShowExhibitorManualFormPaidBadge: false,
            dashboardDownloadList: [],
            ExihibitionID: '',
            IS_SSI_Manual_Approved: false,
            isAgreeRule: false,
            isAgreeMFG: false,
            isAgreeClaim: false,
            isGstApplicable: false,
            isChargesForOpenSide: false,
            isDoubleStorey: false,

            OpenSide: '',
            OpenSideName: '',
            OpenSideFilepath: '',
            OpenSideFileType: '',

            DoubleStorey: '',
            DoubleStoreyName: '',
            DoubleStoreyFilepath: '',
            DoubleStoreyFileType: '',
            isShowVaccination: false,
            isEnableINRCurrencyForFF: false,
            isEnableUSDCurrencyForFF: false,
            isWaitListEnable: false,
            isFullPerformaGenerate: '',
            isAgreeConsent: ""
        }
        this.getMasterFillList = this.getMasterFillList.bind(this)
        this.onFilePick = this.onFilePick.bind(this)
        this.resizeFile = this.resizeFile.bind(this)
        this.onCrossClick = this.onCrossClick.bind(this)
        this.onDocDelete = this.onDocDelete.bind(this)
        this.updateExhibitionConfigration = this.updateExhibitionConfigration.bind(this)
        this.onPdfSelect = this.onPdfSelect.bind(this)
        this.onDownloadListClick = this.onDownloadListClick.bind(this)
        this.getExhibitionConfigrationDetails = this.getExhibitionConfigrationDetails.bind(this)
        this.handleDelete = this.handleDelete.bind(this)

    }

    componentDidMount() {
        this.setState({
            ExihibitionID: this.props.location.state.ExihibitionID != undefined ? (this.props.location.state != null && this.props.location.state.ExihibitionID != null ? this.props.location.state.ExihibitionID : '') : this.props.id != null && this.props.id != undefined ? this.props.id : '',
        })
        setTimeout(() => {
            console.log(this.state.ExihibitionID)
            this.getMasterFillList(dashboardDownloadListId, this.state.ExihibitionID, '', '', '',)
            this.getExhibitionConfigrationDetails()
        }, 200);

    }

    getMasterFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        // if (actionID == countryActionId) {
        this.props.fill_master_action(actionID, exhibitionID, moduleID, keyValue, value)
            .then((data) => {
                if (data.error != 1) {
                    // Country List
                    if (actionID === dashboardDownloadListId) {
                        this.setState({
                            dashboardDownloadList: data.data,
                            // TempCountryList: data.data,
                            isLoading: false,
                            // allocateCountryList: alloCountryList
                        })
                        console.log("dashboardDownloadList data", data.data)
                    }
                }
            })
        // }
    }

    getExhibitionConfigrationDetails() {
        const { ExihibitionID, allocateStalltypeList, exhibitionRandR, allocateCountryList } = this.state
        this.setState({ isLoading: true })
        this.props.getExhibitionConfigrationDetails_action(ExihibitionID)
            .then((data) => {
                if (data.error != 1) {
                    console.log("main Data---------------------->", data.data[0])
                    this.setState({
                        isHighlight: data.data[0].isShowHighlightChapters,
                        isShowMachineryGuidelines: data.data[0].isShowMachineryGuidelines,
                        isShowChapterNotes: data.data[0].isShowChapterNotes,
                        isSendLoginCredentials: data.data[0].isSendLoginCredentials,
                        isVisitorSeminar: data.data[0].isVisitorSeminar,
                        isVisitorB2B: data.data[0].IsVisitorB2B,
                        isShowExhibitorManualFormPaidBadge: data.data[0].isShowExhibitorManualFormPaidBadge,
                        IS_SSI_Manual_Approved: data.data[0].IS_SSI_Manual_Approved,
                        isAgreeRule: data.data[0].isAgreeRule,
                        isAgreeMFG: data.data[0].isAgreeMFG,
                        isAgreeClaim: data.data[0].isAgreeClaim,
                        isGstApplicable: data.data[0].isGstApplicable,
                        isChargesForOpenSide: data.data[0].isChargesForOpenSide,
                        isDoubleStorey: data.data[0].isDoubleStorey,
                        isShowVaccination: data.data[0].isShowVaccination,
                        isEnableINRCurrencyForFF: data.data[0].isEnableINRCurrencyForFF,
                        isEnableUSDCurrencyForFF: data.data[0].isEnableUSDCurrencyForFF,
                        isWaitListEnable: data.data[0].isWaitListEnable,
                        isFullPerformaGenerate: data.data[0].isFullPerformaGenerate,
                        isAgreeConsent: data.data[0].isAgreeConsent,
                    })
                    if (data.data[0] != null) {
                        var signatureFilePath = data.data[0]['signature'] != null ? data.data[0]['signature'] : ""
                        var array = signatureFilePath != null && signatureFilePath != "" && signatureFilePath.split("/")
                        const signatureName = array[array.length - 1]

                        var mailHeaderFilePath = data.data[0]['MailHeaderPath'] != null ? data.data[0]['MailHeaderPath'] : ""
                        var array = mailHeaderFilePath != null && mailHeaderFilePath != "" && mailHeaderFilePath.split("/")
                        const mailHeaderName = array[array.length - 1]

                        var mailFooterFilePath = data.data[0]['MailFooterPath'] != null ? data.data[0]['MailFooterPath'] : ""
                        var array = mailFooterFilePath != null && mailFooterFilePath != "" && mailFooterFilePath.split("/")
                        const mailFooterName = array[array.length - 1]

                        var undertakingFilePath = data.data[0]['undertaking'] != null ? data.data[0]['undertaking'] : ""
                        var array2 = undertakingFilePath != null && undertakingFilePath != "" && undertakingFilePath.split("/")
                        const undertakingName = array2[array2.length - 1]

                        var RandRFilePath = data.data[0]['RulesAndRegulations'] != null ? data.data[0]['RulesAndRegulations'] : ""
                        var array3 = RandRFilePath != null && RandRFilePath != "" && RandRFilePath.split("/")
                        const RandRName = array3[array3.length - 1]

                        var waterMarkFilePath = data.data[0]['RptWaterMark'] != null ? data.data[0]['RptWaterMark'] : ""
                        var array4 = waterMarkFilePath != null && waterMarkFilePath != "" && waterMarkFilePath.split("/")
                        const waterMarkName = array4[array4.length - 1]

                        var OpenSideFilePath = data.data[0]['OpenSidePath'] != null ? data.data[0]['OpenSidePath'] : ""
                        var array5 = OpenSideFilePath != null && OpenSideFilePath != "" && OpenSideFilePath.split("/")
                        const OpenSideName = array5[array5.length - 1]

                        var DoubleStoreyFilePath = data.data[0]['DoubleStoreyPath'] != null ? data.data[0]['DoubleStoreyPath'] : ""
                        var array6 = DoubleStoreyFilePath != null && DoubleStoreyFilePath != "" && DoubleStoreyFilePath.split("/")
                        const DoubleStoreyName = array6[array6.length - 1]


                        this.setState({
                            mailHeaderName: mailHeaderName,
                            mailFooterName: mailFooterName,
                            signatureName: signatureName,
                            signatureFilePath: signatureFilePath,
                            exhibitionSignature: signatureFilePath,
                            undertakingName: undertakingName,
                            undertakingFilePath: undertakingFilePath,
                            exhibitionUndertaking: undertakingFilePath,
                            RandRName: RandRName,
                            RandRFilePath: RandRFilePath,
                            exhibitionRandR: RandRFilePath,

                            waterMarkName: waterMarkName,
                            waterMarkFilepath: waterMarkFilePath,
                            waterMark: waterMarkFilePath,

                            OpenSideName: OpenSideName,
                            OpenSideFilepath: OpenSideFilePath,
                            OpenSide: OpenSideFilePath,

                            DoubleStoreyName: DoubleStoreyName,
                            DoubleStoreyFilepath: DoubleStoreyFilePath,
                            DoubleStorey: DoubleStoreyFilePath,

                        })
                    }
                    console.log('waterMarkFilePath', waterMarkFilePath)
                    // this.state.countryList.map(id => parseInt(id.CountryCode) == maindata[0].CountryID && this.setState({ Country: id.Description }))
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // On Document Delete Modal Toggle
    onDocDelete(key) {
        const { isDelOpen } = this.state
        this.setState({ isDelOpen: !isDelOpen, deleteKey: key })
    }


    onFilePick = async (e, file, key) => {
        e.preventDefault()
        const { waterMark, waterMarkFilepath, waterMarkName, mailFooterFilePath, mailFooterFiletype, mailFooterName, mailHeaderFilePath, mailHeaderFiletype, mailHeaderName, signatureFilePath, signatureFileType, signatureName, waterMartFileType, RandRFilePath, RandRFileType, exhibitionRandR } = this.state
        console.log('file', file)
        if (file.length != 0) {
            if (key == 2 ? file[0]['size'] >= 10 * 1024 * 1024 : file[0]['size'] >= 5 * 1024 * 1024) {
                key == 2 ? toastError('File size more than 10mb are not allow.') :
                    toastError('File size more than 5mb are not allow.')
            } else {
                var image = '';
                if (key == 1 || key == 2 || key == 3 || key == 6 || key == 7 || key == 8) {
                    if (
                        file[0]['name'].toString().toLowerCase().includes('.jpeg') ||
                        file[0]['name'].toString().toLowerCase().includes('.png') ||
                        file[0]['name'].toString().toLowerCase().includes('.jpg')
                    ) {
                        if (file[0]['name'].toString().toLowerCase().trim().includes('.jpg') ||
                            file[0]['name'].toString().toLowerCase().trim().includes('.png') ||
                            file[0]['name'].toString().toLowerCase().trim().includes('.jpeg')
                        ) {
                            image = await this.resizeFile(file[0]);
                            if (key == 1) {
                                this.setState({ exhibitionLogo: image })
                            } else if (key == 2) {
                                this.setState({ exhibitionBanner: image })
                            } else if (key == 3) {
                                this.setState({ exhibitionSignature: image })
                            } else if (key == 6) {
                                this.setState({ waterMark: image })
                            } else if (key == 7) {
                                this.setState({ exhibitionMailHeader: image })
                            } else if (key == 8) {
                                this.setState({ exhibitionMailFooter: image })
                            }
                        }
                        var fileType = file[0]['type']
                        var fileTypeList;
                        if (fileType.includes("/")) {
                            fileTypeList = fileType.split("/")

                        }
                        if (key == 1) {
                            this.setState({ logoFileType: fileTypeList.length > 0 ? fileTypeList[1] : "" })
                        } else if (key == 2) {
                            this.setState({ bannerFileType: fileTypeList.length > 0 ? fileTypeList[1] : "" })
                        } else if (key == 3) {
                            this.setState({ signatureFileType: fileTypeList.length > 0 ? fileTypeList[1] : "" })
                        } else if (key == 6) {
                            this.setState({ waterMartFileType: fileTypeList.length > 0 ? fileTypeList[1] : "" })
                        } else if (key == 7) {
                            this.setState({ mailHeaderFiletype: fileTypeList.length > 0 ? fileTypeList[1] : "" })
                        } else if (key == 8) {
                            this.setState({ mailFooterFiletype: fileTypeList.length > 0 ? fileTypeList[1] : "" })
                        }
                    } else {
                        toastError('Only .jpeg, .png, .jpg files are allowed')
                    }
                } else if (key == 4 || key == 5 || key == 9 || key == 10) {
                    if (
                        file[0]['name'].toString().toLowerCase().includes('.pdf')
                    ) {
                        if (file[0]['name'].toString().toLowerCase().trim().includes('.pdf')
                        ) {
                            image = await this.onPdfSelect(file[0]);
                            if (key == 4) {
                                this.setState({ exhibitionUndertaking: image })
                            } else if (key == 5) {
                                this.setState({ exhibitionRandR: image })
                            } else if (key == 9) {
                                this.setState({ OpenSide: image })
                            } else if (key == 10) {
                                this.setState({ DoubleStorey: image })
                            }
                        }
                        var fileType = file[0]['type']
                        var fileTypeList;
                        if (fileType.includes("/")) {
                            fileTypeList = fileType.split("/")

                        }
                        if (key == 4) {
                            this.setState({ undertakingFileType: fileTypeList.length > 0 ? fileTypeList[1] : "" })
                        } else if (key == 5) {
                            this.setState({ RandRFileType: fileTypeList.length > 0 ? fileTypeList[1] : "" })
                        } else if (key == 9) {
                            this.setState({ OpenSideFileType: fileTypeList.length > 0 ? fileTypeList[1] : "" })
                        } else if (key == 10) {
                            this.setState({ DoubleStoreyFileType: fileTypeList.length > 0 ? fileTypeList[1] : "" })
                        }
                    } else {
                        toastError('Only .jpeg, .png, .jpg files are allowed')
                    }
                }
            }
        }
        console.log(' waterMark', RandRFilePath, ' waterMark', RandRFileType, ' waterMark', exhibitionRandR, ' waterMark', signatureFilePath, ' waterMark', signatureName, 'header')
    }

    onPdfSelect = (file) =>
        new Promise((resolve) => {
            let base64;
            // FileReader function for read the file.
            let fileReader = new FileReader();
            // Onload of file read the file content
            fileReader.onload = function (fileLoadedEvent) {
                base64 = fileLoadedEvent.target.result;
                // Print data in console
                resolve(base64);
            };
            // Convert data to base64
            fileReader.readAsDataURL(file);
        });

    resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                500,
                600,
                'JPEG',
                80,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64",
                300,
                300,
            );
        });

    onCrossClick(key) {
        if (key == 1) {
            this.setState({ exhibitionLogo: "" })
            document.getElementById("path").value = ""
        } else if (key == 2) {
            this.setState({ exhibitionBanner: "" })
            document.getElementById("path2").value = ""
        } else if (key == 3) {
            this.setState({ exhibitionSignature: "" })
            document.getElementById("path3").value = ""
        } else if (key == 7) {
            this.setState({ exhibitionMailHeader: "" })
            document.getElementById("path7").value = ""
        } else if (key == 8) {
            this.setState({ exhibitionMailFooter: "" })
            document.getElementById("path8").value = ""
        } else if (key == 9) {
            this.setState({ OpenSide: "" })
            document.getElementById("path9").value = ""
        } else if (key == 10) {
            this.setState({ DoubleStorey: "" })
            document.getElementById("path10").value = ""
        }
    }

    updateExhibitionConfigration() {
        const { ExihibitionID, exhibitionSignature, signatureFileType, exhibitionMailFooter, exhibitionMailHeader, mailFooterFilePath, mailFooterFiletype, mailFooterName, mailHeaderFilePath, mailHeaderFiletype, mailHeaderName, signatureFilePath, exhibitionUndertaking, undertakingFileType, undertakingFilePath, exhibitionRandR, RandRFileType, RandRFilePath, isVisitorSeminar, isVisitorB2B, isHighlight, isShowMachineryGuidelines, isWaitListEnable, isShowChapterNotes, isSendLoginCredentials, waterMark, waterMarkFilepath, isShowExhibitorManualFormPaidBadge, waterMarkName, waterMartFileType, dashboardDownloadList, OpenSide, OpenSideFileType, OpenSideFilepath, DoubleStorey, DoubleStoreyFileType, DoubleStoreyFilepath, isAgreeRule, isAgreeMFG, isAgreeClaim, isDoubleStorey, isChargesForOpenSide, isGstApplicable, IS_SSI_Manual_Approved, isShowVaccination, isEnableUSDCurrencyForFF, isEnableINRCurrencyForFF, isFullPerformaGenerate, isAgreeConsent } = this.state

        this.setState({ isLoading: true })
        // console.log('waterMarkFilePath', waterMarkFilePath)
        this.props.updateExhibitionConfigration_action(ExihibitionID, exhibitionSignature, signatureFileType, signatureFilePath, exhibitionUndertaking, undertakingFileType, undertakingFilePath, exhibitionRandR, RandRFileType, RandRFilePath, isHighlight, isShowMachineryGuidelines, isShowChapterNotes, isSendLoginCredentials, isVisitorSeminar, isVisitorB2B, isShowExhibitorManualFormPaidBadge, dashboardDownloadList, waterMark, waterMartFileType, waterMarkFilepath, exhibitionMailHeader, mailHeaderFiletype, mailHeaderFilePath, exhibitionMailFooter, mailFooterFiletype, mailFooterFilePath, OpenSide, OpenSideFileType, OpenSideFilepath, DoubleStorey, DoubleStoreyFileType, DoubleStoreyFilepath, isAgreeRule, isAgreeMFG, isAgreeClaim, isDoubleStorey, isChargesForOpenSide, isGstApplicable, IS_SSI_Manual_Approved, isShowVaccination, isEnableINRCurrencyForFF, isEnableUSDCurrencyForFF, isWaitListEnable, isFullPerformaGenerate, isAgreeConsent)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ isLoading: false, is_update: true, RandRFileType: '', signatureFileType: '', undertakingFileType: '', waterMartFileType: '', mailFooterFiletype: '', mailHeaderFiletype: '', OpenSideFileType: '', DoubleStoreyFileType: '' })
                    toastSuccess("data updated successfully")
                    this.getExhibitionConfigrationDetails()

                    // window.location.reload()
                    // this.props.navigate('/exhibitionMasterAU',)
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
        // }
        // else {
        //     toastError("Pleaser Enter Mandatory Fields")
        // }
    }

    onDownloadListClick(id) {
        const { dashboardDownloadList } = this.state
        for (var i = 0; i < dashboardDownloadList.length; i++) {
            if (dashboardDownloadList[i].ExhibitionDownloadActionID == id) {
                dashboardDownloadList[i].Status = !dashboardDownloadList[i].Status
            }
        }
        this.setState({ dashboardDownloadList: dashboardDownloadList })
    }

    // Delete Document Delete
    handleDelete(e) {
        e.preventDefault()
        const { isDelOpen, ExihibitionID, deleteKey, exhibitionSignature, } = this.state
        this.setState({ isLoading: true })
        this.props.deleteExhibitionDocument_action(ExihibitionID, deleteKey)
            .then((data) => {
                if (data.error != 1) {
                    toastSuccess("Document Deleted SuccessFully")
                    this.setState({ isLoading: false, isDelOpen: !isDelOpen })
                    if (deleteKey == 2) {
                        this.setState({ exhibitionSignature: "", signatureFilePath: "", signatureFileType: "", signatureName: "" })
                    } else if (deleteKey == 3) {
                        this.setState({ exhibitionUndertaking: "", undertakingFilePath: "", undertakingFileType: "", undertakingName: "" })
                    } else if (deleteKey == 4) {
                        this.setState({ exhibitionRandR: "", RandRFilePath: "", RandRFileType: "", RandRName: "" })
                    } else if (deleteKey == 5) {
                        this.setState({ waterMark: "", waterMarkFilepath: "", waterMartFileType: "", waterMarkName: "" })
                    } else if (deleteKey == 6) {
                        this.setState({ exhibitionMailHeader: "", mailHeaderFilePath: "", mailHeaderFiletype: "", mailHeaderName: "" })
                    } else if (deleteKey == 7) {
                        this.setState({ exhibitionMailFooter: "", mailFooterFilePath: "", mailFooterFiletype: "", mailFooterName: "" })
                    } else if (deleteKey == 8) {
                        this.setState({ OpenSide: "", OpenSidePath: "", OpenSideFilePath: "", OpenSideFileType: "" })
                    } else if (deleteKey == 9) {
                        this.setState({ DoubleStorey: "", DoubleStoreyPath: "", DoubleStoreyFilePath: "", DoubleStoreyFileType: "" })
                    }
                    this.getExhibitionConfigrationDetails()
                } else {
                    toastError(data.msg)
                    this.setState({ isLoading: false })
                }
            }).catch((error) => {
                console.log(error)
            })
    }

    render() {
        const { isLoading, countryId, ExihibitionID, summaryPage, Name, Code, BankDetails, toDate, fromDate, Country, TempCountryList,
            isDisplayCountryList, countryList, address, is_add, exhibitionLogo, logoFileType, logoFilePath, logoName, exhibitionBanner,
            bannerFilePath, bannerName, isShowExhibitorManualFormPaidBadge, isDelOpen, isHighlight, isShowMachineryGuidelines, deleteKey,
            isShowChapterNotes,
            isSendLoginCredentials, isVisitorB2B, isVisitorSeminar, isWaitListEnable,
            exhibitionMailFooter, exhibitionMailHeader, exhibitionSignature, mailFooterFilePath, mailFooterFiletype, mailFooterName, mailHeaderFilePath, mailHeaderFiletype, mailHeaderName,
            isDownloadExhibitorSignature,
            isLETTERFROMVENUEKICC,
            isDownloadExitPass,
            isDownloadEDirectory,
            isDownloadVisitorDatabase,
            isDownloadPostEventReport,
            isParticipationCertificate,
            isNoDuescertificate,
            isDownloadBonafideCertificate,
            exhibitionUndertaking,
            undertakingFileType,
            undertakingName,
            undertakingFilePath,
            exhibitionRandR,
            RandRFileType,
            RandRName, waterMarkName,
            RandRFilePath, waterMark, waterMarkFilepath, waterMartFileType,
            IS_SSI_Manual_Approved,
            isAgreeRule,
            isAgreeMFG,
            isAgreeClaim,
            isGstApplicable,
            isDoubleStorey,
            isChargesForOpenSide,
            isShowVaccination,
            isEnableUSDCurrencyForFF,
            isEnableINRCurrencyForFF,
            isFullPerformaGenerate, isAgreeConsent
        } = this.state


        return (
            <>

                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <AdminDrawer />


                    <div className="main-container p-2">
                        <div className="col-xl-4">
                            <label htmlFor="validationCustom03" className="mt-3">
                                Signature (Upto 5MB){" "}
                            </label>
                            <div className="box">
                                <div className="js--image-preview" />

                                {
                                    this.state.signatureName != "" && this.state.signatureName != null ?
                                        <div style={{
                                            border: '1px solid #ccc',
                                            padding: '10px',
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            // marginTop: '10px'
                                        }}>
                                            <a
                                                href={this.state.signatureFilePath}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <span style={{ marginRight: '10px' }}
                                                >
                                                    {this.state.signatureName}
                                                </span>

                                            </a>
                                            <i className={`fa fa-times text-danger img-can}`}
                                                onClick={(e) => this.onDocDelete(2)}
                                            ></i>
                                        </div> : <label>
                                            <input
                                                type="file"
                                                id="path3"
                                                className="image-upload"
                                                accept=".jpg, .jpeg, .png"
                                                onChange={e => this.onFilePick(e, e.target.files, 3)}
                                            />
                                            {
                                                this.state.exhibitionSignature != "" &&
                                                <i class="fa fa-times text-danger img-can"
                                                    onClick={() => this.onCrossClick(3)}
                                                ></i>
                                            }
                                        </label>
                                }
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <label htmlFor="validationCustom03" className="mt-3">
                                Undertaking (Upto 5MB){" "}
                            </label>
                            <div className="box">
                                <div className="js--image-preview" />

                                {
                                    is_add ?
                                        <div className="upload-options mt-1">
                                            <label>
                                                <input
                                                    type="file"
                                                    id="path4"
                                                    className="image-upload"
                                                    accept=".pdf"
                                                    onChange={e => this.onFilePick(e, e.target.files, 4)}
                                                />
                                                {
                                                    exhibitionUndertaking != "" &&
                                                    <i class="fa fa-times text-danger img-can"
                                                        onClick={() => this.onCrossClick(4)}></i>
                                                }
                                            </label>

                                        </div>
                                        : undertakingName != "" && undertakingName != null ?
                                            <div style={{
                                                border: '1px solid #ccc',
                                                padding: '10px',
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                // marginTop: '10px'
                                            }}>
                                                <a
                                                    href={undertakingFilePath}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <span style={{ marginRight: '10px' }}
                                                    >
                                                        {undertakingName}
                                                    </span>

                                                </a>
                                                <i className={`fa fa-times text-danger img-can}`}
                                                    onClick={(e) => this.onDocDelete(3)}
                                                ></i>
                                            </div> : <label>
                                                <input
                                                    type="file"
                                                    id="path4"
                                                    className="image-upload"
                                                    accept=".pdf"
                                                    onChange={e => this.onFilePick(e, e.target.files, 4)}
                                                />
                                                {
                                                    exhibitionUndertaking != "" &&
                                                    <i class="fa fa-times text-danger img-can"
                                                        onClick={() => this.onCrossClick(4)}
                                                    ></i>
                                                }
                                            </label>
                                }
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <label htmlFor="validationCustom03" className="mt-3">
                                Rules and Regulations (Upto 5MB){" "}
                            </label>
                            <div className="box">
                                <div className="js--image-preview" />

                                {
                                    is_add ?
                                        <div className="upload-options mt-1">
                                            <label>
                                                <input
                                                    type="file"
                                                    id="path5"
                                                    className="image-upload"
                                                    accept=".pdf"
                                                    onChange={e => this.onFilePick(e, e.target.files, 5)}
                                                />
                                                {
                                                    exhibitionRandR != "" &&
                                                    <i class="fa fa-times text-danger img-can"
                                                        onClick={() => this.onCrossClick(5)}></i>
                                                }
                                            </label>

                                        </div>
                                        : RandRName != "" && RandRName != null ?
                                            <div style={{
                                                border: '1px solid #ccc',
                                                padding: '10px',
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                // marginTop: '10px'
                                            }}>
                                                <a
                                                    href={RandRFilePath}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <span style={{ marginRight: '10px' }}
                                                    >
                                                        {RandRName}
                                                    </span>

                                                </a>
                                                <i className={`fa fa-times text-danger img-can}`}
                                                    onClick={(e) => this.onDocDelete(4)}
                                                ></i>
                                            </div> : <label>
                                                <input
                                                    type="file"
                                                    id="path5"
                                                    className="image-upload"
                                                    accept=".pdf"
                                                    onChange={e => this.onFilePick(e, e.target.files, 5)}
                                                />
                                                {
                                                    exhibitionRandR != "" &&
                                                    <i class="fa fa-times text-danger img-can"
                                                        onClick={() => this.onCrossClick(5)}
                                                    ></i>
                                                }
                                            </label>
                                }
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <label htmlFor="validationCustom03" className="mt-3">
                                WaterMark Logo (Upto 5MB){" "}
                            </label>
                            <div className="box">
                                <div className="js--image-preview" />

                                {
                                    is_add ?
                                        <div className="upload-options mt-1">
                                            <label>
                                                <input
                                                    type="file"
                                                    id="path6"
                                                    className="image-upload"
                                                    accept=".jpg, .jpeg, .png"
                                                    onChange={e => this.onFilePick(e, e.target.files, 6)}
                                                />
                                                {
                                                    waterMark != "" &&
                                                    <i class="fa fa-times text-danger img-can"
                                                        onClick={() => this.onCrossClick(6)}></i>
                                                }
                                            </label>

                                        </div>
                                        : waterMarkName != "" && waterMarkName != null ?
                                            <div style={{
                                                border: '1px solid #ccc',
                                                padding: '10px',
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                // marginTop: '10px'
                                            }}>
                                                <a
                                                    href={waterMarkFilepath}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <span style={{ marginRight: '10px' }}
                                                    >
                                                        {waterMarkName}
                                                    </span>

                                                </a>
                                                <i className={`fa fa-times text-danger img-can}`}
                                                    onClick={(e) => this.onDocDelete(5)}
                                                ></i>
                                            </div> : <label>
                                                <input
                                                    type="file"
                                                    id="path6"
                                                    className="image-upload"
                                                    accept=".jpg, .jpeg, .png"
                                                    onChange={e => this.onFilePick(e, e.target.files, 6)}
                                                />
                                                {
                                                    waterMark != "" &&
                                                    <i class="fa fa-times text-danger img-can"
                                                        onClick={() => this.onCrossClick(6)}
                                                    ></i>
                                                }
                                            </label>
                                }
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <label htmlFor="validationCustom03" className="mt-3">
                                Mail Header Image (Upto 5MB){" "}
                            </label>
                            <div className="box">
                                <div className="js--image-preview" />

                                {
                                    this.state.mailHeaderName != "" && this.state.mailHeaderName != null ?
                                        <div style={{
                                            border: '1px solid #ccc',
                                            padding: '10px',
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            // marginTop: '10px'
                                        }}>
                                            <a
                                                href={this.state.mailHeaderFilePath}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <span style={{ marginRight: '10px' }}
                                                >
                                                    {this.state.mailHeaderName}
                                                </span>

                                            </a>
                                            <i className={`fa fa-times text-danger img-can}`}
                                                onClick={(e) => this.onDocDelete(6)}
                                            ></i>
                                        </div> : <label>
                                            <input
                                                type="file"
                                                id="path7"
                                                className="image-upload"
                                                accept=".jpg, .jpeg, .png"
                                                onChange={e => this.onFilePick(e, e.target.files, 7)}
                                            />
                                            {
                                                this.state.exhibitionMailHeader != "" &&
                                                <i class="fa fa-times text-danger img-can"
                                                    onClick={() => this.onCrossClick(7)}
                                                ></i>
                                            }
                                        </label>
                                }
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <label htmlFor="validationCustom03" className="mt-3">
                                Mail Footer Image (Upto 5MB){" "}
                            </label>
                            <div className="box">
                                <div className="js--image-preview" />

                                {
                                    this.state.mailFooterName != "" && this.state.mailFooterName != null ?
                                        <div style={{
                                            border: '1px solid #ccc',
                                            padding: '10px',
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            // marginTop: '10px'
                                        }}>
                                            <a
                                                href={this.state.mailFooterFilePath}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <span style={{ marginRight: '10px' }}
                                                >
                                                    {this.state.mailFooterName}
                                                </span>

                                            </a>
                                            <i className={`fa fa-times text-danger img-can}`}
                                                onClick={(e) => this.onDocDelete(7)}
                                            ></i>
                                        </div> : <label>
                                            <input
                                                type="file"
                                                id="path8"
                                                className="image-upload"
                                                accept=".jpg, .jpeg, .png"
                                                onChange={e => this.onFilePick(e, e.target.files, 8)}
                                            />
                                            {
                                                this.state.exhibitionMailFooter != "" &&
                                                <i class="fa fa-times text-danger img-can"
                                                    onClick={() => this.onCrossClick(8)}
                                                ></i>
                                            }
                                        </label>
                                }
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <label htmlFor="validationCustom03" className="mt-3">

                                <input type="checkbox" name="" key={isChargesForOpenSide}
                                    checked={isChargesForOpenSide}
                                    onClick={(e) => this.setState({ isChargesForOpenSide: !isChargesForOpenSide })}
                                />Rules For Open Side
                            </label>
                            <div className="box">
                                <div className="js--image-preview" />
                                {
                                    this.state.OpenSideName != "" && this.state.OpenSideName != null ?
                                        <div style={{
                                            border: '1px solid #ccc',
                                            padding: '10px',
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            // marginTop: '10px'
                                        }}>
                                            <a
                                                href={this.state.OpenSideFilepath}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <span style={{ marginRight: '10px' }}
                                                >
                                                    {this.state.OpenSideName}
                                                </span>

                                            </a>
                                            <i className={`fa fa-times text-danger img-can}`}
                                                onClick={(e) => this.onDocDelete(8)}
                                            ></i>
                                        </div> : <label>
                                            <input
                                                type="file"
                                                id="path9"
                                                className="image-upload"
                                                accept=".pdf"
                                                onChange={e => this.onFilePick(e, e.target.files, 9)}
                                            />
                                            {
                                                this.state.OpenSide != "" &&
                                                <i class="fa fa-times text-danger img-can"
                                                    onClick={() => this.onCrossClick(9)}
                                                ></i>
                                            }
                                        </label>
                                }
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <label htmlFor="validationCustom03" className="mt-3">
                                <input type="checkbox" name="" key={isDoubleStorey}
                                    checked={isDoubleStorey}
                                    onClick={(e) => this.setState({ isDoubleStorey: !isDoubleStorey })}
                                />
                                Rules For Double Storey
                            </label>
                            <div className="box">
                                <div className="js--image-preview" />
                                {
                                    this.state.DoubleStoreyName != "" && this.state.DoubleStoreyName != null ?
                                        <div style={{
                                            border: '1px solid #ccc',
                                            padding: '10px',
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            // marginTop: '10px'
                                        }}>
                                            <a
                                                href={this.state.DoubleStoreyFilepath}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <span style={{ marginRight: '10px' }}
                                                >
                                                    {this.state.DoubleStoreyName}
                                                </span>

                                            </a>
                                            <i className={`fa fa-times text-danger img-can}`}
                                                onClick={(e) => this.onDocDelete(9)}
                                            ></i>
                                        </div> : <label>
                                            <input
                                                type="file"
                                                id="path10"
                                                className="image-upload"
                                                accept=".pdf"
                                                onChange={e => this.onFilePick(e, e.target.files, 10)}
                                            />
                                            {
                                                this.state.DoubleStorey != "" &&
                                                <i class="fa fa-times text-danger img-can"
                                                    onClick={() => this.onCrossClick(10)}
                                                ></i>
                                            }
                                        </label>
                                }
                            </div>
                        </div>

                        <div class="row">
                            <div className="col-xl-4 mt-4">
                                <input type="checkbox" name="" key={isHighlight}
                                    checked={isHighlight}
                                    onClick={(e) => this.setState({ isHighlight: !isHighlight })}
                                />
                                Highlight Chapter on select
                            </div>
                            <div className="col-xl-4 mt-4">
                                <input type="checkbox" name="" key={isShowMachineryGuidelines}
                                    checked={isShowMachineryGuidelines}
                                    onClick={(e) => this.setState({ isShowMachineryGuidelines: !isShowMachineryGuidelines })}
                                />
                                Show Machinery Guidelines
                            </div>
                            <div className="col-xl-4 mt-4">
                                <input type="checkbox" name="" key={isShowChapterNotes}
                                    checked={isShowChapterNotes}
                                    onClick={(e) => this.setState({ isShowChapterNotes: !isShowChapterNotes })}
                                />
                                Show Chapter Notes
                            </div>
                            <div className="col-xl-4 mt-2">
                                <input type="checkbox" name="" key={isSendLoginCredentials}
                                    checked={isSendLoginCredentials}
                                    onClick={(e) => this.setState({ isSendLoginCredentials: !isSendLoginCredentials })}
                                />
                                Send Login Credentials
                            </div>
                            <div className="col-xl-4 mt-2">
                                <input type="checkbox" name="" key={isVisitorB2B}
                                    checked={isVisitorB2B}
                                    onClick={(e) => this.setState({ isVisitorB2B: !isVisitorB2B })}
                                />
                                Visitor B2b
                            </div>
                            <div className="col-xl-4 mt-2">
                                <input type="checkbox" name="" key={isVisitorSeminar}
                                    checked={isVisitorSeminar}
                                    onClick={(e) => this.setState({ isVisitorSeminar: !isVisitorSeminar })}
                                />
                                Visitor Seminar
                            </div>
                            <div className="col-xl-4 mt-2">
                                <input type="checkbox" name="" key={isShowExhibitorManualFormPaidBadge}
                                    checked={isShowExhibitorManualFormPaidBadge}
                                    onClick={(e) => this.setState({ isShowExhibitorManualFormPaidBadge: !isShowExhibitorManualFormPaidBadge })}
                                />
                                Show Exhibitor Paid Badge (Manual Form)
                            </div>
                            <div className="col-xl-4 mt-2">
                                <input type="checkbox" name="" key={IS_SSI_Manual_Approved}
                                    checked={IS_SSI_Manual_Approved}
                                    onClick={(e) => this.setState({ IS_SSI_Manual_Approved: !IS_SSI_Manual_Approved })}
                                />
                                SSI Manual Approved
                            </div>
                            <div className="col-xl-4 mt-2">
                                <input type="checkbox" name="" key={isAgreeRule}
                                    checked={isAgreeRule}
                                    onClick={(e) => this.setState({ isAgreeRule: !isAgreeRule })}
                                />
                                Agree Rule
                            </div>
                            <div className="col-xl-4 mt-2">
                                <input type="checkbox" name="" key={isAgreeMFG}
                                    checked={isAgreeMFG}
                                    onClick={(e) => this.setState({ isAgreeMFG: !isAgreeMFG })}
                                />
                                Agree MFG
                            </div>
                            <div className="col-xl-4 mt-2">
                                <input type="checkbox" name="" key={isAgreeClaim}
                                    checked={isAgreeClaim}
                                    onClick={(e) => this.setState({ isAgreeClaim: !isAgreeClaim })}
                                />
                                Agree Claim
                            </div>
                            <div className="col-xl-4 mt-2">
                                <input type="checkbox" name="" key={isGstApplicable}
                                    checked={isGstApplicable}
                                    onClick={(e) => this.setState({ isGstApplicable: !isGstApplicable })}
                                />
                                GST Applicable
                            </div>
                            <div className="col-xl-4 mt-2">
                                <input type="checkbox" name="" key={isShowVaccination}
                                    checked={isShowVaccination}
                                    onClick={(e) => this.setState({ isShowVaccination: !isShowVaccination })}
                                />
                                Show Vaccination Certificate
                            </div>
                            <div className="col-xl-4 mt-2">
                                <input type="checkbox" name="" key={isEnableINRCurrencyForFF}
                                    checked={isEnableINRCurrencyForFF}
                                    onClick={(e) => this.setState({ isEnableINRCurrencyForFF: !isEnableINRCurrencyForFF })}
                                />
                                Enable INR Currency For Air & Sea in Freight Forward
                            </div>

                            <div className="col-xl-4 mt-2">
                                <input type="checkbox" name="" key={isEnableUSDCurrencyForFF}
                                    checked={isEnableUSDCurrencyForFF}
                                    onClick={(e) => this.setState({ isEnableUSDCurrencyForFF: !isEnableUSDCurrencyForFF })}
                                />
                                Enable USD Currency For Air & Sea in Freight Forward
                            </div>

                            <div className="col-xl-4 mt-2">
                                <input type="checkbox" name="" key={isWaitListEnable}
                                    checked={isWaitListEnable}
                                    onClick={(e) => this.setState({ isWaitListEnable: !isWaitListEnable })}
                                />
                                WaitList Enable
                            </div>
                            <div className="col-xl-4 mt-2">
                                <input type="checkbox" name="" key={isFullPerformaGenerate}
                                    checked={isFullPerformaGenerate}
                                    onClick={(e) => this.setState({ isFullPerformaGenerate: !isFullPerformaGenerate })}
                                />
                                Generate 100 % PI
                            </div>
                            <div className="col-xl-4 mt-2">
                                <input type="checkbox" name="" key={isAgreeConsent}
                                    checked={isAgreeConsent}
                                    onClick={(e) => this.setState({ isAgreeConsent: !isAgreeConsent })}
                                />
                                Agree Terms & Conditions of Email, SMS and WhatsApp
                            </div>
                            {this.state.dashboardDownloadList.length > 0 && this.state.dashboardDownloadList.map((data, i) => (
                                < div className="col-xl-4 mt-2">
                                    <input type="checkbox" name="" key={data.ExhibitionDownloadActionID}
                                        checked={data.Status}
                                        onClick={(e) => this.onDownloadListClick(data.ExhibitionDownloadActionID)}
                                    />
                                    {data.DownloadName}
                                </div>
                            ))}


                        </div>
                        <div class="row mt-3">

                            <div class="col-md-12">

                                <div style={{ float: 'right', textAlign: 'right' }}>
                                    <button onClick={() => this.updateExhibitionConfigration()} class="btn rounded-0 btn-primary mt-3" style={{ padding: "5px 7px", marginLeft: "10px", width: "100px" }}>Save</button>
                                    {/* <button onClick={this.handleBack} class="btn rounded-0 btn-danger mt-3" style={{ padding: "5px 7px", marginLeft: "10px", width: "100px" }}>Back</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal isOpen={isDelOpen} onRequestClose={() => this.setState({ isDelOpen: false })}>
                        <div className="modal-dialog">
                            <div className="modal-content confirm-logout">
                                <div class="modal-header p-2">
                                    <h5 class="modal-title modal_title_text text-danger">Confirm Delete</h5>
                                    <button type="button " class="close pop-close" data-dismiss="modal" onClick={() => this.setState({ isDelOpen: false })} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
                                </div>
                                <div class="modal-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <p class="pop_label">Do you really want to delete this Document?</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer p-1">
                                    <div class="form-group text-right mod-fot">
                                        <button type="button" class="btn btn-del-pop"
                                            onClick={(e) => this.handleDelete(e)}
                                        >Yes</button>
                                        <button type="button" class="btn btn-can-pop" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={() => this.setState({ isDelOpen: false })}>No</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Modal>
                </>
            </>)
    }
}

export default withRouter(connect(null, {
    fill_action, addExhibitionMaster_action, fill_master_action, getExhibitionMasterDetails_action, deleteExhibitionDocument_action,
    updateExhibitionMaster_action, updateExhibitionConfigration_action, getExhibitionConfigrationDetails_action
})(ExhibitionConfigration));