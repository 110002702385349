import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { login_action, forgetCheckedEmail_action, forgetGetOtp_action, forgetUpdatePassword_action, get_login_pass_action } from '../../actions/user_login_auth_actions/user_login_auth_action';
import { withRouter } from '../../utils/withRouter';
import Loader from '../../utils/Loader'
import { clearSessionStorage, toastError } from '../../utils/constants'
import Header from '../../common_components/Header';
import { emailValidator, loginPasswordValidator, otpValidator, confirmPasswordValidator } from '../../utils/Validator'
import Modal from "react-modal";
import Footerlogin from '../../common_components/Footerlogin';
import AdminHeader from '../../common_components/admin_module_header';

/* 
 * mapDispatchToProps
*/
// const mapDispatchToProps = dispatch => ({
//     loginAction: () => dispatch(loginAction())
// })

/* 
 * mapStateToProps
*/


// const mapStateToProps = state => {
//   return {
//     loginData: state,
//   };
// }


class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userEmail: '',
      password: '',
      is_showpass: false,
      isOpen: false,
      email: '',
      is_forgotPassword: false,
      isLoading: false,
      is_rememberme: false,
      verifyEmail: '',
      is_verifyEmail: false,
      is_loginForgotPassword: false,
      getOtp: '',
      forgetNewPass: '',
      forgetConfirmPass: ''
    }
    this.handleObscure = this.handleObscure.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.getLoginPass = this.getLoginPass.bind(this)
  }




  handleSubmit(isIndirectAccess) {
    // event.preventDefault();
    const { userEmail, password } = this.state
    // this.props.navigate("/dashboard");
    console.log("testing-------------->", userEmail, password)
    var errMsg = emailValidator(userEmail)
    var errMsg2 = loginPasswordValidator(password)
    if (errMsg != '' || errMsg2 != '') {
      toast.error(errMsg != '' ? errMsg : errMsg2 != '' ? errMsg2 : '', {
        theme: 'colored',
        autoClose: 1000,
        hideProgressBar: true,
      })
    } else {
      if (userEmail != ' ' && password != ' ') {
        this.setState({ isLoading: true })
        this.props
          .login_action(userEmail.toString().trim(), password.toString().trim())
          .then((data) => {
            if (data.error != 1) {
              // const dummyData = {
              //   // Dummy Data
              //   accessRights: [
              //     { ModuleId: "1", ModuleName: "Receipt", isUpdate: false, isAdd: true, isDelete: false, isView: true },
              //     { ModuleId: "2", ModuleName: "Agent", isUpdate: false, isAdd: true, isDelete: false, isView: true }
              //   ]
              // }
              // sessionStorage.setItem('DummyLoginData', JSON.stringify(dummyData))
              sessionStorage.clear()
              if (data.data['RoleID'] == 4 || data.data['RoleID'] == 1) {
                sessionStorage.setItem('IsAgentDirectLogin', true)
              }
              if (isIndirectAccess) {
                sessionStorage.setItem('isIndirectAccess', true)
              } else {
                sessionStorage.setItem('isIndirectAccess', false)
              }
              // sessionStorage.setItem("IsDirect", true)
              // sessionStorage.setItem("IsInDirect", false)
              sessionStorage.setItem("userEmailSS", userEmail)
              sessionStorage.setItem("tokenSS", "token")
              sessionStorage.setItem("AccountIDSS", data.data['AccountID'])
              sessionStorage.setItem("loginDataSS", JSON.stringify(data.data))
              console.log("login data", data.data['FirstName'].charAt(0))
              console.log("login data", data.data['LastName'].charAt(0))
              sessionStorage.setItem("firstNameSS", data.data['FirstName'])
              sessionStorage.setItem("lastNameSS", data.data['LastName'])
              sessionStorage.setItem("profileNameSS", `${data.data['FirstName'].charAt(0)}${data.data['LastName'].charAt(0)}`)
              sessionStorage.setItem("RoleIDSS", data.data['RoleID'])
              if (password.includes('CSR')) {
                this.props.navigate("/changePassword", { state: { is_forgotPassword: true } });
              }
              else {
                this.props.navigate("/selectExhibition");
              }

              this.setState({ isLoading: false })
            }
            else {
              this.setState({ isLoading: false })
              toast.error(data.msg, {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
              })
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
      else {
        this.setState({ isLoading: false })

        toast.error('Please check user name and password', {
          theme: 'colored',
          autoClose: 1000,
          hideProgressBar: true,
        })
      }
    }
  }

  componentDidMount() {
    clearSessionStorage('')
    if (this.props.location != null) {
      console.log('pathname : ', this.props.location.pathname);
      var url;
      if (this.props.location.pathname != null && this.props.location.pathname.includes('/login/')) {
        url = this.props.location.pathname != null && this.props.location.pathname.split("/login/")
      } else if (this.props.location.pathname != null) {
        url = this.props.location.pathname != null && this.props.location.pathname.split("/")
      }

      console.log('url : ', url);
      var loginContent = url[url.length - 1]
      console.log('loginContent : ', loginContent);
      var splitContent = loginContent != null && loginContent != "" && loginContent.split("--")
      console.log('splitContent : ', splitContent[0], splitContent[1]);
      // var passwordContent = splitContent.length > 2 ? splitContent[2].replace('%C2%A7', '/') : splitContent[2]
      // console.log("content", splitContent[2])
      this.getLoginPass(splitContent[0], splitContent[1]).then(res => {
        res && this.setState({
          userEmail: splitContent[1],
          password: res
        }, () => {
          if (loginContent != "" && loginContent != "login") {
            this.handleSubmit(true);

          }
        })
      })
    }
  }

  // forget login password

  getLoginPass(exhid, username) {
    return new Promise((resolve, reject) => {
      if (exhid != null && username != null && exhid != undefined && username != undefined) {
        this.props.get_login_pass_action(exhid, username)
          .then((data) => {
            if (data.error != 1) {
              console.log(data.data[0].Password)
              resolve(data.data[0].Password)
            } else {
              toastError(data.msg)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    })

  }

  onVerifyEmail(e) {
    const { verifyEmail } = this.state
    // console.log("testing-------------->", verifyEmail)
    var errMsg = emailValidator(verifyEmail)
    if (errMsg != '') {
      toast.error(errMsg != '' ? errMsg : '', {
        theme: 'colored',
        autoClose: 1000,
        hideProgressBar: true,
      })
    }
    else {
      if (verifyEmail != ' ') {
        this.setState({ isLoading: true })
        this.isCheckEmailPresent(e);
      }
    }
  }

  isCheckEmailPresent(e) {
    e.preventDefault()
    const { verifyEmail, isOpen, is_verifyEmail, } = this.state
    this.props
      .forgetCheckedEmail_action(verifyEmail)
      .then((data) => {
        if (data.error != 1) {
          toast.success('OTP Send on your Email', {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
          console.log("FORG", data.data[0]['UserID'])
          sessionStorage.setItem("userIdForget", data.data[0]['UserID'])
          this.getOtp()
          this.setState({ is_verifyEmail: true })
          this.setState({ isLoading: false })
        }
        else {
          this.setState({ isLoading: false, email: '' })
          toast.error(data.msg, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getOtp() {
    const { verifyEmail } = this.state
    this.props
      .forgetGetOtp_action(verifyEmail)
      .then((data) => {
        if (data.error != 1) {
          // session
          sessionStorage.setItem('NODE_OTP', data.data)
          this.setState({ isLoading: false })

        }
        else {
          this.setState({ isLoading: false, email: '' })
          toast.error(data.msg, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  onVerifyOTP() {
    const { getOtp, is_verifyOTP, is_verifyEmail, adminOtp } = this.state
    // this.props.navigate("/dashboard");
    this.setState({
      adminOtp: sessionStorage.getItem('NODE_OTP')
    }, () => {
      console.log("adminOtp-------------->", adminOtp)
    })

    var errMsg = otpValidator(getOtp)

    setTimeout(() => {
      if (errMsg != '') {
        toast.error(errMsg != '' ? errMsg : '', {
          theme: 'colored',
          autoClose: 1000,
          hideProgressBar: true,
        })
      } else {
        if (getOtp != '') {
          if (sessionStorage.getItem('NODE_OTP') === getOtp) {
            this.setState({ is_loginForgotPassword: true })
            toast.success("OTP Verify", {
              theme: 'colored',
              autoClose: 3000,
              hideProgressBar: true,
            })
          }
          else {
            toast.error("OTP incorrect!", {
              theme: 'colored',
              autoClose: 3000,
              hideProgressBar: true,
            })
          }
        }
      }
    }, 500);
  }

  onForgetPassword(e) {
    e.preventDefault();
    const { forgetNewPass, forgetConfirmPass, isOpen, is_forgotPassword } = this.state
    this.setState({ isLoading: true })
    // this.props.navigate("/dashboard");
    console.log("testing-------------->", forgetNewPass, forgetConfirmPass)
    var errMsg = loginPasswordValidator(forgetNewPass)
    var errMsg2 = confirmPasswordValidator(forgetNewPass, forgetConfirmPass)
    console.log(errMsg);
    console.log(errMsg2);
    if (errMsg != '' || errMsg2 != '') {
      toast.error(errMsg != '' ? errMsg : errMsg2 != '' ? errMsg2 : '', {
        theme: 'colored',
        autoClose: 1000,
        hideProgressBar: true,
      })
      this.setState({ isLoading: false })
    } else {
      if (forgetNewPass != '' && forgetConfirmPass != '') {
        this.props
          .forgetUpdatePassword_action(forgetConfirmPass)
          .then((data) => {
            if (data.error != 1) {
              this.setState({ isLoading: false, isOpen: false })
              toast.success('Password Updated SuccessFully!', {
                theme: 'colored',
                autoClose: 1000,
                hideProgressBar: true,
              })
              // document.querySelector(".recovery").style.display = "none";
              this.clearValue()
              this.toggleModal()
            }
            else {
              this.setState({ isLoading: false, isOpen: !isOpen })
              toast.error(data.msg, {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
              })
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }

  }

  handleObscure() {
    const { is_showpass } = this.state
    this.setState({ is_showpass: !is_showpass })
  }

  //toggle model for forgot password.................................
  toggleModal() {
    const { isOpen, } = this.state
    this.setState({ isOpen: !isOpen })
    this.setState({
      verifyEmail: '',
      is_verifyEmail: false,
      is_loginForgotPassword: false,
      getOtp: ''
    })
  }


  handleKeyDown(e) {
    if (e.code === 'Enter') {
      this.onVerifyEmail(e)
    }
  }

  handleKeyDownOTP(e) {
    if (e.code === 'Enter') {
      this.onVerifyOTP(e)
    }
  }

  handleKeyDownForgotPass(e) {
    if (e.code === 'Enter') {
      this.onForgetPassword(e)
    }
  }


  render() {
    const { userEmail, password, isLoading, isOpen, verifyEmail, is_showpass, is_forgotPassword, is_verifyEmail, getOtp, forgetNewPass, forgetConfirmPass, is_loginForgotPassword } = this.state
    return (
      <>

        {isLoading ? (
          <Loader></Loader>
        ) : (
          null
        )}
        <Modal isOpen={isOpen} onRequestClose={this.toggleModal}>
          <div className="modal-dialog custom_modal_dialog">
            <div className="modal-content" style={{ marginTop: 110 }}>
              <div className="modal-body pl-0 pr-0">
                <div className="container">
                  <div className="row mt-4">
                    <img
                      src="images/6195699.png"
                      style={{ width: 110, margin: "0 auto" }}
                    />
                    <h3 className="text-center mb-1 mt-2">
                      <strong>Forgot Password</strong>
                    </h3>
                    <p className="text-center mb-4">
                      Enter your email and we'll send you a link to reset your
                      password
                    </p>
                    <div className="col-xl-12 mt-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your register email id"
                        disabled={is_verifyEmail}
                        value={verifyEmail}
                        onChange={(e) => this.setState({ verifyEmail: e.target.value })}
                        onKeyDown={e => this.handleKeyDown(e)}
                      />
                    </div>
                    {!is_loginForgotPassword ?
                      <>
                        {is_verifyEmail ?
                          <div className="col-xl-12 mt-4">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter OTP"
                              value={getOtp}
                              onChange={(e) => this.setState({ getOtp: e.target.value })}
                              onKeyDown={e => this.handleKeyDownOTP(e)}
                            />
                          </div> :
                          <></>
                        }
                      </>
                      :
                      <>
                        <div className="col-xl-12 mt-4">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter New Password"
                            value={forgetNewPass}
                            onChange={(e) => this.setState({ forgetNewPass: e.target.value })}
                            onKeyDown={e => this.handleKeyDownForgotPass(e)}
                          />
                        </div>
                        <div className="col-xl-12 mt-4">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Confirm Password"
                            value={forgetConfirmPass}
                            onChange={(e) => this.setState({ forgetConfirmPass: e.target.value })}
                            onKeyDown={e => this.handleKeyDownForgotPass(e)}
                          />
                        </div>
                      </>
                    }
                  </div>

                  {
                    !is_verifyEmail ?

                      <button
                        className="btn mt-4 mb-1"
                        type="submit"
                        style={{
                          width: "100%",
                          display: "block",
                          background: "#007bff",
                          padding: 4
                        }}
                        onClick={(e) => this.onVerifyEmail(e)}
                      >
                        {" "}
                        Submit
                        {" "}
                      </button> :
                      <>
                        {!is_loginForgotPassword ?
                          <button
                            className="btn mt-4 mb-1"
                            type="submit"
                            style={{
                              width: "100%",
                              display: "block",
                              background: "#007bff",
                              padding: 4
                            }}
                            onClick={(e) => this.onVerifyOTP(e)}
                          >
                            {" "}
                            Submit
                            {" "}
                          </button> :
                          <button
                            className="btn mt-4 mb-1"
                            type="submit"
                            style={{
                              width: "100%",
                              display: "block",
                              background: "#007bff",
                              padding: 4
                            }}
                            onClick={(e) => this.onForgetPassword(e)}
                          >
                            {" "}
                            Submit
                            {" "}
                          </button>
                        }
                      </>
                  }
                  <a className="text-center" style={{ cursor: 'pointer' }} onClick={this.toggleModal}>
                    <i className="fa fa-angle-double-left" /> Back to Login
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="modal-dialog custom_modal_dialog" >
            <div className="modal-content" style={{ borderRadius: '0px' }}>
              <div class="modal-header">
                <h4 class="modal-title modal_title_text">Forget Password</h4>
                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

              </div>
              <form>
                <div class="modal-body">
                  <div class="row">
                    <div className="form-group">
                      <label>Enter Email Id</label>
                      <input type="email"
                        className="form-control"
                        placeholder=""
                        value={email}
                        onChange={(e) => this.setState({ email: e.target.value })}
                        onKeyDown={e => this.handleKeyDown(e)}
                        required />
                    </div>
                  </div>
                </div>
                <div class="row ">
                  <div class="col-md-12">
                    <div class="form-group text-right mod-fot">
                      <button type='submit' class="btn  btn-Add rounded-0" onClick={(e) => this.forgetPassword(e)}><i class="fa fa-check"></i>&nbsp; Submit</button>
                      <button class="btn cancel_btn rounded-0" data-dismiss="modal"
                        style={{ marginLeft: '5px' }} onClick={this.toggleModal}><i class="fa fa-times"></i>&nbsp; No</button>
                    </div>
                  </div>
                </div>
              </form>

            </div>
          </div> */}
        </Modal>
        <ToastContainer />
        <AdminHeader isSteper={false} />
        <>

          <div className="container" style={{ marginTop: '9em' }}>
            <h5 className="text-center" >For new corporate site please <a href='https://corporate.india-itme.com' target='blank' className='header-login-a' style={{fontSize:'24px'}}>click here</a></h5>
            <div className="login-box" >

              <h4 className="login-name">Login</h4>
              <div className="row mt-4">
                <div className="col-xl-12 mt-2">
                  <label className="text-left d-block mt-4">Email Id</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Email Id"
                    value={userEmail}
                    onChange={(e) => this.setState({ userEmail: e.target.value })}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12 ">
                  <label className="text-left d-block mt-3">Password</label>
                  <input
                    type={is_showpass ? "type" : "password"}
                    placeholder={is_forgotPassword ? "Enter password which got in an email" : "Enter Password"}
                    className="form-control"
                    value={password}
                    onChange={(e) => this.setState({ password: e.target.value })}
                  />
                  <i
                    className="fa fa-eye show-password"
                    onClick={this.handleObscure} />
                </div>
              </div>
              <button
                className="btn mt-4 mb-1"
                type="submit"
                style={{
                  width: "100%",
                  display: "block",
                  background: "#007bff",
                  padding: 4
                }}
                onClick={() => this.handleSubmit(false)}
              >
                {" "}
                <a
                  className="text-center btn-next"
                  style={{}}
                >
                  Login
                </a>{" "}
              </button>
              {/* <a
                onClick={this.toggleModal}>
                Forgot Password
              </a> */}
              <p class="forgot" style={{ cursor: 'pointer', color: "#dc3545", marginTop: '10px' }}
                onClick={this.toggleModal}>Forgot your password?</p>
            </div>
          </div>
        </>
        <Footerlogin />

      </>
    );
  }


}

export default withRouter(connect(null, { login_action, forgetCheckedEmail_action, forgetGetOtp_action, get_login_pass_action, forgetUpdatePassword_action })(Login));
