import { INSERT_DELEGATE_INVITATION_EVENT_ACTION_TYPE, UPDATE_DELEGATE_INVITATION_EVENT_ACTION_TYPE, GET_DELEGATE_INVITATION_EVENT_ACTION_TYPE, DELETE_DELEGATE_INVITATION_EVENT_ACTION_TYPE } from "../../../utils/constants"


const initialState = [];

function delegate_InvitationEventReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case INSERT_DELEGATE_INVITATION_EVENT_ACTION_TYPE:
            return [...state, payload];
        case UPDATE_DELEGATE_INVITATION_EVENT_ACTION_TYPE:
            return [...state, payload];
        case GET_DELEGATE_INVITATION_EVENT_ACTION_TYPE:
            return [...state, payload];
        case DELETE_DELEGATE_INVITATION_EVENT_ACTION_TYPE:
            return [...state, payload];

        default:
            return state;
    }
};

export default delegate_InvitationEventReducer;