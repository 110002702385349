import { axiosGet, axiosPost } from "../../../../../react_axios/Axios"
import { GET_INETRNET_CONNECTION_DETAILS_ACTION_TYPE, INSERT_UPDATE_INTERNET_CONNECTION_ACTION_TYPE, getInternetConnectionDetailsUrl, insertUpdateInternetConnectionUrl, headers } from "../../../../../utils/constants"

export const getInternetConnectionDetails_action = () => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhregistrationID: sessionStorage.getItem('ExhRegistrationIDSS')
        }
        console.log("getMeetingRoomDetails_action ----->", params)
        const res = await axiosGet(getInternetConnectionDetailsUrl, params)

        dispatch({
            type: GET_INETRNET_CONNECTION_DETAILS_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const insertUpdateInternetConnection_action = (Name, Designation, Mobile, NoOfPublicIP, Duration, PortVoucherRequirement, Remark, Is_agree) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhregistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
            Name: Name,
            Designation: Designation,
            Mobile: Mobile,
            NoOfPublicIP: NoOfPublicIP,
            Duration: Duration,
            PortVoucherRequirement: PortVoucherRequirement != '' ? PortVoucherRequirement : null,
            Remark: Remark != '' ? Remark : null,
            Is_Agree: Is_agree,
        }
        console.log("insertUpdateMeetingRoom_action ----->", params)
        const res = await axiosPost(insertUpdateInternetConnectionUrl, params, headers)

        dispatch({
            type: INSERT_UPDATE_INTERNET_CONNECTION_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}