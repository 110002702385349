import { GET_DIRECTORY_EXPORT_ACTION_TYPE, GET_E_DIRECTORY_APPLICATION_ACTION_TYPE, GET_E_DIRECTORY_SUMMARY_ACTION_TYPE, UPDATE_E_DIRECTORY_APPROVAL_ACTION_TYPE } from "../../../../utils/constants";


const initialState = [];

function eDirectory_approvalReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_E_DIRECTORY_SUMMARY_ACTION_TYPE:
            return [...state, payload];
        case GET_E_DIRECTORY_APPLICATION_ACTION_TYPE:
            return [...state, payload];
        case UPDATE_E_DIRECTORY_APPROVAL_ACTION_TYPE:
            return [...state, payload];
        case GET_DIRECTORY_EXPORT_ACTION_TYPE:
            return [...state, payload];

        default:
            return state;
    }
};

export default eDirectory_approvalReducer;