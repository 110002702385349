import React, { Component } from 'react';
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action, fill_master_action } from '../../../../../actions/fillCombo_action';
import { cityActionId, countryActionId, toastError, stalltypeMasterListActionId, dashboardDownloadListId, countryMasterListActionId, toastSuccess, paymentMasterListActionId } from "../../../../../utils/constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import Exh_chapter from '../exh_chapter/exh_chapter';
import Resizer from "react-image-file-resizer";
// import ManageArea from './manage_area/manage_area';
import Exh_timeline from '../exh_timeline/exh_timeline';
import ManageAreaMaster from '../manage_area_master/manage_area_master';
import ManualFormsMaster from "../manual_froms_master/manual_forms_master";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import { useNavigate } from "react-router-dom";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import { data } from "jquery";
import {
    updateExhibitionPaymentConfigration_action
} from "../../../../../actions/admin_module_action/master_action/exhibition_master_action/payment_config_action";

class ExhibitionPaymentConfigration extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            ExihibitionID: '',
            paymentModList: [],

        }
        this.getMasterFillList = this.getMasterFillList.bind(this)
        this.onClickPaymentEnable = this.onClickPaymentEnable.bind(this)
        this.updateExhibitionPaymentConfigration = this.updateExhibitionPaymentConfigration.bind(this)
        this.renderTable = this.renderTable.bind(this)
    }


    componentDidMount() {
        this.setState({
            ExihibitionID: this.props.location.state.ExihibitionID != undefined ? (this.props.location.state != null && this.props.location.state.ExihibitionID != null ? this.props.location.state.ExihibitionID : '') : this.props.id != null && this.props.id != undefined ? this.props.id : '',
        }, () => {
            this.getMasterFillList(paymentMasterListActionId, this.state.ExihibitionID, '', '', '',).then(res => {
                this.setState({ isLoading: false })
            })
        })

    }

    updateExhibitionPaymentConfigration() {
        const { ExihibitionID, paymentModList } = this.state

        this.setState({ isLoading: true })
        // console.log('waterMarkFilePath', waterMarkFilePath)
        this.props.updateExhibitionPaymentConfigration_action(ExihibitionID, paymentModList)
            .then((data) => {
                if (data.error != 1) {
                    toastSuccess("data updated successfully")
                    this.getMasterFillList(paymentMasterListActionId, this.state.ExihibitionID, '', '', '',).then(res => {
                        this.setState({ isLoading: false })
                    })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    onClickPaymentEnable(id, value) {
        const { paymentModList } = this.state
        for (var i = 0; i < paymentModList.length; i++) {
            if (paymentModList[i].PaymentModuleID == id) {
                if (value == 'IsPaymentEnable') {
                    paymentModList[i].IsPaymentEnable = !paymentModList[i].IsPaymentEnable
                } else if (value == 'isDomestic') {
                    paymentModList[i].isDomestic = !paymentModList[i].isDomestic
                } else {
                    paymentModList[i].isInternational = !paymentModList[i].isInternational
                }
            }
        }
        this.setState({ paymentModList: paymentModList })
    }

    renderTable(record) {
        return (
            <>
                <div className="row mt-3">
                    <div class=" col-md-6">
                        <table className="table border text-nowrap text-md-nowrap table-bordered mb-    0 mt-3">
                            <thead>
                                <tr>
                                    <th className="text-center">Sr no.</th>
                                    <th className="text-center">Module Name</th>
                                    <th className="text-center">Online Payment</th>
                                    <th className="text-center">Domestic</th>
                                    <th className="text-center">International</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    record.length > 0 ? <>{
                                        record.map((data, ID) => (
                                            <tr key={ID}>
                                                <td className="text-center">
                                                    {ID + 1}
                                                </td>
                                                <td>
                                                    {data.ModuleName}
                                                </td>
                                                <td className="text-center" >
                                                    <input type="checkbox" name="" key={data.IsPaymentEnable}
                                                        checked={data.IsPaymentEnable}
                                                        onClick={(e) => this.onClickPaymentEnable(data.PaymentModuleID, 'IsPaymentEnable')}
                                                    />
                                                </td>
                                                <td className="text-center" >
                                                    <input type="checkbox" name="" key={data.isDomestic}
                                                        checked={data.isDomestic}
                                                        onClick={(e) => this.onClickPaymentEnable(data.PaymentModuleID, 'isDomestic')}
                                                    />
                                                </td>
                                                <td className="text-center" >
                                                    <input type="checkbox" name="" key={data.isInternational}
                                                        checked={data.isInternational}
                                                        onClick={(e) => this.onClickPaymentEnable(data.PaymentModuleID, 'isInternational')}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </> :
                                        <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div></>
        )
    }
    getMasterFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        // if (actionID == countryActionId) {
        return new Promise((resolve, reject) => {
            this.props.fill_master_action(actionID, exhibitionID != '' && exhibitionID != null ? exhibitionID : null, moduleID, keyValue, value)
                .then((data) => {
                    if (data.error != 1) {
                        // Country List
                        if (actionID === paymentMasterListActionId) {
                            this.setState({
                                paymentModList: data.data,
                                // isLoading: false,
                            })
                            resolve(true)
                            console.log("paymentMasterListActionId data", data.data)
                        }
                    }
                }
                )
        })
    }

    render() {
        const { isLoading, paymentModList } = this.state
        return (<>

            {isLoading ? (
                <Loader></Loader>
            ) : (
                null
            )}

            <ToastContainer />
            <HeaderWithLogo />
            <>
                <AdminDrawer />
                <div className="main-container p-2">
                    {this.renderTable(paymentModList)}
                    <div class="row mt-3">

                        <div class="col-md-12">

                            <div >
                                <button onClick={() => this.updateExhibitionPaymentConfigration()} class="btn rounded-0 btn-primary mt-3" style={{ padding: "5px 7px", marginLeft: "10px", width: "100px" }}>Save</button>
                                {/* <button onClick={this.handleBack} class="btn rounded-0 btn-danger mt-3" style={{ padding: "5px 7px", marginLeft: "10px", width: "100px" }}>Back</button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>)
    }
}
export default withRouter(connect(null, { fill_master_action, updateExhibitionPaymentConfigration_action })(ExhibitionPaymentConfigration));