import { DELETE_VISA_DOCUMENT_ACTION_TYPE, GET_COMBINE_VISITOR_REGISTRATION_DETAIL_ACTION_TYPE, VISITOR_REGISTRATION_UPDATE_ACTION_TYPE } from "../../../utils/constants";


const initialState = [];

function visitorFormReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_COMBINE_VISITOR_REGISTRATION_DETAIL_ACTION_TYPE:
            return [...state, payload];
        case VISITOR_REGISTRATION_UPDATE_ACTION_TYPE:
            return [...state, payload];
        case DELETE_VISA_DOCUMENT_ACTION_TYPE:
            return [...state, payload];
        default:
            return state;
    }
};

export default visitorFormReducer;