import { axiosGet, axiosPost } from "../../../../react_axios/Axios";
import { headers, UPDATE_AREA_MANAGEMENT_ACTION_TYPE, GET_AREA_MANAGEMENT_ACTION_TYPE, updateAreaManagementUrl, getAreaManagementListUrl } from "../../../../utils/constants";


export const getAreaManagementList_action = (ChapterID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem("sessionExhibitionID"),
            ChapterID: ChapterID,
        }
        const res = await axiosGet(getAreaManagementListUrl, params, headers)

        dispatch({
            type: GET_AREA_MANAGEMENT_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const updateAreaManagement_action = (ChapterID, From, To, Area) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem("sessionExhibitionID"),
            ChapterID: ChapterID,
            From: From,
            To: To,
            Area: Area,
        }

        const res = await axiosPost(updateAreaManagementUrl, params, headers)

        dispatch({
            type: UPDATE_AREA_MANAGEMENT_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}