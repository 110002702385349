import { axiosGet, axiosPost } from "../../../../../react_axios/Axios";
import { GET_AIR_CONNECTION_DETAILS_ACTION_TYPE, INSERT_UPDATE_AIR_CONNECTION_ACTION_TYPE, headers, insertUpdateAirConnectionUrl, getAirConnectionDetailsUrl } from "../../../../../utils/constants";



export const insertUpdateAirConnection_action = (Remark, Is_Agree, CFMQuantity, NoofConnection, CompressedAirConsumptionID, ServiceID, Rates, Amount, BarQuantity) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: parseInt(sessionStorage.getItem('sessionExhibitionID')),
            ExhRegistrationID: parseInt(sessionStorage.getItem('ExhRegistrationIDSS')),
            Remark: Remark,
            Is_Agree: Is_Agree,
            CFMQuantity: CFMQuantity,
            NoofConnection: NoofConnection,
            CompressedAirConsumptionID: CompressedAirConsumptionID,
            ServiceID: ServiceID,
            Rates: Rates,
            Amount: Amount,
            BarQuantity: BarQuantity,
        }
        const res = await axiosPost(insertUpdateAirConnectionUrl, params, headers)

        dispatch({
            type: INSERT_UPDATE_AIR_CONNECTION_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getAirConnectionDetails_action = () => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhRegistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
        }
        const res = await axiosGet(getAirConnectionDetailsUrl, params, headers)

        dispatch({
            type: GET_AIR_CONNECTION_DETAILS_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}
