import { Component } from "react";
import { ToastContainer } from "react-toastify";
import Loader from "../../../utils/Loader";
import { downloadReport, toastError } from "../../../utils/constants";
import VisitorHeader2 from "../../../common_components/visitor_module_header2";
import VisitorDrawer from "../../../common_components/visitor_module_drawer";
import VisitorTopMenu from "../../../common_components/visitor_module_topmenu";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";

class VisitorBadge extends Component {

    // Initialize Constructor
    constructor(props) {
        super(props)
        //States
        this.state = {
            isLoading: false,
            getUrl: "",
        }
    }

    // On Component Load
    componentDidMount() {
        this.setState({ isLoading: true })
        this.setState({
            getUrl: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.geturl != " " ? this.props.location.state.geturl : '',
        })
        setTimeout(() => {
            this.setState({ isLoading: false })
        }, 5000);
    }



    render() {
        const { isLoading, getUrl } = this.state

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <VisitorHeader2 />
                <VisitorDrawer />
                {/*app-content open*/}
                <div className="page">
                    <div className="page-main">
                        <div className="main-content  with-dr-container">
                            {/* <div className="container-fluid">
                                <div className="row">
                                    <div className="step-name">
                                <p>Exhibitor Dashboard</p>
                            </div>
                                    <ExhibitorTopMenu />
                                </div>
                            </div> */}
                            <div className=" width-dr-nav">
                                <div className="row">
                                    <VisitorTopMenu />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12 p-0">
                                    <div className="card rounded-0 pt-1">
                                        <div className="card-header p-3">
                                            <h3 className="card-title" style={{ fontSize: 15 }}>
                                                Visitor Badge for ITME AFRICA &amp; M.E. 2023
                                            </h3>
                                        </div>
                                        <div className="card-body p-1">
                                            <iframe
                                                id="myIframe"
                                                src={getUrl}
                                                width="100%"
                                                height="435px"
                                                title="Bing"

                                            >
                                                &lt;/div&gt; &lt;div style="height: 100px;"&gt;&lt;/div&gt;
                                                &lt;/div&gt; &lt;/div&gt; &lt;/div&gt;
                                            </iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </>

        )
    }
}

export default withRouter(connect(null, {})(VisitorBadge));