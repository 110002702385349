import { Component } from "react";
import Loader from "../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action } from '../../../../actions/fillCombo_action';
import { cityActionId, countryActionId, toastError, toastSuccess } from "../../../../utils/constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import AdminDrawer from "../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../common_components/header_with_logo";
import { useNavigate } from "react-router-dom";
import ExhibitorDrawer from "../../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../../common_components/exhibitor_module_header";
import { data } from "jquery";
import { getRoleMasterList_action, deleteRoleMaster_action } from "../../../../actions/admin_module_action/master_action/role_master_action";


class RoleMasterList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            roleList: [],
            isLoading: false,
        }
        this.getRoleMasterList = this.getRoleMasterList.bind(this)
        // this.record = this.record.bind(this)
        this.getFillList = this.getFillList.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.deleteRoleMaster = this.deleteRoleMaster.bind(this)

    }

    componentDidMount() {
        this.getFillList(countryActionId, '', '', '', '', '');

        this.getRoleMasterList()
    }

    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value)
            .then((data) => {
                if (data.error != 1) {
                    // country list
                    if (actionID === countryActionId) {
                        console.log("country data", data.data)
                        // var internationalCountryData = data.data.length > 0 && data.data.filter((item) => item.Code != "91")
                        this.setState({
                            countryList: data.data
                        })
                    }
                }
                this.setState({ isLoading: false })
            })
            .catch((e) => {
                console.log(e);
            });
    }

    getRoleMasterList() {
        this.setState({ isLoading: true })
        this.props.getRoleMasterList_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log(data.data)
                    this.setState({ roleList: data.data })
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    deleteRoleMaster(id) {
        this.setState({ isLoading: true })
        this.props.deleteRoleMaster_action(id)
            .then((data) => {
                if (data.error != 1) {
                    console.log(data.data)
                    this.setState({ isLoading: false })
                    toastSuccess("deleted successfully")
                    setTimeout(() => {
                        this.getRoleMasterList()
                    }, 200);
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    record(list) {
        return (
            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
                <thead>
                    <tr>
                        {/* <th className="text-center">Role ID</th> */}
                        <th>Name</th>
                        {/* <th className="text-center">From Date</th>
                        <th className="text-center">To Date</th>
                        <th className="text-center">Country</th> */}
                        <th style={{ width: '120px' }} className="text-center">Status</th>
                        <th style={{ width: '60px' }} className="text-center"></th>
                        <th style={{ width: '60px' }} className="text-center"></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.length > 0 ? <>{
                            list.map((data, i) => (
                                <tr key={Math.random()}>
                                    {/* <td className="text-center">{data.RoleID}</td> */}
                                    <td >{data.RoleName}</td>
                                    {/* <td className="text-center">{moment(data.ExhibitionDateFrom).format('L')}</td>
                                    <td className="text-center">{moment(data.ExhibitionDateTo).format('L')}</td>
                                    <td className="text-center">{this.state.countryList.map(id => (parseInt(id.Code) == data.CountryID ? id.Description : null))}</td> */}
                                    <td className="text-center">{data.Status == true ? 'Active' : 'Inactive'}</td>
                                    <td className="text-center">
                                        <button
                                            type="button"
                                            className="btn btn-outline-primary btn-sm"
                                            onClick={(e) => this.handleUpdate(data.RoleID)}
                                        >
                                            Update
                                        </button>
                                    </td>
                                    <td className="text-center">
                                        <button
                                            type="button"
                                            className="btn btn-outline-danger btn-sm"
                                            onClick={(e) => this.deleteRoleMaster(data.RoleID)}
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </> :
                            <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                    }
                </tbody>
            </table>)
    }

    handleAdd() {
        this.props.navigate('/roleMasterAU', { state: { is_add: true, } })
    }

    handleUpdate(id) {
        this.props.navigate('/roleMasterAU', { state: { is_update: true, RoleID: id } })
    }

    render() {
        const { isLoading, roleList } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                <div className={JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ? "with-dr-container" : "with-dr-container com-height"}>
                    <div className="main-container">
                        <AdminDrawer />
                        <div className="row">
                            <div className="step-name">
                                <p>Role Master List</p>
                            </div>
                        </div>
                    </div>
                    <div className="main-container p-2">
                        <div className="row ">
                            {
                                JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 1 &&
                                <div className="row ">
                                    {/* <div className="col-xl-3 mb-3">
                                        <label>Exhibitor</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue=""
                                            required=""
                                            value={exhibitor}
                                            onKeyDown={e => this.handleKeyDown(e)}
                                            onChange={(e) => this.setState({ exhibitor: e.target.value, exhibitorErr: "" })}
                                        />
                                    </div>
                                    <div className="col-xl-3 mb-3">
                                        <label>Receipt Date From</label>
                                        <DatePicker className="form-control"
                                            dateFormat="dd-MM-yyyy"
                                            onKeyDown={e => this.handleKeyDown(e)}
                                            onChange={(date) => this.setState({ recptDtFrom: date, recptDtFromErr: "" })}
                                            selected={this.state.recptDtFrom}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            placeholderText="From Date"
                                            customInput={<ExampleCustomInput />}
                                            dayClassName={() => "example-datepicker-day-class"}
                                            popperClassName="example-datepicker-class"
                                        />
                                    </div>
                                    <div className="col-xl-3 mb-3">
                                        <label>Receipt Date To</label>
                                        <DatePicker className="form-control"
                                            dateFormat="dd-MM-yyyy"
                                            minDate={this.state.recptDtFrom}
                                            onKeyDown={e => this.handleKeyDown(e)}
                                            onChange={(date) => this.setState({ recptDtTo: date, recptDtToErr: "" })}
                                            selected={this.state.recptDtTo}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            placeholderText="To Date"
                                            customInput={<ExampleCustomInput />}
                                            dayClassName={() => "example-datepicker-day-class"}
                                            popperClassName="example-datepicker-class"
                                        />
                                    </div>
                                    <div className="col-xl-3 mb-3">
                                        <label>Receipt No</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="validationCustom03"
                                            value={recptNo}
                                            onKeyDown={e => this.handleKeyDown(e)}
                                            onChange={(e) => this.setState({ recptNo: e.target.value, recptNoErr: "" })}
                                        />
                                    </div> */}
                                </div>
                            }
                        </div >
                        < div className="row" >
                            <div className="col-md-12">
                                {
                                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 1 &&
                                    <div
                                        className="btn-group mb-1"
                                        role="group"
                                        aria-label="Basic example"
                                        style={{ float: "right" }}
                                    >
                                        <button type="button" className="btn ad-fil-btn"
                                            onClick={e => this.handleAdd()}
                                        >
                                            Add
                                        </button>
                                    </div>
                                }
                                <div className="table-responsive">
                                    {this.record(roleList)}
                                </div>
                                {/* <div className="mt-2 float-left">
                                    {
                                        exhibitorList.length > 0 ?
                                            <Pagination
                                                nPages={nPages}
                                                currentPage={currentPage}
                                                setCurrentPage={this.updateItem}
                                            /> : <></>
                                    }
                                    <div className="tab-rec " style={{ float: "right" }}>
                                        <p>{`${currentPage} of ${nPages} pages`}</p>
                                    </div>
                                </div> */}
                            </div>
                        </div >
                    </div >
                </div >
            </>
        )
    }
}

export default withRouter(connect(null, { fill_action, getRoleMasterList_action, deleteRoleMaster_action })(RoleMasterList));