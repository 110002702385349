import { axiosGet, axiosPost } from "../../../react_axios/Axios";
import {
    headers, getAirConnetionManualFormReportUrl, GET_AIR_CONNECTION_MANUAL_FORM_REPORT, getWaterConnetionManualFormReportUrl, GET_WATER_CONNECTION_MANUAL_FORM_REPORT, getPowerConnetionManualFormReportUrl, GET_POWER_CONNECTION_MANUAL_FORM_REPORT, GET_FRIEGHT_FORWARDER_MANUAL_FORM_REPORT, GET_FASCIA_MANUAL_FORM_REPORT, getFreightForwardersManualFormReportUrl, getFasciaFormManualReportUrl, GET_E_DIRECTORY_MANUAL_FORM_REPORT,
    GET_MEETING_ROOM_MANUAL_FORM_REPORT, GET_EXH_BADGE_MANUAL_FORM_REPORT,
    GET_EXHIBIT_DETAILS_MANUAL_FORM_REPORT, getEDirectoryFormReportUrl, getMeetingRoomFormReportUrl, getExhBadgesFormReportUrl, getExhibitDetailFormReportUrl, GET_EXHIBITOR_CONTRACTOR_BADGE_FORM_REPORT, GET_STALL_BOOKING_SUMMARY_FORM_REPORT, GET_STALL_DESIGN_FORM_REPORT, GET_VISITOR_BADGE_FORM_REPORT, getExhContractorBadgeFormReportUrl, getStallBookingSummaryFormReportUrl, getStallDesignFormFormReportUrl, getVisitorBadgeFormFormReportUrl, GET_STALL_POSSESSION_FORM_REPORT, GET_VISA_APPLICATION_FORM_REPORT, getStallPossessionFormReportUrl, getVisaApplicationFormReportUrl
} from "../../../utils/constants";

export const getAirConnectionReport_action = (exhibitionID, ExhRegistrationID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: exhibitionID,
            ExhRegistrationID: ExhRegistrationID
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getAirConnetionManualFormReportUrl, params, headers)

        dispatch({
            type: GET_AIR_CONNECTION_MANUAL_FORM_REPORT,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getWaterConnectionReport_action = (exhibitionID, ExhRegistrationID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: exhibitionID,
            ExhRegistrationID: ExhRegistrationID
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getWaterConnetionManualFormReportUrl, params, headers)

        dispatch({
            type: GET_WATER_CONNECTION_MANUAL_FORM_REPORT,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getPowerConnectionReport_action = (exhibitionID, ExhRegistrationID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: exhibitionID,
            ExhRegistrationID: ExhRegistrationID
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getPowerConnetionManualFormReportUrl, params, headers)

        dispatch({
            type: GET_POWER_CONNECTION_MANUAL_FORM_REPORT,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getFrightForwarderReport_action = (exhibitionID, ExhRegistrationID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: exhibitionID,
            ExhRegistrationID: ExhRegistrationID
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getFreightForwardersManualFormReportUrl, params, headers)

        dispatch({
            type: GET_FRIEGHT_FORWARDER_MANUAL_FORM_REPORT,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getFasciaReport_action = (exhibitionID, ExhRegistrationID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: exhibitionID,
            ExhRegistrationID: ExhRegistrationID
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getFasciaFormManualReportUrl, params, headers)

        dispatch({
            type: GET_FASCIA_MANUAL_FORM_REPORT,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getEDirectoryFormReport_action = (exhibitionID, ExhRegistrationID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: exhibitionID,
            ExhRegistrationID: ExhRegistrationID
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getEDirectoryFormReportUrl, params, headers)

        dispatch({
            type: GET_E_DIRECTORY_MANUAL_FORM_REPORT,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getMeetingRoomFormReport_action = (exhibitionID, ExhRegistrationID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: exhibitionID,
            ExhRegistrationID: ExhRegistrationID
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getMeetingRoomFormReportUrl, params, headers)

        dispatch({
            type: GET_MEETING_ROOM_MANUAL_FORM_REPORT,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getExhBadgesFormReport_action = (exhibitionID, ExhRegistrationID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: exhibitionID,
            ExhRegistrationID: ExhRegistrationID
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getExhBadgesFormReportUrl, params, headers)

        dispatch({
            type: GET_EXH_BADGE_MANUAL_FORM_REPORT,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getExhibitDetailFormReport_action = (exhibitionID, ExhRegistrationID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: exhibitionID,
            ExhRegistrationID: ExhRegistrationID
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getExhibitDetailFormReportUrl, params, headers)

        dispatch({
            type: GET_EXHIBIT_DETAILS_MANUAL_FORM_REPORT,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getExhContractorBadgeFormReport_action = (exhibitionID, ExhRegistrationID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: exhibitionID,
            ExhRegistrationID: ExhRegistrationID
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getExhContractorBadgeFormReportUrl, params, headers)

        dispatch({
            type: GET_EXHIBITOR_CONTRACTOR_BADGE_FORM_REPORT,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getStallBookingSummaryFormReport_action = (exhibitionID, ExhRegistrationID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: exhibitionID,
            ExhRegistrationID: ExhRegistrationID
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getStallBookingSummaryFormReportUrl, params, headers)

        dispatch({
            type: GET_STALL_BOOKING_SUMMARY_FORM_REPORT,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getStallDesignFormFormReport_action = (exhibitionID, ExhRegistrationID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: exhibitionID,
            ExhRegistrationID: ExhRegistrationID
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getStallDesignFormFormReportUrl, params, headers)

        dispatch({
            type: GET_VISITOR_BADGE_FORM_REPORT,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getVisitorBadgeFormFormReport_action = (exhibitionID, ExhVisitorID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: exhibitionID,
            ExhVisitorID: ExhVisitorID
        }

        const res = await axiosGet(getVisitorBadgeFormFormReportUrl, params, headers)

        dispatch({
            type: GET_STALL_DESIGN_FORM_REPORT,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getStallPossessionFormReport_action = (exhibitionID, ExhRegistrationID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: exhibitionID,
            ExhRegistrationID: ExhRegistrationID
        }

        const res = await axiosGet(getStallPossessionFormReportUrl, params, headers)

        dispatch({
            type: GET_STALL_POSSESSION_FORM_REPORT,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getVisaApplicationFormReport_action = (exhibitionID, ExhRegistrationID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: exhibitionID,
            ExhRegistrationID: ExhRegistrationID
        }

        const res = await axiosGet(getVisaApplicationFormReportUrl, params, headers)

        dispatch({
            type: GET_VISA_APPLICATION_FORM_REPORT,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}