import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { getVisaApplication_action, deleteVisaApplication_action, submitVisaApplication_action } from '../../../../../actions/exhibitor_module_actions/manual_Forms_actions/forms_actions/invitationVisaApplication_action/visaApplication_action'
import { toastError, toastSuccess } from "../../../../../utils/constants";
import Modal from "react-modal";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";


class InvitationVisaApplicationList extends Component {

    // Initialize Constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            visaApplicationList: [],
            DeadlineDate: "",
            currentDate: new Date(),
            isDelOpen: false,
            VisaApplicationFormID: '',
            StatusID: '',
        }
        this.getVisaApplicationList = this.getVisaApplicationList.bind(this)
        this.deleteVisaApplication = this.deleteVisaApplication.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.handleBack = this.handleBack.bind(this)
        this.onUpdateClick = this.onUpdateClick.bind(this)
        this.toggleDelModal = this.toggleDelModal.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.submitVisaApplications = this.submitVisaApplications.bind(this)
        this.onViewClick = this.onViewClick.bind(this)
    }

    componentDidMount() {
        this.setState({
            DeadlineDate: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.Deadline != "" ?
                new Date(this.props.location.state.Deadline) : '',
            StatusID: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.StatusID != "" ?
                this.props.location.state.StatusID : '',
        })
        this.getVisaApplicationList()
    }

    // Get visa application list
    getVisaApplicationList() {
        this.setState({ isLoading: true })
        this.props.getVisaApplication_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log("Visa application list", data.data)
                    this.setState({ visaApplicationList: data.data, isLoading: false })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    //Visa application 
    deleteVisaApplication() {
        this.setState({ isLoading: true })
        this.props.deleteVisaApplication_action()
            .then((data) => {
                if (data.error != 1) {
                    toastSuccess('Record deleted successfully')

                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // Handle Add 
    handleAdd() {
        const { DeadlineDate } = this.state
        this.props.navigate('/visaApplicationAU', { state: { is_add: true, Deadline: DeadlineDate } })
    }

    // On Update Icon Click
    onUpdateClick(Id) {
        const { DeadlineDate } = this.state
        this.props.navigate('/visaApplicationAU', { state: { is_update: true, VisaApplicationFormID: Id, Deadline: DeadlineDate } })
    }

    // On View Click
    onViewClick(Id) {
        const { DeadlineDate } = this.state
        this.props.navigate('/visaApplicationAU', { state: { is_View: true, VisaApplicationFormID: Id, Deadline: DeadlineDate } })
    }

    // Handle Back
    handleBack() {
        this.props.navigate('/manualForms')
    }

    //toggle for delete
    toggleDelModal(id) {
        const { isDelOpen } = this.state
        this.setState({
            isDelOpen: !isDelOpen, VisaApplicationFormID: id
        })
    }

    // // handle Receipt Delete"
    handleDelete(e) {
        e.preventDefault()
        const { isDelOpen, VisaApplicationFormID } = this.state
        this.setState({ isLoading: true })
        this.props.deleteVisaApplication_action(VisaApplicationFormID)
            .then((data) => {
                if (data.error != 1) {
                    toastSuccess("Visa Application Deleted SuccessFully")
                    this.setState({ isLoading: false, isDelOpen: !isDelOpen })
                    this.getVisaApplicationList()
                } else {
                    toastError(data.msg)
                    this.setState({ isLoading: false })
                }
            }).catch((error) => {
                console.log(error)
            })
    }

    // On submit Visa Application
    submitVisaApplications() {
        this.setState({ isLoading: true })
        this.props.submitVisaApplication_action()
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ isLoading: false })
                    this.props.navigate('/manualForms')
                } else {
                    this.setState({ isLoading: false })
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    render() {
        const { isLoading, isDelOpen, visaApplicationList, currentDate, DeadlineDate, StatusID } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <ExhibitorHeader />
                <ExhibitorDrawer />
                <div className="page">
                    <div className="page-main">
                        <div className="main-content with-dr-container">
                            <div className=" width-dr-nav">
                                <div className="row">
                                    <ExhibitorTopMenu />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card rounded-0 pt-1">
                                        <div className="card-header p-3">
                                            <h3 className="card-title">Invitation letter for visa Application</h3>
                                        </div>
                                        <div className="card-body p-2">
                                            <div className="row">
                                                {currentDate > DeadlineDate &&
                                                    <span className="text-danger">THE DEADLINE TO FILL OUT THE FORM HAS PASSED.</span>
                                                }
                                            </div>
                                            <button
                                                className="btn btn-outline-primary p-1 ms-2"
                                                type="btn"
                                                style={{ float: "right", width: '60px' }}
                                                onClick={(e) => this.handleBack()}
                                            >
                                                Back
                                            </button>
                                            <button
                                                className="btn btn-outline-primary p-1 mr-2"
                                                type="btn"
                                                style={{ float: "right", width: '60px' }}
                                                disabled={currentDate > DeadlineDate}
                                                onClick={(e) => this.handleAdd()}
                                            >
                                                Add
                                            </button>
                                            <div className="table-responsive mt-6">
                                                <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1">
                                                    <thead>
                                                        <tr>
                                                            <th>First Name</th>
                                                            <th>Middel Name</th>
                                                            <th>Last Name</th>
                                                            <th>Email</th>
                                                            <th>Country</th>
                                                            <th>Mobile</th>
                                                            <th>ID Type</th>
                                                            <th>Passport No</th>
                                                            <th>Status</th>
                                                            <th style={{ width: '60px' }} className="text-center">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            visaApplicationList.length > 0 ? (visaApplicationList.map(data => (
                                                                <tr key={data.VisaApplicationFormID}>
                                                                    <td>{data.FirstName}</td>
                                                                    <td>{data.MiddleName}</td>
                                                                    <td>{data.LastName}</td>
                                                                    <td>{data.Email}</td>
                                                                    <td>{data.CountryName}</td>
                                                                    <td>{data.MobileNo}</td>
                                                                    <td>{data.IDType}</td>
                                                                    <td>{data.PassportNo}</td>
                                                                    <td className="text-center">{data.Status}</td>
                                                                    <td className="text-center">
                                                                        {
                                                                            currentDate < DeadlineDate &&
                                                                            <span className="action-icon">
                                                                                {data.Status != 'Approved' ?
                                                                                    <i className="fa fa-trash text-danger" aria-hidden="true" onClick={(e) => this.toggleDelModal(data.VisaApplicationFormID)} /> :
                                                                                    <></>
                                                                                }
                                                                            </span>
                                                                        }
                                                                        <span className="">
                                                                            {data.Status == 'Approved' || (currentDate > DeadlineDate) ?
                                                                                <i className="fa fa-eye text-info" aria-hidden="true" onClick={(e) => this.onViewClick(data.VisaApplicationFormID)} /> :
                                                                                <i className="fa fa-pencil text-info" aria-hidden="true" onClick={(e) => this.onUpdateClick(data.VisaApplicationFormID)} />
                                                                            }
                                                                        </span>

                                                                    </td>
                                                                </tr>
                                                            ))) : <tr><td className="text-center" colSpan={8}>No record found</td></tr>
                                                        }
                                                    </tbody>
                                                </table>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary rounded-0 d-block mt-4"
                                                    style={{ marginRight: 10 }}
                                                    disabled={currentDate > DeadlineDate}
                                                    onClick={() => this.submitVisaApplications()}
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <Modal isOpen={isDelOpen} onRequestClose={() => this.setState({ isDelOpen: false })}>
                    <div className="modal-dialog">
                        <div className="modal-content confirm-logout">
                            <div class="modal-header p-2">
                                <h5 class="modal-title modal_title_text text-danger">Confirm Delete</h5>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={() => this.setState({ isDelOpen: false })} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <p class="pop_label">Do you really want to delete this record?</p>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer p-1">
                                <div class="form-group text-right mod-fot">
                                    <button type="button" class="btn btn-del-pop" onClick={(e) => this.handleDelete(e)}>Yes</button>
                                    <button type="button" class="btn btn-can-pop" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={() => this.setState({ isDelOpen: false })}>No</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </Modal>

            </>
        )
    }
}

export default withRouter(connect(null, { getVisaApplication_action, deleteVisaApplication_action, submitVisaApplication_action })(InvitationVisaApplicationList));