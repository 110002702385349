import { axiosGet, axiosPost } from "../../../../react_axios/Axios";
import { headers, deleteVendorAllocationUrl, DELETE_VENDOR_ALLOCATION, ADD_UPDATE_VENDOR_ALLOCATION, GET_DETAILS_VENDOR_ALLOCATION, GET_HALL_STALL_LIST_ALLOCATION, addUpdateVendorAllocationUrl, getVendorAllocationDetailsUrl, getHallStallListUrl } from "../../../../utils/constants";


export const addUpdateVendorAllocation_action = (ExhibitionID, VendorID, Serviceid, HallStallList) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExhibitionID,
            VendorID: VendorID,
            Serviceid: Serviceid,
            HallStallList: HallStallList,
        }

        const res = await axiosPost(addUpdateVendorAllocationUrl, params, headers)

        dispatch({
            type: ADD_UPDATE_VENDOR_ALLOCATION,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getVendorAllocationDetails_action = (ExhibitionID, VendorID, Serviceid) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            // ExhibitionID: sessionStorage.getItem("sessionExhibitionID"),
            ExhibitionID: ExhibitionID,
            VendorID: VendorID,
            Serviceid: Serviceid,
        }

        const res = await axiosGet(getVendorAllocationDetailsUrl, params, headers)

        dispatch({
            type: GET_DETAILS_VENDOR_ALLOCATION,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getHallStallList_action = (ExhibitionID, HallId, StallId, VendorID, ServiceID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExhibitionID,
            HallId: HallId,
            StallId: StallId,
            VendorID: VendorID,
            ServiceID: ServiceID,
        }

        const res = await axiosGet(getHallStallListUrl, params, headers)

        dispatch({
            type: GET_HALL_STALL_LIST_ALLOCATION,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const deleteVendorAllocation_action = (VendorID, Serviceid) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem("sessionExhibitionID"),
            VendorID: VendorID,
            Serviceid: Serviceid,
        }

        const res = await axiosGet(deleteVendorAllocationUrl, params, headers)

        dispatch({
            type: DELETE_VENDOR_ALLOCATION,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}