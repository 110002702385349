import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action } from '../../../../../actions/fillCombo_action';
import { cityActionId, stallTypeActionId, stallListActionId, countryActionId, exhibitionActionId, toastError, toastSuccess } from "../../../../../utils/constants";
import { emailValidator } from "../../../../../utils/Validator";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import Pagination from "../../../../../utils/pagination";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import { useNavigate } from "react-router-dom";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import { data } from "jquery";
import { deleteHallMaster_action } from "../../../../../actions/admin_module_action/master_action/hall_master_action";
import { getVisitorMeetingMasterList_action, insertUpdateVisitorMeetingMaster_action, deleteVisitorMeetingMaster_action } from "../../../../../actions/admin_module_action/master_action/manual_form_master_action/visitor_meeting_master_action";
import Footer from "../../../../../common_components/Footer";


class VisitorMeetingMaster extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isOpen: false,
            // currentPage: 1,
            visitorMeeting: [{
                'VisitorName': '',
                'CompanyName': '',
                'Designation': '',
                "City": '',
                'ChapterName': '',
                'RegDays': '',
                'isNew': true,
            }],
            deleteID: '',
        }

        this.record = this.record.bind(this)
        this.onInput = this.onInput.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.deleteVisitorMeetingMaster = this.deleteVisitorMeetingMaster.bind(this)
        this.onDelete = this.onDelete.bind(this)
        this.getVisitorMeetingMasterList = this.getVisitorMeetingMasterList.bind(this)
    }

    componentDidMount() {
        this.getVisitorMeetingMasterList(null)
    }

    onInput(value, i, flag) {
        const { visitorMeeting } = this.state
        console.log(value, i, flag)
        if (flag == 'VisitorName') {
            visitorMeeting[i].VisitorName = value
        } else if (flag == 'CompanyName') {
            visitorMeeting[i].CompanyName = value
        } else if (flag == 'Designation') {
            visitorMeeting[i].Designation = value
        } else if (flag == 'City') {
            visitorMeeting[i].City = value
        } else if (flag == 'ChapterName') {
            visitorMeeting[i].ChapterName = value
        } else if (flag == 'RegDays') {
            visitorMeeting[i].RegDays = value
        }
        this.setState({ visitorMeeting: visitorMeeting })
    }

    record(list) {
        return (
            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
                <thead>
                    <tr>
                        <th className="text-center" style={{ width: '50px' }}>Sr no.</th>
                        <th className="text-center">Visitor Name<span className="text-red">*</span></th>
                        <th className="text-center">Company Name<span className="text-red">*</span></th>
                        <th className="text-center">Designation<span className="text-red">*</span></th>
                        <th className="text-center">City<span className="text-red">*</span></th>
                        <th className="text-center">ChapterName<span className="text-red">*</span></th>
                        <th className="text-center">RegDays<span className="text-red">*</span></th>
                        <th className="text-center" style={{ width: '50px' }}></th>

                    </tr>
                </thead>
                <tbody>
                    {
                        list.length > 0 ? <>{
                            list.map((data, i) => (
                                <tr key={i}>
                                    <td className="text-center" style={{ width: '50px' }}>{i + 1}</td>
                                    <td className="text-center">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            value={data.VisitorName}
                                            onChange={(e) => this.onInput(e.target.value, i, 'VisitorName')}
                                        />
                                    </td>
                                    <td className="text-center">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            value={data.CompanyName}
                                            onChange={(e) => this.onInput(e.target.value, i, 'CompanyName')}
                                        />
                                    </td>
                                    <td className="text-center">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            value={data.Designation}
                                            onChange={(e) => this.onInput(e.target.value, i, 'Designation')}

                                        />
                                    </td>
                                    <td className="text-center">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            value={data.City}
                                            onChange={(e) => this.onInput(e.target.value, i, 'City')}

                                        />
                                    </td>
                                    <td className="text-center">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            value={data.ChapterName}
                                            onChange={(e) => this.onInput(e.target.value, i, 'ChapterName')}

                                        />
                                    </td>
                                    <td className="text-center">
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder=""
                                            value={data.RegDays}
                                            onChange={(e) => this.onInput(e.target.value, i, 'RegDays')}

                                        />
                                    </td>
                                    <td className="text-center">
                                        <button
                                            type="button"
                                            className="btn btn-outline-danger btn-sm"
                                            onClick={(e) => this.onDelete(data.VisitorMeetingBookingDataID, i, data.isNew)}
                                        >
                                            <i className="fa fa-trash action_btn_table" />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </> :
                            <tr><td colSpan={13} style={{ textAlign: 'center' }}></td></tr>
                    }
                </tbody>
            </table>)
    }

    getVisitorMeetingMasterList(status) {
        this.setState({ isLoading: true })
        const { visitorMeeting } = this.state
        this.props.getVisitorMeetingMasterList_action()
            .then((data) => {
                if (data.error != 1) {
                    status == 1 ? data.data.map((item) => { item.isNew = false }) : data.data.length > 0 && data.data.map((item) => { item.isNew = false })
                    status == 1 ? this.setState({ visitorMeeting: data.data }) : data.data.length > 0 && this.setState({ visitorMeeting: data.data })
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    handleAdd() {
        const { visitorMeeting } = this.state
        console.log("pp")
        var obj = {
            'VisitorName': '',
            'CompanyName': '',
            'Designation': '',
            "City": '',
            'ChapterName': '',
            'RegDays': '',
            'isNew': true,
        }
        visitorMeeting.push(obj)
        this.setState({ visitorMeeting: visitorMeeting })
    }

    handleSubmit() {
        const { visitorMeeting } = this.state
        var arr = [...visitorMeeting]
        var err = false
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].VisitorName == '' || arr[i].VisitorName == null) {
                err = 'Please enter mandatory fields'
                break
            }
            if (arr[i].CompanyName == '' || arr[i].CompanyName == null) {
                err = 'Please enter mandatory fields'
                break
            }
            if (arr[i].Designation == '' || arr[i].Designation == null) {
                err = 'Please enter mandatory fields'
                break
            }
            if (arr[i].City == '' || arr[i].City == null) {
                err = 'Please enter mandatory fields'
                break
            }
            if (arr[i].ChapterName == '' || arr[i].ChapterName == null) {
                err = 'Please enter mandatory fields'
                break
            }
            if (arr[i].RegDays == '' || arr[i].RegDays == null) {
                err = 'Please enter mandatory fields'
                break
            }
        }
        if (!err) {
            this.setState({ isLoading: true })
            this.props.insertUpdateVisitorMeetingMaster_action(arr)
                .then((data) => {
                    if (data.error != 1) {
                        console.log(data.data)
                        this.getVisitorMeetingMasterList(1)
                        this.setState({ isLoading: false, })
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            toastError(err)
        }
    }

    deleteVisitorMeetingMaster(id) {
        this.setState({ isLoading: true })
        this.props.deleteVisitorMeetingMaster_action(id)
            .then((data) => {
                if (data.error != 1) {
                    console.log(data.data)
                    this.setState({ isLoading: false })
                    toastSuccess("deleted successfully")
                    setTimeout(() => {
                        this.getVisitorMeetingMasterList(1)
                    }, 200);
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
                this.setState({ isOpen: false })
            })
            .catch((err) => {
                console.log(err)
            })
    }


    onDelete(id, index, status) {
        const { visitorMeeting } = this.state
        console.log(status, index)
        if (status) {
            visitorMeeting.splice(index, 1)
            this.setState({ visitorMeeting: visitorMeeting })
        } else {
            this.setState({ deleteID: id })
            this.setState({ isOpen: true, })
        }
    }


    render() {
        const { isOpen, isLoading, visitorMeeting } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <div className="app-content with-dr-container com-height">
                        <AdminDrawer />
                        <div className="row">
                            <div className="step-name">
                                <p>Visitor Meeting Master</p>
                            </div>
                        </div>
                        <div className="m-2">

                            <div className="table-responsive">
                                <div
                                    className="card-header p-3"
                                    style={{ paddingLeft: "7px !important" }}
                                >
                                    <h3 className="card-title" style={{ fontSize: 15 }}>
                                        Agency contact details
                                    </h3>
                                </div>
                                {this.record(visitorMeeting)}
                            </div>
                        </div>
                        <div className="btn-group m-2 save-can d-block" >
                            <div className="btn-group" role="group" aria-label="Basic example">
                                <button type="button" className="btn btn-primary rounded-0 d-block" style={{ marginLeft: '5px', cursor: 'pointer' }}
                                    onClick={this.handleAdd}
                                >Add more</button>
                            </div>
                        </div>
                        <div className="btn-group m-2 save-can d-block" >
                            <div className="btn-group" role="group" aria-label="Basic example" style={{ float: 'right' }} >
                                <button type="button" className="btn btn-success rounded-0 d-block" data-dismiss="modal" style={{ marginLeft: '5px' }}
                                    onClick={this.handleSubmit} disabled={visitorMeeting.length == 0}
                                >Submit</button>
                            </div>
                        </div>
                    </div>
                </>
                <Modal isOpen={isOpen} onRequestClose={() => this.setState({ isOpen: false })}>
                    <div className="modal-dialog" style={{ marginTop: '20%' }}>
                        <div className="modal-content confirm-logout">
                            <div class="modal-header text-right p-2">
                                <h5 class="modal-title modal_title_text text-danger">Do you really want to delete this record?</h5>

                                {/* <button type="button " class="close pop-close" data-dismiss="modal"
                                    onClick={() => this.setState({ isOpen: false })}
                                ><span aria-hidden="true" class="pop-cancel">&times;</span></button> */}
                            </div>
                            {/* <div class="modal-body">
                                <div class="row">
                                </div>
                            </div> */}
                            <div class="modal-footer p-1">
                                <div class="form-group text-right mod-fot">
                                    <button type="button" class="btn btn-del-pop"
                                        onClick={(e) => this.deleteVisitorMeetingMaster(this.state.deleteID)}
                                    >Yes</button>
                                    <button type="button" class="btn btn-can-pop" data-dismiss="modal" style={{ marginLeft: '5px' }}
                                        onClick={() => this.setState({ isOpen: false })}
                                    >No</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
                <Footer />
            </>)
    }

}
export default withRouter(connect(null, { fill_action, getVisitorMeetingMasterList_action, insertUpdateVisitorMeetingMaster_action, deleteVisitorMeetingMaster_action })(VisitorMeetingMaster));