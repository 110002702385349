import {
    GET_AIR_CONNECTION_MANUAL_FORM_REPORT, GET_WATER_CONNECTION_MANUAL_FORM_REPORT, GET_POWER_CONNECTION_MANUAL_FORM_REPORT, GET_FASCIA_MANUAL_FORM_REPORT, GET_FRIEGHT_FORWARDER_MANUAL_FORM_REPORT, GET_E_DIRECTORY_MANUAL_FORM_REPORT,
    GET_MEETING_ROOM_MANUAL_FORM_REPORT, GET_EXH_BADGE_MANUAL_FORM_REPORT,
    GET_EXHIBIT_DETAILS_MANUAL_FORM_REPORT, GET_EXHIBITOR_CONTRACTOR_BADGE_FORM_REPORT, GET_STALL_BOOKING_SUMMARY_FORM_REPORT, GET_STALL_DESIGN_FORM_REPORT, GET_VISITOR_BADGE_FORM_REPORT, GET_STALL_POSSESSION_FORM_REPORT, GET_VISA_APPLICATION_FORM_REPORT
} from "../../../utils/constants";

const initialState = [];

function manualFormReportReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_AIR_CONNECTION_MANUAL_FORM_REPORT:
            return [...state, payload];
        case GET_WATER_CONNECTION_MANUAL_FORM_REPORT:
            return [...state, payload];
        case GET_POWER_CONNECTION_MANUAL_FORM_REPORT:
            return [...state, payload];
        case GET_FRIEGHT_FORWARDER_MANUAL_FORM_REPORT:
            return [...state, payload];
        case GET_FASCIA_MANUAL_FORM_REPORT:
            return [...state, payload];
        case GET_E_DIRECTORY_MANUAL_FORM_REPORT:
            return [...state, payload];
        case GET_MEETING_ROOM_MANUAL_FORM_REPORT:
            return [...state, payload];
        case GET_EXH_BADGE_MANUAL_FORM_REPORT:
            return [...state, payload];
        case GET_EXHIBIT_DETAILS_MANUAL_FORM_REPORT:
            return [...state, payload];
        case GET_EXHIBITOR_CONTRACTOR_BADGE_FORM_REPORT:
            return [...state, payload];
        case GET_STALL_BOOKING_SUMMARY_FORM_REPORT:
            return [...state, payload];
        case GET_STALL_DESIGN_FORM_REPORT:
            return [...state, payload];
        case GET_VISITOR_BADGE_FORM_REPORT:
            return [...state, payload];
        case GET_STALL_POSSESSION_FORM_REPORT:
            return [...state, payload];
        case GET_VISA_APPLICATION_FORM_REPORT:
            return [...state, payload];
        default:
            return state;
    }
}

export default manualFormReportReducer;