import moment from "moment";
import { axiosGet, axiosPost } from "../../react_axios/Axios";
import { SEND_PAYMENT_FORM, sendPaymentFormUrl, headers } from "../../utils/constants";

export const sendPaymentForm_action = (exhibitionID, exhRegistrationID, amount, currency, proformaList, from, moreInfo, remark) => async (dispatch) => {
    try {
        const params = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')) != null && JSON.parse(sessionStorage.getItem('loginDataSS')) != '' && JSON.parse(sessionStorage.getItem('loginDataSS')).UserID != null && JSON.parse(sessionStorage.getItem('loginDataSS')).UserID != undefined && JSON.parse(sessionStorage.getItem('loginDataSS')).UserID != '' ? JSON.parse(sessionStorage.getItem('loginDataSS')).UserID : null,
            ExhibitionID: exhibitionID,
            ID: exhRegistrationID,
            amount: amount,
            currency: currency,
            proformaList: proformaList,
            from: from,
            moreInfo: moreInfo,
            remark: remark
        }
        console.log("pendingReceiptDetails_action-Action--------------------------->", params)

        const res = await axiosPost(sendPaymentFormUrl, params, headers);
        dispatch({
            type: SEND_PAYMENT_FORM,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};  