import { SELECT_EXHIBITION_ACTION_TYPE } from "../../../utils/constants"


const initialState = [];

function selectExhibitionReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SELECT_EXHIBITION_ACTION_TYPE:
      return [...state, payload];
    
    default:
      return state;
  }
};

export default selectExhibitionReducer;