import { GET_BRANDING_OPPORTUNITY_APPROVAL_DETAILS_ACTION_TYPE, GET_BRANDING_OPPORTUNITY_APPROVAL_LIST_ACTION_TYPE, GET_BRANDING_OPPORTUNITY_APPROVAL_EXPORT_DATA_ACTION_TYPE, UPDATE_BRANDING_OPPORTUNITY_APPROVAL__ACTION_TYPE } from "../../../../utils/constants";


const initialState = [];

function brandingOpportunityApprovalReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_BRANDING_OPPORTUNITY_APPROVAL_DETAILS_ACTION_TYPE:
            return [...state, payload];
        case GET_BRANDING_OPPORTUNITY_APPROVAL_LIST_ACTION_TYPE:
            return [...state, payload];
        case GET_BRANDING_OPPORTUNITY_APPROVAL_EXPORT_DATA_ACTION_TYPE:
            return [...state, payload];
        case UPDATE_BRANDING_OPPORTUNITY_APPROVAL__ACTION_TYPE:
            return [...state, payload];

        default:
            return state;
    }
};

export default brandingOpportunityApprovalReducer;