import { axiosGet, axiosPost } from "../../../react_axios/Axios";
import { GET_VISA_APPLICATION_ACTION_TYPE, GET_VISA_APPLICATION_EXPORT_ACTION_TYPE, GET_VISA_INVITATON_ACTION_TYPE, UPDATE_VISA_APPROVAL_ACTION_TYPE, getVisaApplicationExportUrl, getVisaApplicationUrl, getVisaInvitationUrl, headers, updateVisaApprovalUrl } from "../../../utils/constants";

export const getVisaInvitation_action = (companyName, status, exhibitorTypeId) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            CompanyName: companyName,
            Status: status,
            ExhibitorTypeID: exhibitorTypeId,
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getVisaInvitationUrl, params, headers)

        dispatch({
            type: GET_VISA_INVITATON_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getVisaApplication_action = (companyname) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            CompanyName: companyname,
        }
        console.log("getVisaApplication_action Action------->", params)

        const res = await axiosGet(getVisaApplicationUrl, params, headers)

        dispatch({
            type: GET_VISA_APPLICATION_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const updateVisaApproval_action = (isApproved, list) => async (dispatch) => {
    try {
        const mapData = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            Is_Approved: isApproved,
            approvalList: list
        }
        console.log("updateVisaApproval_action Action------->", mapData)

        const res = await axiosPost(updateVisaApprovalUrl, mapData, headers)

        dispatch({
            type: UPDATE_VISA_APPROVAL_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getVisaApplicationExportData_action = () => async (dispatch) => {
    try {
        const params = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
        }
        console.log("getVisaApplicationExportData_action Action------->", params)

        const res = await axiosGet(getVisaApplicationExportUrl, params, headers)

        dispatch({
            type: GET_VISA_APPLICATION_EXPORT_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}