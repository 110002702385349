import { INSERT_UPDATE_EXHIBITOR_SEMINAR,GET_EXHIBITOR_SEMINAR_DETAILS } from "../../../utils/constants";


const initialState = [];

function exhibitorSeminarReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case INSERT_UPDATE_EXHIBITOR_SEMINAR:
            return [...state, payload];
        case GET_EXHIBITOR_SEMINAR_DETAILS:
            return [...state, payload];
        default:
            return state;
    }
};

export default exhibitorSeminarReducer;