import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action } from '../../../../../actions/fillCombo_action';
import { cityActionId, countryActionId, toastError, toastSuccess, manualFormsListActionId } from "../../../../../utils/constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import { useNavigate } from "react-router-dom";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import { data } from "jquery";
import { getManualformsMasterList_action, insertUpdateManualFormsMaster_action, deleteManualformsMaster_action } from "../../../../../actions/admin_module_action/master_action/exhibition_master_action/manual_forms_master_action";


class ManageArea extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            list: [],
            manualFormsList: [],
            ManualFormMappingID: '',
            isOpen: false,
            toAdd: false,
            ExhibitionID: '',
        }
        this.onDeleteRow = this.onDeleteRow.bind(this)
        this.onDelete = this.onDelete.bind(this)
        this.onAddRow = this.onAddRow.bind(this)
        this.getFillList = this.getFillList.bind(this)
        this.getManualFormsMasterList = this.getManualFormsMasterList.bind(this)
        // this.addManualFormsMasterList = this.addManualFormsMasterList.bind(this)
        this.insertUpdateManualFormsMasterList = this.insertUpdateManualFormsMasterList.bind(this)
        this.onPriceINRChange = this.onPriceINRChange.bind(this)
        this.onPriceUSDChange = this.onPriceUSDChange.bind(this)
        this.onDateChange = this.onDateChange.bind(this)
        this.onNameChange = this.onNameChange.bind(this)
    }

    componentDidMount() {
        this.getFillList(manualFormsListActionId, '', '', '', '', '');
        this.setState({
            ExhibitionID: this.props.location.state.ExhibitionID != undefined ? (this.props.location.state != null && this.props.location.state.ExhibitionID != null ? this.props.location.state.ExhibitionID : '') : this.props.id != null && this.props.id != undefined ? this.props.id : '',
        })
        setTimeout(() => {

            this.getManualFormsMasterList()
        }, 200);
    }

    onAddRow(e) {
        e.preventDefault();
        // console.log(this.state.listLength)
        const { list } = this.state
        var id = list.length + 1
        var map = {
            Deadline: "",
            // Mandatory: 0,
            ManualFormID: '',
            ManualFormMappingID: null,
            PriceINR: null,
            PriceUSD: null,
            isAdd: true,
            isUpdate: true,
        }
        list.push(map)
        this.setState({ list: list, })
        // console.log(this.state.StallSizeList)
    }

    // Get Fill Lists
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value)
            .then((data) => {
                if (data.error != 1) {
                    // country list
                    if (actionID === manualFormsListActionId) {
                        console.log("country data", data.data)
                        this.setState({ manualFormsList: data.data, isLoading: false })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    getManualFormsMasterList() {
        this.setState({ isLoading: true })
        this.props.getManualformsMasterList_action(this.state.ExhibitionID)
            .then((data) => {
                if (data.error != 1) {
                    if (data.data.length > 0) {
                        if (data.data[0].length > 0) {
                            data.data[0].map(element => {
                                element.isUpdate = false
                                element.isAdd = false
                                element.isDirectApproval = element.isDirectApproved
                            })
                            console.log(data.data)
                            this.setState({
                                list: data.data[0],
                                isAdd: false
                            })
                        }
                        else {
                            this.setState({ isAdd: true })
                        }
                    }
                    else {
                        this.setState({ isAdd: true })
                    }
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    insertUpdateManualFormsMasterList() {
        this.setState({ isLoading: true })
        const { list, ExhibitionID } = this.state
        console.log(list)

        var finalList = []
        for (var i = 0; i < list.length; i++) {
            if (list[i].isUpdate == true) {
                finalList.push(list[i])
            }
        }
        var inputValidate = false
        for (var i = 0; i < list.length; i++) {
            if (list[i].ManualFormID == '' || list[i].ManualFormID == null) {
                inputValidate = true
                break
            }
            if (list[i].Deadline == '' || list[i].Deadline == null) {
                inputValidate = true
                break
            }
        }
        if (inputValidate == true) {
            toastError("Please Fill all the Mandatory fields")
        }
        else {
            for (var i = 0; i < finalList.length; i++) {
                var obj = finalList[i]
                delete obj.isAdd
                delete obj.isUpdate
            }
            console.log(finalList)
            this.props.insertUpdateManualFormsMaster_action(ExhibitionID, finalList)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({ isLoading: false })
                        this.getManualFormsMasterList()
                        toastSuccess("update successfully")
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    onDelete(i) {
        const { list } = this.state
        // var ManualFormMappingID = list[i].ManualFormMappingID
        if (list[i].isAdd == true) {
            list.splice(i, 1)
            this.setState({ list: list })
        }
        else {
            // this.setState({ isLoading: true })
            this.setState({ ManualFormMappingID: list[i].ManualFormMappingID })
            this.setState({ isOpen: true })

        }
    }

    onDeleteRow() {
        const { ManualFormMappingID, ExhibitionID } = this.state
        this.props.deleteManualformsMaster_action(ExhibitionID, ManualFormMappingID)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ isOpen: false })
                    this.setState({ isLoading: false, list: [] })

                    this.setState({ isLoading: false })
                    toastSuccess("delete successfully")
                    this.getManualFormsMasterList()
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    onPriceINRChange(e, ID) {
        e.preventDefault()
        const { list } = this.state
        list.map((data, i) => {
            if (ID == i) {
                data.PriceINR = parseFloat(e.target.value)
                data.isUpdate = true
            }
        })
        setTimeout(() => {
            console.log(list)
            this.setState({ list: list })
        }, 200);
    }

    onPriceUSDChange(e, ID) {
        e.preventDefault()
        const { list } = this.state
        list.map((data, i) => {
            if (ID == i) {
                data.PriceUSD = parseFloat(e.target.value)
                data.isUpdate = true
            }
        })
        setTimeout(() => {
            console.log(list)
            this.setState({ list: list })
        }, 200);
    }

    onDateChange(e, ID) {
        // e.preventDefault()
        const { list } = this.state
        console.log(e)
        list.map((data, i) => {
            if (ID == i) {
                data.Deadline = new Date(moment(e, 'DD-MM-YYYY').format('YYYY-MM-DD'))
                data.isUpdate = true
            }
        })
        setTimeout(() => {
            console.log(list)
            this.setState({ list: list })
        }, 200);
    }

    onCheckboxChange(e, ID) {
        // e.preventDefault()
        const { list } = this.state
        console.log(e)
        list.map((data, i) => {
            if (ID == i) {
                data.isDirectApproval = e.target.checked
                data.isUpdate = true
            }
        })
        setTimeout(() => {
            console.log(list)
            this.setState({ list: list })
        }, 200);
    }

    onNameChange(e, ID) {
        // e.preventDefault()
        const { list } = this.state
        console.log(e.target.value)
        var checkForm = false
        for (var i = 0; i < list.length; i++) {
            if (parseInt(e.target.value) == list[i].ManualFormID) {
                checkForm = true
                break
            }
        }
        if (checkForm == true) {
            toastError("Manual form already select in list")
        }
        else {

            list.map((data, i) => {
                if (ID == i) {
                    data.ManualFormID = parseInt(e.target.value)
                    data.isUpdate = true
                }
            })
        }
        setTimeout(() => {
            console.log(list)
            this.setState({ list: list })
        }, 200);
    }

    render() {
        const { isLoading, isOpen, list, manualFormsList } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                <AdminDrawer />
                <div className="main-container p-2 mt-5">
                    <div class="table-responsive">
                        <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-3">
                            <thead>
                                <tr>
                                    <th className="text-center">Name<span style={{ color: 'red' }}>*</span></th>
                                    <th className="text-center">Deadline<span style={{ color: 'red' }}>*</span></th>
                                    {/* <th className="text-center">Price (INR)</th> */}
                                    {/* <th className="text-center">Price (USD)</th> */}
                                    <th className="text-center">Approval</th>
                                    <th style={{ width: '60px' }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    list.length > 0 ? <>{
                                        list.map((data, i) => (
                                            <tr key={i}>
                                                <td>
                                                    <select
                                                        className="form-control"
                                                        value={data.ManualFormID}
                                                        onChange={(e) => this.onNameChange(e, i)}
                                                    >
                                                        <option>Select</option>
                                                        {manualFormsList.length > 0 &&
                                                            manualFormsList.map((data) => (
                                                                <option key={data.ManualFormID} value={data.ManualFormID}>
                                                                    {data.Name}
                                                                </option>
                                                            ))}
                                                    </select>
                                                </td>
                                                <td>
                                                    <DatePicker
                                                        selected={data.Deadline != null && data.Deadline != '' ? new Date(data.Deadline) : null}
                                                        dateFormat={"dd-MM-yyyy"} className="form-control"
                                                        onChange={(e) => this.onDateChange(e, i)}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        placeholderText="dd-mm-yyyy"
                                                        dayClassName={() => "example-datepicker-day-class"}
                                                        popperClassName="example-datepicker-class" />
                                                </td>
                                                {/* <td>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder=""
                                                        value={data.PriceINR}
                                                        // disabled={true}
                                                        onChange={(e) => this.onPriceINRChange(e, i)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder=""
                                                        value={data.PriceUSD}
                                                        // disabled={true}
                                                        onChange={(e) => this.onPriceUSDChange(e, i)}
                                                    />
                                                </td> */}
                                                <td className="text-center">
                                                    <input
                                                        type="checkbox"
                                                        placeholder=""
                                                        checked={data.isDirectApproval}
                                                        // disabled={true}
                                                        onChange={(e) => this.onCheckboxChange(e, i)}
                                                    />Checked for Direct Approval
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    <i className="fa fa-trash-o"
                                                        onClick={() => this.onDelete(i)}
                                                    />
                                                </td>

                                            </tr>
                                        ))}
                                    </> :
                                        <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                                }
                            </tbody>
                        </table>
                        <div style={{ float: 'right' }} >

                            <button type="button" class="btn btn-primary rounded-0 mt-3 " onClick={(e) => this.onAddRow(e)}>
                                Add Row
                            </button>
                            <button disabled={list.length == 0} type="button" class="btn btn-success rounded-0 mt-3 ms-2"
                                onClick={(e) => this.insertUpdateManualFormsMasterList(e)}>
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
                <Modal isOpen={isOpen} onRequestClose={() => this.setState({ isOpen: false })}>
                    <div className="modal-dialog">
                        <div className="modal-content confirm-logout">
                            {/* <div class="modal-header text-right p-2">
                                <button type="button " class="close pop-close" data-dismiss="modal"
                                    onClick={() => this.setState({ isOpen: false })}
                                ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
                            </div> */}
                            <div class="modal-body">
                                <div class="row">
                                    <h5 class="modal-title modal_title_text text-danger">Do you really want to delete this record?</h5>
                                </div>
                            </div>
                            <div class="modal-footer p-1">
                                <div class="form-group text-right mod-fot">
                                    <button type="button" class="btn btn-del-pop"
                                        onClick={(e) => this.onDeleteRow()}
                                    >Yes</button>
                                    <button type="button" class="btn btn-can-pop" data-dismiss="modal" style={{ marginLeft: '5px' }}
                                        onClick={() => this.setState({ isOpen: false })}
                                    >No</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
            </>
        )
    }
}

export default withRouter(connect(null, { fill_action, getManualformsMasterList_action, insertUpdateManualFormsMaster_action, deleteManualformsMaster_action })(ManageArea));