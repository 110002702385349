import { GET_SPACE_BOOKING_DETAILS_ACTION_TYPE, SPACE_BOOKING_EDIT_ACTION_TYPE } from "../../../utils/constants";


const initialState = [];

function spaceBookingReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case GET_SPACE_BOOKING_DETAILS_ACTION_TYPE:
            return [...state, payload];
        case SPACE_BOOKING_EDIT_ACTION_TYPE:
            return [...state, payload]

        default:
            return state;
    }
};

export default spaceBookingReducer;