import { REGISTRATION_FORM_ADD_ACTION_TYPE, 
  REGISTRATION_DESK_Details_ACTION_TYPE, 
  REGISTRATION_DESK_UPDATE_ACTION_TYPE,REGISTRATION_PENDING_DETAILS_ACTION_TYPE,
  REGISTRATION_DETAILS_ACTION_TYPE, 
  REGISTRATION_FORM_UPDATE_ACTION_TYPE,REGISTRATION_AGENT_DETAILS_ACTION_TYPE} from "../../utils/constants"


const initialState = [];

function registrationReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
   
      case REGISTRATION_FORM_ADD_ACTION_TYPE:
      return [...state, payload];
       
      case REGISTRATION_DETAILS_ACTION_TYPE:
      return [...state, payload];

      case REGISTRATION_FORM_UPDATE_ACTION_TYPE:
      return [...state, payload];

      case REGISTRATION_PENDING_DETAILS_ACTION_TYPE:
      return [...state, payload];

      case REGISTRATION_AGENT_DETAILS_ACTION_TYPE:
      return [...state, payload];
      
    default:
      return state;
  }
};

export default registrationReducer;