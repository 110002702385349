import { POWER_CONNECTION_LIST_ACTION_TYPE, POWER_CONNECTION_DETAILS_ACTION_TYPE, INSERT_AND_UPDATE_POWER_CONNECTION_ACTION_TYPE } from "../../../../../utils/constants";


const initialState = [];

function PowerConnectionReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case POWER_CONNECTION_LIST_ACTION_TYPE:
      return [...state, payload];
    case POWER_CONNECTION_DETAILS_ACTION_TYPE:
      return [...state, payload];
    case INSERT_AND_UPDATE_POWER_CONNECTION_ACTION_TYPE:
      return [...state, payload];
    default:
      return state;
  }
};

export default PowerConnectionReducer;