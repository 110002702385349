import { axiosGet, axiosPost } from "../../../react_axios/Axios";
import { headers, GET_LEDGER_LIST_ACTION_TYPE, getLedgerListUrl, SEND_LEDGER_ACTION_TYPE, sendLedgerUrl, SEND_OUTSTANDING_ACTION_TYPE, sendOutstandingUrl, fetchAuthReport } from "../../../utils/constants";


export const getLedgerList_action = (exhibitorId, accountID, currencyID, fromAmount, toAmount, exhibitorTypeId) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem("sessionExhibitionID"),
            ExhRegistrationID: exhibitorId != '' ? exhibitorId : null,
            AccountID: accountID != '' ? accountID : null,
            CurrencyID: currencyID != '' ? currencyID : null,
            FromAmount: fromAmount != '' ? fromAmount : null,
            ToAmount: toAmount != '' ? toAmount : null,
            ExhibitorType: exhibitorTypeId
        }
        console.log("sessionStorage.getItem('ExhRegistrationIDSS')", sessionStorage.getItem('ExhRegistrationIDSS'))

        const res = await axiosGet(getLedgerListUrl, params, headers)

        dispatch({
            type: GET_LEDGER_LIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const sendLedger_action = (receiptIdList) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem("sessionExhibitionID"),
            ReceiptIdList: receiptIdList,
        }

        const res = await axiosPost(sendLedgerUrl, params, headers)

        dispatch({
            type: SEND_LEDGER_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const sendOutstanding_action = (receiptIdList) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ReceiptIdList: receiptIdList,
            ExhibitionID: sessionStorage.getItem("sessionExhibitionID"),
        }

        const res = await axiosPost(sendOutstandingUrl, params, headers)

        dispatch({
            type: SEND_OUTSTANDING_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getLedgerPdf_action = (exhRegId) => async (dispatch) => {
    try {
        var list = [];
        const mapdata1 = {
            "ParameterName": "@ExhRegistrationID",
            "ParameterValue": exhRegId,
            "ParameterDataType": "int"
        }
        list.push(mapdata1);
        const mapData = {
            "report_name": 'Outstanding.rpt',
            "parameter_details": list
        }
        const res = await axiosPost(fetchAuthReport, mapData);
        dispatch({
            type: SEND_OUTSTANDING_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};