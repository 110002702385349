import { axiosGet, axiosPost } from "../../../../react_axios/Axios";
import { headers, getSecurityApplicationMasterListUrl, insertUpdateSecurityApplicationMasterUrl, deleteSecurityApplicationMasterUrl, DELETE_SECURITY_APPLICATION_MASTER, INSERT_UPDATE_SECURITY_APPLICATION_MASTER, GET_SECURITY_APPLICATION_MASTER_LIST } from "../../../../utils/constants";


export const insertUpdateSecurityApplicationMaster_action = (SecurityApplicationContactBankList, SecurityApplicationDescriptionList) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            SecurityApplicationContactBankList: SecurityApplicationContactBankList,
            SecurityApplicationDescriptionList: SecurityApplicationDescriptionList,
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosPost(insertUpdateSecurityApplicationMasterUrl, params, headers)

        dispatch({
            type: INSERT_UPDATE_SECURITY_APPLICATION_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getSecurityApplicationMasterList_action = () => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getSecurityApplicationMasterListUrl, params, headers)

        dispatch({
            type: GET_SECURITY_APPLICATION_MASTER_LIST,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const deleteSecurityApplicationMaster_action = (SecurityApplicationContactPersonID, SecurityApplicationDescriptionID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            SecurityApplicationContactPersonID: SecurityApplicationContactPersonID,
            SecurityApplicationDescriptionID: SecurityApplicationDescriptionID
        }
        console.log("deleteExhibitionDocument_action Action------->", params)

        const res = await axiosGet(deleteSecurityApplicationMasterUrl, params, headers)

        dispatch({
            type: DELETE_SECURITY_APPLICATION_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}