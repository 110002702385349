import { ADD_E_DIRECTORY_ACTION_TYPE, DEL_DIRECTORY_ACTION_TYPE, GET_E_DIRECTORY_DETAILS_ACTION_TYPE, GET_E_DIRECTORY_LIST_ACTION_TYPE, UPDATE_E_DIRECTORY_ACTION_TYPE } from "../../../../../utils/constants";


const initialState = [];

function eDirectoryReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case GET_E_DIRECTORY_LIST_ACTION_TYPE:
            return [...state, payload];
        case ADD_E_DIRECTORY_ACTION_TYPE:
            return [...state, payload];
        case GET_E_DIRECTORY_DETAILS_ACTION_TYPE:
            return [...state, payload];
        case UPDATE_E_DIRECTORY_ACTION_TYPE:
            return [...state, payload];
        case DEL_DIRECTORY_ACTION_TYPE:
            return [...state, payload];

        default:
            return state;
    }
};

export default eDirectoryReducer;