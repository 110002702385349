import { GET_INETRNET_CONNECTION_DETAILS_ACTION_TYPE, INSERT_UPDATE_INTERNET_CONNECTION_ACTION_TYPE } from "../../../../../utils/constants";


const initialState = [];

function InternetConnectionReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_INETRNET_CONNECTION_DETAILS_ACTION_TYPE:
      return [...state, payload];
    case INSERT_UPDATE_INTERNET_CONNECTION_ACTION_TYPE:
      return [...state, payload];
    default:
      return state;
  }
};

export default InternetConnectionReducer;