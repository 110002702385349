import { axiosGet, axiosPost } from "../../../react_axios/Axios"
import {
    headers, exhibitionDashboardUrl,
    exhibitionDrawerDetailsUrl, uploadUndertakingUrl,
    EXHIBITOR_DASHBOARD_ACTION_TYPE, EXHIBITOR_DRAWER_DETAILS_ACTION_TYPE, UPLOAD_UNDERTAKING_ACTION_TYPE, insertExhibitorBarcodeUrl, INSERT_EXHIBITOR_BARCODE_ACTION_TYPE
} from "../../../utils/constants"



export const getExhibitorDashboard_action = () => async (dispatch) => {

    try {
        const params = {
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhregistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
            AccountID: JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID,
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
        }
        console.log("getExhibitorDashboard_action Action------->", params)

        const res = await axiosGet(exhibitionDashboardUrl, params, headers)

        dispatch({
            type: EXHIBITOR_DASHBOARD_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}


export const getDrawerDetails_action = () => async (dispatch) => {

    try {
        const params = {
            ExhibitionID:sessionStorage.getItem('sessionExhibitionID')
        }
        console.log("getDrawerDetails_action Action------->", params)

        const res = await axiosGet(exhibitionDrawerDetailsUrl, params, headers)

        dispatch({
            type: EXHIBITOR_DRAWER_DETAILS_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const uploadUndertaking_action = (file, fileType) => async (dispatch) => {

    try {
        const mapData = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhRegistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
            ExhRegistrationNo: sessionStorage.getItem('ExhRegistrationNo'),
            file: file,
            fileType: fileType,
        }
        const res = await axiosPost(uploadUndertakingUrl, mapData, headers)

        dispatch({
            type: UPLOAD_UNDERTAKING_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const insertExhibitorBarcode_action = () => async (dispatch) => {

    try {
        const mapData = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhregistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
            StallNo: JSON.parse(sessionStorage.getItem('sessionHeaderData'))[0]['BothAllotted'],
            HallNo: JSON.parse(sessionStorage.getItem('sessionHeaderData'))[0]['HallNo']
        }
        console.log("getExhibitorDashboard_action Action------->", mapData)

        const res = await axiosPost(insertExhibitorBarcodeUrl, mapData, headers)

        dispatch({
            type: INSERT_EXHIBITOR_BARCODE_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

