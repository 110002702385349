import { GET_STALL_DESIGN_DETAILS_ACTION_TYPE, INSERT_UPDATE_STALL_DESIGN_ACTION_TYPE } from "../../../../../utils/constants";

const initialState = [];

function stallDesignReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case GET_STALL_DESIGN_DETAILS_ACTION_TYPE:
            return [...state, payload];
        case INSERT_UPDATE_STALL_DESIGN_ACTION_TYPE:
            return [...state, payload];

        default:
            return state;
    }
};

export default stallDesignReducer;