import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../../../common_components/Header';
import Footer from '../../../../common_components/Footer';
import Drawer from '../../../../common_components/Drawer';
import {
    addVendorRegistration_action, updateVendorRegistration_action, getDetailsVendorRegistration_action
} from '../../../../actions/admin_module_action/vendor_action/vendorRegistration_action/vendorRegistration_action'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../../utils/withRouter';
import moment from 'moment';
import Modal from "react-modal";
import Loader from '../../../../utils/Loader'
import {
    exhibitionId, countryActionId,
    stateActionId, cityActionId, pincodeActionId, verifyPrimaryEmailIDActionId,
    toastError, toastSuccess, serviceTypeId
} from '../../../../utils/constants'
import Pagination from '../../../../utils/pagination'
import { useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { fill_action } from '../../../../actions/fillCombo_action'
import Resizer from "react-image-file-resizer";
import { contactNoValidator, panNoValidator, tanNoValidator, gstNoValidator, emailValidator, pincodeValidator } from '../../../../utils/Validator'
import $ from 'jquery'
import HeaderWithLogo from "../../../../common_components/header_with_logo";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ExhibitorHeader from "../../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../../common_components/exhibitor_module_drawer";
import ExhibitorTopMenu from "../../../../common_components/exhibitor_module_topmenu";
import AdminDrawer from "../../../../common_components/admin_module_drawer";

/* 
 * mapDispatchToProps
*/
// const mapDispatchToProps = dispatch => ({
//     loginAction: () => dispatch(loginAction())
// })

/* 
 * mapStateToProps
*/
const mapStateToProps = state => ({
    ...state
})

class VendorRegistrationAU extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isCorrespondenceDetails: false,
            isFirstStepCompleted: false,
            is_update: false,
            isCountryIndia: false,
            countryNamePre: '',
            countryNamePreRegisterMobileNo: '',
            countryNamePreRegisterAlternateMobileNo: '',
            countryNamePreOwnerMobileNo: '',
            countryNamePreInvoiceregisterMobileNo: '',
            countryNamePreInvoiceregisterAlternateMobileNo: '',

            isPrimaryEmailId: false,
            isSecondaryEmailId: false,
            isOwenerEmailId: false,

            isInvoiceprimaryEmailId: false,
            isInvoicesecondaryEmailId: false,

            registerTelephoneNoPre: "",
            registerTelephoneNo1Pre: "",

            invLastNamePersonIncharge: "",
            lastNamePersonIncharge: "",
            invLastNamePersonInchargeErr: "",
            lastNamePersonInchargeErr: "",

            invoiceregisterTelephoneNoPre: "",
            invoiceregisterTelephoneNo1Pre: "",

            formFileType: '',
            declarationFileType: '',
            trcFileType: '',
            isSelfPaid: false,



            countryList: [],
            stateList: [],
            cityList: [],
            pincodeList: [],
            tempStateList: [],
            tempCityList: [],
            tempPincodeList: [],
            _pincodeList: [],
            isEmailExist: false,

            InvtempStateList: [],
            InvtempCityList: [],
            InvtempPincodeList: [],

            formFile: '',
            declarationFile: '',
            trcFile: '',

            countryId: '',
            countryName: "",
            registerMobileNo: "",
            registerMobileNoErr: '',

            companyName: "",
            companyId: '',
            fasciaName: "",
            address1: "",
            address2: "",
            cityId: '',
            cityName: "",
            stateId: '',
            stateName: "",
            pinCode: "",
            inchargeFirstName: "",
            designation: "",
            registerAlternateMobileNo: "",
            registerTelephoneNo: "",
            registerTelephoneNo1: "",
            registerTelefaxNo: "",
            primaryEmailId: "",
            secondaryEmailId: "",
            website: "",
            nameOfOwner: "",
            lastName: "",
            ownerMobileNo: "",
            ownerEmailId: "",
            countryId: "",

            title_inchargeFirstName: "Mr",
            title_name_Of_CEO_MD_President_Owner: "Mr",
            title_InvPersonIncharge: "Mr",

            isOther_title_inchargeFirstName: false,
            isOther_title_name_Of_CEO_MD_President_Owner: false,
            isOther_title_InvPersonIncharge: false,

            other_title_inchargeFirstName: '',
            other_title_name_Of_CEO_MD_President_Owner: '',
            other_title_InvPersonIncharge: '',


            invoicecompanyName: "",
            invoicecompanyId: '',
            invoicefasciaName: "",
            invoiceaddress1: "",
            invoiceaddress2: "",
            invoicecityId: '',
            invoicecityName: "",
            invoicestateId: '',
            invoicestateName: "",
            invoicepinCode: "",
            invoiceinchargeFirstName: "",
            invoicedesignation: "",
            invoiceregisterMobileNo: "",
            invoiceregisterAlternateMobileNo: "",
            invoiceregisterTelephoneNo: "",
            invoiceregisterTelephoneNo1: "",
            invoiceregisterTelefaxNo: "",
            invoiceprimaryEmailId: "",
            invoicesecondaryEmailId: "",
            invoicewebsite: "",
            invoicenameOfOwner: "",
            invoicelastName: "",
            invoiceownerMobileNo: "",
            invoiceownerEmailId: "",
            invoicecountryName: "",
            invoicecountryid: '',
            compInfoNameOnPanCard: "",
            compInfoPanNo: "",
            compInfoTAN: "",
            compInfoGstNo: "",
            compInfoRegistrationNo: "",
            compInfoIecCode: "",

            bankDtlAccountName: "",
            bankDtlAccountNo: "",
            bankDtlAccountType: "",
            bankDtlAccountTypeOthers: "",
            bankDtlBankName: "",
            bankDtlBranchName: "",
            bankDtlIfscCode: "",

            // error state
            companyNameErr: '',
            fasciaNameErr: '',
            address1Err: '',
            address2Err: '',
            registerMobileNoErr: '',
            cityNameErr: '',
            stateNameErr: '',
            pinCodeErr: '',
            countryNameErr: '',

            inchargeFirstNameErr: '',
            designationErr: '',
            registerAlternateMobileNoErr: '',
            registerTelephoneNoErr: '',
            registerTelefaxNoErr: '',
            primaryEmailIdErr: '',
            secondaryEmailIdErr: '',
            websiteErr: '',
            nameOfOwnerErr: '',

            lastNameErr: '',
            ownerMobileNoErr: '',
            ownerEmailIdErr: '',


            invoicecompanyNameErr: "",
            invoicecompanyIdErr: 0,
            invoicefasciaNameErr: "",
            invoiceaddress1Err: "",
            invoiceaddress2Err: "",
            invoicecityIdErr: 0,
            invoicecityNameErr: "",
            invoicestateIdErr: 0,
            invoicestateNameErr: "",
            invoicepinCodeErr: "",
            invoicecountryNameErr: "",
            invoiceinchargeFirstNameErr: "",
            invoicedesignationErr: "",
            invoiceregisterMobileNoErr: "",
            invoiceregisterAlternateMobileNoErr: "",
            invoiceregisterTelephoneNoErr: "",
            invoiceregisterTelefaxNoErr: "",
            invoiceprimaryEmailIdErr: "",
            invoicesecondaryEmailIdErr: "",
            invoicewebsiteErr: "",
            invoicenameOfOwnerErr: "",
            invoicelastNameErr: "",
            invoiceownerMobileNoErr: "",
            invoiceownerEmailIdErr: "",
            countryNameErr: "",

            compInfoNameOnPanCardErr: "",
            compInfoPanNoErr: "",
            compInfoTANErr: "",
            compInfoGstNoErr: "",
            compInfoRegistrationNoErr: "",
            compInfoIecCodeErr: "",

            bankDtlAccountNameErr: "",
            bankDtlAccountNoErr: "",
            bankDtlAccountTypeErr: "",
            bankDtlAccountTypeOthersErr: "",
            bankDtlBankNameErr: "",
            bankDtlBranchNameErr: "",
            bankDtlIfscCodeErr: "",

            exhRegistrationID: 0,
            exhibitionID: 0,
            exhRegistrationNo: '',
            exhRegistrationDate: '',

            isDisplayCityList: false,
            isDisplayStateList: false,
            isDisplayPincodeList: false,

            isDisplayInvCityList: false,
            isDisplayInvStateList: false,
            isDisplayInvPincodeList: false,

            namePrefix: ['Mr', 'Ms', 'DR', 'Prof'],

            add: false,
            update: false,
            serviceTypeId: '',
            serviceTypeList: [],
            renderServiceTypeList: [],
            password: '',
            passwordErr: '',
            Billing: 1,
            Approval_Rights: 1,
            countryCode: '',
            exhibitionId: '',
            exhibitionList: [],
            serviceTypeErr: '',
            exhibitionErr: '',
            PreMobileNo: '',
            PreAlternateMobileNo: '',
            PreTelephoneNo: '',
            PreTelephoneNo1: '',
            preMobile: '',
        }
        this.getFillList = this.getFillList.bind(this)
        this.inputValidate = this.inputValidate.bind(this)
    }

    onSelectCityId(e) {
        e.preventDefault();
        const { cityId, cityList, cityName } = this.state

        let cityData = cityList.filter(x => x.Code == e.target.value)[0]
        this.setState({ cityId: cityData.Code, cityName: cityData.Description });

        this.getFillList(pincodeActionId, '', '', cityData.Code, '', '')

        // cityList.map(data => {
        //     data.Code == cityName && this.setState({ cityId: data.Code, cityName: data.Description , cityNameErr:''})
        // });
    }

    onSelectStateId(e) {
        e.preventDefault();
        const { stateId, stateList, stateName, _pincodeList, pincodeList, cityName } = this.state

        stateList.map(data => {
            data.Code == stateName && this.setState({
                stateId: data.Code, stateName: data.Description,
                stateNameErr: '', _pincodeList: pincodeList.filter(x => x.StateName == stateName && x.CityName == cityName)
            })
        });
    }

    onSelectPincodeId(e) {
        e.preventDefault();
        const { pincodeId, pincodeList, pinCode } = this.state
        pincodeList.map(data => {
            data.Code == pinCode && this.setState({ pincodeId: data.Code, pinCode: data.Description, pinCodeErr: '' })
        });
    }


    componentDidMount() {

        console.log('prop is :', this.props);
        this.getFillList(countryActionId, sessionStorage.getItem("sessionExhibitionID"), '', '', '', '')
        this.getFillList(cityActionId, sessionStorage.getItem("sessionExhibitionID"), '', this.props.location.state.countryId, '', '')
        this.getFillList(stateActionId, sessionStorage.getItem("sessionExhibitionID"), '', this.props.location.state.countryId, '', '')
        this.getFillList(pincodeActionId, sessionStorage.getItem("sessionExhibitionID"), '', this.props.location.state.countryId, '', '')
        this.getFillList(serviceTypeId, sessionStorage.getItem("sessionExhibitionID"), '', this.props.location.state.countryId, '', '')
        this.getFillList(exhibitionId, sessionStorage.getItem("sessionExhibitionID"), '', this.props.location.state.countryId, '', '')
        // console.log("7879", this.props.location.state.countryId)

        this.setState({
            add: this.props.location.state != null && this.props.location.state.add != null ? this.props.location.state.add : null,
            update: this.props.location.state != null && this.props.location.state.update != null ? this.props.location.state.update : null,
        })

        this.props.location.state != null && this.props.location.state.update != null && this.props.location.state.update ? this.detailsVendorRegistration() : <></>

    }

    loadscript() {
        $(document).ready(function () {
            $(this).scrollTop(0);
        });
    }

    // render city
    renderCityList() {
        const { cityList, cityName, tempCityList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1, cursor: 'pointer' }}>
                {
                    tempCityList.length > 0 && tempCityList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({ cityName: data.Description, isDisplayCityList: false })}
                        >{data.Description}</li>

                    ))
                }
            </ul>
        )
    }
    // searcg city
    onCitySearch(e) {
        const { cityList, cityName, tempCityList, countryName } = this.state
        if (e.target.value != '') {
            this.setState({ cityNameErr: '' })
            if (countryName == 91 || countryName == 'select' || countryName == '') {
                var searchCityList = tempCityList.filter(data => {
                    return (
                        data
                            .Description
                            .toLowerCase()
                            .includes(e.target.value.toLowerCase())
                    )
                })
                this.setState({
                    cityName: e.target.value.replace(/[^a-zA-Z ]/g, '').split(' ')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' '),
                    cityNameErr: '',
                    tempCityList: searchCityList,
                    isDisplayCityList: true
                })
                // call state fill list pass city id 
                //this.getFillList(stateActionId,"","",)
            } else {
                this.setState({
                    cityName: e.target.value.replace(/[^a-zA-Z ]/g, '').split(' ')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' '),
                    isDisplayCityList: false
                })
            }
        } else {
            this.setState({
                cityName: '',
                tempCityList: cityList,
                isDisplayCityList: false
            })
        }
    }
    // state list
    callStateList(value) {

        const { stateList, stateName, tempStateList } = this.state
        this.setState({ stateName: value, isDisplayStateList: false })
        this.getFillList(stateActionId, '', '', '', stateName, '', '')

    }

    // render state
    renderStateList() {
        const { stateList, stateName, tempStateList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1, cursor: 'pointer' }}>
                {
                    tempStateList.length > 0 && tempStateList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.callStateList(data.Description)}
                        >{data.Description}</li>

                    ))
                }
            </ul>
        )
    }
    // search state
    onStateSearch(e) {
        const { stateList, stateName, tempStateList, countryName } = this.state
        console.log("onStateSearch")
        if (e.target.value != '') {
            this.setState({ stateNameErr: '' })
            if (countryName == 91 || countryName == 'select' || countryName == '') {
                var searchStateList = tempStateList.filter(data => {
                    return (
                        data
                            .Description
                            .toLowerCase()
                            .includes(e.target.value.toLowerCase())
                    )
                })
                this.setState({
                    stateName: e.target.value.replace(/[^a-zA-Z ]/g, '').split(' ')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' '),
                    stateNameErr: '',
                    tempStateList: searchStateList,
                    isDisplayStateList: true
                })
                // call pincode fill list pass city id and state id
                // this.getFillList(pincodeActionId, '', '', '', stateName, '')
            } else {
                this.setState({
                    stateName: e.target.value.replace(/[^a-zA-Z ]/g, '').split(' ')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' '),
                    isDisplayStateList: false
                })
            }
        } else {
            this.setState({
                stateName: '',
                tempStateList: stateList,
                isDisplayStateList: false
            })
        }
    }

    // render pincode
    renderPincodeList() {
        const { pincodeList, pinCode, tempPincodeList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1, cursor: 'pointer' }}>
                {
                    tempPincodeList.length > 0 && tempPincodeList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({
                                pinCode: data.Pincode,
                                isDisplayPincodeList: false
                            })}
                        >{data.Pincode}</li>

                    ))
                }
            </ul>
        )
    }
    // search pincode
    onPincodeSearch(e) {
        const { pincodeList, pinCode, tempPincodeList, countryName } = this.state
        if (e.target.value != '') {
            this.setState({ pinCodeErr: '' })
            if (countryName == 91 || countryName == 'select' || countryName == '') {
                var searchPincodeList = tempPincodeList.filter(data => {
                    return (
                        data
                            .Pincode
                            .toLowerCase()
                            .includes(e.target.value.toLowerCase())
                    )
                })
                console.log("onPincodeSearch 123", countryName)
                // console.log('pincodeCount', searchPincodeList.length)
                this.setState({
                    tempPincodeList: searchPincodeList,
                    pinCode: e.target.value.replace(/\D/, ''),
                    pinCodeErr: '',
                    isDisplayPincodeList: true
                })
            } else {
                this.setState({
                    pinCode: e.target.value.replace(/\D/, ''),
                    isDisplayPincodeList: false
                })
            }
        } else {
            this.setState({
                pinCode: '',
                tempPincodeList: pincodeList,
                isDisplayPincodeList: false
            })
        }
    }


    inputValidate() {
        const { countryId, countryName, registerMobileNo, registerMobileNoErr, countryNamePre,
            companyName, companyId, fasciaName, address1, address2, cityId, cityName, stateId, stateName, pinCode, inchargeFirstName, designation,
            registerAlternateMobileNo, registerTelephoneNo, registerTelephoneNo1, registerTelefaxNo, primaryEmailId, secondaryEmailId, website, nameOfOwner, lastName,
            ownerMobileNo, ownerEmailId, password,

            compInfoNameOnPanCard, compInfoPanNo, compInfoTAN, compInfoGstNo, compInfoRegistrationNo, compInfoIecCode,
            bankDtlAccountName, bankDtlAccountNo, bankDtlAccountType, bankDtlBankName, bankDtlBranchName, bankDtlIfscCode,

            compInfoNameOnPanCardErr,
            isCountryIndia, invLastNamePersonIncharge, lastNamePersonIncharge,
            invLastNamePersonInchargeErr, lastNamePersonInchargeErr, serviceTypeId, exhibitionId, bankDtlAccountTypeOthers, renderServiceTypeList

        } = this.state

        if (exhibitionId == '' || exhibitionId == 'Select' || exhibitionId == null) {
            this.setState({
                exhibitionErr: 'Please select exhibition'
            })
        }

        if (renderServiceTypeList == '') {
            this.setState({
                serviceTypeErr: 'Please select service type'
            })
        }

        if (lastNamePersonIncharge == '') {
            this.setState({
                lastNamePersonInchargeErr: 'Please enter last name'
            })
        }

        if (invLastNamePersonIncharge == '') {
            this.setState({
                invLastNamePersonInchargeErr: 'Please enter last name'
            })
        }

        if (companyName == '') {
            this.setState({
                companyNameErr: 'Please enter company name'
            })
        }
        if (fasciaName == '') {
            this.setState({
                fasciaNameErr: 'Please enter fascia name'
            })
        }
        if (address1 == '') {
            this.setState({
                address1Err: 'Please enter address'
            })
        }

        if (registerMobileNo == '') {
            this.setState({
                registerMobileNoErr: 'Please enter mobile number'
            })
        }

        if (cityName == '') {
            this.setState({
                cityNameErr: 'Please enter city'
            })
        }

        if (stateName == '' && (countryName == '' || countryName == 'Select' || countryName == 91)) {
            this.setState({
                stateNameErr: 'Please enter state'
            })
        }

        if (password == '') {
            this.setState({
                passwordErr: 'Please enter password'
            })
        }

        if (pinCode == '') {
            this.setState({
                pinCodeErr: 'Please enter pincode'
            })
        }

        if (countryName == '' || countryName == 'Select') {
            this.setState({
                countryNameErr: 'Please select country'
            })
        }
        if (inchargeFirstName == '') {
            this.setState({
                inchargeFirstNameErr: 'Please enter person incharge first name'
            })
        }
        if (designation == '') {
            this.setState({
                designationErr: 'Please enter designation'
            })
        }
        if (primaryEmailId == '') {
            this.setState({
                primaryEmailIdErr: 'Please enter primary email'
            })
        }
        if (nameOfOwner == '') {
            this.setState({
                nameOfOwnerErr: 'Please enter name of owner'
            })
        }
        if (ownerEmailId == '') {
            this.setState({
                ownerEmailIdErr: 'Please enter email'
            })
        }


        // // account details
        // if (bankDtlAccountName == '') {
        //     this.setState({
        //         bankDtlAccountNameErr: 'Please enter account name'
        //     })
        // }
        // if (bankDtlAccountNo == '') {
        //     this.setState({
        //         bankDtlAccountNoErr: 'Please enter account number'
        //     })
        // }
        // if (bankDtlIfscCode == '') {
        //     this.setState({
        //         bankDtlIfscCodeErr: 'Please enter IFSC Code'
        //     })
        // }
        // if (bankDtlAccountType == '' || bankDtlAccountType == 'Select') {
        //     this.setState({
        //         bankDtlAccountTypeErr: 'Please select account type'
        //     })
        // }
        // if (bankDtlAccountType == 'Others') {
        //     console.log("bankDtlAccountTypeOthers", bankDtlAccountTypeOthers)
        //     if (bankDtlAccountTypeOthers == '') {
        //         this.setState({
        //             bankDtlAccountTypeOthersErr: 'Please enter account type'
        //         })
        //     }
        // }


        // if (countryName == '' || countryName == 'Select' || countryName == 91) {
        //     if (compInfoNameOnPanCard == '' || compInfoNameOnPanCard == null) {
        //         this.setState({
        //             compInfoNameOnPanCardErr: 'Please enter name on pan card'
        //         })
        //     }
        //     if (compInfoPanNo == '' || compInfoPanNo == null) {
        //         this.setState({
        //             compInfoPanNoErr: 'Please enter pan no'
        //         })
        //     }
        //     if (compInfoTAN == '' || compInfoTAN == null) {
        //         this.setState({
        //             compInfoTANErr: 'Please enter tan no'
        //         })
        //     }
        //     if (compInfoGstNo == '' || compInfoGstNo == null) {
        //         this.setState({
        //             compInfoGstNoErr: 'Please enter gst no'
        //         })
        //     }
        // }

    }

    maxLengthCheck = (object, maxLength) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }

    }

    onlyNumbers = (event) => {
        var charCode = (event.which) ? event.which : event.keyCode
        if ((charCode >= 48 && charCode <= 57))
            return true;
        return false;
    }

    // get fill List dropdown  
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        const { isPrimaryEmailId, isSecondaryEmailId, isOwenerEmailId, isInvoiceprimaryEmailId, isInvoicesecondaryEmailId } = this.state
        this.setState({ isLoading: true })
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value)
            .then((data) => {
                if (data.error != 1) {
                    // country list
                    if (actionID === countryActionId) {
                        console.log("country data", data.data)
                        this.setState({
                            countryList: data.data, isLoading: false,
                        })
                    }
                    // state list
                    if (actionID === stateActionId) {
                        console.log("stateList data", data.data)
                        this.setState({
                            stateList: data.data,
                            tempStateList: data.data,
                            InvtempStateList: data.data,
                            isLoading: false,
                        })
                    }

                    // city list
                    if (actionID === cityActionId) {
                        console.log("city data", data.data)
                        this.setState({
                            cityList: data.data,
                            tempCityList: data.data,
                            InvtempCityList: data.data,
                            isLoading: false,
                        })
                    }


                    // pincode
                    if (actionID === pincodeActionId) {
                        // console.log("pincode data", data.data)
                        this.setState({
                            pincodeList: data.data,
                            tempPincodeList: data.data,
                            InvtempPincodeList: data.data,
                            isLoading: false,
                        })
                    }

                    if (actionID === serviceTypeId) {
                        // console.log("pincode data", data.data)
                        this.setState({
                            serviceTypeList: data.data,
                            isLoading: false,
                        })
                    }
                    if (actionID === exhibitionId) {
                        // console.log("voucher type data", data.data)
                        this.setState({
                            exhibitionList: data.data, isLoading: false,
                        })
                    }

                    // verify email existence
                    if (actionID === verifyPrimaryEmailIDActionId) {
                        // console.log("is Email exist", data.data)
                        this.setState({
                            isLoading: false,
                        })

                        if (data.data[0].Description != undefined) {
                            if (from == 'PrimaryEmailId') {
                                this.setState({
                                    primaryEmailId: '',
                                })
                            }
                            if (from == 'InvPrimaryEmailId') {
                                this.setState({
                                    invoiceprimaryEmailId: '',
                                })
                            }

                        }

                        if (isPrimaryEmailId) {
                            this.setState({ isPrimaryEmailId: false, primaryEmailId: '' })
                        }
                        else if (isSecondaryEmailId) {
                            this.setState({ isSecondaryEmailId: false, secondaryEmailId: '' })
                        }
                        else if (isOwenerEmailId) {
                            this.setState({ isOwenerEmailId: false, ownerEmailId: '' })
                        }

                        if (isInvoiceprimaryEmailId) {
                            this.setState({ isInvoiceprimaryEmailId: false, invoiceprimaryEmailId: '' })
                        }
                        else if (isInvoicesecondaryEmailId) {
                            this.setState({ isInvoicesecondaryEmailId: false, invoicesecondaryEmailId: '' })
                        }

                        toastError(data.data[0].Description);
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    validateNumberField = myNumber => {
        const numberRegEx = /\-?\d*\.?\d{1,2}/;
        return numberRegEx.test(String(myNumber).toLowerCase());
    };

    changeUrl = e => {
        const { value } = e.target;
        const isValid = !value;
        console.log('isValid', isValid)
        this.setState({ pinCode: e.target.value, pinCodeErr: '' })
    };


    onCountry(e) {
        const { InvtempPincodeList, InvtempStateList, InvtempCityList, countryList } = this.state
        this.setState({ countryName: e.target.value, countryNameErr: '' })
        console.log("e.target.value", e.target.value)
        if (e.target.value != '') {
            if (e.target.value != 91) {
                this.setState({
                    address1: '',
                    address2: '',
                    pinCode: '',
                    stateName: '',
                    cityName: '',
                    pincodeList: [],
                    cityList: [],
                    stateList: [],
                    InvtempPincodeList: [],
                    InvtempStateList: [],
                    InvtempCityList: [],
                    isDisplayCityList: false,
                    isDisplayStateList: false,
                    isDisplayPincodeList: false,
                    isDisplayInvCityList: false,
                    isDisplayInvStateList: false,
                    isDisplayInvPincodeList: false,
                    // compInfoNameOnPanCard: '',
                    // compInfoPanNo: '',
                    // compInfoTAN: '',
                    // compInfoGstNo: ''
                })
            } else {
                this.setState({
                    address1: '',
                    address2: '',
                    pinCode: '',
                    stateName: '',
                    cityName: '',
                })
            }


            countryList.map((data) => {
                if (data.Code == e.target.value) {
                    this.setState({
                        preMobile: data.CountryCode,
                        PreMobileNo: data.CountryCode,
                        PreAlternateMobileNo: data.CountryCode,
                    })
                }
            })

        } else {
            this.getFillList(cityActionId, sessionStorage.getItem("sessionExhibitionID"), '', e.target.value, '', '')
            this.getFillList(stateActionId, sessionStorage.getItem("sessionExhibitionID"), '', e.target.value, '', '')
            this.getFillList(pincodeActionId, sessionStorage.getItem("sessionExhibitionID"), '', '', '', '')
            // this.setState({
            //     compInfoNameOnPanCard: '',
            //     compInfoPanNo: '',
            //     compInfoTAN: '',
            //     compInfoGstNo: ''
            // })
        }

        // countryList.map((data) => {
        //     if (data.Code == e.target.value) {
        //         this.setState({
        //             countryCode: data.CountryCode
        //         })
        //     }
        // })

    }

    onServiceType(e) {
        const { serviceTypeList, renderServiceTypeList } = this.state
        this.setState({ serviceTypeId: e.target.value, serviceTypeErr: '' })
        const value = e.target.value
        if (value != '' && value != 'Select') {
            const checkedId = renderServiceTypeList.some(item => item.ServiceID == value)
            if (!checkedId) {
                serviceTypeList.map((item) => {
                    if (e.target.value == item.ServiceID) {
                        renderServiceTypeList.push(item)
                        setTimeout(() => {
                            this.setState({ serviceTypeId: '' })
                        }, 500);
                    }
                })
            } else {
                toastError("Service type already exits")
                setTimeout(() => {
                    this.setState({ serviceTypeId: '' })
                }, 500);
            }
        }
    }

    deleteServiceType(id) {
        const { renderServiceTypeList } = this.state

        console.log("renderServiceTypeList", renderServiceTypeList)
        console.log(" id", id)

        const array = renderServiceTypeList.filter(item => item.ServiceID != id)

        this.setState({ renderServiceTypeList: array })

        toastSuccess(" Service deleted successfully")
    }

    detailsVendorRegistration() {
        const id = this.props.location.state.UserId
        this.setState({ isLoading: true })
        this.props.getDetailsVendorRegistration_action(id)
            .then((data) => {
                if (data.error != 1) {

                    this.setState({
                        isLoading: false,
                        Billing: data.data['data'][0]['IsItemBilling'],
                        Approval_Rights: data.data['data'][0]['IsApprovalRights'],
                        companyName: data.data['data'][0]['CompanyName'],
                        password: data.data['data'][0]['Password'],
                        countryName: data.data['data'][0]['CountryID'],
                        stateName: data.data['data'][0]['State'],
                        cityName: data.data['data'][0]['City'],
                        pinCode: data.data['data'][0]['Pin'],
                        address1: data.data['data'][0]['Address1'],
                        address2: data.data['data'][0]['Address2'],
                        title_inchargeFirstName: data.data['data'][0]['Title_PersonIncharge'],
                        inchargeFirstName: data.data['data'][0]['FirstName'],
                        lastNamePersonIncharge: data.data['data'][0]['LastNamePersonIncharge'],
                        designation: data.data['data'][0]['Designation'][0],
                        registerTelefaxNo: data.data['data'][0]['TeleFax'],
                        primaryEmailId: data.data['data'][0]['PrimaryEmail'],
                        secondaryEmailId: data.data['data'][0]['SecondaryEmail'],
                        website: data.data['data'][0]['Website'],
                        title_name_Of_CEO_MD_President_Owner: data.data['data'][0]['Title_Name_Of_CEO_MD_President_Owner'],
                        nameOfOwner: data.data['data'][0]['Name_Of_CEO_MD_President_Owner'],
                        lastName: data.data['data'][0]['LastName'][0],
                        ownerMobileNo: data.data['data'][0]['Mobile'],
                        ownerEmailId: data.data['data'][0]['Email'],
                        compInfoNameOnPanCard: data.data['data'][0]['NameOnPanCard'],
                        compInfoPanNo: data.data['data'][0]['PanNo'],
                        compInfoTAN: data.data['data'][0]['Tan'],
                        compInfoGstNo: data.data['data'][0]['GSTNo'],
                        compInfoRegistrationNo: data.data['data'][0]['CompanyRegistrationNo'],
                        compInfoIecCode: data.data['data'][0]['IECCode'],
                        bankDtlAccountName: data.data['data'][0]['AccountName'],
                        bankDtlAccountNo: data.data['data'][0]['AccountNo'],
                        bankDtlBankName: data.data['data'][0]['BankName'],
                        bankDtlBranchName: data.data['data'][0]['BranchName'],
                        bankDtlIfscCode: data.data['data'][0]['SwiftCode_IFSCCode'],

                    })

                    const MobileNo = data.data['data'][0]['MobileNo'] != null && data.data['data'][0]['MobileNo'][0] != null ? data.data['data'][0]['MobileNo'][0].split('-') : null
                    const AlternateMobileNo = data.data['data'][0]['AlternateMobileNo'] != null && data.data['data'][0]['AlternateMobileNo'][0] != null ? data.data['data'][0]['AlternateMobileNo'][0].split('-') : null
                    const Telephone = data.data['data'][0]['Telephone'] != null && data.data['data'][0]['Telephone'][0] != null ? data.data['data'][0]['Telephone'][0].split('-') : null
                    const Telephone1 = data.data['data'][0]['Telephone1'] != null ? data.data['data'][0]['Telephone1'].split('-') : null
                    const Mobile = data.data['data'][0]['Mobile'] != null ? data.data['data'][0]['Mobile'].split('-') : null

                    this.setState({
                        registerMobileNo: MobileNo != null ? MobileNo[1] : null,
                        registerAlternateMobileNo: AlternateMobileNo != null ? AlternateMobileNo[1] : null,
                        registerTelephoneNo: Telephone != null ? Telephone[1] : null,
                        registerTelephoneNo1: Telephone1 != null ? Telephone1[1] : null,
                        ownerMobileNo: Mobile != null ? Mobile[1] : null,
                        preMobile: Mobile != null ? Mobile[0] : null,
                        PreMobileNo: MobileNo != null ? MobileNo[0] : null,
                        PreAlternateMobileNo: AlternateMobileNo != null ? AlternateMobileNo[0] : null,
                        PreTelephoneNo: Telephone != null ? Telephone[0] : null,
                        PreTelephoneNo1: Telephone1 != null ? Telephone1[0] : null,
                    })

                    const checkService = data.data['data'][0]['AccountType']

                    if (checkService == 'Savingaccount' || checkService == 'Currentaccount' || checkService == 'Overdraftaccount') {
                        this.setState({
                            bankDtlAccountType: data.data['data'][0]['AccountType'],
                        })
                    } else {
                        this.setState({
                            bankDtlAccountType: 'Others',
                            bankDtlAccountTypeOthers: data.data['data'][0]['AccountType'],
                        })
                    }

                    console.log("data.data['data'][0]['MobileNo'][0]", data.data['data'][0]['MobileNo'][1])

                    const array = []
                    const { serviceTypeList } = this.state
                    serviceTypeList.map((item) => {
                        data.data['serviceTypeData'].map((obj) => {
                            if (item.ServiceID == obj.ServiceId) {
                                array.push(item)
                            }
                        })
                    })

                    this.setState({ renderServiceTypeList: array })

                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })

    }

    handleClear() {
        this.setState({
            renderServiceTypeList: '',
            Billing: '',
            Approval_Rights: '',
            companyName: '',
            password: '',
            countryName: '',
            stateName: '',
            cityName: '',
            pinCode: '',
            address1: '',
            address2: '',
            title_inchargeFirstName: '',
            inchargeFirstName: '',
            lastNamePersonIncharge: '',
            designation: '',
            registerMobileNo: '',
            registerAlternateMobileNo: '',
            registerTelephoneNo: '',
            registerTelephoneNo1: '',
            registerTelefaxNo: '',
            primaryEmailId: '',
            secondaryEmailId: '',
            website: '',
            title_name_Of_CEO_MD_President_Owner: '',
            nameOfOwner: '',
            lastName: '',
            ownerMobileNo: '',
            ownerEmailId: '',
            compInfoNameOnPanCard: '',
            compInfoPanNo: '',
            compInfoTAN: '',
            compInfoGstNo: '',
            compInfoRegistrationNo: '',
            compInfoIecCode: '',
            bankDtlAccountName: '',
            bankDtlAccountNo: '',
            bankDtlAccountType: '',
            bankDtlAccountTypeOthers: '',
            bankDtlBankName: '',
            bankDtlBranchName: '',
            bankDtlIfscCode: '',
            PreMobileNo: '',
            PreAlternateMobileNo: '',
            PreTelephoneNo: '',
            PreTelephoneNo1: '',
            preMobile: ''
        })
    }

    addVendor() {
        const { renderServiceTypeList, Billing, Approval_Rights, companyName, password, countryName, stateName, cityName, pinCode, address1, address2, title_inchargeFirstName, inchargeFirstName, lastNamePersonIncharge, designation, registerMobileNo, registerAlternateMobileNo, registerTelephoneNo, registerTelephoneNo1, registerTelefaxNo, primaryEmailId, secondaryEmailId, website, title_name_Of_CEO_MD_President_Owner, nameOfOwner, lastName, ownerMobileNo, ownerEmailId, compInfoNameOnPanCard, compInfoPanNo, compInfoTAN, compInfoGstNo, compInfoRegistrationNo, compInfoIecCode, bankDtlAccountName, bankDtlAccountNo, bankDtlAccountType, bankDtlAccountTypeOthers, bankDtlBankName, bankDtlBranchName, bankDtlIfscCode, PreMobileNo, PreAlternateMobileNo, PreTelephoneNo, PreTelephoneNo1, preMobile } = this.state
        this.setState({ isLoading: true })
        this.inputValidate()

        const RoleID = 12

        if (
            renderServiceTypeList != '' && companyName != '' && countryName != '' && countryName != 'Select' && stateName != '' && cityName != '' && pinCode != '' && address1 != '' && inchargeFirstName != '' && lastNamePersonIncharge != '' && designation != '' && registerMobileNo != '' && primaryEmailId != '' && nameOfOwner != '' && ownerEmailId != '' &&
            renderServiceTypeList != null && companyName != null && countryName != null && stateName != null && cityName != null && pinCode != null && address1 != null && inchargeFirstName != null && lastNamePersonIncharge != null && designation != null && registerMobileNo != null && primaryEmailId != null && nameOfOwner != null && ownerEmailId != null
        ) {

            var errMsg1 = emailValidator(primaryEmailId)
            var errMsg2 = emailValidator(ownerEmailId)
            var panErrMsg = panNoValidator(compInfoPanNo)
            var gstErrMsg = gstNoValidator(compInfoGstNo)
            var tanErrMsg = tanNoValidator(compInfoTAN)
            var pincodeErrMsg = pincodeValidator(pinCode)
            var mobileErrMsg = contactNoValidator(registerMobileNo)

            if (errMsg1 != '' || errMsg2 != '' || mobileErrMsg != '') {

                toast.error(
                    errMsg1 != '' ? errMsg1 :
                        errMsg2 != '' ? errMsg2 :
                            mobileErrMsg != '' ? mobileErrMsg :
                                '',
                    {
                        theme: 'colored',
                        autoClose: 1000,
                        hideProgressBar: true,
                    })
                this.setState({ isLoading: false })

            } else if (panErrMsg != '' || gstErrMsg != '' || tanErrMsg != '') {
                toast.error(
                    panErrMsg != '' ? panErrMsg :
                        tanErrMsg != '' ? tanErrMsg :
                            gstErrMsg != '' ? gstErrMsg :
                                '',
                    {
                        theme: 'colored',
                        autoClose: 1000,
                        hideProgressBar: true,
                    })
                this.setState({ isLoading: false })
            } else {
                this.props.addVendorRegistration_action(
                    primaryEmailId, title_inchargeFirstName, inchargeFirstName, lastNamePersonIncharge, registerMobileNo, registerAlternateMobileNo, registerTelephoneNo, registerTelephoneNo1, companyName, address1, address2, cityName, stateName, pinCode, countryName, secondaryEmailId, website, registerTelefaxNo, designation, title_name_Of_CEO_MD_President_Owner, nameOfOwner, lastName, compInfoNameOnPanCard, compInfoPanNo, compInfoTAN, compInfoGstNo, compInfoRegistrationNo, compInfoIecCode, bankDtlAccountName, bankDtlAccountNo,
                    (bankDtlAccountType == 'Others' ? bankDtlAccountTypeOthers : bankDtlAccountType),
                    bankDtlBankName, bankDtlBranchName, bankDtlIfscCode, PreMobileNo, PreAlternateMobileNo, PreTelephoneNo, PreTelephoneNo1, renderServiceTypeList, Billing, Approval_Rights, ownerEmailId, ownerMobileNo, preMobile
                )
                    .then((data) => {
                        if (data.error != 1) {
                            this.setState({ isLoading: false })
                            this.handleClear()
                            this.cancle()
                            toastSuccess("Vendor added successfully!")
                        } else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        } else {
            this.setState({ isLoading: false })
            toastError("please enter mendatory fields!")
        }

    }

    updateVendor() {
        const { renderServiceTypeList, Billing, Approval_Rights, companyName, password, countryName, stateName, cityName, pinCode, address1, address2, title_inchargeFirstName, inchargeFirstName, lastNamePersonIncharge, designation, registerMobileNo, registerAlternateMobileNo, registerTelephoneNo, registerTelephoneNo1, registerTelefaxNo, primaryEmailId, secondaryEmailId, website, title_name_Of_CEO_MD_President_Owner, nameOfOwner, lastName, ownerMobileNo, ownerEmailId, compInfoNameOnPanCard, compInfoPanNo, compInfoTAN, compInfoGstNo, compInfoRegistrationNo, compInfoIecCode, bankDtlAccountName, bankDtlAccountNo, bankDtlAccountType, bankDtlAccountTypeOthers, bankDtlBankName, bankDtlBranchName, bankDtlIfscCode, PreMobileNo, PreAlternateMobileNo, PreTelephoneNo, PreTelephoneNo1, preMobile } = this.state
        this.setState({ isLoading: true })
        this.inputValidate()

        const RoleID = 12

        const id = this.props.location.state.AccountID

        if (
            renderServiceTypeList != '' && companyName != '' && countryName != '' && countryName != 'Select' && stateName != '' && cityName != '' && pinCode != '' && address1 != '' && inchargeFirstName != '' && lastNamePersonIncharge != '' && designation != '' && registerMobileNo != '' && primaryEmailId != '' && nameOfOwner != '' && ownerEmailId != '' &&
            renderServiceTypeList != null && companyName != null && countryName != null && stateName != null && cityName != null && pinCode != null && address1 != null && inchargeFirstName != null && lastNamePersonIncharge != null && designation != null && registerMobileNo != null && primaryEmailId != null && nameOfOwner != null && ownerEmailId != null
        ) {

            var errMsg1 = emailValidator(primaryEmailId)
            var errMsg2 = emailValidator(ownerEmailId)
            var panErrMsg = panNoValidator(compInfoPanNo)
            var gstErrMsg = gstNoValidator(compInfoGstNo)
            var tanErrMsg = tanNoValidator(compInfoTAN)
            var pincodeErrMsg = pincodeValidator(pinCode)
            var mobileErrMsg = contactNoValidator(registerMobileNo)

            if (errMsg1 != '' || errMsg2 != '' || mobileErrMsg != '') {

                toast.error(
                    errMsg1 != '' ? errMsg1 :
                        errMsg2 != '' ? errMsg2 :
                            mobileErrMsg != '' ? mobileErrMsg :
                                '',
                    {
                        theme: 'colored',
                        autoClose: 1000,
                        hideProgressBar: true,
                    })
                this.setState({ isLoading: false })

            } else if ((compInfoPanNo != '' && compInfoPanNo != null) || (compInfoTAN != '' && compInfoTAN != null) || (compInfoGstNo != '' && compInfoGstNo != null)) {
                if (panErrMsg != '' || gstErrMsg != '' || tanErrMsg != '') {
                    toast.error(
                        panErrMsg != '' ? panErrMsg :
                            tanErrMsg != '' ? tanErrMsg :
                                gstErrMsg != '' ? gstErrMsg :
                                    '',
                        {
                            theme: 'colored',
                            autoClose: 1000,
                            hideProgressBar: true,
                        })
                    this.setState({ isLoading: false })
                }
            } else {
                this.props.updateVendorRegistration_action(
                    primaryEmailId, title_inchargeFirstName, inchargeFirstName, lastNamePersonIncharge, registerMobileNo, registerAlternateMobileNo, registerTelephoneNo, registerTelephoneNo1, companyName, address1, address2, cityName, stateName, pinCode, countryName, secondaryEmailId, website, registerTelefaxNo, designation, title_name_Of_CEO_MD_President_Owner, nameOfOwner, lastName, compInfoNameOnPanCard, compInfoPanNo, compInfoTAN, compInfoGstNo, compInfoRegistrationNo, compInfoIecCode, bankDtlAccountName, bankDtlAccountNo,
                    (bankDtlAccountType == 'Others' ? bankDtlAccountTypeOthers : bankDtlAccountType),
                    bankDtlBankName, bankDtlBranchName, bankDtlIfscCode, PreMobileNo, PreAlternateMobileNo, PreTelephoneNo, PreTelephoneNo1, id, renderServiceTypeList, Billing, Approval_Rights, ownerEmailId, ownerMobileNo, preMobile
                )
                    .then((data) => {
                        if (data.error != 1) {
                            this.setState({ isLoading: false })
                            this.handleClear()
                            this.cancle()
                            toastSuccess("Vendor added successfully!")
                        } else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        } else {
            this.setState({ isLoading: false })
            toastError("please enter mendatory fields!")
        }

    }

    cancle() {
        this.props.navigate('/VendorRegistrationList')
    }

    render() {
        const {
            isLoading, countryName, registerMobileNo,
            registerMobileNoErr, serviceTypeErr,
            companyName, address1,
            address2, cityName, stateName,
            pinCode, inchargeFirstName, designation,
            registerAlternateMobileNo, registerTelephoneNo, registerTelephoneNo1,
            registerTelefaxNo, primaryEmailId,
            secondaryEmailId, website, nameOfOwner,
            lastName,
            ownerMobileNo, ownerEmailId, title_inchargeFirstName, title_name_Of_CEO_MD_President_Owner, companyNameErr, address1Err, cityNameErr, stateNameErr, pinCodeErr, countryNameErr, inchargeFirstNameErr, designationErr, primaryEmailIdErr, nameOfOwnerErr, ownerEmailIdErr,
            compInfoNameOnPanCard, compInfoPanNo, compInfoTAN, compInfoGstNo, compInfoRegistrationNo, compInfoIecCode,
            bankDtlAccountName, bankDtlAccountNo, bankDtlAccountType, bankDtlBankName, bankDtlBranchName, bankDtlIfscCode,
            compInfoNameOnPanCardErr, compInfoPanNoErr, compInfoTANErr, compInfoGstNoErr, compInfoRegistrationNoErr,
            bankDtlAccountNameErr, bankDtlAccountNoErr, bankDtlIfscCodeErr, isCountryIndia, isDisplayCityList, isDisplayStateList, isDisplayPincodeList, lastNamePersonIncharge, lastNamePersonInchargeErr, bankDtlAccountTypeOthers, add, countryList, exhibitionList, exhibitionId, bankDtlAccountTypeOthersErr,

            serviceTypeId, serviceTypeList, renderServiceTypeList, password, passwordErr, Billing, Approval_Rights, countryCode, bankDtlAccountTypeErr, exhibitionErr,

            PreMobileNo, PreAlternateMobileNo, PreTelephoneNo, PreTelephoneNo1, preMobile

        } = this.state

        const toInputUppercase = e => {
            e.target.value = ("" + e.target.value).toUpperCase();
        };

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                {
                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                        <ExhibitorHeader /> : <HeaderWithLogo />
                }
                <div className={JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ? "with-dr-container" : "with-dr-container com-height"}>
                    <>
                        {/* PAGE */}
                        <div className="page">
                            {
                                JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                                    <ExhibitorDrawer /> : <AdminDrawer />
                            }
                            {
                                JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                                    <ExhibitorTopMenu /> : null
                            }
                            <div className="page-main">
                                <div class="row">
                                    <div class="step-name">
                                        <p>{add ? 'Add' : 'Update'} Vendor Registration</p>
                                    </div>
                                </div>
                                {/*app-content open*/}
                                <div className="main-content app-content mt-0">
                                    <div className="side-app">
                                        {/* <div className="main-container form-start container-fluid p-0">
                                        {is_update && <>
                                            <div className="row ">
                                                <div className="step-name">
                                                    <p>Exhibitor Registration</p>
                                                </div>
                                            </div>
                                            <StepHeader />
                                        </>}
                                    </div> */}
                                        {/* <StepHeader /> */}
                                        {/* <div className="page-height"> */}
                                        <div className="main-container p-2">
                                            <div className="row p-1">

                                                <div className="form-row mb-1 d-flex" style={{ flexDirection: 'column' }}>
                                                    <div className="col-xl-3 pl-0">
                                                        <label htmlFor="validationCustom03">
                                                            Service Type<span className="text-red">*</span>
                                                        </label>
                                                        <select
                                                            className="form-control"
                                                            // style={{ textAlign: "left" }}
                                                            value={serviceTypeId}
                                                            onChange={(e) => this.onServiceType(e)}
                                                        >
                                                            <option>Select</option>
                                                            {serviceTypeList.length > 0 && serviceTypeList.map((data) => (
                                                                <option key={data.ServiceID
                                                                } value={data.ServiceID
                                                                }>{data.Name}</option>
                                                            ))}
                                                        </select>
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{serviceTypeErr}</span>}
                                                    </div>
                                                    <div class="table-responsive mb-2 p-2 w-50">
                                                        <table class="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-3">
                                                            <thead>
                                                                <tr>
                                                                    {/* text-center */}
                                                                    <th style={{ width: "10px" }}></th>
                                                                    <th>Service Name</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    renderServiceTypeList.length > 0 ?
                                                                        renderServiceTypeList.map((data) => (
                                                                            <tr>
                                                                                <td >
                                                                                    <i className='fa fa-trash' onClick={() => this.deleteServiceType(data.ServiceID)} style={{ cursor: 'pointer' }}></i>
                                                                                </td>
                                                                                <td>{data.Name}</td>
                                                                            </tr>
                                                                        )) : (<tr><td colSpan={3} className='text-center'>Record not found</td></tr>)
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="form-row mb-1">
                                                    {/* <div className="col-md-3">
                                                        <label>Exhibition</label>
                                                        <select
                                                            className="form-control"
                                                            id="roledb"
                                                            // style={{ textAlign: "left" }}
                                                            value={exhibitionId}
                                                            onChange={(e) => this.setState({ exhibitionId: e.target.value, exhibitionErr: '' })}
                                                        >
                                                            <option>Select</option>
                                                            {exhibitionList.length > 0 && exhibitionList.map((data) => (
                                                                <option key={data.ExhibitionID
                                                                } value={data.ExhibitionID
                                                                }>{data.ExhibitionName}</option>
                                                            ))}
                                                        </select>
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{exhibitionErr}</span>}
                                                    </div> */}
                                                    <div className="col-xl-2 mb-1 w-100" style={{ alignItems: 'right', justifyContent: 'right' }}>
                                                        <label htmlFor="validationCustom03">
                                                            Billing<span className="text-red">*</span>
                                                        </label>
                                                        <div className='d-flex w-100 mt-2'>
                                                            <div className='radio-btn-space'>
                                                                <label className='d-flex'><input type="radio"
                                                                    name="Billing"
                                                                    checked={Billing == 1}
                                                                    onChange={(e) => this.setState({ Billing: 1 })}
                                                                />Itme </label>
                                                            </div>
                                                            <div>
                                                                <label className='d-flex'><input type="radio"
                                                                    checked={Billing == 0}
                                                                    onChange={(e) => this.setState({ Billing: 0 })}
                                                                    name="Billing" />Direct</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-2 mb-1 w-100" style={{ alignItems: 'right', justifyContent: 'right' }}>
                                                        <label htmlFor="validationCustom03">
                                                            Approval Rights<span className="text-red">*</span>
                                                        </label>
                                                        <div className='d-flex w-100 mt-2'>
                                                            <div className='radio-btn-space'>
                                                                <label className='d-flex'><input
                                                                    type="radio"
                                                                    checked={Approval_Rights == 1}
                                                                    name="Approval_Rights"
                                                                    onChange={(e) => this.setState({ Approval_Rights: 1 })}
                                                                />Yes </label>
                                                            </div>
                                                            <div>
                                                                <label className='d-flex'><input
                                                                    type="radio"
                                                                    checked={Approval_Rights == 0}
                                                                    name="Approval_Rights"
                                                                    onChange={(e) => this.setState({ Approval_Rights: 0 })}
                                                                />No</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-xl-6 mb-1">
                                                        <label htmlFor="validationCustom03">
                                                            Company Name<span className="text-red">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            required=""
                                                            value={companyName}
                                                            maxLength={150}
                                                            onChange={e => this.setState({
                                                                companyName: e.target.value.split(' ')
                                                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                                    .join(' '), companyNameErr: ''
                                                            })} />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{companyNameErr}</span>}

                                                    </div>
                                                    {/* <div className="col-xl-3 mb-1">
                                                        <label htmlFor="validationCustom01">
                                                            Password <span className="text-red">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            maxLength={200}
                                                            required=""
                                                            value={password}
                                                            onChange={e => this.setState({ password: e.target.value, address1Err: '' })} />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{passwordErr}</span>}

                                                    </div> */}
                                                </div>


                                                <div className="form-row">
                                                    <div className="col-xl-3 mb-1">
                                                        <label htmlFor="validationCustom01">
                                                            Country<span className="text-red">*</span>
                                                        </label>
                                                        <select
                                                            className="form-control"
                                                            // style={{ textAlign: "left" }}
                                                            value={countryName}
                                                            onChange={(e) => this.onCountry(e)}
                                                        >
                                                            <option>Select</option>
                                                            {countryList.length > 0 && countryList.map((data) => (
                                                                <option key={data.Code
                                                                } value={data.Code
                                                                }>{data.Description}</option>
                                                            ))}
                                                        </select>
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{countryNameErr}</span>}

                                                    </div>
                                                    <div className="col-xl-3 mb-1">
                                                        <label htmlFor="validationCustom01">
                                                            State/Province{countryName == '' || countryName == 'Select' || countryName == 91 ? <span className="text-red">*</span> : <></>}
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            maxLength={30}
                                                            required=""
                                                            value={stateName}

                                                            onChange={e => this.onStateSearch(e)}
                                                        />
                                                        {isDisplayStateList && this.renderStateList()}
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{stateNameErr}</span>}

                                                    </div>
                                                    <div className="col-xl-3 mb-1">
                                                        <label htmlFor="validationCustom01">
                                                            City<span className="text-red">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            maxLength={30}
                                                            required=""
                                                            value={cityName}


                                                            onChange={e => this.onCitySearch(e)}
                                                        />
                                                        {isDisplayCityList && this.renderCityList()}
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{cityNameErr}</span>}

                                                    </div>
                                                    <div className="col-xl-3 mb-1">
                                                        {console.log("countryName", countryName)}
                                                        <label htmlFor="validationCustom01">
                                                            {countryName == 91 || countryName == '' || countryName == 'Select' ? 'Pincode' : 'Zipcode'}<span className="text-red">*</span>
                                                        </label>
                                                        {
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                maxLength={10}
                                                                // onKeyPress={this.onlyNumbers}
                                                                required=""
                                                                value={pinCode}
                                                                onChange={e => this.onPincodeSearch(e)}
                                                                onInput={toInputUppercase}
                                                            // onChange={this.changeUrl}
                                                            />
                                                        }
                                                        {isDisplayPincodeList && this.renderPincodeList()}
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{pinCodeErr}</span>}

                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="col-xl-6 mb-1">
                                                        <label htmlFor="validationCustom01">
                                                            Address Line 1 <span className="text-red">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            maxLength={200}
                                                            required=""
                                                            value={address1}
                                                            onChange={e => this.setState({
                                                                address1: e.target.value.split(' ')
                                                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                                    .join(' '), address1Err: ''
                                                            })} />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{address1Err}</span>}

                                                    </div>
                                                    <div className="col-xl-6 mb-1">
                                                        <label htmlFor="validationCustom01">
                                                            Address Line 2{" "}
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            maxLength={200}
                                                            value={address2}
                                                            onChange={e => this.setState({
                                                                address2: e.target.value.split(' ')
                                                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                                    .join(' ')
                                                            })} />
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="col-xl-3">
                                                        <label>
                                                            First Name of Person Incharge<span className="text-red">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            maxLength={100}
                                                            required=""
                                                            defaultValue=""
                                                            style={{ position: "relative", paddingLeft: '55px' }}
                                                            value={inchargeFirstName}
                                                            onChange={e => this.setState({
                                                                inchargeFirstName: e.target.value.replace(/[^a-zA-Z ]/g, '').split(' ')
                                                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                                    .join(' '), inchargeFirstNameErr: ''
                                                            })}
                                                        />
                                                        <div className="col-xl-1">
                                                            <div
                                                                className="form-group"

                                                                style={{
                                                                    width: '45px',
                                                                    position: "absolute",
                                                                    top: "-56.5px",
                                                                    left: '0px'
                                                                }}
                                                            >
                                                                <label />
                                                                <select
                                                                    className="form-control-1 select2 select-hidden-accessible"
                                                                    id="roledb"
                                                                    style={{
                                                                        paddingLeft: 0,
                                                                        paddingRight: 0,
                                                                        borderTop: "none",
                                                                        borderBottom: "none",
                                                                        marginTop: 0,
                                                                        textAlign: "left"
                                                                    }}
                                                                    value={title_inchargeFirstName}
                                                                    onChange={e => this.setState({
                                                                        title_inchargeFirstName: e.target.value,
                                                                        isOther_title_inchargeFirstName: e.target.value == "Other" && true
                                                                    })}

                                                                >
                                                                    <option value="Mr">Mr</option>
                                                                    <option value="Ms">Ms</option>
                                                                    <option value="DR">DR</option>
                                                                    <option value="Prof">Prof</option>
                                                                    <option value="Other">Other</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{inchargeFirstNameErr}</span>}

                                                    </div>
                                                    <div className="col-xl-3">
                                                        <label>
                                                            Last Name of Person Incharge<span className="text-red">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            maxLength={100}
                                                            required=""
                                                            defaultValue=""
                                                            value={lastNamePersonIncharge}
                                                            onChange={e => this.setState({
                                                                lastNamePersonIncharge: e.target.value.replace(/[^a-zA-Z ]/g, '').split(' ')
                                                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                                    .join(' '), lastNamePersonInchargeErr: ''
                                                            })}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{lastNamePersonInchargeErr}</span>}

                                                    </div>
                                                    <div className="col-xl-3 mb-1">
                                                        <label htmlFor="validationCustom01">
                                                            Designation <span className="text-red">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            maxLength={100}
                                                            className="form-control"
                                                            value={designation}
                                                            onChange={e => this.setState({
                                                                designation: e.target.value.split(' ')
                                                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                                    .join(' '), designationErr: ''
                                                            })} />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{designationErr}</span>}

                                                    </div>
                                                    <div
                                                        className="col-xl-3 mb-1"
                                                        style={{ position: "relative" }}
                                                    >
                                                        <label htmlFor="validationCustom01">
                                                            Mobile No<span className="text-red">*</span>
                                                        </label>
                                                        <input
                                                            type="text"

                                                            className="form-control"
                                                            value={registerMobileNo}
                                                            maxLength={countryName == 91 || countryName == '' || countryName == 'Select' ? 10 : 11}
                                                            onChange={e => this.setState({ registerMobileNo: e.target.value.replace(/\D/, ''), registerMobileNoErr: '' })}
                                                            style={{ paddingLeft: '50px' }} />
                                                        <input
                                                            type="text"
                                                            placeholder="ISD"
                                                            className="form-control-1"
                                                            value={PreMobileNo}
                                                            disabled={true}
                                                            maxLength={5}
                                                            onChange={e => this.setState({ PreMobileNo: e.target.value })}
                                                            style={{
                                                                width: '45px',
                                                                position: "absolute",
                                                                top: '22.5px',
                                                                paddingLeft: '5px'
                                                            }}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{registerMobileNoErr}</span>}

                                                    </div>

                                                </div>
                                                <div className="form-row">
                                                    <div className="col-xl-3 mb-1">
                                                        <label htmlFor="validationCustom01">
                                                            Alternate Mobile No{" "}
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={registerAlternateMobileNo}
                                                            maxLength={countryName == 91 || countryName == '' || countryName == 'Select' ? 10 : 11}
                                                            onChange={e => this.setState({ registerAlternateMobileNo: e.target.value.replace(/\D/, '') })}
                                                            style={{ paddingLeft: '50px' }}
                                                        />
                                                        <input
                                                            type="text"
                                                            className="form-control-1"
                                                            placeholder="ISD"
                                                            value={PreAlternateMobileNo}
                                                            onChange={e => this.setState({ PreAlternateMobileNo: e.target.value })}
                                                            maxLength={5}
                                                            disabled={true}

                                                            // placeholder={+91}
                                                            style={{
                                                                width: '45px',
                                                                position: "absolute",
                                                                top: '22.5px',
                                                                paddingLeft: '5px'
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-xl-3 mb-1">
                                                        <label htmlFor="validationCustom01">Telephone No</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={registerTelephoneNo}
                                                            maxLength={15}
                                                            onChange={e => this.setState({ registerTelephoneNo: e.target.value.replace(/\D/, '') })}
                                                            style={{ paddingLeft: '55px' }}
                                                        />
                                                        <input
                                                            type="text"
                                                            className="form-control-1"
                                                            placeholder="STD"
                                                            value={PreTelephoneNo}
                                                            maxLength={5}
                                                            onChange={e => this.setState({ PreTelephoneNo: e.target.value })}
                                                            style={{
                                                                width: '50px',
                                                                position: "absolute",
                                                                top: '23px',
                                                                padding: "6px !important",
                                                                paddingLeft: '5px'
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-xl-3 mb-1">
                                                        <label htmlFor="validationCustom01">Telephone No 1</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={registerTelephoneNo1}
                                                            maxLength={15}
                                                            onChange={e => this.setState({ registerTelephoneNo1: e.target.value.replace(/\D/, '') })}
                                                            style={{ paddingLeft: '55px' }}
                                                        />
                                                        <input
                                                            type="text"
                                                            className="form-control-1"
                                                            placeholder="STD"
                                                            value={PreTelephoneNo1}
                                                            maxLength={5}
                                                            onChange={e => this.setState({ PreTelephoneNo1: e.target.value })}
                                                            style={{
                                                                width: 50,
                                                                position: "absolute",
                                                                top: 23,
                                                                paddingLeft: 5,
                                                                padding: "6px !important",
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-xl-3 mb-1">
                                                        <label htmlFor="validationCustom01">Telefax</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={registerTelefaxNo} maxLength={50}
                                                            onChange={e => this.setState({ registerTelefaxNo: e.target.value.replace(/\D/, '') })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-xl-3 mb-1">
                                                        <label htmlFor="validationCustom01">
                                                            Primary Email <span className="text-red">*</span>
                                                        </label>
                                                        <input
                                                            type="email"
                                                            maxLength={100}
                                                            className="form-control"
                                                            value={primaryEmailId}
                                                            onChange={e => this.setState({ primaryEmailId: e.target.value, primaryEmailIdErr: '' })}
                                                        // onBlur={e => primaryEmailId != '' && this.emailFocusOut('fromprimaryemail')}

                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{primaryEmailIdErr}</span>}

                                                    </div>
                                                    <div className="col-xl-3 mb-1">
                                                        <label htmlFor="validationCustom01">
                                                            Secondary Email{" "}
                                                        </label>
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            maxLength={100}
                                                            value={secondaryEmailId}
                                                            onChange={e => this.setState({ secondaryEmailId: e.target.value })}
                                                        // onBlur={e => secondaryEmailId != '' && this.emailFocusOut('fromsecondaryemail')}
                                                        />
                                                    </div>
                                                    <div className="col-xl-6 mb-1">
                                                        <label htmlFor="validationCustom01">Website</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            maxLength={100}
                                                            value={website}
                                                            onChange={e => this.setState({ website: e.target.value })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-xl-3">
                                                        <label>
                                                            First Name of CEO/MD/President/Owner
                                                            <span className="text-red">*</span>
                                                        </label>

                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            maxLength={100}
                                                            style={{ position: "relative", paddingLeft: '55px' }}
                                                            onChange={e => this.setState({
                                                                nameOfOwner: e.target.value.replace(/[^a-zA-Z]/g, '').split(' ')
                                                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                                    .join(' '), nameOfOwnerErr: ""
                                                            })}
                                                            value={nameOfOwner}
                                                        />
                                                        <div className="col-xl-1">
                                                            <div
                                                                className="form-group"

                                                                style={{
                                                                    width: '45px',
                                                                    position: "absolute",
                                                                    top: "-54.5px",
                                                                    left: '0px'
                                                                }}
                                                            >
                                                                <label />
                                                                <select
                                                                    className="form-control-1 select2 select-hidden-accessible"
                                                                    id="roledb"
                                                                    style={{
                                                                        paddingLeft: '0px',
                                                                        paddingRight: '0px',
                                                                        borderTop: "none",
                                                                        borderBottom: "none",
                                                                        marginTop: '-2px',
                                                                        textAlign: "left"
                                                                    }}
                                                                    value={title_name_Of_CEO_MD_President_Owner}
                                                                    onChange={e => this.setState({
                                                                        title_name_Of_CEO_MD_President_Owner: e.target.value,
                                                                        isOther_title_name_Of_CEO_MD_President_Owner: e.target.value == "Other" && true
                                                                    })}

                                                                >
                                                                    <option value="Mr">Mr</option>
                                                                    <option value="Ms">Ms</option>
                                                                    <option value="DR">DR</option>
                                                                    <option value="Prof">Prof</option>
                                                                    <option value="Other">Other</option>

                                                                </select>
                                                            </div>
                                                        </div>
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{nameOfOwnerErr}</span>}

                                                    </div>
                                                    <div className="col-xl-3 mb-1">
                                                        <label htmlFor="validationCustom01">Last Name of CEO/MD/President/Owner</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={lastName}
                                                            maxLength={100}
                                                            onChange={e => this.setState({
                                                                lastName: e.target.value.replace(/[^a-zA-Z]/g, '').split(' ')
                                                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                                    .join(' ')
                                                            })}
                                                        />
                                                    </div>
                                                    <div className="col-xl-3 mb-1">
                                                        <label htmlFor="validationCustom01">Mobile No of CEO/MD/President/Owner</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={ownerMobileNo}
                                                            maxLength={countryName == 91 || countryName == '' || countryName == 'Select' ? 10 : 11}
                                                            onChange={e => this.setState({
                                                                ownerMobileNo: e.target.value.replace(/\D/, '')
                                                            })}
                                                            style={{ paddingLeft: '50px' }}
                                                        />
                                                        <input
                                                            type="text"
                                                            placeholder="STD"
                                                            className="form-control-1"
                                                            value={preMobile}
                                                            maxLength={5}
                                                            onChange={e => this.setState({ preMobile: e.target.value })}
                                                            // placeholder={+91}
                                                            disabled={true}
                                                            style={{
                                                                width: '45px',
                                                                position: "absolute",
                                                                top: '22px',
                                                                left: '5px',
                                                                paddingLeft: '0px'
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-xl-3 mb-1">
                                                        <label htmlFor="validationCustom01">
                                                            Email of CEO/MD/President/Owner<span className="text-red">*</span>
                                                        </label>
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            maxLength={100}
                                                            value={ownerEmailId}
                                                            onChange={e => this.setState({ ownerEmailId: e.target.value, ownerEmailIdErr: '' })}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{ownerEmailIdErr}</span>}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* COMPANY INFORMATION Start Here */}
                                        {/* {
                                            countryName == 91 || countryName == '' || countryName == 'Select' ? */}
                                        <div className="main-container p-2">
                                            <h6> <strong>Company Information</strong></h6>
                                            <div className="form-row ">
                                                <div className="col-xl-3 mb-1">
                                                    <label htmlFor="validationCustom01">Name On Pan Card</label>
                                                    <input
                                                        type="text"
                                                        maxLength={100}
                                                        className="form-control"
                                                        value={compInfoNameOnPanCard}
                                                        onChange={e => this.setState({
                                                            compInfoNameOnPanCard: e.target.value.replace(/[^a-zA-Z ]/g, '').split(' ')
                                                                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                                .join(' '), compInfoNameOnPanCardErr: ''
                                                        })}
                                                    />
                                                    <span className='text-danger text-start text-left d-block mt-1'>{compInfoNameOnPanCardErr}</span>

                                                </div>
                                                <div className="col-xl-3 mb-1">
                                                    <label htmlFor="validationCustom01"> PAN No </label>
                                                    <input
                                                        type="text"
                                                        maxLength={10}
                                                        className="form-control"
                                                        value={compInfoPanNo}
                                                        onChange={e => this.setState({ compInfoPanNo: e.target.value, compInfoPanNoErr: '' })}
                                                        onInput={toInputUppercase}
                                                    />
                                                    <span className='text-danger text-start text-left d-block mt-1'>{compInfoPanNoErr}</span>
                                                </div>
                                                <div className="col-xl-3 mb-1">
                                                    <label htmlFor="validationCustom01">TAN </label>
                                                    <input
                                                        type="text"
                                                        maxLength={10}
                                                        className="form-control"
                                                        value={compInfoTAN}
                                                        onChange={e => this.setState({ compInfoTAN: e.target.value, compInfoTANErr: '' })}
                                                        onInput={toInputUppercase}
                                                    />
                                                    <span className='text-danger text-start text-left d-block mt-1'>{compInfoTANErr}</span>
                                                </div>
                                                <div className="col-xl-3 mb-1">
                                                    <label htmlFor="validationCustom01">GST No </label>
                                                    <input
                                                        type="text"
                                                        maxLength={15}
                                                        className="form-control"
                                                        value={compInfoGstNo}
                                                        onChange={e => this.setState({ compInfoGstNo: e.target.value, compInfoGstNoErr: "" })}
                                                        onInput={toInputUppercase}
                                                    />
                                                    <span className='text-danger text-start text-left d-block mt-1'>{compInfoGstNoErr}</span>
                                                </div>
                                            </div>
                                            <div className="form-row ">
                                                <div className="col-xl-3 mb-1">
                                                    <label htmlFor="validationCustom01">
                                                        Company Registration No
                                                    </label>
                                                    <input
                                                        type="text"
                                                        maxLength={21}
                                                        className="form-control"
                                                        value={compInfoRegistrationNo}
                                                        onChange={e => this.setState({ compInfoRegistrationNo: e.target.value, compInfoRegistrationNoErr: "" })}
                                                        onInput={toInputUppercase}
                                                    />
                                                    {/* <span className='text-danger text-start text-left d-block mt-1'>{compInfoRegistrationNoErr}</span> */}
                                                </div>
                                                <div className="col-xl-3 mb-1">
                                                    <label htmlFor="validationCustom01"> IEC Code</label>
                                                    <input
                                                        type="text"
                                                        maxLength={10}
                                                        className="form-control"
                                                        value={compInfoIecCode}
                                                        onChange={e => this.setState({ compInfoIecCode: e.target.value })}
                                                        onInput={toInputUppercase}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* : <></>
                                        } */}

                                        <div className="main-container bg-section">
                                            <div className="container-fluid pt-2 pb-0 p-3">
                                                <h6> Bank Details </h6>
                                                <div className="form-row mt-2">
                                                    <div className="col-xl-3 mb-1">
                                                        <label htmlFor="validationCustom01">
                                                            Account Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            maxLength={100}
                                                            className="form-control"
                                                            value={bankDtlAccountName}
                                                            onChange={e => this.setState({
                                                                bankDtlAccountName: e.target.value.replace(/[^a-zA-Z ]/g, '').split(' ')
                                                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                                    .join(' '), bankDtlAccountNameErr: ''
                                                            })}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{bankDtlAccountNameErr}</span>}

                                                    </div>
                                                    <div className="col-xl-3 mb-1">
                                                        <label htmlFor="validationCustom01">
                                                            {" "}
                                                            Account No
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            maxLength={30}
                                                            onInput={e => this.maxLengthCheck(e, 30)}
                                                            value={bankDtlAccountNo}
                                                            onChange={e => this.setState({ bankDtlAccountNo: e.target.value.replace(/\D/, ''), bankDtlAccountNoErr: '' })}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{bankDtlAccountNoErr}</span>}

                                                    </div>
                                                    <div className="col-xl-3 mb-1">
                                                        <label htmlFor="validationCustom01">Account Type</label>
                                                        <select
                                                            className="form-control-1 select2 select-hidden-accessible text-left"
                                                            id="roledb"
                                                            style={{ textAlign: "left" }}
                                                            value={bankDtlAccountType}
                                                            onChange={e => this.setState({ bankDtlAccountType: e.target.value, bankDtlAccountTypeErr: '' })}>
                                                            <option>Select</option>
                                                            <option value={'Savingaccount'}>Saving account</option>
                                                            <option value={'Currentaccount'}>Current account</option>
                                                            <option value={'Overdraftaccount'}>Overdraft account</option>
                                                            <option value={'Others'}>Others</option>
                                                        </select>
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{bankDtlAccountTypeErr}</span>}
                                                        {bankDtlAccountType == 'Others' ? (
                                                            <>
                                                                <input
                                                                    type="text"
                                                                    maxLength={20}
                                                                    className="form-control acount-type-oth"
                                                                    value={bankDtlAccountTypeOthers}
                                                                    onChange={e => this.setState({ bankDtlAccountTypeOthers: e.target.value, bankDtlAccountTypeOthersErr: '' })}
                                                                />
                                                                {<span className='text-danger text-start text-left d-block mt-1'>{bankDtlAccountTypeOthersErr}</span>}
                                                            </>
                                                        ) : (null)}
                                                    </div>
                                                </div>
                                                <div className="form-row ">
                                                    <div className="col-xl-3 mb-1">
                                                        <label htmlFor="validationCustom01">Bank Name</label>
                                                        <input
                                                            type="text"
                                                            maxLength={100}
                                                            className="form-control"
                                                            value={bankDtlBankName}
                                                            onChange={e => this.setState({
                                                                bankDtlBankName: e.target.value.replace(/[^a-zA-Z ]/g, '').split(' ')
                                                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                                    .join(' ')
                                                            })}
                                                        />
                                                    </div>
                                                    <div className="col-xl-3 mb-1">
                                                        <label htmlFor="validationCustom01">Branch Name</label>
                                                        <input
                                                            type="text"
                                                            maxLength={50}
                                                            className="form-control"
                                                            value={bankDtlBranchName}
                                                            onChange={e => this.setState({
                                                                bankDtlBranchName: e.target.value.replace(/[^a-zA-Z ]/g, '').split(' ')
                                                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                                    .join(' ')
                                                            })}
                                                        />
                                                    </div>
                                                    <div className="col-xl-3 mb-1">
                                                        <label htmlFor="validationCustom01">
                                                            {" "}
                                                            IFSC CODE{" "}
                                                        </label>
                                                        <input
                                                            type="text"
                                                            maxLength={50}
                                                            className="form-control"
                                                            value={bankDtlIfscCode}
                                                            onChange={e => this.setState({ bankDtlIfscCode: e.target.value, bankDtlIfscCodeErr: '' })}
                                                            onInput={toInputUppercase}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{bankDtlIfscCodeErr}</span>}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="main-container container-fluid p-2 pt-0 pb-0">
                                            <div className="mt-1 mb-4 p-2">
                                                {
                                                    add ?
                                                        <button className="btn btn-next-bot"
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={e => this.addVendor()}
                                                        >
                                                            {" "}
                                                            Save{" "}
                                                        </button> :
                                                        <button className="btn btn-next-bot" onClick={e => this.updateVendor()}
                                                        >
                                                            {" "}
                                                            Update{" "}
                                                        </button>
                                                }

                                                <button className="btn btn-next-bot mx-2" onClick={e => this.cancle()} >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                        <div className="bottom-height"></div>
                                        <Footer />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* </div> */}

                    </>
                </div>
            </>
        );
    }
}

export default withRouter(connect(null, {
    fill_action, addVendorRegistration_action, updateVendorRegistration_action, getDetailsVendorRegistration_action
})(VendorRegistrationAU));