import { DELETE_VENDOR_ALLOCATION, ADD_UPDATE_VENDOR_ALLOCATION, GET_DETAILS_VENDOR_ALLOCATION, GET_HALL_STALL_LIST_ALLOCATION } from "../../../../utils/constants";


const initialState = [];

function vendorAllocation_reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case ADD_UPDATE_VENDOR_ALLOCATION:
            return [...state, payload];
        case GET_DETAILS_VENDOR_ALLOCATION:
            return [...state, payload];
        case GET_HALL_STALL_LIST_ALLOCATION:
            return [...state, payload];
        case DELETE_VENDOR_ALLOCATION:
            return [...state, payload];
        default:
            return state;
    }
}
export default vendorAllocation_reducer;