import { Component } from "react";
import Loader from "../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action } from '../../../../actions/fillCombo_action';
import { cityActionId, stallTypeActionId, stallListActionId, countryActionId, exhibitionActionId, toastError, toastSuccess } from "../../../../utils/constants";
import { emailValidator, panNoValidator, gstNoValidator, tanNoValidator, notMendetoryEmailValidator, pincodeValidator } from '../../../../utils/Validator'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import Pagination from "../../../../utils/pagination";
import AdminDrawer from "../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../common_components/header_with_logo";
import { useNavigate } from "react-router-dom";
import ExhibitorDrawer from "../../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../../common_components/exhibitor_module_header";
import { data } from "jquery";
// import { getGSTMasterDetails_action, insertUpdateGSTMaster_action } from "../../../../actions/admin_module_action/master_action/hall_master_action";
import { insertUpdateGSTMaster_action, getgstMasterList_action } from "../../../../actions/admin_module_action/master_action/gst_master_action";
import Footer from "../../../../common_components/Footer";


class GSTAU extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            currentPage: 1,
            is_add: false,
            is_update: false,
            GstPanMasterID: '',
            GSTNo: '',
            Panno: '',
        }

        this.getGSTMasterDetails = this.getGSTMasterDetails.bind(this)
        this.onPreviousClick = this.onPreviousClick.bind(this)
        this.insertGSTMaster = this.insertGSTMaster.bind(this)
        this.updateGSTMaster = this.updateGSTMaster.bind(this)
        this.onNextClick = this.onNextClick.bind(this)

    }

    componentDidMount() {
        this.setState({
            is_add: this.props.location.state != null && this.props.location.state.is_add != null ? this.props.location.state.is_add : false,
            is_update: this.props.location.state != null && this.props.location.state.is_update != null ? this.props.location.state.is_update : false,
            GstPanMasterID: this.props.location.state != null && this.props.location.state.GstPanMasterID != null ? this.props.location.state.GstPanMasterID : '',
        })
        setTimeout(() => {
            this.state.is_update && this.getGSTMasterDetails()
        }, 100);
    }



    getGSTMasterDetails() {
        this.setState({ isLoading: true })
        const { GstPanMasterID } = this.state
        this.props.getgstMasterList_action(null, GstPanMasterID)
            .then((data) => {
                if (data.error != 1) {
                    console.log(data.data[0])
                    this.setState({ GSTNo: data.data[0][0].GSTNo })
                    this.setState({ Panno: data.data[0][0].PANNo })
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    onPreviousClick() {
        this.props.navigate('/gstList',)
    }

    insertGSTMaster() {
        const { isLoading, exhibitionID, hallName, is_update, GSTNo, Panno } = this.state
        const { hallID } = this.state
        var panErrMsg = panNoValidator(Panno)
        var gstErrMsg = gstNoValidator(GSTNo)
        if (GSTNo != '' && GSTNo != null && Panno != '' && Panno != '') {
            if ((gstErrMsg != '') || (panErrMsg != '')) {

                toastError(
                    gstErrMsg != '' ? gstErrMsg :
                        panErrMsg != '' ? panErrMsg :
                            '',)
            }
            else {
                this.setState({ isLoading: true })
                this.props.insertUpdateGSTMaster_action(null, GSTNo, Panno)
                    .then((data) => {
                        if (data.error != 1) {
                            console.log(data.data)
                            this.props.navigate('/gstList',)
                            toastSuccess("added successfully")
                            this.setState({ isLoading: false })
                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        }
        else {
            toastError("Please enter mandatory fields")
        }
    }

    updateGSTMaster() {
        const { isLoading, exhibitionID, GstPanMasterID, GSTNo, Panno, hallName, is_update } = this.state
        var panErrMsg = panNoValidator(Panno)
        var gstErrMsg = gstNoValidator(GSTNo)
        if (GSTNo != '' && GSTNo != null && Panno != '' && Panno != '') {
            if ((gstErrMsg != '') || (panErrMsg != '')) {

                toastError(
                    gstErrMsg != '' ? gstErrMsg :
                        panErrMsg != '' ? panErrMsg :
                            '',)
            }
            else {
                this.setState({ isLoading: true })
                this.props.insertUpdateGSTMaster_action(GstPanMasterID, GSTNo, Panno)
                    .then((data) => {
                        if (data.error != 1) {
                            console.log(data.data)
                            this.props.navigate('/gstList',)
                            toastSuccess("update successful")
                            this.setState({ isLoading: false })
                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        }
        else {
            toastError("Please enter mandatory fields")
        }
    }

    onNextClick() {
        const { is_update } = this.state;
        if (is_update == true) {
            this.updateGSTMaster();
        } else {
            this.insertGSTMaster();
        }
    }

    render() {
        const { isLoading, exhibitionID, currentPage, GSTNo, Panno } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <div className="app-content with-dr-container com-height">
                        <AdminDrawer />
                        <div className="row">
                            <div className="step-name">
                                <p>{this.state.is_add == true ? 'GST Master Add' : 'GST Master Update'}</p>
                            </div>
                        </div>
                        <div className="m-3">
                            <div className="">
                                {
                                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 1 &&
                                    <div className="row ">
                                        <div className="col-md-4">
                                            <label>GSTIN<span className="text-red">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={GSTNo}
                                                onChange={(e) => this.setState({ GSTNo: e.target.value.toUpperCase(), })}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <label>Pan NO.<span className="text-red">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={Panno}
                                                onChange={(e) => this.setState({ Panno: e.target.value.toUpperCase(), })}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                            < div className="row mt-2" >
                                <div className="col-md-12">
                                    <div className="main-container container-fluid p-0 mt-2 pt-0 pb-0">
                                        <div className="mt-1 mb-4">
                                            {
                                                sessionStorage.getItem("sessionExhRegistrationID") == null &&
                                                <button className="btn btn-previous" onClick={e => this.onPreviousClick()}>
                                                    {" "}
                                                    Back{" "}
                                                </button>
                                            }

                                            <button className="btn btn-next-bot mx-2" onClick={e => this.onNextClick()} >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </>
                <Footer />
            </>)
    }

}
export default withRouter(connect(null, { fill_action, insertUpdateGSTMaster_action, getgstMasterList_action })(GSTAU));