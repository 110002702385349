import { axiosGet, axiosPost } from "../../../../react_axios/Axios";
import { headers, deleteDelegateMeetingMasterUrl, getDelegateMeetingMasterListUrl, insertUpdateDelegateMeetingMasterUrl, DELETE_DELAGATE_MEETING_MASTER, GET_DELAGATE_MEETING_MASTER_LIST, INSERT_UPDATE_DELAGATE_MEETING_MASTER } from "../../../../utils/constants";


export const insertUpdateDelegateMeetingMaster_action = (DelegateMeetingMasterList, DelegateTimeslotmasterList) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            DelegateMeetingMasterList: DelegateMeetingMasterList,
            DelegateTimeslotmasterList: DelegateTimeslotmasterList,
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosPost(insertUpdateDelegateMeetingMasterUrl, params, headers)

        dispatch({
            type: INSERT_UPDATE_DELAGATE_MEETING_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getDelegateMeetingMasterList_action = () => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getDelegateMeetingMasterListUrl, params, headers)

        dispatch({
            type: GET_DELAGATE_MEETING_MASTER_LIST,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const deleteDelegateMeetingMaster_action = (DelegateMeetingID, DelegateTimeSlotID,) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            DelegateMeetingID: DelegateMeetingID,
            DelegateTimeSlotID: DelegateTimeSlotID,
        }
        console.log("deleteExhibitionDocument_action Action------->", params)

        const res = await axiosGet(deleteDelegateMeetingMasterUrl, params, headers)

        dispatch({
            type: DELETE_DELAGATE_MEETING_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}