import { EXHIBITOR_DASHBOARD_ACTION_TYPE, EXHIBITOR_DRAWER_DETAILS_ACTION_TYPE, INSERT_CIRCULAR_ACTION_TYPE, INSERT_EXHIBITOR_BARCODE_ACTION_TYPE, UPLOAD_UNDERTAKING_ACTION_TYPE } from "../../../utils/constants";


const initialState = [];

function exhibitorDashboardReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case EXHIBITOR_DASHBOARD_ACTION_TYPE:
            return [...state, payload];
        case EXHIBITOR_DRAWER_DETAILS_ACTION_TYPE:
            return [...state, payload];
        case UPLOAD_UNDERTAKING_ACTION_TYPE:
            return [...state, payload];
        case INSERT_EXHIBITOR_BARCODE_ACTION_TYPE:
            return [...state, payload];

        default:
            return state;
    }
};

export default exhibitorDashboardReducer