import { axiosGet, axiosPost } from "../../../../react_axios/Axios";
import { getManageAreaListUrl, GET_MANAGE_AREA_LIST, addManageAreaStallUrl, ADD_MANAGE_AREA_STALL, updateManageAreaUrl, UPDATE_MANAGE_AREA, headers } from "../../../../utils/constants";

export const getManageAreaList_action = (ExhibitionID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExhibitionID
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getManageAreaListUrl, params, headers)

        dispatch({
            type: GET_MANAGE_AREA_LIST,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const updateManageArea_action = (ExhibitionID, totalExhibitionArea, tempchapterList, stallList, noOfInstallments, installmentStartDate, baseArea, minBaseArea, inrPrice, usdPrice, earlyBirdDate, earlyBirdDomestic, earlyBirdInternational, maxAreaSSI, domesticSSI, internationalSSi, isOpenSideCharges, isEarlyBird, isDoubleStorey, isSSI, sides, installmentList, twoSideList, isSecurityDeposit, SDlist) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExhibitionID,
            TotalArea: totalExhibitionArea,
            // Installment1: firstInstallment,
            // Installment2: secondInstallment,
            NoofInstallment: noOfInstallments,
            InstallMentStartDate: installmentStartDate,
            DoubleStoreyArea: baseArea,
            DoubleStoreyAreaPercentage: minBaseArea,
            DoubleStoreyINRPrice: inrPrice,
            DoubleStoreyUSDPrice: usdPrice,
            EarlyBirdDate: earlyBirdDate,
            EarlyBirdDomasticPrice: earlyBirdDomestic,
            EarlyBirdInternationalPrice: earlyBirdInternational,
            SSIArea: maxAreaSSI,
            SSIDomasticPrice: domesticSSI,
            SSIInternationalPrice: internationalSSi,
            isChargesForOpenSide: isOpenSideCharges,
            isDoubleStorey: isDoubleStorey,
            isEarlyBird: isEarlyBird,
            isSSICertificate: isSSI,
            OpenSidesID: sides,
            chapterList: tempchapterList,
            stallList: stallList,
            installmentList: installmentList,
            sideChargesList: twoSideList,
            isSecurityDeposit: isSecurityDeposit,
            SDlist: SDlist,
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosPost(updateManageAreaUrl, params, headers)

        dispatch({
            type: UPDATE_MANAGE_AREA,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const addManageAreaStall_action = (ExhibitionStallDimension, StallArea, StalltypeID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionStallDimension: ExhibitionStallDimension,
            StallArea: StallArea,
            StalltypeID: StalltypeID
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosPost(addManageAreaStallUrl, params, headers)

        dispatch({
            type: ADD_MANAGE_AREA_STALL,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}