import { GET_TIMELINE_ACTION_TYPE, INSERT_TIMELINE_MASTER_LIST, UPDATE_TIMELINE_MASTER_LIST, DELETE_TIMELINE_MASTER_LIST } from "../../../utils/constants";


const initialState = [];

function timelineMasterReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case GET_TIMELINE_ACTION_TYPE:
            return [...state, payload];
        case INSERT_TIMELINE_MASTER_LIST:
            return [...state, payload];
        case UPDATE_TIMELINE_MASTER_LIST:
            return [...state, payload];
        case DELETE_TIMELINE_MASTER_LIST:
            return [...state, payload];

        default:
            return state;
    }
};

export default timelineMasterReducer