import { Component, forwardRef } from "react";
import Loader from "../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import HeaderWithLogo from "../../../common_components/header_with_logo";
import AdminDrawer from "../../../common_components/admin_module_drawer";
import Pagination from "../../../utils/pagination";
import { downloadReport, invoiceTypeActionId, loginUrl, priorityNoActionId, toastError, headers, toastSuccess, exhibitionActionId, exhibitorNameActionId } from "../../../utils/constants";
import Modal from "react-modal";
import moment from "moment";
import { data } from "jquery";
import { descriptionPreview_action } from "../../../actions/description_action"
import { fill_action } from "../../../actions/fillCombo_action"
import Accordion from '@mui/material/Accordion';
import { getAcccountTallyList_action } from "../../../actions/admin_module_action/admin_account_action/account_tally_action";
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import DatePicker from "react-datepicker";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Workbook from 'react-excel-workbook'
import { axiosGet, axiosPost } from "../../../react_axios/Axios";

class subReport1 extends Component {
    // intialize constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            showTable: false,
            exhibitionID: '',
            exhibitionList: [],
            invoiceTypeList: [],
            list: [],
            columnArr: [],
            rowArr: [],
            invoiceTypeID: '',
            xlrowArr: [],
            fromDate: '',
            toDate: '',
            showTable: false,
        }
        this.getFillList = this.getFillList.bind(this)
        this.getTallyData = this.getTallyData.bind(this)

    }

    componentDidMount() {
        // this.getExhibitionManualForm()
        this.getFillList(exhibitionActionId, '', '', '', '', '')
        this.getFillList(invoiceTypeActionId, '', '', '', '', '')
    }

    // get Fill List
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props.fill_action(actionID, exhibitionID, moduleID, keyValue, value)
            .then((data) => {
                if (data.error != 1) {
                    // Country ListinvoiceTypeActionId
                    if (actionID === exhibitionActionId) {
                        console.log("country data", data.data)
                        this.setState({
                            exhibitionList: data.data,
                            isLoading: false
                        })
                    }
                    if (actionID === invoiceTypeActionId) {
                        console.log("country data", data.data)
                        this.setState({
                            invoiceTypeList: data.data,
                            isLoading: false
                        })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    getTallyData() {
        const { exhibitionID, invoiceTypeID, fromDate, toDate } = this.state
        console.log(exhibitionID)
        if (exhibitionID != '' && exhibitionID != null && invoiceTypeID != '' && invoiceTypeID != null) {
            this.setState({ isLoading: true })
            this.props.getAcccountTallyList_action(exhibitionID, invoiceTypeID, (fromDate != null && fromDate != '' ? moment(fromDate).format('YYYY-MM-DD') : ''), (toDate != null && toDate != '' ? moment(toDate).format('YYYY-MM-DD') : ''))
                .then((data) => {
                    console.log(data)
                    if (data.error != 1) {
                        // data.data.map((element) =>
                        //     delete element['ADDITIONAL BADGES'])
                        this.setState({ list: data.data })
                        // if (status == 'view') {
                        this.getTableData(data.data)
                        this.setState({ showTable: true })
                        // }
                        // else if (status == 'download') {
                        //     this.onDownloadClick(data.data)
                        //     this.setState({ isLoading: false })
                        this.setState({ isLoading: false })

                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            if (exhibitionID != '' && exhibitionID != null) {
                toastError("Please select Invoice Type")
            } else if (invoiceTypeID != '' && invoiceTypeID != null) {
                toastError("Please select Exhibition")
            } else {
                toastError("Please select Exhibition and Invoice Type")
            }
        }
    }

    getTableData(list) {
        const { } = this.state
        // for column data 
        var columnArray = []
        var rowData = []
        console.log('columnArray', list);

        list.map((data, index) => {
            if (index == 0) {
                console.log('columnArray', data, Object.keys);

                Object.keys(data).map((key, index) => {

                    columnArray.push(key)
                })
            }
        })
        this.setState({ columnArr: columnArray })
        console.log('columnArray', columnArray);
        var xlRowdata = []
        // // for row data
        list.map((data, index) => {
            rowData.push(Object.values(data))
            xlRowdata.push(data)
        })
        this.setState({ rowArr: rowData })
        this.setState({ xlrowArr: xlRowdata })
        setTimeout(() => {

            console.log(rowData, xlRowdata)
        }, 500);
    }


    onDownloadClick() {
        const { list, columnArr, rowArr, exhibitionID, reportName } = this.state
        this.setState({ showTable: true })
        return (
            <div className="row text-center">
                {list.length > 0 && <Workbook filename={`account_tally.xlsx`} style={{}} element={
                    <button type="button" className="btn ad-fil-btn m-0" >
                        download
                    </button>
                }>
                    <Workbook.Sheet data={list} name="Sheet A">
                        {columnArr.map((value, index) =>
                            <Workbook.Column label={value} value={value} />
                        )
                        }
                    </Workbook.Sheet>
                </Workbook>}
            </div>
        );
    }


    renderTable() {
        const { columnArr, rowArr } = this.state
        console.log('k')
        return (
            <>
                <div className="table-responsive mb-4">
                    <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1">
                        <thead> <tr>
                            {/* <th><input type="checkbox" key={Math.random()} defaultChecked={isAllCheck} onClick={() => this.allCheckClick(!isAllCheck)} /></th> */}
                            {
                                columnArr.length > 0 && columnArr.map((data, index) => (
                                    <>  {(<th>{data}</th>)}</>
                                ))
                            }    </tr>

                        </thead>

                        <tbody>
                            {
                                rowArr.length > 0 ?
                                    rowArr.map((data, i) => (
                                        <tr key={i}>
                                            {/* <td><input type="checkbox" key={Math.random()} defaultChecked={data[data.length - 1]} onClick={() => this.onSelectCheckClick(data[data.length - 2], !data[data.length - 1])} /></td> */}
                                            {data != null && data != '' && data.map((element, index) => (
                                                <>
                                                    <td>{element}
                                                    </td></>

                                            ))}
                                        </tr>
                                    )) : <tr><td colSpan={3} style={{ textAlign: 'center' }}>Record not found</td></tr>
                            }
                        </tbody>
                    </table>
                </div>
            </>
        )
    }

    render() {
        const { isLoading, reportID, reportName, fromDate, invoiceTypeID, invoiceTypeList, toDate, rowArr, exhibitorList, showTable, exhibitionID, ExhRegistrationID, exhibitionList, xlrowArr, columnArr, list } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <div className={JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ? "with-dr-container" : "with-dr-container com-height"} style={{ minHeight: '100vh' }}>
                        <AdminDrawer />
                        <div className="row">
                            <div className="step-name-space" style={{ zIndex: 9 }}>
                                <p>Account Tally</p>
                            </div>
                        </div>

                        <div className="main-container">
                            <div className="row mt-5 m-2">
                                <div className="col-xl-3">
                                    <label htmlFor="validationCustom03">Select Exhibiton <span className="text-red">*</span></label>
                                    <select className="form-control"
                                        value={exhibitionID}
                                        // disabled={isView}
                                        onChange={(e) => this.setState({ exhibitionID: e.target.value })}
                                    >
                                        <option value={''}>Select</option>
                                        {
                                            exhibitionList.length > 0 && exhibitionList.map(data => (
                                                <option value={data.ExhibitionID} key={data.ExhibitionID}>{data.ExhibitionName}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="col-xl-3">
                                    <label htmlFor="validationCustom03">Select Invoice Type<span className="text-red">*</span></label>
                                    <select className="form-control"
                                        value={invoiceTypeID}
                                        // disabled={isView}
                                        onChange={(e) => this.setState({ invoiceTypeID: e.target.value })}
                                    >
                                        <option value={''}>Select</option>
                                        {
                                            invoiceTypeList.length > 0 && invoiceTypeList.map(data => (
                                                <option value={data.ID} key={data.ID}>{data.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div class="col-md-3">
                                    <div className="col-xl-12 p-0">
                                        <label htmlFor="validationCustom03">From Date</label>
                                        <DatePicker
                                            style={{ zIndex: '99999' }}
                                            selected={fromDate}
                                            dateFormat={"dd/MM/yyyy"}
                                            className="form-control"
                                            onChange={(date) => this.setState({ fromDate: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())) })}
                                            peekNextMonth
                                            // maxDate={toDate}
                                            // minDate={new Date()}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            placeholderText="From Date"
                                        />
                                        {/* {<span className='text-danger text-start text-left d-block mt-1'>{circularDateErr}</span>} */}
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div className="col-xl-12 p-0">
                                        <label htmlFor="validationCustom03">To Date</label>
                                        <DatePicker
                                            style={{ zIndex: '99999', }}
                                            selected={toDate}
                                            dateFormat={"dd/MM/yyyy"}
                                            className="form-control"
                                            onChange={(date) => this.setState({ toDate: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())) })}
                                            peekNextMonth
                                            // minDate={fromDate}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            placeholderText="To Date"
                                        />
                                        {/* {<span className='text-danger text-start text-left d-block mt-1'>{circularDateErr}</span>} */}
                                    </div>
                                </div>
                                <div className="btn-group mt-4 save-can d-block" style={{ float: 'right', right: '0px' }} role="group" aria-label="Basic example">
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary p-1 m-2"
                                        style={{ width: 140, fontSize: 15, }}
                                        onClick={this.getTallyData}
                                    >
                                        Search
                                    </button>
                                    {list.length > 0 ? <Workbook filename={`account_tally.xlsx`} style={{}} element={
                                        <button className="btn btn-outline-primary p-1 ml-3"
                                            // disabled={!this.getDownloadAccess()}
                                            style={{ width: 140, fontSize: 15 }} >
                                            download
                                        </button>
                                    }>
                                        <Workbook.Sheet data={xlrowArr} name="Sheet A">
                                            {columnArr.map((value, index) =>
                                                <Workbook.Column label={value} value={value} />
                                            )
                                            }
                                        </Workbook.Sheet>
                                    </Workbook>
                                        : <button className="btn btn-outline-primary p-1" style={{ width: 140, fontSize: 15 }} disabled={true}>
                                            download
                                        </button>
                                    }
                                </div>
                            </div>
                            {showTable && this.renderTable()}
                        </div>
                    </div>
                </>
            </>

        )
    }
}

export default withRouter(connect(null, { fill_action, getAcccountTallyList_action })(subReport1))