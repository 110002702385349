import { axiosGet, axiosPost } from "../../../../react_axios/Axios";
import {
    GET_STALL_DESIGN_APPROVAL_DETAILS_ACTION_TYPE, GET_STALL_DESIGN_APPROVAL_LIST_ACTION_TYPE, GET_STALL_DESIGN_APPROVAL_EXPORT_DATA_ACTION_TYPE, UPDATE_STALL_DESIGN_APPROVAL__ACTION_TYPE,
    getStallDesignApprovalDetailsUrl, getStallDesignApprovalListUrl, getStallDesignExportDataUrl, updateStallDesignApprovalUrl, headers
} from "../../../../utils/constants";

export const getStallDesignApprovalDetails_action = (companyName, status, exhibitorTypeId) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            CompanyName: companyName,
            Status: status,
            ExhibitorTypeID: exhibitorTypeId,
        }

        const res = await axiosGet(getStallDesignApprovalDetailsUrl, params, headers)

        dispatch({
            type: GET_STALL_DESIGN_APPROVAL_DETAILS_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getStallDesignApprovalList_action = (companyname) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            CompanyName: companyname,

        }

        const res = await axiosGet(getStallDesignApprovalListUrl, params, headers)

        dispatch({
            type: GET_STALL_DESIGN_APPROVAL_LIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getStallDesignExportData_action = () => async (dispatch) => {
    try {
        const params = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
        }

        const res = await axiosGet(getStallDesignExportDataUrl, params, headers)

        dispatch({
            type: GET_STALL_DESIGN_APPROVAL_EXPORT_DATA_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const updateStallDesignApproval_action = (isApproved, list) => async (dispatch) => {
    try {
        const mapData = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            Is_Approved: isApproved,
            approvalList: list
        }

        const res = await axiosPost(updateStallDesignApprovalUrl, mapData, headers)

        dispatch({
            type: UPDATE_STALL_DESIGN_APPROVAL__ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

