import { GET_TIMELINE_ACTION_TYPE } from "../../../utils/constants";


const initialState = [];

function exhibitionTimelineReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case GET_TIMELINE_ACTION_TYPE:
            return [...state, payload];

        default:
            return state;
    }
};

export default exhibitionTimelineReducer