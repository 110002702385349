import { axiosGet, axiosPost } from "../../../react_axios/Axios";
import { GET_CHECKED_EXHIBITORS_ACTION_TYPE, INSERT_UPDATE_FEATURES_ACTION_TYPE, getCheckedExhibitorsUrl, headers, insertUpdateFeaturesUrl } from "../../../utils/constants";

export const getCheckedExhibitors_action = (id) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            FeaturesID: id
        }
        console.log("getCheckedExhibitors_action -Action--------------------------->", params)

        const res = await axiosGet(getCheckedExhibitorsUrl, params, headers);
        dispatch({
            type: GET_CHECKED_EXHIBITORS_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const insertUpdateFeatures_action = (id, list) => async (dispatch) => {
    try {
        const mapData = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            FeaturesID: id,
            exhibitorList: list
        }
        console.log("insertUpdateFeatures_action -Action--------------------------->", mapData)

        const res = await axiosPost(insertUpdateFeaturesUrl, mapData, headers);
        dispatch({
            type: INSERT_UPDATE_FEATURES_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}