import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { toastError, toastSuccess } from "../../../../../utils/constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import Pagination from "../../../../../utils/pagination";
import { fill_action } from '../../../../../actions/fillCombo_action';
import { getTimelineMasterList_action, insertTimelineMasterList_action, updateTimelineMasterList_action, deleteTimelineMasterList_action } from '../../../../../actions/admin_module_action/master_action/exhibition_master_action/timeline_master_action';

class Exh_timeline extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            addNewFlag: false,
            timelineStartDate: '',
            timelineEndDate: '',
            description: '',
            timelineList: [],
            currentPage: 1,
            updateFlag: false,
            searchTimelineStartDate: '',
            searchTimelineEndDate: '',
            searchDescription: '',
            timelineRowId: '',
            ExihibitionID: '',
        }
        this.addNewTimeline = this.addNewTimeline.bind(this)
        this.updateItem = this.updateItem.bind(this)
    }

    componentDidMount() {
        this.setState({
            ExihibitionID: this.props.location.state.ExihibitionID != undefined ? (this.props.location.state != null && this.props.location.state.ExihibitionID != null ? this.props.location.state.ExihibitionID : '') : this.props.id != null && this.props.id != undefined ? this.props.id : '',
        })
        setTimeout(() => {
            console.log(this.state.ExihibitionID)

            this.getTimelineList()
        }, 200);
    }

    updateItem(item) {
        this.setState({ currentPage: item });
    }

    getTimelineList() {
        const { ExihibitionID, searchTimelineStartDate, searchTimelineEndDate, searchDescription } = this.state
        this.setState({ isLoading: true })
        this.props.getTimelineMasterList_action(ExihibitionID, searchTimelineStartDate, searchTimelineEndDate, searchDescription)
            .then((data) => {
                if (data.error != 1) {
                    console.log("1314", data.data)
                    this.setState({ isLoading: false, timelineList: data.data })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    searchList() {
        const { ExihibitionID, searchTimelineStartDate, searchTimelineEndDate, searchDescription } = this.state
        this.setState({ isLoading: true })
        if (searchTimelineEndDate != '') {
            this.props.getTimelineMasterList_action(ExihibitionID, searchTimelineStartDate, searchTimelineEndDate, searchDescription)
                .then((data) => {
                    if (data.error != 1) {
                        console.log("1314", data.data)
                        this.setState({ isLoading: false, timelineList: data.data })
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            toastError("please select timeline end date!")
            this.setState({ isLoading: false })
        }
    }

    insertTimeline() {
        const { ExihibitionID, timelineStartDate, timelineEndDate, description } = this.state
        this.setState({ isLoading: true })
        if (timelineEndDate != '' && timelineEndDate != null && description != '' && description != null) {
            this.props.insertTimelineMasterList_action(ExihibitionID, timelineStartDate, timelineEndDate, description)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({
                            isLoading: false,
                            timelineStartDate: '',
                            timelineEndDate: '',
                            description: '',
                            searchDescription: '',
                            searchTimelineStartDate: '',
                            searchTimelineEndDate: '',
                            addNewFlag: false
                        })
                        toastSuccess("insert successfully!")
                        this.getTimelineList()
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            toastError("Please fill mendetory feilds")
            this.setState({ isLoading: false })
        }
    }

    deleteTimeline(rowId) {
        this.setState({ isLoading: true })
        this.props.deleteTimelineMasterList_action(this.state.ExihibitionID, rowId)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ isLoading: false })
                    toastSuccess("Deleted successfully!")
                    this.getTimelineList()
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    getTimelineDetails(detailsStartDate, detailsEndDate, detailsDescription, rowId) {
        const { timelineDate, description, timelineList } = this.state
        this.setState({
            timelineStartDate: detailsStartDate != '' && detailsStartDate != null ? moment(detailsStartDate, 'YYYY-MM-DD').toDate() : '',
            timelineEndDate: detailsEndDate != '' && detailsEndDate != null ? moment(detailsEndDate, 'YYYY-MM-DD').toDate() : '',
            description: detailsDescription,
            addNewFlag: true,
            updateFlag: true,
            timelineRowId: rowId
        })
    }

    updateTimeline() {
        const { timelineStartDate, ExihibitionID, timelineEndDate, description, timelineRowId } = this.state
        this.setState({ isLoading: true })
        if (timelineEndDate != '' && timelineEndDate != null && description != '' && description != null) {
            this.props.updateTimelineMasterList_action(ExihibitionID, timelineStartDate, timelineEndDate, description, timelineRowId)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({
                            isLoading: false,
                            timelineStartDate: '',
                            timelineEndDate: '',
                            description: '',
                            searchDescription: '',
                            searchTimelineStartDate: '',
                            searchTimelineEndDate: '',
                            updateFlag: false,
                            addNewFlag: false
                        })
                        toastSuccess("update successfully!")
                        this.getTimelineList()
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            toastError("Please fill mendetory feilds")
            this.setState({ isLoading: false })
        }
    }

    addNewTimeline() {
        this.setState({
            addNewFlag: true,
            updateFlag: false,
            timelineStartDate: '',
            timelineEndDate: '',
            description: ''
        })
    }

    handleClear() {
        this.setState({
            searchTimelineStartDate: '',
            searchTimelineEndDate: '',
            searchDescription: '',
            addNewFlag: false,
            updateFlag: false,
            timelineStartDate: '',
            timelineEndDate: '',
            description: ''
        })
        setTimeout(() => {
            this.getTimelineList()
        }, 100);
    }

    record(timelineList) {
        return (
            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
                <thead>
                    <tr style={{ border: '1px solid #ccc' }}>
                        <th style={{ width: '60px', border: '1px solid #ccc' }} className="text-center" colSpan={2}>Timeline</th>
                        <th style={{ width: '100%', border: '1px solid #ccc', paddingBottom: '28px' }} className="text-center" rowSpan={2}>Description</th>
                        <th className="text-center"></th>
                        <th className="text-center"></th>
                    </tr>
                    <tr>
                        <th >Start time</th>
                        <th>End time</th>
                        <th className="text-center"></th>
                        <th className="text-center"></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        timelineList != '' && timelineList != null ? (
                            timelineList.map((data, i) => (
                                <tr key={Math.random()}>
                                    <td className="text-center">{data.Startdate != null && data.Startdate != '' ? moment(data.Startdate).format("DD.MM.YYYY")
                                        : ''}</td>
                                    <td >{data.Enddate != null && data.Enddate != '' ? moment(data.Enddate).format("DD.MM.YYYY")
                                        : ''}</td>
                                    <td >{data.Description}</td>
                                    <td>
                                        <button
                                            class="btn btn-sm delete_record"
                                            onClick={() => this.getTimelineDetails(data.Startdate, data.Enddate, data.Description, data.ID)}
                                        ><i class="fa fa-edit action_btn_table"></i></button>
                                    </td>
                                    <td className="text-center">
                                        <button
                                            title="View Order"
                                            class="btn btn-sm padding_0"
                                            onClick={() => this.deleteTimeline(data.ID)}
                                        >
                                            <i class="fa fa-trash action_btn_table"></i>
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (<tr><td colSpan={6} className='text-center'>Record not found</td></tr>)
                    }
                </tbody>
            </table>)
    }

    render() {
        const { isLoading, addNewFlag, timelineList, currentPage, timelineStartDate, timelineEndDate, description, updateFlag, searchTimelineStartDate, searchTimelineEndDate, searchDescription } = this.state

        const recordsPerPage = 10
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        // Records to be displayed on the current page
        const currentRecords = timelineList.slice(indexOfFirstRecord, indexOfLastRecord)
        const nPages = Math.ceil(timelineList.length / recordsPerPage)

        return (
            <>

                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <AdminDrawer />


                    <div className="main-container p-2">

                        <div className="row" style={{ paddingBottom: '30px' }}>
                            <div className="row mt-3" >
                                <div className="col-md-2">
                                    <label htmlFor="validationCustom03">Timeline Start Date</label>
                                    <DatePicker className="form-control"
                                        dateFormat="dd-MM-yyyy"
                                        selected={searchTimelineStartDate}
                                        onChange={(date) => {
                                            this.setState({ searchTimelineStartDate: date })
                                        }}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        placeholderText="dd-mm-yyyy"
                                    />
                                </div>
                                <div className="col-md-2">
                                    <label htmlFor="validationCustom03">Timeline End Date<span class="required">*</span></label>
                                    <DatePicker className="form-control"
                                        dateFormat="dd-MM-yyyy"
                                        selected={searchTimelineEndDate}
                                        onChange={(date) => {
                                            this.setState({ searchTimelineEndDate: date })
                                        }}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        placeholderText="dd-mm-yyyy"
                                    />
                                </div>
                                <div className="col-md-5">
                                    <label htmlFor="validationCustom03">Description</label>
                                    <input
                                        value={searchDescription}
                                        onChange={(e) => this.setState({ searchDescription: e.target.value })}
                                        type="text"
                                        className="form-control"
                                        placeholder="Search Description"
                                    />
                                </div>
                                <div className="btn-group w-auto" style={{ marginTop: '22px' }}>
                                    <button class="btn rounded-0 btn-primary"
                                        onClick={() => this.searchList()}
                                        style={{ padding: "4px 13px", height: '35px' }}>Search</button>
                                    <button class="btn rounded-0 btn-primary"
                                        onClick={() => this.handleClear()}
                                        style={{ padding: "4px 13px", margin: '0px 10px 0px 5px', height: '35px' }}>Clear</button>
                                    <button class="btn rounded-0 btn-dark"
                                        onClick={() => this.addNewTimeline()}
                                        style={{ padding: "4px 13px", margin: '0px 10px 0px 5px', height: '35px' }}>Add New</button>
                                </div>
                            </div>
                        </div>
                        {
                            addNewFlag ?
                                <div className="row">
                                    <div className="asc" style={{ background: '#f0f0f5', padding: '30px 0' }}>
                                        <div className="d-flex">
                                            <div className="col-md-2">
                                                <label htmlFor="validationCustom03">Timeline Start Date</label>
                                                <DatePicker className="form-control"
                                                    dateFormat="dd-MM-yyyy"
                                                    selected={timelineStartDate}
                                                    onChange={(date) => {
                                                        this.setState({ timelineStartDate: date })
                                                    }}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    placeholderText="dd-mm-yyyy"
                                                />
                                            </div>
                                            <div className="col-md-2">
                                                <label htmlFor="validationCustom03">Timeline End Date<span class="required">*</span></label>
                                                <DatePicker className="form-control"
                                                    dateFormat="dd-MM-yyyy"
                                                    selected={timelineEndDate}
                                                    onChange={(date) => {
                                                        this.setState({ timelineEndDate: date })
                                                    }}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    placeholderText="dd-mm-yyyy"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12" style={{ margin: '10px 0' }}>
                                            <label htmlFor="validationCustom03">Description<span class="required">*</span></label>
                                            <textarea maxLength={1000}
                                                onChange={(e) => this.setState({ description: e.target.value })}
                                                disabled={!addNewFlag}
                                                value={description}
                                                style={{ height: '80px', width: '100%' }}
                                                class="form-control" rows="3"
                                                placeholder={addNewFlag ? "Add Description" : ''}
                                            ></textarea>
                                        </div>
                                        <div className="col-md-4">
                                            {
                                                updateFlag ?
                                                    <button class="btn rounded-0 btn-dark"
                                                        onClick={() => this.updateTimeline()}
                                                        style={{ padding: "4px 13px", margin: '0px 10px 0px 5px', height: '35px' }}>Update </button> :
                                                    <button class="btn rounded-0 btn-dark"
                                                        onClick={() => this.insertTimeline()}
                                                        disabled={!addNewFlag}
                                                        style={{ padding: "4px 13px", margin: '0px 10px 0px 5px', height: '35px' }}>Save </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                                : <></>
                        }
                        <div className="row" style={{ width: '97%', margin: 'auto' }}>
                            <div className="asc" style={{ padding: '20px 0' }}>
                                <div className="table-responsive">
                                    {this.record(currentRecords)}
                                </div>
                            </div>
                            <div className="mt-2 float-left">
                                {timelineList.length > 0 ? <Pagination
                                    nPages={nPages}
                                    currentPage={currentPage}
                                    setCurrentPage={this.updateItem} /> : <></>}

                            </div>
                        </div>
                    </div>

                </>

            </>
        );
    }
}

export default withRouter(connect(null, { fill_action, getTimelineMasterList_action, insertTimelineMasterList_action, updateTimelineMasterList_action, deleteTimelineMasterList_action })(Exh_timeline));