import { axiosGet, axiosPost } from "../../../../react_axios/Axios";
import { headers, getMeetingRoomMasterListUrl, insertUpdateMeetingRoomMasterUrl, deleteMeetingRoomMasterUrl, GET_MEETING_ROOM_MASTER_LIST, DELETE_MEETING_ROOM_MASTER, INSERT_UPDATE_MEETING_ROOM_MASTER } from "../../../../utils/constants";


export const insertUpdateMeetinRoomMaster_action = (MeetingRoomMasterList) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            MeetingRoomMasterList: MeetingRoomMasterList,
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosPost(insertUpdateMeetingRoomMasterUrl, params, headers)

        dispatch({
            type: INSERT_UPDATE_MEETING_ROOM_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getMeetinRoomMasterList_action = () => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getMeetingRoomMasterListUrl, params, headers)

        dispatch({
            type: GET_MEETING_ROOM_MASTER_LIST,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const deleteMeetinRoomMaster_action = (ExhMeetingRoomID, SeaterMeetingRoomID, ExhMeetingRoomDateTimeSlotID, ExhmeetingRoomTimeSlotID, ExhmeetingRoomPriceHdrID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            SeaterMeetingRoomID: SeaterMeetingRoomID,
            ExhMeetingRoomID: ExhMeetingRoomID,
            ExhMeetingRoomDateTimeSlotID: ExhMeetingRoomDateTimeSlotID,
            ExhmeetingRoomTimeSlotID: ExhmeetingRoomTimeSlotID,
            ExhmeetingRoomPriceHdrID: ExhmeetingRoomPriceHdrID,
        }
        console.log("deleteExhibitionDocument_action Action------->", params)

        const res = await axiosGet(deleteMeetingRoomMasterUrl, params, headers)

        dispatch({
            type: DELETE_MEETING_ROOM_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}