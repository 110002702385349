import { Component, forwardRef } from "react";
import Loader from "../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import HeaderWithLogo from "../../../common_components/header_with_logo";
import AdminDrawer from "../../../common_components/admin_module_drawer";
import Pagination from "../../../utils/pagination";
import { downloadReport, loginUrl, priorityNoActionId, toastError, toastSuccess } from "../../../utils/constants";
import Modal from "react-modal";
import { data } from "jquery";
import { getReportList_action } from "../../../actions/admin_module_action/report_action/report_action";
import { fill_action } from "../../../actions/fillCombo_action"
import { exhibitionActionId, exhibitorNameActionId } from "../../../utils/constants";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


class ReportList extends Component {
    // intialize constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            panel: null,
            reportList: []
        }
        this.handleChange = this.handleChange.bind(this)
        this.getReportList = this.getReportList.bind(this)
        this.getFillList = this.getFillList.bind(this)


    }

    componentDidMount() {
        // this.getExhibitionManualForm()
        this.getFillList(exhibitionActionId, '', '', '', '', '')
        this.getFillList(exhibitorNameActionId, '', '', '', '', '')
        this.getReportList()
    }

    // get Fill List
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props.fill_action(actionID, exhibitionID, moduleID, keyValue, value)
            .then((data) => {
                if (data.error != 1) {
                    // Country List
                    if (actionID === exhibitionActionId) {
                        console.log("country data", data.data)
                        this.setState({
                            // countryList: data.data,
                            isLoading: false
                        })
                    }
                    // visa  id type
                    if (actionID === exhibitorNameActionId) {
                        console.log("visaIdTypeActionId data", data.data)
                        this.setState({
                            // idTypeList: data.data,
                            isLoading: false
                        })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    handleChange(panel) {
        if (panel == this.state.panel) {
            this.setState({ panel: null })
        }
        else {
            this.setState({ panel: panel })
        }
    }

    getReportList() {
        this.setState({ isLoading: true })
        this.props.getReportList_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log(data.data)
                    this.createReportList(data.data)
                    this.setState({
                    })
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    createReportList(list) {
        var tempReportGroupList = []
        var tempList = []
        for (var i = 0; i < list.length; i++) {
            if (!tempReportGroupList.includes(list[i].ReportGroupID)) {
                tempReportGroupList.push(list[i].ReportGroupID)
                var obj = { ReportGroupID: list[i].ReportGroupID, ReportName: list[i].ReportName }
                tempList.push(obj)
            }
        }
        for (var i = 0; i < tempList.length; i++) {
            var tempSubReportList = []
            for (var j = 0; j < list.length; j++) {
                if (tempList[i].ReportGroupID == list[j].ReportGroupID) {
                    tempSubReportList.push(list[j])
                }
            }
            tempList[i].subReportList = tempSubReportList
        }
        console.log(tempList)
        setTimeout(() => {
            this.setState({ reportList: tempList })
        }, 100);
    }

    navigateToSubReport(subdata) {
        if (subdata.ReportName == "Manual Forms") {
            this.props.navigate('/manualFormReport', { state: { reportID: subdata.ReportID, reportName: this.titleCase(subdata.SubReport) } })
        } else {
            this.props.navigate('/accountSubReports', { state: { reportID: subdata.ReportID, reportName: this.titleCase(subdata.SubReport) } })
        }
    }

    titleCase(str) {
        return str
            .toLowerCase()  // Convert the string to lowercase
            .split(' ')     // Split the string into an array of words
            .map(word =>    // Capitalize the first letter of each word
                word.charAt(0).toUpperCase() + word.slice(1)
            )
            .join(' ');     // Join the words back into a single string
    }

    render() {
        const { isLoading, panel, reportList } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                <div className={JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ? "with-dr-container" : "with-dr-container com-height"}>
                    <div className="main-container">
                        <AdminDrawer />
                        <div className="row">
                            <div className="step-name">
                                <p>Reports</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-12 p-0">
                        {reportList.length > 0 && reportList.map((data, i) => (
                            <Accordion className="m-1" expanded={panel === i} onChange={() => this.handleChange(i)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                        {data.ReportName}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <ul className="">
                                            {data.subReportList.length > 0 && data.subReportList.map((subdata, i) => (
                                                <li onClick={() => this.navigateToSubReport(subdata)} class="mb-1">
                                                    <a>
                                                        {`${i + 1})`} {this.titleCase(subdata.SubReport)}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                        {/* <Accordion className="m-1" expanded={panel === 1} onChange={() => this.handleChange(1)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                    General settings
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <ul>
                                        <li class="mb-1">
                                            <a href="#">1) 111 Anim pariatur cliche reprehenderit, enim eiusmod high life.</a>
                                        </li>
                                        <li class="mb-1">
                                            <a href="#">2)  You can modify any of this with custom CSS or overriding our default variables. It's also worth</a>
                                        </li>
                                        <li class="mb-1">
                                            <a href="#">3) HTML can go within the .accordion-body, though the transition does limit overflow.</a>
                                        </li>
                                    </ul>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className="m-1" expanded={panel === 2} onChange={() => this.handleChange(2)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2bh-content"
                                id="panel2bh-header"
                            >
                                <Typography sx={{ width: '33%', flexShrink: 0 }}>Users</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus,
                                    varius pulvinar diam eros in elit. Pellentesque convallis laoreet
                                    laoreet.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className="m-1" expanded={panel === 3} onChange={() => this.handleChange(3)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3bh-content"
                                id="panel3bh-header"
                            >
                                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                    Advanced settings
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit
                                    amet egestas eros, vitae egestas augue. Duis vel est augue.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className="m-1" expanded={panel === 4} onChange={() => this.handleChange(4)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel4bh-content"
                                id="panel4bh-header"
                            >
                                <Typography sx={{ width: '33%', flexShrink: 0 }}>Personal data</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit
                                    amet egestas eros, vitae egestas augue. Duis vel est augue.
                                </Typography>
                            </AccordionDetails>
                        </Accordion> */}
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(connect(null, { fill_action, getReportList_action })(ReportList))