import { GET_AGENT_DASHBOARD_ACTION_TYPE, GET_EXHIBITOR_DETAIL_ACTION_TYPE } from "../../../utils/constants";


const initialState = [];

function agentDashboardReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case GET_AGENT_DASHBOARD_ACTION_TYPE:
            return [...state, payload];
        case GET_EXHIBITOR_DETAIL_ACTION_TYPE:
            return [...state, payload];

        default:
            return state;
    }
};

export default agentDashboardReducer