import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../../utils/withRouter';
import Loader from '../../../../utils/Loader'
// import { clearSessionStorage } from '../../../utils/constants'
import Header from '../../../../common_components/Header';
// import { emailValidator } from '../../../utils/Validator'
import Modal from "react-modal";
import Footerwithdr from '../../../../common_components/Footerwithdr';
import HeaderWithLogo from '../../../../common_components/header_with_logo';
import AdminDrawer from '../../../../common_components/admin_module_drawer';
import { fill_action } from '../../../../actions/fillCombo_action'
// import ReceiptList from '../registration_receipt/ReceiptList';
import { Link } from "react-router-dom";
import { getAdminApplicationSummaryList_action } from "../../../../actions/admin_module_action/admin_dasboard_action/admin_dashboard_summary_action/admin_dashboard_summary_action"
import { getAdminDashboardData_action } from "../../../../actions/admin_module_action/admin_dasboard_action/admin_dashboard_action"
import { data } from 'jquery';

class ApplicationSummary extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            applicationSummaryList: [],
            pendingPaymentCount: '',
        }
        this.record = this.record.bind(this)
        this.getDashboardData = this.getDashboardData.bind(this)
        this.navToPaymentList = this.navToPaymentList.bind(this)
        this.getApplicationSummaryList = this.getApplicationSummaryList.bind(this)
        this.onCountClick = this.onCountClick.bind(this)
    }

    // On Component Load
    componentDidMount() {
        this.getDashboardData()
        this.getApplicationSummaryList()
    }

    // Get DashBoard Data
    getDashboardData() {
        this.setState({ isLoading: true })
        this.props.getAdminDashboardData_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log("Admin Dashboard Data ------->", data.data)
                    this.setState({
                        isLoading: false,
                        pendingPaymentCount: data.data[0]['Count_Of_Payment']
                    })
                } else {
                    this.setState({ isLoading: false })
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // Get suummary list
    getApplicationSummaryList() {
        this.setState({ isLoading: true })
        this.props.getAdminApplicationSummaryList_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log("Admin Data ------->", data.data)
                    // data.data.length > 0 && data.data.map((dat) => (
                    //     dat.Total = dat.Applied + dat.Agent + dat.Waiting + dat.Incomplete
                    // ))
                    var temptotalAgent = 0
                    var temptotalApplied = 0
                    var temptotalAlloted = 0
                    var temptotalIncomplete = 0
                    var temptotalComplete = 0
                    var temptotalWaiting = 0
                    var temptotalTotal = 0
                    var obj = {}

                    for (var i = 0; i < data.data.length; i++) {
                        temptotalAgent = temptotalAgent + data.data[i].Agent
                        temptotalApplied = temptotalApplied + data.data[i].Applied
                        temptotalAlloted = temptotalAlloted + data.data[i].Alloted
                        temptotalIncomplete = temptotalIncomplete + data.data[i].Incomplete
                        temptotalComplete = temptotalComplete + data.data[i].Complete
                        temptotalWaiting = temptotalWaiting + data.data[i].Waiting
                        temptotalTotal = temptotalTotal + data.data[i].Total

                    }

                    obj.Agent = temptotalAgent
                    obj.Applied = temptotalApplied
                    obj.Alloted = temptotalAlloted
                    obj.Incomplete = temptotalIncomplete
                    obj.Complete = temptotalComplete
                    obj.Waiting = temptotalWaiting
                    obj.Total = temptotalTotal
                    obj.ChapterName = 'TOTAL'
                    if (data.data.length > 0 && data.data[data.data.length - 1].ChapterName != 'TOTAL') {
                        data.data.push(obj)
                    }
                    this.setState({
                        isLoading: false,
                        applicationSummaryList: data.data,
                        // pendingPaymentCount: data.data[0]['Count_Of_Payment']
                    })
                    console.log("Data ------->", this.state.applicationSummaryList)

                } else {
                    this.setState({ isLoading: false })
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    navToPaymentList() {
        this.props.navigate('/paymentlist')
    }

    onCountClick(chapterName, status) {
        console.log("Keyword------------>", `${chapterName.trim()}/${status}`)
        this.props.navigate('/exhibitorlist', { state: { fromDashBoard: true, keyword: `${chapterName.trim()}/${status}`, tab: "Application Summary" } })
    }


    record(list) {
        return (
            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
                <thead>
                    <tr>
                        <th className="text-center">Chapters</th>
                        <th className="text-center">Applied</th>
                        <th className="text-center">Agent</th>
                        <th className="text-center">Waiting</th>
                        <th className="text-center">Incomplete</th>
                        <th className="text-center">Complete</th>
                        <th className="text-center">Total Application</th>
                        <th className="text-center">Alloted</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.length > 0 ? <>{
                            list.map((data, i) => (
                                <tr key={Math.random()}>
                                    <td className="text-center">{i == list.length - 1 ? <strong>{data.ChapterName}</strong> : data.ChapterName}</td>
                                    <td className="text-center" style={{ cursor: "pointer" }}
                                        onClick={(e) => this.onCountClick(data.ChapterName, "Applied")}
                                    >{i == list.length - 1 ? <strong>{data.Applied}</strong> : data.Applied}</td>
                                    <td className="text-center" style={{ cursor: "pointer" }}
                                        onClick={(e) => this.onCountClick(data.ChapterName, "Agent")}
                                    >{i == list.length - 1 ? <strong>{data.Agent}</strong> : data.Agent}</td>
                                    <td className="text-center" style={{ cursor: "pointer" }}
                                        onClick={(e) => this.onCountClick(data.ChapterName, "Waiting")}
                                    >{i == list.length - 1 ? <strong>{data.Waiting}</strong> : data.Waiting}</td>
                                    <td className="text-center" style={{ cursor: "pointer" }}
                                        onClick={(e) => this.onCountClick(data.ChapterName, "Incomplete")}
                                    >{i == list.length - 1 ? <strong>{data.Incomplete}</strong> : data.Incomplete}</td>
                                    <td className="text-center" style={{ cursor: "pointer" }}
                                        onClick={(e) => this.onCountClick(data.ChapterName, "Complete")}
                                    >{i == list.length - 1 ? <strong>{data.Complete}</strong> : data.Complete}</td>
                                    <td className="text-center" style={{ cursor: "pointer" }}
                                        onClick={(e) => this.onCountClick(data.ChapterName, "Total")}
                                    >{i == list.length - 1 ? <strong>{data.Total}</strong> : data.Total}</td>
                                    <td className="text-center" style={{ cursor: "pointer" }}
                                     onClick={(e) => this.onCountClick(data.ChapterName, "Alloted")}
                                    >{i == list.length - 1 ? <strong>{data.Alloted}</strong> : data.Alloted}</td>
                                </tr>
                            ))}
                            {/* {list[list.length - 1].ChapterName != 'TOTAL' && <tr key={Math.random()}>
                                <td className="text-center"><strong>TOTAL</strong></td>
                                <td className="text-center"><strong>{this.state.totalApplied}</strong></td>
                                <td className="text-center"><strong>{this.state.totalAgent}</strong></td>
                                <td className="text-center"><strong>{this.state.totalWaiting}</strong></td>
                                <td className="text-center"><strong>{this.state.totalIncomplete}</strong></td>
                                <td className="text-center"><strong>{this.state.totalTotal}</strong></td>
                                <td className="text-center"><strong>{this.state.totalAlloted}</strong></td>
                            </tr>} */}
                        </> :
                            <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                    }
                </tbody>
            </table>)
    }

    render() {
        const { isLoading, pendingPaymentCount, applicationSummaryList } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <AdminDrawer />
                    {/*app-content open*/}
                    {/* <div className="app-content with-dr-container com-height"> */}
                    {/* <AdminDashboardTopmenu /> */}
                    <div className="row mt-3">
                        <div className="table-responsive mb-3">
                            {this.record(applicationSummaryList)}
                        </div>
                    </div>
                    {/* <div className='container-fluid'>
                            <div className="col-md-3 mt-5">
                                <div
                                    className="com-box com-box-1"
                                    style={{ borderBottom: "2px solid #ff9770", width: '400px' }}
                                >
                                    <h4>Today's Updates</h4>
                                    <div
                                        className="box-count"
                                        style={{
                                            height: '150px',
                                            padding: '25px',
                                            textAlign: "center",
                                            fontSize: '17px',
                                            fontWeight: '500',
                                            fontFamily: '"Poppins", sans-serif'
                                        }}
                                    >
                                        <p onClick={this.navToPaymentList}>Updated Payment Count <span style={{ float: 'right' }}>{pendingPaymentCount}</span></p>
                                        <p>New Application  <span style={{ float: 'right' }}>0</span></p>
                                        <p>Applications in Waiting  <span style={{ float: 'right' }}>0</span></p>

                                    </div>
                                </div>
                            </div>

                        </div> */}
                    {/* </div> */}
                </>

                {/* <Footerwithdr /> */}

            </>
        );
    }
}

export default withRouter(connect(null, { getAdminDashboardData_action, getAdminApplicationSummaryList_action })(ApplicationSummary));
