import { axiosGet, axiosPost } from "../../../../../react_axios/Axios";
import {
    ADD_FASCIA_FORM_ACTION_TYPE, addFasciaFormUrl,
    headers,
    getFasciaFormDetailsUrl, GET_FASCIA_FORM_ACTION_TYPE,
    updateFasciaFormUrl, UPDATE_FASCIA_FORM_ACTION_TYPE,
    GET_FASCIA_DEFAULT_ACTION_TYPE, getDefaultFasciaDataUrl, insertUpdateFasciaUrl, INSERT_UPDATE_FASCIA_ACTION_TYPE
} from "../../../../../utils/constants";


export const addFasciaForm_action = (fasciaName) => async (dispatch) => {
    try {
        const mapData = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhregistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
            FasciaName: fasciaName,
        }
        console.log("addfasciaForm_action Action------->", mapData)
        const res = await axiosPost(addFasciaFormUrl, mapData, headers)
        dispatch({
            type: ADD_FASCIA_FORM_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        Promise.reject(error)
    }
}

export const updateFasciaForm_action = (fasciaFormID, fasciaName) => async (dispatch) => {
    try {
        const mapData = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            FasciaFormID: 5, // need to change
            FasciaName: fasciaName,
        }
        console.log("addfasciaForm_action Action------->", mapData)
        const res = await axiosPost(updateFasciaFormUrl, mapData, headers)
        dispatch({
            type: UPDATE_FASCIA_FORM_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        Promise.reject(error)
    }
}

export const getFasciaDetails_action = (fasciaFormId) => async (dispatch) => {
    try {
        const param = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            FasciaFormID: 5 // need to change
        }
        const res = await axiosGet(getFasciaFormDetailsUrl, param)
        dispatch({
            type: GET_FASCIA_FORM_ACTION_TYPE,
            payload: res
        });
        return Promise.resolve(res)
    } catch (error) {
        Promise.reject(error)
    }

}

export const getDefaultFasciaData_action = () => async (dispatch) => {
    try {
        const param = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhregistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
        }
        const res = await axiosGet(getDefaultFasciaDataUrl, param)
        dispatch({
            type: GET_FASCIA_DEFAULT_ACTION_TYPE,
            payload: res
        });
        return Promise.resolve(res)
    } catch (error) {
        Promise.reject(error)
    }

}


export const insertUpdateFacia_action = (fasciaName, fasciaFormId) => async (dispatch) => {
    try {
        const mapData = {
            userId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            FasciaFormID: fasciaFormId != null && fasciaFormId != "" ? fasciaFormId : null,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhregistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
            FasciaName: fasciaName
        }
        console.log("insertUpdateFacia_action Action------->", mapData)
        const res = await axiosPost(insertUpdateFasciaUrl, mapData, headers)
        dispatch({
            type: INSERT_UPDATE_FASCIA_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        Promise.reject(error)
    }
}