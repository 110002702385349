import { GET_BRANDING_OPPORTUNITY_DETAILS_ACTION_TYPE, INSERT_UPDATE_BRANDING_OPPORTUNITY_ACTION_TYPE } from "../../../../../utils/constants";


const initialState = [];

function brandingOpportunityReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case GET_BRANDING_OPPORTUNITY_DETAILS_ACTION_TYPE:
            return [...state, payload];
        case INSERT_UPDATE_BRANDING_OPPORTUNITY_ACTION_TYPE:
            return [...state, payload];

        default:
            return state;
    }
};

export default brandingOpportunityReducer;