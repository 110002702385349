import { PRIMARY_CHAPTER_ADD_ACTION_TYPE} from "../../utils/constants"


const initialState = [];

function primaryChapterReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
   
      case PRIMARY_CHAPTER_ADD_ACTION_TYPE:
      return [...state, payload];
    default:
      return state;
  }
};

export default primaryChapterReducer;