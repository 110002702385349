import { axiosGet, axiosPost } from "../../../react_axios/Axios";
import { getAdminUserListUrl, GET_ADMIN_USER_LIST, addAdminUserUrl, updateAdminUserUrl, ADD_ADMIN_USER, UPDATE_ADMIN_USER, getAdminUserDetailsUrl, GET_ADMIN_USER_DETAILS, deleteAdminUserUrl, DELETE_ADMIN_USER, headers } from "../../../utils/constants";

export const getuserList_action = (ExhibitionID, RoleID, Name) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExhibitionID,
            RoleID: RoleID,
            Name: Name
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getAdminUserListUrl, params, headers)

        dispatch({
            type: GET_ADMIN_USER_LIST,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getuserDetails_action = (AccountID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem("sessionExhibitionID"),
            AccountID: AccountID
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getAdminUserDetailsUrl, params, headers)

        dispatch({
            type: GET_ADMIN_USER_DETAILS,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const deleteAdmin_action = (AccountID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem("sessionExhibitionID"),
            AccountID: AccountID
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(deleteAdminUserUrl, params, headers)

        dispatch({
            type: DELETE_ADMIN_USER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const addUser_action = (
    CompanyName,
    FasciaName,
    Address1,
    Address2,
    City,
    State,
    Pin,
    CountryID,
    Title_PersonIncharge,
    PersonIncharge,
    LastNamePersonIncharge,
    Designation,
    MobileNo,
    AlternateMobileNo,
    Telephone,
    Telephone1,
    TeleFax,
    PrimaryEmail,
    SecondaryEmail,
    Website,
    RoleTD,
    password,
    preMobileNo,
    preAlternateMobileNo,
    preTelephoneNo,
    preTelephoneNo1,
    //     Title_Name_Of_CEO_MD_President_Owner,
    //     Name_Of_CEO_MD_President_Owner,
    // LastName,
    // Mobile,
    // Email,
) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem("sessionExhibitionID"),
            CompanyName: CompanyName,
            FasciaName: CompanyName,
            Address1: Address1,
            Address2: Address2,
            City: City,
            State: State,
            Pin: Pin,
            CountryID: CountryID,
            Title_PersonIncharge: Title_PersonIncharge,
            PersonIncharge: PersonIncharge,
            LastNamePersonIncharge: LastNamePersonIncharge,
            Designation: Designation,
            MobileNo: MobileNo,
            AlternateMobileNo: AlternateMobileNo,
            Telephone: Telephone,
            Telephone1: Telephone1,
            TeleFax: TeleFax,
            PrimaryEmail: PrimaryEmail,
            SecondaryEmail: SecondaryEmail,
            Website: Website,
            RoleTD: RoleTD,
            password: password,
            preMobileNo: preMobileNo, preAlternateMobileNo: preAlternateMobileNo,
            preTelephoneNo: preTelephoneNo, preTelephoneNo1: preTelephoneNo1,// 
            // Title_Name_Of_CEO_MD_President_Owner:Title_Name_Of_CEO_MD_President_Owner,
            // Name_Of_CEO_MD_President_Owner:Name_Of_CEO_MD_President_Owner,
            // LastName:LastName,
            // Mobile:Mobile,
            // Email:Email,
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosPost(addAdminUserUrl, params, headers)

        dispatch({
            type: ADD_ADMIN_USER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const updateUser_action = (
    CompanyName,
    FasciaName,
    Address1,
    Address2,
    City,
    State,
    Pin,
    CountryID,
    Title_PersonIncharge,
    PersonIncharge,
    LastNamePersonIncharge,
    Designation,
    MobileNo,
    AlternateMobileNo,
    Telephone,
    Telephone1,
    TeleFax,
    PrimaryEmail,
    SecondaryEmail,
    Website,
    RoleTD,
    password,
    preMobileNo,
    preAlternateMobileNo,
    preTelephoneNo,
    preTelephoneNo1,
    //     Title_Name_Of_CEO_MD_President_Owner,
    //     Name_Of_CEO_MD_President_Owner,
    // LastName,
    // Mobile,
    // Email,
    AccountID
) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem("sessionExhibitionID"),
            CompanyName: CompanyName,
            FasciaName: CompanyName,
            Address1: Address1,
            Address2: Address2,
            City: City,
            State: State,
            Pin: Pin,
            CountryID: CountryID,
            Title_PersonIncharge: Title_PersonIncharge,
            PersonIncharge: PersonIncharge,
            LastNamePersonIncharge: LastNamePersonIncharge,
            Designation: Designation,
            MobileNo: MobileNo,
            AlternateMobileNo: AlternateMobileNo,
            Telephone: Telephone,
            Telephone1: Telephone1,
            TeleFax: TeleFax,
            PrimaryEmail: PrimaryEmail,
            SecondaryEmail: SecondaryEmail,
            Website: Website,
            RoleTD: RoleTD,
            password: password,
            preMobileNo: preMobileNo, preAlternateMobileNo: preAlternateMobileNo,
            preTelephoneNo: preTelephoneNo, preTelephoneNo1: preTelephoneNo1,// Title_Name_Of_CEO_MD_President_Owner:Title_Name_Of_CEO_MD_President_Owner,
            // Name_Of_CEO_MD_President_Owner:Name_Of_CEO_MD_President_Owner,
            // LastName:LastName,
            // Mobile:Mobile,
            // Email:Email,
            AccountID: AccountID,
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosPost(updateAdminUserUrl, params, headers)

        dispatch({
            type: UPDATE_ADMIN_USER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}