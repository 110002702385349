import { GET_EXH_CIRCULAR_LIST_ACTION_TYPE, GET_EXH_VISITOR_CIRCULAR_LIST_ACTION_TYPE } from "../../../utils/constants";


const initialState = [];

function exhCircularReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case GET_EXH_CIRCULAR_LIST_ACTION_TYPE:
            return [...state, payload];
        case GET_EXH_VISITOR_CIRCULAR_LIST_ACTION_TYPE:
            return [...state, payload];

        default:
            return state;
    }
};

export default exhCircularReducer