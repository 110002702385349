import { axiosGet, axiosPost } from "../../../../../react_axios/Axios";
import { INSERT_AND_UPDATE_CATERING_SERVICES_ACTION_TYPE, GET_CATERING_SERVICES_DETAILS_ACTION_TYPE, headers, insertUpdateCateringServicesUrl, getCateringServicesDetailsUrl } from "../../../../../utils/constants";



export const insertUpdateCateringServices_action = (IsIntrested, Remark, Is_Agree) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhRegistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
            IsIntrested: IsIntrested,
            Remark: Remark,
            Is_Agree: Is_Agree,
        }
        const res = await axiosPost(insertUpdateCateringServicesUrl, params, headers)

        dispatch({
            type: INSERT_AND_UPDATE_CATERING_SERVICES_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getCateringServicesDetails_action = () => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhRegistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
        }
        const res = await axiosGet(getCateringServicesDetailsUrl, params, headers)

        dispatch({
            type: GET_CATERING_SERVICES_DETAILS_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}
