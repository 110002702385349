import { GET_POWER_CONNECTION_APPROVAL_DETAILS_ACTION_TYPE, GET_POWER_CONNECTION_APPROVAL_LIST_ACTION_TYPE, GET_POWER_CONNECTION_EXPORT_ACTION_TYPE, UPDATE_POWER_APPROVAL_ACTION_TYPE } from "../../../../utils/constants";



const initialState = [];

function power_approvalReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_POWER_CONNECTION_APPROVAL_DETAILS_ACTION_TYPE:
            return [...state, payload];
        case GET_POWER_CONNECTION_APPROVAL_LIST_ACTION_TYPE:
            return [...state, payload];
        case GET_POWER_CONNECTION_EXPORT_ACTION_TYPE:
            return [...state, payload];
        case UPDATE_POWER_APPROVAL_ACTION_TYPE:
            return [...state, payload];


        default:
            return state;
    }
};

export default power_approvalReducer;