import { axiosGet, axiosPost } from "../../../../../react_axios/Axios";
import {
    headers, platform,
    VISA_APPLICATION_LIST_ACTION_TYPE, ADD_VISA_APPLICATION_ACTION_TYPE,
    GET_VISA_APPLICATION_DETAILS_ACTION_TYPE, UPDATE_VISA_APPLICATION_ACTION_TYPE,
    DELETE_VISA_APPLICATION_ACTION_TYPE,
    getVisaApplicationListUrl, addVisaApplicationUrl,
    getVisaApplicationDetailsUrl, updateVisaApplicationUrl,
    deleteVisaApplicationUrl, deleteVisaFilePathUrl, DELETE_VISA_DOC_ACTION_TYPE, submitVisaApplicationUrl, SUBMIT_VISA_APPLICATION_ACTION_TYPE
} from "../../../../../utils/constants";

export const getVisaApplication_action = () => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhRegistrationID: sessionStorage.getItem('ExhRegistrationIDSS')
        }
        console.log("getVisaApplication_action Action------->", params)
        const res = await axiosGet(getVisaApplicationListUrl, params, headers)

        dispatch({
            type: VISA_APPLICATION_LIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const addVisaApplication_action = (visaApplicationList, passportFrontImageList, passportFrontImageTypeList) => async (dispatch) => {
    try {
        const mapData = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhRegistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
            visaApplicationList: visaApplicationList,
            ExhRegistrationNo: sessionStorage.getItem('ExhRegistrationNo'),
            passportFrontImageList: passportFrontImageList,
            passportFrontImageTypeList: passportFrontImageTypeList,
        }
        console.log("addVisaApplication_action------->", mapData)
        const res = await axiosPost(addVisaApplicationUrl, mapData, headers)

        dispatch({
            type: ADD_VISA_APPLICATION_ACTION_TYPE,
            payload: res
        })
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getVisaApplicationDetails_action = (id) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            VisaApplicationFormID: id,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhregistrationID: sessionStorage.getItem('ExhRegistrationIDSS')
        }
        console.log("getVisaApplicationDetails_action Action------->", params)
        const res = await axiosGet(getVisaApplicationDetailsUrl, params, headers)

        dispatch({
            type: GET_VISA_APPLICATION_DETAILS_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const updateVisaApplication_action = (VisaApplicationFormID, firstName, middleName, lastName, email, countryId, mobile, idTypeId,
    passportNo, passportFrontImage, passportFrontImageType, passportIssueDate, passportExpiryDate) => async (dispatch) => {
        try {
            const mapData = {
                UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
                ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
                ExhRegistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
                ExhRegistrationNo: sessionStorage.getItem('ExhRegistrationNo'),
                VisaApplicationFormID: VisaApplicationFormID,
                FirstName: firstName,
                MiddleName: middleName,
                LastName: lastName,
                Email: email,
                countryid: countryId,
                MobileNo: mobile,
                FormDocumentTypeID: idTypeId,
                PassportNo: passportNo,
                passportFrontImage: passportFrontImage,
                passportFrontImageType: passportFrontImageType,
                Passportissuedate: passportIssueDate,
                Passportexpirydate: passportExpiryDate,

            }
            console.log("updateVisaApplication_action------->", mapData)
            const res = await axiosPost(updateVisaApplicationUrl, mapData, headers)

            dispatch({
                type: UPDATE_VISA_APPLICATION_ACTION_TYPE,
                payload: res
            })
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }

export const deleteVisaApplication_action = (id) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            VisaApplicationFormID: id,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhregistrationID: sessionStorage.getItem('ExhRegistrationIDSS')
        }
        console.log("deleteVisaApplication_action Action------->", params)
        const res = await axiosGet(deleteVisaApplicationUrl, params, headers)

        dispatch({
            type: DELETE_VISA_APPLICATION_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const deleteVisaApplicationDoc_action = (id) => async (dispatch) => {

    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhRegistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
            VisaApplicationFormID: id
        }
        console.log("Delete delete visa Document Action---------------->", params)
        const res = await axiosGet(deleteVisaFilePathUrl, params, headers)
        dispatch({
            type: DELETE_VISA_DOC_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const submitVisaApplication_action = () => async (dispatch) => {
    try {
        const mapData = {
            userID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhregistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
        }
        console.log("submitVisaApplication_action------->", mapData)
        const res = await axiosPost(submitVisaApplicationUrl, mapData, headers)

        dispatch({
            type: SUBMIT_VISA_APPLICATION_ACTION_TYPE,
            payload: res
        })
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}