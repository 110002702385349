import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { getCateringServicesMasterList_action, insertUpdateCateringServicesMaster_action, deleteCateringServicesMaster_action } from '../../../../../actions/admin_module_action/master_action/manual_form_master_action/catering_services_master_action'
import { fill_action } from '../../../../../actions/fillCombo_action'
import { BASE_URL, countryActionId, toastError, toastSuccess, visaIdTypeActionId } from "../../../../../utils/constants";
import { emailValidator, mobileNoValidator } from "../../../../../utils/Validator";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import { data } from "jquery";
import Resizer from "react-image-file-resizer";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";
import Passport_Front_1008_VIS_903747 from "../../../../../files/Passport_Front_1008_VIS_903747.jpeg"

class CateringServicesMasterAU extends Component {

    // initialize constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            // fields
            Name: '',
            Address: '',
            Phone: '',
            Mobile: '',
            Fax: '',
            PrimaryEmail: '',
            SecondaryEmail: '',
            companyName: '',
            img: '',
            index: 1,
            imgType: '',
            imgPath: '',
            // List
            CateringServicesList: [],
            NameErr: "",
            AddressErr: "",
            MobileErr: '',
            PrimaryEmailErr: '',
            SecondaryEmailErr: '',
            companyNameErr: '',
            imgErr: '',
        }
        this.OnCancel = this.OnCancel.bind(this)
        this.inputValidate = this.inputValidate.bind(this)
        // this.getFillList = this.getFillList.bind(this)
        this.renderCateringServicesList = this.renderCateringServicesList.bind(this)
        // this.onFilePick = this.onFilePick.bind(this)
        // this.onPdfSelect = this.onPdfSelect.bind(this)
        // this.resizeFile = this.resizeFile.bind(this)
        this.handleAddMore = this.handleAddMore.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.ClearValue = this.ClearValue.bind(this)
        // this.handleUpdate = this.handleUpdate.bind(this)
        // this.getDetails = this.getDetails.bind(this)
        // this.emailValidation = this.emailValidation.bind(this)
        // this.onCrossClick = this.onCrossClick.bind(this)
        // this.mobileValidation = this.mobileValidation.bind(this)
        // this.onDocDelete = this.onDocDelete.bind(this)
        // this.handleDelete = this.handleDelete.bind(this)
    }

    componentDidMount() {
        this.getCateringServicesMasterList()
    }

    // On cancel Click
    OnCancel() {
        this.props.navigate('/cateringServicesMasterList')
    }
    getCateringServicesMasterList(status) {
        this.setState({ isLoading: true })
        this.props.getCateringServicesMasterList_action()
            .then((data) => {
                if (data.error != 1) {
                    data.data.length > 0 && data.data.map((item) => {
                        item.isNew = false
                        var filePath = item['CateringImage']
                        var array = item['CateringImage'] != null && item['CateringImage'] != "" && filePath.split("/")
                        var FileName = array[array.length - 1]
                        item.img = FileName
                        item.imgPath = filePath
                        item.imgType = null
                    })
                    data.data.length > 0 && data.data.map(item => item.isShow = false)
                    console.log(data.data)
                    this.setState({ CateringServicesList: data.data })
                    this.setState({ isLoading: false })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // Input Fields Validation
    inputValidate() {
        const { Name, Address, Phone, Mobile, Fax, PrimaryEmail, SecondaryEmail, companyName, img, imgType, imgPath, CateringServicesList, NameErr, AddressErr, MobileErr, PrimaryEmailErr, SecondaryEmailErr, companyNameErr, imgErr } = this.state
        if (Name == "") {
            this.setState({ NameErr: "Please Enter Name" })
        }
        if (Address == "") {
            this.setState({ AddressErr: "Please Enter Address" })
        }
        if (Mobile == "") {
            this.setState({ MobileErr: "Please Enter Mobile No." })
        }
        if (companyName == "") {
            this.setState({ companyNameErr: "Please Enter Company Name" })
        }
        if (img == "") {
            this.setState({ imgErr: "Please Select Image" })
        }
        if (PrimaryEmail == "") {
            this.setState({ PrimaryEmailErr: "Please Enter Primary Email" })
        } else {
            var PEmailerr = (emailValidator(PrimaryEmail) == '') ? false : emailValidator(PrimaryEmail)
            if (PEmailerr) {
                this.setState({ PrimaryEmailErr: "Please Enter Proper Primary Email" })
            }
        }
        if (SecondaryEmail != "" && SecondaryEmail != null) {
            var SEmailerr = (emailValidator(SecondaryEmail) == '') ? false : emailValidator(SecondaryEmail)
            if (SEmailerr) {
                this.setState({ SecondaryEmailErr: "Please Enter Proper Secondary Email" })
            }
        }
    }

    renderCateringServicesList() {
        const { CateringServicesList } = this.state
        return (
            <ul class="list-group " style={{ position: 'relative', zIndex: 1 }}>
                {
                    CateringServicesList.length > 0 && CateringServicesList.map((data, index) => (
                        data.isShow &&
                        <li class="list-group-item" key={Math.random()}>
                            <div className="added-more p-3 rounded-2">
                                <h5 style={{ color: "#0056ff" }}>Added Catering Services Master {data.index}</h5>
                                <h6 >Contact Details</h6>
                                <div className="row mt-4">
                                    <div className="col-md-3">
                                        <h6>Name: {data.Name}</h6>
                                    </div>
                                    <div className="col-md-3">
                                        <h6>Mobile no.: {data.Mobile}</h6>
                                    </div>
                                    <div className="col-md-6">
                                        <h6>Address: {data.Address}</h6>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <h6>Telephone: {data.Phone}</h6>
                                    </div>
                                    <div className="col-md-3">
                                        <h6>Fax: {data.Fax}</h6>
                                    </div>
                                    <div className="col-md-3">
                                        <h6>Primary Email: {data.PrimaryEmail}</h6>
                                    </div>
                                    <div className="col-md-3">
                                        <h6>Secondary Email: {data.SecondaryEmail}</h6>
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-md-3">
                                        <h6>Company Name: {data.companyName}</h6>
                                    </div>
                                    <div className="col-md-3">
                                        <h6>Image:</h6>
                                        <img
                                            src={data.img}
                                            style={{ width: '200px', height: '80px', display: 'block' }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {console.log(data)}
                        </li>
                    ))
                }
            </ul>
        )
    }

    handleAddMore() {
        const { Name, Address, Phone, Mobile, Fax, index, PrimaryEmail, SecondaryEmail, companyName, img, imgType, imgPath, CateringServicesList, NameErr, AddressErr, MobileErr, PrimaryEmailErr, SecondaryEmailErr, companyNameErr, imgErr } = this.state
        this.inputValidate()
        // if (companyName != '' && img != '' && Name != '' && Address != "" && Mobile != "" && PrimaryEmail != "") {
        //     if (NameErr == '' && companyNameErr == '' && imgErr == '' && AddressErr == '' && MobileErr == '' && PrimaryEmailErr == '' && SecondaryEmailErr == '') {
        CateringServicesList.push({
            'Name': Name,
            'Address': Address,
            'Phone': Phone,
            'Mobile': Mobile,
            'Fax': Fax,
            'PrimaryEmail': PrimaryEmail,
            'SecondaryEmail': SecondaryEmail,
            'companyName': companyName,
            'img': img,
            "imgType": imgType,
            'imgPath': imgPath,
            'CateringServicesContactPersonID': null,
            'index': index,
            'isShow': true,
        })
        setTimeout(() => { this.ClearValue() }, 200);
        //     }
        //     else {
        //         toastError('Please enter proper fields')
        //     }
        // }
        // else {
        //     toastError('Please enter mandatory fields')
        // }
        console.log(this.state.CateringServicesList)
    }

    handleSave() {
        const { CateringServicesList } = this.state
        this.setState({ isLoading: true })
        this.props.insertUpdateCateringServicesMaster_action(CateringServicesList)
            .then((data) => {
                if (data.error != 1) {
                    toastSuccess("Record Added Successfully")
                    setTimeout(() => {
                        this.setState({ isLoading: false })
                        this.props.navigate('/CateringServicesMasterList')
                    }, 300);
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    ClearValue() {
        this.setState({
            Name: '',
            Address: '',
            Phone: '',
            Mobile: '',
            Fax: '',
            PrimaryEmail: '',
            SecondaryEmail: '',
            companyName: '',
            img: '',
            imgType: '',
            imgPath: '',
            index: this.state.index + 1
        })
        document.getElementById(`path`).value = ""
    }

    onFilePick = async (e, file) => {
        e.preventDefault()
        const { CateringServicesList, img } = this.state
        console.log('file', file)
        if (file.length != 0) {
            if (file[0]['size'] >= 5 * 1024 * 1024) {
                toastError('File size more than 5mb are not allow.')
            } else {
                var image = '';
                if (file[0]['name'].toString().toLowerCase().includes('.jpeg') ||
                    file[0]['name'].toString().toLowerCase().includes('.png') ||
                    file[0]['name'].toString().toLowerCase().includes('.jpg')
                ) {
                    if (file[0]['name'].toString().toLowerCase().trim().includes('.jpg') ||
                        file[0]['name'].toString().toLowerCase().trim().includes('.png') ||
                        file[0]['name'].toString().toLowerCase().trim().includes('.jpeg')
                    ) {
                        image = await this.resizeFile(file[0]);
                        console.log(image)
                        this.setState({ img: image, imgErr: '' })
                    }
                    var fileType = file[0]['type']
                    var fileTypeList;
                    if (fileType.includes("/")) {
                        fileTypeList = fileType.split("/")

                    }
                    this.setState({ imgType: fileTypeList.length > 0 ? fileTypeList[1] : "" })
                } else {
                    toastError('Only .jpeg, .png, .jpg files are allowed')
                }
            }
        }
    }

    onPdfSelect = (file) =>
        new Promise((resolve) => {
            let base64;
            // FileReader function for read the file.
            let fileReader = new FileReader();
            // Onload of file read the file content
            fileReader.onload = function (fileLoadedEvent) {
                base64 = fileLoadedEvent.target.result;
                // Print data in console
                resolve(base64);
            };
            // Convert data to base64
            fileReader.readAsDataURL(file);
        });

    resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                500,
                600,
                'JPEG',
                80,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64",
                300,
                300,
            );
        });
    onCrossClick() {
        const { } = this.state
        this.setState({ img: '', imgType: '' })
        document.getElementById(`path`).value = ""
    }
    render() {
        const { Name, Address, Phone, Mobile, Fax, PrimaryEmail, SecondaryEmail, img, companyName, imgType, imgPath, CateringServicesList, NameErr, AddressErr, MobileErr, PrimaryEmailErr, SecondaryEmailErr, companyNameErr, imgErr, isLoading } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <div className="app-content with-dr-container com-height">
                        <AdminDrawer />
                        <div className="row">
                            <div className="step-name">
                                <p>Catering Services Master</p>
                            </div>
                        </div>
                        <div className="m-2">
                            <div className="">

                                <div className="">
                                    <div
                                        className="card-header p-3"
                                        style={{ paddingLeft: "7px !important" }}
                                    >
                                        <h3 className="card-title" style={{ fontSize: 15 }}>
                                            Agency contact
                                        </h3>
                                    </div>
                                    <div className="card-body p-2 mt-1">
                                        <div className="row">
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    Name<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={Name}
                                                    onChange={(e) => this.setState({ Name: e.target.value, NameErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{NameErr}</span>}

                                            </div>
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    Mobile No.<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={Mobile}
                                                    onChange={(e) => this.setState({ Mobile: e.target.value, MobileErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{MobileErr}</span>}

                                            </div>
                                            <div className="col-xl-6">
                                                <label htmlFor="validationCustom03">
                                                    Address<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={Address}
                                                    onChange={(e) => this.setState({ Address: e.target.value, AddressErr: '' })}
                                                />

                                                {<span className='text-danger text-start text-left d-block mt-1'>{AddressErr}</span>}
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    Telephone
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={Phone}
                                                    onChange={(e) => this.setState({ Phone: e.target.value, })}
                                                />
                                            </div>
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    Fax
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={Fax}
                                                    onChange={(e) => this.setState({ Fax: e.target.value, })}
                                                />
                                            </div>
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">Primary Email<span className="text-red">*</span></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={PrimaryEmail}
                                                    onChange={(e) => this.setState({ PrimaryEmail: e.target.value, PrimaryEmailErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{PrimaryEmailErr}</span>}
                                            </div>
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">Secondary Email</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={SecondaryEmail}
                                                    onChange={(e) => this.setState({ SecondaryEmail: e.target.value, SecondaryEmailErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{SecondaryEmailErr}</span>}
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-xl-3">
                                                    <label htmlFor="validationCustom03">Company Name<span className="text-red">*</span></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom03"
                                                        required=""
                                                        value={companyName}
                                                        onChange={(e) => this.setState({ companyName: e.target.value, companyNameErr: '' })}
                                                    />
                                                    {<span className='text-danger text-start text-left d-block mt-1'>{companyNameErr}</span>}
                                                </div>
                                                <div className="col-xl-3">
                                                    <label htmlFor="validationCustom03">Image<span className="text-red">*</span></label>
                                                    <div className="box">
                                                        <div className="js--image-preview" />
                                                        {
                                                            <div className="upload-options mt-1 ms-2">
                                                                <label>
                                                                    <input
                                                                        type="file"
                                                                        id={`path`}
                                                                        className="image-upload"
                                                                        accept=".jpg, .jpeg, .png"
                                                                        onChange={e => this.onFilePick(e, e.target.files,)}
                                                                    />
                                                                    {
                                                                        img != "" &&
                                                                        <i class="fa fa-times text-danger img-can"
                                                                            onClick={() => this.onCrossClick()}></i>
                                                                    }
                                                                </label>

                                                            </div>
                                                        }
                                                    </div>
                                                    {<span className='text-danger text-start text-left d-block mt-1'>{imgErr}</span>}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="btn-group mt-2 save-can d-block">
                                    <div className="btn-group mt-2" role="group" aria-label="Basic example">
                                        <button
                                            style={{ marginRight: 10 }}
                                            type="button"
                                            className="btn btn-primary rounded-0 d-block"
                                            // style={{ }}
                                            onClick={() => this.handleAddMore()}
                                        >
                                            {this.state.index == 1?'Add':'Add More'}
                                        </button>
                                        {/* <button type="button" class="btn btn-primary rounded-0">Payment Listing Page</button> */}
                                    </div>

                                    <hr />
                                    {this.renderCateringServicesList()}

                                    <div className="row">
                                        <div className="btn-group mt-2 save-can d-block" role="group" style={{ float: 'right' }} aria-label="Basic example">
                                            <button
                                                type="button"
                                                className="btn btn-success rounded-0"
                                                style={{ marginRight: '10px', width: '100px' }}
                                                onClick={() => this.handleSave()}
                                                disabled={this.state.index == 1}
                                            >
                                                Save
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-warning rounded-0"
                                                style={{ marginRight: '10px', width: '100px' }}
                                                onClick={() => this.OnCancel()}
                                            >
                                                Back
                                            </button>

                                            {/* <button type="button" class="btn btn-primary rounded-0">Payment Listing Page</button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </>
            </>
        )
    }
}
export default withRouter(connect(null, { getCateringServicesMasterList_action, insertUpdateCateringServicesMaster_action, deleteCateringServicesMaster_action, fill_action })(CateringServicesMasterAU))