import { connect } from "react-redux";
import { withRouter } from "../../../utils/withRouter";
import ExhibitorTopMenu from "../../../common_components/exhibitor_module_topmenu";
import { ToastContainer } from "react-toastify";
import ExhibitorHeader from "../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../common_components/exhibitor_module_drawer";
import Loader from "../../../utils/Loader";
import { getExhibitorCircularList_action, getVisitorCircularList_action } from "../../../actions/exhibitor_module_actions/exhibitor_circular_actions/exhibitor_circular_action";
import { toastError } from "../../../utils/constants";
import { data } from "jquery";
import moment from "moment";
import VisitorHeader2 from "../../../common_components/visitor_module_header2";
import VisitorDrawer from "../../../common_components/visitor_module_drawer";
import VisitorTopMenu from "../../../common_components/visitor_module_topmenu";
import { getVisitorRegistrationCredentials_action, getCoordinatorVisitor_action } from "../../../actions/visitor_module_action/visitor_dashboard_action/visitor_dashboard_action";
const { Component } = require("react");

class ExhibitorCircularList extends Component {

    // Intialize Constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            circularList: [],
            selectedVisitorID: '',
            isVisitorSelect: false,
            isCoordinator: false,
            CoordinatorID: '',
            coordinatorExhVisitorID: '',
            visitorList: [],
            coordinatorFN: '',
            coordinatorLN: '',
        }
        this.getCircularList = this.getCircularList.bind(this)
        this.getVisitorCredentials = this.getVisitorCredentials.bind(this)
        this.onVisitorSelect = this.onVisitorSelect.bind(this)
        this.getVisitorCircularList = this.getVisitorCircularList.bind(this)
    }

    getCoordinatorVisitor() {
        this.setState({ isLoading: true })
        return new Promise((resolve => {
            this.props.getCoordinatorVisitor_action(this.state.CoordinatorID)
                .then((data) => {
                    if (data.error != 1) {
                        console.log("Get getCoordinatorVisitor_action", data.data[0])
                        // var obj = {}
                        // obj.AccountID = this.state.CoordinatorID
                        // obj.ExhVisitorID = this.state.coordinatorExhVisitorID
                        // obj.PersonIncharge = this.state.coordinatorFN
                        // obj.LastNamePersonIncharge = this.state.coordinatorLN
                        // data.data.unshift(obj)
                        // if (data.data[0].isCoordinator) {
                        data.data.map(item => {
                            if (item.AccountID == this.state.CoordinatorID) {
                                this.setState({ selectedVisitorID: item.AccountID })
                            }
                        })
                        // sessionStorage.setItem('VisitorDataSS', JSON.stringify(data.data[0]))
                        // if (data.data[0].isCoordinator) {
                        this.setState({ visitorList: data.data })
                        // }
                        // this.setState({ isLoading: false })
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                }).catch((err) => {
                    console.log(err)
                })
        }))
    }

    // On componet Load
    componentDidMount() {
        // this.setState({
        //     isVisitorSelect: this.props.location.state != null && this.props.location.state.isVisitorSelect != undefined && this.props.location.state.isVisitorSelect ? true : false
        // }, () => {

        this.props.location.state != null && this.props.location.state.userFlag && this.props.location.state.userFlag == 'Exhibitor' ?

            this.getCircularList() : (this.getVisitorCredentials(0).then(() => {
                this.getCoordinatorVisitor()
                setTimeout(() => {
                    this.getVisitorCircularList()
                }, 500);
            }))
        // this.getCircularList()
        // })
    }

    // Get Exhibitor Circular List
    getCircularList() {
        this.setState({ isLoading: true })
        this.props.getExhibitorCircularList_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log("getCircularList Data", data.data)
                    this.setState({
                        circularList: data.data,
                        isLoading: false,
                    })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    getVisitorCredentials(status) {
        this.setState({ isLoading: true })
        return new Promise((resolve => {
            this.props.getVisitorRegistrationCredentials_action(status == 0 ? true : false, this.state.selectedVisitorID)
                .then((data) => {
                    if (data.error != 1) {
                        console.log("Get getVisitorCredentials", data.data[0])
                        status == 0 && sessionStorage.setItem('VisitorDataSS', JSON.stringify(data.data[0]))
                        status == 1 && sessionStorage.setItem('SelectedVisitorDataSS', JSON.stringify(data.data[0]))
                        if (status == 0 && data.data[0].isCoordinator) {
                            this.setState({ coordinatorFN: JSON.parse(sessionStorage.getItem('loginDataSS')).FirstName, coordinatorLN: JSON.parse(sessionStorage.getItem('loginDataSS')).LastName })
                            this.setState({ isCoordinator: true, CoordinatorID: data.data[0].ExhVisitorID, coordinatorExhVisitorID: data.data[0].ExhVisitorID })
                        }
                        setTimeout(() => {
                            resolve(true)
                        }, 200);
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                }).catch((err) => {
                    console.log(err)
                })
        }))
    }

    // Get Exhibitor Circular List
    getVisitorCircularList() {
        this.setState({ isLoading: true })
        this.props.getVisitorCircularList_action(this.state.isVisitorSelect)
            .then((data) => {
                if (data.error != 1) {
                    console.log("getCircularList Data", data.data)
                    this.setState({
                        circularList: data.data,
                        isLoading: false,
                    })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    onVisitorSelect(e) {
        const { isLoading, isCoordinator, visitorList, isVisitorSelect, selectedVisitorID } = this.state
        this.setState({ selectedVisitorID: e.target.value, isVisitorSelect: true }, () => {
            this.getVisitorCredentials(1).then(() => {
                // Calling Get Visor Details
                setTimeout(() => {
                    this.getVisitorCircularList()
                }, 500);
                // this.setState({ isLoading: false })
            })
        })
    }

    render() {
        const { isLoading, circularList, isCoordinator, visitorList, selectedVisitorID } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                {
                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                        <ExhibitorHeader /> : <VisitorHeader2 />
                }
                {
                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                        <ExhibitorDrawer /> : <VisitorDrawer />
                }
                <div className="main-content with-dr-container">
                    <div className="">
                        <div className="">
                            {/* <div class="step-name">
                  <p>Dashboard</p>
              </div> */}
                            {
                                JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                                    <ExhibitorTopMenu /> : <VisitorTopMenu />
                            }
                        </div>
                        <div className="row">
                            <div className="col-xl-12 p-0">
                                <div className="card rounded-0 pt-1">
                                    <div className="card-header p-3">
                                        <h3 className="card-title">Circulars</h3>
                                    </div>
                                    {isCoordinator && <div className='col-md-4 mt-2 mb-2 ms-2'>
                                        {/* <div className="country-box"> */}
                                        <div className=" mb-0">
                                            <label className=''>Visitor List</label>
                                            <select className="form-control select2 select-hidden-accessible pl-0"
                                                style={{ width: "100%" }}
                                                value={selectedVisitorID}
                                                onChange={e => this.onVisitorSelect(e)}
                                            >
                                                {/* <option>Select </option> */}
                                                {
                                                    visitorList.length > 0 && visitorList.map((item) => (
                                                        <option key={item.AccountID} value={item.AccountID}>{item.PersonIncharge} {item.LastNamePersonIncharge}</option>
                                                    ))
                                                }
                                            </select>

                                        </div>
                                        {/* </div> */}
                                    </div>}
                                    <div className="card-body p-4">
                                        <div className="table-responsive">
                                            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 ">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center" style={{ width: 60 }}>
                                                            Sr No
                                                        </th>
                                                        <th>Circulars Date</th>
                                                        <th>Subject</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        circularList.length > 0 ? circularList.map((data, index) => (
                                                            <tr key={Math.random()}>
                                                                <td className="text-center">{index + 1}</td>
                                                                <td>{moment(data.CircularDate).utc().format('DD-MM-YYYY')}</td>
                                                                <td>
                                                                    <a
                                                                        className="text-primary"
                                                                        href={data.Attachment}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                    >
                                                                        {data.subject}
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        )) : <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}

export default withRouter(connect(null, { getExhibitorCircularList_action, getVisitorCircularList_action, getCoordinatorVisitor_action, getVisitorRegistrationCredentials_action })(ExhibitorCircularList))