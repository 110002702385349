import { axiosGet } from "../../../react_axios/Axios"
import { headers, getExhibitionTimelineUrl,GET_TIMELINE_ACTION_TYPE } from "../../../utils/constants"



export const getExhibitionTimeline_action = () => async (dispatch) => {

    try {
        const params = {
            ExhibitionID : sessionStorage.getItem('sessionExhibitionID') ,
        }
        console.log("getExhibitionTimeline_action Action------->", params)

        const res = await axiosGet(getExhibitionTimelineUrl, params, headers)

        dispatch({
            type: GET_TIMELINE_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}