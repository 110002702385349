import { axiosGet, axiosPost } from "../../../../../react_axios/Axios";
import { INSERT_AND_UPDATE_EXHIBITOR_CONTRACTOR_BADGE_APPLICATION_ACTION_TYPE, GET_EXHIBITOR_CONTRACTOR_BADGE_APPLICATION_DETAILS_ACTION_TYPE, headers, insertUpdateExhibitorContractorBadgeApplicationUrl, getExhibitorContractorBadgeApplicationDetailsUrl } from "../../../../../utils/constants";



export const insertUpdateExhibitorContractorBadgeApplication_action = (CompanyName, PersonName, Address, Email, Mobile, LandlineNo, SetUpDayPasses, DismantleDayPasses, Remark, IsAgree, ExhContractorBadgeID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhRegistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
            CompanyName: CompanyName,
            PersonName: PersonName,
            Address: Address,
            Email: Email,
            Mobile: Mobile,
            LandlineNo: LandlineNo != '' ? LandlineNo : null,
            SetUpDayPasses: SetUpDayPasses,
            DismantleDayPasses: DismantleDayPasses,
            Remark: Remark,
            IsAgree: IsAgree,
            ExhContractorBadgeID: ExhContractorBadgeID != '' ? ExhContractorBadgeID : null,
        }
        const res = await axiosPost(insertUpdateExhibitorContractorBadgeApplicationUrl, params, headers)

        dispatch({
            type: INSERT_AND_UPDATE_EXHIBITOR_CONTRACTOR_BADGE_APPLICATION_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getExhibitorContractorBadgeApplicationDetails_action = (CarPassesID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhRegistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
        }
        const res = await axiosGet(getExhibitorContractorBadgeApplicationDetailsUrl, params, headers)

        dispatch({
            type: GET_EXHIBITOR_CONTRACTOR_BADGE_APPLICATION_DETAILS_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}