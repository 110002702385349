import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../../utils/withRouter';
import Loader from '../../../../utils/Loader'
// import { clearSessionStorage } from '../../../utils/constants'
import Header from '../../../../common_components/Header';
// import { emailValidator } from '../../../utils/Validator'
import Modal from "react-modal";
import Footerwithdr from '../../../../common_components/Footerwithdr';
import HeaderWithLogo from '../../../../common_components/header_with_logo';
import AdminDrawer from '../../../../common_components/admin_module_drawer';
import { fill_action } from '../../../../actions/fillCombo_action'
// import ReceiptList from '../registration_receipt/ReceiptList';
import { Link } from "react-router-dom";
import { getAdminDashboardData_action } from "../../../../actions/admin_module_action/admin_dasboard_action/admin_dashboard_action"
import { data } from 'jquery';
import { getAdminPaymentSummaryList_action } from "../../../../actions/admin_module_action/admin_dasboard_action/admin_dashboard_summary_action/admin_dashboard_summary_action"

class PaymentSummary extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            paymentSummaryList: [],
        }
        this.record = this.record.bind(this)
        this.getPaymentSummaryList = this.getPaymentSummaryList.bind(this)
        this.onCountClick = this.onCountClick.bind(this)

    }

    componentDidMount() {
        this.getPaymentSummaryList()
    }

    // Get suummary list
    getPaymentSummaryList() {
        this.setState({ isLoading: true })
        this.props.getAdminPaymentSummaryList_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log("Admin Data ------->", data.data)
                    // data.data.length > 0 && data.data.map((dat) => (
                    //     dat.Total = dat.Applied + dat.Agent + dat.Waiting + dat.Incomplete
                    // ))
                    var temptotalArea100 = 0
                    var temptotalArea25 = 0
                    var temptotalExhibitor100 = 0
                    var temptototalExhibitor25 = 0
                    var obj = {}

                    for (var i = 0; i < data.data.length; i++) {
                        temptotalArea100 = temptotalArea100 + data.data[i]['AREA_100%']
                        temptotalArea25 = temptotalArea25 + data.data[i]['AREA_25%']
                        temptotalExhibitor100 = temptotalExhibitor100 + data.data[i]['EXHIBITORS_100%']
                        temptototalExhibitor25 = temptototalExhibitor25 + data.data[i]['EXHIBITORS_25%']

                    }

                    obj['AREA_100%'] = temptotalArea100
                    obj['AREA_25%'] = temptotalArea25
                    obj['EXHIBITORS_100%'] = temptotalExhibitor100
                    obj['EXHIBITORS_25%'] = temptototalExhibitor25
                    obj.Details = 'TOTAL'
                    if (data.data.length > 0 && data.data[data.data.length - 1].ChapterName != 'TOTAL') {
                        data.data.push(obj)
                    }
                    this.setState({
                        isLoading: false,
                        paymentSummaryList: data.data,
                        // pendingPaymentCount: data.data[0]['Count_Of_Payment']
                    })
                    console.log("Data ------->", this.state.paymentSummaryList)

                } else {
                    this.setState({ isLoading: false })
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    onCountClick(chapterName, status) {
        console.log("Keyword------------>", `${chapterName.trim()}/${status}`)
        this.props.navigate('/exhibitorlist', { state: { fromDashBoard: true,
            paymentType : status == "EXHIBITORS_25%" ? 2 : status == "EXHIBITORS_100%" ? 3 : null,
             exhibitorType : chapterName.trim() == "INDIAN PARTICIPANTS" ? 1 : chapterName.trim() == "FOREIGN PARTICIPANTS" ? 2 : 
             chapterName.trim() == "TOTAL" ? "" : null
           } })
    }

    record(list) {
        return (
            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
                <thead>
                    <tr>
                        <th className="text-center">Details</th>
                        <th colSpan={2} className="text-center">Payment status 25%</th>
                        <th colSpan={2} className="text-center">Payment status 100%</th>
                    </tr>
                    <tr>
                        <th className="text-center"></th>
                        <th className="text-center">EXHIBITORS</th>
                        <th className="text-center">AREA</th>
                        <th className="text-center">EXHIBITORS</th>
                        <th className="text-center">AREA</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.length > 0 ? <>{
                            list.map((data, i) => (
                                <tr key={Math.random()}>
                                    <td className="text-center">{ i == list.length - 1 ? <strong>{data.Details}</strong> : data.Details}</td>
                                    <td className="text-center" style={{ cursor: "pointer" }}
                                     onClick={(e) => this.onCountClick(data.Details, "EXHIBITORS_25%")}
                                    >{ i == list.length - 1 ? <strong>{data['EXHIBITORS_25%']}</strong> : data['EXHIBITORS_25%']}</td>
                                    <td className="text-center" style={{ cursor: "pointer" }}
                                      onClick={(e) => this.onCountClick(data.Details, "EXHIBITORS_25%")}
                                    >{ i == list.length - 1 ? <strong>{data['AREA_25%']}</strong> : data['AREA_25%']}</td>
                                    <td className="text-center" style={{ cursor: "pointer" }}
                                    onClick={(e) => this.onCountClick(data.Details, "EXHIBITORS_100%")}
                                    >{ i == list.length - 1 ? <strong>{data['EXHIBITORS_100%']}</strong> : data['EXHIBITORS_100%']}</td>
                                    <td className="text-center"  style={{ cursor: "pointer" }}
                                      onClick={(e) => this.onCountClick(data.Details, "EXHIBITORS_100%")}
                                    >{ i == list.length - 1 ? <strong>{data['AREA_100%']}</strong> : data['AREA_100%']}</td>
                                </tr>
                            ))}
                            {/* {list[list.length - 1].Details != 'TOTAL' &&
                                <tr key={Math.random()}>
                                    <td className="text-center"><strong>TOTAL</strong></td>
                                    <td className="text-center"><strong>{this.state.totalExhibitor25}</strong></td>
                                    <td className="text-center"><strong>{this.state.totalArea25}</strong></td>
                                    <td className="text-center"><strong>{this.state.totalExhibitor100}</strong></td>
                                    <td className="text-center"><strong>{this.state.totalArea100}</strong></td>

                                </tr>} */}
                        </> :
                            <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                    }
                </tbody>
            </table>)
    }

    render() {
        const { isLoading, paymentSummaryList } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <AdminDrawer />
                    {/*app-content open*/}
                    {/* <div className="app-content with-dr-container com-height"> */}
                    {/* <AdminDashboardTopmenu /> */}
                    <div className="row mt-3">
                        <div className="table-responsive mb-3">
                            {this.record(paymentSummaryList)}
                        </div>
                    </div>
                    {/* </div> */}
                </>
                {/* <Footerwithdr /> */}
            </>
        );
    }
}

export default withRouter(connect(null, { getAdminPaymentSummaryList_action, getAdminDashboardData_action })(PaymentSummary));
