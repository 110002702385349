import { axiosGet, axiosPost } from "../../../../react_axios/Axios";
import { getManulFormPricesListUrl, GET_MANUAL_FORM_PRICES, insertUpdateManulFormPricesListUrl, INSERT_UPDATE_MANUAL_FORM_PRICES, headers } from "../../../../utils/constants";

export const getManualformsMasterPricesList_action = (ExihibitionID, ManualFormID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExihibitionID,
            ManualFormID: ManualFormID,
        }

        const res = await axiosGet(getManulFormPricesListUrl, params, headers)

        dispatch({
            type: GET_MANUAL_FORM_PRICES,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const insertUpdateManualformsMasterPricesList_action = (ExihibitionID, ManualFormID, ManualFormList) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExihibitionID,
            ManualFormID: ManualFormID,
            ManualFormList: ManualFormList,
        }

        const res = await axiosPost(insertUpdateManulFormPricesListUrl, params, headers)

        dispatch({
            type: INSERT_UPDATE_MANUAL_FORM_PRICES,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}