import { Component, forwardRef } from "react";
import Loader from "../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import Pagination from "../../../utils/pagination";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action } from '../../../actions/fillCombo_action';
import AdminDrawer from "../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../common_components/header_with_logo";
import "react-datepicker/dist/react-datepicker.css";
import ExhibitorHeader from "../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../common_components/exhibitor_module_drawer";
import ExhibitorTopMenu from "../../../common_components/exhibitor_module_topmenu";
import { exhibitionId, serviceTypeId, toastError, toastSuccess } from "../../../utils/constants";
import { getVendorExhibitionList_action } from '../../../actions/vendor_module_action/vendor_exhibition_list_action/vendor_exhibition_list_action'
import moment from "moment";

const styles = {
    width: '100%',
};

class VendorExhibitionList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            vendorRegistrationList: [],
            currentPage: 1,
            ExhibitionID: '',
            exhibitionList: [],
            ServiceID: '',
            serviceTypeList: [],
        }
        this.updateItem = this.updateItem.bind(this)
    }

    componentDidMount() {
        this.getFillList(exhibitionId, '', '', '', '', '');
        this.getFillList(serviceTypeId, '', '', '', '', '');


        this.getVendorExhibitionList()
    }

    // get fill List dropdown  
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, exhRegistrationID) {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value, exhRegistrationID)
            .then((data) => {
                if (data.error != 1) {
                    // voucher list
                    if (actionID === exhibitionId) {
                        // console.log("voucher type data", data.data)
                        this.setState({
                            exhibitionList: data.data, isLoading: false,
                        })
                    }
                    if (actionID === serviceTypeId) {
                        console.log("voucher type data", data.data)
                        this.setState({
                            serviceTypeList: data.data, isLoading: false,
                        })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }


    // Update Current Page
    updateItem(item) {
        const { vendorRegistrationList } = this.state
        this.setState({ currentPage: item });
        const recordsPerPage = 10;
        const indexOfLastRecord = item * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = vendorRegistrationList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(vendorRegistrationList.length / recordsPerPage)
    }

    getVendorExhibitionList() {
        const { ExhibitionID, ServiceID } = this.state
        this.setState({ isLoading: true })
        this.props.getVendorExhibitionList_action(ExhibitionID, ServiceID)
            .then((data) => {
                if (data.error != 1) {

                    this.setState({ isLoading: false, vendorRegistrationList: data.data })

                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })

    }

    clearValue() {
        this.setState({
            ExhibitionID: '',
            ServiceID: ''
        })

        setTimeout(() => {
            this.getVendorExhibitionList()
        }, 100);
    }


    render() {
        const { isLoading, ExhibitionID, CompanyName, PersonName, ServiceID, currentPage, vendorRegistrationList, exhibitionList, serviceTypeList } = this.state

        const recordsPerPage = 10;
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = vendorRegistrationList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(vendorRegistrationList.length / recordsPerPage)

        console.log("currentRecords", currentRecords)
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                {
                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                        <ExhibitorHeader /> : <HeaderWithLogo />
                }
                <div class="main-content">

                    {
                        JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                            <ExhibitorDrawer /> : <AdminDrawer />
                    }
                    {
                        JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                            <ExhibitorTopMenu /> : null
                    }
                    {/* <div class="row">
                        <div class="step-name">
                            <p>Vendor Registration List</p>
                        </div>
                    </div> */}

                    <div class="row mt-1 pt-3 pb-3 p-2">
                        <div className="col-md-3">
                            <label>Exhibition</label>
                            <select
                                className="form-control"
                                id="roledb"
                                // style={{ textAlign: "left" }}
                                value={ExhibitionID}
                                onChange={(e) => this.setState({ ExhibitionID: e.target.value })}
                            >
                                <option>Select</option>
                                {exhibitionList.length > 0 && exhibitionList.map((data) => (
                                    <option key={data.ExhibitionID
                                    } value={data.ExhibitionID
                                    }>{data.ExhibitionName}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-3">
                            <label>Service Type</label>
                            <select
                                className="form-control"
                                id="roledb"
                                // style={{ textAlign: "left" }}
                                value={ServiceID}
                                onChange={(e) => this.setState({ ServiceID: e.target.value })}
                            >
                                <option>Select</option>
                                {serviceTypeList.length > 0 && serviceTypeList.map((data) => (
                                    <option key={data.ServiceID
                                    } value={data.ServiceID
                                    }>{data.Name}</option>
                                ))}
                            </select>
                        </div>
                        {/* <div className="row mt-3"> */}
                        <div className="col-md-3" style={{ marginTop: '22px' }}>
                            <div
                                className="btn-group mb-1"
                                role="group"
                                aria-label="Basic example"
                                style={{ float: "left" }}
                            >
                                <button type="button" className="btn ad-fil-btn"
                                    onClick={e => this.getVendorExhibitionList()}
                                >
                                    Search
                                </button>
                                <button type="button" className="btn ad-fil-btn"
                                    onClick={e => this.clearValue()}
                                >
                                    Clear
                                </button>
                            </div>
                        </div>
                        {/* </div> */}
                    </div>


                    <div class="table-responsive mb-5 p-2">
                        <table class="table border text-nowrap text-md-nowrap table-bordered mb-0 ">
                            <thead>
                                <tr>
                                    {/* text-center */}
                                    <th>Company Name</th>
                                    <th>Name</th>
                                    <th>Registration Date</th>
                                    <th>Mobile</th>
                                    <th>Country</th>
                                    <th>Service Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    currentRecords.length > 0 && currentRecords != null ?
                                        currentRecords.map((data, i) => (
                                            <tr>
                                                <td>{data.CompanyName != null ? data.CompanyName : '-'}</td>
                                                <td>{data.name != null ? data.name : '-'}</td>
                                                <td>{data.ExhRegistrationDate != null ? moment(data.ExhRegistrationDate).format('DD/MM/YYYY') : '-'}</td>
                                                <td>{data.MobileNo != null ? data.MobileNo : '-'}</td>
                                                <td>{data.CountryName != null ? data.CountryName : '-'}</td>
                                                <td>{data.serviceprovided != null ? data.serviceprovided : '-'}</td>
                                                {/* <td>{'-'}</td>
                                                <td className="text-center">
                                                    <i className="fa fa-pencil" aria-hidden="true"
                                                        style={{ marginRight: '12px', cursor: 'pointer' }}
                                                        onClick={() => this.updateVendor(data.Userid, data.AccountID)}
                                                    />
                                                    <i className="fa fa-trash" aria-hidden="true"
                                                        onClick={() => this.deleteVendorRegistration(data.Userid)}
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                </td> */}

                                            </tr>
                                        )) : (<tr><td colSpan={10} className='text-center'>Record not found</td></tr>)
                                }

                            </tbody>
                        </table>
                        <div className="mt-2 float-left">
                            {
                                vendorRegistrationList.length > 0 ?
                                    <Pagination
                                        nPages={nPages}
                                        currentPage={currentPage}
                                        setCurrentPage={this.updateItem}
                                    /> : <></>
                            }
                            <div className="tab-rec " style={{ float: "right" }}>
                                <p>{`${currentPage} of ${nPages} pages`}</p>
                            </div>
                        </div>
                    </div>
                </div>


            </>
        )
    }
}
export default withRouter(connect(null, { fill_action, getVendorExhibitionList_action })(VendorExhibitionList));

