import { GET_EXHIBITION_MASTER_DETAILS, GET_EXHIBITION_MASTER_LIST, ADD_EXHIBITION_MASTER, UPDATE_EXHIBITION_MASTER, DELETE_EXHIBITION_MASTER, DELETE_EXHIBITION_DOCUMENT_ACTION_TYPE, CLEAR_EXHIBITION_MASTER_DATA_DETAILS} from "../../../utils/constants";


const initialState = [];

function exhibitionMasterReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case GET_EXHIBITION_MASTER_DETAILS:
            return [...state, payload];
        case GET_EXHIBITION_MASTER_LIST:
            return [...state, payload];
        case ADD_EXHIBITION_MASTER:
            return [...state, payload];
        case UPDATE_EXHIBITION_MASTER:
            return [...state, payload];
        case DELETE_EXHIBITION_MASTER:
            return [...state, payload];
        case DELETE_EXHIBITION_DOCUMENT_ACTION_TYPE:
            return [...state, payload];
        case CLEAR_EXHIBITION_MASTER_DATA_DETAILS:
            return [...state, payload];

        default:
            return state;
    }
};

export default exhibitionMasterReducer