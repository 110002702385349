import { axiosGet, axiosPost } from "../../../../react_axios/Axios";
import { getManualFormsMasterListUrl, GET_MANUAL_FORMS_MASTER_LIST, deleteManualFormsMasterUrl, DELETE_MANUAL_FORMS_MASTER, insertUpdateManualformsMasterList, INSERT_UPDATE_MANUAL_FORMS_MASTER, headers } from "../../../../utils/constants";

export const getManualformsMasterList_action = (ExihibitionID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExihibitionID
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getManualFormsMasterListUrl, params, headers)

        dispatch({
            type: GET_MANUAL_FORMS_MASTER_LIST,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const deleteManualformsMaster_action = (ExihibitionID, ManualFormMappingID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExihibitionID,
            ManualFormMappingID: ManualFormMappingID

        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(deleteManualFormsMasterUrl, params, headers)

        dispatch({
            type: DELETE_MANUAL_FORMS_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const insertUpdateManualFormsMaster_action = (ExihibitionID, manualFormsList) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExihibitionID,
            manualFormsList: manualFormsList
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosPost(insertUpdateManualformsMasterList, params, headers)

        dispatch({
            type: INSERT_UPDATE_MANUAL_FORMS_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}