import { axiosGet, axiosPost } from "../../../react_axios/Axios";
import { getBankDetailsMasterListUrl, insertUpdateBankDetailsMasterUrl, deleteBankDetailMasterUrl, GET_BANK_DETAILS_MASTER_LIST, INSERT_UPDATE_BANK_DETAILS_MASTER, DELETE_BANK_DETAILS_MASTER, headers } from "../../../utils/constants";

export const getBankDetailsMasterList_action = (ExhibitionID, BankDetailID, BeneficiaryName, CurrentAccountNo, BankName, BranchName) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: JSON.parse(sessionStorage.getItem("sessionExhibitionID")),
            BankDetailID: BankDetailID,
            BeneficiaryName: BeneficiaryName,
            CurrentAccountNo: CurrentAccountNo,
            BankName: BankName,
            BranchName: BranchName,
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getBankDetailsMasterListUrl, params, headers)

        dispatch({
            type: GET_BANK_DETAILS_MASTER_LIST,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const deleteBankDetailsMaster_action = (BankDetailID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: JSON.parse(sessionStorage.getItem("sessionExhibitionID")),
            BankDetailID: BankDetailID

        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(deleteBankDetailMasterUrl, params, headers)

        dispatch({
            type: DELETE_BANK_DETAILS_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const insertUpdateBankDetailsMaster_action = (BankDetailID, BeneficiaryName, CurrentAccountNo, BankName, BranchName, SwiftCode, IFSCode) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: JSON.parse(sessionStorage.getItem("sessionExhibitionID")),
            BankDetailID: BankDetailID,
            BeneficiaryName: BeneficiaryName,
            CurrentAccountNo: CurrentAccountNo,
            BankName: BankName,
            BranchName: BranchName,
            SwiftCode: SwiftCode,
            IFSCode: IFSCode,
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosPost(insertUpdateBankDetailsMasterUrl, params, headers)

        dispatch({
            type: INSERT_UPDATE_BANK_DETAILS_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

// export const getHallMasterDetails_action = (HallId) => async (dispatch) => {
//     try {
//         const params = {
//             UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
//             ExhibitionID: JSON.parse(sessionStorage.getItem("sessionExhibitionID")),
//             HallId: HallId
//         }
//         console.log("getVisaInvitation_action Action------->", params)

//         const res = await axiosGet(getHallMasterDetailsUrl, params, headers)

//         dispatch({
//             type: GET_HALL_MASTER_DETAILS,
//             payload: res,
//         });
//         return Promise.resolve(res)
//     } catch (error) {
//         return Promise.reject(error)
//     }
// }