import { Component } from "react";
import Loader from "../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action } from '../../../../actions/fillCombo_action';
import { cityActionId, stallTypeActionId, stallListActionId, countryActionId, exhibitionActionId, toastError, toastSuccess } from "../../../../utils/constants";
import { emailValidator } from "../../../../utils/Validator";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import Pagination from "../../../../utils/pagination";
import AdminDrawer from "../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../common_components/header_with_logo";
import { useNavigate } from "react-router-dom";
import ExhibitorDrawer from "../../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../../common_components/exhibitor_module_header";
import { data } from "jquery";
import { getInvitationType_action, deleteInvitationType_action, insertInvitationType_action, updateInvitationType_action } from "../../../../actions/admin_module_action/delegate_master_action/delegate_InvitationType_action";
import Footer from "../../../../common_components/Footer";


class Delegate_InvitationType extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isOpen: false,
            currentPage: 1,
            countryList: [],
            InvitationTypeList: [],
            deleteID: '',
            InvitationTypeName: '',
            Status: '',
            isOpen2: false,
            is_add: false,
            InvitationTypeID: '',
            modalInvitationTypeName: '',
            diplayStatus: 1
        }
    }

    componentDidMount() {
        this.getFillList(countryActionId, '', '', '', '', '').then(() => {
            this.getInvitationTypeList()
        })
    }

    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        const { } = this.state
        return new Promise((resolve, reject) => {
            this.setState({ isLoading: true })
            this.props
                .fill_action(actionID, exhibitionID, moduleID, keyValue, value)
                .then((data) => {
                    if (data.error != 1) {
                        // country list
                        if (actionID === countryActionId) {
                            console.log("country data", data.data)
                            // var internationalCountryData = data.data.length > 0 && data.data.filter((item) => item.Code != "91")
                            this.setState({
                                countryList: data.data
                            })
                        }
                        resolve(true)
                    }
                    this.setState({ isLoading: false })
                })
                .catch((e) => {
                    console.log(e);
                });
        })
    }

    record(list) {
        return (
            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
                <thead>
                    <tr>
                        <th style={{ width: '50px' }}>SR no.</th>
                        <th  >InvitationType Name</th>
                        {(this.state.diplayStatus==1 ||this.state.diplayStatus=='')  &&
                            <th style={{ width: '20px' }} className="text-center" colSpan={2}>Action</th>}
                    </tr>
                </thead>
                <tbody>
                    {
                        list.length > 0 ? <>{
                            list.map((data, i) => (
                                <tr key={Math.random()}>
                                    <td className="text-center">{i + 1}</td>
                                    <td >{data.InvitationTypeName}</td>
                                    {(this.state.diplayStatus==1 ||this.state.diplayStatus=='')  &&
                                        <>
                                            <td className="text-center" style={{ width: '20px' }}>
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-primary btn-sm"
                                                    onClick={(e) => this.handleUpdate(data.InvitationTypeID)}
                                                >
                                                    Update
                                                </button>
                                            </td>
                                            <td className="text-center" style={{ width: '20px' }}>
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-danger btn-sm"
                                                    onClick={(e) => this.onDelete(data.InvitationTypeID)}
                                                >
                                                    Delete
                                                </button>
                                            </td>
                                        </>}
                                </tr>
                            ))}
                        </> :
                            <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                    }
                </tbody>
            </table>)
    }

    getInvitationTypeDetails(id) {
        this.setState({ isLoading: true })
        const { InvitationTypeName, Status } = this.state
        this.props.getInvitationType_action(null, null, id,)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ isLoading: false })
                    if (id != '') {
                        this.setState({ modalInvitationTypeName: data.data[0]['InvitationTypeName'] })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    getInvitationTypeList() {
        this.setState({ isLoading: true })
        const { InvitationTypeName, Status } = this.state
        this.props.getInvitationType_action(
            InvitationTypeName != '' ? InvitationTypeName : null,
            Status != '' ? Status : 1,
            null,
        )
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ InvitationTypeList: data.data, diplayStatus: Status != '' ? Status : 1 })
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    insertInvitationType() {
        this.setState({ isLoading: true })
        const { modalInvitationTypeName, Status } = this.state
        if (modalInvitationTypeName != '' && modalInvitationTypeName != null) {
            this.props.insertInvitationType_action(
                modalInvitationTypeName,
            )
                .then((data) => {
                    if (data.error != 1) {
                        toastSuccess("Inserted sucessfully")
                        this.clearSearch()
                        this.setState({ isLoading: false, isOpen2: false })
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            toastError("Please enter mandatory fields")
            this.setState({ isLoading: false })
        }
    }

    updateInvitationType() {
        this.setState({ isLoading: true })
        const { modalInvitationTypeName, InvitationTypeID } = this.state
        if (modalInvitationTypeName != '' && modalInvitationTypeName != null) {
            this.props.updateInvitationType_action(
                InvitationTypeID, modalInvitationTypeName,
            )
                .then((data) => {
                    if (data.error != 1) {
                        toastSuccess("Updated Sucessfully")
                        this.clearSearch()
                        this.setState({ isLoading: false, isOpen2: false })
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            toastError("Please enter mandatory fields")
            this.setState({ isLoading: false })
        }
    }

    clearSearch() {
        const { Stateno, PANNo } = this.state
        this.setState({
            InvitationTypeName: '',
            Status: ''
        })
        setTimeout(() => {
            this.getInvitationTypeList()
        }, 100);
    }

    handleAdd() {
        this.setState({ isOpen2: true, is_add: true, InvitationTypeID: '', modalInvitationTypeName: '' })
    }

    handleUpdate(id) {
        this.setState({ isOpen2: true, is_add: false, InvitationTypeID: id })
        this.getInvitationTypeDetails(id)
    }

    deleteDelegateInvitationType(id) {
        this.setState({ isLoading: true })
        this.props.deleteInvitationType_action(id)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ isLoading: false })
                    toastSuccess("Deleted successfully")
                    setTimeout(() => {
                        this.getInvitationTypeList()
                    }, 200);
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
                this.setState({ isOpen: false })
            })
            .catch((err) => {
                console.log(err)
            })
    }

    onDelete(id) {
        const { } = this.state
        this.setState({ deleteID: id })
        this.setState({ isOpen: true, })
    }

    updateItem(item) {
        const { InvitationTypeList } = this.state
        this.setState({ currentPage: item });
        const recordsPerPage = 10;
        const indexOfLastRecord = item * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = InvitationTypeList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(InvitationTypeList.length / recordsPerPage)
        this.setState({ startIndex: recordsPerPage * (item - 1) });
    }

    render() {
        const { isOpen, isLoading, currentPage, InvitationTypeList, InvitationTypeName, Status, isOpen2, is_add, modalInvitationTypeName } = this.state
        const recordsPerPage = 10;
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = InvitationTypeList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(InvitationTypeList.length / recordsPerPage)
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />

                <>
                    <div className="app-content with-dr-container com-height">
                        <AdminDrawer />
                        <div className="row">
                            <div className="step-name">
                                <p>Invitation Type</p>
                            </div>
                        </div>
                        <div className="m-3">
                            <div className="">
                                <div className="row ">
                                    <div className="col-md-3">
                                        <label>Delegate Invitation Type Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={InvitationTypeName}
                                            onChange={(e) => this.setState({ InvitationTypeName: e.target.value, })}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <label>Status</label>
                                        <select className="form-control"
                                            value={Status}
                                            onChange={(e) => this.setState({ Status: e.target.value })}  >
                                            <option value={''}>Select</option>
                                            <option value={1}>Active</option>
                                            <option value={0}>Inactive</option>
                                        </select>
                                    </div>
                                    <div className="col-md-3" style={{ textAlign: 'left' }}>
                                        <label>&emsp;</label>
                                        <button type="button" className="btn ad-fil-btn"
                                            onClick={e => this.getInvitationTypeList()}
                                        >
                                            Search
                                        </button>
                                        <button type="button" className="btn ad-fil-btn"
                                            onClick={e => this.clearSearch()}
                                        >
                                            Clear
                                        </button>
                                    </div>
                                </div>
                            </div>
                            < div className="row mt-2 mb-1" >
                                <div className="col-md-12 mt-3 mb-1">
                                    <div
                                        className="btn-group mb-1"
                                        role="group"
                                        aria-label="Basic example"
                                        style={{ float: "right" }}
                                    >
                                        <button type="button" className="btn ad-fil-btn"
                                            onClick={e => this.handleAdd()}
                                        >
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 pl-0">
                                <div className="table-responsive">
                                    {this.record(currentRecords)}
                                </div>
                                <div className="row">
                                    <div className="col-md-9 pl-0">
                                        <div className="mt-2 float">
                                            {
                                                InvitationTypeList.length > 0 ?
                                                    <Pagination
                                                        nPages={nPages}
                                                        currentPage={currentPage}
                                                        setCurrentPage={this.updateItem}
                                                    /> : <></>
                                            }

                                        </div>
                                    </div>
                                    <div className="col-md-3 text-right">
                                        <div className="tab-rec mt-3" style={{ float: "right" }}>
                                            <p>{`${currentPage} of ${nPages} pages`}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </>
                <Modal isOpen={isOpen} onRequestClose={() => this.setState({ isOpen: false })}>
                    <div className="modal-dialog" style={{ marginTop: '10%' }}>
                        <div className="modal-content confirm-logout">
                            <div class="modal-header text-right p-2">
                                <h5 class="modal-title modal_title_text text-danger">Do you really want to delete this record?</h5>

                                {/* <button type="button " class="close pop-close" data-dismiss="modal"
                                    onClick={() => this.setState({ isOpen: false })}
                                ><span aria-hidden="true" class="pop-cancel">&times;</span></button> */}
                            </div>
                            {/* <div class="modal-body">
                                <div class="row">
                                </div>
                            </div> */}
                            <div class="modal-footer p-1">
                                <div class="form-group text-right mod-fot">
                                    <button type="button" class="btn btn-del-pop"
                                        onClick={(e) => this.deleteDelegateInvitationType(this.state.deleteID)}
                                    >Yes</button>
                                    <button type="button" class="btn btn-can-pop" data-dismiss="modal" style={{ marginLeft: '5px' }}
                                        onClick={() => this.setState({ isOpen: false })}
                                    >No</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
                <Modal isOpen={isOpen2} onRequestClose={() => this.setState({ isOpen2: false })}>
                    <div className="modal-dialog" style={{ marginTop: '5%' }}>
                        <div className="modal-content confirm-logout">
                            <div class="modal-header text-right p-2">
                                <h5 class="modal-title modal_title_text">{is_add ? 'Add' : 'Update'} Invitation Type</h5>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div className="col-md-9">
                                        <label>Invitation Type Name<span className="text-red">*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={modalInvitationTypeName}
                                            onChange={(e) => this.setState({ modalInvitationTypeName: e.target.value, })}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer p-1">
                                <div class="form-group text-right mod-fot">
                                    {is_add ? <button type="button" class="btn btn-del-pop"
                                        onClick={(e) => this.insertInvitationType(this.state.InvitationTypeID)}
                                    > Add</button> :
                                        <button type="button" class="btn btn-del-pop"
                                            onClick={(e) => this.updateInvitationType(this.state.InvitationTypeID)}
                                        > Update</button>
                                    }
                                    <button type="button" class="btn btn-can-pop" data-dismiss="modal" style={{ marginLeft: '5px' }}
                                        onClick={() => this.setState({ isOpen2: false })}
                                    >No</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
                <Footer />
            </>)
    }

}
export default withRouter(connect(null, { fill_action, getInvitationType_action, deleteInvitationType_action, insertInvitationType_action, updateInvitationType_action })(Delegate_InvitationType));