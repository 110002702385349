import { GET_VISA_APPLICATION_ACTION_TYPE, GET_VISA_INVITATON_ACTION_TYPE, UPDATE_VISA_APPROVAL_ACTION_TYPE } from "../../../utils/constants";


const initialState = [];

function visa_approvalReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_VISA_INVITATON_ACTION_TYPE:
            return [...state, payload];
        case GET_VISA_APPLICATION_ACTION_TYPE:
            return [...state, payload];
        case UPDATE_VISA_APPROVAL_ACTION_TYPE:
            return [...state, payload];
        default:
            return state;
    }
}
export default visa_approvalReducer;