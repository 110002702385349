import { axiosGet, axiosPost } from "../../../../../react_axios/Axios"
import { GET_HOST_AND_HOSTESS_DETAILS_ACTION_TYPE, INSERT_UPDATE_HOST_AND_HOSTESS_ACTION_TYPE, getHostAndHostessDetailsUrl, insertUpdateHostAndHostessUrl, headers } from "../../../../../utils/constants"


export const getHostAndHostessDetails_action = () => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhregistrationID: sessionStorage.getItem('ExhRegistrationIDSS')
        }
        // console.log("getMeetingRoomDetails_action ----->", params)
        const res = await axiosGet(getHostAndHostessDetailsUrl, params, headers)

        dispatch({
            type: GET_HOST_AND_HOSTESS_DETAILS_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const insertUpdateHostAndHostess_action = (remark, is_Agree, HostAndHostessList) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhregistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
            Remark: remark,
            Is_Agree: is_Agree,
            HostAndHostessList: HostAndHostessList
        }
        // console.log("getMeetingRoomDetails_action ----->", params)
        const res = await axiosPost(insertUpdateHostAndHostessUrl, params)

        dispatch({
            type: INSERT_UPDATE_HOST_AND_HOSTESS_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}