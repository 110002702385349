import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../../utils/withRouter';
import Loader from '../../../../utils/Loader'
// import { clearSessionStorage } from '../../../utils/constants'
import Header from '../../../../common_components/Header';
// import { emailValidator } from '../../../utils/Validator'
import Modal from "react-modal";
import Footerwithdr from '../../../../common_components/Footerwithdr';
import HeaderWithLogo from '../../../../common_components/header_with_logo';
import AdminDrawer from '../../../../common_components/admin_module_drawer';
import { fill_action } from '../../../../actions/fillCombo_action'
// import ReceiptList from '../registration_receipt/ReceiptList';
import { Link } from "react-router-dom";

import { getAdminParticipantSummaryList_action } from "../../../../actions/admin_module_action/admin_dasboard_action/admin_dashboard_summary_action/admin_dashboard_summary_action"
// import { getAdminDashboardData_action } from "../../../actions/admin_module_action/admin_dasboard_action/admin_dashboard_action"
import { data } from 'jquery';

class ParticipantSummary extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            participantSummaryList: []
        }
        this.record = this.record.bind(this)
        this.getParticipantSummaryList = this.getParticipantSummaryList.bind(this)

    }

    componentDidMount() {
        this.getParticipantSummaryList()
    }

    // Get suummary list
    getParticipantSummaryList() {
        this.setState({ isLoading: true })
        this.props.getAdminParticipantSummaryList_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log("Admin Data ------->", data.data)
                    // data.data.length > 0 && data.data.map((dat) => (
                    //     dat.Total = dat.Applied + dat.Agent + dat.Waiting + dat.Incomplete
                    // ))
                    // var temptotalAgent = this.state.totalAgent
                    // var temptotalApplied = this.state.totalApplied
                    // var temptotalAlloted = this.state.totalAlloted
                    // var temptotalIncomplete = this.state.totalIncomplete
                    // var temptotalWaiting = this.state.totalWaiting
                    // var temptotalTotal = this.state.totalTotal

                    // for (var i = 0; i < data.data.length; i++) {
                    //     temptotalAgent = temptotalAgent + data.data[i].Agent
                    //     temptotalApplied = temptotalApplied + data.data[i].Applied
                    //     temptotalAlloted = temptotalAlloted + data.data[i].Alloted
                    //     temptotalIncomplete = temptotalIncomplete + data.data[i].Incomplete
                    //     temptotalWaiting = temptotalWaiting + data.data[i].Waiting
                    //     temptotalTotal = temptotalTotal + data.data[i].Total

                    // }
                    this.setState({
                        isLoading: false,
                        participantSummaryList: data.data,
                        // totalAgent: temptotalAgent,
                        // totalAlloted: temptotalAlloted,
                        // totalApplied: temptotalApplied,
                        // totalIncomplete: temptotalIncomplete,
                        // totalWaiting: temptotalWaiting,
                        // totalTotal: temptotalTotal
                        // pendingPaymentCount: data.data[0]['Count_Of_Payment']
                    })
                    console.log("Data ------->", this.state.applicationSummaryList)

                } else {
                    this.setState({ isLoading: false })
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    record(list) {
        return (
            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
                <thead>
                    <tr>
                        <th className="text-center">Details</th>
                        <th className="text-center">Indian Participants</th>
                        <th colSpan={2} className="text-center">Foreign Participants</th>
                        <th colSpan={2} className="text-center">Partners</th>
                    </tr>
                </thead>
                {/* <tbody>
                {
                    agentList.length > 0 ?
                        agentList.map((data) => (
                            <tr key={Math.random()}>
                                <td className="text-center">{data.Type}</td>
                                <td>{data.agentcode}</td>
                                <td>{data['Company Name']}</td>
                                <td>{data.Name}</td>
                                <td>{data.PrimaryEmail}</td>
                                <td className="text-center">{data.MobileNo}</td>
                                <td className="text-center">{data['Total Area']}</td>
                                <td className="text-center">{data['Booked Area']}</td>
                                <td className="text-center">{data['Balance Area']}</td>
                                <td className="text-center">{data['Total Exhibitor']}</td>
                                <td>
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary btn-sm"
                                        onClick={(e) => this.onEdit(e, data.AccountID, data.AgentRegistrationType)}
                                    >
                                        Edit
                                    </button>
                                </td>
                                <td>
                                    <button
                                        type="button"
                                        className="btn btn-outline-success btn-sm"
                                        onClick={(e) => this.onView(e, data.AccountID, data.AgentRegistrationType)}
                                    >
                                        View
                                    </button>
                                </td>
                                <td>
                                    <button type="button" className="btn btn-outline-info btn-sm"
                                        onClick={(e) => this.onLoginClick(data.AccountID, data)}
                                    >
                                        Login
                                    </button>
                                </td>
                            </tr>
                        )) :
                        <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                }
            </tbody> */}
            </table>)
    }

    render() {
        const { isLoading, participantSummaryList } = this.state
        return (
            <>

                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <AdminDrawer />

                    {/*app-content open*/}
                    {/* <div className="app-content with-dr-container com-height"> */}
                    {/* <AdminDashboardTopmenu /> */}

                    <div className="row mt-3">
                        <div className="table-responsive mb-3">
                            {this.record(participantSummaryList)}
                        </div>
                    </div>

                    {/* </div> */}
                </>

                {/* <Footerwithdr /> */}

            </>
        );
    }
}

export default withRouter(connect(null, { getAdminParticipantSummaryList_action })(ParticipantSummary));
