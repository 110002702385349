import { Component } from "react";
import Loader from "../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import HeaderWithLogo from "../../../../common_components/header_with_logo";
import AdminDrawer from "../../../../common_components/admin_module_drawer";
import { withRouter } from "../../../../utils/withRouter";
import { connect } from "react-redux";
import { exhibitorList_action } from "../../../../actions/admin_module_action/exhibitor_list_action/exhibitor_list_action";
import { priorityNoActionId, exhibitorTypeActionId, paymentTypeActionId, toastError, exhibitionActionId, downloadReport, toastSuccess, stallPosessionStatusActionId } from "../../../../utils/constants";
import Pagination from "../../../../utils/pagination";
import { sendNoDues_action } from "../../../../actions/admin_module_action/tax_invoice_action/tax_invoice_action"
import { data } from "jquery";
import { createTaxInvoice_action, getStallPossessionExport_action, download_no_due_action, accountExhibitorList_action } from "../../../../actions/admin_module_action/tax_invoice_action/tax_invoice_action";
import Modal from "react-modal";
import Workbook from 'react-excel-workbook'
import { fill_action } from "../../../../actions/fillCombo_action";
import { submitAutoPriorityNo_action,resendPriorityNo_action, submitManualPriorityNo_action, generate2ndPI_action } from "../../../../actions/admin_module_action/exhibitor_list_action/exhibitor_list_action";


class Tax_Invoice_Exhibitors extends Component {
    // Initialize Constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            checkedAll: false,
            exhibitor: "",
            exhibition: "",
            paymentTypeId: '',
            personIncharge: '',
            exhibitorTypeId: '',
            exhibitorList: [],
            currentPage: 1,
            exhregId: "",
            exhibitorOutstanding: "",
            priorityNoList: [],
            exhibitorTypeList: [],
            paymentTypeList: [],
            isOpen: false,
            generatePriorityNoType: 1,
            priorityNoId: "",
            stallPosessionStatusId: "",
            stallPossionStatusList: [],
            loginAccesslist: [],

            // Err Msg
            priorityNoErr: "",
            exportData: [],

            exhibitionList: [],
            exhibitionID: '',
            isOpen5: false,
            exhRegistrationId : ''
        }
        this.handleKeyDown = this.handleKeyDown.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.clearValue = this.clearValue.bind(this)
        this.getExhibitorList = this.getExhibitorList.bind(this)
        this.selectAllCompany = this.selectAllCompany.bind(this)
        this.handleCheckedCompany = this.handleCheckedCompany.bind(this)
        this.sendNoDues = this.sendNoDues.bind(this)
        this.updateItem = this.updateItem.bind(this)
        this.onGenerateClick = this.onGenerateClick.bind(this)
        this.getFillList = this.getFillList.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
        this.downloadNoDues = this.downloadNoDues.bind(this)
        this.onFinalGeneratePriorityNo = this.onFinalGeneratePriorityNo.bind(this)
        this.generateAutoPriorityNo = this.generateAutoPriorityNo.bind(this)
        this.resendAutoPriorityNo = this.resendAutoPriorityNo.bind(this)
        this.generateManualPriorityNo = this.generateManualPriorityNo.bind(this)
        this.onExportData = this.onExportData.bind(this)
        this.onCreateExcel2 = this.onCreateExcel2.bind(this)
        this.getDownloadAccess = this.getDownloadAccess.bind(this)
        this.getSendAccess = this.getSendAccess.bind(this)
        this.getWriteAccess = this.getWriteAccess.bind(this)
    }

    componentDidMount() {
        this.setState({ loginAccesslist: JSON.parse(sessionStorage.getItem('loginDataSS'))['loginAccesslist'] })
        this.getFillList(paymentTypeActionId, '', '', '', '', '');
        this.getFillList(exhibitorTypeActionId, '', '', '', '', '');
        this.getFillList(stallPosessionStatusActionId, '', '', '', '', '');
        this.getFillList(exhibitionActionId, '', '', '', '', '');
        this.getExhibitorList()
        this.onExportData()
    }

    handleKeyDown(e) {
        if (e.code == 'Enter') {
            this.getExhibitorList()
        }
    }

    // On Search Click
    onSearchClick() {
        const { exhibitor, exhibitionID, exhibition, exhibitorTypeId, paymentTypeId, personIncharge, stallPosessionStatusId } = this.state
        if (exhibitor != "" || exhibitionID != "" || exhibitorTypeId != "" || paymentTypeId != '' || personIncharge != ''
            || stallPosessionStatusId != "") {
            this.getExhibitorList()
        } else {
            toastError("Atleast Fill One field to Search")
        }
    }

    clearValue() {
        this.setState({
            exhibitor: "",
            exhibition: "",
            exhibitorTypeId: '',
            paymentTypeId: '',
            personIncharge: '',
            stallPosessionStatusId: "",
            exhibitionID: '',
        })
        setTimeout(() => {
            this.getExhibitorList()
        }, 500);
    }

    // For Exhibitor List
    getExhibitorList() {
        const { exhibitor, exhibition, exhibitionID, exhibitorTypeId, paymentTypeId, personIncharge, stallPosessionStatusId } = this.state
        this.setState({ isLoading: true })
        this.props.accountExhibitorList_action(exhibitionID, exhibitor, exhibitorTypeId, paymentTypeId, personIncharge, stallPosessionStatusId)
            .then((data) => {
                if (data.error != 1) {
                    console.log(data)
                    this.setState({
                        exhibitorList: data.data,
                        isLoading: false
                    })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    //on Select All Click
    selectAllCompany(e) {
        const { checkedAll, exhibitorList } = this.state
        this.setState({ checkedAll: !checkedAll })
        const checked = e.target.checked
        exhibitorList.length > 0 && exhibitorList.map((data) => {
            if (checked) {
                data.Is_Select = true
            } else {
                data.Is_Select = false
            }
        })
        console.log("yyyyyyyyyyyyy", exhibitorList)
    }

    // On Checked Company
    handleCheckedCompany(e, id) {
        const { exhibitorList } = this.state
        const checked = e.target.checked

        exhibitorList.length > 0 && exhibitorList.map((data) => {
            if (data.ExhRegistrationID == id) {
                if (checked) {
                    data.Is_Select = true
                } else {
                    data.Is_Select = false
                    this.setState({ checkedAll: false })
                }
            }
        })
        console.log("aaaaaaaaaaa", exhibitorList)
        this.setState({})
    }

    // On Send No Dues
    sendNoDues() {
        const { isLoading, exhibitorList } = this.state
        var selectedExhibitors = exhibitorList.length > 0 && exhibitorList.filter((item) => item.Is_Select)
        if (selectedExhibitors.length > 0) {
            console.log("sssssssssssssssssssssssssssssssss", selectedExhibitors)
            // exhibitor data Preparing to store
            var list = []
            selectedExhibitors.map(data => {
                var mapData = {
                    'ExhregistrationID': parseInt(data.ExhRegistrationID),
                    'hallStall': data['HallNo/StallNo']
                }
                list.push(mapData)
            });
            this.setState({ isLoading: true })
            this.props.sendNoDues_action(list)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({ isLoading: false })
                        toastSuccess("No Dues Send")
                        this.getExhibitorList()
                    } else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            toastError("Select Atleast one Exhibitors")
        }

    }

    // Update Current Page
    updateItem(item) {
        const { exhibitorList } = this.state
        this.setState({ currentPage: item });
        const recordsPerPage = 10;
        const indexOfLastRecord = item * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = exhibitorList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(exhibitorList.length / recordsPerPage)
    }

    // On Create Tax Invoice
    createTaxInvoice() {
        const { isLoading, exhibitorList } = this.state
        var selectedExhibitors = exhibitorList.length > 0 && exhibitorList.filter((item) => item.Is_Select)
        if (selectedExhibitors.length > 0) {
            console.log("sssssssssssssssssssssssssssssssss", selectedExhibitors)
            // exhibitor data Preparing to store
            var list = []
            selectedExhibitors.map(data => {
                var mapData = {
                    'ExhregistrationID': parseInt(data.ExhRegistrationID)
                }
                list.push(mapData)
            });
            this.setState({ isLoading: true })
            this.props.createTaxInvoice_action(list)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({ isLoading: false })
                        toastSuccess("Tax Invoice Created")
                        this.getExhibitorList()
                    } else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            toastError("Select Atleast one Exhibitors")
        }

    }

    // get fill List dropdown  
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, exhRegistrationID) {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value, exhRegistrationID)
            .then((data) => {
                if (data.error != 1) {
                    // Priority NO list
                    if (actionID === priorityNoActionId) {
                        console.log("Priority No data", data.data)
                        this.setState({
                            priorityNoList: data.data, isLoading: false,
                        })
                    }
                    if (actionID === paymentTypeActionId) {
                        console.log("Payment Type list data", data.data)
                        var list = data.data.filter(element =>
                            element.ID != 4
                        )
                        this.setState({
                            paymentTypeList: list.length > 0 ? list : [],
                            isLoading: false
                        })
                    }
                    // Exhibitor Type list
                    if (actionID === exhibitorTypeActionId) {
                        console.log("Exhibitor Type list data", data.data)
                        this.setState({
                            exhibitorTypeList: data.data.length > 0 ? data.data : [],
                            isLoading: false
                        })
                    }
                    // Stall Posession Status list
                    if (actionID === stallPosessionStatusActionId) {
                        console.log("Stall Posession Status list data", data.data)
                        this.setState({
                            stallPossionStatusList: data.data.length > 0 ? data.data : [],
                            isLoading: false
                        })
                    }
                    //exibition
                    if (actionID === exhibitionActionId) {
                        console.log("Stall Posession Status list data", data.data)
                        this.setState({
                            exhibitionList: data.data.length > 0 ? data.data : [],
                            isLoading: false
                        })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    // Modal Toggle
    toggleModal() {
        const { isOpen } = this.state
        this.setState({ isOpen: !isOpen })
    }

    // On Generate Click 
    onGenerateClick(id, outstanding) {
        this.setState({ exhregId: id, exhibitorOutstanding: outstanding })
        this.getFillList(priorityNoActionId, sessionStorage.getItem('sessionExhibitionID'), '', '', '', '');
        this.toggleModal()
    }

    // On Generate Priority No
    onFinalGeneratePriorityNo() {
        const { generatePriorityNoType } = this.state
        if (generatePriorityNoType == 1) {
            this.generateAutoPriorityNo()
        } else {
            this.generateManualPriorityNo()
        }
    }

    // On Auto Generate Priority No
    generateAutoPriorityNo() {
        const { exhregId, isOpen } = this.state
        this.setState({ isLoading: true })
        this.props.submitAutoPriorityNo_action(exhregId)
            .then((data) => {
                if (data.error != 1) {
                    console.log("Auto Priority No ", data.data)
                    this.setState({
                        exhregId: "",
                        exhibitorOutstanding: "",
                        isOpen: !isOpen,
                        isLoading: false
                    })
                    toastSuccess(`Generated Priority No:- ${data.data[0]['PriorityNo']}`)
                    this.getExhibitorList()
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // resend Priority No
    resendAutoPriorityNo(id, outstanding) {
        const { exhregId, isOpen } = this.state
        this.setState({ isLoading: true })
        this.props.resendPriorityNo_action(id)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({
                        exhregId: "",
                        exhibitorOutstanding: "",
                        // isOpen: !isOpen,
                        isLoading: false
                    })
                    toastSuccess(`Priority number send successfully.`)
                    // this.getExhibitorList()
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    onExportData() {
        this.setState({ isLoading: true })
        this.props.getStallPossessionExport_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log("onExportData Data", data.data)
                    this.setState({
                        exportData: data.data,
                        isLoading: false
                    })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    downloadNoDues(ExhibitionID, ExhRegistrationID) {
        this.setState({ isLoading: true })
        this.props.download_no_due_action(ExhibitionID, ExhRegistrationID)
            .then((data) => {
                if (data.error != 1) {
                    console.log("jjjjjjjjjjjjjjj", data)
                    var url = `${downloadReport}?SessionIdentifier=${data}&Export=Yes`;
                    const win = window.location.replace(url, '_blank');
                    if (win != null) {
                        win.focus();
                    }
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })

                    toastError(data.msg);
                }
            }).catch((err) => {
                console.log(err);
            })
    }

    // generate Manual priority No
    generateManualPriorityNo() {
        const { priorityNoId } = this.state
        if (priorityNoId == "" || priorityNoId == "Select Priority Number") {
            this.setState({ priorityNoErr: 'Please Select Priority No' })
        }
        setTimeout(() => {
            const { priorityNoErr, exhregId, isOpen, priorityNoId } = this.state

            if (priorityNoId != "") {
                if (priorityNoErr == "") {
                    this.setState({ isLoading: true })
                    this.props.submitManualPriorityNo_action(priorityNoId, exhregId)
                        .then((data) => {
                            if (data.error != 1) {
                                console.log("Manual Priority No ", data.data[0]['PriorityNo'])
                                this.setState({
                                    isLoading: false,
                                    exhregId: "",
                                    exhibitorOutstanding: "",
                                    isOpen: !isOpen,
                                })
                                this.getExhibitorList()
                                toastSuccess(`Generated Priority No:- ${data.data[0]['PriorityNo']}`)
                            } else {
                                this.setState({ isLoading: false })
                                toastError(data.msg)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                }
            }
        }, 500);
    }

    // to create excel file
    onCreateExcel2() {
        const { exportData } = this.state
        var listData = []
        var list = [...exportData]
        var columnArray = [],
            rowData = [];
        // for column data 
        list.map((data, index) => {
            if (index == 0) {
                Object.keys(data).map((key, index) => {
                    columnArray.push(key)
                })
            }
        })

        // for row data
        list.map((data, index) => {
            rowData.push(data)
        })

        return (
            <div className="row text-center">
                {<Workbook filename="Stall_Possession_Data.xlsx" style={{}} element={
                    <button type="button" className="btn ad-fil-btn"
                        disabled={!this.getDownloadAccess()}
                    >
                        Export Stall Possession
                    </button>
                }>
                    <Workbook.Sheet data={rowData} name="Sheet A">
                        {columnArray.map((value, index) =>
                            <Workbook.Column label={value} value={value} />
                        )
                        }
                    </Workbook.Sheet>
                </Workbook>}
            </div>
        );
    }

    getDownloadAccess() {
        const { loginAccesslist } = this.state
        const moduleAccess = loginAccesslist.find((item) => item.ModuleID == '7');
        if (moduleAccess) {
            return moduleAccess.DownloadAccess;
        } else {
            return false
        }
        return false;
    }

    getSendAccess() {
        const { loginAccesslist } = this.state
        const moduleAccess = loginAccesslist.find((item) => item.ModuleID == '7');
        if (moduleAccess) {
            return moduleAccess.SendAccess;
        } else {
            return false
        }
        return false;
    }

    getWriteAccess() {
        const { loginAccesslist } = this.state
        const moduleAccess = loginAccesslist.find((item) => item.ModuleID == '7');
        if (moduleAccess) {
            return moduleAccess.WriteAccess;
        } else {
            return false
        }
        return false;
    }

    toggleModal5(id) {
        const { isOpen5 } = this.state
        if (isOpen5 == true) {
            this.setState({ isOpen5: false, exhRegistrationId : ''})
        }
        else {
            this.setState({ isOpen5: true, exhRegistrationId : id})
        }
    }

    OnGenerate2ndPI(){
        const { exhRegistrationId, isOpen5 } = this.state
        this.setState({ isLoading: true })
        this.props.generate2ndPI_action(exhRegistrationId)
            .then((data) => {
                if (data.error != 1) {
                    toastSuccess(`Proforma invoice 2 generated successfully.`)
                    this.setState({ isLoading: false,isOpen5 : false })
                    this.getExhibitorList()
                }
                else {
                    this.setState({ isLoading: false,isOpen5 : false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }


    render() {
        const { isLoading, exhibitor, exhibition, exhibitorList, currentPage, checkedAll, isOpen, exhibitorOutstanding, generatePriorityNoType,
            priorityNoId, priorityNoList, priorityNoErr, exhibitorTypeId, exhibitorTypeList, personIncharge, paymentTypeId, paymentTypeList,
            stallPosessionStatusId, stallPossionStatusList } = this.state
        const recordsPerPage = 10;
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = exhibitorList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(exhibitorList.length / recordsPerPage)
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                <div className={JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ? "with-dr-container" : "with-dr-container com-height"}>
                    <div className="main-container">
                        <AdminDrawer />
                        <div className="row">
                            <div className="step-name">
                                <p>Exhibitor List</p>
                            </div>
                        </div>
                    </div>
                    <div className="main-container p-2 mt-1">
                        <div className="row ">
                            <div className="col-xl-3 mb-3">
                                <label>Exhibitor</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    defaultValue=""
                                    required=""
                                    value={exhibitor}
                                    onKeyDown={e => this.handleKeyDown(e)}
                                    onChange={(e) => this.setState({ exhibitor: e.target.value })}
                                />
                            </div>
                            <div className="col-xl-3 mb-3">
                                <label htmlFor="validationCustom03">Exhibition</label>
                                <select className="form-control"
                                    value={this.state.exhibitionID}
                                    onChange={(e) => this.setState({ exhibitionID: e.target.value })}
                                >
                                    <option value={''}>Select Exhibition</option>
                                    {
                                        this.state.exhibitionList.length > 0 && this.state.exhibitionList.map((data) => (
                                            <option key={data.ExhibitionID} value={data.ExhibitionID}>{data.ExhibitionName}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="col-xl-3 mb-3">
                                <label>Person Incharge</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    defaultValue=""
                                    required=""
                                    value={personIncharge}
                                    onKeyDown={e => this.handleKeyDown(e)}
                                    onChange={(e) => this.setState({ personIncharge: e.target.value })}
                                />
                            </div>
                            <div className="col-xl-3 mb-3">
                                <label htmlFor="validationCustom03">Payment Type</label>
                                <select className="form-control"
                                    value={paymentTypeId}
                                    onChange={(e) => this.setState({ paymentTypeId: e.target.value })}
                                >
                                    <option>Select Payment Type</option>
                                    {
                                        paymentTypeList.length > 0 && paymentTypeList.map((data) => (
                                            <option key={data.ID} value={data.ID}>{data.PaymentType}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="validationCustom03">Exhibitor Type</label>
                                <select className="form-control"
                                    value={exhibitorTypeId}
                                    onChange={(e) => this.setState({ exhibitorTypeId: e.target.value })}
                                >
                                    <option>Select Exhibitor Type</option>
                                    {
                                        exhibitorTypeList.length > 0 && exhibitorTypeList.map((data) => (
                                            <option key={data.ID} value={data.ID}>{data.ExhibitorType}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="validationCustom03">Exhibitor Type</label>
                                <select className="form-control"
                                    value={stallPosessionStatusId}
                                    onChange={(e) => this.setState({ stallPosessionStatusId: e.target.value })}
                                >
                                    <option>Select Stall Possession Status</option>
                                    {
                                        stallPossionStatusList.length > 0 && stallPossionStatusList.map((data) => (
                                            <option key={data.ID} value={data.ID}>{data.Name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-12">
                                <div
                                    className="btn-group mb-1"
                                    role="group"
                                    aria-label="Basic example"
                                    style={{ float: "right" }}
                                >
                                    <button type="button" className="btn ad-fil-btn"
                                        onClick={e => this.onSearchClick()}
                                    >
                                        Search
                                    </button>
                                    <button type="button" className="btn ad-fil-btn"
                                        onClick={e => this.clearValue()}
                                    >
                                        Clear
                                    </button>
                                    <button type="button" className="btn ad-fil-btn"
                                        disabled={!this.getSendAccess()}
                                        onClick={e => this.sendNoDues()}
                                    >
                                        Send No Dues
                                    </button>
                                    {/* <button type="button" className="btn ad-fil-btn" */}
                                    {this.onCreateExcel2()}
                                    <button type="button" className="btn ad-fil-btn"
                                        disabled={!this.getWriteAccess()}
                                        onClick={e => this.createTaxInvoice()}
                                    >
                                        Create Tax Invoice
                                    </button>
                                </div>
                                <div className="table-responsive">
                                    <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1">
                                        <thead>
                                            <tr>
                                                <th className="text-center" style={{ width: 60 }}>
                                                    <input type="checkbox"
                                                        checked={checkedAll}
                                                        onChange={(e) => this.selectAllCompany(e)}
                                                    />
                                                </th>
                                                <th className="text-center" style={{ width: 50 }}>
                                                    Sr.No
                                                </th>
                                                <th>Exhibition</th>
                                                <th>Exhibitor</th>
                                                <th>Person Incharge</th>
                                                <th>Exhibitor Type</th>
                                                <th>Chapters</th>
                                                <th className="text-center" style={{ width: 100 }}>
                                                    Area (sqm)
                                                </th>
                                                <th>Scheme</th>
                                                {this.getWriteAccess() &&
                                                    <th className="text-center" style={{ width: 100 }}>
                                                        Priority No
                                                    </th>
                                                }
                                                {this.getWriteAccess() &&
                                                    <th className="text-center" style={{ width: 100 }}>
                                                        Generate 2nd PI
                                                    </th>
                                                }
                                                {this.getDownloadAccess() &&
                                                    <th>No Due</th>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentRecords.length > 0 && currentRecords.map((data, index) => (
                                                <tr key={Math.random()}>
                                                    <td className="text-center">
                                                        <input type="checkbox"
                                                            value={data.Is_Select}
                                                            checked={data.Is_Select}
                                                            onChange={(e) => this.handleCheckedCompany(e, data.ExhRegistrationID)}
                                                        />
                                                    </td>
                                                    <td className="text-center">{index + 1}</td>
                                                    <td>{data.ExhibitionName}</td>
                                                    <td>{data.ExhibitorName}</td>
                                                    <td>{data.PersonIncharge}</td>
                                                    <td>{data.ExhibitorType}</td>
                                                    <td>
                                                        {data.ChapterName}
                                                    </td>
                                                    <td className="text-center">{data.Area}</td>
                                                    <td>{data.Scheme}</td>
                                                    {this.getWriteAccess() &&
                                                        <td>
                                                            {
                                                                data.PriorityNo != null ?
                                                                    <div>
                                                                        <span className="text-primary">{data.PriorityNo}</span><br></br>
                                                                        <button
                                                                        type="button"
                                                                        className="btn btn-primary"
                                                                        style={{ padding: "1px 10px" }}
                                                                        onClick={(e) => this.resendAutoPriorityNo(data.ExhRegistrationID, data.Outstanding)}
                                                                    >
                                                                        Resend 
                                                                    </button>
                                                                    </div>:
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary"
                                                                        style={{ padding: "1px 10px" }}
                                                                        onClick={(e) => this.onGenerateClick(data.ExhRegistrationID, data.Outstanding)}
                                                                    >
                                                                        Generate
                                                                    </button>
                                                            }

                                                        </td>
                                                    }
                                                    {this.getWriteAccess() &&
                                                        <td>
                                                            {
                                                                data.isSecondPICreated == 1 ?
                                                                    <span className="text-primary">{''}</span> :
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary"
                                                                        style={{ padding: "1px 10px" }}
                                                                        onClick={(e) => this.toggleModal5(data.ExhRegistrationID)}
                                                                    >
                                                                        Generate
                                                                    </button>
                                                            }

                                                        </td>
                                                    }
                                                    {this.getDownloadAccess() &&
                                                        <td>
                                                            {data.IS_NoDueCertificate_Sent && <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                style={{ padding: "1px 10px", marginRight: "10px" }}
                                                                onClick={(e) => this.downloadNoDues(data.ExhibitionID, data.ExhRegistrationID)}
                                                            >
                                                                Download No Due
                                                            </button>}
                                                        </td>
                                                    }
                                                </tr>
                                            ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="mt-2 float-left">
                                    {
                                        exhibitorList.length > 0 ?
                                            <Pagination
                                                nPages={nPages}
                                                currentPage={currentPage}
                                                setCurrentPage={this.updateItem}
                                            /> : <></>
                                    }
                                    <div className="tab-rec " style={{ float: "right" }}>
                                        <p>{`${currentPage} of ${nPages} pages`}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={isOpen} onRequestClose={(e) => this.toggleModal(e)}>
                    <div
                        className="modal-dialog modal-dialog-centered bd-example-modal-lg"
                        id="detail"
                    // tabIndex={-1}
                    // aria-labelledby="detailLabel"
                    // aria-hidden="true"
                    >
                        <div className="modal-dialog modal-md">
                            <div className="modal-content Gen-btnpop">
                                <div className="modal-header pb-1">
                                    <h5 className="modal-title" id="detailLabel">
                                        <strong>Generate Priority No</strong>
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close pt-6"
                                        data-bs-dismiss="modal"
                                        style={{ fontSize: 12 }}
                                        aria-label="Close"
                                        onClick={(e) => this.toggleModal(e)}
                                    />
                                </div>
                                <div className="modal-body ">
                                    {
                                        exhibitorOutstanding != 0 ?
                                            <p className="text-danger"> The exhibitor has not yet made a 100% payment for the Space Booking.</p>
                                            : ""
                                    }
                                    {/* {/* <p className=""> The exhibitor has made a 100% payment for the exhibition.</p> */}
                                    <hr />

                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label" for="inlineRadio1" style={{ lineHeight: '15px' }}>
                                            <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                                value={generatePriorityNoType}
                                                checked={generatePriorityNoType == 1}
                                                onClick={(e) => this.setState({ generatePriorityNoType: 1 })}
                                            />Auto</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label" for="inlineRadio2" style={{ lineHeight: '15px' }}>
                                            <input class="form-check-input" type="radio" id="inlineRadio2"
                                                value={generatePriorityNoType}
                                                checked={generatePriorityNoType == 0}
                                                onClick={(e) => this.setState({ generatePriorityNoType: 0 })}
                                            />Manual</label>
                                    </div>

                                    {
                                        generatePriorityNoType == 0 &&
                                        <form>
                                            <select class="form-control mt-2" aria-label="Default select example"
                                                value={priorityNoId}
                                                onChange={(e) => this.setState({ priorityNoId: e.target.value, priorityNoErr: "" })}
                                            >
                                                <option selected>Select Priority Number</option>
                                                {
                                                    priorityNoList.length > 0 && priorityNoList.map((data) => (
                                                        <option key={data.ID} value={data.ID}>{data.PriorityNo}</option>
                                                    ))
                                                }

                                            </select>
                                            {<span className='text-danger text-start text-left d-block mt-1'>{priorityNoErr}</span>}
                                        </form>
                                    }

                                </div>
                                <div className="modal-footer p-1">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={(e) => this.onFinalGeneratePriorityNo(e)}
                                    >
                                        Generate
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal isOpen={this.state.isOpen5} onRequestClose={(e) => this.toggleModal5(e)}>
                    <div
                        className="modal-dialog modal-dialog-centered bd-example-modal-lg"
                        id="detail"
                    // tabIndex={-1}
                    // aria-labelledby="detailLabel"
                    // aria-hidden="true"
                    >
                        <div className="modal-dialog modal-md">
                            <div className="modal-content estimate-details">
                                <div className="modal-header pb-1">
                                    <h5 className="modal-title" id="detailLabel">
                                        <strong>Do you want to generate 2nd proforma invoice?</strong>
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close pt-6"
                                        data-bs-dismiss="modal"
                                        style={{ fontSize: 12 }}
                                        aria-label="Close"
                                        onClick={(e) => this.toggleModal5(e)}
                                    />
                                </div>
                            
                                <div className="modal-footer p-1">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={(e) => this.OnGenerate2ndPI(e)}
                                    >
                                        Yes
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={(e) => this.toggleModal5(e)}
                                    >
                                        No
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

            </>
        )
    }
}
export default withRouter(connect(null, {
    exhibitorList_action, sendNoDues_action, createTaxInvoice_action, accountExhibitorList_action, download_no_due_action, fill_action,
    submitAutoPriorityNo_action,resendPriorityNo_action, submitManualPriorityNo_action, getStallPossessionExport_action, generate2ndPI_action
})(Tax_Invoice_Exhibitors))