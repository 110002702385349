import { Component } from "react";
import { ToastContainer } from "react-toastify";
import Loader from "../../../utils/Loader";
import ExhibitorHeader from "../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../common_components/exhibitor_module_drawer";
import ExhibitorTopMenu from "../../../common_components/exhibitor_module_topmenu";
import { withRouter } from "../../../utils/withRouter";
import { getDetails_spaceBooking_action, edit_spaceBoking_action } from "../../../actions/exhibitor_module_actions/space_booking_action/spaceBooking_action"
import { connect } from "react-redux";
import { data } from "jquery";
import { cityActionId, countryActionId, pincodeActionId, stateActionId, toastError, toastSuccess } from "../../../utils/constants";
import { fill_action } from "../../../actions/fillCombo_action"
import { gstNoValidator, panNoValidator, tanNoValidator } from "../../../utils/Validator";
import Modal from "react-modal";
import HeaderWithLogo from "../../../common_components/header_with_logo";
import AdminDrawer from "../../../common_components/admin_module_drawer";
import { getDetails_exhibitorSpaceBooking_action, edit_exhibitorSpaceBoking_action } from "../../../actions/admin_module_action/exhibitor_spacebooking_action/exhibitor_spacebooking_action";

class ExhibitorsSpacebooking extends Component {

    // Initialize Constructor
    constructor(props) {
        super(props)
        //States    
        this.state = {
            isLoading: false,
            isAgree: false,
            isAgree2: false,
            isAgree3: false,
            isDisplayPincodeList: false,
            isDisplayCityList: false,
            isDisplayStateList: false,
            isDisplayCountryList: false,
            isDisplayBillPincodeList: false,
            isDisplayBillCityList: false,
            isDisplayBillStateList: false,
            isDisplayBillCountryList: false,
            isOtherPreFixPersonInch: false,
            isOtherOwnerFirstName: false,
            isOtherBillPersonInchFirst: false,
            primaryChapterName: "",
            stallArea: "",
            exbRegNo: "",
            isCorrespondence: false,
            primaryChapterId: "",
            isPreviousOrSameDate: false,
            isOpen: false,

            namePrefix: ['Mr', 'Ms', 'DR', 'Prof'],

            // Registration Details Fields
            preFixPersonInch: "Mr",
            otherPersonInchFirst: "",
            personInchFirst: "",
            personInchLast: "",
            regDesignation: "",
            regCompany: "",
            fasciaName: "",
            regAdd1: "",
            regAdd2: "",
            regPin: "",
            regCity: "",
            regState: "",
            regCountryId: "",
            regCountry: "",
            preRegMob1: "",
            regMob1: "",
            preRegMob2: "",
            regMob2: "",
            preRegTel: "",
            regTel: "",
            preRegTel1: "",
            regTel1: "",
            regTelFax: "",
            regPrimaryEmail: "",
            regSecondaryEmail: "",
            regWeb: "",
            otherOwnerFirstName: "",
            preOwnerNameFirst: "",
            ownerNameFirst: "",
            ownerNameLast: "",
            preOwnerMob: "",
            ownerMob: "",
            ownerEmail: "",

            // Bill Details field
            preFixBillPersonInch: "",
            otherBillPersonInchFirst: "",
            billPersonInchFirst: "",
            billPersonInchLast: "",
            billDesignation: "",
            billCompany: "",
            billAdd1: "",
            billAdd2: "",
            billPin: "",
            billCity: "",
            billState: "",
            billCountry: "",
            billCountryId: "",
            preBillMob1: "",
            billMob1: "",
            preBillMob2: "",
            billMob2: "",
            preBillTel: "",
            billTel: "",
            preBillTel1: "",
            billTel1: "",
            billTelFax: "",
            billPrimaryEmail: "",
            billSecondaryEmail: "",
            billWeb: "",

            ///lists
            countryList: [],
            tempCountryList: [],
            billTempCountryList: [],
            stateList: [],
            tempStateList: [],
            billTempStateList: [],
            cityList: [],
            tempCityList: [],
            billTempCityList: [],
            pincodeList: [],
            tempPincodeList: [],
            billTempPincodeList: [],
            primaryChapterHeaderList: [],
            subChapters: [],
            estimateDetails: [],
            lev2: [],
            lev3: [],
            lev4: [],


            // Company Credentials Fields
            companyRegNo: "",
            panNo: "",
            panName: "",
            gstNo: "",
            status: "",
            tanNo: "",
            uanNo: "",
            iecCode: "",
            accName: "",
            accNo: "",
            accType: "",
            accTypeOthers: "",
            bankName: "",
            branchName: "",
            ifsc: "",

            // Validation Error Messages
            personInchFirstErr: "",
            personInchLastErr: "",
            regDesignationErr: "",
            regCompanyErr: "",
            fasciaNameErr: "",
            regAdd1Err: "",
            regPinErr: "",
            regCityErr: "",
            regStateErr: "",
            regCountryErr: "",
            regMob1Err: "",
            regPrimaryEmailErr: "",
            ownerNameFirstErr: "",
            ownerEmailErr: "",
            billPersonInchFirstErr: "",
            billPersonInchLastErr: "",
            billDesignationErr: "",
            billCompanyErr: "",
            billAdd1Err: "",
            billPinErr: "",
            billCityErr: "",
            billStateErr: "",
            billCountryErr: "",
            billMob1Err: "",
            billPrimaryEmailErr: "",
            panNoErr: "",
            panNameErr: "",
            gstNoErr: "",
            statusErr: "",
            tanNoErr: "",
            uanNoErr: "",
            accNameErr: "",
            accNoErr: "",
            ifscErr: "",
            exbTableErr: ""
        }
        this.editSpaceBooking = this.editSpaceBooking.bind(this)
        this.getSpaceBookingDetails = this.getSpaceBookingDetails.bind(this)
        this.inputValidate = this.inputValidate.bind(this)
        this.getFillList = this.getFillList.bind(this)
        this.onPincodeSearch = this.onPincodeSearch.bind(this)
        this.renderPincodeList = this.renderPincodeList.bind(this)
        this.onCitySearch = this.onCitySearch.bind(this)
        this.renderCityList = this.renderCityList.bind(this)
        this.onStateSearch = this.onStateSearch.bind(this)
        this.renderStateList = this.renderStateList.bind(this)
        this.callStateList = this.callStateList.bind(this)
        this.onCountrySearch = this.onCountrySearch.bind(this)
        this.renderCountryList = this.renderCountryList.bind(this)
        this.maxLengthCheck = this.maxLengthCheck.bind(this)
        this.preventMinus = this.preventMinus.bind(this)
        this.onBillPincodeSearch = this.onBillPincodeSearch.bind(this)
        this.renderBillPincodeList = this.renderBillPincodeList.bind(this)
        this.onBillCitySearch = this.onBillCitySearch.bind(this)
        this.renderBillCityList = this.renderBillCityList.bind(this)
        this.onBillStateSearch = this.onBillStateSearch.bind(this)
        this.renderBillStateList = this.renderBillStateList.bind(this)
        this.callBillStateList = this.callBillStateList.bind(this)
        this.onBillCountrySearch = this.onBillCountrySearch.bind(this)
        this.renderBillCountryList = this.renderBillCountryList.bind(this)
        this.onEnterProductName = this.onEnterProductName.bind(this)
        this.onMachineDetailsChange = this.onMachineDetailsChange.bind(this)
        this.onEnterHSNCode = this.onEnterHSNCode.bind(this)
        this.onLengthChange = this.onLengthChange.bind(this)
        this.onBreadthChange = this.onBreadthChange.bind(this)
        this.onHeightChange = this.onHeightChange.bind(this)
        this.onFloorCapacityChange = this.onFloorCapacityChange.bind(this)
        this.onManufacturerNameChange = this.onManufacturerNameChange.bind(this)
        this.onMachineWeightChange = this.onMachineWeightChange.bind(this)
        this.onManufacturingCountryChange = this.onManufacturingCountryChange.bind(this)
        this.onAddClick = this.onAddClick.bind(this)
        this.record = this.record.bind(this)
        this.onCheckLevel2 = this.onCheckLevel2.bind(this)
        this.onCheckLevel3 = this.onCheckLevel3.bind(this)
        this.recordFour = this.recordFour.bind(this)
        this.onSelectLevel4 = this.onSelectLevel4.bind(this)
        this.onCancelClick = this.onCancelClick.bind(this)

    }

    // On Component Load
    componentDidMount() {
        this.getSpaceBookingDetails()

    }

    // On Cancel Click
    onCancelClick() {
        this.props.navigate('/exhibitorlist')
    }

    //Input Field Validations
    inputValidate() {
        const { personInchFirst, personInchLast, regDesignation, regCompany, fasciaName, regAdd1, regPin, regCity, regState, regCountry, regMob1,
            regTel, regPrimaryEmail, ownerNameFirst, ownerEmail, billDesignation, billCompany, billAdd1, billPin, billCity, billState,
            billCountry, billMob1, billMob2, billTel, billPrimaryEmail, billSecondaryEmail, billWeb, companyRegNo,
            panNo, panName, gstNo, status, tanNo, uanNo, iecCode, accName, accNo, bankName, branchName, ifsc, billPersonInchFirst,
            billPersonInchLast } = this.state

        if (personInchFirst == "") {
            this.setState({ personInchFirstErr: "Please enter person incharge first name" });
        }
        if (personInchLast == "") {
            this.setState({ personInchLastErr: "Please enter last name" });
        }
        if (regDesignation == "") {
            this.setState({ regDesignationErr: "Please Enter Designation" });
        }
        if (regCompany == "") {
            this.setState({ regCompanyErr: "Please Enter Company Name" });
        }
        if (fasciaName == "") {
            this.setState({ fasciaName: "Please enter fascia name" });
        }
        if (regAdd1 == "") {
            this.setState({ regAdd1Err: "Please Enter Address" });
        }
        if (regPin == "") {
            this.setState({ regPinErr: "Please Enter PinCode" });
        }
        if (regCity == "") {
            this.setState({ regCityErr: "Please Enter City" });
        }
        if (regState == "" && regCountry.toLowerCase().trim() == "india") {
            this.setState({ regStateErr: "Please Enter State" });
        }
        if (regCountry == "") {
            this.setState({ regCountryErr: "Please Enter Country" });
        }
        if (regMob1 == "") {
            this.setState({ regMob1Err: "Please Enter Mobile No." });
        }
        if (regPrimaryEmail == "") {
            this.setState({ regPrimaryEmailErr: "Please Enter Primary Email" });
        }
        if (ownerNameFirst == "") {
            this.setState({ ownerNameFirstErr: "Please Enter Owner Name" });
        }
        if (ownerEmail == "") {
            this.setState({ ownerEmailErr: "Please Enter Owner Email" });
        }
        if (billPersonInchFirst == "") {
            this.setState({ billPersonInchFirstErr: "Please enter person incharge first name" });
        }
        if (billPersonInchLast == "") {
            this.setState({ billPersonInchLastErr: "Please enter person incharge first name" });
        }
        if (billDesignation == "") {
            this.setState({ billDesignationErr: "Please Enter Designation" });
        }
        if (billCompany == "") {
            this.setState({ billCompanyErr: "Please Enter Billing Name" });
        }
        if (billAdd1 == "") {
            this.setState({ billAdd1Err: "Please Enter Address" });
        }
        if (billPin == "") {
            this.setState({ billPinErr: "Please Enter PinCode" });
        }
        if (billCity == "") {
            this.setState({ billCityErr: "Please Enter City" });
        }
        if (billState == "" && billCountry.toLowerCase().trim() == "india") {
            this.setState({ billStateErr: "Please Enter State" });
        }
        if (billCountry == "") {
            this.setState({ billCountryErr: "Please Enter Country" });
        }
        if (billMob1 == "") {
            this.setState({ billMob1Err: "Please Enter Mobile No." });
        }
        if (billPrimaryEmail == "") {
            this.setState({ billPrimaryEmailErr: "Please Enter Primary Email" });
        }
        if (regCountry != "" && regCountry != null && regCountry.toLowerCase().trim() == "india") {
            if (panNo == "") {
                this.setState({ panNoErr: "Please Enter PAN No." });
            }
            if (panName == "") {
                this.setState({ panNameErr: "Please Enter PAN Name" });
            }
            if (gstNo == "") {
                this.setState({ gstNoErr: "Please Enter GST No." });
            }
            if (tanNo == "") {
                this.setState({ tanNoErr: "Please Enter TAN No." });
            }
        }
        // if (accName == "" && regCountry != "" && regCountry != null && regCountry.toLowerCase().trim() == "india") {
        //     this.setState({ accNameErr: "Please Enter Account Name" });
        // }
        // if (accNo == "" && regCountry != "" && regCountry != null && regCountry.toLowerCase().trim() == "india") {
        //     this.setState({ accNoErr: "Please Enter Account No." });
        // }
        // if (ifsc == "" && regCountry != "" && regCountry != null && regCountry.toLowerCase().trim() == "india") {
        //     this.setState({ ifscErr: "Please Enter IFCI" });
        // }
    }

    // Edit Space Booking
    editSpaceBooking() {
        const { isLoading, personInchFirst, regDesignation, regCompany, fasciaName, regAdd1, regAdd2, regPin, regCity,
            regState, regCountry, regMob1, regMob2, regTel, regPrimaryEmail, regSecondaryEmail, regWeb,
            billDesignation, billCompany, billAdd1, billAdd2, billPin, billCity, billState, billCountry, billMob1,
            billMob2, billTel, billPrimaryEmail, billSecondaryEmail, billWeb, companyRegNo, panNo, panName, gstNo, status,
            tanNo, uanNo, iecCode, accName, accNo, accType, bankName, branchName, ifsc, isAgree, isAgree2, personInchLast,
            regTel1, regTelFax, preOwnerNameFirst, ownerNameFirst, ownerNameLast, ownerMob, ownerEmail, preFixBillPersonInch,
            billPersonInchFirst, billPersonInchLast, billTel1, billTelFax, preFixPersonInch, preRegTel, preRegTel1, preRegMob1,
            preRegMob2, preBillMob1, preBillMob2, preBillTel, preBillTel1, preOwnerMob, personInchLastErr,
            billPersonInchLastErr, regCompanyErr, fasciaNameErr, regAdd1Err, regCityErr, regStateErr, regPinErr,
            regCountryErr, personInchFirstErr, regDesignationErr, regMob1Err, regPrimaryEmailErr, ownerNameFirstErr,
            ownerEmailErr, billCompanyErr, billAdd1Err, billCityErr, billStateErr, billPinErr, billCountryErr,
            billPersonInchFirstErr, billDesignationErr, billPrimaryEmailErr, accNameErr, accNoErr, ifscErr, regCountryId,
            billCountryId, accTypeOthers, isOtherPreFixPersonInch, otherPersonInchFirst, isOtherOwnerFirstName,
            otherOwnerFirstName, isOtherBillPersonInchFirst, otherBillPersonInchFirst, exbRegNo, isCorrespondence, subChapters,
            primaryChapterId, exbTableErr, billMob1Err, countryList } = this.state

        /// Setting data in session storage For Update
        sessionStorage.setItem("sessionregisterTelephoneNoPre", preRegTel)
        sessionStorage.setItem("sessionregisterTelephoneNo1Pre", preRegTel1)
        sessionStorage.setItem("sessioninvoiceregisterTelephoneNoPre", preBillTel)
        sessionStorage.setItem("sessioninvoiceregisterTelephoneNo1Pre", preBillTel1)
        sessionStorage.setItem("sessionCountryNamePreRegisterMobileNo", preRegMob1)
        sessionStorage.setItem("sessionCountryNamePreRegisterAlternateMobileNo", preRegMob2)
        sessionStorage.setItem("sessionCountryNamePreOwnerMobileNo", preOwnerMob)
        sessionStorage.setItem("sessionCountryNamePreInvoiceregisterMobileNo", preBillMob1)
        sessionStorage.setItem("sessionCountryNamePreInvoiceregisterAlternateMobileNo", preBillMob1)

        var errMsg = ''
        errMsg = errMsg + panNoValidator(panNo)
        errMsg = errMsg + tanNoValidator(tanNo)
        errMsg = errMsg + gstNoValidator(gstNo)

        //Validate InputFields before edit
        this.inputValidate()

        var isnotContact = false
        if (regMob1 != '') {
            isnotContact = false
        } else {
            isnotContact = true
        }

        // validation for company info
        var isNotCompanyValidate = false
        if (regCountry.toLowerCase().trim() == "india") {
            if (
                panName != '' && panNo != '' && tanNo != '' && gstNo != ''
            ) {
                isNotCompanyValidate = false
            }
            else {
                isNotCompanyValidate = true
            }
        }

        var isNotStaBankValidate = false
        // if (regCountry.toLowerCase().trim() == "india") {
        //     if (
        //         accName != '' && accNo != '' && ifsc != ''
        //     ) {
        //         isNotStaBankValidate = false
        //     }
        //     else {
        //         isNotStaBankValidate = true
        //     }
        // }

        // Preparing SubChapter List For Udt 
        var list = []
        subChapters.map(data => {
            var data = {
                "ExhibitionID": parseInt(JSON.parse(sessionStorage.getItem('ExhibitorAccessData')).ExhibitionID),
                "ExhRegistrationID": JSON.parse(sessionStorage.getItem('ExhibitorAccessData')).ExhRegistrationID,
                "ChapterID": data.ChapterID,
                'ChapterName': data.ChapterName,
                "HSNCode": data.HSNCode,
                "MachineDetails": data.MachineDetails,
                "Length": data.Length,
                "Breadth": data.Breadth,
                "Height": data.Height,
                "FloorCapacity": data.FloorCapacity,
                "MachineWeight": data.MachineWeight,
                "ManufacturerName": data.ManufacturerName,
                "ManufacturingCountry": data.ManufacturingCountry,
            }
            list.push(data)
        })

        console.log("p0p0p0p0", regCountry, regCountryId)
        countryList.map(data => {

        })
        if (personInchLast != "" && billPersonInchLast != "" && regCompany != "" && fasciaName != "" && regAdd1 != "" &&
            regCity != "" && regPin != "" && (regCountry.toLowerCase().trim() == "india" ? regState != "" : true) && regCountry != ""
            && personInchFirst != "" && regDesignation != "" && regMob1 != ""
            && regPrimaryEmail != "" && ownerNameFirst != "" && ownerEmail != "" && billCompany != "" && billAdd1 != "" &&
            billCity != "" && billPin != "" && (billCountry.toLowerCase().trim() == "india" ? billState != "" : true) &&
            billCountry != "" && billPersonInchFirst != "" && billDesignation != "" &&
            billMob1 != "" && billPrimaryEmail != "" && isnotContact == false && isNotCompanyValidate == false &&
            isNotStaBankValidate == false) {
            console.log("0090909090909")
            if (errMsg != "") {
                toastError(errMsg)
            } else {
                if (personInchLastErr == "" && billPersonInchLastErr == "" && regCompanyErr == "" && fasciaNameErr == "" &&
                    regAdd1Err == "" && regCityErr == "" && (regCountry.toLowerCase().trim() == "" ? regStateErr == "" : true)
                    && regPinErr == "" && regCountryErr == "" &&
                    personInchFirstErr == "" && regDesignationErr == "" && regMob1Err == "" && regPrimaryEmailErr == "" &&
                    ownerNameFirstErr == "" && ownerEmailErr == "" && billCompanyErr == "" && billAdd1Err == "" &&
                    billCityErr == "" && (billCountry.toLowerCase().trim() == "india" ? billStateErr == "" : true) && billPinErr == "" && billCountryErr == "" &&
                    billPersonInchFirstErr == "" && billDesignationErr == "" && billMob1Err == "" && billPrimaryEmailErr == ""
                    && accNameErr == "" && accNoErr == "" && ifscErr == "") {
                    console.log("x9x9x9x99x9x9")
                    if (exbTableErr == "") {
                        this.setState({ isLoading: true })
                        this.props.edit_exhibitorSpaceBoking_action(isCorrespondence, exbRegNo, regCountryId, billCountryId, regCompany, fasciaName, regAdd1, regAdd2,
                            regCity, regState, regPin, regCountry, (isOtherPreFixPersonInch ? otherPersonInchFirst : preFixPersonInch),
                            personInchFirst, personInchLast, regDesignation,
                            regMob1, regMob2, regTel, regTel1, regTelFax, regPrimaryEmail, regSecondaryEmail, regWeb,
                            (isOtherOwnerFirstName ? otherOwnerFirstName : preOwnerNameFirst), ownerNameFirst, ownerNameLast, ownerMob, ownerEmail, billCompany, billAdd1,
                            billAdd2, billCity, billState, billPin, billCountry, (isOtherBillPersonInchFirst ? otherBillPersonInchFirst : preFixBillPersonInch), billPersonInchFirst,
                            billPersonInchLast, billDesignation, billMob1, billMob2, billTel, billTel1, billTelFax, billPrimaryEmail,
                            billSecondaryEmail, billWeb, panName, panNo, tanNo, gstNo, companyRegNo, iecCode, accName, accNo,
                            accType == "Others" ? accTypeOthers : accType, bankName, branchName, ifsc, parseInt(primaryChapterId), list)
                            .then((data) => {
                                if (data.error != 1) {
                                    console.log("EditSpaceBooking", data.data)
                                    toastSuccess("Data Updated Successfully")
                                    setTimeout(() => {
                                        this.setState({ isLoading: false })
                                        setTimeout(() => {
                                            this.props.navigate('/exhibitorlist')
                                        }, 1000);
                                    }, 300);
                                } else {
                                    this.setState({ isLoading: false })
                                    toastError(data.msg)
                                }
                            }).catch((err) => {
                                console.log(err)
                            })
                    }
                    else {
                        toastError("Please Correct Length And Breadth Size")
                    }
                }
                else {
                    toastError("Enter Mandatory Fields")
                }
            }
        }
        else {
            toastError("Enter Mandatory Fields")
        }
    }

    // Get Space Booking Details
    getSpaceBookingDetails() {
        const { namePrefix } = this.state
        this.setState({ isLoading: true })
        this.props.getDetails_exhibitorSpaceBooking_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log("Get Space Booking Details", data.data)
                    console.log("00000000000", data.data['primaryChapterListDetails'])
                    const countryId = data.data['registrationDetails'][0]['CountryID'] ? data.data['registrationDetails'][0]['CountryID'] : ''

                    // Primary Chapter Name 
                    const primaryChapterList = data.data['primaryChapterListDetails']['primaryChapter'].filter(obj => obj.IsSelect == true)
                    const primaryChpName = primaryChapterList[0].ChapterName
                    const PrimaryChapId = primaryChapterList[0].ChapterID

                    // Chapter Level 2 Level 3 And Level 4
                    var allChap = []
                    const level2 = data.data['primaryChapterListDetails']['level2'].filter(obj => obj.IsSelect == true)
                    allChap.push(...level2)
                    const level3 = data.data['primaryChapterListDetails']['level3'].filter(obj => obj.IsSelect == true)
                    allChap.push(...level3)
                    const level4 = data.data['primaryChapterListDetails']['level4'].filter(obj => obj.IsSelect == true)
                    allChap.push(...level4)

                    //filterinh all sub chapters only checkbox
                    var finalSubChap = allChap.filter(element => element.IsCheckbox == true)


                    // Primary Chapter Stall Area
                    const primaryChapterStallArea = data.data['primaryChapterHeaderDetails'][0].StallArea



                    // Fill List Calling ForDropDown
                    this.getFillList(countryActionId, '', '', countryId, '', '');
                    this.getFillList(stateActionId, '', '', countryId, '', '');
                    this.getFillList(cityActionId, '', '', countryId, '', '');
                    this.getFillList(pincodeActionId, '', '', countryId, '', '');


                    // Split Phone Numbers and country Code 
                    // Registration Mobile No
                    var mob1 = data.data['registrationDetails'][0]['MobileNo']
                    const parts = mob1.split("-");
                    const regMob1Code = parts[0]
                    const regMob1No = parts[1]
                    // Registration Alternate Mobile No
                    var mob2 = data.data['registrationDetails'][0]['AlternateMobileNo']
                    const parts2 = mob2.split("-")
                    const regMob2Code = parts2[0]
                    const regMob2No = parts2[1]
                    // Registration Telephone No
                    var tel = data.data['registrationDetails'][0]['Telephone']
                    var parts3 = tel.split("-")
                    const regTelCode = parts3[0]
                    const regTelNo = parts3[1]
                    // Registration Telephone No 
                    var tel1 = data.data['registrationDetails'][0]['Telephone1']
                    const parts4 = tel1.split("-")
                    const tel1Code = parts4[0]
                    const tel1No = parts4[1]
                    // Registration Owner Mobile No 
                    var ownerMobile = data.data['registrationDetails'][0]['Mobile']
                    const parts5 = ownerMobile.split("-")
                    const ownerMobileCode = parts5[0]
                    const ownerMobileNumber = parts5[1]
                    // Invoice Mobile No
                    var invMob = data.data['registrationDetails'][0]['InvMobileNo']
                    const parts6 = invMob.split("-")
                    const invMobCode = parts6[0]
                    const invMobNo = parts6[1]
                    // Invoice Alternate Mob No
                    var invMob2 = data.data['registrationDetails'][0]['InvAlternateMobileNo']
                    const parts7 = invMob2.split("-")
                    const invMob2Code = parts7[0]
                    const invMob2No = parts7[1]
                    // Invoice TelePhone No 
                    var invTel = data.data['registrationDetails'][0]['InvTelephone']
                    const parts8 = invTel.split("-")
                    const invTelCode = parts8[0]
                    const invTelNo = parts8[1]
                    // Invoice TelePhone 1 No 
                    var invTel1 = data.data['registrationDetails'][0]['InvTelephone1']
                    const parts9 = invTel1.split("-")
                    const invTel1Code = parts9[0]
                    const invTel1No = parts9[1]
                    async function seqnoFormat(arr) {
                        for (var i = 0; i < arr.length; i++) {
                            if (arr[i].seqno != '' && arr[i].seqno != null) {
                                arr[i].ChapterName.trim()
                                // console.log(arr[i].ChapterName)
                                if (/^\d$/.test(arr[i].ChapterName[0])) {
                                    for (var j = 0; j < arr[i].ChapterName.length; j++) {
                                        if (arr[i].ChapterName[j] == ' ') {
                                            var str = arr[i].ChapterName.slice(j)
                                            var finalName = arr[i].seqno + str
                                            arr[i].ChapterName = finalName
                                            // console.log("primaryChapterListActionId 121212", i, finalName)
                                            break
                                        }
                                    }
                                } else {
                                    var str = arr[i].ChapterName
                                    var finalName = arr[i].seqno + ' ' + str
                                    arr[i].ChapterName = finalName
                                }
                            }
                        }
                        return
                    }
                    for (var i = 0; i < data.data['primaryChapterListDetails']['primaryChapter'].length; i++) {
                        for (var j = 0; j < data.data['primaryChapterListDetails']['primaryChapter'].length - 1 - i; j++) {
                            var seq1 = data.data['primaryChapterListDetails']['primaryChapter'][j].seqno
                            var seq2 = data.data['primaryChapterListDetails']['primaryChapter'][j + 1].seqno
                            if ((parseFloat(seq1) > parseFloat(seq2))) {
                                var temp = data.data['primaryChapterListDetails']['primaryChapter'][j]
                                data.data['primaryChapterListDetails']['primaryChapter'][j] = data.data['primaryChapterListDetails']['primaryChapter'][j + 1]
                                data.data['primaryChapterListDetails']['primaryChapter'][j + 1] = temp;
                                // console.log(i, j, arr[j])
                            }
                        }
                        if (data.data['primaryChapterListDetails']['primaryChapter'][i].seqno == null || data.data['primaryChapterListDetails']['primaryChapter'][i].seqno == undefined) {
                            console.log(data.data['primaryChapterListDetails']['primaryChapter'][i].seqno, i)
                        }
                        // seqnoFormat(data.data['primaryChapterListDetails']['primaryChapter'])
                    }
                    for (var i = 0; i < data.data['primaryChapterListDetails']['level2'].length; i++) {
                        for (var j = 0; j < data.data['primaryChapterListDetails']['level2'].length - 1 - i; j++) {
                            var seq1 = data.data['primaryChapterListDetails']['level2'][j].seqno
                            var seq2 = data.data['primaryChapterListDetails']['level2'][j + 1].seqno
                            if ((parseFloat(seq1) > parseFloat(seq2))) {
                                var temp = data.data['primaryChapterListDetails']['level2'][j]
                                data.data['primaryChapterListDetails']['level2'][j] = data.data['primaryChapterListDetails']['level2'][j + 1]
                                data.data['primaryChapterListDetails']['level2'][j + 1] = temp;
                                // console.log(i, j, arr[j])
                            }
                        }
                        if (data.data['primaryChapterListDetails']['level2'][i].seqno == null || data.data['primaryChapterListDetails']['level2'][i].seqno == undefined) {
                            console.log(data.data['primaryChapterListDetails']['level2'][i].seqno, i)
                        }
                        seqnoFormat(data.data['primaryChapterListDetails']['level2'])
                    }
                    // console.log("prr", data.data['level2'])
                    for (var i = 0; i < data.data['primaryChapterListDetails']['level3'].length; i++) {
                        for (var j = 0; j < data.data['primaryChapterListDetails']['level3'].length - 1 - i; j++) {

                            var seq1 = data.data['primaryChapterListDetails']['level3'][j].seqno
                            seq1 = seq1 != null && seq1.replaceAll('.', '')
                            var seq2 = data.data['primaryChapterListDetails']['level3'][j + 1].seqno
                            seq2 = seq2 != null && seq2.replaceAll('.', '')

                            if ((parseFloat(seq1) > parseFloat(seq2))) {
                                var temp = data.data['primaryChapterListDetails']['level3'][j]
                                data.data['primaryChapterListDetails']['level3'][j] = data.data['primaryChapterListDetails']['level3'][j + 1]
                                data.data['primaryChapterListDetails']['level3'][j + 1] = temp;
                            }
                        }
                        if (data.data['primaryChapterListDetails']['level3'][i].seqno == null || data.data['primaryChapterListDetails']['level3'][i].seqno == undefined) {
                            console.log(data.data['primaryChapterListDetails']['level3'][i].seqno, i)
                        }
                        seqnoFormat(data.data['primaryChapterListDetails']['level3'])
                    }
                    for (var i = 0; i < data.data['primaryChapterListDetails']['level4'].length; i++) {
                        for (var j = 0; j < data.data['primaryChapterListDetails']['level4'].length - 1 - i; j++) {
                            var seq1 = data.data['primaryChapterListDetails']['level4'][j].seqno
                            seq1 = seq1 != null && seq1.replaceAll('.', '')
                            var seq2 = data.data['primaryChapterListDetails']['level4'][j + 1].seqno
                            seq2 = seq2 != null && seq2.replaceAll('.', '')
                            if ((parseFloat(seq1) > parseFloat(seq2))) {
                                var temp = data.data['primaryChapterListDetails']['level4'][j]
                                data.data['primaryChapterListDetails']['level4'][j] = data.data['primaryChapterListDetails']['level4'][j + 1]
                                data.data['primaryChapterListDetails']['level4'][j + 1] = temp;
                                // console.log(i, j, arr[j])
                            }
                        }
                        if (data.data['primaryChapterListDetails']['level4'][i].seqno == null || data.data['primaryChapterListDetails']['level4'][i].seqno == undefined) {
                            console.log(data.data['primaryChapterListDetails']['level4'][i].seqno, i)
                        }
                        seqnoFormat(data.data['primaryChapterListDetails']['level4'])
                    }

                    for (var i = 0; i < data.data['primaryChapterListDetails']['level2'].length; i++) {
                        data.data['primaryChapterListDetails']['level2'][i].isHighlight = false;
                    }
                    this.setState({
                        isLoading: false,
                        isCorrespondence: data.data['registrationDetails'][0]['SameAsCorrespondence'] != null ? data.data['registrationDetails'][0]['SameAsCorrespondence'] : '',
                        exbRegNo: data.data['registrationDetails'][0]['ExhRegistrationNo'] != null ? data.data['registrationDetails'][0]['ExhRegistrationNo'] : '',
                        personInchFirst: data.data['registrationDetails'][0]['PersonIncharge'] != null ? data.data['registrationDetails'][0]['PersonIncharge'] : '',
                        preFixPersonInch: data.data['registrationDetails'][0]['Title_PersonIncharge'] != null ? data.data['registrationDetails'][0]['Title_PersonIncharge'] : '',
                        isOtherPreFixPersonInch: data.data['registrationDetails'][0]['Title_PersonIncharge'] != null && data.data['registrationDetails'][0]['Title_PersonIncharge'] != "" && namePrefix.includes(data.data['registrationDetails'][0]['Title_PersonIncharge']) ? false : true,
                        personInchLast: data.data['registrationDetails'][0]['LastNamePersonIncharge'] != null ? data.data['registrationDetails'][0]['LastNamePersonIncharge'] : '',
                        regDesignation: data.data['registrationDetails'][0]['Designation'] != null ? data.data['registrationDetails'][0]['Designation'] : '',
                        regCompany: data.data['registrationDetails'][0]['CompanyName'] != null ? data.data['registrationDetails'][0]['CompanyName'] : '',
                        fasciaName: data.data['registrationDetails'][0]['FasciaName'] != null ? data.data['registrationDetails'][0]['FasciaName'] : '',
                        regAdd1: data.data['registrationDetails'][0]['Address1'] != null ? data.data['registrationDetails'][0]['Address1'] : '',
                        regAdd2: data.data['registrationDetails'][0]['Address2'] != null ? data.data['registrationDetails'][0]['Address2'] : '',
                        regPin: data.data['registrationDetails'][0]['Pin'] != null ? data.data['registrationDetails'][0]['Pin'] : '',
                        regCity: data.data['registrationDetails'][0]['City'] != null ? data.data['registrationDetails'][0]['City'] : '',
                        regState: data.data['registrationDetails'][0]['State'] != null ? data.data['registrationDetails'][0]['State'] : '',
                        regCountry: data.data['registrationDetails'][0]['CountryName'] != null ? data.data['registrationDetails'][0]['CountryName'].trim() : '',
                        regCountryId: data.data['registrationDetails'][0]['CountryID'] != null ? data.data['registrationDetails'][0]['CountryID'] : '',
                        regMob1: data.data['registrationDetails'][0]['MobileNo'] != null ? regMob1No : '',
                        preRegMob1: data.data['registrationDetails'][0]['MobileNo'] != null ? regMob1Code : '',
                        regMob2: data.data['registrationDetails'][0]['AlternateMobileNo'] != null ? regMob2No : '',
                        preRegMob2: data.data['registrationDetails'][0]['AlternateMobileNo'] != null ? regMob2Code : '',
                        regTel: data.data['registrationDetails'][0]['Telephone'] != null ? regTelNo : '',
                        preRegTel: data.data['registrationDetails'][0]['Telephone'] != null ? regTelCode : '',
                        regTel1: data.data['registrationDetails'][0]['Telephone1'] != null ? tel1No : '',
                        preRegTel1: data.data['registrationDetails'][0]['Telephone1'] != null ? tel1Code : '',
                        regTelFax: data.data['registrationDetails'][0]['TeleFax'] != null ? data.data['registrationDetails'][0]['TeleFax'] : '',
                        regPrimaryEmail: data.data['registrationDetails'][0]['PrimaryEmail'] != null ? data.data['registrationDetails'][0]['PrimaryEmail'] : '',
                        regSecondaryEmail: data.data['registrationDetails'][0]['SecondaryEmail'] != null ? data.data['registrationDetails'][0]['SecondaryEmail'] : '',
                        regWeb: data.data['registrationDetails'][0]['Website'] != null ? data.data['registrationDetails'][0]['Website'] : '',
                        ownerNameFirst: data.data['registrationDetails'][0]['Name_Of_CEO_MD_President_Owner'] != null ? data.data['registrationDetails'][0]['Name_Of_CEO_MD_President_Owner'] : '',
                        preOwnerNameFirst: data.data['registrationDetails'][0]['Title_Name_Of_CEO_MD_President_Owner'] != null ? data.data['registrationDetails'][0]['Title_Name_Of_CEO_MD_President_Owner'] : '',
                        isOtherOwnerFirstName: data.data['registrationDetails'][0]['Title_Name_Of_CEO_MD_President_Owner'] != null && data.data['registrationDetails'][0]['Title_Name_Of_CEO_MD_President_Owner'] != "" && namePrefix.includes(data.data['registrationDetails'][0]['Title_Name_Of_CEO_MD_President_Owner']) ? false : true,
                        ownerNameLast: data.data['registrationDetails'][0]['LastName'] != null ? data.data['registrationDetails'][0]['LastName'] : '',
                        ownerMob: data.data['registrationDetails'][0]['Mobile'] != null ? ownerMobileNumber : '',
                        preOwnerMob: data.data['registrationDetails'][0]['Mobile'] != null ? ownerMobileCode : '',
                        ownerEmail: data.data['registrationDetails'][0]['Email'] != null ? data.data['registrationDetails'][0]['Email'] : '',
                        billPersonInchFirst: data.data['registrationDetails'][0]['InvPersonIncharge'] != null ? data.data['registrationDetails'][0]['InvPersonIncharge'] : '',
                        preFixBillPersonInch: data.data['registrationDetails'][0]['Title_InvPersonIncharge'] != null ? data.data['registrationDetails'][0]['Title_InvPersonIncharge'] : '',
                        isOtherBillPersonInchFirst: data.data['registrationDetails'][0]['Title_InvPersonIncharge'] != null && data.data['registrationDetails'][0]['Title_InvPersonIncharge'] != "" && namePrefix.includes(data.data['registrationDetails'][0]['Title_InvPersonIncharge']) ? false : true,
                        billPersonInchLast: data.data['registrationDetails'][0]['InvLastNamePersonIncharge'] != null ? data.data['registrationDetails'][0]['InvLastNamePersonIncharge'] : '',
                        billDesignation: data.data['registrationDetails'][0]['InvDesignation'] != null ? data.data['registrationDetails'][0]['InvDesignation'] : '',
                        billCompany: data.data['registrationDetails'][0]['InvCompanyName'] != null ? data.data['registrationDetails'][0]['InvCompanyName'] : '',
                        billAdd1: data.data['registrationDetails'][0]['InvAddress1'] != null ? data.data['registrationDetails'][0]['InvAddress1'] : '',
                        billAdd2: data.data['registrationDetails'][0]['InvAddress2'] != null ? data.data['registrationDetails'][0]['InvAddress2'] : '',
                        billPin: data.data['registrationDetails'][0]['InvPin'] != null ? data.data['registrationDetails'][0]['InvPin'] : '',
                        billCity: data.data['registrationDetails'][0]['InvCity'] != null ? data.data['registrationDetails'][0]['InvCity'] : '',
                        billState: data.data['registrationDetails'][0]['InvState'] != null ? data.data['registrationDetails'][0]['InvState'] : '',
                        billCountry: data.data['registrationDetails'][0]['InvCountryName'] != null ? data.data['registrationDetails'][0]['InvCountryName'].trim() : '',

                        billCountryId: data.data['registrationDetails'][0]['InvCountryID'] != null ? data.data['registrationDetails'][0]['InvCountryID'] : '',
                        billMob1: data.data['registrationDetails'][0]['InvMobileNo'] != null ? invMobNo : '',
                        preBillMob1: data.data['registrationDetails'][0]['InvMobileNo'] != null ? invMobCode : '',
                        billMob2: data.data['registrationDetails'][0]['InvAlternateMobileNo'] != null ? invMob2No : '',
                        preBillMob2: data.data['registrationDetails'][0]['InvAlternateMobileNo'] != null ? invMob2Code : '',
                        billTel: data.data['registrationDetails'][0]['InvTelephone'] != null ? invTelNo : '',
                        preBillTel: data.data['registrationDetails'][0]['InvTelephone'] != null ? invTelCode : '',
                        billTel1: data.data['registrationDetails'][0]['InvTelephone1'] != null ? invTel1No : '',
                        preBillTel1: data.data['registrationDetails'][0]['InvTelephone1'] != null ? invTel1Code : '',
                        billTelFax: data.data['registrationDetails'][0]['InvTeleFax'] != null ? data.data['registrationDetails'][0]['InvTeleFax'] : '',
                        billPrimaryEmail: data.data['registrationDetails'][0]['InvPrimaryEmail'] != null ? data.data['registrationDetails'][0]['InvPrimaryEmail'] : '',
                        billSecondaryEmail: data.data['registrationDetails'][0]['InvSecondaryEmail'] != null ? data.data['registrationDetails'][0]['InvSecondaryEmail'] : '',
                        billWeb: data.data['registrationDetails'][0]['InvWebsite'] != null ? data.data['registrationDetails'][0]['InvWebsite'] : '',
                        companyRegNo: data.data['registrationDetails'][0]['CompanyRegistrationNo'] != null ? data.data['registrationDetails'][0]['CompanyRegistrationNo'] : '',
                        panNo: data.data['registrationDetails'][0]['PanNo'] != null ? data.data['registrationDetails'][0]['PanNo'] : '',
                        panName: data.data['registrationDetails'][0]['NameOnPanCard'] != null ? data.data['registrationDetails'][0]['NameOnPanCard'] : '',
                        gstNo: data.data['registrationDetails'][0]['GSTNo'] != null ? data.data['registrationDetails'][0]['GSTNo'] : '',
                        tanNo: data.data['registrationDetails'][0]['Tan'] != null ? data.data['registrationDetails'][0]['Tan'] : '',
                        iecCode: data.data['registrationDetails'][0]['IECCode'] != null ? data.data['registrationDetails'][0]['IECCode'] : '',
                        accName: data.data['registrationDetails'][0]['AccountName'] != null ? data.data['registrationDetails'][0]['AccountName'] : '',
                        accNo: data.data['registrationDetails'][0]['AccountNo'] != null ? data.data['registrationDetails'][0]['AccountNo'] : '',
                        accType: data.data['registrationDetails'][0]['AccountType'] != null ? data.data['registrationDetails'][0]['AccountType'] : '',
                        bankName: data.data['registrationDetails'][0]['BankName'] != null ? data.data['registrationDetails'][0]['BankName'] : '',
                        branchName: data.data['registrationDetails'][0]['BranchName'] != null ? data.data['registrationDetails'][0]['BranchName'] : '',
                        ifsc: data.data['registrationDetails'][0]['SwiftCode_IFSCCode'] != null ? data.data['registrationDetails'][0]['SwiftCode_IFSCCode'] : '',
                        primaryChapterHeaderList: data.data['primaryChapterHeaderDetails'].length > 0 ? data.data['primaryChapterHeaderDetails'] : '',
                        primaryChapterName: primaryChpName,
                        subChapters: finalSubChap,
                        stallArea: primaryChapterStallArea,
                        estimateDetails: data.data['estimateDetails'].length > 0 ? data.data['estimateDetails'] : '',
                        isAgree: data.data['descriptionDetails'][0]['Is_AgreeClaim'] != null ? data.data['descriptionDetails'][0]['Is_AgreeClaim'] : '',
                        isAgree2: data.data['descriptionDetails'][0]['Is_AgreeRules'] != null ? data.data['descriptionDetails'][0]['Is_AgreeRules'] : '',
                        isAgree3: data.data['descriptionDetails'][0]['Is_AgreeMFG'] != null ? data.data['descriptionDetails'][0]['Is_AgreeMFG'] : '',
                        primaryChapterId: PrimaryChapId,
                        lev2: data.data['primaryChapterListDetails']['level2'].length > 0 ? data.data['primaryChapterListDetails']['level2'] : [],
                        lev3: data.data['primaryChapterListDetails']['level3'].length > 0 ? data.data['primaryChapterListDetails']['level3'] : [],
                        lev4: data.data['primaryChapterListDetails']['level4'].length > 0 ? data.data['primaryChapterListDetails']['level4'] : [],

                    })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            }).catch((err) => {
                console.log(err)
            })
    }

    // Get Fill Lists
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value)
            .then((data) => {
                if (data.error != 1) {
                    // country list
                    if (actionID === countryActionId) {
                        console.log("country data", data.data)
                        this.setState({
                            countryList: data.data,
                            tempCountryList: data.data,
                            billTempCountryList: data.data,
                            isLoading: false,
                        })
                    }
                    // state list
                    if (actionID === stateActionId) {
                        console.log("stateList data", data.data)
                        this.setState({
                            stateList: data.data,
                            tempStateList: data.data,
                            billTempStateList: data.data,
                            isLoading: false,
                        })
                    }
                    // city list
                    if (actionID === cityActionId) {
                        console.log("city data", data.data)
                        this.setState({
                            cityList: data.data,
                            tempCityList: data.data,
                            billTempCityList: data.data,
                            isLoading: false,
                        })
                    }
                    // pincode
                    if (actionID === pincodeActionId) {
                        console.log("pincode data", data.data)
                        this.setState({
                            pincodeList: data.data,
                            tempPincodeList: data.data,
                            billTempPincodeList: data.data,
                            isLoading: false,
                        })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    //Pincode Search
    onPincodeSearch(e) {
        const { pincodeList, tempPincodeList } = this.state
        // console.log("onPincodeSearch 123", tempPincodeList)
        if (e.target.value != '') {
            var searchPincodeList = tempPincodeList.filter(data => {
                return (
                    data
                        .Pincode
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            // console.log('pincodeCount', searchPincodeList.length)
            this.setState({
                regPin: e.target.value,
                regPinErr: '',
                tempPincodeList: searchPincodeList,
                isDisplayPincodeList: true
            })
        } else {
            this.setState({
                regPin: e.target.value,
                tempPincodeList: pincodeList,
                isDisplayPincodeList: false
            })
        }
    }

    // render pincode
    renderPincodeList() {
        const { tempPincodeList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempPincodeList.length > 0 && tempPincodeList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({
                                regPin: data.Pincode,
                                isDisplayPincodeList: false
                            })}
                        >{data.Pincode}</li>
                    ))
                }
            </ul>
        )
    }

    // search city
    onCitySearch(e) {
        const { cityList, tempCityList } = this.state
        if (e.target.value != '') {
            var searchCityList = tempCityList.filter(data => {
                return (
                    data
                        .Description
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            this.setState({
                regCity: e.target.value,
                regCityErr: '',
                tempCityList: searchCityList,
                isDisplayCityList: true
            })
        } else {
            this.setState({
                regCity: e.target.value,
                tempCityList: cityList,
                isDisplayCityList: false
            })
        }
    }

    // render city
    renderCityList() {
        const { tempCityList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempCityList.length > 0 && tempCityList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({ regCity: data.Description, isDisplayCityList: false })}
                        >{data.Description}</li>
                    ))
                }
            </ul>
        )
    }

    // search state
    onStateSearch(e) {
        const { tempStateList, stateList } = this.state
        console.log("onStateSearch")
        if (e.target.value != '') {
            var searchStateList = tempStateList.filter(data => {
                return (
                    data
                        .Description
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            this.setState({
                regState: e.target.value,
                regStateErr: '',
                tempStateList: searchStateList,
                isDisplayStateList: true
            })
        } else {
            this.setState({
                regState: e.target.value,
                tempStateList: stateList,
                isDisplayStateList: false
            })
        }
    }

    // state list
    callStateList(value) {

        const { regState, regCountryId } = this.state
        this.setState({ regState: value, isDisplayStateList: false })
        this.getFillList(stateActionId, '', '', '', regState, '', '')
    }

    // render state
    renderStateList() {
        const { tempStateList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempStateList.length > 0 && tempStateList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.callStateList(data.Description)}
                        >{data.Description}</li>

                    ))
                }
            </ul>
        )
    }

    // Search Country
    onCountrySearch(e) {
        const { countryList, tempCountryList } = this.state
        this.setState({ regCountry: '' })
        if (e.target.value != '') {
            console.log("tempCountryList ++++----------->", tempCountryList)

            var searchCoutryList = tempCountryList.filter(data => {

                return (
                    data
                        .Description
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            console.log("searchCoutryList ++++----------->", searchCoutryList)

            this.setState({
                regCountry: e.target.value,
                // regCountryId: searchCoutryList[0]['CountryCode'] != null ? searchCoutryList[0]['CountryCode'] : "",
                regCountryErr: '',
                tempCountryList: searchCoutryList,
                isDisplayCountryList: true
            })
        }
        else {
            console.log("123123123=======----------->", e.target.value, e.target.label)

            this.setState({
                regCountry: e.target.value,
                tempCountryList: countryList,
                isDisplayCountryList: false
            })
        }
    }

    // render Country
    renderCountryList() {
        const { tempCountryList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempCountryList.length > 0 && tempCountryList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({ regCountry: data.Description, regCountryId: data.Code, isDisplayCountryList: false })}
                        >{data.Description}</li>
                    ))
                }
            </ul>
        )
    }

    maxLengthCheck = (object, maxLength) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    preventMinus = (e) => {
        (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    }


    handlePaste = (event) => {
        const pastedValue = event.clipboardData.getData('text/plain');
        const sanitizedValue = pastedValue.replace(/[-.eE]/g, ''); // Remove '-', '.', 'e', 'E' characters

        // Replace the input value with the sanitized value
        document.execCommand('insertText', false, sanitizedValue);

        event.preventDefault(); // Prevent the default paste behavior
    }

    // Bill Pincode Search
    onBillPincodeSearch(e) {
        const { pincodeList, billTempPincodeList, billCity } = this.state
        if (e.target.value != '') {
            var searchBillPincodeList = billTempPincodeList.filter(x => {
                return (
                    x
                        .Pincode
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            this.setState({
                billPin: e.target.value,
                billPinErr: '',
                billTempPincodeList: searchBillPincodeList,
                isDisplayBillPincodeList: true
            })
        } else {
            this.setState({
                billPin: e.target.value,
                billTempPincodeList: pincodeList,
                isDisplayBillPincodeList: false
            })
        }
    }

    // render Bill pincode
    renderBillPincodeList() {
        const { billTempPincodeList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    billTempPincodeList.length > 0 && billTempPincodeList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({
                                billPin: data.Pincode,
                                isDisplayBillPincodeList: false
                            })}
                        >{data.Pincode}</li>
                    ))
                }
            </ul>
        )
    }

    // search Bill city
    onBillCitySearch(e) {
        const { cityList, billTempCityList } = this.state
        if (e.target.value != '') {
            var searchBillCityList = billTempCityList.filter(data => {
                return (
                    data
                        .Description
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            this.setState({
                billCity: e.target.value,
                billCityErr: '',
                billTempCityList: searchBillCityList,
                isDisplayBillCityList: true
            })
        } else {
            this.setState({
                billCity: e.target.value,
                billTempCityList: cityList,
                isDisplayBillCityList: false
            })
        }
    }

    // render Bill city
    renderBillCityList() {
        const { billTempCityList } = this.state
        return (
            <ul className="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    billTempCityList.length > 0 && billTempCityList.map(data => (
                        <li className="list-group-item" key={Math.random()}
                            onClick={e => this.setState({ billCity: data.Description, isDisplayBillCityList: false })}
                        >{data.Description}</li>
                    ))
                }
            </ul>
        )
    }

    // search state
    onBillStateSearch(e) {
        const { stateList, billTempStateList } = this.state
        console.log("onStateSearch")
        if (e.target.value != '') {
            var searchBillStateList = billTempStateList.filter(data => {
                return (
                    data
                        .Description
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            this.setState({
                billState: e.target.value,
                billStateErr: '',
                billTempStateList: searchBillStateList,
                isDisplayBillStateList: true
            })
        } else {
            this.setState({
                billState: e.target.value,
                billTempStateList: stateList,
                isDisplayBillStateList: false
            })
        }
    }

    // state list
    callBillStateList(value) {
        const { billState, billTempStateList, billCountryId } = this.state
        this.setState({ billState: value, isDisplayBillStateList: false })
        this.getFillList(stateActionId, '', '', '', billState, '', '')
    }

    // render Bill state
    renderBillStateList() {
        const { billTempStateList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    billTempStateList.length > 0 && billTempStateList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.callBillStateList(data.Description)}
                        >{data.Description}</li>
                    ))
                }
            </ul>
        )
    }

    // search Bill city
    onBillCountrySearch(e) {
        const { countryList, billTempCountryList } = this.state
        if (e.target.value != '') {
            var searchBillCountryList = billTempCountryList.filter(data => {
                return (
                    data
                        .Description
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            this.setState({
                billCountry: e.target.value,
                // billCountryId: searchBillCountryList[0]['CountryCode'] != null ? searchBillCountryList[0]['CountryCode'] : "",
                billCountryErr: '',
                billTempCountryList: searchBillCountryList,
                isDisplayBillCountryList: true
            })
        } else {
            this.setState({
                billCountry: e.target.value,
                billTempCountryList: countryList,
                isDisplayBillCountryList: false
            })
        }
    }


    // render Bill country 
    renderBillCountryList() {
        const { billTempCountryList } = this.state
        return (
            <ul className="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    billTempCountryList.length > 0 && billTempCountryList.map(data => (
                        <li className="list-group-item" key={Math.random()}
                            onClick={e => this.setState({ billCountry: data.Description, billCountryId: data.Code, isDisplayBillCountryList: false })}
                        >{data.Description}</li>
                    ))
                }
            </ul>
        )
    }

    // On Change Of Product Name In Exhibit Table
    onEnterProductName(e, index) {
        const { subChapters } = this.state
        subChapters.map((data, i) => {
            if (index == i) {
                data.ChapterName = e.target.value
            }
        })
        for (var i = 0; i < subChapters.length; i++) {
            if (index != i && subChapters[i].ChapterName.toString().trim().toLowerCase() == e.target.value.toString().trim().toLowerCase()) {
                this.setState({ exbTableErr: 'Product name must be unique.' })
                return;
            } else {
                this.setState({ exbTableErr: '' })
            }
        }
        setTimeout(() => {
            this.setState({ subChapters: subChapters })
        }, 200);
    }

    // On Machine Details Change In Exhibit Table
    onMachineDetailsChange(e, index) {
        const { subChapters } = this.state
        subChapters.map((data, i) => {
            if (index == i) {
                data.MachineDetails = e.target.value
            }
        })
        setTimeout(() => {
            this.setState({ subChapters: subChapters })
        }, 100);
    }

    // On HSN No Change In Exhibit Table
    onEnterHSNCode(e, index) {
        const { subChapters } = this.state
        subChapters.map((data, i) => {
            if (index == i) {
                data.HSNCode = e.target.value
            }
        })
        setTimeout(() => {
            this.setState({ subChapters: subChapters })
        }, 200);
    }

    // On Length Change In Exhibit Table
    onLengthChange(e, index) {
        const { subChapters, stallArea } = this.state
        var breadth = 0;
        subChapters.map((data, i) => {
            if (index == i) {
                data.Length = e.target.value
                breadth = data.Breadth;
            }
        })
        setTimeout(() => {
            this.setState({ subChapters: subChapters })
        }, 100);
        if (stallArea != null && stallArea != '' && e.target.value != null && e.target.value != '' && e.target.value != 0 &&
            breadth != null && breadth != 0) {
            var totalEnterSpace;
            totalEnterSpace = parseFloat(e.target.value) * parseFloat(breadth);
            if (totalEnterSpace > stallArea) {
                this.setState({ exbTableErr: 'Length and breadth must be below or equal to stall area.' })
            } else {
                this.setState({ exbTableErr: '' })
            }
        }

    }

    // On Bredth Change In ExiBit Table
    onBreadthChange(e, index) {
        const { subChapters, stallArea } = this.state
        var length = 0;
        subChapters.map((data, i) => {
            if (index == i) {
                data.Breadth = e.target.value
                length = data.Length;
            }
        })
        if (stallArea != null && stallArea != '' && e.target.value != null && e.target.value != '' && e.target.value != 0 &&
            length != null && length != 0) {
            var totalEnterSpace;
            totalEnterSpace = parseFloat(e.target.value) * parseFloat(length);
            if (totalEnterSpace > stallArea) {
                subChapters.map((data, i) => {
                    if (index == i) {
                        data.isLengthValidate = true;
                    }
                })
                this.setState({ exbTableErr: 'Length and breadth must be below or equal to stall area.' })
            } else {
                subChapters.map((data, i) => {
                    if (index == i) {
                        data.isLengthValidate = false;
                    }
                })
                this.setState({ exbTableErr: '' })
            }
        }
        setTimeout(() => {
            this.setState({ subChapters: subChapters })
        }, 100);
    }

    // On Height Change In Exibit Table
    onHeightChange(e, index) {
        const { subChapters } = this.state
        subChapters.map((data, i) => {
            if (index == i) {
                data.Height = e.target.value
            }
        })
        setTimeout(() => {
            this.setState({ subChapters: subChapters })
        }, 100);

        if (e.target.value != null && e.target.value != '' && e.target.value != 0 && e.target.value > 3) {
            this.setState({ exbTableErr: 'Height must be less than or equal to 3.' })
        } else {
            this.setState({ exbTableErr: '' })
        }
    }

    // On Flor Capacity Change In Exibit Table
    onFloorCapacityChange(e, index) {
        const { subChapters } = this.state
        subChapters.map((data, i) => {
            if (index == i) {
                data.FloorCapacity = e.target.value
            }
        })
        setTimeout(() => {
            this.setState({ subChapters: subChapters })
        }, 100);
    }

    // On Machine Weight Change 
    onMachineWeightChange(e, index) {
        const { subChapters } = this.state
        subChapters.map((data, i) => {
            if (index == i) {
                data.MachineWeight = e.target.value
            }
        })
        setTimeout(() => {
            this.setState({ subChapters: subChapters })
        }, 100);
    }

    // On Manufacturer Name Change
    onManufacturerNameChange(e, index) {
        const { subChapters } = this.state
        subChapters.map((data, i) => {
            if (index == i) {
                data.ManufacturerName = e.target.value
            }
        })
        setTimeout(() => {
            this.setState({ subChapters: subChapters })
        }, 100);
    }

    // On Manufacturing Country Name Change
    onManufacturingCountryChange(e, index) {
        const { subChapters } = this.state
        subChapters.map((data, i) => {
            if (index == i) {
                data.ManufacturingCountry = e.target.value
            }
        })
        setTimeout(() => {
            this.setState({ subChapters: subChapters })
        }, 100);
    }

    onAddClick(e) {
        e.preventDefault()
        const { isOpen } = this.state
        this.setState({ isOpen: !isOpen })
    }

    // on check level 2
    onCheckLevel2(e, id) {
        const { lev2, subChapters } = this.state
        var tempExhibitDetailsList = []
        for (let i = 0; i < lev2.length; i++) {
            if (lev2[i]['ChapterID'] == id) {

                if (e.target.checked) {
                    if (!subChapters.includes(lev2[i])) {
                        lev2[i].isLengthValidate = false;
                        subChapters.push(lev2[i])
                        this.setState({ subChapters: subChapters })
                    }
                }
                else {
                    this.setState({ subChapters: subChapters.filter(item => item.ChapterID != lev2[i]['ChapterID']) })
                }

                lev2[i]['IsSelect'] = !lev2[i]['IsSelect']
            }
        }
        setTimeout(() => {
            this.setState({ lev2: lev2 })
        }, 200);
    }

    // on check level 3
    onCheckLevel3(e, id) {
        const { lev3, subChapters } = this.state
        var tempExhibitDetailsList = []
        for (let i = 0; i < lev3.length; i++) {
            if (lev3[i]['ChapterID'] == id) {

                if (e.target.checked) {
                    if (!subChapters.includes(lev3[i])) {
                        lev3[i].isLengthValidate = false;
                        subChapters.push(lev3[i])
                        this.setState({ subChapters: subChapters })
                    }
                }
                else {
                    this.setState({ subChapters: subChapters.filter(item => item.ChapterID != lev3[i]['ChapterID']) })
                }

                lev3[i]['IsSelect'] = !lev3[i]['IsSelect']
            }
        }
        setTimeout(() => {
            this.setState({ lev3: lev3 })
        }, 200);
    }

    // on check level 4
    onSelectLevel4(e, id) {
        const { lev4, subChapters } = this.state
        var tempExhibitDetailsList = []
        for (let i = 0; i < lev4.length; i++) {
            if (lev4[i]['ChapterID'] == id) {

                if (e.target.checked) {
                    if (!subChapters.includes(lev4[i])) {
                        lev4[i].isLengthValidate = false;
                        subChapters.push(lev4[i])
                        this.setState({ subChapters: subChapters })
                    }
                }
                else {
                    this.setState({ subChapters: subChapters.filter(item => item.ChapterID != lev4[i]['ChapterID']) })
                }

                lev4[i]['IsSelect'] = !lev4[i]['IsSelect']
            }
        }
        setTimeout(() => {
            this.setState({ lev4: lev4, exbTableErr: '' })
        }, 200);

    }

    // to call level third list 
    record(level2, level3, level4) {
        // var newdata = JSON.stringify(data);
        var tempLevel3 = [];
        for (var j = 0; j < level3.length; j++) {
            if (level2.ChapterID == level3[j]['ParentChapterID']) {
                tempLevel3.push(level3[j]);
            }
        }
        return (
            <>
                <div id={'collapse_l1_' + level2.ChapterID} class="accordion-collapse collapse" aria-labelledby={'heading_l1_' + level2.ChapterID} data-bs-parent="#accordionExample">
                    {
                        tempLevel3 && tempLevel3.map(level3data => {
                            return (
                                <div class="accordion-body">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id={'heading_l2_' + level3data.ChapterID}>
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={'#collapse_l2_' + level3data.ChapterID} aria-expanded="false" aria-controls={'collapse_l2_' + level3data.ChapterID}>
                                                {/* // checkbox for level3 */}
                                                {
                                                    level3data.IsCheckbox && <input type="checkbox" className="from-left-sp"
                                                        name="" key={level3data.ChapterID} checked={level3data.IsSelect}
                                                        onClick={e => this.onCheckLevel3(e, level3data.ChapterID)} />
                                                }

                                                {level3data.ChapterName} </button>
                                        </h2>
                                        {this.recordFour(level3data, level4)}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </>
        );
    }

    // to call level four list
    recordFour(level3data, level4) {
        var tempLevel4 = [];
        for (var j = 0; j < level4.length; j++) {
            if (level3data.ChapterID == level4[j]['ParentChapterID']) {
                tempLevel4.push(level4[j]);
            }
        }
        return (<>
            <div id={'collapse_l2_' + level3data.ChapterID} class="accordion-collapse collapse" aria-labelledby={'heading_l2_' + level3data.ChapterID}>
                {
                    tempLevel4 && tempLevel4.map(level4data => {
                        return (
                            <div class="accordion-body third-step">
                                {/* level4data.IsCheckbox && */}
                                <input type="checkbox" name="" key={level4data.ChapterID} checked={level4data.IsSelect}
                                    onClick={(e) => this.onSelectLevel4(e, level4data.ChapterID)} />
                                {level4data.ChapterName}
                            </div>
                        )
                    })
                }
            </div>
        </>);
    }

    render() {
        const { isLoading, firstName, lastName, preFixPersonInch, personInchFirst, personInchLast, regDesignation, regCompany,
            fasciaName, regAdd1, regAdd2, regPin, regCity, regState, regCountry, regMob1, regMob2, regTel, regTel1, regTelFax,
            regPrimaryEmail, regSecondaryEmail, regWeb, preOwnerNameFirst, ownerNameFirst, ownerNameLast, ownerMob, preOwnerMob,
            ownerEmail, billPersonInchFirst, billPersonInchLast, billDesignation, billCompany, billAdd1, billAdd2, billPin,
            billCity, billState, billCountry, billMob1, billMob2, billTel, preBillTel1, billTel1, billTelFax, billPrimaryEmail,
            billSecondaryEmail, billWeb, companyRegNo, panNo, panName, gstNo, tanNo, iecCode, accName, accNo, accType,
            bankName, branchName, ifsc, personInchFirstErr, personInchLastErr, regDesignationErr, regCompanyErr, fasciaNameErr,
            regAdd1Err, regPinErr, regCityErr, regStateErr, regCountryErr, regMob1Err, regPrimaryEmailErr, ownerNameFirstErr,
            ownerEmailErr, billPersonInchFirstErr, billDesignationErr, billCompanyErr, billAdd1Err, billPinErr, billCityErr,
            billStateErr, billCountryErr, billMob1Err, billMob2Err, billPrimaryEmailErr, panNoErr,
            panNameErr, gstNoErr, statusErr, tanNoErr, uanNoErr, accNameErr, accNoErr, ifscErr,
            isDisplayPincodeList, isDisplayCityList, isDisplayStateList, isDisplayCountryList, preRegMob1, preRegMob2,
            preRegTel, preRegTel1, isDisplayBillPincodeList, isDisplayBillCityList, isDisplayBillStateList,
            isDisplayBillCountryList, preBillMob1, preBillMob2, preBillTel, preFixBillPersonInch, billPersonInchLastErr,
            isOtherPreFixPersonInch, otherPersonInchFirst, isOtherOwnerFirstName, otherOwnerFirstName, isOtherBillPersonInchFirst,
            otherBillPersonInchFirst, primaryChapterHeaderList, primaryChapterName, subChapters, exbTableErr, estimateDetails,
            isAgree, isAgree2, isAgree3, accTypeOthers, isPreviousOrSameDate, isOpen, lev2, lev3, lev4 } = this.state

        const toInputUppercase = e => {
            e.target.value = ("" + e.target.value).toUpperCase();
        };

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                <AdminDrawer />
                <div className="page">
                    <div className="page-main">
                        <div className="main-content with-dr-container com-height">
                            <div className="row">
                                <div className="step-name">
                                    <p>Exhibitor Spacebooking</p>
                                </div>
                            </div>
                            {console.log(JSON.parse(sessionStorage.getItem('loginDataSS')))}
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-xl-12 p-0">
                                        <div className="card rounded-0">
                                            <div className="card-body p-0">
                                                <h6 className="" style={{ padding: "5px 7px 3px 0px" }}>
                                                    {/* Dear {JSON.parse(sessionStorage.getItem('sessionHeaderData'))[0]['CompanyName'] != null &&
                                                        JSON.parse(sessionStorage.getItem('sessionHeaderData'))[0]['CompanyName']} */}
                                                </h6>
                                                <div className="row mt-1">
                                                    <div className="col-md-12">
                                                        <h6 style={{ padding: "5px 7px 0px 0px", color: "#0072ff" }}>
                                                            Registration Details
                                                        </h6>
                                                    </div>
                                                    <div className="col-xl-6">
                                                        <label htmlFor="validationCustom03">
                                                            Name of the Company <span className="text-red">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            // disabled={true}
                                                            value={regCompany}
                                                            onChange={(e) => this.setState({ regCompany: e.target.value, regCompanyErr: "" })}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{regCompanyErr}</span>}
                                                    </div>
                                                    <div className="col-xl-6">
                                                        <label htmlFor="validationCustom03">
                                                            Fascia Name (Shell Scheme Only)<span className="text-red">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            maxLength={'250'}
                                                            value={fasciaName}
                                                            onChange={(e) => this.setState({ fasciaName: e.target.value, fasciaNameErr: "" })}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{fasciaNameErr}</span>}
                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-xl-6">
                                                        <label htmlFor="validationCustom03">
                                                            Address Line 1<span className="text-red">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            maxLength={'200'}
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            value={regAdd1}
                                                            onChange={(e) => this.setState({ regAdd1: e.target.value, regAdd1Err: "" })}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{regAdd1Err}</span>}
                                                    </div>
                                                    <div className="col-xl-6">
                                                        <label htmlFor="validationCustom03">Address Line 2</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            maxLength={'200'}
                                                            id="validationCustom03"
                                                            value={regAdd2}
                                                            onChange={(e) => this.setState({ regAdd2: e.target.value })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom03">
                                                            City<span className="text-red">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            disabled={true}
                                                            value={regCity}
                                                        // onChange={(e) => this.onCitySearch(e)}
                                                        />
                                                        {/* {isDisplayCityList && this.renderCityList()}
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{regCityErr}</span>} */}
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom03">
                                                            {regCountry.toLowerCase().trim() == "india" ? 'state' : 'State/Province'}
                                                            {regCountry.toLowerCase().trim() == "india" ? <span className="text-red">*</span> : ''}
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            disabled={true}
                                                            value={regState}
                                                        // onChange={(e) => this.onStateSearch(e)}
                                                        />
                                                        {/* {isDisplayStateList && this.renderStateList()}
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{regStateErr}</span>} */}
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom03">
                                                            {regCountry.toLowerCase().trim() == "india" ? 'Pincode' : 'Zipcode'}
                                                            <span className="text-red">*</span>
                                                        </label>
                                                        {
                                                            regCountry.toLowerCase().trim() == "india" &&
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="validationCustom03"
                                                                value={regPin}
                                                                disabled={true}
                                                                maxLength={6}
                                                            // onChange={(e) => this.onPincodeSearch(e)}
                                                            />
                                                        }
                                                        {
                                                            regCountry.toLowerCase().trim() != "india" &&
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="validationCustom03"
                                                                value={regPin}
                                                                disabled={true}
                                                                maxLength={10}
                                                                // onChange={(e) => this.onPincodeSearch(e)}
                                                                onInput={toInputUppercase}
                                                            />
                                                        }
                                                        {/* {isDisplayPincodeList && this.renderPincodeList()}
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{regPinErr}</span>} */}
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom03">
                                                            Country<span className="text-red">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            disabled={true}
                                                            value={regCountry}
                                                        // onChange={(e) => this.onCountrySearch(e)}
                                                        />
                                                        {/* {isDisplayCountryList && this.renderCountryList()}
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{regCountryErr}</span>} */}
                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom03">
                                                            First Name of Person Incharge
                                                            <span className="text-red">*</span>
                                                        </label>
                                                        {
                                                            isOtherPreFixPersonInch &&
                                                            <input
                                                                type="text"
                                                                className="form-control-1 other-fil"
                                                                value={otherPersonInchFirst}
                                                                onChange={e => this.setState({ otherPersonInchFirst: e.target.value })}
                                                                placeholder={'Other'}
                                                                style={{
                                                                    // position: "absolute",
                                                                    paddingLeft: '5px'
                                                                }}
                                                            />
                                                        }
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            value={personInchFirst}
                                                            maxLength={'100'}
                                                            onChange={(e) => this.setState({ personInchFirst: e.target.value, personInchFirstErr: "" })}
                                                            style={{
                                                                // position: "absolute",
                                                                paddingLeft: '50px'
                                                            }}
                                                        />
                                                        <div className="col-xl-1 mb-3">
                                                            <div
                                                                className="form-group"
                                                                style={{
                                                                    width: '45px',
                                                                    position: "absolute",
                                                                    top: "-58px",
                                                                    left: '0px'
                                                                }}
                                                            >
                                                                <label />
                                                                <select
                                                                    className="form-control-1 select2 select-hidden-accessible"
                                                                    id="roledb"
                                                                    style={{
                                                                        paddingLeft: '0px',
                                                                        paddingRight: '0px',
                                                                        borderTop: "none",
                                                                        borderBottom: "none",
                                                                        marginTop: '0px',
                                                                        textAlign: "left"
                                                                    }}
                                                                    value={preFixPersonInch}
                                                                    onChange={(e) => this.setState({
                                                                        preFixPersonInch: e.target.value,
                                                                        isOtherPreFixPersonInch: e.target.value == "Other" && true
                                                                    })}
                                                                >
                                                                    <option value="Mr">Mr</option>
                                                                    <option value="Ms">Ms</option>
                                                                    <option value="DR">DR</option>
                                                                    <option value="Prof">Prof</option>
                                                                    <option value="Other">Other</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{personInchFirstErr}</span>}
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <label>
                                                            Last Name of Person Incharge<span className="text-red">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            maxLength={'100'}
                                                            value={personInchLast}
                                                            onChange={(e) => this.setState({ personInchLast: e.target.value, personInchLastErr: "" })}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{personInchLastErr}</span>}
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom03">
                                                            Designation<span className="text-red">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            value={regDesignation}
                                                            onChange={(e) => this.setState({ regDesignation: e.target.value, regDesignationErr: "" })}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{regDesignationErr}</span>}
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom03">
                                                            Mobile<span className="text-red">*</span>
                                                        </label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            value={regMob1}
                                                            maxLength={regCountry.toLowerCase().trim() == "China" ? 11 : 10}
                                                            onInput={(e) => this.maxLengthCheck(e, 10)}
                                                            onKeyDown={(e) => this.preventMinus(e)}
                                                            onWheel={(e) => e.target.blur()}
                                                            onPaste={(e) => this.handlePaste(e)}
                                                            style={{ paddingLeft: '50px' }}
                                                            onChange={(e) => e.target.value >= 0 &&
                                                                this.setState({ regMob1: e.target.value, regMob1Err: "" })}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{regMob1Err}</span>}
                                                        <input
                                                            type="text"
                                                            className="form-control-1"
                                                            value={preRegMob1}
                                                            placeholder="ISD"
                                                            onChange={(e) => this.setState({ preRegMob1: e.target.value })}
                                                            style={{
                                                                width: '45px',
                                                                position: "absolute",
                                                                top: '22px',
                                                                padding: "6px !important",
                                                                paddingLeft: '5px',
                                                                borderLeft: '0px',
                                                                borderTop: '0px',
                                                                background: "none",
                                                                borderBottom: '0px',
                                                                height: '35px'
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom03">Alternate Mobile No</label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            style={{ paddingLeft: 50 }}
                                                            value={regMob2}
                                                            maxLength={regCountry.toLowerCase().trim() == "China" ? 11 : 10}
                                                            onInput={(e) => this.maxLengthCheck(e, 10)} size={'10'}
                                                            onKeyDown={(e) => this.preventMinus(e)}
                                                            onPaste={(e) => this.handlePaste(e)}
                                                            onWheel={(e) => e.target.blur()}
                                                            onChange={(e) => e.target.value >= 0 && this.setState({ regMob2: e.target.value })}
                                                        />
                                                        <input
                                                            type="text"
                                                            className="form-control-1"
                                                            value={preRegMob2}
                                                            onChange={(e) => this.setState({ preRegMob2: e.target.value })}
                                                            style={{
                                                                width: 45,
                                                                position: "absolute",
                                                                top: 22,
                                                                padding: "6px !important",
                                                                paddingLeft: 5,
                                                                borderLeft: 0,
                                                                borderTop: 0,
                                                                background: "none",
                                                                borderBottom: 0,
                                                                height: 35
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom03">
                                                            Telephone
                                                        </label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            style={{ paddingLeft: 50 }}
                                                            value={regTel} maxLength={'10'}
                                                            onPaste={(e) => this.handlePaste(e)}
                                                            onInput={(e) => this.maxLengthCheck(e, 10)} size={'10'}
                                                            onKeyDown={(e) => this.preventMinus(e)}
                                                            onWheel={(e) => e.target.blur()}
                                                            onChange={(e) => e.target.value >= 0 && this.setState({ regTel: e.target.value })}
                                                        />
                                                        <input
                                                            type="text"
                                                            className="form-control-1"
                                                            value={preRegTel}
                                                            onChange={(e) => this.setState({ preRegTel: e.target.value })}
                                                            style={{
                                                                width: 45,
                                                                position: "absolute",
                                                                top: 21,
                                                                padding: "6px !important",
                                                                paddingLeft: 5,
                                                                borderLeft: 0,
                                                                borderTop: 0,
                                                                background: "none",
                                                                borderBottom: 0,
                                                                height: 35
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom03">
                                                            Telephone 1
                                                        </label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            style={{ paddingLeft: 50 }}
                                                            value={regTel1} maxLength={'10'}
                                                            onPaste={(e) => this.handlePaste(e)}
                                                            onInput={(e) => this.maxLengthCheck(e, 10)} size={'10'}
                                                            onKeyDown={(e) => this.preventMinus(e)}
                                                            onWheel={(e) => e.target.blur()}
                                                            onChange={(e) => e.target.value >= 0 && this.setState({ regTel1: e.target.value })}
                                                        />
                                                        <input
                                                            type="text"
                                                            className="form-control-1"
                                                            value={preRegTel1}
                                                            onChange={(e) => this.setState({ preRegTel1: e.target.value })}
                                                            style={{
                                                                width: 45,
                                                                position: "absolute",
                                                                top: 22,
                                                                padding: "6px !important",
                                                                paddingLeft: 5,
                                                                borderLeft: 0,
                                                                borderTop: 0,
                                                                background: "none",
                                                                borderBottom: 0,
                                                                height: 35
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-xl-3 mb-1">
                                                        <label htmlFor="validationCustom01">Telefax</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            maxLength={50}
                                                            value={regTelFax}
                                                            onChange={e => this.setState({ regTelFax: e.target.value })}
                                                        />
                                                    </div>
                                                </div>


                                                <div className="row mt-2">
                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom03">
                                                            Primary Email<span className="text-red">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            disabled={true}
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            value={regPrimaryEmail}
                                                            onChange={(e) => this.setState({ regPrimaryEmail: e.target.value, regPrimaryEmailErr: "" })}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{regPrimaryEmailErr}</span>}
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom03">Secondary Email</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            maxLength={'100'}
                                                            value={regSecondaryEmail}
                                                            onChange={(e) => this.setState({ regSecondaryEmail: e.target.value })}
                                                        />
                                                    </div>
                                                    <div className="col-xl-6">
                                                        <label htmlFor="validationCustom03">Website</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            maxLength={'100'}
                                                            id="validationCustom03"
                                                            value={regWeb}
                                                            onChange={(e) => this.setState({ regWeb: e.target.value })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-xl-6">
                                                        <label>
                                                            First Name of CEO/MD/President/Owner
                                                            <span className="text-red">*</span>
                                                        </label>
                                                        {
                                                            isOtherOwnerFirstName &&
                                                            <input
                                                                type="text"
                                                                className="form-control-1 other-fil"
                                                                value={otherOwnerFirstName}
                                                                onChange={e => this.setState({ otherOwnerFirstName: e.target.value })}
                                                                placeholder={'Other'}
                                                                style={{
                                                                    // position: "absolute",
                                                                    paddingLeft: '5px'
                                                                }}
                                                            />
                                                        }
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            maxLength={100}
                                                            style={{ position: "relative", paddingLeft: '55px' }}
                                                            onChange={e => this.setState({ ownerNameFirst: e.target.value, ownerNameFirstErr: "" })}
                                                            value={ownerNameFirst}
                                                        />
                                                        <div className="col-xl-1 mb-3">
                                                            <div
                                                                className="form-group"

                                                                style={{
                                                                    width: '45px',
                                                                    position: "absolute",
                                                                    top: "-58px",
                                                                    left: '0px'
                                                                }}
                                                            >
                                                                <label />
                                                                <select
                                                                    className="form-control-1 select2 select-hidden-accessible"
                                                                    id="roledb"
                                                                    style={{
                                                                        paddingLeft: '0px',
                                                                        paddingRight: '0px',
                                                                        borderTop: "none",
                                                                        borderBottom: "none",
                                                                        marginTop: '-2px',
                                                                        textAlign: "left"
                                                                    }}
                                                                    value={preOwnerNameFirst}
                                                                    onChange={e => this.setState({
                                                                        preOwnerNameFirst: e.target.value,
                                                                        isOtherOwnerFirstName: e.target.value == "Other" && true
                                                                    })}

                                                                >
                                                                    <option value="Mr">Mr</option>
                                                                    <option value="Ms">Ms</option>
                                                                    <option value="DR">DR</option>
                                                                    <option value="Prof">Prof</option>
                                                                    <option value="Other">Other</option>

                                                                </select>
                                                            </div>
                                                        </div>
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{ownerNameFirstErr}</span>}

                                                    </div>
                                                    <div className="col-xl-6">
                                                        <label htmlFor="validationCustom01">Last Name of CEO/MD/President/Owner</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={ownerNameLast}
                                                            maxLength={100}
                                                            onChange={e => this.setState({ ownerNameLast: e.target.value })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-xl-3">
                                                        <label htmlFor="validationCustom01">Mobile No</label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            value={ownerMob}
                                                            maxLength={regCountry.toLowerCase().trim() == "China" ? 11 : 10}
                                                            onChange={e => e.target.value >= 0 && this.setState({ ownerMob: e.target.value })}
                                                            onInput={e => this.maxLengthCheck(e, 10)} size={'10'}
                                                            onKeyDown={(e) => this.preventMinus(e)}
                                                            onWheel={(e) => e.target.blur()}
                                                            style={{ paddingLeft: '50px' }}
                                                        />
                                                        <input
                                                            type="text"
                                                            placeholder="STD"
                                                            className="form-control-1"
                                                            value={preOwnerMob}
                                                            onChange={e => this.setState({ preOwnerMob: e.target.value })}
                                                            // placeholder={+91}
                                                            style={{
                                                                width: '45px',
                                                                position: "absolute",
                                                                top: '23px',
                                                                left: '12px',
                                                                paddingLeft: '14px'
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-xl-9">
                                                        <label htmlFor="validationCustom01">
                                                            Email<span className="text-red">*</span>
                                                        </label>
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            maxLength={100}
                                                            value={ownerEmail}
                                                            onChange={e => this.setState({ ownerEmail: e.target.value, ownerEmailErr: '' })}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{ownerEmailErr}</span>}

                                                    </div>
                                                </div>

                                                <div className="main-container bg-section p-2 mt-3">
                                                    <div className="row ">
                                                        <div className="col-md-12">
                                                            <h6 style={{ padding: "5px 7px 0px 0px", color: "#0072ff" }}>
                                                                Invoice Details
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <div className="row">

                                                        <div className="col-xl-6">
                                                            <label htmlFor="validationCustom03">
                                                                Name of the Company<span className="text-red">*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="validationCustom03"
                                                                // disabled={true}
                                                                value={billCompany}
                                                                onChange={(e) => this.setState({ billCompany: e.target.value, billCompanyErr: "" })}
                                                            />
                                                            {<span className='text-danger text-start text-left d-block mt-1'>{billCompanyErr}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-xl-6">
                                                            <label htmlFor="validationCustom03">
                                                                Address Line 1<span className="text-red">*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                maxLength={'200'}
                                                                id="validationCustom03"
                                                                value={billAdd1}
                                                                onChange={(e) => this.setState({ billAdd1: e.target.value, billAdd1Err: "" })}
                                                            />
                                                            {<span className='text-danger text-start text-left d-block mt-1'>{billAdd1Err}</span>}
                                                        </div>
                                                        <div className="col-xl-6">
                                                            <label htmlFor="validationCustom03">Address Line 2</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                maxLength={'200'}
                                                                id="validationCustom03"
                                                                value={billAdd2}
                                                                onChange={(e) => this.setState({ billAdd2: e.target.value })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-xl-3">
                                                            <label htmlFor="validationCustom03">
                                                                City<span className="text-red">*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="validationCustom03"
                                                                disabled={true}
                                                                value={billCity}
                                                            // onChange={(e) => this.onBillCitySearch(e)}
                                                            />
                                                            {/* {isDisplayBillCityList && this.renderBillCityList()}
                                                            {<span className='text-danger text-start text-left d-block mt-1'>{billCityErr}</span>} */}
                                                        </div>
                                                        <div className="col-xl-3">
                                                            <label htmlFor="validationCustom03">
                                                                {billCountry.toLowerCase().trim() == "india" ? 'State' : 'State/Province'}
                                                                {billCountry.toLowerCase().trim() == "india" ? <span className="text-red">*</span> : ""}
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="validationCustom03"
                                                                disabled={true}
                                                                value={billState}
                                                            // onChange={(e) => this.onBillStateSearch(e)}
                                                            />
                                                            {/* {isDisplayBillStateList && this.renderBillStateList()}
                                                            {billCountry.toLowerCase().trim() == "india" ? <span className='text-danger text-start text-left d-block mt-1'>{billStateErr}</span> : ""} */}
                                                        </div>
                                                        <div className="col-xl-3">
                                                            <label htmlFor="validationCustom03">
                                                                {billCountry.toLowerCase().trim() == "india" ? 'Pincode' : 'Zipcode'}
                                                                <span className="text-red">*</span>
                                                            </label>
                                                            {
                                                                billCountry.toLowerCase().trim() == "india" &&
                                                                <input
                                                                    type="text"
                                                                    maxLength={6}
                                                                    className="form-control"
                                                                    id="validationCustom03"
                                                                    disabled={true}
                                                                    value={billPin}
                                                                // onChange={(e) => this.onBillPincodeSearch(e)}
                                                                />
                                                            }
                                                            {
                                                                billCountry.toLowerCase().trim() != "india" &&
                                                                <input
                                                                    type="number"
                                                                    maxLength={10}
                                                                    className="form-control"
                                                                    id="validationCustom03"
                                                                    value={billPin}
                                                                    disabled={true}
                                                                    // onChange={(e) => this.onBillPincodeSearch(e)}
                                                                    onInput={toInputUppercase}
                                                                />
                                                            }
                                                            {/* {isDisplayBillPincodeList && this.renderBillPincodeList()}
                                                            {<span className='text-danger text-start text-left d-block mt-1'>{billPinErr}</span>} */}
                                                        </div>
                                                        <div className="col-xl-3">
                                                            <label htmlFor="validationCustom03">
                                                                Country<span className="text-red">*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="validationCustom03"
                                                                disabled={true}
                                                                value={billCountry}
                                                            // onChange={(e) => this.onBillCountrySearch(e)}
                                                            />
                                                            {/* {isDisplayBillCountryList && this.renderBillCountryList()}
                                                            {<span className='text-danger text-start text-left d-block mt-1'>{billCountryErr}</span>} */}
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-xl-3">
                                                            <label htmlFor="validationCustom03">
                                                                First Name of Person Incharge
                                                                <span className="text-red">*</span>
                                                            </label>
                                                            {
                                                                isOtherBillPersonInchFirst &&
                                                                <input
                                                                    type="text"
                                                                    className="form-control-1 other-fil"
                                                                    value={otherBillPersonInchFirst}
                                                                    onChange={e => this.setState({ otherBillPersonInchFirst: e.target.value })}
                                                                    placeholder={'Other'}
                                                                    style={{
                                                                        // position: "absolute",
                                                                        paddingLeft: '5px'
                                                                    }}
                                                                />
                                                            }
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="validationCustom03"
                                                                maxLength={'100'}
                                                                value={billPersonInchFirst}
                                                                onChange={(e) => this.setState({ billPersonInchFirst: e.target.value, billPersonInchFirstErr: "" })}
                                                                style={{
                                                                    // position: "absolute",
                                                                    paddingLeft: '50px'
                                                                }}
                                                            />
                                                            <div className="col-xl-1 mb-3">
                                                                <div
                                                                    className="form-group"
                                                                    style={{
                                                                        width: '45px',
                                                                        position: "absolute",
                                                                        top: "-58px",
                                                                        left: '0px'
                                                                    }}
                                                                >
                                                                    <label />
                                                                    <select
                                                                        className="form-control-1 select2 select-hidden-accessible"
                                                                        id="roledb"
                                                                        style={{
                                                                            paddingLeft: '0px',
                                                                            paddingRight: '0px',
                                                                            borderTop: "none",
                                                                            borderBottom: "none",
                                                                            marginTop: '0px',
                                                                            textAlign: "left"
                                                                        }}
                                                                        value={preFixBillPersonInch}
                                                                        onChange={(e) => this.setState({
                                                                            preFixBillPersonInch: e.target.value,
                                                                            isOtherBillPersonInchFirst: e.target.value == "Other" && true
                                                                        })}
                                                                    >
                                                                        <option value="Mr">Mr</option>
                                                                        <option value="Ms">Ms</option>
                                                                        <option value="DR">DR</option>
                                                                        <option value="Prof">Prof</option>
                                                                        <option value="Other">Other</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            {<span className='text-danger text-start text-left d-block mt-1'>{billPersonInchFirstErr}</span>}
                                                        </div>
                                                        <div className="col-xl-3">
                                                            <label>
                                                                Last Name of Person Incharge<span className="text-red">*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                maxLength={100}
                                                                value={billPersonInchLast}
                                                                onChange={(e) => this.setState({ billPersonInchLast: e.target.value, billPersonInchLastErr: "" })}
                                                            />
                                                            {<span className='text-danger text-start text-left d-block mt-1'>{billPersonInchLastErr}</span>}
                                                        </div>
                                                        <div className="col-xl-3">
                                                            <label htmlFor="validationCustom03">
                                                                Designation<span className="text-red">*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="validationCustom03"
                                                                maxLength={'100'}
                                                                value={billDesignation}
                                                                onChange={(e) => this.setState({ billDesignation: e.target.value, billDesignationErr: "" })}
                                                            />
                                                            {<span className='text-danger text-start text-left d-block mt-1'>{billDesignationErr}</span>}
                                                        </div>
                                                        <div className="col-xl-3">
                                                            <label htmlFor="validationCustom03">
                                                                Mobile 1<span className="text-red">*</span>
                                                            </label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                style={{ paddingLeft: '50px' }}
                                                                id="validationCustom03"
                                                                value={billMob1}
                                                                maxLength={billCountry.toLowerCase().trim() == "China" ? 11 : 10}
                                                                onInput={(e) => this.maxLengthCheck(e, 10)} size={'10'}
                                                                onKeyDown={(e) => this.preventMinus(e)}
                                                                onPaste={(e) => this.handlePaste(e)}
                                                                onWheel={(e) => e.target.blur()}
                                                                onChange={(e) => e.target.value >= 0 && this.setState({ billMob1: e.target.value, billMob1Err: "" })}
                                                            />
                                                            {<span className='text-danger text-start text-left d-block mt-1'>{billMob1Err}</span>}
                                                            <input
                                                                type="text"
                                                                className="form-control-1"
                                                                value={preBillMob1}
                                                                onChange={(e) => this.setState({ preBillMob1: e.target.value })}
                                                                style={{
                                                                    width: 45,
                                                                    position: "absolute",
                                                                    top: 21,
                                                                    padding: "6px !important",
                                                                    paddingLeft: 5,
                                                                    borderLeft: 0,
                                                                    borderTop: 0,
                                                                    background: "none",
                                                                    borderBottom: 0,
                                                                    height: 35
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-xl-3">
                                                            <label htmlFor="validationCustom03">
                                                                Alternate Mobile No
                                                            </label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                style={{ paddingLeft: '50px' }}
                                                                id="validationCustom03"
                                                                value={billMob2}
                                                                maxLength={billCountry.toLowerCase().trim() == "China" ? 11 : 10}
                                                                onInput={(e) => this.maxLengthCheck(e, 10)} size={'10'}
                                                                onKeyDown={(e) => this.preventMinus(e)}
                                                                onPaste={(e) => this.handlePaste(e)}
                                                                onWheel={(e) => e.target.blur()}
                                                                onChange={(e) => e.target.value >= 0 && this.setState({ billMob2: e.target.value })}
                                                            />
                                                            {/* {<span className='text-danger text-start text-left d-block mt-1'>{billMob2Err}</span>} */}
                                                            <input
                                                                type="text"
                                                                className="form-control-1"
                                                                value={preBillMob2}
                                                                onChange={(e) => this.setState({ preBillMob2: e.target.value })}
                                                                style={{
                                                                    width: 45,
                                                                    position: "absolute",
                                                                    top: 22,
                                                                    padding: "6px !important",
                                                                    paddingLeft: 5,
                                                                    borderLeft: 0,
                                                                    borderTop: 0,
                                                                    background: "none",
                                                                    borderBottom: 0,
                                                                    height: 35
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-xl-3">
                                                            <label htmlFor="validationCustom03">
                                                                Telephone No
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="validationCustom03"
                                                                value={billTel} maxLength={'10'}
                                                                onInput={(e) => this.maxLengthCheck(e, 10)} size={'10'}
                                                                onKeyDown={(e) => this.preventMinus(e)}
                                                                onPaste={(e) => this.handlePaste(e)}
                                                                onWheel={(e) => e.target.blur()}
                                                                onChange={(e) => e.target.value >= 0 && this.setState({ billTel: e.target.value })}
                                                            />
                                                            <input
                                                                type="text"
                                                                className="form-control-1"
                                                                value={preBillTel}
                                                                onChange={(e) => this.setState({ preBillTel: e.target.value })}
                                                                style={{
                                                                    width: 45,
                                                                    position: "absolute",
                                                                    top: 22,
                                                                    padding: "6px !important",
                                                                    paddingLeft: 5,
                                                                    borderLeft: 0,
                                                                    borderTop: 0,
                                                                    background: "none",
                                                                    borderBottom: 0,
                                                                    height: 35
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-xl-3">
                                                            <label htmlFor="validationCustom03">
                                                                Telephone 1
                                                            </label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                style={{ paddingLeft: 50 }}
                                                                value={billTel1} maxLength={'10'}
                                                                onInput={(e) => this.maxLengthCheck(e, 10)} size={'10'}
                                                                onKeyDown={(e) => this.preventMinus(e)}
                                                                onPaste={(e) => this.handlePaste(e)}
                                                                onWheel={(e) => e.target.blur()}
                                                                onChange={(e) => e.target.value >= 0 && this.setState({ billTel1: e.target.value })}
                                                            />
                                                            <input
                                                                type="text"
                                                                className="form-control-1"
                                                                value={preBillTel1}
                                                                onChange={(e) => this.setState({ preBillTel1: e.target.value })}
                                                                style={{
                                                                    width: 45,
                                                                    position: "absolute",
                                                                    top: 22,
                                                                    padding: "6px !important",
                                                                    paddingLeft: 5,
                                                                    borderLeft: 0,
                                                                    borderTop: 0,
                                                                    background: "none",
                                                                    borderBottom: 0,
                                                                    height: 35
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-xl-3 mb-1">
                                                            <label htmlFor="validationCustom01">Telefax</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                maxLength={50}
                                                                value={billTelFax}
                                                                onChange={e => this.setState({ billTelFax: e.target.value })}
                                                            />
                                                        </div>

                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-xl-3">
                                                            <label htmlFor="validationCustom03">
                                                                Primary Email<span className="text-red">*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                disabled={true}
                                                                id="validationCustom03"
                                                                value={billPrimaryEmail}
                                                                onChange={(e) => this.setState({ billPrimaryEmail: e.target.value, billPrimaryEmailErr: "" })}
                                                            />
                                                            {<span className='text-danger text-start text-left d-block mt-1'>{billPrimaryEmailErr}</span>}
                                                        </div>
                                                        <div className="col-xl-3">
                                                            <label htmlFor="validationCustom03">
                                                                Secondary Email
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="validationCustom03"
                                                                value={billSecondaryEmail}
                                                                maxLength={'100'}
                                                                onChange={(e) => this.setState({ billSecondaryEmail: e.target.value })}
                                                            />
                                                        </div>
                                                        <div className="col-xl-6">
                                                            <label htmlFor="validationCustom03">
                                                                Website
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="validationCustom03"
                                                                maxLength={'100'}
                                                                value={billWeb}
                                                                onChange={(e) => this.setState({ billWeb: e.target.value })}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    regCountry.toLowerCase().trim() == "india" ?
                                                        (
                                                            <div className="main-container">
                                                                <div className="row mt-4">
                                                                    <div className="col-md-12">
                                                                        <h6 style={{ padding: "5px 7px 0px 0px", color: "#0072ff" }}>
                                                                            Company Credentials
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                                <div className="row ">
                                                                    <div className="col-xl-3">
                                                                        <label htmlFor="validationCustom03">
                                                                            PAN Card Name
                                                                            {regCountry.toLowerCase().trim() == "india" && <span className="text-red">*</span>}
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="validationCustom03"
                                                                            // disabled={true}
                                                                            value={panName}
                                                                            onChange={(e) => this.setState({ panName: e.target.value, panNameErr: "" })}
                                                                        />
                                                                        {regCountry.toLowerCase().trim() == "india" && <span className='text-danger text-start text-left d-block mt-1'>{panNameErr}</span>}
                                                                    </div>
                                                                    <div className="col-xl-3">
                                                                        <label htmlFor="validationCustom03">
                                                                            PAN Card no
                                                                            {regCountry.toLowerCase().trim() == "india" && <span className="text-red">*</span>}
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="validationCustom03"
                                                                            // disabled={true}
                                                                            value={panNo}
                                                                            onChange={(e) => this.setState({ panNo: e.target.value, panNoErr: "" })}
                                                                        />
                                                                        {regCountry.toLowerCase().trim() == "india" && <span className='text-danger text-start text-left d-block mt-1'>{panNoErr}</span>}
                                                                    </div>
                                                                    <div className="col-xl-3">
                                                                        <label htmlFor="validationCustom03">
                                                                            TAN Number
                                                                            {regCountry.toLowerCase().trim() == "india" && <span className="text-red">*</span>}
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="validationCustom03"
                                                                            // disabled={true}
                                                                            value={tanNo}
                                                                            onChange={(e) => this.setState({ tanNo: e.target.value, tanNoErr: "" })}
                                                                        />
                                                                        {regCountry.toLowerCase().trim() == "india" && <span className='text-danger text-start text-left d-block mt-1'>{tanNoErr}</span>}
                                                                    </div>
                                                                    <div className="col-xl-3">
                                                                        <label htmlFor="validationCustom03">
                                                                            GST No
                                                                            {regCountry.toLowerCase().trim() == "india" && <span className="text-red">*</span>}
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="validationCustom03"
                                                                            // disabled={true}
                                                                            value={gstNo}
                                                                            onChange={(e) => this.setState({ gstNo: e.target.value, gstNoErr: "" })}
                                                                        />
                                                                        {regCountry.toLowerCase().trim() == "india" && <span className='text-danger text-start text-left d-block mt-1'>{gstNoErr}</span>}
                                                                    </div>
                                                                    <div className="col-xl-3">
                                                                        <label htmlFor="validationCustom03">
                                                                            Company Registration No
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="validationCustom03"
                                                                            // disabled={true}
                                                                            value={companyRegNo}
                                                                            onChange={(e) => this.setState({ companyRegNo: e.target.value })}
                                                                        />
                                                                        {/* {regCountry.toLowerCase().trim() == "india" && <span className='text-danger text-start text-left d-block mt-1'>{companyRegNoErr}</span>} */}
                                                                    </div>
                                                                    <div className="col-xl-3">
                                                                        <label htmlFor="validationCustom03">
                                                                            IEC Code
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="validationCustom03"
                                                                            // disabled={true}
                                                                            value={iecCode}
                                                                            onChange={(e) => this.setState({ iecCode: e.target.value })}
                                                                        />
                                                                        {/* {regCountry.toLowerCase().trim() == "india" && <span className='text-danger text-start text-left d-block mt-1'>{iecCodeErr}</span>} */}
                                                                    </div>

                                                                </div>
                                                                <div className="row mt-2">

                                                                </div>
                                                            </div>
                                                        ) : (null)
                                                }
                                                {
                                                    regCountry.toLowerCase().trim() == "india" &&
                                                    <div className="main-container bg-section p-2 mt-3">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <h6 style={{ padding: "5px 7px 0px 0px", color: "#0072ff" }}>
                                                                    Bank Details
                                                                </h6>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-xl-4">
                                                                <label htmlFor="validationCustom03">
                                                                    Account Name<span className="text-red">*</span>
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="validationCustom03"
                                                                    // disabled={true}
                                                                    value={accName}
                                                                    onChange={(e) => this.setState({ accName: e.target.value, accNameErr: "" })}
                                                                />
                                                                {/* {<span className='text-danger text-start text-left d-block mt-1'>{accNameErr}</span>} */}
                                                            </div>
                                                            <div className="col-xl-4">
                                                                <label htmlFor="validationCustom03">
                                                                    Account No<span className="text-red">*</span>
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="validationCustom03"
                                                                    // disabled={true}
                                                                    value={accNo} maxLength={'30'}
                                                                    onInput={e => this.maxLengthCheck(e, 30)}
                                                                    onChange={(e) => this.setState({ accNo: e.target.value, accNoErr: "" })}
                                                                />
                                                                {/* {<span className='text-danger text-start text-left d-block mt-1'>{accNoErr}</span>} */}
                                                            </div>

                                                            <div className="col-xl-4 ">
                                                                <label htmlFor="validationCustom01">Account Type</label>
                                                                <select
                                                                    className="form-control-1 select2 onl-bor select-hidden-accessible text-left"
                                                                    id="roledb"
                                                                    style={{ textAlign: "left" }}
                                                                    value={accType}
                                                                    // disabled={true}
                                                                    onChange={e => this.setState({ accType: e.target.value })}>
                                                                    <option>Select</option>
                                                                    <option value={'Saving account'}>Saving account</option>
                                                                    <option value={'Current account'}>Current account</option>
                                                                    <option value={'Overdraft account'}>Overdraft account</option>
                                                                    <option value={'Others'}>Others</option>
                                                                </select>
                                                                {accType == 'Others' ? (
                                                                    <input
                                                                        type="text"
                                                                        maxLength={20}
                                                                        // disabled={true}
                                                                        className="form-control acount-type-oth"
                                                                        value={accTypeOthers}
                                                                        onChange={e => this.setState({ accTypeOthers: e.target.value })}
                                                                    />
                                                                ) : (null)}

                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-xl-4">
                                                                <label htmlFor="validationCustom03">
                                                                    Bank Name
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="validationCustom03"
                                                                    // disabled={true}
                                                                    value={bankName}
                                                                    onChange={(e) => this.setState({ bankName: e.target.value })}
                                                                />
                                                            </div>
                                                            <div className="col-xl-4">
                                                                <label htmlFor="validationCustom03">
                                                                    Branch Name
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="validationCustom03"
                                                                    // disabled={true}
                                                                    value={branchName}
                                                                    onChange={(e) => this.setState({ branchName: e.target.value })}
                                                                />
                                                            </div>
                                                            <div className="col-xl-4">
                                                                <label htmlFor="validationCustom03">
                                                                    IFSC Code<span className="text-red">*</span>
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="validationCustom03"
                                                                    // disabled={true}
                                                                    value={ifsc}
                                                                    onChange={(e) => this.setState({ ifsc: e.target.value, ifscErr: "" })}
                                                                />
                                                                {/* {<span className='text-danger text-start text-left d-block mt-1'>{ifscErr}</span>} */}
                                                            </div>
                                                        </div>
                                                    </div >
                                                }
                                                <hr />
                                                <div className="row mt-4 chapter-list">
                                                    {
                                                        primaryChapterHeaderList.length > 0 && primaryChapterHeaderList.map((data) => (
                                                            <div>
                                                                <p>
                                                                    Selected Stall Category{" "}
                                                                    <span>
                                                                        <spa> : {data.StallTypeName}</spa>
                                                                    </span>
                                                                </p>
                                                                <p>
                                                                    Stall Area :
                                                                    <span>
                                                                        <spa>{`${data.StallArea} ${data.Unit}`}</spa>
                                                                    </span>
                                                                </p>
                                                                <p>
                                                                    Open Sides :{" "}
                                                                    <span>
                                                                        <spa>{data.OpenSides}</spa>
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        ))
                                                    }
                                                    <p>
                                                        Selected Primary Chapter :{" "}
                                                        <span>
                                                            <spa>{primaryChapterName}</spa>
                                                        </span>
                                                    </p>
                                                    {
                                                        subChapters.length > 0 && subChapters.map((data, index) => (
                                                            <p key={index}>
                                                                Selected Sub Chapter :{" "}
                                                                <span>
                                                                    <spa>{data.ChapterName}</spa>
                                                                </span>
                                                            </p>
                                                        ))
                                                    }
                                                </div>
                                                <div className=" mt-2">
                                                    <div className="table-responsive m-1">
                                                        <h6> Exhibit Details </h6>{<span className='text-danger ml-2' style={{ float: 'left' }}>{exbTableErr}</span>}
                                                        <button className="byn btn-primary rounded "
                                                            style={{ float: "right", marginBottom: "10px", width: "80px", height: "30px" }}
                                                            onClick={(e) => this.onAddClick(e)}
                                                        >Add</button>
                                                        <table
                                                            className="table table-bordered"
                                                            style={{ border: "1px solid #d8d8d8 !important" }}
                                                        >
                                                            <thead>
                                                                <tr>
                                                                    <th>Chapter Selected</th>
                                                                    <th>Machine Details</th>
                                                                    <th>HSN Code</th>
                                                                    <th colSpan={3}>Machine Dimension (mtr)</th>
                                                                    <th>Floor Capacity(Kgs per sq mtr)</th>
                                                                    <th>Machine Weight (Kg)</th>
                                                                    <th>Manufacturer Name</th>
                                                                    <th>Manufacturer Country</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td />
                                                                    <td />
                                                                    <td />
                                                                    <td>Length</td>
                                                                    <td>Breadth</td>
                                                                    <td>Height</td>
                                                                    <td />
                                                                    <td />
                                                                    <td />
                                                                    <td />
                                                                </tr>
                                                                {
                                                                    subChapters.length > 0 ? subChapters.map((data, index) => (
                                                                        <tr key={index}>
                                                                            <td>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    placeholder="3.7.22"
                                                                                    value={data.ChapterName}
                                                                                    onChange={(e) => this.onEnterProductName(e, index)}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    placeholder={0.0}
                                                                                    maxLength={'200'}
                                                                                    value={data.MachineDetails}
                                                                                    onChange={(e) => this.onMachineDetailsChange(e, index)}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    placeholder={0.0}
                                                                                    maxLength={'10'}
                                                                                    value={data.HSNCode}
                                                                                    onInput={(e) => this.maxLengthCheck(e, 10)}
                                                                                    onChange={(e) => this.onEnterHSNCode(e, index)}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                    maxLength={'9'}
                                                                                    value={data.Length}
                                                                                    onInput={(e) => this.maxLengthCheck(e, 10)}
                                                                                    onChange={(e) => this.onLengthChange(e, index)}

                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                    maxLength={'9'}
                                                                                    value={data.Breadth}
                                                                                    onInput={e => this.maxLengthCheck(e, 10)}
                                                                                    onChange={(e) => this.onBreadthChange(e, index)}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                    value={data.Height}
                                                                                    maxLength={'9'}
                                                                                    onInput={(e) => this.maxLengthCheck(e, 10)}
                                                                                    onChange={(e) => this.onHeightChange(e, index)}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    maxLength={'9'}
                                                                                    value={data.FloorCapacity}
                                                                                    onInput={(e) => this.maxLengthCheck(e, 10)}
                                                                                    onChange={(e) => this.onFloorCapacityChange(e, index)}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    value={data.MachineWeight}
                                                                                    maxLength={'9'}
                                                                                    onInput={(e) => this.maxLengthCheck(e, 10)}
                                                                                    onChange={(e) => this.onMachineWeightChange(e, index)}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    maxLength={'200'}
                                                                                    value={data.ManufacturerName}
                                                                                    onChange={(e) => this.onManufacturerNameChange(e, index)}

                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    maxLength={'100'}
                                                                                    value={data.ManufacturingCountry}
                                                                                    onChange={(e) => this.onManufacturingCountryChange(e, index)}
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    )) :
                                                                        <tr >
                                                                            <td colSpan={10} className="text-center">Record not available</td>
                                                                        </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    {/* {sessionStorage.getItem('isPaidByAgentRegistration') != null && sessionStorage.getItem('isPaidByAgentRegistration') == 0 ? ( */}
                                                    <div className="row mt-3">
                                                        <p>
                                                            <strong>
                                                                Note: Only Products manufactured in india are eligible for
                                                                subsidy
                                                            </strong>
                                                        </p>
                                                        <div className="table-responsive">
                                                            <p className="mb-2" style={{ float: "left", color: "#0072ff" }}>
                                                                As per Rule no. 7.1,7.2,7.8,7.9,and 7.10 the total rent payable
                                                                by us is as follows:
                                                            </p>
                                                            <p className="mb-2" style={{ float: "right", color: "#0072ff" }}>
                                                                Please refer Rule no. 7.1,7.2,7.8,7.9 and 7.10
                                                            </p>
                                                            <p />
                                                            <table className="table table-bordered">
                                                                <tbody>
                                                                    {
                                                                        estimateDetails.length > 0 ? estimateDetails.map((data) => (
                                                                            <tr>
                                                                                <th>{data.Description}</th>
                                                                                <th>{data.Installment1}</th>
                                                                                <th>{data.Installment2}</th>
                                                                                <th>{data.Total_amt}</th>
                                                                            </tr>
                                                                        )) :
                                                                            <>
                                                                                <tr className='text-center'><td colSpan={4} className='text-center'>Record not found</td></tr>
                                                                            </>
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    {/* ) : (null)} */}



                                                    <div className="row">
                                                        <p>
                                                            <input type="checkbox"
                                                                value={isAgree}
                                                                checked={isAgree}
                                                                readOnly
                                                            />
                                                            We hereby agree to indemnity the Exhibition Authority and its
                                                            Office-Bearers, Streering Committee Members, Executive Director,
                                                            officers, employees and members and hold them and each of them
                                                            harmless from and against any and all claims arising out of the
                                                            acts of the exhibitor, his agents, representatives and employees.
                                                            and all contractors, erectors, delivery persons, suppliers and
                                                            material persons, furnishing services, delivery work or materials
                                                            at the instance of the exhibitor Click Here
                                                        </p>
                                                        <p className="mb-0">
                                                            <input type="checkbox"
                                                                value={isAgree2}
                                                                checked={isAgree2}
                                                                readOnly
                                                            />I have read the space booking Rules and
                                                            Regulation for ITME AFRICA 2023 &amp; agree to abide by them
                                                        </p>
                                                        {
                                                            regCountry.toLowerCase().trim() == "india" &&
                                                            <p className="mb-0">
                                                                <input type="checkbox"
                                                                    value={isAgree3}
                                                                    checked={isAgree3}
                                                                    readOnly
                                                                />We hereby confirm that exhibits in the allocated booth to us based on the National exhibitor
                                                                application submitted are all manufactured in India and we are not showcasing /promoting any
                                                                foreign products/brand under our company banner.
                                                            </p>
                                                        }
                                                    </div>
                                                    <hr />
                                                    <div className="btn-group w-auto">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary rounded-0 d-block"
                                                            style={{ marginRight: 10 }}
                                                            onClick={() => this.editSpaceBooking()}
                                                        >
                                                            Update
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary rounded-0 d-block"
                                                            style={{ marginRight: 10 }}
                                                            onClick={() => this.onCancelClick()}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Modal isOpen={isOpen} onRequestClose={this.onAddClick}>
                    <div className="modal-dialog">
                        <div className="modal-content" style={{ marginTop: '6em', left: '-8em' }}>
                            <div className="modal-header p-3 pb-1">
                                <h3 className="card-title" style={{ fontSize: 14 }}><strong>Select Below Product</strong>
                                </h3>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ fontSize: '12px' }}
                                    onClick={e => this.setState({ isOpen: !isOpen })}
                                />
                            </div>
                            <div className="modal-body" style={{ maxHeight: '400px', overflow: 'auto' }}>
                                <div className="" style={{ paddingLeft: 10 }}>
                                    <form className="needs-validation pb-2" noValidate="">
                                        <div className="form-row">

                                            <div class="accordion" id="accordionExample">
                                                {
                                                    lev2.length > 0 && lev2.map(level2Data => (
                                                        <>
                                                            {
                                                                <div class="accordion-item">
                                                                    <h2 class="accordion-header" id={'heading_l1_' + level2Data.ChapterID}>
                                                                        <button class={"accordion-button collapsed"}
                                                                            type="button" data-bs-toggle="collapse"
                                                                            data-bs-target={'#collapse_l1_' + level2Data.ChapterID} aria-expanded="false"
                                                                            aria-controls={'collapse_l1_' + level2Data.ChapterID}>
                                                                            {
                                                                                level2Data.IsCheckbox &&
                                                                                <input type="checkbox" className="from-left-sp" name="" key={level2Data.ChapterID}
                                                                                    checked={level2Data.IsSelect}
                                                                                    onClick={e => this.onCheckLevel2(e, level2Data.ChapterID)} />
                                                                            }
                                                                            {level2Data.ChapterName} </button>
                                                                    </h2>
                                                                    {this.record(level2Data, lev3, lev4)}
                                                                </div>
                                                            }

                                                        </>
                                                    ))
                                                }
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
            </>
        )
    }
}

export default withRouter(connect(null, { getDetails_exhibitorSpaceBooking_action, edit_exhibitorSpaceBoking_action, fill_action })(ExhibitorsSpacebooking));