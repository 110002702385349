import { Component, forwardRef } from "react";
import Header from "../../../common_components/Header";
import Loader from "../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import Pagination from "../../../utils/pagination";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action } from '../../../actions/fillCombo_action';
import { downloadReport, toastError, toastSuccess } from "../../../utils/constants";
import AdminDrawer from "../../../common_components/admin_module_drawer";
import Modal from "react-modal";
import Footerwithdr from '../../../common_components/Footerwithdr';
import HeaderWithLogo from "../../../common_components/header_with_logo";
import "react-datepicker/dist/react-datepicker.css";



class VendorDashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            // Input Fields
            pendingPaymentCount: ""
        }
        // this.getDashboardData = this.getDashboardData.bind(this)

    }


    // On Component Load
    componentDidMount() {
        console.log(this.props.location)
        // this.getDashboardData()
    }


    render() {
        const { isLoading, pendingPaymentCount, summaryPage } = this.state
        return (
            <>

                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <AdminDrawer />
                    <h1>Dashboard</h1>
                </>

            </>
        );
    }


}

export default withRouter(connect(null, {})(VendorDashboard));