import React from 'react';




const Footer = ({ head, userId }) => {
  return (
    <>

      <div className="" style={{ bottom: '0px !important' }}>
        <div className='footer-out'>
          <div className="footer-sec">
            <strong>Ownership:</strong> All content, formats and images used on this site are owned or licensed by India ITME Society. You may not copy, reproduce, distribute, publish, display, perform, modify, create derivative works, transmit, or in any other way exploit any part of copyrighted material without permission of the copyright owner. Failure to comply shall result in legal action.
          </div>
          <div className="footer-first">
            ITME All Rights Reserved <i class="fa fa-copyright"></i> 2023
          </div>
        </div>
      </div>

    </>
  );
}
export default Footer;