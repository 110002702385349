import { axiosGet, axiosPost } from "../../../react_axios/Axios";
import { getExhibitorDetailsReportUrl, getReportListUrl, GET_REPORT_LIST, GET_EXHIBITOR_DETAILS_REPORT, getReceiptReportUrl, GET_RECEIPT_REPORT, getTaxInvoiceReportUrl, GET_TAX_INVOICE_REPORT, headers } from "../../../utils/constants";

export const getExhibitorDetailsReport_action = (exhibitionID, ExhRegistrationID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: exhibitionID,
            ExhRegistrationID: ExhRegistrationID
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getExhibitorDetailsReportUrl, params, headers)

        dispatch({
            type: GET_EXHIBITOR_DETAILS_REPORT,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getReportList_action = () => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: JSON.parse(sessionStorage.getItem("sessionExhibitionID")),
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getReportListUrl, params, headers)

        dispatch({
            type: GET_REPORT_LIST,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getReceiptReport_action = (exhibitionID, ExhRegistrationID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: exhibitionID,
            ExhRegistrationID: ExhRegistrationID
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getReceiptReportUrl, params, headers)

        dispatch({
            type: GET_RECEIPT_REPORT,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getTaxInvoiceReport_action = (exhibitionID, ExhRegistrationID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: exhibitionID,
            ExhRegistrationID: ExhRegistrationID
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getTaxInvoiceReportUrl, params, headers)

        dispatch({
            type: GET_TAX_INVOICE_REPORT,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}