import { axiosGet, axiosPost } from "../../../../react_axios/Axios";
import { headers, getCateringServicesMasterListUrl, insertUpdateCateringServicesMasterUrl, deleteCateringServicesMasterUrl, GET_CATERING_SERVICES_MASTER_LIST, INSERT_UPDATE_CATERING_SERVICES_MASTER, DELETE_CATERING_SERVICES_MASTER } from "../../../../utils/constants";


export const insertUpdateCateringServicesMaster_action = (CateringServicesMasterList) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            CateringServicesMasterList: CateringServicesMasterList
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosPost(insertUpdateCateringServicesMasterUrl, params, headers)

        dispatch({
            type: INSERT_UPDATE_CATERING_SERVICES_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getCateringServicesMasterList_action = () => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getCateringServicesMasterListUrl, params, headers)

        dispatch({
            type: GET_CATERING_SERVICES_MASTER_LIST,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const deleteCateringServicesMaster_action = (deleteId) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            CateringServicesContactPersonID: deleteId
        }
        console.log("deleteExhibitionDocument_action Action------->", params)

        const res = await axiosGet(deleteCateringServicesMasterUrl, params, headers)

        dispatch({
            type: DELETE_CATERING_SERVICES_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}