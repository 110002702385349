import { axiosGet } from "../../../../react_axios/Axios";
import { GET_ADMIN_PARTICIPANT_SUMMARY_LIST_ACTION_TYPE, getAdminPaymentSummaryListUrl, GET_ADMIN_PAYMENT_SUMMARY_LIST_ACTION_TYPE, getAdminParticipantSummaryListUrl, getAdminAreaSummaryListUrl, GET_ADMIN_AREA_SUMMARY_LIST_ACTION_TYPE, GET_ADMIN_APPLICATION_SUMMARY_LIST_ACTION_TYPE, getAdminAdditionalSummaryListUrl, getAdminApplicationSummaryListUrl, GET_ADMIN_ADDITIONAL_SUMMARY_LIST_ACTION_TYPE, headers } from "../../../../utils/constants";

export const getAdminAdditionalSummaryList_action = () => async (dispatch) => {

    try {
        const params = {
            Userid: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
        }
        console.log("getAdminDashboardData_action Action------->", params)

        const res = await axiosGet(getAdminAdditionalSummaryListUrl, params, headers)

        dispatch({
            type: GET_ADMIN_ADDITIONAL_SUMMARY_LIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getAdminApplicationSummaryList_action = () => async (dispatch) => {

    try {
        const params = {
            Userid: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
        }
        console.log("getAdminDashboardData_action Action------->", params)

        const res = await axiosGet(getAdminApplicationSummaryListUrl, params, headers)

        dispatch({
            type: GET_ADMIN_APPLICATION_SUMMARY_LIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getAdminAreaSummaryList_action = () => async (dispatch) => {

    try {
        const params = {
            Userid: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
        }
        console.log("getAdminDashboardData_action Action------->", params)

        const res = await axiosGet(getAdminAreaSummaryListUrl, params, headers)

        dispatch({
            type: GET_ADMIN_AREA_SUMMARY_LIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getAdminParticipantSummaryList_action = () => async (dispatch) => {

    try {
        const params = {
            Userid: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
        }
        console.log("getAdminDashboardData_action Action------->", params)

        const res = await axiosGet(getAdminParticipantSummaryListUrl, params, headers)

        dispatch({
            type: GET_ADMIN_PARTICIPANT_SUMMARY_LIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getAdminPaymentSummaryList_action = () => async (dispatch) => {

    try {
        const params = {
            Userid: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
        }
        console.log("getAdminDashboardData_action Action------->", params)

        const res = await axiosGet(getAdminPaymentSummaryListUrl, params, headers)

        dispatch({
            type: GET_ADMIN_PAYMENT_SUMMARY_LIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}