import { Component } from "react";
import Loader from "../../../../utils/Loader";
import { ToastContainer, toast } from "react-toastify";
import HeaderWithLogo from "../../../../common_components/header_with_logo";
import AdminDrawer from "../../../../common_components/admin_module_drawer";
import { withRouter } from "../../../../utils/withRouter";
import { connect } from "react-redux";
import {
  taxInvoiceList_action,
  taxInvoiceDetails_action,
} from "../../../../actions/admin_module_action/tax_invoice_action/tax_invoice_action";
import moment from "moment";
import Footerwithdr from "../../../../common_components/Footerwithdr";
import { fill_action } from "../../../../actions/fillCombo_action";

class taxInvoiceEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      UserID: "",
      ExhibitionID: "",
      ReceiptID: "",
      AccountID: "",
      ReceiptNo: "",
      State: "",
      CountryName: "",
      taxInvoiceData: [],
      CompanyName: "",
      Name: "",
      ReceiptNo: "",
      ReceiptDate: "",
      ProformaInvoice_No: "",
      ReceivedAmount: "",
      currentPage: 1,
      countryList: [],
      stateList: [],
      exhibitorTypeList: [],
      exhibitorFillList: [],
      exhibitorFillId: "",
      exhibitorTypeId: "",
      stateId: "",
      countryId: "",
      selectAll: false,
      checkboxStates: [],
    };
    this.taxInvoiceDetails = this.taxInvoiceDetails.bind(this);
  }
  componentDidMount() {
    if (JSON.parse(sessionStorage.getItem("loginDataSS")) == null) {
      console.log("log");
      setTimeout(() => {
        this.props.navigate("/login");
      }, 500);
    }
    this.setState({
      UserID: JSON.parse(sessionStorage.getItem("loginDataSS")).UserID,
      ExhibitionID:
        this.props.location.state != null &&
          this.props.location.state.ExhibitionID != null
          ? this.props.location.state.ExhibitionID
          : "",
      ReceiptID:
        this.props.location.state != null &&
          this.props.location.state.ReceiptID != null
          ? this.props.location.state.ReceiptID
          : "",
    });
    console.log("this.props.location.state", this.props.location.state);
    this.taxInvoiceDetails();
  }
  backToInvoiceList() {
    this.props.navigate("/taxinvoicelist");
  }
  taxInvoiceDetails() {
    const UserID = JSON.parse(sessionStorage.getItem("loginDataSS")).UserID;
    const ExhibitionID =
      this.props.location.state != null &&
        this.props.location.state.ExhibitionID != null
        ? this.props.location.state.ExhibitionID
        : "";
    const ReceiptID =
      this.props.location.state != null &&
        this.props.location.state.ReceiptID != null
        ? this.props.location.state.ReceiptID
        : "";
    if ((UserID, ExhibitionID, ReceiptID)) {
      this.props
        .taxInvoiceDetails_action(UserID, ExhibitionID, ReceiptID)
        .then((data) => {
          console.log("tax invoice details--------", data.data[0]);
          if (data.error !== 1) {
            this.setState({
              CompanyName: data.data[0][0]["CompanyName"],
              Name: data.data[0][0]["Name"],
              ProformaInvoice_No: data.data[0][0]["ProformaInvoice_No"],
              ReceiptDate: data.data[0][0]["ReceiptDate"],
              ReceiptNo: data.data[0][0]["ReceiptNo"],
              ReceivedAmount: data.data[0][0]["ReceivedAmount"],
            });
          } else {
            toast.error(data.data, {
              theme: "colored",
              autoClose: 3000,
              hideProgressBar: true,
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }
  render() {
    const {
      isLoading,
      CompanyName,
      Name,
      ProformaInvoice_No,
      ReceiptDate,
      ReceiptNo,
      ReceivedAmount,
      taxInvoiceData,
      currentPage,
      exhibitorFillId,
      exhibitorTypeId,
      stateId,
      countryId,
      exhibitorFillList,
      exhibitorTypeList,
      stateList,
      countryList,
    } = this.state;
    return (
      <>
        {isLoading ? <Loader></Loader> : null}
        <ToastContainer />
        <HeaderWithLogo />

        <>
          <AdminDrawer />
          <div
            className={
              JSON.parse(sessionStorage.getItem("loginDataSS")).RoleID == 1
                ? "with-dr-container"
                : "with-dr-container com-height"
            }
          >
            <div className="main-content app-content mt-0">
              <div className="container-fluid">
                <div className="row">
                  <div className="step-name">
                    <p>Tax Invoice Details</p>
                  </div>
                </div>
                <div style={{ marginTop: 10 }}>
                  <div className="row mb-2 mt-4 pt-3 pb-3">
                    <div className="col-md-4 pr-0">
                      <label htmlFor="validationCustom03">Company Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={CompanyName}
                        disabled={true}
                      />
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="validationCustom03">Invoice No</label>
                      <input
                        type="text"
                        className="form-control"
                        value={ProformaInvoice_No}
                        disabled={true}
                      />
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="validationCustom03">Date</label>
                      <input
                        type="text"
                        className="form-control"
                        value={
                          ReceiptDate
                            ? moment(ReceiptDate).format("DD-MM-YYYY")
                            : null
                        }
                        disabled={true}
                      />
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="validationCustom03">Receipt No</label>
                      <input
                        type="text"
                        className="form-control"
                        value={ReceiptNo}
                        disabled={true}
                      />
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="validationCustom03">Amount</label>
                      <input
                        type="text"
                        className="form-control"
                        value={ReceivedAmount?.toLocaleString("en-IN")}
                        disabled={true}
                      />
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="validationCustom03">Type</label>
                      <input
                        type="text"
                        className="form-control"
                        value={Name}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="row p-0 mb-4">
                    <div className="btn-group d-flex justify-content-center">
                      <button
                        className="btn rounded-0 btn-primary"
                        style={{ padding: "3px 10px", maxWidth: "6rem" }}
                        onClick={() => this.backToInvoiceList()}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 1 &&
            <Footerwithdr />
          }
        </>
      </>
    );
  }
}
export default withRouter(
  connect(null, {
    taxInvoiceList_action,
    taxInvoiceDetails_action,
    fill_action,
  })(taxInvoiceEdit)
);
