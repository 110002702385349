import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { getSecurityApplicationMasterList_action, insertUpdateSecurityApplicationMaster_action, deleteSecurityApplicationMaster_action } from '../../../../../actions/admin_module_action/master_action/manual_form_master_action/security_application_master_action'
import { toastError, toastSuccess } from "../../../../../utils/constants";
import Modal from "react-modal";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import Footer from "../../../../../common_components/Footer";
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import moment from "moment";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";



class SecurityApplicationMasterList extends Component {

    // Initialize Constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isOpen: false,
            // currentPage: 1,
            SecurityApplicationContactList: [],
            SecurityApplicationDescriptionList: [],
            deleteID: '',
            deleteType: '',
        }
        this.getSecurityApplicationMasterList = this.getSecurityApplicationMasterList.bind(this)
        this.deleteSecurityApplicationMaster = this.deleteSecurityApplicationMaster.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.onDelete = this.onDelete.bind(this)
    }

    componentDidMount() {
        this.getSecurityApplicationMasterList()
    }

    getSecurityApplicationMasterList(status) {
        this.setState({ isLoading: true })
        this.props.getSecurityApplicationMasterList_action()
            .then((data) => {
                if (data.error != 1) {
                    var contactList = data.data[0]
                    var bankDetailsList = data.data[1]
                    var finalList = []
                    for (var i = 0; i < contactList.length; i++) {
                        for (var j = 0; j < bankDetailsList.length; j++) {
                            if (bankDetailsList[j].SecurityApplicationContactPersonID != null) {
                                if (contactList[i].SecurityApplicationContactPersonID == bankDetailsList[j].SecurityApplicationBankDetailID) {
                                    var mergObj = { ...contactList[i], ...bankDetailsList[j] }
                                    finalList.push(mergObj)
                                }
                            }
                        }
                    }
                    console.log(finalList)
                    // this.setState({ SecurityApplicationContactList: finalList })
                    this.setState({
                        SecurityApplicationContactList: finalList,
                        SecurityApplicationDescriptionList: data.data[2].length > 0 ? data.data[2] : []
                    })
                    this.setState({ isLoading: false })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    deleteSecurityApplicationMaster(id) {
        const { deleteType } = this.state
        this.setState({ isLoading: true })
        this.props.deleteSecurityApplicationMaster_action(deleteType == 1 ? id : '', deleteType == 2 ? id : '')
            .then((data) => {
                if (data.error != 1) {
                    console.log(data.data)
                    this.setState({ isLoading: false, deleteType: '' })
                    toastSuccess("deleted successfully")
                    setTimeout(() => {
                        this.getSecurityApplicationMasterList()
                    }, 200);
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
                this.setState({ isOpen: false })
            })
            .catch((err) => {
                console.log(err)
            })
    }


    // Handle Add 
    handleAdd(status) {
        this.props.navigate('/securityApplicationMasterAU', { state: { status: status } })
    }

    onDelete(id, index, status) {
        const { SecurityApplicationMasterList } = this.state
        console.log(status, index)
        this.setState({ deleteID: id, deleteType: status })
        this.setState({ isOpen: true, })
    }

    render() {
        const { isLoading, isOpen, SecurityApplicationContactList, SecurityApplicationDescriptionList } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <div className="app-content with-dr-container com-height">
                        <AdminDrawer />
                        <div className="row">
                            <div className="step-name">
                                <p>Security Application Master</p>
                            </div>
                        </div>
                        <div className="m-2">
                            <div className="table-responsive">
                                <div
                                    className="card-header p-3"
                                    style={{ paddingLeft: "7px !important" }}
                                >
                                    <h3 className="card-title" style={{ fontSize: 15 }}>
                                        Agency contact and bank details
                                    </h3>
                                </div>
                                <div className="table-responsive ">
                                    <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1">
                                        <thead>
                                            <tr>
                                                <th className="text-center" >Name</th>
                                                <th className="text-center" >Address</th>
                                                <th className="text-center" >Telephone</th>
                                                <th className="text-center" >Mobile</th>
                                                <th className="text-center" >Fax</th>
                                                <th className="text-center" >Primary Email</th>
                                                <th className="text-center" >Secondary Email</th>
                                                <th className="text-center" >BankName</th>
                                                <th className="text-center" >Beneficiary Name</th>
                                                <th className="text-center" >Beneficiary Address</th>
                                                <th className="text-center" >Account Number</th>
                                                <th className="text-center" >Swift Code</th>
                                                <th className="text-center" >IFSC Code </th>
                                                <th className="text-center" >PAN Number</th>
                                                <th className="text-center" >GST Number</th>
                                                <th style={{ width: '60px' }} className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                SecurityApplicationContactList.length > 0 ? (SecurityApplicationContactList.map((data, i) => (
                                                    <tr key={data.HousekeepingContactPersonID}>
                                                        <td className="text-center" >{data.Name}</td>
                                                        <td className="text-center" >{data.Address}</td>
                                                        <td className="text-center" >{data.Phone}</td>
                                                        <td className="text-center" >{data.Mobile}</td>
                                                        <td className="text-center" >{data.Fax}</td>
                                                        <td className="text-center" >{data.PrimaryEmail}</td>
                                                        <td className="text-center" >{data.SecondaryEmail}</td>
                                                        <td className="text-center" >{data.BankName}</td>
                                                        <td className="text-center"  >{data.BeneficiaryName}</td>
                                                        <td className="text-center"  >{data.BeneficiaryAddress}</td>
                                                        <td className="text-center"  >{data.AccountNumber}</td>
                                                        <td className="text-center"  >{data.SwiftCode}</td>
                                                        <td className="text-center"  >{data.IFSCCode}</td>
                                                        <td className="text-center"  >{data.PAN}</td>
                                                        <td className="text-center"  >{data.GST}</td>
                                                        <td className="text-center">
                                                            <button
                                                                type="button"
                                                                className="btn btn-outline-danger btn-sm"
                                                                onClick={(e) => this.onDelete(data.SecurityApplicationContactPersonID, i, 1)}
                                                            >
                                                                <i className="fa fa-trash action_btn_table" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))) : <tr><td className="text-center" colSpan={20}>No record found</td></tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <button
                                    type="button"
                                    className="btn btn-primary rounded-0 d-block mt-4"
                                    style={{ marginRight: 10, float: "right" }}
                                    onClick={() => this.handleAdd(1)}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                        <div className="m-2">
                            <div className="table-responsive">
                                <div
                                    className="card-header p-3"
                                    style={{ paddingLeft: "7px !important" }}
                                >
                                    <h3 className="card-title" style={{ fontSize: 15 }}>
                                        Security Application Description List
                                    </h3>
                                </div>
                                <div className="table-responsive ">
                                    <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1">
                                        <thead>
                                            <tr>
                                                <th className="text-center" >Date</th>
                                                <th className="text-center" >Description</th>
                                                <th className="text-center" >Day Rate (INR)</th>
                                                <th className="text-center" >Night Rate (INR)</th>
                                                <th className="text-center" >Day Rate (USD)</th>
                                                <th className="text-center" >Night Rate (USD)</th>
                                                <th style={{ width: '60px' }} className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                SecurityApplicationDescriptionList.length > 0 ? (SecurityApplicationDescriptionList.map((data, i) => (
                                                    <tr key={data.SecurityApplicationDescriptionID}>
                                                        <td className="text-center" >{moment(data.Date).format('DD-MM-YYYY')}</td>
                                                        <td className="text-center" >{data.Description}</td>
                                                        <td className="text-center" >{data.DayRateINR}</td>
                                                        <td className="text-center" >{data.NightRateINR}</td>
                                                        <td className="text-center" >{data.DayRateUSD}</td>
                                                        <td className="text-center" >{data.NightRateUSD}</td>
                                                        <td className="text-center">
                                                            <button
                                                                type="button"
                                                                className="btn btn-outline-danger btn-sm"
                                                                onClick={(e) => this.onDelete(data.SecurityApplicationDescriptionID, i, 1)}
                                                            >
                                                                <i className="fa fa-trash action_btn_table" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))) : <tr><td className="text-center" colSpan={20}>No record found</td></tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <button
                                    type="button"
                                    className="btn btn-primary rounded-0 d-block mt-4"
                                    style={{ marginRight: 10, float: "right" }}
                                    onClick={() => this.handleAdd(2)}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                </>
                <Modal isOpen={isOpen} onRequestClose={() => this.setState({ isOpen: false })}>
                    <div className="modal-dialog" style={{ marginTop: '20%' }}>
                        <div className="modal-content confirm-logout">
                            <div class="modal-header text-right p-2">
                                <h5 class="modal-title modal_title_text text-danger">Do you really want to delete this record?</h5>

                                {/* <button type="button " class="close pop-close" data-dismiss="modal"
                                    onClick={() => this.setState({ isOpen: false })}
                                ><span aria-hidden="true" class="pop-cancel">&times;</span></button> */}
                            </div>
                            {/* <div class="modal-body">
                                <div class="row">
                                </div>
                            </div> */}
                            <div class="modal-footer p-1">
                                <div class="form-group text-right mod-fot">
                                    <button type="button" class="btn btn-del-pop"
                                        onClick={(e) => this.deleteSecurityApplicationMaster(this.state.deleteID)}
                                    >Yes</button>
                                    <button type="button" class="btn btn-can-pop" data-dismiss="modal" style={{ marginLeft: '5px' }}
                                        onClick={() => this.setState({ isOpen: false })}
                                    >No</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
                <Footer />
            </>
        )
    }
}

export default withRouter(connect(null, { getSecurityApplicationMasterList_action, insertUpdateSecurityApplicationMaster_action, deleteSecurityApplicationMaster_action })(SecurityApplicationMasterList));