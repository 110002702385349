import { axiosGet, axiosPost } from "../../../../react_axios/Axios";
import { headers, getBrandingOpportunityMasterListUrl, insertUpdateBrandingOpportunityMasterUrl, deleteBrandingOpportunityMasterUrl, GET_BRANDING_OPPORTUNITY_MASTER_LIST, INSERT_UPDATE_BRANDING_OPPORTUNITY_MASTER, DELETE_BRANDING_OPPORTUNITY_MASTER } from "../../../../utils/constants";


export const insertUpdateBrandingOpportunityMaster_action = (BrandingOppotunitymasterList, BrandingOppotunityBankDetailList) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            BrandingOppotunitymasterList: BrandingOppotunitymasterList,
            BrandingOppotunityBankDetailList: BrandingOppotunityBankDetailList
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosPost(insertUpdateBrandingOpportunityMasterUrl, params, headers)

        dispatch({
            type: INSERT_UPDATE_BRANDING_OPPORTUNITY_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getBrandingOpportunityMasterList_action = () => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getBrandingOpportunityMasterListUrl, params, headers)

        dispatch({
            type: GET_BRANDING_OPPORTUNITY_MASTER_LIST,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const deleteBrandingOpportunityMaster_action = (BrandingOppotunityID, BrandingOppotunityBankDetailID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            BrandingOppotunityID: BrandingOppotunityID,
            BrandingOppotunityBankDetailID: BrandingOppotunityBankDetailID
        }
        console.log("deleteExhibitionDocument_action Action------->", params)

        const res = await axiosGet(deleteBrandingOpportunityMasterUrl, params, headers)

        dispatch({
            type: DELETE_BRANDING_OPPORTUNITY_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}