import { axiosGet, axiosPost } from "../../../../react_axios/Axios";
import { headers, getHousekeepingApplicationMasterListUrl, insertUpdateHousekeepingApplicationMasterUrl, deleteHousekeepingApplicationMasterUrl, GET_HOUSEKEEPING_APPLICATION_MASTER_LIST, INSERT_UPDATE_HOUSEKEEPING_APPLICATION_MASTER, DELETE_HOUSEKEEPING_APPLICATION_MASTER } from "../../../../utils/constants";


export const insertUpdateHousekeepingApplicationMaster_action = (HousekeepingApplicationList) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            HousekeepingApplicationList: HousekeepingApplicationList
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosPost(insertUpdateHousekeepingApplicationMasterUrl, params, headers)

        dispatch({
            type: INSERT_UPDATE_HOUSEKEEPING_APPLICATION_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getHousekeepingApplicationMasterList_action = () => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getHousekeepingApplicationMasterListUrl, params, headers)

        dispatch({
            type: GET_HOUSEKEEPING_APPLICATION_MASTER_LIST,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const deleteHousekeepingApplicationMaster_action = (HousekeepingContactPersonID, HousekeepingBankDetailID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            HousekeepingContactPersonID: HousekeepingContactPersonID,
            HousekeepingBankDetailID: HousekeepingBankDetailID
        }
        console.log("deleteExhibitionDocument_action Action------->", params)

        const res = await axiosGet(deleteHousekeepingApplicationMasterUrl, params, headers)

        dispatch({
            type: DELETE_HOUSEKEEPING_APPLICATION_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}