import { axiosGet } from "../../../react_axios/Axios";
import { GET_ADMIN_DASHBOARD_DATA_ACTION_TYPE, adminDashboardUrl, headers } from "../../../utils/constants";


export const getAdminDashboardData_action = () => async (dispatch) => {

    try {
        const params = {
            Userid: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            AccountID: JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
        }
        console.log("getAdminDashboardData_action Action------->", params)

        const res = await axiosGet(adminDashboardUrl, params, headers)

        dispatch({
            type: GET_ADMIN_DASHBOARD_DATA_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}
