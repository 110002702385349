import { Component } from "react";
import Loader from "../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action } from '../../../../actions/fillCombo_action';
import { cityActionId, stallTypeActionId, stallListActionId, countryActionId, exhibitionActionId, toastError, toastSuccess } from "../../../../utils/constants";
import { emailValidator, panNoValidator, gstNoValidator, tanNoValidator, notMendetoryEmailValidator, pincodeValidator } from '../../../../utils/Validator'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import Pagination from "../../../../utils/pagination";
import AdminDrawer from "../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../common_components/header_with_logo";
import { useNavigate } from "react-router-dom";
import ExhibitorDrawer from "../../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../../common_components/exhibitor_module_header";
import { data } from "jquery";
import { insertUpdateOnlinePresenceMaster_action, getOnlinePresenceMasterDetails_action } from "../../../../actions/admin_module_action/master_action/online_presence_action";
import Footer from "../../../../common_components/Footer";


class OnlinePresenceAU extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            currentPage: 1,
            is_add: false,
            is_update: false,
            OnlinePresencePartnerID: '',
            Name: '',
        }

        this.getOnlinePresenceMasterDetails = this.getOnlinePresenceMasterDetails.bind(this)
        this.onPreviousClick = this.onPreviousClick.bind(this)
        this.insertOnlinePresenceMaster = this.insertOnlinePresenceMaster.bind(this)
        this.updateOnlinePresenceMaster = this.updateOnlinePresenceMaster.bind(this)
        this.onNextClick = this.onNextClick.bind(this)

    }

    componentDidMount() {
        this.setState({
            is_add: this.props.location.state != null && this.props.location.state.is_add != null ? this.props.location.state.is_add : false,
            is_update: this.props.location.state != null && this.props.location.state.is_update != null ? this.props.location.state.is_update : false,
            OnlinePresencePartnerID: this.props.location.state != null && this.props.location.state.OnlinePresencePartnerID != null ? this.props.location.state.OnlinePresencePartnerID : '',
        })
        setTimeout(() => {
            this.state.is_update && this.getOnlinePresenceMasterDetails()
        }, 100);
    }



    getOnlinePresenceMasterDetails() {
        this.setState({ isLoading: true })
        const { OnlinePresencePartnerID } = this.state
        this.props.getOnlinePresenceMasterDetails_action(OnlinePresencePartnerID)
            .then((data) => {
                if (data.error != 1) {
                    console.log(data.data[0])
                    this.setState({ Name: data.data[0][0].Name })
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    onPreviousClick() {
        this.props.navigate('/onlinePresenceList',)
    }

    insertOnlinePresenceMaster() {
        const { isLoading, exhibitionID, hallName, is_update, GSTNo, Name, Panno } = this.state
        const { hallID } = this.state
        this.setState({ isLoading: true })
        this.props.insertUpdateOnlinePresenceMaster_action(null, Name)
            .then((data) => {
                if (data.error != 1) {
                    console.log(data.data)
                    this.props.navigate('/OnlinePresenceList',)
                    toastSuccess("added successfully")
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    updateOnlinePresenceMaster() {
        const { isLoading, exhibitionID, OnlinePresencePartnerID, GSTNo, Panno, hallName, Name, is_update } = this.state
        this.setState({ isLoading: true })
        this.props.insertUpdateOnlinePresenceMaster_action(OnlinePresencePartnerID, Name)
            .then((data) => {
                if (data.error != 1) {
                    console.log(data.data)
                    this.props.navigate('/OnlinePresenceList',)
                    toastSuccess("update successful")
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    onNextClick() {
        const { is_update } = this.state;
        if (is_update == true) {
            this.updateOnlinePresenceMaster();
        } else {
            this.insertOnlinePresenceMaster();
        }
    }

    render() {
        const { isLoading, exhibitionID, currentPage, Name } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <div className="app-content with-dr-container com-height">
                        <AdminDrawer />
                        <div className="row">
                            <div className="step-name">
                                <p>{this.state.is_add == true ? 'Online Presence Master Add' : 'Online Presence Master Update'}</p>
                            </div>
                        </div>
                        <div className="m-3">
                            <div className="">
                                {
                                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 1 &&
                                    <div className="row ">
                                        <div className="col-md-4">
                                            <label>Online Presence<span className="text-red">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={Name}
                                                onChange={(e) => this.setState({ Name: e.target.value.toUpperCase(), })}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                            < div className="row mt-2" >
                                <div className="col-md-12">
                                    <div className="main-container container-fluid p-0 mt-2 pt-0 pb-0">
                                        <div className="mt-1 mb-4">
                                            {
                                                sessionStorage.getItem("sessionExhRegistrationID") == null &&
                                                <button className="btn btn-previous" onClick={e => this.onPreviousClick()}>
                                                    {" "}
                                                    Back{" "}
                                                </button>
                                            }

                                            <button className="btn btn-next-bot mx-2" onClick={e => this.onNextClick()} >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </>
                <Footer />
            </>)
    }

}
export default withRouter(connect(null, { fill_action, insertUpdateOnlinePresenceMaster_action, getOnlinePresenceMasterDetails_action })(OnlinePresenceAU));