import { GET_AIR_CONNECTION_APPROVAL_DETAILS_ACTION_TYPE, GET_AIR_CONNECTION_APPROVAL_LIST_ACTION_TYPE, GET_AIR_CONNECTION_APPROVAL_EXPORT_DATA_ACTION_TYPE, UPDATE_AIR_CONNECTION_APPROVAL__ACTION_TYPE } from "../../../../utils/constants";


const initialState = [];

function airConnectionApprovalReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_AIR_CONNECTION_APPROVAL_DETAILS_ACTION_TYPE:
            return [...state, payload];
        case GET_AIR_CONNECTION_APPROVAL_LIST_ACTION_TYPE:
            return [...state, payload];
        case GET_AIR_CONNECTION_APPROVAL_EXPORT_DATA_ACTION_TYPE:
            return [...state, payload];
        case UPDATE_AIR_CONNECTION_APPROVAL__ACTION_TYPE:
            return [...state, payload];

        default:
            return state;
    }
};

export default airConnectionApprovalReducer;