import { axiosGet, axiosPost } from "../../../react_axios/Axios";
import { GET_DETAILS_VISITOR_VISA_APPLICATION_ACTION_TYPE, GET_VISA_APPLICATION_ACTION_TYPE, GET_VISITOR_VISA_APPLIACTION_EXPORT_ACTION_TYPE, GET_VISITOR_VISA_APPLICATION_ACTION_TYPE, GET_VISITOR_VISA_INVITATION_ACTION_TYPE, UPDATE_VISITOR_VISA_APPROVAL_ACTION_TYPE, getDetailsVisitorVisaApplication, getDetailsVisitorVisaApplicationUrl, getVisitorVisaAppliacationExportUrl, getVisitorVisaApplicationUrl, getVisitorVisaInvitationUrl, headers, updateVisitorVisaApprovalUrl } from "../../../utils/constants";

export const getVisitorVisaInvitation_action = (companyName, status, exhibitorTypeId) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            CompanyName: companyName,
            Status: status,
            ExhibitorTypeID: exhibitorTypeId,
        }
        console.log("getVisitorVisaInvitation_action Action------->", params)

        const res = await axiosGet(getVisitorVisaInvitationUrl, params, headers)

        dispatch({
            type: GET_VISITOR_VISA_INVITATION_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getVisitorVisaApplication_action = (companyname) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            CompanyName: companyname,

        }
        console.log("getVisitorVisaApplication_action Action------->", params)

        const res = await axiosGet(getVisitorVisaApplicationUrl, params, headers)

        dispatch({
            type: GET_VISITOR_VISA_APPLICATION_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getDetailsVisitorVisaApplication_action = (id) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhVisitorID: id,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),

        }
        console.log("getDetailsVisitorVisaApplication_action Action------->", params)

        const res = await axiosGet(getDetailsVisitorVisaApplicationUrl, params, headers)

        dispatch({
            type: GET_DETAILS_VISITOR_VISA_APPLICATION_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const updateVisitorVisaApproval_action = (isApproved, list) => async (dispatch) => {
    try {
        const mapData = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            Is_Approved: isApproved,
            approvalList: list
        }
        console.log("updateVisitorVisaApproval_action Action------->", mapData)

        const res = await axiosPost(updateVisitorVisaApprovalUrl, mapData, headers)

        dispatch({
            type: UPDATE_VISITOR_VISA_APPROVAL_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getVisitorVisaisaApplicationExportData_action = () => async (dispatch) => {
    try {
        const params = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
        }
        console.log("getVisitorVisaisaApplicationExportData_action Action------->", params)

        const res = await axiosGet(getVisitorVisaAppliacationExportUrl, params, headers)

        dispatch({
            type: GET_VISITOR_VISA_APPLIACTION_EXPORT_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}