import { GET_CHECKED_EXHIBITORS_ACTION_TYPE, INSERT_UPDATE_FEATURES_ACTION_TYPE } from "../../../utils/constants";


const initialState = [];

function featuresReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_CHECKED_EXHIBITORS_ACTION_TYPE:
            return [...state, payload];
        case INSERT_UPDATE_FEATURES_ACTION_TYPE:
            return [...state, payload];
        default:
            return state;
    }
};

export default featuresReducer;