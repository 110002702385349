import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { getCateringServicesMasterList_action, insertUpdateCateringServicesMaster_action, deleteCateringServicesMaster_action } from '../../../../../actions/admin_module_action/master_action/manual_form_master_action/catering_services_master_action'
import { toastError, toastSuccess } from "../../../../../utils/constants";
import Modal from "react-modal";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import Footer from "../../../../../common_components/Footer";
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";



class CateringServicesMasterList extends Component {

    // Initialize Constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isOpen: false,
            // currentPage: 1,
            CateringServicesList: [],
            deleteID: '',
        }
        this.getCateringServicesMasterList = this.getCateringServicesMasterList.bind(this)
        this.deleteCateringServicesMaster = this.deleteCateringServicesMaster.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.onDelete = this.onDelete.bind(this)
    }

    componentDidMount() {
        this.getCateringServicesMasterList()
    }

    getCateringServicesMasterList(status) {
        this.setState({ isLoading: true })
        this.props.getCateringServicesMasterList_action()
            .then((data) => {
                if (data.error != 1) {
                    data.data.length > 0 && data.data.map((item) => {
                        item.isNew = false
                        var filePath = item['CateringImage']
                        var array = item['CateringImage'] != null && item['CateringImage'] != "" && filePath.split("/")
                        var FileName = array[array.length - 1]
                        item.img = FileName != undefined ? FileName : null
                        item.imgPath = filePath
                        item.imgType = null
                    })
                    data.data.length > 0 && this.setState({ CateringServicesList: data.data })
                    console.log(data.data)
                    this.setState({ isLoading: false })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    deleteCateringServicesMaster(id) {
        this.setState({ isLoading: true })
        this.props.deleteCateringServicesMaster_action(id)
            .then((data) => {
                if (data.error != 1) {
                    console.log(data.data)
                    this.setState({ isLoading: false })
                    toastSuccess("deleted successfully")
                    setTimeout(() => {
                        this.getCateringServicesMasterList(1)
                    }, 200);
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
                this.setState({ isOpen: false })
            })
            .catch((err) => {
                console.log(err)
            })
    }


    // Handle Add 
    handleAdd() {
        this.props.navigate('/cateringServicesMasterAU', { state: { is_add: true } })
    }

    onDelete(id, index, status) {
        const { CateringServicesList } = this.state
        console.log(status, index)
        this.setState({ deleteID: id })
        this.setState({ isOpen: true, })
    }

    render() {
        const { isLoading, isOpen, CateringServicesList } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <div className="app-content with-dr-container com-height">
                        <AdminDrawer />
                        <div className="row">
                            <div className="step-name">
                                <p>Catering Services Master</p>
                            </div>
                        </div>
                        <div className="m-2">
                            <div className="table-responsive">
                                <div
                                    className="card-header p-3"
                                    style={{ paddingLeft: "7px !important" }}
                                >
                                    <h3 className="card-title" style={{ fontSize: 15 }}>
                                      Catering Details
                                    </h3>
                                </div>
                                <div className="table-responsive ">
                                    <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1">
                                        <thead>
                                            <tr>
                                                <th className="text-center">Name</th>
                                                <th className="text-center">Address</th>
                                                <th className="text-center">Telephone</th>
                                                <th className="text-center">Mobile</th>
                                                <th className="text-center">Fax</th>
                                                <th className="text-center">Primary Email</th>
                                                <th className="text-center">Secondary Email</th>
                                                <th className="text-center">Company Name</th>
                                                <th className="text-center">Image</th>
                                                <th className="text-center" style={{ width: '60px' }}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                CateringServicesList.length > 0 ? (CateringServicesList.map((data, i) => (
                                                    <tr key={data.CateringServicesContactPersonID}>
                                                        <td className="text-center">{data.Name}</td>
                                                        <td className="text-center">{data.Address}</td>
                                                        <td className="text-center">{data.Phone}</td>
                                                        <td className="text-center">{data.Mobile}</td>
                                                        <td className="text-center">{data.Fax}</td>
                                                        <td className="text-center">{data.PrimaryEmail}</td>
                                                        <td className="text-center">{data.SecondaryEmail}</td>
                                                        <td className="text-center">{data.CompanyName}</td>
                                                        <td className="text-center">
                                                            {data.img != null && <div style={{
                                                                border: '1px solid #ccc',
                                                                padding: '10px',
                                                                display: 'inline-flex',
                                                                alignItems: 'center',
                                                                // marginTop: '10px'
                                                            }}>
                                                                <a
                                                                    href={data.CateringImage}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    <span style={{ marginRight: '10px' }}
                                                                    >{data.img}</span>
                                                                    {/* <i className={`fa fa-times text-danger img-can ${isView ? 'disabled' : ''}`}
                                                                onClick={!isView ? (e) => this.onDocDelete() : undefined}
                                                            ></i> */}
                                                                </a>
                                                            </div>}
                                                        </td>
                                                        <td className="text-center" >
                                                            <button
                                                                type="button"
                                                                className="btn btn-outline-danger btn-sm"
                                                                onClick={(e) => this.onDelete(data.CateringServicesContactPersonID, i)}
                                                            >
                                                                <i className="fa fa-trash action_btn_table" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))) : <tr><td className="text-center" colSpan={20}>No record found</td></tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <button
                                    type="button"
                                    className="btn btn-primary rounded-0 d-block mt-4"
                                    style={{ marginRight: 10, float: "right" }}
                                    onClick={() => this.handleAdd()}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                </>
                <Modal isOpen={isOpen} onRequestClose={() => this.setState({ isOpen: false })}>
                    <div className="modal-dialog" style={{ marginTop: '20%' }}>
                        <div className="modal-content confirm-logout">
                            <div class="modal-header text-right p-2">
                                <h5 class="modal-title modal_title_text text-danger">Do you really want to delete this record?</h5>

                                {/* <button type="button " class="close pop-close" data-dismiss="modal"
                                    onClick={() => this.setState({ isOpen: false })}
                                ><span aria-hidden="true" class="pop-cancel">&times;</span></button> */}
                            </div>
                            {/* <div class="modal-body">
                                <div class="row">
                                </div>
                            </div> */}
                            <div class="modal-footer p-1">
                                <div class="form-group text-right mod-fot">
                                    <button type="button" class="btn btn-del-pop"
                                        onClick={(e) => this.deleteCateringServicesMaster(this.state.deleteID)}
                                    >Yes</button>
                                    <button type="button" class="btn btn-can-pop" data-dismiss="modal" style={{ marginLeft: '5px' }}
                                        onClick={() => this.setState({ isOpen: false })}
                                    >No</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
                <Footer />
            </>
        )
    }
}

export default withRouter(connect(null, { getCateringServicesMasterList_action, insertUpdateCateringServicesMaster_action, deleteCateringServicesMaster_action })(CateringServicesMasterList));