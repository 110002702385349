import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { getExhibitorBadge_action, deleteExhibitorBadge_action, submitExhBadges_action } from '../../../../../actions/exhibitor_module_actions/manual_Forms_actions/forms_actions/exhibitor_badge_action/exhibitor_badge_action'
import { toastError, toastSuccess, countryActionId, cityActionId, stateActionId } from "../../../../../utils/constants";
import Modal from "react-modal";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";




class ExhibitorBadge extends Component {

    // Initialize Constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            exhibitorBadgesList: [],
            isDelOpen: false,
            exhibitorBadgeFormId: "",
            exBadgeListLength: "",
            DeadlineDate: "",
            currentDate: new Date(),
            MaxUnpaidBadge: '',
            unpaidArrayList: [],
            paidArrayList: [],
            StatusID: '',
            isShowExhibitorManualFormPaidBadge: '',
        }
        this.getExhibitorBadgesList = this.getExhibitorBadgesList.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.handleBack = this.handleBack.bind(this)
        this.onUpdateClick = this.onUpdateClick.bind(this)
        this.toggleDelModal = this.toggleDelModal.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.submitExhBadges = this.submitExhBadges.bind(this)
        this.onViewClick = this.onViewClick.bind(this)
    }

    componentDidMount() {
        this.setState({
            DeadlineDate: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.Deadline != "" ?
                new Date(this.props.location.state.Deadline) : '',

            StatusID: this.props.location.state != null && this.props.location.state.StatusID ? this.props.location.state.StatusID : '',
        })
        console.log("this.props", this.props)
        this.getExhibitorBadgesList()
    }

    // Get Exhibitor Badges
    getExhibitorBadgesList() {
        this.setState({ isLoading: true })
        this.props.getExhibitorBadge_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log("Exhibitor Badge Details", data.data)
                    this.setState({
                        MaxUnpaidBadge: data.maxUnpaidBadge[0]['MaxUnpaidBadge'],
                        isShowExhibitorManualFormPaidBadge: data.maxUnpaidBadge[0]['isShowExhibitorManualFormPaidBadge'],
                    })
                    if (data.data != '') {
                        const unpaidArray = []
                        const paidArray = []
                        data.data.map((item) => {
                            if (!item.isPaid) {
                                var filePath = item.VaccinationCertifcateFilePath
                                var array = item.VaccinationCertifcateFilePath != null && filePath.split("/")
                                item.vaccFileName = array[array.length - 1]
                                unpaidArray.push(item)
                            } else {
                                var filePath = item.VaccinationCertifcateFilePath
                                var array = item.VaccinationCertifcateFilePath != null && filePath.split("/")
                                item.vaccFileName = array[array.length - 1]
                                paidArray.push(item)
                            }
                        })
                        this.setState({ unpaidArrayList: unpaidArray, paidArrayList: paidArray })

                    }

                    // setting unique doc No 
                    var docNo = data.data.length
                    this.setState({ exhibitorBadgesList: data.data, isLoading: false, exBadgeListLength: docNo })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // Handle Add 
    handleAdd(value) {
        const { exBadgeListLength, DeadlineDate, StatusID } = this.state
        if (value == 'UnPaid') {
            this.props.navigate('/exhibitorbadgeAU', { state: { is_add: true, exBadgeListLength: exBadgeListLength, UnPaid: true, Deadline: DeadlineDate, StatusID: StatusID } })
        } else {
            this.props.navigate('/exhibitorbadgeAU', { state: { is_add: true, exBadgeListLength: exBadgeListLength, Paid: true, Deadline: DeadlineDate, StatusID: StatusID } })
        }
    }

    // Handle Back
    handleBack() {
        this.props.navigate('/manualForms')
    }

    // On Update Icon Click
    onUpdateClick(Id, value) {
        const { exBadgeListLength, DeadlineDate, StatusID } = this.state
        this.props.navigate('/exhibitorbadgeAU', { state: { is_update: true, exhibitorBadgeFormId: Id, Deadline: DeadlineDate, StatusID: StatusID, UnPaid: value } })
    }

    // On View Click
    onViewClick(Id) {
        const { exBadgeListLength, DeadlineDate, StatusID } = this.state
        this.props.navigate('/exhibitorbadgeAU', { state: { is_View: true, exhibitorBadgeFormId: Id, Deadline: DeadlineDate, StatusID: StatusID } })
    }

    // Delete Exhibitor Badge Modal Toggle
    toggleDelModal(id) {
        const { isDelOpen } = this.state
        this.setState({ isDelOpen: !isDelOpen, exhibitorBadgeFormId: id })
    }

    // // handle Receipt Delete"
    handleDelete(e) {
        e.preventDefault()
        const { isDelOpen, exhibitorBadgeFormId } = this.state
        this.setState({ isLoading: true })
        this.props.deleteExhibitorBadge_action(exhibitorBadgeFormId)
            .then((data) => {
                if (data.error != 1) {
                    toastSuccess("Exhibitor Badge Deleted SuccessFully")
                    this.setState({ isLoading: false, isDelOpen: !isDelOpen })
                    this.getExhibitorBadgesList()
                } else {
                    toastError(data.msg)
                    this.setState({ isLoading: false })
                }
            }).catch((error) => {
                console.log(error)
            })
    }

    // On Submit Exhibitor Badges
    submitExhBadges() {
        this.setState({ isLoading: true })
        this.props.submitExhBadges_action()
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ isLoading: false })
                    this.props.navigate('/manualForms')
                } else {
                    toastError(data.msg)
                    this.setState({ isLoading: false })
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }


    render() {
        const { isLoading, exhibitorBadgesList, isDelOpen, DeadlineDate, currentDate, MaxUnpaidBadge, unpaidArrayList, paidArrayList, StatusID, isShowExhibitorManualFormPaidBadge } = this.state
        console.log("List-------------------->1", currentDate)
        console.log("List-------------------->2", DeadlineDate)
        console.log("List-------------------->3", currentDate > DeadlineDate)
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <ExhibitorHeader />
                <ExhibitorDrawer />
                <div className="page">
                    <div className="page-main">
                        <div className="main-content with-dr-container">
                            <div className=" width-dr-nav">
                                <div className="row">
                                    <ExhibitorTopMenu />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card rounded-0 pt-1">
                                        <div className="card-header p-3">
                                            <h3 className="card-title">Exhibitor Badges</h3>
                                        </div>

                                        <div className="card-body p-2">
                                            <div className="row">
                                                {currentDate > DeadlineDate &&
                                                    <span className="text-danger">THE DEADLINE TO FILL OUT THE FORM HAS PASSED.</span>
                                                }
                                            </div>
                                            <div className="table-responsive mt-3">
                                                <div>
                                                    <button
                                                        className="btn btn-outline-primary p-1 mb-1"
                                                        type="btn"
                                                        style={{ float: "right", width: '60px' }}
                                                        disabled={currentDate > DeadlineDate || (unpaidArrayList.length == MaxUnpaidBadge)}
                                                        onClick={(e) => this.handleAdd('UnPaid')}
                                                    >
                                                        Add
                                                    </button>
                                                    <p className="m-0">Exhibitor UnPaid Badge <span className="text-danger">{unpaidArrayList.length == MaxUnpaidBadge && MaxUnpaidBadge != '' ? `(You Can Only Add ${MaxUnpaidBadge} UnPaid Badges)` : ""}</span></p>
                                                    <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th className="text-center">Sr No.</th>
                                                                <th>Images</th>
                                                                <th>Name</th>
                                                                <th>CATEGORY</th>
                                                                <th>DESIGNATION</th>
                                                                <th>COUNTRY</th>
                                                                <th>COMPANY NAME</th>
                                                                <th>REMARKS</th>
                                                                <th>Status</th>
                                                                <th className="text-center">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                unpaidArrayList.length > 0 ? unpaidArrayList.map((data, index) => (
                                                                    <tr>
                                                                        <td>{index + 1}</td>
                                                                        <td>{data.vaccFileName}</td>
                                                                        <td>{data.Name}</td>
                                                                        <td>{data.ChapterNames}</td>
                                                                        <td>{data.Designation}</td>
                                                                        <td>{data.CountryName}</td>
                                                                        <td>{data.CompanyName}</td>
                                                                        <td>{data.Remarks}</td>
                                                                        <td>{data.Status}</td>
                                                                        <td className="text-center">
                                                                            {data.Status != 'Approved' || (currentDate > DeadlineDate) ?
                                                                                <span className="action-icon">
                                                                                    <i className="fa fa-trash text-danger" aria-hidden="true" onClick={(e) => this.toggleDelModal(data.ExhBadgesFormID)} />
                                                                                </span> : <></>
                                                                            }
                                                                            <span className="">
                                                                                {data.Status == 'Approved' || (currentDate > DeadlineDate) ?
                                                                                    <i className="fa fa-eye text-info" aria-hidden="true" onClick={(e) => this.onViewClick(data.ExhBadgesFormID)} /> :
                                                                                    <i className="fa fa-pencil text-info" aria-hidden="true" onClick={(e) => this.onUpdateClick(data.ExhBadgesFormID, 1)} />
                                                                                }
                                                                            </span>

                                                                        </td>
                                                                    </tr>
                                                                )) :
                                                                    <tr >
                                                                        <td colSpan={10} className="text-center">Record not available</td>
                                                                    </tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {isShowExhibitorManualFormPaidBadge ?
                                                    <div className="mt-6">
                                                        <button
                                                            className="btn btn-outline-primary p-1 mb-1"
                                                            type="btn"
                                                            style={{ float: "right", width: '60px' }}
                                                            disabled={currentDate > DeadlineDate}
                                                            onClick={(e) => this.handleAdd('Paid')}
                                                        >
                                                            Add
                                                        </button>
                                                        <p className="m-0">Exhibitor Paid Badge</p>
                                                        <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 ">
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-center">Sr No.</th>
                                                                    <th>Images</th>
                                                                    <th>Name</th>
                                                                    <th>CATEGORY</th>
                                                                    <th>DESIGNATION</th>
                                                                    <th>COUNTRY</th>
                                                                    <th>COMPANY NAME</th>
                                                                    <th>REMARKS</th>
                                                                    <th>Status</th>
                                                                    <th className="text-center">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    paidArrayList.length > 0 ? paidArrayList.map((data, index) => (
                                                                        <tr>
                                                                            <td>{index + 1}</td>
                                                                            <td>{data.vaccFileName}</td>
                                                                            <td>{data.Name}</td>
                                                                            <td>{data.Category}</td>
                                                                            <td>{data.Designation}</td>
                                                                            <td>{data.CountryName}</td>
                                                                            <td>{data.CompanyName}</td>
                                                                            <td>{data.Remarks}</td>
                                                                            <td>{data.Status}</td>
                                                                            <td className="text-center">
                                                                                {data.Status != 'Approved' || (currentDate > DeadlineDate) ?
                                                                                    <span className="action-icon">
                                                                                        <i className="fa fa-trash text-danger" aria-hidden="true" onClick={(e) => this.toggleDelModal(data.ExhBadgesFormID)} />
                                                                                    </span> : <></>
                                                                                }
                                                                                <span className="">
                                                                                    {data.Status == 'Approved' || (currentDate > DeadlineDate) ?
                                                                                        <i className="fa fa-eye text-info" aria-hidden="true" onClick={(e) => this.onViewClick(data.ExhBadgesFormID)} /> :
                                                                                        <i className="fa fa-pencil text-info" aria-hidden="true" onClick={(e) => this.onUpdateClick(data.ExhBadgesFormID, 0)} />
                                                                                    }
                                                                                </span>

                                                                            </td>
                                                                        </tr>
                                                                    )) :
                                                                        <tr >
                                                                            <td colSpan={10} className="text-center">Record not available</td>
                                                                        </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    : <></>}
                                                <div class='d-flex m-2 mt-3'>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary rounded-0 d-block mt-3 mb-3"
                                                        style={{ marginRight: 10 }}
                                                        disabled={currentDate > DeadlineDate}
                                                        onClick={() => this.submitExhBadges()}
                                                    >
                                                        Submit
                                                    </button>
                                                    <button className="btn rounded-0 d-block mt-3 mb-3 apper-cancel" onClick={() => this.handleBack()}>Back</button>
                                                </div>
                                                <p className="m-0 mt-3">
                                                    <strong>Note:</strong>{" "}
                                                    <span className="text-danger">
                                                        All Exhibitors must carry Valid Photo Id &amp; Valid
                                                        Vaccination Certificate during {sessionStorage.getItem("sessionExhibitionName")}
                                                    </span>
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="footer-sec">
          <div class="sdsd">
              <strong>Ownership:</strong> All content, formats and images used on this site are owned or licensed by India ITME Society. You may not copy, reproduce, distribute, publish, display, perform, modify, create derivative works, transmit, or in any other way exploit any part of copyrighted material without permission of the copyright owner. Failure to comply shall result in legal action.
          </div>
      </div>

      <div class="footer-first">
          <div class="container-fluid">
              <div class="footer-first">  ITME All Rights Reserved <i class="fa fa-copyright" aria-hidden="true"></i> 2023  </div>
          </div>
      </div> */}
                        </div>
                    </div>
                </div>
                <Modal isOpen={isDelOpen} onRequestClose={() => this.setState({ isDelOpen: false })}>
                    <div className="modal-dialog">
                        <div className="modal-content confirm-logout">
                            <div class="modal-header p-2">
                                <h5 class="modal-title modal_title_text text-danger">Confirm Delete</h5>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={() => this.setState({ isDelOpen: false })} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <p class="pop_label">Do you really want to delete this record?</p>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer p-1">
                                <div class="form-group text-right mod-fot">
                                    <button type="button" class="btn btn-del-pop" onClick={(e) => this.handleDelete(e)}>Yes</button>
                                    <button type="button" class="btn btn-can-pop" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={() => this.setState({ isDelOpen: false })}>No</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>

            </>
        )
    }
}

export default withRouter(connect(null, { getExhibitorBadge_action, deleteExhibitorBadge_action, submitExhBadges_action })(ExhibitorBadge));