import { Component, forwardRef } from "react";
import Header from "../../../common_components/Header";
import Loader from "../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import Pagination from "../../../utils/pagination";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action } from '../../../actions/fillCombo_action';
import { downloadReport, toastError, toastSuccess } from "../../../utils/constants";
import AdminDrawer from "../../../common_components/admin_module_drawer";
import Modal from "react-modal";
import Footerwithdr from '../../../common_components/Footerwithdr';
import HeaderWithLogo from "../../../common_components/header_with_logo";
import "react-datepicker/dist/react-datepicker.css";
import ApplicationHistory from './aaplication_history/application_history';
import ApplicationStatusManagement from './application_status_management/application_status_management';
import AreaManagement from './area_management/area_management';



class ManageArea extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            summaryPage: 'applicationStatusManagement',
            // Input Fields
            pendingPaymentCount: ""
        }
        // this.getDashboardData = this.getDashboardData.bind(this)
        this.isRouteActive = this.isRouteActive.bind(this)
        this.toNavigate = this.toNavigate.bind(this)

    }


    // On Component Load
    componentDidMount() {
        console.log(this.props.location)
        // this.getDashboardData()
    }

    // Get DashBoard Data
    // getDashboardData() {
    //     this.setState({ isLoading: true })
    //     this.props.getAdminDashboardData_action()
    //         .then((data) => {
    //             if (data.error != 1) {
    //                 console.log("Admin Dashboard Data ------->", data.data)
    //                 this.setState({
    //                     isLoading: false,
    //                     pendingPaymentCount: data.data[0]['Count_Of_Payment']
    //                 })
    //             } else {
    //                 this.setState({ isLoading: false })
    //             }
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         })
    // }

    isRouteActive(routePath) {
        console.log(this.props.location)
        return this.props.location.pathname == routePath
    }

    toNavigate() {
        const { summaryPage } = this.state
        if (summaryPage == 'applicationStatusManagement') {
            return <ApplicationStatusManagement />
        }
        else if (summaryPage == 'applicationHistory') {
            return <ApplicationHistory />
        }
        else if (summaryPage == 'areaManagement') {
            return <AreaManagement />
        }
        else {
            return <ApplicationStatusManagement />
        }
    }


    render() {
        const { isLoading, pendingPaymentCount, summaryPage } = this.state
        return (
            <>

                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <AdminDrawer />

                    <div className="app-content with-dr-container com-height">
                        {/* <AdminDashboardTopmenu /> */}
                        <div className="navg mt-0">
                            <ul className="nav nav-pills">
                                <li onClick={() => this.setState({ summaryPage: 'applicationStatusManagement' })} className="nav-item ">
                                    {" "}
                                    <a className={summaryPage == 'applicationStatusManagement' ? "nav-link active px-4 py-2" : "nav-link  px-4 py-2"}>
                                        Application Status Management{" "}
                                    </a>{" "}
                                </li>
                                {/* </Link> */}
                                <li onClick={() => this.setState({ summaryPage: 'areaManagement' })} className="nav-item ">
                                    {" "}
                                    <a className={summaryPage == 'areaManagement' ? "nav-link active px-4 py-2" : "nav-link  px-4 py-2"}>
                                        Area Management{" "}
                                    </a>{" "}
                                </li>
                                {/* </Link> */}
                                <li onClick={() => this.setState({ summaryPage: 'applicationHistory' })} className="nav-item ">
                                    {" "}
                                    <a className={summaryPage == 'applicationHistory' ? "nav-link active px-4 py-2" : "nav-link  px-4 py-2"} >
                                        Application History{" "}
                                    </a>{" "}
                                </li>
                                {/* </Link> */}

                            </ul>
                        </div>
                        {this.toNavigate()}
                    </div>
                </>

                <Footerwithdr />

            </>
        );
    }


}

export default withRouter(connect(null, {})(ManageArea));