import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { toastError, toastSuccess } from "../../../../../utils/constants";
import { getDefaultDelegateMeeting_action, getDelegateMeetingDetails_action, insertUpdateDelegateMeeting_action } from '../../../../../actions/exhibitor_module_actions/manual_Forms_actions/forms_actions/delegate_meeting_action/delegate_meeting_action'
import Modal from "react-modal";
import { data } from "jquery";
import $ from 'jquery'
import { Tab, Nav } from "react-bootstrap";

// 
class DelegateMeeting extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            DeadlineDate: "",
            currentDate: new Date(),

            delegateMeetingList: [],
            currentDelegateMeetingList: [],
            countryList: [],
            StatusID: ''
        }
        this.handleCancel = this.handleCancel.bind(this)
        this.getDefaultDelegateMeeting = this.getDefaultDelegateMeeting.bind(this)
        this.updateList = this.updateList.bind(this)
        this.handleBookMeeting = this.handleBookMeeting.bind(this)

    }

    componentDidMount() {
        this.setState({
            DeadlineDate: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.Deadline != "" ?
                new Date(this.props.location.state.Deadline) : '',
            StatusID: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.StatusID != "" ?
                this.props.location.state.StatusID : '',
        })

        this.getDefaultDelegateMeeting()
        this.loadscript()
    }

    loadscript() {
        $(document).ready(function () {
            $(this).scrollTop(0);
        });
    }

    handleCancel() {
        this.props.navigate('/manualForms')
    }

    getDefaultDelegateMeeting() {
        const { Remark, Is_Agree, isQuantity, Is_Required } = this.state
        this.setState({ isLoading: true })
        this.props
            .getDefaultDelegateMeeting_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log(data)
                    var countryList = ['All']
                    for (var i = 0; i < data.data.length; i++) {
                        if (!countryList.includes(data.data[i].CountryName)) {
                            countryList.push(data.data[i].CountryName)
                        }
                    }
                    this.setState({
                        delegateMeetingList: data.data,
                        countryList: countryList,
                        currentDelegateMeetingList: data.data,
                    })
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    updateList(country) {
        const { countryList, delegateMeetingList } = this.state
        var list = []
        if (country != 'All') {
            for (var i = 0; i < delegateMeetingList.length; i++) {
                if (delegateMeetingList[i].CountryName == country) {
                    list.push(delegateMeetingList[i])
                }
            }
        }
        else {
            list = [...delegateMeetingList]
        }
        this.setState({ currentDelegateMeetingList: list })
    }

    handleBookMeeting(id, country) {
        const { DeadlineDate, StatusID } = this.state
        this.props.navigate('/delegateMeetingAU', {
            state: {
                Deadline: DeadlineDate, delegateMeetingID: id, country: country, StatusID: StatusID
            }
        })

    }

    // addCarPassessApplication() {
    //     const { Remark, Is_Agree, isQuantity, Is_Required, carPassessId } = this.state
    //     this.inputValidate()
    //     this.setState({ isLoading: true })
    //     if (Is_Agree) {
    //         this.props
    //             .insertUpdateCarPassessApplication_action(isQuantity,
    //                 Is_Required == 1 ? true : false,
    //                 Remark, Is_Agree, carPassessId)
    //             .then((data) => {
    //                 if (data.error != 1) {
    //                     this.setState({ isLoading: false })
    //                     this.handleCancel()
    //                 }
    //                 else {
    //                     this.setState({ isLoading: false })
    //                     toastError(data.msg)
    //                 }
    //             })
    //             .catch((e) => {
    //                 console.log(e);
    //             });
    //     } else {
    //         this.inputValidate()
    //         this.setState({ isLoading: false })
    //     }
    // }

    render() {
        const { isLoading, currentDate, DeadlineDate, countryList, currentDelegateMeetingList, delegateMeetingList } = this.state

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <ExhibitorHeader />
                <ExhibitorDrawer />
                <div className="main-content app-content mt-0 with-dr-container">
                    <div className="">
                        <div className="row">
                            <ExhibitorTopMenu />
                        </div>
                        <div className=" mt-2">
                            <div className="row">
                                <div class="card rounded-0 pt-1">
                                    <div class="card-header p-3" style={{ paddingleft: "7px !important" }}>
                                        <h3 class="card-title" style={{ fontsize: "15px" }}>Delegate Meeting</h3>
                                    </div>
                                    <Tab.Container defaultActiveKey={'All'} className="mt-2">
                                        <div className="mt-3">

                                            <Nav
                                                variant="pills"
                                                className="nav nav-tabs-custom nav-success "
                                                role="tablist"
                                            >
                                                {countryList.length > 0 && countryList.map((data) => (
                                                    <Nav.Item>
                                                        <Nav.Link
                                                            eventKey={data}
                                                            onClick={() => this.updateList(data)}
                                                        >
                                                            <i className="ri-store-2 me-1 align-bottom" />{" "}
                                                            {data}
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                ))}
                                            </Nav>
                                            <Tab.Content>
                                                <div class="card-body p-1">
                                                    <div class="table-responsive">
                                                        <table class="table border text-nowrap text-md-nowrap table-bordered mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th> Name || Company</th>
                                                                    <th class="text-center"> Designation</th>
                                                                    <th class="text-center"> Country</th>
                                                                    <th class="text-center"> Book Meeting</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {currentDelegateMeetingList.length > 0 && currentDelegateMeetingList.map((data) => (
                                                                    <tr key={data.DelegateMeetingID}>
                                                                        <td>{data.PersonInchargeName} || {data.CompanyName} </td>
                                                                        <td class="text-center">{data.Designation} </td>
                                                                        <td class="text-center">{data.CountryName} </td>
                                                                        <td class="text-center">
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-outline-primary btn-sm"
                                                                                onClick={(e) => this.handleBookMeeting(data.DelegateMeetingID, data.CountryName)}
                                                                            >
                                                                                Book Meeting        {/* {data.isBooked == 1 ? 'Edit' : '  Book Meeting'} */}
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </Tab.Content>
                                        </div>

                                    </Tab.Container>

                                </div>

                            </div>
                            <div className="row">
                                {currentDate > DeadlineDate ?
                                    <span className="text-danger">THE DEADLINE TO FILL OUT THE FORM HAS PASSED.</span> : null
                                }
                            </div>
                            <div class='d-flex m-3'>
                                <button className="btn rounded-0 d-block mt-1 mb-3 apper-cancel" onClick={() => this.handleCancel()}>Back</button>

                            </div>

                        </div>
                    </div>
                </div >
            </>
        )
    }
}

export default withRouter(connect(null, { getDefaultDelegateMeeting_action, getDelegateMeetingDetails_action, insertUpdateDelegateMeeting_action })(DelegateMeeting));