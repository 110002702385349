import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../utils/Loader";
import { userManualDownloadUrl } from '../utils/constants';
import Modal from "react-modal";
import StepHeader from '../screens/registration/stepHeader'

Modal.setAppElement("#root");


// {head,subHeader}
const ApplicationHeader = ({ isSteper }) => {

    const history = useNavigate();
    const [isLoading, setLoader] = useState(false);


    const loadScripts = () => {
        // This array contains all the files/CDNs 
        // const dynamicScripts = [
        //   'js/jquery.sticky.js',
        //   'js/metisMenu.min.js',
        //   'js/metisMenu-active.js',
        //   'js/main.js',
        //   'js/azia.js'
        // ];

        // for (let i = 0; i < dynamicScripts.length; i++) {
        //   const node = document.createElement('script');
        //   node.src = dynamicScripts[i];
        //   node.type = 'text/javascript';
        //   node.async = false;
        //   document.getElementsByTagName('head')[0].appendChild(node);
        // }
    }

    useEffect(() => {
        loadScripts();

    }, []);



    const onLogoutClick = () => {
        localStorage.clear();
        // history.push('/login');
    }

    const openPdf = () => {
        var URL = userManualDownloadUrl /* http://0.0.0.0:8000/ or http://127.0.0.1:8000/; */
        window.open(URL, null);
    }

    return (
        <>
            {isLoading ? (
                <Loader></Loader>
            ) : (
                null
            )}
            {/* app-Header */}
            <div className="app-header header sticky" style={{ paddingBottom: '32px', paddingBlockEnd: '21px' }}>
                <div className="container-fluid main-container">
                    <div className="d-flex">
                        <div class="side-header" style={{ height: '85px' }}>
                            <a class="header-brand1" >
                                <img src={sessionStorage.getItem('ExhibitionLogoSS')} class="header-brand-img header-brand-img-1 light-logo1" alt="top-left logo" />
                            </a>
                        </div>
                        <h5 className="m-0 mt-2 header-logo-name">INDIA ITME SOCIETY<br></br>    Trusted name for Quality and Commitment    </h5>




                    </div>
                </div>
                {isSteper ? (
                    <div className="row ">
                        <div className="step-namev2">
                            <p>Choose your Exhibition</p>
                        </div>
                    </div>
                ) : (null)}


            </div>
            {/* /app-Header */}
        </>
    );
}
export default ApplicationHeader;

