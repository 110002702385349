import { INSERT_UPDATE_EMAIL_CONFIGRATION, GET_EMAIL_CONFIGRATION_DETAILS } from "../../../utils/constants";

const initialState = [];

function emailConfigrationReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case INSERT_UPDATE_EMAIL_CONFIGRATION:
            return [...state, payload];
        case GET_EMAIL_CONFIGRATION_DETAILS:
            return [...state, payload];

        default:
            return state;
    }
};

export default emailConfigrationReducer