import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { toastError, toastSuccess } from "../../../../../utils/constants";
import { getHouseKeepingDefaultDetails_action, getHouseKeepingDetails_action, insertUpdateHouseKeeping_action } from '../../../../../actions/exhibitor_module_actions/manual_Forms_actions/forms_actions/houseKeeping_action/houseKeeping_action'
import Modal from "react-modal";
import { data } from "jquery";
import moment from "moment";
import $ from 'jquery'
// 
class HousekeepingConnection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            PowerConnectionList: [],
            Is_Agree: false,
            Remark: '',
            CGST: '',
            SGST: '',
            Is_AgreeErr: '',
            DeadlineDate: "",
            Is_Required: true,
            carPassessId: '',
            currentDate: new Date(),
            AccountNumber: '',
            BankName: '',
            BeneficiaryAddress: '',
            BeneficiaryName: '',
            GSTNumber: '',
            IFSCCodeValue: '',
            PANNumber: '',
            SwiftCode: '',
            tableList: [],
            totalAmount: '',
            IGST: '',
            StatusID: '',

            contactDetailsList: [],
        }
        // this.getPowerConnectionList = this.getPowerConnectionList.bind(this)

    }

    componentDidMount() {
        this.setState({
            DeadlineDate: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.Deadline != "" ?
                new Date(this.props.location.state.Deadline) : '',
            StatusID: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.StatusID != "" ?
                this.props.location.state.StatusID : '',
        })

        this.getHouseKeepingDefaultDetails()
        this.loadscript()
    }
    loadscript() {
        $(document).ready(function () {
            $(this).scrollTop(0);
        });
    }


    sumOfArrayOfObject(arrayOfObjects) {
        const sum = arrayOfObjects.reduce((accumulator, currentObject) => {
            return accumulator + currentObject.Amount;
        }, 0);
        this.setState({ totalAmount: sum })
    }

    handleCancel() {
        this.props.navigate('/manualForms')
    }

    inputValidate() {
        const { Is_Agree } = this.state
        console.log("Is_Agree", Is_Agree)

        if (Is_Agree == 0 || Is_Agree == false || Is_Agree == undefined) {
            this.setState({ Is_AgreeErr: 'kindly agree the disclaimer & Vendor Terms and conditions mentioned in Guideline.' })
        }
    }

    getHouseKeepingDefaultDetails() {
        const { Remark, Is_Agree, isQuantity, Is_Required } = this.state
        this.setState({ isLoading: true })
        this.props
            .getHouseKeepingDefaultDetails_action()
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ isLoading: false })
                    this.setState({
                        AccountNumber: data.bankDetails[0]['AccountNumber'],
                        BankName: data.bankDetails[0]['BankName'],
                        BeneficiaryAddress: data.bankDetails[0]['BeneficiaryAddress'],
                        BeneficiaryName: data.bankDetails[0]['BeneficiaryName'],
                        IFSCCodeValue: data.bankDetails[0]['IFSCCodeValue'],
                        GSTNumber: data.bankDetails[0]['GSTNumber'],
                        PANNumber: data.bankDetails[0]['PANNumber'],
                        SwiftCode: data.bankDetails[0]['SwiftCode'],
                        tableList: data.data,
                        contactDetailsList: data.contactDetails,
                    })
                    setTimeout(() => {
                        this.getHouseKeepingDetails()
                    }, 100);
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    getHouseKeepingDetails() {
        const { tableList } = this.state
        this.props
            .getHouseKeepingDetails_action()
            .then((data) => {
                if (data.error != 1) {
                    this.setState({
                        Quantity: data.data[0]['Quantity'],
                        Remark: data.data[0]['Remark'],
                        Is_Agree: data.data[0]['Is_Agree'],
                    })
                    setTimeout(() => {
                        // console.log("tableList----------", tableList)
                        this.sumOfArrayOfObject(tableList)
                    }, 100);
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    insertUpdateHouseKeeping() {
        const { Remark, Is_Agree, Quantity } = this.state
        this.inputValidate()
        this.setState({ isLoading: true })
        if (Is_Agree) {
            this.props
                .insertUpdateHouseKeeping_action(Remark, Is_Agree, Quantity)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({ isLoading: false })
                        this.handleCancel()
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            toastError("Plase Enter mandatory feilds!")
            this.inputValidate()
            this.setState({ isLoading: false })
        }
    }

    isQuantity(e, i) {
        const { tableList } = this.state
        const event = e.target.value.replace(/\D/, '')
        tableList.map((data, index) => {
            if (i === index) {
                if (event != '') {
                    data.Quantity = event
                    data.Amount = data.Rate * event
                } else {
                    data.Quantity = ''
                    data.Amount = 0
                }
            }
        })
        this.setState({ tableList: tableList })
        this.sumOfArrayOfObject(tableList)
    }

    render() {
        const { isLoading, Is_Agree, Remark, Is_AgreeErr, currentDate, DeadlineDate, AccountNumber,
            BankName,
            BeneficiaryAddress,
            BeneficiaryName,
            GSTNumber,
            IFSCCodeValue,
            PANNumber,
            SwiftCode, tableList, totalAmount, IGST,
            contactDetailsList, Quantity, StatusID
        } = this.state

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <ExhibitorHeader />
                <ExhibitorDrawer />
                <div className="main-content app-content mt-0 with-dr-container">
                    <div className="">
                        <div className="row">
                            <ExhibitorTopMenu />
                        </div>
                        <div className=" mt-2">
                            <div className="row">
                                <div class="card rounded-0 pt-1">
                                    <div class="card-header p-3" style={{ paddingleft: "7px !important" }}>
                                        <h3 class="card-title" style={{ fontsize: "15px" }}>Housekeeping Connection Application</h3>
                                    </div>
                                    <div class="card-body p-1 m-2">
                                        <ul style={{ lineHeight: '25px', textAlign: 'left', padding: 0 }}>
                                            <li>1) The cost/rates and terms & conditions of utilizing the individual services from the given service provider is solely discretion of exhibitors.
                                            </li>
                                            <li>2) India ITME Society is only information facilitator for the service provider & In no event shall INDIA ITME SOCIETY be liable for any direct, indirect, punitive, incidental, special consequential issues, whatsoever arising out of taking services with the above given service provider.
                                            </li>
                                        </ul>
                                        <strong><p className="m-0">Contact Details:.</p></strong>
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {contactDetailsList != '' && contactDetailsList.length > 0 ?
                                                contactDetailsList.map((data) => (
                                                    <>
                                                        <div class="table-responsive w-50" style={{ backgroundColor: '#ebebeb', marginBottom: '14px', borderRight: '1px solid lightgray' }}>
                                                            <div style={{ margin: '10px 0 10px 10px', lineHeight: '23px' }}>
                                                                <p className="m-0"><strong>Name:</strong>  {data.Name} </p>
                                                                <p className="m-0"><strong>Phone:</strong>  {data.Phone}</p>
                                                                <p className="m-0"><strong>Fax:</strong>  {data.Fax}</p>
                                                                <p className="m-0"><strong>Mobile No.</strong>  {data.Mobile}</p>
                                                                <p className="m-0"><strong>Email:</strong>  {data.PrimaryEmail}</p>
                                                                <p className="m-0"><strong>Alternate E-mail:</strong>  {data.SecondaryEmail}</p>
                                                                <p className="m-0"><strong>Address:</strong>  {data.Address}</p>
                                                            </div>
                                                        </div>
                                                    </>
                                                ))
                                                :
                                                <div class="table-responsive w-50" style={{ backgroundColor: '#ebebeb', marginBottom: '14px', borderRight: '1px solid lightgray' }}>
                                                    <div style={{ margin: '10px 0 10px 10px', lineHeight: '23px' }}>
                                                        <p className="m-0">No record found </p>
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        <div class="table-responsive" style={{ backgroundColor: '#ebebeb', marginBottom: '14px' }}>
                                            <div style={{ margin: '0px 0 10px 10px', lineHeight: '23px' }}>
                                                <strong><p className="m-0">Terms and Condition:-</p></strong>
                                                <div class="table-responsive" style={{ backgroundColor: '#ebebeb', marginBottom: '14px' }}>
                                                    <p className="m-0">1) The company reserves the right to refuse any order. Cancellation of orders will only be accepted when made in writing to Customer Service Department no later than 7 business days prior to the commencement of tenancy period. Cancellation of order is subject to a charge at half of standard rate.
                                                    </p>
                                                    <p className="m-0">2) Booth Cleaning Service includes floor cleaning with mop, broom, emptying waste paper bins and ashtray, wiping counter & desktop etc. (not exhibit), one daily service either after or before exhibition hours, each booth per one cleaner. Shift timings 7 am to 7 pm (day shift) 7 pm to 7 am (night shift)
                                                    </p>
                                                    <p className="m-0">3) Booth Cleaning Services does not cover removal of oil spillage, paints and other liquids deposited on the floor, walls and other surfaces.
                                                    </p>
                                                    <p className="m-0">4) The final request for Housekeeping must be received by 31 October 2022 for onward intimation to service provider.
                                                    </p>
                                                    <strong><p className="m-0">Note:- This is only manpower cost. Cost of material requirement will be extra</p></strong>
                                                    <p className="m-0"> • Rates mentioned above are on hire basis and applicable for show days only.</p>
                                                    <p className="m-0"> • Orders are valid only when accompanied with full payment before the deadline</p>
                                                    <p className="m-0"> • Please make DD or transfer payable to India Exposition Mart Ltd</p>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="table-responsive">
                                            <table class="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1">
                                                <thead>
                                                    <tr>
                                                        <th colSpan={2}>
                                                            Bank Details
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td><strong>Bank Name</strong></td>
                                                        <td>{BankName != null ? BankName : '-'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Beneficiary Name</strong></td>
                                                        <td>{BeneficiaryName != null ? BeneficiaryName : '-'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Beneficiary Address</strong></td>
                                                        <td>{BeneficiaryAddress != null ? BeneficiaryAddress : '-'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Account No</strong></td>
                                                        <td>{AccountNumber != null ? AccountNumber : '-'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Swift Code</strong></td>
                                                        <td>{SwiftCode != null ? SwiftCode : '-'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>IFSC Code</strong></td>
                                                        <td>{IFSCCodeValue != null ? IFSCCodeValue : '-'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>PAN No</strong></td>
                                                        <td>{PANNumber != null ? PANNumber : '-'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>GST No</strong></td>
                                                        <td>{GSTNumber != null ? GSTNumber : '-'}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="mt-4">
                                            <strong><p className="m-0">Quantity</p></strong>
                                            <p className="m-0 p-1" style={{ backgroundColor: '#ebebeb' }}>
                                                <input type='text' className="form-control w-30"
                                                    value={Quantity}
                                                    onChange={(e) => this.setState({ Quantity: e.target.value.replace(/\D/, '') })}
                                                    maxLength={30}
                                                />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row m-1">
                                    <form>
                                        <strong> <label>Remark</label></strong>
                                        <textarea class="form-control"
                                            value={Remark}
                                            onChange={(e) => this.setState({ Remark: e.target.value })}
                                            maxLength={300}
                                            rows="3">
                                        </textarea>
                                        <label class="d-flex mt-1" style={{ lineheight: "15px;" }}>
                                            <input type="checkbox"
                                                checked={Is_Agree}
                                                onChange={(e) => this.setState({ Is_Agree: e.target.checked, Is_AgreeErr: '' })}
                                            />
                                            <strong> I agree the above disclaimer & Vendor Terms and conditions mentioned in Guideline<span className="required">*</span>.</strong></label>
                                        {<span className='text-danger text-start text-left d-block mt-1'>{Is_AgreeErr}</span>}
                                    </form>
                                </div>

                            </div>
                            <div className="row">
                                {currentDate > DeadlineDate &&
                                    <span className="text-danger">THE DEADLINE TO FILL OUT THE FORM HAS PASSED.</span>
                                }
                            </div>
                            <div class='d-flex m-3'>

                                <button
                                    type="button"
                                    className="btn btn-primary rounded-0 d-block mt-3 mb-3"
                                    style={{ marginRight: 10 }}
                                    disabled={currentDate > DeadlineDate }
                                    onClick={() => this.insertUpdateHouseKeeping()}
                                >
                                    Submit
                                </button>
                                <button className="btn rounded-0 d-block mt-3 mb-3 apper-cancel" onClick={() => this.handleCancel()}>Back</button>

                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(connect(null, { getHouseKeepingDefaultDetails_action, getHouseKeepingDetails_action, insertUpdateHouseKeeping_action })(HousekeepingConnection));