import { INSERT_AND_UPDATE_LIQUOR_LICENSE_ACTION_TYPE, GET_LIQUOR_LICENSE_DETAILS_ACTION_TYPE } from "../../../../../utils/constants";


const initialState = [];

function liqourLicenseReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case INSERT_AND_UPDATE_LIQUOR_LICENSE_ACTION_TYPE:
            return [...state, payload];
        case GET_LIQUOR_LICENSE_DETAILS_ACTION_TYPE:
            return [...state, payload];

        default:
            return state;
    }
};

export default liqourLicenseReducer;