import { Component } from "react"
import Loader from "../../../utils/Loader"
import { ToastContainer } from "react-toastify"
import HeaderWithLogo from "../../../common_components/header_with_logo"
import AdminDrawer from "../../../common_components/admin_module_drawer";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import { getVisitorVisaApplication_action, updateVisitorVisaApproval_action } from "../../../actions/admin_module_action/visitor_visa_approval_action/visitor_visa_approval_action";
import { toastError, toastSuccess } from "../../../utils/constants";


class VisitorVisaApproval extends Component {
    // Initialize Constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            visaApplicationsList: [],
            CompanyName: ""
        }
        this.getVisitorVisaApplication = this.getVisitorVisaApplication.bind(this)
        this.updateVisitorVisaApproval = this.updateVisitorVisaApproval.bind(this)
        this.onCheckVisaApplication = this.onCheckVisaApplication.bind(this)
        this.onRemarkEnter = this.onRemarkEnter.bind(this)
        this.onResendLetter = this.onResendLetter.bind(this)
        this.onViewClick = this.onViewClick.bind(this)
    }

    // On Component Load
    componentDidMount() {

        this.props.location.state != null && this.props.location.state.companyName != "" && this.getVisitorVisaApplication(this.props.location.state.companyName)

        this.setState({
            CompanyName: this.props.location.state != null && this.props.location.state.companyName ? this.props.location.state.companyName : '',
        })
    }

    // get Visa Application 
    getVisitorVisaApplication(companyname) {
        this.setState({ isLoading: true })
        this.props.getVisitorVisaApplication_action(companyname)
            .then((data) => {
                if (data.error != 1) {
                    console.log("getVisitorVisaApplication Data", data.data)
                    data.data.map((item) => {
                        item.isSelected = false;
                        item.isReadOnly = true;
                    })
                    this.setState({
                        visaApplicationsList: data.data,
                        isLoading: false
                    })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // On Update Visa Approval
    updateVisitorVisaApproval(e, key) {
        const { visaApplicationsList, CompanyName } = this.state
        var applicationList = visaApplicationsList.filter((item) => item.isSelected == true)
        var list
        // checked Application List data Preparing to store
        var list = []
        applicationList.map(data => {
            var mapData = {
                'ExhVisitorID': parseInt(data.ExhVisitorID),
                "Remark": data.Remark
            }
            list.push(mapData)
        });
        if (applicationList.length > 0) {
            this.setState({ isLoading: true })
            this.props.updateVisitorVisaApproval_action(key == 1 ? true : false, list)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({ isLoading: false })
                        toastSuccess("Visa Approval Updated")
                        setTimeout(() => {
                            this.getVisitorVisaApplication(CompanyName)
                        }, 1000);
                    } else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            toastError("Select Atleast One Application")
        }
    }

    // On Check Visa Applications
    onCheckVisaApplication(e) {
        const { visaApplicationsList } = this.state
        var checked = e.target.checked
        var id = e.target.value

        visaApplicationsList.length > 0 && visaApplicationsList.map((data) => {
            if (checked) {
                if (id == data.ExhVisitorID) {
                    data.isSelected = true;
                    data.isReadOnly = false
                }
            } else {
                if (id == data.ExhVisitorID) {
                    data.isSelected = false;
                    data.isReadOnly = true
                }
            }
        })
        console.log("09000909090", visaApplicationsList)
        this.setState({})
    }

    /// On Remark Enter
    onRemarkEnter(e, id) {
        const { visaApplicationsList } = this.state
        var value = e.target.value
        var list = [...visaApplicationsList]
        list.length > 0 && list.map((data) => {
            if (data.ExhVisitorID == id) {
                data.Remark = value
            }
        })
        console.log("0101010100101", list)
        this.setState({visaApplicationsList : list})
    }

    // On Resend Visa Letter
    onResendLetter(e, data) {
        var list
        // Checked Application List data Preparing to store
        var list = []
        var mapData = {
            'ExhVisitorID': parseInt(data.ExhVisitorID),
            "Remark": data.Remark
        }
        list.push(mapData)
        this.setState({ isLoading: true })
        this.props.updateVisitorVisaApproval_action(true, list)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ isLoading: false })
                    toastSuccess("Visa Letter Sent")
                    // this.getVisaApplication()
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // On View Click
    onViewClick(e, id) {
        this.props.navigate('/visitorvisaform', { state: { visitorID: id } })
    }

    render() {
        const { isLoading, visaApplicationsList, CompanyName } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                < AdminDrawer />
                <div className="main-content app-content with-dr-container">
                    <div className="container-fluid p-3 pt-1">
                        <div className="">

                        </div>
                        <div className="row">
                            <div className="col-xl-12 p-0">
                                <div className="card rounded-0 pt-1">
                                    <div
                                        className="card-header p-3"
                                        style={{ paddingLeft: "7px !important" }}
                                    >
                                        <h3 className="card-title" style={{ fontSize: 15 }}>
                                            Visa Invitation-Visitor
                                        </h3>
                                    </div>
                                    <div className="card-body p-1">
                                        <div className=" mt-1 mb-1">
                                            <form role="form">
                                                <div className="input-group flex-nowrap col-md-7 p-0">
                                                    <span
                                                        className="input-group-text"
                                                        id="addon-wrapping"
                                                        style={{ background: "#d1d1d1", color: "#2a2a2a" }}
                                                    >
                                                        Company Name
                                                    </span>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        // placeholder="Please enter..."
                                                        aria-label="Username"
                                                        aria-describedby="addon-wrapping"
                                                        disabled
                                                        value={CompanyName}
                                                    />
                                                    {/* <button
                                                        className="btn btn-primary"
                                                        type="submit"
                                                        id="article-update-button"
                                                    >
                                                        Search
                                                    </button> */}
                                                </div>
                                            </form>
                                        </div>
                                        <div className="table-responsive">
                                            <div className="row mt-3 mb-2" style={{ float: "right" }}></div>
                                            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center">Sr No</th>
                                                        <th>Select</th>
                                                        <th>Passport No</th>
                                                        <th>Passport Front Page</th>
                                                        <th>Name</th>
                                                        <th>Country</th>
                                                        <th>Email ID</th>
                                                        {/* <th className="text-center">ID Number</th> */}
                                                        <th className="text-center">Passport Issue Date</th>
                                                        <th className="text-center">Passport Expiry Date</th>
                                                        <th>Status</th>
                                                        <th style={{ width: 200 }}>Remark</th>
                                                        <th>Action</th>
                                                        <th>Send Visa Letter</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        visaApplicationsList.length > 0 ? visaApplicationsList.map((data, index) => (
                                                            <tr>
                                                                <td className="text-center">{index + 1}</td>
                                                                <td className="text-center">
                                                                    <form>
                                                                        <input type="checkbox"
                                                                            checked={data.isSelected} value={data.ExhVisitorID}
                                                                            disabled={data.Status == "Approved" || data.Status == "Disapproved"}
                                                                            onChange={(e) => this.onCheckVisaApplication(e)}
                                                                        />
                                                                    </form>
                                                                </td>
                                                                <td>{data['ID Number']}</td>
                                                                <td className="text-center"

                                                                >
                                                                    <a
                                                                        href={data['Passport Front Page']}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                    >
                                                                        <img src={data['Passport Front Page']} style={{ width: 40 }} />
                                                                    </a>
                                                                </td>
                                                                <td>{data.Name}</td>
                                                                <td>{data.Country}</td>
                                                                <td>{data['Email ID']}</td>
                                                                {/* <td className="text-center">FA065106</td> */}
                                                                <td className="text-center">{new Date(data['Passport Iss. DT.']).toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' })}</td>
                                                                <td className="text-center">{new Date(data['Passport Expiry DT.']).toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' })}</td>
                                                                <td>{data.Status}</td>
                                                                <td>
                                                                    <form>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            style={{ width: 200 }}
                                                                            disabled={data.isReadOnly}
                                                                            value={data.Remark}
                                                                            onChange={(e) => this.onRemarkEnter(e, data.ExhVisitorID)}
                                                                        />
                                                                    </form>
                                                                </td>
                                                                <td>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-outline-primary"
                                                                        style={{ padding: "4px 10px" }}
                                                                        onClick={(e) => this.onViewClick(e, data.ExhVisitorID)}
                                                                    >
                                                                        View
                                                                    </button>
                                                                </td>
                                                                <td>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-outline-primary"
                                                                        style={{ padding: "4px 10px" }}
                                                                        disabled={!(data.Status == "Approved")}
                                                                        onClick={(e) => this.onResendLetter(e, data)}
                                                                    >
                                                                        Re-Send Visa Letter
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )) : <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                                                    }



                                                </tbody>
                                            </table>
                                            <div />
                                        </div>
                                        <div class="row mt-3">
                                            <div className="btn-group w-auto" role="group" aria-label="Basic example">
                                                <button type="button" class="btn btn-primary rounded-0" style={{ marginRight: "10px" }}
                                                    onClick={(e) => this.updateVisitorVisaApproval(e, 1)}
                                                >DATA APPROVAL & SEND VISA LETTER</button>
                                                <button type="button" class="btn btn-danger rounded-0"
                                                    onClick={(e) => this.updateVisitorVisaApproval(e, 2)}
                                                >DATA DISAPPROVAL</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div class="footer-sec">
          <div class="sdsd">
              <strong>Ownership:</strong> All content, formats and images used on this site are owned or licensed by India ITME Society. You may not copy, reproduce, distribute, publish, display, perform, modify, create derivative works, transmit, or in any other way exploit any part of copyrighted material without permission of the copyright owner. Failure to comply shall result in legal action.
          </div>
      </div>

      <div class="footer-first">
          <div class="container-fluid">
              <div class="footer-first">  ITME All Rights Reserved <i class="fa fa-copyright" aria-hidden="true"></i> 2023  </div>
          </div>
      </div> */}
                </div>

            </>
        )
    }
}
export default withRouter(connect(null, { getVisitorVisaApplication_action, updateVisitorVisaApproval_action })(VisitorVisaApproval))