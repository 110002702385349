import { Component } from "react";
import Loader from "../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action } from '../../../../actions/fillCombo_action';
import { cityActionId, countryActionId, toastError, toastSuccess } from "../../../../utils/constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import AdminDrawer from "../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../common_components/header_with_logo";
import { useNavigate } from "react-router-dom";
import ExhibitorDrawer from "../../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../../common_components/exhibitor_module_header";
import { data } from "jquery";
import { addRoleMaster_action, updateRoleMaster_action, getRoleMasterDetailsDefualt_action, getRoleMasterDetails_action } from "../../../../actions/admin_module_action/master_action/role_master_action";


class RoleMasterAU extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            Name: '',
            is_add: false,
            RoleID: '',
            is_update: false,
            moduleList: [],
            reportList: [],
            // isDisplayCountryList: false,
            // selectedModeuleList: [],
        }
        this.getFillList = this.getFillList.bind(this)
        // this.onCountrySearch = this.onCountrySearch.bind(this)
        // this.renderCountryList = this.renderCountryList.bind(this)
        this.addRoleMaster = this.addRoleMaster.bind(this)
        this.getRoleMasterDetails = this.getRoleMasterDetails.bind(this)
        this.handleBack = this.handleBack.bind(this)
        this.updateRoleMaster = this.updateRoleMaster.bind(this)
        this.RoleMasterAU = this.RoleMasterAU.bind(this)
        this.moduleRecord = this.moduleRecord.bind(this)
        this.getRoleMasterDetailsDefault = this.getRoleMasterDetailsDefault.bind(this)
        this.onModuleSelect = this.onModuleSelect.bind(this)
        this.reportRecord = this.reportRecord.bind(this)
    }

    componentDidMount() {
        this.setState({
            is_add: this.props.location.state != null && this.props.location.state.is_add != null ? this.props.location.state.is_add : false,
            is_update: this.props.location.state != null && this.props.location.state.is_update != null ? this.props.location.state.is_update : false,
            RoleID: this.props.location.state != null && this.props.location.state.RoleID != null ? this.props.location.state.RoleID : '',
        })
        this.getFillList(countryActionId, '', '', '', '')

        setTimeout(() => {
            this.state.is_update ? this.getRoleMasterDetails() : this.getRoleMasterDetailsDefault()
            console.log(this.state.moduleList)
        }, 200);
    }


    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props.fill_action(actionID, exhibitionID, moduleID, keyValue, value)
            .then((data) => {
                if (data.error != 1) {
                    // Country List
                    if (actionID === countryActionId) {
                        console.log("country data", data.data)
                        this.setState({
                            countryList: data.data,
                            TempCountryList: data.data,
                            isLoading: false
                        })
                    }
                }
                this.setState({ isLoading: false })
            }
            )
    }

    getRoleMasterDetails() {
        const { RoleID } = this.state
        this.setState({ isLoading: true })
        this.props.getRoleMasterDetails_action(RoleID)
            .then((data) => {
                if (data.error != 1) {
                    console.log(data.data)
                    this.setState({
                        Name: data.data.RoleName,
                        RoleID: data.data.RoleID,
                        moduleList: data.data.moduleList,
                        reportList: data.data.reportList,
                    })
                    // this.state.countryList.map(id => parseInt(id.CountryCode) == data.data[0].CountryID && this.setState({ Country: id.Description }))
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    getRoleMasterDetailsDefault() {
        const { RoleID } = this.state
        this.setState({ isLoading: true })
        this.props.getRoleMasterDetailsDefualt_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log(data.data)
                    this.setState({
                        moduleList: data.data.moduleList,
                        reportList: data.data.reportList,
                    })
                    // this.state.countryList.map(id => parseInt(id.CountryCode) == data.data[0].CountryID && this.setState({ Country: id.Description }))
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    addRoleMaster() {
        const { Name, moduleList, reportList } = this.state
        if (Name != '' && Name != null) {

            this.setState({ isLoading: true })
            this.props.addRoleMaster_action(Name, moduleList, reportList)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({ isLoading: false })
                        toastSuccess("data added successfully")
                        this.props.navigate('/roleMasterList', { state: { is_add: true, } })
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        else {
            toastError("Pleaser Enter Mandatory Fields")
        }
    }

    RoleMasterAU() {
        if (this.state.is_add == true) {
            this.addRoleMaster()
        }
        else {
            this.updateRoleMaster()
        }
    }

    handleBack() {
        this.props.navigate('/roleMasterList')
    }

    updateRoleMaster() {
        const { Name, RoleID, moduleList, reportList } = this.state
        if (Name != '' && Name != null && RoleID != '' && RoleID != null) {

            this.setState({ isLoading: true })
            this.props.updateRoleMaster_action(RoleID, Name, moduleList, reportList)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({ isLoading: false })
                        toastSuccess("data updated successfully")
                        this.props.navigate('/roleMasterList', { state: { is_add: true, } })
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        else {
            toastError("Pleaser Enter Mandatory Fields")
        }
    }

    onModuleSelect(i, access) {
        const { selectedModeuleList, moduleList } = this.state
        // console.log(moduleList)
        if (access == 'fullAccess') {
            moduleList[i].FullAccess = !moduleList[i].FullAccess
            if (moduleList[i].FullAccess == 1) {
                moduleList[i].ReadAccess = 1
                moduleList[i].WriteAccess = 1
                moduleList[i].UpdateAccess = 1
                moduleList[i].DeleteAccess = 1
                moduleList[i].Is_SpecialAccess = 1
                moduleList[i].DownloadAccess = 1
                moduleList[i].SendAccess = 1

            }
            else {
                moduleList[i].ReadAccess = 0
                moduleList[i].WriteAccess = 0
                moduleList[i].UpdateAccess = 0
                moduleList[i].DeleteAccess = 0
                moduleList[i].Is_SpecialAccess = 0
                moduleList[i].DownloadAccess = 0
                moduleList[i].SendAccess = 0
            }

        }
        if (access == 'view') {
            if (moduleList[i].ReadAccess == 1) {
                moduleList[i].ReadAccess = 0
            }
            else {
                moduleList[i].ReadAccess = 1
            }
        }
        if (access == 'add') {
            if (moduleList[i].WriteAccess == 1) {
                moduleList[i].WriteAccess = 0
            }
            else {
                moduleList[i].WriteAccess = 1
            }
        }
        if (access == 'update') {
            if (moduleList[i].UpdateAccess == 1) {
                moduleList[i].UpdateAccess = 0
            }
            else {
                moduleList[i].UpdateAccess = 1
            }
        }
        if (access == 'delete') {
            if (moduleList[i].DeleteAccess == 1) {
                moduleList[i].DeleteAccess = 0
            }
            else {
                moduleList[i].DeleteAccess = 1
            }
        }
        if (access == 'special') {
            if (moduleList[i].Is_SpecialAccess == 1) {
                moduleList[i].Is_SpecialAccess = 0
            }
            else {
                moduleList[i].Is_SpecialAccess = 1
            }
        }
        if (access == 'download') {
            if (moduleList[i].DownloadAccess == 1) {
                moduleList[i].DownloadAccess = 0
            }
            else {
                moduleList[i].DownloadAccess = 1
            }
        }
        if (access == 'send') {
            if (moduleList[i].SendAccess == 1) {
                moduleList[i].SendAccess = 0
            }
            else {
                moduleList[i].SendAccess = 1
            }
        }

        if (moduleList[i].ReadAccess == 1 &&
            moduleList[i].UpdateAccess == 1 &&
            moduleList[i].WriteAccess == 1 &&
            moduleList[i].DeleteAccess == 1 &&
            moduleList[i].Is_SpecialAccess == 1 &&
            moduleList[i].DownloadAccess == 1 &&
            moduleList[i].SendAccess == 1) {
            moduleList[i].FullAccess = 1
        }
        else {
            moduleList[i].FullAccess = 0
        }
        this.setState({ moduleList: moduleList })
        console.log(moduleList,)
    }

    moduleRecord(list) {
        return (
            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
                <thead>
                    <tr>
                        <th>Module Name</th>
                        <th style={{ width: '80px' }} className="text-center">Full Access</th>
                        <th style={{ width: '80px' }} className="text-center">View</th>
                        <th style={{ width: '80px' }} className="text-center">Add</th>
                        <th style={{ width: '80px' }} className="text-center">Update</th>
                        <th style={{ width: '80px' }} className="text-center">Delete</th>
                        <th style={{ width: '80px' }} className="text-center">Special</th>
                        <th style={{ width: '80px' }} className="text-center">Downlaod</th>
                        <th style={{ width: '80px' }} className="text-center">Send</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.length > 0 ? <>{
                            list.map((data, i) => (
                                <tr key={Math.random()}>
                                    <td>{data.ModuleName}</td>
                                    <td style={{ textAlign: 'center' }}>
                                        <input onClick={(e) => this.onModuleSelect(i, 'fullAccess')} checked={data.FullAccess} type="checkbox" />
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        <input onClick={(e) => this.onModuleSelect(i, 'view')} checked={data.ReadAccess} type="checkbox" />
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        <input onClick={(e) => this.onModuleSelect(i, 'add')} checked={data.WriteAccess} type="checkbox" />
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        <input onClick={(e) => this.onModuleSelect(i, 'update')} checked={data.UpdateAccess} type="checkbox" />
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        <input onClick={(e) => this.onModuleSelect(i, 'delete')} checked={data.DeleteAccess} type="checkbox" />
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        <input onClick={(e) => this.onModuleSelect(i, 'special')} checked={data.Is_SpecialAccess} type="checkbox" />
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        <input onClick={(e) => this.onModuleSelect(i, 'download')} checked={data.DownloadAccess} type="checkbox" />
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        <input onClick={(e) => this.onModuleSelect(i, 'send')} checked={data.SendAccess} type="checkbox" />
                                    </td>
                                </tr>
                            ))}
                        </> :
                            <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                    }
                </tbody>
            </table>)
    }

    onReportSelect(i, access) {
        const { selectedModeuleList, reportList } = this.state
        // console.log(moduleList)
        if (access == 'fullAccess') {
            reportList[i].FullAccess = !reportList[i].FullAccess
            if (reportList[i].FullAccess == 1) {
                reportList[i].ViewAccess = 1
                reportList[i].DownloadAccess = 1
            }
            else {
                reportList[i].ViewAccess = 0
                reportList[i].DownloadAccess = 0
            }

        }
        if (access == 'view') {
            if (reportList[i].ViewAccess == 1) {
                reportList[i].ViewAccess = 0
            }
            else {
                reportList[i].ViewAccess = 1
            }
        }
        if (access == 'download') {
            if (reportList[i].DownloadAccess == 1) {
                reportList[i].DownloadAccess = 0
            }
            else {
                reportList[i].DownloadAccess = 1
            }
        }

        if (reportList[i].ViewAccess == 1 &&
            reportList[i].DownloadAccess == 1) {
            reportList[i].FullAccess = 1
        }
        else {
            reportList[i].FullAccess = 0
        }
        this.setState({ reportList: reportList })
        console.log(reportList,)
    }

    reportRecord(list) {
        return (
            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
                <thead>
                    <tr>
                        <th>Report Name</th>
                        <th style={{ width: '80px' }} className="text-center">Full Access</th>
                        <th style={{ width: '80px' }} className="text-center">View</th>
                        <th style={{ width: '80px' }} className="text-center">Download</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.length > 0 ? <>{
                            list.map((data, i) => (
                                <tr key={Math.random()}>
                                    <td>{data.ReportName}</td>
                                    <td style={{ textAlign: 'center' }}>
                                        <input onClick={(e) => this.onReportSelect(i, 'fullAccess')} checked={data.FullAccess} type="checkbox" />
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        <input onClick={(e) => this.onReportSelect(i, 'view')} checked={data.ViewAccess} type="checkbox" />
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        <input onClick={(e) => this.onReportSelect(i, 'download')} checked={data.DownloadAccess} type="checkbox" />
                                    </td>
                                </tr>
                            ))}
                        </> :
                            <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                    }
                </tbody>
            </table>)
    }

    render() {
        const { isLoading, Name, moduleList, reportList } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                <div className={JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ? "with-dr-container" : "with-dr-container com-height"}>
                    <div className="main-container">
                        <AdminDrawer />
                        <div className="row">
                            <div className="step-name">
                                <p>Role Master {this.state.is_add == true ? 'Add' : 'Update'}</p>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row ">
                                <div class="row ">
                                    <div class="col-md-4">
                                        <label for="validationCustom03">Role Name<span style={{ color: 'red' }}>*</span></label>
                                        <input value={Name} onChange={(e) => this.setState({ Name: e.target.value })} type="text" class="form-control" placeholder="Name" />
                                    </div>
                                    {/* <div class="col-md-4">
                                        <label for="validationCustom03">Code<span style={{ color: 'red' }}>*</span></label>
                                        <input value={Code} onChange={(e) => this.setState({ Code: e.target.value })} type="text" class="form-control" placeholder="Code" />
                                    </div>
                                    <div className="col-xl-4">
                                        <label htmlFor="validationCustom03">
                                            Country<span className="text-red">*</span>
                                        </label>
                                        <input
                                            placeholder="Country"
                                            type="text"
                                            className="form-control"
                                            id="validationCustom03"
                                            value={Country}
                                            onChange={(e) => this.onCountrySearch(e)}
                                        />
                                        {isDisplayCountryList && this.renderCountryList()}

                                    </div> */}
                                </div>
                                <h5 className="m-4 ms-0" style={{ textAlign: 'left' }}>MODULE ACCESS</h5>
                                {this.moduleRecord(moduleList)}

                                <h5 className="m-4 ms-0" style={{ textAlign: 'left' }}>REPORT ACCESS</h5>
                                {this.reportRecord(reportList)}
                                <button onClick={this.RoleMasterAU} class="btn rounded-0 btn-primary mt-3" style={{ padding: "5px 7px", marginLeft: "10px", width: "100px" }}>Save</button>
                                <button onClick={this.handleBack} class="btn rounded-0 btn-danger mt-3" style={{ padding: "5px 7px", marginLeft: "10px", width: "100px" }}>Back</button>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        )
    }
}

export default withRouter(connect(null, { fill_action, addRoleMaster_action, getRoleMasterDetailsDefualt_action, updateRoleMaster_action, getRoleMasterDetails_action })(RoleMasterAU));