import { Component, forwardRef } from "react";
import Loader from "../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import Pagination from "../../../../utils/pagination";
import { withRouter } from "../../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action } from '../../../../actions/fillCombo_action';
import AdminDrawer from "../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../common_components/header_with_logo";
import "react-datepicker/dist/react-datepicker.css";
import ExhibitorHeader from "../../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../../common_components/exhibitor_module_drawer";
import ExhibitorTopMenu from "../../../../common_components/exhibitor_module_topmenu";
import { getVendorRegistrationList_action, deleteVendorRegistration_action } from "../../../../actions/admin_module_action/vendor_action/vendorRegistration_action/vendorRegistration_action";
import { exhibitionId, serviceTypeId, toastError, toastSuccess } from "../../../../utils/constants";

const styles = {
    width: '100%',
};

class VendorRegistrationList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            vendorRegistrationList: [],
            currentPage: 1,
            ExhibitionID: '',
            exhibitionList: [],
            CompanyName: '',
            PersonName: '',
            ServiceID: '',
            serviceTypeList: [],
            Status: 'Select'
        }
        this.updateItem = this.updateItem.bind(this)
    }

    componentDidMount() {
        this.getFillList(exhibitionId, '', '', '', '', '');
        this.getFillList(serviceTypeId, '', '', '', '', '');


        this.getVenddorRegistrationList()
    }

    // get fill List dropdown  
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, exhRegistrationID) {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value, exhRegistrationID)
            .then((data) => {
                if (data.error != 1) {
                    // voucher list
                    if (actionID === exhibitionId) {
                        // console.log("voucher type data", data.data)
                        this.setState({
                            exhibitionList: data.data, isLoading: false,
                        })
                    }
                    if (actionID === serviceTypeId) {
                        console.log("voucher type data", data.data)
                        this.setState({
                            serviceTypeList: data.data, isLoading: false,
                        })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    addVendor() {
        this.props.navigate('/VendorRegistrationAU', { state: { add: true } })
    }

    // Update Current Page
    updateItem(item) {
        const { vendorRegistrationList } = this.state
        this.setState({ currentPage: item });
        const recordsPerPage = 10;
        const indexOfLastRecord = item * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = vendorRegistrationList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(vendorRegistrationList.length / recordsPerPage)
    }

    getVenddorRegistrationList() {
        const { ExhibitionID, CompanyName, PersonName, ServiceID, Status } = this.state
        this.setState({ isLoading: true })
        this.props.getVendorRegistrationList_action(ExhibitionID, CompanyName, PersonName, ServiceID,
            Status != 'Select' && Status != 1 ? 0 : 1
        )
            .then((data) => {
                if (data.error != 1) {

                    this.setState({ isLoading: false, vendorRegistrationList: data.data })

                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })

    }

    clearValue() {
        this.setState({
            ExhibitionID: '',
            CompanyName: '',
            PersonName: '',
            ServiceID: '',
            Status: 'Select',
        })

        setTimeout(() => {
            this.getVenddorRegistrationList()
        }, 100);
    }


    deleteVendorRegistration(id) {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props.deleteVendorRegistration_action(id)
            .then((data) => {
                if (data.error != 1) {

                    this.setState({ isLoading: false, })
                    toastSuccess("Vendor deleted successfully!")
                    this.getVenddorRegistrationList()

                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })

    }

    updateVendor(userId, accountId) {
        this.props.navigate('/VendorRegistrationAU', { state: { AccountID: accountId, UserId: userId, update: true } })
    }

    render() {
        const { isLoading, ExhibitionID, CompanyName, PersonName, ServiceID, currentPage, vendorRegistrationList, exhibitionList, serviceTypeList, Status } = this.state

        const recordsPerPage = 10;
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = vendorRegistrationList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(vendorRegistrationList.length / recordsPerPage)

        console.log("currentRecords", currentRecords)
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                {
                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                        <ExhibitorHeader /> : <HeaderWithLogo />
                }
                <div className={JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ? "with-dr-container" : "with-dr-container com-height"}>
                    <div class="main-content">

                        {
                            JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                                <ExhibitorDrawer /> : <AdminDrawer />
                        }
                        {
                            JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                                <ExhibitorTopMenu /> : null
                        }
                        <div class="row">
                            <div class="step-name">
                                <p>Vendor Registration List</p>
                            </div>
                        </div>

                        <div class="row mt-1 pt-3 pb-3 p-2">
                            <div className="col-md-3">
                                <label>Exhibition</label>
                                <select
                                    className="form-control"
                                    id="roledb"
                                    // style={{ textAlign: "left" }}
                                    value={ExhibitionID}
                                    onChange={(e) => this.setState({ ExhibitionID: e.target.value })}
                                >
                                    <option>Select</option>
                                    {exhibitionList.length > 0 && exhibitionList.map((data) => (
                                        <option key={data.ExhibitionID
                                        } value={data.ExhibitionID
                                        }>{data.ExhibitionName}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-xl-3 mb-3">
                                <label>Company Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    defaultValue=""
                                    required=""
                                    value={CompanyName}
                                    onChange={(e) => this.setState({ CompanyName: e.target.value })}
                                />
                            </div>
                            <div className="col-xl-3 mb-3">
                                <label>Person Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    defaultValue=""
                                    required=""
                                    value={PersonName}
                                    onChange={(e) => this.setState({ PersonName: e.target.value })}
                                />
                            </div>
                            <div className="col-md-3">
                                <label>Status</label>
                                <select
                                    className="form-control"
                                    id="roledb"
                                    value={Status}
                                    onChange={e => this.setState({
                                        Status: e.target.value
                                    })}

                                >
                                    <option value='Select'>Select</option>
                                    <option value={1}>Active</option>
                                    <option value={0}>Inactive</option>
                                </select>
                            </div>
                            {/* <div className="col-md-3">
                                <label>Service Type</label>
                                <select
                                    className="form-control"
                                    id="roledb"
                                    // style={{ textAlign: "left" }}
                                    value={ServiceID}
                                    onChange={(e) => this.setState({ ServiceID: e.target.value })}
                                >
                                    <option>Select</option>
                                    {serviceTypeList.length > 0 && serviceTypeList.map((data) => (
                                        <option key={data.ServiceID
                                        } value={data.ServiceID
                                        }>{data.Name}</option>
                                    ))}
                                </select>
                            </div> */}
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div
                                    className="btn-group mb-1"
                                    role="group"
                                    aria-label="Basic example"
                                    style={{ float: "right" }}
                                >
                                    <button type="button" className="btn ad-fil-btn"
                                        onClick={e => this.getVenddorRegistrationList()}
                                    >
                                        Search
                                    </button>
                                    <button type="button" className="btn ad-fil-btn"
                                        onClick={e => this.clearValue()}
                                    >
                                        Clear
                                    </button>
                                    <button type="button" className="btn ad-fil-btn"
                                        onClick={() => this.addVendor()}
                                    >
                                        Add Vendor
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive mb-5 p-2">
                            <table class="table border text-nowrap text-md-nowrap table-bordered mb-0 ">
                                <thead>
                                    <tr>
                                        {/* text-center */}
                                        <th>Company Name</th>
                                        <th>Person Name</th>
                                        <th>Owner Name</th>
                                        <th>Email</th>
                                        <th>Mobile</th>
                                        <th>Country</th>
                                        <th>Service Type</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        currentRecords.length > 0 && currentRecords != null ?
                                            currentRecords.map((data, i) => (
                                                <tr>
                                                    <td>{data.CompanyName != null ? data.CompanyName : '-'}</td>
                                                    <td>{data.FirstName != null ? data.FirstName : '-'}</td>
                                                    <td>{data.Name_Of_CEO_MD_President_Owner != null ? data.Name_Of_CEO_MD_President_Owner : '-'}</td>
                                                    <td>{data.PrimaryEmail != null ? data.PrimaryEmail : '-'}</td>
                                                    <td>{data.MobileNo != null ? data.MobileNo : '-'}</td>
                                                    <td>{data.Country != null ? data.Country : '-'}</td>
                                                    <td>{data.abc != null ? data.abc : '-'}</td>
                                                    <td>{data.Status}</td>
                                                    <td className="text-center">
                                                        <i className="fa fa-pencil" aria-hidden="true"
                                                            style={{ marginRight: '12px', cursor: 'pointer' }}
                                                            onClick={() => this.updateVendor(data.Userid, data.AccountID)}
                                                        />
                                                        <i className="fa fa-trash" aria-hidden="true"
                                                            onClick={() => this.deleteVendorRegistration(data.Userid)}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                    </td>

                                                </tr>
                                            )) : (<tr><td colSpan={10} className='text-center'>Record not found</td></tr>)
                                    }

                                </tbody>
                            </table>
                            <div className="mt-2 float-left">
                                {
                                    vendorRegistrationList.length > 0 ?
                                        <Pagination
                                            nPages={nPages}
                                            currentPage={currentPage}
                                            setCurrentPage={this.updateItem}
                                        /> : <></>
                                }
                                <div className="tab-rec " style={{ float: "right" }}>
                                    <p>{`${currentPage} of ${nPages} pages`}</p>
                                </div>
                            </div>
                        </div>

                        <div class="footer-sec ">
                            <div class="sdsd">
                                <strong>Ownership:</strong> All content, formats and images used on this site are owned or licensed by India ITME Society. You may not copy, reproduce, distribute, publish, display, perform, modify, create derivative works, transmit, or in any other way exploit any part of copyrighted material without permission of the copyright owner. Failure to comply shall result in legal action.
                            </div>
                        </div>

                        <div class="footer-first">
                            <div class="container-fluid">
                                <div class="footer-first">  ITME All Rights Reserved <i class="fa fa-copyright" aria-hidden="true"></i> 2023  </div>
                            </div>
                        </div>
                    </div>
                </div>


            </>
        )
    }
}
export default withRouter(connect(null, { getVendorRegistrationList_action, fill_action, deleteVendorRegistration_action })(VendorRegistrationList));

