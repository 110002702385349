import { GET_FASCIA_APPLICATIONS_ACTION_TYPE, UPDATE_APPROVE_FASCIA_FROM_ACTION_TYPE } from "../../../../utils/constants";



const initialState = [];

function fascia_approvalReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_FASCIA_APPLICATIONS_ACTION_TYPE:
            return [...state, payload];
        case UPDATE_APPROVE_FASCIA_FROM_ACTION_TYPE:
            return [...state, payload];

        default:
            return state;
    }
};

export default fascia_approvalReducer;