import {
    GET_REPORT_LIST,GET_EXHIBITOR_DETAILS_REPORT
  } from "../../../utils/constants";
  
  const initialState = [];
  
  function reportReducer(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case GET_REPORT_LIST:
        return [...state, payload];
      case GET_EXHIBITOR_DETAILS_REPORT:
        return [...state, payload];
      default:
        return state;
    }
  }
  
  export default reportReducer;
  