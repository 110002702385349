import { Component } from "react";
import { ToastContainer } from "react-toastify";
import Loader from "../../../utils/Loader";
import ExhibitorHeader from "../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../common_components/exhibitor_module_drawer";
import ExhibitorTopMenu from "../../../common_components/exhibitor_module_topmenu";
import {
  getExhibitorDashboard_action,
  getDrawerDetails_action,
  insertExhibitorBarcode_action,
} from "../../../actions/exhibitor_module_actions/exhibitor_dashboard_action/exhibitor_dashboard_action";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import {
  downloadReport,
  fetchAuthReport,
  signatureDocName,
  toastError,
  toastSuccess,
} from "../../../utils/constants";
import { Link } from "react-router-dom";
import { axiosGet, axiosPost } from "../../../react_axios/Axios";
import KICC_Doc from "../../../files/KICC_DOCUMENTS.pdf";
import exit_pass from "../../../files/EXIT_PASS.docx";
import e_directory from "../../../files/E_DIRECTORY_2023.pdf";
import visitor_database from "../../../files/Visitor_Database.pdf";
import post_event from "../../../files/ITME-AFRICA-2023-Post-Event-Report.pdf";

class Exhibitor_dashboard extends Component {
  // Initialize Constructor
  constructor(props) {
    super(props);
    //States
    this.state = {
      isLoading: false,
      exhibitorDashboardList: [],
      isDrawerData: false,
      isNoDuesData: false,
      isDownloadExhibitorSignature: false,
      isLETTERFROMVENUEKICC: false,
      isDownloadExitPass: false,
      isDownloadEDirectory: false,
      isDownloadVisitorDatabase: false,
      isDownloadPostEventReport: false,
      isParticipationCertificate: false,
      isNoDuescertificate: false,
      isDownloadBonafideCertificate: false,
    };
    this.getExhibitorDashboard = this.getExhibitorDashboard.bind(this);
    this.getDrawerDetails = this.getDrawerDetails.bind(this);
    this.onCircularsClick = this.onCircularsClick.bind(this);
    this.onDownloadSignature = this.onDownloadSignature.bind(this);
    this.paymentDueNavigate = this.paymentDueNavigate.bind(this);
  }

  componentDidMount() {
    this.getExhibitorDashboard();
    this.getDrawerDetails();
  }

  // Get Exhibition Timeline
  getExhibitorDashboard() {
    this.setState({ isLoading: true });
    this.props
      .getExhibitorDashboard_action()
      .then((data) => {
        if (data.error != 1) {
          console.log("Get getExhibitorDashboard", data.data);
          sessionStorage.setItem(
            "sessionHeaderData",
            JSON.stringify(data.data[0])
          );
          this.setState({
            exhibitorDashboardList: data.data[0],
            isNoDuesData: true,
            isLoading: false,
          });
          for (var i = 0; i < data.data[1].length; i++) {
            if (
              data.data[1][i].DownloadName == "Download Exhibitor Signature"
            ) {
              this.setState({
                isDownloadExhibitorSignature: data.data[1][i].Status,
              });
            } else if (
              data.data[1][i].DownloadName == "LETTER FROM VENUE KICC"
            ) {
              this.setState({ isLETTERFROMVENUEKICC: data.data[1][i].Status });
            } else if (data.data[1][i].DownloadName == "Download Exit Pass") {
              this.setState({ isDownloadExitPass: data.data[1][i].Status });
            } else if (data.data[1][i].DownloadName == "Download E-Directory") {
              this.setState({ isDownloadEDirectory: data.data[1][i].Status });
            } else if (
              data.data[1][i].DownloadName == "Download Visitor Database"
            ) {
              this.setState({
                isDownloadVisitorDatabase: data.data[1][i].Status,
              });
            } else if (
              data.data[1][i].DownloadName == "Download Post Event Report"
            ) {
              this.setState({
                isDownloadPostEventReport: data.data[1][i].Status,
              });
            } else if (
              data.data[1][i].DownloadName == "Participation Certificate"
            ) {
              this.setState({
                isParticipationCertificate: data.data[1][i].Status,
              });
              sessionStorage.setItem(
                "isParticipationCertificateDashboard",
                data.data[1][i].Status
              );
            } else if (data.data[1][i].DownloadName == "No Dues certificate") {
              this.setState({ isNoDuescertificate: data.data[1][i].Status });
              sessionStorage.setItem(
                "isNoDuescertificateDashboard",
                data.data[1][i].Status
              );
            } else if (
              data.data[1][i].DownloadName == "Download Bonafide Certificate"
            ) {
              this.setState({
                isDownloadBonafideCertificate: data.data[1][i].Status,
              });
              sessionStorage.setItem(
                "isDownloadBonafideCertificate",
                data.data[1][i].Status
              );
            }
          }
        } else {
          this.setState({ isLoading: false });
          toastError(data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // Get Drawer Details
  getDrawerDetails() {
    this.setState({ isLoading: true });
    this.props
      .getDrawerDetails_action()
      .then((data) => {
        if (data.error != 1) {
          console.log("Get getDrawerDetails", data.data);
          sessionStorage.setItem(
            "sessionExhibitorDrawerData",
            JSON.stringify(data.data)
          );
          var address =
            data.data[0] != null &&
            data.data[0] != "" &&
            data.data[0]["Address"] != null &&
            data.data[0]["Address"] != "" &&
            data.data[0]["Address"];
          var addArray = address != "" && address != null && address.split(",");
          sessionStorage.setItem("FormatedAddress", JSON.stringify(addArray));
          this.setState({ isDrawerData: true, isLoading: false });
        } else {
          this.setState({ isLoading: false });
          toastError(data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // On Circular Click
  onCircularsClick() {
    this.props.navigate("/exhCircularList", {
      state: { userFlag: "Exhibitor" },
    });
  }

  // On Download Signature Click
  // onDownloadSignature = async () => {
  //     this.setState({ isLoading: true })
  //     var list = [];
  //     const mapdata1 = {
  //         "ParameterName": "ExhibitionID",
  //         "ParameterValue": sessionStorage.getItem('sessionExhibitionID'),
  //         "ParameterDataType": "int"
  //     }
  //     const mapdata2 = {
  //         "ParameterName": "ExhRegistrationID",
  //         "ParameterValue": sessionStorage.getItem('ExhRegistrationIDSS'),
  //         "ParameterDataType": "int"
  //     }
  //     list.push(mapdata1);
  //     list.push(mapdata2);

  //     const mapData = {
  //         "report_name": signatureDocName,
  //         "parameter_details": list
  //     }
  //     const res = await axiosPost(fetchAuthReport, mapData);
  //     this.setState({ isLoading: false })
  //     if (res != null) {
  //         var url = `${downloadReport}?SessionIdentifier=${res}&Export=Yes`;
  //         const win = window.location.replace(url, '_blank');
  //         if (win != null) {
  //             win.focus();
  //         }
  //     }
  // }

  onDownloadSignature = async () => {
    this.setState({ isLoading: true });
    try {
      // const data = await this.props.insertExhibitorBarcode_action();

      // if (data.error !== 1) {
      var list = [];
      const mapdata1 = {
        ParameterName: "@ExhibitionID",
        ParameterValue: parseInt(sessionStorage.getItem("sessionExhibitionID")),
        ParameterDataType: "int",
      };
      const mapdata2 = {
        ParameterName: "@ExhRegistrationID",
        ParameterValue: parseInt(sessionStorage.getItem("ExhRegistrationIDSS")),
        ParameterDataType: "int",
      };
      list.push(mapdata1);
      list.push(mapdata2);
      const mapData = {
        report_name: signatureDocName,
        parameter_details: list,
      };
      console.log("xxxxxxxxxxxxxxxxxxxxx", mapData);

      const res = await axiosPost(fetchAuthReport, mapData);
      this.setState({ isLoading: false });

      if (res != null) {
        var url = `${downloadReport}?SessionIdentifier=${res}&Export=Yes`;
        const win = window.location.replace(url, "_blank");
        if (win != null) {
          win.focus();
        }
      }
      // } else {
      //     this.setState({ isLoading: false });
      //     toastError(data.msg);
      // }
    } catch (err) {
      console.error(err);
    }
  };

  paymentDueNavigate() {
    this.props.navigate("/duePaymentsList", {
      // state: { userFlag: "Exhibitor" },
    });
  }

  render() {
    const { isLoading, exhibitorDashboardList, isDrawerData, isNoDuesData } =
      this.state;
    const sessionHeaderData = JSON.parse(
      sessionStorage.getItem("sessionHeaderData")
    );
    return (
      <>
        {isLoading ? <Loader></Loader> : null}
        <ToastContainer />
        <ExhibitorHeader />
        {isDrawerData && isNoDuesData ? <ExhibitorDrawer /> : null}
        {/*app-content open*/}
        <div className="page">
          <div className="page-main">
            <div className="main-content  with-dr-container">
              {/* <div className="container-fluid">
                                <div className="row">
                                    <div className="step-name">
                                <p>Exhibitor Dashboard</p>
                            </div>
                                    <ExhibitorTopMenu />
                                </div>
                            </div> */}
              <div className="">
                <div className=" width-dr-nav">
                  <div className="row">
                    <ExhibitorTopMenu />
                  </div>
                </div>
                <div className="p-2 pt-1">
                  <div className="row" style={{ marginTop: "15px" }}>
                    {/* {JSON.parse(sessionStorage.getItem("loginDataSS"))
                      .CountryID == "91" && (
                        <div className="col-md-3 mb-4">
                          <Link to={"/undertaking"}>
                            <div
                              className="com-box com-box-1"
                              style={{ borderBottom: "2px solid #ff9770" }}
                            >
                              <div
                                className="box-count"
                                style={{
                                  height: "142px",
                                  padding: "25px",
                                  textAlign: "center",
                                  fontSize: "17px",
                                  fontWeight: "500",
                                  fontFamily: '"Poppins", sans-serif',
                                }}
                              >
                                <spa>UNDERTAKING FOR NATIONAL EXHIBITOR</spa>
                                <span
                                  style={{
                                    background: "#fc9670",
                                    position: "absolute",
                                    top: "7px",
                                    left: "21px",
                                  }}
                                >
                                  <i
                                    className="fa fa-download"
                                    aria-hidden="true"
                                    style={{ fontSize: "17px" }}
                                  />
                                </span>
                              </div>
                            </div>
                          </Link>
                        </div>
                      )} */}

                    <div className="col-md-3 mb-4">
                      <div
                        className="com-box com-box-2"
                        style={{ borderBottom: "2px solid #ffba08" }}
                      >
                        <div className="box-count p-3">
                          <span style={{ background: "#febc0f" }}>
                            <i className="fa fa-file-o" aria-hidden="true" />
                          </span>
                        </div>
                        <div className="box-count-right ">
                          {exhibitorDashboardList.length > 0 &&
                            exhibitorDashboardList[0]["ExhRegistrationNo"]}
                        </div>
                        <hr className="m-0 mt-1" />
                        <p className="p-2 m-0">Application Ref#</p>
                      </div>
                    </div>
                    <div className="col-md-3 mb-4">
                      <div
                        className="com-box com-box-3"
                        style={{ borderBottom: "2px solid #390099" }}
                      >
                        <div className="box-count p-3">
                          <span style={{ background: "#390199" }}>#</span>
                        </div>
                        <div className="box-count-right ">
                          {exhibitorDashboardList.length > 0 &&
                            exhibitorDashboardList[0]["PriorityNo"]}
                        </div>
                        <hr className="m-0 mt-1" />
                        <p className="p-2 m-0">Priority No</p>
                      </div>
                    </div>
                    <div className="col-md-3 mb-4">
                      <div
                        className="com-box com-box-4"
                        style={{ borderBottom: "2px solid #fb5607" }}
                      >
                        <div className="box-count p-3">
                          <span style={{ background: "#fa5a0e" }}>
                            <i className="fa fa-flag-o" aria-hidden="true" />
                          </span>
                        </div>
                        <div className="box-count-right ">
                          {exhibitorDashboardList.length > 0 &&
                            exhibitorDashboardList[0]["StatusID"] == 4
                            ? "Applied"
                            : ""}
                        </div>
                        <hr className="m-0 mt-1" />
                        <p className="p-2 m-0">Booking Status</p>
                      </div>
                    </div>

                    <div className="col-md-3 mb-4">
                      <div
                        className="com-box com-box-5"
                        style={{ borderBottom: "2px solid #7678ed" }}
                      >
                        <div className="box-count p-3">
                          <span style={{ background: "#797bec" }}>
                            <i className="fa fa-spinner" aria-hidden="true" />
                          </span>
                        </div>
                        <div className="box-count-right ">
                          {exhibitorDashboardList.length > 0 &&
                            exhibitorDashboardList[0]["MainCategory"]}
                        </div>
                        <hr className="m-0 mt-1" />
                        <p className="p-2 m-0">Primary Chapter</p>
                      </div>
                    </div>
                    <div className="col-md-3 mb-4">
                      <div
                        className="com-box com-box-6"
                        style={{ borderBottom: "2px solid #7cb518" }}
                      >
                        <div className="box-count p-2">
                          <span style={{ background: "#7cb419" }}>
                            <i className="fa fa-th-large" aria-hidden="true" />
                          </span>
                        </div>
                        <div className="box-count-right ">
                          {exhibitorDashboardList.length > 0 &&
                            exhibitorDashboardList[0]["BothAllotted"]}
                        </div>
                        <hr className="m-0 mt-1" />
                        <p className="p-2 m-0">Stall Allotted</p>
                      </div>
                    </div>
                    <div className="col-md-3 mb-4">
                      <div
                        className="com-box com-box-7"
                        style={{ borderBottom: "2px solid #585123" }}
                      >
                        <div className="box-count p-3">
                          <span style={{ background: "#5c552a" }}>
                            <i className="fa fa-cubes" aria-hidden="true" />
                          </span>
                        </div>
                        <div className="box-count-right ">
                          {exhibitorDashboardList.length > 0 &&
                            exhibitorDashboardList[0]["HallNo"]}
                        </div>
                        <hr className="m-0 mt-1" />
                        <p className="p-2 m-0">Hall Name</p>
                      </div>
                    </div>
                    {/* <div className="col-md-3 mb-4">
                      <div
                        className="com-box com-box-7"
                        style={{ borderBottom: "2px solid #585123" }}
                      >
                        <div className="box-count p-3">
                          <span style={{ background: "#5c552a" }}>
                            <i className="fa fa-cubes" aria-hidden="true" />
                          </span>
                        </div>
                        <div className="box-count-right ">
                          {exhibitorDashboardList.length > 0 &&
                            exhibitorDashboardList[0]["StallName"]}
                        </div>
                        <hr className="m-0 mt-1" />
                        <p className="p-2 m-0">Stall No</p>
                      </div>
                    </div> */}
                    <div className="col-md-3 mb-4">
                      <div
                        className="com-box com-box-8"
                        style={{ borderBottom: "2px solid #33658a" }}
                      >
                        <div className="box-count p-2 pt-1">
                          <span
                            style={{
                              background: "#38698d",
                              padding: "1px 8px 4px",
                            }}
                          >
                            <i className="fa fa-th" aria-hidden="true" />
                          </span>
                        </div>
                        <div className="box-count-right ">
                          {exhibitorDashboardList.length > 0 && (
                            <>
                              Stall Area-
                              {exhibitorDashboardList[0]["StallArea"]}m
                              <sup>2</sup>
                            </>
                          )}
                          <br />
                          {exhibitorDashboardList.length > 0 &&
                            `Stall Type- ${exhibitorDashboardList[0]["StallTypeName"]}`}
                          <br />
                          {exhibitorDashboardList.length > 0 &&
                            `Open Sides- ${exhibitorDashboardList[0]["OpenSides"]}`}
                          <br />
                          {exhibitorDashboardList.length > 0 && (
                            <>
                              Double Storey Area-{" "}
                              {exhibitorDashboardList[0]["DoubleStoreyArea"] !=
                                null &&
                                exhibitorDashboardList[0]["DoubleStoreyArea"] !=
                                "" ? (
                                <>
                                  {
                                    exhibitorDashboardList[0][
                                    "DoubleStoreyArea"
                                    ]
                                  }
                                  m<sup>2</sup>
                                </>
                              ) : (
                                0
                              )}
                            </>
                          )}
                        </div>

                        <hr className="m-0 mt-1" />
                        <p className="p-2 m-0">
                          Booth Area (m<sup>2</sup>)
                        </p>
                      </div>
                    </div>

                    <div className="col-md-3 mb-4">
                      <div
                        className="com-box com-box-9"
                        style={{ borderBottom: "2px solid #219ebc" }}
                      >
                        <div className="box-count p-3">
                          <span style={{ background: "#219ebc" }}>
                            <i className="fa fa-list" aria-hidden="true" />
                          </span>
                        </div>
                        <div className="box-count-right "></div>
                        <hr className="m-0 mt-1" />
                        <p className="p-2 m-0">
                          {exhibitorDashboardList.length > 0 &&
                            exhibitorDashboardList[0]["EDirectory"]}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3 mb-4">
                      <div
                        className="com-box com-box-10"
                        style={{ borderBottom: "2px solid #4e598c" }}
                        onClick={(e) => this.onCircularsClick(e)}
                      >
                        <div className="box-count p-3">
                          <span style={{ background: "#4e598c" }}>
                            <i className="fa fa-id-card-o" aria-hidden="true" />
                          </span>
                        </div>
                        <div className="box-count-right ">
                          {exhibitorDashboardList.length > 0 &&
                            exhibitorDashboardList[0]["Messages"]}
                        </div>
                        <hr className="m-0 mt-1" />
                        <p className="p-2 m-0">Messages / Circulars</p>
                      </div>
                    </div>
                    {sessionStorage.getItem("isPaidByAgentRegistration") !=
                      null &&
                      sessionStorage.getItem("isPaidByAgentRegistration") == 0 ? (
                      <div className="col-md-3 mb-4" style={{ cursor: 'pointer' }} onClick={() => JSON.parse(sessionStorage.getItem("loginDataSS")).CountryID == 91 ? this.paymentDueNavigate() : null}>
                        <div
                          className="com-box com-box-11"
                          style={{ borderBottom: "2px solid #00a6a6" }}
                        >
                          <div className="box-count p-3">
                            <span style={{ background: "#00a6a6" }}>
                              <i className="fa fa-money" aria-hidden="true" />
                            </span>
                          </div>
                          <div className="box-count-right ">
                            {exhibitorDashboardList.length > 0 &&
                              exhibitorDashboardList[0]["PendingAmount"]}
                          </div>
                          <hr className="m-0 mt-1" />
                          <p className="p-2 m-0">Payment Due</p>
                        </div>
                      </div>
                    ) : null}

                    <div className="col-md-3 mb-4">
                      <div
                        className="com-box com-box-12"
                        style={{ borderBottom: "2px solid #f3de2c" }}
                      >
                        <div className="box-count p-3">
                          <span style={{ background: "#f3df31" }}>
                            <i className="fa fa-globe" aria-hidden="true" />
                          </span>
                        </div>
                        <div className="box-count-right ">
                          {exhibitorDashboardList.length > 0 &&
                            exhibitorDashboardList[0]["PendingExhManualForm"]}
                        </div>
                        <hr className="m-0 mt-1" />
                        <p className="p-2 m-0">
                          Pending Exhibitor Manual Forms
                        </p>
                      </div>
                    </div>
                    {console.log(
                      sessionHeaderData &&
                      sessionHeaderData[0] &&
                      sessionHeaderData[0]["Exhibitor Signature"] == "Yes"
                    )}
                    {sessionHeaderData &&
                      sessionHeaderData[0] &&
                      sessionHeaderData[0]["Exhibitor Signature"] == "Yes" ? (
                      <div className="col-md-3">
                        <div
                          className="com-box"
                          style={{ borderBottom: "2px solid #ff9770" }}
                        >
                          <div
                            className="box-count"
                            style={{
                              height: "108px",
                              paddingTop: "30px",
                              textAlign: "center",
                              fontSize: "19px",
                              fontWeight: "500",
                              fontFamily: '"Poppins", sans-serif',
                            }}
                          >
                            <button
                              type="button"
                              className="btn btn-outline-primary"
                              style={{
                                backgroundColor: "#6c5ffc",
                                color: "#fff",
                              }}
                              onClick={(e) => this.onDownloadSignature(e)}
                            >
                              Download Exhibitor Signature
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {(sessionHeaderData &&
                      sessionHeaderData[0] &&
                      sessionHeaderData[0]["Letter from venue KICC"] ==
                      "Yes") ||
                      this.state.isLETTERFROMVENUEKICC ? (
                      <div className="col-md-3">
                        <div
                          className="com-box"
                          style={{ borderBottom: "2px solid #ff9770" }}
                        >
                          <div
                            className="box-count"
                            style={{
                              height: "108px",
                              paddingTop: "30px",
                              textAlign: "center",
                              fontSize: "19px",
                              fontWeight: "500",
                              fontFamily: '"Poppins", sans-serif',
                            }}
                          >
                            <a href={KICC_Doc} download="KICC_Document">
                              <button
                                type="button"
                                className="btn btn-outline-primary"
                                style={{
                                  backgroundColor: "#6c5ffc",
                                  color: "#fff",
                                }}
                              >
                                LETTER FROM VENUE KICC
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {(sessionHeaderData &&
                      sessionHeaderData[0] &&
                      sessionHeaderData[0]["Exit Pass"] == "Yes") ||
                      this.state.isDownloadExitPass ? (
                      <div className="col-md-3">
                        <div
                          className="com-box"
                          style={{ borderBottom: "2px solid #ff9770" }}
                        >
                          <div
                            className="box-count"
                            style={{
                              height: "108px",
                              paddingTop: "30px",
                              textAlign: "center",
                              fontSize: "19px",
                              fontWeight: "500",
                              fontFamily: '"Poppins", sans-serif',
                            }}
                          >
                            <a href={exit_pass} download="EXIT_PASS">
                              <button
                                type="button"
                                className="btn btn-outline-primary"
                                style={{
                                  backgroundColor: "#6c5ffc",
                                  color: "#fff",
                                }}
                              >
                                Download Exit Pass
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {
                      // console.log('xxxxxxxxxxxxxxxxxxxxxxxx', sessionHeaderData[0]['E Directory']) &&
                      (sessionHeaderData &&
                        sessionHeaderData[0] &&
                        sessionHeaderData[0]["e_Directory"] == "Yes") ||
                        this.state.isDownloadEDirectory ? (
                        <div className="col-md-3">
                          <div
                            className="com-box"
                            style={{ borderBottom: "2px solid #ff9770" }}
                          >
                            <div
                              className="box-count"
                              style={{
                                height: "108px",
                                paddingTop: "30px",
                                textAlign: "center",
                                fontSize: "19px",
                                fontWeight: "500",
                                fontFamily: '"Poppins", sans-serif',
                              }}
                            >
                              <a href={e_directory} download="E_Directory">
                                <button
                                  type="button"
                                  className="btn btn-outline-primary"
                                  style={{
                                    backgroundColor: "#6c5ffc",
                                    color: "#fff",
                                  }}
                                >
                                  Download E-Directory
                                </button>
                              </a>
                            </div>
                          </div>
                        </div>
                      ) : null
                    }

                    {this.state.isDownloadVisitorDatabase ? (
                      <div className="col-md-3">
                        <div
                          className="com-box"
                          style={{ borderBottom: "2px solid #ff9770" }}
                        >
                          <div
                            className="box-count"
                            style={{
                              height: "108px",
                              paddingTop: "30px",
                              textAlign: "center",
                              fontSize: "19px",
                              fontWeight: "500",
                              fontFamily: '"Poppins", sans-serif',
                            }}
                          >
                            <a
                              href={visitor_database}
                              download="Visitor_Database"
                            >
                              <button
                                type="button"
                                className="btn btn-outline-primary"
                                style={{
                                  backgroundColor: "#6c5ffc",
                                  color: "#fff",
                                }}
                              >
                                Download Visitor Database
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.state.isDownloadPostEventReport ? (
                      <div className="col-md-3">
                        <div
                          className="com-box"
                          style={{ borderBottom: "2px solid #ff9770" }}
                        >
                          <div
                            className="box-count"
                            style={{
                              height: "108px",
                              paddingTop: "30px",
                              textAlign: "center",
                              fontSize: "19px",
                              fontWeight: "500",
                              fontFamily: '"Poppins", sans-serif',
                            }}
                          >
                            <a
                              href={post_event}
                              download="ITME-AFRICA-2023-Post-Event-Report"
                            >
                              <button
                                type="button"
                                className="btn btn-outline-primary"
                                style={{
                                  backgroundColor: "#6c5ffc",
                                  color: "#fff",
                                }}
                              >
                                Download Post Event Report
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="row mt-3 mb-4">
                  {/* <div className="col-md-3">
                                        <div
                                            className="com-box"
                                            style={{ borderBottom: "2px solid #f52f57" }}
                                        >
                                            <div className="box-count p-3">
                                                <span style={{ background: "#f52f57", padding: "2px 8px 4px" }}>
                                                    <i className="fa fa-globe" aria-hidden="true" />
                                                </span>
                                            </div>
                                            <div className="box-count-right ">17</div>
                                            <hr className="m-0 mt-1" />
                                            <p className="p-2 m-0">IEML Water Quality</p>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div
                                            className="com-box"
                                            style={{ borderBottom: "2px solid #ff9770" }}
                                        >
                                            <div
                                                className="box-count"
                                                style={{
                                                    height: '98px',
                                                    paddingTop: '30px',
                                                    textAlign: "center",
                                                    fontSize: '19px',
                                                    fontWeight: '500',
                                                    fontFamily: '"Poppins", sans-serif'
                                                }}
                                            >
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-primary"
                                                    style={{ backgroundColor: "#6c5ffc", color: "#fff" }}
                                                >
                                                    Download Invitation
                                                </button>
                                            </div>
                                        </div>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(
  connect(null, {
    getExhibitorDashboard_action,
    getDrawerDetails_action,
    insertExhibitorBarcode_action,
  })(Exhibitor_dashboard)
);
