import { axiosGet, axiosPost } from "../../../../react_axios/Axios";
import { headers, getRentalAudioVideoMasterListUrl, insertUpdateRentalAudioVideoMasterUrl, deleteRentalAudioVideoMasterUrl, GET_RENTAL_AUDIO_VIDEO_MASTER_LIST, INSERT_UPDATE_RENTAL_AUDIO_VIDEO_MASTER, DELETE_RENTAL_AUDIO_VIDEO_MASTER } from "../../../../utils/constants";


export const insertUpdateRentalAudioVideoMaster_action = (RentalAudioVideoMasterList) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            RentalAudioVideoMasterList: RentalAudioVideoMasterList
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosPost(insertUpdateRentalAudioVideoMasterUrl, params, headers)

        dispatch({
            type: INSERT_UPDATE_RENTAL_AUDIO_VIDEO_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getRentalAudioVideoMasterList_action = () => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getRentalAudioVideoMasterListUrl, params, headers)

        dispatch({
            type: GET_RENTAL_AUDIO_VIDEO_MASTER_LIST,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const deleteRentalAudioVideoMaster_action = (deleteId) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            AgencyRentalAudioVideoID: deleteId
        }
        console.log("deleteExhibitionDocument_action Action------->", params)

        const res = await axiosGet(deleteRentalAudioVideoMasterUrl, params, headers)

        dispatch({
            type: DELETE_RENTAL_AUDIO_VIDEO_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}