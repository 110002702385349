import { INSERT_UPDATE_EXH_FREIGHT_FORWARD, GET_EXH_EXH_FREIGHT_DETAILS } from "../../../utils/constants";

const initialState = [];

function exhFreightForwardiReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case INSERT_UPDATE_EXH_FREIGHT_FORWARD:
            return [...state, payload];
        case GET_EXH_EXH_FREIGHT_DETAILS:
            return [...state, payload];

        default:
            return state;
    }
};

export default exhFreightForwardiReducer