import { Component } from "react";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";

class PromoCode extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false
        }
    }


    render() {
        const { isLoading } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                <AdminDrawer />
                <div className="main-container p-2 mt-2">
                    <div className="row ">
                        <div className="tab-pane " id="tab6" role="tabpanel">
                            <div className="row mt-2">
                                <div className="col-xl-4">
                                    <label> Applied to</label>
                                    <select className="form-control pl-0">
                                        <option>Visitor Registration</option>
                                        <option />
                                        <option />
                                        <option />
                                    </select>
                                </div>
                            </div>
                            <div className="row mt-2 m-0">
                                <div className="col-sm-1 m-0 p-0">
                                    <label>
                                        <input type="checkbox" className="" />
                                        Unlimited
                                    </label>
                                </div>
                                <div className="col-sm-2">
                                    <label>
                                        <input type="checkbox" className="" />
                                        Slab
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 ">
                                    <div className="table-responsive mt-1">
                                        <table
                                            className="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-2"
                                            id="myTable"
                                        >
                                            <thead>
                                                <tr>
                                                    <th>Occurrence</th>
                                                    <th className="text-center">Discount %</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex">
                                                            <input
                                                                type="text"
                                                                className="form-control equ-field text-center"
                                                                defaultValue={1}
                                                                disabled=""
                                                            />
                                                            <span className="m-2">-</span>
                                                            <input
                                                                type="text"
                                                                className="form-control equ-field text-center"
                                                                defaultValue={50}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control text-center"
                                                            defaultValue={20}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex">
                                                            <input
                                                                type="text"
                                                                className="form-control equ-field text-center"
                                                                defaultValue={51}
                                                                disabled=""
                                                            />
                                                            <span className="m-2">-</span>
                                                            <input
                                                                type="text"
                                                                className="form-control equ-field text-center"
                                                                defaultValue={100}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control text-center"
                                                            defaultValue={10}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary rounded-1 mt-2"
                                        style={{ padding: "0px 10px", height: 33 }}
                                    >
                                        Add New
                                    </button>
                                    <br />
                                    <br />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-4">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <label>Name</label>
                                        </div>
                                        <div className="col-sm-9">
                                            <input type="text" className="form-control" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-xl-3">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <label>Validity</label>
                                        </div>
                                        <div className="col-sm-8">
                                            <input type="date" className="form-control" placeholder="From" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-2">
                                    <div className="row">
                                        <div className="col-sm-1">
                                            <label />
                                        </div>
                                        <div className="col-sm-10 p-0">
                                            <input type="date" className="form-control" placeholder="To" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-7 mt-2 p-0 d-flex ">
                                <div className="col-md-5 p-0">
                                    <div className="row mt-2 m-0">
                                        <div className="col-sm-4 m-0 p-0">
                                            <label>
                                                <input type="radio" className="" />
                                                Individual
                                            </label>
                                        </div>
                                        <div className="col-sm-4">
                                            <label>
                                                <input type="radio" className="" />
                                                Group
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-7 mt-4 p-0 d-flex mt-2">
                                <hr />
                                <div className="col-md-6 p-0">
                                    <button
                                        type="button"
                                        className="btn btn-outline-info rounded-1 mt-2"
                                        style={{ padding: "0px 10px", height: 33, marginTop: "0px !important" }}
                                    >
                                        Apply
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-outline-success rounded-1 mt-2"
                                        style={{ padding: "0px 10px", height: 33, marginTop: "0px !important" }}
                                    >
                                        Start
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-outline-light rounded-1 mt-2"
                                        style={{
                                            padding: "0px 10px",
                                            height: 33,
                                            marginTop: "0px !important",
                                            borderColor: "#7c7c7c",
                                            color: "#7c7c7c!important"
                                        }}
                                    >
                                        Stop
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        )
    }
}
export default withRouter(connect(null, {})(PromoCode))