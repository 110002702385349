import { Component } from "react";
import Loader from "../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action } from '../../../../actions/fillCombo_action';
import { cityActionId, countryActionId, toastError, toastSuccess, industrySegmentListActionId, visitPurposeActionId, industryChaptersActionId } from "../../../../utils/constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import AdminDrawer from "../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../common_components/header_with_logo";
import { useNavigate } from "react-router-dom";
import ExhibitorDrawer from "../../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../../common_components/exhibitor_module_header";
import { data } from "jquery";
import { insertUpdatePurposeofVisitMaster_action } from "../../../../actions/admin_module_action/master_action/purpose_of_visit_master_action";


class PurposeOfVisitMaster extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            POVList: [],
            isOpen: false,
            list: [],
            toAdd: false,
            ExhibitionID: '',
        }
        // this.onDeleteRow = this.onDeleteRow.bind(this)
        this.onDelete = this.onDelete.bind(this)
        this.onAddRow = this.onAddRow.bind(this)
        this.getFillList = this.getFillList.bind(this)
        // this.getManualFormsMasterList = this.getManualFormsMasterList.bind(this)
        // this.addManualFormsMasterList = this.addManualFormsMasterList.bind(this)
        this.insertUpdatePOVMasterList = this.insertUpdatePOVMasterList.bind(this)
        this.onNameChange = this.onNameChange.bind(this)
    }

    componentDidMount() {
        this.getFillList(visitPurposeActionId, '', '', '', '', '');
    }

    onAddRow(e) {
        e.preventDefault();
        // console.log(this.state.listLength)
        const { POVList } = this.state
        // var id = list.length + 1
        var map = {
            Name: "",
            PurposeofVisitID: null,
            isAdd: true,
            isUpdate: true,
        }
        POVList.push(map)
        this.setState({ POVList: POVList, })
        // console.log(this.state.StallSizeList)
    }

    // Get Fill Lists
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value)
            .then((data) => {
                if (data.error != 1) {
                    // country list
                    if (actionID === visitPurposeActionId) {
                        console.log("country data", data.data)
                        data.data.map(item => {
                            // item.isAdd
                            item.isAdd = false
                            item.isUpdate = false
                        })
                        this.setState({ POVList: data.data, isLoading: false })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    insertUpdatePOVMasterList() {
        this.setState({ isLoading: true })
        const { POVList, ExhibitionID } = this.state
        console.log(POVList)

        var finalList = []
        for (var i = 0; i < POVList.length; i++) {
            if (POVList[i].isUpdate == true || POVList[i].isAdd == true) {
                finalList.push(POVList[i])
            }
        }
        var inputValidate = false
        for (var i = 0; i < finalList.length; i++) {
            if (finalList[i].Name == '' || finalList[i].Name == null) {
                inputValidate = true
                break
            }
        }
        if (inputValidate == true) {
            toastError("Please Fill all the Mandatory fields")
        }
        else {
            console.log(finalList)
            this.props.insertUpdatePurposeofVisitMaster_action(finalList)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({ isLoading: false })
                        // this.getManualFormsMasterList()
                        this.getFillList(visitPurposeActionId, '', '', '', '', '');
                        toastSuccess("update successfully")
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    onDelete(i) {
        const { POVList, list } = this.state
        // var ManualFormMappingID = list[i].ManualFormMappingID
        if (POVList[i].isAdd == true) {
            POVList.splice(i, 1)
            this.setState({ POVList: POVList })
        }
        else {
            // this.setState({ isLoading: true })
            this.setState({ ManualFormMappingID: list[i].ManualFormMappingID })
            this.setState({ isOpen: true })

        }
    }

    // onDeleteRow() {
    //     const { ManualFormMappingID, ExhibitionID } = this.state
    //     this.props.deleteManualformsMaster_action(ExhibitionID, ManualFormMappingID)
    //         .then((data) => {
    //             if (data.error != 1) {
    //                 this.setState({ isOpen: false })
    //                 this.setState({ isLoading: false, list: [] })

    //                 this.setState({ isLoading: false })
    //                 toastSuccess("delete successfully")
    //                 this.getManualFormsMasterList()
    //             }
    //             else {
    //                 this.setState({ isLoading: false })
    //                 toastError(data.msg)
    //             }
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         })
    // }

    onNameChange(e, ID) {
        // e.preventDefault()
        const { POVList } = this.state
        console.log(e.target.value)
        POVList.map((data, i) => {
            if (ID == i) {
                data.Name = e.target.value
                data.isUpdate = true
            }
        })
        // setTimeout(() => {
        console.log(POVList)
        this.setState({ POVList: POVList })
        // }, 200);
    }

    render() {
        const { isLoading, isOpen, list, POVList } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <div className="app-content with-dr-container com-height">
                        <AdminDrawer />
                        <div className="row">
                            <div className="step-name">
                                <p>Purpose of Visit List</p>
                            </div>
                        </div>
                        <div className="main-container p-2">
                            <div class="table-responsive">
                                <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-3">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '60px' }}>Sr No.</th>
                                            <th >Purpose of Visit<span style={{ color: 'red' }}>*</span></th>
                                            <th style={{ width: '60px' }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            POVList.length > 0 ? <>{
                                                POVList.map((data, i) => (
                                                    <tr key={i}>
                                                        <td style={{ textAlign: 'center' }}>
                                                            {i + 1}
                                                        </td>
                                                        <td>
                                                            <input type="text" className="form-control" name="" value={data.PurposeofVisitName}
                                                                maxLength={'50'}
                                                                onChange={(e) => this.onNameChange(e, i)}
                                                            />
                                                        </td>
                                                        <td style={{ textAlign: 'center' }}>
                                                            <i className="fa fa-trash-o"
                                                                onClick={() => this.onDelete(i)}
                                                            />
                                                        </td>

                                                    </tr>
                                                ))}
                                            </> :
                                                <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                                        }
                                    </tbody>
                                </table>
                                <div style={{ float: 'right' }} >

                                    <button type="button" class="btn btn-primary rounded-0 mt-3 " onClick={(e) => this.onAddRow(e)}>
                                        Add Row
                                    </button>
                                    <button disabled={list.length == 0} type="button" class="btn btn-success rounded-0 mt-3 ms-2"
                                        onClick={(e) => this.insertUpdatePOVMasterList(e)}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>

                <Modal isOpen={isOpen} onRequestClose={() => this.setState({ isOpen: false })}>
                    <div className="modal-dialog">
                        <div className="modal-content confirm-logout">
                            {/* <div class="modal-header text-right p-2">
                                <button type="button " class="close pop-close" data-dismiss="modal"
                                    onClick={() => this.setState({ isOpen: false })}
                                ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
                            </div> */}
                            <div class="modal-body">
                                <div class="row">
                                    <h5 class="modal-title modal_title_text text-danger">Do you really want to delete this record?</h5>
                                </div>
                            </div>
                            <div class="modal-footer p-1">
                                <div class="form-group text-right mod-fot">
                                    <button type="button" class="btn btn-del-pop"
                                        onClick={(e) => this.onDeleteRow()}
                                    >Yes</button>
                                    <button type="button" class="btn btn-can-pop" data-dismiss="modal" style={{ marginLeft: '5px' }}
                                        onClick={() => this.setState({ isOpen: false })}
                                    >No</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
            </>
        )
    }
}

export default withRouter(connect(null, { fill_action, insertUpdatePurposeofVisitMaster_action })(PurposeOfVisitMaster));