import { axiosGet, axiosPost } from "../../../../../react_axios/Axios";
import { DEL_EXHIBITOR_BADGE_ACTION_TYPE, DEL_EXHIBITOR_BADGE_DOC_ACTION_TYPE, SUBMIT_EXHIBITOR_BADGES_ACTION_TYPE, delExBadgeDocUrl, delExhibitorBadgeUrl, submitExhBadgesUrl, updateExhibitionBadgeUrl } from "../../../../../utils/constants";
import { ADD_EXHIBITOR_BADGE_ACTION_TYPE, GET_EXHIBITOR_BADGE_ACTION_TYPE, GET_EXHIBITOR_BADGE_DETAILS_ACTION_TYPE, UPDATE_EXHIBITOR_BADGE_ACTION_TYPE, addExhibitorBadgeUrl, getExhibitorBadgeDetailsUrl, getExhibitorBadgeUrl, headers } from "../../../../../utils/constants";




export const getExhibitorBadge_action = () => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhRegistrationID: sessionStorage.getItem('ExhRegistrationIDSS')
        }
        console.log("getExhibitorBadge_action Action------->", params)
        const res = await axiosGet(getExhibitorBadgeUrl, params, headers)

        dispatch({
            type: GET_EXHIBITOR_BADGE_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const addExhibitorBadge_action = (exhibitorName, designation, countryId, companyName, remarks, vaccineCertificateFile,
    list, vaccineCertificateFileType, badgeLength, mobileNo, email, IsPaid, City, State, ExhBadgesProductCategoryList) => async (dispatch) => {
        try {
            const mapData = {
                UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
                ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
                ExhRegistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
                Name: exhibitorName,
                Designation: designation,
                countryid: countryId,
                CompanyName: companyName,
                Email: email,
                MobileNo: mobileNo,
                Remark: remarks,
                PhotoFilePath: null,
                PhotoIDFilePath: null,
                VaccinationCertifcateFilePath: vaccineCertificateFile,
                visitDaysList: list,
                ExhRegistrationNo: sessionStorage.getItem('ExhRegistrationNo'),
                vaccineCertificateFileType: vaccineCertificateFileType,
                DocNo: badgeLength,
                IsPaid: IsPaid,
                City: City,
                State: State,
                ExhBadgesProductCategoryList: ExhBadgesProductCategoryList,
            }
            console.log("addExhibitorBadge_action------->", mapData)
            const res = await axiosPost(addExhibitorBadgeUrl, mapData, headers)

            dispatch({
                type: ADD_EXHIBITOR_BADGE_ACTION_TYPE,
                payload: res
            })
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }

export const getExhibitorBadgeDetails_action = (exhibitorBadgeFormId) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitorBadgesFormID: exhibitorBadgeFormId,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhregistrationID: sessionStorage.getItem('ExhRegistrationIDSS')
        }
        console.log("getExhibitorBadgeDetails_action Action------->", params)
        const res = await axiosGet(getExhibitorBadgeDetailsUrl, params, headers)

        dispatch({
            type: GET_EXHIBITOR_BADGE_DETAILS_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const updateExhibitorBadge_action = (exBadgeFormId, exhibitorName, designation, countryId, companyName, remarks,
    vaccineCertificateFile, list, vaccineCertificateFileType, mobileNo, email, IsPaid, City, State, ExhBadgesProductCategoryList) => async (dispatch) => {
        try {
            const mapData = {
                UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
                ExhibitorBadgesFormID: exBadgeFormId,
                ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
                ExhRegistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
                Name: exhibitorName,
                Designation: designation,
                countryid: countryId,
                CompanyName: companyName,
                Email: email,
                MobileNo: mobileNo,
                Remark: remarks,
                PhotoFilePath: null,
                PhotoIDFilePath: null,
                VaccinationCertifcateFilePath: vaccineCertificateFile,
                visitDaysList: list,
                ExhRegistrationNo: sessionStorage.getItem('ExhRegistrationNo'),
                vaccineCertificateFileType: vaccineCertificateFileType,
                IsPaid: IsPaid,
                City: City,
                State: State,
                ExhBadgesProductCategoryList: ExhBadgesProductCategoryList,
            }
            console.log("addExhibitorBadge_action------->", mapData)
            const res = await axiosPost(updateExhibitionBadgeUrl, mapData, headers)

            dispatch({
                type: UPDATE_EXHIBITOR_BADGE_ACTION_TYPE,
                payload: res
            })
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }

export const deleteExhibitorBadge_action = (exhibitorBadgeFormId) => async (dispatch) => {

    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitorBadgesFormID: exhibitorBadgeFormId
        }
        console.log("Delete deleteExhibitorBadge_ Entry Action---------------->", params)
        const res = await axiosGet(delExhibitorBadgeUrl, params, headers)
        dispatch({
            type: DEL_EXHIBITOR_BADGE_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const deleteExBadgeDoc_action = (exhibitorBadgeFormId) => async (dispatch) => {

    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhRegistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
            ExhibitorBadgesFormID: exhibitorBadgeFormId
        }
        console.log("Delete deleteExhibitorBadge_ Document Action---------------->", params)
        const res = await axiosGet(delExBadgeDocUrl, params, headers)
        dispatch({
            type: DEL_EXHIBITOR_BADGE_DOC_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const submitExhBadges_action = () => async (dispatch) => {
    try {
        const mapData = {
            userID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhregistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),

        }
        console.log("submitExhBadges_action------->", mapData)
        const res = await axiosPost(submitExhBadgesUrl, mapData, headers)

        dispatch({
            type: SUBMIT_EXHIBITOR_BADGES_ACTION_TYPE,
            payload: res
        })
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}
