import { GET_EXH_BADGES_APPLICATION_ACTION_TYPE, GET_EXH_BADGES_SUMMARY_ACTION_TYPE, UPDATE_EXH_BADGES_APPROVAL_ACTION_TYPE } from "../../../../utils/constants";



const initialState = [];

function exhBadges_approvalReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_EXH_BADGES_SUMMARY_ACTION_TYPE:
            return [...state, payload];
        case GET_EXH_BADGES_APPLICATION_ACTION_TYPE:
            return [...state, payload];
        case UPDATE_EXH_BADGES_APPROVAL_ACTION_TYPE:
            return [...state, payload];
        default:
            return state;
    }
};

export default exhBadges_approvalReducer;