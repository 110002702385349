import { axiosGet, axiosPost } from "../../../react_axios/Axios";
import { getAttendeeTypeMasterListUrl, deleteAttendeeTypeMasterUrl, insertAttendeeTypeMasterUrl, updateAttendeeTypeMasterUrl, GET_ATTENDEE_TYPE_MASTER_LIST, DELETE_ATTENDEE_TYPE_MASTER, INSERT_ATTENDEE_TYPE_MASTER, UPDATE_ATTENDEE_TYPE_MASTER, headers } from "../../../utils/constants";

export const getAttendeeTypeMasterList_action = (Name, Status) => async (dispatch) => {
    try {
        const params = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            Name: Name,
            Status: Status != '' && Status != '' ? Status : null,
        }
        console.log("getVisaInvitation_action Action------->", params)
        const res = await axiosGet(getAttendeeTypeMasterListUrl, params, headers)

        dispatch({
            type: GET_ATTENDEE_TYPE_MASTER_LIST,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const deleteAttendeeTypeMaster_action = (AttendeeTypeID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            AttendeeTypeID: AttendeeTypeID,
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(deleteAttendeeTypeMasterUrl, params, headers)

        dispatch({
            type: DELETE_ATTENDEE_TYPE_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const insertAttendeeTypeMaster_action = (Name) => async (dispatch) => {
    try {
        const params = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            // countryID: countryID,
            // stateID: stateID,
            Name: Name,
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosPost(insertAttendeeTypeMasterUrl, params, headers)

        dispatch({
            type: INSERT_ATTENDEE_TYPE_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const updateAttendeeTypeMaster_action = (AttendeeTypeID, Name) => async (dispatch) => {
    try {
        const params = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            // countryID: countryID,
            // stateID: stateID,
            AttendeeTypeID: AttendeeTypeID,
            Name: Name,
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosPost(updateAttendeeTypeMasterUrl, params, headers)

        dispatch({
            type: UPDATE_ATTENDEE_TYPE_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

// export const getHallMasterDetails_action = (HallId) => async (dispatch) => {
//     try {
//         const params = {
//             UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
//             ExhibitionID: JSON.parse(sessionStorage.getItem("sessionExhibitionID")),
//             HallId: HallId
//         }
//         console.log("getVisaInvitation_action Action------->", params)

//         const res = await axiosGet(getHallMasterDetailsUrl, params, headers)

//         dispatch({
//             type: GET_HALL_MASTER_DETAILS,
//             payload: res,
//         });
//         return Promise.resolve(res)
//     } catch (error) {
//         return Promise.reject(error)
//     }
// }