import {
    INSERT_AND_UPDATE_EXHIBITOR_CONTRACTOR_BADGE_APPLICATION_ACTION_TYPE, GET_EXHIBITOR_CONTRACTOR_BADGE_APPLICATION_DETAILS_ACTION_TYPE
} from "../../../../../utils/constants";


const initialState = [];

function exhibitorContractorBadgeApllicationReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case INSERT_AND_UPDATE_EXHIBITOR_CONTRACTOR_BADGE_APPLICATION_ACTION_TYPE:
            return [...state, payload];
        case GET_EXHIBITOR_CONTRACTOR_BADGE_APPLICATION_DETAILS_ACTION_TYPE:
            return [...state, payload];
        default:
            return state;
    }
};

export default exhibitorContractorBadgeApllicationReducer;