import { axiosGet, axiosPost } from "../../../../react_axios/Axios";
import {
    GET_MEETING_ROOM_APPROVAL_DETAILS_ACTION_TYPE, GET_MEETING_ROOM_APPROVAL_LIST_ACTION_TYPE, GET_MEETING_ROOM_APPROVAL_EXPORT_DATA_ACTION_TYPE, UPDATE_MEETING_ROOM_APPROVAL__ACTION_TYPE,
    getMeetingRoomApprovalDetailsUrl, getMeetingRoomApprovalListUrl, getMeetingRoomExportDataUrl, updateMeetingRoomApprovalUrl, headers
} from "../../../../utils/constants";

export const getMeetingRoomApprovalDetails_action = (companyName, status, exhibitorTypeId) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            CompanyName: companyName,
            Status: status,
            ExhibitorTypeID: exhibitorTypeId,
        }
        console.log("getVisitorVisaInvitation_action Action------->", params)

        const res = await axiosGet(getMeetingRoomApprovalDetailsUrl, params, headers)

        dispatch({
            type: GET_MEETING_ROOM_APPROVAL_DETAILS_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getMeetingRoomApprovalList_action = (companyname) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            CompanyName: companyname,

        }
        console.log("getVisitorVisaApplication_action Action------->", params)

        const res = await axiosGet(getMeetingRoomApprovalListUrl, params, headers)

        dispatch({
            type: GET_MEETING_ROOM_APPROVAL_LIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getMeetingRoomExportData_action = () => async (dispatch) => {
    try {
        const params = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
        }
        console.log("getVisitorVisaisaApplicationExportData_action Action------->", params)

        const res = await axiosGet(getMeetingRoomExportDataUrl, params, headers)

        dispatch({
            type: GET_MEETING_ROOM_APPROVAL_EXPORT_DATA_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const updateMeetingRoomApproval_action = (isApproved, list) => async (dispatch) => {
    try {
        const mapData = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            Is_Approved: isApproved,
            approvalList: list
        }
        console.log("updateVisitorVisaApproval_action Action------->", mapData)

        const res = await axiosPost(updateMeetingRoomApprovalUrl, mapData, headers)

        dispatch({
            type: UPDATE_MEETING_ROOM_APPROVAL__ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

