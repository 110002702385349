import { DEL_CIRCULAR_ACTION_TYPE, GET_CIRCULAR_COMPANY_ACTION_TYPE, GET_CIRCULAR_LIST_ACTION_TYPE, INSERT_CIRCULAR_ACTION_TYPE } from "../../../utils/constants";


const initialState = [];

function circularReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_CIRCULAR_COMPANY_ACTION_TYPE:
            return [...state, payload];
        case GET_CIRCULAR_LIST_ACTION_TYPE:
            return [...state, payload];
        case INSERT_CIRCULAR_ACTION_TYPE:
            return [...state, payload];
        case DEL_CIRCULAR_ACTION_TYPE:
            return [...state, payload];

        default:
            return state;
    }
};

export default circularReducer;