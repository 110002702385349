import {
    VISA_APPLICATION_LIST_ACTION_TYPE, ADD_VISA_APPLICATION_ACTION_TYPE,
    GET_VISA_APPLICATION_DETAILS_ACTION_TYPE, UPDATE_VISA_APPLICATION_ACTION_TYPE,
    DELETE_VISA_APPLICATION_ACTION_TYPE,
    SUBMIT_VISA_APPLICATION_ACTION_TYPE
} from "../../../../../utils/constants";


const initialState = [];

function visaApplicationReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case VISA_APPLICATION_LIST_ACTION_TYPE:
            return [...state, payload];
        case ADD_VISA_APPLICATION_ACTION_TYPE:
            return [...state, payload];
        case GET_VISA_APPLICATION_DETAILS_ACTION_TYPE:
            return [...state, payload];
        case UPDATE_VISA_APPLICATION_ACTION_TYPE:
            return [...state, payload];
        case DELETE_VISA_APPLICATION_ACTION_TYPE:
            return [...state, payload];
        case SUBMIT_VISA_APPLICATION_ACTION_TYPE:
            return [...state, payload];
        default:
            return state;
    }
};

export default visaApplicationReducer;