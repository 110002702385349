import { Component } from "react";
import Loader from "../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import HeaderWithLogo from "../../../../common_components/header_with_logo";
import AdminDrawer from "../../../../common_components/admin_module_drawer";
import Footerwithdr from "../../../../common_components/Footerwithdr";
import { withRouter } from "../../../../utils/withRouter";
import { connect } from "react-redux";
import { taxInvoiceList_action, invoiceMail_action } from "../../../../actions/admin_module_action/tax_invoice_action/tax_invoice_action";
import {
  countryActionId,
  downloadReport,
  exhibitorActionId,
  exhibitorTypeActionId,
  fetchAuthReport,
  stateActionId,
  taxInvoiceDoc,
  toastError,
  toastSuccess,
} from "../../../../utils/constants";
import Pagination from "../../../../utils/pagination";
import moment from "moment";
import Workbook from 'react-excel-workbook'
import { fill_action } from "../../../../actions/fillCombo_action";
import { axiosPost } from "../../../../react_axios/Axios";
import Modal from "react-modal";
import { emailValidator } from "../../../../utils/Validator";

class taxInvoiceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ReferenceNo: "",
      isLoading: false,
      isOpen: false,
      UserID: "",
      ExhibitionID: "",
      ReceiptID: "",
      AccountID: "",
      ReceiptNo: "",
      State: "",
      CountryName: "",
      taxInvoiceData: [],
      CompanyName: "",
      Name: "",
      ReceiptNo: "",
      ReceiptDate: "",
      ProformaInvoice_No: "",
      ReceivedAmount: "",
      currentPage: 1,
      countryList: [],
      stateList: [],
      exhibitorTypeList: [],
      exhibitorFillList: [],
      exhibitorFillId: "",
      exhibitorTypeId: "",
      stateId: "",
      countryId: "",
      allSelected: false,
      checkboxStates: [],
      excelList: [],
      loginAccesslist: [],
      exhibitorCompany: "",
      taxInvoiceNo: "",
      taxInvoiceId: "",
      additionalEmail: "",
      additionalEmailErr: ""
    };
    this.getTaxInvoiceList = this.getTaxInvoiceList.bind(this);
    this.taxInvoiceRecords = this.taxInvoiceRecords.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.handleView = this.handleView.bind(this)
    this.onSendTaxInvoice = this.onSendTaxInvoice.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.invoiceMail = this.invoiceMail.bind(this)
    this.sendTaxInvoice = this.sendTaxInvoice.bind(this)
    this.getDownloadAccess = this.getDownloadAccess.bind(this)
    this.getSendAccess = this.getSendAccess.bind(this)
  }
  componentDidMount() {
    this.getTaxInvoiceList();
    this.getFillList(exhibitorActionId, "", "", "", "", "");
    this.getFillList(exhibitorTypeActionId, "", "", "", "", "");
    this.getFillList(stateActionId, "", "", "", "", "");
    this.getFillList(countryActionId, "", "", "", "", "");
    const { taxInvoiceData } = this.state;
    const initialCheckboxStates = new Array(taxInvoiceData.length).fill(false);
    this.setState({
      checkboxStates: initialCheckboxStates,
      loginAccesslist: JSON.parse(sessionStorage.getItem('loginDataSS'))['loginAccesslist']
    });
  }

  async getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
    this.setState({ isLoading: true });
    this.props
      .fill_action(actionID, exhibitionID, moduleID, keyValue, value)
      .then((data) => {
        if (data.error != 1) {
          if (actionID == exhibitorActionId) {
            this.setState({ exhibitorFillList: data.data, isLoading: false });
            console.log("exhibitorActionId", data.data);
          }
          if (actionID == exhibitorTypeActionId) {
            this.setState({ exhibitorTypeList: data.data, isLoading: false });
            console.log("exhibitorTypeActionId", data.data);
          }
          if (actionID == stateActionId) {
            this.setState({ stateList: data.data, isLoading: false });
            console.log("stateActionId", data.data);
          }
          if (actionID == countryActionId) {
            this.setState({ countryList: data.data, isLoading: false });
            console.log("countryActionId", data.data);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getTaxInvoiceList() {
    const { exhibitorCompany, ReferenceNo, taxInvoiceNo } =
      this.state;
    this.setState({ isLoading: true });
    this.props
      .taxInvoiceList_action(
        exhibitorCompany, ReferenceNo, taxInvoiceNo
      )
      .then((data) => {
        if (data.error != 1) {
          var list = [...data.data]
          list.map((item, index) => {
            item.isSelect = 0;
          });
          // data.data = data.data.map(item => ({ ...item, Is_checked: 0 }));
          console.log("from UI Client", list);
          this.setState({
            taxInvoiceData: list,
            isLoading: false,
          });
        } else {
          this.setState({ isLoading: false });
          toastError(data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  checkedBoxList(checkedItems, index, e) {
    const { taxInvoiceData, excelList } = this.state;
    const list = [...taxInvoiceData];
    const event = e.target.checked;

    if (event == true) {
      checkedItems.forEach((data, i) => {
        if (i === index) {
          data.isSelect = 1;

          excelList.push(data)
        }
      });
      // this.setState({
      //   taxInvoiceData: list,
      // });
    } else {
      checkedItems.forEach((data, i) => {
        if (i === index) {
          data.isSelect = 0;
        }
      });
      this.setState({
        // taxInvoiceData: list,
        excelList: []
      });
    }
    console.log("list", list);
    const checkboxDisable = list.some((item) => item.isSelect == 1);

    // debugger
    if (checkboxDisable) {
      this.setState({ isDisable: false });
    } else {
      this.setState({ isDisable: true });
    }

    const allSelectedChecked = list.every((item) => item.isSelect == 1);

    // debugger
    if (allSelectedChecked) {
      this.setState({ allSelected: true });
    } else {
      this.setState({ allSelected: false });
    }
  }

  allSelectedCheckbox(e) {
    const { taxInvoiceData, excelList } = this.state;

    if (e.target.checked == true) {
      taxInvoiceData.map((data) => {
        data.isSelect = 1;
      });
      this.setState({ allSelected: true, excelList: taxInvoiceData });
    } else {
      taxInvoiceData.map((data) => {
        data.isSelect = 0;
      });
      this.setState({ allSelected: false, excelList: [] });
    }
  }

  navigateToEdit(ExhibitionID, ReceiptID) {
    this.props.navigate("/taxinvoiceedit", {
      state: {
        ExhibitionID: ExhibitionID,
        ReceiptID: ReceiptID
      }
    });
  }

  invoiceMail() {
    const { taxInvoiceData, additionalEmail } = this.state
    const list = []
    taxInvoiceData.map((data) => {
      if (data.isSelect == 1) {
        list.push(
          {
            'TaxInvoiceID': data.TaxInvoiceID
          }
        )
      }
    })
    console.log("taxInvoiceData", list)
    this.setState({ isLoading: true })
    this.props.invoiceMail_action(list, additionalEmail)
      .then((data) => {
        if (data.error != 1) {
          console.log("data", data)
          this.setState({ isLoading: false })
          toastSuccess("InvoiceMail send successfully!")
          this.getTaxInvoiceList()
        } else {
          this.setState({ isLoading: false })
          toastError(data.msg)
        }
      })
      .catch((err) => {
        console.log(err)
      })

  }

  updateItem(item) {
    this.setState({ currentPage: item });
  }

  taxInvoiceRecords(taxInvoiceData) {
    const { allSelected, checkboxStates } = this.state;
    return (
      <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
        <thead>
          <tr>
            <th className="text-center">
              <input
                type="checkbox"
                checked={allSelected}
                onChange={(e) => this.allSelectedCheckbox(e)}
              />
            </th>
            <th className="text-center">Reference No</th>
            <th>Exhibitor</th>
            <th className="text-center">Invoice No</th>
            <th className="text-center">Date</th>
            <th className="text-center">Amount</th>
            <th className="text-center">Type</th>
            <th>View</th>
            {this.getSendAccess() &&
              <th>Action</th>
            }
            {/* <th>Send</th> */}
          </tr>
        </thead>
        <tbody>
          {taxInvoiceData.length > 0 ? (
            taxInvoiceData.map((data, index) => (
              <tr key={index}>
                <td className="text-center">
                  <input
                    type="checkbox"
                    checked={data.isSelect}
                    onChange={(e) => this.checkedBoxList(taxInvoiceData, index, e)}
                  />
                </td>
                <td className="text-center">{data.ExhRegistrationNo}</td>
                <td>{data.CompanyName}</td>
                <td>{data.TaxInvoice_No}</td>
                <td className="text-center">
                  {data.TaxInvoice_Date
                    ? moment(data.TaxInvoice_Date).format("DD-MM-YYYY")
                    : null}
                </td>
                <td className="text-center">
                  {data.Total_Payable?.toLocaleString("en-IN")}
                </td>
                <td className="text-center">{data.ServiceName}</td>
                <td className="text-center">

                  <i className="fa fa-eye" aria-hidden="true"
                    onClick={e => this.handleView(data.TaxInvoiceID)}
                  />
                </td>
                {this.getSendAccess() &&
                  <td className="text-center">
                    <button className="btn rounded-0 btn-secondary" style={{ padding: "2px" }}
                      onClick={(e) => this.onSendTaxInvoice(data.TaxInvoiceID)}
                    >Send</button>
                  </td>
                }
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8">No data available</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  onCreateExcel() {
    const { taxInvoiceData } = this.state

    const checkboxDisable = taxInvoiceData.some(item => item.isSelect == 1)

    var listData = []
    taxInvoiceData.length > 0 && taxInvoiceData.map((item, index) => {
      var mapData1 = {
        'SR No': index + 1,

      }
      var finalMapData = { ...mapData1, ...item }
      listData.push(finalMapData)
    })

    var excludeKeys = [
      'ExhibitionID', 'TaxInvoiceID'
    ]

    // Function to remove keys from objects
    function removeKeysFromObjects(arr, keys) {
      return arr.map((obj) => {
        const newObj = { ...obj }; // Create a new object to avoid modifying the original
        keys.forEach((key) => delete newObj[key]); // Remove specified keys from the new object
        return newObj;
      });
    }

    // Remove keys from the array of objects
    const newArray = removeKeysFromObjects(listData, excludeKeys);

    var rowData = [];
    var columnArray = []

    // for column data 
    newArray.map((data, index) => {
      if (index == 0) {
        Object.keys(data).map((key, index) => {
          columnArray.push(key)
        })
      }
    })

    // for row data
    if (checkboxDisable) {
      newArray.map((data, index) => {
        if (data.isSelect == 1) {
          rowData.push(data)
        }
      })
    } else {
      newArray.map((data, index) => {
        rowData.push(data)
      })
    }


    return (
      <div className="row text-center" style={{ marginLeft: "-1px" }} >
        <Workbook filename="Tax_Invoice_List.xlsx" element={
          <button type="button" className="btn rounded-0 btn-success"
            disabled={!this.getDownloadAccess()}>
            Download EXCEL
          </button>
        }>
          <Workbook.Sheet data={rowData} name="Sheet A">
            {columnArray.map((value, index) =>
              <Workbook.Column label={value} value={value} />
            )
            }
          </Workbook.Sheet>
        </Workbook>
      </div>
    );
  }

  handleSearch() {
    this.getTaxInvoiceList()
  }

  clearFilters() {
    this.setState(
      {
        exhibitorCompany: "",
        ReferenceNo: "",
        taxInvoiceNo: ""
      },
      () => {
        this.getTaxInvoiceList();

      }
    );
  }

  handleView = async (id) => {
    this.setState({ isLoading: true });
    try {
      var list = [];
      const mapdata1 = {
        "ParameterName": "@TaxInvoiceID",
        "ParameterValue": id,
        "ParameterDataType": "int"
      };
      list.push(mapdata1);
      const mapData = {
        "report_name": taxInvoiceDoc,
        "parameter_details": list
      };
      console.log("xxxxxxxxxxxxxxxxxxxxx", mapData)
      const res = await axiosPost(fetchAuthReport, mapData);
      this.setState({ isLoading: false });

      if (res != null) {
        var url = `${downloadReport}?SessionIdentifier=${res}`;
        const win = window.open(url, '_blank', 'noopener,noreferrer');
        if (win != null) {
          win.focus();
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  // On Generate Hall No Stall No 
  onSendTaxInvoice(id) {
    this.setState({ taxInvoiceId: id })
    this.toggleModal()
  }

  // Modal Toggle 2
  toggleModal() {
    const { isOpen } = this.state
    this.setState({ isOpen: !isOpen })
  }

  // On Send Tax Invoice
  sendTaxInvoice() {
    const { isOpen, additionalEmail, taxInvoiceId, additionalEmailErr } = this.state;
    const emailError = emailValidator(additionalEmail);

    if (additionalEmail !== "" && emailError !== "") {
      this.setState({ additionalEmailErr: emailError });
      toastError("Please Enter a Proper Email Address");
      return;
    }

    this.setState({ additionalEmailErr: "", isLoading: true });

    if (additionalEmailErr === "") {
      const list = [{ TaxInvoiceID: taxInvoiceId }];
      this.props
        .invoiceMail_action(list, additionalEmail)
        .then((data) => {
          if (data.error !== 1) {
            this.setState({
              isLoading: false,
              taxInvoiceId: "",
              isOpen: !isOpen,
            });
            toastSuccess("Tax Invoice Sent Successfully");
          } else {
            this.setState({ isLoading: false });
            toastError(data.msg);
          }
        });
    }
  }

  getDownloadAccess() {
    const { loginAccesslist } = this.state
    const moduleAccess = loginAccesslist.find((item) => item.ModuleID == '6');
    if (moduleAccess) {
      return moduleAccess.DownloadAccess;
    } else {
      return false
    }
    return false;
  }

  getSendAccess() {
    const { loginAccesslist } = this.state
    const moduleAccess = loginAccesslist.find((item) => item.ModuleID == '6');
    if (moduleAccess) {
      return moduleAccess.SendAccess;
    } else {
      return false
    }
    return false;
  }


  render() {
    const {
      isLoading, CompanyName, Name, ProformaInvoice_No, ReceiptDate, ReceiptNo, ReceivedAmount, taxInvoiceData, currentPage, exhibitorFillId,
      exhibitorTypeId, stateId, countryId, exhibitorFillList, exhibitorTypeList, stateList, countryList, exhibitorCompany, ReferenceNo,
      taxInvoiceNo, isOpen, additionalEmail, additionalEmailErr
    } = this.state;
    const recordsPerPage = 10;
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    // Records to be displayed on the current page
    const currentRecords = taxInvoiceData.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );
    const nPages = Math.ceil(taxInvoiceData.length / recordsPerPage);
    return (
      <>
        {isLoading ? <Loader></Loader> : null}
        <ToastContainer />
        <HeaderWithLogo />

        <>
          <AdminDrawer />
          <div
            className={
              JSON.parse(sessionStorage.getItem("loginDataSS")).RoleID == 1
                ? "with-dr-container"
                : "with-dr-container com-height"
            }
          >
            <div className="main-content app-content mt-0 p-1">
              <div className="">
                <div className="row">
                  <div className="step-name">
                    <p>Tax Invoice List</p>
                  </div>
                </div>
                <div style={{ marginTop: 10 }}>
                  <div className="row mb-2 mt-4 pt-3 pb-3">
                    <div className="col-md-4 pr-0">
                      <label htmlFor="validationCustom03">Exhibitor Company</label>
                      <input type="text" className="form-control" value={exhibitorCompany}
                        onChange={(e) =>
                          this.setState({
                            exhibitorCompany: e.target.value,
                          })
                        } />
                    </div>
                    <div className="col-md-4 pr-0">
                      <label htmlFor="validationCustom03">Reference No</label>
                      <input type="text" className="form-control" value={ReferenceNo}
                        onChange={(e) =>
                          this.setState({
                            ReferenceNo: e.target.value,
                          })
                        } />
                    </div>
                    <div className="col-md-4 pr-0">
                      <label htmlFor="validationCustom03"> Tax Invoice No</label>
                      <input type="text" className="form-control" value={taxInvoiceNo}
                        onChange={(e) =>
                          this.setState({
                            taxInvoiceNo: e.target.value,
                          })
                        } />
                    </div>
                  </div>
                  <div className="row p-0 mb-4">
                    <div className="btn-group d-flex justify-content-center">
                      <button
                        className="btn rounded-0 btn-primary"
                        style={{ padding: "3px 10px", maxWidth: "6rem" }}
                        onClick={() => this.handleSearch()}
                      >
                        Search
                      </button>
                      <button
                        className="btn rounded-0 btn-warning"
                        style={{ padding: "3px 10px", maxWidth: "6rem", marginLeft: 10, }}
                        onClick={() => this.clearFilters()}
                      >
                        Clear
                      </button>
                      <button
                        className="btn rounded-0 btn-secondary"
                        style={{
                          padding: "3px 10px",
                          marginLeft: 10,
                          maxWidth: "6rem",
                        }}
                        disabled={!this.getSendAccess()}
                        onClick={() => this.invoiceMail()}
                      >
                        Send
                      </button>
                      {this.onCreateExcel()}
                    </div>
                  </div>
                  <div className="table-responsive mb-5">
                    {this.taxInvoiceRecords(currentRecords)}
                    {taxInvoiceData.length > 0 ? (
                      <Pagination
                        nPages={nPages}
                        currentPage={currentPage}
                        setCurrentPage={this.updateItem}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 1 &&
            <Footerwithdr />
          }
          <Modal isOpen={isOpen} onRequestClose={(e) => this.toggleModal(e)}>
            <div
              className="modal-dialog modal-dialog-centered bd-example-modal-lg"
              id="detail"
            // tabIndex={-1}
            // aria-labelledby="detailLabel"
            // aria-hidden="true"
            >
              <div className="modal-dialog modal-md">
                <div className="modal-content modal-width">
                  <div className="modal-header pb-1">
                    <h5 className="modal-title" id="detailLabel">
                      <strong>Send Tax Invoice</strong>
                    </h5>
                    <button
                      type="button"
                      className="btn-close pt-6"
                      data-bs-dismiss="modal"
                      style={{ fontSize: 12 }}
                      aria-label="Close"
                      onClick={(e) => this.toggleModal(e)}
                    />
                  </div>
                  <div className="modal-body p-2">
                    <div className="d-flex">
                      <div className="col-xl-11">
                        <label className="text-left">Email Address</label>
                        <input
                          type="text"
                          className="form-control"
                          value={additionalEmail}
                          onChange={(e) => this.setState({ additionalEmail: e.target.value, additionalEmailErr: "" })}
                        />
                        {<span className='text-danger text-start text-left d-block mt-1'>{additionalEmailErr}</span>}
                      </div>

                    </div>
                  </div>
                  <div className="modal-footer p-1">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                      onClick={(e) => this.sendTaxInvoice(e)}
                    >
                      Send
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                      onClick={(e) => this.toggleModal(e)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </>

      </>
    );
  }
}
export default withRouter(
  connect(null, { taxInvoiceList_action, fill_action, invoiceMail_action })(taxInvoiceList)
);
