import { async } from "q"
import { axiosGet, axiosPost } from "../../../../../react_axios/Axios"
import { POWER_CONNECTION_LIST_ACTION_TYPE, POWER_CONNECTION_DETAILS_ACTION_TYPE, INSERT_AND_UPDATE_KEYWORDS_INFORMATION_LIST__ACTION_TYPE, getPowerConnectionListUrl, getPowerConnectionDetailsUrl, insertUpdatePowerConnectionUrl, headers } from "../../../../../utils/constants"

export const getPowerConnectionList_action = () => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhregistrationID: sessionStorage.getItem('ExhRegistrationIDSS')
        }
        console.log("Space Booking Action ----->", params)
        const res = await axiosGet(getPowerConnectionListUrl, params)

        dispatch({
            type: POWER_CONNECTION_LIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getPowerConnectionDetails_action = () => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhregistrationID: sessionStorage.getItem('ExhRegistrationIDSS')
        }
        console.log("Space Booking Action ----->", params)
        const res = await axiosGet(getPowerConnectionDetailsUrl, params, headers)

        dispatch({
            type: POWER_CONNECTION_DETAILS_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const submitPowerConnection_action = (remark, is_Agree, powerConnectionTable) => async (dispatch) => {
    try {
        const mapData = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: parseInt(sessionStorage.getItem('sessionExhibitionID')),
            ExhregistrationID: parseInt(sessionStorage.getItem('ExhRegistrationIDSS')),
            Remark: remark,
            Is_Agree: is_Agree,
            PowerConnectionTable: powerConnectionTable,
        }
        console.log("map", mapData)
        const res = await axiosPost(insertUpdatePowerConnectionUrl, mapData, headers)

        dispatch({
            type: INSERT_AND_UPDATE_KEYWORDS_INFORMATION_LIST__ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}
