import { GET_LEDGER_LIST_ACTION_TYPE, SEND_LEDGER_ACTION_TYPE, SEND_OUTSTANDING_ACTION_TYPE } from "../../../utils/constants";


const initialState = [];

function ledger_reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_LEDGER_LIST_ACTION_TYPE:
            return [...state, payload];
        case SEND_LEDGER_ACTION_TYPE:
            return [...state, payload];
        case SEND_OUTSTANDING_ACTION_TYPE:
            return [...state, payload];
        default:
            return state;
    }
}
export default ledger_reducer;