import { axiosGet, axiosPost } from "../../../../react_axios/Axios";
import { insertUpdateVisitorConfigrationUrl, getVisitorConfigrationDetailsUrl, headers, GET_VISITOR_CONFIGRATION_DETAILS, INSERT_UPDATE_VISITOR_CONFIGRATION } from "../../../../utils/constants";


export const insertUpdateVisitorConfigration_action = (ExihibitionID, AttendeeTypeList, PurposeofVisitList, VisitorTypeList, VisitorDaysList, industrySegmentList, VisitorEBadgeDownloadDate, eventTimingList) => async (dispatch) => {
    try {
        const params = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExihibitionID,
            AttendeeTypeList: AttendeeTypeList,
            PurposeofVisitList: PurposeofVisitList,
            VisitorTypeList: VisitorTypeList,
            VisitorDaysList: VisitorDaysList,
            industrySegmentList: industrySegmentList,
            VisitorEBadgeDownloadDate: VisitorEBadgeDownloadDate != '' && VisitorEBadgeDownloadDate != null ? VisitorEBadgeDownloadDate : null,
            eventTimingList: eventTimingList
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosPost(insertUpdateVisitorConfigrationUrl, params, headers)

        dispatch({
            type: INSERT_UPDATE_VISITOR_CONFIGRATION,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getExhibitionConfigrationDetails_action = (ExhibitionID,) => async (dispatch) => {
    try {
        const params = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExhibitionID,
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getVisitorConfigrationDetailsUrl, params, headers)

        dispatch({
            type: GET_VISITOR_CONFIGRATION_DETAILS,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}
