import { Component } from "react";
import Loader from "../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action } from '../../../../actions/fillCombo_action';
import { cityActionId, stallTypeActionId, delegateInvitationTypeActionId, countryActionId, exhibitionActionId, toastError, toastSuccess } from "../../../../utils/constants";
import { emailValidator, panNoValidator, tanNoValidator, notMendetoryEmailValidator, pincodeValidator } from '../../../../utils/Validator'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import Pagination from "../../../../utils/pagination";
import AdminDrawer from "../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../common_components/header_with_logo";
import { useNavigate } from "react-router-dom";
import ExhibitorDrawer from "../../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../../common_components/exhibitor_module_header";
import { data } from "jquery";
import { insertInvitationEvent_action, updateInvitationEvent_action, getInvitationEvent_action } from "../../../../actions/admin_module_action/delegate_master_action/delegate_InvitationEvent_action";
import Footer from "../../../../common_components/Footer";
import Resizer from "react-image-file-resizer";


class Delegate_InvitationEventAU extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            currentPage: 1,
            is_add: false,
            is_update: false,
            is_view: false,
            StateID: '',
            countryID: '',
            countryList: [],
            Status: '',
            InvitationEventName: '',
            StateErrMsg: '',
            countryErrMsg: '',
            exhibitionID: '',
            exhibitionList: [],
            InvitationTypeID: [],
            InvitationTypeList: [],
            InvitationDate: '',
            Location: '',
            ReportingTime: '',
            FromTime: '',
            ToTime: '',
            Capacity: '',
            formFile: '',
            fileName: '',
            formFileType: '',
            InvitationEventID: '',
            InvitationEventImagePath: '',
        }

    }

    componentDidMount() {
        this.setState({
            is_add: this.props.location.state != null && this.props.location.state.is_add != null ? this.props.location.state.is_add : false,
            is_update: this.props.location.state != null && this.props.location.state.is_update != null ? this.props.location.state.is_update : false,
            is_view: this.props.location.state != null && this.props.location.state.is_view != null ? this.props.location.state.is_view : false,
            InvitationEventID: this.props.location.state != null && this.props.location.state.InvitationEventID != null ? this.props.location.state.InvitationEventID : '',
        })
        this.getFillList(exhibitionActionId, '', '', '', '', '')
        this.getFillList(delegateInvitationTypeActionId, '', '', '', '', '')
        setTimeout(() => {
            this.props.location.state != null && this.props.location.state.is_update != null && this.props.location.state.is_update ? this.getInvitationEvent() : <></>
        }, 200);
        console.log("this.props", this.props)
    }

    getInvitationEvent() {
        const { InvitationEventID } = this.state
        // var StateErrMsg = StateNoValidator(StateNo)
        this.setState({ isLoading: true })
        this.props.getInvitationEvent_action(null, parseInt(InvitationEventID))
            .then((data) => {
                if (data.error != 1) {
                    if (data.data != '') {
                        const file = data.data[0]['InvitationEventImagePath'].split(`${data.data[0]['InvitationEventID']}/`)
                        this.setState({
                            fileName: file[1],
                            InvitationEventID: data.data[0]['InvitationEventID'],
                            InvitationEventName: data.data[0]['InvitationEventName'],
                            exhibitionID: data.data[0]['ExhibitionID'],
                            InvitationTypeID: data.data[0]['InvitationTypeID'],
                            InvitationDate: new Date(data.data[0]['InvitationEventDate']),
                            Location: data.data[0]['Location'],
                            ReportingTime: moment.utc(data.data[0]['ReportingTime']).format('HH:mm'),
                            FromTime: moment.utc(data.data[0]['FromTime']).format('HH:mm'),
                            ToTime: moment.utc(data.data[0]['ToTime']).format('HH:mm'),
                            Capacity: data.data[0]['Capacity'],
                            InvitationEventImagePath: data.data[0]['InvitationEventImagePath'],
                        })
                    }
                    this.setState({ isLoading: false, })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        const { } = this.state
        return new Promise((resolve, reject) => {
            this.setState({ isLoading: true })
            this.props
                .fill_action(actionID, exhibitionID, moduleID, keyValue, value)
                .then((data) => {
                    if (data.error != 1) {
                        // country list
                        if (actionID === exhibitionActionId) {
                            this.setState({
                                exhibitionList: data.data
                            })
                        }
                        if (actionID === delegateInvitationTypeActionId) {
                            this.setState({
                                InvitationTypeList: data.data
                            })
                        }
                        resolve(true)
                    }
                    this.setState({ isLoading: false })
                })
                .catch((e) => {
                    console.log(e);
                });
        })
    }

    insertInvitationEvent() {
        const { InvitationTypeID, exhibitionID, InvitationEventName, InvitationDate, Location, ReportingTime, FromTime, ToTime, Capacity, formFile, fileName, formFileType, } = this.state
        this.setState({ isLoading: true })
        if (exhibitionID != '' && InvitationTypeID != '' && InvitationEventName != '' && InvitationDate != '' && Location != '' && ReportingTime != '' && FromTime != '' && ToTime != '' && Capacity != '' && fileName != '') {
            this.props.insertInvitationEvent_action(exhibitionID, InvitationTypeID, InvitationEventName, InvitationDate, Location, ReportingTime, FromTime, ToTime, null, Capacity, formFile, fileName, formFileType)
                .then((data) => {
                    if (data.error != 1) {
                        toastSuccess("Insert Successfully")
                        this.setState({ isLoading: false })
                        setTimeout(() => {
                            this.props.navigate('/delegateInvitationEventList',)
                        }, 100);
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            toastError("Please enter mandatory fields!")
            this.setState({ isLoading: false })
        }
    }

    updateInvitationEvent() {
        const { InvitationTypeID, exhibitionID, InvitationEventID, InvitationEventName, InvitationDate, Location, ReportingTime, FromTime, ToTime, Capacity, formFile, fileName, formFileType, InvitationEventImagePath } = this.state
        this.setState({ isLoading: true })
        if (exhibitionID != '' && InvitationTypeID != '' && InvitationEventName != '' && InvitationDate != '' && Location != '' && ReportingTime != '' && FromTime != '' && ToTime != '' && Capacity != '' && fileName != '') {
            this.props.updateInvitationEvent_action(exhibitionID, InvitationEventID, InvitationTypeID, InvitationEventName, InvitationDate, Location, ReportingTime, FromTime, ToTime, InvitationEventImagePath, Capacity, formFile, fileName, formFileType)
                .then((data) => {
                    if (data.error != 1) {
                        toastSuccess("Insert Successfully")
                        this.setState({ isLoading: false })
                        setTimeout(() => {
                            this.props.navigate('/delegateInvitationEventList',)
                        }, 100);
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            toastError("Please enter mandatory fields!")
            this.setState({ isLoading: false })
        }
    }

    onPreviousClick() {
        this.props.navigate('/delegateInvitationEventList',)
    }

    insertStateMaster() {
        const { isLoading, exhibitionID, InvitationEventName, countryID, hallName, StateErrMsg, is_update, StateNo, Panno } = this.state
        // var StateErrMsg = StateNoValidator(StateNo)
        if (countryID != null && countryID != '' && InvitationEventName != null && InvitationEventName != '') {
            this.setState({ isLoading: true })
            this.props.insertStateMaster_action(countryID, InvitationEventName)
                .then((data) => {
                    if (data.error != 1) {
                        console.log(data.data)
                        this.props.navigate('/stateMasterList',)
                        toastSuccess("added successfully")
                        this.setState({ isLoading: false })
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })

        }
        else {
            toastError("Please enter mandatory fields")
        }
    }

    onFilePick = async (e, file) => {
        e.preventDefault()
        var image = '';
        if (file[0]['size'] > 5242880) {
            toastError('File size more than 5MB are not allow.')
        } else {
            if (
                file[0]['name'].toString().toLowerCase().includes('.jpeg') ||
                file[0]['name'].toString().toLowerCase().includes('.png') ||
                file[0]['name'].toString().toLowerCase().includes('.jpg')
            ) {
                image = await this.resizeFile(file[0]);
                console.log("file[0]--->", file[0])
                console.log("file[0]['name']--->", file[0]['name'])
                this.setState({ formFile: image, fileName: file[0]['name'] })

                const fileType = file[0]['type']
                var fileTypeList;
                if (fileType.includes("/")) {
                    fileTypeList = fileType.split("/")

                }
                this.setState({ formFileType: fileTypeList.length > 0 ? fileTypeList[1] : '' })
            } else {
                toastError('Only .jpg, .jpeg, .png, files are allowed')
            }
        }
    }

    resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                500,
                600,
                'JPEG',
                80,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64",
                300,
                300,
            );
        });

    removeFile() {
        this.setState({
            formFile: '',
            fileName: '',
            formFileType: '',
        })
    }

    render() {
        const { isLoading, InvitationEventName, InvitationDate, Location, ReportingTime, FromTime, ToTime, Capacity, fileName, is_view } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <div className="app-content with-dr-container com-height">
                        <AdminDrawer />
                        <div className="row">
                            <div className="step-name">
                                <p>{this.state.is_add == true ? 'Invitation Event Add' : 'Invitation Event Update'}</p>
                            </div>
                        </div>
                        <div className="m-3">
                            <div className="">
                                <div className="row ">
                                    <div className="col-md-3">
                                        <label >Exhibition<span className="text-red">*</span></label>
                                        <select className="form-control"
                                            value={this.state.exhibitionID}
                                            onChange={(e) => this.setState({ exhibitionID: e.target.value })}
                                        >
                                            <option value={''}>Select</option>
                                            {
                                                this.state.exhibitionList.length > 0 && this.state.exhibitionList.map((data) => (
                                                    <option key={data.ExhibitionID} value={data.ExhibitionID}>{data.ExhibitionName}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-3">
                                        <label >Invitation Type<span className="text-red">*</span></label>
                                        <select className="form-control"
                                            value={this.state.InvitationTypeID}
                                            onChange={(e) => this.setState({ InvitationTypeID: e.target.value })}
                                        >
                                            <option value={''}>Select</option>
                                            {
                                                this.state.InvitationTypeList.length > 0 && this.state.InvitationTypeList.map((data) => (
                                                    <option key={data.InvitationTypeID} value={data.InvitationTypeID}>{data.InvitationTypeName}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-3">
                                        <label>Invitation Event Name<span className="text-red">*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={InvitationEventName}
                                            onChange={(e) => this.setState({ InvitationEventName: e.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <label >Invitation Date <span className="text-red">*</span>  </label>
                                        <DatePicker selected={InvitationDate}
                                            onChange={(date) =>
                                                this.setState({
                                                    InvitationDate: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())),
                                                    arrivalDateErr: ""
                                                })}
                                            dateFormat={"dd/MM/yyyy"} className="form-control"
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            placeholderText="DD/MM/YYYY"
                                            dayClassName={() => "example-datepicker-day-class"}
                                            popperClassName="example-datepicker-class" />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label>Location<span className="text-red">*</span></label>
                                        <input
                                            type="text"
                                            maxLength={200}
                                            className="form-control"
                                            value={Location}
                                            onChange={(e) => this.setState({ Location: e.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <label>Reporting Time<span className="text-red">*</span></label>
                                        <input
                                            type="time"
                                            maxLength={200}
                                            className="form-control"
                                            value={ReportingTime}
                                            onChange={(e) => this.setState({ ReportingTime: e.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <label>From Time<span className="text-red">*</span></label>
                                        <input
                                            type="time"
                                            maxLength={200}
                                            className="form-control"
                                            value={FromTime}
                                            onChange={(e) => this.setState({ FromTime: e.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <label>To Time<span className="text-red">*</span></label>
                                        <input
                                            type="time"
                                            maxLength={200}
                                            className="form-control"
                                            value={ToTime}
                                            onChange={(e) => this.setState({ ToTime: e.target.value })}
                                        />
                                    </div>
                                </div>
                                < div className="row mt-2" >
                                    <div className="col-md-3">
                                        <label>Invitation Event Image<span className="text-red">*</span></label>
                                        {fileName != '' ?
                                            <>
                                                <div className="d-flex" style={{ justifyContent: 'center' }}>
                                                    <div className="chooseStallFile" style={{ margin: '0px', width: '100%' }}>
                                                        <p className="overflow-membership m-0">{fileName}
                                                        </p>
                                                        <span style={{ textAlign: 'right', width: '100%' }}
                                                            className='membership_file_cross text-danger'
                                                            onClick={(e) => this.removeFile(e)}
                                                        >X</span>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <div className="d-flex" style={{ justifyContent: 'center' }}>
                                                <input type="file"
                                                    className="form-control mt-2 p-1 mb-2 custom-file-input"
                                                    accept=".jpeg, .jpg, .png"
                                                    onChange={(e) => this.onFilePick(e, e.target.files)}
                                                />
                                            </div>
                                        }
                                    </div>
                                    <div className="col-md-3">
                                        <label>Capacity<span className="text-red">*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={Capacity}
                                            maxLength={50}
                                            onChange={(e) => this.setState({ Capacity: e.target.value.replace(/\D/, '') })}
                                        />
                                    </div>
                                </div>
                            </div>
                            < div className="row mt-2" >
                                <div className="col-md-12">
                                    <div className="main-container container-fluid p-0 mt-2 pt-0 pb-0">
                                        <div className="mt-1 mb-4">
                                            {this.state.is_add ?
                                                <button className="btn btn-next-bot mx-2" onClick={e => this.insertInvitationEvent()} >
                                                    Save
                                                </button> :
                                                <>
                                                    {!is_view &&
                                                        <button className="btn btn-next-bot mx-2" onClick={e => this.updateInvitationEvent()} >
                                                            Update
                                                        </button>}
                                                </>
                                            }
                                            <button className="btn btn-previous" onClick={e => this.onPreviousClick()}>
                                                {" "}
                                                Back{" "}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </>
                <Footer />
            </>)
    }

}
export default withRouter(connect(null, { fill_action, insertInvitationEvent_action, updateInvitationEvent_action, getInvitationEvent_action })(Delegate_InvitationEventAU));