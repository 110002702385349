import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../common_components/Header';
import HeaderLogin from '../../common_components/HeaderLogin';
import Drawer from '../../common_components/Drawer';
import {
    registrationOneAdd_action,
} from '../../actions/registration_action'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../utils/withRouter';
import moment from 'moment';
import Modal from "react-modal";
import Loader from '../../utils/Loader'
import { clearSessionStorage, countryActionId, toastError, toastSuccess, countryIWithCodeActionId } from '../../utils/constants'
import Pagination from '../../utils/pagination'
import { useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { fill_action } from '../../actions/fillCombo_action'
import {
    getAgentDetails_action
} from '../../actions/registration_action'

/* 
 * mapDispatchToProps
*/
// const mapDispatchToProps = dispatch => ({
//     loginAction: () => dispatch(loginAction())
// })

/* 
 * mapStateToProps
*/
const mapStateToProps = state => ({
    ...state
})

class CountrySelect extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            countryId: '',
            countryName: '',
            countryList: [],
            agentCountryId: '',
            code: "",
            bannerPath: "",
            isDataLoad: false,
            isExibitionClosed: false,
            website: '',
        }
        this.onSelectCountryId = this.onSelectCountryId.bind(this)
        this.getFillList = this.getFillList.bind(this)
        this.getAgentDetails = this.getAgentDetails.bind(this);
        this.onPreviousClick = this.onPreviousClick.bind(this)
    }

    componentDidMount() {
        sessionStorage.setItem('completed', false);
        const { code } = this.state
        sessionStorage.clear()
        var urlCode = '';
        var exhCode = '';
        var agentCode = '';
        if (this.props.location != null) {
            console.log('location code is :', this.props.location);
            urlCode = this.props.location.pathname.replace('/', '')
            if (urlCode.includes('-')) {
                // var splitList = urlCode.split('-');
                var index = urlCode.indexOf('-');
                var splitList = [urlCode.slice(0, index), urlCode.slice(index + 1)];
                // var splitList = urlCode.split('-').slice(1).join('-');
                console.log('agent code list : ',splitList);
                exhCode = splitList.length > 0 ? splitList[0] : '';
                agentCode = splitList.length > 1 ? splitList[1] : '';
                sessionStorage.setItem("agentCode", agentCode)

            } else {
                exhCode = urlCode
                sessionStorage.setItem("agentCode", '')
            }
            console.log('final code is :', exhCode);
            this.setState({ code: exhCode })
        }



        sessionStorage.setItem("renderPage", 'countrySelect')
        if (sessionStorage.getItem("sessionCode") == null) {

            sessionStorage.setItem("sessionCode", exhCode)
        } else {

            sessionStorage.setItem("sessionCode", exhCode)
        }
        this.getFillList(countryIWithCodeActionId, '', '', '', exhCode, '')
    }

    onSelectCountryId(e) {
        e.preventDefault();
        const { countryId, countryList, countryName } = this.state
        var countryID = '';
        countryList.map(data => {
            data.Code == countryId && this.setState({ countryName: data.Description })
        });
        // console.log("787sdsdddddddddddddddd9", countryId )
        // to pass country Id to registration one page
        setTimeout(() => {
            // sessionStorage.clear()
            sessionStorage.setItem("sessionContryNamePre", countryList.filter(x => x.Code == countryId)[0].CountryCode)

            sessionStorage.setItem("isCountrySelected", true)
            sessionStorage.setItem("isFirstStepCompleted", false)
            sessionStorage.setItem("sessionContryName", countryList.filter(x => x.Code == countryId)[0].Description)
            sessionStorage.setItem("renderPage", "registerForm")
            sessionStorage.setItem("countryId", countryId)
            countryId != '' ? this.props.navigate('/registration', {
                state: {
                    countryId: Number(countryId), is_update: false,
                    countryName: countryList.filter(x => x.Code == countryId)[0].Description
                }
            })
                : toastError("Please select country")

        }, 300);
    }

    // get fill List dropdown  
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        this.setState({ isLoading: true })
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value, from)
            .then((data) => {
                if (data.error != 1) {
                    // country list
                    if (actionID === countryActionId) {
                        console.log("country data", data.data)

                        if (sessionStorage.getItem("agentCode") != null && sessionStorage.getItem("agentCode") != '') {
                            this.getAgentDetails(sessionStorage.getItem("agentCode"));
                        }

                        this.setState({
                            countryList: data.data, isLoading: false,
                        })
                    }
                    // ExhibitionID
                    if (actionID === countryIWithCodeActionId) {
                        console.log("country data", data.data[0])
                        console.log(data.data[0])
                        var emailArr = data.data[0].ExhMailEmail != '' && data.data[0].ExhMailEmail != null ? data.data[0].ExhMailEmail.split(",") : []
                        var mobileArr = data.data[0].ExhMailMobile != '' && data.data[0].ExhMailMobile != null ? data.data[0].ExhMailMobile.split(",") : []

                        sessionStorage.setItem("sessionExhibitionID", data.data[0]['ExhibitionID'])
                        sessionStorage.setItem("exhibitionAddress", data.data[0]['Address'])
                        sessionStorage.setItem("sessionBannerPath", data.data[0]['Banner'])
                        sessionStorage.setItem("exhibitionDateFrom", data.data[0]['ExhibitionDateFrom'])
                        sessionStorage.setItem("exhibitionDateTo", data.data[0]['ExhibitionDateTo'])
                        sessionStorage.setItem("sessionLogoPath", data.data[0]['Logo'])
                        sessionStorage.setItem("sessionExhibitionName", data.data[0]['ExhibitionName'])
                        sessionStorage.setItem("sessionExhibitionEmail", JSON.stringify(emailArr))
                        sessionStorage.setItem("sessionExhibitionMobileNo", JSON.stringify(mobileArr))
                        sessionStorage.setItem("sessionExhMailWebsite", data.data[0]['ExhMailWebsite'])

                        this.setState({
                            isDataLoad: true,
                            bannerPath: data.data[0]['Banner'] != null && data.data[0]['Banner'],
                            website: data.data[0]['ExhMailWebsite'],
                            isExibitionClosed: data.data[0]['IsExhibitionClosed'] != null && data.data[0]['IsExhibitionClosed'] == 1 ? true : false
                        })
                        console.log("country data", emailArr, mobileArr, JSON.parse(sessionStorage.getItem("sessionExhibitionMobileNo")))
                        this.getFillList(countryActionId, data.data[0]['ExhibitionID'], '', '', '', '')

                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    getAgentDetails(code) {
        this.setState({ isLoading: true });
        this.props
            .getAgentDetails_action(code)
            .then((data) => {
                if (data.error != 1 && data.data != null && data.data.length > 0) {
                    const { countryList } = this.state

                    if (countryList.length > 0) {
                        var filterList = []
                        var countryIDs = []
                        if (data.data[0]['CountryIDs'] != null && data.data[0]['CountryIDs'] != 'null' && data.data[0]['CountryIDs'] != '' && data.data[0]['CountryIDs'].includes(',')) {
                            countryIDs = data.data[0]['CountryIDs'].split(',')
                        } else {
                            if (data.data[0]['CountryIDs'] != null && data.data[0]['CountryIDs'] != 'null' && data.data[0]['CountryIDs'] != '') {
                                countryIDs.push(data.data[0]['CountryIDs'])
                            } else {
                                countryIDs.push(data.data[0]['CountryID'])
                            }
                        }

                        for (var i = 0; i < countryList.length; i++) {
                            for (var j = 0; j < countryIDs.length; j++) {
                                if (countryList[i].Code == countryIDs[j]) {
                                    filterList.push(countryList[i]);
                                }
                            }
                        }
                        this.setState({
                            countryList: filterList,
                            countryId: data.data[0]['CountryID']
                        })
                    }

                    setTimeout(() => {
                        this.setState({ isLoading: false, agentCountryId: data.data[0]['CountryID'] ?? '' })
                    }, 300);
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    onPreviousClick(e) {
        e.preventDefault();
        const win = window.location.replace('https://www.itme-africa.com/', '_blank');
        if (win != null) {
            win.focus();
        }
    }



    render() {
        const { isLoading, countryId, countryList, isDataLoad, website, bannerPath, isExibitionClosed } = this.state


        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                {/* <!-- PAGE --> */}
                <div className="page">
                    <div className="page-main">
                        <div className="app-header header sticky">
                            <div className="container-fluid main-container">
                                <div className="d-flex">
                                    {/* <a aria-label="Hide Sidebar" class="app-sidebar__toggle" data-bs-toggle="sidebar" href="javascript:void(0)"><i class="fa fa-bars"></i></a> */}
                                    {/* sidebar-toggle*/}
                                    <div class="side-header">
                                        <a class="header-brand1" >
                                            <img src="../images/logo.svg" class="header-brand-img light-logo1" alt="top-left logo" />
                                        </a>
                                    </div>
                                    <h5 className="m-0 mt-2 header-logo-name"> INDIA ITME SOCIETY <br />
                                        <span style={{ fontSize: 13 }}>Trusted name for Quality and Commitment </span>
                                    </h5>
                                    <p />
                                    <a className="logo-horizontal " href="index.html">
                                        <img
                                            src="../images/logo.svg"
                                            className="header-brand-img desktop-logo" style={{ marginLeft: '-10px' }}
                                            alt="logo"
                                        />
                                        <img
                                            src="../images/logo.svg"
                                            className="header-brand-img light-logo1"
                                            alt="logo"
                                        />
                                    </a>
                                    <div className="d-flex order-lg-2 ms-auto header-right-icons">
                                        {/* SEARCH */}
                                        <button
                                            className="navbar-toggler navresponsive-toggler d-lg-none ms-auto"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#navbarSupportedContent-4"
                                            aria-controls="navbarSupportedContent-4"
                                            aria-expanded="false"
                                            aria-label="Toggle navigation"
                                        >
                                            <span className="navbar-toggler-icon fe fe-more-vertical" />
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="main-content app-content mt-0">
                            <div className="side-app">
                                {isDataLoad ? <HeaderLogin /> : null}
                                {/* <!-- CONTAINER --> */}
                                <div >
                                    <div className="main-container container-fluid">


                                        {/* <!-- ROW-1 --> */}
                                        {!isExibitionClosed ? (
                                            <div className="row ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
                                                    <div className="row ">
                                                        <form className="needs-validation " novalidate="">
                                                            <div className="form-row mt-4">
                                                                <div className='col-md-6 p-4 mt-7 login-left-img'>
                                                                    <a href={website}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer">
                                                                        <img src={bannerPath} alt="ITME Logo" />
                                                                    </a>
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <div className="country-box" style={{ marginTop: '40%' }}>
                                                                        <div className=" mb-0">
                                                                            {/* <div>
                                                                    <spa className="country-icon"><i className="fa fa-file-text-o" aria-hidden="true"></i></spa>
                                                                </div> */}
                                                                            {/* <iframe
                                                                        id="myIframe"
                                                                        src='https://www.bing.com'
                                                                        width="400px"
                                                                        height="400px"
                                                                        title="Bing"
                                                                    /> */}
                                                                            <div>
                                                                                {/* <img src="../images/itme.png" className="header-brand-img " alt="ITME Logo" style={{ width: '105px' }} /> */}
                                                                                {/* <h4 className="mb-1 mt-3" style={{ fontSize: '17px' }}><strong>ITME AFRICA & M.E. 2023</strong></h4> */}
                                                                                {/* <p className="text-center" style={{ marginTop: '0px', fontSize: '16px', marginBottom: '7px' }}><strong>Individual</strong></p> */}
                                                                            </div>
                                                                            <h4 className='country-mes'>Please select your country, and click on apply now to fill the application form.</h4>
                                                                            <select className="form-control select2 select-hidden-accessible pl-0"
                                                                                style={{ width: "100%" }}
                                                                                value={countryId}
                                                                                onChange={e => this.setState({ countryId: e.target.value, })}>
                                                                                <option>Select Your Country</option>
                                                                                {
                                                                                    countryList.length > 0 && countryList.map((item) => (
                                                                                        <option key={item.Code} value={item.Code}>{item.Description}</option>
                                                                                    ))
                                                                                }
                                                                            </select>

                                                                        </div>
                                                                        <button className="btn apply-btn mt-2" type="submit" onClick={e => this.onSelectCountryId(e)}>Apply Now</button>
                                                                    </div>
                                                                </div>


                                                                {/* <!--  <div class="form-group col-md-3 mb-0 pl-0">
                                                <div class="form-group">
                                                    <select class="form-control select2 select-hidden-accessible" id="roledb">
                                                        <option value="">Select Country</option>
                                                        <option>India</option>
                                                        <option>Andorra</option>
                                                        <option>Belgium</option>
                                                        <option>Brazil</option>
                                                        <option>Canada</option>
                                                    </select>
                                                </div>
                                            </div> --> */}
                                                            </div>

                                                        </form>
                                                    </div>
                                                </div>

                                            </div>
                                        ) : (
                                            <div
                                                className="main-content app-content mt-0"
                                                style={{ backgroundColor: "#ececec" }}
                                            >
                                                <div className="side-app">
                                                    <div className="main-container container-fluid top-form-name">
                                                        <div className="row ">
                                                            <form className="needs-validation " noValidate="">
                                                                <div className="form-row mt-4">
                                                                    <div className="form-group col-md-7 p-4 thank-box">
                                                                        <div className="form-group mb-2">
                                                                            <h4
                                                                                className="mb-0 pt-2 pb-4 thank-top-mes"
                                                                                style={{ color: "#318958" }}
                                                                            >
                                                                                <strong>Exhibition Closed</strong>
                                                                            </h4>
                                                                            <p className="mb-1 mt-1 text-center com-font">
                                                                                Thank you for your interest but the
                                                                            </p>
                                                                            <p className="mt-1 mb-0 text-center com-font">
                                                                                ITME AFRICA &amp; M.E. 2023 from 30 November 2023 to 2
                                                                                December 2023
                                                                            </p>
                                                                            <p className="mb-3 text-center com-font">
                                                                                at Kenyatta International Convention Centre, Nairobi, Kenya is now closed.
                                                                            </p>
                                                                            <p className="mb-3 text-center com-font">
                                                                                This Event already completed. Please Visit Again once the next edition is announced.
                                                                            </p>
                                                                        </div>
                                                                        <div classname="">
                                                                            <div classname="mt-1 mb-4">

                                                                                <button
                                                                                    classname="btn btn-next-bot m-1"
                                                                                    style={{
                                                                                        background: "#007bff",
                                                                                        padding: "6px 20px",
                                                                                        fontSize: 14,
                                                                                        border: "1px solid #007bff",
                                                                                        borderRadius: 2,
                                                                                        color: "#fff",
                                                                                        marginTop: 16
                                                                                    }}
                                                                                    onClick={e => this.onPreviousClick(e)}
                                                                                >
                                                                                    {" "}
                                                                                    BACK TO EVENT WEBSITE
                                                                                </button>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}




                                    </div>
                                </div>
                                {/* <!-- CONTAINER END --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(connect(null, {
    registrationOneAdd_action, fill_action, getAgentDetails_action
})(CountrySelect));

