import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { toastError, toastSuccess, exhDescriptionActionId } from "../../../../../utils/constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import Pagination from "../../../../../utils/pagination";
import { fill_action } from '../../../../../actions/fillCombo_action';
import { insertUpdateExhFreightForward_action, getExhFreightForwardDetails_action } from '../../../../../actions/admin_module_action/master_action/exhibition_master_action/exh_freightForward_action';

class Exh_freightForward extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            currentPage: 1,
            ExihibitionID: '',
            exhFreightForwardList: [],
            DescriptionList: [],
            tempDescriptionList: [],
            FreightForwardTypeList: [],
            tempFreightForwardTypeList: [],
        }
        this.updateItem = this.updateItem.bind(this)
    }

    componentDidMount() {
        this.getFillList(exhDescriptionActionId, '', '', '', '')

        this.setState({
            ExihibitionID: this.props.location.state.ExihibitionID != undefined ? (this.props.location.state != null && this.props.location.state.ExihibitionID != null ? this.props.location.state.ExihibitionID : '') : this.props.id != null && this.props.id != undefined ? this.props.id : '',
        })
        setTimeout(() => {
            console.log(this.state.ExihibitionID)

            this.getExhFreightForwardDetails()
        }, 200);
    }

    getFillList(value, userID, keyword, keyvalue) {
        // this.setState({ isLoading: true })
        this.props
            .fill_action(value, (userID == '' || userID == null) ? null : userID, keyword != '' ? keyword : null, keyvalue != '' ? keyvalue : null)
            .then((data) => {
                if (data.error != 1) {
                    if (value == exhDescriptionActionId) {
                        this.setState({
                            DescriptionList: data.data,
                            tempDescriptionList: data.data,
                        })
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    updateItem(item) {
        this.setState({ currentPage: item });
    }

    getExhFreightForwardDetails() {
        const { ExihibitionID } = this.state
        this.setState({ isLoading: true })
        this.props.getExhFreightForwardDetails_action(ExihibitionID)
            .then((data) => {
                if (data.error != 1) {
                    console.log("1314", data.data)
                    if (data.data != '') {
                        data.data.map((item, i) => {
                            item.id = i + 1
                        })
                        this.setState({ exhFreightForwardList: data.data })
                    } else {
                        this.setState({
                            exhFreightForwardList: [
                                {
                                    MainParticularsName: null,
                                    Particulars: null,
                                    Unit: null,
                                    RateINR: null,
                                    RateUSD: null,
                                    RateINRByAir: null,
                                    RateINRBySea: null,
                                    RateUSDByAir: null,
                                    RateUSDBySea: null,
                                    id: 1
                                }
                            ]
                        })
                    }
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    insertUpdateExhFreightForwardDetails() {
        const { ExihibitionID, exhFreightForwardList } = this.state
        const checkedDuplicate = this.checkForDuplicates(exhFreightForwardList, ['MainParticularsName', 'Particulars', 'Unit', 'RateINR', 'RateUSD', 'RateINRByAir', 'RateINRBySea', 'RateUSDByAir', 'RateUSDBySea'])
        this.setState({ isLoading: true })
        console.log("checkedDuplicate------->", checkedDuplicate)
        if (!checkedDuplicate) {
            this.props.insertUpdateExhFreightForward_action(ExihibitionID, exhFreightForwardList)
                .then((data) => {
                    if (data.error != 1) {
                        this.getExhFreightForwardDetails()
                        toastSuccess("data updated Successfuly!")
                        this.setState({ isLoading: false })
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            toastError("Can not add duplicate data!")
            this.setState({ isLoading: false })
        }
    }

    addExhFreightForwardList(i) {
        const { exhFreightForwardList } = this.state
        exhFreightForwardList.splice(
            i + 1, 0, {
            id: exhFreightForwardList.length + 1,
            MainParticularsName: '',
            Particulars: '',
            Unit: '',
            RateINR: '',
            RateUSD: '',
            RateINRByAir: '',
            RateINRBySea: '',
            RateUSDByAir: '',
            RateUSDBySea: '',
        }
        )
        this.setState({ exhFreightForwardList: exhFreightForwardList })
    }

    checkForDuplicates(arr, keys) {
        const seen = new Set();
        for (const obj of arr) {
            const uniqueKey = keys.map(key => obj[key]).join('|');
            if (seen.has(uniqueKey)) {
                return true; // Duplicate found
            }
            seen.add(uniqueKey);
        }
        return false; // No duplicates found
    }


    deleteExhFreightForwardList(id) {
        const { exhFreightForwardList } = this.state
        const filterArray = exhFreightForwardList.filter(item => item.id != id)
        filterArray.map((item, i) => {
            item.id = i + 1
        })
        this.setState({ exhFreightForwardList: filterArray })
    }

    onInputChange(e, i, param) {
        const { exhFreightForwardList } = this.state
        const event = e.target.value
        exhFreightForwardList.map((data, index) => {
            if (i === index) {
                if (param == 'MainParticularsName') {
                    data.MainParticularsName = event
                } else if (param == 'Particulars') {
                    data.Particulars = event
                } else if (param == 'Unit') {
                    data.Unit = event
                } else if (param == 'RateINR') {
                    data.RateINR = event
                } else if (param == 'RateUSD') {
                    data.RateUSD = event
                } else if (param == 'RateINRByAir') {
                    data.RateINRByAir = event
                } else if (param == 'RateINRBySea') {
                    data.RateINRBySea = event
                } else if (param == 'RateUSDByAir') {
                    data.RateUSDByAir = event
                } else if (param == 'RateUSDBySea') {
                    data.RateUSDBySea = event
                }
            }
        })
        this.setState({ exhFreightForwardList: exhFreightForwardList })
    }


    record(exhFreightForwardList) {
        return (
            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
                <thead>
                    <tr style={{ border: '1px solid #ccc' }}>
                        <th className="text-center">Action</th>
                        <th className="text-left">Particulars Header Name</th>
                        <th className="text-left">Particulars</th>
                        <th className="text-left">Unit</th>
                        <th className="text-center">Rate (INR)</th>
                        <th className="text-center">Rate (USD)</th>
                        <th className="text-center">Rate INR By Air</th>
                        <th className="text-center">Rate INR By Sea</th>
                        <th className="text-center">Rate USD By Air</th>
                        <th className="text-center">Rate USD By Sea</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        exhFreightForwardList != '' && exhFreightForwardList != null ? (
                            exhFreightForwardList.map((data, i) => (
                                <tr key={i}>
                                    <td className="text-center">
                                        <button
                                            title="Add FreightForward"
                                            class="btn btn-sm padding_0"
                                            onClick={() => this.addExhFreightForwardList(i)}
                                        >
                                            <i class="fa fa-plus action_btn_table"></i>
                                        </button>
                                        {this.state.exhFreightForwardList.length != 1 &&
                                            <button
                                                title="Delete FreightForward"
                                                class="btn btn-sm padding_0"
                                                onClick={() => this.deleteExhFreightForwardList(data.id)}
                                            >
                                                <i class="fa fa-trash action_btn_table"></i>
                                            </button>
                                        }
                                    </td>
                                    <td >
                                        <input className="form-control"
                                            style={{ width: '600px' }}
                                            type="text"
                                            value={data.MainParticularsName}
                                            onChange={(e) => this.onInputChange(e, i, 'MainParticularsName')}
                                        />
                                    </td>
                                    <td>
                                        <input className="form-control"
                                            style={{ width: '700px' }}
                                            type="text"
                                            value={data.Particulars}
                                            onChange={(e) => this.onInputChange(e, i, 'Particulars')}
                                        />
                                    </td>
                                    <td>
                                        <input className="form-control"
                                            style={{ width: '300px' }}
                                            type="text"
                                            value={data.Unit}
                                            onChange={(e) => this.onInputChange(e, i, 'Unit')}
                                        />
                                    </td>
                                    <td>
                                        <input className="form-control"
                                            style={{ width: '150px' }}
                                            type="text"
                                            value={data.RateINR}
                                            onChange={(e) => this.onInputChange(e, i, 'RateINR')}
                                        />
                                    </td>
                                    <td>
                                        <input className="form-control"
                                            style={{ width: '150px' }}
                                            type="text"
                                            value={data.RateUSD}
                                            onChange={(e) => this.onInputChange(e, i, 'RateUSD')}
                                        />
                                    </td>
                                    <td>
                                        <input className="form-control"
                                            style={{ width: '150px' }}
                                            type="text"
                                            value={data.RateINRByAir}
                                            onChange={(e) => this.onInputChange(e, i, 'RateINRByAir')}
                                        />
                                    </td>
                                    <td>
                                        <input className="form-control"
                                            style={{ width: '150px' }}
                                            type="text"
                                            value={data.RateINRBySea}
                                            onChange={(e) => this.onInputChange(e, i, 'RateINRBySea')}
                                        />
                                    </td>
                                    <td>
                                        <input className="form-control"
                                            style={{ width: '150px' }}
                                            type="text"
                                            value={data.RateUSDByAir}
                                            onChange={(e) => this.onInputChange(e, i, 'RateUSDByAir')}
                                        />
                                    </td>
                                    <td>
                                        <input className="form-control"
                                            style={{ width: '150px' }}
                                            type="text"
                                            value={data.RateUSDByAir}
                                            onChange={(e) => this.onInputChange(e, i, 'RateUSDByAir')}
                                        />
                                    </td>
                                </tr>
                            ))
                        ) : (<tr><td colSpan={6} className='text-center'>Record not found</td></tr>)
                    }
                </tbody>
            </table>
        )
    }


    render() {
        const { isLoading, exhFreightForwardList, currentPage } = this.state

        const recordsPerPage = 10
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        // Records to be displayed on the current page
        const currentRecords = exhFreightForwardList.slice(indexOfFirstRecord, indexOfLastRecord)
        const nPages = Math.ceil(exhFreightForwardList.length / recordsPerPage)

        return (
            <>

                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <AdminDrawer />


                    <div className="main-container p-2">
                        <div className="row" style={{ margin: 'auto' }}>
                            <div className="table-responsive mt-2">
                                {this.record(exhFreightForwardList)}
                            </div>
                            <div class="col-md-12 mt-2">
                                <button type="button" class="btn btn-primary rounded-0" style={{ float: 'right' }}
                                    onClick={() => this.insertUpdateExhFreightForwardDetails()}>
                                    Save
                                </button>
                            </div>
                            {/* <div className="mt-2 float-left">
                                {exhFreightForwardList.length > 0 ? <Pagination
                                    nPages={nPages}
                                    currentPage={currentPage}
                                    setCurrentPage={this.updateItem} /> : <></>}

                            </div> */}
                        </div>
                    </div>

                </>

            </>
        );
    }
}

export default withRouter(connect(null, { fill_action, insertUpdateExhFreightForward_action, getExhFreightForwardDetails_action })(Exh_freightForward));