import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action } from '../../../../../actions/fillCombo_action';
import { cityActionId, stallTypeActionId, stallListActionId, countryActionId, exhibitionActionId, toastError, toastSuccess } from "../../../../../utils/constants";
import { emailValidator } from "../../../../../utils/Validator";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import Pagination from "../../../../../utils/pagination";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import { useNavigate } from "react-router-dom";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import { data } from "jquery";
import { deleteHallMaster_action } from "../../../../../actions/admin_module_action/master_action/hall_master_action";
import { getRentalAudioVideoMasterList_action, insertUpdateRentalAudioVideoMaster_action, deleteRentalAudioVideoMaster_action } from "../../../../../actions/admin_module_action/master_action/manual_form_master_action/rental_audio_video_master_action";
import Footer from "../../../../../common_components/Footer";
import Resizer from "react-image-file-resizer";


class RentalAudioVideoMaster extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isOpen: false,
            // currentPage: 1,
            rentalAVList: [],
            deleteID: '',
        }

        this.record = this.record.bind(this)
        this.onInput = this.onInput.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.deleteRentalAudioVideoMaster = this.deleteRentalAudioVideoMaster.bind(this)
        this.onDelete = this.onDelete.bind(this)
        this.getRentalAudioVideoMasterList = this.getRentalAudioVideoMasterList.bind(this)
        this.onCrossClick = this.onCrossClick.bind(this)
        this.onFilePick = this.onFilePick.bind(this)
        this.onPdfSelect = this.onPdfSelect.bind(this)
        this.resizeFile = this.resizeFile.bind(this)

    }

    componentDidMount() {
        this.getRentalAudioVideoMasterList()
    }

    onFilePick = async (e, file, i) => {
        e.preventDefault()
        const { rentalAVList } = this.state
        console.log('file', file)
        if (file.length != 0) {
            if (file[0]['size'] >= 5 * 1024 * 1024) {
                toastError('File size more than 5mb are not allow.')
            } else {
                var image = '';
                if (file[0]['name'].toString().toLowerCase().includes('.jpeg') ||
                    file[0]['name'].toString().toLowerCase().includes('.png') ||
                    file[0]['name'].toString().toLowerCase().includes('.jpg')
                ) {
                    if (file[0]['name'].toString().toLowerCase().trim().includes('.jpg') ||
                        file[0]['name'].toString().toLowerCase().trim().includes('.png') ||
                        file[0]['name'].toString().toLowerCase().trim().includes('.jpeg')
                    ) {
                        image = await this.resizeFile(file[0]);
                        rentalAVList[i].img = image
                        this.setState({ rentalAVList: rentalAVList })
                    }
                    var fileType = file[0]['type']
                    var fileTypeList;
                    if (fileType.includes("/")) {
                        fileTypeList = fileType.split("/")

                    }
                    rentalAVList[i].imgType = fileTypeList.length > 0 ? fileTypeList[1] : ""
                    this.setState({ rentalAVList: rentalAVList })
                } else {
                    toastError('Only .jpeg, .png, .jpg files are allowed')
                }
            }
        }
    }

    onPdfSelect = (file) =>
        new Promise((resolve) => {
            let base64;
            // FileReader function for read the file.
            let fileReader = new FileReader();
            // Onload of file read the file content
            fileReader.onload = function (fileLoadedEvent) {
                base64 = fileLoadedEvent.target.result;
                // Print data in console
                resolve(base64);
            };
            // Convert data to base64
            fileReader.readAsDataURL(file);
        });

    resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                500,
                600,
                'JPEG',
                80,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64",
                300,
                300,
            );
        });

    onCrossClick(i) {
        const { rentalAVList } = this.state
        rentalAVList[i].img = ''
        this.setState({ rentalAVList: rentalAVList })
        document.getElementById(`path${i + 1}`).value = ""
    }

    onInput(e, value, i, flag) {
        const { rentalAVList } = this.state
        console.log(value, i, flag)
        if (flag == 'AgencyName') {
            rentalAVList[i].AgencyName = value
        } else if (flag == 'AgencyEmail') {
            rentalAVList[i].AgencyEmail = value
        } else if (flag == 'image') {
            this.onFilePick(e, value, i)
        }
        this.setState({ rentalAVList: rentalAVList })
    }

    record(list) {
        return (
            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
                <thead>
                    <tr>
                        <th className="text-center" style={{ width: '50px' }}>Sr no.</th>
                        <th className="text-center">Agency Name<span className="text-red">*</span></th>
                        <th className="text-center">Agency Email<span className="text-red">*</span></th>
                        <th className="text-center" style={{ width: '350px' }}>Image<span className="text-red">*</span></th>
                        <th className="text-center" style={{ width: '50px' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.length > 0 ? <>{
                            list.map((data, i) => (
                                <tr key={i}>
                                    <td className="text-center" style={{ width: '50px' }}>{i + 1}</td>
                                    <td className="text-center">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            value={data.AgencyName}
                                            onChange={(e) => this.onInput(e, e.target.value, i, 'AgencyName')}
                                        />
                                    </td>
                                    <td className="text-center">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            value={data.AgencyEmail}
                                            onChange={(e) => this.onInput(e, e.target.value, i, 'AgencyEmail')}
                                        />
                                    </td>
                                    <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="text-center">
                                        <div className="box">
                                            <div className="js--image-preview" />
                                            {
                                                data.AgencyRentalAudioVideoID == '' || data.AgencyRentalAudioVideoID == null ?
                                                    <div className="upload-options mt-1 ms-2">
                                                        <label>
                                                            <input
                                                                type="file"
                                                                id={`path${i + 1}`}
                                                                className="image-upload"
                                                                accept=".jpg, .jpeg, .png"
                                                                onChange={e => this.onInput(e, e.target.files, i, 'image')}
                                                            />
                                                            {
                                                                data.img != "" &&
                                                                <i class="fa fa-times text-danger img-can"
                                                                    onClick={() => this.onCrossClick(i)}></i>
                                                            }
                                                        </label>

                                                    </div>
                                                    :
                                                    <div style={{
                                                        border: '1px solid #ccc',
                                                        padding: '10px',
                                                        display: 'inline-flex',
                                                        alignItems: 'center',
                                                        // marginTop: '10px'
                                                    }}>
                                                        <a
                                                            href={data.AgencyRentalAudioVideoImage}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <span style={{ marginRight: '10px' }}
                                                            >{data.img}</span>
                                                            {/* <i className={`fa fa-times text-danger img-can ${isView ? 'disabled' : ''}`}
                                                                onClick={!isView ? (e) => this.onDocDelete() : undefined}
                                                            ></i> */}
                                                        </a>
                                                    </div>
                                            }
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        <button
                                            type="button"
                                            className="btn btn-outline-danger btn-sm"
                                            onClick={(e) => this.onDelete(data.AgencyRentalAudioVideoID, i, data.isNew)}
                                        >
                                            <i className="fa fa-trash action_btn_table" />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </> :
                            <tr><td colSpan={13} style={{ textAlign: 'center' }}></td></tr>
                    }
                </tbody>
            </table >)
    }

    getRentalAudioVideoMasterList() {
        this.setState({ isLoading: true })
        const { rentalAVList } = this.state
        this.props.getRentalAudioVideoMasterList_action()
            .then((data) => {
                if (data.error != 1) {
                    data.data.length > 0 && data.data.map((item) => {
                        item.isNew = false
                        var filePath = item['AgencyRentalAudioVideoImage']
                        var array = item['AgencyRentalAudioVideoImage'] != null && item['AgencyRentalAudioVideoImage'] != "" && filePath.split("/")
                        var FileName = array[array.length - 1]
                        item.img = FileName
                        item.imgPath = filePath
                        item.imgType = null
                    })
                    data.data.length > 0 ? this.setState({ rentalAVList: data.data }) : this.setState({
                        rentalAVList: [{
                            'AgencyName': '',
                            'AgencyEmail': '',
                            'img': '',
                            "imgType": '',
                            'imgPath': '',
                            'AgencyRentalAudioVideoID': null,
                            'isNew': true,
                        }]
                    })
                    this.setState({ isLoading: false })
                    console.log(data.data)
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    handleAdd() {
        const { rentalAVList } = this.state
        console.log("pp")
        var obj = {
            'AgencyName': '',
            'AgencyEmail': '',
            'img': '',
            "imgType": '',
            'imgPath': '',
            'AgencyRentalAudioVideoID': null,
            'isNew': true,
        }
        rentalAVList.push(obj)
        this.setState({ rentalAVList: rentalAVList })
    }

    handleSubmit() {
        const { rentalAVList } = this.state
        var arr = [...rentalAVList]
        var err = false
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].AgencyName == '' || arr[i].AgencyName == null) {
                err = 'Please enter mandatory fields'
                break
            }
            if (arr[i].img == '' || arr[i].img == null) {
                err = 'Please enter mandatory fields'
                break
            }
            if (arr[i].AgencyEmail == '' || arr[i].AgencyEmail == null) {
                err = 'Please enter mandatory fields'
                break
            } else {
                err = (emailValidator(arr[i].AgencyEmail) == '') ? false : emailValidator(arr[i].AgencyEmail)
                if (err) {
                    break
                }
            }
        }
        if (!err) {
            this.setState({ isLoading: true })
            this.props.insertUpdateRentalAudioVideoMaster_action(arr)
                .then((data) => {
                    if (data.error != 1) {
                        console.log(data.data)
                        this.getRentalAudioVideoMasterList()
                        this.setState({ isLoading: false, })
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            toastError(err)
        }
    }

    deleteRentalAudioVideoMaster(id) {
        this.setState({ isLoading: true })
        this.props.deleteRentalAudioVideoMaster_action(id)
            .then((data) => {
                if (data.error != 1) {
                    console.log(data.data)
                    this.setState({ isLoading: false })
                    toastSuccess("deleted successfully")
                    setTimeout(() => {
                        this.getRentalAudioVideoMasterList()
                    }, 200);
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
                this.setState({ isOpen: false })
            })
            .catch((err) => {
                console.log(err)
            })
    }


    onDelete(id, index, status) {
        const { rentalAVList } = this.state
        console.log(status, index)
        if (status) {
            rentalAVList.splice(index, 1)
            this.setState({ rentalAVList: rentalAVList })
        } else {
            this.setState({ deleteID: id })
            this.setState({ isOpen: true, })
        }
    }


    render() {
        const { isOpen, isLoading, rentalAVList } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <div className="app-content with-dr-container com-height">
                        <AdminDrawer />
                        <div className="row">
                            <div className="step-name">
                                <p>Rental Audio Video Master</p>
                            </div>
                        </div>
                        <div className="m-2">

                            <div className="table-responsive">
                                <div
                                    className="card-header p-3"
                                    style={{ paddingLeft: "7px !important" }}
                                >
                                    <h3 className="card-title" style={{ fontSize: 15 }}>
                                        Agency contact details
                                    </h3>
                                </div>
                                {this.record(rentalAVList)}
                            </div>
                        </div>
                        <div className="btn-group m-2 save-can d-block" >
                            <div className="btn-group" role="group" aria-label="Basic example">
                                <button type="button" className="btn btn-primary rounded-0 d-block" style={{ marginLeft: '5px', cursor: 'pointer' }}
                                    onClick={this.handleAdd}
                                >Add more</button>
                            </div>
                        </div>
                        <div className="btn-group m-2 save-can d-block" >
                            <div className="btn-group" role="group" aria-label="Basic example" style={{ float: 'right' }} >
                                <button type="button" className="btn btn-success rounded-0 d-block" data-dismiss="modal" style={{ marginLeft: '5px' }}
                                    onClick={this.handleSubmit} disabled={rentalAVList.length == 0}
                                >Submit</button>
                            </div>
                        </div>
                    </div>
                </>
                <Modal isOpen={isOpen} onRequestClose={() => this.setState({ isOpen: false })}>
                    <div className="modal-dialog" style={{ marginTop: '20%' }}>
                        <div className="modal-content confirm-logout">
                            <div class="modal-header text-right p-2">
                                <h5 class="modal-title modal_title_text text-danger">Do you really want to delete this record?</h5>

                                {/* <button type="button " class="close pop-close" data-dismiss="modal"
                                    onClick={() => this.setState({ isOpen: false })}
                                ><span aria-hidden="true" class="pop-cancel">&times;</span></button> */}
                            </div>
                            {/* <div class="modal-body">
                                <div class="row">
                                </div>
                            </div> */}
                            <div class="modal-footer p-1">
                                <div class="form-group text-right mod-fot">
                                    <button type="button" class="btn btn-del-pop"
                                        onClick={(e) => this.deleteRentalAudioVideoMaster(this.state.deleteID)}
                                    >Yes</button>
                                    <button type="button" class="btn btn-can-pop" data-dismiss="modal" style={{ marginLeft: '5px' }}
                                        onClick={() => this.setState({ isOpen: false })}
                                    >No</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
                <Footer />
            </>)
    }

}
export default withRouter(connect(null, { fill_action, getRentalAudioVideoMasterList_action, insertUpdateRentalAudioVideoMaster_action, deleteRentalAudioVideoMaster_action })(RentalAudioVideoMaster));