import { GET_ADMIN_DASHBOARD_DATA_ACTION_TYPE } from "../../../utils/constants";


const initialState = [];

function adminDashboardReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case GET_ADMIN_DASHBOARD_DATA_ACTION_TYPE:
            return [...state, payload];

        default:
            return state;
    }
};

export default adminDashboardReducer