import { axiosGet, axiosPost } from "../../../../../react_axios/Axios"
import { GET_MEETING_ROOM_DETAILS_ACTION_TYPE, getMeetingRoomDetailsUrl, INSERT_UPDATE_MEETING_ROOM_ACTION_TYPE, insertUpdateMeetingRoomUrl, headers } from "../../../../../utils/constants"

export const getMeetingRoomDetails_action = () => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhregistrationID: sessionStorage.getItem('ExhRegistrationIDSS')
        }
        // console.log("getMeetingRoomDetails_action ----->", params)
        const res = await axiosGet(getMeetingRoomDetailsUrl, params)

        dispatch({
            type: GET_MEETING_ROOM_DETAILS_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const insertUpdateMeetingRoom_action = (TotalAmount, Remark, Is_agree, MeetingRoomChekedList) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhregistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
            TotalAmount: TotalAmount,
            Remark: Remark != '' ? Remark : null,
            Is_agree: Is_agree,
            MeetingRoomChekedList: MeetingRoomChekedList
        }
        // console.log("insertUpdateMeetingRoom_action ----->", params)
        const res = await axiosPost(insertUpdateMeetingRoomUrl, params, headers)

        dispatch({
            type: INSERT_UPDATE_MEETING_ROOM_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}