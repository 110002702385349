import moment from "moment";
import { axiosGet, axiosPost } from "../../../react_axios/Axios";
import { GET_PROFORMA_INV_LIST_ACTION, GET_PROFORMA_PDF_ACTION_TYPE, getDuePaymentListUrl, GET_DUE_PAYMENT_LIST_ACTION_TYPE, fetchAuthReport, getProformaInvListUrl, SHARE_PROFORMA_INV_ACTION, proformaShareUrl, headers } from "../../../utils/constants";

export const proformaInvShare_action = (proformaListShare, flag) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem("sessionExhibitionID"),
            proformaListShare: proformaListShare,
            flag: flag
        }
        console.log("pendingReceiptDetails_action-Action--------------------------->", params)

        const res = await axiosPost(proformaShareUrl, params, headers);
        dispatch({
            type: SHARE_PROFORMA_INV_ACTION,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};


export const getProformaPdf_action = (ExhRegistrationID, countryid, InstallmentType, formID) => async (dispatch) => {
    try {
        var rptFileName = ''
        var formType = ''

        if (InstallmentType == "INST 1") {
            if (countryid == "91") {
                var rptFileName = "1stInstallment_India.rpt"
            } else {
                var rptFileName = "1stInstallment.rpt"
            }
        } else if (InstallmentType == "INST 2") {
            if (countryid == "91") {
                var rptFileName = "2ndInstallment_India.rpt"
            } else {
                var rptFileName = "2ndInstallment.rpt"
            }
        } else if (InstallmentType == "OpenSide") {
            // if (countryid == "91") {
            var rptFileName = "OpenSidespi.rpt"
            // } else {
            //     var rptFileName = "2ndInstallment.rpt"
            // }
        } else {
            var rptFileName = "ManualPI.rpt"
            if (InstallmentType == 'PW' || InstallmentType == 'POW') {
                formType = 'Power'
            } else if (InstallmentType == 'WT' || InstallmentType == 'WAT') {
                formType = 'Water'
            } else if (InstallmentType == 'AR' || InstallmentType == 'COM') {
                formType = 'Air'
            } else if (InstallmentType == 'BO') {
                formType = 'BRANDING OPPORTUNITY'
            } else if (InstallmentType == 'MR') {
                formType = 'Meeting Room'
            } else if (InstallmentType == 'EXB') {
                formType = 'Exhibitor Paid Badges'
            }
        }

        var list = [];
        var mapData = {}
        if (InstallmentType == "INST 1" || InstallmentType == "INST 2") {
            const mapdata1 = {
                "ParameterName": "@ExhRegistrationID",
                "ParameterValue": ExhRegistrationID,
                "ParameterDataType": "int"
            }
            list.push(mapdata1);
        } else if (InstallmentType == "OpenSide") {
            const mapdata1 = {
                "ParameterName": "@UserID",
                "ParameterValue": JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
                "ParameterDataType": "int"
            }
            list.push(mapdata1);
            const mapdata2 = {
                "ParameterName": "@ExhibitionID",
                "ParameterValue": sessionStorage.getItem("sessionExhibitionID"),
                "ParameterDataType": "int"
            }
            list.push(mapdata2);
            const mapdata3 = {
                "ParameterName": "@ExhRegistrationID",
                "ParameterValue": ExhRegistrationID,
                "ParameterDataType": "int"
            }
            list.push(mapdata3);
        } else {
            const mapdata1 = {
                "ParameterName": "@FormID",
                "ParameterValue": formID,
                "ParameterDataType": "int"
            }
            list.push(mapdata1);
            const mapdata2 = {
                "ParameterName": "@FormType",
                "ParameterValue": formType,
                "ParameterDataType": "VARCHAR"
            }
            list.push(mapdata2);
        }
        mapData = {
            "report_name": rptFileName,
            "parameter_details": list
        }
        const res = await axiosPost(fetchAuthReport, mapData);
        dispatch({
            type: GET_PROFORMA_PDF_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const proformaInvList_action = (exhibitor, proformaDate, proformaNo, FilterType) => async (dispatch) => {

    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem("sessionExhibitionID"),
            AccountID: JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID,
            Exhibitor: exhibitor,
            ProformaInvoiceDate: proformaDate != "" ? (moment(proformaDate).format('MM-DD-YYYY')) : null,
            ProformaInvoiceNo: proformaNo,
            FilterType: FilterType
        }
        console.log("proformaInvList_action -Action--------------------------->", params)

        const res = await axiosGet(getProformaInvListUrl, params, headers);
        dispatch({
            type: GET_PROFORMA_INV_LIST_ACTION,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }

}

export const getDuePaymentList_action = (exhibitor, proformaDate, proformaNo) => async (dispatch) => {

    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem("sessionExhibitionID"),
            AccountID: JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID,
            Exhibitor: exhibitor,
            ProformaInvoiceDate: proformaDate != "" ? (moment(proformaDate).format('MM-DD-YYYY')) : null,
            ProformaInvoiceNo: proformaNo
        }
        console.log("proformaInvList_action -Action--------------------------->", params)

        const res = await axiosGet(getDuePaymentListUrl, params, headers);
        dispatch({
            type: GET_DUE_PAYMENT_LIST_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }

}