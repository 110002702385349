import { EXHIBITOR_SPACE_BOOKING_EDIT_ACTION_TYPE, GET_EXHIBITOR_SPACEBOOKING_DETAILS_ACTION_TYPE, GET_SPACE_BOOKING_DETAILS_ACTION_TYPE, SPACE_BOOKING_EDIT_ACTION_TYPE } from "../../../utils/constants";


const initialState = [];

function exhibitorSpacebookingReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case GET_EXHIBITOR_SPACEBOOKING_DETAILS_ACTION_TYPE:
            return [...state, payload];
        case EXHIBITOR_SPACE_BOOKING_EDIT_ACTION_TYPE:
            return [...state, payload]

        default:
            return state;
    }
};

export default exhibitorSpacebookingReducer;