import { axiosGet, axiosPost } from "../../../react_axios/Axios";
import { getGstMasterDetailsUrl, getGSTmasterListUrl, insertUpdateGSTMasterUrl, deleteGSTMasterUrl, GET_GST_MASTER_DETAILS, GET_GST_MASTER_LIST, INSERT_UPDATE_GST_MASTER, DELETE_GST_MASTER, headers } from "../../../utils/constants";

export const getgstMasterList_action = (ExhibitionID, GstPanMasterID, GSTNo, PANNo) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: JSON.parse(sessionStorage.getItem("sessionExhibitionID")),
            GstPanMasterID: GstPanMasterID,
            GSTNo: GSTNo,
            PANNo: PANNo,
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getGSTmasterListUrl, params, headers)

        dispatch({
            type: GET_GST_MASTER_LIST,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const deleteGSTMaster_action = (GstPanMasterID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: JSON.parse(sessionStorage.getItem("sessionExhibitionID")),
            GstPanMasterID: GstPanMasterID

        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(deleteGSTMasterUrl, params, headers)

        dispatch({
            type: DELETE_GST_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const insertUpdateGSTMaster_action = (GstPanMasterID, GSTNo, PANNo) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: JSON.parse(sessionStorage.getItem("sessionExhibitionID")),
            GstPanMasterID: GstPanMasterID,
            GSTNo: GSTNo,
            PANNo: PANNo,
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosPost(insertUpdateGSTMasterUrl, params, headers)

        dispatch({
            type: INSERT_UPDATE_GST_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

// export const getHallMasterDetails_action = (HallId) => async (dispatch) => {
//     try {
//         const params = {
//             UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
//             ExhibitionID: JSON.parse(sessionStorage.getItem("sessionExhibitionID")),
//             HallId: HallId
//         }
//         console.log("getVisaInvitation_action Action------->", params)

//         const res = await axiosGet(getHallMasterDetailsUrl, params, headers)

//         dispatch({
//             type: GET_HALL_MASTER_DETAILS,
//             payload: res,
//         });
//         return Promise.resolve(res)
//     } catch (error) {
//         return Promise.reject(error)
//     }
// }