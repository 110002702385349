import { GET_STALL_POSSESSION_ACTION_TYPE } from "../../../../../utils/constants";

const initialState = [];

function stallPossessionReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case GET_STALL_POSSESSION_ACTION_TYPE:
            return [...state, payload];

        default:
            return state;
    }
};

export default stallPossessionReducer;