import { Component } from "react";
import Loader from "../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action } from '../../../../actions/fillCombo_action';
import { cityActionId, stallTypeActionId, stallListActionId, countryActionId, exhibitionActionId, toastError, toastSuccess } from "../../../../utils/constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import Pagination from "../../../../utils/pagination";
import AdminDrawer from "../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../common_components/header_with_logo";
import { useNavigate } from "react-router-dom";
import ExhibitorDrawer from "../../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../../common_components/exhibitor_module_header";
import { data } from "jquery";
import { getBankDetailsMasterList_action, insertUpdateBankDetailsMaster_action } from "../../../../actions/admin_module_action/master_action/bank_details_action";
import Footer from "../../../../common_components/Footer";


class BankDetailsAU extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            currentPage: 1,
            is_add: false,
            is_update: false,
            NAMEOFTHEBENEFICIARY: '',
            BankDetailID: '',
            CURRENTACCOUNTNO: '',
            BRANCHNAME: '',
            BANKNAME: '',
            SWIFTCODE: '',
            IFSCode: '',
        }

        this.getBankDetailsMasterDetails = this.getBankDetailsMasterDetails.bind(this)
        this.onPreviousClick = this.onPreviousClick.bind(this)
        this.insertBankDetailsMaster = this.insertBankDetailsMaster.bind(this)
        this.updateBankDetailsMaster = this.updateBankDetailsMaster.bind(this)
        this.onNextClick = this.onNextClick.bind(this)

    }

    componentDidMount() {
        this.setState({
            is_add: this.props.location.state != null && this.props.location.state.is_add != null ? this.props.location.state.is_add : false,
            is_update: this.props.location.state != null && this.props.location.state.is_update != null ? this.props.location.state.is_update : false,
            BankDetailID: this.props.location.state != null && this.props.location.state.BankDetailID != null ? this.props.location.state.BankDetailID : '',
        })
        setTimeout(() => {
            this.state.is_update && this.getBankDetailsMasterDetails()
        }, 100);
    }



    getBankDetailsMasterDetails() {
        this.setState({ isLoading: true })
        const { BankDetailID } = this.state
        this.props.getBankDetailsMasterList_action(null, BankDetailID, null, null, null, null)
            .then((data) => {
                if (data.error != 1) {
                    console.log(data.data[0][0])
                    this.setState({ NAMEOFTHEBENEFICIARY: data.data[0][0].BeneficiaryName, CURRENTACCOUNTNO: data.data[0][0].CurrentAccountNo, BANKNAME: data.data[0][0].BankName, BRANCHNAME: data.data[0][0].BranchName, SWIFTCODE: data.data[0][0].SwiftCode, IFSCode: data.data[0][0].IFSCode })
                    // this.setState({ exhibitionID: data.data[0][0].ExhibitionID })
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    onPreviousClick() {
        this.props.navigate('/bankDetailsList',)
    }

    insertBankDetailsMaster() {
        const { isLoading, NAMEOFTHEBENEFICIARY, BRANCHNAME, BANKNAME, SWIFTCODE, IFSCode, CURRENTACCOUNTNO } = this.state
        if (NAMEOFTHEBENEFICIARY != '' && NAMEOFTHEBENEFICIARY != null && BRANCHNAME != '' && BRANCHNAME != null && BANKNAME != '' && BANKNAME != null && SWIFTCODE != '' && SWIFTCODE != null && IFSCode != '' && IFSCode != null && CURRENTACCOUNTNO != '' && CURRENTACCOUNTNO != null) {
            this.setState({ isLoading: true })
            this.props.insertUpdateBankDetailsMaster_action(null, NAMEOFTHEBENEFICIARY, CURRENTACCOUNTNO, BANKNAME, BRANCHNAME, SWIFTCODE, IFSCode)
                .then((data) => {
                    if (data.error != 1) {
                        console.log(data.data)
                        this.props.navigate('/bankDetailsList',)
                        toastSuccess("added successfully")
                        this.setState({ isLoading: false })
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        else {
            toastError("Please enter mandatory fields")
        }
    }

    updateBankDetailsMaster() {
        const { isLoading, NAMEOFTHEBENEFICIARY, BRANCHNAME, BANKNAME, SWIFTCODE, IFSCode, BankDetailID, CURRENTACCOUNTNO } = this.state
        if (NAMEOFTHEBENEFICIARY != '' && NAMEOFTHEBENEFICIARY != null && BRANCHNAME != '' && BRANCHNAME != null && BANKNAME != '' && BANKNAME != null && SWIFTCODE != '' && SWIFTCODE != null && IFSCode != '' && IFSCode != null && CURRENTACCOUNTNO != '' && CURRENTACCOUNTNO != null && BankDetailID != '' && BankDetailID != null) {
            this.setState({ isLoading: true })
            this.props.insertUpdateBankDetailsMaster_action(BankDetailID, NAMEOFTHEBENEFICIARY, CURRENTACCOUNTNO, BANKNAME, BRANCHNAME, SWIFTCODE, IFSCode)
                .then((data) => {
                    if (data.error != 1) {
                        console.log(data.data)
                        this.props.navigate('/bankDetailsList',)
                        toastSuccess("update successful")
                        this.setState({ isLoading: false })
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        else {
            toastError("Please enter mandatory fields")
        }
    }

    onNextClick() {
        const { is_update } = this.state;
        if (is_update == true) {
            this.updateBankDetailsMaster();
        } else {
            this.insertBankDetailsMaster();
        }
    }

    render() {
        const { isLoading, exhibitionID, currentPage, IFSCode, BANKNAME, CURRENTACCOUNTNO, BRANCHNAME, SWIFTCODE, NAMEOFTHEBENEFICIARY } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <div className="app-content with-dr-container com-height">
                        <AdminDrawer />
                        <div className="row">
                            <div className="step-name">
                                <p>{this.state.is_add == true ? 'Bank Details Add' : 'Bank Details Update'}</p>
                            </div>
                        </div>
                        <div className="m-3">
                            <div className="">
                                {
                                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 1 &&
                                    <div className="row ">
                                        <div className="col-md-4">
                                            <label>NAME OF THE BENEFICIARY<span className="text-red">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={NAMEOFTHEBENEFICIARY}
                                                onChange={(e) => this.setState({ NAMEOFTHEBENEFICIARY: e.target.value, })}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <label>CURRENT ACCOUNT NO.<span className="text-red">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={CURRENTACCOUNTNO}
                                                onChange={(e) => this.setState({ CURRENTACCOUNTNO: e.target.value, })}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <label>BANK NAME<span className="text-red">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={BANKNAME}
                                                onChange={(e) => this.setState({ BANKNAME: e.target.value, })}
                                            />
                                        </div>
                                        <div className="col-md-4 mt-3">
                                            <label>BRANCH NAME<span className="text-red">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={BRANCHNAME}
                                                onChange={(e) => this.setState({ BRANCHNAME: e.target.value, })}
                                            />
                                        </div>
                                        <div className="col-md-4 mt-3">
                                            <label>SWIFT CODE<span className="text-red">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={SWIFTCODE}
                                                onChange={(e) => this.setState({ SWIFTCODE: e.target.value, })}
                                            />
                                        </div>
                                        <div className="col-md-4 mt-3">
                                            <label>IFSC Code<span className="text-red">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={IFSCode}
                                                onChange={(e) => this.setState({ IFSCode: e.target.value, })}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                            < div className="row mt-2" >
                                <div className="col-md-12">
                                    <div className="main-container container-fluid p-0 mt-2 pt-0 pb-0">
                                        <div className="mt-1 mb-4">
                                            {
                                                sessionStorage.getItem("sessionExhRegistrationID") == null &&
                                                <button className="btn btn-previous" onClick={e => this.onPreviousClick()}>
                                                    {" "}
                                                    Back{" "}
                                                </button>
                                            }

                                            <button className="btn btn-next-bot mx-2" onClick={e => this.onNextClick()} >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </>
                <Footer />
            </>)
    }

}
export default withRouter(connect(null, { fill_action, getBankDetailsMasterList_action, insertUpdateBankDetailsMaster_action })(BankDetailsAU));