import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { toastError, toastSuccess, exhibitionActionId } from "../../../../../utils/constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import Pagination from "../../../../../utils/pagination";
import { fill_action } from '../../../../../actions/fillCombo_action';
import { getEmailConfigrationDetails_action, insertUpdateEmailConfigration_action } from '../../../../../actions/admin_module_action/master_action/exhibition_master_action/emailConfigration_action';

class Exh_EmailConfigration extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            ExihibitionID: '',
            exhibitionList: [],
            ExihibitionID: '',
            HostName: '',
            PortNo: '',
            UserName: '',
            Password: '',
            EmailConfigrationId: '',
            exh_CC: '',
            exh_BCC: '',
        }
    }

    componentDidMount() {
        this.getFillList(exhibitionActionId, '', '', '', '', '');
        this.setState({
            ExihibitionID: this.props.location.state.ExihibitionID != undefined ? (this.props.location.state != null && this.props.location.state.ExihibitionID != null ? this.props.location.state.ExihibitionID : '') : this.props.id != null && this.props.id != undefined ? this.props.id : '',
        })
        setTimeout(() => {
            console.log(this.state.ExihibitionID)

            this.getEmailConfigrationDetails()
        }, 200);
    }

    getFillList(actionID, exhibitionID, moduleID, keyValue, value, exhRegistrationID) {
        const { stallAreaId } = this.state
        this.setState({ isLoading: true })
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value, exhRegistrationID)
            .then((data) => {
                if (data.error != 1) {
                    //exhibition
                    if (actionID === exhibitionActionId) {
                        console.log("Stall Posession Status list data", data.data)
                        this.setState({
                            exhibitionList: data.data.length > 0 ? data.data : [],
                            isLoading: false
                        })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    updateItem(item) {
        this.setState({ currentPage: item });
    }

    getEmailConfigrationDetails() {
        const { ExihibitionID, EmailConfigrationId } = this.state
        this.setState({ isLoading: true })
        this.props.getEmailConfigrationDetails_action(ExihibitionID, EmailConfigrationId)
            .then((data) => {
                if (data.error != 1) {
                    if (data.data != '') {
                        this.setState({
                            HostName: data.data[0]['HostName'],
                            PortNo: data.data[0]['PortNo'],
                            UserName: data.data[0]['User'],
                            Password: data.data[0]['Password'],
                            EmailConfigrationId: data.data[0]['EmailConfigrationId'],
                            exh_CC: data.data[0]['exh_CC'],
                            exh_BCC: data.data[0]['exh_BCC'],
                        })
                    }
                    this.setState({ isLoading: false, timelineList: data.data })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    insertUpdateEmailConfigration() {
        const { ExihibitionID, EmailConfigrationId, HostName, PortNo, UserName, Password, exh_CC, exh_BCC } = this.state
        this.setState({ isLoading: true })
        var isValid = false
        if (exh_CC != null && exh_CC != '') {
            const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
            const emailArray = exh_CC.split(";").map((email) => email.trim());
            isValid = emailArray.every((email) => emailRegex.test(email))
        } else {
            var isValid = true
        }
        var isValid2 = false
        if (exh_BCC != null && exh_BCC != '') {
            const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
            const emailArray = exh_BCC.split(";").map((email) => email.trim());
            isValid2 = emailArray.every((email) => emailRegex.test(email))
        } else {
            var isValid2 = true
        }
        console.log("isValid--->", isValid)
        console.log("isValid2--->", isValid2)

        if (HostName != '' && PortNo != '' && UserName != '' && Password != '') {
            if (isValid) {
                if (isValid2) {
                    this.props.insertUpdateEmailConfigration_action(ExihibitionID, EmailConfigrationId, HostName, PortNo, UserName, Password, exh_CC, exh_BCC)
                        .then((data) => {
                            if (data.error != 1) {
                                toastSuccess("Updated successfully!")
                                this.getEmailConfigrationDetails()
                            }
                            else {
                                this.setState({ isLoading: false })
                                toastError(data.msg)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                } else {
                    toastError("Please Enter Proper BCC Email Id's")
                    this.setState({ isLoading: false })
                }
            } else {
                toastError("Please Enter Proper CC Email Id's")
                this.setState({ isLoading: false })
            }
        } else {
            toastError("Please fill mendetory feilds")
            this.setState({ isLoading: false })
        }
    }


    render() {
        const { isLoading, HostName, PortNo, UserName, Password, exh_CC, exh_BCC } = this.state

        return (
            <>

                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <AdminDrawer />


                    <div className="main-container p-2 mt-3">
                        <div className="row">
                            <div className="row">
                                <div className="col-md-6">
                                    <label htmlFor="validationCustom03">Host Name<span class="required">*</span></label>
                                    <input className="form-control"
                                        value={HostName}
                                        onChange={(e) => this.setState({ HostName: e.target.value })}
                                        type="text" />
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="validationCustom03">Port Number<span class="required">*</span></label>
                                    <input className="form-control"
                                        value={PortNo}
                                        onChange={(e) => this.setState({ PortNo: e.target.value })}
                                        type="text" />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2" >
                            <div className="row">
                                <div className="col-md-6">
                                    <label htmlFor="validationCustom03">Email Id<span class="required">*</span></label>
                                    <input className="form-control"
                                        value={UserName}
                                        onChange={(e) => this.setState({ UserName: e.target.value })}
                                        type="text" />
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="validationCustom03">Password<span class="required">*</span></label>
                                    <input className="form-control"
                                        value={Password}
                                        onChange={(e) => this.setState({ Password: e.target.value })}
                                        type="text" />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2" >
                            <div className="row">
                                <div className="col-md-12">
                                    <label htmlFor="validationCustom03">CC <span className="required">{" "}(Enter ; between two Email id's)</span></label>
                                    <input className="form-control"
                                        value={exh_CC}
                                        onChange={(e) => this.setState({ exh_CC: e.target.value })}
                                        type="text" />
                                </div>
                                <div className="col-md-12 mt-2">
                                    <label htmlFor="validationCustom03">BCC {" "}<span className="required">{" "}(Enter ; between two Email id's)</span></label>
                                    <input className="form-control"
                                        value={exh_BCC}
                                        onChange={(e) => this.setState({ exh_BCC: e.target.value })}
                                        type="text" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <button type="button" class="btn btn-primary rounded-0 mt-4" style={{ float: 'right' }}
                                onClick={(e) => this.insertUpdateEmailConfigration()}>
                                Save
                            </button>
                        </div>
                    </div>

                </>

            </>
        );
    }
}

export default withRouter(connect(null, { fill_action, getEmailConfigrationDetails_action, insertUpdateEmailConfigration_action })(Exh_EmailConfigration));