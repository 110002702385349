import { axiosGet, axiosPost } from "../../../../react_axios/Axios";
import { getExhSeminarDetailsUrl, insertUpdateExhSeminarUrl, INSERT_UPDATE_EXH_SEMINAR, GET_EXH_SEMINAR_DETAILS, headers,DELETE_EXH_SEMINAR ,deleteExhSeminarUrl} from "../../../../utils/constants";


export const getExhSeminarDetails_action = (ExihibitionID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExihibitionID,
        }

        const res = await axiosGet(getExhSeminarDetailsUrl, params, headers)

        dispatch({
            type: GET_EXH_SEMINAR_DETAILS,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const insertUpdateExhSeminar_action = (ExihibitionID, ParentSeminarList, ChildSeminarList) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExihibitionID,
            ParentSeminarList: ParentSeminarList,
            ChildSeminarList: ChildSeminarList,
        }

        const res = await axiosPost(insertUpdateExhSeminarUrl, params, headers)

        dispatch({
            type: INSERT_UPDATE_EXH_SEMINAR,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const deleteExhSeminar_action = (SeminarID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            SeminarID: SeminarID,
        }

        const res = await axiosPost(deleteExhSeminarUrl, params, headers)

        dispatch({
            type: DELETE_EXH_SEMINAR,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}