import { Component } from "react";
import { withRouter } from "../../../../utils/withRouter";
import { connect } from "react-redux";
import Loader from "../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import HeaderWithLogo from "../../../../common_components/header_with_logo";
import AdminDrawer from "../../../../common_components/admin_module_drawer";
import { getDefaultFasciaData_action } from "../../../../actions/exhibitor_module_actions/manual_Forms_actions/forms_actions/fascia_form_action/fascia_form_action";
import { toastError, toastSuccess } from "../../../../utils/constants";
import Modal from "react-modal";
import { updateApproveFasciaForm_action } from "../../../../actions/admin_module_action/manual_forms_admin_action/fascia_admin_approval_action/fascia_approval_action";

class FasciaApproval extends Component {

    // Initialized Constructor 
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            fasciaFormID: "",
            exhibitorName: "",
            hallNo: "",
            stallNo: "",
            stallType: "",
            area: "",
            sidesOpen: "",
            fasciaName: "",
            isOpen: false,
            isRejectOpen: false,
            remark: "",
            status: "",
        }
        this.getDefaultData = this.getDefaultData.bind(this)
        this.onBackClick = this.onBackClick.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
        this.toggleDisapprovalModal = this.toggleDisapprovalModal.bind(this)
        this.updateApprovalFasciaForm = this.updateApprovalFasciaForm.bind(this)
    }

    componentDidMount() {
        this.setState({
            fasciaFormID: this.props.location.state != null && this.props.location.state.FormID ? this.props.location.state.FormID : '',
        })
        this.getDefaultData()
    }

    getDefaultData() {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props.getDefaultFasciaData_action()
            .then(data => {
                if (data.error != 1) {
                    console.log("Testing----------->")
                    this.setState({
                        isLoading: false,
                        exhibitorName: data.data[0]['ExhibitorName'],
                        hallNo: data.data[0]['HallNo'],
                        stallNo: data.data[0]['StallNo'],
                        stallType: data.data[0]['StallTypeID'],
                        area: data.data[0]['Area'],
                        sidesOpen: data.data[0]['OpenSides'],
                        fasciaName: data.data[0]['FasciaName'],
                        remark: data.data[0]['Remark'] != null ? data.data[0]['Remark'] : "",
                        status: data.data[0]['Status'] != null ? data.data[0]['Status'] : "",
                    })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch(err => { console.log(err) })
    }

    // On Back Click
    onBackClick() {
        this.props.navigate('/fasciaadmin')
    }

    // Toggle ApprovaL Modal
    toggleModal() {
        const { isOpen } = this.state
        this.setState({ isOpen: !isOpen })
    }

    // Toggle Disapproval Modal
    toggleDisapprovalModal() {
        const { isRejectOpen } = this.state
        this.setState({ isRejectOpen: !isRejectOpen })
    }

    // Update Approval Fascia Form
    updateApprovalFasciaForm(isApproved) {
        const { remark, fasciaFormID } = this.state
        if (isApproved == false ? remark != "" ? true : false : true) {
            console.log("testetetetet")
            this.setState({ isLoading: true })
            this.props.updateApproveFasciaForm_action(isApproved, remark, fasciaFormID)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({ isLoading: false })
                        toastSuccess('Fascia Form Approval Updated')
                        setTimeout(() => {
                            this.props.navigate('/fasciaadmin')
                        }, 1000);
                    } else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            toastError('Please Enter Remark')
        }
    }

    render() {
        const { isLoading, exhibitorName, stallNo, hallNo, stallType, area, sidesOpen, fasciaName, isOpen, isRejectOpen, remark, status } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                <AdminDrawer />
                <div className="main-content with-dr-container com-height">
                    <div className="">
                        <div className="row">
                            <div className="step-name">
                                <p>Fascia Update Approval</p>
                            </div>
                        </div>
                        <div className="p-2">
                            <div className="row mt-3 mb-4">
                                <div className="col-md-4">
                                    <label htmlFor="validationCustom03">Exhibitor Name</label>
                                    <input type="text" className="form-control"
                                        disabled
                                        value={exhibitorName}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="validationCustom03">Hall Name</label>
                                    <input type="text" className="form-control"
                                        disabled
                                        value={hallNo}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="validationCustom03">Stall No.</label>
                                    <input type="text" className="form-control"
                                        disabled
                                        value={stallNo}
                                    />
                                </div>
                                <div className="col-md-4 mt-2">
                                    <label htmlFor="validationCustom03">Stall Type</label>
                                    <input type="text" className="form-control"
                                        disabled
                                        value={stallType}
                                    />
                                </div>
                                <div className="col-md-4 mt-2">
                                    <label htmlFor="validationCustom03">Area</label>
                                    <input type="text" className="form-control"
                                        disabled
                                        value={area}
                                    />
                                </div>
                                <div className="col-md-4 mt-2">
                                    <label htmlFor="validationCustom03">Sides Open</label>
                                    <input type="text" className="form-control"
                                        disabled
                                        value={sidesOpen}
                                    />
                                </div>
                            </div>

                            <div className="row mt-1">
                                <div className="card">
                                    <div
                                        className="card-header fac-header"
                                        style={{ fontWeight: "bold" }}
                                    >
                                        Fascia name for Shell Scheme Exhibitors
                                        <span className="fac-header-inner text-primary">
                                            (Last Date of Submission- 24<sup>th</sup> Oct 2028)
                                        </span>
                                    </div>
                                    <div className="p-2" style={{ border: "1px solid #ececec" }}>
                                        <h5 className="text-center" style={{ fontWeight: 500 }}>
                                            The Following name is to Appear on the Front Fascia of Our Stand
                                        </h5>
                                        <p className="text-center">(Maximum 30 letters)</p>
                                        <h4 className="text-center border p-2" style={{ fontWeight: 500 }}>
                                            {fasciaName}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="row ">
                                {/* <div className="card">
                                    <div
                                        className="card-header fac-header"
                                        style={{ fontWeight: "bold" }}
                                    >
                                        Admin Section
                                    </div>
                                    <div className="p-2" style={{ border: "1px solid #ececec" }}>
                                        <fieldset className="d-flex mt-1">
                                            <label style={{ marginRight: 25 }}>Admin Approved:</label>
                                            <label htmlFor="yes" style={{ marginRight: 25 }}>
                                                {" "}
                                                <input
                                                    type="radio"
                                                    name="eq"
                                                    id="yes"
                                                    defaultValue="yes"
                                                    defaultChecked=""
                                                />{" "}
                                                Yes
                                            </label>
                                            <br />
                                            <label htmlFor="No" style={{ marginRight: 25 }}>
                                                {" "}
                                                <input type="radio" name="eq" id="no" defaultValue="no" /> No
                                            </label>
                                            <br />
                                        </fieldset>
                                        <div className="col-md-8 mt-3 p-0">
                                            <label htmlFor="validationCustom03">Reason to Disapprove</label>
                                            <textarea className="form-control" defaultValue={""} />
                                        </div>
                                    </div>

                                </div> */}
                                <div className="btn-group w-auto">
                                    <button className="btn btn-primary m-1 rounded-0"
                                        disabled={status == "Disapproved" || status == "Approved" ? true : false}
                                        onClick={(e) => this.toggleModal()}
                                    >
                                        Approve
                                    </button>
                                    <button className="btn btn-primary m-1 rounded-0"
                                        disabled={status == "Disapproved" || status == "Approved" ? true : false}
                                        onClick={(e) => this.toggleDisapprovalModal()}
                                    >
                                        Disapprove
                                    </button>
                                    <button className="btn btn-primary m-1 rounded-0"

                                        onClick={(e) => this.onBackClick()}
                                    >
                                        Back
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <style
                        dangerouslySetInnerHTML={{
                            __html:
                                "\n    .fac-header{\n            background: #ececec;\n    padding: 12px;\n    position: revert;\n    }\n    .fac-header ul{\n        border: 1px solid #ececec !important;\n    }\n    .fac-header-inner{\n        position: absolute;\n        right: 30px;\n    font-weight: normal;\n    }\n"
                        }}
                    />
                </div>
                <Modal isOpen={isOpen} onRequestClose={() => this.setState({ isOpen: false })}>
                    <div className="modal-dialog">
                        <div className="modal-content confirm-logout">
                            <div class="modal-header p-2">
                                <h5 class="modal-title modal_title_text text-danger">Confirm</h5>
                                <button type="button " class="close pop-close" data-dismiss="modal"
                                    onClick={() => this.setState({ isOpen: false })}
                                ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <p class="pop_label">Do you really want to Approve ?</p>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer p-1">
                                <div class="form-group text-right mod-fot">
                                    <button type="button" class="btn btn-del-pop"
                                        onClick={(e) => this.updateApprovalFasciaForm(true)}
                                    >Yes</button>
                                    <button type="button" class="btn btn-can-pop" data-dismiss="modal" style={{ marginLeft: '5px' }}
                                        onClick={() => this.setState({ isOpen: false })}
                                    >No</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
                <Modal isOpen={isRejectOpen} onRequestClose={() => this.setState({ isRejectOpen: false })}>
                    <div className="modal-dialog">
                        <div className="modal-content confirm-logout">
                            <div class="modal-header p-2">
                                <h5 class="modal-title modal_title_text text-danger">Confirm</h5>
                                <button type="button " class="close pop-close" data-dismiss="modal"
                                    onClick={() => this.setState({ isRejectOpen: false })}
                                ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        {/* <p class="pop_label">Do you really want to Approve ?</p> */}
                                        <label htmlFor="validationCustom03">Remark</label>
                                        <textarea className="form-control"
                                            type="text"
                                            placeholder="Please Enter Remark...."
                                            value={remark}
                                            onChange={(e) => this.setState({ remark: e.target.value })}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer p-1">
                                <div class="form-group text-right mod-fot">
                                    <button type="button" class="btn btn-del-pop"
                                        onClick={(e) => this.updateApprovalFasciaForm(false)}
                                    >Yes</button>
                                    <button type="button" class="btn btn-can-pop" data-dismiss="modal" style={{ marginLeft: '5px' }}
                                        onClick={() => this.setState({ isRejectOpen: false })}
                                    >No</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
            </>
        )
    }
}
export default withRouter(connect(null, { getDefaultFasciaData_action, updateApproveFasciaForm_action })(FasciaApproval))