import { axiosGet, axiosPost } from "../../../../react_axios/Axios";
import { headers, getLeadRetrivalConnectionMasterListUrl, deleteLeadRetrivalConnectionMasterUrl, insertUpdateLeadRetrivalConnectionMasterUrl, GET_LEAD_RETRIVAL_CONNECTION_MASTER_LIST, DELETE_LEAD_RETRIVAL_CONNECTION_MASTER, INSERT_UPDATE_LEAD_RETRIVAL_CONNECTION_MASTER } from "../../../../utils/constants";


export const insertUpdateLeadRetrivalConnectionMaster_action = (LeadRetrivalConnectionList) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            LeadRetrivalConnectionList: LeadRetrivalConnectionList
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosPost(insertUpdateLeadRetrivalConnectionMasterUrl, params, headers)

        dispatch({
            type: INSERT_UPDATE_LEAD_RETRIVAL_CONNECTION_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getLeadRetrivalConnectionMasterList_action = () => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getLeadRetrivalConnectionMasterListUrl, params, headers)

        dispatch({
            type: GET_LEAD_RETRIVAL_CONNECTION_MASTER_LIST,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const deleteLeadRetrivalConnectionMaster_action = (deleteId) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            LeadRetrievalContactPersonID: deleteId
        }
        console.log("deleteExhibitionDocument_action Action------->", params)

        const res = await axiosGet(deleteLeadRetrivalConnectionMasterUrl, params, headers)

        dispatch({
            type: DELETE_LEAD_RETRIVAL_CONNECTION_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}