import React from "react";


const Loader = () => {
  return (
    <>
          <div class="loader_class">
              <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>
    </>
  );
}

export default Loader;