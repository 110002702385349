import { axiosGet, axiosPost } from "../../../../../react_axios/Axios"
import { GET_SECURITY_APPLICATION_DETAILS_ACTION_TYPE, INSERT_UPDATE_SECURITY_APPLICATION_ACTION_TYPE,getSecurityApplicationDetailsUrl,insertUpdateSecurityApplicationUrl, headers } from "../../../../../utils/constants"


export const getSecurityApplicationDetails_action = () => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhregistrationID: sessionStorage.getItem('ExhRegistrationIDSS')
        }
        // console.log("getMeetingRoomDetails_action ----->", params)
        const res = await axiosGet(getSecurityApplicationDetailsUrl, params, headers)

        dispatch({
            type: GET_SECURITY_APPLICATION_DETAILS_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const insertUpdateSecurityApplication_action = (remark, is_Agree, SecurityApplicationList) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhregistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
            Remark: remark,
            Is_Agree: is_Agree,
            SecurityApplicationList: SecurityApplicationList
        }
        // console.log("getMeetingRoomDetails_action ----->", params)
        const res = await axiosPost(insertUpdateSecurityApplicationUrl, params)

        dispatch({
            type: INSERT_UPDATE_SECURITY_APPLICATION_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}