import { async } from "q"
import { axiosGet, axiosPost } from "../../../react_axios/Axios"
import { GET_SPACE_BOOKING_DETAILS_ACTION_TYPE, SPACE_BOOKING_EDIT_ACTION_TYPE, editSpaceBookingUrl, headers, getSpaceBookingDetailsUrl } from "../../../utils/constants"



export const getDetails_spaceBooking_action = () => async (dispatch) => {
    try {
        const params = {
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhregistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
            AccountID: JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID,
            Token: sessionStorage.getItem('tokenSS'),
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ServiceID: sessionStorage.getItem('ServiceIDSS')
        }
        console.log("Space Booking Action ----->", params)
        const res = await axiosGet(getSpaceBookingDetailsUrl, params, headers)

        dispatch({
            type: GET_SPACE_BOOKING_DETAILS_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const edit_spaceBoking_action = (isCorrespondence, exbRegNo, regCountryId, billCountryId, regCompany, fasciaName, regAdd1, regAdd2, regCity, regState, regPin, regCountry,
    preFixPersonInch, personInchFirst, personInchLast, regDesignation, regMob1, regMob2, regTel, regTel1, regTelFax,
    regPrimaryEmail, regSecondaryEmail, regWeb, preOwnerNameFirst, ownerNameFirst, ownerNameLast, ownerDesignation, ownerMob,
    ownerEmail, billCompany, billAdd1, billAdd2, billCity, billState, billPin, billCountry, preFixBillPersonInch,
    billPersonInchFirst, billPersonInchLast, billDesignation, billMob1, billMob2, billTel, billTel1, billTelFax,
    billPrimaryEmail, billSecondaryEmail, billWeb, panName, panNo, tanNo, gstNo, companyRegNo, iecCode,
    accName, accNo, accType, bankName, branchName, ifsc, primaryChapterId, chapterList, isDoubleStorey, DoubleStoreyArea, isSSICertified, TMMA_ITAMMA, isSSICertifiedFile, TMMA_ITAMMAFile, isSSICertifiedFiletype, TMMA_ITAMMAfileType, ssiFilepath, TMMA_ITAMMAfilepath) => async (dispatch) => {

        try {
            const mapData = {
                UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
                ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
                ExhRegistrationNo: exbRegNo,
                ExhRegistrationDate: sessionStorage.getItem('ExhibitionToDateSS'),

                CompanyName: regCompany,
                FasciaName: fasciaName,
                Address1: regAdd1,
                Address2: regAdd2,
                City: regCity,
                State: regState,
                Pin: regPin,
                CountryID: regCountryId,

                Title_PersonIncharge: preFixPersonInch,
                PersonIncharge: personInchFirst,
                lastNamePersonIncharge: personInchLast,
                Designation: regDesignation,
                MobileNo: `${sessionStorage.getItem('sessionCountryNamePreRegisterMobileNo')}-${regMob1}`,
                AlternateMobileNo: `${sessionStorage.getItem('sessionCountryNamePreRegisterAlternateMobileNo')}-${regMob2}`,
                Telephone: `${sessionStorage.getItem('sessionregisterTelephoneNoPre')}-${regTel}`,
                Telephone1: `${sessionStorage.getItem('sessionregisterTelephoneNo1Pre')}-${regTel1}`,
                TeleFax: regTelFax,
                PrimaryEmail: regPrimaryEmail,
                SecondaryEmail: regSecondaryEmail,
                Website: regWeb,

                Title_Name_Of_CEO_MD_President_Owner: preOwnerNameFirst,
                Name_Of_CEO_MD_President_Owner: ownerNameFirst,
                LastName: ownerNameLast,
                OwnerDesignation: ownerDesignation,
                Mobile: `${sessionStorage.getItem('sessionCountryNamePreOwnerMobileNo')}-${ownerMob}`,
                Email: ownerEmail,
                SameAsCorrespondence: isCorrespondence,
                InvCompanyName: billCompany,
                InvAddress1: billAdd1,
                InvAddress2: billAdd2,
                InvCity: billCity,
                InvState: billState,
                InvPin: billPin,
                InvCountryID: billCountryId,
                Title_InvPersonIncharge: preFixBillPersonInch,
                InvPersonIncharge: billPersonInchFirst,
                InvLastNamePersonIncharge: billPersonInchLast,
                InvDesignation: billDesignation,
                InvMobileNo: `${sessionStorage.getItem('sessionCountryNamePreInvoiceregisterMobileNo')}-${billMob1}`,
                InvAlternateMobileNo: `${sessionStorage.getItem('sessionCountryNamePreInvoiceregisterAlternateMobileNo')}-${billMob2}`,
                InvTelephone: `${sessionStorage.getItem('sessioninvoiceregisterTelephoneNoPre')}-${billTel}`,
                InvTelephone1: `${sessionStorage.getItem('sessioninvoiceregisterTelephoneNo1Pre')}-${billTel1}`,
                InvTeleFax: billTelFax,
                InvPrimaryEmail: billPrimaryEmail,
                InvSecondaryEmail: billSecondaryEmail,
                InvWebsite: billWeb,
                NameOnPanCard: panName,
                PanNo: panNo,
                Tan: tanNo,
                GSTNo: gstNo,
                CompanyRegistrationNo: companyRegNo,
                IECCode: iecCode,
                AccountName: accName,
                AccountNo: accNo,
                AccountType: accType,
                BankName: bankName,
                BranchName: branchName,
                SwiftCode_IFSCCode: ifsc,
                StatusID: 4,
                ExhRegistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
                AcccountID: sessionStorage.getItem('AccountIDSS'),
                PrimaryChapter: primaryChapterId,
                chapterList: chapterList,

                isDoubleStorey: isDoubleStorey,
                DoubleStoreyArea: DoubleStoreyArea,
                isSSICertified: isSSICertified,
                TMMA_ITAMMA: TMMA_ITAMMA,
                isSSICertifiedFile: isSSICertifiedFile,
                TMMA_ITAMMAFile: TMMA_ITAMMAFile,
                isSSICertifiedFiletype: isSSICertifiedFiletype,
                TMMA_ITAMMAfileType: TMMA_ITAMMAfileType,
                ssiFilepath: ssiFilepath,
                TMMA_ITAMMAfilepath: TMMA_ITAMMAfilepath,
            }
            console.log("Space Booking Action ----->", mapData)
            const res = await axiosPost(editSpaceBookingUrl, mapData, headers)

            dispatch({
                type: SPACE_BOOKING_EDIT_ACTION_TYPE,
                payload: res,
            });
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }

    }