import { axiosGet, axiosPost } from "../../../../../react_axios/Axios"
import { GET_HOUSE_KEEPING_DEFAULT_DETAILS_ACTION_TYPE, GET_HOUSE_KEEPING_DETAILS_ACTION_TYPE, INSERT_UPDATE_HOUSE_KEEPING_ACTION_TYPE, getHouseKeepingDefaultDetailsUrl, getHouseKeepingDetailsUrl, insertUpdateHouseKeepingUrl, headers } from "../../../../../utils/constants"

export const getHouseKeepingDefaultDetails_action = () => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhregistrationID: sessionStorage.getItem('ExhRegistrationIDSS')
        }
        // console.log("getMeetingRoomDetails_action ----->", params)
        const res = await axiosGet(getHouseKeepingDefaultDetailsUrl, params)

        dispatch({
            type: GET_HOUSE_KEEPING_DEFAULT_DETAILS_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getHouseKeepingDetails_action = () => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhregistrationID: sessionStorage.getItem('ExhRegistrationIDSS')
        }
        // console.log("getMeetingRoomDetails_action ----->", params)
        const res = await axiosGet(getHouseKeepingDetailsUrl, params, headers)

        dispatch({
            type: GET_HOUSE_KEEPING_DETAILS_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const insertUpdateHouseKeeping_action = (remark, is_Agree, Quantity) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhregistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
            Remark: remark,
            Is_Agree: is_Agree,
            Quantity: Quantity
        }
        // console.log("getMeetingRoomDetails_action ----->", params)
        const res = await axiosPost(insertUpdateHouseKeepingUrl, params)

        dispatch({
            type: INSERT_UPDATE_HOUSE_KEEPING_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}