import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { getDelegateMeetingMasterList_action, insertUpdateDelegateMeetingMaster_action, deleteDelegateMeetingMaster_action } from '../../../../../actions/admin_module_action/master_action/manual_form_master_action/delegate_meeting_master_action'
import { toastError, toastSuccess } from "../../../../../utils/constants";
import Modal from "react-modal";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import Footer from "../../../../../common_components/Footer";
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import moment from "moment";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";



class DelegateMeetingMasterList extends Component {

    // Initialize Constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isOpen: false,
            // currentPage: 1,
            DelegateMeetingMasterList: [],
            DelegateTimeslotmasterList: [],
            deleteID: '',
            deleteType: '',
        }
        this.getDelegateMeetingMasterList = this.getDelegateMeetingMasterList.bind(this)
        this.deleteDelegateMeetingMaster = this.deleteDelegateMeetingMaster.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.onDelete = this.onDelete.bind(this)
    }

    componentDidMount() {
        this.getDelegateMeetingMasterList()
    }

    getDelegateMeetingMasterList(status) {
        this.setState({ isLoading: true })
        this.props.getDelegateMeetingMasterList_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log(data.data)
                    this.setState({ DelegateMeetingMasterList: data.data[1] })
                    this.setState({ DelegateTimeslotmasterList: data.data[0] })
                    this.setState({ isLoading: false })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    deleteDelegateMeetingMaster(id) {
        const { deleteType } = this.state
        this.setState({ isLoading: true })
        this.props.deleteDelegateMeetingMaster_action(deleteType == 1 ? id : null, deleteType == 2 ? id : null)
            .then((data) => {
                if (data.error != 1) {
                    console.log(data.data)
                    this.setState({ isLoading: false, deleteType: '' })
                    toastSuccess("deleted successfully")
                    setTimeout(() => {
                        this.getDelegateMeetingMasterList()
                    }, 200);
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
                this.setState({ isOpen: false })
            })
            .catch((err) => {
                console.log(err)
            })
    }


    // Handle Add 
    handleAdd(status) {
        this.props.navigate('/DelegateMeetingMasterAU', { state: { status: status } })
    }

    onDelete(id, index, status) {
        const { DelegateMeetingMasterList } = this.state
        console.log(status, index)
        this.setState({ deleteID: id, deleteType: status })
        this.setState({ isOpen: true, })
    }

    render() {
        const { isLoading, isOpen, DelegateMeetingMasterList, DelegateTimeslotmasterList } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <div className="app-content with-dr-container com-height">
                        <AdminDrawer />
                        <div className="row">
                            <div className="step-name">
                                <p>Delegate Meeting Master</p>
                            </div>
                        </div>
                        <div className="m-2">
                            <div className="table-responsive">
                                <div
                                    className="card-header p-3"
                                    style={{ paddingLeft: "7px !important" }}
                                >
                                    <h3 className="card-title" style={{ fontSize: 15 }}>
                                        Agency contact and bank details
                                    </h3>
                                </div>
                                <div className="table-responsive ">
                                    <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1">
                                        <thead>
                                            <tr>
                                                <th className="text-center">Person Incharge</th>
                                                <th className="text-center">Designation</th>
                                                <th className="text-center">Country</th>
                                                <th className="text-center">Company Profile</th>
                                                <th className="text-center">Industry Segment</th>
                                                <th className="text-center">Interest</th>
                                                <th className="text-center">Purpose of Visit</th>
                                                <th style={{ width: '60px' }} className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                DelegateMeetingMasterList.length > 0 ? (DelegateMeetingMasterList.map((data, i) => (
                                                    <tr key={data.DelegateMeetingID}>
                                                        <td className="text-center">{data.PersonInchargeName}</td>
                                                        <td className="text-center">{data.Designation}</td>
                                                        <td className="text-center">{data.countryname}</td>
                                                        <td className="text-center">{data.CompanyProfile}</td>
                                                        <td className="text-center">{data.IndustrySegment}</td>
                                                        <td className="text-center">{data.Interest}</td>
                                                        <td className="text-center">{data.PurposeOfVisit}</td>
                                                        <td className="text-center">
                                                            <button
                                                                type="button"
                                                                className="btn btn-outline-danger btn-sm"
                                                                onClick={(e) => this.onDelete(data.DelegateMeetingID, i, 1)}
                                                            >
                                                                <i className="fa fa-trash action_btn_table" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))) : <tr><td className="text-center" colSpan={20}>No record found</td></tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <button
                                    type="button"
                                    className="btn btn-primary rounded-0 d-block mt-4"
                                    style={{ marginRight: 10, float: "right" }}
                                    onClick={() => this.handleAdd(1)}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                        <div className="m-2">
                            <div className="table-responsive">
                                <div
                                    className="card-header p-3"
                                    style={{ paddingLeft: "7px !important" }}
                                >
                                    <h3 className="card-title" style={{ fontSize: 15 }}>
                                        Meeting Details
                                    </h3>
                                </div>
                                <div className="table-responsive ">
                                    <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1">
                                        <thead>
                                            <tr>
                                                <th className="text-center">Meeting Date</th>
                                                <th className="text-center">Meeting From Time</th>
                                                <th className="text-center">Meeting To Time</th>
                                                <th style={{ width: '60px' }} className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                DelegateTimeslotmasterList.length > 0 ? (DelegateTimeslotmasterList.map((data, i) => (
                                                    <tr key={data.DelegateTimeSlotID}>
                                                        <td className="text-center">{moment(data.MeetingDate).format('DD-MM-YYYY')}</td>
                                                        <td className="text-center">{moment(data.MeetingFromTime).format('HH:mm')}</td>
                                                        <td className="text-center">{moment(data.MeetingToTime).format('HH:mm')}</td>
                                                        <td className="text-center">
                                                            <button
                                                                type="button"
                                                                className="btn btn-outline-danger btn-sm"
                                                                onClick={(e) => this.onDelete(data.DelegateTimeSlotID, i, 2)}
                                                            >
                                                                <i className="fa fa-trash action_btn_table" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))) : <tr><td className="text-center" colSpan={20}>No record found</td></tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <button
                                    type="button"
                                    className="btn btn-primary rounded-0 d-block mt-4"
                                    style={{ marginRight: 10, float: "right" }}
                                    onClick={() => this.handleAdd(2)}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                </>
                <Modal isOpen={isOpen} onRequestClose={() => this.setState({ isOpen: false })}>
                    <div className="modal-dialog" style={{ marginTop: '20%' }}>
                        <div className="modal-content confirm-logout">
                            <div class="modal-header text-right p-2">
                                <h5 class="modal-title modal_title_text text-danger">Do you really want to delete this record?</h5>

                                {/* <button type="button " class="close pop-close" data-dismiss="modal"
                                    onClick={() => this.setState({ isOpen: false })}
                                ><span aria-hidden="true" class="pop-cancel">&times;</span></button> */}
                            </div>
                            {/* <div class="modal-body">
                                <div class="row">
                                </div>
                            </div> */}
                            <div class="modal-footer p-1">
                                <div class="form-group text-right mod-fot">
                                    <button type="button" class="btn btn-del-pop"
                                        onClick={(e) => this.deleteDelegateMeetingMaster(this.state.deleteID)}
                                    >Yes</button>
                                    <button type="button" class="btn btn-can-pop" data-dismiss="modal" style={{ marginLeft: '5px' }}
                                        onClick={() => this.setState({ isOpen: false })}
                                    >No</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
                <Footer />
            </>
        )
    }
}

export default withRouter(connect(null, { getDelegateMeetingMasterList_action, insertUpdateDelegateMeetingMaster_action, deleteDelegateMeetingMaster_action })(DelegateMeetingMasterList));