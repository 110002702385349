import { axiosGet, axiosPost } from "../../../react_axios/Axios";
import { CREATE_TAX_INVOICE_ACTION_TYPE, SEND_NO_DUES_ACTION_TYPE, GET_STALL_POSSESSION_EXPORT_ACTION_TYPE, TAX_INVOICE_DETAILS_ACTION_TYPE, TAX_INVOICE_LIST_ACTION_TYPE, TAX_INVOICE_MAIL_ACTION_TYPE, createTaxInvoiceUrl, headers, sendNoDuesUrl, getAccountExhibitorListUrl, getStallPossessionExportUrl, GET_ACCOUNT_EXHIBITOR_LIST_ACTION_TYPE, taxInvoiceDetailsUrl, taxInvoiceMailUrl, fetchAuthReport, DOWNLOAD_NODUE, NoDuesCertificateDoc, taxinvoiceListUrl } from "../../../utils/constants";



export const taxInvoiceList_action = (exhibitorCompany, ReferenceNo, taxInvoiceNo) => async (dispatch) => {
    try {
        const params = {
            ExhRegistrationNo: ReferenceNo,
            ExhibitionName: exhibitorCompany,
            TaxInvoice_No: taxInvoiceNo,
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem("sessionExhibitionID"),

        }
        console.log("taxInvoiceList_action Action------->", params)
        const res = await axiosGet(taxinvoiceListUrl, params, headers)
        dispatch({
            type: TAX_INVOICE_LIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const download_no_due_action = (ExhibitionID, ExhRegistrationID) => async (dispatch) => {
    try {
        var list = [];
        const mapdata1 = {
            "ParameterName": "@ExhibitionID",
            "ParameterValue": parseInt(ExhibitionID),
            "ParameterDataType": "int"
        };
        const mapdata2 = {
            "ParameterName": "@ExhRegistrationID",
            "ParameterValue": parseInt(ExhRegistrationID),
            "ParameterDataType": "int"
        };
        list.push(mapdata1);
        list.push(mapdata2);
        const mapData = {
            "report_name": NoDuesCertificateDoc,
            "parameter_details": list
        };
        console.log("xxxxxxxxxxxxxxxxxxxxx", mapData)
        const res = await axiosPost(fetchAuthReport, mapData);


        dispatch({
            type: DOWNLOAD_NODUE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const accountExhibitorList_action = (ExhibitionID, exhibitor, exhibitorTypeID, PaymentTypeID, PersonIncharge, stallPosessionStatusId) => async (dispatch) => {
    try {
        const params = {
            ExhibitionID: ExhibitionID != '' && ExhibitionID != null ? ExhibitionID : sessionStorage.getItem("sessionExhibitionID"),
            ExhibitorName: exhibitor,
            ExhibitorType: exhibitorTypeID,
            PaymentTypeID: PaymentTypeID,
            PersonIncharge: PersonIncharge,
            StallPossessionStatusID: stallPosessionStatusId
        }
        console.log("exhibitorList_action -Action--------------------------->", params)

        const res = await axiosGet(getAccountExhibitorListUrl, params, headers);
        dispatch({
            type: GET_ACCOUNT_EXHIBITOR_LIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const taxInvoiceDetails_action = (UserID, ExhibitionID, ReceiptID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExhibitionID,
            ReceiptID: ReceiptID
        }
        console.log("taxInvoiceDetails_action Action------->", params)
        const res = await axiosGet(taxInvoiceDetailsUrl, params, headers)
        dispatch({
            type: TAX_INVOICE_DETAILS_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const invoiceMail_action = (list, additionalEmail) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            taxInvoiceList: list,
            additionalEmail: additionalEmail
        }
        console.log(params, "invoice mail")
        const res = await axiosPost(taxInvoiceMailUrl, params, headers)
        dispatch({
            type: TAX_INVOICE_MAIL_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const sendNoDues_action = (list) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            exhibitorList: list,
        }
        const res = await axiosPost(sendNoDuesUrl, params, headers)
        dispatch({
            type: SEND_NO_DUES_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const createTaxInvoice_action = (list) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            exhibitorList: list,
        }
        const res = await axiosPost(createTaxInvoiceUrl, params, headers)
        dispatch({
            type: CREATE_TAX_INVOICE_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getStallPossessionExport_action = () => async (dispatch) => {
    try {
        const params = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
        }
        console.log("getStallPossessionExport_action Action------->", params)

        const res = await axiosGet(getStallPossessionExportUrl, params, headers)

        dispatch({
            type: GET_STALL_POSSESSION_EXPORT_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}