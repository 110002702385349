import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Footer from '../../../common_components/Footer';
import Drawer from '../../../common_components/Drawer';
import {
    registrationOneAdd_action, registrationOneUpdate_action, getAgentDetails_action, getPendingRegDetails_action
} from '../../../actions/registration_action'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../utils/withRouter';
import moment from 'moment';
import Modal from "react-modal";
import Loader from '../../../utils/Loader'
import {
    clearSessionStorage, countryActionId, adminUserRoleListActionId,
    stateActionId, cityActionId, pincodeActionId, verifyPrimaryEmailIDActionId,
    toastError, toastSuccess
} from '../../../utils/constants'
import Pagination from '../../../utils/pagination'
import Resizer from "react-image-file-resizer";
import { contactNoValidator, panNoValidator, tanNoValidator, gstNoValidator, emailValidator } from '../../../utils/Validator'
import $ from 'jquery'
import { fill_action } from '../../../actions/fillCombo_action'
import AdminDrawer from "../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../common_components/header_with_logo";
import { getuserDetails_action, addUser_action, updateUser_action } from "../../../actions/admin_module_action/user_action/user_action";



/* 
 * mapDispatchToProps
*/
// const mapDispatchToProps = dispatch => ({
//     loginAction: () => dispatch(loginAction())
// })

/* 
 * mapStateToProps
*/
const mapStateToProps = state => ({
    ...state
})

class Registration extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            exhibitionID: '',
            is_add: false,
            is_update: false,

            countryList: [],
            stateList: [],
            cityList: [],
            pincodeList: [],
            tempStateList: [],
            tempCityList: [],
            tempPincodeList: [],
            _pincodeList: [],

            countryId: '',
            countryName: "",
            companyName: '',
            fasciaName: '',
            address1: '',
            address2: '',
            cityId: '',
            cityName: '',
            stateId: '',
            stateName: '',
            pincode: '',
            other_title_inchargeFirstName: '',
            inchargeFirstName: '',
            lastNamePersonIncharge: '',
            designation: '',
            preMobileNo: '',
            mobileNo: '',
            preAltMobileNo: '',
            altMobileNo: '',
            preTelephoneNo: '',
            telephoneNo: '',
            preTelephone1No: '',
            telephone1No: '',
            telefax: '',
            primaryEmailId: '',
            secondaryEmailId: '',
            website: '',
            title_inchargeFirstName: "Mr",

            countryErr: '',
            companyNameErr: '',
            fasciaNameErr: '',
            address1Err: '',
            cityNameErr: '',
            stateNameErr: '',
            pinCodeErr: '',
            inchargeFirstNameErr: '',
            lastNamePersonInchargeErr: '',
            designationErr: '',
            preMobileNoErr: '',
            mobileNoErr: '',
            roleIdErr: '',
            passwordErr: '',
            primaryEmailIdErr: '',

            isOther_title_inchargeFirstName: false,
            isDisplayCityList: false,
            isDisplayStateList: false,
            isDisplayPincodeList: false,

            namePrefix: ['Mr', 'Mrs', 'Ms', 'DR', 'Prof'],

            tempCountryList: [],
            isDisplayCountryList: false,

            userId: '',
            password: '',
            roleId: '',
            roleName: '',
            roleList: [],
        }
        this.getFillList = this.getFillList.bind(this)
        this.inputValidate = this.inputValidate.bind(this)
        this.preventMinus = this.preventMinus.bind(this)
        this.maxLengthCheck = this.maxLengthCheck.bind(this)
        this.onNextClick = this.onNextClick.bind(this)
        this.addAdminUser = this.addAdminUser.bind(this)

        this.onCitySearch = this.onCitySearch.bind(this)
        this.renderCityList = this.renderCityList.bind(this)
        this.callStateList = this.callStateList.bind(this)

        this.onStateSearch = this.onStateSearch.bind(this)
        this.renderStateList = this.renderStateList.bind(this)

        this.onPincodeSearch = this.onPincodeSearch.bind(this)
        this.renderPincodeList = this.renderPincodeList.bind(this)

        this.getAdminUserDetails = this.getAdminUserDetails.bind(this);
        this.onCountrySelect = this.onCountrySelect.bind(this);
    }

    componentDidMount() {
        this.setState({
            is_add: this.props.location.state != null && this.props.location.state.is_add != null ? this.props.location.state.is_add : false,
            is_update: this.props.location.state != null && this.props.location.state.is_update != null ? this.props.location.state.is_update : false,
            userId: this.props.location.state != null && this.props.location.state.userId != null ? this.props.location.state.userId : '',
        })
        this.getFillList(countryActionId, sessionStorage.getItem("sessionExhibitionID"), '', '', '', '')

        this.getFillList(adminUserRoleListActionId, sessionStorage.getItem("sessionExhibitionID"), '', '', '', '');
        console.log('prop is :', this.props);
        setTimeout(() => {
            this.state.is_update && this.getAdminUserDetails()
        }, 200);
    }


    // render city
    renderCityList() {
        const { cityList, cityName, tempCityList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempCityList.length > 0 && tempCityList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({ cityName: data.Description, isDisplayCityList: false })}
                        >{data.Description}</li>

                    ))
                }
            </ul>
        )
    }
    // searcg city
    onCitySearch(e) {
        const { cityList, cityName, tempCityList } = this.state
        if (e.target.value != '') {
            var searchCityList = tempCityList.filter(data => {
                return (
                    data
                        .Description
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            this.setState({
                cityName: e.target.value,
                cityNameErr: '',
                tempCityList: searchCityList,
                isDisplayCityList: true
            })
            // call state fill list pass city id 
            //this.getFillList(stateActionId,"","",)
        } else {
            this.setState({
                cityName: e.target.value,
                tempCityList: cityList,
                isDisplayCityList: false
            })
        }
    }
    // state list
    callStateList(value) {

        const { stateList, stateName, tempStateList } = this.state
        this.setState({ stateName: value, isDisplayStateList: false })
        this.getFillList(stateActionId, '', '', '', stateName, '', '')

    }

    // render state
    renderStateList() {
        const { stateList, stateName, tempStateList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempStateList.length > 0 && tempStateList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.callStateList(data.Description)}
                        >{data.Description}</li>

                    ))
                }
            </ul>
        )
    }
    // search state
    onStateSearch(e) {
        const { stateList, stateName, tempStateList } = this.state
        console.log("onStateSearch")
        if (e.target.value != '') {
            var searchStateList = tempStateList.filter(data => {
                return (
                    data
                        .Description
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            this.setState({
                stateName: e.target.value,
                stateNameErr: '',
                tempStateList: searchStateList,
                isDisplayStateList: true
            })
            // call pincode fill list pass city id and state id
            // this.getFillList(pincodeActionId, '', '', '', stateName, '')
        } else {
            this.setState({
                stateName: e.target.value,
                tempStateList: stateList,
                isDisplayStateList: false
            })
        }
    }

    // render pincode
    renderPincodeList() {
        const { pincodeList, Pincode, tempPincodeList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempPincodeList.length > 0 && tempPincodeList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({
                                pincode: data.Pincode,
                                isDisplayPincodeList: false
                            })}
                        >{data.Pincode}</li>

                    ))
                }
            </ul>
        )
    }
    // search pincode
    onPincodeSearch(e) {
        const { pincodeList, pincode, tempPincodeList } = this.state
        console.log("onPincodeSearch 123", tempPincodeList)
        if (e.target.value != '') {
            var searchPincodeList = tempPincodeList.filter(data => {
                return (
                    data
                        .Pincode
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            console.log('pincodeCount', searchPincodeList.length)
            this.setState({
                pincode: e.target.value,
                pincodeErr: '',
                tempPincodeList: searchPincodeList,
                isDisplayPincodeList: true
            })
        } else {
            this.setState({
                pincode: e.target.value,
                tempPincodeList: pincodeList,
                isDisplayPincodeList: false
            })
        }
    }

    getAdminUserDetails() {
        const { userId } = this.state

        this.setState({ isLoading: true });

        this.props
            .getuserDetails_action(userId)
            .then((data) => {
                if (data.error != 1) {
                    console.log('======', data.data)
                    this.setState({
                        address1: data.data.Address1, address2: data.data.Address2, altMobileNo: data.data.AlternateMobileNo, cityName: data.data.City, companyName: data.data.CompanyName, countryId: data.data.CountryID, designation: data.data.Designation, fasciaName: data.data.FasciaName, lastNamePersonIncharge: data.data.LastNamePersonIncharge, mobileNo: data.data.MobileNo, password: data.data.Password, title_inchargeFirstName: data.data.Title_PersonIncharge, inchargeFirstName: data.data.PersonIncharge, pincode: data.data.Pin, primaryEmailId: data.data.PrimaryEmail, roleId: data.data.RoleID, secondaryEmailId: data.data.SecondaryEmail, stateName: data.data.State, telefax: data.data.TeleFax, telephoneNo: data.data.Telephone, preMobileNo: data.data.PreMobileNo, preAltMobileNo: data.data.PreAlternateMobileNo, preTelephoneNo: data.data.PreTelephone, telephone1No: data.data.Telephone1, preTelephone1No: data.data.PreTelephone1, website: data.data.Website
                    })
                    this.setState({ isLoading: false })

                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    addAdminUser() {
        const {
            isLoading, countryId, roleList,
            countryName, countryList,
            companyName,
            fasciaName,
            address1,
            address2,
            cityId,
            cityName,
            stateId,
            stateName,
            pincode,
            other_title_inchargeFirstName,
            inchargeFirstName,
            lastNamePersonIncharge,
            designation,
            preMobileNo,
            mobileNo,
            preAltMobileNo,
            altMobileNo,
            preTelephoneNo,
            telephoneNo,
            preTelephone1No,
            telephone1No,
            telefax,
            primaryEmailId,
            secondaryEmailId,
            website,
            title_inchargeFirstName,

            countryErr,
            companyNameErr,
            fasciaNameErr,
            address1Err,
            cityNameErr,
            stateNameErr,
            pinCodeErr,
            inchargeFirstNameErr,
            lastNamePersonInchargeErr,
            designationErr,
            preMobileNoErr,
            mobileNoErr,
            primaryEmailIdErr,
            isOther_title_inchargeFirstName,
            isDisplayCityList,
            isDisplayStateList,
            isDisplayPincodeList,
            namePrefix,
            isDisplayCountryList,
            userId,
            password,
            roleId,
            roleName,
            roleIdErr,
            passwordErr,
        } = this.state

        var errMsg = ''

        errMsg = errMsg + emailValidator(primaryEmailId);
        console.log("errMsg", errMsg)

        this.inputValidate()

        if (countryErr == '' &&
            companyNameErr == '' &&
            address1Err == '' &&
            cityNameErr == '' &&
            stateNameErr == '' &&
            pinCodeErr == '' &&
            inchargeFirstNameErr == '' &&
            lastNamePersonInchargeErr == '' &&
            designationErr == '' &&
            preMobileNoErr == '' &&
            mobileNoErr == '' &&
            primaryEmailIdErr == '' &&
            roleIdErr == '' &&
            passwordErr == ''
        ) {
            if (errMsg != '') {
                toastError(errMsg)
                errMsg = ''
            } else {
                console.log("entered")
console.log(countryId ,
companyName ,
address1,
cityName ,
stateName ,
pincode ,
inchargeFirstName ,
lastNamePersonIncharge ,
designation,
preMobileNo ,
mobileNo ,
primaryEmailId,
roleId,
password ,)
                if (countryId != '' && countryId != null &&
                    companyName != '' && companyName != null &&
                    address1 != '' && address1 != null &&
                    cityName != '' && cityName != null &&
                    stateName != '' && stateName != null &&
                    pincode != '' && pincode != null &&
                    inchargeFirstName != '' && inchargeFirstName != null &&
                    lastNamePersonIncharge != '' && lastNamePersonIncharge != null &&
                    designation != '' && designation != null &&
                    preMobileNo != '' && preMobileNo != null &&
                    mobileNo != '' && mobileNo != null &&
                    primaryEmailId != '' && primaryEmailId != null &&
                    roleId != '' && roleId != null &&
                    password != '' && password != null
                ) {
                    this.setState({ isLoading: true })
                    this.props
                        .addUser_action(
                            companyName,
                            fasciaName,
                            address1,
                            address2,
                            cityName,
                            stateName,
                            pincode,
                            countryId,
                            title_inchargeFirstName,
                            inchargeFirstName,
                            lastNamePersonIncharge,
                            designation, mobileNo,
                            altMobileNo,
                            telephoneNo,
                            telephone1No,
                            telefax,
                            primaryEmailId,
                            secondaryEmailId,
                            website,
                            roleId,
                            password,
                            preMobileNo,
                            preAltMobileNo,
                            preTelephoneNo,
                            preTelephone1No,
                        )
                        .then((data) => {
                            this.setState({ isLoading: false })
                            if (data.error != 1) {
                                toastSuccess("Data saved successfully");
                                // this.setState({ isLoading: false })
                                this.props.navigate('/userList',)

                            }
                            else {
                                // this.setState({ isLoading: false })
                                toastError(data.msg)
                            }
                        })
                        .catch((e) => {
                            console.log(e);
                        });
                }
                else {
                    toastError('Enter mandatory fields')
                }
            }
        } else {
            console.log("0909999=++++")
            toastError('Enter mandatory fields')
        }
    }

    updateAdminUser() {
        const {
            isLoading, countryId, roleList,
            countryName, countryList,
            companyName,
            fasciaName,
            address1,
            address2,
            cityId,
            cityName,
            stateId,
            stateName,
            pincode,
            other_title_inchargeFirstName,
            inchargeFirstName,
            lastNamePersonIncharge,
            designation,
            preMobileNo,
            mobileNo,
            preAltMobileNo,
            altMobileNo,
            preTelephoneNo,
            telephoneNo,
            preTelephone1No,
            telephone1No,
            telefax,
            primaryEmailId,
            secondaryEmailId,
            website,
            title_inchargeFirstName,


            countryErr,
            companyNameErr,
            fasciaNameErr,
            address1Err,
            cityNameErr,
            stateNameErr,
            pinCodeErr,
            inchargeFirstNameErr,
            lastNamePersonInchargeErr,
            designationErr,
            preMobileNoErr,
            mobileNoErr,
            primaryEmailIdErr,
            isOther_title_inchargeFirstName,
            isDisplayCityList,
            isDisplayStateList,
            isDisplayPincodeList,
            namePrefix,
            isDisplayCountryList,
            userId,
            password,
            roleId,
            roleName,
            roleIdErr,
            passwordErr,
        } = this.state

        var errMsg = ''

        errMsg = errMsg + emailValidator(primaryEmailId);
        console.log("errMsg", errMsg)

        this.inputValidate()

        if (countryErr == '' &&
            companyNameErr == '' &&
            address1Err == '' &&
            cityNameErr == '' &&
            stateNameErr == '' &&
            pinCodeErr == '' &&
            inchargeFirstNameErr == '' &&
            lastNamePersonInchargeErr == '' &&
            designationErr == '' &&
            preMobileNoErr == '' &&
            mobileNoErr == '' &&
            primaryEmailIdErr == '' &&
            roleIdErr == '' &&
            passwordErr == ''
        ) {
            if (errMsg != '') {
                toastError(errMsg)
                console.log(primaryEmailId)
                errMsg = ''
            } else {
                console.log("entered")

                if (countryId != '' && countryId != null &&
                    companyName != '' && companyName != null &&
                    address1 != '' && address1 != null &&
                    cityName != '' && cityName != null &&
                    stateName != '' && stateName != null &&
                    pincode != '' && pincode != null &&
                    inchargeFirstName != '' && inchargeFirstName != null &&
                    lastNamePersonIncharge != '' && lastNamePersonIncharge != null &&
                    designation != '' && designation != null &&
                    preMobileNo != '' && preMobileNo != null &&
                    mobileNo != '' && mobileNo != null &&
                    primaryEmailId != '' && primaryEmailId != null &&
                    roleId != '' && roleId != null &&
                    password != '' && password != null && userId != '' && userId != null
                ) {
                    this.setState({ isLoading: true })
                    this.props
                        .updateUser_action(
                            companyName,
                            fasciaName,
                            address1,
                            address2,
                            cityName,
                            stateName,
                            pincode,
                            countryId,
                            title_inchargeFirstName,
                            inchargeFirstName,
                            lastNamePersonIncharge,
                            designation, mobileNo,
                            altMobileNo,
                            telephoneNo,
                            telephone1No,
                            telefax,
                            primaryEmailId,
                            secondaryEmailId,
                            website,
                            roleId,
                            password,
                            preMobileNo,
                            preAltMobileNo,
                            preTelephoneNo,
                            preTelephone1No,
                            userId
                        )
                        .then((data) => {
                            this.setState({ isLoading: false })
                            if (data.error != 1) {
                                toastSuccess("Data saved successfully");
                                // this.setState({ isLoading: false })
                                this.props.navigate('/userList',)

                            }
                            else {
                                // this.setState({ isLoading: false })
                                console.log(data)
                                toastError(data.msg)
                            }
                        })
                        .catch((e) => {
                            console.log(e);
                        });
                }
                else {
                    toastError('Enter mandatory fields')
                }
            }
        } else {
            console.log("0909999=++++")
            toastError('Enter mandatory fields')
        }
    }

    onPreviousClick() {
        this.props.navigate('/userList',)
    }

    onNextClick() {
        const { is_update } = this.state;
        this.inputValidate()
        if (is_update == true) {
            this.updateAdminUser();
        } else {
            this.addAdminUser();
        }
    }

    inputValidate() {
        const {
            isLoading, countryId, roleList,
            countryName, countryList,
            companyName,
            fasciaName,
            address1,
            address2,
            cityId,
            cityName,
            stateId,
            stateName,
            pincode,
            other_title_inchargeFirstName,
            inchargeFirstName,
            lastNamePersonIncharge,
            designation,
            preMobileNo,
            mobileNo,
            preAltMobileNo,
            altMobileNo,
            preTelephoneNo,
            telephoneNo,
            preTelephone1No,
            telephone1No,
            telefax,
            primaryEmailId,
            secondaryEmailId,
            website,
            title_inchargeFirstName,


            countryErr,
            companyNameErr,
            fasciaNameErr,
            address1Err,
            cityNameErr,
            stateNameErr,
            pinCodeErr,
            inchargeFirstNameErr,
            lastNamePersonInchargeErr,
            designationErr,
            preMobileNoErr,
            mobileNoErr,
            primaryEmailIdErr,
            isOther_title_inchargeFirstName,
            isDisplayCityList,
            isDisplayStateList,
            isDisplayPincodeList,
            namePrefix,
            isDisplayCountryList,
            userId,
            password,
            roleId,
            roleName,
            roleIdErr,
            passwordErr,
        } = this.state

        if (countryId == '') {
            this.setState({
                countryErr: 'Please Select country'
            })
        }

        if (roleId == '') {
            this.setState({
                roleIdErr: 'Please select role'
            })
        }
        if (password == '') {
            this.setState({
                passwordErr: 'Please enter password'
            })
        }
        if (companyName == '') {
            this.setState({
                companyNameErr: 'Please enter Compnay Name'
            })
        }

    

        if (address1 == '') {
            this.setState({
                address1Err: 'Please enter address'
            })
        }

        if (stateName == '' && countryId == 91) {
            this.setState({
                stateNameErr: 'Please enter state'
            })
        }

        if (cityName == '') {
            this.setState({
                cityNameErr: 'Please enter City'
            })
        }

        if (pincode == '') {
            this.setState({
                pinCodeErr: 'Please enter Pin'
            })
        }
        if (inchargeFirstName == '') {
            this.setState({
                inchargeFirstNameErr: 'Please enter person incharge first name'
            })
        }
        if (designation == '') {
            this.setState({
                designationErr: 'Please enter designation'
            })
        }
        if (lastNamePersonIncharge == '') {
            this.setState({
                lastNamePersonInchargeErr: 'Please enter person incharge Last name'
            })
        }
        if (mobileNo == '') {
            this.setState({
                mobileNoErr: 'Please enter mobile No'
            })
        }
        if (primaryEmailId == '') {
            this.setState({
                primaryEmailIdErr: 'Please enter primary email'
            })
        }

    }

    preventMinus = (e) => {
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    }

    maxLengthCheck = (object, maxLength) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }

    }

    // get fill List dropdown  
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        const { isPrimaryEmailId, isSecondaryEmailId, isOwenerEmailId, isInvoiceprimaryEmailId, isInvoicesecondaryEmailId } = this.state
        this.setState({ isLoading: true })
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value)
            .then((data) => {
                if (data.error != 1) {
                    // country list
                    if (actionID === countryActionId) {
                        console.log("country data", data.data)
                        this.setState({
                            countryList: data.data, tempCountryList: data.data, isLoading: false,
                        })
                    }
                    if (actionID === adminUserRoleListActionId) {
                        console.log("country data", data.data)
                        // var internationalCountryData = data.data.length > 0 && data.data.filter((item) => item.Code != "91")
                        this.setState({
                            roleList: data.data
                        })
                    }
                    // state list
                    if (actionID === stateActionId) {
                        console.log("stateList data", data.data)
                        this.setState({
                            stateList: data.data,
                            tempStateList: data.data,
                            InvtempStateList: data.data,
                            isLoading: false,
                        })
                    }

                    // city list
                    if (actionID === cityActionId) {
                        console.log("city data", data.data)
                        this.setState({
                            cityList: data.data,
                            tempCityList: data.data,
                            InvtempCityList: data.data,
                            isLoading: false,
                        })
                    }


                    // pincode
                    if (actionID === pincodeActionId) {
                        // console.log("pincode data", data.data)
                        this.setState({
                            pincodeList: data.data,
                            tempPincodeList: data.data,
                            InvtempPincodeList: data.data,
                            isLoading: false,
                        })
                    }

                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    onCountrySelect(id) {
        const { countryList } = this.state
        for (var i = 0; i < this.state.countryList.length; i++) {
            if (countryList[i].Code == id) {
                this.setState({ countryId: countryList[i].Code, countryName: countryList[i].Description, preMobileNo: countryList[i].CountryCode, preAltMobileNo: countryList[i].CountryCode })
            }
        }

        setTimeout(() => {
            this.getFillList(cityActionId, sessionStorage.getItem("sessionExhibitionID"), '', id, '', '')
            this.getFillList(stateActionId, sessionStorage.getItem("sessionExhibitionID"), '', id, '', '')
            this.getFillList(pincodeActionId, sessionStorage.getItem("sessionExhibitionID"), '', id, '', '')
        }, 100);
    }




    render() {
        const { is_add,
            isLoading, countryId, roleList,
            countryName, countryList,
            companyName,
            fasciaName,
            address1,
            address2,
            cityId,
            cityName,
            stateId,
            stateName,
            pincode,
            other_title_inchargeFirstName,
            inchargeFirstName,
            lastNamePersonIncharge,
            designation,
            preMobileNo,
            mobileNo,
            preAltMobileNo,
            altMobileNo,
            preTelephoneNo,
            telephoneNo,
            preTelephone1No,
            telephone1No,
            telefax,
            primaryEmailId,
            secondaryEmailId,
            website,
            title_inchargeFirstName,

            countryErr,
            companyNameErr,
            fasciaNameErr,
            address1Err,
            cityNameErr,
            stateNameErr,
            pinCodeErr,
            inchargeFirstNameErr,
            lastNamePersonInchargeErr,
            designationErr,
            preMobileNoErr,
            mobileNoErr,
            primaryEmailIdErr,
            isOther_title_inchargeFirstName,
            isDisplayCityList,
            isDisplayStateList,
            isDisplayPincodeList,
            namePrefix,
            isDisplayCountryList,
            userId,
            password,
            roleId,
            roleName,
            roleIdErr,
            passwordErr,
        } = this.state

        const toInputUppercase = e => {
            e.target.value = ("" + e.target.value).toUpperCase();
        };

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                <>
                    {/* PAGE */}
                    <div className={JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ? "with-dr-container" : "with-dr-container com-height"}>
                        <div className="main-container">
                            <AdminDrawer />
                            <div className="row">
                                <div className="step-name-space" style={{ zIndex: 9 }}>
                                    <p>User {is_add == true ? 'Add' : 'Update'}</p>
                                </div>
                            </div>
                        </div>
                        {/* <div className="side-app"> */}
                        <div className="main-container p-2 mt-5">
                            {/* <div className="main-container form-start container-fluid p-0">
                                        {is_update && <>
                                            <div className="row ">
                                                <div className="step-name">
                                                    <p>Exhibitor Registration</p>
                                                </div>
                                            </div>
                                            <StepHeader />
                                        </>}
                                    </div> */}
                            {/* <StepHeader /> */}
                            <div className="main-container container-fluid">
                                <div className="row p-1">
                                    <div className="col-xl-3 mb-3">
                                        <label>Country<span className="text-red">*</span></label>
                                        <select className="form-control"
                                            value={countryId}
                                            // disabled={isView}
                                            onChange={e => this.onCountrySelect(e.target.value)} >
                                            <option value={''}>Select</option>
                                            {/* <option value={0}>All</option> */}
                                            {
                                                countryList.length > 0 && countryList.map(data => (
                                                    <option value={data.Code} key={data.Code}>{data.Description}</option>
                                                ))
                                            }
                                        </select>
                                        {<span className='text-danger text-start text-left d-block mt-1'>{countryErr}</span>}
                                    </div>
                                    <div className="col-xl-3 mb-3">
                                        <label>Role<span className="text-red">*</span></label>
                                        <select className="form-control"
                                            value={roleId}
                                            // disabled={isView}
                                            onChange={(e) => this.setState({ roleId: e.target.value, roleIdErr: '' })}  >
                                            <option value={''}>Select</option>
                                            {/* <option value={0}>All</option> */}
                                            {
                                                roleList.length > 0 && roleList.map(data => (
                                                    <option value={data.RoleID} key={data.RoleID}>{data.RoleName}</option>
                                                ))
                                            }
                                        </select>
                                        {<span className='text-danger text-start text-left d-block mt-1'>{roleIdErr}</span>}
                                    </div>
                                    <div className="col-xl-3 mb-1">
                                        <label htmlFor="validationCustom03">
                                            Password<span className="text-red">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="validationCustom03"
                                            required=""
                                            value={password}
                                            maxLength={500}
                                            onChange={e => this.setState({ password: e.target.value, companyNameErr: '' })} />
                                        {<span className='text-danger text-start text-left d-block mt-1'>{passwordErr}</span>}

                                    </div>
                                </div>

                                <div className="row p-1">
                                    <div className="form-row">
                                        <div className="col-xl-6 mb-1">
                                            <label htmlFor="validationCustom03">
                                                Company Name<span className="text-red">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="validationCustom03"
                                                required=""
                                                value={companyName}
                                                maxLength={500}
                                                onChange={e => this.setState({ companyName: e.target.value, companyNameErr: '' })} />
                                            {<span className='text-danger text-start text-left d-block mt-1'>{companyNameErr}</span>}

                                        </div>
                                        <div className="col-xl-6 mb-1">
                                            <label htmlFor="validationCustom01">
                                                Address Line 1 <span className="text-red">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                maxLength={200}
                                                required=""
                                                value={address1}
                                                onChange={e => this.setState({ address1: e.target.value, address1Err: '' })} />
                                            {<span className='text-danger text-start text-left d-block mt-1'>{address1Err}</span>}

                                        </div>
                                        <div className="col-xl-6 mb-1">
                                            <label htmlFor="validationCustom01">
                                                Address Line 2{" "}
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                maxLength={200}
                                                value={address2}
                                                onChange={e => this.setState({ address2: e.target.value })} />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-xl-3 mb-1">
                                            <label htmlFor="validationCustom01">
                                                City<span className="text-red">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"

                                                required=""
                                                value={cityName}


                                                onChange={e => this.onCitySearch(e)}
                                            />
                                            {isDisplayCityList && this.renderCityList()}
                                            {<span className='text-danger text-start text-left d-block mt-1'>{cityNameErr}</span>}

                                        </div>
                                        <div className="col-xl-3 mb-1">
                                            <label htmlFor="validationCustom01">
                                                {countryId == 91 ? 'State' : 'State/Province'}
                                                {countryId == 91 ? <span className="text-red">*</span> : ''}

                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"

                                                required=""
                                                value={stateName}

                                                onChange={e => this.onStateSearch(e)}
                                            />
                                            {isDisplayStateList && this.renderStateList()}
                                            {<span className='text-danger text-start text-left d-block mt-1'>{stateNameErr}</span>}

                                        </div>
                                        <div className="col-xl-3 mb-1">
                                            <label htmlFor="validationCustom01">
                                                {countryId == 91 ? 'Pincode' : 'Zipcode'}<span className="text-red">*</span>
                                            </label>
                                            {
                                                countryId == 91 &&
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    maxLength={6}
                                                    // onKeyPress={this.onlyNumbers}
                                                    required=""
                                                    value={pincode}
                                                    onChange={e => this.onPincodeSearch(e)}
                                                // onChange={this.changeUrl}
                                                />
                                            }
                                            {
                                                countryId != 91 &&
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    maxLength={10}
                                                    // onKeyPress={this.onlyNumbers}
                                                    required=""
                                                    value={pincode}
                                                    onChange={e => this.onPincodeSearch(e)}
                                                    onInput={toInputUppercase}
                                                // onChange={this.changeUrl}
                                                />
                                            }
                                            {isDisplayPincodeList && this.renderPincodeList()}
                                            {<span className='text-danger text-start text-left d-block mt-1'>{pinCodeErr}</span>}

                                        </div>

                                        <div className="col-xl-3">
                                            <label>
                                                First Name of Person Incharge<span className="text-red">*</span>
                                            </label>
                                            {
                                                isOther_title_inchargeFirstName &&
                                                <input
                                                    type="text"
                                                    className="form-control-1 other-fil"
                                                    value={other_title_inchargeFirstName}
                                                    onChange={e => this.setState({ other_title_inchargeFirstName: e.target.value })}

                                                    placeholder={'Other'}
                                                    style={{
                                                        // position: "absolute",
                                                        paddingLeft: '5px'
                                                    }}
                                                />
                                            }

                                            <input
                                                type="text"
                                                className="form-control"
                                                maxLength={100}
                                                required=""
                                                defaultValue=""
                                                style={{ position: "relative", paddingLeft: '55px' }}
                                                value={inchargeFirstName}
                                                onChange={e => this.setState({ inchargeFirstName: e.target.value, inchargeFirstNameErr: '' })}
                                            />
                                            <div className="col-xl-1 mb-3">
                                                <div
                                                    className="form-group"

                                                    style={{
                                                        width: '45px',
                                                        position: "absolute",
                                                        top: "-59px",
                                                        left: '0px'
                                                    }}
                                                >
                                                    <label />
                                                    <select
                                                        className="form-control-1 select2 select-hidden-accessible"
                                                        id="roledb"
                                                        style={{
                                                            paddingLeft: 0,
                                                            paddingRight: 0,
                                                            borderTop: "none",
                                                            borderBottom: "none",
                                                            marginTop: 0,
                                                            textAlign: "left"
                                                        }}
                                                        value={title_inchargeFirstName}
                                                        onChange={e => this.setState({
                                                            title_inchargeFirstName: e.target.value,
                                                            isOther_title_inchargeFirstName: e.target.value == "Other" && true
                                                        })}

                                                    >
                                                        <option value="Mr">Mr</option>
                                                        <option value="Ms">Ms</option>
                                                        <option value="DR">DR</option>
                                                        <option value="Prof">Prof</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {<span className='text-danger text-start text-left d-block mt-1'>{inchargeFirstNameErr}</span>}

                                        </div>
                                        <div className="col-xl-3">
                                            <label>
                                                Last Name of Person Incharge<span className="text-red">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                maxLength={100}
                                                required=""
                                                defaultValue=""
                                                value={lastNamePersonIncharge}
                                                onChange={e => this.setState({ lastNamePersonIncharge: e.target.value, lastNamePersonInchargeErr: '' })}
                                            />
                                            {<span className='text-danger text-start text-left d-block mt-1'>{lastNamePersonInchargeErr}</span>}

                                        </div>
                                        <div className="col-xl-3 mb-1">
                                            <label htmlFor="validationCustom01">
                                                Designation <span className="text-red">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                maxLength={100}
                                                className="form-control"
                                                value={designation}
                                                onChange={e => this.setState({ designation: e.target.value, designationErr: '' })} />
                                            {<span className='text-danger text-start text-left d-block mt-1'>{designationErr}</span>}

                                        </div>
                                        <div
                                            className="col-xl-3 mb-1"
                                            style={{ position: "relative" }}
                                        >
                                            <label htmlFor="validationCustom01">
                                                Mobile No<span className="text-red">*</span>
                                            </label>
                                            <input
                                                type="number"

                                                className="form-control"
                                                value={mobileNo}
                                                maxLength={sessionStorage.getItem('countryId') === 43 ? 11 : 10}
                                                onInput={e => this.maxLengthCheck(e, 10)}
                                                onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                                                onWheel={(e) => e.target.blur()}
                                                onKeyPress={e => this.preventMinus(e)}
                                                onChange={e => this.setState({ mobileNo: e.target.value, mobileNoErr: '' })}
                                                style={{ paddingLeft: '50px' }} />
                                            <input
                                                type="text"
                                                placeholder="ISD"
                                                className="form-control-1"
                                                value={preMobileNo}
                                                onChange={e => this.setState({ preMobileNo: e.target.value })}
                                                style={{
                                                    width: '45px',
                                                    position: "absolute",
                                                    top: '23px',
                                                    paddingLeft: '5px'
                                                }}
                                            />
                                            {<span className='text-danger text-start text-left d-block mt-1'>{mobileNoErr}</span>}

                                        </div>

                                    </div>
                                    <div className="form-row">
                                        <div className="col-xl-3 mb-1">
                                            <label htmlFor="validationCustom01">
                                                Alternate Mobile No{" "}
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={altMobileNo}
                                                maxLength={sessionStorage.getItem('countryId') === "43" ? 11 : 10}
                                                onInput={e => this.maxLengthCheck(e, 10)} size={'10'}
                                                onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                                                onWheel={(e) => e.target.blur()}
                                                onKeyPress={e => this.preventMinus(e)}
                                                onChange={e => this.setState({ altMobileNo: e.target.value })}
                                                style={{ paddingLeft: '50px' }}
                                            />
                                            <input
                                                type="text"
                                                className="form-control-1"
                                                placeholder="ISD"
                                                value={preAltMobileNo}
                                                onChange={e => this.setState({ preAltMobileNo: e.target.value })}

                                                // placeholder={+91}
                                                style={{
                                                    width: '45px',
                                                    position: "absolute",
                                                    top: '26px',
                                                    paddingLeft: '5px'
                                                }}
                                            />
                                        </div>
                                        <div className="col-xl-3 mb-1">
                                            <label htmlFor="validationCustom01">Telephone No</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={telephoneNo} maxLength={'10'}
                                                onChange={e => this.setState({ telephoneNo: e.target.value })}
                                                onInput={e => this.maxLengthCheck(e, 10)} size={'10'}
                                                onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                                                onWheel={(e) => e.target.blur()}
                                                onKeyPress={e => this.preventMinus(e)}
                                                style={{ paddingLeft: '50px' }}
                                            />
                                            <input
                                                type="text"
                                                className="form-control-1"
                                                placeholder="STD"
                                                value={preTelephoneNo}
                                                onChange={e => this.setState({ preTelephoneNo: e.target.value })}
                                                style={{
                                                    width: '50px',
                                                    position: "absolute",
                                                    top: '26px',
                                                    padding: "6px !important",
                                                    paddingLeft: '5px'
                                                }}
                                            />
                                        </div>
                                        <div className="col-xl-3 mb-1">
                                            <label htmlFor="validationCustom01">Telephone No 1</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={telephone1No} maxLength={'10'}
                                                onChange={e => this.setState({ telephone1No: e.target.value })}
                                                onInput={e => this.maxLengthCheck(e, 10)} size={'10'}
                                                onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                                                onWheel={(e) => e.target.blur()}
                                                onKeyPress={e => this.preventMinus(e)}
                                                style={{ paddingLeft: '50px' }}
                                            />
                                            <input
                                                type="text"
                                                className="form-control-1"
                                                placeholder="STD"
                                                value={preTelephone1No}
                                                onChange={e => this.setState({ preTelephone1No: e.target.value })}
                                                style={{
                                                    width: 50,
                                                    position: "absolute",
                                                    top: 26,
                                                    paddingLeft: 5,
                                                    padding: "6px !important",
                                                }}
                                            />
                                        </div>
                                        <div className="col-xl-3 mb-1">
                                            <label htmlFor="validationCustom01">Telefax</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={telefax} maxLength={50}
                                                onChange={e => this.setState({ telefax: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-xl-3 mb-1">
                                            <label htmlFor="validationCustom01">
                                                Primary Email <span className="text-red">*</span>
                                            </label>
                                            <input
                                                type="email"
                                                maxLength={100}
                                                className="form-control"
                                                value={primaryEmailId}
                                                onChange={e => this.setState({ primaryEmailId: e.target.value, primaryEmailIdErr: '' })}
                                            // onBlur={e => primaryEmailId != '' && this.emailFocusOut('fromprimaryemail')}

                                            />
                                            {<span className='text-danger text-start text-left d-block mt-1'>{primaryEmailIdErr}</span>}

                                        </div>
                                        <div className="col-xl-3 mb-1">
                                            <label htmlFor="validationCustom01">
                                                Secondary Email{" "}
                                            </label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                maxLength={100}
                                                value={secondaryEmailId}
                                                onChange={e => this.setState({ secondaryEmailId: e.target.value })}
                                            // onBlur={e => secondaryEmailId != '' && this.emailFocusOut('fromsecondaryemail')}
                                            />
                                        </div>
                                        <div className="col-xl-6 mb-1">
                                            <label htmlFor="validationCustom01">Website</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                maxLength={100}
                                                value={website}
                                                onChange={e => this.setState({ website: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="main-container container-fluid p-2 pt-0 pb-0">
                                <div className="mt-1 mb-4">
                                    {
                                        sessionStorage.getItem("sessionExhRegistrationID") == null &&
                                        <button className="btn btn-previous" onClick={e => this.onPreviousClick()}>
                                            {" "}
                                            Back{" "}
                                        </button>
                                    }

                                    <button className="btn btn-next-bot mx-2" onClick={e => this.onNextClick()} >
                                        Save
                                    </button>
                                </div>
                            </div>
                            <div className="bottom-height"></div>
                            <Footer />
                        </div>
                    </div>
                </>

            </>
        );
    }
}

export default withRouter(connect(null, {
    registrationOneAdd_action, fill_action, getuserDetails_action, addUser_action, updateUser_action, registrationOneUpdate_action, getPendingRegDetails_action, getAgentDetails_action
})(Registration));