import { async } from "q"
import { axiosGet, axiosPost } from "../../../react_axios/Axios"
import { GET_SPACE_BOOKING_DETAILS_ACTION_TYPE, SPACE_BOOKING_EDIT_ACTION_TYPE, editSpaceBookingUrl, headers, getSpaceBookingDetailsUrl, GET_EXHIBITOR_SPACEBOOKING_DETAILS_ACTION_TYPE } from "../../../utils/constants"



export const getDetails_exhibitorSpaceBooking_action = () => async (dispatch) => {
    try {
        const params = {
            ExhibitionID: JSON.parse(sessionStorage.getItem('ExhibitorAccessData')).ExhibitionID,
            ExhregistrationID: JSON.parse(sessionStorage.getItem('ExhibitorAccessData')).ExhRegistrationID,
            AccountID: JSON.parse(sessionStorage.getItem('ExhibitorAccessData')).AccountID,
            Token: 'token',
            UserID: JSON.parse(sessionStorage.getItem('ExhibitorAccessData')).Userid,
            ServiceID: JSON.parse(sessionStorage.getItem('ExhibitorAccessData')).ServiceID,
        }
        console.log("getDetails_exhibitorSpaceBooking_action ----->", params)
        const res = await axiosGet(getSpaceBookingDetailsUrl, params, headers)

        dispatch({
            type: GET_EXHIBITOR_SPACEBOOKING_DETAILS_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const edit_exhibitorSpaceBoking_action = (isCorrespondence, exbRegNo, regCountryId, billCountryId, regCompany, fasciaName, regAdd1, regAdd2, regCity, regState, regPin, regCountry,
    preFixPersonInch, personInchFirst, personInchLast, regDesignation, regMob1, regMob2, regTel, regTel1, regTelFax,
    regPrimaryEmail, regSecondaryEmail, regWeb, preOwnerNameFirst, ownerNameFirst, ownerNameLast, ownerMob,
    ownerEmail, billCompany, billAdd1, billAdd2, billCity, billState, billPin, billCountry, preFixBillPersonInch,
    billPersonInchFirst, billPersonInchLast, billDesignation, billMob1, billMob2, billTel, billTel1, billTelFax,
    billPrimaryEmail, billSecondaryEmail, billWeb, panName, panNo, tanNo, gstNo, companyRegNo, iecCode,
    accName, accNo, accType, bankName, branchName, ifsc, primaryChapterId, chapterList) => async (dispatch) => {
        try {
            const mapData = {
                UserID:JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
                ExhibitionID: JSON.parse(sessionStorage.getItem('ExhibitorAccessData')).ExhibitionID,
                ExhRegistrationNo: exbRegNo,
                ExhRegistrationDate: JSON.parse(sessionStorage.getItem('ExhibitorAccessData')).ToDate,

                CompanyName: regCompany,
                FasciaName: fasciaName,
                Address1: regAdd1,
                Address2: regAdd2,
                City: regCity,
                State: regState,
                Pin: regPin,
                CountryID: regCountryId,

                Title_PersonIncharge: preFixPersonInch,
                PersonIncharge: personInchFirst,
                lastNamePersonIncharge: personInchLast,
                Designation: regDesignation,
                MobileNo: `${sessionStorage.getItem('sessionCountryNamePreRegisterMobileNo')}-${regMob1}`,
                AlternateMobileNo: `${sessionStorage.getItem('sessionCountryNamePreRegisterAlternateMobileNo')}-${regMob2}`,
                Telephone: `${sessionStorage.getItem('sessionregisterTelephoneNoPre')}-${regTel}`,
                Telephone1: `${sessionStorage.getItem('sessionregisterTelephoneNo1Pre')}-${regTel1}`,
                TeleFax: regTelFax,
                PrimaryEmail: regPrimaryEmail,
                SecondaryEmail: regSecondaryEmail,
                Website: regWeb,

                Title_Name_Of_CEO_MD_President_Owner: preOwnerNameFirst,
                Name_Of_CEO_MD_President_Owner: ownerNameFirst,
                LastName: ownerNameLast,
                Mobile: `${sessionStorage.getItem('sessionCountryNamePreOwnerMobileNo')}-${ownerMob}`,
                Email: ownerEmail,
                SameAsCorrespondence: isCorrespondence,
                InvCompanyName: billCompany,
                InvAddress1: billAdd1,
                InvAddress2: billAdd2,
                InvCity: billCity,
                InvState: billState,
                InvPin: billPin,
                InvCountryID: billCountryId,
                Title_InvPersonIncharge: preFixBillPersonInch,
                InvPersonIncharge: billPersonInchFirst,
                InvLastNamePersonIncharge: billPersonInchLast,
                InvDesignation: billDesignation,
                InvMobileNo: `${sessionStorage.getItem('sessionCountryNamePreInvoiceregisterMobileNo')}-${billMob1}`,
                InvAlternateMobileNo: `${sessionStorage.getItem('sessionCountryNamePreInvoiceregisterAlternateMobileNo')}-${billMob2}`,
                InvTelephone: `${sessionStorage.getItem('sessioninvoiceregisterTelephoneNoPre')}-${billTel}`,
                InvTelephone1: `${sessionStorage.getItem('sessioninvoiceregisterTelephoneNo1Pre')}-${billTel1}`,
                InvTeleFax: billTelFax,
                InvPrimaryEmail: billPrimaryEmail,
                InvSecondaryEmail: billSecondaryEmail,
                InvWebsite: billWeb,
                NameOnPanCard: panName,
                PanNo: panNo,
                Tan: tanNo,
                GSTNo: gstNo,
                CompanyRegistrationNo: companyRegNo,
                IECCode: iecCode,
                AccountName: accName,
                AccountNo: accNo,
                AccountType: accType,
                BankName: bankName,
                BranchName: branchName,
                SwiftCode_IFSCCode: ifsc,
                StatusID: 4,
                ExhRegistrationID: JSON.parse(sessionStorage.getItem('ExhibitorAccessData')).ExhRegistrationID,
                AcccountID: JSON.parse(sessionStorage.getItem('ExhibitorAccessData')).AccountID,
                PrimaryChapter: primaryChapterId,
                chapterList: chapterList
            }
            console.log("edit_exhibitorSpaceBoking_action ----->", mapData)
            const res = await axiosPost(editSpaceBookingUrl, mapData, headers)

            dispatch({
                type: SPACE_BOOKING_EDIT_ACTION_TYPE,
                payload: res,
            });
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }

    }