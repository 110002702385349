import { Component } from "react";
import Loader from "../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action } from '../../../../actions/fillCombo_action';
import { AttendeeTypeActionId, stallTypeActionId, stallListActionId, countryActionId, exhibitionActionId, toastError, stateActionId, toastSuccess } from "../../../../utils/constants";
import { emailValidator, panNoValidator, tanNoValidator, notMendetoryEmailValidator, pincodeValidator } from '../../../../utils/Validator'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import Pagination from "../../../../utils/pagination";
import AdminDrawer from "../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../common_components/header_with_logo";
import { useNavigate } from "react-router-dom";
import ExhibitorDrawer from "../../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../../common_components/exhibitor_module_header";
import { data } from "jquery";
// import { getStateMasterDetails_action, insertUpdateAttendeeTypeMaster_action } from "../../../../actions/admin_module_action/master_action/hall_master_action";
import { insertAttendeeTypeMaster_action, updateAttendeeTypeMaster_action, getAttendeeTypeMasterList_action } from "../../../../actions/admin_module_action/master_action/attendee_type_master_action";
import Footer from "../../../../common_components/Footer";


class AttendeeTypeAU extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            currentPage: 1,
            is_add: false,
            is_update: false,
            attendeeTypeID: '',
            // attendeeTypeID: '',
            AttendeeTypeName: '',
            // countryID: '',
            attendeeTypeList: [],
            // stateList: [],
            // AttendeeTypeName: '',
            // StateErrMsg: '',
            // AttendeeTypeErrMsg: '',
            // countryErrMsg: '',
        }

        // this.getAttendeeTypeMasterList = this.getAttendeeTypeMasterList.bind(this)
        this.onPreviousClick = this.onPreviousClick.bind(this)
        this.insertAttendeeTypeMaster = this.insertAttendeeTypeMaster.bind(this)
        this.updateAttendeeTypeMaster = this.updateAttendeeTypeMaster.bind(this)
        this.onNextClick = this.onNextClick.bind(this)
        this.getFillList = this.getFillList.bind(this)

    }

    componentDidMount() {
        this.setState({
            is_add: this.props.location.state != null && this.props.location.state.is_add != null ? this.props.location.state.is_add : false,
            is_update: this.props.location.state != null && this.props.location.state.is_update != null ? this.props.location.state.is_update : false,
            attendeeTypeID: this.props.location.state != null && this.props.location.state.attendeeTypeID != null ? this.props.location.state.attendeeTypeID : '',
            AttendeeTypeName: this.props.location.state != null && this.props.location.state.AttendeeTypeName != null ? this.props.location.state.AttendeeTypeName : '',
        })

        // this.getFillList(countryActionId, '', '', '', '', '').then(() => {
        // this.getFillList(stateActionId, '', '', '', '', '').then(() => {
        // this.getAttendeeTypeMasterList()
        // })
        // })
    }

    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        const { } = this.state
        return new Promise((resolve, reject) => {
            this.setState({ isLoading: true })
            this.props
                .fill_action(actionID, exhibitionID, moduleID, keyValue, value)
                .then((data) => {
                    if (data.error != 1) {
                        // country list
                        if (actionID === countryActionId) {
                            console.log("country data", data.data)
                            // var internationalCountryData = data.data.length > 0 && data.data.filter((item) => item.Code != "91")
                            this.setState({
                                countryList: data.data
                            })
                            resolve(true)
                        } else if (actionID === stateActionId) {
                            console.log("country data", data.data)
                            // var internationalCountryData = data.data.length > 0 && data.data.filter((item) => item.Code != "91")
                            this.setState({
                                stateList: data.data
                            })
                            resolve(true)
                        }
                    }
                    this.setState({ isLoading: false })
                })
                .catch((e) => {
                    console.log(e);
                });
        })
    }

    // getAttendeeTypeMasterList() {
    //     this.setState({ isLoading: true })
    //     const { countryID, stateName, StateID, attendeeTypeID, Status, AttendeeTypeName } = this.state
    //     console.log('CountryID', countryID)
    //     this.props.getAttendeeTypeMasterList_action(AttendeeTypeName, null)
    //         .then((data) => {
    //             if (data.error != 1) {
    //                 console.log(data.data[0])
    //                 data.data[0].length > 0 && data.data[0].map((item) => {
    //                     if (item.Name == AttendeeTypeName) {
    //                         this.setState({ AttendeeTypeName: item.AttendeeTypeName })
    //                     }
    //                 })
    //                 this.setState({ isLoading: false })
    //             }
    //             else {
    //                 this.setState({ isLoading: false })
    //                 toastError(data.msg)
    //             }
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         })
    // }

    onPreviousClick() {
        this.props.navigate('/attendeeTypeMasterList',)
    }
    insertAttendeeTypeMaster() {
        const { isLoading, exhibitionID, AttendeeTypeName, StateID, attendeeTypeID, countryID, hallName, StateErrMsg, is_update, StateNo, Panno } = this.state
        const { hallID } = this.state
        // var panErrMsg = panNoValidator(Panno)
        // var StateErrMsg = StateNoValidator(StateNo)
        if (AttendeeTypeName != null && AttendeeTypeName != '') {
            this.setState({ isLoading: true })
            this.props.insertAttendeeTypeMaster_action(AttendeeTypeName)
                .then((data) => {
                    if (data.error != 1) {
                        console.log(data.data)
                        this.props.navigate('/attendeeTypeMasterList',)
                        toastSuccess("added successfully")
                        this.setState({ isLoading: false })
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })

        }
        else {
            toastError("Please enter mandatory fields")
        }
    }

    updateAttendeeTypeMaster() {
        const { isLoading, exhibitionID, StateID, StateErrMsg, countryID, attendeeTypeID, AttendeeTypeName, StateNo, Panno, hallName, is_update } = this.state
        // var StateErrMsg = StateNoValidator(StateNo)
        if (AttendeeTypeName != null && AttendeeTypeName != '' && attendeeTypeID != null && attendeeTypeID != '') {
            this.setState({ isLoading: true })
            this.props.updateAttendeeTypeMaster_action(attendeeTypeID, AttendeeTypeName)
                .then((data) => {
                    if (data.error != 1) {
                        console.log(data.data)
                        this.props.navigate('/attendeeTypeMasterList',)
                        toastSuccess("update successful")
                        this.setState({ isLoading: false })
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        else {
            toastError("Please enter mandatory fields")
        }
    }

    onNextClick() {
        const { is_update } = this.state;
        if (is_update == true) {
            this.updateAttendeeTypeMaster();
        } else {
            this.insertAttendeeTypeMaster();
        }
    }

    render() {
        const { isLoading, exhibitionID, currentPage, countryID, StateID, stateList, StateNo, AttendeeTypeName, countryList, Panno } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <div className="app-content with-dr-container com-height">
                        <AdminDrawer />
                        <div className="row">
                            <div className="step-name">
                                <p>{this.state.is_add == true ? 'AttendeeType Master Add' : 'AttendeeType Master Update'}</p>
                            </div>
                        </div>
                        <div className="m-3">
                            <div className="">
                                {
                                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 1 &&
                                    <div className="row ">
                                        {/* <div className="col-md-4">
                                            <label>Country<span className="text-red">*</span></label>
                                            <select className="form-control"
                                                value={countryID}
                                                onChange={(e) => this.setState({ countryID: e.target.value })}  >
                                                <option value={''}>Select</option>
                                                {
                                                    countryList.length > 0 && countryList.map(data => (
                                                        <option value={data.Code} key={data.Code}>{data.Description}</option>
                                                    ))
                                                }
                                            </select>
                                        </div> */}
                                        {/* <div className="col-md-4">
                                            <label>State<span className="text-red">*</span></label>
                                            <select className="form-control"
                                                value={StateID}
                                                onChange={(e) => this.setState({ StateID: e.target.value })}  >
                                                <option value={''}>Select</option>
                                                {
                                                    stateList.length > 0 && stateList.map(data => (
                                                        <option value={data.Code} key={data.Code}>{data.Description}</option>
                                                    ))
                                                }
                                            </select>
                                        </div> */}
                                        <div className="col-md-4">
                                            <label>AttendeeType Name<span className="text-red">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={AttendeeTypeName}
                                                onChange={(e) => this.setState({ AttendeeTypeName: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                            < div className="row mt-2" >
                                <div className="col-md-12">
                                    <div className="main-container container-fluid p-0 mt-2 pt-0 pb-0">
                                        <div className="mt-1 mb-4">
                                            {
                                                sessionStorage.getItem("sessionExhRegistrationID") == null &&
                                                <button className="btn btn-previous" onClick={e => this.onPreviousClick()}>
                                                    {" "}
                                                    Back{" "}
                                                </button>
                                            }

                                            <button className="btn btn-next-bot mx-2" onClick={e => this.onNextClick()} >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </>
                <Footer />
            </>)
    }

}
export default withRouter(connect(null, { fill_action, insertAttendeeTypeMaster_action, updateAttendeeTypeMaster_action, getAttendeeTypeMasterList_action })(AttendeeTypeAU));