import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { getMeetinRoomMasterList_action, insertUpdateMeetinRoomMaster_action, deleteMeetinRoomMaster_action } from '../../../../../actions/admin_module_action/master_action/manual_form_master_action/meeting_room_master_action'
import { fill_action } from '../../../../../actions/fillCombo_action'
import { BASE_URL, countryActionId, toastError, toastSuccess, visaIdTypeActionId } from "../../../../../utils/constants";
import { TimePicker } from '@hilla/react-components/TimePicker.js';
import { emailValidator, mobileNoValidator } from "../../../../../utils/Validator";
import moment from "moment";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import { data } from "jquery";
import Resizer from "react-image-file-resizer";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";
import Passport_Front_1008_VIS_903747 from "../../../../../files/Passport_Front_1008_VIS_903747.jpeg"

class MeetingRoomMasterAU extends Component {

    // initialize constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            // fields
            MeetingRoomDate: '',
            SeaterName: '',
            FromTimeSlot: '',
            ToTimeSlot: '',
            TimeSlotDuration: '',
            PriceName: '',
            PriceINR: '',
            PriceUSD: '',
            // List
            meetingRoomMasterList: [],
            MeetingRoomDateErr: '',
            SeaterNameErr: '',
            FromTimeSlotErr: '',
            ToTimeSlotErr: '',
            TimeSlotDurationErr: '',
            PriceNameErr: '',
            PriceINRErr: '',
            index: 1,
            PriceUSDErr: '',
        }
        this.OnCancel = this.OnCancel.bind(this)
        this.inputValidate = this.inputValidate.bind(this)
        // this.getFillList = this.getFillList.bind(this)
        this.renderMeetingRoomMasterList = this.renderMeetingRoomMasterList.bind(this)
        // this.onFilePick = this.onFilePick.bind(this)
        // this.onPdfSelect = this.onPdfSelect.bind(this)
        // this.resizeFile = this.resizeFile.bind(this)
        this.handleAddMore = this.handleAddMore.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.ClearValue = this.ClearValue.bind(this)
        // this.handleUpdate = this.handleUpdate.bind(this)
        // this.getDetails = this.getDetails.bind(this)
        // this.emailValidation = this.emailValidation.bind(this)
        // this.onCrossClick = this.onCrossClick.bind(this)
        // this.mobileValidation = this.mobileValidation.bind(this)
        // this.onDocDelete = this.onDocDelete.bind(this)
        // this.handleDelete = this.handleDelete.bind(this)
    }

    componentDidMount() {
        this.getMeetingRoomMasterMasterList()
    }

    // On cancel Click
    OnCancel() {
        this.props.navigate('/meetingRoomMasterList')
    }
    getMeetingRoomMasterMasterList(status) {
        this.setState({ isLoading: true })
        this.props.getMeetinRoomMasterList_action()
            .then((data) => {
                if (data.error != 1) {
                    var finalList = []
                    for (var i = 0; i < data.data[0].length; i++) {
                        for (var j = 0; j < data.data[1].length; j++) {
                            if (data.data[0][i].SeaterMeetingRoomID == data.data[1][j].SeaterMeetingRoomID && data.data[0][i].SeaterMeetingRoomID != null && data.data[1][j].SeaterMeetingRoomID != null) {
                                data.data[0][i].NoOfSeats = data.data[1][j].Name
                            }
                        }
                    }
                    finalList = data.data[0].length > 0 ? data.data[0] : []
                    this.setState({ meetingRoomMasterList: finalList })
                    this.setState({ isLoading: false })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // Input Fields Validation
    inputValidate() {
        const { MeetingRoomDate, SeaterName, FromTimeSlot, ToTimeSlot, TimeSlotDuration, PriceName, PriceINR, PriceUSD, meetingRoomMasterList, MeetingRoomDateErr, SeaterNameErr, FromTimeSlotErr, ToTimeSlotErr, TimeSlotDurationErr, PriceNameErr, PriceINRErr, PriceUSDErr } = this.state
        return new Promise((resolve, reject) => {
            var err = false
            if (MeetingRoomDate == "") {
                this.setState({ MeetingRoomDateErr: "Please Select Meeting Date" })
                err = true
            }
            if (FromTimeSlot == "") {
                this.setState({ FromTimeSlotErr: "Please Enter Meeting From Time" })
                err = true
            }
            if (FromTimeSlot == "Invalid date") {
                this.setState({ FromTimeSlotErr: "Please Enter Proper From Time" })
                err = true
            }
            if (ToTimeSlot == "") {
                this.setState({ ToTimeSlotErr: "Please Enter Meeting To Time" })
                err = true
            }
            if (ToTimeSlot == "Invalid date") {
                this.setState({ ToTimeSlotErr: "Please Enter Proper To Time" })
                err = true
            }
            if (SeaterName.trim() == "") {
                this.setState({ SeaterNameErr: "Please Enter Seater Name" })
                err = true
            }
            if (TimeSlotDuration.trim() == "") {
                this.setState({ TimeSlotDurationErr: "Please Enter TimeSlot Duration" })
                err = true
            }
            if (PriceName == "") {
                this.setState({ PriceNameErr: "Please Enter Price name" })
                err = true
            }
            if (PriceINR.trim() == "") {
                this.setState({ PriceINRErr: "Please Enter Price" })
                err = true
            }
            !err ? resolve(true) : reject(true)
        })

    }

    renderMeetingRoomMasterList() {
        const { meetingRoomMasterList } = this.state
        return (
            <ul class="list-group " style={{ position: 'relative', zIndex: 1 }}>
                {
                    meetingRoomMasterList.length > 0 && meetingRoomMasterList.map((data, index) => (
                        data.isShow &&
                        <li class="list-group-item" key={Math.random()}>
                            <div className="added-more p-3 rounded-2">
                                <h5 style={{ color: "#0056ff" }}>Added Meeting Room Master {data.index}</h5>
                                <h6 >Agency Contact </h6>
                                <div className="row mt-4">
                                    <div className="col-md-3">
                                        <h6>Meeting Room Date: {moment(data.MeetingRoomDate).format('DD-MM-YYYY')} </h6>
                                    </div>
                                    <div className="col-md-3">
                                        <h6>Seater Name: {data.SeaterName}</h6>
                                    </div>
                                    <div className="col-md-3">
                                        <h6>From TimeSlot: {moment(data.FromTimeSlot).format('HH:mm')}</h6>
                                    </div>
                                    <div className="col-md-3">
                                        <h6>To TimeSlot: {moment(data.ToTimeSlot).format('HH:mm')}</h6>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <h6>Time Slot Duration: {data.TimeSlotDuration}</h6>
                                    </div>
                                    <div className="col-md-3">
                                        <h6>Price Name: {data.PriceName}</h6>
                                    </div>
                                    <div className="col-md-3">
                                        <h6>Price(INR): {data.PriceINR}</h6>
                                    </div>
                                    <div className="col-md-3">
                                        <h6>Price(USD): {data.PriceUSD}</h6>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))
                }
            </ul>
        )
    }

    handleAddMore() {
        const { MeetingRoomDate, SeaterName, FromTimeSlot, ToTimeSlot, TimeSlotDuration, PriceName, PriceINR, PriceUSD, meetingRoomMasterList, MeetingRoomDateErr, SeaterNameErr, FromTimeSlotErr, ToTimeSlotErr, index, TimeSlotDurationErr, PriceNameErr, PriceINRErr, PriceUSDErr, } = this.state
        // this.inputValidate()
        this.inputValidate().then(res => {
            if (MeetingRoomDate != '' && SeaterName != '' && FromTimeSlot != '' && ToTimeSlot != '' && TimeSlotDuration != '' && PriceName != '' && PriceINR != '') {
                if (MeetingRoomDateErr == '' && SeaterNameErr == '' && FromTimeSlotErr == '' && ToTimeSlotErr == '' && TimeSlotDurationErr == '' && PriceNameErr == '' &&
                    PriceINRErr == '') {
                    meetingRoomMasterList.push({
                        'MeetingRoomDate': moment(MeetingRoomDate).format('YYYY-MM-DD'),
                        'SeaterName': SeaterName,
                        'FromTimeSlot': moment(new Date(`${moment(MeetingRoomDate).format('YYYY-MM-DD')} ${FromTimeSlot}`)).format(),
                        'ToTimeSlot': moment(new Date(`${moment(MeetingRoomDate).format('YYYY-MM-DD')} ${ToTimeSlot}`)).format(),
                        'TimeSlotDuration': TimeSlotDuration,
                        'PriceName': PriceName,
                        'PriceINR': PriceINR,
                        'PriceUSD': PriceUSD,
                        'index': index,
                        'isShow': true,
                    })
                    setTimeout(() => { this.ClearValue() }, 200);
                }
                else {
                    toastError('Please enter proper fields')
                }
            }
            else {
                toastError('Please enter mandatory fields')
            }
        }).catch(err => {
            toastError('Please enter proper fields')
        })
        console.log(this.state.MeetingRoomMasterList)
    }

    handleSave() {
        const { meetingRoomMasterList } = this.state
        this.setState({ isLoading: true })
        this.props.insertUpdateMeetinRoomMaster_action(meetingRoomMasterList)
            .then((data) => {
                if (data.error != 1) {
                    toastSuccess("Record Added Successfully")
                    setTimeout(() => {
                        this.setState({ isLoading: false })
                        this.props.navigate('/meetingRoomMasterList')
                    }, 300);
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    ClearValue() {
        this.setState({
            MeetingRoomDate: '',
            SeaterName: '',
            FromTimeSlot: '',
            ToTimeSlot: '',
            TimeSlotDuration: '',
            PriceName: '',
            PriceINR: '',
            PriceUSD: '',
            index: this.state.index + 1
        })
    }

    render() {
        const {
            isLoading, MeetingRoomDate, SeaterName, FromTimeSlot, ToTimeSlot, TimeSlotDuration, PriceName, PriceINR, PriceUSD, meetingRoomMasterList, MeetingRoomDateErr, SeaterNameErr, index, FromTimeSlotErr, ToTimeSlotErr, TimeSlotDurationErr, PriceNameErr, PriceINRErr, PriceUSDErr,
        } = this.state
        return (
            <>
                {
                    isLoading ? (
                        <Loader></Loader >
                    ) : (
                        null
                    )
                }
                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <div className="app-content with-dr-container com-height">
                        <AdminDrawer />
                        <div className="row">
                            <div className="step-name">
                                <p>Meeting Room Master</p>
                            </div>
                        </div>
                        <div className="m-2">
                            <div className="">

                                <div className="">
                                    <div
                                        className="card-header p-3"
                                        style={{ paddingLeft: "7px !important" }}
                                    >
                                        <h3 className="card-title" style={{ fontSize: 15 }}>
                                            Meeting Room Add
                                        </h3>
                                    </div>
                                    <div className="card-body p-2 mt-1">
                                        <div className="row">
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    Meeting Room Date<span className="text-red">*</span>
                                                </label>
                                                <DatePicker
                                                    style={{ zIndex: '99999' }}
                                                    selected={MeetingRoomDate}
                                                    dateFormat={"dd/MM/yyyy"}
                                                    className="form-control"
                                                    onChange={(date) => this.setState({ MeetingRoomDate: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())), MeetingRoomDateErr: "" })}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                // placeholderText="Date"
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{MeetingRoomDateErr}</span>}
                                            </div>
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    Seater Name<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={SeaterName}
                                                    onChange={(e) => this.setState({ SeaterName: e.target.value, SeaterNameErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{SeaterNameErr}</span>}

                                            </div>
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    From TimeSlot<span className="text-red">*</span>
                                                </label>
                                                <TimePicker
                                                    className='time-picker'
                                                    max={ToTimeSlot != '' ? ToTimeSlot : null}
                                                    style={{ width: '100%' }}
                                                    value={FromTimeSlot != undefined ? FromTimeSlot.replace(/\s+(?:AM|PM)/, "") : ''}
                                                    onChange={(e) => this.setState({ FromTimeSlot: ((e.target.value)) })}
                                                    onInput={() => this.setState({ FromTimeSlotErr: '' })}
                                                    step={60 * 15} />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{FromTimeSlotErr}</span>}
                                            </div>
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    To TimeSlot<span className="text-red">*</span>
                                                </label>
                                                <TimePicker
                                                    className='time-picker'
                                                    min={FromTimeSlot != '' ? FromTimeSlot : null}
                                                    style={{ width: '100%' }}
                                                    value={ToTimeSlot != undefined ? ToTimeSlot.replace(/\s+(?:AM|PM)/, "") : ''}
                                                    onChange={(e) => this.setState({ ToTimeSlot: ((e.target.value)) })}
                                                    onInput={() => this.setState({ ToTimeSlotErr: '' })}
                                                    step={60 * 15} />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{ToTimeSlotErr}</span>}
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    TimeSlot Duration<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={TimeSlotDuration}
                                                    onChange={(e) => this.setState({ TimeSlotDuration: e.target.value, TimeSlotDurationErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{TimeSlotDuration}</span>}
                                            </div>
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">Price Name<span className="text-red">*</span></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={PriceName}
                                                    onChange={(e) => this.setState({ PriceName: e.target.value, PriceNameErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{PriceNameErr}</span>}
                                            </div>
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">Price(INR)<span className="text-red">*</span></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={PriceINR}
                                                    onChange={(e) => this.setState({ PriceINR: e.target.value, PriceINRErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{PriceINRErr}</span>}
                                            </div>
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">Price(USD)</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={PriceUSD}
                                                    onChange={(e) => this.setState({ PriceUSD: e.target.value, PriceUSDErr: '' })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="btn-group mt-2 save-can d-block">
                                    <div className="btn-group mt-2" role="group" aria-label="Basic example">
                                        <button
                                            style={{ marginRight: 10 }}
                                            type="button"
                                            className="btn btn-primary rounded-0 d-block"
                                            // style={{ }}
                                            onClick={() => this.handleAddMore()}
                                        >
                                            {this.state.index == 1 ? 'Add' : 'Add More'}
                                        </button>
                                        {/* <button type="button" class="btn btn-primary rounded-0">Payment Listing Page</button> */}
                                    </div>

                                    <hr />
                                    {this.renderMeetingRoomMasterList()}

                                    <div className="row">
                                        <div className="btn-group mt-2 save-can d-block" role="group" style={{ float: 'right' }} aria-label="Basic example">
                                            <button
                                                type="button"
                                                className="btn btn-success rounded-0"
                                                style={{ marginRight: '10px', width: '100px' }}
                                                onClick={() => this.handleSave()}
                                                disabled={this.state.index == 1}
                                            >
                                                Save
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-warning rounded-0"
                                                style={{ marginRight: '10px', width: '100px' }}
                                                onClick={() => this.OnCancel()}
                                            >
                                                Back
                                            </button>

                                            {/* <button type="button" class="btn btn-primary rounded-0">Payment Listing Page</button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </>
            </>
        )
    }
}
export default withRouter(connect(null, { getMeetinRoomMasterList_action, insertUpdateMeetinRoomMaster_action, deleteMeetinRoomMaster_action, fill_action })(MeetingRoomMasterAU))