import { Component } from "react";
import { withRouter } from "../../../../utils/withRouter";
import { connect } from "react-redux";
import Loader from "../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import HeaderWithLogo from "../../../../common_components/header_with_logo";
import AdminDrawer from "../../../../common_components/admin_module_drawer";
import { toastError, toastSuccess } from "../../../../utils/constants";
import { getMeetingRoomApprovalList_action, updateMeetingRoomApproval_action } from "../../../../actions/admin_module_action/manual_forms_admin_action/meetingRoomApproval_action/meetingRoomApproval_action";

class MeetingRoomRequestFormApproval extends Component {

    //Initialized Constructor 
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            CompanyName: "",
            ApplicationList: [],
        }
        this.getMeetingRoomApprovalList = this.getMeetingRoomApprovalList.bind(this)
        this.onCheckedAppliactions = this.onCheckedAppliactions.bind(this)
        this.onRemarkEnter = this.onRemarkEnter.bind(this)
        this.updateEDirectoryApproval = this.updateEDirectoryApproval.bind(this)
    }

    // On Component Load
    componentDidMount() {
        this.props.location.state != null && this.props.location.state.companyName != "" && this.getMeetingRoomApprovalList(this.props.location.state.companyName)

        this.setState({
            CompanyName: this.props.location.state != null && this.props.location.state.companyName ? this.props.location.state.companyName : '',
        })
    }

    // Get Exh Badges Appliactions
    getMeetingRoomApprovalList(companyname) {
        this.setState({ isLoading: true })
        this.props.getMeetingRoomApprovalList_action(companyname)
            .then((data) => {
                if (data.error != 1) {
                    console.log("getMeetingRoomApprovalList Data", data.data)
                    data.data.map((item) => {
                        item.isSelected = false;
                        item.isReadOnly = true;
                    })
                    this.setState({
                        ApplicationList: data.data,
                        isLoading: false
                    })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // On Check Exh Badge Applications
    onCheckedAppliactions(e) {
        const { ApplicationList } = this.state
        var checked = e.target.checked
        var id = e.target.value

        ApplicationList.length > 0 && ApplicationList.map((data) => {
            if (checked) {
                if (id == data.MeetingRoomID) {
                    data.isSelected = true;
                    data.isReadOnly = false
                }
            } else {
                if (id == data.MeetingRoomID) {
                    data.isSelected = false;
                    data.isReadOnly = true
                }
            }
        })
        console.log("09000909090", ApplicationList)
        this.setState({})
    }

    /// On Remark Enter
    onRemarkEnter(e, id) {
        const { ApplicationList } = this.state
        var value = e.target.value
        var list = [...ApplicationList]
        list.length > 0 && list.map((data) => {
            if (data.MeetingRoomID == id) {
                data.Remarks = value
            }
        })
        console.log("0101010100101", list)
        this.setState({ ApplicationList: list })

    }

    // On View E-Directory
    onViewClick(id) {
        const { CompanyName } = this.state
        this.props.navigate('/meetingRoomRequestForm', { state: { is_View: true, companyName: CompanyName } })
    }

    // On Update Visa Approval
    updateEDirectoryApproval(e, key) {
        const { ApplicationList, CompanyName } = this.state
        var applicationList = ApplicationList.filter((item) => item.isSelected == true)
        var list
        // checked Application List data Preparing to store
        var list = []
        applicationList.map(data => {
            var mapData = {
                'MeetingRoomID': parseInt(data.MeetingRoomID),
                "Remark": data.Remarks
            }
            list.push(mapData)
        });
        const isCheckedRemark = list.every(item => item.Remark != '' && item.Remark != null)
        if (list.length > 0) {
            if (key == 2 ? isCheckedRemark : true) {
                this.setState({ isLoading: true })
                this.props.updateMeetingRoomApproval_action(key == 1 ? true : false, list)
                    .then((data) => {
                        if (data.error != 1) {
                            this.setState({ isLoading: false })
                            toastSuccess("Exhibitor Badge Approval Updated")
                            setTimeout(() => {
                                this.getMeetingRoomApprovalList(CompanyName)
                            }, 1000);
                        } else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } else {
                toastError("Please Enter Remark")
            }
        } else {
            toastError("Select Atleast One Application")
        }
    }

    onBackClick() {
        this.props.navigate('/meetingRoomRequestFormAdmin')
    }


    render() {
        const { isLoading, ApplicationList } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                <AdminDrawer />
                <div>
                    <>
                        <div className="main-content with-dr-container com-height">
                            <div className="">
                                <div className="row">
                                    <div className="step-name">
                                        <p>Meeting Room Request Form Approval</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="row mb-6 m-0">
                                        {/* <div className="col-lg-12 mt-2">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label htmlFor="validationCustom03">Login Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                defaultValue="jindal rectifiers"
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="validationCustom03">Badge Quota</label>
                                            <input type="text" className="form-control" />
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="validationCustom03">Balance Quota</label>
                                            <input type="text" className="form-control" />
                                        </div>
                                    </div>
                                </div> */}
                                        <div className="table-responsive mt-4">
                                            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 ">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center">Sr.No</th>
                                                        <th className="text-center" >Select</th>
                                                        <th className="text-center">Company Name</th>
                                                        <th>Name</th>
                                                        <th className="text-center">Country</th>
                                                        <th className="text-center">Status</th>
                                                        <th>Remarks</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        ApplicationList.length > 0 && ApplicationList.map((data, index) => (
                                                            <tr key={data.MeetingRoomID}>
                                                                <td className="text-center">{index + 1}</td>
                                                                <td className="text-center">
                                                                    <input type="checkbox"
                                                                        checked={data.isSelected} value={data.MeetingRoomID}
                                                                        disabled={data.StatusID == "Approved" || data.StatusID == "Disapproved"}
                                                                        onChange={(e) => this.onCheckedAppliactions(e)}
                                                                    />
                                                                </td>
                                                                <td className="text-center">
                                                                    {data.CompanyName}
                                                                </td>

                                                                <td>{data.Name}</td>
                                                                <td className="text-center">{data.Country}</td>
                                                                <td className="text-center">{data.StatusID}</td>
                                                                <td>
                                                                    <form>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            style={{ height: 40 }}
                                                                            disabled={data.isReadOnly}
                                                                            value={data.Remarks}
                                                                            onChange={(e) => this.onRemarkEnter(e, data.MeetingRoomID)}
                                                                        />
                                                                    </form>
                                                                </td>
                                                                <td>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-outline-primary"
                                                                        style={{ padding: "4px 10px" }}
                                                                        onClick={(e) => this.onViewClick(data.MeetingRoomID)}
                                                                    >
                                                                        View
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="main-container mt-2">
                                            <div className="mt-1 mb-4">
                                                <a

                                                    className="btn btn-success rounded-0 text-white m-1"
                                                    style={{ padding: "6px 25px" }}
                                                    type="submit"
                                                    onClick={(e) => this.updateEDirectoryApproval(e, 1)}
                                                >
                                                    Data Approval
                                                </a>
                                                <a

                                                    className="btn btn-dark rounded-0 text-white m-1"
                                                    type="submit"
                                                    onClick={(e) => this.updateEDirectoryApproval(e, 2)}
                                                >
                                                    Data Disapproval
                                                </a>
                                                <a

                                                    className="btn btn-primary rounded-0 text-white m-1"
                                                    type="submit"
                                                    onClick={(e) => this.onBackClick()}
                                                >
                                                    Back
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </>

                </div>

            </>
        )
    }
}
export default withRouter(connect(null, { getMeetingRoomApprovalList_action, updateMeetingRoomApproval_action })(MeetingRoomRequestFormApproval))