import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../common_components/Header';
import Drawer from '../../common_components/Drawer';
import { getExcelListData_action } from '../../actions/excelListData_action'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../utils/withRouter';
import moment from 'moment';
import Modal from "react-modal";
import Loader from '../../utils/Loader'
import { clearSessionStorage, countryActionId, toastError, toastSuccess, countryIWithCodeActionId } from '../../utils/constants'
import Pagination from '../../utils/pagination'
import { useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { fill_action } from '../../actions/fillCombo_action'
import StepHeader from './stepHeader'
import Workbook from 'react-excel-workbook'
import { detectOverflow } from '@popperjs/core';


class ExcelListData extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            geturl: "",
            excelData: [],
            columnArrayData: [],
            rowData: [],
        }

        this.onGetExcelData = this.onGetExcelData.bind(this)
        this.onCreateExcel = this.onCreateExcel.bind(this)
        // this.renderList = this.renderList.bind(this)
    }

    onGetExcelData(e) {
        const { geturl } = this.state
        // console.log("excel geturl", geturl)
        var code = ''
        if (this.props.location != null) {
            console.log('location code is :', this.props.location);
            code = this.props.location.pathname.replace('/excelListData/', '')
            console.log('final code is :', code);
        }

        var exbid = sessionStorage.getItem("sessionExhibitionID")
        var regid = sessionStorage.getItem("sessionExhRegistrationID")
        // var geturl 
        this.setState({ isLoading: true })
        this.props
            .getExcelListData_action(code)
            .then((data) => {
                this.setState({ isLoading: false })
                if (data.error != 1) {
                    // console.log("excel data", data.data)
                    this.setState({ excelData: data.data[0] })
                    this.onCreateExcel()

                }
                else {
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    // create excel file
    onCreateExcel(data) {
        // console.log("excel data 121", data)
        const { excelData } = this.state
        var list = [...excelData]
        var columnArray = [],
            rowData = [];

        // for column data 
        list.map((data, index) => {
            if (index == 0) {
                Object.keys(data).map((key, index) => {
                    columnArray.push(key)
                })
            }
        })
        // console.log("columnArray", columnArray)

        // for row data
        list.map((data, index) => {
            rowData.push(data)
        })

        // console.log("rowData", rowData)
        // this.setState({
        //     columnArrayData: columnArray,
        //     rowData: rowData
        // })

        // { this.renderList() }

        return (
            <div className="row text-center">
                <Workbook filename="registerForm.xlsx" style={{}} element={
                    <button type="button" className="btn btn-info mt-2" style={{ float:'right', marginRight:'13px' }}>
                        Export to Excel</button>

                }>
                    <Workbook.Sheet data={rowData} name="Sheet A">
                        {columnArray && columnArray.map((value, index) =>
                            <Workbook.Column label={value} value={value} />
                        )}
                    </Workbook.Sheet>
                </Workbook>
            </div>

        );
    }

    componentDidMount() {
        this.setState({
            geturl: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.geturl != " " ? this.props.location.state.geturl : '',
        })

        this.onGetExcelData()
    }

    // table list
    // renderList() {
    //     const { columnArrayData, rowData } = this.state

    //     // return (
    //     //     <>
    //     //         <table className="table table-bordered tb-action du-table">
    //     //             <thead>
    //     //                 {columnArrayData.length > 0 && columnArrayData.map(data =>
    //     //                     <tr key={Math.random()}>
    //     //                         <th style={{ width: 50 }}>{data}</th>
    //     //                     </tr>
    //     //                 )}

    //     //             </thead>
    //     //             <tbody>
    //     //                 {/* {
    //     //                     list.length > 0 ?
    //     //                         list.map((data, index) => (
    //     //                             <tr >
    //     //                                 <td className="text-center">{data.srNo}</td>
    //     //                                 <td>{data.registrationNo}</td>
    //     //                                 <td className="text-center">{data.regDate != "" ? moment(data.regDate).format('DD-MM-yyyy') : ''}</td>
    //     //                                 <td className="text-center">{data.place}</td>
    //     //                                 <td className="text-center">{data.partnerOrg}</td>
    //     //                                 <td className="text-center">{data.name}</td>
    //     //                                 <td className="text-center">{data.age}</td>
    //     //                                 <td>{data.gender}</td>
    //     //                                 <td>{data.disabilityNo}</td>
    //     //                                 <td>{data.aadhaarNo}</td>
    //     //                                 <td>{data.address}</td>
    //     //                                 <td>{data.temperature}</td>
    //     //                                 <td>{data.primaryContactNo}</td>
    //     //                                 <td>{data.alternateContactNo}</td>
    //     //                                 <td>{data.profession}</td>
    //     //                                 <td>{data.levelOfAmputation1}</td>
    //     //                                 <td>{data.levelOfAmputation2}</td>
    //     //                                 <td>{data.causeOfAmputation}</td>

    //     //                                 <td>{data.isFirstLimbUser ? "Yes" : "No"}</td>
    //     //                                 <td>{data.noOfUsedLimbs}</td>
    //     //                                 <td>{data.EndoExo}</td>

    //     //                                 <td>{data.fitmentDate != "" && data.fitmentDate != null ? moment(data.fitmentDate).format('DD-MM-yyyy') : ''}</td>
    //     //                                 <td>{data.remark}</td>


    //     //                                 <td>{data.dispatchDetails}</td>
    //     //                                 <td>{data.applicationStatusName}</td>
    //     //                                 <td>{data.Appliance}</td>
    //     //                             </tr>
    //     //                         )) : <><tr><td colSpan={12} className='text-center'>Record not found</td></tr></>
    //     //                 } */}

    //     //             </tbody>
    //     //         </table>
    //     //     </>
    //     // );
    // }

    render() {
        const { isLoading, geturl, excelData, rowData, columnArrayData } = this.state

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (null)}
                <ToastContainer />
                <>
                    {this.onCreateExcel()}
                    <div className='m-1 p-2 fixTableHead'>
                   <table className="table table-bordered table-responsive overflow-scroll" style={{height: '500px'}}>
                        <thead>
                            {/* {
                                    excelData.map((data, index) => {
                                        if (index == 0) {
                                            Object.keys(data).map((key, index) => {
                                                <th key={Math.random()} style={{ width: 50 }}>{key}</th>

                                            })
                                        }
                                    })
                                }

                                {excelData.length > 0 &&  Object.keys(excelData).map((data, index) =>
                                    <th  key={Math.random()} style={{ width: 50 }}>{data}</th>
                                )} */}
                            <tr key={Math.random()}>
                                <th style={{ width: '50px' }}>ApplicationNo</th>
                                <th style={{ width: '60px' }}> ApplicationDate</th>
                                <th style={{ width: '380px' }}>	CompanyName</th>

                                <th style={{ width: '50px' }}>FasciaName</th>
                                <th style={{ width: '60px' }}>Address1</th>
                                <th style={{ width: '380px' }}>	Address2</th>
                                
                                
                                <th style={{ width: '50px' }}>City</th>
                                <th style={{ width: '60px' }}>State</th>
                                <th style={{ width: '380px' }}>	Pin</th>

                                <th style={{ width: '50px' }}>CountryName</th>
                                <th style={{ width: '60px' }}>PersonIncharge</th>
                                <th style={{ width: '380px' }}>	LastNamePersonIncharge</th>


                                <th style={{ width: '50px' }}>Designation</th>
                                <th style={{ width: '60px' }}>MobileNo</th>
                                <th style={{ width: '380px' }}>	AlternateMobileNo</th>
                                <th style={{ width: '50px' }}>Telephone</th>
                                <th style={{ width: '60px' }}>Telephone1</th>
                                <th style={{ width: '380px' }}>	TeleFax</th>
                            
                            
                                <th style={{ width: '50px' }}>PrimaryEmail</th>
                                <th style={{ width: '60px' }}>SecondaryEmail</th>
                                <th style={{ width: '380px' }}>	Website</th>
                                <th style={{ width: '50px' }}>Name_Of_CEO_MD_President_Owner</th>
                                <th style={{ width: '60px' }}>LastName</th>
                                <th style={{ width: '380px' }}>	Mobile</th>
                         
                                <th style={{ width: '50px' }}>Email</th>
                                <th style={{ width: '60px' }}>SameAsCorrespondence:</th>
                                <th style={{ width: '380px' }}>	InvCompanyName</th>
                                <th style={{ width: '50px' }}>InvAddress1</th>
                                <th style={{ width: '60px' }}>InvAddress2</th>
                                <th style={{ width: '380px' }}>	InvCity</th>
                          
                          
                                <th style={{ width: '50px' }}>InvState</th>
                                <th style={{ width: '380px' }}>	InvPin</th>
                                <th style={{ width: '50px' }}>InvCountry</th>
                                <th style={{ width: '60px' }}>InvPersonIncharge</th>
                                <th style={{ width: '380px' }}>	InvLastNamePersonIncharge</th>
                          
                          
                                <th style={{ width: '50px' }}>InvDesignation</th>
                                <th style={{ width: '60px' }}>InvMobileNo</th>
                                <th style={{ width: '380px' }}>	InvAlternateMobileNo</th>
                                <th style={{ width: '50px' }}>InvTelephone</th>
                                <th style={{ width: '60px' }}>InvTelephone1</th>
                                <th style={{ width: '380px' }}>	InvTeleFax</th>
                          
                          
                          
                                <th style={{ width: '50px' }}>InvPrimaryEmail</th>
                                <th style={{ width: '60px' }}>InvSecondaryEmail</th>
                                <th style={{ width: '380px' }}>	InvWebsite</th>
                                <th style={{ width: '50px' }}>NameOnPanCard</th>
                                <th style={{ width: '60px' }}>PanNo</th>
                                <th style={{ width: '380px' }}>	Tan</th>
                          
                                <th style={{ width: '50px' }}>GSTNo</th>
                                <th style={{ width: '60px' }}>CompanyRegistrationNo</th>
                                <th style={{ width: '380px' }}>	IECCode</th>
                                <th style={{ width: '50px' }}>AccountName</th>
                                <th style={{ width: '60px' }}>AccountNo</th>
                                <th style={{ width: '380px' }}>	AccountType</th>
                          
                                <th style={{ width: '50px' }}>BankName</th>
                                <th style={{ width: '60px' }}>BranchName</th>
                                <th style={{ width: '380px' }}>	SwiftCode_IFSCCode</th>
                                <th style={{ width: '50px' }}>StallArea</th>
                                <th style={{ width: '60px' }}>StallTypeName</th>
                                <th style={{ width: '380px' }}>	OpenSides</th>
                          
                                <th style={{ width: '50px' }}>Comment</th>
                                <th style={{ width: '60px' }}>Is_AgreeClaim</th>
                                <th style={{ width: '380px' }}>	Is_AgreeMFG</th>
                                <th style={{ width: '50px' }}>Is_AgreeRules</th>
                                <th style={{ width: '60px' }}>Form10F</th>
                                <th style={{ width: '380px' }}>	Declaration</th>
                          
                                <th style={{ width: '50px' }}>Tax_Certificate</th>
                                <th style={{ width: '60px' }}>Installment1</th>
                                <th style={{ width: '380px' }}>	Installment2</th>
                                <th style={{ width: '50px' }}>TotalAmt</th>
                                <th style={{ width: '60px' }}>ProformaInvoice_Type</th>
                                <th style={{ width: '380px' }}>	ProformaInvoice_No</th>
                          
                                <th style={{ width: '50px' }}>ProformaInvoice_Date</th>
                                <th style={{ width: '60px' }}>ChapterNames</th>
                                <th style={{ width: '60px' }}>Agent</th>
                                <th className='pay-col' style={{ width: '100px'}}>Payment By</th>
                            
                          
                          
                          
                          
                            </tr>
                        </thead>
                        <tbody>
                    {
                        excelData.length > 0 ?
                        excelData.map((data, index) => (
                                <tr>
                                    <td>{data.ApplicationNo}</td>
                                    <td>{data.ApplicationDate != null && moment(data.ApplicationDate).format('MM-DD-YYYY').toString()}</td>
                                    <td>{data.CompanyName}</td>

                                    <td>{data.FasciaName}</td>
                                    <td>{data.Address1}</td>
                                    <td>{data.Address2}</td>
                                    <td>{data.City}</td>
                                    <td>{data.State}</td>
                                    <td>{data.Pin}</td>


                                    <td>{data.CountryName}</td>
                                    <td>{data.PersonIncharge}</td>
                                    <td>{data.LastNamePersonIncharge}</td>
                                    <td>{data.Designation}</td>


                                    <td>{data.MobileNo}</td>
                                    <td>{data.AlternateMobileNo}</td>
                                    <td>{data.Telephone}</td>
                                    <td>{data.Telephone1}</td>

                                    <td>{data.TeleFax}</td>
                                    <td>{data.PrimaryEmail}</td>
                                    <td>{data.SecondaryEmail}</td>
                                    <td>{data.Website}</td>
                                    <td>{data.Name_Of_CEO_MD_President_Owner}</td>

                                    <td>{data.LastName}</td>
                                    <td>{data.Mobile}</td>
                                    <td>{data.Email}</td>
                                    <td>{data.SameAsCorrespondence}</td>

                                    <td>{data.InvCompanyName}</td>
                                    <td>{data.InvAddress1}</td>
                                    <td>{data.InvAddress2}</td>
                                    <td>{data.InvCity}</td>

                                    <td>{data.InvState}</td>
                                    <td>{data.InvPin}</td>
                                    <td>{data.InvCountry}</td>
                                    <td>{data.InvPersonIncharge}</td>


                                    <td>{data.InvLastNamePersonIncharge}</td>
                                    <td>{data.InvDesignation}</td>
                                    <td>{data.InvMobileNo}</td>
                                    <td>{data.InvAlternateMobileNo}</td>


                                    <td>{data.InvTelephone}</td>
                                    <td>{data.InvTelephone1}</td>
                                    <td>{data.InvTeleFax}</td>
                                    <td>{data.InvPrimaryEmail}</td>


                                    <td>{data.InvSecondaryEmail}</td>
                                    <td>{data.InvWebsite}</td>
                                    <td>{data.NameOnPanCard}</td>
                                    <td>{data.PanNo}</td>

                                    <td>{data.Tan}</td>
                                    <td>{data.GSTNo}</td>
                                    <td>{data.CompanyRegistrationNo}</td>
                                    <td>{data.IECCode}</td>

                                    <td>{data.AccountName}</td>
                                    <td>{data.AccountNo}</td>
                                    <td>{data.AccountType}</td>
                                    <td>{data.BankName}</td>


                                    <td>{data.BranchName}</td>
                                    <td>{data.SwiftCode_IFSCCode}</td>
                                    <td>{data.StallArea}</td>
                                    <td>{data.StallTypeName}</td>


                                    <td>{data.OpenSides}</td>
                                    <td>{data.Comment}</td>
                                    <td>{data.Is_AgreeClaim}</td>
                                    <td>{data.Is_AgreeMFG}</td>

                                    <td>{data.Is_AgreeRules}</td>
                                    <td>{data.Form10F}</td>
                                    <td>{data.Declaration}</td>
                                    <td>{data.Tax_Certificate}</td>


                                    <td>{data.Installment1}</td>
                                    <td>{data.Installment2}</td>
                                    <td>{data.TotalAmt}</td>
                                    <td>{data.ProformaInvoice_Type}</td>


                                    <td>{data.ProformaInvoice_No}</td>
                                    <td>{data.ProformaInvoice_Date != null && moment(data.ProformaInvoice_Date).format('MM-DD-YYYY').toString()}</td>

                                    <td>{data.ChapterNames}</td>
                                    <td>{data.AgentCompanyName?? ''}</td>
                                    <td>{data.PaidBySelf}</td>

                                </tr>
                            )) : <><tr><td colSpan={3} className='text-center'>Record not found</td></tr></>
                    }

                </tbody>
                    </table>
                   </div>
                </>
            </>
        );
    }
}

export default withRouter(connect(null, {
    getExcelListData_action
})(ExcelListData));

