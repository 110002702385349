import { axiosGet, axiosPost } from "../../../../react_axios/Axios";
import { updateExhibitionPaymentConfigrationUrl, headers, UPDATE_PAYMENT_CONFIG } from "../../../../utils/constants";


export const updateExhibitionPaymentConfigration_action = (ExihibitionID,paymentModuleList) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExihibitionID,
            paymentModuleList: paymentModuleList
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosPost(updateExhibitionPaymentConfigrationUrl, params, headers)

        dispatch({
            type: UPDATE_PAYMENT_CONFIG,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}