import { axiosGet, axiosPost } from "../../../../react_axios/Axios";
import { headers, getHostAndHostessMasterListUrl, insertUpdateHostAndHostessMasterUrl, deleteHostAndHostessMasterUrl, GET_HOST_AND_HOSTESS_MASTER_LIST, INSERT_UPDATE_HOST_AND_HOSTESS_MASTER, DELETE_HOST_AND_HOSTESS_MASTER } from "../../../../utils/constants";


export const insertUpdateHostAndHostessMaster_action = (AgencyContactList, CategoryList) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            AgencyContactList: AgencyContactList,
            CategoryList: CategoryList
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosPost(insertUpdateHostAndHostessMasterUrl, params, headers)

        dispatch({
            type: INSERT_UPDATE_HOST_AND_HOSTESS_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getHostAndHostessMasterList_action = () => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getHostAndHostessMasterListUrl, params, headers)

        dispatch({
            type: GET_HOST_AND_HOSTESS_MASTER_LIST,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const deleteHostAndHostessMaster_action = (AgencyHostAndHostessID, HostAndHostessCategoryID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            AgencyHostAndHostessID: AgencyHostAndHostessID,
            HostAndHostessCategoryID: HostAndHostessCategoryID
        }
        console.log("deleteExhibitionDocument_action Action------->", params)

        const res = await axiosGet(deleteHostAndHostessMasterUrl, params, headers)

        dispatch({
            type: DELETE_HOST_AND_HOSTESS_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}