import { axiosGet, axiosPost } from "../../../react_axios/Axios";
import {
  ADD_CHAPTERS_MASTER,
  UPDATE_CHAPTERS_MASTER,
  addChapterMasterUrl,
  updateChapterMasterUrl, headers, deleteChapterMasterUrl, DELETE_CHAPTERS_MASTER,
  GET_CHAPTERS_PARENT_DETAILS_MASTER, getChapterParentDetailsUrl
} from "../../../utils/constants";

export const addChapterMaster_action =
  (Level, SrNo, ParentChapterID, ChapterName, IsCheckbox, ParentChapterIDs) =>
    async (dispatch) => {
      try {
        const params = {
          UserID: JSON.parse(sessionStorage.getItem("loginDataSS")).UserID,
          level: Level,
          SrNo: SrNo,
          ParentChapterID: ParentChapterID,
          ChapterName: ChapterName,
          IsCheckbox: IsCheckbox,
          ParentChapterIDs: ParentChapterIDs,
        };
        console.log("addChapterMaster_action Action------->", params);

        const res = await axiosPost(addChapterMasterUrl, params, headers);

        dispatch({
          type: ADD_CHAPTERS_MASTER,
          payload: res,
        });
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    };

export const deleteChapterMaster_action = (ChapterID) => async (dispatch) => {
  try {
    const params = {
      UserId: JSON.parse(sessionStorage.getItem("loginDataSS")).UserID,
      ChapterID: ChapterID,
    };
    console.log("addChapterMaster_action Action------->", params);

    const res = await axiosGet(deleteChapterMasterUrl, params, headers);

    dispatch({
      type: DELETE_CHAPTERS_MASTER,
      payload: res,
    });
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateChapterMaster_action =
  (ChapterID, ChapterName, level, ParentChapterID, ParentChapterIDs) =>
    async (dispatch) => {
      try {
        const params = {
          UserId: JSON.parse(sessionStorage.getItem("loginDataSS")).UserID,
          ChapterID: ChapterID,
          ChapterName: ChapterName,
          level: level,
          ParentChapterID: ParentChapterID,
          ParentChapterIDs: ParentChapterIDs,
        };
        console.log("updateChapterMaster_action Action------->", params);

        const res = await axiosPost(updateChapterMasterUrl, params, headers);

        dispatch({
          type: UPDATE_CHAPTERS_MASTER,
          payload: res,
        });
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    }

export const getChapterParentDetails_action = (ChapterID) => async (dispatch) => {
  try {
    const params = {
      UserId: JSON.parse(sessionStorage.getItem("loginDataSS")).UserID,
      ChapterID:ChapterID
    };

    const res = await axiosGet(getChapterParentDetailsUrl, params, headers);

    dispatch({
      type: GET_CHAPTERS_PARENT_DETAILS_MASTER,
      payload: res,
    });
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
};