import { axiosGet, axiosPost } from "../../../../react_axios/Axios";
import { getEmailConfigrationDetailsUrl, insertUpdateEmailConfigrationUrl, INSERT_UPDATE_EMAIL_CONFIGRATION, GET_EMAIL_CONFIGRATION_DETAILS, headers } from "../../../../utils/constants";


export const getEmailConfigrationDetails_action = (ExihibitionID, EmailConfigrationId) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExihibitionID,
            EmailConfigrationId: EmailConfigrationId != '' ? EmailConfigrationId : null
        }

        const res = await axiosGet(getEmailConfigrationDetailsUrl, params, headers)

        dispatch({
            type: GET_EMAIL_CONFIGRATION_DETAILS,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const insertUpdateEmailConfigration_action = (ExihibitionID, EmailConfigrationId, HostName, PortNo, User, Password, exh_CC, exh_BCC) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExihibitionID,
            EmailConfigrationId: EmailConfigrationId,
            HostName: HostName,
            PortNo: PortNo,
            User: User,
            Password: Password,
            exh_CC: exh_CC != '' ? exh_CC : null,
            exh_BCC: exh_BCC != '' ? exh_BCC : null,
        }

        const res = await axiosPost(insertUpdateEmailConfigrationUrl, params, headers)

        dispatch({
            type: INSERT_UPDATE_EMAIL_CONFIGRATION,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}