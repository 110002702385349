import { async } from "q";
import { axiosGet } from "../../../react_axios/Axios";
import { GET_MANUAL_FORMS_ACTION_TYPE, getManualFormsUrl, headers } from "../../../utils/constants";




export const getManualForms_action = () => async (dispatch) => {
    try {
        const params = {
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhregistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
            AccountID: JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID,
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
        }
        console.log("getManualForms_action Action------->", params)
        const res = await axiosGet(getManualFormsUrl, params, headers)

        dispatch({
            type: GET_MANUAL_FORMS_ACTION_TYPE,
            payload: res,
        });
        console.log(res)
        return Promise.resolve(res)

    } catch (error) {
        Promise.reject(error)
    }
}