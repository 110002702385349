import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../../utils/withRouter';
import Loader from '../../../../utils/Loader'
// import { clearSessionStorage } from '../../../utils/constants'
import Header from '../../../../common_components/Header';
// import { emailValidator } from '../../../utils/Validator'
import Modal from "react-modal";
import Footerwithdr from '../../../../common_components/Footerwithdr';
import HeaderWithLogo from '../../../../common_components/header_with_logo';
import AdminDrawer from '../../../../common_components/admin_module_drawer';
import { fill_action } from '../../../../actions/fillCombo_action'
// import ReceiptList from '../registration_receipt/ReceiptList';
import { Link } from "react-router-dom";

import { getAdminAdditionalSummaryList_action } from "../../../../actions/admin_module_action/admin_dasboard_action/admin_dashboard_summary_action/admin_dashboard_summary_action"
import { getAdminDashboardData_action } from "../../../../actions/admin_module_action/admin_dasboard_action/admin_dashboard_action"
import { data } from 'jquery';

class AdditionalSummary extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            additionalSummaryList: [],
        }
        this.record = this.record.bind(this)
        this.getAdditionalSummaryList = this.getAdditionalSummaryList.bind(this)

    }

    componentDidMount() {
        this.getAdditionalSummaryList()
    }
    // Get suummary list
    getAdditionalSummaryList() {
        this.setState({ isLoading: true })
        this.props.getAdminAdditionalSummaryList_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log("Admin Data ------->", data.data)
                    // data.data.length > 0 && data.data.map((dat) => (
                    //     dat.Total = dat.Applied + dat.Agent + dat.Waiting + dat.Incomplete
                    // ))
                    var temptotalDoubleStoreyArea = 0
                    var temptotalDoubleStoreyExhibitor = 0
                    var temptotalITAMMAandTMMAArea = 0
                    var tempttotalITAMMAandTMMAExhibitor = 0
                    var temptotalPendingFormsArea = 0
                    var temptotalPendingFormsExhibitor = 0
                    var obj = {}

                    for (var i = 0; i < data.data.length; i++) {
                        temptotalDoubleStoreyArea = temptotalDoubleStoreyArea + parseInt(data.data[i]['AREA_Double Storey'])
                        temptotalDoubleStoreyExhibitor = temptotalDoubleStoreyExhibitor + parseInt(data.data[i]['EXHIBITORS_Double Storey'])
                        temptotalITAMMAandTMMAArea = temptotalITAMMAandTMMAArea + parseInt(data.data[i]['AREA_ITAMMA And TMMA'])
                        tempttotalITAMMAandTMMAExhibitor = tempttotalITAMMAandTMMAExhibitor + parseInt(data.data[i]['EXHIBITORS_ITAMMA And TMMA'])
                        temptotalPendingFormsArea = temptotalPendingFormsArea + parseInt(data.data[i]['AREA_Pending Forms'])
                        temptotalPendingFormsExhibitor = temptotalPendingFormsExhibitor + parseInt(data.data[i]['EXHIBITORS_Pending Forms'])
                    }
                    obj.Details = 'TOTAL'
                    obj['AREA_Double Storey'] = temptotalDoubleStoreyArea
                    obj['EXHIBITORS_Double Storey'] = temptotalDoubleStoreyExhibitor
                    obj['AREA_ITAMMA And TMMA'] = temptotalITAMMAandTMMAArea
                    obj['EXHIBITORS_ITAMMA And TMMA'] = tempttotalITAMMAandTMMAExhibitor
                    obj['AREA_Pending Forms'] = temptotalPendingFormsArea
                    obj['EXHIBITORS_Pending Forms'] = temptotalPendingFormsExhibitor

                    if (data.data.length > 0 && data.data[data.data.length - 1].Details != 'TOTAL') {
                        data.data.push(obj)
                    }

                    this.setState({
                        isLoading: false,
                        additionalSummaryList: data.data,
                        // totalAgent: temptotalAgent,
                        // totalApplied: temptotalApplied,
                        // totalAvailable: temptotalAvailable,
                        // totalBalance: temptotalBalance,
                        // totalWaiting: temptotalWaiting,
                        // totalOverall: temptotalOverall,
                        // totalBuffer: temptotalBuffer,

                        // pendingPaymentCount: data.data[0]['Count_Of_Payment']
                    })
                    // console.log("Data ------->", this.state.applicationSummaryList)

                    setTimeout(() => {
                        // console.log("Admin Data ------->", this.state.totalAgent)

                    }, 500);
                } else {
                    this.setState({ isLoading: false })
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }


    record(list) {
        return (
            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
                <thead>
                    <tr>
                        <th className="text-center">Details</th>
                        <th colSpan={2} className="text-center">Double Storey</th>
                        <th colSpan={2} className="text-center">ITAMMA and TMMA</th>
                        <th colSpan={2} className="text-center">Pending Forms</th>

                    </tr>
                    <tr>
                        <th className="text-center"></th>
                        <th className="text-center">EXHIBITORS</th>
                        <th className="text-center">AREA</th>
                        <th className="text-center">EXHIBITORS</th>
                        <th className="text-center">AREA</th>
                        <th className="text-center">EXHIBITORS</th>
                        <th className="text-center">AREA</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.length > 0 ? <>{
                            list.map((data, i) => (
                                <tr key={Math.random()}>
                                    <td className="text-center">{i == list.length - 1 ? <strong>{data.Details}</strong> : data.Details}</td>
                                    <td className="text-center">{i == list.length - 1 ? <strong>{data['EXHIBITORS_Double Storey']}</strong> : data['EXHIBITORS_Double Storey']}</td>
                                    <td className="text-center">{i == list.length - 1 ? <strong>{data['AREA_Double Storey']}</strong> : data['AREA_Double Storey']}</td>
                                    <td className="text-center">{i == list.length - 1 ? <strong>{data['EXHIBITORS_ITAMMA And TMMA']}</strong> : data['EXHIBITORS_ITAMMA And TMMA']}</td>
                                    <td className="text-center">{i == list.length - 1 ? <strong>{data['AREA_ITAMMA And TMMA']}</strong> : data['AREA_ITAMMA And TMMA']}</td>
                                    <td className="text-center">{i == list.length - 1 ? <strong>{data['EXHIBITORS_Pending Forms']}</strong> : data['EXHIBITORS_Pending Forms']}</td>
                                    <td className="text-center">{i == list.length - 1 ? <strong>{data['AREA_Pending Forms']}</strong> : data['AREA_Pending Forms']}</td>
                                </tr>
                            ))}
                        </> :
                            <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                    }
                </tbody>
            </table>)
    }

    render() {
        const { isLoading, additionalSummaryList } = this.state
        return (
            <>

                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <AdminDrawer />

                    {/*app-content open*/}
                    {/* <div className="app-content with-dr-container com-height"> */}
                    {/* <AdminDashboardTopmenu /> */}

                    <div className="row mt-3">
                        <div className="table-responsive mb-3">
                            {this.record(additionalSummaryList)}
                        </div>
                    </div>

                    {/* </div> */}
                </>

                {/* <Footerwithdr /> */}

            </>
        );
    }
}

export default withRouter(connect(null, { getAdminAdditionalSummaryList_action, getAdminDashboardData_action })(AdditionalSummary));
