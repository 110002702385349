import { ADD_EXHIBITOR_BADGE_ACTION_TYPE, DEL_EXHIBITOR_BADGE_ACTION_TYPE, DEL_EXHIBITOR_BADGE_DOC_ACTION_TYPE, GET_EXHIBITOR_BADGE_ACTION_TYPE, GET_EXHIBITOR_BADGE_DETAILS_ACTION_TYPE, SUBMIT_EXHIBITOR_BADGES_ACTION_TYPE, UPDATE_EXHIBITOR_BADGE_ACTION_TYPE } from "../../../../../utils/constants";


const initialState = [];

function exhibitorBardgeReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case GET_EXHIBITOR_BADGE_ACTION_TYPE:
            return [...state, payload];
        case ADD_EXHIBITOR_BADGE_ACTION_TYPE:
            return [...state, payload];
        case GET_EXHIBITOR_BADGE_DETAILS_ACTION_TYPE:
            return [...state, payload];
        case UPDATE_EXHIBITOR_BADGE_ACTION_TYPE:
            return [...state, payload];
        case DEL_EXHIBITOR_BADGE_ACTION_TYPE:
            return [...state, payload];
        case DEL_EXHIBITOR_BADGE_DOC_ACTION_TYPE:
            return [...state, payload];
        case SUBMIT_EXHIBITOR_BADGES_ACTION_TYPE:
            return [...state, payload];
        default:
            return state;
    }
};

export default exhibitorBardgeReducer;