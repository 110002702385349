import { GET_EXHIBITOR_DETAILS_ACTION_TYPE,UPDATE_EXHIBITOR_DETAILS_ACTION_TYPE } from "../../../../../utils/constants";


const initialState = [];

function ExhibitorDetailsReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_EXHIBITOR_DETAILS_ACTION_TYPE:
      return [...state, payload];
      case UPDATE_EXHIBITOR_DETAILS_ACTION_TYPE:
        return [...state, payload];
    default:
      return state;
  }
};

export default ExhibitorDetailsReducer;