import { Component } from "react";
import Loader from "../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action } from '../../../../actions/fillCombo_action';
import { cityActionId, stallTypeActionId, stallListActionId, countryActionId, exhibitionActionId, toastError, toastSuccess } from "../../../../utils/constants";
import { emailValidator, panNoValidator, tanNoValidator, notMendetoryEmailValidator, pincodeValidator } from '../../../../utils/Validator'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import Pagination from "../../../../utils/pagination";
import AdminDrawer from "../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../common_components/header_with_logo";
import { useNavigate } from "react-router-dom";
import ExhibitorDrawer from "../../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../../common_components/exhibitor_module_header";
import { data } from "jquery";
// import { getStateMasterDetails_action, insertUpdateStateMaster_action } from "../../../../actions/admin_module_action/master_action/hall_master_action";
import { insertStateMaster_action, updateStateMaster_action, getStateMasterList_action } from "../../../../actions/admin_module_action/master_action/state_master_action";
import Footer from "../../../../common_components/Footer";


class StateAU extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            currentPage: 1,
            is_add: false,
            is_update: false,
            StateID: '',
            countryID: '',
            countryList: [],
            Status: '',
            stateName: '',
            StateErrMsg: '',
            countryErrMsg: '',
        }

        this.getStateMasterList = this.getStateMasterList.bind(this)
        this.onPreviousClick = this.onPreviousClick.bind(this)
        this.insertStateMaster = this.insertStateMaster.bind(this)
        this.updateStateMaster = this.updateStateMaster.bind(this)
        this.onNextClick = this.onNextClick.bind(this)
        this.getFillList = this.getFillList.bind(this)

    }

    componentDidMount() {
        this.setState({
            is_add: this.props.location.state != null && this.props.location.state.is_add != null ? this.props.location.state.is_add : false,
            is_update: this.props.location.state != null && this.props.location.state.is_update != null ? this.props.location.state.is_update : false,
            StateID: this.props.location.state != null && this.props.location.state.StateID != null ? this.props.location.state.StateID : '',
        })

        this.getFillList(countryActionId, '', '', '', '', '').then(() => {
            this.getStateMasterList()
        })
    }

    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        const { } = this.state
        return new Promise((resolve, reject) => {
            this.setState({ isLoading: true })
            this.props
                .fill_action(actionID, exhibitionID, moduleID, keyValue, value)
                .then((data) => {
                    if (data.error != 1) {
                        // country list
                        if (actionID === countryActionId) {
                            console.log("country data", data.data)
                            // var internationalCountryData = data.data.length > 0 && data.data.filter((item) => item.Code != "91")
                            this.setState({
                                countryList: data.data
                            })
                        }
                        resolve(true)
                    }
                    this.setState({ isLoading: false })
                })
                .catch((e) => {
                    console.log(e);
                });
        })
    }

    getStateMasterList() {
        this.setState({ isLoading: true })
        const { countryID, stateName, Status, StateID } = this.state
        console.log('CountryID', StateID)
        this.props.getStateMasterList_action(countryID, stateName, null)
            .then((data) => {
                if (data.error != 1) {
                    console.log(data.data[0])
                    data.data[0].length > 0 && data.data[0].map((item) => {
                        if (item.StateID == StateID) {
                            this.setState({ stateName: item.StateName, countryID: item.Countryid })
                        }
                    })
                    // this.setState({ stateMasterList: data.data[0] })
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    onPreviousClick() {
        this.props.navigate('/stateMasterList',)
    }

    insertStateMaster() {
        const { isLoading, exhibitionID, stateName, countryID, hallName, StateErrMsg, is_update, StateNo, Panno } = this.state
        // var StateErrMsg = StateNoValidator(StateNo)
        if (countryID != null && countryID != '' && stateName != null && stateName != '') {
            this.setState({ isLoading: true })
            this.props.insertStateMaster_action(countryID, stateName)
                .then((data) => {
                    if (data.error != 1) {
                        console.log(data.data)
                        this.props.navigate('/stateMasterList',)
                        toastSuccess("added successfully")
                        this.setState({ isLoading: false })
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })

        }
        else {
            toastError("Please enter mandatory fields")
        }
    }

    updateStateMaster() {
        const { isLoading, exhibitionID, StateID, StateErrMsg, countryID, stateName, StateNo, Panno, hallName, is_update } = this.state
        // var StateErrMsg = StateNoValidator(StateNo)
        if (countryID != null && countryID != '' && stateName != null && stateName != '' && StateID != null && StateID != '') {
            this.setState({ isLoading: true })
            this.props.updateStateMaster_action(countryID, StateID, stateName)
                .then((data) => {
                    if (data.error != 1) {
                        console.log(data.data)
                        this.props.navigate('/stateMasterList',)
                        toastSuccess("update successful")
                        this.setState({ isLoading: false })
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        else {
            toastError("Please enter mandatory fields")
        }
    }

    onNextClick() {
        const { is_update } = this.state;
        if (is_update == true) {
            this.updateStateMaster();
        } else {
            this.insertStateMaster();
        }
    }

    render() {
        const { isLoading, exhibitionID, currentPage, countryID, StateNo, stateName, countryList, Panno } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <div className="app-content with-dr-container com-height">
                        <AdminDrawer />
                        <div className="row">
                            <div className="step-name">
                                <p>{this.state.is_add == true ? 'State Master Add' : 'State Master Update'}</p>
                            </div>
                        </div>
                        <div className="m-3">
                            <div className="">
                                {
                                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 1 &&
                                    <div className="row ">
                                        <div className="col-md-3">
                                            <label>Country<span className="text-red">*</span></label>
                                            <select className="form-control"
                                                value={countryID}
                                                onChange={(e) => this.setState({ countryID: e.target.value })}  >
                                                <option value={''}>Select</option>
                                                {
                                                    countryList.length > 0 && countryList.map(data => (
                                                        <option value={data.Code} key={data.Code}>{data.Description}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="col-md-4">
                                            <label>State Name<span className="text-red">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={stateName}
                                                onChange={(e) => this.setState({ stateName: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                            < div className="row mt-2" >
                                <div className="col-md-12">
                                    <div className="main-container container-fluid p-0 mt-2 pt-0 pb-0">
                                        <div className="mt-1 mb-4">
                                            {
                                                sessionStorage.getItem("sessionExhRegistrationID") == null &&
                                                <button className="btn btn-previous" onClick={e => this.onPreviousClick()}>
                                                    {" "}
                                                    Back{" "}
                                                </button>
                                            }

                                            <button className="btn btn-next-bot mx-2" onClick={e => this.onNextClick()} >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </>
                <Footer />
            </>)
    }

}
export default withRouter(connect(null, { fill_action, insertStateMaster_action, updateStateMaster_action, getStateMasterList_action })(StateAU));