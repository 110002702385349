import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../common_components/Header';
import Drawer from '../../common_components/Drawer';
import { } from '../../actions/registration_action'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../utils/withRouter';
import moment from 'moment';
import Modal from "react-modal";
import Loader from '../../utils/Loader'
import { clearSessionStorage, countryActionId, toastError, toastSuccess } from '../../utils/constants'
import Pagination from '../../utils/pagination'
import { useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { fill_action } from '../../actions/fillCombo_action'
import StepHeader from './stepHeader';
import PrimaryChapter from './primary_chapter';
import Description from './description';
import Registration from './registration';
import LastSubmit from './last_submit'


/* 
 * mapDispatchToProps
*/
// const mapDispatchToProps = dispatch => ({
//     loginAction: () => dispatch(loginAction())
// })

/* 
 * mapStateToProps
*/
const mapStateToProps = state => ({
    ...state
})

class MainRegistration extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
        }

    }

    componentDidMount() {
                sessionStorage.setItem("renderPage", "registerForm")

    }



    render() {
        const { isLoading, } = this.state


        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <>
                    {/* PAGE */}
                    <div className="">
                        <div className="">
                           
                            <div className="side-app">
                                    <div className="main-container top-form-name">
                                        <div className="row ">
                                            <div className="step-name">
                                                <p>Exhibitor Registration</p>
                                            </div>
                                        </div>
                                        <StepHeader />
                                    </div>
                                        {/* <form
                                            className="needs-validation"
                                            noValidate=""
                                            style={{ height: 510, overflow: "scroll", overflowX: "hidden" }}
                                        > */}
                                        {/* <Description /> */}
                                        {/* { <PrimaryChapter /> } */}

                                        {sessionStorage.getItem("renderPage") == 'registerForm' &&
                                            <Registration />
                                        }
                                        {
                                            sessionStorage.getItem("renderPage") == 'primaryChapter' &&
                                            <PrimaryChapter />
                                        }
                                        {
                                            sessionStorage.getItem("renderPage") == 'description' &&
                                            <Description />
                                        } 
                                        {/* {<LastSubmit/>} */}
                                        {/* {<Description /> } */}
                                        {/* </form> */}
                                </div>
                            {/*app-content open*/}
                            {/* <div className="main-content app-content mt-0">
                                
                            </div> */}
                        </div>
                    </div>
                </>

            </>
        );
    }
}

export default withRouter(connect(null, {

})(MainRegistration));

