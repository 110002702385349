import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../common_components/Header';
import Drawer from '../../common_components/Drawer';
import {
    registrationOneAdd_action,
} from '../../actions/registration_action'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../utils/withRouter';
import moment from 'moment';
import Modal from "react-modal";
import Loader from '../../utils/Loader'
import { clearSessionStorage, countryActionId, toastError, toastSuccess } from '../../utils/constants'
import Pagination from '../../utils/pagination'
import { useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { fill_action } from '../../actions/fillCombo_action'

/* 
 * mapDispatchToProps
*/
// const mapDispatchToProps = dispatch => ({
//     loginAction: () => dispatch(loginAction())
// })

/* 
 * mapStateToProps
*/
const mapStateToProps = state => ({
    ...state
})

class StepHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            countryId: '',
            countryName: '',
            countryList: [],
        }
        this.onSelectCountryId = this.onSelectCountryId.bind(this)
        this.getFillList = this.getFillList.bind(this)

    }

    componentDidMount() {
        console.log('current active steper is : ', sessionStorage.getItem("renderPage"));
        sessionStorage.setItem("renderPage", 'countrySelect')

        this.getFillList(countryActionId, '', '', '', '', '')
    }

    onSelectCountryId(e) {
        e.preventDefault();
        const { countryId, countryList, countryName } = this.state
        countryList.map(data => {

            data.Code == countryId && this.setState({ countryName: data.Description })
        });

        // to pass country Id to registration one page
        setTimeout(() => {
            countryId != '' ? this.props.navigate('/registration', { state: { countryId: countryId, countryName: countryName } }) : toastError("Please select country")

        }, 300);
    }

    // get fill List dropdown  
    getFillList(actionID, exhibitionID, moduleID, keyValue, value) {
        this.setState({ isLoading: true })
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value)
            .then((data) => {
                if (data.error != 1) {
                    // country list
                    if (actionID === countryActionId) {
                        console.log("country data", data.data)
                        this.setState({
                            countryList: data.data, isLoading: false,
                        })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    render() {
        const { isLoading, countryId, countryList } = this.state


        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <>

                    <div className="main-container form-start container-fluid p-0">
                        <div className='common-steper'>
                            <div className="row ">
                                <div className="step-name">
                                    <p>Exhibitor Registration</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="steper-name">
                                    <div className="">
                                        <ol className="c-stepper">
                                            <li className="c-stepper__item">
                                                <span className={sessionStorage.getItem("renderPage") == 'registerForm' || sessionStorage.getItem("renderPage") == 'primaryChapter' || sessionStorage.getItem("renderPage") == 'description' || sessionStorage.getItem("renderPage") == 'summary' ? "step-no" : 'step-noInactive'}
                                                //  style={sessionStorage.getItem("renderPage") ==  'countrySelect' ? 
                                                //   {   background: "#1170e4" }:{   background: "#ddd" } }
                                                >
                                                    1</span>
                                                <h3
                                                    className="c-stepper__title"
                                                    style={{ fontWeight: 600, width: '150px' }}
                                                >
                                                    Company Information
                                                </h3>
                                            </li>
                                            <li className="c-stepper__item">
                                                <span className={sessionStorage.getItem("renderPage") == 'primaryChapter' || sessionStorage.getItem("renderPage") == 'description' || sessionStorage.getItem("renderPage") == 'summary' ? "step-no" : 'step-noInactive'}
                                                //  style={sessionStorage.getItem("renderPage") ==  'primaryChapter'  ? 
                                                //   {   background: "#1170e4" }:{   background: "#ddd" } }
                                                >
                                                    2
                                                </span>
                                                <h3 className="c-stepper__title" style={{ fontWeight: 600, width: '280px' }}>Product Chapters and Booth Specification</h3>
                                            </li>
                                            <li className="c-stepper__item">
                                                <span className={sessionStorage.getItem("renderPage") == 'description' || sessionStorage.getItem("renderPage") == 'summary' ? "step-no" : 'step-noInactive'}
                                                //  style={sessionStorage.getItem("renderPage") !=  'description' 
                                                //  &&  { background: "#ddd" }}
                                                >
                                                    3
                                                </span>
                                                <h3 className="c-stepper__title" style={{ fontWeight: 600, width: '70px' }}>Estimates</h3>
                                            </li>
                                            <li className="c-stepper__item">
                                                <span className={sessionStorage.getItem("renderPage") == 'summary' ?
                                                    "step-no" : 'step-noInactive'}
                                                // style={sessionStorage.getItem("renderPage") !=  'summary' &&
                                                //   { background: "#ddd" }}
                                                >
                                                    4
                                                </span>
                                                <h3 className="c-stepper__title" style={{ fontWeight: 600, width: '180px' }}>Summary & Submit</h3>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </>
            </>
        );
    }
}

export default withRouter(connect(null, {
    registrationOneAdd_action, fill_action
})(StepHeader));

