import { axiosGet, axiosPost } from "../../../react_axios/Axios";
import { getRoleMasterListUrl, getRoleMasterDetailsDefaultUrl, GET_ROLE_MASTER_DETAILS_DEFAULT, getRoleMasterDetailsUrl, addRoleMasterUrl, updateRoleMasterUrl, deleteRoleMasterUrl, GET_ROLE_MASTER_DETAILS, GET_ROLE_MASTER_LIST, ADD_ROLE_MASTER, UPDATE_ROLE_MASTER, DELETE_ROLE_MASTER, headers } from "../../../utils/constants";

export const getRoleMasterList_action = () => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getRoleMasterListUrl, params, headers)

        dispatch({
            type: GET_ROLE_MASTER_LIST,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const addRoleMaster_action = (Name, moduleList, reportList) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            // RoleID: null,
            RoleName: Name,
            moduleList: moduleList,
            reportList: reportList
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosPost(addRoleMasterUrl, params, headers)

        dispatch({
            type: ADD_ROLE_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const updateRoleMaster_action = (RoleID, Name, moduleList, reportList) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            RoleID: RoleID,
            RoleName: Name,
            moduleList: moduleList,
            reportList: reportList
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosPost(updateRoleMasterUrl, params, headers)

        dispatch({
            type: UPDATE_ROLE_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getRoleMasterDetails_action = (RoleID,) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            RoleID: RoleID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),

        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getRoleMasterDetailsUrl, params, headers)

        dispatch({
            type: GET_ROLE_MASTER_DETAILS,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getRoleMasterDetailsDefualt_action = (RoleID,) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            // RoleID: RoleID,
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getRoleMasterDetailsDefaultUrl, params, headers)

        dispatch({
            type: GET_ROLE_MASTER_DETAILS_DEFAULT,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const deleteRoleMaster_action = (RoleID,) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            RoleID: RoleID,
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(deleteRoleMasterUrl, params, headers)

        dispatch({
            type: DELETE_ROLE_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}