import { axiosGet, axiosPost } from "../../../../../react_axios/Axios";
import { ADD_E_DIRECTORY_ACTION_TYPE, GET_E_DIRECTORY_DETAILS_ACTION_TYPE, GET_E_DIRECTORY_LIST_ACTION_TYPE, SUBMIT_E_DIRECTORY_LIST_ACTION_TYPE, UPDATE_E_DIRECTORY_ACTION_TYPE, addEDirectoryUrl, delDirectoryUrl, getEDirectoryDetailsUrl, getEdirectoryListUrl, headers, submitEDirectoryListUrl, updateEDirectoryUrl } from "../../../../../utils/constants";



export const getEdirectoryList_action = () => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhRegistrationID: sessionStorage.getItem('ExhRegistrationIDSS')
        }
        console.log("getEdirectoryList_action Action------->", params)
        const res = await axiosGet(getEdirectoryListUrl, params, headers)

        dispatch({
            type: GET_E_DIRECTORY_LIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const addEDirectory_action = (EDirectoryId, companyName, add, altAdd, pin, city, regState,
    countryId, personInchName, Designation, mobNo, telNo, fax, primaryEmail, secondaryEmail,
    web, directoryStatus, list) => async (dispatch) => {
        try {
            const mapData = {
                UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
                ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
                ExhRegistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
                DirectoryListingID: EDirectoryId != "" ? EDirectoryId : null,
                CompanyName: companyName,
                Address: add,
                Address1: altAdd,
                Pincode: pin,
                City: city,
                State: regState,
                CountryID: countryId,
                PersonIncharge: personInchName,
                Designation: Designation,
                MobileNo: `${sessionStorage.getItem('sessionregisterMobNoPre')}-${mobNo}`,
                Telephone: `${sessionStorage.getItem('sessionregisterTelephoneNoPre')}-${telNo}`,
                Fax: fax,
                PrimaryEmail: primaryEmail,
                SecondaryEmail: secondaryEmail,
                Website: web,
                Status: directoryStatus.toString() != "" ? directoryStatus : null,
                chapterListData: list
            }
            console.log("addEDirectory_action------->", mapData)
            const res = await axiosPost(addEDirectoryUrl, mapData, headers)
            dispatch({
                type: ADD_E_DIRECTORY_ACTION_TYPE,
                payload: res
            })
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }

export const getEDirectoryDetails_action = (id) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhRegistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
            DirectoryListingID: id
        }
        console.log("getEDirectoryDetails_action Action------->", params)
        const res = await axiosGet(getEDirectoryDetailsUrl, params, headers)

        dispatch({
            type: GET_E_DIRECTORY_DETAILS_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const delDirectory_action = (id) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            DirectoryListingID: id
        }
        console.log("delDirectory_action Action------->", params)
        const res = await axiosGet(delDirectoryUrl, params, headers)

        dispatch({
            type: GET_E_DIRECTORY_LIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const updateEDirectory_action = () => async (dispatch) => {
    try {
        const mapData = {
            // UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            // ExhibitorBadgesFormID: exBadgeFormId,
            // ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            // ExhRegistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
            // Name: exhibitorName,
            // Designation: designation,
            // countryid: countryId,
            // Category: category,
            // CompanyName: companyName,
            // Remark: remarks,
            // PhotoFilePath: null,
            // PhotoIDFilePath: null,
            // VaccinationCertifcateFilePath: vaccineCertificateFile,
            // visitDaysList: list,
            // ExhRegistrationNo: sessionStorage.getItem('ExhRegistrationNo'),
            // vaccineCertificateFileType: vaccineCertificateFileType
        }
        console.log("updateEDirectory_action------->", mapData)
        const res = await axiosPost(updateEDirectoryUrl, mapData, headers)

        dispatch({
            type: UPDATE_E_DIRECTORY_ACTION_TYPE,
            payload: res
        })
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const submitEDirectoryList_action = () => async (dispatch) => {
    try {
        const mapData = {
            userID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhregistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),

        }
        console.log("submitEDirectoryList_action------->", mapData)
        const res = await axiosPost(submitEDirectoryListUrl, mapData, headers)

        dispatch({
            type: SUBMIT_E_DIRECTORY_LIST_ACTION_TYPE,
            payload: res
        })
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}