import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action } from '../../../../../actions/fillCombo_action';
import { cityActionId, stallTypeActionId, stallListActionId, countryActionId, exhibitionActionId, toastError, toastSuccess } from "../../../../../utils/constants";
import { emailValidator } from "../../../../../utils/Validator";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import Pagination from "../../../../../utils/pagination";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import { useNavigate } from "react-router-dom";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import { data } from "jquery";
import { deleteHallMaster_action } from "../../../../../actions/admin_module_action/master_action/hall_master_action";
import { getBrandingOpportunityMasterList_action, insertUpdateBrandingOpportunityMaster_action, deleteBrandingOpportunityMaster_action } from "../../../../../actions/admin_module_action/master_action/manual_form_master_action/branding_opportunity_master_action";
import Footer from "../../../../../common_components/Footer";
import Resizer from "react-image-file-resizer";


class BrandingOpportunityMaster extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isOpen: false,
            // currentPage: 1,
            bankDetailsList: [],
            BOList: [],
            deleteId: '',
            deleteType: '',
        }

        this.recordBO = this.recordBO.bind(this)
        this.recordCategory = this.recordCategory.bind(this)
        this.onInputBO = this.onInputBO.bind(this)
        this.onFilePick = this.onFilePick.bind(this)
        this.onPdfSelect = this.onPdfSelect.bind(this)
        this.resizeFile = this.resizeFile.bind(this)
        this.onCrossClick = this.onCrossClick.bind(this)
        // this.resizeFile = this.resizeFile.bind(this)

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleAddBO = this.handleAddBO.bind(this)
        this.handleAddBankDetails = this.handleAddBankDetails.bind(this)
        // this.deleteBrandingOpportunityMaster = this.deleteBrandingOpportunityMaster.bind(this)
        this.onDelete = this.onDelete.bind(this)
        // this.handleAddAgency = this.handleAddAgency.bind(this)
        // this.handleAddCategory = this.handleAddCategory.bind(this)
        this.onInputBankDetails = this.onInputBankDetails.bind(this)
    }

    componentDidMount() {
        this.getBrandingOpportunityMasterList(null)
    }

    onFilePick = async (e, file, i) => {
        e.preventDefault()
        const { BOList } = this.state
        console.log('file', file)
        if (file.length != 0) {
            if (file[0]['size'] >= 5 * 1024 * 1024) {
                toastError('File size more than 5mb are not allow.')
            } else {
                var image = '';
                if (file[0]['name'].toString().toLowerCase().includes('.jpeg') ||
                    file[0]['name'].toString().toLowerCase().includes('.png') ||
                    file[0]['name'].toString().toLowerCase().includes('.jpg')
                ) {
                    if (file[0]['name'].toString().toLowerCase().trim().includes('.jpg') ||
                        file[0]['name'].toString().toLowerCase().trim().includes('.png') ||
                        file[0]['name'].toString().toLowerCase().trim().includes('.jpeg')
                    ) {
                        image = await this.resizeFile(file[0]);
                        BOList[i].img = image
                        this.setState({ BOList: BOList })
                    }
                    var fileType = file[0]['type']
                    var fileTypeList;
                    if (fileType.includes("/")) {
                        fileTypeList = fileType.split("/")

                    }
                    BOList[i].imgType = fileTypeList.length > 0 ? fileTypeList[1] : ""
                    this.setState({ BOList: BOList })
                } else {
                    toastError('Only .jpeg, .png, .jpg files are allowed')
                }
            }
        }
    }

    onPdfSelect = (file) =>
        new Promise((resolve) => {
            let base64;
            // FileReader function for read the file.
            let fileReader = new FileReader();
            // Onload of file read the file content
            fileReader.onload = function (fileLoadedEvent) {
                base64 = fileLoadedEvent.target.result;
                // Print data in console
                resolve(base64);
            };
            // Convert data to base64
            fileReader.readAsDataURL(file);
        });

    resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                500,
                600,
                'JPEG',
                80,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64",
                300,
                300,
            );
        });

    onCrossClick(i) {
        const { BOList } = this.state
        BOList[i].img = ''
        this.setState({ BOList: BOList })
        document.getElementById(`path${i + 1}`).value = ""
    }

    onInputBO(e, value, i, flag) {
        const { BOList } = this.state
        console.log(value, i, flag)
        if (flag == 'ParticularAndSize') {
            BOList[i].ParticularAndSize = value
        } else if (flag == 'ChargesINR') {
            BOList[i].ChargesINR = value
        } else if (flag == 'ChargesUSD') {
            BOList[i].ChargesUSD = value
        } else if (flag == 'TotalQty') {
            BOList[i].TotalQty = value
        } else if (flag == 'MainCategory') {
            BOList[i].MainCategory = value
        } else if (flag == 'image') {
            this.onFilePick(e, value, i)
        }
        this.setState({ BOList: BOList })
    }

    onInputBankDetails(value, i, flag) {
        const { bankDetailsList } = this.state
        console.log(value, i, flag)
        if (flag == 'BeneficiaryName') {
            bankDetailsList[i].BeneficiaryName = value
        } else if (flag == 'AccountNumber') {
            bankDetailsList[i].AccountNumber = value
        } else if (flag == 'BankName') {
            bankDetailsList[i].BankName = value
        } else if (flag == 'Branch') {
            bankDetailsList[i].Branch = value
        } else if (flag == 'SWIFTCode') {
            bankDetailsList[i].SWIFTCode = value
        } else if (flag == 'ExhibitorType') {
            bankDetailsList[i].ExhibitorType = value
        }
        this.setState({ bankDetailsList: bankDetailsList })
    }

    recordBO(list) {
        return (
            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
                <thead>
                    <tr>
                        <th className="text-center" style={{ width: '50px' }}>Sr no.</th>
                        <th className="text-center">Particular And Size <span className="text-red">*</span></th>
                        <th className="text-center">Charges(INR)<span className="text-red">*</span></th>
                        <th className="text-center">Charges(USD)</th>
                        <th className="text-center">Total Quantity<span className="text-red">*</span></th>
                        <th className="text-center">Main Category<span className="text-red">*</span></th>
                        <th className="text-center" style={{ width: '350px' }}>Image<span className="text-red">*</span></th>
                        <th className="text-center" style={{ width: '50px' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.length > 0 ? <>{
                            list.map((data, i) => (
                                <tr key={i}>
                                    <td className="text-center" style={{ width: '50px' }}>{i + 1}</td>
                                    <td className="text-center">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            value={data.ParticularAndSize}
                                            onChange={(e) => this.onInputBO(e, e.target.value, i, 'ParticularAndSize')}
                                        />
                                    </td>
                                    <td className="text-center">
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder=""
                                            value={data.ChargesINR}
                                            onChange={(e) => this.onInputBO(e, e.target.value, i, 'ChargesINR')}
                                        />
                                    </td>
                                    <td className="text-center">
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder=""
                                            value={data.ChargesUSD}
                                            onChange={(e) => this.onInputBO(e, e.target.value, i, 'ChargesUSD')}
                                        />
                                    </td> <td className="text-center">
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder=""
                                            value={data.TotalQty}
                                            onChange={(e) => this.onInputBO(e, e.target.value, i, 'TotalQty')}
                                        />
                                    </td> <td className="text-center">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            value={data.MainCategory}
                                            onChange={(e) => this.onInputBO(e, e.target.value, i, 'MainCategory')}
                                        />
                                    </td>
                                    <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="text-center">
                                        <div className="box">
                                            <div className="js--image-preview" />
                                            {
                                                data.BrandingOppotunityID == '' || data.BrandingOppotunityID == null ?
                                                    <div className="upload-options mt-1 ms-2">
                                                        <label>
                                                            <input
                                                                type="file"
                                                                id={`path${i + 1}`}
                                                                className="image-upload"
                                                                accept=".jpg, .jpeg, .png"
                                                                onChange={e => this.onInputBO(e, e.target.files, i, 'image')}
                                                            />
                                                            {
                                                                data.img != "" &&
                                                                <i class="fa fa-times text-danger img-can"
                                                                    onClick={() => this.onCrossClick(i)}></i>
                                                            }
                                                        </label>

                                                    </div>
                                                    :
                                                    <div style={{
                                                        border: '1px solid #ccc',
                                                        padding: '10px',
                                                        display: 'inline-flex',
                                                        alignItems: 'center',
                                                        // marginTop: '10px'
                                                    }}>
                                                        <a
                                                            // href={data.AgencyRentalAudioVideoImage}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <span style={{ marginRight: '10px' }}
                                                            >{data.img}</span>
                                                            {/* <i className={`fa fa-times text-danger img-can ${isView ? 'disabled' : ''}`}
                                                                onClick={!isView ? (e) => this.onDocDelete() : undefined}
                                                            ></i> */}
                                                        </a>
                                                    </div>
                                            }
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        <button
                                            type="button"
                                            className="btn btn-outline-danger btn-sm"
                                            onClick={(e) => this.onDelete(data.BrandingOppotunityID, i, data.isNew, 1)}
                                        >
                                            <i className="fa fa-trash action_btn_table" />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </> :
                            <tr><td colSpan={13} style={{ textAlign: 'center' }}></td></tr>
                    }
                </tbody>
            </table >)
    }

    recordCategory(list) {
        return (
            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
                <thead>
                    <tr>
                        <th className="text-center" style={{ width: '50px' }}>Sr no.</th>
                        <th className="text-center">Beneficiary Name<span className="text-red">*</span></th>
                        <th className="text-center">Account Number<span className="text-red">*</span></th>
                        <th className="text-center">Bank Name<span className="text-red">*</span></th>
                        <th className="text-center">Branch<span className="text-red">*</span></th>
                        <th className="text-center">SWIFT Code<span className="text-red">*</span></th>
                        <th className="text-center">Exhibitor Type<span className="text-red">*</span></th>
                        <th className="text-center" style={{ width: '50px' }}></th>

                    </tr>
                </thead>
                <tbody>
                    {
                        list.length > 0 ? <>{
                            list.map((data, i) => (
                                <tr key={i}>
                                    <td className="text-center" style={{ width: '50px' }}>{i + 1}</td>
                                    <td className="text-center">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            value={data.BeneficiaryName}
                                            onChange={(e) => this.onInputBankDetails(e.target.value, i, 'BeneficiaryName')}
                                        />
                                    </td>
                                    <td className="text-center">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            value={data.AccountNumber}
                                            onChange={(e) => this.onInputBankDetails(e.target.value, i, 'AccountNumber')}
                                        />
                                    </td>
                                    <td className="text-center">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            value={data.BankName}
                                            onChange={(e) => this.onInputBankDetails(e.target.value, i, 'BankName')}

                                        />
                                    </td>
                                    <td className="text-center">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            value={data.Branch}
                                            onChange={(e) => this.onInputBankDetails(e.target.value, i, 'Branch')}

                                        />
                                    </td>
                                    <td className="text-center">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            value={data.SWIFTCode}
                                            onChange={(e) => this.onInputBankDetails(e.target.value, i, 'SWIFTCode')}

                                        />
                                    </td> <td className="text-center">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            value={data.ExhibitorType}
                                            onChange={(e) => this.onInputBankDetails(e.target.value, i, 'ExhibitorType')}

                                        />
                                    </td>
                                    <td className="text-center">
                                        <button
                                            type="button"
                                            className="btn btn-outline-danger btn-sm"
                                            onClick={(e) => this.onDelete(data.BrandingOppotunityBankDetailID, i, data.isNew, 2)}
                                        >
                                            <i className="fa fa-trash action_btn_table" />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </> :
                            <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                    }
                </tbody>
            </table>)
    }

    getBrandingOpportunityMasterList(status) {
        this.setState({ isLoading: true })
        const { AgencyContactList, bankDetailsList, BOList } = this.state
        this.props.getBrandingOpportunityMasterList_action()
            .then((data) => {
                if (data.error != 1) {
                    data.data[0].length > 0 && data.data[0].map((item) => {
                        item.isNew = false
                        var filePath = item['ImagePath']
                        var array = item['ImagePath'] != null && item['ImagePath'] != "" && filePath.split("/")
                        var FileName = array[array.length - 1]
                        item.img = FileName != undefined ? FileName : null
                        item.imgPath = filePath
                        item.imgType = null
                    })
                    data.data[0].length > 0 ? this.setState({ BOList: data.data[0] }) : this.setState({
                        BOList: [{
                            'ParticularAndSize': '',
                            'ChargesINR': '',
                            'ChargesUSD': '',
                            "TotalQty": '',
                            "MainCategory": '',
                            "img": '',
                            "imgType": '',
                            "imgPath": '',
                            'isNew': true,
                            'BrandingOppotunityID': null,
                        }]
                    })
                    data.data[1].length > 0 ? this.setState({ bankDetailsList: data.data[1] }) : this.setState({
                        BOList: [{
                            'BeneficiaryName': '',
                            'AccountNumber': '',
                            'BankName': '',
                            "Branch": '',
                            "SWIFTCode": '',
                            "ExhibitorType": '',
                            'isNew': true,
                            'BrandingOppotunityBankDetailID': null,
                        }]
                    })
                    console.log(data.data)

                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    handleAddBO() {
        const { BOList } = this.state
        console.log("pp")
        var obj = {
            'ParticularAndSize': '',
            'ChargesINR': '',
            'ChargesUSD': '',
            'TotalQty': '',
            'MainCategory': '',
            'img': '',
            "imgType": '',
            'imgPath': '',
            'BrandingOppotunityID': null,
            'isNew': true
        }
        BOList.push(obj)
        this.setState({ BOList: BOList })
    }
    handleAddBankDetails() {
        const { bankDetailsList } = this.state
        console.log("pp")
        var obj = {
            'BeneficiaryName': '',
            'AccountNumber': '',
            'BankName': '',
            'Branch': '',
            'SWIFTCode': '',
            'ExhibitorType': '',
            'BrandingOppotunityBankDetailID': null,
            'isNew': true
        }
        bankDetailsList.push(obj)
        this.setState({ bankDetailsList: bankDetailsList })
    }

    handleSubmit() {
        const { bankDetailsList, BOList } = this.state
        var BOListArr = [...BOList]
        var bankDetailsListArr = [...bankDetailsList]
        console.log(BOListArr, bankDetailsListArr)
        var err = false
        for (var i = 0; i < BOListArr.length; i++) {
            if (BOListArr[i].ParticularAndSize == '' || BOListArr[i].ParticularAndSize == null) {
                err = 'Please enter mandatory fields'
                break
            }
            if (BOListArr[i].ChargesINR == '' || BOListArr[i].ChargesINR == null) {
                err = 'Please enter mandatory fields'
                break
            }
            if (BOListArr[i].TotalQty == '' || BOListArr[i].TotalQty == null) {
                err = 'Please enter mandatory fields'
                break
            }
            if (BOListArr[i].MainCategory == '' || BOListArr[i].MainCategory == null) {
                err = 'Please enter mandatory fields'
                break
            }
            if (BOListArr[i].img == '' || BOListArr[i].img == null) {
                err = 'Please enter mandatory fields'
                break
            }
        }
        for (var i = 0; i < bankDetailsListArr.length; i++) {
            if (bankDetailsListArr[i].BeneficiaryName == '' || bankDetailsListArr[i].BeneficiaryName == null) {
                err = 'Please enter mandatory fields'
                break
            }
            if (bankDetailsListArr[i].AccountNumber == '' || bankDetailsListArr[i].AccountNumber == null) {
                err = 'Please enter mandatory fields'
                break
            }
            if (bankDetailsListArr[i].BankName == '' || bankDetailsListArr[i].BankName == null) {
                err = 'Please enter mandatory fields'
                break
            }
            if (bankDetailsListArr[i].Branch == '' || bankDetailsListArr[i].Branch == null) {
                err = 'Please enter mandatory fields'
                break
            }
            if (bankDetailsListArr[i].SWIFTCode == '' || bankDetailsListArr[i].SWIFTCode == null) {
                err = 'Please enter mandatory fields'
                break
            }
            if (bankDetailsListArr[i].ExhibitorType == '' || bankDetailsListArr[i].ExhibitorType == null) {
                err = 'Please enter mandatory fields'
                break
            }
        }
        if (!err) {
            this.setState({ isLoading: true })
            this.props.insertUpdateBrandingOpportunityMaster_action(BOListArr, bankDetailsListArr)
                .then((data) => {
                    if (data.error != 1) {
                        console.log(data.data)
                        this.getBrandingOpportunityMasterList(1)
                        this.setState({ isLoading: false, })
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            toastError(err)
        }
    }

    deleteBrandingOpportunityMaster(id) {
        const { deleteType } = this.state
        this.setState({ isLoading: true })
        this.props.deleteBrandingOpportunityMaster_action(deleteType == 1 ? id : '', deleteType == 2 ? id : '')
            .then((data) => {
                if (data.error != 1) {
                    console.log(data.data)
                    this.setState({ isLoading: false, deleteType: '' })
                    toastSuccess("deleted successfully")
                    setTimeout(() => {
                        this.getBrandingOpportunityMasterList(1)
                    }, 200);
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
                this.setState({ isOpen: false })
            })
            .catch((err) => {
                console.log(err)
            })
    }


    onDelete(id, index, status, type) {
        const { BOList, deleteType, bankDetailsList } = this.state
        console.log(status, index, type)
        if (type == 1) {
            if (status) {
                BOList.splice(index, 1)
                this.setState({ BOList: BOList })
            } else {
                this.setState({ deleteID: id })
                this.setState({ isOpen: true, })
            }
            this.setState({ deleteType: 1 })
        } else if (type == 2) {
            if (status) {
                bankDetailsList.splice(index, 1)
                this.setState({ bankDetailsList: bankDetailsList })
            } else {
                this.setState({ deleteID: id })
                this.setState({ isOpen: true, })
            }
            this.setState({ deleteType: 2 })
        }
    }


    render() {
        const { isOpen, isLoading, BOList, bankDetailsList } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <div className="app-content with-dr-container com-height">
                        <AdminDrawer />
                        <div className="row">
                            <div className="step-name">
                                <p>Branding Opportunity Master</p>
                            </div>
                        </div>
                        <div className="m-2">

                            <div className="table-responsive">
                                <div
                                    className="card-header p-3"
                                    style={{ paddingLeft: "7px !important" }}
                                >
                                    <h3 className="card-title" style={{ fontSize: 15 }}>
                                        Bank details
                                    </h3>
                                </div>
                                {this.recordBO(BOList)}
                            </div>
                        </div>
                        <div className="btn-group m-2 save-can d-block" >
                            <div className="btn-group" role="group" aria-label="Basic example">
                                <button type="button" className="btn btn-primary rounded-0 d-block" style={{ marginLeft: '5px', cursor: 'pointer' }}
                                    onClick={this.handleAddBO}
                                >Add more</button>
                            </div>
                        </div>
                        <br />
                        <div className="m-2">

                            <div className="table-responsive">
                                <div
                                    className="card-header p-3"
                                    style={{ paddingLeft: "7px !important" }}
                                >
                                    <h3 className="card-title" style={{ fontSize: 15 }}>
                                        Branding Opportunity List
                                    </h3>
                                </div>
                                {this.recordCategory(bankDetailsList)}
                            </div>
                        </div>
                        <div className="btn-group m-2 save-can d-block" >
                            <div className="btn-group" role="group" aria-label="Basic example">
                                <button type="button" className="btn btn-primary rounded-0 d-block" style={{ marginLeft: '5px', cursor: 'pointer' }}
                                    onClick={this.handleAddBankDetails}
                                >Add more</button>
                            </div>
                        </div>
                        <div className="btn-group m-2 save-can d-block" >
                            <div className="btn-group" role="group" aria-label="Basic example" style={{ float: 'right' }} >
                                <button type="button" className="btn btn-success rounded-0 d-block" data-dismiss="modal" style={{ marginLeft: '5px' }}
                                    onClick={this.handleSubmit} disabled={BOList.length == 0 || bankDetailsList.length == 0}
                                >Submit</button>
                            </div>
                        </div>
                    </div>
                </>
                <Modal isOpen={isOpen} onRequestClose={() => this.setState({ isOpen: false })}>
                    <div className="modal-dialog" style={{ marginTop: '20%' }}>
                        <div className="modal-content confirm-logout">
                            <div class="modal-header text-right p-2">
                                <h5 class="modal-title modal_title_text text-danger">Do you really want to delete this record?</h5>

                                {/* <button type="button " class="close pop-close" data-dismiss="modal"
                                    onClick={() => this.setState({ isOpen: false })}
                                ><span aria-hidden="true" class="pop-cancel">&times;</span></button> */}
                            </div>
                            {/* <div class="modal-body">
                                <div class="row">
                                </div>
                            </div> */}
                            <div class="modal-footer p-1">
                                <div class="form-group text-right mod-fot">
                                    <button type="button" class="btn btn-del-pop"
                                        onClick={(e) => this.deleteBrandingOpportunityMaster(this.state.deleteID)}
                                    >Yes</button>
                                    <button type="button" class="btn btn-can-pop" data-dismiss="modal" style={{ marginLeft: '5px' }}
                                        onClick={() => this.setState({ isOpen: false })}
                                    >No</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
                <Footer />
            </>)
    }

}
export default withRouter(connect(null, { fill_action, getBrandingOpportunityMasterList_action, insertUpdateBrandingOpportunityMaster_action, deleteBrandingOpportunityMaster_action })(BrandingOpportunityMaster));