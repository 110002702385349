import { Component } from "react";
import Loader from "../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action } from '../../../../actions/fillCombo_action';
import { cityActionId, stallTypeActionId, stallListActionId, countryActionId, exhibitionActionId, toastError, toastSuccess } from "../../../../utils/constants";
import { emailValidator } from "../../../../utils/Validator";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import Pagination from "../../../../utils/pagination";
import AdminDrawer from "../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../common_components/header_with_logo";
import { useNavigate } from "react-router-dom";
import ExhibitorDrawer from "../../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../../common_components/exhibitor_module_header";
import { data } from "jquery";
import { getOnlinepresenceMasterList_action, deleteOnlinePresenceMaster_action } from "../../../../actions/admin_module_action/master_action/online_presence_action";
import Footer from "../../../../common_components/Footer";


class OnlinePresenceList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isOpen: false,
            currentPage: 1,
            onlinePresenceList: [],
            deleteID: '',
        }

        this.record = this.record.bind(this)
        // this.clearSearch = this.clearSearch.bind(this)
        this.deleteOnlinePrensenceMaster = this.deleteOnlinePrensenceMaster.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.updateItem = this.updateItem.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.onDelete = this.onDelete.bind(this)
        this.getOnlinePresenceList = this.getOnlinePresenceList.bind(this)
    }

    componentDidMount() {

        this.getOnlinePresenceList()
    }

    record(list) {
        return (
            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
                <thead>
                    <tr>
                        <th style={{ width: '50px' }}>SR no.</th>
                        {/* <th className="text-center" >GSTIN</th> */}
                        <th className="text-center" >Online Prensence</th>
                        <th style={{ width: '50px' }}></th>
                        <th style={{ width: '50px' }}></th>

                    </tr>
                </thead>
                <tbody>
                    {
                        list.length > 0 ? <>{
                            list.map((data, i) => (
                                <tr key={Math.random()}>
                                    {/* <td className="text-center">{data.RoleID}</td> */}
                                    {/* <td className="text-center">{moment(data.ExhibitionDateFrom).format('L')}</td>
                                    <td className="text-center">{moment(data.ExhibitionDateTo).format('L')}</td>
                                <td className="text-center">{this.state.countryList.map(id => (parseInt(id.Code) == data.CountryID ? id.Description : null))}</td> */}
                                    <td className="text-center">{i + 1}</td>
                                    {/* <td className="text-center">{data.GSTNo}</td> */}
                                    <td className="text-center">{data.Name}</td>
                                    <td className="text-center">
                                        <button
                                            type="button"
                                            className="btn btn-outline-primary btn-sm"
                                            onClick={(e) => this.handleUpdate(data.OnlinePresencePartnerID)}
                                        >
                                            Update
                                        </button>
                                    </td>
                                    <td className="text-center">
                                        <button
                                            type="button"
                                            className="btn btn-outline-danger btn-sm"
                                            onClick={(e) => this.onDelete(data.OnlinePresencePartnerID)}
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </> :
                            <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                    }
                </tbody>
            </table>)
    }

    getOnlinePresenceList() {
        this.setState({ isLoading: true })
        const { hallName, exhibitionID, GSTno, PANNo } = this.state
        this.props.getOnlinepresenceMasterList_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log(data.data[0])
                    var list = []
                    for (var i = 0; i < data.data[0].length; i++) {
                        if (data.data[0][i].Is_Active) {
                            list.push(data.data[0][i])
                        }
                    }
                    this.setState({ onlinePresenceList: list })
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // clearSearch() {
    //     const { GSTno, PANNo } = this.state
    //     this.setState({
    //         GSTno: '',
    //         PANNo: ''
    //     })
    //     setTimeout(() => {
    //         this.getOnlinePresenceList()
    //     }, 100);
    // }

    handleAdd() {
        this.props.navigate('/onlinePresenceAU', { state: { is_add: true, } })
    }

    handleUpdate(id) {
        this.props.navigate('/onlinePresenceAU', { state: { is_update: true, OnlinePresencePartnerID: id } })
    }

    deleteOnlinePrensenceMaster(id) {
        this.setState({ isLoading: true })
        this.props.deleteOnlinePresenceMaster_action(id)
            .then((data) => {
                if (data.error != 1) {
                    console.log(data.data)
                    this.setState({ isLoading: false })
                    toastSuccess("deleted successfully")
                    setTimeout(() => {
                        this.getOnlinePresenceList()
                    }, 200);
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
                this.setState({ isOpen: false })
            })
            .catch((err) => {
                console.log(err)
            })
    }

    onDelete(i) {
        const { } = this.state
        this.setState({ deleteID: i })
        this.setState({ isOpen: true, })
    }

    updateItem(item) {
        const { onlinePresenceList } = this.state
        this.setState({ currentPage: item });
        const recordsPerPage = 10;
        const indexOfLastRecord = item * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = onlinePresenceList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(onlinePresenceList.length / recordsPerPage)
        this.setState({ startIndex: recordsPerPage * (item - 1) });
    }

    render() {
        const { isOpen, isLoading, exhibitionID, currentPage, onlinePresenceList, GSTno, PANNo } = this.state
        const recordsPerPage = 10;
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = onlinePresenceList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(onlinePresenceList.length / recordsPerPage)
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />

                <>
                    <div className="app-content with-dr-container com-height">
                        <AdminDrawer />
                        <div className="row">
                            <div className="step-name">
                                <p>Online Presence Master List</p>
                            </div>
                        </div>
                        <div className="m-3">
                            <div className="">
                                {/* {
                                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 1 &&
                                    <div className="row ">
                                        <div className="col-md-4">
                                            <label>GSTIN</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={GSTno}
                                                onChange={(e) => this.setState({ GSTno: e.target.value.toUpperCase(), })}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <label>Pan no</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={PANNo}
                                                onChange={(e) => this.setState({ PANNo: e.target.value.toUpperCase(), })}
                                            />
                                        </div>
                                    </div>
                                } */}
                            </div>
                            < div className="row mt-2 mb-1" >
                                <div className="col-md-8 mt-3 mb-1">
                                    {
                                        JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 1 &&
                                        <div
                                            className="btn-group mb-1"
                                            role="group"
                                            aria-label="Basic example"
                                            style={{ float: "right" }}
                                        >
                                            <button type="button" className="btn ad-fil-btn"
                                                onClick={e => this.handleAdd()}
                                            >
                                                Add
                                            </button>
                                        </div>

                                    }
                                    {/*  <button type="button" className="btn ad-fil-btn"
                                        onClick={e => this.getOnlinePresenceList()}
                                    >
                                        Search
                                    </button>*/}
                                    {/*  </button>
                                            <button type="button" className="btn ad-fil-btn"
                                                onClick={e => this.clearSearch()}
                                            >
                                                Clear
                                            </button>
                                        </div>
                                    } */}
                                </div>
                            </div>
                            <div className="col-md-8 pl-0">
                                <div className="table-responsive">
                                    {this.record(currentRecords)}
                                </div>
                                <div className="row">
                                    <div className="col-md-9 pl-0">
                                        <div className="mt-2 float">
                                            {
                                                onlinePresenceList.length > 0 ?
                                                    <Pagination
                                                        nPages={nPages}
                                                        currentPage={currentPage}
                                                        setCurrentPage={this.updateItem}
                                                    /> : <></>
                                            }

                                        </div>
                                    </div>
                                    <div className="col-md-3 text-right">
                                        <div className="tab-rec mt-3" style={{ float: "right" }}>
                                            <p>{`${currentPage} of ${nPages} pages`}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </>
                <Modal isOpen={isOpen} onRequestClose={() => this.setState({ isOpen: false })}>
                    <div className="modal-dialog" style={{ marginTop: '20%' }}>
                        <div className="modal-content confirm-logout">
                            <div class="modal-header text-right p-2">
                                <h5 class="modal-title modal_title_text text-danger">Do you really want to delete this record?</h5>

                                {/* <button type="button " class="close pop-close" data-dismiss="modal"
                                    onClick={() => this.setState({ isOpen: false })}
                                ><span aria-hidden="true" class="pop-cancel">&times;</span></button> */}
                            </div>
                            {/* <div class="modal-body">
                                <div class="row">
                                </div>
                            </div> */}
                            <div class="modal-footer p-1">
                                <div class="form-group text-right mod-fot">
                                    <button type="button" class="btn btn-del-pop"
                                        onClick={(e) => this.deleteOnlinePrensenceMaster(this.state.deleteID)}
                                    >Yes</button>
                                    <button type="button" class="btn btn-can-pop" data-dismiss="modal" style={{ marginLeft: '5px' }}
                                        onClick={() => this.setState({ isOpen: false })}
                                    >No</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
                <Footer />
            </>)
    }

}
export default withRouter(connect(null, { fill_action, getOnlinepresenceMasterList_action, deleteOnlinePresenceMaster_action })(OnlinePresenceList));