import { axiosGet, axiosPost } from "../../../../react_axios/Axios";
import { headers, getVendorRegistrationListUrl, GET_VENDOR_REGISTRATION_LIST, addVendorRegistrationUrl, ADD_VENDOR_REGISTRATION, UPDATE_VENDOR_REGISTRATION, DELETE_VENDOR_REGISTRATION, updateVendorRegistrationUrl, deleteVendorRegistrationUrl, detailsVendorRegistrationUrl, DETAILS_VENDOR_REGISTRATION } from "../../../../utils/constants";


export const getVendorRegistrationList_action = (ExhibitionID, CompanyName, PersonName, ServiceID, Status) => async (dispatch) => {
    try {
        const params = {
            UserID: null,
            ExhibitionID: ExhibitionID != '' ? parseInt(ExhibitionID) : null,
            CompanyName: CompanyName != '' ? CompanyName : null,
            PersonName: PersonName != '' ? PersonName : null,
            ServiceID: ServiceID != '' ? parseInt(ServiceID) : null,
            Status: Status,
        }

        const res = await axiosGet(getVendorRegistrationListUrl, params, headers)

        dispatch({
            type: GET_VENDOR_REGISTRATION_LIST,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}


export const addVendorRegistration_action = (
    PrimaryEmail, Title_PersonIncharge, PersonIncharge, LastNamePersonIncharge, MobileNo, AlternateMobileNo, Telephone, Telephone1, CompanyName, Address1, Address2, City, State, Pin, CountryID, SecondaryEmail, Website, TeleFax, Designation, Title_Name_Of_CEO_MD_President_Owner, Name_Of_CEO_MD_President_Owner, LastName_Of_CEO_MD_President_Owner, NameOnPanCard, PanNo, Tan, GSTNo, CompanyRegistrationNo, IECCode, AccountName, AccountNo, AccountType, BankName, BranchName, SwiftCode_IFSCCode, PreMobileNo, PreAlternateMobileNo, PreTelephoneNo, PreTelephoneNo1, renderServiceTypeList, IsItemBilling, IsApprovalRights, Email, Mobile, preMobile
) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem("sessionExhibitionID"),
            PrimaryEmail: PrimaryEmail != '' ? PrimaryEmail : null,
            Title_PersonIncharge: Title_PersonIncharge != '' ? Title_PersonIncharge : null,
            PersonIncharge: PersonIncharge != '' ? PersonIncharge : null,
            LastNamePersonIncharge: LastNamePersonIncharge != '' ? LastNamePersonIncharge : null,
            MobileNo: MobileNo != '' ? MobileNo : null,
            AlternateMobileNo: AlternateMobileNo != '' ? AlternateMobileNo : null,
            Telephone: Telephone != '' ? Telephone : null,
            Telephone1: Telephone1 != '' ? Telephone1 : null,
            CompanyName: CompanyName != '' ? CompanyName : null,
            Address1: Address1 != '' ? Address1 : null,
            Address2: Address2 != '' ? Address2 : null,
            City: City != '' ? City : null,
            State: State != '' ? State : null,
            Pin: Pin != '' ? Pin : null,
            CountryID: CountryID != '' ? CountryID : null,
            SecondaryEmail: SecondaryEmail != '' ? SecondaryEmail : null,
            Website: Website != '' ? Website : null,
            TeleFax: TeleFax != '' ? TeleFax : null,
            // RoleTD: RoleTD != '' ? RoleTD : null,
            Designation: Designation != '' ? Designation : null,
            Title_Name_Of_CEO_MD_President_Owner: Title_Name_Of_CEO_MD_President_Owner != '' ? Title_Name_Of_CEO_MD_President_Owner : null,
            Name_Of_CEO_MD_President_Owner: Name_Of_CEO_MD_President_Owner != '' ? Name_Of_CEO_MD_President_Owner : null,
            LastName_Of_CEO_MD_President_Owner: LastName_Of_CEO_MD_President_Owner != '' ? LastName_Of_CEO_MD_President_Owner : null,
            NameOnPanCard: NameOnPanCard != '' ? NameOnPanCard : null,
            PanNo: PanNo != '' ? PanNo : null,
            Tan: Tan != '' ? Tan : null,
            GSTNo: GSTNo != '' ? GSTNo : null,
            CompanyRegistrationNo: CompanyRegistrationNo != '' ? CompanyRegistrationNo : null,
            IECCode: IECCode != '' ? IECCode : null,
            AccountName: AccountName != '' ? AccountName : null,
            AccountNo: AccountNo != '' ? AccountNo : null,
            AccountType: AccountType != '' ? AccountType : null,
            BankName: BankName != '' ? BankName : null,
            BranchName: BranchName != '' ? BranchName : null,
            SwiftCode_IFSCCode: SwiftCode_IFSCCode != '' ? SwiftCode_IFSCCode : null,
            PreMobileNo: PreMobileNo != '' ? PreMobileNo : null,
            PreAlternateMobileNo: PreAlternateMobileNo != '' ? PreAlternateMobileNo : null,
            PreTelephoneNo: PreTelephoneNo != '' ? PreTelephoneNo : null,
            PreTelephoneNo1: PreTelephoneNo1 != '' ? PreTelephoneNo1 : null,
            ServiceTypeList: renderServiceTypeList,
            IsItemBilling: IsItemBilling == true || IsItemBilling == 1 ? 1 : 0,
            IsApprovalRights: IsApprovalRights == true || IsApprovalRights == 1 ? 1 : 0,
            Email: Email,
            Mobile: Mobile,
            preMobile: preMobile,
        }

        const res = await axiosPost(addVendorRegistrationUrl, params, headers)

        dispatch({
            type: ADD_VENDOR_REGISTRATION,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const updateVendorRegistration_action = (
    PrimaryEmail, Title_PersonIncharge, PersonIncharge, LastNamePersonIncharge, MobileNo, AlternateMobileNo, Telephone, Telephone1, CompanyName, Address1, Address2, City, State, Pin, CountryID, SecondaryEmail, Website, TeleFax, Designation, Title_Name_Of_CEO_MD_President_Owner, Name_Of_CEO_MD_President_Owner, LastName_Of_CEO_MD_President_Owner, NameOnPanCard, PanNo, Tan, GSTNo, CompanyRegistrationNo, IECCode, AccountName, AccountNo, AccountType, BankName, BranchName, SwiftCode_IFSCCode, PreMobileNo, PreAlternateMobileNo, PreTelephoneNo, PreTelephoneNo1, AccountID, renderServiceTypeList, IsItemBilling, IsApprovalRights, Email, Mobile, preMobile
) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem("sessionExhibitionID"),
            PrimaryEmail: PrimaryEmail != '' ? PrimaryEmail : null,
            Title_PersonIncharge: Title_PersonIncharge != '' ? Title_PersonIncharge : null,
            PersonIncharge: PersonIncharge != '' ? PersonIncharge : null,
            LastNamePersonIncharge: LastNamePersonIncharge != '' ? LastNamePersonIncharge : null,
            MobileNo: MobileNo != '' ? MobileNo : null,
            AlternateMobileNo: AlternateMobileNo != '' ? AlternateMobileNo : null,
            Telephone: Telephone != '' ? Telephone : null,
            Telephone1: Telephone1 != '' ? Telephone1 : null,
            CompanyName: CompanyName != '' ? CompanyName : null,
            Address1: Address1 != '' ? Address1 : null,
            Address2: Address2 != '' ? Address2 : null,
            City: City != '' ? City : null,
            State: State != '' ? State : null,
            Pin: Pin != '' ? Pin : null,
            CountryID: CountryID != '' ? CountryID : null,
            SecondaryEmail: SecondaryEmail != '' ? SecondaryEmail : null,
            Website: Website != '' ? Website : null,
            TeleFax: TeleFax != '' ? TeleFax : null,
            // RoleTD: RoleTD != '' ? RoleTD : null,
            Designation: Designation != '' ? Designation : null,
            Title_Name_Of_CEO_MD_President_Owner: Title_Name_Of_CEO_MD_President_Owner != '' ? Title_Name_Of_CEO_MD_President_Owner : null,
            Name_Of_CEO_MD_President_Owner: Name_Of_CEO_MD_President_Owner != '' ? Name_Of_CEO_MD_President_Owner : null,
            LastName_Of_CEO_MD_President_Owner: LastName_Of_CEO_MD_President_Owner != '' ? LastName_Of_CEO_MD_President_Owner : null,
            NameOnPanCard: NameOnPanCard != '' ? NameOnPanCard : null,
            PanNo: PanNo != '' ? PanNo : null,
            Tan: Tan != '' ? Tan : null,
            GSTNo: GSTNo != '' ? GSTNo : null,
            CompanyRegistrationNo: CompanyRegistrationNo != '' ? CompanyRegistrationNo : null,
            IECCode: IECCode != '' ? IECCode : null,
            AccountName: AccountName != '' ? AccountName : null,
            AccountNo: AccountNo != '' ? AccountNo : null,
            AccountType: AccountType != '' ? AccountType : null,
            BankName: BankName != '' ? BankName : null,
            BranchName: BranchName != '' ? BranchName : null,
            SwiftCode_IFSCCode: SwiftCode_IFSCCode != '' ? SwiftCode_IFSCCode : null,
            PreMobileNo: PreMobileNo != '' ? PreMobileNo : null,
            PreAlternateMobileNo: PreAlternateMobileNo != '' ? PreAlternateMobileNo : null,
            PreTelephoneNo: PreTelephoneNo != '' ? PreTelephoneNo : null,
            PreTelephoneNo1: PreTelephoneNo1 != '' ? PreTelephoneNo1 : null,
            AccountID: AccountID,
            ServiceTypeList: renderServiceTypeList,
            IsItemBilling: IsItemBilling == true || IsItemBilling == 1 ? 1 : 0,
            IsApprovalRights: IsApprovalRights == true || IsApprovalRights == 1 ? 1 : 0,
            Email: Email,
            Mobile: Mobile,
            preMobile: preMobile,
        }

        const res = await axiosPost(updateVendorRegistrationUrl, params, headers)

        dispatch({
            type: UPDATE_VENDOR_REGISTRATION,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const deleteVendorRegistration_action = (userId) => async (dispatch) => {
    try {
        const params = {
            UserID: userId,
            ExhibitionID: sessionStorage.getItem("sessionExhibitionID"),
        }

        const res = await axiosGet(deleteVendorRegistrationUrl, params, headers)

        dispatch({
            type: DELETE_VENDOR_REGISTRATION,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getDetailsVendorRegistration_action = (userId) => async (dispatch) => {
    try {
        const params = {
            UserID: userId,
            ExhibitionID: null,
        }

        const res = await axiosGet(detailsVendorRegistrationUrl, params, headers)

        dispatch({
            type: DETAILS_VENDOR_REGISTRATION,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}