import { INSERT_AND_UPDATE_LEAD_RETRIEVAL_FORM_ACTION_TYPE, GET_LEAD_RETRIEVAL_FORM_DETAILS_ACTION_TYPE } from "../../../../../utils/constants";


const initialState = [];

function leadRetrievalFormReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case INSERT_AND_UPDATE_LEAD_RETRIEVAL_FORM_ACTION_TYPE:
            return [...state, payload];
        case GET_LEAD_RETRIEVAL_FORM_DETAILS_ACTION_TYPE:
            return [...state, payload];

        default:
            return state;
    }
};

export default leadRetrievalFormReducer;