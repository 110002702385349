import { GET_REMINDER_LIST_ACTION_TYPE, REMINDER_EMAIL_ACTION_TYPE } from "../../../utils/constants";


const initialState = [];

function reminderEmailReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_REMINDER_LIST_ACTION_TYPE:
            return [...state, payload];
        case REMINDER_EMAIL_ACTION_TYPE:
            return [...state, payload];

        default:
            return state;
    }
};

export default reminderEmailReducer;