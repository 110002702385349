import { axiosGet, axiosPost } from "../../../react_axios/Axios";
import { getStallMasterDetailsUrl, getStallMasterListUrl, insertUpdateStallMasterUrl, deleteStallMasterUrl, GET_STALL_MASTER_LIST, GET_STALL_MASTER_DETAILS, INSERT_UPDATE_STALL_MASTER, DELETE_STALL_MASTER, headers } from "../../../utils/constants";

export const getStallMasterList_action = (ExhibitionID, HallId, StallName) => async (dispatch) => {
    try {
        const params = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExhibitionID,
            HallId: HallId,
            StallName: StallName,
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getStallMasterListUrl, params, headers)

        dispatch({
            type: GET_STALL_MASTER_LIST,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const deleteStallMaster_action = (StallId) => async (dispatch) => {
    try {
        const params = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: JSON.parse(sessionStorage.getItem("sessionExhibitionID")),
            StallId: StallId

        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(deleteStallMasterUrl, params, headers)

        dispatch({
            type: DELETE_STALL_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const insertUpdateHallMaster_action = (ExhibitionID, StallNameList, HallId, StallId) => async (dispatch) => {
    try {
        const params = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExhibitionID,
            StallNameList: StallNameList,
            HallId: HallId,
            StallId: StallId
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosPost(insertUpdateStallMasterUrl, params, headers)

        dispatch({
            type: INSERT_UPDATE_STALL_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getHallMasterDetails_action = (StallId) => async (dispatch) => {
    try {
        const params = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: JSON.parse(sessionStorage.getItem("sessionExhibitionID")),
            StallId: StallId
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getStallMasterDetailsUrl, params, headers)

        dispatch({
            type: GET_STALL_MASTER_DETAILS,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}