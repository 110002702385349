import { Component, forwardRef } from "react";
import Loader from "../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../../utils/withRouter";
import { connect } from "react-redux";
import HeaderWithLogo from "../../../../common_components/header_with_logo";
import AdminDrawer from "../../../../common_components/admin_module_drawer";
import Pagination from "../../../../utils/pagination";
import { downloadReport, loginUrl, priorityNoActionId, toastError, headers, toastSuccess, exhibitionActionId, exhibitorNameActionId } from "../../../../utils/constants";
import Modal from "react-modal";
import { data } from "jquery";
import { descriptionPreview_action } from "../../../../actions/description_action"
import { fill_action } from "../../../../actions/fillCombo_action"
import Accordion from '@mui/material/Accordion';
import { getExhibitorDetailsReport_action, getReceiptReport_action, getTaxInvoiceReport_action } from "../../../../actions/admin_module_action/report_action/report_action";
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Workbook from 'react-excel-workbook'
import { axiosGet, axiosPost } from "../../../../react_axios/Axios";

class subReport1 extends Component {
    // intialize constructor
    constructor(props) {
        super(props)
        this.state = {
            reportName: '',
            reportID: '',

            isLoading: false,
            showTable: false,
            exhibitionID: '',
            exhibitionList: [],
            exhibitorList: [],
            list: [],
            columnArr: [],
            rowArr: [],
            ExhRegistrationID: '',
            xlrowArr: [],
            reportAccessList: []
        }

        this.onViewClick = this.onViewClick.bind(this)
        this.renderTable = this.renderTable.bind(this)
        this.onDownloadClick = this.onDownloadClick.bind(this)
        this.getFillList = this.getFillList.bind(this)
        this.getExhibitorDetailsReportlist = this.getExhibitorDetailsReportlist.bind(this)
        this.reportIdCondition = this.reportIdCondition.bind(this)
        this.getReceiptReportlist = this.getReceiptReportlist.bind(this)
        this.getTaxInvoiceReportlist = this.getTaxInvoiceReportlist.bind(this)
        this.getDownloadAccess = this.getDownloadAccess.bind(this)
        this.getViewAccess = this.getViewAccess.bind(this)
    }

    componentDidMount() {
        // this.getExhibitionManualForm()
        this.getFillList(exhibitionActionId, '', '', '', '', '')
        this.getFillList(exhibitorNameActionId, '', '', '', '', '')
        this.setState({
            reportName: this.props.location.state != null && this.props.location.state.reportName != null ? this.props.location.state.reportName : '',
            reportID: this.props.location.state != null && this.props.location.state.reportID != null ? this.props.location.state.reportID : '',
            reportAccessList: JSON.parse(sessionStorage.getItem('loginDataSS'))['reportAccesslist']
        })
        setTimeout(() => {
            console.log("BBBBBBBBBBBBBBBBBBBB", JSON.parse(sessionStorage.getItem('loginDataSS'))['reportAccesslist'])
            console.log(this.state.reportID)
        }, 100);
    }

    reportIdCondition(status, value) {
        const { reportID,reportName } = this.state
        if (reportName == 'Exhibitor Details Report') {
            this.getExhibitorDetailsReportlist(status, value)
        }
        if (reportName == 'Exhibitor Receipt Report') {
            this.getReceiptReportlist(status, value)
        }
        if (reportName == 'Exhibitor invoice Report') {
            this.getTaxInvoiceReportlist(status, value)
        }
    }

    getExhibitorDetailsReportlist(status, value) {
        const { exhibitionID, ExhRegistrationID } = this.state
        console.log(status, value)
        if (status == 'exhibitionID') {
            this.setState({ exhibitionID: value })
            if (value != 'select') {
                this.setState({ isLoading: true })
                this.props.getExhibitorDetailsReport_action(parseInt(value), ExhRegistrationID == '' || ExhRegistrationID == null ? 0 : ExhRegistrationID)
                    .then((data) => {
                        console.log(data)
                        if (data.error != 1) {
                            // data.data.map((element) =>
                            //     delete element['ADDITIONAL BADGES'])
                            this.setState({ list: data.data })
                            // if (status == 'view') {
                            this.getTableData(data.data)
                            this.setState({ showTable: false })
                            // }
                            // else if (status == 'download') {
                            //     this.onDownloadClick(data.data)
                            //     this.setState({ isLoading: false })
                            this.setState({ isLoading: false })

                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })

            }
            else {
                console.log(value)
                this.setState({ list: [], rowArr: [], columnArr: [], showTable: false })
            }
        }
        if (status == 'ExhRegistrationID') {
            console.log(exhibitionID)
            this.setState({ ExhRegistrationID: value })
            if (exhibitionID != null && exhibitionID != '' && exhibitionID != 'select') {
                this.setState({ isLoading: true })
                this.props.getExhibitorDetailsReport_action(exhibitionID, value == 'select' ? 0 : value)
                    .then((data) => {
                        console.log(data)
                        if (data.error != 1) {
                            // data.data.map((element) =>
                            //     delete element['ADDITIONAL BADGES'])
                            this.setState({ list: data.data })
                            // if (status == 'view') {
                            this.getTableData(data.data)
                            this.setState({ showTable: false })
                            // }
                            // else if (status == 'download') {
                            //     this.onDownloadClick(data.data)
                            //     this.setState({ isLoading: false })
                            // }
                            this.setState({ isLoading: false })

                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        }
        // if (exhibitionID = null || exhibitionID == '') {
        //     toastError("please select exhibition")
        // }
    }

    getReceiptReportlist(status, value) {
        const { exhibitionID, ExhRegistrationID } = this.state
        console.log(status, value)
        if (status == 'exhibitionID') {
            this.setState({ exhibitionID: value })
            if (value != 'select') {
                this.setState({ isLoading: true })
                this.props.getReceiptReport_action(parseInt(value), ExhRegistrationID == '' || ExhRegistrationID == null ? 0 : ExhRegistrationID)
                    .then((data) => {
                        console.log(data)
                        if (data.error != 1) {
                            // data.data.map((element) =>
                            //     delete element['ADDITIONAL BADGES'])
                            this.setState({ list: data.data })
                            // if (status == 'view') {
                            this.getTableData(data.data)
                            this.setState({ showTable: false })
                            // }
                            // else if (status == 'download') {
                            //     this.onDownloadClick(data.data)
                            //     this.setState({ isLoading: false })
                            this.setState({ isLoading: false })

                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })

            }
            else {
                console.log(value)
                this.setState({ list: [], rowArr: [], columnArr: [], showTable: false })
            }
        }
        if (status == 'ExhRegistrationID') {
            console.log(exhibitionID)
            this.setState({ ExhRegistrationID: value })
            if (exhibitionID != null && exhibitionID != '' && exhibitionID != 'select') {
                this.setState({ isLoading: true })
                this.props.getReceiptReport_action(exhibitionID, value == 'select' ? 0 : value)
                    .then((data) => {
                        console.log(data)
                        if (data.error != 1) {
                            // data.data.map((element) =>
                            //     delete element['ADDITIONAL BADGES'])
                            this.setState({ list: data.data })
                            // if (status == 'view') {
                            this.getTableData(data.data)
                            this.setState({ showTable: false })
                            // }
                            // else if (status == 'download') {
                            //     this.onDownloadClick(data.data)
                            //     this.setState({ isLoading: false })
                            // }
                            this.setState({ isLoading: false })

                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        }
        // if (exhibitionID = null || exhibitionID == '') {
        //     toastError("please select exhibition")
        // }
    }

    getTaxInvoiceReportlist(status, value) {
        const { exhibitionID, ExhRegistrationID } = this.state
        console.log(status, value)
        if (status == 'exhibitionID') {
            this.setState({ exhibitionID: value })
            if (value != 'select') {
                this.setState({ isLoading: true })
                this.props.getTaxInvoiceReport_action(parseInt(value), ExhRegistrationID == '' || ExhRegistrationID == null ? 0 : ExhRegistrationID)
                    .then((data) => {
                        console.log(data)
                        if (data.error != 1) {
                            // data.data.map((element) =>
                            //     delete element['ADDITIONAL BADGES'])
                            this.setState({ list: data.data })
                            // if (status == 'view') {
                            this.getTableData(data.data)
                            this.setState({ showTable: false })
                            // }
                            // else if (status == 'download') {
                            //     this.onDownloadClick(data.data)
                            //     this.setState({ isLoading: false })
                            this.setState({ isLoading: false })

                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })

            }
            else {
                console.log(value)
                this.setState({ list: [], rowArr: [], columnArr: [], showTable: false })
            }
        }
        if (status == 'ExhRegistrationID') {
            console.log(exhibitionID)
            this.setState({ ExhRegistrationID: value })
            if (exhibitionID != null && exhibitionID != '' && exhibitionID != 'select') {
                this.setState({ isLoading: true })
                this.props.getTaxInvoiceReport_action(exhibitionID, value == 'select' ? 0 : value)
                    .then((data) => {
                        console.log(data)
                        if (data.error != 1) {
                            // data.data.map((element) =>
                            //     delete element['ADDITIONAL BADGES'])
                            this.setState({ list: data.data })
                            // if (status == 'view') {
                            this.getTableData(data.data)
                            this.setState({ showTable: false })
                            // }
                            // else if (status == 'download') {
                            //     this.onDownloadClick(data.data)
                            //     this.setState({ isLoading: false })
                            // }
                            this.setState({ isLoading: false })

                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        }
        // if (exhibitionID = null || exhibitionID == '') {
        //     toastError("please select exhibition")
        // }
    }

    // get Fill List
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props.fill_action(actionID, exhibitionID, moduleID, keyValue, value)
            .then((data) => {
                if (data.error != 1) {
                    // Country List
                    if (actionID === exhibitionActionId) {
                        console.log("country data", data.data)
                        this.setState({
                            exhibitionList: data.data,
                            isLoading: false
                        })
                    }
                    // visa  id type
                    if (actionID === exhibitorNameActionId) {
                        console.log("visaIdTypeActionId data", data.data)
                        this.setState({
                            exhibitorList: data.data,
                            isLoading: false
                        })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    onViewClick() {
        const { exhibitionID, ExhRegistrationID } = this.state
        console.log(this.state.list)
        if (exhibitionID == '' || exhibitionID == null || exhibitionID == 'select') {
            toastError('Please select exhibition ID')
        }
        else {
            this.setState({ showTable: true })
        }
    }

    getTableData(list) {
        const { } = this.state
        // for column data 
        var columnArray = []
        var rowData = []
        console.log('columnArray', list);

        list.map((data, index) => {
            if (index == 0) {
                console.log('columnArray', data, Object.keys);

                Object.keys(data).map((key, index) => {

                    columnArray.push(key)
                })
            }
        })
        this.setState({ columnArr: columnArray })
        console.log('columnArray', columnArray);
        var xlRowdata = []
        // // for row data
        list.map((data, index) => {
            rowData.push(Object.values(data))
            xlRowdata.push(data)
        })
        this.setState({ rowArr: rowData })
        this.setState({ xlrowArr: xlRowdata })
        setTimeout(() => {

            console.log(rowData, xlRowdata)
        }, 500);

        // this.renderTable()
        // if (listName == 'SLA_BreachList') {
        //     console.log("onReset 12", list)

        //     this.setState({
        //         SLA_BreachRowData: rowData,
        //         SLA_BreachColumnArray: columnArray,
        //         temp_SLA_BreachRowData: rowData,
        //     })
        //     columnArray = []
        //     rowData = []
        //     console.log("onReset 12 original_SLA_BreachRowData", original_SLA_BreachRowData)
        //     setTimeout(() => {
        //         this.getClientList()
        //     }, 300);
        // }
        // if (listName == 'original_SLA_BreachList') {
        //     console.log("onReset 12 original_SLA_BreachList", list)
        //     this.setState({
        //         original_SLA_BreachRowData: rowData,
        //         SLA_BreachRowData: rowData
        //     })
        //     columnArray = []
        //     rowData = []
        //     console.log("onReset 12 original_SLA_BreachRowData", original_SLA_BreachRowData)

        // }
    }

    onDownloadClick() {
        const { list, columnArr, rowArr, exhibitionID, reportName } = this.state
        if (exhibitionID == '' || exhibitionID == null || exhibitionID == 0) {
            toastError('Please select exhibition ID')
        }
        else {
            this.setState({ showTable: true })
            return (
                <div className="row text-center">
                    {list.length > 0 && <Workbook filename={`${reportName}.xlsx`} style={{}} element={
                        <button type="button" className="btn ad-fil-btn m-0" >
                            download
                        </button>
                    }>
                        <Workbook.Sheet data={list} name="Sheet A">
                            {columnArr.map((value, index) =>
                                <Workbook.Column label={value} value={value} />
                            )
                            }
                        </Workbook.Sheet>
                    </Workbook>}
                </div>
            );
        }

    }

    getViewAccess() {
        const { reportAccessList, reportID } = this.state
        const moduleAccess = reportAccessList.find((item) => item.ReportID == reportID);
        if (moduleAccess) {
            return moduleAccess.ViewAccess;
        } else {
            return false
        }
        return false;
    }

    getDownloadAccess() {
        const { reportAccessList, reportID } = this.state
        const moduleAccess = reportAccessList.find((item) => item.ReportID == reportID);
        if (moduleAccess) {
            return moduleAccess.DownloadAccess;
        } else {
            return false
        }
        return false;
    }


    renderTable() {
        const { columnArr, rowArr } = this.state
        console.log('k')
        return (
            <>
                <div className="table-responsive mb-4">
                    <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1">
                        <thead> <tr>
                            {/* <th><input type="checkbox" key={Math.random()} defaultChecked={isAllCheck} onClick={() => this.allCheckClick(!isAllCheck)} /></th> */}
                            {
                                columnArr.length > 0 && columnArr.map((data, index) => (
                                    <>  {(<th>{data}</th>)}</>
                                ))
                            }    </tr>

                        </thead>

                        <tbody>
                            {
                                rowArr.length > 0 ?
                                    rowArr.map((data, i) => (
                                        <tr key={i}>
                                            {/* <td><input type="checkbox" key={Math.random()} defaultChecked={data[data.length - 1]} onClick={() => this.onSelectCheckClick(data[data.length - 2], !data[data.length - 1])} /></td> */}
                                            {data != null && data != '' && data.map((element, index) => (
                                                <>
                                                    <td>{element}
                                                    </td></>

                                            ))}
                                        </tr>
                                    )) : <tr><td colSpan={3} style={{ textAlign: 'center' }}>Record not found</td></tr>
                            }
                        </tbody>
                    </table>
                </div>
            </>
        )
    }

    render() {
        const { isLoading, reportID, reportName, rowArr, exhibitorList, showTable, exhibitionID, ExhRegistrationID, exhibitionList, xlrowArr, columnArr, list } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                <div className={JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ? "with-dr-container" : "with-dr-container com-height"}>
                    <AdminDrawer />
                    <div className="row">
                        <div className="step-name-space" style={{ zIndex: 9 }}>
                            <p>{reportName}</p>
                        </div>
                    </div>

                    <div className="main-container">
                        <div className="row mt-5 m-2">
                            <div className="col-xl-3">
                                <label htmlFor="validationCustom03">Select Exhibiton <span className="text-red">*</span></label>
                                <select className="form-control"
                                    value={exhibitionID}
                                    // disabled={isView}
                                    onChange={(e) => this.reportIdCondition("exhibitionID", e.target.value != '' && e.target.value)}  >
                                    <option value={'select'}>Select</option>
                                    {
                                        exhibitionList.length > 0 && exhibitionList.map(data => (
                                            <option value={data.ExhibitionID} key={data.ExhibitionID}>{data.ExhibitionName}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="col-xl-3">
                                <label htmlFor="validationCustom03">Select Exhibitor company</label>
                                <select className="form-control"
                                    value={ExhRegistrationID}
                                    // disabled={isView}
                                    onChange={(e) => this.reportIdCondition('ExhRegistrationID', e.target.value != '' && e.target.value)}  >
                                    <option value={'select'}>Select</option>
                                    <option value={0}>All</option>

                                    {
                                        exhibitorList.length > 0 && exhibitorList.map(data => (
                                            <option value={data.ExhRegistrationID} key={data.ExhRegistrationID}>{data.CompanyName}</option>
                                        ))
                                    }
                                </select>
                            </div>

                            <div className="btn-group mt-4 save-can d-block" style={{ float: 'right', right: '0px' }} role="group" aria-label="Basic example">
                                <button
                                    type="button"
                                    className="btn btn-outline-primary p-1 m-2"
                                    style={{ width: 140, fontSize: 15, }}
                                    disabled={!this.getViewAccess()}
                                    onClick={this.onViewClick}
                                >
                                    View
                                </button>
                                {list.length > 0 ? <Workbook filename={`${reportName}.xlsx`} style={{}} element={
                                    <button className="btn btn-outline-primary p-1 ml-3"
                                        disabled={!this.getDownloadAccess()}
                                        style={{ width: 140, fontSize: 15 }} >
                                        download
                                    </button>
                                }>
                                    <Workbook.Sheet data={xlrowArr} name="Sheet A">
                                        {columnArr.map((value, index) =>
                                            <Workbook.Column label={value} value={value} />
                                        )
                                        }
                                    </Workbook.Sheet>
                                </Workbook>
                                    : <button className="btn btn-outline-primary p-1" style={{ width: 140, fontSize: 15 }} disabled={true}>
                                        download
                                    </button>
                                }
                            </div>
                        </div>
                        {showTable && this.renderTable()}
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(connect(null, { fill_action, getExhibitorDetailsReport_action, getReceiptReport_action, getTaxInvoiceReport_action })(subReport1))