
import { connect } from "react-redux";
import { withRouter } from "../../../utils/withRouter";
import { Component } from "react";
import AdminDrawer from "../../../common_components/admin_module_drawer";
import Loader from "../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import HeaderWithLogo from "../../../common_components/header_with_logo";
import { featureActionId, toastError, toastSuccess } from "../../../utils/constants";
import { fill_action } from "../../../actions/fillCombo_action";
import { data } from "jquery";
import Modal from "react-modal";
import { exhibitorList_action } from "../../../actions/admin_module_action/exhibitor_list_action/exhibitor_list_action";
import Pagination from "../../../utils/pagination";
import { getCheckedExhibitors_action, insertUpdateFeatures_action } from "../../../actions/admin_module_action/features_action/features_action";

class Features extends Component {

    // Initialize Component 
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            featuresList: [],
            isOpen: false,
            featuresId: "",
            exhibitor: "",
            exhibition: "",
            exhibitorList: [],
            currentPage: 1,
            checkedExhibitors: [],
            checkedAll: false
        }
        this.getFillList = this.getFillList.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
        this.onMenuClick = this.onMenuClick.bind(this)
        this.getExhibitorList = this.getExhibitorList.bind(this)
        this.getCheckedExhibitors = this.getCheckedExhibitors.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.handleCheckedCompany = this.handleCheckedCompany.bind(this)
        this.selectAllCompany = this.selectAllCompany.bind(this)
        this.onSubmitClick = this.onSubmitClick.bind(this)
        this.updateItem = this.updateItem.bind(this)
    }

    // On Component Load
    componentDidMount() {
        this.getExhibitorList()
        this.getFillList(featureActionId, '', '', '', '', '');
    }

    // get fill List dropdown  
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, exhRegistrationID) {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value, exhRegistrationID)
            .then((data) => {
                if (data.error != 1) {
                    // Features list
                    if (actionID === featureActionId) {
                        console.log("Features data", data.data)
                        this.setState({
                            featuresList: data.data, isLoading: false,
                        })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    // Modal Toggle 2
    toggleModal() {
        const { isOpen } = this.state
        this.setState({
            isOpen: !isOpen,
            exhibition: "", exhibitor: ""
        })
        this.getExhibitorList()
    }

    // On Menu Click
    onMenuClick(id) {
        this.setState({ featuresId: id })
        this.toggleModal()
        setTimeout(() => {
            this.getCheckedExhibitors(id)
        }, 500);
    }

    // For Exhibitor List
    getExhibitorList() {
        const { exhibitor, exhibition } = this.state
        this.setState({ isLoading: true })
        this.props.exhibitorList_action(exhibition, exhibitor, null, null, null, null, null, null, null, null, null, null, null, "", "",
            null, null)
            .then((data) => {
                if (data.error != 1) {
                    data.data.length > 0 && data.data.map((item) => {
                        item.IsChecked = false
                    })
                    console.log("pppppppppppp", data.data)
                    this.setState({
                        exhibitorList: data.data['tableData'],
                        isLoading: false
                    })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // Get Cheked Exhibitors
    getCheckedExhibitors(id) {
        const { exhibitorList } = this.state
        this.setState({ isLoading: true })
        this.props.getCheckedExhibitors_action(id)
            .then((data) => {
                if (data.error != 1) {
                    console.log("Cheked Exhibitors", data.data)
                    exhibitorList.length > 0 && exhibitorList.map((item) => {
                        data.data.length > 0 && data.data.map((element) => {
                            if (element.ExhRegistrationID == item.ExhRegistrationID) {
                                item.IsChecked = true
                            }
                        })
                    })
                    this.setState({
                        checkedExhibitors: data.data,
                        exhibitorList: exhibitorList,
                        isLoading: false
                    })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // On Search Click
    onSearchClick() {
        this.getExhibitorList()
    }

    // On Checked Company
    handleCheckedCompany(e, id) {
        const { exhibitorList } = this.state
        const checked = e.target.checked

        exhibitorList.length > 0 && exhibitorList.map((data) => {
            if (data.ExhRegistrationID == id) {
                if (checked) {
                    data.IsChecked = true
                } else {
                    data.IsChecked = false
                    this.setState({ checkedAll: false })
                }
            }
        })
        console.log("aaaaaaaaaaa", exhibitorList)
        this.setState({})
    }

    //on Select All Click
    selectAllCompany(e) {
        const { checkedAll, exhibitorList } = this.state
        this.setState({ checkedAll: !checkedAll })
        const checked = e.target.checked
        exhibitorList.length > 0 && exhibitorList.map((data) => {
            if (checked) {
                data.IsChecked = true
            } else {
                data.IsChecked = false
            }
        })
        console.log("yyyyyyyyyyyyy", exhibitorList)
    }

    // On Submit Click
    onSubmitClick() {
        const { featuresId, exhibitorList } = this.state
        var list = []
        var mapData
        exhibitorList.length > 0 && exhibitorList.map((data) => {
            mapData = {
                "ExhregistrationID": parseInt(data.ExhRegistrationID),
                "IS_Checked": data.IsChecked
            }
            list.push(mapData)
        })
        this.setState({ isLoading: true })
        this.props.insertUpdateFeatures_action(featuresId, list)
            .then((data) => {
                if (data.error != 1) {
                    toastSuccess("Features Enabled Successfully")
                    this.setState({ isLoading: false })
                    this.toggleModal()
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // Update Current Page
    updateItem(item) {
        const { exhibitorList } = this.state
        this.setState({ currentPage: item });
        const recordsPerPage = 10;
        const indexOfLastRecord = item * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = exhibitorList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(exhibitorList.length / recordsPerPage)
    }

    render() {
        const { isLoading, featuresList, isOpen, exhibitor, exhibition, exhibitorList, currentPage, checkedAll } = this.state
        const recordsPerPage = 10;
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = exhibitorList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(exhibitorList.length / recordsPerPage)
        return (

            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                <AdminDrawer />
                <div className="main-content with-dr-container com-height">
                    <div className="">
                        <div className="row">
                            <div className="step-name">
                                <p>Features</p>
                            </div>
                        </div>
                        <div >
                            <div className="row m-2">
                                <div className="table-responsive mt-1" style={{ width: 700 }}>
                                    <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 ">
                                        <thead>
                                            <tr>
                                                <th className="text-center" style={{ width: 100 }}>
                                                    Sr.No
                                                </th>
                                                <th>Features Name</th>
                                                <th className="text-center" style={{ width: 150 }}>
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                featuresList.length > 0 && featuresList.map((data, index) => (
                                                    <tr key={Math.random()}>
                                                        <td className="text-center">{index + 1}</td>
                                                        <td>{data.Name}</td>
                                                        <td className="text-center">

                                                            <i class="fa fa-th-large" aria-hidden="true"
                                                                onClick={(e) => this.onMenuClick(data.FeaturesID)}
                                                            ></i>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <style
                                dangerouslySetInnerHTML={{
                                    __html:
                                        '\n.switch {\n  position: relative;\n  display: inline-block;\n  width: 55px;\n  height: 25px;\n}\n\n.switch input { \n  opacity: 0;\n  width: 0;\n  height: 0;\n}\n\n.slider {\n  position: absolute;\n  cursor: pointer;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: #ccc;\n  -webkit-transition: .4s;\n  transition: .4s;\n}\n\n.slider:before {\n  position: absolute;\n  content: "";\n  height: 20px;\n  width: 20px;\n  left: 4px;\n  bottom: 3px;\n  background-color: white;\n  -webkit-transition: .4s;\n  transition: .4s;\n}\n\ninput:checked + .slider {\n  background-color: #3d9b67;\n}\n\ninput:focus + .slider {\n  box-shadow: 0 0 1px #2196F3;\n}\n\ninput:checked + .slider:before {\n  -webkit-transform: translateX(26px);\n  -ms-transform: translateX(26px);\n  transform: translateX(26px);\n}\n\n/* Rounded sliders */\n.slider.round {\n  border-radius: 34px;\n}\n\n.slider.round:before {\n  border-radius: 50%;\n}\n'
                                }}
                            />

                        </div>
                    </div>
                </div>
                <Modal isOpen={isOpen} onRequestClose={(e) => this.toggleModal(e)}>
                    <div
                        className="modal-dialog modal-dialog-centered bd-example-modal-lg"
                        id="detail"

                    >
                        <div className="modal-dialog modal-md">
                            <div className="modal-content estimate-details">
                                <div className="modal-header pb-1">
                                    <h5 className="modal-title" id="detailLabel">
                                        <strong>Generate Hall No / Stall No</strong>
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close pt-6"
                                        data-bs-dismiss="modal"
                                        style={{ fontSize: 12 }}
                                        aria-label="Close"
                                        onClick={(e) => this.toggleModal(e)}
                                    />
                                </div>
                                <div className="modal-body p-2">
                                    <div className="row mt-4 mb-4 m-2">
                                        <div className="col-md-4">
                                            <label htmlFor="validationCustom03">Exhibitor</label>
                                            <input type="text" className="form-control"
                                                value={exhibitor}
                                                onChange={(e) => this.setState({ exhibitor: e.target.value })}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="validationCustom03">Exhibition</label>
                                            <input type="text" className="form-control"
                                                value={exhibition}
                                                onChange={(e) => this.setState({ exhibition: e.target.value })}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <fieldset className="d-flex mt-4 mb-4">
                                                <button
                                                    className="btn rounded-0 btn-dark"
                                                    style={{ padding: "3px 7px" }}
                                                    onClick={(e) => this.onSearchClick()}
                                                >
                                                    Search
                                                </button>
                                            </fieldset>
                                        </div>
                                        <div className="table-responsive mt-1 ">
                                            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 ">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center">
                                                            <input type="checkbox"
                                                                checked={checkedAll}
                                                                onChange={(e) => this.selectAllCompany(e)}
                                                            />
                                                        </th>
                                                        <th className="text-center">Sr.No</th>
                                                        <th className="text-center">Exhibition</th>
                                                        <th className="text-center">Registration No</th>
                                                        <th>Company Name</th>
                                                        <th>Primary Email</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        exhibitorList.length > 0 && currentRecords.map((data, index) => (
                                                            <tr key={Math.random()}>
                                                                <td className="text-center">
                                                                    <input type="checkbox"
                                                                        value={data.IsChecked}
                                                                        checked={data.IsChecked}
                                                                        onChange={(e) => this.handleCheckedCompany(e, data.ExhRegistrationID)}
                                                                    />
                                                                </td>
                                                                <td className="text-center">{index + 1}</td>
                                                                <td>{data.ExhibitionName}</td>
                                                                <td className="text-center">{data.ExhRegistrationNo}</td>
                                                                <td>{data.ExhibitorName}</td>
                                                                <td>{data.PrimaryEmail}</td>
                                                            </tr>
                                                        ))
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="mt-2 float-left">
                                            {
                                                exhibitorList.length > 0 ?
                                                    <Pagination
                                                        nPages={nPages}
                                                        currentPage={currentPage}
                                                        setCurrentPage={this.updateItem}
                                                    /> : <></>
                                            }
                                            <div className="tab-rec " style={{ float: "right" }}>
                                                <p>{`${currentPage} of ${nPages} pages`}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer p-1">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={(e) => this.onSubmitClick(e)}
                                    >
                                        Submit
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={(e) => this.toggleModal(e)}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

            </>
        )
    }
}

export default withRouter(connect(null, { fill_action, exhibitorList_action, getCheckedExhibitors_action, insertUpdateFeatures_action })(Features))