import { INSERT_UPDATE_EXH_NOTE_CONFIGRATION, GET_EXH_NOTE_DETAILS } from "../../../utils/constants";

const initialState = [];

function exhNoteReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case INSERT_UPDATE_EXH_NOTE_CONFIGRATION:
            return [...state, payload];
        case GET_EXH_NOTE_DETAILS:
            return [...state, payload];

        default:
            return state;
    }
};

export default exhNoteReducer