import { axiosGet, axiosPost } from "../../../react_axios/Axios";
import { getHallMasterDetailsUrl, getHallMasterListUrl, deleteHallMasterUrl, insertUpdateHallMasterUrl, GET_HALL_MASTER_DETAILS, GET_HALL_MASTER_LIST, INSERT_UPDATE_HALL_MASTER, DELETE_HALL_MASTER, headers } from "../../../utils/constants";

export const getHallMasterList_action = (ExhibitionID, HallName) => async (dispatch) => {
    try {
        const params = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExhibitionID,
            HallName: HallName,
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getHallMasterListUrl, params, headers)

        dispatch({
            type: GET_HALL_MASTER_LIST,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const deleteHallMaster_action = (HallId) => async (dispatch) => {
    try {
        const params = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: JSON.parse(sessionStorage.getItem("sessionExhibitionID")),
            HallId: HallId

        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(deleteHallMasterUrl, params, headers)

        dispatch({
            type: DELETE_HALL_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const insertUpdateHallMaster_action = (ExhibitionID, HallName, HallId, chapterList) => async (dispatch) => {
    try {
        const params = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExhibitionID,
            HallName: HallName,
            HallId: HallId,
            chapterList: chapterList,
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosPost(insertUpdateHallMasterUrl, params, headers)

        dispatch({
            type: INSERT_UPDATE_HALL_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getHallMasterDetails_action = (HallId) => async (dispatch) => {
    try {
        const params = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: JSON.parse(sessionStorage.getItem("sessionExhibitionID")),
            HallId: HallId
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getHallMasterDetailsUrl, params, headers)

        dispatch({
            type: GET_HALL_MASTER_DETAILS,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}