import { axiosGet, axiosPost } from "../../../react_axios/Axios";
import { getVisitorTypeMasterListUrl, deleteVisitorTypeMasterUrl, insertVisitorTypeMasterUrl, updateVisitorTypeMasterUrl, GET_VISITOR_TYPE_MASTER_LIST, DELETE_VISITOR_TYPE_MASTER, INSERT_VISITOR_TYPE_MASTER, UPDATE_VISITOR_TYPE_MASTER, headers } from "../../../utils/constants";

export const getVisitorTypeMasterList_action = (VisitorTypeName, Status) => async (dispatch) => {
    try {
        const params = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            VisitorTypeName: VisitorTypeName,
            Status: Status != '' && Status != '' ? Status : null,
        }
        console.log("getVisaInvitation_action Action------->", params)
        const res = await axiosGet(getVisitorTypeMasterListUrl, params, headers)

        dispatch({
            type: GET_VISITOR_TYPE_MASTER_LIST,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const deleteVisitorTypeMaster_action = (VisitorTypeID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            VisitorTypeID: VisitorTypeID,
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(deleteVisitorTypeMasterUrl, params, headers)

        dispatch({
            type: DELETE_VISITOR_TYPE_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const insertVisitorTypeMaster_action = (VisitorTypeName) => async (dispatch) => {
    try {
        const params = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            // countryID: countryID,
            // stateID: stateID,
            VisitorTypeName: VisitorTypeName,
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosPost(insertVisitorTypeMasterUrl, params, headers)

        dispatch({
            type: INSERT_VISITOR_TYPE_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const updateVisitorTypeMaster_action = (VisitorTypeID, VisitorTypeName) => async (dispatch) => {
    try {
        const params = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            // countryID: countryID,
            // stateID: stateID,
            VisitorTypeID: VisitorTypeID,
            VisitorTypeName: VisitorTypeName,
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosPost(updateVisitorTypeMasterUrl, params, headers)

        dispatch({
            type: UPDATE_VISITOR_TYPE_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

// export const getHallMasterDetails_action = (HallId) => async (dispatch) => {
//     try {
//         const params = {
//             UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
//             ExhibitionID: JSON.parse(sessionStorage.getItem("sessionExhibitionID")),
//             HallId: HallId
//         }
//         console.log("getVisaInvitation_action Action------->", params)

//         const res = await axiosGet(getHallMasterDetailsUrl, params, headers)

//         dispatch({
//             type: GET_HALL_MASTER_DETAILS,
//             payload: res,
//         });
//         return Promise.resolve(res)
//     } catch (error) {
//         return Promise.reject(error)
//     }
// }