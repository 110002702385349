import { axiosGet, axiosPost } from "../../../../../react_axios/Axios"
import { GET_RENTAL_AUDIO_AND_VIDEO_DETAILS_ACTION_TYPE, INSERT_UPDATE_RENTAL_AUDIO_AND_VIDEO_ACTION_TYPE, getRentalAudioAndVideoDetailsUrl, insertUpdateRentalAudioAndVideoUrl, headers } from "../../../../../utils/constants"


export const getRentalAudioAndVideoDetails_action = () => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhregistrationID: sessionStorage.getItem('ExhRegistrationIDSS')
        }
        // console.log("getMeetingRoomDetails_action ----->", params)
        const res = await axiosGet(getRentalAudioAndVideoDetailsUrl, params, headers)

        dispatch({
            type: GET_RENTAL_AUDIO_AND_VIDEO_DETAILS_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const insertUpdateRentalAudioAndVideo_action = (Comment, remark, is_Agree) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhregistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
            Comment: Comment,
            Remark: remark,
            Is_Agree: is_Agree,
        }
        // console.log("getMeetingRoomDetails_action ----->", params)
        const res = await axiosPost(insertUpdateRentalAudioAndVideoUrl, params)

        dispatch({
            type: INSERT_UPDATE_RENTAL_AUDIO_AND_VIDEO_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}