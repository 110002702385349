import { Component } from "react";
import { ToastContainer } from "react-toastify";
import Loader from "../../../utils/Loader";
import ExhibitorHeader from "../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../common_components/exhibitor_module_drawer";
import ExhibitorTopMenu from "../../../common_components/exhibitor_module_topmenu";
import { uploadUndertaking_action } from "../../../actions/exhibitor_module_actions/exhibitor_dashboard_action/exhibitor_dashboard_action";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import { toastError, toastSuccess, BASE_URL, DOC_BASE_URL } from "../../../utils/constants"
import { Link } from "react-router-dom";
import undertakingForm from "../../../files/1/Undertaking.pdf"
import Resizer from "react-image-file-resizer";



class Undertaking extends Component {

    // Initialize Constructor
    constructor(props) {
        super(props)
        //States
        this.state = {
            isLoading: false,
            exhibitorDashboardList: [],
            undertakingFile: "",
            undertakingFileType: "",
            url: ''


        }

        this.onCrossClick = this.onCrossClick.bind(this)
        this.onFilePick = this.onFilePick.bind(this)
        this.onPdfSelect = this.onPdfSelect.bind(this)
        this.resizeFile = this.resizeFile.bind(this)
        this.onUpload = this.onUpload.bind(this)
    }

    componentDidMount() {
        if (sessionStorage.getItem('sessionHeaderData') != null) {
            var urlPath = DOC_BASE_URL + JSON.parse(sessionStorage.getItem('sessionHeaderData'))[0]['UndertakingFile']
            console.log('url is : ', urlPath);
            this.setState({ url: urlPath })
        }

        console.log(sessionStorage.getItem("sessionExhibitionID"))
    }


    // on Cross 
    onCrossClick() {
        this.setState({ undertakingFile: "" })
        document.getElementById("vaccFile").value = ""
    }

    onFilePick = async (e, file) => {
        e.preventDefault()
        console.log("File01010101------", file)
        const { } = this.state
        if (file[0]['size'] >= 5 * 1024 * 1024) {
            toastError('File size more than 5mb are not allow.')
        } else {
            var image = '';
            if (file[0]['name'].toString().toLowerCase().includes('.pdf') ||
                file[0]['name'].toString().toLowerCase().includes('.jpeg') ||
                file[0]['name'].toString().toLowerCase().includes('.png') ||
                file[0]['name'].toString().toLowerCase().includes('.jpg')
            ) {

                if (file[0]['name'].toString().toLowerCase().trim().includes('.jpg') ||
                    file[0]['name'].toString().toLowerCase().trim().includes('.png') ||
                    file[0]['name'].toString().toLowerCase().trim().includes('.jpeg')
                ) {
                    image = await this.resizeFile(file[0]);
                    this.setState({ undertakingFile: image })
                }
                else if (file[0]['name'].toString().toLowerCase().trim().includes('.pdf')) {
                    image = await this.onPdfSelect(file[0]);
                    this.setState({ undertakingFile: image })
                }

                var fileType = file[0]['type']
                var fileTypeList;
                if (fileType.includes("/")) {
                    fileTypeList = fileType.split("/")

                }
                this.setState({ undertakingFileType: fileTypeList.length > 0 ? fileTypeList[1] : "" })
            } else {
                toastError('Only .pdf , .jpeg, .png, .jpg files are allowed')
            }
        }
    }

    onPdfSelect = (file) =>
        new Promise((resolve) => {
            let base64;
            // FileReader function for read the file.
            let fileReader = new FileReader();
            // Onload of file read the file content
            fileReader.onload = function (fileLoadedEvent) {
                base64 = fileLoadedEvent.target.result;
                // Print data in console
                resolve(base64);
            };
            // Convert data to base64
            fileReader.readAsDataURL(file);
        });

    resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                500,
                600,
                'JPEG',
                80,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64",
                300,
                300,
            );
        });

    onUpload = () => {
        const { undertakingFile, undertakingFileType } = this.state
        if (undertakingFile != '') {
            this.setState({ isLoading: true })
            this.props.uploadUndertaking_action(undertakingFile, undertakingFileType)
                .then((data) => {
                    if (data.error != 1) {
                        setTimeout(() => {
                            toastSuccess("Your Undertaking Form Successfully Uploaded.")
                        }, 500);
                        this.setState({ isLoading: false, undertakingFile: '', undertakingFileType: '' })
                        document.getElementById("vaccFile").value = ""
                        this.props.navigate(-1)

                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            toastError("Please select file.")
        }

    }


    render() {
        const { isLoading, exhibitorDashboardList, undertakingFile, url } = this.state

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <ExhibitorHeader />
                <ExhibitorDrawer />
                {/*app-content open*/}
                <div className="page">
                    <div className="page-main">
                        <div className="main-content  with-dr-container">
                            {/* <div className="container-fluid">
                                <div className="row">
                                    <div className="step-name">
                                <p>Exhibitor Dashboard</p>
                            </div>
                                    <ExhibitorTopMenu />
                                </div>
                            </div> */}
                            <div className="">
                                <div className=" width-dr-nav">
                                    <div className="row">
                                        <ExhibitorTopMenu />
                                    </div>
                                </div>
                                <div className="row" style={{ float: 'right' }}>
                                    <div className="btn btn-group">
                                        <a
                                            // href={undertakingForm}images/Rules-and-Regulations-for-ITME-AFRICA-M.E.-2023.html
                                            // href={'../../../files/1/Undertaking.pdf'}
                                            href={`files/${sessionStorage.getItem("sessionExhibitionID")}/Undertaking.pdf`}
                                            download="Undertaking"
                                            target="_blank"
                                            rel="noreferrer"
                                            style={{ marginRight: '4px' }}
                                        >
                                            <button
                                                className="btn btn-dark p-1"
                                                type="btn"
                                            >Download Empty Form
                                            </button>
                                        </a>

                                        {url != '' ? (
                                            <a
                                                href={url}
                                                download="Undertaking"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <button
                                                    className="btn btn-dark p-1"
                                                    type="btn"
                                                >Download Submitted Form
                                                </button>
                                            </a>
                                        ) : (null)}


                                    </div>

                                </div>
                                <div className="col-xl-4 mt-3">
                                    <label htmlFor="validationCustom03">
                                        Upload undertaking form
                                    </label>
                                    <div className="box">
                                        <div className="js--image-preview" />
                                        <div className="upload-options" style={{ position: 'relative' }}>
                                            <label>
                                                <input
                                                    type="file"
                                                    id="vaccFile"
                                                    className="image-upload"
                                                    accept=".pdf"
                                                    onChange={e => this.onFilePick(e, e.target.files)}
                                                />

                                            </label>
                                            {
                                                undertakingFile != "" &&
                                                <i class="fa fa-times text-danger img-can-two" onClick={() => this.onCrossClick()}></i>
                                            }

                                        </div>
                                    </div>

                                </div>
                                <div className="col-xl-4 mt-3 text-start">
                                    <button
                                        className="btn btn-dark p-1"
                                        type="btn"
                                        style={{ float: "left" }}
                                        onClick={() => this.onUpload()}
                                    >Upload
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        )
    }
}
export default withRouter(connect(null, { uploadUndertaking_action })(Undertaking));

