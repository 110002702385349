import { GET_OLD_PAYMENT_LIST_ACTION_TYPE, INSERT_PAYMENT_ACTION_TYPE } from "../../../utils/constants";


const initialState = [];

function updatePaymentReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case GET_OLD_PAYMENT_LIST_ACTION_TYPE:
            return [...state, payload];
        case INSERT_PAYMENT_ACTION_TYPE:
            return [...state, payload];

        default:
            return state;
    }
};

export default updatePaymentReducer