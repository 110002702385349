import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { getHousekeepingApplicationMasterList_action, deleteHousekeepingApplicationMaster_action, insertUpdateHousekeepingApplicationMaster_action } from '../../../../../actions/admin_module_action/master_action/manual_form_master_action/housekeeping_application_master_action'
import { fill_action } from '../../../../../actions/fillCombo_action'
import { BASE_URL, countryActionId, toastError, toastSuccess, visaIdTypeActionId } from "../../../../../utils/constants";
import { emailValidator, mobileNoValidator } from "../../../../../utils/Validator";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import { data } from "jquery";
import Resizer from "react-image-file-resizer";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";
import Passport_Front_1008_VIS_903747 from "../../../../../files/Passport_Front_1008_VIS_903747.jpeg"

class HousekeepingApplicationMasterAU extends Component {

    // initialize constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            // fields
            BankName: '',
            BeneficiaryName: '',
            BeneficiaryAddress: '',
            AccountNumber: '',
            SwiftCode: '',
            IFSCCodeValue: '',
            PANNumber: '',
            GSTNumber: '',
            Name: "",
            Address: "",
            Phone: '',
            Mobile: '',
            Fax: '',
            PrimaryEmail: '',
            SecondaryEmail: '',
            // List
            HousekeepingApplicationList: [],
            BankNameErr: '',
            BeneficiaryNameErr: '',
            BeneficiaryAddressErr: '',
            AccountNumberErr: '',
            SwiftCodeErr: '',
            IFSCCodeValueErr: '',
            PANNumberErr: '',
            GSTNumberErr: '',
            NameErr: "",
            AddressErr: "",
            MobileErr: '',
            PrimaryEmailErr: '',
            SecondaryEmailErr: '',
            index: 1,
        }
        this.OnCancel = this.OnCancel.bind(this)
        this.inputValidate = this.inputValidate.bind(this)
        // this.getFillList = this.getFillList.bind(this)
        this.renderHousekeepingApplicationList = this.renderHousekeepingApplicationList.bind(this)
        // this.onFilePick = this.onFilePick.bind(this)
        // this.onPdfSelect = this.onPdfSelect.bind(this)
        // this.resizeFile = this.resizeFile.bind(this)
        this.handleAddMore = this.handleAddMore.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.ClearValue = this.ClearValue.bind(this)
        // this.handleUpdate = this.handleUpdate.bind(this)
        // this.getDetails = this.getDetails.bind(this)
        // this.emailValidation = this.emailValidation.bind(this)
        // this.onCrossClick = this.onCrossClick.bind(this)
        // this.mobileValidation = this.mobileValidation.bind(this)
        // this.onDocDelete = this.onDocDelete.bind(this)
        // this.handleDelete = this.handleDelete.bind(this)
    }

    componentDidMount() {
        this.getHousekeepingApplicationMasterList()
    }

    // On cancel Click
    OnCancel() {
        this.props.navigate('/HousekeepingApplicationMasterList')
    }
    getHousekeepingApplicationMasterList(status) {
        this.setState({ isLoading: true })
        this.props.getHousekeepingApplicationMasterList_action()
            .then((data) => {
                if (data.error != 1) {
                    var contactList = data.data[0]
                    var bankDetailsList = data.data[1]
                    var finalList = []
                    for (var i = 0; i < contactList.length; i++) {
                        for (var j = 0; j < bankDetailsList.length; j++) {
                            if (contactList[i].HousekeepingContactPersonID == bankDetailsList[j].HousekeepingContactPersonID) {
                                var mergObj = { ...contactList[i], ...bankDetailsList[j] }
                                finalList.push(mergObj)
                            }
                        }
                    }
                    console.log(finalList)
                    this.setState({ HousekeepingApplicationList: finalList })
                    this.setState({ isLoading: false })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // Input Fields Validation
    inputValidate() {
        const { BankName, BeneficiaryName, BeneficiaryAddress, AccountNumber, SwiftCode, IFSCCodeValue, PANNumber, GSTNumber, Name, Address, Phone, Mobile, Fax, PrimaryEmail, SecondaryEmail } = this.state
        if (BankName.trim() == "") {
            this.setState({ BankNameErr: "Please Enter Bank Name" })
        }
        if (BeneficiaryName.trim() == "") {
            this.setState({ BeneficiaryNameErr: "Please Enter Beneficiary Name" })
        }
        if (BeneficiaryAddress.trim() == "") {
            this.setState({ BeneficiaryAddressErr: "Please Enter Beneficiary Address" })
        }
        if (AccountNumber == "") {
            this.setState({ AccountNumberErr: "Please Enter Account number" })
        }
        if (SwiftCode.trim() == "") {
            this.setState({ SwiftCodeErr: "Please Enter Swift Code" })
        }
        if (IFSCCodeValue == "") {
            this.setState({ IFSCCodeValueErr: "Please Enter IFSC code" })
        }
        if (PANNumber.trim() == "") {
            this.setState({ PANNumberErr: "Please Enter PAN number" })
        }
        if (GSTNumber == "") {
            this.setState({ GSTNumberErr: "Please Enter GST number" })
        }
        if (Name == "") {
            this.setState({ NameErr: "Please Enter Name" })
        }
        if (Address == "") {
            this.setState({ AddressErr: "Please Enter Address" })
        }
        if (Mobile == "") {
            this.setState({ MobileErr: "Please Enter Mobile No." })
        }
        if (PrimaryEmail == "") {
            this.setState({ PrimaryEmailErr: "Please Enter Primary Email" })
        } else {
            var PEmailerr = (emailValidator(PrimaryEmail) == '') ? false : emailValidator(PrimaryEmail)
            if (PEmailerr) {
                this.setState({ PrimaryEmailErr: "Please Enter Proper Primary Email" })
            }
        }
        if (SecondaryEmail != "" && SecondaryEmail != null) {
            var SEmailerr = (emailValidator(SecondaryEmail) == '') ? false : emailValidator(SecondaryEmail)
            if (SEmailerr) {
                this.setState({ SecondaryEmailErr: "Please Enter Proper Secondary Email" })
            }
        }
    }

    renderHousekeepingApplicationList() {
        const { HousekeepingApplicationList } = this.state
        return (
            <ul class="list-group " style={{ position: 'relative', zIndex: 1 }}>
                {
                    HousekeepingApplicationList.length > 0 && HousekeepingApplicationList.map((data, index) => (
                        data.isShow &&
                        <li class="list-group-item" key={Math.random()}>
                            <div className="added-more p-3 rounded-2">
                                <h5 style={{ color: "#0056ff" }}>Added Housekeepiing Application Master {data.index}</h5>
                                <h6 >Agency Contact </h6>
                                <div className="row mt-4">
                                    <div className="col-md-3">
                                        <h6>Name: {data.Name}</h6>
                                    </div>
                                    <div className="col-md-3">
                                        <h6>Mobile no.: {data.Mobile}</h6>
                                    </div>
                                    <div className="col-md-6">
                                        <h6>Address: {data.Address}</h6>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <h6>Telephone: {data.Phone}</h6>
                                    </div>
                                    <div className="col-md-3">
                                        <h6>Fax: {data.Fax}</h6>
                                    </div>
                                    <div className="col-md-3">
                                        <h6>Primary Email: {data.PrimaryEmail}</h6>
                                    </div>
                                    <div className="col-md-3">
                                        <h6>Secondary Email: {data.SecondaryEmail}</h6>
                                    </div>
                                </div>
                            </div>
                            <hr className="m-1" />
                            <div className="added-more p-3 rounded-2">
                                <h6>Bank Details </h6>
                                <div className="row mt-4">
                                    <div className="col-md-3">
                                        <h6>Bank Name: {data.BankName}</h6>
                                    </div>
                                    <div className="col-md-3">
                                        <h6>Beneficiary Name: {data.BeneficiaryName}</h6>
                                    </div>
                                    <div className="col-md-6">
                                        <h6>Beneficiary Address: {data.BeneficiaryAddress}</h6>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <h6>Account Number: {data.AccountNumber}</h6>
                                    </div>
                                    <div className="col-md-3">
                                        <h6>Swift Code: {data.SwiftCode}</h6>
                                    </div>
                                    <div className="col-md-3">
                                        <h6>IFSC Code: {data.IFSCCodeValue}</h6>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <h6>PAN Number: {data.PANNumber}</h6>
                                    </div>
                                    <div className="col-md-3">
                                        <h6>GST Number: {data.GSTNumber}</h6>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))
                }
            </ul>
        )
    }

    handleAddMore() {
        const {
            BankName, BeneficiaryName, HousekeepingApplicationList, index, BeneficiaryAddress, AccountNumber, SwiftCode, IFSCCodeValue, PANNumber, GSTNumber, Name, Address, Phone, Mobile, Fax, PrimaryEmail, SecondaryEmail, BankNameErr, BeneficiaryNameErr, BeneficiaryAddressErr, AccountNumberErr, SwiftCodeErr, IFSCCodeValueErr, PANNumberErr, GSTNumberErr, NameErr, AddressErr, MobileErr, PrimaryEmailErr, SecondaryEmailErr, } = this.state
        this.inputValidate()
        if (BankName != '' && BeneficiaryName != '' && BeneficiaryAddress != '' && AccountNumber != '' && SwiftCode != '' && IFSCCodeValue != '' && PANNumber != '' && GSTNumber != '' && Name != '' && Address != "" && Mobile != "" && PrimaryEmail != "") {
            if (NameErr == '' && BankNameErr == '' && BeneficiaryNameErr == '' && BeneficiaryAddressErr == '' && AccountNumberErr == '' && SwiftCodeErr == '' &&
                IFSCCodeValueErr == '' && PANNumberErr == '' && GSTNumberErr == '' && AddressErr == '' && MobileErr == '' && PrimaryEmailErr == '' && SecondaryEmailErr == '') {
                HousekeepingApplicationList.push({
                    'BankName': BankName,
                    'BeneficiaryName': BeneficiaryName,
                    'BeneficiaryAddress': BeneficiaryAddress,
                    'AccountNumber': AccountNumber,
                    'SwiftCode': SwiftCode,
                    'IFSCCodeValue': IFSCCodeValue,
                    'PANNumber': PANNumber,
                    'GSTNumber': GSTNumber,
                    'Name': Name,
                    'Address': Address,
                    'Phone': Phone,
                    'Mobile': Mobile,
                    'Fax': Fax,
                    'PrimaryEmail': PrimaryEmail,
                    'SecondaryEmail': SecondaryEmail,
                    'isShow': true,
                    'index': index
                })
                setTimeout(() => { this.ClearValue() }, 200);
            }
            else {
                toastError('Please enter proper fields')
            }
        }
        else {
            toastError('Please enter mandatory fields')
        }
        console.log(this.state.HousekeepingApplicationList)
    }

    handleSave() {
        const { HousekeepingApplicationList } = this.state
        this.setState({ isLoading: true })
        this.props.insertUpdateHousekeepingApplicationMaster_action(HousekeepingApplicationList)
            .then((data) => {
                if (data.error != 1) {
                    toastSuccess("Record Added Successfully")
                    setTimeout(() => {
                        this.setState({ isLoading: false })
                        this.props.navigate('/HousekeepingApplicationMasterList')
                    }, 300);
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    ClearValue() {
        this.setState({
            BankName: '',
            BeneficiaryName: '',
            BeneficiaryAddress: '',
            AccountNumber: '',
            SwiftCode: '',
            IFSCCodeValue: '',
            PANNumber: '',
            GSTNumber: '',
            Name: "",
            Address: "",
            Phone: '',
            Mobile: '',
            Fax: '',
            PrimaryEmail: '',
            SecondaryEmail: '',
            index: this.state.index + 1
        })
    }

    render() {
        const { isLoading, BankName, BeneficiaryName, HousekeepingApplicationList, BeneficiaryAddress, AccountNumber, SwiftCode, IFSCCodeValue, PANNumber, GSTNumber, Name, Address, Phone, Mobile, Fax, PrimaryEmail, SecondaryEmail, BankNameErr, BeneficiaryNameErr, BeneficiaryAddressErr, AccountNumberErr, SwiftCodeErr, IFSCCodeValueErr, PANNumberErr, GSTNumberErr, NameErr, AddressErr, MobileErr, PrimaryEmailErr, SecondaryEmailErr,
        } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <div className="app-content with-dr-container com-height">
                        <AdminDrawer />
                        <div className="row">
                            <div className="step-name">
                                <p>Houserkeeping Application Master</p>
                            </div>
                        </div>
                        <div className="m-2">
                            <div className="">

                                <div className="">
                                    <div
                                        className="card-header p-3"
                                        style={{ paddingLeft: "7px !important" }}
                                    >
                                        <h3 className="card-title" style={{ fontSize: 15 }}>
                                            Agency contact
                                        </h3>
                                    </div>
                                    <div className="card-body p-2 mt-1">
                                        <div className="row">
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    Name<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={Name}
                                                    onChange={(e) => this.setState({ Name: e.target.value, NameErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{NameErr}</span>}

                                            </div>
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    Mobile No.<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={Mobile}
                                                    onChange={(e) => this.setState({ Mobile: e.target.value, MobileErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{MobileErr}</span>}

                                            </div>
                                            <div className="col-xl-6">
                                                <label htmlFor="validationCustom03">
                                                    Address<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={Address}
                                                    onChange={(e) => this.setState({ Address: e.target.value, AddressErr: '' })}
                                                />

                                                {<span className='text-danger text-start text-left d-block mt-1'>{AddressErr}</span>}
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    Telephone
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={Phone}
                                                    onChange={(e) => this.setState({ Phone: e.target.value, })}
                                                />
                                            </div>
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    Fax
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={Fax}
                                                    onChange={(e) => this.setState({ Fax: e.target.value, })}
                                                />
                                            </div>
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">Primary Email<span className="text-red">*</span></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={PrimaryEmail}
                                                    onChange={(e) => this.setState({ PrimaryEmail: e.target.value, PrimaryEmailErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{PrimaryEmailErr}</span>}
                                            </div>
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">Secondary Email</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={SecondaryEmail}
                                                    onChange={(e) => this.setState({ SecondaryEmail: e.target.value, SecondaryEmailErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{SecondaryEmailErr}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <div
                                        className="card-header p-3"
                                        style={{ paddingLeft: "7px !important" }}
                                    >
                                        <h3 className="card-title" style={{ fontSize: 15 }}>
                                            Bank Details
                                        </h3>
                                    </div>
                                    <div className="card-body p-2 mt-1">
                                        <div className="row">
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    Bank Name<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={BankName}
                                                    onChange={(e) => this.setState({ BankName: e.target.value, BankNameErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{BankNameErr}</span>}

                                            </div>
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    Beneficiary Name<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={BeneficiaryName}
                                                    onChange={(e) => this.setState({ BeneficiaryName: e.target.value, BeneficiaryNameErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{BeneficiaryNameErr}</span>}

                                            </div>
                                            <div className="col-xl-6">
                                                <label htmlFor="validationCustom03">
                                                    Beneficiary Address<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={BeneficiaryAddress}
                                                    onChange={(e) => this.setState({ BeneficiaryAddress: e.target.value, BeneficiaryAddressErr: '' })}
                                                />

                                                {<span className='text-danger text-start text-left d-block mt-1'>{BeneficiaryAddressErr}</span>}
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    Account Number<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={AccountNumber}
                                                    onChange={(e) => this.setState({ AccountNumber: e.target.value, AccountNumberErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{AccountNumberErr}</span>}
                                            </div>
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    Swift Code<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={SwiftCode}
                                                    onChange={(e) => this.setState({ SwiftCode: e.target.value, SwiftCodeErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{SwiftCodeErr}</span>}

                                            </div>
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">IFSC Code<span className="text-red">*</span></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={IFSCCodeValue}
                                                    onChange={(e) => this.setState({ IFSCCodeValue: e.target.value, IFSCCodeValueErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{IFSCCodeValueErr}</span>}
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">PAN Number<span className="text-red">*</span></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={PANNumber}
                                                    onChange={(e) => this.setState({ PANNumber: e.target.value, PANNumberErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{PANNumberErr}</span>}
                                            </div>
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">GST Number<span className="text-red">*</span></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={GSTNumber}
                                                    onChange={(e) => this.setState({ GSTNumber: e.target.value, GSTNumberErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{GSTNumberErr}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="btn-group mt-2 save-can d-block">
                                    <div className="btn-group mt-2" role="group" aria-label="Basic example">
                                        <button
                                            style={{ marginRight: 10 }}
                                            type="button"
                                            className="btn btn-primary rounded-0 d-block"
                                            // style={{ }}
                                            onClick={() => this.handleAddMore()}
                                        >
                                            {this.state.index == 1 ? 'Add' : 'Add More'}
                                        </button>
                                        {/* <button type="button" class="btn btn-primary rounded-0">Payment Listing Page</button> */}
                                    </div>

                                    <hr />
                                    {this.renderHousekeepingApplicationList()}

                                    <div className="row">
                                        <div className="btn-group mt-2 save-can d-block" role="group" style={{ float: 'right' }} aria-label="Basic example">
                                            <button
                                                type="button"
                                                className="btn btn-success rounded-0"
                                                style={{ marginRight: '10px', width: '100px' }}
                                                onClick={() => this.handleSave()}
                                                disabled={this.state.index == 1}
                                            >
                                                Save
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-warning rounded-0"
                                                style={{ marginRight: '10px', width: '100px' }}
                                                onClick={() => this.OnCancel()}
                                            >
                                                Back
                                            </button>

                                            {/* <button type="button" class="btn btn-primary rounded-0">Payment Listing Page</button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </>
            </>
        )
    }
}
export default withRouter(connect(null, { getHousekeepingApplicationMasterList_action, deleteHousekeepingApplicationMaster_action, insertUpdateHousekeepingApplicationMaster_action, fill_action })(HousekeepingApplicationMasterAU))