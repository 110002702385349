import { SELECT_APPLICATION_ACTION_TYPE } from "../../../utils/constants";


const initialState = [];

function selectApplicationReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case SELECT_APPLICATION_ACTION_TYPE:
            return [...state, payload];

        default:
            return state;
    }
};

export default selectApplicationReducer