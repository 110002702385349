import { Component } from "react";
import Loader from "../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action } from '../../../actions/fillCombo_action';
import { cityActionId, countryActionId, adminUserRoleListActionId, toastError, toastSuccess, roleListActionId, exhibitionActionId } from "../../../utils/constants";
import DatePicker from "react-datepicker";
import Footer from '../../../common_components/Footer';
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import Pagination from "../../../utils/pagination";
import AdminDrawer from "../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../common_components/header_with_logo";
import { useNavigate } from "react-router-dom";
import ExhibitorDrawer from "../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../common_components/exhibitor_module_header";
import { data } from "jquery";
import { getuserList_action, deleteAdmin_action } from "../../../actions/admin_module_action/user_action/user_action";


class UserList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userList: [],
            isLoading: false,
            exhibitionList: [],
            roleList: [],
            Name: '',
            RoleID: '',
            isOpen: false,
            deleteID: '',
            roleName: '',
            exhibitionID: '',
            currentPage: 1,
            exhibitionName: '',
        }
        this.getUserList = this.getUserList.bind(this)
        this.clearSearch = this.clearSearch.bind(this)
        this.getFillList = this.getFillList.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.deleteUser = this.deleteUser.bind(this)
        this.onDelete = this.onDelete.bind(this)
        this.updateItem = this.updateItem.bind(this)
    }

    componentDidMount() {
        this.getFillList(exhibitionActionId, '', '', '', '', '');
        this.getFillList(adminUserRoleListActionId, '', '', '', '', '');

        this.getUserList()
    }

    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value)
            .then((data) => {
                if (data.error != 1) {
                    // country list
                    if (actionID === exhibitionActionId) {
                        console.log("country data", data.data)
                        // var internationalCountryData = data.data.length > 0 && data.data.filter((item) => item.Code != "91")
                        this.setState({
                            exhibitionList: data.data
                        })
                    }
                    if (actionID === adminUserRoleListActionId) {
                        console.log("country data", data.data)
                        // var internationalCountryData = data.data.length > 0 && data.data.filter((item) => item.Code != "91")
                        this.setState({
                            roleList: data.data
                        })
                    }
                }
                this.setState({ isLoading: false })
            })
            .catch((e) => {
                console.log(e);
            });
    }

    handleSearch() {
        this.getUserList()
    }

    clearSearch() {
        const { exhibitionID, RoleID, Name } = this.state
        this.setState({
            exhibitionID: '',
            RoleID: '',
            Name: ''
        })
        setTimeout(() => {
            this.getUserList()
        }, 100);
    }

    getUserList() {

        const { exhibitionID, RoleID, Name } = this.state
        this.setState({ isLoading: true })
        this.props.getuserList_action(exhibitionID != null && exhibitionID != '' ? exhibitionID : null, RoleID != null && RoleID != '' ? RoleID : null, Name != null && Name != '' ? Name : null)
            .then((data) => {
                if (data.error != 1) {
                    console.log(data.data)
                    this.setState({ userList: data.data })
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // deleteRoleMaster(id) {
    //     this.setState({ isLoading: true })
    //     this.props.deleteRoleMaster_action(id)
    //         .then((data) => {
    //             if (data.error != 1) {
    //                 console.log(data.data)
    //                 this.setState({ isLoading: false })
    //                 toastSuccess("deleted successfully")
    //                 setTimeout(() => {
    //                     this.getUserList()
    //                 }, 200);
    //             }
    //             else {
    //                 this.setState({ isLoading: false })
    //                 toastError(data.msg)
    //             }
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         })
    // }

    // Update Current Page
    updateItem(item) {
        const { userList } = this.state
        this.setState({ currentPage: item });
        const recordsPerPage = 10;
        const indexOfLastRecord = item * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = userList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(userList.length / recordsPerPage)
    }

    record(list) {
        return (
            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
                <thead>
                    <tr>
                        {/* <th className="text-center">Role ID</th> */}
                        <th>Name</th>
                        <th className="text-center">Company Name</th>
                        <th className="text-center">Designation</th>
                        <th className="text-center">Role</th>
                        {/* <th className="text-center">Address1</th> */}
                        {/* <th className="text-center">Address2</th> */}
                        {/* <th className="text-center">City</th> */}
                        {/* <th className="text-center">State</th> */}
                        <th className="text-center">Country</th>
                        {/* <th className="text-center">Pin</th> */}
                        {/* <th className="text-center">Mobile No</th> */}
                        {/* <th className="text-center">Alternate Mobile no</th> */}
                        {/* <th className="text-center">Telephone</th> */}
                        <th className="text-center">UserName</th>
                        <th className="text-center">Password</th>
                        <th style={{ width: '60px' }} className="text-center"></th>
                        <th style={{ width: '60px' }} className="text-center"></th>

                    </tr>
                </thead>
                <tbody>
                    {
                        list.length > 0 ? <>{
                            list.map((data, i) => (
                                <tr key={Math.random()}>
                                    {/* <td className="text-center">{data.RoleID}</td> */}
                                    <td >{data.FirstName} {data.LastName}</td>
                                    <td className="text-center">{data.CompanyName}</td>
                                    <td className="text-center">{data.Designation}</td>
                                    <td className="text-center">{data.RoleName}</td>
                                    {/* <td className="text-center">{data.Address1}</td> */}
                                    {/* <td className="text-center">{data.Address2}</td> */}
                                    {/* <td className="text-center">{data.City}</td> */}
                                    {/* <td className="text-center">{data.State}</td> */}
                                    <td className="text-center">{data.Country}</td>
                                    {/* <td className="text-center">{data.Pin}</td> */}
                                    {/* <td className="text-center">{data.MobileNo}</td> */}
                                    {/* <td className="text-center">{data.AlternateMobileNo}</td> */}
                                    {/* <td className="text-center">{data.Telephone}</td> */}
                                    <td className="text-center">{data.UserName}</td>
                                    <td className="text-center">{data.Password}</td>
                                    {/* <td className="text-center"> */}
                                    <td className="text-center">
                                        <i className="fa fa-pencil" aria-hidden="true"
                                            onClick={(e) => this.handleUpdate(data.AccountID)}

                                        />

                                    </td>
                                    <td className="text-center">
                                        <i className="fa fa-trash" aria-hidden="true"
                                            onClick={(e) => this.onDelete(data.AccountID)}


                                        />
                                    </td>
                                </tr>
                            ))}
                        </> :
                            <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                    }
                </tbody>
            </table>)
    }

    handleAdd() {
        this.props.navigate('/userAU', { state: { is_add: true, } })
    }

    handleUpdate(id) {
        this.props.navigate('/userAU', { state: { is_update: true, userId: id } })
    }

    deleteUser(id) {
        this.setState({ isLoading: true })
        this.props.deleteAdmin_action(id)
            .then((data) => {
                if (data.error != 1) {
                    console.log(data.data)
                    this.setState({ isLoading: false })
                    toastSuccess("deleted successfully")
                    setTimeout(() => {
                        this.getUserList()
                    }, 200);
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
                this.setState({ isOpen: false })
            })
            .catch((err) => {
                console.log(err)
            })
    }

    onDelete(i) {
        const { } = this.state
        this.setState({ deleteID: i })
        this.setState({ isOpen: true, })
    }

    render() {
        const { isLoading, isOpen, exhibitionList, currentPage, roleList, Name, userList, exhibitionID, exhibitionName, roleName, RoleID, } = this.state
        const recordsPerPage = 10;
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = userList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(userList.length / recordsPerPage)
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                <div className={JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ? "with-dr-container" : "with-dr-container com-height"}>
                    <div className="main-container">
                        <AdminDrawer />
                        <div className="row">
                            <div className="step-name-space" style={{ zIndex: 9 }}>
                                <p>User</p>
                            </div>
                        </div>
                    </div>
                    <div className="main-container p-2 mt-5">
                        <div className="row ">
                            {
                                JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 1 &&
                                <div className="row ">
                                    <div className="col-xl-3 mb-3">
                                        <label>Exhibition</label>
                                        <select className="form-control"
                                            value={exhibitionID}
                                            // disabled={isView}
                                            onChange={(e) => this.setState({ exhibitionID: e.target.value })}  >
                                            <option value={''}>Select</option>
                                            {/* <option value={0}>All</option> */}
                                            {
                                                exhibitionList.length > 0 && exhibitionList.map(data => (
                                                    <option value={data.ExhibitionID} key={data.ExhibitionID}>{data.ExhibitionName}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="col-xl-3 mb-3">
                                        <label>Role</label>
                                        <select className="form-control"
                                            value={RoleID}
                                            // disabled={isView}
                                            onChange={(e) => this.setState({ RoleID: e.target.value })}  >
                                            <option value={''}>Select</option>
                                            {/* <option value={0}>All</option> */}
                                            {
                                                roleList.length > 0 && roleList.map(data => (
                                                    <option value={data.RoleID} key={data.RoleID}>{data.RoleName}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="col-xl-3 mb-3">
                                        <label>Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue=""
                                            required=""
                                            value={Name}
                                            onChange={(e) => this.setState({ Name: e.target.value, })}
                                        />
                                    </div>
                                </div>
                            }
                        </div >
                        < div className="row" >
                            <div className="col-md-12">
                                {
                                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 1 &&
                                    <div
                                        className="btn-group mb-1"
                                        role="group"
                                        aria-label="Basic example"
                                        style={{ float: "right" }}
                                    >
                                        <button type="button" className="btn ad-fil-btn"
                                            onClick={e => this.handleSearch()}
                                        >
                                            search
                                        </button>
                                        <button type="button" className="btn ad-fil-btn"
                                            onClick={e => this.handleAdd()}
                                        >
                                            Add
                                        </button>
                                        <button type="button" className="btn ad-fil-btn"
                                            onClick={e => this.clearSearch()}
                                        >
                                            Clear
                                        </button>
                                    </div>
                                }
                                <div className="table-responsive">
                                    {this.record(currentRecords)}
                                </div>
                                <div className="mt-2 float">
                                    {
                                        userList.length > 0 ?
                                            <Pagination
                                                nPages={nPages}
                                                currentPage={currentPage}
                                                setCurrentPage={this.updateItem}
                                            /> : <></>
                                    }
                                    <div className="tab-rec " style={{ float: "right" }}>
                                        <p>{`${currentPage} of ${nPages} pages`}</p>
                                    </div>
                                </div>
                                {/* <div className="mt-2 float-left">
                                    {
                                        exhibitorList.length > 0 ?
                                            <Pagination
                                                nPages={nPages}
                                                currentPage={currentPage}
                                                setCurrentPage={this.updateItem}
                                            /> : <></>
                                    }
                                    <div className="tab-rec " style={{ float: "right" }}>
                                        <p>{`${currentPage} of ${nPages} pages`}</p>
                                    </div>
                                </div> */}
                            </div>
                        </div >
                    </div >
                    <Footer />
                </div >
                <Modal isOpen={isOpen} onRequestClose={() => this.setState({ isOpen: false })}>
                    <div className="modal-dialog" style={{ marginTop: '20%' }}>
                        <div className="modal-content confirm-logout">
                            <div class="modal-header text-right p-2">
                                <h5 class="modal-title modal_title_text text-danger">Do you really want to delete this record?</h5>

                                {/* <button type="button " class="close pop-close" data-dismiss="modal"
                                    onClick={() => this.setState({ isOpen: false })}
                                ><span aria-hidden="true" class="pop-cancel">&times;</span></button> */}
                            </div>
                            {/* <div class="modal-body">
                                <div class="row">
                                </div>
                            </div> */}
                            <div class="modal-footer p-1">
                                <div class="form-group text-right mod-fot">
                                    <button type="button" class="btn btn-del-pop"
                                        onClick={(e) => this.deleteUser(this.state.deleteID)}
                                    >Yes</button>
                                    <button type="button" class="btn btn-can-pop" data-dismiss="modal" style={{ marginLeft: '5px' }}
                                        onClick={() => this.setState({ isOpen: false })}
                                    >No</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
            </>
        )
    }
}

export default withRouter(connect(null, { fill_action, getuserList_action, deleteAdmin_action })(UserList));