import { ADD_FASCIA_FORM_ACTION_TYPE,
    GET_FASCIA_FORM_ACTION_TYPE,
    GET_FASCIA_DEFAULT_ACTION_TYPE,
    UPDATE_FASCIA_FORM_ACTION_TYPE,
 } from "../../../../../utils/constants";


const initialState = [];

function fasciaFormReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case GET_FASCIA_FORM_ACTION_TYPE:
            return [...state, payload];
        case GET_FASCIA_DEFAULT_ACTION_TYPE:
            return [...state, payload];
        case UPDATE_FASCIA_FORM_ACTION_TYPE:
            return [...state, payload];
        case ADD_FASCIA_FORM_ACTION_TYPE:
            return [...state, payload];
        default:
            return state;
    }
};

export default fasciaFormReducer;