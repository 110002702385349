import {
    AGENT_RECEIPT_ENTRY_ACTION_TYPE, AGENT_RECEIPT_LIST_ACTION_TYPE, SHARE_EMAIL_ACTION_TYPE,
    AGENT_PERFORMA_INVOICE_ACTION_TYPE, AGENT_RECEIPT_DETAILS_ACTION_TYPE, proformaInvoiceUrl, receiptDetailsUrl,
    receiptUpdateUrl, fetchAgentReceiptListUrl, shareEmailUrl, headers, receiptAddUrl, receiptNoUrl, AGENT_RECEIPT_NUMBER_ACTION_TYPE, RECEIPT_ADD_ACTION_TYPE,
    receiptShareUrl, AGENT_RECEIPT_SHARE_ACTION_TYPE
    , GENERATE_VOUCHER_ACTION_TYPE, addAgentReceiptUrl, receiptDelUrl, getEmailDataUrl,
    generateVoucherUrl, AGENT_RECEIPT_GENERATE_VOUCHER_ACTION_TYPE, AGENT_RECEIPT_DEL_ACTION_TYPE, GET_EMAIL_ACTION_TYPE
    , AGENT_RECEIPT_UPDATE_ACTION_TYPE,
    getPendingReceiptDetailsUrl,
    PENDING_RECEIPT_DETAILS_ACTION_TYPE,
    fetchAuthReport,
    GET_RECEIPT_PDF_ACTION_TYPE,
    getAgentProformaInvoiceUrl,
    getAgentReceiptNoUrl,
    generateVoucherAgentUrl,
    getAgentReceiptDetailsUrl,
    updateAgentReceiptUrl,
    deleteAgentReceiptDetailsUrl,
    agentReceiptShareUrl
} from "../../../utils/constants"
import { axiosPost, axiosGet } from "../../../react_axios/Axios"
import moment from 'moment';



export const agent_receipt_entry_save_action = (voucherTypeId, exhibitorId, bankAccountId, currencyId, conRate, bankAmount, tdsAmount, exchdiif,
    onAccAmt, crAmount, totalAmount, recptNo, recptDate, pymtModeId, paymentReceivedDate, instrNo, instruDate, bankName,
    branchName, narration, checklnvListData, OtherChargesListData, accPaymentId, exhregistrationID) => async (dispatch) => {
        try {
            const mapdata = {
                UserID: parseInt(JSON.parse(sessionStorage.getItem('loginDataSS')).UserID),
                Token: 'token',
                LogedinAccountID: parseInt(JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID),
                ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
                // ExhRegistrationID: exhregistrationID,
                ReceiptID: null,
                VoucherTypeID: parseInt(voucherTypeId),
                ExhAccountID: parseInt(exhibitorId),
                BankID: parseInt(bankAccountId),
                CurrencyID: parseInt(currencyId),
                ConversionRate: parseFloat(conRate).toFixed(2),
                BankAmount: bankAmount != "" && bankAmount != null ? parseFloat(bankAmount).toFixed(2) : parseFloat(0).toFixed(2),
                TDSAmount: tdsAmount != "" && tdsAmount != null ? parseFloat(tdsAmount).toFixed(2) : parseFloat(0).toFixed(2),
                ExchangeDifference: exchdiif != "" && exchdiif != null ? parseFloat(exchdiif).toFixed(2) : 0,
                OnAccAmount: parseFloat(onAccAmt).toFixed(2),
                CreditAmount: parseFloat(crAmount).toFixed(2),
                TotalAmount: parseFloat(totalAmount).toFixed(2),
                ReceiptNo: recptNo,
                ReceiptDate: recptDate,
                PaymentModeID: parseInt(pymtModeId),
                PaymentReceivedDate: paymentReceivedDate,
                InstrumentNo: instrNo != "" ? instrNo : 0,
                InstrumentDate: instruDate,
                BankName: bankName,
                BranchName: branchName,
                Narration: narration,
                proformaList: checklnvListData,
                otherChargesList: OtherChargesListData,
                AccountPaymentID: accPaymentId != "" ? accPaymentId : null
            }
            console.log("Receiptentry-Action--------------------------->", mapdata)
            const res = await axiosPost(addAgentReceiptUrl, mapdata, headers);

            dispatch({
                type: AGENT_RECEIPT_ENTRY_ACTION_TYPE,
                payload: res,
            })
            return Promise.resolve(res);
        } catch (err) {
            return Promise.reject(err);
        }
    }


export const agent_receiptUpdate_action = (recptId, voucherTypeId, exhibitorId, bankAccountId, currencyId, conRate, bankAmount, tdsAmount,
    exchdiif, onAccAmt, crAmount, totalAmount, recptNo, recptDate, paymentReceivedDate, pymtModeId, instrNo, instruDate, bankName, branchName,
    narration, checklnvListData, OtherChargesListData, exhregistrationID) => async (dispatch) => {
        try {
            console.log('BankAmount : ', bankAmount);
            const mapData = {
                UserID: parseInt(JSON.parse(sessionStorage.getItem('loginDataSS')).UserID),
                Token: 'token',
                ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
                ExhRegistrationID: exhregistrationID,
                LogedinAccountID: parseInt(JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID),
                ReceiptID: parseInt(recptId),
                VoucherTypeID: parseInt(voucherTypeId),
                ExhAccountID: parseInt(exhibitorId),
                BankID: parseInt(bankAccountId),
                CurrencyID: parseInt(currencyId),
                ConversionRate: parseFloat(conRate).toFixed(2),
                BankAmount: bankAmount != "" && bankAmount != null ? parseFloat(bankAmount).toFixed(2) : parseFloat(0).toFixed(2),
                TDSAmount: tdsAmount != "" && tdsAmount != null ? parseFloat(tdsAmount).toFixed(2) : parseFloat(0).toFixed(2),
                ExchangeDifference: exchdiif != "" && exchdiif != null ? parseFloat(exchdiif).toFixed(2) : parseFloat(0).toFixed(2),
                OnAccAmount: parseFloat(onAccAmt).toFixed(2),
                CreditAmount: parseFloat(crAmount).toFixed(2),
                TotalAmount: parseFloat(totalAmount).toFixed(2),
                ReceiptNo: recptNo,
                ReceiptDate: recptDate,
                PaymentReceivedDate: paymentReceivedDate,
                PaymentModeID: parseInt(pymtModeId),
                InstrumentNo: instrNo != "" ? instrNo : 0,
                InstrumentDate: instruDate,
                BankName: bankName,
                BranchName: branchName,
                Narration: narration,
                proformaList: checklnvListData,
                otherChargesList: OtherChargesListData
            }
            console.log("ReceiptList-Action Update--------------------------->", mapData);
            const res = await axiosPost(updateAgentReceiptUrl, mapData, headers);
            dispatch({
                type: AGENT_RECEIPT_UPDATE_ACTION_TYPE,
                payload: res
            })
            return Promise.resolve(res)
        } catch (error) {
            return Promise.resolve(error)
        }
    }


export const agent_receipt_list_search_action = (exhibitor, recptDtFrom, recptDtTo, recptNo) => async (dispatch) => {

    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            AccountID: JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            token: 'token',
            Exhibitor: exhibitor,
            receiptFromDate: recptDtFrom != "" ? (moment(recptDtFrom).format('MM-DD-YYYY')) : null,
            receiptToDate: recptDtTo != "" ? (moment(recptDtTo).format('MM-DD-YYYY')) : null,
            receiptNo: recptNo
        }
        console.log("ReceiptListSearch -Action--------------------------->", params)

        const res = await axiosGet(fetchAgentReceiptListUrl, params, headers);
        dispatch({
            type: AGENT_RECEIPT_LIST_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }

}

export const agent_receiptDetails_action = (receiptID) => async (dispatch) => {

    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            AccountID: JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID,
            receiptID: receiptID,
            token: sessionStorage.getItem('tokenSS')
        }
        console.log("ReceiptList-Action--------------------------->", params)

        const res = await axiosGet(getAgentReceiptDetailsUrl, params, headers);
        dispatch({
            type: AGENT_RECEIPT_DETAILS_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }

}

export const agent_proformaInvoice_action = (ExhAccountID, exhID) => async (dispatch) => {

    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            AccountID: JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID,
            // ExhRegistrationID: exhID,
            ExhAccountID: ExhAccountID,
            token: sessionStorage.getItem('tokenSS')
        }
        console.log("ReceiptList-Action--------------------------->", params)

        const res = await axiosGet(getAgentProformaInvoiceUrl, params, headers);
        dispatch({
            type: AGENT_PERFORMA_INVOICE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }

}


export const agent_receiptNo_action = (exhibitorId) => async (dispatch) => {

    try {
        const params = {
            AccountID: exhibitorId,
            exhibitionid: sessionStorage.getItem('sessionExhibitionID')
        }

        const res = await axiosGet(getAgentReceiptNoUrl, params, headers);
        dispatch({
            type: AGENT_RECEIPT_NUMBER_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }

}

export const shareEmail_action = (toEmail, ccEmail, fileData) => async (dispatch) => {

    try {
        const mapdata = {
            toEmail: toEmail,
            ccEmail: ccEmail,
            attachedment: fileData
        }
        console.log("shareEmail_action--------------------------->", mapdata)

        const res = await axiosPost(shareEmailUrl, mapdata, headers);
        dispatch({
            type: SHARE_EMAIL_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }

}

export const agent_generateVoucher_action = (voucherTypeId, exhibitorId, bankAccountId, currency,
    conRate, bankAmount, tdsAmount, exchdiif, onAccAmt, crAmount, totalAmount,
    proformaList, otherChargesList, recptId) => async (dispatch) => {
        try {
            const mapData = {
                UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
                AccountID: JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID,
                token: sessionStorage.getItem('tokenSS'),
                voucherTypeId: voucherTypeId,
                ExhibitorId: exhibitorId,
                BankAccountId: bankAccountId,
                currency: currency,
                conRate: conRate,
                BankAmount: bankAmount,
                TDSAmount: tdsAmount,
                ExchDiff: exchdiif,
                onAccAmt: onAccAmt,
                CrAmount: crAmount,
                TotalAmount: totalAmount,
                proformaList: proformaList,
                otherChargesList: otherChargesList,
                receiptId: recptId != '' ? recptId : 0,
                exhibitionid: sessionStorage.getItem('sessionExhibitionID')
            }
            console.log("Generate Voucher Action --------->", mapData)
            const res = await axiosPost(generateVoucherAgentUrl, mapData, headers);
            dispatch({
                type: AGENT_RECEIPT_GENERATE_VOUCHER_ACTION_TYPE,
                payload: res,
            })
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }

export const getEmailData_action = () => async (dispatch) => {

    try {
        const mapdata = {

        }
        console.log("shareEmail_action--------------------------->", mapdata)

        const res = await axiosPost(getEmailDataUrl, mapdata, headers);
        dispatch({
            type: GET_EMAIL_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }

}

export const agent_deleteReceipt_action = (receiptId) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            // AccountID: JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID,
            receiptID: receiptId,
            // token: 'token'
        }
        console.log("Delete Receipt Entry Action---------------->", params)
        const res = await axiosGet(deleteAgentReceiptDetailsUrl, params, headers);
        dispatch({
            type: AGENT_RECEIPT_DEL_ACTION_TYPE,
            payload: res
        })
        return Promise.resolve(res)

    } catch (error) {
        return Promise.reject(error)
    }
}

export const pendingReceiptDetails_action = (ID) => async (dispatch) => {

    try {
        const params = {
            Userid: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            AccountID: JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID,
            AccountPaymentID: ID,
        }
        console.log("pendingReceiptDetails_action-Action--------------------------->", params)

        const res = await axiosGet(getPendingReceiptDetailsUrl, params, headers);
        dispatch({
            type: PENDING_RECEIPT_DETAILS_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }

}


export const getReceiptPdf_action = (receptId) => async (dispatch) => {
    try {
        var list = [];
        const mapdata1 = {
            "ParameterName": "@ReceiptID",
            "ParameterValue": receptId,
            "ParameterDataType": "int"
        }
        list.push(mapdata1);
        const mapData = {
            "report_name": 'ReceiptVoucherAgent.rpt',
            "parameter_details": list
        }
        const res = await axiosPost(fetchAuthReport, mapData);
        dispatch({
            type: GET_RECEIPT_PDF_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const agent_receiptShare_action = (receiptIdArray) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ReceiptShareID: receiptIdArray,
        }
        console.log("pendingReceiptDetails_action-Action--------------------------->", params)

        const res = await axiosPost(agentReceiptShareUrl, params, headers);
        dispatch({
            type: AGENT_RECEIPT_SHARE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};


