import { UPDATE_AREA_MANAGEMENT_ACTION_TYPE, GET_AREA_MANAGEMENT_ACTION_TYPE } from "../../../../utils/constants";


const initialState = [];

function area_management_reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case UPDATE_AREA_MANAGEMENT_ACTION_TYPE:
            return [...state, payload];
        case GET_AREA_MANAGEMENT_ACTION_TYPE:
            return [...state, payload];
        default:
            return state;
    }
}
export default area_management_reducer;