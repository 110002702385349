import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { toastError, toastSuccess } from "../../../../../utils/constants";
import { insertUpdateLiqaureLicense_action, getLiquareLicenseDetails_action } from '../../../../../actions/exhibitor_module_actions/manual_Forms_actions/forms_actions/liquoreLicense_action/liquoreLicense_action'
import Modal from "react-modal";
import { data } from "jquery";
import $ from 'jquery'
// 
class LiquareLicense extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            PowerConnectionList: [],
            Is_Agree: false,
            Is_Agree2: false,
            Remark: '',
            CGST: '',
            SGST: '',
            Is_AgreeErr: '',
            Is_Agree2Err: '',
            DeadlineDate: "",
            Is_Required: true,
            LiquorLicenseID: '',
            currentDate: new Date(),
            contactDetailsList: [],
            StatusID: ''
        }
        // this.getPowerConnectionList = this.getPowerConnectionList.bind(this)

    }

    componentDidMount() {
        this.setState({
            DeadlineDate: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.Deadline != "" ?
                new Date(this.props.location.state.Deadline) : '',
            StatusID: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.StatusID != "" ?
                this.props.location.state.StatusID : '',
        })

        this.getLiquorLicenseDetails()
        this.loadscript()
    }
    loadscript() {
        $(document).ready(function () {
            $(this).scrollTop(0);
        });
    }

    handleCancel() {
        this.props.navigate('/manualForms')
    }

    inputValidate() {
        const { Is_Agree, Is_Agree2 } = this.state

        if (Is_Agree == 0 || Is_Agree == false) {
            this.setState({ Is_AgreeErr: 'kindly agree to request liquor license' })
        }
        if (Is_Agree2 == 0 || Is_Agree2 == false) {
            this.setState({ Is_Agree2Err: 'kindly agree to disclaimer for liquor license permissions kindly agree to request liquor license' })
        }
    }

    getLiquorLicenseDetails() {
        const { Remark, Is_Agree, isQuantity, Is_Required } = this.state
        this.setState({ isLoading: true })
        this.props
            .getLiquareLicenseDetails_action()
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ isLoading: false, contactDetailsList: data.contactDetails, })
                    if (data.data != '') {
                        this.setState({
                            LiquorLicenseID: data.data[0]['LiquorLicenseID'],
                            Is_Agree: data.data[0]['isRequiredForLiquorLicense'],
                            Is_Agree2: data.data[0]['IsAgree'],
                            Remark: data.data[0]['Remark'],
                        })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    addLiquorLicense() {
        const { Remark, Is_Agree, LiquorLicenseID, Is_Agree2 } = this.state
        this.inputValidate()
        this.setState({ isLoading: true })
        if (Is_Agree && Is_Agree2) {
            this.props
                .insertUpdateLiqaureLicense_action(LiquorLicenseID, Is_Agree, Remark, Is_Agree2)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({ isLoading: false })
                        this.handleCancel()
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            toastError("Plase Enter mandatory feilds!")
            this.inputValidate()
            this.setState({ isLoading: false })
        }
    }

    render() {
        const { isLoading, Is_Agree, Remark, Is_AgreeErr, currentDate, contactForLiquorLicense, DeadlineDate, Is_Agree2, Is_Agree2Err, contactDetailsList, StatusID } = this.state

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <ExhibitorHeader />
                <ExhibitorDrawer />
                <div className="main-content app-content mt-0 with-dr-container">
                    <div className="">
                        <div className="row">
                            <ExhibitorTopMenu />
                        </div>
                        <div className=" mt-2">
                            <div className="row">
                                <div class="card rounded-0 pt-1">
                                    <div class="card-header p-3" style={{ paddingleft: "7px !important" }}>
                                        <h3 class="card-title" style={{ fontsize: "15px" }}>Liquor License</h3>
                                    </div>
                                    <div class="card-body p-1 m-2">
                                        <ul style={{ lineHeight: '25px', textAlign: 'left', padding: 0 }}>
                                            <li>1) The cost/rates and terms & conditions of utilizing the individual services from the given service provider is solely discretion of exhibitors.
                                            </li>
                                            <li>2) India ITME Society is only information facilitator for the service provider & In no event shall INDIA ITME SOCIETY be liable for any direct, indirect, punitive, incidental, special consequential issues, whatsoever arising out of taking services with the above given service provider.
                                            </li>
                                        </ul>
                                        <strong><p className="m-0">Contact Details:-</p></strong>
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {contactDetailsList != '' && contactDetailsList.length > 0 ?
                                                contactDetailsList.map((data) => (
                                                    <>
                                                        <div class="table-responsive w-50" style={{ backgroundColor: '#ebebeb', marginBottom: '14px', borderRight: '1px solid lightgray' }}>
                                                            <div style={{ margin: '10px 0 10px 10px', lineHeight: '23px' }}>
                                                                <p className="m-0"><strong>Name:</strong>  {data.Name} </p>
                                                                <p className="m-0"><strong>Phone:</strong>  {data.Phone}</p>
                                                                <p className="m-0"><strong>Fax:</strong>  {data.Fax}</p>
                                                                <p className="m-0"><strong>Mobile No.</strong>  {data.Mobile}</p>
                                                                <p className="m-0"><strong>Email:</strong>  {data.PrimaryEmail}</p>
                                                                <p className="m-0"><strong>Alternate E-mail:</strong> {data.SecondaryEmail}</p>
                                                                <p className="m-0"><strong>Address:</strong> {data.Address}</p>
                                                            </div>
                                                        </div>
                                                    </>
                                                ))
                                                :
                                                <div class="table-responsive w-50" style={{ backgroundColor: '#ebebeb', marginBottom: '14px', borderRight: '1px solid lightgray' }}>
                                                    <div style={{ margin: '10px 0 10px 10px', lineHeight: '23px' }}>
                                                        <p className="m-0">No record found </p>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div class="row m-1">
                                    <form>
                                        <label class="d-flex mt-1" style={{ lineheight: "15px;" }}>
                                            <input type="checkbox"
                                                checked={Is_Agree}
                                                onChange={(e) => this.setState({ Is_Agree: e.target.checked, Is_AgreeErr: '' })}
                                            />
                                            <strong>I want to request liquor license.</strong></label>{<span className='text-danger text-start text-left d-block mt-1'>{Is_AgreeErr}</span>}
                                        <strong> <label>Remark</label> </strong>
                                        <textarea class="form-control"
                                            value={Remark}
                                            onChange={(e) => this.setState({ Remark: e.target.value })}
                                            maxLength={300}
                                            rows="3">
                                        </textarea>
                                        <label class="d-flex mt-1" style={{ lineheight: "15px;" }}>
                                            <input type="checkbox"
                                                checked={Is_Agree2}
                                                onChange={(e) => this.setState({ Is_Agree2: e.target.checked, Is_Agree2Err: '' })}
                                            />
                                            <strong>I agree the above disclaimer for liquor license permissions<span className="required">*</span>.</strong></label>{<span className='text-danger text-start text-left d-block mt-1'>{Is_Agree2Err}</span>}
                                    </form>
                                </div>

                            </div>
                            <div className="row">
                                {currentDate > DeadlineDate &&
                                    <span className="text-danger">THE DEADLINE TO FILL OUT THE FORM HAS PASSED.</span>
                                }
                            </div>
                            <div class='d-flex m-3'>

                                <button
                                    type="button"
                                    className="btn btn-primary rounded-0 d-block mt-3 mb-3"
                                    style={{ marginRight: 10 }}
                                    disabled={currentDate > DeadlineDate }
                                    onClick={() => this.addLiquorLicense()}
                                >
                                    Submit
                                </button>
                                <button className="btn rounded-0 d-block mt-3 mb-3 apper-cancel" onClick={() => this.handleCancel()}>Back</button>

                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(connect(null, { insertUpdateLiqaureLicense_action, getLiquareLicenseDetails_action })(LiquareLicense));