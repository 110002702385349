import { Component } from "react"
import { ToastContainer } from "react-toastify";
import HeaderWithLogo from "../../../common_components/header_with_logo";
import AdminDrawer from "../../../common_components/admin_module_drawer"
import Pagination from "../../../utils/pagination";
import Modal from "react-modal";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import Loader from "../../../utils/Loader";
import {
    getVisitorCircularCompany_action, insertVisitorCircular_action, getVisitorCircularList_action, delVisitorCircular_action,
    assignCircular_action
} from "../../../actions/admin_module_action/visitorCircular_action/visitorCircular_action";
import { toastError, toastSuccess } from "../../../utils/constants";
import Resizer from "react-image-file-resizer";


class VisitorCircularAdd extends Component {
    // Initialized Constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            companyList: [],
            currentPage: 1,
            circularList: [],
            circularDate: "",
            circularTitle: "",
            circularFile: "",
            circularFileType: "",
            checkedAll: false,
            selectedCompany: [],
            isDelOpen: false,
            circularID: "",
            companyName: "",
            loginAccesslist: [],

            // Err Messages
            circularDateErr: "",
            circularTitleErr: "",
            circularFileErr: "",

            isSendMail: false,
        }
        this.getCircularCompany = this.getCircularCompany.bind(this)
        this.updateItem = this.updateItem.bind(this)
        this.getCircularList = this.getCircularList.bind(this)
        this.insertCircular = this.insertCircular.bind(this)
        this.inputValidate = this.inputValidate.bind(this)
        this.onFilePick = this.onFilePick.bind(this)
        this.resizeFile = this.resizeFile.bind(this)
        this.onPdfSelect = this.onPdfSelect.bind(this)
        this.selectAllCompany = this.selectAllCompany.bind(this)
        this.handleCheckedCompany = this.handleCheckedCompany.bind(this)
        this.delCircular = this.delCircular.bind(this)
        this.toggleDelModal = this.toggleDelModal.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.getWirteAccess = this.getWirteAccess.bind(this)
        this.getDeleteAccess = this.getDeleteAccess.bind(this)
        this.checkedCirculars = this.checkedCirculars.bind(this)
        this.assignCircular = this.assignCircular.bind(this)
    }

    // Om Component Load
    componentDidMount() {
        this.setState({ loginAccesslist: JSON.parse(sessionStorage.getItem('loginDataSS'))['loginAccesslist'] })
        this.getCircularCompany()
        this.getCircularList()
    }

    // Get Circular Company 
    getCircularCompany() {
        const { companyName } = this.state
        this.setState({ isLoading: true })
        this.props.getVisitorCircularCompany_action(companyName)
            .then((data) => {
                if (data.error != 1) {
                    data.data.length > 0 && data.data.map((item) => {
                        item.Is_Select = false
                    })
                    console.log("getCircularCompany Data", data.data)
                    this.setState({
                        companyList: data.data,
                        isLoading: false
                    })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })

    }

    // Update Current Page
    updateItem(item) {
        const { companyList } = this.state
        this.setState({ currentPage: item });
        const recordsPerPage = 10;
        const indexOfLastRecord = item * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = companyList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(companyList.length / recordsPerPage)
    }

    // Get Circular List
    getCircularList() {
        this.setState({ isLoading: true })
        this.props.getVisitorCircularList_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log("getCircularList Data", data.data)
                    data.data.length > 0 && data.data.map((item) => {
                        item.isSelect = false
                    })
                    this.setState({
                        circularList: data.data,
                        isLoading: false
                    })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })

    }

    // Insert Circular 
    insertCircular(e) {
        const { circularDate, circularFile, circularTitle, circularDateErr, circularFileErr, circularTitleErr, companyList, isSendMail, circularFileType } = this.state
        this.inputValidate()
        if (circularDate != "" && circularTitle != "" && circularFile != "") {
            if (circularDateErr == "" && circularTitleErr == "" && circularFileErr == "") {
                var selectedCompany = []
                selectedCompany = companyList.length > 0 && companyList.filter((item) => item.Is_Select == true)
                if (selectedCompany.length > 0) {
                    // circular data Preparing to store
                    var list = []
                    selectedCompany.map(data => {
                        var mapData = {
                            'AccountID': parseInt(data.AccountID),
                        }
                        list.push(mapData)
                    });

                    this.setState({ isLoading: true })
                    this.props.insertVisitorCircular_action(circularDate, circularTitle, circularFile, list, circularFileType, isSendMail)
                        .then((data) => {
                            if (data.error != 1) {
                                this.setState({ isLoading: false })
                                toastSuccess("Circular Added Successfully")
                                // this.props.navigate('/addcircular')
                                window.location.reload()
                            } else {
                                this.setState({ isLoading: false })
                                toastError(data.msg)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                } else {
                    toastError("Please Select Atleast One Company")
                }
            } else {
                toastError("Please Fill Mandatory Input Fields")
            }
        } else {
            toastError("Please Fill Mandatory Input Fields")
        }



    }

    // Input Feilds Validation
    inputValidate() {
        const { circularDate, circularTitle, circularFile } = this.state

        if (circularDate == "") {
            this.setState({ circularDateErr: 'Please Select Circular Date' })
        }
        if (circularTitle == "") {
            this.setState({ circularTitleErr: 'Please Enter Circular Title' })
        }
        if (circularFile == "") {
            this.setState({ circularFileErr: 'Please Select Circular File' })
        }
    }

    // On Cicular File Select
    onFilePick = async (e, file) => {
        e.preventDefault()
        console.log('file', file)
        if (file.length != 0) {
            if (file[0]['size'] >= 5 * 1024 * 1024) {
                toastError('File size more than 5mb are not allow.')
            } else {
                var image = '';
                if (file[0]['name'].toString().toLowerCase().includes('.pdf')

                ) {
                    if (file[0]['name'].toString().toLowerCase().trim().includes('.pdf')) {
                        image = await this.onPdfSelect(file[0]);
                        this.setState({ circularFile: image, circularFileErr: '' })
                    }
                    var fileType = file[0]['type']
                    var fileTypeList;
                    if (fileType.includes("/")) {
                        fileTypeList = fileType.split("/")

                    }
                    this.setState({ circularFileType: fileTypeList.length > 0 ? fileTypeList[1] : "" })
                } else {
                    toastError('Only .pdf files are allowed')
                }
            }
        }
    }

    onPdfSelect = (file) =>
        new Promise((resolve) => {
            let base64;
            // FileReader function for read the file.
            let fileReader = new FileReader();
            // Onload of file read the file content
            fileReader.onload = function (fileLoadedEvent) {
                base64 = fileLoadedEvent.target.result;
                // Print data in console
                resolve(base64);
            };
            // Convert data to base64
            fileReader.readAsDataURL(file);
        });

    resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                500,
                600,
                'JPEG',
                80,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64",
                300,
                300,
            );
        });

    //on Select All Click
    selectAllCompany(e) {
        const { checkedAll, companyList } = this.state
        this.setState({ checkedAll: !checkedAll })
        const checked = e.target.checked
        companyList.length > 0 && companyList.map((data) => {
            if (checked) {
                data.Is_Select = true
            } else {
                data.Is_Select = false
            }
        })
        console.log("yyyyyyyyyyyyy", companyList)
        this.setState({ selectedCompany: companyList })
    }

    // On Checked Company
    handleCheckedCompany(e, id) {
        const { companyList, selectedCompany } = this.state
        const checked = e.target.checked

        companyList.length > 0 && companyList.map((data) => {
            if (data.AccountID == id) {
                if (checked) {
                    data.Is_Select = true
                } else {
                    data.Is_Select = false
                    this.setState({ checkedAll: false })
                }
            }
        })
        console.log("aaaaaaaaaaa", companyList)
        this.setState({})
    }

    // On Delete Circular
    delCircular(e) {
        const { circularID } = this.state
        this.setState({ isLoading: true })
        this.props.delVisitorCircular_action(circularID)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({
                        isDelOpen: false,
                        isLoading: false
                    })
                    toastSuccess("Circular Deleted Successfully")
                    this.getCircularList()
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    //toggle for delete
    toggleDelModal(id) {
        const { isDelOpen } = this.state
        this.setState({
            isDelOpen: !isDelOpen, circularID: id
        })
    }

    // On Search Click
    onSearchClick() {
        this.getCircularCompany()
    }

    getWirteAccess() {
        const { loginAccesslist } = this.state
        const moduleAccess = loginAccesslist.find((item) => item.ModuleID == '24');
        if (moduleAccess) {
            return moduleAccess.WriteAccess;
        } else {
            return false
        }
        return false;
    }

    getDeleteAccess() {
        const { loginAccesslist } = this.state
        const moduleAccess = loginAccesslist.find((item) => item.ModuleID == '14');
        if (moduleAccess) {
            return moduleAccess.DeleteAccess;
        } else {
            return false
        }
        return false;
    }

    checkedCirculars(e, id) {
        const { circularList } = this.state
        const checked = e.target.checked

        circularList.length > 0 && circularList.map((data) => {
            if (data.CircularVisitorID == id) {
                if (checked) {
                    data.isSelect = true
                } else {
                    data.isSelect = false
                }
            }
        })
        console.log("aaaaaaaaaaa", circularList)
        this.setState({})
    }

    assignCircular() {
        const { circularList, companyList } = this.state
        var selectedCompany = []
        selectedCompany = companyList.length > 0 && companyList.filter((item) => item.Is_Select == true)
        var selectedCircular = []
        selectedCircular = circularList.length > 0 && circularList.filter((item) => item.isSelect == true)

        if (selectedCircular.length > 0) {
            if (selectedCompany.length > 0) {
                // circular User data Preparing to store
                var list = []
                selectedCompany.map(data => {
                    var mapData = {
                        'AccountID': parseInt(data.AccountID),
                    }
                    list.push(mapData)
                });
                // circular data Preparing to store
                var list2 = []
                selectedCircular.map(data => {
                    var mapData = {
                        'CircularID': parseInt(data.CircularVisitorID),
                    }
                    list2.push(mapData)
                });
                this.setState({ isLoading: true })
                this.props.assignCircular_action(list, list2, 1, this.state.isSendMail)
                    .then((data) => {
                        if (data.error != 1) {
                            this.setState({ isLoading: false })
                            toastSuccess("Circular Assign Successfully")
                        } else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } else {
                toastError("Please Select Atleast One Visitor")
            }
        } else {
            toastError("Please Select Atleast One Circular")
        }
    }

    render() {
        const { isLoading, companyList, currentPage, circularDate, circularTitle, circularDateErr, circularTitleErr, circularFileErr,
            checkedAll, circularList, isDelOpen, companyName
        } = this.state

        const toInputUppercase = e => {
            e.target.value = ("" + e.target.value).toUpperCase();
        };

        const recordsPerPage = 10;
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = companyList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(companyList.length / recordsPerPage)

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                <AdminDrawer />
                <div className="main-content app-content with-dr-container">
                    <div className="container-fluid ">
                        <div className="row">

                        </div>
                        <div className="row">
                            <div className="col-xl-12 p-0">
                                <div className="card rounded-0 pt-1">
                                    <div className="card-header p-3">
                                        <h3 className="card-title">Add Visitor Circular</h3>
                                    </div>
                                    <div className="card-body p-1">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="col-xl-12 p-0">
                                                    <label htmlFor="validationCustom03">Circular Date<span style={{ color: 'red' }}>*</span></label>
                                                    <DatePicker
                                                        selected={circularDate}
                                                        dateFormat={"dd/MM/yyyy"} className="form-control"
                                                        onChange={(date) => this.setState({ circularDate: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())), circularDateErr: "" })}
                                                        peekNextMonth
                                                        minDate={new Date()}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        placeholderText="Circular Date"
                                                        dayClassName={() => "example-datepicker-day-class"}
                                                        popperClassName="example-datepicker-class" />
                                                    {<span className='text-danger text-start text-left d-block mt-1'>{circularDateErr}</span>}
                                                </div>
                                                <div className="col-xl-12 p-0 mt-3">
                                                    <label htmlFor="validationCustom03">Upload Circular<span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" className="form-control"
                                                        value={circularTitle}
                                                        onInput={toInputUppercase}
                                                        onChange={(e) => this.setState({ circularTitle: e.target.value, circularTitleErr: "" })}
                                                    />
                                                    {<span className='text-danger text-start text-left d-block mt-1'>{circularTitleErr}</span>}
                                                </div>
                                                <div className="col-xl-12 p-0 mt-4">
                                                    <input type="file" className="form-control"
                                                        accept=".pdf"
                                                        onChange={e => this.onFilePick(e, e.target.files)}
                                                    />
                                                    {<span className='text-danger text-start text-left d-block mt-1'>{circularFileErr}</span>}
                                                </div>
                                                <button type="button" className="btn btn-primary mt-4"
                                                    disabled={!this.getWirteAccess()}
                                                    onClick={(e) => this.insertCircular()}
                                                >
                                                    Submit Circular
                                                </button>
                                                <div className="row col-sm-5" style={{ float: "right" }}>
                                                    <button type="button" className="btn btn-primary mt-4"
                                                        disabled={!this.getWirteAccess()}
                                                        onClick={(e) => this.assignCircular()}
                                                    >
                                                        Assign Circular
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="col-md-8">
                                                <div className="add-circular">
                                                    {
                                                        circularList.length > 0 && circularList.map((data, index) => (
                                                            <p key={Math.random()}>
                                                                <input type="checkbox"
                                                                    value={data.isSelect}
                                                                    checked={data.isSelect}
                                                                    onChange={(e) => this.checkedCirculars(e, data.CircularVisitorID)}
                                                                />
                                                                {data.CircularDescription}
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-danger del-btn-cir"
                                                                    disabled={!this.getDeleteAccess()}
                                                                    onClick={(e) => this.toggleDelModal(data.CircularVisitorID)}
                                                                >
                                                                    Delete
                                                                </button>
                                                            </p>
                                                        ))
                                                    }

                                                </div>
                                            </div>
                                            <style
                                                type="text/css"
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        "\n                                    .del-btn-cir{\n                                    width: 100px; height: 30px; line-height: 15px;\n                                    }\n                                    .add-circular{\n                                        height: 300px;\n                                        overflow: auto;\n                                    }\n                                "
                                                }}
                                            />
                                            <hr className="mt-4" />

                                            <div className="row">
                                                <div className="row">

                                                    <div className="col-md-2">
                                                        <label htmlFor="validationCustom03">Company Name</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Search Company"
                                                            value={companyName}
                                                            onChange={(e) => this.setState({ companyName: e.target.value })}
                                                        />
                                                    </div>
                                                    <div className="col-md-1">
                                                        <label>&nbsp;</label>
                                                        <button class="btn rounded-0 btn-dark"
                                                            onClick={(e) => this.onSearchClick()}
                                                        >Search</button>
                                                    </div>
                                                    <div className="col-md-2 mt-4" style={{ left: '0' }}>
                                                        <input type="checkbox"
                                                            checked={this.state.isSendMail}
                                                            onChange={(e) => this.setState({ isSendMail: !this.state.isSendMail })}
                                                        />
                                                        Send Mail
                                                    </div>
                                                </div>
                                                <div className="table-responsive mt-4">
                                                    <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 ">
                                                        <thead>
                                                            <tr>
                                                                <th className="text-center" style={{ width: 60 }}>
                                                                    <input type="checkbox"
                                                                        checked={checkedAll}
                                                                        onChange={(e) => this.selectAllCompany(e)}
                                                                    />
                                                                </th>
                                                                <th className="text-center">Reference ID</th>
                                                                <th>Company</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                currentRecords.length > 0 ? currentRecords.map((data) => (
                                                                    <tr key={Math.random()}>
                                                                        <td className="text-center">
                                                                            <input type="checkbox"
                                                                                value={data.Is_Select}
                                                                                checked={data.Is_Select}
                                                                                onChange={(e) => this.handleCheckedCompany(e, data.AccountID)}
                                                                            />
                                                                        </td>
                                                                        <td className="text-center">{data.VisitorRegistrationNo}</td>
                                                                        <td>{data.Institute_Organisation}</td>
                                                                    </tr>
                                                                )) : <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="mt-2 float-left">
                                                    {
                                                        companyList.length > 0 ?
                                                            <Pagination
                                                                nPages={nPages}
                                                                currentPage={currentPage}
                                                                setCurrentPage={this.updateItem}
                                                            /> : <></>
                                                    }
                                                    <div className="tab-rec " style={{ float: "right" }}>
                                                        <p>{`${currentPage} of ${nPages} pages`}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal isOpen={isDelOpen} onRequestClose={() => this.setState({ isDelOpen: false })}>
                    <div className="modal-dialog">
                        <div className="modal-content confirm-logout">
                            <div class="modal-header p-2">
                                <h5 class="modal-title modal_title_text text-danger">Confirm Delete</h5>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={() => this.setState({ isDelOpen: false })} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <p class="pop_label">Do you really want to delete this record?</p>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer p-1">
                                <div class="form-group text-right mod-fot">
                                    <button type="button" class="btn btn-del-pop"
                                        onClick={(e) => this.delCircular(e)}
                                    >Yes</button>
                                    <button type="button" class="btn btn-can-pop" data-dismiss="modal" style={{ marginLeft: '5px' }}
                                        onClick={() => this.setState({ isDelOpen: false })}
                                    >No</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </Modal>

            </>

        )
    }
}
export default withRouter(connect(null, {
    getVisitorCircularCompany_action, insertVisitorCircular_action, getVisitorCircularList_action, delVisitorCircular_action, assignCircular_action
})(VisitorCircularAdd))