import { Component } from "react";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import ExhibitionDrawer from "../../../../../common_components/admin_module_drawer";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import Modal from "react-modal";

class AdditionalFurniture extends Component {

    // Initilized Constructor 
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            imageFilePath: "",
            isOpen: false,
        }
        this.toggleModal = this.toggleModal.bind(this)
    }

    //  Modal Toggle
    toggleModal(filePath) {
        const { isOpen } = this.state
        this.setState({ isOpen: !isOpen, imageFilePath: filePath })
    }

    render() {
        const { isLoading, imageFilePath, isOpen } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <ExhibitorHeader />
                <ExhibitorDrawer />
                <div className="main-content with-dr-container">
                    <div className="">
                        <div className="row">
                            <ExhibitorTopMenu />
                        </div>
                        <div className="row p-2">
                            <div className="col-xl-12 p-1">
                                <div className="card rounded-0 pt-1">
                                    <div className="card-header p-2">
                                        <h3 className="card-title">Additional Furniture</h3>
                                    </div>
                                    <div className="card-body p-2">
                                        <div className="row text-left">
                                            <h6 className="mt-2">
                                                1) The cost/rates and terms &amp; conditions of utilizing the
                                                individual services from the given service provider is solely
                                                discretion of exhibitors.
                                            </h6>
                                            <h6 className="mt-2">
                                                2) Organizer is only information facilitator for the service
                                                provider &amp; in no event shall organizer be liable for any
                                                direct, indirect, punitive, incidental, special consequential
                                                issues, whatsoever arising out of taking services with the above
                                                given service provider.
                                            </h6>
                                            <h5 className="mt-2">
                                                <b>Contact Details:</b>
                                            </h5>
                                            <div>
                                                <ul className="m-0 p-0">
                                                    <li className="mb-1">Mr. Nelson Obura</li>
                                                    <li className="mb-1">Marketing Manager</li>
                                                    <li className="mb-1">Phone: +254 702216339</li>
                                                    <li className="mb-1">
                                                        Email:{" "}
                                                        <a href="craignelton07@gmail.com">
                                                            craignelton07@gmail.com
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <h5 className="mt-4">
                                                <b>Terms &amp; Conditions by Vendor</b>
                                            </h5>
                                            <div>
                                                <ul className="m-0 p-0">
                                                    <li className="mb-1">
                                                        1) All orders are subject to stock availability.
                                                    </li>
                                                    <li className="mb-1">
                                                        2) Rates are for the duration of exhibitions.
                                                    </li>
                                                    <li className="mb-1">
                                                        3) Orders cannot be confirmed until all hire and other charges
                                                        have been paid in full.
                                                    </li>
                                                    <li className="mb-1">
                                                        4) Any major damage to the items is payable by the hirer.
                                                    </li>
                                                    <li className="mb-1">
                                                        5) All items are charged per day and are exclusive of VAT.
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="table-responsive mt-1">
                                                <hr className="mt-2" />
                                                <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 ">
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center">
                                                                <a
                                                                    // href=""
                                                                    // data-bs-toggle="modal"
                                                                    // data-bs-target="#exampleModal"
                                                                    onClick={(e) => this.toggleModal("images/FURNITURE_IMG-1.jpg")}
                                                                >
                                                                    <img src="images/FURNITURE_IMG-1.jpg" width="90px;" />
                                                                </a>
                                                            </td>

                                                            <td className="text-center">
                                                                <a
                                                                    // href=""
                                                                    // data-bs-toggle="modal"
                                                                    // data-bs-target="#exampleModal"
                                                                    onClick={(e) => this.toggleModal("images/FURNITURE_IMG-2.jpg")}
                                                                >
                                                                    <img src="images/FURNITURE_IMG-2.jpg" width="90px;" />
                                                                </a>
                                                            </td>
                                                            <td className="text-center">
                                                                <a
                                                                    // href=""
                                                                    // data-bs-toggle="modal"
                                                                    // data-bs-target="#exampleModal"
                                                                    onClick={(e) => this.toggleModal("images/FURNITURE_IMG-3.jpg")}
                                                                >
                                                                    <img src="images/FURNITURE_IMG-3.jpg" width="90px;" />
                                                                </a>
                                                            </td>
                                                            <td className="text-center">
                                                                <a
                                                                    // href=""
                                                                    // data-bs-toggle="modal"
                                                                    // data-bs-target="#exampleModal"
                                                                    onClick={(e) => this.toggleModal("images/FURNITURE_IMG-4.jpg")}
                                                                >
                                                                    <img src="images/FURNITURE_IMG-4.jpg" width="90px;" />
                                                                </a>
                                                            </td>
                                                            <td className="text-center">
                                                                <a
                                                                    // href=""
                                                                    // data-bs-toggle="modal"
                                                                    // data-bs-target="#exampleModal"
                                                                    onClick={(e) => this.toggleModal("images/FURNITURE_IMG-5.jpg")}
                                                                >
                                                                    <img src="images/FURNITURE_IMG-5.jpg" width="90px;" />
                                                                </a>
                                                            </td>
                                                            <td className="text-center">
                                                                <a
                                                                    // href=""
                                                                    // data-bs-toggle="modal"
                                                                    // data-bs-target="#exampleModal"
                                                                    onClick={(e) => this.toggleModal("images/FURNITURE_IMG-6.jpg")}
                                                                >
                                                                    <img src="images/FURNITURE_IMG-6.jpg" width="90px;" />
                                                                </a>
                                                            </td>
                                                            <td className="text-center">
                                                                <a
                                                                    // href=""
                                                                    // data-bs-toggle="modal"
                                                                    // data-bs-target="#exampleModal"
                                                                    onClick={(e) => this.toggleModal("images/FURNITURE_IMG-7.jpg")}
                                                                >
                                                                    <img src="images/FURNITURE_IMG-7.jpg" width="90px;" />
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div
                                                    className="modal fade"
                                                    id="exampleModal"
                                                    tabIndex={-1}
                                                    aria-labelledby="exampleModalLabel"
                                                    aria-hidden="true"
                                                >
                                                    <div className="modal-dialog">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <button
                                                                    type="button"
                                                                    className="btn-close"
                                                                    data-bs-dismiss="modal"
                                                                    aria-label="Close"
                                                                    style={{ fontSize: 14 }}
                                                                />
                                                            </div>
                                                            <div className="modal-body">
                                                                <img src="images/IMG-2.jpg" className="img-fluid" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={isOpen} onRequestClose={() => this.setState({ isOpen: false })}>
                    <div className="modal-dialog">
                        <div className="modal-content confirm-logout">
                            <div class="modal-header p-2">
                                <h5 class="modal-title modal_title_text text-danger">Additional Furniture</h5>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={() => this.setState({ isOpen: false })} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
                            </div>
                            <div>
                                <img src={imageFilePath}>
                                </img>
                            </div>

                        </div>
                    </div>
                </Modal>
            </>
        )
    }
}
export default withRouter(connect(null, {})(AdditionalFurniture))
