import { Component } from "react";
import { withRouter } from "../../../../utils/withRouter";
import { connect } from "react-redux";
import Loader from "../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import HeaderWithLogo from "../../../../common_components/header_with_logo";
import AdminDrawer from "../../../../common_components/admin_module_drawer";

class ExhibitDetailsApproval extends Component {

    // Initialized Constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false
        }
    }

    render() {
        const { isLoading } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                <AdminDrawer />
                <div className="main-content with-dr-container com-height">
                    <div className="">
                        <div className="row">
                            <div className="step-name">
                                <p>Exhibit Details Approve</p>
                            </div>
                        </div>
                        <div>
                            <div className="row mt-4">
                                <div className="card">
                                    <div
                                        className="card-header fac-header"
                                        style={{ fontWeight: "bold" }}
                                    >
                                        International Registration Exhibitors Summary
                                    </div>
                                    <div className="p-2" style={{ border: "1px solid #ececec" }}>
                                        <h6 className="text-primary">Exhibit Details</h6>
                                        <div className="table-responsive mt-1 ">
                                            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 ">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center">Chapter Selected </th>
                                                        <th className="text-center">Machine Details</th>
                                                        <th>HSN Code</th>
                                                        <th className="text-center" colSpan={3}>
                                                            Machine Dimension(Mtr)
                                                        </th>
                                                        <th className="text-center">
                                                            Floor Capscity(Kgs Per Sq Mtr)
                                                        </th>
                                                        <th className="text-center">Machine Weight (Kg)</th>
                                                        <th>Manufacturer Name </th>
                                                        <th>Manufacturer Country </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td />
                                                        <td />
                                                        <td />
                                                        <td className="text-center no-bor">Length</td>
                                                        <td className="text-center no-bor">Breadth </td>
                                                        <td className="text-center no-bor">Height</td>
                                                        <td />
                                                        <td />
                                                        <td />
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue="1.15.41"
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={0.0}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={0.0}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={0.0}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={0.0}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={0.0}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={0.0}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={0.0}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={0.0}
                                                            />
                                                        </td>
                                                        <td>
                                                            <select className="form-control">
                                                                <option>Select Country</option>
                                                                <option />
                                                                <option />
                                                            </select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue="1.15.41"
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={0.0}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={0.0}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={0.0}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={0.0}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={0.0}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={0.0}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={0.0}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={0.0}
                                                            />
                                                        </td>
                                                        <td>
                                                            <select className="form-control">
                                                                <option>Select Country</option>
                                                                <option />
                                                                <option />
                                                            </select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue="1.15.41"
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={0.0}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={0.0}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={0.0}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={0.0}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={0.0}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={0.0}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={0.0}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={0.0}
                                                            />
                                                        </td>
                                                        <td>
                                                            <select className="form-control">
                                                                <option>Select Country</option>
                                                                <option />
                                                                <option />
                                                            </select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue="1.15.41"
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={0.0}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={0.0}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={0.0}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={0.0}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={0.0}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={0.0}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={0.0}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={0.0}
                                                            />
                                                        </td>
                                                        <td>
                                                            <select className="form-control">
                                                                <option>Select Country</option>
                                                                <option />
                                                                <option />
                                                            </select>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <button
                                                className="btn btn-primary mt-2 mb-2"
                                                style={{ float: "right" }}
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="card">
                                    <div
                                        className="card-header fac-header"
                                        style={{ fontWeight: "bold" }}
                                    >
                                        International Registration Exhibitors Summary
                                    </div>
                                    <div className="p-2" style={{ border: "1px solid #ececec" }}>
                                        <fieldset className="d-flex mt-1">
                                            <label style={{ marginRight: 25 }}>Admin Approved:</label>
                                            <label htmlFor="yes" style={{ marginRight: 25 }}>
                                                {" "}
                                                <input
                                                    type="radio"
                                                    name="eq"
                                                    id="yes"
                                                    defaultValue="yes"
                                                    defaultChecked=""
                                                />{" "}
                                                Yes
                                            </label>
                                            <br />
                                            <label htmlFor="No" style={{ marginRight: 25 }}>
                                                {" "}
                                                <input type="radio" name="eq" id="no" defaultValue="no" /> No
                                            </label>
                                            <br />
                                        </fieldset>
                                        <div className="col-md-8 mt-3 p-0">
                                            <label htmlFor="validationCustom03">Reason to Disapprove</label>
                                            <textarea className="form-control" defaultValue={""} />
                                            <button className="btn btn-primary mt-2 mb-2 mr-2">Save</button>
                                            <button className="btn btn-dark mt-2 mb-2">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <style
                        dangerouslySetInnerHTML={{
                            __html:
                                "\n    .fac-header{\n            background: #ececec;\n    padding: 12px;\n    position: revert;\n    }\n    .fac-header ul{\n        border: 1px solid #ececec !important;\n    }\n    .fac-header-inner{\n        position: absolute;\n        right: 30px;\n    font-weight: normal;\n    }\n    .no-bor{\n            background: #ebebeb !important;\n    font-weight: 500;\n    }\n"
                        }}
                    />


                </div>


            </>
        )
    }
}
export default withRouter(connect(null, {})(ExhibitDetailsApproval))