import { INSERT_AND_UPDATE_CATERING_SERVICES_ACTION_TYPE, GET_CATERING_SERVICES_DETAILS_ACTION_TYPE } from "../../../../../utils/constants";


const initialState = [];

function cateringServicesReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case INSERT_AND_UPDATE_CATERING_SERVICES_ACTION_TYPE:
            return [...state, payload];
        case GET_CATERING_SERVICES_DETAILS_ACTION_TYPE:
            return [...state, payload];

        default:
            return state;
    }
};

export default cateringServicesReducer;