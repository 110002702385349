import { axiosGet } from "../../../react_axios/Axios";
import { GET_VENDOR_EXHIBITION_LIST__ACTION_TYPE, getVendorExhibitionListListUrl, headers } from '../../../utils/constants'



export const getVendorExhibitionList_action = (ExhibitionID, ServiceId) => async (dispatch) => {

    try {
        const mapdata = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExhibitionID !='' ? ExhibitionID: null,
            ServiceId: ServiceId !='' ? ServiceId: null,
        }
        console.log("data----------", mapdata)
        const res = await axiosGet(getVendorExhibitionListListUrl, mapdata, headers);

        dispatch({
            type: GET_VENDOR_EXHIBITION_LIST__ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};