import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { getMeetinRoomMasterList_action, insertUpdateMeetinRoomMaster_action, deleteMeetinRoomMaster_action } from '../../../../../actions/admin_module_action/master_action/manual_form_master_action/meeting_room_master_action'
import moment from "moment";
import { toastError, toastSuccess } from "../../../../../utils/constants";
import Modal from "react-modal";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import Footer from "../../../../../common_components/Footer";
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";



class MeetingRoomMasterList extends Component {

    // Initialize Constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isOpen: false,
            // currentPage: 1,
            meetingRoomMasterList: [],
            seaterList: [],
            deleteID: '',
            deleteType: '',
        }
        this.getMeetingRoomMasterList = this.getMeetingRoomMasterList.bind(this)
        this.deleteMeetingRoomMaster = this.deleteMeetingRoomMaster.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.onDelete = this.onDelete.bind(this)
    }

    componentDidMount() {
        this.getMeetingRoomMasterList()
    }

    getMeetingRoomMasterList(status) {
        this.setState({ isLoading: true })
        this.props.getMeetinRoomMasterList_action()
            .then((data) => {
                if (data.error != 1) {
                    var finalList = []
                    for (var i = 0; i < data.data[0].length; i++) {
                        for (var j = 0; j < data.data[1].length; j++) {
                            if (data.data[0][i].SeaterMeetingRoomID == data.data[1][j].SeaterMeetingRoomID && data.data[0][i].SeaterMeetingRoomID != null && data.data[1][j].SeaterMeetingRoomID != null) {
                                data.data[0][i].NoOfSeats = data.data[1][j].Name
                            }
                        }
                    }
                    finalList = data.data[0].length > 0 ? data.data[0] : []
                    this.setState({ meetingRoomMasterList: finalList })
                    this.setState({ isLoading: false })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    deleteMeetingRoomMaster(id) {
        const { deleteType } = this.state
        console.log(id)
        this.setState({ isLoading: true })
        this.props.deleteMeetinRoomMaster_action(id, null, null, null, null)
            .then((data) => {
                if (data.error != 1) {
                    console.log(data.data)
                    this.setState({ isLoading: false, deleteType: '' })
                    toastSuccess("deleted successfully")
                    setTimeout(() => {
                        this.getMeetingRoomMasterList()
                    }, 200);
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
                this.setState({ isOpen: false })
            })
            .catch((err) => {
                console.log(err)
            })
    }


    // Handle Add 
    handleAdd(status) {
        this.props.navigate('/MeetingRoomMasterAU', { state: { status: status } })
    }

    onDelete(id, index, status) {
        const { meetingRoomMasterList } = this.state
        console.log(status, index)
        this.setState({ deleteID: id, deleteType: status })
        this.setState({ isOpen: true, })
    }

    render() {
        const { isLoading, isOpen, meetingRoomMasterList } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <div className="app-content with-dr-container com-height">
                        <AdminDrawer />
                        <div className="row">
                            <div className="step-name">
                                <p>Meeting Room Master</p>
                            </div>
                        </div>
                        <div className="m-2">
                            <div className="table-responsive">
                                <div
                                    className="card-header p-3"
                                    style={{ paddingLeft: "7px !important" }}
                                >
                                    <h3 className="card-title" style={{ fontSize: 15 }}>
                                        Meeting Room Details
                                    </h3>
                                </div>
                                <div className="table-responsive ">
                                    <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1">
                                        <thead>
                                            <tr>
                                                <th className="text-center" >Meeting Room Date</th>
                                                <th className="text-center" >Seater Name</th>
                                                <th className="text-center" >From Time Slot</th>
                                                <th className="text-center" >To Time Slot</th>
                                                <th className="text-center" >Time Slot Duration</th>
                                                <th className="text-center" >Price Name </th>
                                                <th className="text-center" >Price(INR)</th>
                                                <th className="text-center" >Price(USD)</th>
                                                <th style={{ width: '60px' }} className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                meetingRoomMasterList.length > 0 ? (meetingRoomMasterList.map((data, i) => (
                                                    <tr key={i}>
                                                        <td className="text-center" >{moment(data.MeetingRoomDate).format('DD-MM-YYYY')}</td>
                                                        <td className="text-center" >{data.SeaterName}</td>
                                                        <td className="text-center" >{moment(data.FromTimeSlot).format('HH:mm')}</td>
                                                        <td className="text-center" >{moment(data.ToTimeSlot).format('HH:mm')}</td>
                                                        <td className="text-center" >{data.TimeSlotduration}</td>
                                                        <td className="text-center" >{data.PriceName}</td>
                                                        <td className="text-center" >{data.PriceINR}</td>
                                                        <td className="text-center" >{data.PriceUSD}</td>
                                                        <td className="text-center">
                                                            <button
                                                                type="button"
                                                                className="btn btn-outline-danger btn-sm"
                                                                onClick={(e) => this.onDelete(data.SeaterMeetingRoomID, i, 1)}
                                                            >
                                                                <i className="fa fa-trash action_btn_table" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))) : <tr><td className="text-center" colSpan={20}>No record found</td></tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <button
                                    type="button"
                                    className="btn btn-primary rounded-0 d-block mt-4"
                                    style={{ marginRight: 10, float: "right" }}
                                    onClick={() => this.handleAdd()}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                </>
                <Modal isOpen={isOpen} onRequestClose={() => this.setState({ isOpen: false })}>
                    <div className="modal-dialog" style={{ marginTop: '20%' }}>
                        <div className="modal-content confirm-logout">
                            <div class="modal-header text-right p-2">
                                <h5 class="modal-title modal_title_text text-danger">Do you really want to delete this record?</h5>

                                {/* <button type="button " class="close pop-close" data-dismiss="modal"
                                    onClick={() => this.setState({ isOpen: false })}
                                ><span aria-hidden="true" class="pop-cancel">&times;</span></button> */}
                            </div>
                            {/* <div class="modal-body">
                                <div class="row">
                                </div>
                            </div> */}
                            <div class="modal-footer p-1">
                                <div class="form-group text-right mod-fot">
                                    <button type="button" class="btn btn-del-pop"
                                        onClick={(e) => this.deleteMeetingRoomMaster(this.state.deleteID)}
                                    >Yes</button>
                                    <button type="button" class="btn btn-can-pop" data-dismiss="modal" style={{ marginLeft: '5px' }}
                                        onClick={() => this.setState({ isOpen: false })}
                                    >No</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
                <Footer />
            </>
        )
    }
}

export default withRouter(connect(null, { getMeetinRoomMasterList_action, insertUpdateMeetinRoomMaster_action, deleteMeetinRoomMaster_action })(MeetingRoomMasterList));