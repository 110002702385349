import { Component } from "react";
import { withRouter } from "../../../../utils/withRouter";
import { connect } from "react-redux";
import Loader from "../../../../utils/Loader";
import HeaderWithLogo from "../../../../common_components/header_with_logo";
import AdminDrawer from "../../../../common_components/admin_module_drawer";
import { ToastContainer } from "react-toastify";

class ExhibitDetailsAdmin extends Component {

    //Initialize Constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false
        }
    }
    render() {
        const { isLoading } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                <AdminDrawer />
                <div className="main-content with-dr-container com-height">
                    <div className="">
                        <div className="row">
                            <div className="step-name">
                                <p>Exhibit Details List Admin Panel</p>
                            </div>
                        </div>
                        <div >
                            <div className="row border m-0 mb-4 mt-4">
                                <h4 className="text-center mb-3">
                                    <b>Application Received-688</b>
                                </h4>
                                <div className="table-responsive mt-1 ">
                                    <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 ">
                                        <thead>
                                            <tr>
                                                <th>Particular</th>
                                                <th className="text-center">Received</th>
                                                <th className="text-center">Approved</th>
                                                <th className="text-center">DisApproved</th>
                                                <th className="text-center">Pending</th>
                                                <th className="text-center">Complete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Form Information</td>
                                                <td className="text-center">688</td>
                                                <td className="text-center">688</td>
                                                <td className="text-center">0</td>
                                                <td className="text-primary text-center">0</td>
                                                <td className="text-center">688</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="row border m-0 mb-4 mt-4">
                                <div className="col-md-4">
                                    <label htmlFor="validationCustom03">Login Name</label>
                                    <input type="text" className="form-control" />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="validationCustom03">Company Name</label>
                                    <input type="text" className="form-control" />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="validationCustom03">Hall No</label>
                                    <select className="form-control">
                                        <option>Select</option>
                                        <option />
                                        <option />
                                        <option />
                                    </select>
                                </div>
                                <div className="col-md-9 ">
                                    <fieldset className="d-flex mt-4">
                                        <label style={{ marginRight: 25 }}>Admin Approved:</label>
                                        <label htmlFor="yes" style={{ marginRight: 25 }}>
                                            {" "}
                                            <input
                                                type="radio"
                                                name="eq"
                                                id="yes"
                                                defaultValue="yes"
                                                defaultChecked=""
                                            />{" "}
                                            Yes
                                        </label>
                                        <br />
                                        <label htmlFor="No" style={{ marginRight: 25 }}>
                                            {" "}
                                            <input type="radio" name="eq" id="no" defaultValue="no" /> No
                                        </label>
                                        <br />
                                        <label htmlFor="Pending">
                                            <input
                                                type="radio"
                                                name="eq"
                                                id="pending"
                                                defaultValue="pending"
                                            />{" "}
                                            Pending
                                        </label>
                                        <button
                                            className="btn rounded-0 btn-dark"
                                            style={{ padding: "1px 7px", marginLeft: 40 }}
                                        >
                                            Search
                                        </button>
                                        <button
                                            className="btn rounded-0 btn-dark"
                                            style={{ padding: "1px 7px", marginLeft: 10 }}
                                        >
                                            Reset
                                        </button>
                                        <button
                                            className="btn rounded-0 btn-primary"
                                            style={{ padding: "1px 7px", marginLeft: 10 }}
                                        >
                                            Export to Excel
                                        </button>
                                    </fieldset>
                                </div>
                            </div>
                            <div className="row mb-6">
                                <div className="table-responsive mt-1 ">
                                    <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 ">
                                        <thead>
                                            <tr>
                                                <th className="text-center">Date</th>
                                                <th className="text-center">Login Name</th>
                                                <th>Company</th>
                                                <th className="text-center">Total Area</th>
                                                <th className="text-center">Hall No</th>
                                                <th className="text-center">Stall Type</th>
                                                <th className="text-center">Scheme</th>
                                                <th className="text-center">Update</th>
                                                <th className="text-center">Admin Approved</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="text-center">26/11/2022</td>
                                                <td className="text-center">EXH201104</td>
                                                <td>Techno Electronic Iindustries</td>
                                                <td className="text-center">18</td>
                                                <td className="text-center">H12</td>
                                                <td className="text-center">H12B17</td>
                                                <td className="text-center">Shell Scheme</td>
                                                <td className="text-primary text-center">
                                                    <button
                                                        className="btn btn-primary"
                                                        style={{ padding: "0px 7px" }}
                                                    >
                                                        Update
                                                    </button>
                                                </td>
                                                <td className="text-center">Y</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">26/11/2022</td>
                                                <td className="text-center">EXH201104</td>
                                                <td>Techno Electronic Iindustries</td>
                                                <td className="text-center">18</td>
                                                <td className="text-center">H12</td>
                                                <td className="text-center">H12B17</td>
                                                <td className="text-center">Shell Scheme</td>
                                                <td className="text-primary text-center">
                                                    <button
                                                        className="btn btn-primary"
                                                        style={{ padding: "0px 7px" }}
                                                    >
                                                        Update
                                                    </button>
                                                </td>
                                                <td className="text-center">Y</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">26/11/2022</td>
                                                <td className="text-center">EXH201104</td>
                                                <td>Techno Electronic Iindustries</td>
                                                <td className="text-center">18</td>
                                                <td className="text-center">H12</td>
                                                <td className="text-center">H12B17</td>
                                                <td className="text-center">Shell Scheme</td>
                                                <td className="text-primary text-center">
                                                    <button
                                                        className="btn btn-primary"
                                                        style={{ padding: "0px 7px" }}
                                                    >
                                                        Update
                                                    </button>
                                                </td>
                                                <td className="text-center">Y</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </>
        )
    }
}
export default withRouter(connect(null, {})(ExhibitDetailsAdmin))