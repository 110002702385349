import { Component } from "react";
import Loader from "../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action } from '../../../../actions/fillCombo_action';
import { cityActionId, stallEventActionId, stallListActionId, countryActionId, exhibitionActionId, toastError, toastSuccess } from "../../../../utils/constants";
import { emailValidator } from "../../../../utils/Validator";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import Pagination from "../../../../utils/pagination";
import AdminDrawer from "../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../common_components/header_with_logo";
import { useNavigate } from "react-router-dom";
import ExhibitorDrawer from "../../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../../common_components/exhibitor_module_header";
import { data } from "jquery";
import { getInvitationEvent_action, deleteInvitationEvent_action, insertInvitationEvent_action, updateInvitationEvent_action } from "../../../../actions/admin_module_action/delegate_master_action/delegate_InvitationEvent_action";
import Footer from "../../../../common_components/Footer";


class Delegate_invitationEventList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isOpen: false,
            currentPage: 1,
            countryList: [],
            InvitationEventList: [],
            deleteID: '',
            InvitationEventName: '',
            Status: '',
            isOpen2: false,
            is_add: false,
            InvitationEventID: '',
            modalInvitationEventName: '',
            diplayStatus: 1,
            exhibitionID: '',
            exhibitionList: []
        }
    }

    componentDidMount() {
        this.getFillList(exhibitionActionId, '', '', '', '', '').then(() => {
            this.getInvitationEventList()
        })
    }

    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        const { } = this.state
        return new Promise((resolve, reject) => {
            this.setState({ isLoading: true })
            this.props
                .fill_action(actionID, exhibitionID, moduleID, keyValue, value)
                .then((data) => {
                    if (data.error != 1) {
                        // country list
                        if (actionID === exhibitionActionId) {
                            this.setState({
                                exhibitionList: data.data.length > 0 ? data.data : [],
                                isLoading: false
                            })
                        }
                        resolve(true)
                    }
                    this.setState({ isLoading: false })
                })
                .catch((e) => {
                    console.log(e);
                });
        })
    }

    record(list) {
        return (
            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
                <thead>
                    <tr>
                        <th style={{ width: '50px' }}>SR no.</th>
                        <th  >Invitation Event Name</th>
                        <th  >Exhibition Name</th>
                        <th style={{ width: '20px' }} className="text-center" colSpan={2}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.length > 0 ? <>{
                            list.map((data, i) => (
                                <tr key={Math.random()}>
                                    <td className="text-center">{i + 1}</td>
                                    <td >{data.InvitationEventName}</td>
                                    <td >{data.ExhibitionName}</td>
                                    {(this.state.diplayStatus == 1 || this.state.diplayStatus == '') ?
                                        <>
                                            <td className="text-center" style={{ width: '20px' }}>
                                                <button
                                                    Event="button"
                                                    className="btn btn-outline-primary btn-sm"
                                                    onClick={(e) => this.handleUpdate(data.InvitationEventID, 'isUpdate')}
                                                >
                                                    Update
                                                </button>
                                            </td>
                                            <td className="text-center" style={{ width: '20px' }}>
                                                <button
                                                    Event="button"
                                                    className="btn btn-outline-danger btn-sm"
                                                    onClick={(e) => this.onDelete(data.InvitationEventID)}
                                                >
                                                    Delete
                                                </button>
                                            </td>
                                        </> :
                                        <td className="text-center" style={{ width: '20px' }}>
                                            <button
                                                Event="button"
                                                className="btn btn-outline-primary btn-sm"
                                                onClick={(e) => this.handleUpdate(data.InvitationEventID, 'isView')}
                                            >
                                                View
                                            </button>
                                        </td>
                                    }
                                </tr>
                            ))}
                        </> :
                            <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                    }
                </tbody>
            </table>)
    }

    getInvitationEventList() {
        this.setState({ isLoading: true })
        const { InvitationEventName, Status,exhibitionID } = this.state
        this.props.getInvitationEvent_action(
            exhibitionID,
            null,
            InvitationEventName != '' ? InvitationEventName : null,
            Status != '' ? Status : 1,
        )
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ InvitationEventList: data.data, diplayStatus: Status != '' ? Status : 1 })
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    clearSearch() {
        const { Stateno, PANNo } = this.state
        this.setState({
            InvitationEventName: '',
            Status: ''
        })
        setTimeout(() => {
            this.getInvitationEventList()
        }, 100);
    }

    handleAdd() {
        this.props.navigate('/delegateInvitationEventAU', { state: { is_add: true, } })
    }

    handleUpdate(id, value) {
        if (value == 'isUpdate') {
            this.props.navigate('/delegateInvitationEventAU', { state: { is_update: true, is_view: false, InvitationEventID: id } })
        } else {
            this.props.navigate('/delegateInvitationEventAU', { state: { is_update: true, is_view: true, InvitationEventID: id } })
        }
    }

    deleteDelegateInvitationEvent(id) {
        this.setState({ isLoading: true })
        this.props.deleteInvitationEvent_action(id)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ isLoading: false })
                    toastSuccess("Deleted successfully")
                    setTimeout(() => {
                        this.getInvitationEventList()
                    }, 200);
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
                this.setState({ isOpen: false })
            })
            .catch((err) => {
                console.log(err)
            })
    }

    onDelete(id) {
        const { } = this.state
        this.setState({ deleteID: id })
        this.setState({ isOpen: true, })
    }

    updateItem(item) {
        const { InvitationEventList } = this.state
        this.setState({ currentPage: item });
        const recordsPerPage = 10;
        const indexOfLastRecord = item * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = InvitationEventList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(InvitationEventList.length / recordsPerPage)
        this.setState({ startIndex: recordsPerPage * (item - 1) });
    }

    render() {
        const { isOpen, isLoading, currentPage, InvitationEventList, InvitationEventName, Status, isOpen2, is_add, modalInvitationEventName } = this.state
        const recordsPerPage = 10;
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = InvitationEventList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(InvitationEventList.length / recordsPerPage)
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />

                <>
                    <div className="app-content with-dr-container com-height">
                        <AdminDrawer />
                        <div className="row">
                            <div className="step-name">
                                <p>Delegate Invitation Event</p>
                            </div>
                        </div>
                        <div className="m-3">
                            <div className="">
                                <div className="row ">
                                    <div className="col-md-3">
                                        <label>Invitation Event Name</label>
                                        <input
                                            Event="text"
                                            className="form-control"
                                            value={InvitationEventName}
                                            onChange={(e) => this.setState({ InvitationEventName: e.target.value, })}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <label >Exhibition</label>
                                        <select className="form-control"
                                            value={this.state.exhibitionID}
                                            onChange={(e) => this.setState({ exhibitionID: e.target.value })}
                                        >
                                            <option value={''}>Select Exhibition</option>
                                            {
                                                this.state.exhibitionList.length > 0 && this.state.exhibitionList.map((data) => (
                                                    <option key={data.ExhibitionID} value={data.ExhibitionID}>{data.ExhibitionName}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-3">
                                        <label>Status</label>
                                        <select className="form-control"
                                            value={Status}
                                            onChange={(e) => this.setState({ Status: e.target.value })}  >
                                            <option value={''}>Select</option>
                                            <option value={1}>Active</option>
                                            <option value={0}>Inactive</option>
                                        </select>
                                    </div>
                                    <div className="col-md-3" style={{ textAlign: 'left' }}>
                                        <label>&emsp;</label>
                                        <button Event="button" className="btn ad-fil-btn"
                                            onClick={e => this.getInvitationEventList()}
                                        >
                                            Search
                                        </button>
                                        <button Event="button" className="btn ad-fil-btn"
                                            onClick={e => this.clearSearch()}
                                        >
                                            Clear
                                        </button>
                                    </div>
                                </div>
                            </div>
                            < div className="row mt-2 mb-1" >
                                <div className="col-md-12 mt-3 mb-1">
                                    <div
                                        className="btn-group mb-1"
                                        role="group"
                                        aria-label="Basic example"
                                        style={{ float: "right" }}
                                    >
                                        <button Event="button" className="btn ad-fil-btn"
                                            onClick={e => this.handleAdd()}
                                        >
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 pl-0">
                                <div className="table-responsive">
                                    {this.record(currentRecords)}
                                </div>
                                <div className="row">
                                    <div className="col-md-9 pl-0">
                                        <div className="mt-2 float">
                                            {
                                                InvitationEventList.length > 0 ?
                                                    <Pagination
                                                        nPages={nPages}
                                                        currentPage={currentPage}
                                                        setCurrentPage={this.updateItem}
                                                    /> : <></>
                                            }

                                        </div>
                                    </div>
                                    <div className="col-md-3 text-right">
                                        <div className="tab-rec mt-3" style={{ float: "right" }}>
                                            <p>{`${currentPage} of ${nPages} pages`}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </>
                <Modal isOpen={isOpen} onRequestClose={() => this.setState({ isOpen: false })}>
                    <div className="modal-dialog" style={{ marginTop: '10%' }}>
                        <div className="modal-content confirm-logout">
                            <div class="modal-header text-right p-2">
                                <h5 class="modal-title modal_title_text text-danger">Do you really want to delete this record?</h5>

                                {/* <button Event="button " class="close pop-close" data-dismiss="modal"
                                    onClick={() => this.setState({ isOpen: false })}
                                ><span aria-hidden="true" class="pop-cancel">&times;</span></button> */}
                            </div>
                            {/* <div class="modal-body">
                                <div class="row">
                                </div>
                            </div> */}
                            <div class="modal-footer p-1">
                                <div class="form-group text-right mod-fot">
                                    <button Event="button" class="btn btn-del-pop"
                                        onClick={(e) => this.deleteDelegateInvitationEvent(this.state.deleteID)}
                                    >Yes</button>
                                    <button Event="button" class="btn btn-can-pop" data-dismiss="modal" style={{ marginLeft: '5px' }}
                                        onClick={() => this.setState({ isOpen: false })}
                                    >No</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
                <Footer />
            </>)
    }

}
export default withRouter(connect(null, { fill_action, getInvitationEvent_action, deleteInvitationEvent_action, insertInvitationEvent_action, updateInvitationEvent_action })(Delegate_invitationEventList));