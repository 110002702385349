import { axiosGet } from "../../../react_axios/Axios"
import { headers, getExhTaxInvoiceListUrl, GET_EXH_TAX_INVOICE_LIST_ACTION_TYPE } from "../../../utils/constants"



export const getExhibitionTimeline_action = () => async (dispatch) => {

    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhRegistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
        }

        const res = await axiosGet(getExhTaxInvoiceListUrl, params, headers)

        dispatch({
            type: GET_EXH_TAX_INVOICE_LIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}