import { axiosGet, axiosPost } from "../../../../react_axios/Axios";
import { headers, GET_APPLICATION_HISTORY_LIST_ACTION_TYPE, getApplicationHistoryListUrl } from "../../../../utils/constants";


export const getApplicationHistoryList_action = (ExhRegistrationID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem("sessionExhibitionID"),
            ExhRegistrationID: ExhRegistrationID != '' ? parseInt(ExhRegistrationID) : null,
        }

        const res = await axiosGet(getApplicationHistoryListUrl, params, headers)

        dispatch({
            type: GET_APPLICATION_HISTORY_LIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}
