import { axiosGet, axiosPost } from "../../../../../react_axios/Axios";
import { INSERT_AND_UPDATE_LIQUOR_LICENSE_ACTION_TYPE, GET_LIQUOR_LICENSE_DETAILS_ACTION_TYPE, headers, getLiquorLicenseDetailsUrl, insertUpdateLiquorLicenseUrl } from "../../../../../utils/constants";



export const insertUpdateLiqaureLicense_action = (LiquorLicenseID, isRequiredForLiquorLicense, Remark, IsAgree) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhRegistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
            LiquorLicenseID: LiquorLicenseID != '' ? LiquorLicenseID : null,
            isRequiredForLiquorLicense: isRequiredForLiquorLicense,
            Remark: Remark,
            IsAgree: IsAgree,
        }
        const res = await axiosPost(insertUpdateLiquorLicenseUrl, params, headers)

        dispatch({
            type: INSERT_AND_UPDATE_LIQUOR_LICENSE_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getLiquareLicenseDetails_action = () => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhRegistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
        }
        const res = await axiosGet(getLiquorLicenseDetailsUrl, params, headers)

        dispatch({
            type: GET_LIQUOR_LICENSE_DETAILS_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}