import { DEL_VISA_DOCUMENT_ACTION_TYPE, GET_VISITOR_PREVIEW_DETAILS_ACTION_TYPE, GET_VISITOR_REGISTRATION_DETAILS2_ACTION_TYPE, GET_VISITOR_REGISTRATION_DETAILS_ACTION_TYPE, PAYMENT_ACTION_TYPE, VERIFY_VISITOR_EMAIL_ACTION_TYPE, VISITOR_REGISTRATION_NEXT_ACTION_TYPE, VISITOR_REGISTRATION_SUBMIT_ACTION_TYPE } from "../../../utils/constants";


const initialState = [];

function visitorRegistrationReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {

        case GET_VISITOR_REGISTRATION_DETAILS_ACTION_TYPE:
            return [...state, payload];

        case VISITOR_REGISTRATION_NEXT_ACTION_TYPE:
            return [...state, payload];

        case VISITOR_REGISTRATION_SUBMIT_ACTION_TYPE:
            return [...state, payload];

        case VERIFY_VISITOR_EMAIL_ACTION_TYPE:
            return [...state, payload];

        case PAYMENT_ACTION_TYPE:
            return [...state, payload];

        case GET_VISITOR_PREVIEW_DETAILS_ACTION_TYPE:
            return [...state, payload];

        case GET_VISITOR_REGISTRATION_DETAILS2_ACTION_TYPE:
            return [...state, payload];

        case DEL_VISA_DOCUMENT_ACTION_TYPE:
            return [...state, payload];

        default:
            return state;
    }
};

export default visitorRegistrationReducer;