import { axiosGet } from "../../../react_axios/Axios";
import { GET_AGENT_DASHBOARD_ACTION_TYPE, GET_EXHIBITOR_DETAIL_ACTION_TYPE, getAgentDashboardUrl, getExhibitorDetailUrl, headers } from "../../../utils/constants";

export const getAgentDashboard_action = () => async (dispatch) => {

    try {
        const params = {
            UserID: sessionStorage.getItem('IsAgentDirectLogin') == "true" ? JSON.parse(sessionStorage.getItem('loginDataSS')).UserID :
                JSON.parse(sessionStorage.getItem('indirectAgentloginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('IsAgentDirectLogin') == "true" ? sessionStorage.getItem('sessionExhibitionID') :
                sessionStorage.getItem('indirectAgentsessionExhibitionID'),
            AccountID: sessionStorage.getItem('IsAgentDirectLogin') == "true" ? JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID :
                JSON.parse(sessionStorage.getItem('indirectAgentloginDataSS')).AccountID,
        }
        console.log("getAgentDashboard_action Action------->", params)

        const res = await axiosGet(getAgentDashboardUrl, params, headers)

        dispatch({
            type: GET_AGENT_DASHBOARD_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getExhibitorDetail_action = (id) => async (dispatch) => {

    try {
        const params = {
            ExhRegistrationID: id,
            exhibitionID: sessionStorage.getItem('IsAgentDirectLogin') == "true" ? sessionStorage.getItem('sessionExhibitionID') :
                sessionStorage.getItem('indirectAgentsessionExhibitionID'),

        }
        console.log("getExhibitorDetail_action Action------->", params)

        const res = await axiosGet(getExhibitorDetailUrl, params, headers)

        dispatch({
            type: GET_EXHIBITOR_DETAIL_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}