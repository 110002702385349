import { Component } from "react";
import { ToastContainer } from "react-toastify";
import Loader from "../../../utils/Loader";
import ExhibitorHeader from "../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../common_components/exhibitor_module_drawer";
import ExhibitorTopMenu from "../../../common_components/exhibitor_module_topmenu";
import { getOldPaymentList_action } from "../../../actions/exhibitor_module_actions/accounts_action/update_payment_action";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import { toastError, toastSuccess } from "../../../utils/constants"
import moment from "moment";



class UpdatePayment extends Component {

    // Initialize Constructor
    constructor(props) {
        super(props)
        //States
        this.state = {
            isLoading: false,
            oldPaymentList: [],

        }
        this.getOldPaymentList = this.getOldPaymentList.bind(this);
        this.onDetailClick = this.onDetailClick.bind(this)
        this.onNewPaymentClick = this.onNewPaymentClick.bind(this)
    }

    componentDidMount() {
        this.getOldPaymentList()
    }

    //get Old Payment List
    getOldPaymentList() {
        this.setState({ isLoading: true })
        this.props.getOldPaymentList_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log("Get getOldPaymentList", data.data)
                    this.setState({ oldPaymentList: data.data, isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            }).catch((err) => {
                console.log(err)
            })
    }

    // On Payment Detail Click
    onDetailClick(id) {
        this.props.navigate('/updatePaymentAdd', { state: { is_update: true, AccountPaymentID: id } })
    }

    // On New Payment Click
    onNewPaymentClick() {
        this.props.navigate('/updatePaymentAdd', { state: { is_add: true } })
    }

    render() {
        const { isLoading, oldPaymentList } = this.state

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <ExhibitorHeader />
                <ExhibitorDrawer />
                {/*app-content open*/}
                <div className="page">
                    <div className="page-main">
                        <div className="main-content with-dr-container">
                            <div className="row">
                                {/* <div className="step-name">
                                <p>Exhibitor Dashboard</p>
                            </div> */}
                                <ExhibitorTopMenu />
                            </div>
                            <div className="card-header mt-0 p-3 mb-2" >
                                <h3 className="card-title" style={{ fontSize: 15 }}>
                                    View / Update Old Payments
                                </h3>
                            </div>
                            <div className="container-fluid">

                                <div className="row">
                                    <div className="col-xl-12 p-0">
                                        <div className="card rounded-0 pt-1">

                                            <div className="card-body p-0">
                                                <button
                                                    className="btn btn-dark p-1 mb-2"
                                                    type="btn"
                                                    style={{ float: "right" }}
                                                    onClick={(e) => this.onNewPaymentClick()}
                                                >
                                                    New payment
                                                </button>
                                                <div className="table-responsive ">
                                                    <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th>Payment Date</th>
                                                                <th>Gross Amount (INR)</th>
                                                                <th>TDS Amount ( INR)</th>
                                                                <th>Net Amount(INR)</th>
                                                                <th className="text-center" style={{ width: 90 }}>Details</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {oldPaymentList.length > 0 ? oldPaymentList.map(data => (
                                                                <tr>
                                                                    <td>{moment(data.PaymentDate).utc().format('DD-MM-YYYY')}</td>
                                                                    <td>{data.GrossAmount}</td>
                                                                    <td>{data.TDSAmount}</td>
                                                                    <td>{data.NetAmount}</td>
                                                                    <td className="p-1 text-center">
                                                                        <button type="button" className="btn btn-details"
                                                                            onClick={(e) => this.onDetailClick(data.AccountPaymentID)}>
                                                                            Detail
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            )) :
                                                                <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </>

        )
    }
}

export default withRouter(connect(null, { getOldPaymentList_action })(UpdatePayment));

