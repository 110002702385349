import { GET_PROFORMA_INV_LIST_ACTION, GET_PROFORMA_PDF_ACTION_TYPE } from "../../../utils/constants";


const initialState = [];

function proformaListReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case GET_PROFORMA_PDF_ACTION_TYPE:
            return [...state, payload];
        case GET_PROFORMA_INV_LIST_ACTION:
            return [...state, payload];
        default:
            return state;
    }
};

export default proformaListReducer