import { GET_ADMIN_USER_DETAILS, GET_ADMIN_USER_LIST, ADD_ADMIN_USER, UPDATE_ADMIN_USER, DELETE_ADMIN_USER } from "../../../utils/constants";


const initialState = [];

function adminUserReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_ADMIN_USER_DETAILS:
            return [...state, payload];
        case GET_ADMIN_USER_LIST:
            return [...state, payload];
        case ADD_ADMIN_USER:
            return [...state, payload];
        case UPDATE_ADMIN_USER:
            return [...state, payload];
        case DELETE_ADMIN_USER:
            return [...state, payload];
        default:
            return state;
    }
}
export default adminUserReducer;