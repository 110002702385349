import { INSERT_UPDATE_EXH_SEMINAR, GET_EXH_SEMINAR_DETAILS, DELETE_EXH_SEMINAR } from "../../../utils/constants";

const initialState = [];

function exhSeminarReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case INSERT_UPDATE_EXH_SEMINAR:
            return [...state, payload];
        case GET_EXH_SEMINAR_DETAILS:
            return [...state, payload];
        case DELETE_EXH_SEMINAR:
            return [...state, payload];

        default:
            return state;
    }
};

export default exhSeminarReducer