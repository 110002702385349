import { axiosGet, axiosPost } from "../../../../react_axios/Axios";
import { headers, getInternetConnectionMasterListUrl, insertUpdateInternetConnectionMasterUrl, deleteInternetConnectionMasterUrl, GET_INTERNET_CONNECTION_MASTER_LIST, INSERT_UPDATE_INTERNET_CONNECTION_MASTER, DELETE_INTERNET_CONNECTION_MASTER } from "../../../../utils/constants";


export const insertUpdateInternetConnectionMaster_action = (ICList) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ICList: ICList
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosPost(insertUpdateInternetConnectionMasterUrl, params, headers)

        dispatch({
            type: INSERT_UPDATE_INTERNET_CONNECTION_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getInternetConnectionMasterList_action = () => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getInternetConnectionMasterListUrl, params, headers)

        dispatch({
            type: GET_INTERNET_CONNECTION_MASTER_LIST,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const deleteInternetConnectionMaster_action = (deleteId) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            InternetConnectionContactPersonID: deleteId
        }
        console.log("deleteExhibitionDocument_action Action------->", params)

        const res = await axiosGet(deleteInternetConnectionMasterUrl, params, headers)

        dispatch({
            type: DELETE_INTERNET_CONNECTION_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}