import { axiosGet } from "../../../react_axios/Axios";
import { getAccountTallyListUrl, GET_ACCOUNT_TALLY_DATA, headers } from "../../../utils/constants";



export const getAcccountTallyList_action = (ExhibitionID, InvoiceTypeID, FromDate, Todate) => async (dispatch) => {

    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExhibitionID,
            InvoiceTypeID: InvoiceTypeID,
            FromDate: FromDate,
            Todate: Todate
        }
        console.log("getPaymentList_action Action------->", params)

        const res = await axiosGet(getAccountTallyListUrl, params, headers)

        dispatch({
            type: GET_ACCOUNT_TALLY_DATA,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}