import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import $ from 'jquery';
import Modal from "react-modal";
import { withRouter } from '../utils/withRouter';


const Drawer = ({ head, userId }) => {
  const history = useNavigate();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false)
  const [loginAccesslist, setLoginAccesslist] = useState([])

  //toggle model for logout.................................
  const toggleModal = () => {
    setIsOpen(!isOpen)
  }

  const loadScripts = () => {
    // This array contains all the files/CDNs 
    const dynamicScripts = [
      'js/jquery.sticky.js',
      'js/metisMenu.min.js',
      'js/metisMenu-active.js',
      'js/main.js'
    ];

    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }
  const logout = () => {
    sessionStorage.clear()
    navigate('/')
  }
  useEffect(() => {
    loadScripts();
    console.log("233",JSON.parse(sessionStorage.getItem('LoginAccesslist'))[0]['IsRead'])
    setLoginAccesslist(JSON.parse(sessionStorage.getItem('LoginAccesslist')))
  }, []);

  const onRedirect = (path) => {
    history.push({ pathname: path, state: { 'isSearchClear': true } });
  }

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={e => toggleModal()}>
        <div className="modal-dialog custom_modal_dialog" >
          <div className="modal-content" style={{ borderRadius: '0px' }}>
            <div class="modal-header">
              <h4 class="modal-title modal_title_text">Confirm Logout</h4>
              {/* <button type="button" class="close modal-close" data-dismiss="modal" onClick={this.toggleModal}>&times;</button> */}
              <button type="button " class="close pop-close text-dark" data-dismiss="modal" onClick={e => toggleModal()} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12">
                  <h3 class="pop_label">Do you really want to logout?</h3>
                </div>
              </div>
            </div>

            <div class="row ">
              <div class="col-md-12">
                <div class="form-group text-right mod-fot">
                  <a class="btn save_btn del-pop-save" onClick={logout}> Yes</a>
                  <a class="btn cancel_btn del-pop-cancel" data-dismiss="modal"
                    style={{ marginLeft: '5px' }} onClick={toggleModal}> No</a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </Modal>
      {/* Left side menu */}
      <nav className="sidebar lft-sidebar sidebar-offcanvas" id="sidebar3">
        <div className="lft-scroll">
          <ul className="nav navs-top">
            {/* <li className="nav-item"><Link to='/dashboard'>
              <a className="nav-link ">
                <i
                  className="fa fa-tachometer"
                  style={{ marginRight: '8px', fontSize: '17px' }}
                />
                <span className="menu-title"> Dashboard</span>
              </a></Link>
            </li> */}
            { JSON.parse(sessionStorage.getItem('LoginAccesslist'))[0]['IsRead'] &&
            JSON.parse(sessionStorage.getItem('LoginAccesslist'))[0]['ModuleID'] == 1  &&
              <li className="nav-item"><Link to='/registration_desk'>
                <a className="nav-link ">
                  <i
                    className="fa fa-edit"
                    style={{ marginRight: '8px', fontSize: '17px' }}
                  />
                  <span className="menu-title">Registration Desk</span>
                </a></Link>
              </li>
            }
            { JSON.parse(sessionStorage.getItem('LoginAccesslist'))[1]['IsRead'] &&
            JSON.parse(sessionStorage.getItem('LoginAccesslist'))[1]['ModuleID'] == 2  &&
            <li className="nav-item"> <Link to='/physicalExamination'>
              <a className="nav-link ">
                <i
                  className="fa fa-exclamation-triangle"

                  style={{ marginRight: '8px', fontSize: '17px' }}
                />
                <span className="menu-title"> Physical Examination</span>
              </a></Link>
            </li>
            }
            { JSON.parse(sessionStorage.getItem('LoginAccesslist'))[4]['IsRead'] &&
            JSON.parse(sessionStorage.getItem('LoginAccesslist'))[4]['ModuleID'] == 5  &&
            <li className="nav-item"><Link to='/distribution'>
              <a className="nav-link ">
                <i
                  className="fa fa-share-alt"
                  style={{ marginRight: '8px', fontSize: '17px' }}
                />
                <span className="menu-title">Distribution</span>
              </a></Link>
            </li>
            }
            { JSON.parse(sessionStorage.getItem('LoginAccesslist'))[2]['IsRead'] &&
            JSON.parse(sessionStorage.getItem('LoginAccesslist'))[2]['ModuleID'] == 3  &&
            <li className="nav-item"><Link to='/application'>
              <a className="nav-link ">
                <i
                  className=" fa fa-id-card-o"
                  style={{ marginRight: '8px', fontSize: '17px' }}
                />
                <span className="menu-title"> Application</span>
              </a></Link>
            </li>
            }
            { JSON.parse(sessionStorage.getItem('LoginAccesslist'))[3]['IsRead'] &&
            JSON.parse(sessionStorage.getItem('LoginAccesslist'))[3]['ModuleID'] == 4  &&
            <li className="nav-item"><Link to='/request'>
              <a className="nav-link ">
                <i
                  className="fa fa-paper-plane"
                  href="request_list.html"
                  style={{ marginRight: '8px', fontSize: '17px' }}
                />
                <span className="menu-title"> Request</span>
              </a></Link>
            </li>
            }
           
            { JSON.parse(sessionStorage.getItem('LoginAccesslist'))[5]['IsRead'] &&
            JSON.parse(sessionStorage.getItem('LoginAccesslist'))[5]['ModuleID'] == 6  &&
            <li className="nav-item"><Link to='/camp'>
              <a className="nav-link ">
                <i className="fa fa-bed"
                  style={{ marginRight: '8px', fontSize: '17px' }}
                />
                <span className="menu-title"> Camp</span>
              </a></Link>
            </li>
            }
            { JSON.parse(sessionStorage.getItem('LoginAccesslist'))[6]['IsRead'] &&
            JSON.parse(sessionStorage.getItem('LoginAccesslist'))[6]['ModuleID'] == 7  &&
            <li className="nav-item"> <Link to='/user'>
              <a className="nav-link ">
                <i
                  className=" fa fa-user-o"
                  style={{ marginRight: '8px', fontSize: '17px' }}
                />
                <span className="menu-title"> User</span>
              </a></Link>
            </li>
            }
            { JSON.parse(sessionStorage.getItem('LoginAccesslist'))[7]['IsRead'] &&
            JSON.parse(sessionStorage.getItem('LoginAccesslist'))[7]['ModuleID'] == 8  &&
            <li className="nav-item"><Link to='/role'>
              <a className="nav-link ">
                <i
                  className="fa fa-user-circle-o"
                  style={{ marginRight: '8px', fontSize: '17px' }}
                />
                <span className="menu-title"> Role</span>
              </a></Link>
            </li>
            }
            { JSON.parse(sessionStorage.getItem('ReportAccesslist'))[0]['IsAllow']  &&
            <li className="nav-item">
              <a
                className="nav-link collapsed"
                data-bs-toggle="collapse"
                href="#masters"
                aria-expanded="false"
                aria-controls="masters"
              >
                <i
                  className="fa fa-file-pdf-o"
                  style={{ marginRight: '8px', fontSize: '17px' }}
                />
                <span className="menu-title">Report</span>
                <i className="menu-arrow" />
              </a>
              <div className="collapse" id="masters" style={{}}>
                <ul className="nav flex-column sub-menu ">
                  <li className="nav-item"><Link to='/campWiseSummary'>
                    <a className="nav-link" >
                      <i className="metismenu-icon" />
                      Camp Wise Summary
                    </a>{" "}</Link>
                  </li>
                  <li className="nav-item"><Link to='/beneficiaryDetails'>
                    <a className="nav-link">
                      <i className="metismenu-icon" />
                      Beneficiary Details
                    </a>{" "}</Link>
                  </li>
                </ul>
              </div>
            </li>
            }
            <li className="nav-item"> <Link to='/changePassword'>
              <a className="nav-link ">
                <i
                  className="fa fa-cog"
                  style={{ marginRight: '8px', fontSize: '17px' }}
                />
                <span className="menu-title"> Change Password</span>
              </a></Link>
            </li>
            <li className="nav-item" style={{cursor:'pointer'}}   data-toggle="modal" data-target="#logout" onClick={toggleModal}>

              <a className="nav-link ">
                <i
                  className="fa fa-sign-out"
                  href="login.html"
                  style={{ marginRight: '8px', fontSize: '17px' }}
                />
                <span className="menu-title"> Log Out</span>
              </a>
            </li>
          </ul>
        </div>
        <div className="toggle-btn" onClick="toggleSidebar(this)">
          <i className="mdi mdi-chevron-left asd1" />
          <i className="mdi mdi-chevron-right asd" />
        </div>
      </nav>
      {/* end left menu */}

    </>
  );
}
export default Drawer;