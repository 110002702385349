import { axiosGet, axiosPost } from "../../../../react_axios/Axios";
import { GET_FASCIA_APPLICATIONS_ACTION_TYPE, GET_FASCIA_EXPORT_ACTION_TYPE, UPDATE_APPROVE_FASCIA_FROM_ACTION_TYPE, getFasciaApplicationsUrl, getFasciaExportUrl, headers, updateApproveFasciaFormUrl } from "../../../../utils/constants";

export const getFasciaFormApplication_action = (companyName, status, exhibitorTypeId) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            CompanyName: companyName,
            Status: status,
            ExhibitorTypeID: exhibitorTypeId
        }
        console.log("getFasciaFormApplication_action Action------->", params)

        const res = await axiosGet(getFasciaApplicationsUrl, params, headers)

        dispatch({
            type: GET_FASCIA_APPLICATIONS_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const updateApproveFasciaForm_action = (isApproved, remark, fasciaFormID) => async (dispatch) => {
    try {
        const mapData = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            FasciaFormID: fasciaFormID,
            Is_Approved: isApproved,
            Remark: remark,
        }
        console.log("updateApproveFasciaForm_action Action------->", mapData)

        const res = await axiosPost(updateApproveFasciaFormUrl, mapData, headers)

        dispatch({
            type: UPDATE_APPROVE_FASCIA_FROM_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getFasciaExportData_action = () => async (dispatch) => {
    try {
        const params = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
        }
        console.log("getFasciaExportData_action Action------->", params)

        const res = await axiosGet(getFasciaExportUrl, params, headers)

        dispatch({
            type: GET_FASCIA_EXPORT_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}



