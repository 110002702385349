import {
    RECEIPT_ENTRY_ACTION_TYPE, RECEIPT_LIST_ACTION_TYPE,
    RECEIPT_NUMBER_ACTION_TYPE, SHARE_EMAIL_ACTION_TYPE,
    RECEIPT_DETAILS_ACTION_TYPE, PERFORMA_INVOICE_ACTION_TYPE,
    RECEIPT_GENERATE_VOUCHER_ACTION_TYPE, RECEIPT_DEL_ACTION_TYPE
    , RECEIPT_UPDATE_ACTION_TYPE, GET_EMAIL_ACTION_TYPE, PENDING_RECEIPT_DETAILS_ACTION_TYPE, GET_RECEIPT_PDF_ACTION_TYPE, RECEIPT_SHARE_ACTION_TYPE
} from "../../../utils/constants";


const initialState = [];

function receipt_reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case RECEIPT_ENTRY_ACTION_TYPE:
            return [...state, payload];
        case RECEIPT_LIST_ACTION_TYPE:
            return [...state, payload];
        case RECEIPT_NUMBER_ACTION_TYPE:
            return [...state, payload];
        case SHARE_EMAIL_ACTION_TYPE:
            return [...state, payload];
        case RECEIPT_DETAILS_ACTION_TYPE:
            return [...state, payload];
        case PERFORMA_INVOICE_ACTION_TYPE:
            return [...state, payload];
        case RECEIPT_GENERATE_VOUCHER_ACTION_TYPE:
            return [...state, payload];
        case GET_EMAIL_ACTION_TYPE:
            return [...state, payload];
        case RECEIPT_DEL_ACTION_TYPE:
            return [...state, payload];
        case RECEIPT_UPDATE_ACTION_TYPE:
            return [...state, payload]
        case PENDING_RECEIPT_DETAILS_ACTION_TYPE:
            return [...state, payload]
        case GET_RECEIPT_PDF_ACTION_TYPE:
            return [...state, payload]
        case RECEIPT_SHARE_ACTION_TYPE:
            return [...state, payload]
        default:
            return state;
    }
}
export default receipt_reducer;