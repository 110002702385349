import { axiosGet } from "../../../react_axios/Axios"
import { SELECT_APPLICATION_ACTION_TYPE, headers, selectApplicationUrl } from "../../../utils/constants"



export const selectApplication_action = () => async (dispatch) => {

    try {
        const params = {
            ExhregistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            AccountID: sessionStorage.getItem('AccountIDSS'),
        }
        console.log("SelectApplication Action------->", params)

        const res = await axiosGet(selectApplicationUrl, params, headers)
        console.log("SelectApplication Action------->", res)

        dispatch({
            type: SELECT_APPLICATION_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}