import { axiosGet, axiosPost } from "../../../react_axios/Axios";
import { getOnlinePresenceListUrl, getOnlinePresenceMasterDetailsUrl, insertUpdateOnlinePresencelMasterUrl, deleteonlinePresenceMasterUrl, GET_ONLINE_PRESENCE_MASTER_DETAILS, GET_ONLINE_PRESENCE_MASTER_LIST, DELETE_ONLINE_PRESENCE_MASTER, INSERT_UPDATE_ONLINE_PRESENCE_MASTER, headers } from "../../../utils/constants";

export const getOnlinepresenceMasterList_action = () => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getOnlinePresenceListUrl, params, headers)

        dispatch({
            type: GET_ONLINE_PRESENCE_MASTER_LIST,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const deleteOnlinePresenceMaster_action = (OnlinePresencePartnerID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            OnlinePresencePartnerID: OnlinePresencePartnerID,

        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(deleteonlinePresenceMasterUrl, params, headers)

        dispatch({
            type: DELETE_ONLINE_PRESENCE_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const insertUpdateOnlinePresenceMaster_action = (OnlinePresencePartnerID, Name) => async (dispatch) => {
    try {
        const params = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            OnlinePresencePartnerID: OnlinePresencePartnerID,
            Name: Name,
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosPost(insertUpdateOnlinePresencelMasterUrl, params, headers)

        dispatch({
            type: INSERT_UPDATE_ONLINE_PRESENCE_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getOnlinePresenceMasterDetails_action = (OnlinePresencePartnerID) => async (dispatch) => {
    try {
        const params = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            OnlinePresencePartnerID: OnlinePresencePartnerID,
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getOnlinePresenceMasterDetailsUrl, params, headers)

        dispatch({
            type: GET_ONLINE_PRESENCE_MASTER_DETAILS,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}