import { GET_EXHIBITOR_MANUAL_FORM_SUMMARY_EXPORT, GET_EXHIBITOR_MANUAL_FORM_SUMMARY_LIST } from "../../../utils/constants";

const initialState = [];

function exhibitor_manualForm_summaryReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_EXHIBITOR_MANUAL_FORM_SUMMARY_LIST:
            return [...state, payload];
        case GET_EXHIBITOR_MANUAL_FORM_SUMMARY_EXPORT:
            return [...state, payload];

        default:
            return state;
    }
};

export default exhibitor_manualForm_summaryReducer;