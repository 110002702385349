import { Component } from "react";
import { ToastContainer } from "react-toastify";
import Loader from "../../../utils/Loader";
import ExhibitorHeader from "../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../common_components/exhibitor_module_drawer";
import ExhibitorTopMenu from "../../../common_components/exhibitor_module_topmenu";
import AgentHeader from "../../../common_components/agent_module_header";
import AgentDrawer from "../../../common_components/agent_module_drawer";
import AgentTopMenu from "../../../common_components/agent_module_topmenu";

class RulesRegulations extends Component {

    // Initialize Constructor
    constructor(props) {
        super(props)
        //States
        this.state = {
            isLoading: false
        }
    }

    render() {
        const { isLoading } = this.state
        // console.log('test data : ',sessionStorage.getItem('IsAgentDirectLogin'));
        // console.log('test data 2: ',sessionStorage.getItem('loginDataSS'));
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                {/* {
                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                        <ExhibitorHeader /> :
                        sessionStorage.getItem('IsAgentDirectLogin') == "false" ?
                            JSON.parse(sessionStorage.getItem('indirectAgentloginDataSS')).RoleID == 4 :
                            JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 4 ?
                                <AgentHeader /> : 
                } */}
                {
                    sessionStorage.getItem('loginDataSS') && JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 8 ?
                        sessionStorage.getItem('IsAgentDirectLogin') == 'true' ?
                        sessionStorage.getItem('loginDataSS') && JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 4 ?
                                <AgentHeader /> : <ExhibitorHeader /> :
                                sessionStorage.getItem('indirectAgentloginDataSS') && JSON.parse(sessionStorage.getItem('indirectAgentloginDataSS')).RoleID == 4
                                ? <AgentHeader /> : <ExhibitorHeader />
                        : <ExhibitorHeader />
                }
                {
                    sessionStorage.getItem('loginDataSS') && JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 8 ?
                        sessionStorage.getItem('IsAgentDirectLogin') == 'true' ?
                        sessionStorage.getItem('loginDataSS') && JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 4 ?
                                <AgentDrawer /> : <ExhibitorDrawer /> :
                                sessionStorage.getItem('indirectAgentloginDataSS') && JSON.parse(sessionStorage.getItem('indirectAgentloginDataSS')).RoleID == 4
                                ? <AgentDrawer /> : <ExhibitorDrawer />
                        : <ExhibitorDrawer />
                }

                {/*app-content open*/}
                <div className="page">
                    <div className="page-main">
                        <div className="main-content with-dr-container">
                            {/* <div className="container-fluid">
                                <div className="row">
                                    <div className="step-name">
                                <p>Exhibitor Dashboard</p>
                            </div>
                                    <ExhibitorTopMenu />
                                </div>
                            </div> */}
                            <div className=" width-dr-nav">
                                <div className="row">
                                    {
                                        sessionStorage.getItem('loginDataSS') && JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 8 ?
                                            sessionStorage.getItem('IsAgentDirectLogin') == 'true' ?
                                            sessionStorage.getItem('loginDataSS') && JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 4 ?
                                                    <AgentTopMenu /> : <ExhibitorTopMenu /> :
                                                    sessionStorage.getItem('indirectAgentloginDataSS') && JSON.parse(sessionStorage.getItem('indirectAgentloginDataSS')).RoleID == 4
                                                    ? <AgentTopMenu /> : <ExhibitorTopMenu />
                                            : <ExhibitorTopMenu />
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12 p-0">
                                    <div className="card rounded-0 pt-1">
                                        <div className="card-header p-3">
                                            <h3 className="card-title" style={{ fontSize: 15 }}>

                                                Rules and Regulations for {sessionStorage.getItem("sessionExhibitionName")}
                                            </h3>
                                        </div>
                                        <div className="card-body p-1">
                                            <iframe
                                                id="myIframe"
                                                // src={`/${sessionStorage.getItem("sessionExhibitionID")}/Rules-and-Regulations.pdf#toolbar=0&navpanes=0`}
                                                src={`${sessionStorage.getItem('RulesAndRegulations')}#toolbar=0&navpanes=0`}
                                                width="100%"
                                                height="435px"
                                                title="Bing"
                                            >
                                                &lt;/div&gt; &lt;div style="height: 100px;"&gt;&lt;/div&gt;
                                                &lt;/div&gt; &lt;/div&gt; &lt;/div&gt;
                                            </iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </>

        )
    }
}

export default RulesRegulations;