import { axiosGet, axiosPost } from "../../../../../react_axios/Axios";
import { INSERT_AND_UPDATE_CAR_PASSESS_ACTION_TYPE, GET_CAR_PASSESS_DETAILS_ACTION_TYPE, headers, insertUpdateCarPassessApplicationUrl, getCarPassessApplicationDetailsUrl } from "../../../../../utils/constants";



export const insertUpdateCarPassessApplication_action = (CarPassesQty, IsRequiredCarPassed, Remark, IsAgree, CarPassesID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhRegistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
            CarPassesQty: CarPassesQty,
            IsRequiredCarPassed: IsRequiredCarPassed,
            Remark: Remark,
            IsAgree: IsAgree,
            CarPassesID: CarPassesID != '' ? CarPassesID : null,
        }
        const res = await axiosPost(insertUpdateCarPassessApplicationUrl, params, headers)

        dispatch({
            type: INSERT_AND_UPDATE_CAR_PASSESS_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getCarPassessApplicationDetails_action = (CarPassesID) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            ExhRegistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
        }
        const res = await axiosGet(getCarPassessApplicationDetailsUrl, params, headers)

        dispatch({
            type: GET_CAR_PASSESS_DETAILS_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}
