import { axiosGet, axiosPost } from "../../../../react_axios/Axios";
import { getExhibitionMasterDetailsUrl, getExhibitionMasterlistUrl, addExhibitionMasterUrl, updateExhibitionMasterUrl, deleteExhibitionMasterUrl, GET_EXHIBITION_MASTER_DETAILS, GET_EXHIBITION_MASTER_LIST, ADD_EXHIBITION_MASTER, UPDATE_EXHIBITION_MASTER, DELETE_EXHIBITION_MASTER, headers, deleteExhibitionDocumentUrl, DELETE_EXHIBITION_DOCUMENT_ACTION_TYPE, updateExhibitionConfigrationUrl, UPDATE_EXHIBITION_CONFIGRATION, getExhibitionConfigrationDetailsUrl, GET_EXHIBITION_CONFIGRATION_DETAILS } from "../../../../utils/constants";


export const updateExhibitionConfigration_action = (ExihibitionID, exhibitionSignature, signatureFileType, signatureFilePath, exhibitionUndertaking, undertakingFileType, undertakingFilePath, exhibitionRandR, RandRFileType, RandRFilePath, isHighlight, isShowMachineryGuidelines, isShowChapterNotes, isSendLoginCredentials, isVisitorSeminar, isVisitorB2B, isShowExhibitorManualFormPaidBadge, dashboardDownloadList, waterMark, waterMartFileType, waterMarkFilepath, exhibitionMailHeader, mailHeaderFiletype, mailHeaderFilePath, exhibitionMailFooter, mailFooterFiletype, mailFooterFilePath, OpenSide, OpenSideFileType, OpenSideFilepath, DoubleStorey, DoubleStoreyFileType, DoubleStoreyFilepath, isAgreeRule, isAgreeMFG, isAgreeClaim, isDoubleStorey, isChargesForOpenSide, isGstApplicable, IS_SSI_Manual_Approved, isShowVaccination, isEnableINRCurrencyForFF, isEnableUSDCurrencyForFF, isWaitListEnable, isFullPerformaGenerate, isAgreeConsent) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExihibitionID,
            signatureFile: exhibitionSignature,
            signatureFileType: signatureFileType,
            signaturePath: signatureFilePath,
            undertakingFile: exhibitionUndertaking,
            undertakingFileType: undertakingFileType,
            undertakingPath: undertakingFilePath,
            rulesAndRegulationsFile: exhibitionRandR,
            rulesAndRegulationsFileType: RandRFileType,
            rulesAndRegulationsPath: RandRFilePath,

            isShowHighlightChapters: isHighlight,
            isShowMachineryGuidelines: isShowMachineryGuidelines,
            isShowChapterNotes: isShowChapterNotes,
            isSendLoginCredentials: isSendLoginCredentials,
            isVisitorSeminar: isVisitorSeminar,
            isVisitorB2B: isVisitorB2B,
            isShowExhibitorManualFormPaidBadge: isShowExhibitorManualFormPaidBadge,
            ExhibitionDownloadList: dashboardDownloadList,
            rptWaterMartLogoFile: waterMark,
            rptWaterMartLogoFileType: waterMartFileType,
            rptWaterMartLogoFilePath: waterMarkFilepath,

            mailHeaderFile: exhibitionMailHeader,
            mailHeaderFileType: mailHeaderFiletype,
            mailHeaderPath: mailHeaderFilePath,
            mailFooterFile: exhibitionMailFooter,
            mailFooterFileType: mailFooterFiletype,
            mailFooterPath: mailFooterFilePath,

            OpenSide: OpenSide,
            OpenSideFileType: OpenSideFileType,
            OpenSideFilepath: OpenSideFilepath,
            DoubleStorey: DoubleStorey,
            DoubleStoreyFileType: DoubleStoreyFileType,
            DoubleStoreyFilepath: DoubleStoreyFilepath,

            isAgreeRule: isAgreeRule,
            isAgreeMFG: isAgreeMFG,
            isAgreeClaim: isAgreeClaim,
            isDoubleStorey: isDoubleStorey,
            isChargesForOpenSide: isChargesForOpenSide,
            isGstApplicable: isGstApplicable,
            IS_SSI_Manual_Approved: IS_SSI_Manual_Approved,
            isShowVaccination: isShowVaccination,
            isEnableINRCurrencyForFF: isEnableINRCurrencyForFF,
            isEnableUSDCurrencyForFF: isEnableUSDCurrencyForFF,
            isWaitListEnable: isWaitListEnable,
            isFullPerformaGenerate: isFullPerformaGenerate,
            isAgreeConsent: isAgreeConsent,
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosPost(updateExhibitionConfigrationUrl, params, headers)

        dispatch({
            type: UPDATE_EXHIBITION_CONFIGRATION,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getExhibitionConfigrationDetails_action = (ExhibitionID,) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExhibitionID,
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getExhibitionConfigrationDetailsUrl, params, headers)

        dispatch({
            type: GET_EXHIBITION_CONFIGRATION_DETAILS,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const deleteExhibitionDocument_action = (ExhibitionID, deleteKey) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExhibitionID,
            flag: deleteKey
        }
        console.log("deleteExhibitionDocument_action Action------->", params)

        const res = await axiosGet(deleteExhibitionDocumentUrl, params, headers)

        dispatch({
            type: DELETE_EXHIBITION_DOCUMENT_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}