import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { getInternetConnectionMasterList_action, insertUpdateInternetConnectionMaster_action, deleteInternetConnectionMaster_action } from '../../../../../actions/admin_module_action/master_action/manual_form_master_action/internet_connection_master_action'
import { fill_action } from '../../../../../actions/fillCombo_action'
import { BASE_URL, countryActionId, toastError, toastSuccess, visaIdTypeActionId } from "../../../../../utils/constants";
import { emailValidator, mobileNoValidator } from "../../../../../utils/Validator";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import { data } from "jquery";
import Resizer from "react-image-file-resizer";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";
import Passport_Front_1008_VIS_903747 from "../../../../../files/Passport_Front_1008_VIS_903747.jpeg"

class InternetConnectionMasterAU extends Component {

    // initialize constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            // fields
            BankName: '',
            Branch: '',
            Address: "",
            AccountNumber: '',
            RTGS_IFSC: '',
            Name: "",
            Phone: '',
            Mobile: '',
            Fax: '',
            Email: '',
            Designation: '',
            // List
            internetConnectionList: [],
            BankNameErr: '',
            BranchErr: '',
            AddressErr: "",
            AccountNumberErr: '',
            RTGS_IFSCErr: '',
            NameErr: "",
            PhoneErr: '',
            MobileErr: '',
            FaxErr: '',
            EmailErr: '',
            DesignationErr: '',
            index: 1,
        }
        this.OnCancel = this.OnCancel.bind(this)
        this.inputValidate = this.inputValidate.bind(this)
        // this.getFillList = this.getFillList.bind(this)
        this.renderInternetConnectionList = this.renderInternetConnectionList.bind(this)
        // this.onFilePick = this.onFilePick.bind(this)
        // this.onPdfSelect = this.onPdfSelect.bind(this)
        // this.resizeFile = this.resizeFile.bind(this)
        this.handleAddMore = this.handleAddMore.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.ClearValue = this.ClearValue.bind(this)
        // this.handleUpdate = this.handleUpdate.bind(this)
        // this.getDetails = this.getDetails.bind(this)
        // this.emailValidation = this.emailValidation.bind(this)
        // this.onCrossClick = this.onCrossClick.bind(this)
        // this.mobileValidation = this.mobileValidation.bind(this)
        // this.onDocDelete = this.onDocDelete.bind(this)
        // this.handleDelete = this.handleDelete.bind(this)
    }

    componentDidMount() {
        this.getInternetConnectionMasterList()
    }

    // On cancel Click
    OnCancel() {
        this.props.navigate('/internetConnectionMasterList')
    }
    getInternetConnectionMasterList(status) {
        this.setState({ isLoading: true })
        this.props.getInternetConnectionMasterList_action()
            .then((data) => {
                if (data.error != 1) {
                    var contactList = data.data[0]
                    var bankDetailsList = data.data[1]
                    var finalList = []
                    for (var i = 0; i < contactList.length; i++) {
                        for (var j = 0; j < bankDetailsList.length; j++) {
                            if (contactList[i].InternetConnectionContactPersonID == bankDetailsList[j].InternetConnectionContactPersonID) {
                                var mergObj = { ...contactList[i], ...bankDetailsList[j] }
                                finalList.push(mergObj)
                            }
                        }
                    }
                    console.log(finalList)
                    this.setState({ internetConnectionList: finalList })
                    this.setState({ isLoading: false })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // Input Fields Validation
    inputValidate() {
        const { BankName, Branch, Address, AccountNumber, RTGS_IFSC, Name, Phone, Mobile, Fax, Email, Designation, internetConnectionList,
            BankNameErr, BranchErr, AddressErr, AccountNumberErr, RTGS_IFSCErr, NameErr, PhoneErr, MobileErr, FaxErr, EmailErr, DesignationErr, } = this.state
        if (BankName.trim() == "") {
            this.setState({ BankNameErr: "Please Enter Bank Name" })
        }
        if (Branch.trim() == "") {
            this.setState({ BranchErr: "Please Enter Branch Name" })
        }
        if (Address.trim() == "") {
            this.setState({ AddressErr: "Please Enter Address" })
        }
        if (AccountNumber == "") {
            this.setState({ AccountNumber: "Please Enter Account number" })
        }
        if (RTGS_IFSC == "") {
            this.setState({ RTGS_IFSC: "Please Enter RTGS IFSC code" })
        }
        if (Name == "") {
            this.setState({ NameErr: "Please Enter Name" })
        }
        if (Designation == "") {
            this.setState({ DesignationErr: "Please Enter Designation" })
        }
        if (Mobile == "") {
            this.setState({ MobileErr: "Please Enter Mobile No." })
        }
        if (Email == "") {
            this.setState({ EmailErr: "Please Enter Email" })
        } else {
            var Emailerr = (emailValidator(Email) == '') ? false : emailValidator(Email)
            if (Email) {
                this.setState({ EmailErr: "Please Enter Proper Primary Email" })
            }
        }
    }

    renderInternetConnectionList() {
        const { internetConnectionList } = this.state
        return (
            <ul class="list-group " style={{ position: 'relative', zIndex: 1 }}>
                {
                    internetConnectionList.length > 0 && internetConnectionList.map((data, index) => (
                        data.isShow &&
                        <li class="list-group-item" key={Math.random()}>
                            <div className="added-more p-3 rounded-2">
                                <h5 style={{ color: "#0056ff" }}>Added Internet Connection Master {data.index}</h5>
                                <h6 >Agency Contact </h6>
                                <div className="row mt-4">
                                    <div className="col-md-4">
                                        <h6>Name: {data.Name}</h6>
                                    </div>
                                    <div className="col-md-4">
                                        <h6>Mobile no.: {data.Mobile}</h6>
                                    </div>
                                    <div className="col-md-4">
                                        <h6>Designation: {data.Designation}</h6>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-4">
                                        <h6>Telephone: {data.Phone}</h6>
                                    </div>
                                    <div className="col-md-4">
                                        <h6>Fax: {data.Fax}</h6>
                                    </div>
                                    <div className="col-md-4">
                                        <h6>Email: {data.Email}</h6>
                                    </div>
                                </div>
                            </div>
                            <hr className="m-1" />
                            <div className="added-more p-3 rounded-2">
                                <h6>Bank Details </h6>
                                <div className="row mt-4">
                                    <div className="col-md-4">
                                        <h6>Account Number: {data.AccountNumber}</h6>
                                    </div>
                                    <div className="col-md-4">
                                        <h6>Bank Name: {data.BankName}</h6>
                                    </div>
                                    <div className="col-md-4">
                                        <h6>Branch Name: {data.Branch}</h6>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-4">
                                        <h6>Address: {data.Address}</h6>
                                    </div>
                                    <div className="col-md-4">
                                        <h6>RTGS IFSC: {data.RTGS_IFSC}</h6>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))
                }
            </ul>
        )
    }

    handleAddMore() {
        const { BankName, Branch, Address, AccountNumber, index, RTGS_IFSC, Name, Phone, Mobile, Fax, Email, Designation, internetConnectionList,
            BankNameErr, BranchErr, AddressErr, AccountNumberErr, RTGS_IFSCErr, NameErr, PhoneErr, MobileErr, FaxErr, EmailErr, DesignationErr } = this.state
        this.inputValidate()
        if (BankName != '' && Branch != '' && Address != '' && AccountNumber != '' && RTGS_IFSC != '' && Name != '' && Mobile != '' && Email != '' && Designation != '') {
            if (BankNameErr == '' && BranchErr == '' && AddressErr == '' && AccountNumberErr == '' && RTGS_IFSCErr == '' && NameErr == '' && EmailErr == '' && MobileErr == '' && DesignationErr == '') {
                internetConnectionList.push({
                    'BankName': BankName,
                    'Branch': Branch,
                    'Address': Address,
                    'AccountNumber': AccountNumber,
                    'RTGS_IFSC': RTGS_IFSC,
                    'Name': Name,
                    'Phone': Phone,
                    'Mobile': Mobile,
                    'Fax': Fax,
                    'Email': Email,
                    'Designation': Designation,
                    'index': index,
                    'isShow': true,
                })
                setTimeout(() => { this.ClearValue() }, 200);
            }
        }
        console.log(this.state.internetConnectionList)
    }

    handleSave() {
        const { internetConnectionList } = this.state
        // this.setState({ isLoading: true })
        this.props.insertUpdateInternetConnectionMaster_action(internetConnectionList)
            .then((data) => {
                if (data.error != 1) {
                    toastSuccess("Record Added Successfully")
                    setTimeout(() => {
                        this.setState({ isLoading: false })
                        this.props.navigate('/internetConnectionMasterList')
                    }, 300);
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    ClearValue() {
        this.setState({
            BankName: '',
            Branch: '',
            Address: "",
            AccountNumber: '',
            RTGS_IFSC: '',
            Name: "",
            Phone: '',
            Mobile: '',
            Fax: '',
            Email: '',
            Designation: '',
            index: this.state.index + 1
        })
    }

    render() {
        const { isLoading, BankName, Branch, Address, AccountNumber, RTGS_IFSC, Name, Phone, Mobile, Fax, Email, Designation, internetConnectionList,
            BankNameErr, BranchErr, AddressErr, AccountNumberErr, RTGS_IFSCErr, NameErr, PhoneErr, MobileErr, FaxErr, EmailErr, DesignationErr
        } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <div className="app-content with-dr-container com-height">
                        <AdminDrawer />
                        <div className="row">
                            <div className="step-name">
                                <p>Internet Connection Master</p>
                            </div>
                        </div>
                        <div className="m-2">
                            <div className="">

                                <div className="">
                                    <div
                                        className="card-header p-3"
                                        style={{ paddingLeft: "7px !important" }}
                                    >
                                        <h3 className="card-title" style={{ fontSize: 15 }}>
                                            Agency contact
                                        </h3>
                                    </div>
                                    <div className="card-body p-2 mt-1">
                                        <div className="row">
                                            <div className="col-xl-4">
                                                <label htmlFor="validationCustom03">
                                                    Name<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={Name}
                                                    onChange={(e) => this.setState({ Name: e.target.value, NameErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{NameErr}</span>}

                                            </div>
                                            <div className="col-xl-4">
                                                <label htmlFor="validationCustom03">
                                                    Mobile No.<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={Mobile}
                                                    onChange={(e) => this.setState({ Mobile: e.target.value, MobileErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{MobileErr}</span>}

                                            </div>
                                            <div className="col-xl-4">
                                                <label htmlFor="validationCustom03">
                                                    Designation<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={Designation}
                                                    onChange={(e) => this.setState({ Designation: e.target.value, DesignationErr: '' })}
                                                />

                                                {<span className='text-danger text-start text-left d-block mt-1'>{DesignationErr}</span>}
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-xl-4">
                                                <label htmlFor="validationCustom03">
                                                    Telephone
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={Phone}
                                                    onChange={(e) => this.setState({ Phone: e.target.value, })}
                                                />
                                            </div>
                                            <div className="col-xl-4">
                                                <label htmlFor="validationCustom03">
                                                    Fax
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={Fax}
                                                    onChange={(e) => this.setState({ Fax: e.target.value, })}
                                                />
                                            </div>
                                            <div className="col-xl-4">
                                                <label htmlFor="validationCustom03">Email<span className="text-red">*</span></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={Email}
                                                    onChange={(e) => this.setState({ Email: e.target.value, EmailErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{EmailErr}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <div
                                        className="card-header p-3"
                                        style={{ paddingLeft: "7px !important" }}
                                    >
                                        <h3 className="card-title" style={{ fontSize: 15 }}>
                                            Bank Details
                                        </h3>
                                    </div>
                                    <div className="card-body p-2 mt-1">
                                        <div className="row">
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    Bank Name<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={BankName}
                                                    onChange={(e) => this.setState({ BankName: e.target.value, BankNameErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{BankNameErr}</span>}

                                            </div>
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    Branch Name<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={Branch}
                                                    onChange={(e) => this.setState({ Branch: e.target.value, BranchErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{BranchErr}</span>}

                                            </div>
                                            <div className="col-xl-6">
                                                <label htmlFor="validationCustom03">
                                                    Bank Address<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={Address}
                                                    onChange={(e) => this.setState({ Address: e.target.value, AddressErr: '' })}
                                                />

                                                {<span className='text-danger text-start text-left d-block mt-1'>{AddressErr}</span>}
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">
                                                    Account Number<span className="text-red">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={AccountNumber}
                                                    onChange={(e) => this.setState({ AccountNumber: e.target.value, AccountNumberErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{AccountNumberErr}</span>}
                                            </div>
                                            <div className="col-xl-3">
                                                <label htmlFor="validationCustom03">RTGS IFSC Code<span className="text-red">*</span></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                    required=""
                                                    value={RTGS_IFSC}
                                                    onChange={(e) => this.setState({ RTGS_IFSC: e.target.value, RTGS_IFSCErr: '' })}
                                                />
                                                {<span className='text-danger text-start text-left d-block mt-1'>{RTGS_IFSCErr}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="btn-group mt-2 save-can d-block">
                                    <div className="btn-group mt-2" role="group" aria-label="Basic example">
                                        <button
                                            style={{ marginRight: 10 }}
                                            type="button"
                                            className="btn btn-primary rounded-0 d-block"
                                            // style={{ }}
                                            onClick={() => this.handleAddMore()}
                                        >
                                            {this.state.index == 1 ? 'Add' : 'Add More'}
                                        </button>
                                        {/* <button type="button" class="btn btn-primary rounded-0">Payment Listing Page</button> */}
                                    </div>

                                    <hr />
                                    {this.renderInternetConnectionList()}

                                    <div className="row">
                                        <div className="btn-group mt-2 save-can d-block" role="group" style={{ float: 'right' }} aria-label="Basic example">
                                            <button
                                                type="button"
                                                className="btn btn-success rounded-0"
                                                style={{ marginRight: '10px', width: '100px' }}
                                                onClick={() => this.handleSave()}
                                                disabled={this.state.index == 1}
                                            >
                                                Save
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-warning rounded-0"
                                                style={{ marginRight: '10px', width: '100px' }}
                                                onClick={() => this.OnCancel()}
                                            >
                                                Back
                                            </button>

                                            {/* <button type="button" class="btn btn-primary rounded-0">Payment Listing Page</button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </>
            </>
        )
    }
}
export default withRouter(connect(null, { getInternetConnectionMasterList_action, insertUpdateInternetConnectionMaster_action, deleteInternetConnectionMaster_action, fill_action })(InternetConnectionMasterAU))