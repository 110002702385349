import moment from "moment";
import { axiosGet, axiosPost } from "../../../react_axios/Axios";
import { headers, INSERT_DELEGATE_INVITATION_EVENT_ACTION_TYPE, UPDATE_DELEGATE_INVITATION_EVENT_ACTION_TYPE, GET_DELEGATE_INVITATION_EVENT_ACTION_TYPE, DELETE_DELEGATE_INVITATION_EVENT_ACTION_TYPE, insertDelegateInvitationEventUrl, UpdateDelegateInvitationEventUrl, getDelegateInvitationEventListUrl, deleteDelegateInvitationEventUrl } from "../../../utils/constants";


export const insertInvitationEvent_action = (ExhibitionID, InvitationTypeID, InvitationEventName, InvitationEventDate, Location, ReportingTime, FromTime, ToTime, InvitationEventImagePath, Capacity, formFile, fileName, formFileType) => async (dispatch) => {
    try {
        const params = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExhibitionID,
            InvitationTypeID: InvitationTypeID,
            InvitationEventName: InvitationEventName,
            InvitationEventDate: moment(InvitationEventDate).format('YYYY-MM-DD'),
            Location: Location,
            ReportingTime: moment(ReportingTime, 'HH:mm').format('HH:mm:ss'),
            FromTime: moment(FromTime, 'HH:mm').format('HH:mm:ss'),
            ToTime: moment(ToTime, 'HH:mm').format('HH:mm:ss'),
            InvitationEventImagePath: InvitationEventImagePath,
            Capacity: parseInt(Capacity),
            FormFile: formFile,
            FileName: fileName,
            FormFileType: formFileType,
        }

        const res = await axiosPost(insertDelegateInvitationEventUrl, params, headers)

        dispatch({
            type: INSERT_DELEGATE_INVITATION_EVENT_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const updateInvitationEvent_action = (ExhibitionID, InvitationEventID, InvitationTypeID, InvitationEventName, InvitationEventDate, Location, ReportingTime, FromTime, ToTime, InvitationEventImagePath, Capacity, formFile, fileName, formFileType) => async (dispatch) => {
    try {
        const params = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            InvitationEventID: InvitationEventID,
            ExhibitionID: ExhibitionID,
            InvitationTypeID: InvitationTypeID,
            InvitationEventName: InvitationEventName,
            InvitationEventDate: InvitationEventDate,
            Location: Location,
            ReportingTime: moment(ReportingTime, 'HH:mm').format('HH:mm:ss'),
            FromTime: moment(FromTime, 'HH:mm').format('HH:mm:ss'),
            ToTime: moment(ToTime, 'HH:mm').format('HH:mm:ss'),
            InvitationEventImagePath: InvitationEventImagePath,
            Capacity: Capacity,
            FormFile: formFile,
            FileName: fileName,
            FormFileType: formFileType,
        }

        const res = await axiosPost(UpdateDelegateInvitationEventUrl, params, headers)

        dispatch({
            type: UPDATE_DELEGATE_INVITATION_EVENT_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getInvitationEvent_action = (ExhibitionID,InvitationEventID, InvitationEventName, Status,InvitationTypeID,InvitationEventDate,Location) => async (dispatch) => {
    try {
        const params = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExhibitionID,
            InvitationEventID: InvitationEventID,
            InvitationEventName: InvitationEventName,
            Status: Status,
            InvitationTypeID: InvitationTypeID,
            InvitationEventDate: InvitationEventDate,
            Location: Location,
        }

        const res = await axiosGet(getDelegateInvitationEventListUrl, params, headers)

        dispatch({
            type: GET_DELEGATE_INVITATION_EVENT_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const deleteInvitationEvent_action = (InvitationEventID) => async (dispatch) => {
    try {
        const params = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            InvitationEventID: InvitationEventID
        }

        const res = await axiosGet(deleteDelegateInvitationEventUrl, params, headers)

        dispatch({
            type: DELETE_DELEGATE_INVITATION_EVENT_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}