import { Component } from "react";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import ExhibitionDrawer from "../../../../../common_components/admin_module_drawer";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import { getEdirectoryList_action, submitEDirectoryList_action, delDirectory_action } from "../../../../../actions/exhibitor_module_actions/manual_Forms_actions/forms_actions/e-directory_form_action/e-directory_form_action";
import { toastError, toastSuccess } from "../../../../../utils/constants";
import Modal from "react-modal";



class EDirectory extends Component {

    // Intinialize constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            eDirectoryList: [],
            directoryId: "",
            isDelOpen: false,
            DeadlineDate: "",
            currentDate: new Date(),
            StatusID: ''
        }
        this.onAddNational = this.onAddNational.bind(this)
        this.getEDirectoryList = this.getEDirectoryList.bind(this)
        this.onView = this.onView.bind(this)
        this.onEdit = this.onEdit.bind(this)
        this.submitEDirectoryList = this.submitEDirectoryList.bind(this)
        this.toggleDelModal = this.toggleDelModal.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
    }

    // On Component Load
    componentDidMount() {
        this.setState({
            DeadlineDate: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.Deadline != "" ?
                new Date(this.props.location.state.Deadline) : '',
            StatusID: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.StatusID != "" ? this.props.location.state.StatusID : '',
        })
        this.getEDirectoryList()
        console.log(this.props)
    }

    // On Add New National
    onAddNational() {
        const { DeadlineDate } = this.state
        this.props.navigate('/edirectoryAdd', { state: { is_add: true, addType: 'National', DeadlineDate: DeadlineDate } })
    }

    // On Add New International
    onAddInternational() {
        const { DeadlineDate } = this.state
        this.props.navigate('/edirectoryAdd', { state: { is_add: true, addType: 'International', DeadlineDate: DeadlineDate } })
    }

    // Get E-Directory List
    getEDirectoryList() {
        this.setState({ isLoading: true })
        this.props.getEdirectoryList_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log(" E-Directory List Details", data.data)
                    this.setState({ eDirectoryList: data.data, isLoading: false })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // On View E-Directory
    onView(id) {
        this.props.navigate('/edirectoryAdd', { state: { is_View: true, eDirectoryID: id } })
    }

    // On Edit E-Directory
    onEdit(id) {
        this.props.navigate('/edirectoryAdd', { state: { is_Update: true, eDirectoryID: id } })
    }

    // On Submit E-Directory Listing
    submitEDirectoryList() {
        this.setState({ isLoading: true })
        this.props.submitEDirectoryList_action()
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ isLoading: false })
                    this.props.navigate('/manualForms')
                } else {
                    toastError(data.msg)
                    this.setState({ isLoading: false })
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    handleCancel() {
        this.props.navigate('/manualForms')
    }

    // Delete E Directory  Modal Toggle
    toggleDelModal(id) {
        const { isDelOpen } = this.state
        this.setState({ isDelOpen: !isDelOpen, directoryId: id })
    }

    // // handle Directory Delete Delete"
    handleDelete(e) {
        e.preventDefault()
        const { isDelOpen, directoryId } = this.state
        this.setState({ isLoading: true })
        this.props.delDirectory_action(directoryId)
            .then((data) => {
                if (data.error != 1) {
                    toastSuccess("Directory Deleted SuccessFully")
                    this.setState({ isLoading: false, isDelOpen: !isDelOpen })
                    this.getEDirectoryList()
                } else {
                    toastError(data.msg)
                    this.setState({ isLoading: false })
                }
            }).catch((error) => {
                console.log(error)
            })
    }

    render() {
        const { isLoading, eDirectoryList, isDelOpen, currentDate, DeadlineDate, StatusID } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <ExhibitorHeader />
                <ExhibitorDrawer />
                <div className="page">
                    <div className="page-main">
                        <div className="main-content with-dr-container">
                            <div className=" width-dr-nav">
                                <div className="row">
                                    <ExhibitorTopMenu />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card rounded-0 pt-1">
                                        <div
                                            className="card-header p-3"
                                            style={{ paddingLeft: "7px !important" }}
                                        >
                                            <h3 className="card-title" style={{ fontSize: 15 }}>
                                                Directory Listing
                                            </h3>
                                        </div>
                                        <div className="card-body p-1">
                                            <div className="row">
                                                {currentDate > DeadlineDate &&
                                                    <span className="text-danger">THE DEADLINE TO FILL OUT THE FORM HAS PASSED.</span>
                                                }
                                            </div>
                                            <div className="col-status mt-1 mb-1">
                                                <ul>
                                                    <li>
                                                        <i className="fa fa-info-circle text-warning" /> Pending
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check text-success" /> Approved
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-times text-danger" /> Disapproved
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="table-responsive">
                                                <div className="row mt-3 mb-2" style={{ float: "right" }}></div>
                                                <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-center">Date</th>
                                                            <th>Company Name</th>
                                                            <th>Exhibitor Type </th>
                                                            <th className="text-center">Chapters Selected</th>
                                                            <th className="text-center">Form Complete </th>
                                                            <th className="text-center">View/Edits</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            eDirectoryList.length > 0 && eDirectoryList.map((data) => (
                                                                <tr>
                                                                    <td className="text-center">{data.Date}</td>
                                                                    <td>{data['Company Name']}</td>
                                                                    <td>{data.Type}</td>
                                                                    <td className="text-center">{data['Chapters Selected']}</td>
                                                                    <td className="text-center">
                                                                        {
                                                                            data.Status == "Pending" || data.Status == "Applied" ?
                                                                                <i className="fa fa-info-circle text-warning" /> :
                                                                                data.Status == "Approved" ?
                                                                                    <i className="fa fa-check text-success" /> :
                                                                                    data.Status == "Disapproved" ?
                                                                                        <i className="fa fa-times text-danger" /> :
                                                                                        null
                                                                        }
                                                                    </td>
                                                                    <td className="text-center">
                                                                        {currentDate < DeadlineDate &&
                                                                            <span className="action-icon m-2">
                                                                                <i className="fa fa-trash text-danger"
                                                                                    onClick={(e) => this.toggleDelModal(data.DirectoryListingID)}
                                                                                />
                                                                            </span>
                                                                        }
                                                                        <span className="">
                                                                            {
                                                                                (currentDate > DeadlineDate) ?
                                                                                    <i className="fa fa-eye text-info" aria-hidden="true" onClick={(e) => this.onView(data.DirectoryListingID)} /> :
                                                                                    <i className="fa fa-pencil text-info"
                                                                                        onClick={(e) => this.onEdit(data.DirectoryListingID)}
                                                                                    />
                                                                            }

                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                                <div />

                                            </div>
                                            <div class='d-flex m-3'>
                                                <div
                                                    className="btn-group"
                                                    role="group"
                                                    aria-label="Basic example"
                                                    // style={{ position: "absolute", top: 4, right: 5 }}
                                                >
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-primary rounded-0"
                                                        style={{ marginRight: 10 }}
                                                        disabled={currentDate > DeadlineDate}
                                                        onClick={(e) => this.onAddNational()}
                                                    >
                                                        Add Company Details (National)
                                                    </button>
                                                    <button type="button" className="btn btn-outline-primary rounded-0"
                                                        disabled={currentDate > DeadlineDate}
                                                        onClick={(e) => this.onAddInternational()}
                                                        style={{ marginRight:'10px' }}
                                                    >
                                                        Add Company Details (International)
                                                    </button>
                                                </div>

                                                <button
                                                    type="button"
                                                    className="btn btn-primary rounded-0 "
                                                    style={{ marginRight: 10 }}
                                                    disabled={currentDate > DeadlineDate || eDirectoryList.length ==0}
                                                    onClick={() => this.submitEDirectoryList()}
                                                >
                                                    Submit
                                                </button>
                                                <button className="btn apper-cancel"
                                                style={{borderRadius:'0px'}}
                                                        onClick={(e) => this.handleCancel()}>Back</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={isDelOpen} onRequestClose={() => this.setState({ isDelOpen: false })}>
                    <div className="modal-dialog">
                        <div className="modal-content confirm-logout">
                            <div class="modal-header p-2">
                                <h5 class="modal-title modal_title_text text-danger">Confirm Delete</h5>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={() => this.setState({ isDelOpen: false })} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <p class="pop_label">Do you really want to delete this record?</p>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer p-1">
                                <div class="form-group text-right mod-fot">
                                    <button type="button" class="btn btn-del-pop"
                                        onClick={(e) => this.handleDelete(e)}
                                    >Yes</button>
                                    <button type="button" class="btn btn-can-pop" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={() => this.setState({ isDelOpen: false })}>No</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
            </>
        )
    }
}
export default withRouter(connect(null, { getEdirectoryList_action, submitEDirectoryList_action, delDirectory_action })(EDirectory))
