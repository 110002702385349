import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from "react-router-dom";
import $ from 'jquery';
import Modal from "react-modal";
import { withRouter } from '../utils/withRouter';


const ExhibitorTopMenu = ({ head, userId }) => {
    const history = useNavigate();
    const navigate = useNavigate();
    const location = useLocation();

    const [isOpen, setIsOpen] = useState(false)
    const [loginAccesslist, setLoginAccesslist] = useState([])

    //toggle model for logout.................................
    const toggleModal = () => {
        setIsOpen(!isOpen)
    }

    const loadScripts = () => {
        // This array contains all the files/CDNs 
        // const dynamicScripts = [
        //     'js/jquery.sticky.js',
        //     'js/metisMenu.min.js',
        //     'js/metisMenu-active.js',
        //     'js/main.js'
        // ];

        // for (let i = 0; i < dynamicScripts.length; i++) {
        //     const node = document.createElement('script');
        //     node.src = dynamicScripts[i];
        //     node.type = 'text/javascript';
        //     node.async = false;
        //     document.getElementsByTagName('head')[0].appendChild(node);
        // }
    }
    const logout = () => {
        sessionStorage.clear()
        navigate('/')
    }
    useEffect(() => {
        console.log("ExhibitorTopMenu")
        console.log('is paid registration : ', sessionStorage.getItem('isPaidByAgentRegistration'));
        console.log('isIndirectAccess : ', sessionStorage.getItem('isIndirectAccess'));
        // loadScripts();
        // console.log("233", JSON.parse(sessionStorage.getItem('LoginAccesslist'))[0]['IsRead'])
        // setLoginAccesslist(JSON.parse(sessionStorage.getItem('LoginAccesslist')))
    }, []);

    const onRedirect = (path) => {
        history.push({ pathname: path, state: { 'isSearchClear': true } });
    }

    const isRouteActive = (routePath) => {
        return location.pathname == routePath
    }

    return (
        <>
            <Modal isOpen={isOpen} onRequestClose={e => toggleModal()}>
                <div className="modal-dialog modal-dialog-centered bd-example-modal-lg" >
                    <div className="modal-content" style={{ borderRadius: '0px' }}>
                        <div className="modal-header p-2">
                            <h4 className="modal-title">Confirm Logout</h4>
                            {/* <button type="button" class="close modal-close" data-dismiss="modal" onClick={this.toggleModal}>&times;</button> */}
                            <button style={{ backgroundColor: '#eee' }} type="button " className="close pop-close text-dark" data-dismiss="modal" onClick={e => toggleModal()} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

                        </div>
                        <div class="modal-body pl-0 pt-4 pb-4">
                            <p class="pop_label">Do you really want to logout?</p>
                        </div>
                        <div className='modal-footer d-block p-2'>
                            <button className="btn btn-outline-danger" onClick={logout}> Yes</button>
                            <button class="btn btn-outline-secondary" data-dismiss="modal" onClick={toggleModal}> No</button>
                        </div>

                    </div>
                </div>
            </Modal>
            {/* Top menu */}
            <div className="navg">
                <ul className="nav nav-pills">
                    <Link to={'/exhibitor_dashboard'}>
                        <li className="nav-item ">
                            {" "}
                            <a className={isRouteActive('/exhibitor_dashboard') ? "nav-link active px-4 py-2" : "nav-link  px-4 py-2"}>
                                Dashboard{" "}
                            </a>{" "}
                        </li>
                    </Link>
                    {
                        (sessionStorage.getItem('isIndirectAccess') && sessionStorage.getItem('isIndirectAccess') != null &&
                            sessionStorage.getItem('isIndirectAccess') == 'true') || (sessionStorage.getItem('isPaidByAgentRegistration') != null && sessionStorage.getItem('isPaidByAgentRegistration') == 1) || (sessionStorage.getItem("RoleIDSS") != null && sessionStorage.getItem("RoleIDSS") == 8) ?
                            <Link to={'/manualForms'}>
                                <li className="nav-item ">
                                    {" "}
                                    <a className={isRouteActive('/manualForms') ? "nav-link active px-4 py-2" : "nav-link  px-4 py-2"}>
                                        Exhibitor Manual Forms
                                    </a>{" "}
                                </li>
                            </Link>
                            :
                            (JSON.parse(sessionStorage.getItem('sessionHeaderData')) &&
                                JSON.parse(sessionStorage.getItem('sessionHeaderData')) != null &&
                                JSON.parse(sessionStorage.getItem('sessionHeaderData'))[0]['Flag'] != null &&
                                (JSON.parse(sessionStorage.getItem('sessionHeaderData'))[0]['Flag']))

                            // ((JSON.parse(sessionStorage.getItem('sessionHeaderData'))[0]['Flag'])  || 
                            // (JSON.parse(sessionStorage.getItem('sessionHeaderData'))[0]['Flag']) == false))
                            &&
                            <Link to={'/manualForms'}>
                                <li className="nav-item ">
                                    {" "}
                                    <a className={isRouteActive('/manualForms') ? "nav-link active px-4 py-2" : "nav-link  px-4 py-2"}>
                                        Exhibitor Manual Forms
                                    </a>{" "}
                                </li>
                            </Link>
                    }
                    {(sessionStorage.getItem('isPaidByAgentRegistration') != null && sessionStorage.getItem('isPaidByAgentRegistration') == 0) ||
                        (sessionStorage.getItem('isIndirectAccess') != null && sessionStorage.getItem('isIndirectAccess') == 'true') ? (
                        <Link to={'/rulesRegulations'}>
                            <li className="nav-item ">
                                {" "}
                                <a className={isRouteActive('/rulesRegulations') ? "nav-link active px-4 py-2" : "nav-link  px-4 py-2"}>
                                    Rules and Regulations
                                </a>{" "}
                            </li>
                        </Link>
                    ) : (null)}
                    <Link to={'/importantDates'}>
                        <li className="nav-item ">
                            {" "}
                            <a className={isRouteActive('/importantDates') ? "nav-link active px-4 py-2" : "nav-link  px-4 py-2"} >
                                Important Dates
                            </a>{" "}
                        </li>
                    </Link>
                    {(sessionStorage.getItem("RoleIDSS") != null && sessionStorage.getItem("RoleIDSS") == 8) ? <Link to={'/partnerApplication'}>
                        <li className="nav-item ">
                            {" "}
                            <a className={isRouteActive('/partnerApplication') ? "nav-link active px-4 py-2" : "nav-link  px-4 py-2"}>
                                Partner Application
                            </a>{" "}
                        </li>
                    </Link> :
                        <Link to={'/spacebooking'}>
                            <li className="nav-item ">
                                {" "}
                                <a className={isRouteActive('/spacebooking') ? "nav-link active px-4 py-2" : "nav-link  px-4 py-2"}>
                                    Spacebooking
                                </a>{" "}
                            </li>
                        </Link>}
                    {/* <Link to={'/exhibitorSeminar'}>
                        <li className="nav-item ">
                            {" "}
                            <a className={isRouteActive('/exhibitorSeminar') ? "nav-link active px-4 py-2" : "nav-link  px-4 py-2"}>
                                Exhibitor Seminar{" "}
                            </a>{" "}
                        </li>
                    </Link> */}

                    {(sessionStorage.getItem('isPaidByAgentRegistration') != null && sessionStorage.getItem('isPaidByAgentRegistration') == 0 && sessionStorage.getItem("RoleIDSS") != null && sessionStorage.getItem("RoleIDSS") != 8) ||
                        (sessionStorage.getItem('isIndirectAccess') != null && sessionStorage.getItem('isIndirectAccess') == 'true') ? (
                        <li className="nav-item dropdown ">
                            {console.log(sessionStorage.getItem('isPaidByAgentRegistration'))}
                            {" "}
                            <a
                                className="nav-link dropdown-toggle px-4 py-2"
                                data-bs-toggle="dropdown"
                                href="javascript:void(0)"
                                role="button"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                Accounts
                            </a>
                            <div className="dropdown-menu">
                                {" "}   <Link to={'/updatePayment'}>

                                    <a className="dropdown-item" href="update-payment.html">
                                        Update Payment
                                    </a>   </Link>
                                <Link to={'/receiptlist'}>

                                    <a className="dropdown-item" href="update-payment.html">
                                        Receipt List
                                    </a>   </Link>
                                <Link to={'/proformalist'}>

                                    <a className="dropdown-item" href="update-payment.html">
                                        Proforma Invoices
                                    </a>   </Link>
                                {JSON.parse(sessionStorage.getItem("loginDataSS")).CountryID == 91 && <Link to={'/duePaymentsList'}>

                                    <a className="dropdown-item" href="update-payment.html">
                                        Payment Online
                                    </a>   </Link>}
                                <Link to={'/taxInvoices'}>

                                    <a className="dropdown-item" href="update-payment.html">
                                        Tax Invoices
                                    </a>   </Link>
                            </div>
                        </li>
                    ) : (null)}


                </ul>

                <span style={{ position: "absolute", right: "0", top: "7px" }}>
                    {/* <span>
                        <Link to={'/Change_Password'}>
                            <i class="fa fa-cog"></i> Change Password
                        </Link>
                    </span> */}
                    <span className="log-11" onClick={e => toggleModal()}>
                        <i className="fa fa-power-off" aria-hidden="true" /> Logout
                    </span>
                </span>

            </div>

            {/* Top menu */}

        </>
    );
}
export default ExhibitorTopMenu;