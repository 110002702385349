import { Component } from "react";
import { Link } from "react-router-dom";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ManualForms from "../../manual_forms";
import { addFasciaForm_action, updateFasciaForm_action, getFasciaDetails_action, getDefaultFasciaData_action, insertUpdateFacia_action } from '../../../../../actions/exhibitor_module_actions/manual_Forms_actions/forms_actions/fascia_form_action/fascia_form_action'
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import { toastError } from "../../../../../utils/constants";
import moment from "moment";

class FasciaForm extends Component {
    // Initialize Constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            is_add: false,
            is_update: false,
            exhibitorName: '',
            hallNo: '',
            stallNo: '',
            stallType: '',
            area: '',
            sidesOpen: '',
            fasciaName: '',
            fasciaFormId: '',
            DeadlineDate: "",
            currentDate: new Date(),
            FasiaDate: '',
            StatusID: '',
            isStallDesignfill: false,
            StallTypeName: '',
        }
        this.handleSave = this.handleSave.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.getDetails = this.getDetails.bind(this)
        this.getDefaultData = this.getDefaultData.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
    }

    componentDidMount() {
        console.log("Fascia Props", this.props.location.state)

        this.setState({
            is_update: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.is_update != " " ? this.props.location.state.is_update : '',
            is_add: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.is_add != " " ? this.props.location.state.is_add : '',
            fasciaFormId: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.fasciaFormId != " " ? this.props.location.state.fasciaFormId : '',
            DeadlineDate: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.Deadline != "" ? new Date(this.props.location.state.Deadline) : '',
            StatusID: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.StatusID != "" ? this.props.location.state.StatusID : '',
        })
        this.props.location.state != null && this.props.location.state != "" && this.props.location.state.is_update == true ? this.getDetails() : <></>
        this.props.location.state != null && this.props.location.state != "" && this.props.location.state.is_add == true ? this.getDefaultData() : <></>
    }

    getDetails() {
        const { fasciaName, fasciaFormId } = this.state
        this.setState({ isLoading: true })
        this.props.getFasciaDetails_action(fasciaFormId)
            .then(data => {
                if (data.error != 1) {
                    this.setState({
                        exhibitorName: data.data[0]['ExhibitorName'],
                        hallNo: data.data[0]['HallNo'],
                        stallNo: data.data[0]['StallNo'],
                        stallType: data.data[0]['StallTypeID'],
                        area: data.data[0]['Area'],
                        sidesOpen: data.data[0]['OpenSides'],
                        fasciaName: data.data[0]['FasciaName'],
                        fasciaFormId: data.data[0]['FasciaFormID'] != null ? data.data[0]['FasciaFormID'] : "",
                        isLoading: false,
                    })

                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch(err => { console.log(err) })
    }

    getDefaultData() {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props.getDefaultFasciaData_action()
            .then(data => {
                if (data.error != 1) {
                    console.log("Testing----------->")
                    this.setState({
                        isLoading: false,
                        exhibitorName: data.data[0]['ExhibitorName'],
                        hallNo: data.data[0]['HallNo'],
                        stallNo: data.data[0]['StallNo'],
                        stallType: data.data[0]['StallTypeID'],
                        area: data.data[0]['StallArea'],
                        sidesOpen: data.data[0]['OpenSides'],
                        fasciaName: data.data[0]['FasciaName'],
                        fasciaFormId: data.data[0]['FasciaFormID'] != null ? data.data[0]['FasciaFormID'] : "",
                        FasiaDate: data.data[0]['FasiaDate'],
                        isStallDesignfill: data.data[0]['isStallDesignfill'],
                        StallTypeName: data.data[0]['StallTypeName'],
                    })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch(err => { console.log(err) })
    }

    // handleSave() {
    //     const { fasciaName } = this.state
    //     this.setState({ isLoading: true })
    //     this.props.addFasciaForm_action(fasciaName)
    //         .then(data => {
    //             if (data.error != 1) {
    //                 this.setState({ isLoading: false })
    //                 this.props.navigate('/manualForms')

    //             } else {
    //                 this.setState({ isLoading: false })
    //                 toastError(data.msg)
    //             }
    //         })
    //         .catch(err => { console.log(err) })
    // }

    handleSave() {
        const { fasciaName, fasciaFormId } = this.state
        this.setState({ isLoading: true })
        this.props.insertUpdateFacia_action(fasciaName, fasciaFormId)
            .then(data => {
                if (data.error != 1) {
                    this.setState({ isLoading: false })
                    this.props.navigate('/manualForms')

                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch(err => { console.log(err) })
    }

    handleUpdate() {
        const { fasciaFormId, fasciaName } = this.state
        this.setState({ isLoading: true })
        this.props.updateFasciaForm_action(fasciaFormId, fasciaName)
            .then(data => {
                if (data.error != 1) {
                    this.setState({ isLoading: false })

                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch(err => { console.log(err) })
    }

    handleCancel() {
        this.props.navigate('/manualForms')
    }

    render() {
        const { isLoading, is_add, is_update, exhibitorName, hallNo, stallNo, stallType, area, sidesOpen, fasciaName, DeadlineDate, currentDate, FasiaDate, StatusID, isStallDesignfill } = this.state
        console.log("xxxxxxxxxxxxxx", currentDate > DeadlineDate)
        const toInputUppercase = e => {
            e.target.value = ("" + e.target.value).toUpperCase();
        };

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer></ToastContainer>
                <ExhibitorHeader />
                <ExhibitorDrawer />
                <div className="page">
                    <div className="page-main">
                        <div className="main-content with-dr-container">
                            <div className=" width-dr-nav">
                                <div className="row">
                                    <ExhibitorTopMenu />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card rounded-0">
                                        <div className="card-header p-3">
                                            <h3 className="card-title">Fascia Form</h3>
                                        </div>
                                        {this.state.StallTypeName != 'Bare Space' ?
                                            <div className="card-body p-2">

                                                <div style={{ lineHeight: '25px', textAlign: 'left' }}>
                                                    <p className="m-0"><strong>Note:</strong></p>
                                                    <ul style={{ listStyleType: 'disc' }}>
                                                        <li>Exhibitors who had opted for shell scheme are requested to indicate hereunder the name, which they require on the fascia. This will be provided on Front Fascia of the stall with standard 75 mm high (3”) ENGLISH ALPHABETS (Maximum 24 characters including spaces). Please use block letters only. Logos may not be allowed on the fascia. If the concerned exhibitor fails to submit this form within the deadline date, the organizer will have no option but to incorporate on the fascia the name of the company on which the booth is contracted.</li>
                                                        <li>For more details or any queries please contact: gttes@india-itme.com, itme@india-itme.com.</li>
                                                    </ul>
                                                </div>

                                                <div className="row">
                                                    <div className="col-xl-4">
                                                        <label htmlFor="validationCustom03">Exhibitor Name</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            value={exhibitorName}
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="col-xl-4">
                                                        <label htmlFor="validationCustom03">Hall No</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            value={hallNo}
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="col-xl-4">
                                                        <label htmlFor="validationCustom03">Stall No</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            value={stallNo}
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="col-xl-4 mt-3">
                                                        <label htmlFor="validationCustom03">Stall Type</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            value={stallType}
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="col-xl-4 mt-3">
                                                        <label htmlFor="validationCustom03">Area</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            value={area}
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="col-xl-4 mt-3">
                                                        <label htmlFor="validationCustom03">Sides open</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            value={sidesOpen}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <h6 className="mt-2">
                                                        FASCIA NAME FOR SHELL SCHEME EXHIBITORS{" "}
                                                        <span className="text-danger">
                                                            {" "}
                                                            Last Date Of Submission – {moment(DeadlineDate).format("DD-MM-YYYY")}
                                                        </span>
                                                    </h6>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="apper">
                                                        <h4>
                                                            <strong>
                                                                THE FOLLOWING NAME IS TO APPEAR ON THE FRONT FASCIA OF OUR
                                                                STAND{" "}
                                                            </strong>
                                                        </h4>
                                                        <p className="text-danger mb-1 mt-2">Fascia Name should not be greater than 24 characters</p>
                                                        <textarea
                                                            type="textarea"
                                                            className="form-control"
                                                            maxLength={'24'}
                                                            col=""
                                                            name=""
                                                            rows={2}
                                                            style={{ textAlign: "center", paddingTop: 20 }}
                                                            placeholder="INDIA ITME 2022"
                                                            value={fasciaName}
                                                            onInput={toInputUppercase}
                                                            onChange={(e) => this.setState({ fasciaName: e.target.value })}
                                                        />

                                                        <div className="btn-group mt-4">
                                                            {
                                                                is_add ? <button className="btn apper-submit" disabled={currentDate > DeadlineDate || isStallDesignfill}
                                                                    onClick={() => this.handleSave()}>Submit</button>
                                                                    : <button className="btn apper-submit" disabled={currentDate > DeadlineDate || isStallDesignfill}
                                                                        onClick={() => this.handleUpdate()}>Update</button>
                                                            }
                                                            <button className="btn apper-cancel" onClick={() => this.handleCancel()}>Back</button>


                                                        </div>
                                                        <div className="row">
                                                            {currentDate > DeadlineDate &&
                                                                <span className="text-danger">THE DEADLINE TO FILL OUT THE FORM HAS PASSED.</span>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <>
                                                <div className="row mt-4" style={{ height: '100px', alignItems: 'center' }}>
                                                    <h5 className="mt-2" style={{ textAlign: 'center' }}>
                                                        <span className="text-danger ">
                                                            Fascia Form is not available for Bare Space
                                                        </span>
                                                    </h5>
                                                </div>
                                                <div className="row mt-4 d-flex" style={{ textAlign: 'center', justifyContent:'center' }}>
                                                    <div className="btn-group mt-4" style={{ width: '100px' }}>
                                                        <button className="btn apper-cancel" onClick={() => this.handleCancel()}>Back</button>
                                                    </div>
                                                </div>

                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}

export default withRouter(connect(null, {
    addFasciaForm_action, updateFasciaForm_action, getFasciaDetails_action,
    getDefaultFasciaData_action, insertUpdateFacia_action
})(FasciaForm))