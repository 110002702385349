import { axiosGet, axiosPost } from "../../../../react_axios/Axios";
import { getTimelineListUrl, insertTimelineListUrl, updateTimelineListUrl, deleteTimelineListUrl, GET_TIMELINE_ACTION_TYPE, INSERT_TIMELINE_MASTER_LIST, UPDATE_TIMELINE_MASTER_LIST, DELETE_TIMELINE_MASTER_LIST, headers } from "../../../../utils/constants";
import moment from "moment";

export const getTimelineMasterList_action = (ExihibitionID, StartDate, EndDate, Description) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExihibitionID,
            StartDate: StartDate != '' ? moment(StartDate).format('YYYY-MM-DD') : null,
            EndDate: EndDate != '' ? moment(EndDate).format('YYYY-MM-DD') : null,
            Description: Description != '' ? Description : null,
        }

        const res = await axiosGet(getTimelineListUrl, params, headers)

        dispatch({
            type: GET_TIMELINE_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const insertTimelineMasterList_action = (ExihibitionID, StartDate, EndDate, Description) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExihibitionID,
            StartDate: StartDate != '' ? moment(StartDate).format('YYYY-MM-DD') : null,
            EndDate: EndDate != '' ? moment(EndDate).format('YYYY-MM-DD') : null,
            Description: Description,
        }

        const res = await axiosPost(insertTimelineListUrl, params, headers)

        dispatch({
            type: INSERT_TIMELINE_MASTER_LIST,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const updateTimelineMasterList_action = (ExihibitionID,StartDate, EndDate, Description, RowId) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExihibitionID,
            StartDate: StartDate != '' ? moment(StartDate).format('YYYY-MM-DD') : null,
            EndDate: EndDate != '' ? moment(EndDate).format('YYYY-MM-DD') : null,
            Description: Description,
            RowId: RowId,
        }

        const res = await axiosPost(updateTimelineListUrl, params, headers)

        dispatch({
            type: UPDATE_TIMELINE_MASTER_LIST,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const deleteTimelineMasterList_action = (ExihibitionID,RowId) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExihibitionID,
            RowId: RowId,
        }

        const res = await axiosGet(deleteTimelineListUrl, params, headers)

        dispatch({
            type: DELETE_TIMELINE_MASTER_LIST,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}