import { axiosPost } from "../../react_axios/Axios"
import { authUrl, platform, LOGIN_ACTION_TYPE, headers, changePasswordUrl, getLoginPassUrl, GET_LOGIN_PASS_ACTION_TYPE, CHANGE_PASSWORD_ACTION_TYPE, updatePasswordUrl, UPDATE_PASSWORD_ACTION_TYPE, forgetCheckedEmailUrl, forgetGetOtpUrl, forgetUpdatePasswordUrl, FORGET_CHECKED_EMAIL_ACTION_TYPE, FORGET_GET_OTP_ACTION_TYPE, FORGET_UPDATE_PASSWORD_ACTION_TYPE } from "../../utils/constants"

export const login_action = (Email, password) => async (dispatch) => {

  try {
    const mapdata = {
      Email: Email,
      password: password,
    }
    console.log("data----------", mapdata)
    const res = await axiosPost(authUrl, mapdata, headers);

    if (res != null) {
      console.log("login_action", res)
      sessionStorage.clear()
      // sessionStorage.setItem('UserLoginData', res)
      // sessionStorage.setItem('UserName', res.data['Email'])

      // sessionStorage.setItem('UserId', res.data['LoginID'])
      // sessionStorage.setItem('LoginRoleId', res.data['Roleid'])
      // sessionStorage.setItem('LoginAccesslist', JSON.stringify(res.data['loginAccesslist']))
      // sessionStorage.setItem('ReportAccesslist', JSON.stringify(res.data['reportAccesslist']))

    }

    dispatch({
      type: LOGIN_ACTION_TYPE,
      payload: res,
    });

    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const get_login_pass_action = (exhid, Username) => async (dispatch) => {
  try {
    const mapdata = {
      ExhibitionID: exhid,
      Username: Username,
    }
    console.log("data----------", mapdata)
    const res = await axiosPost(getLoginPassUrl, mapdata, headers);

    if (res != null) {
      console.log("login_action", res)
    }

    dispatch({
      type: GET_LOGIN_PASS_ACTION_TYPE,
      payload: res,
    });

    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};


export const changePassword_action = (oldPassword, newPassword) => async (dispatch) => {
  try {
    const mapdata = {
      loginUserID: parseInt(sessionStorage.getItem('UserId')),
      platform: platform,
      oldPassword: oldPassword.toString().trim(),
      newPassword: newPassword.toString().trim(),
    }
    const res = await axiosPost(changePasswordUrl, mapdata, headers);
    dispatch({
      type: CHANGE_PASSWORD_ACTION_TYPE,
      payload: res,
    });

    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updatePassword_action = (oldPassword, newPassword) => async (dispatch) => {
  try {
    const mapdata = {
      UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
      OldPassword: oldPassword.toString().trim(),
      NewPassword: newPassword.toString().trim(),
    }
    const res = await axiosPost(updatePasswordUrl, mapdata, headers);
    dispatch({
      type: UPDATE_PASSWORD_ACTION_TYPE,
      payload: res,
    });

    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};

// login forget password

export const forgetCheckedEmail_action = (email) => async (dispatch) => {
  try {
    const mapdata = {
      UserID: null,
      EmailID: email.toString().trim(),
    }

    const res = await axiosPost(forgetCheckedEmailUrl, mapdata, headers);
    dispatch({
      type: FORGET_CHECKED_EMAIL_ACTION_TYPE,
      payload: res,
    });

    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const forgetGetOtp_action = (email) => async (dispatch) => {
  try {
    const mapdata = {
      Email: email.toString().trim(),
    }

    const res = await axiosPost(forgetGetOtpUrl, mapdata, headers);
    dispatch({
      type: FORGET_GET_OTP_ACTION_TYPE,
      payload: res,
    });

    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const forgetUpdatePassword_action = (password) => async (dispatch) => {
  try {
    const mapdata = {
      UserID: sessionStorage.getItem("userIdForget"),
      Password: password,
    }

    const res = await axiosPost(forgetUpdatePasswordUrl, mapdata, headers);
    dispatch({
      type: FORGET_UPDATE_PASSWORD_ACTION_TYPE,
      payload: res,
    });

    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};

