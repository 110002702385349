import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action } from '../../../../../actions/fillCombo_action';
import { cityActionId, stallTypeActionId, stallListActionId, countryActionId, exhibitionActionId, toastError, toastSuccess } from "../../../../../utils/constants";
import { emailValidator } from "../../../../../utils/Validator";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import Pagination from "../../../../../utils/pagination";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import { useNavigate } from "react-router-dom";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import { data } from "jquery";
import { deleteHallMaster_action } from "../../../../../actions/admin_module_action/master_action/hall_master_action";
import { getHostAndHostessMasterList_action, insertUpdateHostAndHostessMaster_action, deleteHostAndHostessMaster_action } from "../../../../../actions/admin_module_action/master_action/manual_form_master_action/host_and_hostess_master_action";
import Footer from "../../../../../common_components/Footer";


class HostAndHostessMaster extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isOpen: false,
            // currentPage: 1,
            AgencyContactList: [{
                'AgencyName': '',
                'AgencyEmail': '',
                'NAME': '',
                "Mobile": '',
                'isNew': true,
            }],
            CategoryList: [{
                'CategoryName': '',
                'Type': '',
                'PriceINR': '',
                "PriceUSD": '',
                'isNew': true,
            }],
            AgencyHostAndHostessID: '',
            HostAndHostessCategoryID: '',
            deleteType: '',
        }

        this.recordAgency = this.recordAgency.bind(this)
        this.recordCategory = this.recordCategory.bind(this)
        this.onInputAgency = this.onInputAgency.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleAddAgency = this.handleAddAgency.bind(this)
        this.handleAddCategory = this.handleAddCategory.bind(this)
        // this.deleteHostAndHostessMaster = this.deleteHostAndHostessMaster.bind(this)
        // this.onDelete = this.onDelete.bind(this)
        this.handleAddAgency = this.handleAddAgency.bind(this)
        this.handleAddCategory = this.handleAddCategory.bind(this)
        this.onInputCategory = this.onInputCategory.bind(this)
    }

    componentDidMount() {
        this.getHostAndHostessMasterList(null)
    }

    onInputAgency(value, i, flag) {
        const { AgencyContactList } = this.state
        console.log(value, i, flag)
        if (flag == 'AgencyName') {
            AgencyContactList[i].AgencyName = value
        } else if (flag == 'AgencyEmail') {
            AgencyContactList[i].AgencyEmail = value
        } else if (flag == 'Mobile') {
            AgencyContactList[i].Mobile = value
        } else if (flag == 'NAME') {
            AgencyContactList[i].NAME = value
        }
        this.setState({ AgencyContactList: AgencyContactList })
    }

    onInputCategory(value, i, flag) {
        const { CategoryList } = this.state
        console.log(value, i, flag)
        if (flag == 'CategoryName') {
            CategoryList[i].CategoryName = value
        } else if (flag == 'Type') {
            CategoryList[i].Type = value
        } else if (flag == 'PriceINR') {
            CategoryList[i].PriceINR = value
        } else if (flag == 'PriceUSD') {
            CategoryList[i].PriceUSD = value
        }
        this.setState({ CategoryList: CategoryList })
    }

    recordAgency(list) {
        return (
            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
                <thead>
                    <tr>
                        <th className="text-center" style={{ width: '50px' }}>Sr no.</th>
                        <th className="text-center">Agency Name<span className="text-red">*</span></th>
                        <th className="text-center">Agency Email<span className="text-red">*</span></th>
                        <th className="text-center">Name<span className="text-red">*</span></th>
                        <th className="text-center">Mobile<span className="text-red">*</span></th>
                        <th className="text-center" style={{ width: '50px' }}></th>

                    </tr>
                </thead>
                <tbody>
                    {
                        list.length > 0 ? <>{
                            list.map((data, i) => (
                                <tr key={i}>
                                    <td className="text-center" style={{ width: '50px' }}>{i + 1}</td>
                                    <td className="text-center">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            value={data.AgencyName}
                                            onChange={(e) => this.onInputAgency(e.target.value, i, 'AgencyName')}
                                        />
                                    </td>
                                    <td className="text-center">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            value={data.AgencyEmail}
                                            onChange={(e) => this.onInputAgency(e.target.value, i, 'AgencyEmail')}
                                        />
                                    </td>
                                    <td className="text-center">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            value={data.NAME}
                                            onChange={(e) => this.onInputAgency(e.target.value, i, 'NAME')}

                                        />
                                    </td>
                                    <td className="text-center">
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder=""
                                            value={data.Mobile}
                                            onChange={(e) => this.onInputAgency(e.target.value, i, 'Mobile')}

                                        />
                                    </td>
                                    <td className="text-center">
                                        <button
                                            type="button"
                                            className="btn btn-outline-danger btn-sm"
                                            onClick={(e) => this.onDelete(data.AgencyHostAndHostessID, i, data.isNew, 1)}
                                        >
                                            <i className="fa fa-trash action_btn_table" />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </> :
                            <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                    }
                </tbody>
            </table>)
    }

    recordCategory(list) {
        return (
            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
                <thead>
                    <tr>
                        <th className="text-center" style={{ width: '50px' }}>Sr no.</th>
                        <th className="text-center">Category Name<span className="text-red">*</span></th>
                        <th className="text-center">Type<span className="text-red">*</span></th>
                        <th className="text-center">Price (INR)<span className="text-red">*</span></th>
                        <th className="text-center">Price (USD)</th>
                        <th className="text-center" style={{ width: '50px' }}></th>

                    </tr>
                </thead>
                <tbody>
                    {
                        list.length > 0 ? <>{
                            list.map((data, i) => (
                                <tr key={i}>
                                    <td className="text-center" style={{ width: '50px' }}>{i + 1}</td>
                                    <td className="text-center">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            value={data.CategoryName}
                                            onChange={(e) => this.onInputCategory(e.target.value, i, 'CategoryName')}
                                        />
                                    </td>
                                    <td className="text-center">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            value={data.Type}
                                            onChange={(e) => this.onInputCategory(e.target.value, i, 'Type')}
                                        />
                                    </td>
                                    <td className="text-center">
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder=""
                                            value={data.PriceINR}
                                            onChange={(e) => this.onInputCategory(e.target.value, i, 'PriceINR')}

                                        />
                                    </td>
                                    <td className="text-center">
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder=""
                                            value={data.PriceUSD}
                                            onChange={(e) => this.onInputCategory(e.target.value, i, 'PriceUSD')}

                                        />
                                    </td>
                                    <td className="text-center">
                                        <button
                                            type="button"
                                            className="btn btn-outline-danger btn-sm"
                                            onClick={(e) => this.onDelete(data.HostAndHostessCategoryID, i, data.isNew, 2)}
                                        >
                                            <i className="fa fa-trash action_btn_table" />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </> :
                            <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                    }
                </tbody>
            </table>)
    }

    getHostAndHostessMasterList(status) {
        this.setState({ isLoading: true })
        const { AgencyContactList, CategoryList } = this.state
        this.props.getHostAndHostessMasterList_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log(data.data.AgencyContactList)
                    status == 1 ? data.data.AgencyContactList.map((item) => { item.isNew = false }) : data.data.AgencyContactList.length > 0 && data.data.AgencyContactList.map((item) => { item.isNew = false })
                    status == 1 ? this.setState({ AgencyContactList: data.data.AgencyContactList }) : data.data.AgencyContactList.length > 0 && this.setState({ AgencyContactList: data.data.AgencyContactList })

                    status == 1 ? data.data.CategoryList.map((item) => { item.isNew = false }) : data.data.CategoryList.length > 0 && data.data.CategoryList.map((item) => { item.isNew = false })
                    status == 1 ? this.setState({ CategoryList: data.data.CategoryList }) : data.data.CategoryList.length > 0 && this.setState({ CategoryList: data.data.CategoryList })
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    handleAddAgency() {
        const { AgencyContactList } = this.state
        console.log("pp")
        var obj = {
            'AgencyName': '',
            'AgencyEmail': '',
            "NAME": '',
            'Mobile': '',
            'isNew': true
        }
        AgencyContactList.push(obj)
        this.setState({ AgencyContactList: AgencyContactList })
    }
    handleAddCategory() {
        const { CategoryList } = this.state
        console.log("pp")
        var obj = {
            'CategoryName': '',
            'Type': '',
            "PriceINR": '',
            'PriceUSD': '',
            'isNew': true
        }
        CategoryList.push(obj)
        this.setState({ CategoryList: CategoryList })
    }

    handleSubmit() {
        const { CategoryList, AgencyContactList } = this.state
        var AgencyContactListArr = [...AgencyContactList]
        var CategoryListArr = [...CategoryList]
        console.log(AgencyContactListArr, CategoryListArr)
        var err = false
        for (var i = 0; i < AgencyContactListArr.length; i++) {
            if (AgencyContactListArr[i].AgencyName == '' || AgencyContactListArr[i].AgencyName == null) {
                err = 'Please enter mandatory fields'
                break
            }
            if (AgencyContactListArr[i].NAME == '' || AgencyContactListArr[i].NAME == null) {
                err = 'Please enter mandatory fields'
                break
            }
            if (AgencyContactListArr[i].AgencyEmail == '' || AgencyContactListArr[i].AgencyEmail == null) {
                err = 'Please enter mandatory fields'
                break
            } else {
                err = (emailValidator(AgencyContactListArr[i].AgencyEmail) == '') ? false : emailValidator(AgencyContactListArr[i].AgencyEmail)
                if (err) {
                    break
                }
            }
            if (AgencyContactListArr[i].Mobile == '' || AgencyContactListArr[i].Mobile == null) {
                err = 'Please enter mandatory fields'
                break

            }
        }
        for (var i = 0; i < CategoryListArr.length; i++) {
            if (CategoryListArr[i].CategoryName == '' || CategoryListArr[i].CategoryName == null) {
                err = 'Please enter mandatory fields'
                break
            }
            if (CategoryListArr[i].Type == '' || CategoryListArr[i].Type == null) {
                err = 'Please enter mandatory fields'
                break
            }
            if (CategoryListArr[i].PriceINR == '' || CategoryListArr[i].PriceINR == null) {
                err = 'Please enter mandatory fields'
                break
            }
        }
        if (!err) {
            this.setState({ isLoading: true })
            this.props.insertUpdateHostAndHostessMaster_action(AgencyContactListArr, CategoryListArr)
                .then((data) => {
                    if (data.error != 1) {
                        console.log(data.data)
                        this.getHostAndHostessMasterList(1)
                        this.setState({ isLoading: false, })
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            toastError(err)
        }
    }

    deleteHostAndHostessMaster(id) {
        const { deleteType } = this.state
        this.setState({ isLoading: true })
        this.props.deleteHostAndHostessMaster_action(deleteType == 1 ? id : '', deleteType == 2 ? id : '')
            .then((data) => {
                if (data.error != 1) {
                    console.log(data.data)
                    this.setState({ isLoading: false, deleteType: '' })
                    toastSuccess("deleted successfully")
                    setTimeout(() => {
                        this.getHostAndHostessMasterList(1)
                    }, 200);
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
                this.setState({ isOpen: false })
            })
            .catch((err) => {
                console.log(err)
            })
    }


    onDelete(id, index, status, type) {
        const { AgencyContactList, deleteType, CategoryList } = this.state
        console.log(status, index, type)
        if (type == 1) {
            if (status) {
                AgencyContactList.splice(index, 1)
                this.setState({ AgencyContactList: AgencyContactList })
            } else {
                this.setState({ deleteID: id })
                this.setState({ isOpen: true, })
            }
            this.setState({ deleteType: 1 })
        } else if (type == 2) {
            if (status) {
                CategoryList.splice(index, 1)
                this.setState({ CategoryList: CategoryList })
            } else {
                this.setState({ deleteID: id })
                this.setState({ isOpen: true, })
            }
            this.setState({ deleteType: 2 })
        }
    }


    render() {
        const { isOpen, isLoading, AgencyContactList, CategoryList } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <div className="app-content with-dr-container com-height">
                        <AdminDrawer />
                        <div className="row">
                            <div className="step-name">
                                <p>Host and Hostess Master</p>
                            </div>
                        </div>
                        <div className="m-2">

                            <div className="table-responsive">
                                <div
                                    className="card-header p-3"
                                    style={{ paddingLeft: "7px !important" }}
                                >
                                    <h3 className="card-title" style={{ fontSize: 15 }}>
                                        Agency contact details
                                    </h3>
                                </div>
                                {this.recordAgency(AgencyContactList)}
                            </div>
                        </div>
                        <div className="btn-group m-2 save-can d-block" >
                            <div className="btn-group" role="group" aria-label="Basic example">
                                <button type="button" className="btn btn-primary rounded-0 d-block" style={{ marginLeft: '5px', cursor: 'pointer' }}
                                    onClick={this.handleAddAgency}
                                >Add more</button>
                            </div>
                        </div>
                        <br />
                        <div className="m-2">

                            <div className="table-responsive">
                                <div
                                    className="card-header p-3"
                                    style={{ paddingLeft: "7px !important" }}
                                >
                                    <h3 className="card-title" style={{ fontSize: 15 }}>
                                        Category List
                                    </h3>
                                </div>
                                {this.recordCategory(CategoryList)}
                            </div>
                        </div>
                        <div className="btn-group m-2 save-can d-block" >
                            <div className="btn-group" role="group" aria-label="Basic example">
                                <button type="button" className="btn btn-primary rounded-0 d-block" style={{ marginLeft: '5px', cursor: 'pointer' }}
                                    onClick={this.handleAddCategory}
                                >Add more</button>
                            </div>
                        </div>
                        <div className="btn-group m-2 save-can d-block" >
                            <div className="btn-group" role="group" aria-label="Basic example" style={{ float: 'right' }} >
                                <button type="button" className="btn btn-success rounded-0 d-block" data-dismiss="modal" style={{ marginLeft: '5px' }}
                                    onClick={this.handleSubmit} disabled={AgencyContactList.length == 0 || CategoryList.length == 0}
                                >Submit</button>
                            </div>
                        </div>
                    </div>
                </>
                <Modal isOpen={isOpen} onRequestClose={() => this.setState({ isOpen: false })}>
                    <div className="modal-dialog" style={{ marginTop: '20%' }}>
                        <div className="modal-content confirm-logout">
                            <div class="modal-header text-right p-2">
                                <h5 class="modal-title modal_title_text text-danger">Do you really want to delete this record?</h5>

                                {/* <button type="button " class="close pop-close" data-dismiss="modal"
                                    onClick={() => this.setState({ isOpen: false })}
                                ><span aria-hidden="true" class="pop-cancel">&times;</span></button> */}
                            </div>
                            {/* <div class="modal-body">
                                <div class="row">
                                </div>
                            </div> */}
                            <div class="modal-footer p-1">
                                <div class="form-group text-right mod-fot">
                                    <button type="button" class="btn btn-del-pop"
                                        onClick={(e) => this.deleteHostAndHostessMaster(this.state.deleteID)}
                                    >Yes</button>
                                    <button type="button" class="btn btn-can-pop" data-dismiss="modal" style={{ marginLeft: '5px' }}
                                        onClick={() => this.setState({ isOpen: false })}
                                    >No</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
                <Footer />
            </>)
    }

}
export default withRouter(connect(null, { fill_action, getHostAndHostessMasterList_action, insertUpdateHostAndHostessMaster_action, deleteHostAndHostessMaster_action })(HostAndHostessMaster));