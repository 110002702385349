import { GET_VISITOR_BADGE_ACCESS_LOG_ACTION_TYPE, GET_VISITOR_LIST_ACTION_TYPE, INSERT_VISITOR_BADGE_ACCESS_LOG_ACTION_TYPE, SEND_USER_CRED_ACTION_TYPE, SEND_VISITOR_BADGE_ACTION_TYPE } from "../../../utils/constants";


const initialState = [];

function visitor_listReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_VISITOR_LIST_ACTION_TYPE:
            return [...state, payload];
        case SEND_USER_CRED_ACTION_TYPE:
            return [...state, payload];
        case SEND_VISITOR_BADGE_ACTION_TYPE:
            return [...state, payload];
        case INSERT_VISITOR_BADGE_ACCESS_LOG_ACTION_TYPE:
            return [...state, payload];
        case GET_VISITOR_BADGE_ACCESS_LOG_ACTION_TYPE:
            return [...state, payload];

        default:
            return state;
    }
}
export default visitor_listReducer;